import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { t } from 'i18next'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import style from './style.module.scss'

import { createPartnerType, getPartnerTypes } from '@sweetspot/club-portal-legacy/store/actions'

class CreatePartner extends Component {
  state = {
    partnerName: '',
    partnerTypes: [],
    isLoading: false,
  }

  componentDidMount = () => {
    this.getPartnerTypes()
  }

  handleKeyPress(e) {
    const { partnerName } = this.state

    if (e.charCode === 13 && partnerName) this.props.createPartnerType(partnerName)
  }

  handleChange(e) {
    const { name, value } = e.target

    this.setState({
      [name]: value,
    })
  }

  getPartnerTypes = () => {
    const { token, golfClub, getPartnerTypes } = this.props

    this.setState({ isLoading: true })
    getPartnerTypes(token, golfClub.selectedId)
      .then((response) => {
        this.setState({
          isLoading: false,
          partnerTypes: response,
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          partnerTypes: [],
        })
      })
  }

  createPartnerType = (name) => {
    const { token, golfClub } = this.props

    this.setState({ isLoading: true })
    this.props
      .createPartnerType(token, golfClub.selectedId, name)
      .then((response) => {
        this.setState({
          isLoading: false,
          partnerTypes: this.state.partnerTypes.concat(response),
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  renderButtons = () => {
    const { partnerName } = this.state

    return (
      <div className={style.btnGroup}>
        <button
          className="system-button primary md-32"
          disabled={this.state.isLoading || !partnerName}
          onClick={() => this.createPartnerType(partnerName)}
        >
          {t('create')}
        </button>
      </div>
    )
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.label}>
          <Text textId="settings.createTag" />
          <div>
            <PulseLoader fillHeight showIf={this.state.isLoading} />
          </div>
        </div>
        <input
          className="ss-input"
          name="partnerName"
          placeholder={getLang(this.props.lang)['settings']['createTagPlaceholder']}
          onKeyPress={(e) => this.handleKeyPress(e)}
          onChange={(e) => this.handleChange(e)}
        />
        {this.renderButtons()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.me.lang,
    token: state.auth.token,
    golfClub: state.golfClub,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createPartnerType: (token, clubId, name) => dispatch(createPartnerType(token, clubId, name)),
    getPartnerTypes: (token, clubId) => dispatch(getPartnerTypes(token, clubId)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreatePartner))

CreatePartner.propTypes = {
  createPartnerType: PropTypes.func.isRequired,
  getPartnerTypes: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  golfClub: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
}
