import React from 'react'

const PoliciesContext = React.createContext({
  values: {
    clubId: null,
    membershipId: null,
    openPolicies: [],
    allClubs: [],
    policies: [],
  },
  actionStates: {
    checkingPolicies: true,
    setCheckingPolicies: () => {},
    savingNewPolicy: false,
    setSavingNewPolicy: () => {},
    savingExistingPolicy: false,
    setSavingExistingPolicy: () => {},
  },
  functions: {
    setOpenPolicy: () => {},
    deleteNewPolicy: () => {},
    replaceNewPolicy: () => {},
    toggleOpenPolicy: (id = 0) => {},
    archivePolicy: (policyId = 0) => {},
    updatePolicy: () => {},
  },
})

export default PoliciesContext
