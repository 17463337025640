import { AuthState } from '@sweetspot/club-portal-legacy/store/types'

export const initialState: AuthState = {
  token: '',
  loggedIn: false,
  me: {
    id: null,
    uuid: null,
    email: null,
    firstName: null,
    lastName: null,
    lang: 'gb',
  },
  roles: [],
  isSuperAdmin: false,
}
