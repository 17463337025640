import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Searches for players by query
 */
export const searchPlayers = (
  query = {
    search: null,
    email: null,
    phone: null,
    golfId: null,
    relatedGolfClub: null,
    golfClub: null,
    'type[members]': null,
    'type[guests]': null,
    'membership[golf_club_id]': null,
    membership: null,
    'membershipPaid[paid]': null,
    'membershipPaid[not_paid]': null,
    gitOnlyMembership: null,
    externalPlayer: null,
    friendSearch: null,
    page: 1,
    limit: 50,
  }
) => {
  let searchUrl = `${getApiPlatformBaseUrl()}/players`

  const url = queryString.stringifyUrl(
    {
      url: searchUrl,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get player by ID
 *
 * @param {number} playerId
 * @returns
 */
export const getPlayerById = (playerId) => {
  const url = `${getApiPlatformBaseUrl()}/players/${playerId}`
  return request(url)
}

/**
 * Patch player by ID
 *
 * @param {number} playerId
 * @param {{}} payload
 * @returns
 */
export const patchPlayerById = (playerId, payload) => {
  const url = `${getApiPlatformBaseUrl()}/players/${playerId}`
  return request(url, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: payload,
  })
}

/**
 * Delete player by ID
 *
 * @param {number} playerId
 * @returns
 */
export const deletePlayerById = (playerId) => {
  const url = `${getApiPlatformBaseUrl()}/players/${playerId}`
  return request(url, {
    method: 'DELETE',
  })
}

/**
 * Query vouchers/coupons by player uuid
 * Deprecated API, instead use V2 from below
 */
export const getPlayerVouchers = (
  playerId,
  query = {
    player: null,
    'player[]': null,
    promotion_coupon: null,
    'promotion_coupon[]': null,
    page: 1,
    limit: 50,
  }
) => {
  if (playerId) {
    query.player = playerId
  }
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/player-promotion-coupons`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Query vouchers/coupons
 *
 * @param {{
 *  'player'?: number?,
 *  'player[]'?: number[]?,
 *  'promotion'?: any?,
 *  'promotion[]'?: any[]?,
 *  'promotion.club'?: number?,
 *  'orders.uuid'?: string?,
 *  'orders.uuid[]'?: string[]?,
 *  'page'?: number?,
 *  'limit'?: number?,
 * }} [query]
 */
export const queryPromotionCoupons = (
  query = {
    player: null,
    'player[]': null,
    promotion: null,
    'promotion[]': null,
    'promotion.club': null,
    'orders.uuid': null,
    'orders.uuid[]': null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/promotion-coupons`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get voucher coupons of the booking owner with eligibility state
 *
 * @param {string} bookingUuid
 * @returns voucher coupons array
 */
export const getEligibleBookingCoupons = (bookingUuid) => {
  const url = `${getApiPlatformBaseUrl()}/bookings/${bookingUuid}/coupons/eligible`
  return request(url)
}

/**
 * Assign coupon to player
 *
 * @param {string|number} playerId
 * @param {string} couponCode
 */
export const assignCouponToPlayer = (playerId, couponCode) => {
  const url = `${getApiPlatformBaseUrl()}/crm/player/${playerId}/coupon/assign`
  return request(url, { method: 'PUT', body: { code: couponCode } })
}

/**
 * Import player from federation
 *
 * @returns
 */
export const importPlayerFromFederation = (payload = { federation: null, identifier: null }) => {
  const url = `${getApiPlatformBaseUrl()}/players/federation/import`
  return request(url, { method: 'POST', body: payload })
}
