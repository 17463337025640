import {
  Hydra,
  Payment,
  PaymentRequestPayload,
  getFailedMembershipPayments,
} from '@sweetspot/shared/data-access/api-platform'
import { QueryObserverOptions, useQuery } from 'react-query'
import { PAYMENT_QUERY_KEYS } from '../constants/queryKeys'

export interface FailedPaymentsProps<TResult> {
  query?: PaymentRequestPayload
  queryOptions?: QueryObserverOptions
  select?: (data: TResult) => TResult
}

export const useFailedPayments = <TResult = Hydra<Payment>>(
  props?: FailedPaymentsProps<TResult>
) => {
  return useQuery({
    queryKey: [
      PAYMENT_QUERY_KEYS.FAILED_PAYMENT,
      props?.query?.page,
      props?.query?.['retry.attempts'],
    ],
    queryFn: () => getFailedMembershipPayments(props.query),
    placeholderData: { 'hydra:member': [] },
    ...props?.queryOptions,
  })
}
