import { useSelector } from 'react-redux'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import { useCallback } from 'react'

const useTranslation = () => {
  const lang = useSelector((state) => state.auth.me.lang)

  const t = useCallback(
    (string) => {
      return GetTranslatedString(lang, string)
    },
    [lang]
  )

  return { t, currentLang: lang }
}

export default useTranslation
