import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, useHistory } from 'react-router-dom'
import style from './style.module.scss'
import moment from 'moment'
import i18n from 'i18next'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import BookingModal from '@sweetspot/club-portal-legacy/modals/BookingModal'
import EditTeeTime from '../../components/EditTeeTime'
import PlayerCardModal from '@sweetspot/club-portal-legacy/modals/PlayerCard'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

import { getTeeTimesForDayFromGolfCourse } from '@sweetspot/club-portal-legacy/store/actions'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import {
  exportNoShowPlayerSlots,
  getNoShowSlots,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

import { ReactComponent as GpsIcon } from '@sweetspot/sweetspot-js/assets/svgs/gps-icon.svg'
import {
  getStartAndEndOfDateLocal,
  toISOStringWithOffset,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { formatToDatePicker } from '@sweetspot/club-portal-legacy/utils/date'
import { useForm } from 'react-hook-form'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'
import { ReactComponent as CalendarIcon } from '../../resources/images/calendar-icon_small.svg'
import { useTranslation } from 'react-i18next'
import {
  InputBase,
  InputContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  InputTrailingContainer,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'

const bookingStatus = ['pay on site', 'paid', 'waiting for payment']

const noShowHeaders = {
  teeTime: {
    name: '.teeTime',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
  },
  course: {
    name: '.course',
    type: 'label',
    width: '200px',
    selected: true,
  },
  player: {
    name: '.player',
    type: 'label',
    valueType: 'string',
    width: '120px',
    selected: true,
  },
  golfId: {
    name: '.golfId',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
  },
  bookingOwner: {
    name: '.booking.bookingOwner',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
  },
  email: {
    name: '.email',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  phone: {
    name: '.phone',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
}

const NoShow = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [noShows, setNoShows] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [showBookingPopup, setShowBookingPopup] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [noSearchResult, setNoSearchResult] = useState(false)
  const [selectedTeeTime, setSelectedTeeTime] = useState(null)
  const [course, setCourse] = useState(null)
  const currentClubCoursesList = useSelector(({ golfCourse }) => golfCourse.list)

  const { golfClubs, selectedGolfCourse } = useSelector((state) => ({
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubs: state.golfClub,
    golfCourses: state.golfCourse.list,
    selectedGolfCourse: state.golfCourse.selectedId,
  }))

  const {
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start_date: moment().local().format('YYYY-MM-DD'),
    },
  })

  const formState = watch()

  const getNoShows = async (pageNum = 1) => {
    setIsLoading(true)

    const startDate = moment(formState.start_date).local().format('YYYY-MM-DDTHH:mm:ss')

    let query = `?isArrivalRegistration=false&course_type=course&booking.git_booking.id%5Bexists%5D=false&booking.startTime%5Bgt%5D=${startDate}&order%5Bbooking.startTime%5D=desc&page=${pageNum}`

    if (formState.end_date) {
      const endDate = moment(formState.end_date).local().format('YYYY-MM-DDTHH:mm:ss')
      query += `&booking.endTime%5Blt%5D=${endDate}`
    }

    if (course) {
      query += `&booking.course.id=${course.id}`
    }

    if (golfClubs.selectedId) query += `&club=${golfClubs.selectedId}`
    if (searchString) query += `&search=${encodeURIComponent(searchString)}`
    bookingStatus.forEach((status) => {
      query += `&${encodeURIComponent('booking.status[]')}=${encodeURIComponent(status)}`
    })
    const [res, err] = await to(getNoShowSlots(golfClubs.selectedId, query))

    if (res) {
      const rowsPerPage = res['hydra:itemsPerPage']
      const totalRows = res['hydra:totalItems']
      const totalPages = totalRows <= rowsPerPage ? 1 : Math.ceil(totalRows / rowsPerPage)
      const noShowList = res['hydra:member']
      const parsedNoShows = parseNoShows(noShowList)

      if (!totalRows && currentPage > 1) {
        setCurrentPage(pageNum - 1)
        getNoShows(pageNum - 1)
        return
      }

      setNoShows(parsedNoShows)
      setNoSearchResult(!!searchString && !parsedNoShows.length)
      setTotalPages(totalPages)
      setRowsPerPage(rowsPerPage)
      setTotalRows(totalRows)
    } else if (err) {
      setNoShows([])
      setNoSearchResult(false)
      setTotalPages(1)
      setRowsPerPage(100)
      setTotalRows(0)
    }
    setIsLoading(false)
  }

  const parseNoShows = (fetchedNoShows) => {
    return fetchedNoShows.map((x) => {
      const player = x?.player?.first_name
        ? `${x?.player?.first_name} ${x?.player?.last_name}`
        : x?.stub_player?.name || '-'
      return {
        id: x.id,
        teeTime: DateHelpers.dateStringwithMinutes(moment(x.booking?.start_time)),
        from: x.booking?.start_time,
        course: x.booking?.course?.name,
        courseId: x.booking?.course?.id,
        courseUuid: x.booking?.course?.uuid,
        player,
        playerId: x?.player?.id || '-',
        golfId: x?.player?.golf_id || '-',
        cdhId: x?.player?.cdh_id || '-',
        bookingOwner: x?.is_owner ? i18n.t('words.yes') : i18n.t('words.no'),
        email: x?.player?.email || '-',
        phone: x?.player?.phone || '-',
      }
    })
  }

  const handleCloseBookingModal = () => {
    setShowBookingPopup(false)
    getNoShows(currentPage || 1)
  }

  const handleSearchChange = (value) => {
    setSearchString(value)
    getNoShows(1)
  }

  const setPageNum = (page) => {
    setCurrentPage(page)
    getNoShows(page)
  }

  const openTeeTimeModal = (row) => {
    if (selectedGolfCourse === 0) return
    setIsLoading(true)
    const dailyTimeStamp = new Date(row.from)
    const [startDay, endDay] = getStartAndEndOfDateLocal(dailyTimeStamp)
    dispatch(
      getTeeTimesForDayFromGolfCourse(
        row.courseUuid ? row.courseUuid : selectedGolfCourse,
        toISOStringWithOffset(startDay),
        toISOStringWithOffset(endDay)
      )
    )
      .then((teeTimes) => {
        const timestamp = moment.utc(row?.from).unix()
        const teeTime = teeTimes.find((time) => moment.utc(time.from).unix() === timestamp)
        if (!teeTime) return
        setIsLoading(false)
        setSelectedTeeTime(teeTime)
        setShowBookingPopup(true)
      })
      .catch(() => {
        setIsLoading(false)
        setSelectedTeeTime(null)
        setShowBookingPopup(false)
      })
  }

  const openPlayerCard = (row) => {
    history.push(`no-show/player/${row.playerId}`)
  }

  const getNoShowHeaders = () => {
    const currentGolfClub = golfClubs.list.find((club) => club.id === golfClubs.selectedId)
    const hasCdhNumber = !!currentGolfClub?.cdh_id

    const noShowHeader = {}

    for (let key in noShowHeaders) {
      if (noShowHeaders[key].selected) {
        if (hasCdhNumber && key === 'golfId') {
          noShowHeader.cdhId = noShowHeaders.golfId
        } else {
          noShowHeader[key] = noShowHeaders[key]
        }
      }
    }

    return noShowHeader
  }

  const exportNoShowPlayers = async () => {
    const startDate = moment(formState.start_date).local().format('YYYY-MM-DDTHH:mm:ss')
    let query = `?isArrivalRegistration=false&course_type=course&booking.git_booking.id%5Bexists%5D=false%5Bexists%5D&booking.startTime%5Bgt%5D=${startDate}&order%5Bbooking.startTime%5D=desc&page=${
      currentPage || 1
    }`

    if (formState.end_date) {
      const endDate = moment(formState.end_date).local().format('YYYY-MM-DDTHH:mm:ss')
      query += `&booking.endTime%5Blt%5D=${endDate}`
    }

    if (course) {
      query += `&booking.course.id=${course.id}`
    }

    if (golfClubs.selectedId) query += `&club=${golfClubs.selectedId}`
    if (searchString) query += `&search=${encodeURIComponent(searchString)}`
    bookingStatus.forEach((status) => {
      query += `&${encodeURIComponent('booking.status[]')}=${encodeURIComponent(status)}`
    })
    const [res, err] = await to(exportNoShowPlayerSlots(query))

    if (res) {
      console.log('No-show players were exported')
    } else if (err) {
      console.log(err)
    }
  }

  const handleActionConfirm = (action) => {
    switch (action.action) {
      case 'exportNoShowPlayers':
        exportNoShowPlayers()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (formState.start_date) {
      getNoShows(currentPage)
    }
  }, [currentPage, formState.start_date, formState.end_date, course?.id])

  const handleClearCourse = () => {
    setCourse(undefined)
  }

  const renderCourseSelect = () => {
    return (
      <InputSelect value={course?.id} onValueChange={(course) => setCourse(course)}>
        <InputBase className="relative">
          <InputSelectTrigger asChild className="w-[300px]">
            <InputContainer className="ring-border-stroke-subtle">
              <InputSelectStatefulInput
                placeholder={t('sentences.selectCourse_one')}
                className="h-[38px] text-ellipsis pl-4 pr-8 text-sm"
                value={course?.name || ''}
              />
              {!course?.name && <InputSelectTrailingContainer />}
            </InputContainer>
          </InputSelectTrigger>
          {course?.name && (
            <InputTrailingContainer
              className="absolute top-5 z-50 cursor-pointer"
              onClick={handleClearCourse}
            >
              <i className="fa-regular fa-xmark" />
            </InputTrailingContainer>
          )}
        </InputBase>
        <InputSelectContent className="max-h-72">
          <InputSelectContentView>
            {currentClubCoursesList.map((clubCourse) => {
              return (
                <InputSelectItem value={clubCourse} key={clubCourse.id}>
                  <ListItem className="ml-4 h-full w-[260px] min-w-[260px] justify-center gap-2 ">
                    <ListItemMainContent className="justify-center py-1">
                      <ListItemParagraph className="text-content-base text-ellipsis">
                        {clubCourse?.name}
                      </ListItemParagraph>
                    </ListItemMainContent>
                    {course?.id === clubCourse.id && (
                      <ListItemTrailing>
                        <i className="fa-regular fa-check h-4 w-4" />
                      </ListItemTrailing>
                    )}
                  </ListItem>
                </InputSelectItem>
              )
            })}
          </InputSelectContentView>
        </InputSelectContent>
      </InputSelect>
    )
  }

  return (
    <div className={style.content}>
      <div className="mb-[-10px] flex">
        <div className="flex gap-2">
          <div className={errors.start_date ? 'mb-8' : ''}>
            <TextInputField
              type="calendar"
              value={formState.start_date}
              calendarSettings={{
                selectedDate: formState.start_date,
                returnDateFormat: 'YYYY-MM-DD',
              }}
              onChange={(value) => setValue('start_date', value)}
              CalendarComponent={MiniCalendar}
              prefix={<CalendarIcon />}
              calendarPrefix={true}
              noMargin={true}
              noMarginPrefix={true}
              inputClassName="!w-[125px]"
            />
            {
              <div className="absolute mt-1 w-full break-words text-sm text-red-500">
                {errors.start_date}
              </div>
            }
          </div>
          <span className="mt-3">-</span>
          <div className={errors.end_date ? 'mb-8' : ''}>
            <TextInputField
              type="calendar"
              value={formState.end_date}
              calendarSettings={{
                selectedDate: formState.end_date,
                returnDateFormat: 'YYYY-MM-DD',
              }}
              onChange={(value) => setValue('end_date', value)}
              CalendarComponent={MiniCalendar}
              prefix={<CalendarIcon />}
              calendarPrefix={true}
              inputClassName="!w-[125px]"
            />
            {
              <div className="absolute mt-1 w-full break-words text-sm text-red-500">
                {errors.end_date}
              </div>
            }
          </div>
          {renderCourseSelect()}
        </div>
      </div>
      <Grid
        values={noShows}
        {...(noShows?.length > 0
          ? {
              actions: [
                {
                  id: 1,
                  label: 'players.export',
                  confirmDialog: 'players.exportQuestion',
                  successConfirmDialog: 'players.exportSuccess',
                  action: 'exportNoShowPlayers',
                  disabled: !noShows,
                },
              ],
              actionsEnabled: true,
            }
          : {})}
        pageTitle="noShow"
        headers={getNoShowHeaders()}
        hideArrows
        shouldHideColumnEdit
        onRowClick={(row) => openTeeTimeModal(row)}
        rightClickOptions={[
          {
            label: i18n.t('openBooking'),
            onClick: (row) => openTeeTimeModal(row),
            disabled: false,
          },
          {
            label: i18n.t('openPlayerCard'),
            onClick: (row) => openPlayerCard(row),
            disabled: false,
          },
        ]}
        pagination
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
        setCurrentPage={(page) => setPageNum(page)}
        displayRangeOfRows
        searchEnabled
        searchPlaceholder=".players.searchPlayer"
        onSearchChange={handleSearchChange}
        onSearch={() => getNoShows()}
        onActionConfirm={handleActionConfirm}
      />
      {(!noShows || !noShows.length) && (
        <div className={style.container}>
          {isLoading ? (
            <div className={style.loading}>
              <PulseLoader showIf={isLoading} />
              <Text textId="loadingData" />
            </div>
          ) : (
            <div>
              <GpsIcon />
              <Text
                textId={noSearchResult ? 'noSearchResults' : 'allPlayersHasBeenAR'}
                className={style.emptyState}
              />
            </div>
          )}
        </div>
      )}
      {showBookingPopup && (
        <div className={style.bookingModalContainer}>
          <BookingModal
            teeTimeId={selectedTeeTime?.uuid}
            onClose={() => handleCloseBookingModal()}
            type="clubPortal"
            EditTeeTimeComponent={EditTeeTime}
          />
        </div>
      )}
      <Switch>
        <Route exact path="/no-show/player/:id" component={PlayerCardModal} />
      </Switch>
    </div>
  )
}

export default NoShow
