import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18next'

import { ChromePicker } from 'react-color'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import ToggleSwitchControlled from '@sweetspot/sweetspot-js/common/components/ToggleSwitchControlled'

import CLUB_ICON from '@sweetspot/club-portal-legacy/resources/images/home_club_icon.svg'
import style from './style.module.scss'
import {
  getHomeClubSettings,
  putHomeClubSettings,
  uploadMediaObject,
} from '@sweetspot/shared/data-access/api-platform'

import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions'

const initialState = {
  isActive: false,
  isShowCP: false,
  bgColor: '#FFFFFF',
  footer: '',
  phone: '',
  email: '',
  freeText: '',
  logoImageMediaObject: null,
  clubImageMediaObject: null,
  newLogoImage: null,
  newClubImage: null,
  isLoading: false,
  fileSizeError: false,
  fileTypeError: false,
  clubImageError: false,
}

class HomeClubSettings extends Component {
  constructor(props) {
    super(props)

    this.state = initialState

    this.handleChange = this.handleChange.bind(this)
    this.toggleActive = this.toggleActive.bind(this)
    this.toggleShow = this.toggleShow.bind(this)
    this.changeColor = this.changeColor.bind(this)
    this.fileChange = this.fileChange.bind(this)
    this.clubImgChange = this.clubImgChange.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidMount() {
    this.getClubSettings()
  }

  getCurrentClub() {
    const { golfClubs } = this.props
    return golfClubs?.list?.find((el) => el.id === golfClubs.selectedId)
  }

  getClubSettings() {
    const currentClub = this.getCurrentClub()
    if (!currentClub?.id) return

    this.setState({ isLoading: true })
    getHomeClubSettings(currentClub.id)
      .then((res) => {
        this.setState({
          isLoading: false,
          isActive: res.active,
          logoImageMediaObject: res.logo || null,
          clubImageMediaObject: res.club_image || null,
          phone: res.phone || '',
          email: res.email || '',
          footer: res.title || '',
          freeText: res.description || '',
          bgColor: res.background_color || '#FFFFFF',
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleChange(e) {
    let { name, value } = e.target

    if (name === 'footer') value = value.substr(0, 9)
    this.setState({ [name]: value })
  }

  toggleActive() {
    this.setState({ isActive: !this.state.isActive })
  }

  toggleShow() {
    this.setState({ isShowCP: !this.state.isShowCP })
  }

  changeColor(color) {
    this.setState({ bgColor: color.hex })
  }

  isValidImageFormat(fileType) {
    return fileType === 'image/png' || fileType === 'image/jpeg'
  }

  fileChange(e) {
    const file = e.target.files[0]

    if (!file) return
    if (file.type !== 'image/png') {
      this.setState({ fileTypeError: true })
    } else if (file.size > 200 * 1024) {
      this.setState({ fileSizeError: true })
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      this.setState({
        newLogoImage: {
          file: file,
          src: null,
        },
        fileSizeError: false,
        fileTypeError: false,
      })

      reader.onloadend = () => {
        this.setState((state) => ({
          ...state,
          newLogoImage: {
            ...state.newLogoImage,
            src: reader.result,
          },
        }))
      }
    }
  }

  clubImgChange(e) {
    const file = e.target.files[0]

    if (!file) return
    if (!this.isValidImageFormat(file.type)) {
      this.setState({ clubImageError: true })
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      this.setState((state) => ({
        ...state,
        newClubImage: {
          file: file,
          src: null,
        },
      }))

      reader.onloadend = () => {
        this.setState((state) => ({
          ...state,
          newClubImage: {
            ...state.newClubImage,
            src: reader.result,
          },
          clubImageError: false,
        }))
      }
    }
  }

  handleClear() {
    this.setState({ ...initialState })
  }

  async handleSave() {
    const {
      isActive,
      bgColor,
      footer,
      phone,
      email,
      freeText,
      newLogoImage,
      newClubImage,
      logoImageMediaObject,
      clubImageMediaObject,
    } = this.state
    const { golfClubs, dispatch } = this.props

    let payload = {
      title: footer,
      phone: phone,
      email: email,
      description: freeText,
      is_active: isActive ? true : false,
      background_color: bgColor,
      logo: logoImageMediaObject?.id || null,
      club_image: clubImageMediaObject?.id || null,
    }

    if (newLogoImage?.file) {
      let logotypeUploadFormData = new FormData()
      logotypeUploadFormData.append('file', newLogoImage.file)
      const [logotypeUploadRes] = await to(uploadMediaObject(logotypeUploadFormData))
      if (logotypeUploadRes?.id) {
        payload['logo'] = logotypeUploadRes.id
        this.setState({ logoImageMediaObject: logotypeUploadRes })
      }
    }

    if (newClubImage?.file) {
      let clubImageUploadFormData = new FormData()
      clubImageUploadFormData.append('file', newClubImage.file)
      const [clubImageUploadRes] = await to(uploadMediaObject(clubImageUploadFormData))
      if (clubImageUploadRes?.id) {
        payload['club_image'] = clubImageUploadRes.id
        this.setState({ clubImageMediaObject: clubImageUploadRes })
      }
    }

    this.setState({ isLoading: true })
    putHomeClubSettings(golfClubs.selectedId, payload)
      .then((res) => {
        this.setState({ isLoading: false, newLogoImage: null, newClubImage: null })
        dispatch(addToast('infoUpdatedSuccessfully', 'success'))
      })
      .catch(() => {
        this.setState({ isLoading: false })
        dispatch(addToast('somethingWentWrongNoId', 'error'))
      })
  }

  renderFileError() {
    const { fileSizeError, fileTypeError } = this.state

    if (fileSizeError)
      return (
        <label className={`ss-label ${style.formLabel2}`}>
          {t('settings.homeClub.fileSizeError')}
        </label>
      )
    if (fileTypeError)
      return (
        <label className={`ss-label ${style.formLabel2}`}>
          {t('settings.homeClub.fileTypeError2')}
        </label>
      )
    return (
      <label className={`ss-label ${style.formLabel}`}>{t('settings.homeClub.imgFormat')}</label>
    )
  }

  renderImageError() {
    const { clubImageError } = this.state

    if (clubImageError)
      return (
        <label className={`ss-label ${style.formLabel2}`}>
          {t('settings.homeClub.fileTypeError')}
        </label>
      )
  }

  renderForm() {
    const { isActive, bgColor, footer, phone, email, freeText, isShowCP, isLoading } = this.state

    return (
      <div className={style.forms}>
        <div className={style.label}>
          <Text textId="settings.homeClub.title" />
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className={`ss-form-group ${style.status}`}>
            <Text textId="status" />
            <ToggleSwitchControlled
              large
              switchWidth="full"
              textChecked=".settings.active"
              textUnchecked=".settings.inactive"
              checked={isActive}
              onChange={this.toggleActive}
            />
          </div>

          <div className={`ss-form-group ${style.logoType}`}>
            <button
              className="system-button info-outline ss-block-button md-32"
              onClick={() => this._file.click()}
            >
              {t('settings.homeClub.logoType')}
            </button>
            {this.renderFileError()}
            <input
              ref={(el) => (this._file = el)}
              type="file"
              hidden
              accept="image/x-png"
              onChange={this.fileChange}
            />
          </div>

          <div className={`ss-form-group ${style.logoType}`}>
            <button
              className="system-button info-outline ss-block-button md-32"
              onClick={() => this._file2.click()}
            >
              {t('settings.homeClub.clubImage')}
            </button>
            {this.renderImageError()}
            <input
              ref={(el) => (this._file2 = el)}
              type="file"
              hidden
              accept="image/x-png, image/jpeg"
              onChange={this.clubImgChange}
            />
          </div>

          <div className="ss-form-group" style={{ position: 'relative' }}>
            <label className={`ss-label ${style.formLabel}`}>
              {t('settings.homeClub.bgColor')}
            </label>
            <div className={style.bgColor} onClick={this.toggleShow}>
              <div style={{ background: bgColor }} />
              <div>{bgColor}</div>
            </div>
            {isShowCP && (
              <div className={style.colorPicker}>
                <ChromePicker color={bgColor} disableAlpha onChange={this.changeColor} />
              </div>
            )}
          </div>

          <div className="ss-form-group">
            <label className={`ss-label ${style.formLabel}`}>
              {t('settings.homeClub.footerTitle')}
            </label>
            <input
              className="ss-input"
              type="text"
              name="footer"
              value={footer}
              disabled={isLoading}
              onChange={this.handleChange}
            />
          </div>

          <div className="ss-form-group">
            <label className={`ss-label ${style.formLabel}`}>
              {t('settings.homeClub.phoneNum')}
            </label>
            <input
              className="ss-input"
              type="text"
              name="phone"
              value={phone}
              disabled={isLoading}
              onChange={this.handleChange}
            />
          </div>

          <div className="ss-form-group">
            <label className={`ss-label ${style.formLabel}`}>{t('email')}</label>
            <input
              className="ss-input"
              type="text"
              name="email"
              value={email}
              disabled={isLoading}
              onChange={this.handleChange}
            />
          </div>

          <div className="ss-form-group">
            <label className={`ss-label ${style.formLabel}`}>
              {t('settings.homeClub.freeText')}
            </label>
            <textarea
              className="ss-input"
              rows="5"
              name="freeText"
              value={freeText}
              disabled={isLoading}
              onChange={this.handleChange}
            />
          </div>

          <div className={`ss-form-group ${style.actionBtn}`}>
            <button
              className="system-button primary-outline md-32"
              disabled={isLoading}
              onClick={this.handleClear}
            >
              {t('clear')}
            </button>
            <button
              className="system-button info-outline md-32"
              disabled={isLoading}
              onClick={this.handleSave}
            >
              {t('save')}
            </button>
          </div>
        </form>
      </div>
    )
  }

  renderPreview() {
    const {
      logoImageMediaObject,
      newLogoImage,
      footer,
      bgColor,
      phone,
      email,
      freeText,
      clubImageMediaObject,
      newClubImage,
    } = this.state
    const { golfClubs } = this.props

    const golfClub = golfClubs.list.find((item) => item.id === golfClubs.selectedId)

    return (
      <div className={style.prevContainer}>
        <div className={style.label}>
          <Text textId="settings.homeClub.preview" />
        </div>

        <div className={style.preview}>
          <div>
            <div className={style.header} style={{ background: bgColor }}>
              {(logoImageMediaObject?.content_url || newLogoImage?.src) && (
                <img alt="Logo" src={newLogoImage?.src || logoImageMediaObject?.content_url} />
              )}
            </div>
            <div
              style={{
                backgroundImage: `url(${
                  newClubImage?.src || clubImageMediaObject?.content_url || ''
                })`,
              }}
              className={style.banner}
            >
              <div>
                <div>
                  <Text textId="settings.homeClub.play" />
                </div>
                <div>
                  <Text textId="settings.homeClub.clubInfo" />
                </div>
              </div>
            </div>
            <div className={style.body} />
            <div className={style.footer}>
              <div className={style.footerIcon}>
                <img alt="Home Club Icon" width="15" src={CLUB_ICON} />
                <span>{footer}</span>
              </div>
              <div className={style.footerItem} />
              <div className={style.footerItem} />
              <div className={style.footerItem} />
            </div>
          </div>
          <div>
            <div className={style.header}>
              <span>&times;</span>
              {golfClub.name}
            </div>
            <div className={style.body}>{freeText}</div>
            <div className={style.footer}>
              {(phone || email) && <Text textId="settings.homeClub.contact" />}
              <br />
              <span>{phone}</span>
              <br />
              <span>{email}</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className="grid grid-cols-3 gap-4">
          <div>{this.renderForm()}</div>
          <div className="col-span-2">{this.renderPreview()}</div>
        </div>
      </div>
    )
  }
}

HomeClubSettings.propTypes = {
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  golfClubs: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.me.lang,
  golfClubs: state.golfClub,
})

export default connect(mapStateToProps)(HomeClubSettings)
