import { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  Button,
  cn,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import {
  BayScreenDisplay,
  BayScreenMode,
  BayTypeV2,
  TrackingTech,
  Stance,
} from '@sweetspot/shared/types'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

import Summary from './Summary'
import DetailsForm from './Details/DetailsForm'

const UpdateBay = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const {
    selectedRange,
    refetchRanges,
    openBaySideBar,
    selectedBays,
    toggleBaySideBar,
    reFetchBays,
    setSelectedBays,
    openDiscardDialog,
    setOpenDiscardDialog,
    updateBay,
    checkForErrors,
  } = useBaysContext()
  const [numberOfBays] = useState<number>(selectedBays?.length || 1)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const selectedBay = useMemo<BayTypeV2 | undefined>(
    () => selectedBays && selectedBays[0],
    [selectedBays]
  )

  const [floor, setFloor] = useState<number>(
    selectedBay?.floor !== undefined ? selectedBay.floor : 1
  )
  const [stance, setStance] = useState<Stance>(selectedBays ? selectedBays[0].stance : Stance.BOTH)
  const [roof, setRoof] = useState<boolean>(
    selectedBay?.amenities?.roof ? selectedBay?.amenities?.roof : false
  )
  const [trackingTech, setTrackingTech] = useState<TrackingTech>(
    selectedBay?.ball_tracking_technology?.tracking_provider
      ? selectedBay?.ball_tracking_technology?.tracking_provider
      : TrackingTech.NONE
  )
  const [display, setDisplay] = useState<BayScreenDisplay | null>(
    selectedBay?.ball_tracking_technology?.display || null
  )
  const [screenMode, setScreenMode] = useState<BayScreenMode | null | undefined>(
    selectedBay?.ball_tracking_technology?.screen_mode
  )
  const [siteId, setSiteId] = useState<string>(selectedRange?.toptracer_site_id || '')
  const [trmsNumber, setTrmsNumber] = useState<string | null | undefined>(
    selectedBay?.ball_tracking_technology?.api_details?.trms_bay_number
  )

  const bayNumber = useMemo(() => selectedBays && selectedBays[0].bay_number, [selectedBays])

  const dirtify = useCallback(<T,>(setter: (value: T) => void, value: T) => {
    setter(value)
    setIsDirty(true)
  }, [])

  const handleClose = useCallback(() => {
    if (isDirty) {
      setOpenDiscardDialog(true)
      return
    }
    setOpenDiscardDialog(false)
    toggleBaySideBar(BaySideBarMode.CREATE)
  }, [setOpenDiscardDialog, toggleBaySideBar, isDirty])

  const handleUpdateBay = useCallback(async () => {
    if (!selectedRange?.id || !selectedBays?.length) {
      return
    }

    const payload = {
      rangeId: selectedRange?.id,
      bayId: selectedBays[0].id,
      siteId,
      bay: {
        floor,
        amenities: { roof },
        stance,
        ball_tracking_technology: {
          api_details: { trms_bay_number: trmsNumber },
          display,
          screen_mode: screenMode,
          tracking_provider: trackingTech,
        },
      },
    }

    const errorText = checkForErrors?.(payload)
    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      setIsLoading(true)
      updateBay?.(payload)
        ?.then(() => {
          setIsLoading(false)
          addToast(t('toast.updateBaySuccess'), { appearance: 'success' })
          setIsDirty(false)
          reFetchBays()
          refetchRanges()
          setSelectedBays([])
          toggleBaySideBar()
        })
        ?.catch(() => {
          setIsLoading(false)
          setIsDirty(false)
        })
    }
  }, [
    selectedRange?.id,
    selectedBays,
    floor,
    stance,
    roof,
    trackingTech,
    trmsNumber,
    display,
    screenMode,
    reFetchBays,
    setSelectedBays,
    toggleBaySideBar,
    addToast,
    t,
    checkForErrors,
    siteId,
    updateBay,
    refetchRanges,
  ])

  return (
    <Sheet open={openBaySideBar} onOpenChange={handleClose}>
      <SheetContent className={cn(isLoading && 'overflow-hidden')}>
        {isLoading && (
          <SpinnerLoader
            className="fixed left-[calc(100%-384px)]"
            text={t('sentences.updatingBay')}
            description={t('sentences.updatingBayDescription')}
            isOverlay
          />
        )}
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={handleClose}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{`${t('edit')} ${t('settings.bays.bay').toLowerCase()}`}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Summary numberOfBays={numberOfBays} bayNumber={bayNumber} />
          <DetailsForm
            floor={floor}
            setFloor={(floor) => dirtify<number>(setFloor, floor)}
            stance={stance}
            setStance={(stance) => dirtify<Stance>(setStance, stance)}
            roof={roof}
            setRoof={(roof) => dirtify<boolean>(setRoof, roof)}
            trackingTech={trackingTech}
            setTrackingTech={(trackingTech) => dirtify<TrackingTech>(setTrackingTech, trackingTech)}
            showDisplay={trackingTech === TrackingTech.TOPTRACER}
            display={display}
            setDisplay={(display) => dirtify<BayScreenDisplay>(setDisplay, display)}
            showScreenMode={display === BayScreenDisplay.TOPTRACER_SCREEN}
            screenMode={screenMode}
            setScreenMode={(screenMode) => dirtify<BayScreenMode>(setScreenMode, screenMode)}
            siteId={siteId}
            setSiteId={(siteId) => dirtify<string>(setSiteId, siteId)}
            trmsNumbers={{ [bayNumber as number]: trmsNumber }}
            setTrmsNumbers={(trmsNumber) => dirtify<string>(setTrmsNumber, trmsNumber)}
          />
        </SheetCustomContent>

        <SheetFooter>
          <Button
            onClick={handleUpdateBay}
            className="min-h-touch-height-lg w-full"
            disabled={!isDirty}
          >
            {`${t('update')} ${t('settings.bays.bay').toLowerCase()}`}
          </Button>
        </SheetFooter>
      </SheetContent>
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog?.(false)}
        onDiscard={() => {
          setOpenDiscardDialog(false)
          toggleBaySideBar(BaySideBarMode.EDIT)
        }}
        onSave={() => {
          setOpenDiscardDialog(false)
          handleUpdateBay()
        }}
      />
    </Sheet>
  )
}

export default UpdateBay
