import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import HoverDiv from '@sweetspot/sweetspot-js/common/components/HoverDiv'
import DotMarker from '@sweetspot/sweetspot-js/common/components/DotMarker'
import { ReactComponent as CrossIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'

const HoverPill = (props) => {
  if (props.hoverText) {
    return (
      <HoverDiv
        direction={props.hoverDirection}
        hoverText={props.hoverText}
        className={cx(styles.miniPill)}
      >
        {props.dotEnabled && <DotMarker className={styles.dot} color={props.dotColor} />}
        <span>{props.text}</span>
        {props.removeEnabled && (
          <span className={styles.closeButton} onClick={props.onRemove}>
            <CrossIcon />
          </span>
        )}
      </HoverDiv>
    )
  }

  return (
    <div className={cx(!props.removeEnabled ? styles.miniPillReadOnly : styles.miniPill)}>
      {props.dotEnabled && <DotMarker className={styles.dot} color={props.dotColor} />}
      <span>{props.text}</span>
      {props.removeEnabled && (
        <span className={styles.closeButton} onClick={props.onRemove}>
          <CrossIcon />
        </span>
      )}
    </div>
  )
}

HoverPill.propTypes = {
  hoverDirection: PropTypes.string,
  text: PropTypes.string.isRequired,
  hoverText: PropTypes.string,
  removeEnabled: PropTypes.bool,
  dotColor: PropTypes.string,
  dotEnabled: PropTypes.bool,
  onRemove: PropTypes.func,
}

HoverPill.defaultProps = {
  dotColor: 'red',
  removeEnabled: true,
  dotEnabled: false,
}

export default HoverPill
