import { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  Button,
  cn,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetHeaderRightIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { BayScreenDisplay, BayScreenMode, TrackingTech, Stance } from '@sweetspot/shared/types'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

import NumberOfBays from './NumberOfBays/NumberOfBays'
import Summary from './Summary'
import DetailsForm from './Details/DetailsForm'

const CreateBay = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isBulkCreate, setIsBulkCreate] = useState<boolean>(false)
  const [numberOfBays, setNumberOfBays] = useState<number>(1)
  const [floor, setFloor] = useState<number>()
  const [stance, setStance] = useState<Stance>()
  const [roof, setRoof] = useState<boolean>(false)
  const [trackingTech, setTrackingTech] = useState<TrackingTech>(TrackingTech.NONE)
  const [display, setDisplay] = useState<BayScreenDisplay>()
  const [screenMode, setScreenMode] = useState<BayScreenMode>()
  const [siteId, setSiteId] = useState<string>('')
  const [trmsNumber, setTrmsNumber] = useState<string>('')
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    selectedRange,
    openBaySideBar,
    toggleBaySideBar,
    reFetchBays,
    bays,
    openDiscardDialog,
    setOpenDiscardDialog,
    checkForErrors,
    createBay,
    refetchRanges,
  } = useBaysContext()

  useEffect(() => {
    if (selectedRange?.toptracer_site_id) {
      setSiteId(selectedRange.toptracer_site_id)
    }
  }, [selectedRange])

  const title = useMemo(() => {
    if (numberOfBays === 1) {
      return `${t('create')} 1 ${t('new').toLowerCase()} ${t('settings.bays.bay').toLowerCase()}`
    }

    return `${t('create')} ${numberOfBays} ${t('new').toLowerCase()} ${t(
      'settings.bays.bays'
    ).toLowerCase()}`
  }, [numberOfBays, t])

  const bayNumber = useMemo(() => bays?.length + 1, [bays])

  const toggleBulkCreate = useCallback(() => {
    setIsBulkCreate((prev) => !prev)
    setNumberOfBays(isBulkCreate ? 1 : 2)
  }, [isBulkCreate])

  const dirtify = useCallback(<T,>(setter: (value: T) => void, value: T) => {
    setter(value)
    setIsDirty(true)
  }, [])

  const handleClose = useCallback(() => {
    if (isDirty) {
      setOpenDiscardDialog(true)
      return
    }
    setOpenDiscardDialog(false)
    toggleBaySideBar(BaySideBarMode.CREATE)
  }, [setOpenDiscardDialog, toggleBaySideBar, isDirty])

  const handleCreateBay = useCallback(async () => {
    if (!selectedRange?.id) {
      return
    }

    const payload = {
      rangeId: selectedRange?.id,
      siteId,
      numberOfBays,
      bay: {
        floor,
        amenities: { roof, infrared_heat: false },
        stance,
        ball_tracking_technology: {
          api_details: { trms_bay_number: trmsNumber },
          display,
          screen_mode: screenMode,
          tracking_provider: trackingTech,
        },
      },
    }

    const errorText = checkForErrors?.(payload)
    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      setIsLoading(true)
      createBay?.(payload)
        ?.then(() => {
          setIsLoading(false)
          addToast(t('toast.createBaySuccess'), { appearance: 'success' })
          setIsDirty(false)
          reFetchBays()
          refetchRanges()
          toggleBaySideBar()
        })
        ?.catch(() => {
          setIsLoading(false)
          setIsDirty(false)
        })
    }
  }, [
    addToast,
    floor,
    stance,
    reFetchBays,
    roof,
    selectedRange?.id,
    toggleBaySideBar,
    t,
    trackingTech,
    screenMode,
    trmsNumber,
    display,
    checkForErrors,
    createBay,
    refetchRanges,
    siteId,
    numberOfBays,
  ])

  return (
    <Sheet open={openBaySideBar} onOpenChange={handleClose}>
      <SheetContent className={cn(isLoading && 'overflow-hidden')}>
        {isLoading && (
          <SpinnerLoader
            className="fixed left-[calc(100%-384px)]"
            text={t('sentences.creatingBay')}
            description={t('sentences.creatingBayDescription')}
            isOverlay
          />
        )}
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={handleClose}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{title}</SheetTitle>
          <SheetHeaderRightIcon onClick={toggleBulkCreate}>
            <i
              key={String(isBulkCreate)}
              className={`${
                isBulkCreate ? 'fa-solid' : 'fa-regular'
              } fa-rectangle-vertical-history`}
            />
            <span className="text-content-sm text-text-dark font-medium">{t('words.bulk')}</span>
          </SheetHeaderRightIcon>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          {isBulkCreate && (
            <NumberOfBays numberOfBays={numberOfBays} setNumberOfBay={setNumberOfBays} />
          )}
          <Summary bayNumber={bayNumber} numberOfBays={numberOfBays} />
          <DetailsForm
            floor={floor}
            setFloor={(floor) => dirtify<number>(setFloor, floor)}
            stance={stance}
            setStance={(stance) => dirtify<Stance>(setStance, stance)}
            roof={roof}
            setRoof={(roof) => dirtify<boolean>(setRoof, roof)}
            trackingTech={trackingTech}
            setTrackingTech={(trackingTech) => dirtify<TrackingTech>(setTrackingTech, trackingTech)}
            showDisplay={trackingTech === TrackingTech.TOPTRACER}
            display={display}
            setDisplay={(display) => dirtify<BayScreenDisplay>(setDisplay, display)}
            showScreenMode={display === BayScreenDisplay.TOPTRACER_SCREEN}
            screenMode={screenMode}
            setScreenMode={(screenMode) => dirtify<BayScreenMode>(setScreenMode, screenMode)}
            siteId={siteId}
            setSiteId={(siteId) => dirtify<string>(setSiteId, siteId)}
            trmsNumbers={{ [bayNumber]: trmsNumber }}
            setTrmsNumbers={(trmsNumber) => dirtify<string>(setTrmsNumber, trmsNumber)}
          />
        </SheetCustomContent>
        <SheetFooter>
          <Button
            onClick={handleCreateBay}
            className="min-h-touch-height-lg w-full"
            disabled={!isDirty}
          >
            {t('create')}
          </Button>
        </SheetFooter>
      </SheetContent>
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog(false)}
        onDiscard={() => {
          setOpenDiscardDialog(false)
          toggleBaySideBar(BaySideBarMode.CREATE)
        }}
        onSave={() => {
          setOpenDiscardDialog(false)
          handleCreateBay()
        }}
      />
    </Sheet>
  )
}

export default CreateBay
