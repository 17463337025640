import { OpeningHour } from '@sweetspot/shared/data-access/api-platform'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface OpeningHourItemProps {
  item: OpeningHour
  onChange(itemToUpdate): void
}

export const OpeningHourItem = (props: OpeningHourItemProps) => {
  const { t } = useTranslation()
  const [endTime, setEndTime] = useState(props.item.last_return_time)
  const [startTime, setStartTime] = useState(props.item.first_rental_time)

  useEffect(() => {
    if (props.item) {
      setEndTime(props.item.last_return_time)
      setStartTime(props.item.first_rental_time)
    }
  }, [props.item])

  const onSave = (val: string, prop: string) => {
    if (val !== props.item[prop]) {
      props.onChange({ ...props.item, [prop]: val })
    }
  }

  return (
    <div
      className={`contents [&>*]:flex [&>*]:h-12 [&>*]:items-center [&>*]:pl-4 ${
        props.item.id % 2 === 0 ? '' : '[&>*]:bg-neutral-100'
      }`}
    >
      <div>{t(`dateTime.longDays.${DateHelpers.getDaysArray()[props.item.day]}_one`)}</div>
      <div className={'items-center justify-center'}>
        <ToggleButton
          value={props.item.is_closed}
          containerWidth="full"
          disabled={false}
          onChange={(val) => props.onChange({ ...props.item, is_closed: val })}
          noMargin
        ></ToggleButton>
      </div>
      <div>
        <TextInputField
          type={'time'}
          value={startTime}
          onInputBlur={(val) => onSave(val, 'first_rental_time')}
          containerWidth="auto"
          disabled={props.item.is_closed}
          onChange={(val) => setStartTime(val)}
          noMargin
          inputProps={{
            tabIndex: props.item.id * 2 + 1,
          }}
          onEnter={(val) => onSave(val, 'first_rental_time')}
          placeholder="__:__"
        />
      </div>
      <div>
        <TextInputField
          type={'time'}
          value={endTime}
          onInputBlur={(val) => onSave(val, 'last_return_time')}
          containerWidth="auto"
          disabled={props.item.is_closed}
          onChange={(val) => setEndTime(val)}
          noMargin
          inputProps={{
            tabIndex: props.item.id * 2 + 2,
          }}
          onEnter={(val) => onSave(val, 'last_return_time')}
          placeholder="__:__"
        />
      </div>
    </div>
  )
}
