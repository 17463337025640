import { Hydra, PaymentLink } from '@sweetspot/shared/types'
import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

export const getPaymentLinks = (
  searchValue: string,
  clubId: number,
  pageParam: number
): Promise<Hydra<PaymentLink>> => {
  const searchValueDecoded = encodeURIComponent(searchValue)
  const url = `${getApiPlatformBaseUrl()}/payment-links?club=${clubId}&order[payment.state]=desc&order[is_active]=desc&page=${pageParam}&limit=10&search_field=${searchValueDecoded}`

  return request(url, {
    method: 'GET',
    headers: {
      Accept: 'Application/ld+json',
    },
  })
}
