import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'

const ModalSimple = ({ className, headerChildren, children, onClose, visible }) => {
  return (
    <Portal id="modals">
      <div className={cx(styles.overlay, visible && styles.visible)}>
        <div className={cx(styles.container, className)}>
          <div className={cx(styles.header)}>
            <div className={cx(styles.left)}>{headerChildren}</div>
            <span
              onClick={onClose}
              className={cx('material-icons md-white md-32', styles.closeIcon)}
            >
              close
            </span>
          </div>
          <div className={cx(styles.inner)}>{children}</div>
        </div>
      </div>
    </Portal>
  )
}

ModalSimple.propTypes = {
  className: PropTypes.string,
  headerChildren: PropTypes.element,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
}

ModalSimple.defaultProps = {
  className: '',
}

export default ModalSimple
