import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import {
  DemandEntry,
  queryDemandModel,
  queryRangeSchedule,
  updateDemandModel,
  createDemandModel,
  DemandStatus,
} from '@sweetspot/shared/data-access/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { daysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import {
  constructDefaultDemandPayload,
  constructDemandPayload,
  constructScheduleData,
  constructStatusMatrix,
  generateInitStatusList,
  generateTimeSections,
} from '../utils'
import { OpeningHours, StatusObject } from '../types'
import { DEFAULT_OPENING_HOURS_WEEK } from '../constants'

type UseDemandModelProps = {
  rangeId?: string
  demandModelId?: string
}

const useDemandModel = ({ rangeId, demandModelId: initialDemandModelId }: UseDemandModelProps) => {
  const [demandModelId, setDemandModelId] = useState<string | undefined>()
  const [hasDemandEntries, setHasDemandEntries] = useState<boolean>(false)
  const [openingHours, setOpeningHours] = useState<OpeningHours>(DEFAULT_OPENING_HOURS_WEEK)
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const DAYS_OF_WEEK = useMemo(() => daysOfWeek(), [])
  const timeSections = useMemo(() => generateTimeSections(openingHours), [openingHours])
  const initialStatusMatrix = useMemo(() => {
    return timeSections.map(() => generateInitStatusList(7))
  }, [timeSections])
  const [statusMatrix, setStatusMatrix] = useState<StatusObject[][]>(initialStatusMatrix)

  useEffect(() => {
    setDemandModelId(initialDemandModelId)
  }, [initialDemandModelId])

  const { mutate: createDemandModelMutate } = useMutation(
    () => {
      return createDemandModel({
        rangeId,
        demand_entries: constructDefaultDemandPayload(
          initialStatusMatrix,
          timeSections,
          DAYS_OF_WEEK
        ),
      })
    },
    {
      onSuccess: (data) => {
        const { id } = data
        setDemandModelId(id)
        refetchDemandModel()
      },
      onError: () => {
        setStatusMatrix(initialStatusMatrix)
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  // set default demand model if there is none
  useEffect(() => {
    if (!!rangeId && !initialDemandModelId) {
      setStatusMatrix(initialStatusMatrix)
    }
  }, [initialDemandModelId, rangeId, initialStatusMatrix])

  // fetch demand model if range has demand model id
  const {
    isFetching: isDemandModelFetching,
    refetch: refetchDemandModel,
    isError: isDemandModelError,
  } = useQuery([CLUB_QUERIES.DEMAND_MODEL, rangeId], () => queryDemandModel(rangeId), {
    enabled: !!rangeId && !!initialDemandModelId,
    onSuccess: (data) => {
      const { id, demand_entries } = data
      setDemandModelId(id)
      setHasDemandEntries(demand_entries?.length > 0)
      setStatusMatrix(constructStatusMatrix(demand_entries, initialStatusMatrix, DAYS_OF_WEEK))
    },
    onError: () => {
      setStatusMatrix(initialStatusMatrix)
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    },
  })

  const { isFetching: isRangeScheduleFetching, refetch: refetchRangeSchedule } = useQuery(
    [CLUB_QUERIES.RANGE_SCHEDULE, rangeId],
    () => queryRangeSchedule(rangeId),
    {
      enabled: !!rangeId,
      onSuccess: (data) => {
        const scheduleData = constructScheduleData(data)
        setOpeningHours(scheduleData)
      },
      onError: () => {
        setOpeningHours(DEFAULT_OPENING_HOURS_WEEK)
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const demandModelUpdateMutation = useMutation(
    (payload: DemandEntry) => {
      return updateDemandModel({
        rangeId,
        demandModel: {
          id: demandModelId as string,
          demand_entries: [payload],
        },
      })
    },
    {
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const handleStatusChange = (rowIndex: number, colIndex: number, newStatus: DemandStatus) => {
    const updatedStatusMatrix = [...statusMatrix]
    updatedStatusMatrix[rowIndex] = [...updatedStatusMatrix[rowIndex]]
    updatedStatusMatrix[rowIndex][colIndex] = { status: newStatus }
    setStatusMatrix?.(updatedStatusMatrix)

    const demandPayload = constructDemandPayload(
      DAYS_OF_WEEK[colIndex],
      timeSections[rowIndex][0],
      newStatus
    )
    demandModelUpdateMutation.mutate(demandPayload)
  }

  return {
    openingHours,
    timeSections,
    isDemandModelFetching,
    isRangeScheduleFetching,
    statusMatrix,
    setStatusMatrix,
    refetchRangeSchedule,
    refetchDemandModel,
    handleStatusChange,
    createDemandModel: createDemandModelMutate,
    DAYS_OF_WEEK,
    demandModelId,
    isDemandModelError,
    hasDemandEntries,
  }
}

export default useDemandModel
