export const parseSymbol = (symbol) => {
  const string = symbol.toString()
  return string.slice(7, string.length - 1)
}

export const parseError = (error, isForm = false) => {
  if (!error) return error
  if (typeof error === 'string') return error
  if (typeof error === 'object') {
    if (error.detail && isForm) {
      return error.detail
        .split('\n')
        .map((errorString) => {
          const [fieldName, message, error] = errorString.split(': ')
          if (error) console.log(`[parseError]: Extra data in error message - ${error}`)
          return message ? { [fieldName]: message } : { $global: fieldName }
        })
        .reduce((acc, next) => {
          return Object.assign(acc, next)
        }, {})
    }
    return (
      error.message || error.detail || `[func parseError] Unknown error: ${JSON.stringify(error)}`
    )
  }
  return `[func parseError] Unknown error type: ${typeof error} - ${JSON.stringify(error)}`
}
