import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ItemBox from '@sweetspot/club-portal-legacy/pages/SimulatorsRangesOrder/components/ItemBox'
import InputSelectOptionSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectOptionSimple'
import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/close-icon.svg'

import useMe from '@sweetspot/sweetspot-js/common/hooks/useMe'
import { useTranslation } from 'react-i18next'
import { parseSymbol } from '@sweetspot/sweetspot-js/common/functions/parse'
import { useStyles } from '@sweetspot/sweetspot-js/common/hooks/useStyles'

import useBookingNotes from '@sweetspot/sweetspot-js/features/bookings/hooks/useBookingNotes'

import styles from './styles.module.scss'
import { useToasts } from 'react-toast-notifications'

const MAX_NOTE_LENGTH = 1000

const BookingNotesCourses = (props) => {
  const { t, i18n } = useTranslation()
  const { addToast } = useToasts()
  const me = useMe()
  const cx = useStyles(props.styles)
  const notesContainer = useRef()

  const sortValues = [
    { isNewest: true, label: t('bookingNotes.label_Newest') },
    { isNewest: false, label: t('bookingNotes.label_Oldest') },
  ]

  const [newNoteText, setNewNoteText] = useState('')
  const [pendingNotes, setPendingNotes] = useState([])
  const [editNoteID, setEditNoteID] = useState()
  const [removeNoteID, setRemoveNoteID] = useState()
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isNewestFirst, setIsNewestFirst] = useState(true)

  const { notes, isLoading, error, createBookingNote, updateBookingNote, removeBookingNote } =
    useBookingNotes(props.bookingID, me.uuid, null, isNewestFirst)

  const selectSort = (option) => {
    setIsNewestFirst(option.isNewest)
  }

  const isNew = pendingNotes.find((i) => i === 'new')
  const isLengthError = newNoteText.length > MAX_NOTE_LENGTH

  useEffect(() => {
    if (error) {
      let _error = typeof error === 'symbol' ? parseSymbol(error) : error
      addToast(_error, { appearance: 'error' })
    }
  }, [error])

  const addNote = async () => {
    if (newNoteText.trim().length === 0) {
      resetEdit()
      return
    }
    resetEdit()
    setPendingNotes([...pendingNotes, editNoteID || 'new'])
    if (notesContainer?.current) {
      notesContainer.current.scrollTo(0, isNewestFirst ? 0 : notesContainer.current.scrollHeight)
    }
    if (editNoteID && editNoteID !== 'new') {
      await updateBookingNote(editNoteID, newNoteText)
    } else {
      await createBookingNote(newNoteText)
    }
    setPendingNotes([])
  }

  const editNote = (note) => {
    if (note.uuid === editNoteID) {
      resetEdit()
    } else {
      setIsInputFocused(true)
      setNewNoteText(note.text)
      setEditNoteID(note.uuid)
    }
  }

  const removeNote = async (noteID) => {
    setPendingNotes([...pendingNotes, noteID])
    await removeBookingNote(noteID)
    setPendingNotes([])
  }

  const resetEdit = () => {
    setEditNoteID(null)
    setIsInputFocused(false)
    setNewNoteText('')
  }

  const _notes = [isNew ? 'new' : null, ...notes]

  const SHOW_SORT = notes.length > 1
  const SHOW_LIST = notes.length > 0 || pendingNotes.length > 0

  return (
    <ItemBox className={cx(styles.container)}>
      <div className={cx(styles.header)}>
        <h2 className={cx(styles.title)}>{t('bookingNotes.title')}</h2>
      </div>
      <div className={cx(styles.container)}>
        {props.canEdit && !props.isCancelled && (
          <div className={cx(styles.addNoteContainer)}>
            {editNoteID ? (
              <div
                className={cx(styles.edit, {
                  [styles.focused]: isInputFocused,
                })}
              >
                <p>{t('bookingNotes.label_Edit')}</p>
                <CloseIcon className={cx(styles.closeEdit)} onClick={resetEdit} />
              </div>
            ) : null}
            <TextInputField
              containerClassName={cx(styles.textContainer)}
              inputClassName={cx(styles.inputContainer, {
                [styles.error]: isLengthError,
                [styles.editing]: editNoteID,
              })}
              placeholder={isInputFocused ? undefined : t('bookingNotes.placeholder_AddNote')}
              type="text-area"
              autoSize={true}
              value={newNoteText}
              onChange={(val) => setNewNoteText(val)}
              inputProps={{
                autoFocus: isInputFocused,
                autoSize: true,
              }}
              onInputFocus={() => setIsInputFocused(true)}
              onInputBlur={() => setIsInputFocused(false)}
              onEnter={addNote}
            />
            <div
              className={cx(styles.infoText, {
                [styles.focused]: isInputFocused,
                [styles.error]: isLengthError,
              })}
            >
              {t(`bookingNotes.${isLengthError ? 'error_MessageLength' : 'label_PressEnter'}`)}
              <p
                className={cx(styles.length, {
                  [styles.error]: isLengthError,
                })}
              >
                {`${newNoteText.length}/${MAX_NOTE_LENGTH}`}
              </p>
            </div>
          </div>
        )}
        {SHOW_SORT && (
          <InputSelectOptionSimple
            value={t(`bookingNotes.label_${isNewestFirst ? 'Newest' : 'Oldest'}`)}
            options={sortValues}
            className={cx(styles.sortBox)}
            disabled={isLoading}
            onOptionSelect={selectSort}
          />
        )}
        {SHOW_LIST && (
          <div className={cx(styles.notesContainer)} ref={notesContainer}>
            {isLoading && pendingNotes.length === 0 && !editNoteID ? (
              <div className={cx(styles.note)} key="placeholder">
                <Skeleton width="100%" height="30px" />
              </div>
            ) : (
              _notes.map((note) => {
                if (!note) return null
                if (pendingNotes.includes(note.uuid)) {
                  return (
                    <div className={cx(styles.note)} key={`pending_${note.uuid}`}>
                      <Skeleton width="100%" height="30px" />
                    </div>
                  )
                }
                if (note === 'new') {
                  return (
                    <div className={cx(styles.note)} key={'new'}>
                      <Skeleton width="100%" height="30px" />
                    </div>
                  )
                }
                return (
                  <div
                    className={cx(styles.note, {
                      [styles.active]: removeNoteID === note.uuid,
                    })}
                    key={note.uuid}
                  >
                    <div className={cx(styles.headerArea)}>
                      <p className={cx(styles.author)}>{note.author}</p>
                      <p className={cx(styles.time)}>{note.time}</p>
                      {props.canEdit && !props.isCancelled && note.isAuthor && (
                        <div className={cx(styles.buttonArea)}>
                          <p className={cx(styles.button)} onClick={() => editNote(note)}>
                            {t(
                              `bookingNotes.button_Edit${editNoteID === note.uuid ? 'Cancel' : ''}`
                            )}
                          </p>
                          {removeNoteID === note.uuid ? (
                            <div
                              className={cx(
                                styles.removeBox,
                                i18n.language === 'sv' ? styles.sv : false
                              )}
                            >
                              <strong>{t('bookingNotes.label_Remove')}</strong>
                              <div
                                className={cx(
                                  styles.removeArea,
                                  i18n.language === 'sv' ? styles.sv : false
                                )}
                              >
                                <button
                                  onClick={() => setRemoveNoteID(null)}
                                  className={cx(
                                    'system-button primary-outline md-32',
                                    styles.button,
                                    styles.no
                                  )}
                                  // variant="outline-secondary"
                                >
                                  {t('words.no')}
                                </button>
                                <button
                                  onClick={() => removeNote(note.uuid)}
                                  // variant="danger"
                                  className={cx(
                                    'system-button danger-outline md-32',
                                    styles.button,
                                    styles.yes
                                  )}
                                >
                                  {t('words.yes')}
                                </button>
                              </div>
                            </div>
                          ) : null}
                          <p
                            className={cx(styles.button)}
                            onClick={() => setRemoveNoteID(note.uuid)}
                          >
                            {t('bookingNotes.button_Remove')}
                          </p>
                        </div>
                      )}
                    </div>
                    <p className={cx(styles.text)}>{note.text}</p>
                  </div>
                )
              })
            )}
          </div>
        )}
      </div>
    </ItemBox>
  )
}

BookingNotesCourses.propTypes = {
  bookingID: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  isCancelled: PropTypes.bool,
  styles: PropTypes.object,
}

BookingNotesCourses.defaultProps = {
  canEdit: true,
  isCancelled: false,
}

export default BookingNotesCourses
