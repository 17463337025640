import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import { FC, MouseEvent, useEffect, useState } from 'react'

type Props = {
  checked: boolean
  onChange(checked: boolean): void
}

const CheckboxCell: FC<Props> = ({ checked, onChange }) => {
  const [checkedInternal, setChecked] = useState(false)

  useEffect(() => {
    setChecked(checked)
  }, [checked])

  const onClick = (evt: MouseEvent) => {
    evt.stopPropagation()
    setChecked(!checkedInternal)
    onChange(!checkedInternal)
  }

  return (
    <div onClick={onClick}>
      <Checkbox value={checkedInternal} onChange={(k) => setChecked(!checked)} />
    </div>
  )
}

export default CheckboxCell
