import styles from './styles.module.scss'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { useTranslation } from 'react-i18next'

export const BillingAccountForm = ({
  accountType,
  accountTypeValues,
  setAccountType,
  country,
  countryValues,
  setCountry,
  handleEmailFiled,
  handleNameField,
  emailError,
  nameError,
  handleSubmitBtn,
  submitButtonLoader,
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={styles.formContainer}>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.formGroupCon}>
          <div className="ss-form-group">
            <label className={`ss-label ${styles.formLabel}`}>{t('sentences.accountType')}</label>
            <DropdownSelect
              showBorder
              width="100%"
              initialId={accountType}
              values={accountTypeValues}
              onSelect={(accountType) => setAccountType(accountType)}
            />
          </div>
          <div className="ss-form-group">
            <label className={`ss-label ${styles.formLabel}`}>{t('words.country')}</label>
            <DropdownSelect
              showBorder
              width="100%"
              initialId={country}
              values={countryValues}
              onSelect={(countryName) => setCountry(countryName)}
            />
          </div>
        </div>
        <div className={styles.formGroupCon}>
          <div className="ss-form-group">
            <label className={`ss-label ${styles.formLabel}`}>
              {t(accountType === 'individual' ? 'words.name' : 'sentences.legalName')}
            </label>
            <input className="ss-input" onChange={handleNameField} type="text" />
          </div>
          <div className="ss-form-group">
            <label className={`ss-label ${styles.formLabel}`}>{t('words.email')}</label>
            <input className="ss-input" onChange={handleEmailFiled} type="text" />
          </div>
        </div>
        <button
          disabled={nameError || emailError ? true : false}
          onClick={handleSubmitBtn}
          className={`system-button primary md-32 ${styles.submitButton}`}
        >
          {submitButtonLoader ? <PulseLoader showIf={true} /> : t('words.continue')}
        </button>
      </form>
    </div>
  )
}
