import { useSelector } from 'react-redux'

import { APP_NAMES } from '@sweetspot/shared/util/constants'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'

const useMe = () => {
  const CURRENT_APP = getCurrentAppName()
  const { me } = useSelector((state) => {
    if (CURRENT_APP === APP_NAMES.PARTNER_PORTAL) {
      return state?.auth
    }
    if (CURRENT_APP === APP_NAMES.CLUB_PORTAL) {
      return state?.auth
    }
    return null
  })

  return me
}

export default useMe
