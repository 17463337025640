import { useEffect } from 'react'

const BUTTONS = {
  ArrowDown: {
    keyCode: 40,
    which: 40,
    key: 'ArrowDown',
    code: 'ArrowDown',
  },
  ArrowUp: {
    keyCode: 38,
    which: 38,
    key: 'ArrowUp',
    code: 'ArrowUp',
  },
  Enter: {
    keyCode: 13,
    which: 13,
    key: 'Enter',
    code: 'Enter',
  },
  Escape: {
    keyCode: 27,
    which: 27,
    key: 'Escape',
    extraKey: 'Esc',
    code: 'Escape',
  },
}

const useButtonListener = (
  buttons = [],
  handler,
  event = 'keydown',
  options = {
    preventDefault: false,
    stopPropagation: false,
    capture: false,
  }
) => {
  useEffect(() => {
    const eventHandler = (e) => {
      buttons.forEach((buttonKey) => {
        const { keyCode, which, key, code, extraKey } = BUTTONS[buttonKey]
        if (
          e?.keyCode === keyCode ||
          e?.which === which ||
          e?.key === key ||
          e?.code === code ||
          (extraKey && e?.key === extraKey)
        ) {
          if (options?.preventDefault) e.preventDefault()
          if (options?.stopPropagation) e.stopPropagation()
          handler(e)
        }
      })
    }
    window.addEventListener(event, eventHandler, { capture: options?.capture })
    return () => window.removeEventListener(event, eventHandler, { capture: options?.capture })
  }, [buttons, handler, event, options])
}

export default useButtonListener
