import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Get membership card
 *
 * @param {number} cardId
 * @returns
 */
export const getMembershipCard = (cardId) => {
  const url = `${getApiPlatformBaseUrl()}/membership-cards/${cardId}`
  return request(url)
}

/**
 * Get membership card payments
 *
 * @param {string} membershipCardUUID
 * @returns
 */
export const getMembershipCardPayments = (membershipCardUUID) => {
  const url = `${getApiPlatformBaseUrl()}/players/membership-cards/${membershipCardUUID}/payments`
  return request(url)
}

/**
 * Get query membership cards
 * @param {object} query
 * @returns
 */
export const queryMembershipCards = (
  query = {
    'club.id': null,
    'player.id': null,
    state: null,
    'groups[]': null,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/membership-cards`,
      query: {
        ...query,
        page: 1,
        limit: 999,
      },
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}
/**
 *
 *
 * @param {string[]} membershipCardUuidList
 * @return
 */
export const cancelMembershipCards = (membershipCardUuidList) => {
  const url = `${getApiPlatformBaseUrl()}/membership-cards/cancel-bulk`
  return request(url, {
    method: 'POST',
    body: {
      membership_cards: membershipCardUuidList,
    },
  })
}

/**
 * @param {object} query
 * @returns All memberships for the club that fullfills the criteria
 */
export const queryMemberships = (
  query = {
    'club.id': null,
    state: null,
    name: null,
    uuid: null,
    'uuid[]': null,
    total: null,
    total_monthly: null,
    isActive: null,
    'order[sort.name]': null,
    'duration.options[]': null,
    isSellMembershipEnabled: null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/memberships`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Query by ID (not uuid)
 *
 * @param {number} membershipId
 * @returns
 */
export const getMembershipById = (membershipId) => {
  const url = `${getApiPlatformBaseUrl()}/memberships/${membershipId}`
  return request(url)
}

/**
 * @param {object} query
 * @returns Array of memberhsips for this player
 */
export const queryMembershipsPlayer = (
  query = {
    club: null,
    isActive: null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/players/memberships`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Add membership to player
 * @param {string} courseUuid
 * @param {string} membershipUuid
 * @param {string} durationType
 * @param {string} calendarYear -- From datestring
 */

export const postMembershipSignup = ({
  courseUuid,
  membershipUuid,
  durationType,
  calendarYear,
}) => {
  const url = `${getApiPlatformBaseUrl()}/players/memberships/sign-up`
  return request(url, {
    method: 'POST',
    body: {
      course: courseUuid,
      membership: membershipUuid,
      duration_type: durationType,
      calendar_year: calendarYear,
    },
  })
}

/**
 * Cancel membership
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const cancelMembershipAutoRenewal = (membershipId) => {
  const URL = `${getApiPlatformBaseUrl()}/crm/membership-cards/${membershipId}/autorenewal/cancel`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * Enable membership
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const enableMembershipAutoRenewal = (membershipId) => {
  const URL = `${getApiPlatformBaseUrl()}/membership-cards/${membershipId}/autorenewal/enable`
  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
