import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

import {
  queryBallDispensers,
  updateBallDispensers,
  RangeWithBallDispensers,
  BallDispenser,
  updateOutletActivation,
  OutletActivationPaylaod,
} from '@sweetspot/shared/data-access/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import { SetupFormFields, IntegrationFormFields, StatusBallDispenser } from '../types'
import {
  constructData,
  constructIntegrationPayload,
  constructSetupPayload,
  validateSetupTab,
  validateIntegrationTab,
} from '../utils'
import { ViolationError } from '@sweetspot/shared/types'

type UseBallDispensersType = {
  rangeId?: string
  siteId?: string
  refetchRanges: () => void
}

const useBallDispensers = ({ rangeId, siteId, refetchRanges }: UseBallDispensersType) => {
  const [ballDispensers, setBallDispensers] = useState<BallDispenser[]>()
  const [statusInitialData, setStatusInitialData] = useState<StatusBallDispenser[]>()
  const [setupInitialData, setSetupInitialData] = useState<SetupFormFields>({ ballDispensers: [] })
  const [integrationInitialData, setIntegrationInitialData] = useState<IntegrationFormFields>({
    ballDispensers: [],
  })
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const ballDispensersUpdateMutation = useMutation(
    (payload: RangeWithBallDispensers) => {
      return updateBallDispensers({
        id: rangeId,
        range_servant_site_id: payload.range_servant_site_id || siteId,
        dispensers: payload.dispensers,
      })
    },
    {
      onSuccess: () => {
        refetch()
        refetchRanges()
      },
      onError: (error: ViolationError) => {
        if (error?.violations?.[0]?.message) {
          addToast(error?.violations?.[0]?.message, { appearance: 'error' })
        } else {
          addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
        }
      },
    }
  )

  const handleSetupSave = useCallback(
    (values: SetupFormFields) => {
      const payload = constructSetupPayload(values.ballDispensers, ballDispensers)
      const errorText = validateSetupTab(payload)
      if (errorText) {
        addToast(t(errorText), { appearance: 'error' })
      } else {
        return ballDispensersUpdateMutation.mutateAsync(payload)
      }
    },
    [ballDispensersUpdateMutation, ballDispensers, t, addToast]
  )

  const handleIntegrationSave = useCallback(
    (values: IntegrationFormFields) => {
      const payload = constructIntegrationPayload(values, ballDispensers)
      const errorText = validateIntegrationTab(payload, t)
      if (errorText) {
        addToast(errorText, { appearance: 'error' })
      } else {
        return ballDispensersUpdateMutation.mutateAsync(payload)
      }
    },
    [ballDispensersUpdateMutation, ballDispensers, t, addToast]
  )

  const outletActivationMutation = useMutation(
    (payload: OutletActivationPaylaod) => {
      return updateOutletActivation({
        rangeId,
        ...payload,
      })
    },
    {
      onSuccess: () => {
        addToast(t('toast.outletUpdatedSuccess'), { appearance: 'success' })
        refetch()
      },
      onError: (error: ViolationError) => {
        if (error?.violations?.[0]?.message) {
          addToast(error?.violations?.[0]?.message, { appearance: 'error' })
        } else {
          addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
        }
      },
    }
  )

  const handleOutletActivation = useCallback(
    (payload: OutletActivationPaylaod) => outletActivationMutation.mutateAsync(payload),
    [outletActivationMutation]
  )

  const { isFetching, refetch, isFetched } = useQuery(
    [CLUB_QUERIES.BALL_DISPENSERS, rangeId],
    () => queryBallDispensers(rangeId),
    {
      enabled: !!rangeId,
      onSuccess: (data) => {
        const { setupData, integrationData, statusData } = constructData(data?.range)
        setStatusInitialData(statusData)
        setSetupInitialData(setupData)
        setIntegrationInitialData(integrationData)
        setBallDispensers(data?.range?.dispensers)
      },
      onError: (error: ViolationError) => {
        if (error?.violations?.[0]?.message) {
          addToast(error?.violations?.[0]?.message, { appearance: 'error' })
        } else {
          addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
        }
      },
    }
  )

  return {
    statusInitialData,
    setupInitialData,
    integrationInitialData,
    handleSetupSave,
    handleIntegrationSave,
    handleOutletActivation,
    areBallDispensersFetching: !isFetched && isFetching,
  }
}

export default useBallDispensers
