import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

const InputInlinePrefixSuffix = ({
  type,
  value,
  error,
  success,
  children,
  focused,
  disabled,
  onSuffixClick,
  theme,
}) => {
  const renderChildren = () => {
    if (typeof children === 'string') {
      return <p className={styles.text}>{children}</p>
    } else if (typeof children === 'function') {
      return children()
    } else {
      return children
    }
  }

  return (
    <div
      className={cx(
        styles.componentContainer,
        styles.defaultHeight,
        styles.borderDefault,
        {
          [styles.borderError]: error,
          [styles.borderSuccess]: success,
          [styles.borderFilled]: value,
          [styles.borderActive]: focused,
          [styles.borderDisabled]: disabled,
        },
        {
          [styles.prefix]: type === 'prefix',
          [styles.suffix]: type === 'suffix',
        },
        { [styles.noBorder]: theme === 'login' }
      )}
      onClick={onSuffixClick}
    >
      {renderChildren()}
    </div>
  )
}

InputInlinePrefixSuffix.propTypes = {
  type: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  focused: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onSuffixClick: PropTypes.func,
}
InputInlinePrefixSuffix.defaultProps = {
  type: 'suffix',
  value: false,
  error: false,
  success: false,
  focused: false,
  children: '',
  disabled: false,
  onSuffixClick: () => {},
}

export default InputInlinePrefixSuffix
