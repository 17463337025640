import React, { FC, useState, useCallback } from 'react'

import styles from '../style.module.scss'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { useTranslation } from 'react-i18next'

type Props = {
  disabled: boolean
  labelTwo?: string
  defaultValue?: string
  onSubmit: (note: string) => Promise<unknown>
}

const MAX_NOTE_LENGTH = 1000

const NoteTextInputField: FC<Props> = ({ disabled, labelTwo, onSubmit, defaultValue }) => {
  const [note, setNote] = useState<string>(defaultValue || '')

  const { t } = useTranslation()

  const handleNoteChange = useCallback((value: string) => {
    if (value.length > MAX_NOTE_LENGTH) return
    setNote(value)
  }, [])

  const handleSubmit = useCallback(async () => {
    await onSubmit(note)
    setNote('')
  }, [note, onSubmit])

  return (
    <>
      <TextInputField
        inputProps={{ autoFocus: true }}
        value={note}
        labelTwo={labelTwo !== undefined ? labelTwo : t('words.note_one')}
        placeholder={t('settings.playerCard.writeNote')}
        type={'text-area'}
        containerClassName={'mb-0'}
        onEnter={handleSubmit}
        onChange={handleNoteChange}
        disabled={disabled}
      />
      <div className={'flex justify-between align-middle'}>
        <Text textId={t('settings.playerCard.pressEnterToSend')} className={styles.infoText} />
        <Text textId={`${note.length}/${MAX_NOTE_LENGTH}`} className={styles.infoText} />
      </div>
    </>
  )
}

export default NoteTextInputField
