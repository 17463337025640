import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import SingleRadioOption from '../FormElements/Partials/SingleRadioOption'
import usePositionFixedElement from '@sweetspot/sweetspot-js/common/hooks/usePositionFixedElement'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

const PopoverRadioOptions = ({
  options,
  selectedValue,
  destructiveAction,
  applyAction,
  cancelAction,
  popoverOptions,
  isVisible,
}) => {
  const elementRef = useRef()

  const [value, setValue] = useState(selectedValue)

  useEffect(() => {
    setValue(selectedValue)
  }, [selectedValue, isVisible])

  const anchorElementRef = useMemo(() => {
    if (!popoverOptions?.anchorElementRef) return null
    return popoverOptions.anchorElementRef
  }, [popoverOptions])

  const isChecked = (option) => {
    return option.value === value
  }

  const position = usePositionFixedElement({
    containerRef: anchorElementRef,
    elementRef,
    isVisible,
    anchorPoint: 'bottom-right',
    direction: 'down-left',
  })

  useOnClickOutside(elementRef, () => {
    if (cancelAction?.onClick) {
      cancelAction.onClick()
    }
  })

  if (!options || !isVisible) return null

  return (
    <div
      className={cx(styles.container, isVisible && styles.visible)}
      ref={elementRef}
      style={{ ...(anchorElementRef ? position : {}) }}
    >
      {options.map((option) => (
        <div
          className={cx(styles.radioGroup)}
          key={option.value}
          onClick={() => setValue(option.value)}
        >
          <SingleRadioOption
            containerClassName={cx(styles.radio)}
            value={option.value}
            checked={isChecked(option)}
          />
          <div className={cx(styles.right)}>
            <p className={cx(styles.label)}>{option?.label || ' '}</p>
            {option?.description && <p className={cx(styles.description)}>{option.description}.</p>}
          </div>
        </div>
      ))}
      <div className={cx(styles.footer)}>
        {destructiveAction && (
          <p className={cx(styles.destructive)} onClick={destructiveAction.onClick}>
            {destructiveAction?.label}
          </p>
        )}
        <div className={cx(styles.right)}>
          {cancelAction && (
            <button
              className={cx('system-button md-32 primary-outline')}
              onClick={cancelAction.onClick}
            >
              {cancelAction.label}
            </button>
          )}
          {applyAction && (
            <button
              className={cx('system-button md-32 primary')}
              onClick={() => applyAction.onClick(value)}
            >
              {applyAction.label}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

PopoverRadioOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  destructiveAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  applyAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  cancelAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  popoverOptions: PropTypes.shape({
    anchorElementRef: PropTypes.any,
  }),
  isVisible: PropTypes.bool,
}

PopoverRadioOptions.defaultProps = {
  options: undefined,
  selectedValue: undefined,
  destructiveAction: undefined,
  applyAction: undefined,
  cancelAction: undefined,
  popoverOptions: undefined,
  isVisible: false,
}

export default PopoverRadioOptions
