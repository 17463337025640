import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import Utilities from '../../../../helpers/Utilities'
import DateHelpers from '../../../../helpers/DateHelpers'
import DOMHelpers from '../../../../helpers/DOMHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

export default class TimeCell extends Component {
  constructor(props) {
    super(props)

    let date = new Date()
    this.state = {
      selectedTime: Utilities.isSet(this.props.initialTime)
        ? this.props.initialTime
        : DateHelpers.toMinutes(date.getMinutes(), date.getHours()),
      isOpen: false,
    }

    this.toggleOpen = this.toggleOpen.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleChangeHours = this.handleChangeHours.bind(this)
    this.handleChangeMinutes = this.handleChangeMinutes.bind(this)
    this.nextMinute = this.nextMinute.bind(this)
    this.previousMinute = this.previousMinute.bind(this)
    this.nextHour = this.nextHour.bind(this)
    this.previousHour = this.previousHour.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialTime !== prevProps.initialTime) {
      let date = new Date()
      this.setState({
        selectedTime: Utilities.isSet(this.props.initialTime)
          ? this.props.initialTime
          : DateHelpers.toMinutes(date.getMinutes(), date.getHours()),
      })
    }
    if (this.state.selectedTime !== prevState.selectedTime) {
      if (this._hoursInput && this._minutesInput) {
        this._minutesInput.value = DateHelpers.toTimeObject(this.state.selectedTime).minutes
        this._hoursInput.value = DateHelpers.toTimeObject(this.state.selectedTime).hours
      }
    }
  }

  handleChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.selectedTime)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick(event) {
    if (!DOMHelpers.didClickInsideArray([this._container, this._selectContainer], event)) {
      this.setState({
        isOpen: false,
      })
    }
  }

  handleChangeHours(event) {
    let newHour = event.target.value
    if (isNaN(newHour) || newHour === '') return
    if (newHour < 0 || newHour >= 24) return
    newHour = parseInt(newHour)
    let minutes = parseInt(DateHelpers.toTimeObject(this.state.selectedTime).minutes)
    this.setState(
      {
        selectedTime: newHour * 60 + minutes,
      },
      this.handleChange
    )
  }

  handleChangeMinutes(event) {
    let newMinutes = event.target.value
    if (isNaN(newMinutes) || newMinutes === '') return
    if (newMinutes < 0 || newMinutes >= 60) return
    newMinutes = parseInt(newMinutes)
    let hours = parseInt(DateHelpers.toTimeObject(this.state.selectedTime).hours)
    this.setState(
      {
        selectedTime: hours * 60 + newMinutes,
      },
      this.handleChange
    )
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  nextMinute() {
    let nextTime = this.state.selectedTime + 1
    if (nextTime >= 24 * 60) nextTime = 0
    this.setState(
      {
        selectedTime: nextTime,
      },
      this.handleChange
    )
  }

  previousMinute() {
    let nextTime = this.state.selectedTime - 1
    if (nextTime < 0) nextTime = 24 * 60 - 1
    this.setState(
      {
        selectedTime: nextTime,
      },
      this.handleChange
    )
  }

  nextHour() {
    let nextTime = this.state.selectedTime + 60
    if (nextTime >= 24 * 60)
      nextTime = parseInt(DateHelpers.toTimeObject(this.state.selectedTime).minutes)
    this.setState(
      {
        selectedTime: nextTime,
      },
      this.handleChange
    )
  }

  previousHour() {
    let nextTime = this.state.selectedTime - 60
    if (nextTime < 0)
      nextTime = 23 * 60 + parseInt(DateHelpers.toTimeObject(this.state.selectedTime).minutes)
    this.setState(
      {
        selectedTime: nextTime,
      },
      this.handleChange
    )
  }

  renderSelect() {
    if (this.state.isOpen) {
      return (
        <div
          className={style.selectContainer}
          ref={(container) => (this._selectContainer = container)}
        >
          <div className={style.selectContent}>
            <div className={[style.timeContent, style.left].join(' ')}>
              <div className={style.chevron} onClick={this.nextHour}>
                <FontAwesomeIcon icon={faChevronUp} />
              </div>
              <input
                type="text"
                defaultValue={DateHelpers.toTimeObject(this.state.selectedTime).hours}
                onChange={this.handleChangeHours}
                ref={(input) => (this._hoursInput = input)}
              />
              <div className={style.chevron} onClick={this.previousHour}>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
            <div className={style.timeContent}>
              <div className={style.chevron} onClick={this.nextMinute}>
                <FontAwesomeIcon icon={faChevronUp} />
              </div>
              <input
                type="text"
                defaultValue={DateHelpers.toTimeObject(this.state.selectedTime).minutes}
                onChange={this.handleChangeMinutes}
                ref={(input) => (this._minutesInput = input)}
              />
              <div className={style.chevron} onClick={this.previousMinute}>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    let containerClassNames = [style.container]
    if (this.state.isOpen) containerClassNames.push(style.open)

    return (
      <div
        className={containerClassNames.join(' ')}
        ref={(container) => (this._container = container)}
      >
        <div className={style.textContainer} onClick={this.toggleOpen}>
          {DateHelpers.toTime(this.state.selectedTime)}
        </div>
        {this.renderSelect()}
      </div>
    )
  }
}
TimeCell.propTypes = {
  initialTime: PropTypes.number,
  onChange: PropTypes.func,
}
