import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useOptionsForSelects } from '../context'
import style from '../style.module.scss'
import { useMemo } from 'react'

const CategorySelect = ({ formState, setValue, isOverride }) => {
  const { t } = useTranslation()
  const { categories } = useOptionsForSelects()

  const categoriesOptions = useMemo(
    () => (isOverride ? [{ id: 0, name: t('sentences.noChange') }, ...categories] : categories),
    [categories, isOverride, t]
  )

  const selectedId = useMemo(() => {
    return (
      parseInt(categoriesOptions.find((option) => option.id === formState.category_id)?.id) || 0
    )
  }, [categoriesOptions, formState.category_id])

  return (
    <div>
      <div className={style.optionsText}>{t('editPeriodsNew.category')}</div>
      <div
        className={cx(
          style.select,
          style.categorySelect,
          !selectedId ? '!pt-0 [&_svg]:!top-[14px]' : ''
        )}
      >
        <DropdownSelect
          values={categoriesOptions}
          selectedId={selectedId}
          initialId={selectedId}
          onSelect={(value) => setValue('category_id', value || null)}
          noMargin
        />
      </div>
    </div>
  )
}

export default CategorySelect
