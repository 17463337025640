import i18n from 'i18next'
import moment from 'moment'
import 'moment/min/locales.min'

import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'

const CALENDAR_DAY_AMOUNT = 7 * 6

/**
 * LEGACY: Dont add new utils here
 */
export default class DateHelpers {
  static today() {
    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)

    return today
  }

  static isSameDate(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  static toDateString(date, lang) {
    const monthStr = capitalize(DateHelpers.getMonthName(date, lang))
    let dateStr = date.getDate()
    let yearStr = date.getFullYear()

    return `${monthStr} ${dateStr} ${yearStr}`
  }

  static toWeekdayDateString(date, variant) {
    const monthStr = capitalize(DateHelpers.getMonthName(date))
    let dateStr = date.getDate()
    let dayStr = this.getDayName(date)
    if (variant === 'datePicker') {
      return `${dayStr} ${dateStr} ${monthStr}`
    }
    return `${dayStr}, ${dateStr} ${monthStr}`
  }

  static toBFDateString(date, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(date).format('MMM Do YYYY')
    return this.jsUcfirst(newDate)
  }

  static toPTDateString(time, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(new Date(time * 1000)).format('MMMM Do')
    return this.jsUcfirst(newDate)
  }

  static toPTFormDateString(date, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(date).format('YYYY MM DD')
    return newDate
  }

  static toPODateString(time) {
    const newDate = moment(new Date(time * 1000)).format('YY MM DD')
    return newDate
  }

  static toFullDateString(date, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(date).format('YYYY MM DD - HH.mm')
    return this.jsUcfirst(newDate)
  }

  static onlyDateString(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  static dateStringwithMinutes(date) {
    return moment(date).format('YYYY-MM-DD HH:mm')
  }

  static toPHDateString(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  static toDateStringWithTime(date) {
    return moment(date).format('DD-MM HH:mm')
  }

  // Function to convert from dateString to Date object. DateString Format: YYYY MM DD
  static toDateObject(dateString) {
    const year = dateString.substr(0, 4)
    const month = dateString.substr(5, 2)
    const day = dateString.substr(8, 2)
    return new Date(year, parseInt(month - 1), day)
  }

  static toISODateTime(date) {
    return moment(date).format('YYYY-MM-DDTHH:MM:SSZ')
  }

  static getTime(date, time) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const hour = time.substr(0, 2)
    const minute = time.substr(3, 2)
    return new Date(year, month, day, hour, minute).getTime() / 1000
  }

  static toDateFromDateString(dateString) {
    const [year, month, day] = dateString.split(' ')[0].split('-')
    const [hour, minute, second] = dateString.split(' ')[1].split(':')

    return new Date(year, parseInt(month - 1), day, hour, minute, second)
  }

  static changeTimezone(date) {
    const SEDate = new Date(
      date.toLocaleString('en-US', {
        timeZone: 'Europe/Stockholm',
      })
    )

    const timeOffset = date.getTime() - SEDate.getTime()

    return new Date(date.getTime() + timeOffset)
  }

  static toDateFromDTString(dateTimeString) {
    const [year, month, day] = dateTimeString.split(' ')[0].split('-')
    const [hour, min, sec] = dateTimeString.split(' ')[1].split(':')
    return new Date(year, parseInt(month - 1), day, hour, min, sec)
  }

  // Function to check if selected period(fromDate, toDate) is valid or not
  static checkValidPeriod(from, to) {
    if (from.getTime() > to.getTime()) return false
    return true
  }

  // Function to check if selected period(fromTime, toTime) is valid or not
  static checkValidTimePeriod(fromTime, toTime) {
    if (!fromTime || !toTime || fromTime > toTime) return false
    return true
  }

  static toTimeString(date, separator = '.') {
    const hours = DateHelpers.prependZero(date.getHours())
    let minutes = DateHelpers.prependZero(date.getMinutes())
    return `${hours}${separator}${minutes}`
  }

  static toDailyTimestamp(date) {
    date.setHours(12)
    date.setMinutes(0)
    date.setSeconds(0)

    return Math.floor(date.getTime() / 1000)
  }

  static toTimestamp(date) {
    return Math.floor(Date.parse(date) / 1000)
  }

  static toMinutes(minutes, hours, days = 0) {
    return minutes + hours * 60 + days * 24 * 60
  }

  static toTime(minutes) {
    minutes = minutes % (24 * 60 * 60)

    let hours = Math.floor(minutes / 60)
    minutes = minutes - hours * 60

    return `${DateHelpers.prependZero(hours)}:${DateHelpers.prependZero(minutes)}`
  }

  static toTimeObject(minutes) {
    minutes = minutes % (24 * 60 * 60)

    let hours = Math.floor(minutes / 60)
    minutes = minutes - hours * 60

    return { hours: DateHelpers.prependZero(hours), minutes: DateHelpers.prependZero(minutes) }
  }

  static getAge(dob) {
    if (!dob) {
      return null
    }

    const then = dob.getTime()
    let now = new Date().getTime()
    let diff = now - then

    return Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
  }

  /**
   * Returns the normalized day number (week starting on monday)
   *
   * @param {Date} date
   * @return {Number} dayNumber
   */
  static getDayNumber(date) {
    let dayNumber = date.getDay() - 1
    if (dayNumber === -1) dayNumber = 6

    return dayNumber
  }

  static getDayName(date) {
    const day = this.getDaysArray()[this.getDayNumber(date)]
    return i18n.t(`dateTime.longDays.${day}_one`)
  }

  static getDateFromDateAndTime(date, time) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const hour = time.hours()
    const minutes = time.minutes()

    const newDate = new Date(year, month, day, hour, minutes)

    return newDate
  }

  static getUTCDateString(date) {
    const newDate = moment(date)
    const UTCDate = newDate.utc().format('YYYY-MM-DD')
    const UTCTime = newDate.utc().format('HH:mm:ss')

    const UTCDateString = `${UTCDate} ${UTCTime}`

    return UTCDateString
  }

  static getLocalDateFromUTCString(UTCDateString) {
    if (!UTCDateString) return null
    const offset = new Date().getTimezoneOffset() / 60

    const [date, time] = UTCDateString.split(' ')
    let [year, month, day] = date.split('-')
    let [hour, minute] = time.split(':')

    hour -= offset
    if (hour >= 24) {
      hour -= 24
      day = parseInt(day) + 1
    } else if (hour < 0) {
      hour += 24
      day -= 1
    }

    return new Date(year, month - 1, day, hour, minute)
  }

  /**
   * Returns an array of dates for the current month in calendar format
   *
   * @param {Date} date
   * @return {Date[]} calendar dates
   */
  static getMonthCalendar(date) {
    const firstDay = new Date(date.getTime())
    firstDay.setDate(1)
    let dayNumber = DateHelpers.getDayNumber(firstDay)

    if (dayNumber === 0) {
      firstDay.setDate(firstDay.getDate() - 7)
    } else {
      firstDay.setDate(firstDay.getDate() - dayNumber)
    }

    const dates = []
    for (let i = 0; i < CALENDAR_DAY_AMOUNT; i++) {
      const date = new Date(firstDay.getTime())
      date.setDate(date.getDate() + i)
      dates.push(date)
    }

    return dates
  }

  static previousDay(date) {
    const newDate = new Date(date.getTime())
    newDate.setDate(date.getDate() - 1)

    return newDate
  }

  static nextDay(date) {
    const newDate = new Date(date.getTime())
    newDate.setDate(date.getDate() + 1)

    return newDate
  }

  static oneMonthPrev(date) {
    date.setMonth(date.getMonth() - 1)

    return date
  }

  static oneMonthForward(date) {
    date.setMonth(date.getMonth() + 1)

    return date
  }

  /**
   * Returns a new Date of first of the next month
   *
   * @param {Date} date
   * @return {Date} next month
   */
  static nextMonth(date) {
    const newDate = new Date(date.getTime())
    newDate.setDate(1)
    newDate.setMonth(newDate.getMonth() + 1)

    return newDate
  }

  /**
   * Returns a new Date of first of the previous month
   *
   * @param {Date} date
   * @return {Date} next month
   */
  static previousMonth(date) {
    const newDate = new Date(date.getTime())
    newDate.setDate(1)
    newDate.setMonth(newDate.getMonth() - 1)

    return newDate
  }

  static prependZero(value) {
    if (value < 10) return '0' + value
    return value
  }

  static convertToMomentLang(lang) {
    return lang === 'se' ? 'sv_SE' : 'en'
  }

  static jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  static parseIncludedDays(rawDays) {
    const days = []
    Object.keys(rawDays).forEach((day) => {
      if (rawDays[day]?.to && rawDays[day]?.from) days.push(day)
    })

    const weekends =
      days.filter((day) => day.toLowerCase() === 'saturday' || day.toLowerCase() === 'sunday')
        .length === 2
    const weekdays =
      days.filter(
        (day) =>
          day.toLowerCase() === 'monday' ||
          day.toLowerCase() === 'tuesday' ||
          day.toLowerCase() === 'wednesday' ||
          day.toLowerCase() === 'thursday' ||
          day.toLowerCase() === 'friday'
      ).length === 5

    if (weekends && weekdays) {
      return 'date.days.all_days'
    } else if (weekends && !weekdays) {
      return 'date.days.weekends'
    } else if (!weekends && weekdays) {
      return 'date.days.weekdays'
    } else {
      return days.map((day) => `date.days.${day.toLowerCase()}`)
    }
  }

  static convertToHIS(string) {
    if (string.length === 5) {
      return `${string}:00`
    }
    return string
  }

  /**
   * Returns the name of the month in the current language
   *
   * @param {Date} date
   * @return {String} month name
   */
  static getMonthName(date) {
    const monthNames = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ]
    let monthNumber = date.getMonth()
    return i18n.t('dateTime.longMonths.' + monthNames[monthNumber])
  }

  static getDaysArray() {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  static checkIfDateIsInsidePeriod(startDate, endDate, date) {
    return date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()
  }

  static isInsidePeriod(startDate, endDate, date) {
    return date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()
  }

  static isInsidePeriodAndValidDay(startDate, endDate, availableDays, date) {
    return (
      date.getTime() >= startDate.getTime() &&
      date.getTime() <= endDate.getTime() &&
      availableDays.includes(moment(date).lang('en').format('dddd'))
    )
  }

  static haveDatePassed(date) {
    if (date <= new Date(new Date().setDate(new Date().getDate() + 1))) {
      return true
    }
    return false
  }

  static getNextAvailableDate(startDate, endDate, availableDays, date, future) {
    let workingDate = new Date(date.getTime())

    for (let x = 0; x < 7; x++) {
      if (future) {
        workingDate = this.nextDay(workingDate)
      } else {
        workingDate = this.previousDay(workingDate)
      }
      if (this.isInsidePeriodAndValidDay(startDate, endDate, availableDays, workingDate)) {
        return workingDate
      }
    }
    return false
  }

  static getFirstAvailableDate(startDate, endDate, availableDays, date) {
    let currentDate = date
    while (!this.isInsidePeriodAndValidDay(startDate, endDate, availableDays, currentDate)) {
      if (!this.isSameDate(date, endDate)) currentDate = this.nextDay(currentDate)
      else return currentDate
    }
    return currentDate
  }

  static isPastBooking(bookingTime) {
    return bookingTime.getTime() < new Date().getTime()
  }

  static toDateMonthShorten(date) {
    const dateStr = date.getDate()
    const monthStr = this.getMonthName(date)
    return `${dateStr} ${monthStr.slice(0, 3)}`
  }
}

export const displayDate = (date) => {
  const dateInstance = new Date()
  const currentDate = dateInstance.getDate()
  const currentMonth = dateInstance.getMonth()

  const dateAsArgument = date.getDate()
  const monthAsArgument = date.getMonth()

  let monthStr = DateHelpers.getMonthName(date).slice(0, 3)
  let dateStr = date.getDate()
  let dayStr = DateHelpers.getDayName(date)

  if (currentDate === dateAsArgument && currentMonth === monthAsArgument) {
    return `${i18n.t('dateTime.words.today')}, ${dateStr} ${monthStr}`
  }

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const isTomorrow =
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()

  return `${isTomorrow ? i18n.t('dateTime.words.tomorrow') : dayStr}, ${dateStr} ${monthStr}`
}
