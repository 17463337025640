import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

export const setLanguage = (language: string): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/settings/language`

  return request(URL, {
    method: 'POST',
    body: { language },
  })
}
