import { useQuery } from 'react-query'
import { DATA_QUERIES } from '@sweetspot/shared/util/constants'
import { getTimeZones } from '@sweetspot/sweetspot-js/common/services/timezone-service'

const useTimeZone = () => {
  const { data: timezones } = useQuery({
    queryKey: [DATA_QUERIES.TIMEZONES],
    queryFn: () => {
      return getTimeZones()
    },
    select: (data) => {
      return data.sort((zone1, zone2) => {
        return zone1.offset - zone2.offset
      })
    },
    onError: () => {
      return []
    },
  })
  return { timezones }
}

export { useTimeZone }
