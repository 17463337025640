import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import { cn } from '@sweetspot/scramble-ds'

import IntegrationsTab from './IntegrationsTab'
import SetupTab from './SetupTab'
import StatusTab from './StatusTab/StatusTab'
import { Tabs } from '../types'
import { useBallDispensersContext } from '../BallDispensersProvider'

const BallDispensersTabsContainer = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState<Tabs>()
  const { openDiscardDialog, discardDialogCallbacks } = useBallDispensersContext()

  // set active tab based on hash
  useEffect(() => {
    const hash = (history.location.hash?.slice(1) as Tabs) || Tabs.STATUS
    if (hash && Object.values(Tabs).includes(hash)) setActiveTab(hash)
  }, [history.location.hash])

  const renderContent = useCallback(() => {
    if (activeTab === Tabs.STATUS) {
      return <StatusTab />
    }
    if (activeTab === Tabs.SETUP) {
      return <SetupTab />
    }
    if (activeTab === Tabs.INTEGRATIONS) {
      return <IntegrationsTab />
    }
    return null
  }, [activeTab])

  return (
    <div className="w-full">
      <div className="mb-4 border-b pt-2">
        <div className="flex items-center px-6">
          <SimpleTabs
            className="h-8 gap-0.5 pb-0 [&_span]:!hidden"
            labelClassName="w-[106px] after:!scale-x-100 !mb-[-1px]"
            activeLabelClassName="!font-bold !text-text-dark after:!border-[#171717]"
            inActiveLabelClassName="!font-medium !text-text-subtle after:!border-[#D1D5DB]"
            currentItem={activeTab}
            onClick={(tab) => history.push(`#${tab}`)}
            items={[
              { id: Tabs.STATUS, label: t('words.status') },
              { id: Tabs.SETUP, label: t('words.setup') },
              {
                id: Tabs.INTEGRATIONS,
                label: t('words.integrations'),
              },
            ]}
          />
        </div>
      </div>
      <div className={cn('px-6', { 'h-[calc(100%-61px)]': activeTab === Tabs.STATUS })}>
        {renderContent()}
      </div>
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => discardDialogCallbacks?.onClose()}
        onDiscard={() => discardDialogCallbacks?.onDiscard()}
        onSave={() => discardDialogCallbacks?.onSave()}
      />
    </div>
  )
}

export default BallDispensersTabsContainer
