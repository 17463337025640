import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import { ReactComponent as ClearIcon } from '@sweetspot/club-portal-legacy/resources/images/clear-icon.svg'

import AddUserType from './components/AddUserType'
import Membership from './components/Membership'
import BookingWindow from './components/BookingWindow'
import { MEMBER, POLICY_TYPES, MAN, WOMAN, OTHER, POLICY_CHECKERS } from '../../constants'

import style from './style.module.scss'

const BookingPolicies = ({
  categoryId,
  isBookingCreator,
  policies,
  onCreate,
  onUpdate,
  onRemove,
  loading,
}) => {
  const [showCreateNew, setShowCreateNew] = useState(false)

  const dropdownRef = useRef(null)
  const isParticipant = !isBookingCreator
  const { t } = useTranslation()
  useOnClickOutside(dropdownRef, () => {
    setShowCreateNew(false)
  })

  const addPolicy = (value) => {
    if (value.id === 1) {
      const payload = {
        type: POLICY_CHECKERS.USER_TYPE,
        configuration: {},
      }
      onCreate(payload, isParticipant)
    } else if (value.id === 2) {
      const payload = {
        type: POLICY_CHECKERS.GENDER_TYPE,
        configuration: {
          genders: [],
        },
      }
      onCreate(payload, isParticipant)
    } else if (value.id === 3) {
      const payload = {
        type: POLICY_CHECKERS.TOTAL_HCP,
        configuration: {
          total_hcp: null,
        },
      }
      onCreate(payload, isParticipant)
    } else if (value.id === 4) {
      const payload = {
        type: POLICY_CHECKERS.PLAYER_HCP,
        configuration: {
          max_hcp_per_player: null,
        },
      }
      onCreate(payload, isParticipant)
    } else if (value.id === 5) {
      const payload = {
        type: POLICY_CHECKERS.BOOKING_WINDOW,
        configuration: {
          tee_time_category_id: parseInt(categoryId),
          time_unit: 'day',
          time_unit_value: 1,
          from_time: null,
        },
      }
      onCreate(payload, isParticipant)
    }

    setShowCreateNew(false)
  }

  const removePolicy = (id) => {
    onRemove(id, isParticipant)
    setShowCreateNew(false)
  }

  const changePolicy = (id, value) => {
    const configuration = {}
    const policy = policies.find((item) => item.id === id)
    policy.userTypes = policy.userTypes.map((userType) => {
      if (userType.type === MEMBER) {
        return { ...userType, value }
      }
      return userType
    })
    policy.userTypes.map((userType) => {
      configuration[userType.type.toLowerCase()] = userType.value.map((item) => item.value)
    })

    onUpdate(id, { configuration }, isParticipant)
  }

  const addUserType = (id, type) => {
    const configuration = {}
    const policy = policies.find((item) => item.id === id)
    policy.userTypes = policy.userTypes.concat({ type, value: [] })
    policy.userTypes.map((userType) => {
      configuration[userType.type.toLowerCase()] = userType.value.map((item) => item.value)
    })

    onUpdate(id, { configuration }, isParticipant)
  }

  const removeUserType = (id, type) => {
    const configuration = {}
    const policy = policies.find((item) => item.id === id)
    policy.userTypes = policy.userTypes.filter((x) => x.type !== type)
    policy.userTypes.map((userType) => {
      configuration[userType.type.toLowerCase()] = userType.value.map((item) => item.value)
    })

    onUpdate(id, { configuration }, isParticipant)
  }

  const handleGenderChange = (policy, gender) => {
    let genderTypes = policy.genderTypes
    if (genderTypes.includes(gender)) {
      genderTypes = genderTypes.filter((type) => type !== gender)
    } else {
      genderTypes.push(gender)
    }

    const payload = {
      configuration: {
        genders: genderTypes,
      },
    }
    onUpdate(policy.id, payload, isParticipant)
  }

  const handleHCPChange = _.debounce((policy, value) => {
    const payload = {
      configuration: {
        total_hcp: value === '-0' ? '0' : value || null,
      },
    }
    onUpdate(policy.id, payload, isParticipant)
  }, 1000)

  const handlePlayerHCPChange = _.debounce((policy, value) => {
    const payload = {
      configuration: {
        max_hcp_per_player: value === '-0' ? '0' : value || null,
      },
    }
    onUpdate(policy.id, payload, isParticipant)
  }, 1000)

  return (
    <div className={style.container}>
      {!policies.length && (
        <span className={style.noPolicy}>
          {isBookingCreator
            ? t('teeTimeCategory.noPolicy')
            : t('teeTimeCategory.noParticipantPolicy')}
        </span>
      )}
      {policies
        .filter((policy) => policy.userTypes)
        .map((policy) => (
          <div key={policy.id} className={style.policyWrapper}>
            <ClearIcon className={style.closeBtn} onClick={() => removePolicy(policy.id)} />
            <span className={style.mainLabel}>{t('teeTimeCategory.userType')}</span>
            {!policy.userTypes?.length && (
              <div>
                <span className={style.noPolicy}>{t('teeTimeCategory.noUserType')}</span>
              </div>
            )}

            {policy.userTypes.map((userType) => (
              <div key={userType.type} className={style.uType}>
                <div className={style.title}>{t(userType.type.toLowerCase())}</div>
                {userType.type === MEMBER && (
                  <Membership
                    policy={policy}
                    onSetMemberships={(values) => changePolicy(policy.id, values)}
                  />
                )}
                <ClearIcon
                  className={style.closeBtn}
                  onClick={() => removeUserType(policy.id, userType.type)}
                />
              </div>
            ))}
            <AddUserType
              isParticipant={!isBookingCreator}
              policy={policy}
              onAddUserType={(value) => addUserType(policy.id, value)}
            />
          </div>
        ))}
      {policies
        .filter((policy) => policy.genderTypes)
        .map((policy) => (
          <div key={policy.id} className={style.policyWrapper}>
            <ClearIcon className={style.closeBtn} onClick={() => removePolicy(policy.id)} />
            <span className={style.mainLabel}>{t('settings.playerCard.gender')}</span>
            <span className={style.secondaryLabel}>{t('teeTimeCategory.secondaryLabel')}</span>

            <div className={style.checkboxList}>
              <Checkbox
                label={t('settings.playerCard.man')}
                value={policy.genderTypes.includes(MAN)}
                onChange={() => handleGenderChange(policy, MAN)}
              />
              <Checkbox
                label={t('settings.playerCard.woman')}
                value={policy.genderTypes.includes(WOMAN)}
                onChange={() => handleGenderChange(policy, WOMAN)}
              />
              <Checkbox
                label={t('settings.playerCard.other')}
                value={policy.genderTypes.includes(OTHER)}
                onChange={() => handleGenderChange(policy, OTHER)}
              />
            </div>
          </div>
        ))}
      {policies
        .filter((policy) => policy.totalHcp !== undefined)
        .map((policy) => (
          <div key={policy.id} className={style.policyWrapper}>
            <ClearIcon className={style.closeBtn} onClick={() => removePolicy(policy.id)} />
            <span className={style.mainLabel}>{t('teeTimeCategory.totalHCP')}</span>
            <span className={style.secondaryLabel}>{t('teeTimeCategory.allowedTotalSum')}</span>

            <div className={style.hcpInput}>
              <TextInputField
                prefix="hcp"
                type="number"
                inputProps={{ defaultValue: policy.totalHcp }}
                onChange={(value) => handleHCPChange(policy, value)}
              />
            </div>
          </div>
        ))}
      {policies
        .filter((policy) => policy.playerHcp !== undefined)
        .map((policy) => (
          <div key={policy.id} className={style.policyWrapper}>
            <ClearIcon className={style.closeBtn} onClick={() => removePolicy(policy.id)} />
            <span className={style.mainLabel}>{t('teeTimeCategory.playerHCP')}</span>
            <span className={style.secondaryLabel}>{t('teeTimeCategory.allowedPlayerHCP')}</span>

            <div className={style.hcpInput}>
              <TextInputField
                prefix="hcp"
                type="number"
                inputProps={{ defaultValue: policy.playerHcp }}
                onChange={(value) => handlePlayerHCPChange(policy, value)}
              />
            </div>
          </div>
        ))}
      {policies
        .filter((policy) => policy.bookingWindow !== undefined)
        .map((policy) => (
          <div key={policy.id} className={style.policyWrapper}>
            <ClearIcon className={style.closeBtn} onClick={() => removePolicy(policy.id)} />
            <span className={style.mainLabel}>{t('teeTimeCategory.bookingWindow')}</span>
            <span className={style.secondaryLabel}>{t('teeTimeCategory.opensBeforeTeeTime')}</span>
            <BookingWindow
              configuration={policy?.bookingWindow}
              updatePolicy={(configuration) =>
                onUpdate(policy.id, { configuration }, isParticipant)
              }
            />
          </div>
        ))}
      <div className={style.newContainer}>
        <ButtonBasic
          text={t('teeTimeCategory.policy')}
          icon="plus"
          className={style.addPromotionButton}
          onClick={() => setShowCreateNew(true)}
        />
        <DropdownBox
          ref={dropdownRef}
          className={style.dropdown}
          dropdownOpen={showCreateNew}
          options={POLICY_TYPES.map((type) => {
            return {
              ...type,
              disabled: !!policies.filter((policy) => policy[type.name] !== undefined)?.length,
            }
          })}
          onOptionClick={addPolicy}
        />
      </div>

      <div className={style.clipLoader}>
        <PulseLoader showIf={loading} fillHeight />
      </div>
    </div>
  )
}

BookingPolicies.propTypes = {
  categoryId: PropTypes.string,
  isBookingCreator: PropTypes.bool.isRequired,
  policies: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default BookingPolicies
