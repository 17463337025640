import { useContext } from 'react'
import TimePeriodsContext from './context'

const useActivePeriod = () => {
  const [globalState, setGlobalState] = useContext(TimePeriodsContext)
  const { activePeriod } = globalState

  const setActivePeriod = (period) => {
    setGlobalState((state) => ({ ...state, activePeriod: period, isNewPeriod: false }))
  }

  return [activePeriod, setActivePeriod]
}

export default useActivePeriod
