import React, { useRef } from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'

const ToolTip = ({ text, useOverflowCheck, className, children }) => {
  const tooltipRef = useRef()

  const isOverflow = () => {
    return useOverflowCheck
      ? tooltipRef.current?.children[0]?.scrollWidth > tooltipRef.current?.children[0]?.clientWidth
      : true
  }

  return (
    <div
      ref={tooltipRef}
      title={isOverflow() ? text : null}
      className={cx(className, { [styles.tooltip]: !!useOverflowCheck })}
    >
      {children}
    </div>
  )
}

export default ToolTip
