import { RadioGroup, RadioGroupItem, Stepper } from '@sweetspot/scramble-ds'
import { Controller, ControllerRenderProps, useWatch } from 'react-hook-form'
import { DURATION_TIME } from '../../consts'
import { LastPaymentDurationProps } from '../types'
import { SettingsFormScema } from './validation'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'
import {
  calculateDaysDifferenceBetweenStartAndExpiryDate,
  formatBookingStartTime,
} from '../../utils'
import { getDayDisablingStatus } from './utils'
import { useEffect } from 'react'

export const LastPaymentDuration = ({ control, setValue, booking }: LastPaymentDurationProps) => {
  const { t } = useTranslation()

  const latestExpiryDateFormatted = formatBookingStartTime(
    booking?.booking?.start_time,
    booking?.course?.timezone
  )

  const lastPaymentCustomDays = useWatch({
    control,
    name: 'last_payment_duration_days',
  })

  const lastPaymentDuration = useWatch({
    control,
    name: 'last_payment_duration',
  })

  const lastPaymentCustomHours = useWatch({
    control,
    name: 'last_payment_duration_hours',
  })

  const firstPaymentCustomDays = useWatch({
    control,
    name: 'first_payment_duration_days',
  })

  const showPartialPayments = useWatch({
    control,
    name: 'partial_payments',
  })

  const incrementCustomDaysPayment = () => {
    if (isCustomDaysDisabled(lastPaymentCustomDays)) return
    if (lastPaymentCustomDays >= upperLimit) return
    setValue('last_payment_duration', DURATION_TIME.CUSTOM_DAYS)
    setValue('last_payment_duration_days', lastPaymentCustomDays + 1)
  }

  const decrementCustomDaysPayment = () => {
    if (isCustomDaysDisabled(lastPaymentCustomDays)) return
    if (lastPaymentCustomDays <= 3) return
    setValue('last_payment_duration', DURATION_TIME.CUSTOM_DAYS)
    setValue('last_payment_duration_days', lastPaymentCustomDays - 1)
  }

  const incrementCustomHoursPayment = () => {
    if (
      (daysDifference > 0 && lastPaymentCustomHours >= 23) ||
      (daysDifference === 0 && lastPaymentCustomHours >= hoursDifference)
    )
      return
    setValue('last_payment_duration', DURATION_TIME.HOURS)
    setValue('last_payment_duration_hours', lastPaymentCustomHours + 1)
  }

  const decrementCustomHoursPayment = () => {
    if (lastPaymentCustomHours <= 1) return
    setValue('last_payment_duration', DURATION_TIME.HOURS)
    setValue('last_payment_duration_hours', lastPaymentCustomHours - 1)
  }

  const handleStepperChange =
    (
      field: ControllerRenderProps<z.infer<typeof SettingsFormScema>, 'last_payment_duration_days'>,
      upperLimit: number
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (/^\d*$/.test(value)) {
        const numValue = Number(value)

        field.onChange(
          numValue === 0 ? '' : numValue > 0 && numValue <= upperLimit ? numValue : field.value
        )
      }
    }

  const { daysDifference: rawDaysDifference, hoursDifference } =
    calculateDaysDifferenceBetweenStartAndExpiryDate(
      booking?.booking?.start_time,
      booking?.course?.timezone
    )

  const isHourDisabled = (hour: number) => {
    if (daysDifference >= 1) return false

    return hoursDifference < hour
  }

  const isCustomDaysDisabled = (day: number) => {
    if (daysDifference === 0) return true
    if (day <= 2 && daysDifference < 3) return true

    return daysDifference < day
  }

  const daysDifference: number =
    rawDaysDifference - (showPartialPayments ? firstPaymentCustomDays : 0)

  const { isDayDisabled, isTwoDaysDisabled, upperLimit } = getDayDisablingStatus(daysDifference)

  useEffect(() => {
    if (isDayDisabled) setValue('last_payment_duration_hours', 1)

    if (isHourDisabled(lastPaymentCustomHours) && isDayDisabled) {
      setValue('last_payment_duration', DURATION_TIME.MAX_DAYS)
      setValue('last_payment_duration_days', 0)
    }
  }, [daysDifference])

  useEffect(() => {
    if (showPartialPayments) setValue('last_payment_duration_days', daysDifference)
  }, [firstPaymentCustomDays])

  useEffect(() => {
    if (
      (isDayDisabled && lastPaymentDuration === DURATION_TIME.DAY) ||
      (isCustomDaysDisabled(lastPaymentCustomDays) &&
        lastPaymentDuration === DURATION_TIME.CUSTOM_DAYS) ||
      (isTwoDaysDisabled && lastPaymentDuration === DURATION_TIME.TWO_DAYS)
    ) {
      setValue('last_payment_duration', DURATION_TIME.MAX_DAYS)
      setValue('last_payment_duration_days', 0)
    }
  }, [isDayDisabled, isTwoDaysDisabled, lastPaymentCustomDays, lastPaymentDuration])

  return (
    <Controller
      name="last_payment_duration"
      control={control}
      render={({ field }) => (
        <RadioGroup
          className="flex flex-row"
          onValueChange={(value) => {
            field.onChange(value)
            if (value === DURATION_TIME.MAX_DAYS) {
              setValue('last_payment_duration_days', 0)
            } else if (value === DURATION_TIME.DAY) {
              setValue('last_payment_duration_days', 1)
            } else if (value === DURATION_TIME.TWO_DAYS) {
              setValue('last_payment_duration_days', 2)
            } else if (value === DURATION_TIME.CUSTOM_DAYS) {
              setValue('last_payment_duration_days', 3)
            }
          }}
          value={field.value}
          defaultValue={field.value}
        >
          <div className="border-stroke-pale py-md px-sm h-full w-full items-start">
            <RadioGroupItem
              value={DURATION_TIME.MAX_DAYS}
              id={DURATION_TIME.MAX_DAYS}
              label={
                <p>
                  {t('paymentLinks.untilTeeTime')}:{' '}
                  <b className="text-[15px]">{latestExpiryDateFormatted}</b>
                </p>
              }
              isActive={field.value === DURATION_TIME.MAX_DAYS}
              className="gap-3 pl-4"
            />
            <div className="pb-md relative flex items-center">
              <RadioGroupItem
                value={DURATION_TIME.HOURS}
                id={`FINAL-${DURATION_TIME.HOURS}`}
                isActive={field.value === DURATION_TIME.HOURS}
                label={<p className="w-full pl-[115px]">{t('paymentLinks.hoursBeforeTeeTime')}</p>}
                className="gap-3 pr-2"
                disabled={isHourDisabled(lastPaymentCustomHours)}
              />
              <Controller
                name="last_payment_duration_hours"
                control={control}
                render={({ field: fieldStepper }) => {
                  return (
                    <Stepper
                      value={fieldStepper.value}
                      increment={incrementCustomHoursPayment}
                      decrement={decrementCustomHoursPayment}
                      className={`w-[110px] ${
                        isHourDisabled(lastPaymentCustomHours) && 'opacity-60'
                      } absolute left-10`}
                      readOnly={isHourDisabled(lastPaymentCustomHours)}
                      onChange={handleStepperChange(
                        fieldStepper,
                        daysDifference === 0 ? hoursDifference : 23
                      )}
                      onBlur={() => {
                        const value = Number(fieldStepper.value)
                        if (isHourDisabled(lastPaymentCustomHours)) return
                        if (value < 1) {
                          fieldStepper.onChange(1)
                        } else if (value > hoursDifference && daysDifference === 0) {
                          fieldStepper.onChange(hoursDifference)
                        } else if (value > 23 && daysDifference > 0) {
                          fieldStepper.onChange(23)
                        }
                      }}
                    />
                  )
                }}
              />
            </div>

            {rawDaysDifference !== 0 && (
              <>
                <RadioGroupItem
                  value={DURATION_TIME.DAY}
                  id={`FINAL-${DURATION_TIME.DAY}`}
                  label={
                    <p>
                      <b>{t('paymentLinks.24hours')}</b> {t('paymentLinks.beforeTeeTime')}
                    </p>
                  }
                  isActive={field.value === DURATION_TIME.DAY}
                  disabled={isDayDisabled}
                />
                <RadioGroupItem
                  value={DURATION_TIME.TWO_DAYS}
                  id={`FINAL-${DURATION_TIME.TWO_DAYS}`}
                  label={
                    <p>
                      <b>{t('paymentLinks.48hours')}</b> {t('paymentLinks.beforeTeeTime')}
                    </p>
                  }
                  isActive={field.value === DURATION_TIME.TWO_DAYS}
                  disabled={isTwoDaysDisabled}
                />
                <div className="pb-md relative flex items-center">
                  <RadioGroupItem
                    value={DURATION_TIME.CUSTOM_DAYS}
                    id={`FINAL-${DURATION_TIME.CUSTOM_DAYS}`}
                    isActive={field.value === DURATION_TIME.CUSTOM_DAYS}
                    label={
                      <p className="w-full pl-[115px]">{t('paymentLinks.daysBeforeTeeTime')}</p>
                    }
                    className="pr-2"
                    disabled={isCustomDaysDisabled(lastPaymentCustomDays)}
                  />
                  <Controller
                    name="last_payment_duration_days"
                    control={control}
                    render={({ field: fieldStepper }) => {
                      return (
                        <Stepper
                          value={fieldStepper.value <= 2 ? 3 : fieldStepper.value}
                          increment={incrementCustomDaysPayment}
                          decrement={decrementCustomDaysPayment}
                          className={`w-[110px] ${
                            isCustomDaysDisabled(lastPaymentCustomDays) && 'opacity-60'
                          } absolute left-10`}
                          readOnly={isCustomDaysDisabled(lastPaymentCustomDays)}
                          onChange={handleStepperChange(fieldStepper, upperLimit)}
                          onBlur={() => {
                            const value = Number(fieldStepper.value)
                            if (isCustomDaysDisabled(lastPaymentCustomDays)) return
                            if (value < 3) {
                              fieldStepper.onChange(3)
                            } else if (value > upperLimit) {
                              fieldStepper.onChange(upperLimit)
                            }
                          }}
                        />
                      )
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </RadioGroup>
      )}
    />
  )
}
