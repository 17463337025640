import { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import _isEqual from 'lodash/isEqual'
import cx from 'classnames'

import { ReactComponent as CaretIcon } from '@sweetspot/sweetspot-js/assets/svgs/caret.svg'
import FeedbackContainer from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/FeedbackContainer'

export default class DropdownSelect extends Component {
  state = {
    isOpen: false,
    searchString: '',
    selectedId: this.props.initialId,
    focusedIndex: 0,
  }

  resultsRefs = []

  componentDidUpdate(prevProps, prevState) {
    // empty input value on open, set it to selected option on close
    if (this.state.isOpen !== prevState.isOpen) {
      if (this.state.isOpen) {
        if (!this.props.readOnly) this._input.value = ''
      } else {
        this.setSelectedId(this.state.selectedId)
        this.setState({ focusedIndex: 0 })
      }
    }
    if (prevProps.initialId !== this.props.initialId) {
      this.setSelectedId(this.props.initialId)
    }
    if (!_isEqual(prevProps.values, this.props.values)) {
      this.setSelectedId(this.state.selectedId)
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  initialize = () => {
    this._input.onclick = this.toggleOpen
    this._arrow.onclick = this.toggleOpen
    this._input.oninput = this.handleChange
    this._input.setAttribute('spellcheck', false)

    // if has a selected option write it to the input
    if (this.state.selectedId !== null) {
      let selectedValue = this.props.values.find((value) => value.id === this.state.selectedId)
      if (selectedValue && !selectedValue.color) {
        let name = selectedValue.name
        if (this.props.singleLetter) name = name.charAt(0)
        this._input.value = name
      }
    }
  }

  toggleOpen = () => {
    if (this.props.disabled) return
    if (document.activeElement === this._input) return

    this.setState({ isOpen: !this.state.isOpen })
  }

  setSelectedId = (id) => {
    this.setState({ selectedId: id })

    const selectedValue = this.props.values.find((value) => value.id === id)
    if (selectedValue && !selectedValue.color) {
      let name = selectedValue.name
      if (this.props.singleLetter) name = name.charAt(0)
      this._input.value = name
    } else {
      this._input.value = ''
    }
  }

  getSelectedId = () => {
    return this.state.selectedId
  }

  handleChange = (event) => {
    this.setState({
      searchString: event.target.value,
    })
  }

  onSearch = (event) => {
    this.setState({
      searchString: event.target.value,
    })
    if (typeof this.props.onSearch === 'function') {
      this.props.onSearch(event.target.value.trim())
    }
  }

  handleSelect = (id) => {
    setTimeout(() => {
      this.setState({ selectedId: id, isOpen: false }, () => {
        let selectedValue = this.props.values.find((value) => value.id === this.state.selectedId)
        if (selectedValue && !selectedValue.color) {
          this._input.value = selectedValue.name
        }
      })
    }, 100)
    if (this.props.onSelect) this.props.onSelect(id)
  }

  handleBlur = () => {
    setTimeout(() => {
      this.setState({ isOpen: false })
    }, 150)
  }

  renderSubOption = (desc) => {
    const subOptionClassName = [style.desc]
    if (desc.isActive) subOptionClassName.push(style.active)

    return (
      <div className={subOptionClassName.join(' ')}>
        <h3>{desc.text}</h3>
      </div>
    )
  }

  renderList() {
    const { isOpen, searchString: search, focusedIndex } = this.state
    let { values, hideSelf, selectedId, hiddenValues, noOtherOption, dropdownAnchor } = this.props

    if (hideSelf) values = values.filter((value) => value.id !== selectedId)
    if (hiddenValues) values = values.filter((value) => !hiddenValues.includes(value.id))

    if (isOpen) {
      return (
        <div
          className={cx(style.listContainer, dropdownAnchor === 'top' && style.anchorTop)}
          ref={(container) => (this._listContainer = container)}
        >
          <div className={style.list}>
            {values
              .filter(({ name }) => !search || name.toLowerCase().includes(search.toLowerCase()))
              .map(({ id, name, desc, color }, index) => (
                <div
                  key={id}
                  ref={(ref) => (this.resultsRefs[index] = ref)}
                  className={cx(style.listItem, {
                    [style.focused]: focusedIndex === index,
                  })}
                  onClick={() => this.handleSelect(id)}
                >
                  {color ? <div>TEE TIME STATUS</div> : <span className={style.name}>{name}</span>}
                  {desc && this.renderSubOption(desc)}
                </div>
              ))}
            {!values.length && hideSelf && noOtherOption && (
              <div className={style.listItem}>
                <h3>{noOtherOption}</h3>
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  render() {
    const { isOpen } = this.state
    const {
      disabled,
      className,
      placeholder,
      width,
      largeText,
      hideArrow,
      readOnly,
      showBorder,
      error,
      containerClassName: cName,
      leftIcon,
      rightIcon,
      selectedId,
      values,
    } = this.props

    const containerClassName = [style.container]
    if (isOpen) containerClassName.push(style.open)
    if (largeText) containerClassName.push(style.largeText)
    if (hideArrow) containerClassName.push(style.hideArrow)
    if (showBorder) containerClassName.push(style.showBorder)
    if (disabled) containerClassName.push(style.disabled)
    if (cName) containerClassName.push(cName)
    if (leftIcon) containerClassName.push(style.offset)

    const inputClassName = []
    if (className) {
      className.split(' ').forEach((item) => {
        inputClassName.push(style[item])
      })
    }
    const containerWidth = `${width}px` ? width : '140px'

    if (leftIcon) inputClassName.push(style.offset)

    return (
      <div style={{ width: containerWidth }} className={containerClassName.join(' ')}>
        {leftIcon ? <div className={style.prepend}>{leftIcon}</div> : null}
        <input
          type="text"
          disabled={disabled}
          readOnly={!isOpen || readOnly}
          placeholder={placeholder}
          className={inputClassName.join(' ')}
          ref={(input) => (this._input = input)}
          value={values.find((i) => i.id === selectedId)?.name || ''}
          onChange={this.onSearch}
          onFocus={() => this.setState({ isOpen: true })}
          onBlur={this.handleBlur}
        />
        {rightIcon ? (
          rightIcon
        ) : (
          <CaretIcon className={inputClassName.join(' ')} ref={(icon) => (this._arrow = icon)} />
        )}
        <FeedbackContainer error={error} />
        {this.renderList()}
      </div>
    )
  }
}

DropdownSelect.propTypes = {
  values: PropTypes.array.isRequired,
  error: PropTypes.string,
  hiddenValues: PropTypes.array,
  initialId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  largeText: PropTypes.bool,
  hideArrow: PropTypes.bool,
  hideSelf: PropTypes.bool,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  singleLetter: PropTypes.bool,
  isOpen: PropTypes.bool,
  showBorder: PropTypes.bool,
  noOtherOption: PropTypes.string,
  dropdownAnchor: PropTypes.string,
  statusClassName: PropTypes.string,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
}

DropdownSelect.defaultProps = {
  showArrow: true,
  hiddenValues: [],
  error: '',
  dropdownAnchor: 'bottom',
  statusClassName: '',
}
