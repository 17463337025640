import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'

import style from './style.module.scss'
import Panel from '../Panel'

export default class PanelGroup extends Component {
  renderPanels() {
    const {
      teeTimes,
      maxTeeTimes,
      first,
      last,
      editMode,
      selectedTeeTimes,
      overrides,
      onClick,
      onDoubleClick,
      playerTypeFilter,
      activeFilters,
      filteredTeeTimes,
      course,
    } = this.props

    let panels = []

    teeTimes.forEach((teeTime) => panels.push(teeTime))

    return (
      <div>
        {panels.map((teeTime, key) => {
          const bottomPanel = key === maxTeeTimes - 1
          const isSelected = selectedTeeTimes.includes(teeTime)
          const filteredOut = activeFilters
            ? !filteredTeeTimes.find((filteredTeeTime) => filteredTeeTime.id === teeTime.id)
            : false
          let status
          let tempOverrides
          let latestOverride

          if (teeTime && teeTime.type !== 'empty') {
            tempOverrides = overrides.filter(
              (override) => teeTime.from >= override.start_date && teeTime.to <= override.end_date
            )
            latestOverride = _.orderBy(tempOverrides, ['updated_at'], ['desc'])[0]
            status = latestOverride ? latestOverride.status : null
          }
          return (
            <Panel
              teeTime={teeTime}
              maxTeeTimes={maxTeeTimes}
              first={first}
              last={last}
              top={key === 0}
              bottom={bottomPanel}
              topLeft={first && key === 0}
              topRight={last && key === 0}
              bottomLeft={first && bottomPanel}
              bottomRight={last && bottomPanel}
              editMode={editMode}
              selected={isSelected}
              filteredOut={filteredOut}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              status={status}
              panelGroupIndex={this.props.panelGroupIndex}
              playerTypeFilter={playerTypeFilter}
              key={key}
              course={course}
              disabled={!course?.is_use_dynamic_pricing && teeTime.price === null}
            />
          )
        })}
      </div>
    )
  }

  render() {
    const { first, last, hour } = this.props
    let classNames = [style.container]

    if (first) {
      classNames.push(style.first)
    } else if (last) {
      classNames.push(style.last)
    }

    return (
      <div className={classNames.join(' ')}>
        <div className={style.content}>
          <span className={style.header}>{hour > 9 ? hour : `0${hour}`}</span>
          {this.renderPanels()}
        </div>
      </div>
    )
  }
}

PanelGroup.propTypes = {
  hour: PropTypes.number.isRequired,
  teeTimes: PropTypes.array.isRequired,
  totalGroups: PropTypes.number.isRequired,
  maxTeeTimes: PropTypes.number.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  selectedTeeTimes: PropTypes.array,
  filteredTeeTimes: PropTypes.array,
  overrides: PropTypes.array,
  panelGroupIndex: PropTypes.number.isRequired,
  activeFilters: PropTypes.bool.isRequired,
  playerTypeFilter: PropTypes.array.isRequired,
  course: PropTypes.object.isRequired,
}
