import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import EmailListItem from './EmailListItem'

import style from './style.module.scss'

export class EmailList extends Component {
  render() {
    const { activeId, emails, toggleUpdate, toggleRemove, onChangeActive } = this.props

    return (
      <div className={style.listView}>
        <div className={[style.row, style.tableHeader].join(' ')}>
          <div className={style.date}>
            <Text textId="singleDate" />
          </div>
          <div className={style.period}>
            <Text textId="communication.timePeriod" />
          </div>
          <div className={style.title}>
            <Text textId="communication.subTitle" />
          </div>
          <div className={style.status}>Status</div>
          <div className={style.dateTime}>
            <Text textId="communication.sentDate" />
          </div>
          <div className={style.buttons} />
        </div>

        {emails.map((email) => (
          <EmailListItem
            key={email.id}
            email={email}
            activeId={activeId}
            onChangeActive={onChangeActive}
            toggleUpdate={() => toggleUpdate(activeId)}
            toggleRemove={() => toggleRemove(activeId)}
          />
        ))}
      </div>
    )
  }
}

EmailList.propTypes = {
  emails: PropTypes.array.isRequired,
  activeId: PropTypes.number.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  toggleUpdate: PropTypes.func.isRequired,
  toggleRemove: PropTypes.func.isRequired,
}

export default EmailList
