import { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import produce from 'immer'
import RadioGroup from '@sweetspot/sweetspot-js/common/components/FormElements/RadioGroup'

const OPTIONS = [
  {
    id: 1,
    value: 'excluded',
    label: 'words.excluded',
  },
  {
    id: 2,
    value: 'only',
    label: 'words.only',
  },
]

const PrimeTimeChecker = ({ rule, onSetRule, onSaveRule, disabled, voucherDisabled }) => {
  const { t } = useTranslation()

  const translatedOptions = useMemo(() => {
    return OPTIONS.map((option) => ({ ...option, label: t(option.label) }))
  }, [OPTIONS])

  const confValue = useMemo(() => {
    return rule?.configuration?.prime_time !== null
      ? rule.configuration.prime_time === true
        ? 'only'
        : 'excluded'
      : null
  }, [rule])

  const isNew = useMemo(() => {
    if (typeof rule.id === 'string' && rule.id.includes('new')) return true
    return false
  }, [rule.id])

  const updateValue = (newValue) => {
    let newRule
    if (newValue === 'excluded') {
      newRule = produce(rule, (draft) => {
        draft.configuration.prime_time = false
      })
    } else if (newValue === 'only') {
      newRule = produce(rule, (draft) => {
        draft.configuration.prime_time = true
      })
    }

    onSetRule(newRule)
    onSaveRule(newRule)
  }

  return (
    <div className={cx(styles.container)}>
      <RadioGroup
        value={confValue}
        options={translatedOptions}
        disabled={voucherDisabled || disabled || isNew}
        onChange={(value) => updateValue(value)}
        containerClassName={styles.radioContainer}
      />
    </div>
  )
}

PrimeTimeChecker.propTypes = {
  rule: PropTypes.object,
  onSetRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  disabled: PropTypes.bool,
}

PrimeTimeChecker.defaultProps = {}

export default PrimeTimeChecker
