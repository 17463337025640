import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { ICreatAdyenAccountProps, IEditAccountHolderProps, IUpdateSweepProps } from '../types'

// construct url for request
const getBaseURL = (clubId: number, dest: string) => {
  return `${getApiPlatformBaseUrl()}/club/${clubId}/adyen/${dest}`
}

export const createAdyenAccount = ({ clubId, payload }: ICreatAdyenAccountProps) => {
  const url = getBaseURL(clubId, 'onboarding')
  return request(url, { method: 'PUT', body: payload })
}

export const getAccountHolder = (clubId: number) => {
  const url = getBaseURL(clubId, 'account-holder')
  return request(url, { method: 'GET' })
}

export const getEditAccountHolderLink = ({ clubId, payload }: IEditAccountHolderProps) => {
  const url = getBaseURL(clubId, 'onboarding/link')
  return request(url, { method: 'PUT', body: payload })
}

export const getLegalEntity = (clubId: number) => {
  const url = getBaseURL(clubId, 'legal-entity')
  return request(url, { method: 'GET' })
}

export const getBalanceAccount = (clubId: number) => {
  const url = getBaseURL(clubId, 'balance-accounts')
  return request(url, { method: 'GET' })
}

export const forceSweepsVerification = (clubId: number) => {
  const url = getBaseURL(clubId, 'create-sweeps')
  const payload = {
    force_verification_status: true,
  }
  return request(url, { method: 'PUT', body: payload })
}

export const updateSweep = ({
  clubId,
  balance_account_id,
  schedule_type = 'weekly',
  target_amount = 0,
  trigger_amount = 0,
  currency = null,
}: IUpdateSweepProps) => {
  const url = getBaseURL(clubId, 'update-sweep')
  const payload = {
    balance_account_id,
    schedule_type,
    target_amount,
    trigger_amount,
    currency,
  }

  return request(url, { method: 'PUT', body: payload })
}
