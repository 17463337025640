export default class DomHelpers {
  static didClickInside(refElement, event) {
    if (!refElement) return false
    try {
      let boundary = refElement.getBoundingClientRect()
      return (
        event.clientX >= boundary.x &&
        event.clientX <= boundary.x + boundary.width &&
        event.clientY >= boundary.y &&
        event.clientY <= boundary.y + boundary.height
      )
    } catch (e) {
      console.log(e)
      return false
    }
  }

  static didClickInsideArray(array, event) {
    for (let i = 0; i < array.length; i++) {
      let element = array[i]
      if (this.didClickInside(element, event)) return true
    }
    return false
  }

  static emptyInputs() {
    for (let argument of arguments) {
      argument.value = ''
    }
  }

  static getScrollbarWidth() {
    let scrollDiv = document.createElement('div')
    scrollDiv.style.cssText =
      'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px'
    document.body.appendChild(scrollDiv)
    let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
  }

  static onEnter(inputs, func) {
    if (!Array.isArray(inputs)) {
      inputs = [inputs]
    }

    inputs.forEach((input) => {
      input.addEventListener('keyup', (event) => {
        event.preventDefault()
        if (event.key === 'Enter') func()
      })
    })
  }

  static clearOnEnter(inputs, func) {
    if (!Array.isArray(inputs)) {
      inputs = [inputs]
    }

    inputs.forEach((input) => {
      input.removeEventListener('keyup', func)
    })
  }
}
