import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'

export default class PartnershipCell extends Component {
  render() {
    const partnerships = this.props.partnershipList
    if (!partnerships || partnerships.length === 0) return '-'

    let containerClassNames = [style.container]
    return (
      <div className={containerClassNames.join(' ')} style={this.props.style}>
        {partnerships && (
          <div className={style.inline}>
            <span className={style.name}>{partnerships[0].name}</span>
            <span className={style.status}>&nbsp;|&nbsp;</span>
            <span className={style.status}>{partnerships[0].golfClub}</span>
            {partnerships.length > 1 && (
              <span className={style.infoHover}>
                <InfoHover toolTipIcon={`+${partnerships.length - 1}`}>
                  <PartnershipList list={partnerships} />
                </InfoHover>
              </span>
            )}
          </div>
        )}
        {!partnerships && <div>{'-'}</div>}
      </div>
    )
  }
}

PartnershipCell.propTypes = {
  style: PropTypes.object,
  partnershipList: PropTypes.array,
}

const PartnershipList = (props) => {
  return (
    <div className={style.partnershipList}>
      {props.list &&
        props.list.map((partnership) => {
          return (
            <div key={partnership.id} className={style.partnershipRow}>
              <span className={style.name}>{partnership.name}</span>&nbsp;|&nbsp;
              <span className={style.club}>{partnership.golfClub}</span>
            </div>
          )
        })}
    </div>
  )
}

PartnershipList.propTypes = {
  list: PropTypes.array,
}
