import useVoucher from '@sweetspot/sweetspot-js/features/vouchers/hooks/useVoucher'
import cx from 'classnames'
import type { Voucher } from '../../types'
import VoucherRulesWrapper from '../VoucherRulesWrapper'
import styles from './styles.module.scss'

interface RulesTableProps {
  voucher: Voucher
  selectedGolfClubId: number
}

const RulesTable: React.FC<RulesTableProps> = ({ voucher, selectedGolfClubId }) => {
  const { voucher: voucherData } = useVoucher(voucher.promotion.id, selectedGolfClubId)

  return (
    <div className={cx(styles.container)}>
      <VoucherRulesWrapper
        promotion={voucherData}
        golfClubID={selectedGolfClubId}
        disabled={false}
      />
    </div>
  )
}

export default RulesTable
