import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import style from './style.module.scss'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import CreateGolfClub from './components/CreateGolfClub'
import ModifyGolfClub from './components/ModifyGolfClub'
import DeleteGolfClub from './components/DeleteGolfClub'
import CreateGolfCourse from './components/CreateGolfCourse'
import ModifyGolfCourse from './components/ModifyGolfCourse'
import DeleteGolfCourse from './components/DeleteGolfCourse'
import CourseGuide from './components/CourseGuide'
import CreatePartner from './components/CreatePartner'
import ModifyPartner from './components/ModifyPartner'
import RemovePartner from './components/RemovePartner'
import HomeClubSettings from './components/HomeClubSettings'
import WebBookings from './components/WebBookings'
import PlayRights from './components/PlayRights'
import PartnerAdmins from './components/PartnerAdmins'
import PartnerIntegrations from './components/PartnerIntegrations'
import ClubAdmins from './components/ClubAdmins'
import GolfCarts from './components/GolfCarts'
import TeeTimeCategory from './components/TeeTimeCategory'
import Pricing from '@sweetspot/club-portal-legacy/pages/Pricing'
import Placeholder from './components/Placeholder'
import NotFound from '@sweetspot/club-portal-legacy/pages/NotFound'
import PromotionsManager from '@sweetspot/sweetspot-js/features/promotions/containers/PromotionsManager'
import ManageSpaces from './components/ManageSpaces'
import QTIntegration from './components/QTIntegration'
import CreateSpace from './components/CreateSpace'
import ModifySpace from './components/ModifySpace'
import DeleteSpace from './components/DeleteSpace'
import Bays from './components/Bays'
import ManageRange from './components/ManageRange'
import BallPricing from './components/BallPricing'
import BallDispensers from './components/BallDispensers'
import ClubUsers from './components/ClubUsers'
import Cooperations from './components/Cooperations'
import { BillingSettings, Payouts } from './components/Billing'

import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { AccessControlledRoute } from '@sweetspot/club-portal-legacy/components/AccessControlledRoute'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'

function Settings() {
  const golfClub = useSelector((state) => state.golfClub)
  const isRangeBayEnabled = useFlag(FlagNames.RangeBays)

  const renderContent = () => {
    return (
      <div className={style.settingComponentContainer}>
        <Switch>
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_CLUB.MODIFY}
            exact
            path="/settings/edit-club"
            component={ModifyGolfClub}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_COURSE.CREATE}
            exact
            path="/settings/create-course"
            component={CreateGolfCourse}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_COURSE.MODIFY}
            exact
            path="/settings/edit-course"
            component={ModifyGolfCourse}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_COURSE.DELETE}
            exact
            path="/settings/delete-course"
            component={DeleteGolfCourse}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.SPACES.CREATE}
            exact
            path="/settings/spaces"
            component={ManageSpaces}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.SPACES.CREATE}
            exact
            path="/settings/create-space"
            component={CreateSpace}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.SPACES.MODIFY}
            exact
            path="/settings/edit-space"
            component={ModifySpace}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.SPACES.DELETE}
            exact
            path="/settings/delete-space"
            component={DeleteSpace}
          />
          {!!isRangeBayEnabled && (
            <AccessControlledRoute
              accessTableKey={ACCESS_KEYS.SETTINGS.DRIVING_RANGE.MANAGE_RANGE}
              exact
              path="/settings/manage-range"
              component={ManageRange}
            />
          )}
          {!!isRangeBayEnabled && (
            <AccessControlledRoute
              accessTableKey={ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BAYS}
              exact
              path="/settings/bays"
              component={Bays}
            />
          )}
          {!!isRangeBayEnabled && (
            <AccessControlledRoute
              accessTableKey={ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_DISPENSERS}
              exact
              path="/settings/ball-dispensers"
              component={BallDispensers}
            />
          )}
          {!!isRangeBayEnabled && (
            <AccessControlledRoute
              accessTableKey={ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_PRICING}
              exact
              path="/settings/ball-pricing"
              component={BallPricing}
            />
          )}
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.TAGS.CREATE}
            exact
            path="/settings/create-tag"
            component={CreatePartner}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.TAGS.MODIFY}
            exact
            path="/settings/edit-tag"
            component={ModifyPartner}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.TAGS.DELETE}
            exact
            path="/settings/delete-tag"
            component={RemovePartner}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.BILLING.SETTINGS}
            exact
            path="/settings/billing-settings"
            component={BillingSettings}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.BILLING.PAYOUTS}
            exact
            path="/settings/payouts"
            component={Payouts}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.HOMECLUB}
            exact
            path="/settings/home-club"
            component={HomeClubSettings}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.PLAY_RIGHTS}
            exact
            path="/settings/play-rights"
            component={PlayRights}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.COOPERATIONS}
            exact
            path="/settings/cooperations"
            component={Cooperations}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.COURSE_GUIDES}
            exact
            path="/settings/course-guides"
            component={CourseGuide}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.QT_INTEGRATION}
            exact
            path="/settings/qt-integration"
            component={QTIntegration}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.WEB_BOOKING}
            exact
            path="/settings/web-booking"
            component={WebBookings}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.PROMOTIONS}
            exact
            path="/settings/promotions"
          >
            <PromotionsManager context="club" clubId={golfClub.selectedId} withTitle={true} />
          </AccessControlledRoute>
          <AccessControlledRoute
            exact
            path={['/settings/tee-time-category', '/settings/tee-time-category/:id']}
            component={TeeTimeCategory}
            accessTableKey={ACCESS_KEYS.SETTINGS.TEE_TIME_CATEGORIES}
          />
          <AccessControlledRoute
            exact
            path="/settings/create-club"
            component={CreateGolfClub}
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_CLUB.CREATE}
          />
          <AccessControlledRoute
            exact
            path="/settings/delete-club"
            component={DeleteGolfClub}
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_CLUB.DELETE}
          />
          <AccessControlledRoute
            exact
            path={['/settings/partner-admins', '/settings/partner-admins/:id']}
            component={PartnerAdmins}
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_CLUB.PARTNER_ADMINS}
          />
          <AccessControlledRoute
            exact
            path="/settings/partner-integrations"
            component={PartnerIntegrations}
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_CLUB.PARTNER_INTEGRATIONS}
          />
          <AccessControlledRoute
            exact
            path={['/settings/sa/club-users', '/settings/sa/club-users/:id']}
            component={ClubAdmins}
            accessTableKey={ACCESS_KEYS.SETTINGS.USERS.CLUB_ADMINS}
          />
          <AccessControlledRoute
            exact
            path={['/settings/golf-carts', '/settings/golf-carts/:id']}
            component={GolfCarts}
            accessTableKey={ACCESS_KEYS.SETTINGS.GOLF_CARTS}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.PRICING}
            exact
            path="/settings/pricing"
            component={Pricing}
          />
          <AccessControlledRoute
            accessTableKey={ACCESS_KEYS.SETTINGS.CLUB_USERS.READ}
            exact
            path={['/settings/club-users', '/settings/club-users/invite']}
            component={ClubUsers}
          />
          <Route exact path="/settings" component={Placeholder} />
          <Route path="/settings/*" component={NotFound} />
        </Switch>
      </div>
    )
  }

  return (
    <Container>
      <div className={style.content}>{renderContent()}</div>
    </Container>
  )
}

export default Settings

Settings.propTypes = {
  golfClub: PropTypes.object.isRequired,
}
