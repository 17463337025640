import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import RadioGroup from '@sweetspot/sweetspot-js/common/components/FormElements/RadioGroup'
import produce from 'immer'

const OPTIONS = [
  {
    id: 1,
    value: 'excluded',
    label: 'words.excluded',
  },
  {
    id: 2,
    value: 'only',
    label: 'words.only',
  },
]

const PrimeTimeRule = ({ rule, onSetRule, onSaveRule, disabled }) => {
  const { t } = useTranslation()

  const translatedOptions = useMemo(() => {
    return OPTIONS.map((option) => ({ ...option, label: t(option.label) }))
  }, [OPTIONS])

  return (
    <div className={cx(styles.container)}>
      <RadioGroup
        containerClassName={styles.radioGroup}
        value={rule?.configuration?.option}
        onChange={(option) => {
          const updatedRule = produce(rule, (draft) => {
            draft.configuration.option = option
          })
          onSetRule(updatedRule)
          onSaveRule(updatedRule)
        }}
        //error={getError()}
        disabled={disabled}
        options={translatedOptions}
      />
    </div>
  )
}

PrimeTimeRule.propTypes = {
  rule: PropTypes.object,
  onSetRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  disabled: PropTypes.bool,
}

PrimeTimeRule.defaultProps = {}

export default PrimeTimeRule
