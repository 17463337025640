import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import MainCell from '../MainCell'
import style from './style.module.scss'

export default class StatusCell extends Component {
  render() {
    const { status } = this.props
    let statusText
    if (!status.text) statusText = status ? '.active' : '.inActive'
    else statusText = status.text

    return (
      <div
        style={this.props.style}
        className={classnames(style.container, {
          [style.active]: status.state === 'active', // Green dot
          [style.inActive]: status.state === 'inActive', // Red dot
          [style.neutral]: status.state === 'neutral', // Background dot
          [style.bordered]: status.state === 'bordered', // Border + background fill
          [style.new]: status.state === 'new', // Blue dot
          [style.hide]: status === null,
        })}
      >
        {(status.state === 'active' || status.state === 'inActive' || status.state === 'new') && (
          <span>&bull;</span>
        )}
        <MainCell value={statusText} />
      </div>
    )
  }
}

StatusCell.propTypes = {
  style: PropTypes.object,
  status: PropTypes.shape({
    text: PropTypes.string,
    state: PropTypes.string, // active || inActive || neutral || bordered || new
  }),
}
