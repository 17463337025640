import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import DarkBlueSubtitle from '../../DarkBlueSubtitle'
import ValueText from '../../ValueText'
import { BOOKING_SOURCE_OPTIONS } from '@sweetspot/sweetspot-js/features/promotions/constants/rules'

const BookingSource = ({ rule }) => {
  const { t } = useTranslation()

  const config = rule?.configuration?.sources

  return (
    <div className={cx(styles.container)}>
      <DarkBlueSubtitle>{t('sentences.bookingSource')}</DarkBlueSubtitle>
      {config?.length ? (
        config.map((source) => (
          <ValueText key={source}>
            {t(BOOKING_SOURCE_OPTIONS.find((x) => x.value === source).label)}
          </ValueText>
        ))
      ) : (
        <ValueText>{t('sentences.noBookingSourceAllowed')}</ValueText>
      )}
    </div>
  )
}

BookingSource.propTypes = {
  rule: PropTypes.object,
}

BookingSource.defaultProps = {}

export default BookingSource
