type FormatOptions = {
  locale?: string
  currencyCode?: string | null
  useGrouping?: boolean
}

/**
 * Formats a number to a specific locale and appends a currency symbol.
 *
 * @param value The number to be formatted.
 * @param options Formatting options including locale, currencySymbol, and useGrouping.
 * @returns Formatted string.
 */
export function formatCurrency(value: number, options: FormatOptions = {}): string {
  const { locale = 'sv-SE', currencyCode = 'kr', useGrouping = true } = options

  const formattedNumber = new Intl.NumberFormat(locale, { useGrouping }).format(value)

  return `${formattedNumber} ${currencyCode}`
}
