import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

/**
 * Controlled component
 */
const StepsProgress = ({
  currentStep,
  steps,
  editing,
  goToStep,
  currentEntity,
  errors,
  globalErrors,
  type,
  disabled,
}) => {
  const length = steps.length
  const isCurrentStep = (step) => step.stepNumber === currentStep
  const isCompletedStep = (step) => step.stepNumber < currentStep || editing
  const isFutureStep = (step) => step.stepNumber > currentStep && !editing
  const isLastStep = (step) => step.stepNumber === length

  const onGoToStep = (step) => {
    if (disabled) return
    if (currentEntity && currentEntity.state && goToStep) {
      goToStep(step)
    }
  }

  const hasErrors = (step) => {
    if (type === 'partnership') {
      if (step === 2) {
        if (globalErrors.includes('promotions-required')) return true
        if (globalErrors.includes('invalid-rules-for-partnership')) return true
      }
      return false
    }
    if (type === 'membership') {
      if (step === 2) {
        if (globalErrors.includes('promotions-required')) return true
        if (
          Object.keys(errors).filter((key) => key.includes('promotions.') && errors[key].length > 0)
            .length > 0
        )
          return true
      }
      if (step === 3) {
        if (globalErrors.includes('fees-required')) return true
        if (
          Object.keys(errors).filter((key) => key.includes('fees.') && errors[key].length > 0)
            .length > 0
        )
          return true
      }
    }

    return false
  }

  return (
    <div className={styles.container}>
      {steps.map((step) => {
        const isCurrent = isCurrentStep(step)
        const isCompleted = isCompletedStep(step)
        const isFuture = isFutureStep(step)
        const isLast = isLastStep(step)
        const hasError = hasErrors(step.stepNumber)

        return (
          <div key={step.label || step.stepNumber} className={cx(styles.stepContainer)}>
            {/* Line */}
            <div
              className={cx(styles.stepLine, {
                [styles.stepLineCompleted]: isCompleted,
                [styles.stepLineLast]: isLast,
              })}
            ></div>

            <div className={cx(styles.circleAndLabelContainer)}>
              {/* Circle */}
              <div
                className={cx(
                  styles.stepCircle,
                  {
                    [styles.stepCircleCompleted]: isCompleted,
                    [styles.stepCircleCurrent]: isCurrent,
                    [styles.stepCircleFuture]: isFuture,
                    [styles.stepCircleError]: hasError,
                  },
                  {
                    [styles.pointerOnHover]: !!goToStep && !disabled,
                  },
                  {
                    [styles.pointerNotAllowed]: !currentEntity || !currentEntity.state,
                  }
                )}
                onClick={() => onGoToStep(step.stepNumber)}
              >
                {/* Number */}
                <p
                  className={cx(styles.stepNumber, {
                    [styles.stepNumberCompleted]: isCompleted,
                    [styles.stepNumberCurrent]: isCurrent,
                    [styles.stepNumberFuture]: isFuture,
                    [styles.stepNumberError]: hasError,
                  })}
                >
                  {step.stepNumber || ''}
                </p>
              </div>

              {/* Label */}
              <p
                className={cx(styles.label, {
                  [styles.labelCompleted]: isCompleted,
                  [styles.labelCurrent]: isCurrent,
                  [styles.labelFuture]: isFuture,
                })}
              >
                {step.label || ''}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

StepsProgress.propTypes = {
  currentEntity: PropTypes.object,
  currentStep: PropTypes.number,
  steps: PropTypes.array.isRequired,
  editing: PropTypes.bool,
  goToStep: PropTypes.func,
  errors: PropTypes.object,
  globalErrors: PropTypes.array,
  type: PropTypes.oneOf(['membership', 'partnership']),
  disabled: PropTypes.bool,
}
StepsProgress.defaultProps = {
  currentEntity: null,
  currentStep: 1,
  steps: [],
  editing: false,
  goToStep: undefined,
  errors: '',
  globalErrors: [],
  type: 'membership',
  disabled: false,
}

export default StepsProgress
