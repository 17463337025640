import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import moment from 'moment'

const SLOT_HEIGHT = 88
const SLOT_WIDTH = 140
const HEADER_HEIGHT = 70

const SlotsCalendar = ({ className, spaces, teeTimesPerSpace, currentVenue, render }) => {
  const [horizontalTimelinePosition, setHorizontalTimelinePosition] = useState(null)

  const earliestTeeTime = useMemo(() => {
    const allTimes = Object.keys(teeTimesPerSpace)
      .map((key) => teeTimesPerSpace[key])
      .flat()

    if (allTimes?.length) {
      return allTimes.sort((a, b) => a.from_unix - b.from_unix)[0]
    } else {
      return null
    }
  }, [teeTimesPerSpace])

  const latestTeeTime = useMemo(() => {
    const allTimes = Object.keys(teeTimesPerSpace)
      .map((key) => teeTimesPerSpace[key])
      .flat()

    if (allTimes?.length) {
      return allTimes.sort((a, b) => b.from_unix - a.from_unix)[0]
    } else {
      return null
    }
  }, [teeTimesPerSpace])

  const teeTimesOfFirstSpace = useMemo(() => {
    const firstKey = spaces?.[0]?.id
    return teeTimesPerSpace[firstKey]
  }, [teeTimesPerSpace, spaces])

  const intervalMinutes = useMemo(() => {
    if (earliestTeeTime) {
      const { from, to } = earliestTeeTime
      return Math.floor(Math.abs(new Date(from) - new Date(to)) / 1000 / 60)
    }
    return null
  }, [earliestTeeTime])

  const slotsPerHour = useMemo(() => {
    if (intervalMinutes) return 60 / intervalMinutes
    return 1
  }, [intervalMinutes])

  const venueTimezone = useMemo(() => {
    return currentVenue?.timezone
  }, [currentVenue])

  const numberOfHoursToShow = useMemo(() => {
    if (earliestTeeTime && latestTeeTime && venueTimezone) {
      const from = moment.unix(earliestTeeTime.from_unix)
      const to = moment.unix(latestTeeTime.to_unix)
      const duration = moment.duration(to.diff(from))
      return duration.asHours()
    }
    return null
  }, [earliestTeeTime, latestTeeTime, venueTimezone])

  const updateHorizontalTimeline = useCallback(() => {
    if (teeTimesOfFirstSpace && earliestTeeTime && latestTeeTime && intervalMinutes) {
      let topPosition = HEADER_HEIGHT
      let lastAdded = false

      const now = moment()
      const earliestTeeTimeDate = moment.unix(earliestTeeTime.from_unix)
      const latestTeeTimeDate = moment.unix(latestTeeTime.to_unix)

      if (
        now.format('YYYY-MM-DD') !== earliestTeeTimeDate.format('YYYY-MM-DD') ||
        now.isBefore(earliestTeeTimeDate) ||
        now.isAfter(latestTeeTimeDate)
      ) {
        setHorizontalTimelinePosition(null)
        return
      }

      teeTimesOfFirstSpace.forEach((teeTime) => {
        if (teeTime.to_unix <= now.unix()) topPosition += SLOT_HEIGHT
        if (teeTime.to_unix > now.unix() && !lastAdded) {
          const heightPerMinute = SLOT_HEIGHT / intervalMinutes

          const diff = moment.duration(moment.unix(teeTime.to_unix).diff(now)).asMinutes()
          topPosition += (intervalMinutes - diff) * heightPerMinute
          lastAdded = true
        }
      })

      setHorizontalTimelinePosition(topPosition)
    }

    return
  }, [teeTimesOfFirstSpace, earliestTeeTime, latestTeeTime, intervalMinutes])

  useEffect(() => {
    updateHorizontalTimeline()

    const interval = setInterval(() => updateHorizontalTimeline(), 60000)
    return () => {
      clearInterval(interval)
    }
  }, [updateHorizontalTimeline])

  const teeTimeIsFullHour = (teeTime) => {
    return moment.unix(teeTime.from_unix).minutes() === 0
  }

  const getTeeTimeFromHourString = (teeTime) => {
    return moment.unix(teeTime.from_unix).tz(venueTimezone).format('HH:mm')
  }

  if (
    !spaces ||
    !teeTimesOfFirstSpace ||
    !numberOfHoursToShow ||
    !slotsPerHour ||
    !teeTimesPerSpace
  ) {
    return null
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.timelineColumn)} style={{ marginTop: HEADER_HEIGHT }}>
        {teeTimesOfFirstSpace &&
          numberOfHoursToShow &&
          teeTimesOfFirstSpace.map((teeTime) => (
            <div
              key={teeTime.id}
              className={cx(styles.timelineSlot)}
              style={{ height: SLOT_HEIGHT }}
            >
              {(teeTimeIsFullHour(teeTime) ||
                numberOfHoursToShow < 1 ||
                intervalMinutes === 60) && (
                <p className={cx(styles.hourString)}>{getTeeTimeFromHourString(teeTime)}</p>
              )}
            </div>
          ))}
      </div>
      {render(SLOT_WIDTH, SLOT_HEIGHT, slotsPerHour, intervalMinutes, earliestTeeTime)}

      {horizontalTimelinePosition && (
        <div
          className={cx(styles.horizontalTimeline)}
          style={{ width: spaces?.length * SLOT_WIDTH, top: horizontalTimelinePosition }}
        >
          <div className={cx(styles.circle)}></div>
        </div>
      )}
    </div>
  )
}

SlotsCalendar.propTypes = {
  className: PropTypes.string,
  spaces: PropTypes.array,
  teeTimesPerSpace: PropTypes.object,
  currentVenue: PropTypes.object,
  render: PropTypes.func,
}

SlotsCalendar.defaultProps = {
  className: '',
  spaces: [],
  teeTimesPerSpace: {},
  currentVenue: {},
  render: () => {},
}

export default SlotsCalendar
