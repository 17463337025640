import Header from './Header'
import CreateRangeSideBar from './CreateRangeSideBar/CreateRangeSideBar'
import { ManageRangeProvider } from './ManageRangeProvider'
import FilterToolbar from './FilterToolbar/FilterToolbar'
import RangeTabsContainer from './RangeTabsContainer/RangeTabsContainer'

const ManageRange = () => {
  return (
    <ManageRangeProvider>
      <CreateRangeSideBar />
      <div className="flex h-full flex-col">
        <Header />
        <FilterToolbar />
        <div className={'flex flex-1 items-center justify-center'}>
          <RangeTabsContainer />
        </div>
      </div>
    </ManageRangeProvider>
  )
}

export default ManageRange
