import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'
import _ from 'lodash'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import GolfCourseSelect from '@sweetspot/club-portal-legacy/components/GolfCourseSelect'

import { selectGolfCourse } from '@sweetspot/club-portal-legacy/store/actions'

import style from '../style.module.scss'

class Partner extends Component {
  constructor() {
    super()

    this.state = {
      iframeUrl: '',
    }

    this.getIFrameUrl = this.getIFrameUrl.bind(this)
  }

  componentDidMount() {
    this.getIFrameUrl()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.golfCourses, this.props.golfCourses)) {
      this.getIFrameUrl()
    }
  }

  getIFrameUrl() {
    const {
      golfCourses: { list: courseList, selectedId },
    } = this.props
    const { REACT_APP_METABASE_SITE_URL, REACT_APP_METABASE_SECRET_KEY } = process.env
    const { uuid } = courseList.find((course) => course.id === selectedId) || {}

    const payload = {
      resource: { dashboard: 33 },
      params: {
        course: uuid,
      },
      exp: Math.round(Date.now() / 1000) + 600,
    }

    const token = jwt.sign(payload, REACT_APP_METABASE_SECRET_KEY)

    const iframeUrl = `${REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=true`

    this.setState({ iframeUrl })
  }

  renderGolfCourseSelect() {
    const {
      golfCourses: { list: courseList },
      selectGolfCourse,
      currentCourse,
    } = this.props

    return (
      <GolfCourseSelect
        courses={courseList}
        selectedCourse={currentCourse}
        onSelectCourse={selectGolfCourse}
      />
    )
  }

  render() {
    const { iframeUrl } = this.state

    return (
      <Container>
        <div className={style.container}>
          <div className={style.header}>{this.renderGolfCourseSelect()}</div>
          <div className={style.content}>
            <iframe src={iframeUrl} title="Partner" frameBorder="0" width="100%" height="100%" />
          </div>
        </div>
      </Container>
    )
  }
}

Partner.propTypes = {
  token: PropTypes.string.isRequired,
  golfCourses: PropTypes.object.isRequired,
  selectGolfCourse: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    golfCourses: state.golfCourse,
    currentCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectGolfCourse: (id) => dispatch(selectGolfCourse(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Partner)
