import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import PriceHelpers from '@sweetspot/club-portal-legacy/helpers/PriceHelpers'
import { Chart, registerables } from 'chart.js'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import style from './style.module.scss'

Chart.register(...registerables)

// Default chart options
const chartOptions = {
  legend: {
    labels: {
      boxWidth: 7,
      usePointStyle: true,
      padding: 30,
    },
    display: true,
    position: 'bottom',
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
}

const PriceChart = ({ isWeekend, priceModules, chartData }) => {
  const [chartState, setChartState] = useState({
    labels: [],
    price: [],
    minPrice: [],
    basePrice: [],
    demandPrice: [],
    supplyPrice: [],
  })

  useEffect(() => {
    // Parse and organize data for chart rendering
    const renderChartData1 = () => {
      let labels = []
      let price = []

      // Initialize price arrays
      let minPrice = []
      let basePrice = []
      let demandPrice = []
      let supplyPrice = []

      chartData.forEach((item) => {
        if (item.start_time.getMinutes() === 0) {
          const label = DateHelpers.toTimeString(item.start_time)
          if (labels.includes(label)) {
            return
          }
          labels.push(label)
          price.push(item.price)

          minPrice.push(
            PriceHelpers.getPriceChartModuleValue('minPrice', 'min', isWeekend, priceModules)
          )
          basePrice.push(
            PriceHelpers.getPriceChartModuleValue('basePrice', 'price', isWeekend, priceModules)
          )
          supplyPrice.push(
            PriceHelpers.getPriceChartModuleValue(
              'demandDaily',
              'demand_daily',
              isWeekend,
              priceModules
            )
          )
          demandPrice.push(
            PriceHelpers.getPriceChartModuleValue(
              'demandHistorical',
              'demand_historical',
              isWeekend,
              priceModules
            )
          )
        }
      })

      setChartState({
        labels,
        price,
        minPrice,
        basePrice,
        demandPrice,
        supplyPrice,
      })
    }

    renderChartData1()
  }, [chartData, isWeekend, priceModules])

  const chartDataStructure = useMemo(
    () => ({
      labels: chartState.labels,
      datasets: PriceHelpers.generatePriceChartDataset(chartState),
    }),
    [chartState]
  )

  // If there's no data, render an empty div
  if (!chartState.price.length) return <div />

  return (
    <div className={style.container}>
      <Line data={chartDataStructure} options={chartOptions} />
    </div>
  )
}

PriceChart.propTypes = {
  isWeekend: PropTypes.bool,
  chartData: PropTypes.array.isRequired,
  priceModules: PropTypes.array.isRequired,
}

export default PriceChart
