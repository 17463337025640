import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import SingleAction from '../SingleAction'

const ActionsWrapper = ({ promotion, disabled }) => {
  const { t } = useTranslation()

  const [actions, setActions] = useState(promotion?.actions || [])

  const addNewAction = () => {
    setActions((prev) => [
      ...prev,
      {
        id: 'new-' + crypto.randomUUID(),
        name: '',
        type: null,
        configuration: {
          filter: [],
        },
      },
    ])
  }

  const removeAction = (action) => {
    setActions((prev) => {
      return prev.filter((item) => item.id !== action.id)
    })
  }

  return (
    <div className={cx(styles.container)}>
      {actions?.length <= 0 && <h2 className={cx(styles.title)}>{t('sentences.noActions')}</h2>}

      {actions.map((action, index) => (
        <SingleAction
          index={index}
          key={action.id}
          action={action}
          promotionId={promotion.id}
          onRemoveAction={() => removeAction(action)}
          disabled={disabled}
        />
      ))}

      <ButtonBasic
        text={t('words.action')}
        icon="plus"
        className={cx(styles.addAction)}
        onClick={addNewAction}
        disabled={disabled}
      />
    </div>
  )
}

ActionsWrapper.propTypes = {
  promotion: PropTypes.shape({
    actions: PropTypes.array.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disabled: PropTypes.bool,
}

ActionsWrapper.defaultProps = {}

export default ActionsWrapper
