import { useTranslation } from 'react-i18next'
import { Tag } from '@sweetspot/scramble-ds'
import {
  calculateCreatedDate,
  calculateDuration,
  calculateTimeLeft,
  calculateTotalAmount,
} from '../../../utils'
import { PaymentLinkDetailsRow } from './PaymentLinkDetailsRow'
import { PartialPaymentTag } from '../../../components/common/PartialPaymentStateTag'
import { PaymentLinkDetailsProps } from '../../types'

export const PartialPaymentDetails = ({
  firstPayment,
  secondPayment,
  booking,
}: PaymentLinkDetailsProps) => {
  const { t } = useTranslation()

  const { currency, amount: totalAmount } = calculateTotalAmount(
    [firstPayment, secondPayment],
    booking?.currency_code
  )

  const { amount: firstPaymentAmount } = calculateTotalAmount(
    [firstPayment],
    booking?.currency_code
  )

  const { amount: secondPaymentAmount } = calculateTotalAmount(
    [secondPayment],
    booking?.currency_code
  )

  const isFirstPaymentPaid = firstPayment?.payment?.state === 'completed'
  const isSecondPaymentPaid = secondPayment?.payment?.state === 'completed'
  const isUnpaid = firstPayment?.payment?.state === 'new' && secondPayment?.payment?.state === 'new'
  const isExpired = firstPayment?.is_expired || secondPayment?.is_expired
  const isActive = firstPayment?.is_active || secondPayment?.is_active
  const isPartiallyPaid =
    !isUnpaid && (firstPayment?.payment?.state === 'new' || secondPayment?.payment?.state === 'new')
  const isFullPaid =
    firstPayment?.payment?.state === 'completed' && secondPayment?.payment?.state === 'completed'
  const isCanceled =
    firstPayment?.payment?.state === 'canceled' && secondPayment?.payment?.state === 'canceled'

  return (
    <div className="flex w-full flex-wrap">
      <PaymentLinkDetailsRow title={t('words.status')}>
        <PartialPaymentTag
          condition={isUnpaid && isExpired}
          variant="danger-filled"
          text={t('words.expired')}
        />
        <PartialPaymentTag
          condition={isUnpaid && !isActive}
          variant="danger-filled"
          text={t('words.deactivated')}
        />
        <PartialPaymentTag condition={isUnpaid} variant="warning-filled" text={t('words.unpaid')} />
        <PartialPaymentTag
          condition={isCanceled}
          variant="danger-filled"
          text={t('words.canceled')}
        />
        <PartialPaymentTag
          condition={isPartiallyPaid}
          variant="warning-filled"
          text={t('sentences.partiallyPaid')}
        />
        <PartialPaymentTag condition={isFullPaid} variant="success-filled" text={t('words.paid')} />
      </PaymentLinkDetailsRow>

      <PaymentLinkDetailsRow title={t('paymentLinks.firstPayment')}>
        <Tag variant="primary" className="mt-1 w-max px-2 font-semibold">
          {calculateDuration(
            firstPayment?.expires_at,
            firstPayment?.payment?.order?.course?.timezone
          )}
        </Tag>
      </PaymentLinkDetailsRow>

      <PaymentLinkDetailsRow title={t('paymentLinks.totalAmount')}>
        <p>
          {currency} {totalAmount}
        </p>
      </PaymentLinkDetailsRow>
      <PaymentLinkDetailsRow title={t('paymentLinks.finalPayment')}>
        <Tag variant="primary" className="mt-1 w-max px-2 font-semibold">
          {calculateDuration(
            secondPayment?.expires_at,
            secondPayment?.payment?.order?.course?.timezone
          )}
        </Tag>
      </PaymentLinkDetailsRow>

      <PaymentLinkDetailsRow title={t('paymentLinks.leftToBePaid')}>
        <p>
          {currency}{' '}
          {isFirstPaymentPaid && !isSecondPaymentPaid
            ? secondPaymentAmount
            : isSecondPaymentPaid && !isFirstPaymentPaid
            ? firstPaymentAmount
            : totalAmount}
        </p>
      </PaymentLinkDetailsRow>
      {!isSecondPaymentPaid && !isExpired && !isCanceled && isActive && (
        <PaymentLinkDetailsRow title={t('paymentLinks.dueTime')}>
          <Tag variant="primary" className="mt-1 w-max px-2 font-semibold">
            {isFirstPaymentPaid
              ? calculateTimeLeft(secondPayment?.expires_at)
              : calculateTimeLeft(firstPayment?.expires_at)}
          </Tag>
        </PaymentLinkDetailsRow>
      )}

      <PaymentLinkDetailsRow title={t('paymentLinks.createdOn')}>
        <p>{calculateCreatedDate(firstPayment?.created_at)}</p>
      </PaymentLinkDetailsRow>
    </div>
  )
}
