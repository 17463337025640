import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { t } from 'i18next'

import styles from './style.module.scss'

import ButtonDropdown from '@sweetspot/club-portal-legacy/components/ButtonDropdown'
import SearchDropdown from '@sweetspot/club-portal-legacy/components/SearchDropdown'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

const EDIT = 1
const DELETE = 2
const GOLF_CLUB = 4

const actions = [
  { id: EDIT, label: 'players.edit' },
  { id: DELETE, label: 'settings.delete' },
]

export class Header extends Component {
  TRANSLATIONS = {
    addGolfClub: GetTranslatedString(this.props.lang, 'settings.clubAdmins.addGolfClub'),
  }

  isValidForm = () => {
    const {
      isCreate,
      form: { firstName, lastName, email, password, confirmPass },
      errors,
    } = this.props

    if (!firstName || !lastName || !email) return false
    if (!Utilities.validateEmail(email)) return false
    if (isCreate && !password) return false
    if (password !== confirmPass) return false
    if (!_.isEmpty(errors)) return false

    return true
  }

  renderActions = () => {
    const { sidebar, isCreate, isEdit, isLoading, clubs, onCancel, onSave, onClubAdd } = this.props

    if (sidebar === GOLF_CLUB) {
      return (
        <SearchDropdown
          width="100%"
          placeholder={this.TRANSLATIONS.addGolfClub}
          values={clubs}
          onSelect={onClubAdd}
        />
      )
    } else {
      return (
        <React.Fragment>
          {!isCreate && (
            <ButtonDropdown
              text="players.actions"
              values={actions}
              actionStyles={{ width: '100%' }}
              onClick={this.props.onClickActions}
            />
          )}

          {isEdit && (
            <div className={styles.btnGroup}>
              <div className={styles.cancel} onClick={onCancel}>
                <Text textId="cancel" />
              </div>
              <button
                className="system-button primary"
                disabled={!this.isValidForm() || isLoading}
                onClick={onSave}
              >
                {t(isCreate ? 'create' : 'save')}
              </button>
            </div>
          )}
        </React.Fragment>
      )
    }
  }

  render() {
    const {
      isCreate,
      form: { firstName, lastName },
    } = this.props

    return (
      <div className={styles.container}>
        <h5>{!isCreate && `${firstName} ${lastName}`}</h5>
        <div className={styles.headerText}>
          <Text textId="settings.clubAdmins.clubAdmin" />
        </div>

        <div className={styles.actionGroup}>{this.renderActions()}</div>
      </div>
    )
  }
}

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  sidebar: PropTypes.number,
  isCreate: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  form: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  clubs: PropTypes.array.isRequired,
  onClickActions: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClubAdd: PropTypes.func.isRequired,
}

export default Header
