import moment from 'moment-timezone'

export const now = (defaultDate) => {
  if (defaultDate) return moment(defaultDate)
  return moment()
}
export const max = () => moment().add(10, 'years')

export const startDate = (date, time, useDefault = false) => {
  if (!date && useDefault) date = now()
  return moment(`${moment(date).format('YYYY-MM-DD')} ${time || now().format('HH:mm')}:00`).utc()
}

export const endDate = (date, time, useDefault = false) => {
  if (!date && useDefault) date = now()
  return moment(
    `${moment(date).format('YYYY-MM-DD')} ${time || now().add(1, 'second').format('HH:mm')}:59`
  ).utc()
}

export const local = (date, format) => {
  if (date === null) return ''
  return moment(date).local().format(format)
}

export const parse = (date) => {
  if (date === null) return moment()
  return moment(date)
}

export const format = (date, format) => {
  const isDateValid = moment(date).isValid()

  if (!isDateValid) return null

  return moment(date).format(format)
}

export const nextDay = (date) => {
  return moment(date).add(1, 'day')
}

// NULL is not allowed, for display only
export const value = (date, format, disable = false) => {
  if (!date || disable) return ''
  if (moment(date, format).format(format) === date) {
    return date
  } else {
    return moment(date).format(format)
  }
}
