import * as React from 'react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { tv, VariantProps } from 'tailwind-variants'
import { cn } from '../../../utils'

const dividerVariants = tv({
  base: 'shrink-0 bg-surface-inverted-primary',
  variants: {
    variant: {
      basic: 'h-[1px]',
      section: 'h-0.5',
      module: 'h-2',
    },
  },
  defaultVariants: {
    variant: 'basic',
  },
})

export interface DividerProps
  extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>,
    VariantProps<typeof dividerVariants> {}

const Divider = React.forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, DividerProps>(
  ({ className, variant, orientation = 'horizontal', decorative = true, ...props }, ref) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        dividerVariants({
          variant,
        }),
        orientation === 'horizontal' ? 'w-full' : 'h-full w-[1px]',
        className
      )}
      data-testid="scramble-divider"
      {...props}
    />
  )
)
Divider.displayName = 'Divider'

export { Divider }
