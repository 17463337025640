import { formatInTimeZone, getTimezoneOffset as gtzOffset } from 'date-fns-tz'

export const lastDayOfMonth = (month) =>
  new Date(new Date().getFullYear(), month || new Date().getMonth() + 1, 0)

export const formatToDatePicker = (date) => new Date(date).toISOString().substring(0, 10)

export const getTimezoneOffset = (timezone) => gtzOffset(timezone, new Date()) / 1000 / 60 / 60

export const formatTime = (time, timezone) => {
  if (timezone) {
    const timezoneOffset = getTimezoneOffset(timezone)
    const [hours, mins] = time.split(':')
    return `${leftpad(+hours + timezoneOffset)}:${mins}:00`
  }
  return `${time}:00`
}

export const formatToTimePicker = (time) => {
  const match = time.match(/T(\d{2}):(\d{2})/)
  if (!match || match.length !== 3) throw new Error('Invalid date Format')
  const [, hours, mins] = match
  return `${hours}:${mins}`
}

export const leftpad = (str, len = 2, char = '0') => {
  const string = '' + str
  return string.length < len ? leftpad(char + string, len, char) : string
}

export const convertMinutesToHours = (minutes) => {
  if (typeof minutes !== 'number') {
    throw new TypeError('The input must be a number')
  }
  if (minutes < 0) {
    throw new RangeError('The number of minutes cannot be negative')
  }

  return minutes / 60
}

export const getZonedDayStartAndEnd = (date, timezone) => {
  const startTimeWithZone = formatInTimeZone(date, timezone, "yyyy-MM-dd'T00:00:00'xxx")
  const startTime = new Date(startTimeWithZone).toLocaleString('sv', { timeZone: 'UTC' })
  const startTimeStr = startTime.split(' ')[0] + 'T' + startTime.split(' ')[1]

  const endTimeWithZone = formatInTimeZone(date, timezone, "yyyy-MM-dd'T23:59:59'xxx")
  const endTime = new Date(endTimeWithZone).toLocaleString('sv', { timeZone: 'UTC' })
  const endTimeStr = endTime.split(' ')[0] + 'T' + endTime.split(' ')[1]

  return { startTime: startTimeStr, endTime: endTimeStr }
}
