import { useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { getPaymentLink } from '@sweetspot/shared/data-access/api-platform'
import { UseGetPaymentLinksDetailsProps } from './types'
import { PaymentLink } from '@sweetspot/shared/types'

export const useGetPaymentLinksDetails = ({
  paymentResults,
  bookingPayments,
  handleUpdateBooking,
  paymentIndex,
}: UseGetPaymentLinksDetailsProps) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const paymentLinkId =
    paymentResults[paymentIndex]?.uuid || bookingPayments[paymentIndex]?.payment_link?.uuid

  const isPaymentLinkEnabled =
    !!paymentResults[paymentIndex]?.uuid ||
    (!!bookingPayments[paymentIndex]?.payment_link?.uuid &&
      bookingPayments[paymentIndex]?.payment_link?.is_active &&
      !bookingPayments[paymentIndex]?.payment_link?.is_expired)

  const { data, isLoading } = useQuery({
    queryKey: [`PAYMENT_LINK_${paymentIndex}`, paymentLinkId],
    queryFn: () => getPaymentLink(paymentLinkId),
    enabled: isPaymentLinkEnabled,
    onSuccess: () => handleUpdateBooking(),
    onError: () =>
      addToast(t('paymentLinks.errorGettingPaymentLinksDetails'), { appearance: 'error' }),
    refetchOnWindowFocus: true,
  })

  const payment = data as PaymentLink

  return {
    payment,
    isLoading,
  }
}
