import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { t } from 'i18next'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import style from './style.module.scss'

import { removePartnerType, getPartnerTypes } from '@sweetspot/club-portal-legacy/store/actions'

class RemovePartner extends Component {
  state = {
    selected: {},
    partnerName: '',
    isShowOverlay: false,
    partnerTypes: [],
    isLoading: false,
  }

  componentDidMount = () => {
    this.getPartnerTypes()
  }

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps.golfClub.selectedId, this.props.golfClub.selectedId)) {
      this.setState(
        {
          partnerName: '',
        },
        this.getPartnerTypes()
      )
    }
  }

  handleSelect = (id) => {
    this.setState({
      selected: this.state.partnerTypes.find((item) => item.id === id),
    })
  }

  handleChange = (e) => {
    this.setState({ partnerName: e.target.value })
  }

  handleKeyPress = (e) => {
    const { partnerName, selected } = this.state

    if (e.charCode === 13 && partnerName && partnerName !== selected.name)
      this.props.removePartnerType(partnerName)
  }

  handleClickRemove = () => {
    this.setState({ isShowOverlay: !this.state.isShowOverlay })
  }

  getPartnerTypes = () => {
    const { token, golfClub, getPartnerTypes } = this.props

    this.setState({ isLoading: true })
    getPartnerTypes(token, golfClub.selectedId)
      .then((response) => {
        this.setState({
          isLoading: false,
          partnerTypes: response,
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          partnerTypes: [],
        })
      })
  }

  removePartnerType = () => {
    const { token, golfClub } = this.props
    const { selected } = this.state
    if (!selected) return

    this.setState({ isLoading: true })
    this.props
      .removePartnerType(token, golfClub.selectedId, selected.id)
      .then(() => {
        this.setState({ partnerName: '' })
        this.getPartnerTypes()
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
    this.handleClickRemove()
  }

  renderSelects = () => {
    const { selected, partnerName } = this.state

    return (
      <div>
        <div className="ss-form-group">
          <label className="ss-label">{t('settings.selectTagToRemove')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={selected ? selected.id : 0}
            values={this.state.partnerTypes}
            onSelect={(id) => this.handleSelect(id)}
          />
          <input
            className={`ss-input ${style.input}`}
            placeholder={getLang(this.props.lang)['settings']['deleteTagPlaceholder']}
            value={partnerName}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
          />
        </div>
      </div>
    )
  }

  renderButtons = () => {
    const { partnerName, selected } = this.state

    return (
      <div className={style.btnGroup}>
        <button
          className="system-button danger md-32"
          disabled={this.state.isLoading || !partnerName || partnerName !== selected.name}
          onClick={this.handleClickRemove}
        >
          {t('remove')}
        </button>
      </div>
    )
  }

  renderOverlay() {
    return (
      <div>
        {this.state.isShowOverlay && (
          <OverlayContainer>
            <div className={style.overlay}>
              <Text textId="settings.confirmRemoveTag" />
              <div className={style.confirmBtnGroup}>
                <div onClick={this.removePartnerType}>
                  <Text textId="yes" />
                </div>
                <div onClick={this.handleClickRemove}>
                  <Text textId="no" />
                </div>
              </div>
            </div>
          </OverlayContainer>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.label}>
          <Text textId="settings.removeTag" />
          <div>
            <PulseLoader fillHeight showIf={this.state.isLoading} />
          </div>
        </div>
        {this.renderSelects()}
        {this.renderButtons()}
        {this.renderOverlay()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.me.lang,
    token: state.auth.token,
    golfClub: state.golfClub,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removePartnerType: (token, clubId, id) => dispatch(removePartnerType(token, clubId, id)),
    getPartnerTypes: (token, clubId) => dispatch(getPartnerTypes(token, clubId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RemovePartner))

RemovePartner.propTypes = {
  getPartnerTypes: PropTypes.func.isRequired,
  removePartnerType: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  golfClub: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
}
