import {
  PartnershipActions,
  PartnershipState,
  SetPartnershipListAction,
  SetPartnershipTotalListAction,
  SetPartnershipTotalPagesAction,
} from '@sweetspot/club-portal-legacy/store/types'
import { CONSTANTS } from '../../constants'
import { initialState } from './initialState'

export default function partnership(
  state = initialState,
  action: PartnershipActions
): PartnershipState {
  switch (action.type) {
    case CONSTANTS.PARTNERSHIP.SET_LIST:
      return { ...state, list: (action as SetPartnershipListAction).payload }

    case CONSTANTS.PARTNERSHIP.SET_TOTAL_LIST:
      return { ...state, totalList: (action as SetPartnershipTotalListAction).payload }

    case CONSTANTS.PARTNERSHIP.SET_TOTAL_PAGES:
      // eslint-disable-next-line
      const { itemsPerPage, totalItems } = (action as SetPartnershipTotalPagesAction).payload
      // eslint-disable-next-line
      const totalPages = Math.ceil(totalItems / itemsPerPage)

      return Object.assign({ ...state, totalPages, itemsPerPage, totalItems })

    default:
      return state
  }
}
