import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { Membership } from '@sweetspot/shared/types'

/**
 * Toggle if git club can be selected or not
 */
export const toggleGitClubSelectable = (id: number): Promise<Membership> => {
  const URL = `${getApiPlatformBaseUrl()}/memberships/${id}/toggle-git-club-selectable`

  return request(URL, {
    method: 'PUT',
  })
}
