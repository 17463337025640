import React from 'react'
import PropTypes from 'prop-types'

// import Text from '@sweetspot/club-portal-legacy/components/Text';

import style from './style.module.scss'

const OccupancyItem = (props) => {
  const { percent, active, onClick } = props
  const containerClass = [style.container]
  if (active) containerClass.push(style.active)

  return (
    <div className={containerClass.join(' ')} onClick={onClick}>
      <span className={style.percent}>{percent}%</span>
      {/* <div className={style.days}>
        <span>{days}</span>
        <Text textId="pricing.daysBefore" />
      </div> */}
    </div>
  )
}

OccupancyItem.propTypes = {
  percent: PropTypes.number.isRequired,
  days: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default OccupancyItem
