import React, { FC, useCallback, useState, useMemo } from 'react'

import { Note } from '@sweetspot/shared/types'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import styles from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/style.module.scss'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { useMutation } from 'react-query'
import { deletePlayerNote, updatePlayerNote } from '@sweetspot/shared/data-access/api-platform'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import NoteTextInputField from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/Notes/NoteTextInputField'

type Props = {
  notes: Note[]
  updateNotesList: () => Promise<unknown>
}

const NotesList: FC<Props> = ({ notes, updateNotesList }) => {
  const [deletingNotesMap, setDeletingNotesMap] = useState<Record<string, boolean>>({})
  const [updatingNoteId, setUpdatingNoteId] = useState<string | null>(null)

  const { addToast } = useToasts()
  const { t } = useTranslation()
  const currentUser = useSelector((state: RootState) => state.auth)
  const role = useMemo(() => getFirstMatchingRole(currentUser.roles)?.value, [currentUser])

  const { mutate: deletePlayerNoteMutation } = useMutation(({ noteId }: { noteId: string }) =>
    deletePlayerNote(noteId)
  )

  const { mutateAsync: updatePlayerNoteMutation, isLoading: isUpdatingTheNote } = useMutation(
    ({ noteId, note }: { noteId: string; note: string }) => updatePlayerNote(noteId, note)
  )

  const handleDeletePlayerNote = useCallback(
    (noteId: string) => {
      if (deletingNotesMap[noteId]) return

      setDeletingNotesMap((prevMap) => ({ ...prevMap, [noteId]: true })) // Set the note ID as key with value true

      deletePlayerNoteMutation(
        {
          noteId,
        },
        {
          onSuccess: async () => {
            await updateNotesList()
            setDeletingNotesMap((prevMap) => ({ ...prevMap, [noteId]: false }))
            addToast(t('toast.playerNoteDeleteSuccess'), { appearance: 'success' })
          },
          onError: () => {
            addToast(t('toast.playerNoteDeleteError'), { appearance: 'error' })
            setDeletingNotesMap((prevMap) => ({ ...prevMap, [noteId]: false }))
          },
        }
      )
    },
    [addToast, deletePlayerNoteMutation, deletingNotesMap, t, updateNotesList]
  )

  const handleUpdatePlayerNote = useCallback(
    async (noteId: string, newValue: string) => {
      if (isUpdatingTheNote) return

      try {
        const data = await updatePlayerNoteMutation({
          noteId,
          note: newValue,
        })
        await updateNotesList()
        setUpdatingNoteId(null)
        addToast(t('toast.playerNoteUpdateSuccess'), { appearance: 'success' })
        return data
      } catch (e) {
        addToast(t('toast.playerNoteUpdateError'), { appearance: 'error' })
      }
    },
    [addToast, isUpdatingTheNote, t, updateNotesList, updatePlayerNoteMutation]
  )

  const canEditOrDelete = useCallback(
    (noteAuthorId: string) =>
      hasAccess(ACCESS_KEYS.PAGES.PLAYERS.DELETE_OR_EDIT_NOTE, role) ||
      noteAuthorId === currentUser.me.uuid,
    [currentUser.me.uuid, role]
  )

  return (
    <div className={styles.notesListContainer}>
      {notes.map((note) => (
        <div key={note.uuid} className={'mt-4'}>
          <div className={styles.noteTitleContainer}>
            <Text
              textId={`${DateHelpers.dateStringwithMinutes(note.created_at)}`}
              className={styles.infoText}
            />
          </div>
          <div className={styles.noteTitleContainer}>
            <Text textId={`${note.author_name}`} className={styles.infoText} />
            <div className={styles.noteActionsContainer}>
              {canEditOrDelete(note.author_id) && (
                <>
                  <Button
                    onClick={() => setUpdatingNoteId(note.uuid)}
                    theme={'none'}
                    disabled={updatingNoteId === note.uuid}
                  >
                    {t('words.edit')}
                  </Button>
                  <Button
                    onClick={() => handleDeletePlayerNote(note.uuid)}
                    theme={'none'}
                    disabled={deletingNotesMap[note.uuid]}
                  >
                    {t('words.remove')}
                  </Button>
                </>
              )}
            </div>
          </div>
          {updatingNoteId === note.uuid ? (
            <NoteTextInputField
              disabled={isUpdatingTheNote}
              defaultValue={note.note.note}
              labelTwo={''}
              onSubmit={(newValue) => handleUpdatePlayerNote(note.uuid, newValue)}
            />
          ) : (
            <Text textId={note.note.note} className={styles.noteText} />
          )}
        </div>
      ))}
    </div>
  )
}

export default NotesList
