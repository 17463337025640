import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '../Text'

/**
 * This component displays a popup with a message that can be **accepted** or **rejected**
 */
export default class Alert extends Component {
  render() {
    if (!this.props.showIf) return null
    return (
      <OverlayContainer>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.textContainer}>{this.props.children}</div>

            <div className={style.closeAlert}>
              <div
                onClick={() => {
                  if (this.props.onClick) this.props.onClick()
                }}
              >
                <Text textId="ok" />
              </div>
            </div>
          </div>
        </div>
      </OverlayContainer>
    )
  }
}

Alert.propTypes = {
  /**
   * Determines if the popup should be displayed
   */
  showIf: PropTypes.bool,
  /**
   * Function to run when **accepting**
   */
  onClick: PropTypes.func,
}
