import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _isEqual from 'lodash/isEqual'

import styles from './styles.module.scss'
import { useToasts } from 'react-toast-notifications'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import {
  ACTION_TYPES,
  ACTION_TYPES_OBJ,
  DISCOUNT_TYPES,
  LIMITATION_TYPES,
  LIMITATION_TYPES_OJB,
} from '../../constants/actions'

import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { ReactComponent as RemoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/stop-icon.svg'
import { ReactComponent as WarningIcon } from '@sweetspot/sweetspot-js/assets/svgs/warning-icon.svg'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import FiltersAndQueriesContainer from './FiltersAndQueriesContainer'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import {
  centsToFloat,
  currencyCodeInCurrentLanguage,
  decimalPercentToNumber,
  floatToCents,
  numberToDecimalPercent,
  numberToLocalFormat,
  reverseLocalFormat,
  to,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import { createNewAction, deleteAction, updateAction } from '../../services/api-platform'
import produce from 'immer'
import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import { parseApiErrors, validateAction } from './validation'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import AnimatedLine from '@sweetspot/sweetspot-js/common/components/AnimatedLine'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

const baseErrors = {
  type: '',
  limitation: '',
  rows: [],
}

const SingleAction = ({ action: actionProp, promotionId, onRemoveAction, index, disabled }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const saveTiemout = useRef(null)
  const isMounted = useRef(false)

  const [currencyCode] = useClubCurrency()

  // States ---------------------
  const [action, setAction] = useMergeState(actionProp)
  const prevAction = usePrevious(action)
  const [isOpen, setIsOpen] = useState(
    typeof action?.id === 'string' && action.id?.includes('new') ? true : false
  )
  const [isCreating, setIsCreating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(baseErrors)
  const [limitationType, setLimitationType] = useState('')
  const [discountType, setDiscountType] = useState('')
  const [showConfirmChangeLimitationType, setShowConfirmChangeLimitationType] = useMergeState({
    show: false,
    params: [],
  })
  const [showConfirmDeleteUsesAction, setShowConfirmDeleteUsesAction] = useState(false)

  // Memos ---------------------
  const isNewAction = useMemo(
    () => (typeof action?.id === 'string' && action.id?.includes('new') ? true : false),
    [action.id]
  )

  const currentActionType = useMemo(() => {
    return ACTION_TYPES.find((item) => item.value === action.type) || null
  }, [action])

  const currentLimitationType = useMemo(() => {
    return (
      LIMITATION_TYPES.find((item) => item.value === currentActionType?.limitationType) ||
      limitationType ||
      null
    )
  }, [currentActionType, limitationType])

  const currentDiscountType = useMemo(() => {
    return (
      DISCOUNT_TYPES.find((item) => item.value === currentActionType?.discountType) ||
      discountType ||
      null
    )
  }, [currentActionType, discountType])

  const rows = useMemo(() => {
    const conf = action.configuration
    const type = action?.type

    const actionType = ACTION_TYPES.find((x) => x.value === type)

    if (actionType?.value === ACTION_TYPES_OBJ.PERCENTAGE) {
      return [
        {
          percentage_coefficient: conf.percentage_coefficient,
          minimum_fee_total_amount: conf.minimum_fee_total_amount,
        },
      ]
    } else if (actionType?.value === ACTION_TYPES_OBJ.FIXED_DISCOUNT) {
      return [
        {
          discount_amount: conf.discount_amount,
          minimum_fee_total_amount: conf.minimum_fee_total_amount,
        },
      ]
    }

    return conf?.[actionType?.configurationKey] || []
  }, [action])

  // Effects ---------------------
  useEffect(() => {
    isMounted.current = true

    return () => (isMounted.current = false)
  }, [])

  // Functions ---------------------
  const removeAction = (confirmed = false) => {
    if (disabled) return

    if (currentLimitationType?.value === LIMITATION_TYPES_OJB.USES && !confirmed) {
      setShowConfirmDeleteUsesAction(true)
      return
    }

    onRemoveAction()

    if (!isNewAction) {
      deleteAction(action.id)
    }
  }

  const saveAction = async (updatedAction = null) => {
    clearTimeout(saveTiemout.current)

    // DONT KNOW WHY THIS IS HERE??? IT's BROKEN and APPARENTLY I WROTE IT // Viktor
    // (Left it just in case, but don't see how this could work)
    // const newAction = [ACTION_TYPES_OBJ.PERCENTAGE, ACTION_TYPES_OBJ.FIXED_DISCOUNT].includes(
    //   newAction?.type
    // )
    //   ? produce(updatedAction || action, draft => {
    //       draft.configuration.minimum_fee_total_amount =
    //         draft.configuration.minimum_fee_total_amount || null;
    //     })
    //   : updatedAction || action;

    const newAction = updatedAction || action

    const newPrevAction = updatedAction ? action : prevAction
    const actionIsNew =
      typeof newAction?.id === 'string' && newAction.id?.includes('new') ? true : false

    if (!newAction?.type) return

    const actionTypeChanged = newAction?.type !== newPrevAction?.type

    const shouldSave = () => {
      if (_isEqual(newAction, newPrevAction)) {
        return false
      } else {
        return true
      }
    }

    const [isValid, errors] = validateAction(newAction)

    setErrors(errors)
    if (!isValid) {
      return
    }

    if (actionIsNew) {
      setIsCreating(true)
      saveTiemout.current = setTimeout(async () => {
        const [res, err] = await to(createNewAction(promotionId, newAction))

        if (!isMounted.current) return

        if (!res || err) {
          setErrors(parseApiErrors(err, newAction))
          addToast(t('sentences.couldNotSaveAction'), { appearance: 'error' })
        } else {
          setAction({ id: res.id, uuid: res.uuid })
        }
        setIsCreating(false)
      }, 1000)
      return
    }

    if (!actionIsNew && shouldSave()) {
      setIsLoading(true)
      if (actionTypeChanged) setIsCreating(true)

      saveTiemout.current = setTimeout(async () => {
        const [res, err] = await to(updateAction(newAction.id, newAction))

        if (!isMounted.current) return

        if (!res || err) {
          setErrors(parseApiErrors(err, newAction))
          addToast(t('sentences.couldNotSaveAction'), { appearance: 'error' })
        }
        setIsLoading(false)
        setIsCreating(false)
      }, 1000)
    }
  }

  const changeActionType = (trigger, newType) => {
    const discountType = trigger === 'discountType' ? newType : currentDiscountType
    const limitationType = trigger === 'limitationType' ? newType : currentLimitationType

    if (!discountType || !limitationType) return

    const newActionType = ACTION_TYPES.find(
      (type) =>
        type.discountType === discountType.value && type.limitationType === limitationType.value
    )

    if (!newActionType || newActionType.value === currentActionType?.value) return

    if (!isNewAction) {
      try {
        const oldActionId = action.id
        deleteAction(oldActionId)
      } catch (error) {
        // HANDLE
      }
    }

    const newId = 'new-' + crypto.randomUUID()

    if (
      [ACTION_TYPES_OBJ.PERCENTAGE, ACTION_TYPES_OBJ.FIXED_DISCOUNT].includes(newActionType.value)
    ) {
      let newAction = produce(action, (draft) => {
        draft.id = newId
        if (draft.uuid) delete draft.uuid
        draft.type = newActionType.value
        draft.configuration = {
          ...newActionType.defaultFields,
          filter: draft?.configuration?.filter || [],
        }
      })
      setAction(newAction)
    } else {
      let newAction = produce(action, (draft) => {
        draft.id = newId
        if (draft.uuid) delete draft.uuid
        draft.type = newActionType.value
        draft.configuration = {
          filter: draft?.configuration?.filter || [],
          [newActionType.configurationKey]: [newActionType.defaultFields],
        }
      })
      setAction(newAction)
      saveAction(
        produce(newAction, (draft) => {
          draft.configuration[newActionType.configurationKey] = []
        })
      )
    }
  }

  const removeRow = (index) => {
    let newAction = produce(action, (draft) => {
      draft.configuration[currentActionType.configurationKey].splice(index, 1)
    })

    setAction(newAction)
    saveAction(newAction)
  }

  const addRow = () => {
    setAction(
      produce((draft) => {
        draft.configuration[currentActionType.configurationKey].push(
          currentActionType.defaultFields
        )
      })
    )
  }

  // Getters and setters ---------------------
  const changeDiscountType = (newDiscountType) => {
    setDiscountType(newDiscountType)
    changeActionType('discountType', newDiscountType)
  }

  const changeLimitationType = (newLimitationType, confirmed = false) => {
    if (currentLimitationType?.value === LIMITATION_TYPES_OJB.USES && !confirmed) {
      setShowConfirmChangeLimitationType({ show: true, params: [newLimitationType] })
      return
    }

    setLimitationType(newLimitationType)
    changeActionType('limitationType', newLimitationType)
  }

  const setUsesValue = (value, rowIndex) => {
    if (rowIndex === 1) return

    setErrors(
      produce((draft) => {
        if (draft?.rows[rowIndex] === 'undefined') {
          draft.rows[rowIndex] = {}
        }
        if (draft?.rows[rowIndex]?.uses_to) {
          draft.rows[rowIndex].uses_to = ''
        }
      })
    )

    const parsedValue =
      reverseLocalFormat(value) !== null && parseInt(reverseLocalFormat(value)) >= 0
        ? parseInt(reverseLocalFormat(value))
        : value

    const newAction = produce(action, (draft) => {
      draft.configuration[currentActionType.configurationKey][rowIndex].uses_to = parsedValue
    })

    setAction(newAction)
  }

  const getUsesValue = (row) => {
    return typeof row?.uses_to === 'number' ? numberToLocalFormat(row.uses_to) : row?.uses_to || ''
  }

  const getPercentageValue = (row) => {
    return row?.percentage_coefficient ? decimalPercentToNumber(row.percentage_coefficient) : ''
  }

  const setPercentageValue = (value, rowIndex) => {
    setErrors(
      produce((draft) => {
        if (draft?.rows[rowIndex] === 'undefined') {
          draft.rows[rowIndex] = {}
        }
        if (draft?.rows[rowIndex]?.percentage_coefficient) {
          draft.rows[rowIndex].percentage_coefficient = ''
        }
      })
    )

    const parsedValue = numberToDecimalPercent(value)

    const newAction = produce(action, (draft) => {
      if (draft?.type === ACTION_TYPES_OBJ.PERCENTAGE) {
        draft.configuration.percentage_coefficient = parsedValue
      } else {
        draft.configuration[currentActionType.configurationKey][rowIndex].percentage_coefficient =
          parsedValue
      }
    })

    setAction(newAction)
  }

  const getMinFeeValue = (row) => {
    return row?.minimum_fee_total_amount === null
      ? 0
      : typeof row?.minimum_fee_total_amount === 'number'
      ? numberToLocalFormat(centsToFloat(row.minimum_fee_total_amount))
      : row?.minimum_fee_total_amount || ''
  }

  const setMinFeeValue = (value, rowIndex) => {
    setErrors(
      produce((draft) => {
        if (draft?.rows[rowIndex] === 'undefined') {
          draft.rows[rowIndex] = {}
        }
        if (draft?.rows[rowIndex]?.minimum_fee_total_amount) {
          draft.rows[rowIndex].minimum_fee_total_amount = ''
        }
      })
    )

    let hasDecimal = false
    if (value?.slice(-1) === '.' || value?.slice(-1) === ',') hasDecimal = true

    const parsedValue = hasDecimal
      ? value
      : reverseLocalFormat(value) !== null && parseFloat(reverseLocalFormat(value)) >= 0
      ? floatToCents(parseFloat(reverseLocalFormat(value)))
      : value

    const newAction = produce(action, (draft) => {
      if ([ACTION_TYPES_OBJ.PERCENTAGE, ACTION_TYPES_OBJ.FIXED_DISCOUNT].includes(draft?.type)) {
        draft.configuration.minimum_fee_total_amount = parsedValue || null
      } else {
        draft.configuration[currentActionType.configurationKey][rowIndex].minimum_fee_total_amount =
          parsedValue || null
      }
    })

    setAction(newAction)
  }

  const getDiscountedToValue = (row) => {
    const value =
      typeof row?.discounted_value_to === 'number'
        ? centsToFloat(row.discounted_value_to)
        : row?.discounted_value_to || ''

    return typeof value === 'number' ? numberToLocalFormat(value) : value
  }

  const setDiscountedToValue = (value, rowIndex) => {
    if (rowIndex === 1) return

    setErrors(
      produce((draft) => {
        if (draft?.rows[rowIndex] === 'undefined') {
          draft.rows[rowIndex] = {}
        }
        if (draft?.rows[rowIndex]?.discounted_value_to) {
          draft.rows[rowIndex].discounted_value_to = ''
        }
      })
    )

    const parsedValue =
      reverseLocalFormat(value) !== null && parseFloat(reverseLocalFormat(value)) >= 0
        ? floatToCents(parseFloat(reverseLocalFormat(value)))
        : value

    const newAction = produce(action, (draft) => {
      draft.configuration[currentActionType.configurationKey][rowIndex].discounted_value_to =
        parsedValue
    })

    setAction(newAction)
  }

  const getDiscountAmountValue = (row) => {
    return row?.discount_amount === null
      ? 0
      : typeof row?.discount_amount === 'number'
      ? numberToLocalFormat(centsToFloat(row.discount_amount))
      : row?.discount_amount || ''
  }

  const setDiscountAmountValue = (value, rowIndex) => {
    setErrors(
      produce((draft) => {
        if (draft?.rows[rowIndex] === 'undefined') {
          draft.rows[rowIndex] = {}
        }
        if (draft?.rows[rowIndex]?.discount_amount) {
          draft.rows[rowIndex].discount_amount = ''
        }
      })
    )

    let hasDecimal = false
    if (value?.slice(-1) === '.' || value?.slice(-1) === ',') hasDecimal = true

    const parsedValue = hasDecimal
      ? value
      : reverseLocalFormat(value) !== null && parseFloat(reverseLocalFormat(value)) >= 0
      ? floatToCents(parseFloat(reverseLocalFormat(value)))
      : value

    const newAction = produce(action, (draft) => {
      if ([ACTION_TYPES_OBJ.FIXED_DISCOUNT].includes(draft?.type)) {
        draft.configuration.discount_amount = parsedValue || null
      } else {
        draft.configuration[currentActionType.configurationKey][rowIndex].discount_amount =
          parsedValue || null
      }
    })

    setAction(newAction)
  }

  // Render ---------------------

  return (
    <div className={cx(styles.container)} style={{ zIndex: 100 - index }}>
      <AnimatedLine show={isCreating || isLoading} className={cx(styles.pulse)} />
      {/* Action header */}
      <div className={cx(styles.header)}>
        <div className={cx(styles.leftSide)} onClick={() => setIsOpen((prev) => !prev)}>
          <div className={cx(styles.caretContainer)}>
            <CaretDown className={cx(styles.caretDown, isOpen && styles.isOpen)} />
          </div>
          <p className={cx(styles.headerTitle)}>
            {(isNewAction ? t('sentences.newAction') : t('words.action')) +
              (currentDiscountType ? ` (${t(currentDiscountType.label)})` : '')}
          </p>
        </div>
        <div className={cx(styles.rightSide)}>
          <div
            className={cx(styles.removeIconContainer, disabled && styles.disabled)}
            onClick={() => removeAction()}
          >
            <RemoveIcon className={cx(styles.removeIcon)} />
          </div>
        </div>
      </div>

      {/* Inner dropdown */}
      <div className={cx(styles.foldContainer, isOpen && styles.isOpen)}>
        <div className={cx(styles.innerContainer)}>
          <p className={cx(styles.innerTitle)}>{t('words.settings')}</p>

          <div className={cx(styles.rowsContainer)}>
            {/* Top row */}
            <div className={cx(styles.row)}>
              <TextInputOptionsSelect
                containerWidth="quarter"
                containerClassName={cx(styles.firstInput)}
                labelTwo={t('sentences.discountType')}
                noMargin
                prefix={
                  currentDiscountType?.icon
                    ? () => {
                        if (typeof currentDiscountType.icon === 'string') {
                          return currentDiscountType.icon
                        } else {
                          const Icon = currentDiscountType.icon
                          return <Icon />
                        }
                      }
                    : null
                }
                options={DISCOUNT_TYPES}
                value={currentDiscountType ? t(currentDiscountType?.label) : ''}
                onOptionSelect={changeDiscountType}
                disabled={disabled || isCreating}
                error={errors?.type || ''}
              />
              <TextInputOptionsSelect
                containerWidth="quarter"
                labelTwo={t('sentences.limitationType')}
                noMargin
                options={LIMITATION_TYPES}
                value={currentLimitationType ? t(currentLimitationType.label) : ''}
                onOptionSelect={changeLimitationType}
                disabled={disabled || isCreating || !currentDiscountType}
                error={errors?.limitation || ''}
              />
              <div className={cx(styles.minFeeContainer, styles.label)}>
                {currentDiscountType && currentLimitationType && (
                  <Label label={t('sentences.minimumFee')} secondary />
                )}
              </div>
              <div className={cx(styles.removeRowContainer)}></div>
            </div>

            {/* Settings rows */}
            {rows.map((row, index) => {
              const discount_amount = row?.discount_amount !== undefined ? true : false
              const percentage = row?.percentage_coefficient !== undefined ? true : false
              const uses_to = row?.uses_to !== undefined ? true : false
              const discounted_value_to = row?.discounted_value_to !== undefined ? true : false
              const minimum_fee_total_amount =
                row?.minimum_fee_total_amount !== undefined ? true : false

              return (
                <div key={index} className={cx(styles.row, styles.styled)}>
                  {discount_amount && (
                    <TextInputField
                      containerWidth="quarter"
                      noMargin
                      reducePaddingRight
                      inlineSuffix={currencyCodeInCurrentLanguage(currencyCode)}
                      inputProps={{
                        min: 0,
                        max: 10000.01,
                        step: 0.01,
                        className: cx(styles.textAlignRight),
                      }}
                      value={getDiscountAmountValue(row)}
                      onChange={(value) => setDiscountAmountValue(value, index)}
                      disabled={disabled || isCreating}
                      error={errors?.rows?.[index]?.discount_amount}
                      onEnter={() => saveAction(null)}
                      onInputBlur={() => saveAction(null)}
                    />
                  )}

                  {percentage && (
                    <TextInputField
                      containerWidth="quarter"
                      noMargin
                      reducePaddingRight
                      type="text"
                      inlineSuffix="%"
                      inputProps={{
                        className: cx(styles.textAlignRight),
                      }}
                      value={getPercentageValue(row)}
                      onChange={(value) => setPercentageValue(value, index)}
                      disabled={disabled || isCreating}
                      error={errors?.rows?.[index]?.percentage_coefficient || ''}
                      onEnter={() => saveAction(null)}
                      onInputBlur={() => saveAction(null)}
                    />
                  )}

                  {!uses_to && !discounted_value_to && (
                    <div className={cx(styles.limitationTypeFiller)}></div>
                  )}

                  {uses_to && (
                    <TextInputField
                      containerWidth="quarter"
                      noMargin
                      reducePaddingRight
                      inlinePrefix={index === 0 ? '0 -' : `${getUsesValue(rows[index - 1])} +`}
                      value={getUsesValue(row)}
                      onChange={(value) => setUsesValue(value, index)}
                      disabled={disabled || index >= 1 || isCreating}
                      error={errors?.rows?.[index]?.uses_to}
                      onEnter={() => saveAction(null)}
                      onInputBlur={() => saveAction(null)}
                    />
                  )}

                  {discounted_value_to && (
                    <TextInputField
                      containerWidth="quarter"
                      noMargin
                      reducePaddingRight
                      inlineSuffix={currencyCodeInCurrentLanguage(currencyCode)}
                      inlinePrefix={
                        index === 0 ? '0 -' : `${getDiscountedToValue(rows[index - 1])} +`
                      }
                      value={index === 0 ? getDiscountedToValue(row) : ''}
                      onChange={(value) => setDiscountedToValue(value, index)}
                      disabled={disabled || index >= 1 || isCreating}
                      error={errors?.rows?.[index]?.discounted_value_to}
                      onEnter={() => saveAction(null)}
                      onInputBlur={() => saveAction(null)}
                    />
                  )}

                  {minimum_fee_total_amount ? (
                    <TextInputField
                      containerWidth="100px"
                      noMargin
                      reducePaddingRight
                      type="text"
                      inputProps={{
                        min: 0,
                        max: 10000.01,
                        step: 0.01,
                        className: styles.textAlignRight,
                      }}
                      inlineSuffix={currencyCodeInCurrentLanguage(currencyCode)}
                      value={getMinFeeValue(row)}
                      onChange={(value) => setMinFeeValue(value, index)}
                      disabled={disabled || isCreating}
                      error={errors?.rows?.[index]?.minimum_fee_total_amount || ''}
                      onEnter={() => saveAction(null)}
                      onInputBlur={() => saveAction(null)}
                      feedbackAnchorPosition="right"
                    />
                  ) : (
                    <div className={cx(styles.minFeeContainer)}></div>
                  )}

                  {![ACTION_TYPES_OBJ.PERCENTAGE, ACTION_TYPES_OBJ.FIXED_DISCOUNT].includes(
                    currentActionType?.value
                  ) && index > 0 ? (
                    <div className={cx(styles.removeRowContainer)} onClick={() => removeRow(index)}>
                      <RemoveIcon className={cx(styles.removeIcon)} />
                    </div>
                  ) : (
                    <div className={cx(styles.removeRowContainer)}></div>
                  )}
                </div>
              )
            })}
          </div>

          <ButtonBasic
            icon="plus"
            className={cx(styles.addRowButton)}
            text={t('words.row')}
            onClick={addRow}
            disabled={
              !currentActionType?.value ||
              currentActionType?.value === ACTION_TYPES_OBJ.PERCENTAGE ||
              currentActionType?.value === ACTION_TYPES_OBJ.FIXED_DISCOUNT ||
              disabled ||
              isCreating ||
              rows?.length >= 2
            }
          />

          <FiltersAndQueriesContainer
            action={action}
            onSaveAction={(updated) => saveAction(updated)}
            onSetAction={(updated) => setAction(updated)}
            disabled={disabled || isCreating || !currentActionType?.value}
          />
        </div>
      </div>

      <ConfirmPopup
        visible={showConfirmChangeLimitationType?.show}
        title={t('sentences.confirmChangeLimitationType')}
        text={t('sentences.thisWillDeleteAllCurrentUsesAndDiscountedValue')}
        onReject={() => {
          setShowConfirmChangeLimitationType({ show: false, params: [] })
        }}
        onClose={() => {
          setShowConfirmChangeLimitationType({ show: false, params: [] })
        }}
        onAccept={() => {
          changeLimitationType(...(showConfirmChangeLimitationType?.params || []), true)
          setShowConfirmChangeLimitationType({ show: false, params: [] })
        }}
        rejectText={t('words.cancel')}
        acceptText={t('words.confirm')}
        acceptTheme="default"
        titleIcon={WarningIcon}
        overrideIconColor={false}
      />

      <ConfirmPopup
        visible={showConfirmDeleteUsesAction}
        title={t('sentences.confirmRemoveAction')}
        text={t('sentences.thisWillDeleteAllCurrentUsesAndDiscountedValue')}
        onReject={() => {
          setShowConfirmDeleteUsesAction(false)
        }}
        onClose={() => {
          setShowConfirmDeleteUsesAction(false)
        }}
        onAccept={() => {
          removeAction(true)
        }}
        rejectText={t('words.cancel')}
        acceptText={t('words.confirm')}
        acceptTheme="default"
        titleIcon={WarningIcon}
        overrideIconColor={false}
      />
    </div>
  )
}

SingleAction.propTypes = {
  index: PropTypes.number,
  action: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf([
      ACTION_TYPES_OBJ.PERCENTAGE,
      ACTION_TYPES_OBJ.PERCENTAGE_USES,
      ACTION_TYPES_OBJ.PERCENTAGE_VALUE,
      ACTION_TYPES_OBJ.FIXED_DISCOUNT,
      ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES,
      ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE,
    ]),
    configuration: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    uuid: PropTypes.string,
  }),
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemoveAction: PropTypes.func,
  disabled: PropTypes.bool,
}

SingleAction.defaultProps = {}

export default SingleAction
