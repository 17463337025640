import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { t } from 'i18next'

import styles from './style.module.scss'

import ButtonDropdown from '@sweetspot/club-portal-legacy/components/ButtonDropdown'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

const EDIT = 1
const DELETE = 2

const actions = [
  { id: EDIT, label: 'players.edit' },
  { id: DELETE, label: 'settings.delete' },
]

export class Header extends Component {
  isValidForm = () => {
    const {
      isCreate,
      form: { firstName, lastName, email, password, confirmPass },
      errors,
    } = this.props

    if (!email) return false
    if (!Utilities.validateEmail(email)) return false
    if (isCreate && !password) return false
    if (password !== confirmPass) return false
    if (!_.isEmpty(errors)) return false
    if (!firstName || !lastName) return false

    return true
  }

  render() {
    const {
      hideActions,
      isCreate,
      form: { firstName, lastName, role },
      isLoading,
      isEdit,
      onCancel,
      onSave,
    } = this.props

    return (
      <div className={styles.container}>
        <h5>{!isCreate && `${firstName} ${lastName}`}</h5>
        <div className={styles.headerText}>
          <Text textId={role} />
        </div>

        <div className={styles.actionGroup}>
          {!isCreate && !hideActions && (
            <ButtonDropdown
              text="players.actions"
              values={actions}
              actionStyles={{ width: '100%' }}
              onClick={this.props.onClickActions}
            />
          )}
          {isEdit && (
            <div className={styles.btnGroup}>
              <div className={styles.cancel} onClick={onCancel}>
                <Text textId="cancel" />
              </div>
              <button
                className="system-button primary md-32"
                disabled={!this.isValidForm() || isLoading}
                onClick={onSave}
              >
                {t(isCreate ? 'create' : 'save')}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  hideActions: PropTypes.bool,
  isCreate: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  form: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onClickActions: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default Header
