import * as React from 'react'
import { cn } from '../../../utils'

const Badge = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'text-content-xs line-clamp-1 flex h-6 w-6 items-center justify-center rounded-full border bg-[#EF4444] p-0 text-[#FFFFFF]',
        className
      )}
      {...props}
    />
  )
)

export { Badge }
