export const colorKeys = {
  base: 'base',
  brand: 'brand',
  sand: 'sand',
  water: 'water',
  grass: 'grass',
  sunset: 'sunset',
  slate: 'slate',
  gray: 'gray',
  zinc: 'zinc',
  neutral: 'neutral',
  stone: 'stone',
  red: 'red',
  orange: 'orange',
  amber: 'amber',
  yellow: 'yellow',
  lime: 'lime',
  green: 'green',
  emerald: 'emerald',
  teal: 'teal',
  cyan: 'cyan',
  sky: 'sky',
  blue: 'blue',
  indigo: 'indigo',
  violet: 'violet',
  purple: 'purple',
  fuchsia: 'fuchsia',
  pink: 'pink',
  rose: 'rose',
} as const

export const fontFamilyKeys = {
  content: 'content',
  title: 'title',
  brand: 'brand',
  sans: 'sans',
} as const

export const backgroundColorKeys = {
  'base-clean': 'base-clean',
  'base-neutral': 'base-neutral',
  mono: 'mono',
  sand: 'sand',
  water: 'water',
  grass: 'grass',
  sunset: 'sunset',
  stone: 'stone',
} as const

export const borderColorKeys = {
  'stroke-subtle': 'stroke-subtle',
  'stroke-pale': 'stroke-pale',
  'stroke-clean': 'stroke-clean',
  'stroke-neutral': 'stroke-neutral',
  'stroke-stone': 'stroke-stone',
  'stroke-light': 'stroke-light',
  'stroke-focused': 'stroke-focused',
  'stroke-success': 'stroke-success',
  'stroke-warning': 'stroke-warning',
  'stroke-danger': 'stroke-danger',
  'stroke-info': 'stroke-info',
} as const

export const borderRadiusKeys = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  pill: 'pill',
} as const

export const fontSizeKeys = {
  'content-xs': 'content-xs',
  'content-sm': 'content-sm',
  'content-base': 'content-base',
  'content-lg': 'content-lg',
  'content-xl': 'content-xl',
  'brand-xs': 'brand-xs',
  'brand-sm': 'brand-sm',
  'brand-base': 'brand-base',
  'brand-lg': 'brand-lg',
  'brand-xl': 'brand-xl',
  'brand-2xl': 'brand-2xl',
  'brand-3xl': 'brand-3xl',
  'brand-4xl': 'brand-4xl',
  'brand-5xl': 'brand-5xl',
  'brand-6xl': 'brand-6xl',
} as const

export const marginKeys = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const

export const paddingKeys = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const

export const surfaceColorKeys = {
  primary: 'primary',
  'inverted-primary': 'inverted-primary',
  secondary: 'secondary',
  brand: 'brand',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
  clean: 'clean',
  bright: 'bright',
} as const

export const textColorKeys = {
  dark: 'dark',
  subtle: 'subtle',
  medium: 'medium',
  pale: 'pale',
  clean: 'clean',
  light: 'light',
  warning: 'warning',
  success: 'success',
  danger: 'danger',
  info: 'info',
} as const

export const touchHeightKeys = {
  'touch-height-sm': 'touch-height-sm',
  'touch-height-md': 'touch-height-md',
  'touch-height-lg': 'touch-height-lg',
  'touch-height-xl': 'touch-height-xl',
}

export const translucentColorKeys = {
  'light-900': 'light-900',
  'light-800': 'light-800',
  'light-700': 'light-700',
  'light-600': 'light-600',
  'light-500': 'light-500',
  'light-400': 'light-400',
  'dark-900': 'dark-900',
  'dark-800': 'dark-800',
  'dark-700': 'dark-700',
  'dark-600': 'dark-600',
  'dark-500': 'dark-500',
  'dark-400': 'dark-400',
} as const
