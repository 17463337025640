import style from '../../style.module.scss'
import { useTranslation } from 'react-i18next'

const NameField = ({ errors, triedToSubmit, register }) => {
  const { t } = useTranslation()
  return (
    <div className={triedToSubmit && errors.name ? style.errored : ''}>
      <span className={style.optionsText}>{t('editPeriodsNew.nameOfPeriod')}</span>
      <input
        {...register('name')}
        className={`ss-input ${style.inputOptions}`}
        type="text"
        autoFocus
        required
      />
      {triedToSubmit && <div className={style.error}>{errors.name}</div>}
    </div>
  )
}

export default NameField
