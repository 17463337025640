import i18n from 'i18next'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import utils from '@sweetspot/club-portal-legacy/helpers/Utilities'

const TRANSLATIONS = (lang) => ({
  required: GetTranslatedString(lang, 'errors.required'),
  minCharacters: {
    atleast: GetTranslatedString(lang, 'errors.minCharacters.atleast'),
    characters: GetTranslatedString(lang, 'errors.minCharacters.characters'),
  },
  maxCharacters: GetTranslatedString(lang, 'errors.maxCharacters'),
  minItems: {
    atleast: GetTranslatedString(lang, 'errors.minItems.atleast'),
    items: GetTranslatedString(lang, 'errors.minItems.items'),
    item: GetTranslatedString(lang, 'errors.minItems.item'),
  },
  maxNumber: GetTranslatedString(lang, 'errors.maxNumber'),
  maxPercent: GetTranslatedString(lang, 'errors.maxPercent'),
  minNumber: GetTranslatedString(lang, 'errors.minNumber'),
  minPercent: GetTranslatedString(lang, 'errors.minPercent'),
  mustBeAfterStartDate: GetTranslatedString(lang, 'errors.mustBeAfterStartDate'),
  mustBeLowerThanIncludingVAT: GetTranslatedString(lang, 'errors.mustBeLowerThanIncludingVAT'),
  mustBeTodayOrLater: GetTranslatedString(lang, 'errors.mustBeTodayOrLater'),
})

/**
 * Parses errors from yup and adds translated error strings
 *
 * @param {Object} err
 * @param {Array} err.inner
 * @param {string} lang
 */
export const parseErrors = (err, lang) =>
  new Promise((resolve, reject) => {
    const { inner } = err
    let errors = {}

    inner.forEach((error) => {
      let { path, type, params, message: errorMessage } = error

      if (!path.includes('days_and_time_in_week_checker')) {
        path = path.split('.configuration', 1)[0]
      }

      let message
      if (type === 'required') {
        message = `${TRANSLATIONS(lang).required}.`
      }

      if (type === 'min' && Array.isArray(params.value)) {
        message = `${TRANSLATIONS(lang).minItems.atleast} ${params.min} ${
          params.min === 1 ? TRANSLATIONS(lang).minItems.item : TRANSLATIONS(lang).minItems.items
        }.`
      }
      if (type === 'min' && typeof params.value === 'string') {
        message = `${TRANSLATIONS(lang).minCharacters.atleast} ${params.min} ${
          TRANSLATIONS(lang).minCharacters.characters
        }.`
      }
      if (type === 'max' && typeof params.value === 'string') {
        message = `${TRANSLATIONS(lang).maxCharacters} ${params.max}.`
      }

      if (type === 'min' && typeof params.value === 'number') {
        if (
          (errorMessage.includes && errorMessage?.includes('percentage')) ||
          (path.includes && path?.includes('percentage'))
        ) {
          message = `${TRANSLATIONS(lang).minPercent} ${100 * params.min}%.`
        } else {
          message = `${TRANSLATIONS(lang).minNumber} ${params.min}.`
        }
      }

      if (type === 'max' && typeof params.value === 'number') {
        if (
          (errorMessage.includes && errorMessage?.includes('percentage')) ||
          (path.includes && path?.includes('percentage'))
        ) {
          message = `${TRANSLATIONS(lang).maxPercent} ${100 * params.max}%.`
        } else {
          message = `${TRANSLATIONS(lang).maxNumber} ${params.max}.`
        }
      }

      if (type === 'atleastOneMustBeTrue') {
        message = `${TRANSLATIONS(lang).minItems.atleast} 1 ${TRANSLATIONS(lang).minItems.item}`
      }

      if (type === 'promotions-must-be-saved') {
        message = 'promotionsMustBeSaved'
      }
      if (type === 'invalid-rules-for-partnership-type') {
        message = 'invalidRulesForPartnershipType'
      }

      if (type === 'one-of-required') {
        message = 'Test'
      }

      if (type === 'fees-must-be-saved') {
        message = 'feesMustBeSaved'
      }

      if (type === 'must-be-higher-than-start-date') {
        message = `${TRANSLATIONS(lang).mustBeAfterStartDate}`
      }

      if (type === 'must-be-lower-than-including-vat') {
        message = `${TRANSLATIONS(lang).mustBeLowerThanIncludingVAT}`
      }

      if (type === 'must-be-today-or-later') {
        message = `${TRANSLATIONS(lang).mustBeTodayOrLater}`
      }

      if (type === 'matches') {
        message = i18n.t('errors.valueDoesNotMatchFormat') + '.'
      }

      if (type === 'typeError' && params.value === null) {
        message = i18n.t('errors.fieldRequired') + '.'
      }

      if (type === 'must-be-efter-from') {
        message = i18n.t('errors.mustBeAfterFrom')
      }

      if (type === 'typeError' && params?.type === 'number') {
        message = i18n.t('errors.fieldMustBeANumber')
      }

      if (message) {
        if (errors[path] && errors[path].message) {
          if (!errors[path].message.includes(message)) {
            errors[path].message = errors[path].message + ' ' + message
          }
        } else {
          errors[path] = {
            message,
            path,
          }
        }
      }
    })

    // convert to array
    const errorsArray = utils.objectToArray(errors)

    reject(errorsArray)
  })
