import { createContext, useMemo, FC, PropsWithChildren, useContext, useState } from 'react'

import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'

import { BallDispensersContextValue, DiscardDialogCallbacks } from './types'
import useBallDispensers from './hooks/useBallDispensers'

export const BallDispensersContext = createContext<BallDispensersContextValue>({
  areRangesLoading: false,
  ranges: [],
  statusInitialData: [],
  setupInitialData: { ballDispensers: [] },
  integrationInitialData: { ballDispensers: [] },
  areBallDispensersFetching: false,
  handleOutletActivation: () => {
    return
  },
})

export const BallDispensersProvider: FC<PropsWithChildren> = ({ children }) => {
  const { ranges, areRangesLoading, selectedRange, setSelectedRange, refetchRanges } = useRanges()
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false)
  const [discardDialogCallbacks, setDiscardDialogCallbacks] = useState<DiscardDialogCallbacks>()
  const {
    statusInitialData,
    setupInitialData,
    integrationInitialData,
    handleSetupSave,
    handleIntegrationSave,
    handleOutletActivation,
    areBallDispensersFetching,
  } = useBallDispensers({
    rangeId: selectedRange?.id,
    siteId: selectedRange?.range_servant_site_id,
    refetchRanges,
  })

  const value = useMemo(
    () => ({
      ranges,
      areRangesLoading,
      selectedRange,
      setSelectedRange,
      openDiscardDialog,
      setOpenDiscardDialog,
      statusInitialData,
      setupInitialData,
      integrationInitialData,
      handleSetupSave,
      handleIntegrationSave,
      handleOutletActivation,
      areBallDispensersFetching,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    }),
    [
      areRangesLoading,
      ranges,
      selectedRange,
      setSelectedRange,
      openDiscardDialog,
      setOpenDiscardDialog,
      statusInitialData,
      setupInitialData,
      integrationInitialData,
      handleSetupSave,
      handleIntegrationSave,
      handleOutletActivation,
      areBallDispensersFetching,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    ]
  )

  return <BallDispensersContext.Provider value={value}>{children}</BallDispensersContext.Provider>
}

export const useBallDispensersContext = () => useContext(BallDispensersContext)
