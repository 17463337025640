import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { isValidDate } from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import moment from 'moment'

import { ReactComponent as OptionsIcon } from '@sweetspot/sweetspot-js/assets/svgs/options-icon.svg'
import { ReactComponent as TrashIcon } from '@sweetspot/sweetspot-js/assets/svgs/trash-gray-icon.svg'
import OptionsMenu from '@sweetspot/sweetspot-js/common/components/OptionsMenu'

const SingleReservation = ({ reservation, onRemoveReservation, timezone }) => {
  const { t } = useTranslation()

  const date = useMemo(() => {
    if (!reservation?.hold_from) return ''
    const newDate = new Date(reservation.hold_from)
    if (!isValidDate(newDate)) return ''
    return moment(newDate).format('YYYY-MM-DD')
  }, [reservation])

  const startHour = useMemo(() => {
    if (!reservation?.hold_from) return ''
    const newDate = new Date(reservation.hold_from)
    if (!isValidDate(newDate)) return ''
    return moment(newDate).tz(timezone).format('HH:mm')
  }, [reservation, timezone])

  const toHour = useMemo(() => {
    if (!reservation?.hold_to) return ''
    const newDate = new Date(reservation.hold_to)
    if (!isValidDate(newDate)) return ''
    return moment(newDate).tz(timezone).format('HH:mm')
  }, [reservation, timezone])

  const quantity = useMemo(() => {
    return reservation?.inventory_course_schedules?.length || ''
  }, [reservation])

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.leftWrapper)}>
        <div className={cx(styles.infoWrapper)}>
          <p className={cx(styles.date)}>{date}</p>
          <p className={cx(styles.hours)}>
            {startHour} - {toHour}
          </p>
          <p className={cx(styles.quantity)}>
            {t('words.quantity')}: {quantity}
          </p>
        </div>
        <p className={cx(styles.note)}>{reservation?.note || ' '}</p>
      </div>
      <div className={cx(styles.rightWrapper)}>
        <span className={cx(styles.dropdownToggle)} id={`options-${reservation?.uuid}`}>
          <OptionsIcon />
        </span>
        <OptionsMenu
          elementQuery={`#options-${reservation?.uuid}`}
          options={[
            {
              icon: TrashIcon,
              label: t('words.remove'),
              onClick: () => onRemoveReservation(reservation),
            },
          ]}
        />
      </div>
    </div>
  )
}

SingleReservation.propTypes = {
  reservation: PropTypes.shape({
    hold_from: PropTypes.string,
    hold_to: PropTypes.string,
    id: PropTypes.number,
    inventory_course_schedules: PropTypes.arrayOf(PropTypes.string),
    note: PropTypes.string,
    uuid: PropTypes.string,
    uuid_string: PropTypes.string,
    warehouse: PropTypes.string,
  }).isRequired,
  onRemoveReservation: PropTypes.func.isRequired,
}

SingleReservation.defaultProps = {}

export default SingleReservation
