import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  cn,
  Divider,
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
  InputBase,
  InputContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { DemandStatus } from '@sweetspot/shared/data-access/api-platform'

import useTableHeader from '../hooks/useTableHeader'
import { statusColorMap } from '../constants'
import TableBodyRow from './TableBodyRow'
import { useBallPricingContext } from '../BallPricingProvider'

type Props = {
  isSchemeEditMode: boolean
  handleOnOpenBucketConfiguration: () => void
}

const SchemeTab = ({ isSchemeEditMode, handleOnOpenBucketConfiguration }: Props) => {
  const { t } = useTranslation()
  const {
    demandModel: {
      timeSections,
      statusMatrix,
      handleStatusChange,
      demandModelId,
      isDemandModelError,
      hasDemandEntries,
    },
  } = useBallPricingContext()

  const tableHeader = useTableHeader()

  const noDemand = useMemo(
    () => !demandModelId || isDemandModelError || !hasDemandEntries,
    [demandModelId, isDemandModelError, hasDemandEntries]
  )

  if (noDemand) {
    return (
      <div className="bg-background-base-neutral border-border-stroke-clean mb-4 flex h-full w-full items-center justify-center rounded-sm border">
        <EmptyState
          iconName="fa-regular fa-table"
          className={cn(
            '[&_i]:bg-background-mono-lighter [&_i]:mb-4 [&_i]:flex [&_i]:rounded-full [&_i]:p-[22px]'
          )}
        >
          <EmptyStateTitle>{t('sentences.noOpeningHoursTitle')}</EmptyStateTitle>
          <EmptyStateDescription>{t('sentences.noOpeningHoursText')}</EmptyStateDescription>
          <EmptyStateButton
            children={t('sentences.setOpeningHours')}
            onClick={handleOnOpenBucketConfiguration}
            variant="primary"
          />
        </EmptyState>
      </div>
    )
  }

  return (
    <div className="relative flex w-full flex-col">
      {tableHeader}
      <table>
        <tbody>
          {timeSections.map(([from, to], rowIndex) => {
            const isLastRow = rowIndex === timeSections.length - 1
            return (
              <TableBodyRow key={`${from}-${to}`} from={from} to={to} isLastRow={isLastRow}>
                {statusMatrix?.[rowIndex]?.map((statusObj, colIndex) => {
                  const isFirstColumn = colIndex === 0
                  const isLastColumn = colIndex === 6
                  const statusColorClass = demandModelId
                    ? statusColorMap[statusObj.status]
                    : 'bg-background-base-neutral'
                  const borderRightClass = isLastColumn ? 'border-r' : ''
                  const borderBottomClass = isLastRow ? 'border-b' : ''
                  const roundedBottomRightClass = isLastColumn && isLastRow ? 'rounded-br-sm' : ''

                  return (
                    <td key={colIndex} className="h-4 !w-[114px]">
                      <div
                        className={`border-Borders-stroke-clean h-full w-full ${statusColorClass} ${borderRightClass} ${borderBottomClass} ${roundedBottomRightClass}`}
                      >
                        <div
                          className={`flex h-full w-full items-center justify-center ${
                            !isLastColumn ? 'border-background-mono-lighter' : ''
                          }`}
                        >
                          {!isFirstColumn && demandModelId && (
                            <div className="bg-background-mono-lighter h-full w-[2px]" />
                          )}
                          {statusObj.status === DemandStatus.Closed && (
                            <div
                              className={`text-content-xs text-text-dark h-8 w-full border-2 border-transparent p-2 ${statusColorClass}`}
                            />
                          )}
                          {statusObj.status !== DemandStatus.Closed && (
                            <InputSelect
                              onValueChange={(newStatus: DemandStatus) =>
                                handleStatusChange?.(rowIndex, colIndex, newStatus)
                              }
                              value={statusObj.status}
                              disabled={!isSchemeEditMode}
                            >
                              <InputBase className={`w-full ${statusColorClass}`}>
                                <InputSelectTrigger asChild>
                                  <InputContainer>
                                    <InputSelectStatefulInput
                                      openClassName="rounded-none !border-border-stroke-light shadow-lg"
                                      className={`text-content-xs text-text-dark h-8 w-full border-2 border-transparent p-2 hover:${statusColorClass} ${statusColorClass}`}
                                      value={t(
                                        `words.${
                                          statusObj?.status === DemandStatus.Medium
                                            ? 'medium'
                                            : statusObj?.status
                                        }`
                                      )}
                                    />
                                    <InputSelectTrailingContainer />
                                  </InputContainer>
                                </InputSelectTrigger>
                              </InputBase>

                              <InputSelectContent className="rounded-none !shadow-lg">
                                <InputSelectContentView className={'rounded-none border-0'}>
                                  {Object.values(DemandStatus)
                                    .filter((status) => status !== DemandStatus.Closed)
                                    .map((status) => (
                                      <InputSelectItem
                                        key={status}
                                        value={status}
                                        className="rounded-none"
                                      >
                                        <ListItem
                                          className={`h-full justify-center gap-2 rounded-none pl-2 ${statusColorMap[status]}`}
                                        >
                                          <ListItemMainContent className="justify-center rounded-none">
                                            <ListItemParagraph className="text-content-xs text-text-dark">
                                              {t(
                                                `words.${
                                                  status === DemandStatus.Medium ? 'medium' : status
                                                }`
                                              )}
                                            </ListItemParagraph>
                                          </ListItemMainContent>
                                        </ListItem>
                                      </InputSelectItem>
                                    ))}
                                </InputSelectContentView>
                              </InputSelectContent>
                            </InputSelect>
                          )}
                          {!isLastColumn && demandModelId && (
                            <div className="bg-background-mono-lighter flex h-full w-[3px] items-center justify-end">
                              <Divider className="h-4" orientation="vertical" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  )
                })}
              </TableBodyRow>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default SchemeTab
