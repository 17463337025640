import { useMutation } from 'react-query'
import { track } from '@amplitude/analytics-browser'

import { PlayersSearchPayload, searchPlayers } from '@sweetspot/shared/data-access/api-platform'
// @ts-expect-error fetchBookings is not in typescript
import { fetchBookings } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { ViolationError, GolfCourseTypes, Player, Booking } from '@sweetspot/shared/types'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { toDateObject, isSameDate } from '@sweetspot/shared/util/date'

import { TeeSheetSearchParams, FilteredBooking, PlayerRelatedBookingsSearchParams } from '../types'
import { formatInTimeZone } from 'date-fns-tz'
import { isAPhoneNumber, isGolfId, validateEmail } from '@sweetspot/shared/util/validators'
import { differenceInDays } from 'date-fns'

export const useTeeSheetSearch = () => {
  /**
   * Filters the bookings based on certain conditions and returns an array of filtered bookings.
   *
   * @param bookings - The array of bookings to be filtered.
   * @param player - The player object used to filter the bookings.
   * @returns An array of filtered bookings.
   */
  const filterBookings = (
    bookings: Booking[],
    player: Player,
    currentDate: Date
  ): FilteredBooking[] => {
    const filteredBookings: FilteredBooking[] = []

    bookings
      .sort((a, b) =>
        new Date(a?.booking?.start_time) > new Date(b?.booking?.start_time) ? 1 : -1
      )
      .forEach((bookingObj: Booking) => {
        const { booking, course, uuid } = bookingObj || {}
        const start_time = booking?.start_time as unknown as string
        const isBookingCurrentDate = isSameDate(toDateObject(start_time), currentDate)
        const isBookingFuture = toDateObject(start_time) >= currentDate
        const isBookingPastMonth =
          toDateObject(start_time) < currentDate &&
          differenceInDays(currentDate, toDateObject(start_time)) <= 30

        if (
          course &&
          booking.status !== 'canceled' &&
          (isBookingCurrentDate || isBookingFuture || isBookingPastMonth)
        ) {
          const name = `${player?.first_name} ${player.last_name}`
          const golfId = player?.golf_id as string
          const cdhId = player?.cdh_id as string
          const date = formatInTimeZone(
            new Date(booking.start_time),
            course?.timezone,
            'dd-MM-yyyy - HH:mm'
          )
          const startTime = booking.start_time

          filteredBookings.push({
            id: booking.id,
            name,
            golfId,
            cdhId,
            date,
            startTime,
            courseUuid: course?.uuid,
            course: course.name,
            courseId: course.id,
            uuid,
          })
        }
      })

    return filteredBookings
  }

  const extractErrorMessageKey = (error: Error) => {
    return `toast.${error?.message}`
  }

  const constructPayload = (value: string, hasGolfId: boolean) => {
    const searchPayload: PlayersSearchPayload = {}
    if (isAPhoneNumber(value)) {
      searchPayload.phone = value
    } else if (validateEmail(value)) {
      searchPayload.email = value
    } else if (isGolfId(value) && hasGolfId) {
      searchPayload.golfId = value
    } else {
      searchPayload.fullName = value
    }
    return searchPayload
  }

  const searchForPlayers = useMutation<
    Player[],
    ViolationError,
    { searchPayload: PlayersSearchPayload }
  >({
    mutationFn: ({ searchPayload }) => {
      return searchPlayers(searchPayload)
    },
    onSuccess: async (players: Player[]) => {
      if (!players?.length) {
        throw new Error('playerNotFoundError')
      }
    },
  })

  const searchForPlayerRelatedBookings = useMutation<
    Booking[],
    ViolationError,
    PlayerRelatedBookingsSearchParams
  >({
    mutationFn: ({ playerUuid, courseUuid }) => {
      if (!playerUuid) return
      return fetchBookings({ 'customer.uuid': playerUuid, 'course.uuid': courseUuid })
    },
  })

  const searchTeeSheet = useMutation<FilteredBooking[], string, TeeSheetSearchParams>({
    mutationFn: async ({
      searchValue,
      searchPayload,
      courseType,
      courseUuid,
      currentDate,
    }: TeeSheetSearchParams) => {
      if (!searchValue) {
        throw new Error('invalidSearchError')
      }
      if (courseType === GolfCourseTypes.COURSE) {
        track(AMPLITUDE_EVENTS.COURSES_TEE_SHEET_SEARCHED)
      } else if (courseType === GolfCourseTypes.SIMULATOR) {
        track(AMPLITUDE_EVENTS.SIMULATORS_TEE_SHEET_SEARCHED)
      } else if (courseType === GolfCourseTypes.DRIVING_RANGE) {
        track(AMPLITUDE_EVENTS.RANGES_TEE_SHEET_SEARCHED)
      } else if (courseType === GolfCourseTypes.PRO) {
        track(AMPLITUDE_EVENTS.PRO_TEE_SHEET_SEARCHED)
      } else if (courseType === GolfCourseTypes.OTHER) {
        track(AMPLITUDE_EVENTS.OTHERS_TEE_SHEET_SEARCHED)
      }
      // Search for player
      const playersRes: Player[] = await searchForPlayers.mutateAsync({ searchPayload })

      const filteredBookingsRes = playersRes?.map(async (player: Player) => {
        const bookings = await searchForPlayerRelatedBookings.mutateAsync({
          playerUuid: player?.uuid,
          courseUuid,
        })
        const filteredBookingsPerPlayer = filterBookings(bookings, player, currentDate)
        return filteredBookingsPerPlayer
      })

      const filteredBookings = (await Promise.all(filteredBookingsRes)).reduce(
        (acc, playerBookings) => [...acc, ...playerBookings],
        []
      )

      if (!filteredBookings?.length) {
        throw new Error('bookingsNotFoundError')
      }
      return filteredBookings
    },
  })

  return {
    searchForPlayers,
    searchForPlayerRelatedBookings,
    searchTeeSheet,
    extractErrorMessageKey,
    constructPayload,
  }
}
