import * as React from 'react'
import { tv, type VariantProps } from 'tailwind-variants'
import { cn } from '../../../utils'

const HintVariantKeys = {
  Default: 'default',
  Error: 'error',
  Success: 'success',
  Disabled: 'disabled',
} as const

const hintVariants = tv({
  base: 'flex items-start self-stretch px-2 pt-0.5 gap-1',
  variants: {
    variant: {
      [HintVariantKeys.Default]: ['text-brand-black'],
      [HintVariantKeys.Error]: ['text-red-500'],
      [HintVariantKeys.Success]: ['text-brand-black'],
      [HintVariantKeys.Disabled]: ['text-text-subtle'],
    },
  },
  defaultVariants: {
    variant: HintVariantKeys.Default,
    disabled: false,
  },
})

export interface HintProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof hintVariants> {
  hintText: string
  disabled?: boolean
}

const getHintStateIcon = (variant: string) => {
  if (variant === 'error')
    return <i className="fa-regular fa-seal-exclamation" aria-hidden="true" />

  if (variant === 'success')
    return <i color="#22C55E" className="fa fa-circle-check" aria-hidden="true" />

  return <i className="fa-regular fa-circle-info" aria-hidden="true" />
}

const Hint = React.forwardRef<HTMLDivElement, HintProps>(
  ({ className, variant, hintText, disabled, ...props }, ref) => (
    <div
      data-testid="hint-component"
      className={cn(hintVariants({ variant, disabled, className }))}
      {...props}
      ref={ref}
    >
      <div className="flex h-[18px] w-[18px] content-center items-center">
        {variant && getHintStateIcon(variant)}
      </div>
      <div className="text-content-xs mt-[1px] flex-[1_0_0] text-left">{hintText}</div>
    </div>
  )
)

Hint.displayName = 'Hint'

export { Hint }
