import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { useExtraPlayerState } from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'

const ConfirmCancelModalWrapper = ({ orderBooking, render }) => {
  const { calculatedPriceResult } = useExtraPlayerState()

  const currencyCode = useMemo(() => {
    return orderBooking?.currency_code
  }, [orderBooking])

  const totalToPay = useMemo(() => {
    if (!orderBooking || !calculatedPriceResult) return ''
    const orderTotal = calculatedPriceResult.calculated_total
    return priceToLocal(orderTotal, currencyCode, true)
  }, [orderBooking, calculatedPriceResult, currencyCode])

  const discountTotal = useMemo(() => {
    let total = 0
    orderBooking.items.forEach((item) => (total -= item.adjustments_total))
    return priceToLocal(total, currencyCode, true)
  }, [orderBooking, currencyCode])

  const getSpaceTotalCents = (space) => {
    const items = orderBooking.items.filter((item) => item?.slot?.tee_time?.space?.id === space.id)

    let total = 0
    items.forEach((item) => (total += item.total))
    return total
  }

  const getSpaceTotal = (space) => {
    return priceToLocal(getSpaceTotalCents(space), currencyCode, true)
  }

  return render(totalToPay, discountTotal, getSpaceTotal)
}

ConfirmCancelModalWrapper.propTypes = {
  orderBooking: PropTypes.object,
  venue: PropTypes.object,
}

ConfirmCancelModalWrapper.defaultProps = {}

export default ConfirmCancelModalWrapper
