import { selectGolfCourse } from '@sweetspot/club-portal-legacy/store/actions'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import TimePeriodsContext from './context'

const useSelectedCourse = () => {
  const [globalState, setGlobalState] = useContext(TimePeriodsContext)
  const dispatch = useDispatch()

  const changeGolfCourse = (courseId) => {
    setGlobalState((state) => ({ ...state, isLoading: true }))
    dispatch(selectGolfCourse(courseId))
  }

  const { selectedCourse, isLoading } = globalState

  return { selectedCourse, isLoading, selectGolfCourse: changeGolfCourse }
}

export default useSelectedCourse
