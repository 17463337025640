import { CONSTANTS } from '../../constants'
import { initialState } from './initialState'
import {
  addItem,
  replaceAll,
  replaceOrRemove,
} from '@sweetspot/club-portal-legacy/store/reducers/helpers'
import {
  GolfCourseState,
  GolfCourseActionTypes,
  GolfCourseItem,
} from '@sweetspot/club-portal-legacy/store/types'

export default function golfCourse(
  state = initialState,
  action: GolfCourseActionTypes
): GolfCourseState {
  switch (action.type) {
    case CONSTANTS.GOLF_COURSE.GET_LIST:
      return Object.assign({}, state, {
        list: action.payload,
      })
    case CONSTANTS.GOLF_COURSE.GET_SPECIFIC:
      return Object.assign({}, state, {
        list: replaceOrRemove<GolfCourseItem>(
          state.list,
          (action.payload as GolfCourseItem).id,
          action.payload as GolfCourseItem
        ),
      })
    case CONSTANTS.GOLF_COURSE.GET_FROM_GOLF_CLUB:
      return Object.assign({}, state, {
        list: replaceAll<GolfCourseItem>(state.list, action.payload as GolfCourseItem[]),
      })
    case CONSTANTS.GOLF_COURSE.CREATE:
      return Object.assign({}, state, {
        list: addItem<GolfCourseItem>(state.list, action.payload as GolfCourseItem),
      })
    case CONSTANTS.GOLF_COURSE.UPDATE:
      return Object.assign({}, state, {
        list: replaceOrRemove<GolfCourseItem>(
          state.list,
          (action.payload as GolfCourseItem).id,
          action.payload as GolfCourseItem
        ),
      })
    case CONSTANTS.GOLF_COURSE.DELETE:
      return Object.assign({}, state, {
        list: replaceOrRemove<GolfCourseItem>(
          state.list,
          (action.payload as GolfCourseItem)?.id,
          action.payload as GolfCourseItem
        ),
      })
    case CONSTANTS.GOLF_COURSE.SELECT:
      return Object.assign({}, state, {
        selectedId: action.payload,
      })
    default:
      return state
  }
}
