import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { FeatureFlag } from './types'

export const getAllFeatureFlags = (): Promise<FeatureFlag> => {
  const url = `${getApiPlatformBaseUrl()}/features`
  return request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
