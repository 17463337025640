import { formatToDatePicker } from '@sweetspot/club-portal-legacy/utils/date'

const getDefaultValues = (periodsList) => {
  let startDate = new Date()
  const [latestPeriod] = periodsList

  if (latestPeriod) {
    startDate = new Date(latestPeriod.end_date)
    startDate.setDate(startDate.getDate() + 1)
  }

  return {
    is_golf_id_required: false,
    is_prime_time: false,
    week: 'All Period',
    slots: 1,
    interval: '',
    start_date: formatToDatePicker(startDate),
    end_date: formatToDatePicker(startDate),
    start_time_from: '00:00',
    start_time_to: '00:00',
    category_id: null,
    name: '',
  }
}

export default getDefaultValues
