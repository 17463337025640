import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import utils from '@sweetspot/club-portal-legacy/helpers/Utilities'

import RadioGroup from '@sweetspot/sweetspot-js/common/components/FormElements/RadioGroup'

import { RULE_TYPES, ACTION_TYPES } from '@sweetspot/club-portal-legacy/components/Wizard/constants'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'
import usePrevious from '@sweetspot/club-portal-legacy/hooks/usePrevious'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

const { floatToCents, centsToFloat, decimalPercentToNumber, numberToDecimalPercent } = utils

const getInitialRadioValue = (type) => {
  if (type === 'membership') {
    return 'no-limit'
  }
  if (type === 'partnershipPrepay') {
    return 'partner-play-value'
  }
  if (type === 'partnershipPostpay') {
    return 'partner-greenfee-discount'
  }
}

const radioValues = {
  noLimit: 'no-limit',
  onlyGreenfee: 'only-green-fee',
  numberOfRounds: 'number-of-rounds',
  playValue: 'play-value',
  partnerNumberOfRounds: 'partner-number-of-rounds',
  partnerPlayValue: 'partner-play-value',
  partnerFixedPrice: 'partner-fixed-price',
  partnerGreenfeeDiscount: 'partner-greenfee-discount',
}

const LimitationRule = ({
  promotion,
  onSetLimitationType,
  onSetRuleValue,
  onSetActionValue,
  onClearRule,
  onClearAction,
  errors,
  disabled,
  type,
}) => {
  const { t } = useTranslation()
  const [radioValue, setRadioValue] = useState(null)
  const prevPromotion = usePrevious(promotion)
  const [currencyCode] = useClubCurrency()

  useEffect(() => {
    if (!promotion) return
    if (
      promotion &&
      prevPromotion &&
      promotion.id &&
      prevPromotion.id &&
      promotion.id === prevPromotion.id
    )
      return
    const { id, limitationType } = promotion

    if (
      id &&
      typeof id === 'string' &&
      id.includes('tempId') &&
      radioValue !== getInitialRadioValue(type)
    ) {
      updateRadioValue(getInitialRadioValue(type))
      return
    }

    if (id && typeof id === 'number') {
      const { rules, actions } = promotion

      if (limitationType) {
        switch (limitationType) {
          case 'no_limit':
            setRadioValue(radioValues.noLimit)
            return
          case 'only_greenfee':
            setRadioValue(radioValues.onlyGreenfee)
            return
          case 'play_value':
            setRadioValue(radioValues.playValue)
            return
          case 'number_of_rounds':
            setRadioValue(radioValues.numberOfRounds)
            return
          default:
            break
        }
      }

      if (actions[ACTION_TYPES.playValue]) {
        setRadioValue(radioValues.playValue)
      } else if (rules[RULE_TYPES.numberOfRounds]) {
        setRadioValue(radioValues.numberOfRounds)
      } else if (actions[ACTION_TYPES.partnerPlayValue]) {
        setRadioValue(radioValues.partnerPlayValue)
      } else if (rules[RULE_TYPES.partnerNumberOfRounds]) {
        setRadioValue(radioValues.partnerNumberOfRounds)
      } else if (actions[ACTION_TYPES.partnerFixedPrice]) {
        setRadioValue(radioValues.partnerFixedPrice)
      } else if (actions[ACTION_TYPES.partnerDiscount]) {
        setRadioValue(radioValues.partnerGreenfeeDiscount)
      }
    }
    // TODO: Fix actual warning!
    // eslint-disable-next-line
  }, [promotion])

  const getError = () => {
    const { id: promotionId } = promotion

    if (radioValue === radioValues.noLimit) {
      return ''
    }
    if (radioValue === radioValues.onlyGreenfee) {
      return ''
    }
    if (radioValue === radioValues.numberOfRounds) {
      return errors[`promotions.${promotionId}.rules.${RULE_TYPES.numberOfRounds}`] || ''
    }
    if (radioValue === radioValues.playValue) {
      return errors[`promotions.${promotionId}.actions.${ACTION_TYPES.playValue}`] || ''
    }
    if (radioValue === radioValues.partnerNumberOfRounds) {
      return errors[`promotions.${promotionId}.rules.${RULE_TYPES.partnerNumberOfRounds}`] || ''
    }
    if (radioValue === radioValues.partnerPlayValue) {
      return errors[`promotions.${promotionId}.actions.${ACTION_TYPES.partnerPlayValue}`] || ''
    }
    if (radioValue === radioValues.partnerFixedPrice) {
      return errors[`promotions.${promotionId}.actions.${ACTION_TYPES.partnerFixedPrice}`] || ''
    }
    if (radioValue === radioValues.partnerGreenfeeDiscount) {
      return errors[`promotions.${promotionId}.actions.${ACTION_TYPES.partnerDiscount}`] || ''
    }

    return ''
  }

  const getInputValue = () => {
    const { rules, actions } = promotion

    // Number of rounds
    if (radioValue === radioValues.numberOfRounds && rules && rules[RULE_TYPES.numberOfRounds]) {
      return rules[RULE_TYPES.numberOfRounds].configuration.limitation_value
    }

    // Play Value
    if (radioValue === radioValues.playValue && actions && actions[ACTION_TYPES.playValue]) {
      return centsToFloat(actions[ACTION_TYPES.playValue].configuration.limitation_value)
    }

    // Partner number of rounds
    if (
      radioValue === radioValues.partnerNumberOfRounds &&
      rules &&
      rules[RULE_TYPES.partnerNumberOfRounds]
    ) {
      return rules[RULE_TYPES.partnerNumberOfRounds].configuration.limitation_value
    }

    // Partner play value
    if (
      radioValue === radioValues.partnerPlayValue &&
      actions &&
      actions[ACTION_TYPES.partnerPlayValue]
    ) {
      return centsToFloat(actions[ACTION_TYPES.partnerPlayValue].configuration.limitation_value)
    }

    // Partner fixed price
    if (
      radioValue === radioValues.partnerFixedPrice &&
      actions &&
      actions[ACTION_TYPES.partnerFixedPrice]
    ) {
      return centsToFloat(actions[ACTION_TYPES.partnerFixedPrice].configuration.fixed_price)
    }

    // Partner Greenfee discount
    if (
      radioValue === radioValues.partnerGreenfeeDiscount &&
      actions &&
      actions[ACTION_TYPES.partnerDiscount]
    ) {
      return decimalPercentToNumber(
        actions[ACTION_TYPES.partnerDiscount].configuration.percentage_coefficient
      )
    }

    return ''
  }

  const updateInputValue = (newValue) => {
    if (radioValue === radioValues.numberOfRounds) {
      onSetRuleValue(RULE_TYPES.numberOfRounds, { limitation_value: newValue || 0 })
    }
    if (radioValue === radioValues.playValue) {
      onSetActionValue(ACTION_TYPES.playValue, { limitation_value: floatToCents(newValue || 0) })
    }
    if (radioValue === radioValues.partnerNumberOfRounds) {
      onSetRuleValue(RULE_TYPES.partnerNumberOfRounds, { limitation_value: newValue || 0 })
    }
    if (radioValue === radioValues.partnerPlayValue) {
      onSetActionValue(ACTION_TYPES.partnerPlayValue, {
        limitation_value: floatToCents(newValue || 0),
      })
    }
    if (radioValue === radioValues.partnerFixedPrice) {
      onSetActionValue(ACTION_TYPES.partnerFixedPrice, {
        fixed_price: floatToCents(newValue || 0),
      })
    }
    if (radioValue === radioValues.partnerGreenfeeDiscount) {
      onSetActionValue(ACTION_TYPES.partnerDiscount, {
        percentage_coefficient: numberToDecimalPercent(newValue || 0),
      })
    }
  }

  const updateRadioValue = (newValue) => {
    // CLear all
    onClearRule(RULE_TYPES.numberOfRounds)
    onClearRule(RULE_TYPES.partnerNumberOfRounds)

    onClearRule(RULE_TYPES.noLimit)

    onClearAction(ACTION_TYPES.memberDiscount)
    onClearAction(ACTION_TYPES.partnerDiscount)
    onClearAction(ACTION_TYPES.partnerFixedPrice)
    onClearAction(ACTION_TYPES.playValue)
    onClearAction(ACTION_TYPES.partnerPlayValue)

    // Apply defaults
    if (newValue === radioValues.noLimit) {
      onSetActionValue(ACTION_TYPES.memberDiscount, { percentage_coefficient: 1 })
      onSetLimitationType('no_limit')
    }

    if (newValue === radioValues.onlyGreenfee) {
      onSetActionValue(ACTION_TYPES.memberDiscount, { percentage_coefficient: 0 })
      onSetLimitationType('only_greenfee')
    }

    if (newValue === radioValues.numberOfRounds) {
      onSetRuleValue(RULE_TYPES.numberOfRounds, { limitation_value: 0 })
      onSetActionValue(ACTION_TYPES.memberDiscount, { percentage_coefficient: 1 })
      onSetLimitationType('number_of_rounds')
    }

    if (newValue === radioValues.playValue) {
      onSetActionValue(ACTION_TYPES.playValue, { limitation_value: 0 })
      onSetLimitationType('play_value')
    }

    if (newValue === radioValues.partnerNumberOfRounds) {
      onSetRuleValue(RULE_TYPES.partnerNumberOfRounds, { limitation_value: 0 })
      onSetActionValue(ACTION_TYPES.partnerDiscount, { percentage_coefficient: 1 })
    }

    if (newValue === radioValues.partnerPlayValue) {
      onSetActionValue(ACTION_TYPES.partnerPlayValue, { limitation_value: 0 })
    }

    if (newValue === radioValues.partnerFixedPrice) {
      onSetActionValue(ACTION_TYPES.partnerFixedPrice, { fixed_price: 0 })
    }

    if (newValue === radioValues.partnerGreenfeeDiscount) {
      onSetActionValue(ACTION_TYPES.partnerDiscount, { percentage_coefficient: 0 })
    }

    setRadioValue(newValue)
  }

  if (type === 'membership') {
    return (
      <RadioGroup
        value={radioValue}
        label={t('wizard.limitations')}
        onChange={updateRadioValue}
        error={getError()}
        disabled={disabled}
        options={[
          {
            value: radioValues.noLimit,
            label: t('wizard.noLimitations'),
          },
          {
            value: radioValues.onlyGreenfee,
            label: t('wizard.onlyGreenFee'),
          },
          {
            value: radioValues.numberOfRounds,
            label: t('wizard.numberOfRounds'),
            inputFieldProps: {
              value: getInputValue(),
              onChange: (val) => updateInputValue(val),
              type: 'number',
              containerWidth: 'quarter',
            },
          },
          {
            value: radioValues.playValue,
            label: t('wizard.playValue'),
            inputFieldProps: {
              value: getInputValue(),
              onChange: (val) => updateInputValue(val),
              type: 'number',
              containerWidth: 'quarter',
              suffix: currencyCode,
            },
          },
        ]}
      />
    )
  }
  if (type === 'partnershipPrepay') {
    return (
      <RadioGroup
        value={radioValue}
        label={t('wizard.limitations')}
        onChange={updateRadioValue}
        error={getError()}
        disabled={disabled}
        options={[
          {
            value: radioValues.partnerNumberOfRounds,
            label: t('wizard.numberOfRounds'),
            inputFieldProps: {
              value: getInputValue(),
              onChange: (val) => updateInputValue(val),
              type: 'number',
              label: t('wizard.addRounds'),
            },
          },
          {
            value: radioValues.partnerPlayValue,
            label: t('wizard.playValue'),
            inputFieldProps: {
              value: getInputValue(),
              onChange: (val) => updateInputValue(val),
              type: 'number',
              label: t('wizard.addValue'),
              suffix: currencyCode,
            },
          },
        ]}
      />
    )
  }
  if (type === 'partnershipPostpay') {
    return (
      <RadioGroup
        value={radioValue}
        label={t('wizard.limitations')}
        onChange={updateRadioValue}
        error={getError()}
        disabled={disabled}
        options={[
          {
            value: radioValues.partnerFixedPrice,
            label: t('wizard.fixedPrice'),
            inputFieldProps: {
              value: getInputValue(),
              onChange: (val) => updateInputValue(val),
              type: 'number',
              label: t('wizard.price'),
              suffix: currencyCode,
            },
          },
          {
            value: radioValues.partnerGreenfeeDiscount,
            label: t('wizard.greenfeeDiscount'),
            inputFieldProps: {
              value: getInputValue(),
              onChange: (val) => updateInputValue(val),
              type: 'number',
              label: t('wizard.addDiscount'),
              suffix: '%',
            },
          },
        ]}
      />
    )
  }
}

LimitationRule.propTypes = {
  promotion: PropTypes.object,
  onSetLimitationType: PropTypes.func,
  onSetRuleValue: PropTypes.func,
  onSetActionValue: PropTypes.func,
  onClearRule: PropTypes.func,
  onClearAction: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['partnershipPrepay', 'partnershipPostpay', 'membership']),
}
LimitationRule.defaultProps = {
  promotion: {},
  onSetLimitationType: () => {},
  onSetRuleValue: () => {},
  onSetActionValue: () => {},
  onClearRule: () => {},
  onClearAction: () => {},
  errors: {},
  disabled: false,
  type: 'membership',
}

export default LimitationRule
