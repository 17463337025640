import { useMemo } from 'react'
import { useSelector } from 'react-redux'

interface WithCurrentClubUuidProps {
  currentClubUuid: string
}

export const withCurrentClubUuid = <P extends WithCurrentClubUuidProps>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    const { selectedId, list } = useSelector(
      (state: { golfClub: { list: { id: number; uuid: string }[]; selectedId: number } }) =>
        state.golfClub
    )

    const selectedUuid = useMemo(() => {
      if (selectedId) {
        return list.find((club) => club.id === selectedId)?.uuid
      }
    }, [selectedId, list])

    return <Component {...props} currentClubUuid={selectedUuid} />
  }
}
