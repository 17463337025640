import { Player } from '@sweetspot/shared/types'
import { selfAsync, to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { FC, useEffect, useMemo, useState } from 'react'

import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { queryPromotionCoupons } from '@sweetspot/sweetspot-js/features/players/services/api-platform'
import { useTranslation } from 'react-i18next'
import SingleVoucher from './SingleVoucher'
import styles from './styles.module.scss'
import { Voucher } from './types'

const TYPES = {
  active: 'active',
  canceled: 'canceled',
  expired: 'expired',
  upcoming: 'upcoming',
  consumed: 'consumed',
}

interface Props {
  playerInfo: Player
  selectedGolfClubId: number
}

const VoucherPlayerCard: FC<Props> = ({ playerInfo, selectedGolfClubId }) => {
  const [customerVouchers, setCustomerVouchers] = useState<Voucher[]>([])
  const [displayedCustomerVouchers, setDisplayedCustomerVouchers] = useState<Voucher[]>([])
  const { t } = useTranslation()

  const values = useMemo(
    () => [
      {
        name: t('settings.playerCard.voucher.filterList.all'),
        id: 0,
      },
      {
        name: t(`settings.playerCard.voucher.filterList.${TYPES.upcoming}`),
        id: 1,
      },
      {
        name: t(`settings.playerCard.voucher.filterList.${TYPES.active}`),
        id: 2,
      },
      {
        name: t(`settings.playerCard.voucher.filterList.${TYPES.consumed}`),
        id: 3,
      },
      {
        name: t(`settings.playerCard.voucher.filterList.${TYPES.expired}`),
        id: 4,
      },
      {
        name: t(`settings.playerCard.voucher.filterList.${TYPES.canceled}`),
        id: 5,
      },
    ],
    [t]
  )

  useEffect(() => {
    if (playerInfo && selectedGolfClubId) {
      selfAsync(async () => {
        const [customerVouchers, error] = await to(
          queryPromotionCoupons({ player: playerInfo?.id, 'promotion.club': selectedGolfClubId })
        )
        if (error) return
        setCustomerVouchers(customerVouchers)
        setDisplayedCustomerVouchers(customerVouchers)
      })
    }
  }, [playerInfo, selectedGolfClubId])

  const onVoucherTypeChange = (selectedId: number) => {
    const filteredVouchers = customerVouchers.filter((voucher: Voucher) => {
      switch (selectedId) {
        case 0:
          return true
        case 1:
          return voucher?.promotion?.status === TYPES.upcoming
        case 2:
          return voucher?.promotion?.status === TYPES.active
        case 3:
          return voucher?.promotion?.status === TYPES.consumed
        case 4:
          return voucher?.promotion?.status === TYPES.expired
        case 5:
          return voucher?.promotion?.status === TYPES.canceled
        default:
          return true
      }
    })
    setDisplayedCustomerVouchers(filteredVouchers)
  }

  return (
    <div className={'flex w-full flex-col gap-3 px-5 py-[15px]'}>
      {customerVouchers?.length > 0 ? (
        <>
          <DropdownSelect
            values={values}
            initialId={0}
            selectedId={0}
            onSelect={onVoucherTypeChange}
            containerClassName={'w-40'}
            showBorder
          />

          {displayedCustomerVouchers?.map((voucher) => (
            <SingleVoucher voucher={voucher} selectedGolfClubId={selectedGolfClubId} />
          ))}
        </>
      ) : (
        <div className={styles.container}>{t('settings.playerCard.voucher.noVouchers')}</div>
      )}
    </div>
  )
}

export default VoucherPlayerCard
