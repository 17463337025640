import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ItemBox from '../ItemBox'

const AssignBookingOwnerBox = ({ shouldRender, className, children, isOpen }) => {
  const { t } = useTranslation()

  if (shouldRender) {
    return (
      <ItemBox className={cx(styles.container, className, isOpen && styles.open)}>
        <div className={cx(styles.header)}>
          <h2 className={cx(styles.title)}>{t('sentences.bookingOwner')}</h2>
        </div>
        {children}
      </ItemBox>
    )
  } else {
    return null
  }
}

AssignBookingOwnerBox.propTypes = {
  shouldRender: PropTypes.bool,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
}

AssignBookingOwnerBox.defaultProps = {
  shouldRender: false,
  isOpen: false,
  className: '',
}

export default AssignBookingOwnerBox
