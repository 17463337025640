import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import {
  getElementDimensions,
  getElementRect,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import moment from 'moment'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import { useHistory } from 'react-router'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import PlayerName from '@sweetspot/club-portal-legacy/components/PlayerName'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import getBookingWithRelations from '@sweetspot/sweetspot-js/features/bookings/functions/getBookingWithRelations'
import {
  BOOKING_GROUPS,
  BOOKING_RELATIONS,
} from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { ITEM_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/itemRelations'

const BookingPreviewBox = ({ bookingUuid, venue, isBay, bays, onClickOutsideStatusChange }) => {
  const { t } = useTranslation()
  let history = useHistory()

  const { data: booking } = useQuery(
    [CLUB_QUERIES.SINGLE_BOOKING, bookingUuid],
    () =>
      getBookingWithRelations({
        bookingUuid: bookingUuid,
        deserialize: true,
        includedRelations: [
          BOOKING_RELATIONS.BOOKING,
          BOOKING_RELATIONS.ITEMS,
          BOOKING_RELATIONS.SPACES,
        ],
        includedBookingGroups: [
          BOOKING_GROUPS.PROMOTIONS,
          BOOKING_GROUPS.CUSTOMER,
          BOOKING_GROUPS.PARTNERSHIP,
        ],
        includedItemGroups: [ITEM_GROUPS.SLOT, ITEM_GROUPS.PROMOTION_COUPONS],
      }),
    {
      enabled: !!bookingUuid,
    }
  )

  const isMember = useMemo(() => {
    return booking?.items?.some((item) => item.slot?.is_member) || false
  }, [booking])

  const venueTimeZone = useMemo(() => {
    return venue?.timezone || null
  }, [venue])

  const customer = useMemo(() => {
    return booking?.customer || null
  }, [booking])

  const partnership = useMemo(() => {
    return booking?.partnership || null
  }, [booking])

  const stubName = useMemo(() => {
    if (!booking?.stub_player) return null
    const { name, email, phone } = booking.stub_player
    return name || email || phone || null
  }, [booking])

  const currencyCode = useMemo(() => {
    return booking?.currency_code
  }, [booking])

  const total = useMemo(() => {
    if (!booking) return 0
    return priceToLocal(booking.total, currencyCode, true)
  }, [booking, currencyCode])

  const goToBooking = () => {
    history.push(`/simulators-ranges/orders/${booking.uuid}`)
  }

  const spaces = useMemo(() => {
    const result = []
    if (booking && booking?.spaces) {
      booking?.spaces?.forEach((space) => {
        const total = booking.items.reduce((accu, item) => {
          if (item?.slot?.tee_time?.space?.id === space.id) {
            return accu + item.total
          } else {
            return accu
          }
        }, 0)
        const bay = bays?.find((bay) => bay.uuid === space.uuid)
        const name = isBay && bay ? `${t('words.bay_one')} ${bay.bay_number}` : space.name

        const spaceObj = {
          id: space.id,
          name,
          total: priceToLocal(total, booking?.currency_code, true),
        }
        result.push(spaceObj)
      })
    }

    return result
  }, [booking, bays, isBay, t])

  if (!booking) {
    return (
      <div className={styles.loading}>
        <PulseLoader showIf={true} dotClassName={styles.dot} />
      </div>
    )
  }

  return (
    <>
      <div className={cx(styles.header)}>
        <p>
          {moment(booking.booking.start_time).tz(venueTimeZone).format('HH:mm')}-
          {moment(booking.booking.end_time).tz(venueTimeZone).format('HH:mm')}
        </p>
        <span onClick={goToBooking} className={cx('material-icons md-light md-18')}>
          launch
        </span>
      </div>

      <div className={cx(styles.inner)}>
        <div className={cx(styles.row)}>
          <p className={cx(styles.text, styles.black, 'overflow-hidden')}>
            {!customer && !stubName && t('sentences.reservedWithoutOwner')}
            <PlayerName
              className={'block truncate'}
              slot={booking}
              onShowDetailsChange={(value) => onClickOutsideStatusChange(!value)}
            />
          </p>
          <div className={'flex gap-2'}>
            {isMember && (
              <div className={cx(styles.memberBox)}>
                <p>{t('words.member_one')}</p>
              </div>
            )}
            {partnership && (
              <div className={cx(styles.memberBox)}>
                <p>{t('words.partner')}</p>
              </div>
            )}
          </div>
        </div>
        <hr />
        {spaces?.map((space) => {
          return (
            <React.Fragment key={space.id}>
              <div className={cx(styles.row)}>
                <p className={cx(styles.text)}>{space.name}</p>
                <p className={cx(styles.text)}>{space.total}</p>
              </div>
              <hr />
            </React.Fragment>
          )
        })}
        <div className={cx(styles.row)}>
          <p className={cx(styles.text, styles.black)}>{t('words.total')}</p>
          <p className={cx(styles.text, styles.black)}>{total}</p>
        </div>
      </div>
    </>
  )
}

const BookingPreviewBoxContainer = (props) => {
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const boxRef = useRef(null)
  const [clickOutsideEnabled, setClickOutsideEnabled] = useState(true)

  useOnClickOutside(boxRef, () => {
    if (clickOutsideEnabled) {
      props.onClosePreview()
    }
  })

  useLayoutEffect(() => {
    const { top, left, right } = getElementRect(props.containerRef.current)
    const { height: boxHeight } = getElementDimensions(boxRef.current)
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth

    const elementIsOutsideBottomEdge = top + boxHeight > windowHeight
    const elementIsOutsideRightEdge = right + 288 > windowWidth

    const position = {
      ...(elementIsOutsideBottomEdge ? { bottom: 10 } : { top }),
      ...(elementIsOutsideRightEdge
        ? { left: left - 2 - 288, transformOrigin: 'top right' }
        : { left: right + 2 }),
    }

    setPosition(position)
  }, [props.containerRef, boxRef, props.visible])

  return (
    <Portal id={'booking-preview-box'}>
      <div
        className={cx(styles.container, props.visible && styles.visible)}
        style={{ position: 'fixed', ...position }}
        onClick={(e) => e.stopPropagation()}
        ref={boxRef}
      >
        {props.visible && (
          <BookingPreviewBox {...props} onClickOutsideStatusChange={setClickOutsideEnabled} />
        )}
      </div>
    </Portal>
  )
}

BookingPreviewBox.propTypes = {
  bookingUuid: PropTypes.string,
  venue: PropTypes.object,
}

BookingPreviewBoxContainer.propTypes = {
  className: PropTypes.string,
  bookingUuid: PropTypes.string,
  visible: PropTypes.bool,
  venue: PropTypes.object,
  containerRef: PropTypes.any,
  onClosePreview: PropTypes.func,
}

BookingPreviewBoxContainer.defaultProps = {
  className: '',
  onClosePreview: () => {},
}

export default BookingPreviewBoxContainer
