import { Switch, Route } from 'react-router-dom'

import Container from '@sweetspot/club-portal-legacy/components/Container'

import NotFound from '@sweetspot/club-portal-legacy/pages/NotFound'

import { Insights } from './Insights'
import { holisticFilters } from './holisticFilters'
import { AccessControlledRoute } from '@sweetspot/club-portal-legacy/components/AccessControlledRoute'

const InsightsRouter = (props: { match: { path: string } }) => {
  return (
    <Container>
      <Switch>
        {Object.values(holisticFilters).map((item) => {
          return (
            <AccessControlledRoute
              exact
              path={`${props.match.path}${item.path}`}
              accessTableKey={item.accessKey}
            >
              <Insights
                secretKey={item.secretKey}
                embedCode={item.embedCode}
                filter={item.filter}
                drillthroughs={item.drillthroughs}
              />
            </AccessControlledRoute>
          )
        })}
        <Route exact path={`${props.match.path}/*`} component={NotFound} />
      </Switch>
    </Container>
  )
}

export default InsightsRouter
