import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '@sweetspot/club-portal-legacy/components/Table'

import getHeaders from './headers'
import BallDispensersEmptyState from '../BallDispensersEmptyState'
import { useBallDispensersContext } from '../../BallDispensersProvider'
import { StatusBallDispenser } from '../../types'

const StatusTab = () => {
  const { t } = useTranslation()
  const { statusInitialData, handleOutletActivation } = useBallDispensersContext()
  const [rows, setRows] = useState<StatusBallDispenser[]>(statusInitialData || [])

  // update rows if statusInitialData changes
  useEffect(() => {
    setRows(statusInitialData || [])
  }, [statusInitialData])

  const onActivate = useCallback(
    (value: boolean, outletId: string) => {
      setRows((prev) =>
        prev.map((row) => {
          if (row.outletId === outletId) {
            return { ...row, status: value }
          }
          return row
        })
      )
      handleOutletActivation({ outletId, isActive: value })
    },
    [handleOutletActivation]
  )

  return (
    <div className="relative h-[calc(100%-61px)]">
      <Table
        headers={getHeaders(t, onActivate)}
        values={rows}
        fullWidth
        fullHeight
        hideScrollbar
        // needed to allow toggle click inside table cell
        onRowClick={() => {
          return
        }}
      />
      {statusInitialData?.length === 0 && (
        <div className="absolute bottom-0 left-0 right-0 top-8">
          <BallDispensersEmptyState />
        </div>
      )}
    </div>
  )
}

export default StatusTab
