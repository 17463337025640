import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, withRouter } from 'react-router'
import NewPeriodPage from './NewPeriodPage'
import PeriodDetailsPage from './PeriodDetailsPage'
import GolfCourseSelect from './components/GolfCourseSelect'
import PeriodList from './components/PeriodList'
import { useActivePeriod, usePeriodsList, useSelectedCourse, useTimePeriodsInit } from './context'
import style from './style.module.scss'

const TimePeriodsRoutes = ({ location, match, history }) => {
  const { t } = useTranslation()
  const { golfCourses, selectGolfCourse, token } = useTimePeriodsInit()
  const [activePeriod, setActivePeriod] = useActivePeriod()
  const { periodsList, deletePeriod } = usePeriodsList()
  const { selectedCourse } = useSelectedCourse()

  useEffect(() => {
    if (!periodsList) return

    const sanitizedPath = location.pathname.replace(/\/$/, '')
    const urlIdMatch = sanitizedPath.match(/(\/.+)$/)
    const urlId = urlIdMatch ? urlIdMatch[1].replace(`${match.path}/`, '') : null

    if (sanitizedPath === match.path) {
      if (periodsList.length > 0) {
        history.push(`${match.path}/${periodsList[0].id}`)
      }
      return
    }

    if (!urlId) return

    if (urlId === 'new') {
      if (activePeriod) {
        setActivePeriod(null)
      }
      return
    }

    const periodToBeSetAsActive = periodsList.find((period) => period.id === +urlId)

    if (!periodToBeSetAsActive) {
      history.push(match.path)
      return
    }

    if (activePeriod?.id !== periodToBeSetAsActive.id) {
      setActivePeriod(periodToBeSetAsActive)
    }
  }, [periodsList, history, location, match.path, setActivePeriod, activePeriod])

  if (!periodsList) return null

  const onDeletePeriod = async (periodToDelete) => {
    await deletePeriod(periodToDelete.uuid)
    if (periodToDelete.id === activePeriod?.id) {
      history.push(match.path)
    }
  }

  return (
    <div className={style.container}>
      <GolfCourseSelect
        token={token}
        width={265}
        golfCourses={golfCourses}
        selectedCourse={selectedCourse}
        selectGolfCourse={selectGolfCourse}
        left={true}
      />
      <div className={style.content}>
        <div className={style.left}>
          <div className={style.header}>
            <span className={style.title}>{t('editPeriodsNew.teeTimePeriods')}</span>
            <button
              className="system-button light md-32"
              onClick={() => history.push(`${match.path}/new`)}
            >
              <span className={style.createNewText}>{t('editPeriodsNew.createNew')}</span>
            </button>
          </div>
          <PeriodList
            newVersion={true}
            className={style.periodList}
            periods={periodsList}
            activePeriod={activePeriod}
            onSelectPeriod={({ id }) => history.push(`${match.path}/${id}`)}
            onSelectGap={() => {}}
            onDeletePeriod={onDeletePeriod}
          />
        </div>
        <div className={style.right}>
          <Switch>
            <Route exact path={`${match.path}/new`} component={NewPeriodPage} />
            <Route path={`${match.path}/:id`} component={PeriodDetailsPage} />
            <Route path={`${match.path}/`}></Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default withRouter(TimePeriodsRoutes)
