import { useMemo } from 'react'
import { Venue } from '@sweetspot/shared/types'

type UseAvailableCourseTypesProps = {
  courses: Venue[]
}

export type AvailableCourseTypes = { [key: string]: number }

export const useAvailableCourseTypes = ({
  courses,
}: UseAvailableCourseTypesProps): AvailableCourseTypes => {
  const availableCourseTypes = useMemo(() => {
    if (courses && courses.length) {
      return courses.reduce<AvailableCourseTypes>((acc, course) => {
        const { type, is_active } = course
        if (is_active) {
          acc[type] = (acc[type] || 0) + 1
        }
        return acc
      }, {})
    }
    return {}
  }, [courses])

  return availableCourseTypes
}
