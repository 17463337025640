import { Tooltip } from 'react-tooltip'
import MainCell from '../MainCell'

import style from './style.module.scss'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  courses: any[]
}

const RentalCell = ({ courses }: Props) => {
  if (!courses || !courses.length) return '-'

  if (courses.length === 1) return <MainCell value={courses[0].course.name} />

  return (
    <div className={style.container}>
      <div className={style.label} data-tooltip-id="grid-tooltip">
        <MainCell value=".multiple" />
      </div>

      <Tooltip id="grid-tooltip" place="bottom">
        <div className="rentalList">
          {courses.map((course) => (
            <li key={course.id}>
              <span>{course.course.name}</span>
              &nbsp;-&nbsp;
              <span>{course.on_hold}</span>
            </li>
          ))}
        </div>
      </Tooltip>
    </div>
  )
}

export default RentalCell
