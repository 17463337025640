import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'

import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'

import { useTranslation } from 'react-i18next'

import style from './style.module.scss'

/**
 * This component displays a popup with a message that can be **accepted** or **rejected**, or options
 */

const ConfirmPopup = ({
  visible,
  title,
  titleIcon: TitleIcon,
  overrideIconColor,
  titleIconSrc,
  text,
  textKey,
  children,
  onReject,
  onAccept,
  rejectText,
  rejectTextKey,
  acceptText,
  acceptProps,
  acceptTextKey,
  rejectTheme,
  rejectProps,
  acceptTheme,
  onClose,
  options,
  containerClassName,
}) => {
  const { t } = useTranslation()

  if (!visible) return null

  return (
    <Portal id="confirm-popup">
      <OverlayContainer>
        <div className={cx(style.container, style.containerVisible, containerClassName)}>
          <div className={cx(style.header)}>
            {TitleIcon && (
              <TitleIcon
                className={cx(style.titleIcon, {
                  [style.titleIconDefaultColor]: overrideIconColor,
                })}
              />
            )}
            {titleIconSrc && (
              <img alt="Title icon" src={titleIconSrc} className={style.titleIcon} />
            )}
            {title && <h3 className={style.headerTitle}>{title}</h3>}
            {onClose && <CloseIcon className={style.closeIcon} onClick={onClose} />}
          </div>
          <div className={cx(style.inner)}>
            {children ? (
              children
            ) : (
              <p
                className={cx(style.innerText)}
                dangerouslySetInnerHTML={{ __html: text || t(textKey) }}
              />
            )}
          </div>
          <div className={cx(style.footer)}>
            {options &&
              options.map(({ text, className, onClick, theme, buttonProps }, index) => (
                <Button
                  key={index}
                  size="medium"
                  className={cx(style.button, className)}
                  width="auto"
                  theme={theme || 'default'}
                  text={text}
                  onClick={onClick}
                  {...buttonProps}
                />
              ))}
            {onReject ? (
              <Button
                size="medium"
                className={style.button}
                width="auto"
                theme={rejectTheme || 'none'}
                text={rejectText || (rejectTextKey ? t(rejectTextKey) : false) || t('words.no')}
                onClick={onReject}
                {...rejectProps}
              />
            ) : null}
            {onAccept ? (
              <Button
                className={style.button}
                width="auto"
                size="medium"
                theme={acceptTheme || 'danger'}
                text={acceptText || (acceptTextKey ? t(acceptTextKey) : false) || t('words.yes')}
                onClick={onAccept}
                {...acceptProps}
              />
            ) : null}
          </div>
        </div>
      </OverlayContainer>
    </Portal>
  )
}

ConfirmPopup.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  titleIcon: PropTypes.elementType,
  overrideIconColor: PropTypes.bool,
  titleIconSrc: PropTypes.string,
  text: PropTypes.string,
  textKey: PropTypes.string,
  onReject: PropTypes.func,
  onAccept: PropTypes.func,
  rejectText: PropTypes.string,
  rejectTextKey: PropTypes.string,
  acceptText: PropTypes.string,
  acceptTextKey: PropTypes.string,
  rejectTheme: PropTypes.oneOf(['default', 'danger', 'green', 'none']),
  acceptTheme: PropTypes.oneOf(['default', 'danger', 'green', 'none']),
  onClose: PropTypes.func,
  acceptProps: PropTypes.object,
  rejectProps: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
      theme: PropTypes.string,
      buttonProps: PropTypes.object,
    })
  ),
  children: PropTypes.any,
  containerClassName: PropTypes.string,
}

ConfirmPopup.defaultProps = {
  visible: false,
  rejectTheme: 'none',
  acceptTheme: 'danger',
  onClose: () => {},
  overrideIconColor: true,
  children: null,
  containerClassName: '',
}

export default ConfirmPopup
