import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import PromotionsManager from '@sweetspot/sweetspot-js/features/promotions/containers/PromotionsManager'
import { useSelector } from 'react-redux'

const PromotionsSettings = ({ membershipId }) => {
  const clubId = useSelector((state) => state?.golfClub?.selectedId)

  return (
    <div className={cx(styles.container)}>
      <PromotionsManager
        context="membership"
        membershipId={membershipId}
        clubId={clubId}
        restrictWidth={false}
      />
    </div>
  )
}

PromotionsSettings.propTypes = {
  membershipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PromotionsSettings.defaultProps = {}

export default PromotionsSettings
