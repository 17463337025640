import { forwardRef, HTMLAttributes } from 'react'
import { cn } from '@sweetspot/scramble-ds'
import Dock from './Dock'

const ButtonDock = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { dockClassName?: string; isShown?: boolean }
>(({ className, dockClassName, isShown, ...props }, ref) => (
  <Dock className={dockClassName} isShown={isShown}>
    <div
      ref={ref}
      className={cn(
        'p-lg flex flex-col-reverse gap-2 md:flex-row md:justify-end md:gap-0 md:space-x-2',
        className
      )}
      {...props}
    />
  </Dock>
))

export default ButtonDock
