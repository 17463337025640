import i18n from 'i18next'

export const priceToLocal = (price = 0, currency = 'SEK', trailingZeros = false): string => {
  let minimumFractionDigits: number | undefined

  if (trailingZeros || Math.floor(price) === price) {
    minimumFractionDigits = 0
  } else {
    minimumFractionDigits = 2
  }

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits,
  }

  if (currency) {
    options.style = 'currency'
    options.currency = currency
  }

  return new Intl.NumberFormat(i18n.language, options).format(price / 100)
}
