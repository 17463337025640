import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import DropdownSelect from '@sweetspot/sweetspot-js/common/components/DropdownSelect2'

const options = [
  { id: 'days', name: 'Days' },
  { id: 'hours', name: 'Hours' },
]

const TimeBeforeChecker = ({
  checker,
  onSetTimePeriodBefore,
  onSetIgnoreParticipant,
  onSaveChecker,
  disabled,
  timeBeforeError,
  onTimeUnitChange,
  maxNumberInput,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={cx(styles.row)}>
        <div className="flex flex-col">
          <p className="m-0 mb-[4px] h-auto p-0 text-xs font-semibold leading-4 ">
            {t('sentences.timePeriodBeforeTeeTimeBookable')}
          </p>
          <div className="flex flex-row gap-x-2">
            <TextInputField
              containerClassName={cx(styles.textInputField, '!w-[70px]')}
              type="number"
              inputProps={{
                className: cx(styles.inputField),
                max: maxNumberInput,
                min: 0,
              }}
              value={checker?.configuration?.number}
              onChange={(val) => onSetTimePeriodBefore(val, checker?.configuration?.type)}
              error={timeBeforeError}
              onEnter={() => onSaveChecker()}
              onInputBlur={() => onSaveChecker()}
              disabled={disabled}
            />
            <DropdownSelect
              containerClassName="!w-[100px] h-[32px]"
              values={options}
              initialId={checker?.configuration?.type}
              isOpen
              selectedId={checker?.configuration?.type}
              onSelect={(id) => {
                onTimeUnitChange(id)
              }}
              showBorder
            />
          </div>
        </div>
      </div>
      <ToggleButton
        containerClassName={cx(styles.fromTimeToggle)}
        secondaryLabel={t('sentences.ignoreWhenAddedAsParticipant')}
        containerWidth={'w-full'}
        value={checker?.configuration?.ignore_when_adding_participant}
        onChange={(val) => onSetIgnoreParticipant(val)}
      />
    </>
  )
}

TimeBeforeChecker.propTypes = {
  checker: PropTypes.object,
  onSetTimePeriodBefore: PropTypes.func,
  onSetIgnoreParticipant: PropTypes.func,
  onSaveChecker: PropTypes.func,
  disabled: PropTypes.bool,
  timeBeforeError: PropTypes.string,
}

TimeBeforeChecker.defaultProps = {}

export default TimeBeforeChecker
