import { AppState, AppActionTypes } from '@sweetspot/club-portal-legacy/store/types'
import { CONSTANTS } from '../../constants'
import { initialState } from './initialState'

export default function user(state = initialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case CONSTANTS.APP_STATE.ADD_TOAST:
    case CONSTANTS.APP_STATE.SET_MODE:
    case CONSTANTS.APP_STATE.SET_LOADING:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}
