import {
  parseISO,
  format,
  intervalToDuration,
  add,
  differenceInDays,
  isValid,
  sub,
  differenceInHours,
  subDays,
} from 'date-fns'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { SECONDS_IN_DAY } from './consts'
import { Booking, PaymentLink } from '@sweetspot/shared/types'
import { BookingFields } from './types'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'

export const calculateAmount = (amount: string, percentage: number) => {
  const validPercentage = Math.min(Math.max(percentage, 0), 100)

  return Math.round(Number(amount) * (validPercentage / 100))
}

export const calculateCreatedDate = (createdAtDate: string) => {
  try {
    const parsedDate = parseISO(createdAtDate)
    if (!isValid(parsedDate)) {
      throw new RangeError('Invalid time value')
    }
    return format(parsedDate, 'd MMM yyyy - HH:mm')
  } catch (error) {
    return 'Invalid Date'
  }
}

export const calculatePercentage = (amount: string, value: number) => {
  const numericAmount = Number(amount)

  if (numericAmount === 0) {
    return 0
  }

  return Math.round((value / numericAmount) * 100)
}

export const calculateTimeLeft = (futureDateString: string) => {
  const now = new Date()
  const futureDate = parseISO(futureDateString)

  if (futureDate <= now) {
    return '0m Left'
  }

  const duration = intervalToDuration({ start: now, end: futureDate })

  const durationParts = []

  if (duration.months) {
    durationParts.push(`${duration.months}m`)
  }

  if (duration.days) {
    durationParts.push(`${duration.days}d`)
  }

  if (duration.hours) {
    durationParts.push(`${duration.hours}h`)
  }

  durationParts.push(`${duration.minutes}m Left`)

  return durationParts.join(' ')
}
export const copyToClipboard = ({
  text,
  onSuccess,
  onError,
}: {
  text: string
  onSuccess: () => void
  onError?: () => void
}) => {
  navigator.clipboard.writeText(text).then(onSuccess).catch(onError)
}

export const splitCurrencyAmount = (priceString: string) => {
  const trimmedPrice = priceString.trim()

  // eslint-disable-next-line no-useless-escape
  const match = trimmedPrice.match(/^([^\d\s]+)?\s*([\d,\.]+)\s*([^\d\s]+)?$/)

  if (!match) {
    return { amount: '', currency: '' }
  }

  const currency = match[1] ? match[1].trim() : match[3] ? match[3].trim() : ''
  const amount = match[2] ? match[2].replace(/,/g, '') : ''

  return { amount, currency }
}

export const extractBookingFields = (booking: Booking) => {
  const paymentId =
    booking?.payments?.find((payment) => payment.state === 'new' || payment.state === 'processing')
      ?.uuid ?? ''
  const ownerItem = booking?.items?.find((item) => item.slot?.is_owner)

  const ownerEmail = ownerItem?.slot?.player?.email ?? ownerItem?.slot?.stub_player?.email ?? ''
  const ownerPhone = ownerItem?.slot?.player?.phone ?? ownerItem?.slot?.stub_player?.phone ?? ''

  const totalUnpaidAmount = booking.payments
    .filter((payment) => payment.state === 'new' || payment.state === 'processing')
    .reduce((total, payment) => total + payment.amount, 0)

  const priceString = priceToLocal(totalUnpaidAmount, booking?.currency_code, false)
  const { currency, amount } = splitCurrencyAmount(priceString)

  return { paymentId, ownerEmail, ownerPhone, currency, amount } as BookingFields
}

export const formatDeliveryMethod = (email: string, phone: { phone_number: string }) => {
  let result = ''

  if (email && phone && phone.phone_number.length !== 0) {
    result = `Email & SMS (${phone.phone_number})`
  } else if (email) {
    result = 'Email'
  } else if (phone && phone.phone_number.length !== 0) {
    result = `SMS (${phone.phone_number})`
  }

  return result
}

export const secondsToDays = (seconds: number) => {
  return seconds / SECONDS_IN_DAY
}

export const calculateExpiringDate = (days: number) => {
  const newDateUTC = add(new Date(), { days })

  return formatInTimeZone(newDateUTC, 'UTC', 'yyyy-MM-dd HH:mm:ss')
}

export const calculateExpiringDateBackwards = (days: number, hours: number, booking: Booking) => {
  const latestExpiryDate = parseISO(booking?.booking?.start_time)

  const adjustedDateUTC = sub(latestExpiryDate, { days: days, hours: hours })

  return formatInTimeZone(adjustedDateUTC, 'UTC', 'yyyy-MM-dd HH:mm:ss')
}

export const calculateTotalAmount = (paymentResults: PaymentLink[], currencyCode: string) => {
  const totalAmount = paymentResults.reduce(
    (acc, result) => acc + (result?.payment?.amount || 0),
    0
  )

  const priceString = priceToLocal(totalAmount, currencyCode, false)
  const { currency, amount } = splitCurrencyAmount(priceString)

  return { currency, amount }
}

export const calculateDuration = (expiresAt: string, timeZone: string) => {
  const expiresAtFormatted = parseISO(expiresAt)

  return `Until ${formatInTimeZone(expiresAtFormatted, timeZone, 'dd MMM yyyy, HH:mm')}`
}

export const calculateDaysDifferenceBetweenStartAndExpiryDate = (
  startTime: Date,
  timeZone: string
): { daysDifference: number; hoursDifference: number } => {
  const startDateInTimeZone = utcToZonedTime(parseISO(startTime), timeZone)

  const currentDateInTimeZone = utcToZonedTime(new Date(), timeZone)

  const daysDifference = differenceInDays(startDateInTimeZone, currentDateInTimeZone)

  const remainingTimeAfterDays = subDays(startDateInTimeZone, daysDifference)

  const hoursDifference = differenceInHours(remainingTimeAfterDays, currentDateInTimeZone)

  return { daysDifference, hoursDifference }
}

export const formatBookingStartTime = (startTime: Date, timeZone: string): string => {
  const parsedDate = parseISO(startTime)
  return formatInTimeZone(parsedDate, timeZone, 'dd MMM yyyy, HH:mm')
}

export const getTagVariant = (
  state: string,
  isExpired: boolean | undefined,
  isActive: boolean | undefined
) => {
  if (state === 'new') {
    return isExpired || !isActive ? 'danger-filled' : 'warning-filled'
  }
  if (state === 'completed') {
    return 'success-filled'
  }
  if (state === 'canceled') {
    return 'danger-filled'
  }
  return 'warning-filled'
}

export const getTagLabel = (
  state: string,
  isExpired: boolean | undefined,
  isActive: boolean | undefined,
  t: (key: string) => string
) => {
  if (state === 'new') {
    if (isExpired) return t('words.expired')
    if (!isActive) return t('paymentLinks.deactivated')
    return t('words.unpaid')
  }
  if (state === 'completed') return t('words.paid')
  if (state === 'refunded') return t('words.refunded')
  if (state === 'canceled') return t('words.canceled')
  return state
}
