import { _deleteBookingPeriod } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'
import { useContext } from 'react'
import { useMutation } from 'react-query'
import TimePeriodsContext from './context'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions'
import { useDispatch } from 'react-redux'

const usePeriodsList = () => {
  const allTableData = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')
  const [globalState, setGlobalState] = useContext(TimePeriodsContext)
  const { periodsList, isLoading, lang } = globalState
  const dispatch = useDispatch()

  const setPeriodsList = (periods) => {
    setGlobalState((state) => ({ ...state, periodsList: periods }))
  }

  const deletePeriodMutation = useMutation(
    (uuid) => _deleteBookingPeriod(uuid, lang).then(() => uuid),
    {
      onSuccess: (uuid) => {
        const newPeriodList = [...periodsList]
        const index = newPeriodList.findIndex((period) => period.uuid === uuid)
        const [period] = newPeriodList.splice(index, 1)
        setPeriodsList(newPeriodList)
        dispatch(addToast('deletePeriodSuccess', 'success'))
        delete allTableData[period.id]
        localStorage.setItem('ALL_TABLE_DATA', JSON.stringify(allTableData))
      },
      onError: (err) => {
        let errMsg = 'deletePeriodError'
        if (err?.violations?.length) {
          errMsg = err.violations[0].message
        }
        dispatch(addToast(errMsg, 'error'))
      },
    }
  )

  const deletePeriod = async (uuid) => {
    deletePeriodMutation.mutate(uuid)
  }

  return { periodsList, setPeriodsList, deletePeriod, isLoading }
}

export default usePeriodsList
