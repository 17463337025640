import { StandardHours } from '@sweetspot/club-portal-legacy/components/Warehouse/OpeningHours/StandardHours'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { useOpeningHours } from '@sweetspot/club-portal/feature/warehouse'
import { useTranslation } from 'react-i18next'

export interface OpeningHoursProps {
  warehouseUuid: string
}

const OpeningHours = (props: OpeningHoursProps) => {
  const { t } = useTranslation()

  const { useEnableCustomOpeningHours, useGetOpeningHours } = useOpeningHours({
    warehouseUuid: props.warehouseUuid,
  })

  const { data, isFetching } = useGetOpeningHours()
  const mutation = useEnableCustomOpeningHours()

  return (
    <div className="h-full w-full">
      <div className="flex h-full w-full items-center justify-center">
        {isFetching && <PulseLoader showIf />}
      </div>
      {!isFetching && !data?.length && (
        <div>
          <ToggleButton
            value={data?.length > 0}
            containerWidth="auto"
            disabled={false}
            onChange={() => mutation.mutate()}
            noMargin
            secondaryLabel={t('warehouse.openingHours.label_enable_custom_hours')}
          ></ToggleButton>
        </div>
      )}
      {!isFetching && data?.length > 0 && <StandardHours warehouseUuid={props.warehouseUuid} />}
    </div>
  )
}

export { OpeningHours }
