import country_en from './country_en.json'
import country_sv from './country_sv.json'

export function getCountries(lang) {
  switch (lang) {
    case 'en':
      return country_en
    case 'se':
    case 'sv':
    default:
      return country_sv
  }
}
