import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Input,
  InputBase,
  InputContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectSearchableContent,
  InputSelectContentHeader,
  InputSelectSearchableItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectSearchableTrigger,
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { GolfClubSelectProps } from './types'
import { Club } from '@sweetspot/shared/types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

const GolfClubSelect = ({ golfClubs, selectGolfClub }: GolfClubSelectProps) => {
  const { selectedId, list } = golfClubs
  const history = useHistory()
  const isUpdatingSelectedClub = useSelector((state: RootState) => state?.golfClub?.isUpdating)

  const dispatch = useDispatch()
  const [selectedClubId, setSelectedClubId] = useState(selectedId)
  const [searchTerm, setSearchTerm] = useState('')
  const [itemsToShow, setItemsToShow] = useState(20)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const itemRefs = useRef<HTMLDivElement[]>([])
  const { t } = useTranslation()
  const location = useLocation()
  const isInitialRender = useRef(true)

  const toptracerSiteId = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('trms_site_id') || undefined
  }, [location.search])

  const { data } = useQuery(
    [CLUB_QUERIES.RANGES, toptracerSiteId],
    () => queryRanges({ toptracerSiteId }),
    {
      enabled: !!toptracerSiteId,
    }
  )

  const handleKeyDown = (event: React.KeyboardEvent, index: number, clubId: string) => {
    event.stopPropagation()

    switch (event.key) {
      case 'ArrowUp':
        if (index > 0) {
          itemRefs.current[index - 1].focus()
        }
        break
      case 'ArrowDown':
        if (index < itemRefs.current.length - 1) {
          itemRefs.current[index + 1].focus()
        }
        break
      case 'Enter':
        handleSelect(clubId)
        break
      default:
        break
    }
  }
  const golfClubList = list?.map(({ id, uuid, name }: Club) => ({ id, uuid, name })) as Club[]

  const filteredClubs = useMemo(
    () =>
      golfClubList
        .filter(({ name }: { name: string }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(0, itemsToShow),
    [golfClubList, searchTerm, itemsToShow]
  )

  const handleSelect = useCallback(
    (clubId: string | number, redirect = true) => {
      selectGolfClub(Number(clubId), false, redirect)
      setSelectedClubId(Number(clubId))
    },
    [selectGolfClub]
  )

  useEffect(() => {
    if (!isUpdatingSelectedClub && selectedClubId !== selectedId) {
      setSelectedClubId(selectedId)
    }
  }, [handleSelect, isUpdatingSelectedClub, selectedClubId, selectedId])

  useEffect(() => {
    if (
      !isUpdatingSelectedClub &&
      isInitialRender.current &&
      golfClubList.length > 0 &&
      (!toptracerSiteId || data?.ranges)
    ) {
      const params = new URLSearchParams(window.location.search)
      const clubId = params.get('club_id')

      if (toptracerSiteId) {
        const range = data?.ranges.find((range) => range.toptracer_site_id === toptracerSiteId)
        const foundClub = range && golfClubList.find((club) => club.uuid === range?.organization_id)
        if (foundClub) {
          if (foundClub?.id !== selectedClubId) {
            handleSelect(foundClub.id, false)
          }
        } else {
          history.push('/404', null)
        }
      } else if (clubId) {
        const foundClub = golfClubList.find((club) => club.uuid === clubId)
        if (foundClub) {
          if (foundClub?.id !== selectedClubId) {
            handleSelect(foundClub.id, false)
          }
        } else {
          history.push('/404')
        }
      }

      isInitialRender.current = false
    }
  }, [
    isUpdatingSelectedClub,
    data?.ranges,
    dispatch,
    golfClubList,
    handleSelect,
    history,
    selectedClubId,
    toptracerSiteId,
  ])

  const lastItemRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (observerRef.current) observerRef.current.disconnect()
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems()
        }
      })
      if (node) observerRef.current.observe(node)
    },
    [itemsToShow]
  )

  const loadMoreItems = () => {
    setItemsToShow(itemsToShow + 20)
  }

  if (!golfClubs || golfClubs.list.length <= 1) return null

  return (
    <div className="z-50 flex items-center justify-center">
      <InputSelect withSearch>
        <InputBase className="max-w-[320px]">
          <InputSelectSearchableTrigger asChild>
            <InputContainer className="cursor-pointer">
              <InputSelectStatefulInput
                value={golfClubList.find(({ id }: Club) => id === selectedClubId)?.name}
                onChange={() => null}
                onClick={() => setSearchTerm('')}
                className="text-ellipsis pl-4 pr-8"
                readOnly
              />
              <InputSelectTrailingContainer />
            </InputContainer>
          </InputSelectSearchableTrigger>
        </InputBase>
        <InputSelectSearchableContent>
          <div className="bg-background-mono-lighter">
            <InputBase className="w-full p-4">
              <InputContainer>
                <InputLeadingContainer>
                  <i className="fa-regular fa-magnifying-glass" />
                </InputLeadingContainer>
                <Input
                  rounding="pill"
                  className="px-10"
                  placeholder={t('searchGolfClubs')}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                    setItemsToShow(20)
                  }}
                />
              </InputContainer>
            </InputBase>
          </div>
          <InputSelectContentHeader>{t('booking.golfClubs')}</InputSelectContentHeader>
          {filteredClubs.map((club: Club, index: number) => (
            <div
              tabIndex={0}
              key={club.id}
              ref={(el) => (itemRefs.current[index] = el as HTMLDivElement)}
              onKeyDown={(e) => handleKeyDown(e, index, club.id)}
              className=" focus-visible:border-border-stroke-subtle mx-0 px-0 focus-visible:border-2"
            >
              <InputSelectSearchableItem
                value={club.id.toString()}
                onSelect={handleSelect}
                ref={index === itemsToShow - 1 ? lastItemRef : null}
              >
                <ListItem className="ml-2 h-full justify-center gap-2">
                  <ListItemLeading>
                    <i className="fa-kit fa-tee-light" />
                  </ListItemLeading>
                  <ListItemMainContent className="justify-center">
                    <ListItemParagraph className="text-content-base">{club.name}</ListItemParagraph>
                  </ListItemMainContent>
                  <ListItemTrailing>
                    {selectedClubId === club.id && <i className="fa-regular fa-check h-4 w-4" />}
                  </ListItemTrailing>
                </ListItem>
              </InputSelectSearchableItem>
            </div>
          ))}
        </InputSelectSearchableContent>
      </InputSelect>
    </div>
  )
}

export default GolfClubSelect
