import { useLayoutEffect, useState } from 'react'
import { getElementRect, getElementDimensions } from '../functions/utils'

const usePositionFixedElement = (
  options = {
    containerRef: null,
    elementRef: null,
    isVisible: false,
    elementWidth: null,
    anchorPoint: 'top-right',
    direction: 'down-right',
  }
) => {
  const { containerRef, elementRef, isVisible, elementWidth, direction, anchorPoint } = options
  const [position, setPosition] = useState({ top: 0, left: 0 })

  useLayoutEffect(() => {
    if (!containerRef || !elementRef || !isVisible) return

    const { top, left, right } = getElementRect(containerRef.current)
    const { height: containerHeight } = getElementDimensions(containerRef.current)
    const { height: boxHeight, width: boxWidth } = getElementDimensions(elementRef.current)

    const width = elementWidth || boxWidth
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth

    let elementIsOutsideBottomEdge = false
    let elementIsOutsideRightEdge = false
    let topPosition
    let leftPosition

    if (anchorPoint === 'top-right' && direction === 'down-right') {
      topPosition = top
      leftPosition = right + 2
      elementIsOutsideBottomEdge = top + boxHeight > windowHeight
      elementIsOutsideRightEdge = right + width > windowWidth
    }

    if (anchorPoint === 'bottom-right' && direction === 'down-left') {
      topPosition = top + containerHeight + 2
      leftPosition = right - width
      elementIsOutsideBottomEdge = top + boxHeight > windowHeight
      elementIsOutsideRightEdge = right + width > windowWidth
    }

    const position = {
      ...(elementIsOutsideBottomEdge ? { bottom: 10 } : { top: topPosition }),
      ...(elementIsOutsideRightEdge
        ? { left: left - 2 - width, transformOrigin: 'top right' }
        : { left: leftPosition }),
    }

    setPosition(position)
  }, [containerRef, elementRef, isVisible, elementWidth, anchorPoint, direction])

  return position
}

export default usePositionFixedElement
