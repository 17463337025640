export const CONSTANTS = {
  USER: {
    LOGIN: 'user/LOGIN',
    LOGOUT: 'user/LOGOUT',
    UPDATE_STATUS: 'user/UPDATE_STATUS',
  },
  APP_STATE: {
    ADD_TOAST: 'appState/ADD_TOAST',
    SET_MODE: 'appState/SET_MODE',
    SET_LOADING: 'appState/SET_LOADING',
  },
  GOLFCLUBS: {
    FETCH_FROM_STORAGE: 'golfclubs/FETCH_FROM_STORAGE',
    UPDATE_STATUS: 'golfclubs/UPDATE_STATUS',
    UPDATE_GOLFCLUBS: 'golfclubs/UPDATE_GOLFCLUBS',
    SELECT_GOLFCLUB: 'golfclubs/SELECT_GOLFCLUB',
    SELECT_GOLFCOURSE: 'golfclubs/SELECT_GOLFCOURSE',
    CREATE_GOLFCLUB_STATUS: 'golfclubs/CREATE_GOLFCLUB_STATUS',
    CREATE_GOLFCOURSE_STATUS: 'golfclubs/CREATE_GOLFCOURSE_STATUS',
    STATUS_STRINGS: 'golfclubs/STATUS_STRINGS',
  },
  TEE_TIME: {
    UPDATE_STATUS: 'tee_time/UPDATE_STATUS',
    UPDATE_CURRENT_GOLFCOURSE_TEE_TIMES: 'tee_time/UPDATE_CURRENT_GOLFCOURSE_TEE_TIMES',
  },
  MEMBER: {
    UPDATE_MEMBERS: 'member/UPDATE_MEMBERS',
  },
  // New
  AUTH: {
    LOGIN: 'auth/LOGIN',
    LOGOUT: 'auth/LOGOUT',
    VALIDATE_TOKEN: 'auth/VALIDATE_TOKEN',
    ME: 'auth/ME',
    FETCH_ME_FROM_STORAGE: 'auth/FETCH_ME_FROM_STORAGE',
    MY_ROLES: 'auth/MY_ROLES',
    FETCH_MY_ROLES_FROM_STORAGE: 'auth/FETCH_MY_ROLES_FROM_STORAGE',
    SET_LANGUAGE: 'auth/SET_LANGUAGE',
  },
  GOLF_CLUB: {
    GET_LIST: 'golf_club/GET_LIST',
    GET_SPECIFIC: 'golf_club/GET_SPECIFIC',
    FETCH_FROM_STORAGE: 'golf_club/FETCH_FROM_STORAGE',
    CREATE: 'golf_club/CREATE',
    UPDATE: 'golf_club/UPDATE',
    DELETE: 'golf_club/DELETE',
    SELECT: 'golf-club/SELECT',
    UPDATE_STATE: 'golf-club/UPDATE_STATE',
  },
  GOLF_COURSE: {
    GET_LIST: 'golf_course/GET_LIST',
    GET_SPECIFIC: 'golf_course/GET_SPECIFIC',
    GET_FROM_GOLF_CLUB: 'golf_course/GET_FROM_GOLF_CLUB',
    CREATE: 'golf_course/CREATE',
    UPDATE: 'golf_course/UPDATE',
    DELETE: 'golf_course/DELETE',
    SELECT: 'golf_course/SELECT',
  },
  PRICING: {
    UPDATE_STATUS: 'pricing/UPDATE_STATUS',
    UPDATE_PRICING_PERIODS: 'pricing/UPDATE_PRICING_PERIODS',
    UPDATE_PRICE_PERIOD: 'pricing/UPDATE_PRICE_PERIOD',
    UPDATE_PRICE_CHART: 'pricing/UPDATE_PRICE_CHART',
    CREATE_PRICE_PERIOD: 'pricing/CREATE_PRICE_PERIOD',
    CREATE_PRICE_PERIOD_MODULES: 'pricing/CREATE_PRICE_PERIOD_MODULES',
    GET_PRICE_WIZARD: 'pricing/GET_PRICE_WIZARD',
  },
  MEMBERSHIP: {
    CREATE_MEMBERSHIPGROUP: 'membership/CREATE_MEMBERSHIPGROUP',
    DELETE_MEMBERSHIPGROUP: 'membership/DELETE_MEMBERSHIPGROUP',
    CREATE_MEMBERSHIP: 'membership/CREATE_MEMBERSHIP',
    DELETE_MEMBERSHIP: 'membership/DELETE_MEMBERSHIP',
    ADD_GOLFCOURSE: 'membership/ADD_GOLFCOURSE',
    DELETE_GOLFCOURSE: 'membership/DELETE_GOLFCOURSE',
    ASSIGN_GROUP: 'membership/ASSIGN_GROUP',
    SET_LIST: 'membership/GET_LIST',
    ADD_LIST: 'membership/ADD_LIST',
    SET_TOTAL_PAGES: 'membership/SET_TOTAL_PAGES',
    SET_TOTAL_LIST: 'membership/SET_TOTAL_LIST',
    GET_PREDEFINED_RULES: 'membership/GET_PREDEFIND_RULES',
    SET_FEDERATION_MEMBERSHIPS: 'membership/SET_FEDERATION_MEMBERSHIPS',
  },
  PARTNERSHIP: {
    SET_LIST: 'partnership/GET_LIST',
    SET_TOTAL_PAGES: 'partnership/SET_TOTAL_PAGES',
    SET_TOTAL_LIST: 'partnership/SET_TOTAL_LIST',
  },
  BOOKING_PERIOD: {
    GET_LIST: 'booking_period/GET_LIST',
  },
}
