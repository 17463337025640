import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IBookingSteps, IStepProps } from './types'
import { BookingSteps } from '@sweetspot/club-portal-legacy/pages/SimulatorsRangesOrder/types'

export const BookingTitles = ({ activeBookingStep }: IBookingSteps): JSX.Element => {
  const { t } = useTranslation()
  const stepProps: Array<IStepProps> = useMemo(
    () => [
      {
        id: 1,
        name: BookingSteps.BOOKING_DETAILS,
        title: t('sentences.bookingDetails'),
      },
      {
        id: 2,
        name: BookingSteps.EDIT_SPACES,
        title: t('sentences.editSpaces'),
      },
      {
        id: 3,
        name: BookingSteps.MOVE_BOOKING,
        title: t('sentences.moveBooking'),
      },
    ],
    [t]
  )

  const activeStep = useMemo(
    () => stepProps.find((step) => step.name === activeBookingStep),
    [activeBookingStep, stepProps]
  )

  return (
    <div
      className={
        'text-content-xl absolute left-1/2 flex flex-1 -translate-x-1/2 justify-center font-bold'
      }
    >
      {activeStep?.title || ''}
    </div>
  )
}
