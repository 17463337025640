import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/**
 * Generate a Hubspot token
 */
export const generateHubspotToken = (): Promise<unknown> => {
  const url = `${getApiPlatformBaseUrl()}/chat/hubspot/token`

  return request(url)
}
