import cx from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as ClearIcon } from '@sweetspot/club-portal-legacy/resources/images/clear-icon.svg'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import TimePicker from 'rc-time-picker'

import { useTranslation } from 'react-i18next'
import style from './style.module.scss'

const MIN_MINUTES_PREPARATION_TIME = 1
const MAX_MINUTES_PREPARATION_TIME = 10000

const CourseItem = ({ disabled, course, onDelete, onChange }) => {
  const { t } = useTranslation()

  const { productAvailability } = course

  const handleMinutesInput = (val) => {
    if (!isNaN(val) && val >= MIN_MINUTES_PREPARATION_TIME && val <= MAX_MINUTES_PREPARATION_TIME) {
      onChange(course.id, 'minutes', val)
    } else if (val > MAX_MINUTES_PREPARATION_TIME) {
      onChange(course.id, 'minutes', MAX_MINUTES_PREPARATION_TIME)
    } else {
      onChange(course.id, 'minutes', MIN_MINUTES_PREPARATION_TIME)
    }
  }

  return (
    <div className={cx(style.row)}>
      <p className={cx(style.column)}>{course.name}</p>

      <div className={style.timePicker}>
        <TimePicker
          placeholder="hh:mm"
          value={productAvailability?.time}
          showSecond={false}
          onChange={(moment) => onChange(course.id, 'time', moment)}
        />
      </div>
      <div className={style.minutes}>
        <TextInputField
          type="number"
          containerWidth="quarter"
          suffix={t('wizard.minutesShortForm')}
          value={productAvailability?.minutes}
          onChange={(val) => handleMinutesInput(val)}
        />
      </div>
      <div
        onClick={() => onDelete(course.id)}
        className={cx(style.column, style.clearIcon, {
          [style.disabled]: disabled,
        })}
      >
        <ClearIcon />
      </div>
    </div>
  )
}

CourseItem.propTypes = {
  course: PropTypes.object,
  lang: PropTypes.string,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
}

CourseItem.defaultProps = {
  course: {},
  disabled: false,
  onDelete: () => {},
  onChange: () => {},
}

export default CourseItem
