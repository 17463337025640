import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import {
  UpdateBucketConfigurationPayload,
  RangeWithConfiguration,
  RangeSchedulePayload,
  UpdateRangeSchedulePayload,
} from './types'

export const queryBucketConfiguration = (rangeId?: string): Promise<RangeWithConfiguration> => {
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/configuration`
  return request(URL) as Promise<RangeWithConfiguration>
}

export function updateBucketConfiguration(
  payload: UpdateBucketConfigurationPayload
): Promise<void> {
  const { rangeId, bucketConfiguration } = payload

  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/configuration`
  return request(URL, {
    method: 'PUT',
    contentType: 'application/json',
    body: bucketConfiguration,
  })
}

export const queryRangeSchedule = (rangeId?: string): Promise<RangeSchedulePayload> => {
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/schedule`
  return request(URL) as Promise<RangeSchedulePayload>
}

export function updateRangeSchedule(payload: UpdateRangeSchedulePayload): Promise<void> {
  const { rangeId, schedule } = payload

  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/schedule`
  return request(URL, {
    method: 'PUT',
    contentType: 'application/json',
    body: schedule,
  })
}
