import type { Middleware } from 'openapi-fetch'
import {
  getApiPlatformBaseUrl,
  getCurrentAppName,
  getNodeEnv,
  to,
} from '@sweetspot/shared/util/functions'
import { clearLocalStorage } from '@sweetspot/shared/util/local-storage'
import { APP_NAMES } from '@sweetspot/shared/util/constants'
import {
  clearAllStoredTokens,
  getStoredAuthToken,
  getStoredRefreshToken,
  storeAuthToken,
  storeRefreshToken,
} from './auth'

// Exporting in case you want to catch and match the error somewhere else
export enum AUTH_MIDDLEWARE_ERRORS {
  RefreshFailed = 'Auth refresh failed, redirect user to login page',
}

interface RefreshReponse {
  refresh_token: string | undefined
  token: string | undefined
}

let refreshingTokenPromise: Promise<RefreshReponse> | null = null

export const handleAuthError = (currentAppName = getCurrentAppName()) => {
  // eslint-disable-next-line no-console -- Test env only
  if (getNodeEnv() === 'test') console.warn('Auth error handler called')

  if (currentAppName === APP_NAMES.CLUB_PORTAL) {
    clearAllStoredTokens()
    clearLocalStorage()
    if (!window.location.pathname.includes('/login')) window.location.assign('/login')
  } else if (currentAppName === APP_NAMES.PARTNER_PORTAL) {
    clearAllStoredTokens()
    clearLocalStorage()
    if (!window.location.pathname.includes('/login')) window.location.assign('/login')
  } else if (currentAppName === APP_NAMES.WEB_BOOKING) {
    clearAllStoredTokens()
    // Do nothing more because want users to be able to view app without login
  }
}

enum AUTH_ENDPOINTS {
  ClubUsersLogin = '/auth/club-users/login',
  PlayersLogin = '/auth/players/login',
  RefreshToken = '/auth/token/refresh',
}
const isLoginPath = (schemaPath: string) =>
  schemaPath.startsWith(AUTH_ENDPOINTS.ClubUsersLogin) ||
  schemaPath.startsWith(AUTH_ENDPOINTS.PlayersLogin)

export const apiPlatformAuthMiddleware: Middleware = {
  async onRequest({ request, schemaPath }) {
    if (refreshingTokenPromise !== null) {
      // Wait for refresh before continue
      await refreshingTokenPromise
    }

    if (isLoginPath(schemaPath)) {
      // Reset everything on login call
      refreshingTokenPromise = null
      clearAllStoredTokens()
    }

    const authToken = getStoredAuthToken()
    if (authToken) request.headers.set('Authorization', `Bearer ${authToken}`)

    return request
  },
  async onResponse({ response, schemaPath, request }) {
    // Ignore 401 on login paths because we want to show the error message to user
    if (response.status === 401 && !isLoginPath(schemaPath)) {
      // Check if refresh in progress
      if (refreshingTokenPromise !== null) {
        // Await refresh promise before continue
        const [, err] = await to(refreshingTokenPromise)
        if (err) {
          // Refresh failed, don't retry
          return undefined
        }

        // Retry request with new token
        const newAuthToken = getStoredAuthToken()
        request.headers.set('Authorization', `Bearer ${newAuthToken}`)
        return fetch(request)
      }

      const refreshToken = getStoredRefreshToken()

      // No token, no bueno
      if (!refreshToken) {
        handleAuthError()
        return undefined
      }

      // Create promise so other requests can await
      refreshingTokenPromise = fetch(`${getApiPlatformBaseUrl()}/auth/token/refresh`, {
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          'Accept-Language': 'en',
        },
      })
        .then(async (res) => {
          // Refresh failed, Reject
          if (!res.ok) return Promise.reject(new Error(AUTH_MIDDLEWARE_ERRORS.RefreshFailed))
          // Get res data
          const data = (await res.json()) as RefreshReponse
          // No data, Reject
          if (!data || !data?.token || !data?.refresh_token) {
            return Promise.reject(new Error(AUTH_MIDDLEWARE_ERRORS.RefreshFailed))
          }
          // Clear old
          clearAllStoredTokens()
          // Store new
          storeAuthToken(data.token)
          storeRefreshToken(data.refresh_token)
          return Promise.resolve(data)
        })
        .catch((error) => {
          handleAuthError()
          return Promise.reject(error)
        })

      const [res] = await to(refreshingTokenPromise)
      // Clear the promise so other awaiting requests can retry
      refreshingTokenPromise = null

      // Retry request with new token
      if (res) {
        const newAuthToken = getStoredAuthToken()
        request.headers.set('Authorization', `Bearer ${newAuthToken}`)
        return fetch(request)
      }
      return undefined
    }

    return undefined
  },
}
