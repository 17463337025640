import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Country } from '@sweetspot/shared/types'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import style from './style.module.scss'

type Props = {
  id: number
  disabled?: boolean
  value: string | boolean | number | null
  fieldName: string
  gender: string | null
  handleClick: (id: number) => void
  countries: Country[]
}

const DisplayField: FC<Props> = ({
  id,
  disabled,
  value,
  fieldName,
  gender,
  countries,
  handleClick,
}) => {
  const { t } = useTranslation()

  const fieldContent = useMemo(() => {
    if (fieldName === 'country') {
      return countries.find((country) => country.code === value)?.name || '-'
    }
    if (fieldName === 'gender') {
      return gender ? t(`settings.playerCard.${gender}`) : '-'
    }
    if (fieldName === 'birthday') {
      return value ? DateHelpers.onlyDateString(value) : '-'
    }
    if (typeof value === 'string') return value || '-'
    if (typeof value === 'boolean') return value ? t('yes') : t('no')
    return '-'
  }, [countries, fieldName, gender, t, value])

  return (
    <div
      className={!disabled ? style.field : undefined}
      onClick={() => !disabled && handleClick(id)}
    >
      {fieldContent}
    </div>
  )
}

export default DisplayField
