import { forwardRef } from 'react'
import { useSideSystemNavStore } from '../state'
import { cn } from '../../../../utils'

type BottomNavContentProps = React.HTMLAttributes<HTMLDivElement>
const BottomNavContent = forwardRef<HTMLDivElement, BottomNavContentProps>(
  ({ children, className, ...props }, ref) => {
    const { isExpanded, bottomNavIsActive } = useSideSystemNavStore()
    return (
      <div
        data-test-id="bottom-nav-content"
        className={cn(
          `bg-sand-50 relative mt-auto grid h-auto max-h-[50%] flex-shrink-0 basis-auto translate-y-0 grid-flow-row auto-rows-[minmax(48px,_auto)] shadow-[0_-12px_50px_-12px_rgba(77,65,12,0.15)]`,
          'transition-[max-height,_width] duration-300 hover:will-change-[max-height,_width]',
          {
            'w-[52px]': !isExpanded,
            'w-[256px]': isExpanded,
            'max-h-[100%] overflow-y-auto overflow-x-hidden [&::-webkit-scrollbar]:hidden':
              bottomNavIsActive && isExpanded,
          },
          className
        )}
        ref={ref}
        {...props}
      >
        <div className="absolute left-0 top-0 h-px w-full border-b border-gray-300" />
        {children}
      </div>
    )
  }
)
BottomNavContent.displayName = 'BottomNavContent'

export { BottomNavContent }
