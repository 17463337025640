import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { BayTypeV2 } from '@sweetspot/shared/types'

import Summary from './Summary'
import DetailsView from './Details/DetailsView'

const BayDetails = () => {
  const { t } = useTranslation()
  const { openBaySideBar, selectedBays, toggleBaySideBar, changeBaySideBarMode } = useBaysContext()

  const selectedBay = useMemo<BayTypeV2 | undefined>(
    () => selectedBays && selectedBays[0],
    [selectedBays]
  )

  const { title, submitButtonTitle } = useMemo(() => {
    return {
      title: t('settings.bays.bayDetails'),
      submitButtonTitle: `${t('settings.bays.editBay')} ${selectedBay?.bay_number}`,
    }
  }, [selectedBay?.bay_number, t])

  if (!selectedBay) {
    return null
  }

  return (
    <Sheet open={openBaySideBar} onOpenChange={(open) => toggleBaySideBar(BaySideBarMode.VIEW)}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={() => toggleBaySideBar()}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Summary bayNumber={selectedBay.bay_number} />
          <DetailsView
            floor={selectedBay.floor}
            stance={selectedBay.stance}
            roof={selectedBay.amenities?.roof}
            trackingTech={selectedBay.ball_tracking_technology?.tracking_provider}
            bayNumber={selectedBay.bay_number}
            display={selectedBay.ball_tracking_technology?.display}
            screenMode={selectedBay.ball_tracking_technology?.screen_mode}
            trmsNumber={selectedBay.ball_tracking_technology?.api_details?.trms_bay_number}
          />
        </SheetCustomContent>
        <SheetFooter>
          <Button onClick={() => changeBaySideBarMode(BaySideBarMode.EDIT)} className="w-full">
            {submitButtonTitle}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default BayDetails
