import { ACCESS_TABLE } from '../constants/accessTable'
import { ROLES_ARR } from '../constants/roles'

/**
 *
 * @param {Array} roles
 * @param {Array} userRoles
 * @returns {Bool}
 */
export const hasRole = (roles, userRoles) => {
  const currentUserRoles = userRoles.map((r) => r?.name || r)

  if (currentUserRoles.some((r) => roles.includes(r))) return true
  return false
}

/**
 * Returns first matching role from user roles as proper role constant
 *
 * @param {String[]|String} userRoles
 * @returns
 */
export const getFirstMatchingRole = (userRoles) => {
  let inputRoles
  if (!userRoles) return null
  if (!Array.isArray(userRoles)) {
    inputRoles = [userRoles]
  } else {
    inputRoles = userRoles
  }
  return ROLES_ARR.find((r) => inputRoles.some((r2) => r2 === r.value || r2?.name === r.value))
}

/**
 * Check if key exists in specified role access table
 *
 * @param {string} key
 * @param {string} role
 * @returns
 */
export const hasAccess = (key, role) => {
  if (ACCESS_TABLE?.[role]?.includes(key)) return true
  return false
}
