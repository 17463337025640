import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import {
  UpdateDemandModelPayload,
  DemandModel,
  CreateDemandModelPayload,
  PriceModel,
} from './types'

export const queryDemandModel = (rangeId?: string): Promise<DemandModel> => {
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/demand-model`
  return request(URL) as Promise<DemandModel>
}

export const createDemandModel = (payload: CreateDemandModelPayload): Promise<DemandModel> => {
  const { rangeId, demand_entries } = payload
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/demand-models`
  return request(URL, {
    method: 'POST',
    contentType: 'application/json',
    body: { demand_entries },
  })
}

export function updateDemandModel(payload: UpdateDemandModelPayload): Promise<DemandModel> {
  const { rangeId, demandModel } = payload
  const { demand_entries } = demandModel

  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/demand-model`
  return request(URL, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: { demand_entries },
  })
}

export const queryPriceModelTabs = (rangeId?: string): Promise<PriceModel> => {
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/price-model/ball-price-tabs`
  return request(URL) as Promise<PriceModel>
}
