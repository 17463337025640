import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import Panel from '../Panel'

export default class PanelGroup extends Component {
  renderPanels() {
    const { columnData, maxPriceItems } = this.props
    let interval = maxPriceItems ? 60 / maxPriceItems : 0
    let numberOfPreviousPanels = 0
    if (interval && columnData.length) {
      let firstPriceItem = columnData[0]
      let firstMinutes = firstPriceItem['start_time'].getMinutes()
      numberOfPreviousPanels = firstMinutes / interval
    }

    let panels = []

    for (let i = 0; i < numberOfPreviousPanels; i++) {
      let content = { type: 'empty' }
      panels.push(content)
    }

    columnData.forEach((item) => panels.push(item))

    return (
      <div className={style.panelContainer}>
        {panels.map((panel, key) => {
          return <Panel key={key} panelItem={panel} maxPriceItems={maxPriceItems} />
        })}
      </div>
    )
  }

  render() {
    let classNames = [style.container]

    let _style = {
      width: 100 / (this.props.totalGroups + 1) + '%',
    }

    return (
      <div className={classNames.join(' ')} style={_style}>
        <div className={style.content}>
          <div className={style.header}>{this.props.hour}</div>

          {this.renderPanels()}
        </div>
      </div>
    )
  }
}

PanelGroup.propTypes = {
  hour: PropTypes.number.isRequired,
  columnData: PropTypes.array.isRequired,
  totalGroups: PropTypes.number.isRequired,
  maxPriceItems: PropTypes.number.isRequired,
}
