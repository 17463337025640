import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { uniqBy } from 'lodash'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import Utils from '@sweetspot/club-portal-legacy/helpers/Utilities'
import { RULE_TYPES } from '@sweetspot/club-portal-legacy/components/Wizard/constants'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import IncludedCoursesTable from '@sweetspot/club-portal-legacy/components/Wizard/partials/IncludedCoursesTable'

class CoursesIncludedRule extends Component {
  RULE_TYPE = RULE_TYPES.includedCourses

  TRANSLATIONS = {
    inputLabel: GetTranslatedString(this.props.lang, 'wizard.includedCourses'),
    searchCourses: GetTranslatedString(this.props.lang, 'wizard.searchCourses'),
    alreadyIncludedInOtherRuleSet: GetTranslatedString(
      this.props.lang,
      'wizard.alreadyIncludedInOtherRuleSet'
    ),
    noAvailableCourses: GetTranslatedString(this.props.lang, 'wizard.noAvailableCourses'),
  }

  state = {
    searchValue: '',
    suggestedItems: [],
  }

  getCurrentArray = () => {
    const { promotion } = this.props

    if (
      promotion &&
      promotion.rules &&
      promotion.rules[this.RULE_TYPE] &&
      promotion.rules[this.RULE_TYPE].configuration
    ) {
      return promotion.rules[this.RULE_TYPE].configuration
    } else {
      return []
    }
  }

  getError = () => {
    const { promotion, errors } = this.props
    const { id: promotionId } = promotion
    return errors[`promotions.${promotionId}.rules.${this.RULE_TYPE}`] || ''
  }

  getCourses = () => {
    const { golfCourses, partnerCourses } = this.props
    return uniqBy([...golfCourses, ...partnerCourses], 'id')
  }

  getIncludedCourses = () => {
    const includedCourses = this.getCurrentArray()
    return this.getCourses().filter((course) => includedCourses.includes(course.id))
  }

  updateValue = (newValue) => {
    const { disabled, onChange } = this.props
    if (disabled) return
    onChange(this.RULE_TYPE, newValue)
  }

  removeCourse = (id) => {
    this.updateValue([...this.getCurrentArray().filter((courseId) => courseId !== id)])
  }
  addCourse = (id) => {
    this.setState({
      searchValue: '',
      suggestedItems: [],
    })
    this.updateValue([...this.getCurrentArray(), id])
  }

  getSelectableCourses = () => {
    const { values } = this.props
    const { promotions } = values
    const allCourses = this.getCourses()
    const currentIncluded = this.getCurrentArray()

    let selectableCourses = []

    allCourses.forEach((course) => {
      const { id: courseId } = course

      let includedInOtherPromotion = false

      if (currentIncluded.find((x) => x === courseId)) return

      Object.keys(promotions).forEach((key) => {
        if (
          Utils.checkNestedProp(
            promotions,
            key,
            'rules',
            RULE_TYPES.includedCourses,
            'configuration'
          ) &&
          promotions[key].rules[RULE_TYPES.includedCourses].configuration.includes(courseId)
        ) {
          includedInOtherPromotion = true
        }
      })

      selectableCourses.push({
        ...course,
        includedInOtherPromotion,
      })
    })
    return selectableCourses
  }

  updateSuggestions = (skipFilter = false) => {
    const { searchValue } = this.state
    const { partnerCourses, promotion } = this.props

    const selectableCourses = this.getSelectableCourses()

    let suggestions = selectableCourses

    if (!skipFilter || !searchValue) {
      suggestions = Utils.basicStringSearch(
        searchValue,
        suggestions,
        ['name', 'search_field'],
        true
      )
    }

    if (suggestions.length <= 0) {
      this.setState({
        suggestedItems: [],
      })
    } else {
      this.setState({
        suggestedItems: [
          ...suggestions.map((course) => ({
            id: course.id,
            name: partnerCourses.some((e) => e.id === course.id)
              ? `${course.name} (${course.club.name})`
              : course.name,
            itemKey: `${course.id}-${promotion.id}`,
            disabled: course.includedInOtherPromotion,
            disabledLabel: this.TRANSLATIONS.alreadyIncludedInOtherRuleSet.toLowerCase(),
          })),
        ],
      })
    }
  }

  _filterDebounce = null
  handleInputChange = (value) => {
    this.setState(
      {
        searchValue: value,
      },
      () => {
        clearTimeout(this._filterDebounce)
        this._filterDebounce = setTimeout(() => {
          this.updateSuggestions()
        }, 275)
      }
    )
  }

  render = () => {
    const { suggestedItems, searchValue } = this.state
    const { disabled } = this.props
    return (
      <React.Fragment>
        {disabled ? (
          <Label label={this.TRANSLATIONS.inputLabel} />
        ) : (
          <TextInputField
            label={this.TRANSLATIONS.inputLabel}
            placeholder={this.TRANSLATIONS.searchCourses}
            error={this.getError()}
            value={searchValue}
            onChange={this.handleInputChange}
            suggestionItems={suggestedItems}
            noSuggestionsAvailableLabel={this.TRANSLATIONS.noAvailableCourses}
            suggestionItemsLabelKey="name"
            onSuggestionClick={(id) => this.addCourse(id)}
            onInputFocus={() => this.updateSuggestions(true)}
            suggestionItemsItemKey="itemKey"
          />
        )}
        <IncludedCoursesTable
          includedCourses={this.getIncludedCourses()}
          onDelete={(id) => this.removeCourse(id)}
          disabled={disabled}
        />
      </React.Fragment>
    )
  }
}

CoursesIncludedRule.propTypes = {
  promotion: PropTypes.object.isRequired,
  partnerCourses: PropTypes.array,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  lang: PropTypes.string,
  golfCourses: PropTypes.array,
}
CoursesIncludedRule.defaultProps = {
  promotion: {},
  partnerCourses: [],
  values: {},
  onChange: () => {},
  errors: {},
  disabled: false,
  golfCourses: [],
}

const mapStateToProps = (state) => ({
  lang: state.auth.me.lang,
  golfCourses: state.golfCourse.list,
})

export default connect(mapStateToProps)(CoursesIncludedRule)
