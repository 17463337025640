import { z } from 'zod'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
  getCountryCallingCode,
} from 'libphonenumber-js'

export const UserInformationFormSchema = z.object({
  email: z.string().email({
    message: 'Invalid email address',
  }),
  phone: z.string().refine(
    (value) => {
      if (isPossiblePhoneNumber(value) && isValidPhoneNumber(value)) {
        const phoneNumber = parsePhoneNumber(value, 'SE')
        return phoneNumber.formatInternational()
      }
      return false
    },
    {
      message: 'Invalid country code ' + `‘00${getCountryCallingCode('SE')}’`,
    }
  ),
})
