export type RangeWithBallDispensers = {
  id?: string
  range_servant_site_id?: string
  dispensers: BallDispenser[]
}

export type BallDispenser = {
  id?: string
  name?: string
  outlets: BallDispenserOutlet[]
}

export type BallDispenserOutlet = {
  id?: string
  name?: string
  asset_id?: string
  is_active?: boolean
  status?: OutletStatus
  terminal?: BallDispenserTerminal
}

export type BallDispenserTerminal = {
  id?: string
  model?: string
  serial_number?: string
}

export enum OutletStatus {
  OPERATIONAL = 'operational',
  OFFLINE = 'offline',
  INACTIVE = 'inactive',
  UNKNOWN = 'unknown',
}

export type OutletActivationPaylaod = {
  isActive: boolean
  rangeId?: string
  outletId: string
}
