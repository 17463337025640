import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { OutletActivationPaylaod, RangeWithBallDispensers } from './types'

export const queryBallDispensers = (
  rangeId?: string
): Promise<{ [key: string]: RangeWithBallDispensers }> => {
  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/dispensers`
  return request(URL) as Promise<{ [key: string]: RangeWithBallDispensers }>
}

export const updateBallDispensers = (
  payload: RangeWithBallDispensers
): Promise<RangeWithBallDispensers> => {
  const { id, ...range } = payload

  const URL = `${getApiPlatformBaseUrl()}/ranges/${id}/dispensers`
  return request(URL, {
    method: 'PUT',
    contentType: 'application/json',
    body: { range },
  })
}

export const updateOutletActivation = (payload: OutletActivationPaylaod) => {
  const { rangeId, outletId, isActive } = payload

  const URL = `${getApiPlatformBaseUrl()}/ranges/${rangeId}/outlets/${outletId}/activation`
  return request(URL, {
    method: 'PUT',
    contentType: 'application/json',
    body: { is_active: isActive },
  })
}
