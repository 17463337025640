import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PanelGroup from '../PanelGroup'
import style from './style.module.scss'
import { uniqWith, isEqual } from 'lodash'

export class PriceTable extends Component {
  parseTableData() {
    let tableData = {}

    const noDupeTableData = uniqWith(this.props.tableData, isEqual)

    let intervalMinutes = null
    if (noDupeTableData?.length >= 2) {
      var diff = Math.abs(
        new Date(noDupeTableData[0].start_time) - new Date(noDupeTableData[1].start_time)
      )
      intervalMinutes = Math.floor(diff / 1000 / 60)
    }

    noDupeTableData.forEach((item) => {
      const hour = item['start_time'].getHours()
      const nextHour = hour + 1

      let minutes = item['start_time'].getMinutes()

      if (!tableData[hour]) tableData[hour] = []
      tableData[hour].push(item)

      if (intervalMinutes && minutes + intervalMinutes > 60) {
        if (!tableData[nextHour]) tableData[nextHour] = []

        let newStartTime = new Date(item.start_time)
        newStartTime.setHours(nextHour)
        newStartTime.setMinutes(0)

        tableData[nextHour].push({
          ...item,
          start_time: newStartTime,
        })
      }
    })

    return tableData
  }

  renderMinutes(maxPriceItems, totalGroups) {
    let interval = maxPriceItems ? 60 / maxPriceItems : 0

    let _style = {
      width: 100 / (totalGroups + 1) + '%',
    }

    return (
      <div className={style.minContainer} style={_style}>
        <div className={style.content}>
          <div className={style.header}>0</div>
          <div>
            {new Array(maxPriceItems).fill('').map((item, index) => (
              <div key={index} className={style.item}>
                {interval * index ? interval * index : '00'}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const tableData = this.parseTableData()
    const hours = Object.keys(tableData)
    let maxPriceItems = 0
    Object.values(tableData).forEach((group) => {
      if (group.length > maxPriceItems) {
        maxPriceItems = group.length
      }
    })

    return (
      <div className={style.container}>
        {this.renderMinutes(maxPriceItems, hours.length)}
        {hours.map((hour, key) => {
          return (
            <PanelGroup
              hour={parseInt(hour)}
              columnData={tableData[hour]}
              totalGroups={hours.length}
              maxPriceItems={maxPriceItems}
              key={key}
            />
          )
        })}
      </div>
    )
  }
}

PriceTable.propTypes = {
  tableData: PropTypes.array.isRequired,
}

export default PriceTable
