import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { I18nextNamespaces, LanguageKeys } from '../constants/enums'
import { setYupLocale } from '../utils/setYupLocale'
import { addSweetspotJsBundles } from '../utils/addSweetspotJsBundles'
import { addCommonBundles } from '../utils/addCommonBundles'
import { addClubPortalBundles } from '../utils/addClubPortalBundles'

export const initClubPortalI18next = (debug = false) => {
  i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: LanguageKeys.EN,
      cleanCode: true,
      ns: I18nextNamespaces.DEFAULT,
      defaultNS: I18nextNamespaces.DEFAULT,
      debug: debug,
      load: 'languageOnly',
      returnEmptyString: false,
      interpolation: {
        format: (value, format) => {
          if (format === 'bold') return value.bold()
          if (format === 'lowercase') return value.toLowerCase()
          if (format === 'uppercase') return value.toUpperCase()
          return value
        },
      },
    })

  addSweetspotJsBundles()
  addCommonBundles()
  addClubPortalBundles()
  setYupLocale()
}
