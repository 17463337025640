import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import * as API_BOOKINGS from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import { parseError } from '@sweetspot/sweetspot-js/common/functions/parse'
import m from 'moment'

const useBookingNotes = (bookingID, authorID, onFirstFetch, newestFirst) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  const [isFirstFetch, setIsFirstFetch] = useState(true)

  const {
    data: notes,
    isFetching: isFetchingNotes,
    error: queryError,
    refetch,
  } = useInfiniteQuery(
    [
      CLUB_QUERIES.BOOKING_NOTES,
      {
        booking: bookingID,
        'order[created_at]': newestFirst ? 'desc' : 'asc',
      },
    ],
    () =>
      API_BOOKINGS.getBookingNotes({
        'booking.uuid': bookingID,
        page: 1,
        limit: 999,
        'order[created_at]': newestFirst ? 'desc' : 'asc',
      }),
    {
      enabled: Boolean(bookingID && authorID),
      select: ({ pages }) =>
        pages[0].map((note) => {
          return {
            uuid: note.uuid,
            author: note.author?.full_name.trim().length > 0 ? note.author?.full_name : 'User',
            time: m(note.updated_at).format('DD.MM.YYYY HH:mm'),
            text: note.note,
            isAuthor: authorID === note.author?.uuid,
          }
        }),
      refetchOnWindowFocus: true,
    }
  )

  useEffect(() => {
    if (isFirstFetch && typeof onFirstFetch === 'function' && notes?.length > 0) {
      setIsFirstFetch(false)
      onFirstFetch()
    }
  }, [notes])

  const createBookingNote = async (text) => {
    try {
      setIsUpdating(true)
      await API_BOOKINGS.createBookingNote({
        note: text,
        author: {
          uuid: authorID,
        },
        booking: {
          uuid: bookingID,
        },
      })
      await refetch()
    } catch (e) {
      setError(Symbol(parseError(e)))
    } finally {
      setIsUpdating(false)
    }
  }

  const updateBookingNote = async (noteID, text) => {
    try {
      setIsUpdating(true)
      await API_BOOKINGS.updateBookingNote(noteID, text)
      await refetch()
    } catch (e) {
      setError(Symbol(parseError(e)))
    } finally {
      setIsUpdating(false)
    }
  }

  const removeBookingNote = async (noteID) => {
    try {
      setIsUpdating(true)
      await API_BOOKINGS.removeBookingNote(noteID)
      await refetch()
    } catch (e) {
      setError(Symbol(parseError(e)))
    } finally {
      setIsUpdating(false)
    }
  }

  return {
    notes: notes || [],
    refetch,
    error: error || (queryError ? parseError(queryError) : null),
    createBookingNote,
    updateBookingNote,
    removeBookingNote,
    isLoading: isFetchingNotes || isUpdating,
  }
}

export default useBookingNotes
