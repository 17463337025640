import { useOpeningHours } from '@sweetspot/club-portal/feature/warehouse'
import { useTranslation } from 'react-i18next'
import { OpeningHourItem } from '../OpeningHourItem'
import { OpeningHour } from '@sweetspot/shared/data-access/api-platform'

interface StandardHoursProps {
  warehouseUuid: string
}

export const StandardHours = (props: StandardHoursProps) => {
  const { t } = useTranslation()
  const { useGetOpeningHours, useUpdateOpeningHours } = useOpeningHours({
    warehouseUuid: props.warehouseUuid,
  })

  const { data } = useGetOpeningHours()
  const warehouse = useUpdateOpeningHours()

  const updateItem = (itemToUpdate: OpeningHour) => {
    warehouse.mutate(itemToUpdate)
  }

  return (
    <div className={'flex flex-col'}>
      <div className={'mb-4'}>{t('warehouse.openingHours.text_standard_hour_info')}</div>
      <div className={'grid grid-cols-4 items-center justify-center'}>
        <div
          className={
            'contents font-semibold [&>*]:flex [&>*]:h-14 [&>*]:items-center [&>*]:bg-neutral-100 [&>*]:pl-4'
          }
        >
          <div>{t('warehouse.openingHours.label_day')}</div>
          <div>{t('warehouse.openingHours.label_closed')}</div>
          <div>{t('warehouse.openingHours.label_firstRentalTime')}</div>
          <div>{t('warehouse.openingHours.label_lastRentalTime')}</div>
        </div>

        {data?.map((item, index) => (
          <OpeningHourItem item={item} key={item.id} onChange={(val) => updateItem(val)} />
        ))}
      </div>
    </div>
  )
}
