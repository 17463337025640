import { useTranslation } from 'react-i18next'

import { GolfCourseTypes } from '@sweetspot/shared/types'

type VenueTitleProps = {
  courseType: GolfCourseTypes
  count: number
}

const VenueTitle = ({ courseType, count }: VenueTitleProps) => {
  const { t } = useTranslation()
  switch (courseType) {
    case GolfCourseTypes.SIMULATOR:
      return t('words.simulator', { count })
    case GolfCourseTypes.DRIVING_RANGE:
      return t('words.drivingRange')
    case GolfCourseTypes.PRO:
      return t('menuItems.proSheet')
    default:
      return t('menuItems.otherSheet')
  }
}

export default VenueTitle
