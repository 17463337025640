import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import rootReducer from './reducers'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from '@redux-devtools/extension'

const history = createBrowserHistory()
const router = routerMiddleware(history)

const composeEnhancers = composeWithDevTools({})

const enhancer = composeEnhancers(applyMiddleware(thunk, router))

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'golfClub', 'golfCourse'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, undefined, enhancer)

const persistor = persistStore(store)

export { store, history, persistor }
