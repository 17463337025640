import { combineReducers } from 'redux'
import { routerReducer as router } from 'react-router-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import appState from './appStateReducer'
import auth from './authReducer'
import golfClub from './golfClubReducer'
import golfCourse from './golfCourseReducer'
import membership from './membershipReducer'
import partnership from './partnershipReducer'
import bookingPeriod from './bookingPeriodReducer'

const rootReducer = combineReducers<RootState>({
  appState,
  auth,
  golfClub,
  golfCourse,
  membership,
  partnership,
  router,
  bookingPeriod,
})

export default rootReducer
