import _isString from 'lodash/isString'
import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { getInventoryCourseSchedules } from '@sweetspot/sweetspot-js/features/golfCarts/services/api-platform'
import { BOOKING_GROUPS, BOOKING_RELATIONS } from '../constants/bookingRelations'
import { ITEM_GROUPS } from '../constants/itemRelations'
import { ORDER_STATES } from '../constants/orderStates'
import {
  fetchBookingItems,
  fetchBookingPayments,
  fetchBookingRefunds,
  fetchBookings,
  fetchBookingSpaces,
  getWebTeeSheetBookings,
} from '../services/api-platform'

const DEFAULT_ORDER_STATES_FILTER = [
  ORDER_STATES.NEW,
  ORDER_STATES.FULFILLED,
  ORDER_STATES.REOPENED,
  ORDER_STATES.PARTIALLY_PAID,
  ORDER_STATES.PARTIALLY_REFUNDED,
]

const DEFAULT_INCLUDED_RELATIONS = [BOOKING_RELATIONS.ITEMS]
const DEFAULT_INCLUDED_BOOKING_GROUPS = []
const DEFAULT_INCLUDED_ITEM_GROUPS = []

const getMultipleBookingsWithRelations = async (
  args = {
    courseUuid: null,
    spaceUuid: null,
    startDate: null,
    endDate: null,
    includedRelations: DEFAULT_INCLUDED_RELATIONS,
    includedBookingGroups: DEFAULT_INCLUDED_BOOKING_GROUPS,
    includedItemGroups: DEFAULT_INCLUDED_ITEM_GROUPS,
    orderStates: DEFAULT_ORDER_STATES_FILTER,
    useStrictStart: false,
    useStrictEnd: false,
    useWebBookingEndpoint: false,
  }
) => {
  const {
    courseUuid,
    spaceUuid,
    startDate,
    endDate,
    includedRelations,
    includedBookingGroups,
    includedItemGroups,
    orderStates,
    useStrictStart,
    useStrictEnd,
    useWebBookingEndpoint,
  } = {
    courseUuid: null,
    spaceUuid: null,
    startDate: null,
    endDate: null,
    includedRelations: DEFAULT_INCLUDED_RELATIONS,
    includedBookingGroups: DEFAULT_INCLUDED_BOOKING_GROUPS,
    includedItemGroups: DEFAULT_INCLUDED_ITEM_GROUPS,
    orderStates: DEFAULT_ORDER_STATES_FILTER,
    useStrictStart: false,
    useStrictEnd: false,
    ...args,
  }

  // Validate
  if ((!spaceUuid && !courseUuid) || (spaceUuid && !courseUuid)) {
    throw new Error('getMultipleBookingsWithRelations: courseUuid and/or spaceUuid is required')
  }
  if (!startDate) {
    throw new Error('getMultipleBookingsWithRelations: startDate is invalid')
  }
  if (!endDate) {
    throw new Error('getMultipleBookingsWithRelations: endDate is invalid')
  }

  if (!includedRelations?.length) {
    throw new Error('getMultipleBookingsWithRelations: includedRelations is required')
  }

  if (!orderStates?.length) {
    throw new Error('getMultipleBookingsWithRelations: orderStates is required')
  }

  let promises = []

  let bookingGroups = null
  if (includedBookingGroups.includes(BOOKING_GROUPS.ALL)) {
    bookingGroups = [
      ...Object.keys(BOOKING_GROUPS)
        .filter((key) => key !== 'ALL')
        .map((key) => BOOKING_GROUPS[key]),
    ]
  } else {
    bookingGroups = [...includedBookingGroups]
  }

  const startdate = useStrictStart
    ? {
        'booking.start_time[strictly_after]': _isString(startDate)
          ? startDate
          : startDate.toISOString(),
      }
    : { 'booking.start_time[after]': _isString(startDate) ? startDate : startDate.toISOString() }
  const enddate = useStrictEnd
    ? {
        'booking.start_time[strictly_before]': _isString(endDate) ? endDate : endDate.toISOString(),
      }
    : { 'booking.start_time[before]': _isString(endDate) ? endDate : endDate.toISOString() }

  // Fetch bookings
  let bookings, bookingsErr
  if (useWebBookingEndpoint) {
    let [bookingsRes, bookingsErrRes] = await to(
      getWebTeeSheetBookings({
        ...startdate,
        ...enddate,
        'course.uuid': courseUuid,
      })
    )
    bookings = bookingsRes?.['hydra:member']
    bookingsErr = bookingsErrRes
  } else {
    ;[bookings, bookingsErr] = await to(
      getAllPagesRequest(fetchBookings, {
        'course.uuid': courseUuid,
        'spaces.uuid': spaceUuid,
        'state[]': orderStates,
        ...startdate,
        ...enddate,
        'groups[]': bookingGroups,
        page: 1,
        limit: 50,
      })
    )
  }

  if (!bookings || bookingsErr) {
    throw new Error('getMultipleBookingsWithRelations: Error fetching bookings')
  }

  // Convert to object temporarily
  let bookingsObject = {}
  bookings.forEach((booking) => {
    bookingsObject[booking.uuid] = booking
  })

  // Fetch booking items
  if (
    includedRelations.includes(BOOKING_RELATIONS.ITEMS) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    let itemGroups = null
    if (includedItemGroups.includes(ITEM_GROUPS.ALL)) {
      itemGroups = [
        ...Object.keys(ITEM_GROUPS)
          .filter((key) => key !== 'ALL' && key !== 'WEB_BOOKING')
          .map((key) => ITEM_GROUPS[key]),
      ]
    } else {
      itemGroups = [...includedItemGroups]
    }

    promises.push(
      new Promise((resolve, reject) => {
        Promise.all(
          bookings.map(async (booking, index) => {
            const [res, err] = await to(fetchBookingItems(booking.uuid, { 'groups[]': itemGroups }))
            if (res || !err) {
              bookingsObject[booking.uuid].items = res
            }
            return
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    )
  }

  // Fetch payments
  if (
    includedRelations.includes(BOOKING_RELATIONS.PAYMENTS) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    promises.push(
      new Promise((resolve, reject) => {
        Promise.all(
          bookings.map(async (booking, index) => {
            const [res, err] = await to(fetchBookingPayments(booking.uuid))
            if (res || !err) {
              bookingsObject[booking.uuid].payments = res
            }
            return
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    )
  }

  // Fetch refunds
  if (
    includedRelations.includes(BOOKING_RELATIONS.REFUNDS) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    promises.push(
      new Promise((resolve, reject) => {
        Promise.all(
          bookings.map(async (booking, index) => {
            const [res, err] = await to(fetchBookingRefunds(booking.uuid))
            if (res || !err) {
              bookingsObject[booking.uuid].refund_payments = res
            }
            return
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    )
  }

  // Fetch spaces
  if (
    includedRelations.includes(BOOKING_RELATIONS.SPACES) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    promises.push(
      new Promise((resolve, reject) => {
        Promise.all(
          bookings.map(async (booking, index) => {
            const [res, err] = await to(fetchBookingSpaces(booking.uuid))
            if (res || !err) {
              bookingsObject[booking.uuid].spaces = res
            }
            return
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    )
  }

  // Fetch inventory schedules
  if (
    includedRelations.includes(BOOKING_RELATIONS.INVENTORY_SCHEDULES) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    promises.push(
      new Promise((resolve, reject) => {
        Promise.all(
          bookings.map(async (booking, index) => {
            const [res, err] = await to(
              getInventoryCourseSchedules({ booking: booking.booking.uuid })
            )
            if (res || !err) {
              bookingsObject[booking.uuid] = {
                ...bookingsObject[booking.uuid],
                booking: {
                  ...bookingsObject[booking.uuid].booking,
                  inventory_course_schedules: res,
                },
              }
            }
            return
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    )
  }

  const [results, err] = await to(Promise.all(promises))

  if (err) {
    throw new Error(err)
  }
  if (!results) {
    throw new Error('getMultipleBookingsWithRelations: no results')
  }

  // Back to array
  return Object.keys(bookingsObject).map((key) => bookingsObject[key])
}

export default getMultipleBookingsWithRelations
