import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'
import { QueryRecord } from '@sweetspot/shared/types'

import { PlayersSearchPayload } from './types'

/**
 * Searches for players by query
 */
export const searchPlayers = (
  query: PlayersSearchPayload = {
    search: null,
    email: null,
    phone: null,
    relatedGolfClub: null,
    golfId: null,
    golfClub: null,
    'type[members]': null,
    'type[guests]': null,
    'membership[golf_club_id]': null,
    membership: null,
    'membershipPaid[paid]': null,
    'membershipPaid[not_paid]': null,
    gitOnlyMembership: null,
    externalPlayer: null,
    friendSearch: null,
    page: 1,
    limit: 50,
  }
) => {
  const searchUrl = `${getApiPlatformBaseUrl()}/players`

  const url = queryString.stringifyUrl(
    {
      url: searchUrl,
      query: query as QueryRecord<typeof query>,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}
