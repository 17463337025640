import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

import styles from './styles.module.scss'

const RentalActivity = ({ rentalItem, isEven, timezone }) => {
  const [isOpen, setIsOpen] = useState(false)

  const orderItems = rentalItem?.booking?.order?.items

  const rentalItemDetails = orderItems?.find(
    (item) => item?.product_variant?.uuid === rentalItem?.product_variant?.uuid
  )

  return (
    <div className={cx(styles.container)} onClick={() => setIsOpen(!isOpen)}>
      <div className={cx(styles.cell, { [styles.even]: isEven, [styles.open]: isOpen })}>
        <div>{moment(rentalItem.hold_from).format('DD MMM')}</div>
      </div>
      <div className={cx(styles.cell, { [styles.even]: isEven, [styles.open]: isOpen })}>
        <div>{rentalItem.booking ? rentalItem.booking.course?.name : t('words.all')}</div>
      </div>
      <div className={cx(styles.cell, { [styles.even]: isEven, [styles.open]: isOpen })}>
        <div>{moment(rentalItem.hold_from).tz(timezone).format('HH:mm')}</div>
      </div>
      <div className={cx(styles.cell, { [styles.even]: isEven, [styles.open]: isOpen })}>
        <div>{moment(rentalItem.hold_to).tz(timezone).format('HH:mm')}</div>
      </div>
      <div className={cx(styles.cell, { [styles.even]: isEven, [styles.open]: isOpen })}>
        <div>{rentalItem.product_variant.name}</div>
      </div>
      <div className={cx(styles.cell, { [styles.even]: isEven, [styles.open]: isOpen })}>
        {rentalItemDetails?.state === 'paid' ? (
          <span className={styles.paid}>{t('words.paid')}</span>
        ) : (
          <span className={styles.notPaid}>{t('sentences.notPaid')}</span>
        )}
      </div>
      <div className={cx(styles.cell, { [styles.even]: isEven, [styles.open]: isOpen })}>
        <div>
          {rentalItem.booking ? (
            <Link to={`/booking/${rentalItem?.booking?.order?.uuid}`} target={'_blank'}>
              {t('sentences.goToBooking')}
            </Link>
          ) : (
            t('words.reserved_one')
          )}
        </div>
      </div>
    </div>
  )
}

RentalActivity.propTypes = {
  rentalItem: PropTypes.object,
  isEven: PropTypes.bool,
}

export { RentalActivity }
