import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'
import _ from 'lodash'

import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import Container from '@sweetspot/club-portal-legacy/components/Container'
import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'
import { getLocalStorage, setLocalStorage } from '@sweetspot/shared/util/local-storage'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import { selectGolfCourse } from '@sweetspot/club-portal-legacy/store/actions'
import { playerTypes } from '@sweetspot/club-portal-legacy/constants/config'

import style from '../style.module.scss'

class Statistics extends Component {
  translations = {
    courses: GetTranslatedString(this.props.lang, 'courses'),
    searchCourses: GetTranslatedString(this.props.lang, 'wizard.searchCourses'),
    noCoursesSelected: GetTranslatedString(this.props.lang, 'noCoursesSelected'),
    playerType: GetTranslatedString(this.props.lang, 'players.playerType'),
    playerTypes: GetTranslatedString(this.props.lang, 'playerTypes'),
    searchPlayerTypes: GetTranslatedString(this.props.lang, 'searchPlayerTypes'),
    noPlayerTypeSelected: GetTranslatedString(this.props.lang, 'noPlayerTypeSelected'),
  }

  state = {
    iframeUrl: '',
    selectedPlayerType: null,
    selectedCourses: [],
  }

  componentDidMount() {
    const storedSelectedCourses = JSON.parse(getLocalStorage('selected-statistics-courses'))
    const shouldUseStoredCourses =
      storedSelectedCourses &&
      storedSelectedCourses[0]?.club?.id === this.props.golfCourses?.list[0]?.club?.id

    this.setState(
      {
        selectedPlayerType: playerTypes[4],
        selectedCourses: shouldUseStoredCourses
          ? storedSelectedCourses
          : this.props.golfCourses?.list?.map((course) => {
              return { ...course, label: course.name, value: course.id }
            }),
      },
      () => this.getIFrameUrl()
    )
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.golfCourses, this.props.golfCourses)) {
      setLocalStorage('selected-statistics-courses', JSON.stringify(this.props.golfCourses?.list))

      this.setState(
        {
          selectedPlayerType: playerTypes[4],
          selectedCourses: this.props.golfCourses?.list?.map((course) => {
            return { ...course, label: course.name, value: course.id }
          }),
        },
        () => this.getIFrameUrl()
      )
    }
  }

  getIFrameUrl() {
    const { selectedPlayerType, selectedCourses } = this.state
    if (!selectedPlayerType || !selectedCourses.length) return

    const { REACT_APP_METABASE_SITE_URL, REACT_APP_METABASE_SECRET_KEY } = process.env
    const uuidArray = selectedCourses.map((x) => x.uuid)
    const payload = {
      resource: { dashboard: 258 },
      params: {
        golf_course: uuidArray,
        player_type: selectedPlayerType?.value,
      },
      exp: Math.round(Date.now() / 1000) + 600,
    }

    const token = jwt.sign(payload, REACT_APP_METABASE_SECRET_KEY)
    const iframeUrl = `${REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`

    this.setState({ iframeUrl })
  }

  handleChangePlayerType(value) {
    const selectedPlayerType = playerTypes.find((x) => x.id === value)
    this.setState({ selectedPlayerType }, this.getIFrameUrl)
  }

  handleChangeCourses(courses) {
    localStorage.setItem('selected-statistics-courses', JSON.stringify(courses))

    this.setState(
      {
        showCourseList: false,
        selectedCourses: courses,
      },
      this.getIFrameUrl
    )
  }

  renderGolfCourseSelect() {
    if (!this.props.golfCourses?.list[0]?.id) return
    return (
      <MultiSelect
        title={this.translations.courses}
        options={this.props.golfCourses?.list?.map((course) => {
          return { ...course, label: course.name, value: course.id }
        })}
        selectedOptions={this.state.selectedCourses}
        noSelectedLabel={this.translations.noCoursesSelected}
        handleChangeSelected={(courses) => this.handleChangeCourses(courses)}
        searchPlaceholder={this.translations.searchCourses}
        buttonLabel={this.translations.courses}
        width="full"
      />
    )
  }

  renderPlayerTypeSelect() {
    return (
      <div className={style.playerTypeSelect}>
        <span className={style.dropDownLabel}>{this.translations.playerType}</span>
        <DropdownSelect
          readOnly
          showBorder
          hideSelf
          initialId={5}
          selectedId={this.state.selectedPlayerType?.id}
          width={175}
          values={playerTypes.map((x) => ({
            ...x,
            name: GetTranslatedString(this.props.lang, x.translation),
          }))}
          onSelect={(playerType) => this.handleChangePlayerType(playerType)}
        />
      </div>
    )
  }

  render() {
    const { iframeUrl, selectedCourses, selectedPlayerType } = this.state

    return (
      <Container>
        <div className={style.container}>
          <div className={style.header}>
            {this.renderGolfCourseSelect()}
            {this.renderPlayerTypeSelect()}
          </div>
          <div className={style.content}>
            {!!selectedCourses.length && selectedPlayerType && (
              <iframe
                title="dashboard"
                src={iframeUrl}
                frameBorder="0"
                width="100%"
                height="100%"
              />
            )}
          </div>
        </div>
      </Container>
    )
  }
}

Statistics.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfCourses: PropTypes.object.isRequired,
  selectGolfCourse: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfCourses: state.golfCourse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectGolfCourse: (id) => dispatch(selectGolfCourse(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics)
