import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'

import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { TrackingTech } from '@sweetspot/shared/types'
import { Input, InputBase, InputContainer, Toggle } from '@sweetspot/scramble-ds'
import { DemandStatus } from '@sweetspot/shared/data-access/api-platform'

const BallPrice = () => {
  const { t } = useTranslation()
  const {
    selectedRange,
    bucketConfiguration: {
      ballPrice,
      ballPriceData,
      setBallPrice,
      isExperienceBasedPricing,
      setIsExperienceBasedPricing,
    },
  } = useBallPricingContext()
  const [currency] = useClubCurrency()
  const technologies = useMemo(() => {
    if (!isExperienceBasedPricing) {
      return [TrackingTech.NONE]
    }
    return ballPriceData?.technologies
  }, [isExperienceBasedPricing, ballPriceData?.technologies])

  const handleOnBlur = useCallback(
    (value: number | string, tech: string, demand: Exclude<DemandStatus, DemandStatus.Closed>) => {
      if (typeof value !== 'number') {
        value = parseFloat(value)
      }
      setBallPrice?.({ [tech]: { [demand]: value.toFixed(2) } })
    },
    [setBallPrice]
  )

  const handleOnFocus = useCallback(
    (value: number | string, tech: string, demand: Exclude<DemandStatus, DemandStatus.Closed>) => {
      if (!value || value === '0.00') {
        setBallPrice?.({ [tech]: { [demand]: '' } })
      }
    },
    [setBallPrice]
  )

  const preventedSymbols = useMemo(() => ['e', 'E', '+', '-'], [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isPreventedSymbol = preventedSymbols.includes(e.key)
      if (isPreventedSymbol) {
        e.preventDefault()
      }
    },
    [preventedSymbols]
  )

  const handleOnChange = useCallback(
    (value: number | string, tech: string, demand: Exclude<DemandStatus, DemandStatus.Closed>) => {
      const prevValue: number | string | undefined = ballPrice[tech]?.[demand]
      const shouldUpdateValue = !value || (value && `${value}`.length < 4)
      const hasTwoDecimals = prevValue && `${prevValue}`.split('.')[1]?.length >= 2
      if (hasTwoDecimals && !shouldUpdateValue) {
        value = prevValue
      } else if (value !== '') {
        if (typeof value !== 'number') {
          value = parseFloat(value).toFixed(2)
        }
        value = Math.min(ballPriceData?.maxBallPrice as number, value as number)
      }
      setBallPrice?.({ [tech]: { [demand]: value } })
    },
    [setBallPrice, ballPriceData?.maxBallPrice, ballPrice]
  )

  return (
    <div>
      <span className="text-content-base text-text-dark font-bold">
        {t('sentences.ballPriceCurrency', { currency: `(${currency})` })}
      </span>
      <div className="mt-4 flex min-h-12 items-center gap-4 pb-2">
        <div>
          <label className="text-text-dark text-content-sm mb-0.5 font-bold">
            {t('sentences.experienceBasedPricingActive')}
          </label>
          <div className="text-text-subtle text-content-sm">
            {t('sentences.experienceBasedPricingActiveHint')}
          </div>
        </div>
        <Toggle checked={isExperienceBasedPricing} onCheckedChange={setIsExperienceBasedPricing} />
      </div>
      {technologies?.map((tech, index) => (
        <div
          key={`${tech}-${index}`}
          className="bg-background-mono-lighter mt-2 rounded-md border p-4 pt-2"
        >
          <span className="text-content-base text-text-dark font-bold capitalize">
            {tech === TrackingTech.NONE ? t('words.standard') : tech}
          </span>
          <div className="mb-[2px] mt-1 flex gap-1">
            {ballPriceData?.demands?.map((demand) => (
              <span
                key={`${demand}-${index}`}
                className="text-content-base text-text-dark flex-1 px-2 font-bold"
              >
                {t(`words.${demand === DemandStatus.Medium ? 'medium' : demand}`)}
              </span>
            ))}
          </div>
          <div className="flex items-center gap-1">
            {ballPriceData?.demands?.map((demand) => (
              <InputBase key={`${selectedRange?.id}-${tech}-${demand}-${index}`}>
                <InputContainer>
                  <Input
                    type="number"
                    value={ballPrice[tech]?.[demand]}
                    className="text-content-base py-1' h-8"
                    onBlur={() => handleOnBlur(ballPrice[tech]?.[demand] || 0, tech, demand)}
                    onFocus={() => handleOnFocus(ballPrice[tech]?.[demand] || 0, tech, demand)}
                    onChange={(e) => handleOnChange(e.target.value, tech, demand)}
                    defaultValue={parseFloat('0').toFixed(2)}
                    onKeyDown={handleKeyDown}
                    min="0"
                  />
                </InputContainer>
              </InputBase>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default BallPrice
