import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const DarkBlueSubtitle = ({ children, className }) => {
  return <h3 className={cx(styles.subtitle, className)}>{children}</h3>
}

DarkBlueSubtitle.propTypes = {
  className: PropTypes.string,
}

DarkBlueSubtitle.defaultProps = {
  className: '',
}

export default DarkBlueSubtitle
