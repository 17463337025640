export const loadExternalScript = (script: string) => {
  const scriptEle = document.createElement('script')
  scriptEle.setAttribute('src', script)
  scriptEle.setAttribute('type', 'text/javascript')
  document.body.appendChild(scriptEle)
  return scriptEle
}

export const getAddressValues = (place) => {
  let line1 = ''
  let postalCode = ''
  let city = ''
  let state = ''
  let country = ''

  for (const component of place.address_components) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'street_number': {
        line1 = `${component.long_name} ${line1}`
        break
      }

      case 'route': {
        line1 += component.short_name
        break
      }

      case 'postal_code': {
        postalCode = `${component.long_name}${postalCode}`
        break
      }

      case 'postal_code_suffix': {
        postalCode = `${postalCode}-${component.long_name}`
        break
      }

      case 'locality':
        city = component.long_name
        break

      case 'sublocality_level_1':
        city = component.long_name
        break

      case 'postal_town':
        city = component.long_name
        break

      case 'administrative_area_level_1': {
        state = component.long_name
        break
      }

      case 'country':
        country = component.short_name
        break
    }
  }

  return { line1, postalCode, city, state, country }
}
