export const teeTimeTypes = [
  { id: 0, name: 'Open', value: 'open' },
  { id: 1, name: 'Member Time', value: 'members_only' },
  { id: 2, name: 'Start Forbidden', value: 'start_forbidden' },
  { id: 3, name: 'On Site', value: 'on_site' },
  { id: 4, name: 'Group Booking', value: 'group_booking' },
  { id: 5, name: 'Tournament', value: 'tournament' },
  { id: 6, name: 'Blocked', value: 'blocked' },
]

export const daysOptions = [
  { id: 0, name: 'Every day', value: 'every_day' },
  { id: 1, name: 'Weekend', value: 'weekend' },
  { id: 2, name: 'Weekdays', value: 'weekday' },
  { id: 3, name: 'Monday', value: 'monday' },
  { id: 4, name: 'Tuesday', value: 'tuesday' },
  { id: 5, name: 'Wednesday', value: 'wednesday' },
  { id: 6, name: 'Thursday', value: 'thursday' },
  { id: 7, name: 'Friday', value: 'friday' },
  { id: 8, name: 'Saturday', value: 'saturday' },
  { id: 9, name: 'Sunday', value: 'sunday' },
]

export const playerTypes = [
  { id: 1, value: 'guest', label: 'Guest', translation: 'guest' },
  { id: 2, value: 'guest to member', label: 'Guest To Member', translation: 'guestToMember' },
  { id: 3, value: 'member', label: 'Member', translation: 'member' },
  { id: 4, value: 'partner', label: 'Partner', translation: 'partner' },
  { id: 5, value: 'total', label: 'Total', translation: 'total' },
]

export const buttonsTypes = {
  VOUCHER: 'voucher',
  PARTNERSHIP: 'partnership',
}

export const filtersMap = {
  ACTIVE: '.active',
  INACTIVE: '.inActive',
  EXPIRED: '.expired',
  CANCELED: '.canceled',
}
