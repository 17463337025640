import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

export function _getSpecificGolfCourse(id) {
  const URL = `${getApiPlatformBaseUrl()}/courses/${id}`

  return request(URL, {
    method: 'GET',
  })
}

export function _updateGolfCourse(courseId, payload) {
  const URL = `${getApiPlatformBaseUrl()}/courses/${courseId}`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _deleteGolfCourse(id) {
  const URL = `${getApiPlatformBaseUrl()}/courses/${id}`

  return request(URL, {
    method: 'DELETE',
  })
}

export function _uploadGolfCourseImage(courseId, imageId) {
  const URL = `${getApiPlatformBaseUrl()}/courses/images`

  return request(URL, {
    method: 'POST',
    body: {
      course: `/api/courses/${courseId}`,
      media_object: `/api/media-objects/${imageId}`,
    },
  })
}

export function _getGolfCourseImages(id) {
  const URL = `${getApiPlatformBaseUrl()}/courses/images?course=${id}`
  return request(URL, {
    method: 'GET',
  })
}

export function _getCourseGuide(id) {
  const URL = `${getApiPlatformBaseUrl()}/courses/${id}/guide`
  return request(URL, {
    method: 'GET',
  })
}
export function _getCourseGuideImages(id) {
  const URL = `${getApiPlatformBaseUrl()}/course-guides/${id}/images`
  return request(URL, {
    method: 'GET',
  })
}

export function _addGuideImage(id, newImage) {
  const URL = `${getApiPlatformBaseUrl()}/course-guides/${id}/image`

  const image = new FormData()
  image.append('image', newImage[0])

  return request(URL, {
    contentType: 'multipart/form-data',
    method: 'POST',
    body: image,
  })
}

export function _createCourseGuide(payload) {
  const URL = `${getApiPlatformBaseUrl()}/course-guides`

  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

export function _updateCourseGuide(id, payload) {
  const URL = `${getApiPlatformBaseUrl()}/course-guides/${id}`

  return request(URL, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: payload,
  })
}

export function _importCourseGuideFromCaddee(id) {
  const URL = `${getApiPlatformBaseUrl()}/courses/${id}/guide/import`

  return request(URL, {
    method: 'PUT',
  })
}

export function _getBookings(query) {
  const URL = `${getApiPlatformBaseUrl()}/legacy/bookings${query}`

  return request(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
    },
  })
}

export function _getTeeTimeCategory(id) {
  const URL = `${getApiPlatformBaseUrl()}/tee-time-categories/${id}`

  return request(URL, {
    method: 'GET',
  })
}

export function _createTeeTimeCategory(payload) {
  const URL = `${getApiPlatformBaseUrl()}/tee-time-categories`

  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

export function _updateTeeTimeCategory(payload) {
  const URL = `${getApiPlatformBaseUrl()}/tee-time-categories/${payload.id}`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _getReservationPolicy(uuid) {
  const URL = `${getApiPlatformBaseUrl()}/reservation-policies/${uuid}`

  return request(URL, {
    method: 'GET',
  })
}

export function _createReservationPolicy(uuid, payload) {
  const URL = `${getApiPlatformBaseUrl()}/reservation-policies/${uuid}/checker`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _updateReservationPolicy(checkerUuid, payload) {
  const URL = `${getApiPlatformBaseUrl()}/reservation-policy-checkers/${checkerUuid}`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export function _removeReservationPolicy(checkerUuid) {
  const URL = `${getApiPlatformBaseUrl()}/reservation-policy-checkers/${checkerUuid}`

  return request(URL, {
    method: 'DELETE',
  })
}
