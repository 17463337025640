import React from 'react'
import PropTypes from 'prop-types'

import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import UnbookedIcon from '@sweetspot/club-portal-legacy/resources/images/available_slot_asset.svg'
import BookedSlotIcon from '@sweetspot/club-portal-legacy/resources/images/booked_slot_asset.svg'
import GuestSlotIcon from '@sweetspot/club-portal-legacy/resources/images/guest_slot_asset.svg'
import MemberSlotIcon from '@sweetspot/club-portal-legacy/resources/images/member_slot_asset.svg'
import PartnerSlotIcon from '@sweetspot/club-portal-legacy/resources/images/partner_slot_asset.svg'
import AnonSlotIcon from '@sweetspot/club-portal-legacy/resources/images/anonymous-booked-16x16.svg'
import ReservedSlotIcon from '@sweetspot/club-portal-legacy/resources/images/reserved_slot_asset_16.svg'
import ARSlotIcon from '@sweetspot/club-portal-legacy/resources/images/AR_slot_asset.svg'
import ARGuestSlotIcon from '@sweetspot/club-portal-legacy/resources/images/AR_guest_asset.svg'
import ARMemberSlotIcon from '@sweetspot/club-portal-legacy/resources/images/AR_member_asset.svg'
import ARPartnerSlotIcon from '@sweetspot/club-portal-legacy/resources/images/AR_partner_asset.svg'
import ARAnonIcon from '@sweetspot/club-portal-legacy/resources/images/anonymous-AR-18x18.svg'
import NotesIcon from '@sweetspot/club-portal-legacy/resources/images/note-icon.svg'
import UnPaidIcon from '@sweetspot/club-portal-legacy/resources/images/unpaid-icon.svg'
import GolfCartConfirmedIcon from '@sweetspot/club-portal-legacy/resources/images/golf_cart_confirmed_icon.svg'
import GolfCartRequestedIcon from '@sweetspot/club-portal-legacy/resources/images/golf_cart_requested_icon.svg'
import GolfCartPaidIcon from '@sweetspot/club-portal-legacy/resources/images/golf_cart_paid_icon.svg'

import style from './style.module.scss'

const LegendPopup = (props) => {
  return (
    <OverlayContainer>
      <div className={style.container}>
        <div className={style.close}>
          <span onClick={props.togglePopup}>&times;</span>
        </div>
        <div className={`grid grid-cols-3 ${style.content}`}>
          <div className="col-span-2">
            <div className={style.title}>
              <Text textId="teeSheet.iconLegend" />
            </div>
            <div className={style.subTitle}>
              <LegendItem imgSrc={UnbookedIcon} text="teeSheet.unbooked" />
            </div>
            <div className="grid grid-cols-2">
              <div>
                <LegendItem imgSrc={MemberSlotIcon} text="teeSheet.memberBooked" />
              </div>
              <div>
                <LegendItem imgSrc={ARMemberSlotIcon} text="teeSheet.arMember" />
              </div>
              <div>
                <LegendItem imgSrc={GuestSlotIcon} text="teeSheet.guestBooked" />
              </div>
              <div>
                <LegendItem imgSrc={ARGuestSlotIcon} text="teeSheet.arGuest" />
              </div>
              <div>
                <LegendItem imgSrc={PartnerSlotIcon} text="teeSheet.partnerBooked" />
              </div>
              <div>
                <LegendItem imgSrc={ARPartnerSlotIcon} text="teeSheet.arPartner" />
              </div>
              <div>
                <LegendItem imgSrc={BookedSlotIcon} text="teeSheet.booked" />
              </div>
              <div>
                <LegendItem imgSrc={ARSlotIcon} text="teeSheet.arBooked" />
              </div>
              <div>
                <LegendItem imgSrc={AnonSlotIcon} text="teeSheet.anonBooked" />
              </div>
              <div>
                <LegendItem imgSrc={ARAnonIcon} text="teeSheet.arAnon" />
              </div>
              <div>
                <LegendItem imgSrc={ReservedSlotIcon} text="teeSheet.reservedBooked" />
              </div>
            </div>
          </div>
          {/* <Col md="2">
            <div className={style.title}>
              <Text textId="teeSheet.teeTimeColor" />
            </div>
            <LegendItem teeTimeType="open" text="teeSheet.standardTime" />
            <LegendItem teeTimeType="members_only" text="teeSheet.memberTime" />
            <LegendItem teeTimeType="on_site" text="golf.on_site" />
            <LegendItem teeTimeType="blocked" text="golf.blocked" />
            <LegendItem teeTimeType="group_booking" text="golf.group_booking" />
            <LegendItem teeTimeType="start_forbidden" text="golf.start_forbidden" />
            <LegendItem teeTimeType="tournament" text="golf.tournament" />
          </Col> */}
          <div>
            <div className={style.title}>
              <Text textId="teeSheet.otherIcons" />
            </div>
            <LegendItem imgSrc={NotesIcon} text="golf.note" />
            <LegendItem imgSrc={UnPaidIcon} text="golf.unpaid" />
            <LegendItem imgSrc={GolfCartRequestedIcon} text="teeSheet.golfCartRequested" />
            <LegendItem imgSrc={GolfCartConfirmedIcon} text="teeSheet.golfCartConfirmed" />
            <LegendItem imgSrc={GolfCartPaidIcon} text="teeSheet.golfCartPaid" />
          </div>
        </div>
      </div>
    </OverlayContainer>
  )
}

LegendPopup.propTypes = {
  togglePopup: PropTypes.func.isRequired,
}

export default LegendPopup

export const LegendItem = (props) => {
  const classNames = [style.rect, props.teeTimeType]

  return (
    <div className={style.legendItem}>
      {props.imgSrc && <img alt="legend" src={props.imgSrc} />}
      {props.teeTimeType && <div className={classNames.join(' ')} />}
      <Text textId={props.text} />
    </div>
  )
}

LegendItem.propTypes = {
  imgSrc: PropTypes.string,
  teeTimeType: PropTypes.string,
  text: PropTypes.string.isRequired,
}
