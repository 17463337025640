import { useRef, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

const RightClickMenu = ({ attachToElementRef, options, elementQuery, clickedRow }) => {
  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  })

  // On Click outside
  const containerRef = useRef()
  const handleOnRequestClose = () => {
    setVisible(false)
  }
  useOnClickOutside(containerRef, handleOnRequestClose)

  const handleOnClick = (onClick) => {
    onClick(clickedRow)
    setVisible(false)
  }

  const handleAttachedElementClick = (event) => {
    const { clientY, clientX } = event
    setPosition({
      top: clientY,
      left: clientX,
    })
    setVisible(true)

    event.preventDefault()
    return false
  }

  useEffect(() => {
    if (!options || options?.length <= 0) {
      return
    }

    let element =
      attachToElementRef?.current ||
      attachToElementRef ||
      (elementQuery && document.querySelector(elementQuery))

    const listener = (event) => {
      if (!element || !element?.contains(event.target)) {
        return
      }
      handleAttachedElementClick(event)
    }

    document.addEventListener('contextmenu', listener)
    return () => {
      document.removeEventListener('contextmenu', listener)
    }
  }, [attachToElementRef, options, elementQuery])

  useEffect(() => {
    if (!options || options?.length <= 0) {
      return
    }

    let el = document.createElement('div')
    el.id = crypto.randomUUID()
    document.body.appendChild(el)

    const root = createRoot(el)
    root.render(
      <div
        ref={containerRef}
        className={cx(styles.container, {
          [styles.visible]: visible,
        })}
        style={position}
      >
        {options.map(({ label, icon: Icon, onClick, children, disabled }) => (
          <div
            key={label}
            className={cx(styles.option, { [styles.disabled]: disabled })}
            onClick={() => !disabled && handleOnClick(onClick)}
          >
            {children ? (
              children
            ) : (
              <div className={cx(styles.noChildrenWrapper)}>
                {Icon && (
                  <div className={cx(styles.iconWrapper)}>
                    <Icon className={cx(styles.icon)} />
                  </div>
                )}
                {label && <p className={cx(styles.label)}>{label}</p>}
              </div>
            )}
          </div>
        ))}
      </div>
    )

    return () => el.parentNode?.removeChild(el)
  }, [position, options, visible, handleOnClick])

  return null
}

RightClickMenu.propTypes = {
  attachToElementRef: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      onClick: PropTypes.func,
      children: PropTypes.node,
    })
  ),
  elementQuery: PropTypes.string,
}

RightClickMenu.defaultProps = {}

export default RightClickMenu
