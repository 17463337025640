import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { ChromePicker } from 'react-color'
import ClipboardCopy from '@sweetspot/club-portal-legacy/components/ClipboardCopy'
import FileUploader from '../FileUploader'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import RadioGroup from '@sweetspot/sweetspot-js/common/components/FormElements/RadioGroup'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ToggleSwitchControlled from '@sweetspot/sweetspot-js/common/components/ToggleSwitchControlled'

import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import style from './style.module.scss'

const Form = ({
  status,
  headerColor,
  textColor,
  showClubName,
  url,
  bgImage,
  logo,
  disabled,
  submitDisabled,
  isSuperAdmin,
  onChange,
  onSubmit,
}) => {
  const [isShowCP, setShowCP] = useState(false)
  const _colorPicker = useRef()

  const { t } = useTranslation()

  const colorOptions = [
    { value: 'black', label: '.black', color: '#000000' },
    { value: 'dark', label: '.dark', color: '#585858' },
    { value: 'light', label: '.light', color: '#A8A8A8' },
    { value: 'white', label: '.white', color: '#ffffff' },
  ]

  useOnClickOutside(_colorPicker, () => {
    setShowCP(false)
  })

  const isValidUrl = (url) => {
    const regEx = /^[A-Za-z0-9-_]+$/

    return regEx.test(url)
  }

  return (
    <div className={style.settings}>
      <div className={style.title}>
        <Text textId="settings.webBookings.text" />
      </div>

      <div className={style.form}>
        <div className={style.formField}>
          <ToggleSwitchControlled
            switchWidth="half"
            large
            label=".status"
            textChecked=".active"
            textUnchecked=".inActive"
            disabled={disabled}
            checked={status}
            onChange={() => onChange('status', !status)}
          />
        </div>

        <div className={style.formField}>
          <div className={style.header}>
            <Label label=".settings.webBookings.primaryColor" />
          </div>

          <div className={style.headerColor} onClick={() => setShowCP(true)}>
            <div>{headerColor}</div>
            <div style={{ background: headerColor }} />
          </div>
          {isShowCP && (
            <div ref={_colorPicker} className={style.colorPicker}>
              <ChromePicker
                color={headerColor || '#fff'}
                disableAlpha
                onChange={(color) => onChange('headerColor', color?.hex)}
              />
            </div>
          )}
        </div>

        <div className={style.formField}>
          <div className={style.header}>
            <Label label=".settings.webBookings.textColor" />
          </div>

          <RadioGroup
            disabled={disabled}
            value={textColor}
            options={colorOptions}
            onChange={(value) => onChange('textColor', value)}
          />

          <label className={style.colorPad}>
            {colorOptions.map((option, index) => (
              <div key={index} style={{ background: option.color }} />
            ))}
          </label>
        </div>

        <div className={style.formField}>
          <div className={style.header}>
            <Label
              label=".settings.webBookings.backgroundImage"
              infoTextKey="settings.webBookings.backgroundImageInfo"
            />
          </div>

          <FileUploader
            accept="image/png,image/jpeg"
            disabled={disabled}
            fileSize={10}
            prevFile={bgImage}
            confirmMessage={t('settings.webBookings.confirmUploadBgImage')}
            onError={(err) => onChange('error', err)}
            onChange={(file) => onChange('bgImage', file)}
          />
        </div>

        <div className={style.formField}>
          <div className={style.header}>
            <Label label=".settings.webBookings.logo" infoTextKey="settings.webBookings.logoInfo" />
          </div>

          <FileUploader
            accept="image/png"
            disabled={disabled}
            fileSize={10}
            prevFile={logo}
            confirmMessage={t('settings.webBookings.confirmUploadLogo')}
            onError={(err) => onChange('error', err)}
            onChange={(file) => onChange('logo', file)}
          />
        </div>

        <div className={style.formField}>
          <div className={style.header}>
            <Label label=".settings.webBookings.showClubName" />
          </div>

          <RadioGroup
            value={showClubName}
            options={[
              { value: 1, label: '.yes' },
              { value: 0, label: '.no' },
            ]}
            onChange={(value) => onChange('showClubName', value)}
          />
        </div>

        <div className={style.formField}>
          <div className={style.header}>
            <Label label=".settings.webBookings.url" infoTextKey="settings.webBookings.urlInfo" />
          </div>

          <div className={style.urlField}>
            <span>book.sweetspot.io/clubs/</span>
            {isSuperAdmin ? (
              <TextInputField
                value={url}
                disabled={disabled}
                error={disabled || isValidUrl(url) ? '' : t('settings.webBookings.invalidUrl')}
                onChange={(value) => onChange('url', value)}
              />
            ) : (
              url
            )}
          </div>

          <ClipboardCopy
            autoHide={1000}
            text={`book.sweetspot.io/clubs/${url}`}
            textCopied="settings.webBookings.urlCopied"
          >
            <button className="system-button info-outline md-32">
              {t('settings.webBookings.copyUrl')}
            </button>
          </ClipboardCopy>
        </div>

        <div className={style.formField}>
          <button
            className="system-button primary md-32"
            disabled={submitDisabled}
            onClick={onSubmit}
          >
            {t('save')}
          </button>
        </div>
      </div>
    </div>
  )
}

Form.propTypes = {
  status: PropTypes.bool,
  headerColor: PropTypes.string,
  textColor: PropTypes.string,
  url: PropTypes.string,
  bgImage: PropTypes.object,
  logo: PropTypes.object,
  showClubName: PropTypes.number,
  disabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Form
