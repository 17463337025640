import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import ToolTip from '../ToolTip/tooltip'
import _isString from 'lodash/isString'
import _isUndefined from 'lodash/isUndefined'

const DropdownBox = ({
  visible,
  options,
  onOptionClick,
  className,
  groupTitleClassName,
  disabledOptionsClassName,
  loading,
  useToolTip,
}) => {
  const groups = useMemo(() => {
    return options?.filter((item) => _isString(item?.groupName)).sort((a, b) => a.order - b.order)
  }, [options])

  const notGroupedOptions = useMemo(() => {
    return options?.filter((item) => _isUndefined(item?.groupName))
  }, [options])

  const onOptionClickHandler = (option) => {
    onOptionClick(option)
  }

  const renderOption = (option) => (
    <div
      key={option.id}
      className={cx(styles.row, {
        [styles.disabled]: option?.disabled,
        [disabledOptionsClassName]: option?.disabled,
        [styles.notSelectable]: option?.notSelectable,
      })}
      onClick={() => !option?.disabled && !option?.notSelectable && onOptionClickHandler(option)}
    >
      {option?.icon && (
        <span className={cx('material-icons', styles.icon)} style={{ color: option?.iconColor }}>
          {option?.icon}
        </span>
      )}
      {useToolTip ? (
        <ToolTip text={option?.label} useOverflowCheck={true}>
          <p className={cx(styles.label)}>{option?.label}</p>
        </ToolTip>
      ) : (
        <p className={cx(styles.label)}>{option?.label}</p>
      )}
    </div>
  )

  if (!options) return null

  return (
    <div
      className={cx(styles.container, className, {
        [styles.visible]: visible,
      })}
    >
      <div className={cx(styles.row, styles.loaderRow, loading && styles.visible)}>
        <PulseLoader showIf={true} />
      </div>
      {!loading && notGroupedOptions.map((option) => renderOption(option))}
      {!loading &&
        groups.map((group) => (
          <React.Fragment key={group.groupName}>
            {group.groupName && (
              <div className={cx(styles.row, styles.notSelectable)}>
                <p className={cx(styles.label, styles.groupName, groupTitleClassName)}>
                  {group.groupName}
                </p>
              </div>
            )}
            {group.options.map((option) => renderOption(option))}
          </React.Fragment>
        ))}
    </div>
  )
}

const ItemPropTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
}

DropdownBox.propTypes = {
  visible: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ItemPropTypes),
      PropTypes.shape({
        groupName: PropTypes.string,
        order: PropTypes.number,
        options: PropTypes.arrayOf(ItemPropTypes),
      }),
    ])
  ),
  onOptionClick: PropTypes.func,
  className: PropTypes.string,
  groupTitleClassName: PropTypes.string,
  disabledOptionsClassName: PropTypes.string,
  loading: PropTypes.bool,
  useToolTip: PropTypes.bool,
}

DropdownBox.defaultProps = {
  visible: false,
  options: [],
  onOptionClick: () => {},
  className: '',
  groupTitleClassName: '',
  disabledOptionsClassName: '',
  loading: false,
  useToolTip: false,
}

export default DropdownBox
