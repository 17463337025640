import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { addDays, format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { ReactComponent as MinusIcon } from '@sweetspot/club-portal-legacy/resources/images/clear-icon.svg'
import { ReactComponent as RemoveIcon } from '@sweetspot/club-portal-legacy/resources/images/minus-icon.svg'
import { ReactComponent as TrashIcon } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import { ReactComponent as InfoIcon } from '@sweetspot/club-portal-legacy/resources/images/warning-icon.svg'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import ToolTip from '@sweetspot/sweetspot-js/ui-kit/components/ToolTip/tooltip'

import style from './style.module.scss'

const DAY_TO_MILISECOND = 24 * 3600 * 1000

const PeriodList = ({
  periods,
  activePeriod,
  onSelectPeriod,
  onSelectGap,
  onDeletePeriod,
  newVersion,
}) => {
  const [periodToDelete, setPeriodToDelete] = useState(null)
  const [activeGap, setActiveGap] = useState('')
  const { t } = useTranslation()

  const handleClickRemove = (period) => {
    setPeriodToDelete(period)
  }

  const deletePeriod = () => {
    onDeletePeriod(periodToDelete)
    setPeriodToDelete(null)
  }

  const handleClickGap = (startDate, endDate) => {
    setActiveGap(startDate)
    onSelectGap({ startDate, endDate })
  }

  const handleSelectPeriod = (period) => {
    setActiveGap('')
    onSelectPeriod(period)
  }

  const canBeRemoved = (period) => {
    return period?.start_date.substr(0, 10) > new Date().toISOString().substr(0, 10)
  }

  const renderPeriodGap = (period, prevPeriod) => {
    if (!prevPeriod || !period) return null
    const startDate = DateHelpers.onlyDateString(period.end_date, true)
    const endDate = DateHelpers.onlyDateString(prevPeriod.start_date)
    if (new Date(endDate) - new Date(startDate) <= DAY_TO_MILISECOND) return null

    const gapStart = format(addDays(new Date(startDate), 1), 'yyyy-MM-dd')
    const gapEnd = format(addDays(new Date(endDate), -1), 'yyyy-MM-dd')

    const isSingleDate = gapStart === gapEnd

    return (
      <ToolTip
        id="period-tooltip"
        text={
          isSingleDate
            ? t('editPeriodsNew.noPeriodAvailableSingle')
            : t('editPeriodsNew.noPeriodAvailable')
        }
      >
        <div
          className={cx(style.elementContainer, style.gap, {
            [style.active]: startDate === activeGap,
          })}
          onClick={() => handleClickGap(gapStart, gapEnd)}
        >
          <InfoIcon />
          {isSingleDate ? gapStart : `${gapStart} - ${DateHelpers.onlyDateString(gapEnd)}`}
        </div>
      </ToolTip>
    )
  }

  const renderPeriods = () => {
    return periods.map((period, index) => {
      const { start_date, end_date } = period

      return (
        <React.Fragment key={period.id}>
          {renderPeriodGap(period, periods[index - 1])}
          <div
            className={cx(style.elementContainer, {
              [style.selected]: period.id === activePeriod?.id,
              [style.draft]: period.status === 'draft',
            })}
            onClick={() => handleSelectPeriod(period)}
          >
            <div className={style.name}>
              <div>
                <span>{period.name}</span>
                <span></span>
              </div>
            </div>

            <div className={style.dates}>
              {start_date?.substring(0, 10)} - {end_date?.substring(0, 10)}
            </div>

            <ToolTip
              id="period-tooltip"
              text={
                canBeRemoved(period)
                  ? t('editPeriodsNew.deletePeriodInfo')
                  : t('editPeriodsNew.deletePeriodError1')
              }
            >
              {canBeRemoved(period) ? (
                <MinusIcon
                  className={style.removeIcon}
                  onClick={(event) => {
                    if (newVersion) {
                      event.stopPropagation()
                    }
                    handleClickRemove(period)
                  }}
                />
              ) : (
                <RemoveIcon className={style.minusIcon} />
              )}
            </ToolTip>
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <div className={style.container}>
      {renderPeriods()}

      {!!periodToDelete && (
        <ConfirmPopup
          visible={!!periodToDelete}
          acceptTheme="danger"
          titleIcon={TrashIcon}
          title={t('editPeriodsNew.deletePeriod')}
          rejectTextKey="cancel"
          acceptTextKey="settings.delete"
          text={t('editPeriodsNew.confirmDeletePeriod', {
            name: periodToDelete?.name,
            startDate: periodToDelete?.start_date?.substr(0, 10),
            endDate: periodToDelete?.end_date?.substr(0, 10),
          })}
          onReject={() => handleClickRemove()}
          onClose={() => handleClickRemove()}
          onAccept={deletePeriod}
        />
      )}
    </div>
  )
}

PeriodList.propTypes = {
  periods: PropTypes.array.isRequired,
  activePeriod: PropTypes.object,
  onSelectPeriod: PropTypes.func.isRequired,
  onSelectGap: PropTypes.func.isRequired,
  onDeletePeriod: PropTypes.func.isRequired,
}

export default PeriodList
