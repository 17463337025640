import { ReactNode, useEffect, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { faClock, faCopy, faPen } from '@fortawesome/pro-regular-svg-icons'
import { utcToZonedTime, format } from 'date-fns-tz'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { HeaderOption, HeaderSettingsOption } from '../../types'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'

interface HeaderSettingsProps {
  setHeader: (header: HeaderOption) => void
  timezone?: string
}

const HeaderSettings = ({ setHeader, timezone }: HeaderSettingsProps) => {
  const { t } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const teeSheetUrl = window.location.href

  // reset copy state when url changes
  useEffect(() => {
    setIsCopied(false)
  }, [teeSheetUrl])

  const date = utcToZonedTime(new Date().toISOString(), timezone as string)
  const tz = {
    timeZone: timezone,
  }

  const HEADER_SETTINGS_OPTIONS: HeaderSettingsOption[] = [
    {
      key: 'localTime',
      icon: faClock,
      label: `${t('sentences.localTimeAtVenue')} ${format(date, 'HH:mm', tz)}`,
      subLabel: `(${format(date, 'zzz', tz)}) ${timezone}`,
    },
    {
      key: 'editCategories',
      icon: faPen,
      label: `${t('words.edit')} ${t('sentences.teeTimeCategories').toLowerCase()}`,
      onClick: () => {
        setHeader(HeaderOption.EDIT_CATEGORY)
        setIsDropdownOpen(false)
      },
    },
    {
      key: 'copyLink',
      icon: faCopy,
      copyText: teeSheetUrl,
      label: isCopied ? t('teeSheet.copied') : t('teeSheet.copyLink'),
    },
  ]

  const maybeWrapWithCopy = (children: ReactNode, option: HeaderSettingsOption) => {
    if (option.copyText) {
      return (
        <CopyToClipboard text={option.copyText} onCopy={() => setIsCopied(true)}>
          {children}
        </CopyToClipboard>
      )
    }
    return children
  }

  return (
    <div className="relative flex h-8 items-center justify-center">
      <Popover open={isDropdownOpen}>
        <PopoverTrigger asChild>
          <div className="cursor-pointer px-4" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <FontAwesomeIcon icon={faEllipsis} size="1x" />
          </div>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="p-2 drop-shadow"
          onInteractOutside={() => setIsDropdownOpen(false)}
        >
          {HEADER_SETTINGS_OPTIONS.map((option) =>
            maybeWrapWithCopy(
              <ListItem
                key={option.key}
                className={cx(
                  'text-text-dark w-full min-w-fit cursor-pointer gap-5 px-2 hover:bg-gray-50',
                  option.subLabel ? 'py-2' : 'py-3'
                )}
                onClick={option.onClick}
              >
                <ListItemLeading className="px-0">
                  <FontAwesomeIcon icon={option.icon} />
                </ListItemLeading>
                <ListItemMainContainer>
                  <ListItemMainContent className="py-0">
                    <ListItemMainLabel className="h-auto min-h-6 font-bold">
                      {option.label}
                    </ListItemMainLabel>
                    {option.subLabel && (
                      <ListItemParagraph className="text-text-subtle">
                        {option.subLabel}
                      </ListItemParagraph>
                    )}
                  </ListItemMainContent>
                </ListItemMainContainer>
              </ListItem>,
              option
            )
          )}
          <PopoverArrow className="fill-white" width={16} height={10} />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default HeaderSettings
