import { ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import {
  InputBase,
  InputContainer,
  InputLeadingContainer,
  Input,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'

interface HeaderSearchInputProps {
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  searchValue: string
  handleResetSearch: () => void
  hasGolfId: boolean
}

const HeaderSearchInput = ({
  handleInputChange,
  searchValue,
  handleResetSearch,
  hasGolfId,
}: HeaderSearchInputProps) => {
  const { t } = useTranslation()

  return (
    <InputBase className="w-full p-4">
      <InputContainer>
        <InputLeadingContainer className="left-6">
          <FontAwesomeIcon icon={faMagnifyingGlass} size="1x" />
        </InputLeadingContainer>
        <Input
          onChange={handleInputChange}
          value={searchValue}
          rounding="pill"
          className="text-content-base pl-[52px] pr-11"
          placeholder={t(
            hasGolfId ? 'searchByFullNameOrPhoneOrEmailOrGolfId' : 'searchByFullNameOrPhoneOrEmail'
          )}
        />
        {!!searchValue && (
          <InputTrailingContainer onClick={handleResetSearch} className="cursor-pointer">
            <FontAwesomeIcon icon={faCircleXmark} size="1x" />
          </InputTrailingContainer>
        )}
      </InputContainer>
    </InputBase>
  )
}

export default HeaderSearchInput
