import { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  Button,
  Divider,
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
  InputTrailingContainer,
  RadioGroup,
  RadioGroupItem,
} from '@sweetspot/scramble-ds'
import ButtonDock from '@sweetspot/club-portal-legacy/components/ButtonDock/ButtonDock'

import { SetupFormFields } from '../types'
import { defaultBallDispenser } from '../constants'
import { useBallDispensersContext } from '../BallDispensersProvider'
import useRoutingBlock from '../hooks/useRoutingBlock'

const SetupTab = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { setupInitialData, handleSetupSave } = useBallDispensersContext()
  const { register, watch, setValue, formState, reset, control } = useForm<SetupFormFields>({
    defaultValues: setupInitialData,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ballDispensers',
  })
  const formValues = watch()
  const isDirty = formState?.isDirty
  const ballDispenserOutlets = useMemo(
    () => [
      { value: '1', name: t('settings.ballDispensers.oneOutlet') },
      { value: '2', name: t('settings.ballDispensers.twoOutlets') },
    ],
    [t]
  )

  // reset form initialData
  useEffect(() => {
    reset(setupInitialData)
  }, [setupInitialData, reset])

  const handleFormReset = useCallback(() => {
    reset()
  }, [reset])

  const onSubmit = useCallback(() => {
    handleSetupSave?.(formValues)?.then(() => {
      addToast(t('toast.setupUpdatedSuccess'), { appearance: 'success' })
    })
  }, [formValues, handleSetupSave, addToast, t])

  // block navigation when form is dirty
  useRoutingBlock({ isDirty, onReset: reset, onSubmit })

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="flex max-w-[464px] flex-col gap-6 pb-16 pt-2"
    >
      <div className="flex min-h-12 items-center justify-between gap-4">
        <div>
          <label className="text-text-dark text-content-sm px-md mb-0.5 font-bold">
            {t('settings.ballDispensers.nrOfBallDispensersLabel')}
          </label>
          <div className="text-text-subtle text-content-sm pl-2">
            {t('settings.ballDispensers.nrOfBallDispensersHint')}
          </div>
        </div>
        <div className="flex items-center gap-6">
          <div
            className="bg-surface-inverted-primary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
            onClick={() => remove(fields?.length - 1)}
          >
            <i className="fa-regular fa-minus" />
          </div>
          <div>{fields?.length}</div>
          <div
            className="bg-surface-inverted-primary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
            onClick={() => append(defaultBallDispenser, { shouldFocus: false })}
          >
            <i className="fa-regular fa-plus" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        {fields?.map((ballDispenser, index) => {
          const nrOfOutlets = formValues.ballDispensers[index].nrOfOutlets
          return (
            <div
              className="bg-background-base-neutral flex flex-col gap-4 rounded-md border p-4"
              key={`${ballDispenser.id}-${index}`}
            >
              <div className="text-content-base ml-2 font-bold">
                {ballDispenser.name || `${t('sentences.ballDispenser')} ${index + 1}`}
              </div>

              <Divider />

              <InputBase>
                <InputLabelContainer className="text-text-dark mb-0.5">
                  {t('settings.ballDispensers.ballDispenserNameLabel')}
                </InputLabelContainer>
                <InputContainer>
                  <Input
                    {...register(`ballDispensers.${index}.name`)}
                    className="text-content-base h-8 border"
                    placeholder={`${t('sentences.ballDispenser')} ${index + 1}`}
                  />
                </InputContainer>
                <InputHint
                  className="[&_i]:text-content-xs [&_:first-child]:w-auto [&_i]:pt-[2px]"
                  variant="default"
                  hintText={t('settings.ballDispensers.ballDispenserNameHint')}
                />
              </InputBase>

              <Divider />

              <InputBase disabled>
                <InputLabelContainer className="text-text-pale mb-0.5">
                  {t('words.brand')}
                </InputLabelContainer>
                <InputContainer>
                  <Input
                    className="text-content-base h-8 cursor-not-allowed border"
                    value={ballDispenser.brand}
                  />
                  <InputTrailingContainer>
                    <span className="fa-regular fa-angle-down" />
                  </InputTrailingContainer>
                </InputContainer>
              </InputBase>

              <Divider />

              <div className="px-2">
                <label className="text-text-dark text-content-sm mb-0.5 font-bold">
                  {t('settings.ballDispensers.nrOfOutletsLabel')}
                </label>
                <div className="text-text-subtle text-content-sm mb-2">
                  {t('settings.ballDispensers.nrOfOutletsHint')}
                </div>
                <RadioGroup
                  className="w-fit-content [&_input]:hidden"
                  value={String(nrOfOutlets)}
                  onValueChange={(value) => {
                    setValue(`ballDispensers.${index}.nrOfOutlets`, parseInt(value), {
                      shouldDirty: true,
                    })
                  }}
                >
                  {ballDispenserOutlets.map((outlet) => (
                    <RadioGroupItem
                      key={outlet.value}
                      className="justify-start p-0 [&_label]:mt-0"
                      value={outlet.value}
                      label={outlet.name}
                      isActive={String(nrOfOutlets) === outlet.value}
                    />
                  ))}
                </RadioGroup>
              </div>

              {nrOfOutlets === 2 && (
                <>
                  <div className="text-text-dark text-content-sm pl-2">
                    {t('settings.ballDispensers.twoOutletsHint')}
                  </div>

                  <InputBase required>
                    <InputLabelContainer className="text-text-dark mb-0.5">
                      {t('settings.ballDispensers.nameOfFirstOutletLabel')}
                    </InputLabelContainer>
                    <InputContainer>
                      <Input
                        {...register(`ballDispensers.${index}.outletOneName`)}
                        className="text-content-base h-8 border"
                        placeholder={t('settings.ballDispensers.nameOfFirstOutletPlaceholder')}
                      />
                    </InputContainer>
                  </InputBase>

                  <InputBase required>
                    <InputLabelContainer className="text-text-dark mb-0.5">
                      {t('settings.ballDispensers.nameOfSecondOutletLabel')}
                    </InputLabelContainer>
                    <InputContainer>
                      <Input
                        {...register(`ballDispensers.${index}.outletTwoName`)}
                        className="text-content-base h-8 border"
                        placeholder={t('settings.ballDispensers.nameOfSecondOutletPlaceholder')}
                      />
                    </InputContainer>
                  </InputBase>
                </>
              )}
            </div>
          )
        })}
      </div>

      <ButtonDock
        className="px-0 pl-6 md:justify-center"
        dockClassName="left-[15px] pr-6"
        isShown={isDirty}
      >
        <Button variant="ghost-dark" size="small" type="button" onClick={handleFormReset}>
          {t('words.cancel')}
        </Button>
        <Button variant="primary" size="small" onClick={onSubmit}>
          {t('words.save')}
        </Button>
      </ButtonDock>
    </form>
  )
}

export default SetupTab
