import { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  Button,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { VenuesIcon } from '@sweetspot/club-portal/feature/venues'
import { GolfCourseTypes } from '@sweetspot/shared/types'

import { useManageRangeContext } from '../ManageRangeProvider'

const RangeSelector = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { ranges, selectedRange, setSelectedRange, toggleCreateRangeSideBar } =
    useManageRangeContext()

  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!!setSelectedRange && !selectedRange && ranges?.length) {
      setSelectedRange(ranges[0])
    }
  }, [ranges, selectedRange, setSelectedRange])

  // set selectedRange based on hash
  useEffect(() => {
    const hash = history.location.hash
    const selected = ranges.find((range) => `#${range.id}` === hash)
    if (setSelectedRange && selected) {
      setSelectedRange(selected)
      setSearch('')
    }
  }, [history.location.hash, ranges, setSelectedRange])

  const rangesToRender = useMemo(
    () =>
      ranges?.filter(
        (range) => range.name.toLowerCase().includes(search.toLowerCase()),
        [search, ranges]
      ),
    [search, ranges]
  )

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue === 'new') {
        toggleCreateRangeSideBar()
        return
      }
      const selected = ranges.find((range) => range.id === newValue)
      if (setSelectedRange && selected) {
        const path = history.location.pathname
        history.push(`${path}#${newValue}`)
      }
    },
    [ranges, setSelectedRange, toggleCreateRangeSideBar, history]
  )

  const value = useMemo(
    () => ranges.find((range) => range.id === selectedRange?.id)?.name,
    [ranges, selectedRange?.id]
  )

  return (
    <InputSelect value={value} onValueChange={handleValueChange}>
      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('words.range')}</InputLabelContainer>
        <InputSelectTrigger asChild>
          <InputContainer className="cursor-pointer">
            <InputLeadingContainer>
              <VenuesIcon courseType={GolfCourseTypes.DRIVING_RANGE} type="default" />
            </InputLeadingContainer>
            <InputSelectStatefulInput
              value={value}
              onChange={() => null}
              onClick={() => setSearch('')}
              className="text-content-base text-ellipsis px-10 "
              readOnly
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>

      <InputSelectContent>
        <InputSelectContentView>
          {rangesToRender?.map((range) => (
            <InputSelectItem key={range.id} value={range.id}>
              <ListItem className="ml-4 h-full w-[376px] justify-center gap-2">
                <ListItemMainContent className="flex-row justify-between pr-2">
                  <ListItemParagraph className="text-content-base">{range.name}</ListItemParagraph>
                  <ListItemTrailing>
                    {selectedRange?.id === range.id && (
                      <i className="fa-regular fa-check h-4 w-4" />
                    )}
                  </ListItemTrailing>
                </ListItemMainContent>
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
        <InputSelectContentView className="mt-0.5 min-h-12">
          <InputSelectItem value="new" className="flex justify-center">
            <Button
              variant="link-dark"
              className="text-content-lg text-text-dark h-12 w-full font-medium hover:no-underline focus:no-underline"
            >
              <i className="fa-regular fa-circle-plus " />
              {t('words.new')}
            </Button>
          </InputSelectItem>
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default RangeSelector
