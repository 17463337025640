import { Children } from 'react'
import { cn } from '../../../utils'
import { Button } from '../../atoms'
import { useSideSystemNavStore } from './state'
import { IsInBottomNavContext } from './contexts'

/* ------------------------------------ */
/* ------------------ Sidebar System Nav ------------------ */
/* ------------------------------------ */
type SideSystemNavProps = React.HTMLAttributes<HTMLDivElement>
const SideSystemNav = ({ children, className, ...props }: SideSystemNavProps) => {
  const { isExpanded, toggleIsExpanded } = useSideSystemNavStore()

  return (
    <div className={cn('h-full w-auto translate-x-0', className)} {...props}>
      <div
        data-testid="side-system-nav-container"
        className={cn(
          '!bg-sand-50 transiton-[min-width] relative h-full translate-x-0 select-none overflow-hidden overflow-x-auto transition-[width] duration-300 [&::-webkit-scrollbar]:hidden',
          {
            'w-[256px] min-w-[256px]': isExpanded,
            'w-[52px] min-w-[52px]': !isExpanded,
          },
          'flex flex-col items-start justify-between'
        )}
      >
        <div
          data-testid="top-level-items-container"
          className={cn(
            'flex-col items-start justify-start overflow-y-auto overflow-x-hidden [&::-webkit-scrollbar]:hidden'
          )}
        >
          <IsInBottomNavContext.Provider value={false}>
            {Children.map(
              children,
              (child) => child?.type?.displayName !== 'BottomNavContent' && child
            )}
          </IsInBottomNavContext.Provider>
        </div>
        <IsInBottomNavContext.Provider value>
          {Children.map(
            children,
            (child) => child?.type?.displayName === 'BottomNavContent' && child
          )}
        </IsInBottomNavContext.Provider>
      </div>
      <div
        className={cn(
          'fixed bottom-[82px] left-0 z-50 transition-[left] duration-300 hover:will-change-[left]',
          {
            'left-[32px]': !isExpanded,
            'left-[238px]': isExpanded,
          }
        )}
      >
        <Button
          onClick={toggleIsExpanded}
          size="small"
          variant="clear-dark"
          className="!bg-background-sand-lighter !text-text-dark border px-2.5 focus:ring-0"
          data-testid="toggle-nav-button"
        >
          <div
            className={cn('rotate-0 transition-transform hover:will-change-transform', {
              'rotate-180': isExpanded,
            })}
          >
            <i className={cn('fa-regular fa-angle-right text-content-xl ml-auto')} />
          </div>
        </Button>
      </div>
    </div>
  )
}
SideSystemNav.displayName = 'SideSystemNav'

/* ------------------------------------ */
/* ------------------ Exports ------------------ */
/* ------------------------------------ */

export { SideSystemNav }
export { BottomNavContent } from './BottomNavContent/BottomNavContent'
export { TopLevelItem } from './TopLevelItem/TopLevelItem'
export { SecondLevelItem } from './SecondLevelItem/SecondLevelItem'
export { ThirdLevelItem } from './ThirdLevelItem/ThirdLevelItem'
