import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import _ from 'lodash'

export default class Pagination extends Component {
  constructor(props) {
    super(props)

    this.state = { activePage: 1 }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage > 1 && this.props.currentPage === 1) this.setState({ activePage: 1 })
  }

  // Debounce page changes to prevent spamming of requests
  handleChange = _.debounce(() => {
    this.props.onChange(this.state.activePage)
  }, 500)

  render() {
    const { displayRangeOfRows, totalPages, totalRows, rowsPerPage } = this.props
    const { activePage } = this.state

    let pages = []

    if (totalPages < 11) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i)
      }
    } else {
      if (activePage < 4 || activePage > totalPages - 3) {
        for (let i = 1; i <= 4; i++) {
          pages.push(i)
        }
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pages.push(i)
        }
      } else {
        for (let i = activePage - 2; i <= activePage + 2; i++) {
          pages.push(i)
        }
      }
    }

    const firstRowNo = totalRows > 0 ? activePage * rowsPerPage - rowsPerPage + 1 : 0

    let lastRowNo =
      totalRows > 0 ? (activePage !== totalPages ? activePage * rowsPerPage : totalRows) : 0
    return (
      <div className={style.content}>
        {displayRangeOfRows && (
          <div className={style.viewRange}>
            <Text textId="showing" />
            <span className={style.number}>&nbsp;{`${firstRowNo}-${lastRowNo}`}&nbsp;</span>
            <Text textId="of" />
            &nbsp;
            <span className={style.number}>{` ${totalRows}`}</span>
          </div>
        )}
        {totalPages > 1 && (
          <div className={style.pagination}>
            <span
              className={activePage === 1 ? style.disabled : ''}
              onClick={() =>
                activePage > 1 &&
                this.setState({ activePage: activePage - 1 }, () => this.handleChange())
              }
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
            <div className={style.pages}>
              {totalPages < 11 &&
                pages.map((page) => (
                  <span
                    key={page}
                    className={activePage === page ? style.active : ''}
                    onClick={() =>
                      page !== activePage &&
                      this.setState({ activePage: page }, () => this.handleChange())
                    }
                  >
                    {page}
                  </span>
                ))}
              {totalPages >= 11 &&
                (activePage < 4 || activePage > totalPages - 3 ? (
                  <div>
                    {pages.slice(0, 4).map((page) => (
                      <span
                        key={page}
                        className={activePage === page ? style.active : ''}
                        onClick={() =>
                          page !== activePage &&
                          this.setState({ activePage: page }, () => this.handleChange())
                        }
                      >
                        {page}
                      </span>
                    ))}
                    <span>...</span>
                    {pages.slice(pages.length - 4, pages.length).map((page) => (
                      <span
                        key={page}
                        className={activePage === page ? style.active : ''}
                        onClick={() =>
                          page !== activePage &&
                          this.setState({ activePage: page }, () => this.handleChange())
                        }
                      >
                        {page}
                      </span>
                    ))}
                  </div>
                ) : (
                  <div>
                    <span
                      key={1}
                      className={activePage === 1 ? style.active : ''}
                      onClick={() =>
                        1 !== activePage &&
                        this.setState({ activePage: 1 }, () => this.handleChange())
                      }
                    >
                      {1}
                    </span>
                    <span>...</span>
                    {pages.map((page) => (
                      <span
                        key={page}
                        className={activePage === page ? style.active : ''}
                        onClick={() =>
                          page !== activePage &&
                          this.setState({ activePage: page }, () => this.handleChange())
                        }
                      >
                        {page}
                      </span>
                    ))}
                    <span>...</span>
                    <span
                      key={totalPages}
                      className={activePage === totalPages ? style.active : ''}
                      onClick={() =>
                        totalPages !== activePage &&
                        this.setState({ activePage: totalPages }, () => this.handleChange())
                      }
                    >
                      {totalPages}
                    </span>
                  </div>
                ))}
            </div>
            <span
              className={activePage === totalPages ? style.disabled : ''}
              onClick={() =>
                activePage < totalPages &&
                this.setState({ activePage: activePage + 1 }, () => this.handleChange())
              }
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </div>
        )}
      </div>
    )
  }
}

Pagination.propTypes = {
  /**
   * current page number viewed in grid
   */
  currentPage: PropTypes.number.isRequired,
  /**
   * number of total pages
   */
  totalPages: PropTypes.number.isRequired,
  /**
   * number of rows on each page
   */
  rowsPerPage: PropTypes.number,
  /**
   * number of total rows
   */
  totalRows: PropTypes.number,
  /**
   * show current range of rows
   */
  displayRangeOfRows: PropTypes.bool,
  /**
   * Function to run when page is changed
   */
  onChange: PropTypes.func,
}
