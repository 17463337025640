import { useState, useCallback, SetStateAction } from 'react'
import _isFunction from 'lodash/isFunction'

const useMergeState = <S>(initial?: S): [S, SetStateAction<S>] => {
  const [state, setState] = useState<S>(initial)

  const setMergeState: SetStateAction<S> = useCallback((newState: SetStateAction<S>) => {
    if (_isFunction(newState)) {
      setState((currentState: S) => ({ ...currentState, ...newState(currentState) }))
    } else {
      setState((currentState: S) => ({ ...currentState, ...newState }))
    }
  }, [])

  return [state, setMergeState]
}

export default useMergeState
