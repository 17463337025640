import { ReactComponent as SSLogo } from '@sweetspot/shared/assets/images/logos/logo.svg'
import NotificationModal from '@sweetspot/sweetspot-js/common/components/NotificationModal'
import { useTranslation } from 'react-i18next'

interface LoginContainerProps {
  signInElement: JSX.Element
  currentLoginStep?: string
  resetPasswordStatus?: string | null
  changeResetPasswordStatus: (status: string | null) => void
}

export const LoginContainer = ({
  signInElement,
  currentLoginStep,
  resetPasswordStatus,
  changeResetPasswordStatus,
}: LoginContainerProps) => {
  const { t } = useTranslation()

  const renderNotificationModal = () => {
    if (!resetPasswordStatus) {
      return null
    }

    const title =
      resetPasswordStatus === 'success'
        ? t('sentences.resetPasswordSuccess')
        : t('sentences.resetPasswordLinkExpired')
    const text =
      resetPasswordStatus === 'success'
        ? t('sentences.resetPasswordSuccessStatus')
        : t('sentences.resetPasswordLinkExpiredStatus')

    return (
      <div className="flex h-full w-full">
        <NotificationModal
          status={resetPasswordStatus}
          title={title}
          text={text}
          onClose={() => changeResetPasswordStatus(null)}
        />
      </div>
    )
  }

  return (
    <div
      className={
        'bg-sand-100 relative flex h-screen min-h-0 w-full min-w-0 max-w-full flex-col items-stretch justify-normal overflow-y-auto px-8 py-4 md:w-[600px] md:px-24'
      }
    >
      <div className={'flex grow items-center'}>
        <div
          className={`flex w-full flex-col items-center space-y-6 ${
            currentLoginStep === 'RECOVER_PW' ? 'h-full' : ''
          }`}
        >
          <div className={'h-12 w-auto'}>
            <SSLogo />
          </div>
          {renderNotificationModal()}
          <div className={'flex h-full w-full flex-col items-center justify-center space-y-8'}>
            {signInElement}
          </div>
        </div>
      </div>
    </div>
  )
}
