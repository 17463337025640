import Header from './Header'
import { BallPricingProvider } from './BallPricingProvider'
import FilterToolbar from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/FilterToolbar'
import Body from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/Body'

const BallPricing = () => (
  <BallPricingProvider>
    <div className="flex h-full flex-col">
      <Header />
      <div className={'py-4'}>
        <FilterToolbar />
      </div>
      <div className={'flex flex-1 items-center justify-center px-6'}>
        <Body />
      </div>
    </div>
  </BallPricingProvider>
)

export default BallPricing
