import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import { Country } from '@sweetspot/shared/types'
import style from './style.module.scss'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

type Props = {
  id: number
  disabled?: boolean
  value: string | boolean | number | null
  fieldName: string
  gender: string | null
  memberType: string | null
  membershipCategory: string
  membershipType: string
  handleClick: (id: number) => void
  countries: Country[]
}

const DisplayField: FC<Props> = ({
  id,
  disabled,
  value,
  fieldName,
  gender,
  memberType,
  membershipCategory,
  membershipType,
  countries,
  handleClick,
}) => {
  const { t } = useTranslation()

  const fieldContent = useMemo(() => {
    if (fieldName === 'country') {
      return countries.find((country) => country.code === value)?.name || '-'
    }
    if (fieldName === 'gender') {
      return gender ? t(`settings.playerCard.${gender}`) : '-'
    }
    if (fieldName === 'memberType') {
      return memberType ? t(`settings.playerCard.${memberType}`) : '-'
    }
    if (fieldName === 'club_membership_category') {
      return membershipCategory ? t(`settings.playerCard.${membershipCategory}`) : '-'
    }
    if (fieldName === 'membership_type') {
      return membershipType ? t(`settings.playerCard.${membershipType}`) : '-'
    }
    if (fieldName === 'birthday') {
      return value ? DateHelpers.onlyDateString(value) : '-'
    }
    if (typeof value === 'string') return value || '-'
    if (typeof value === 'boolean') return value ? <Text textId="yes" /> : <Text textId="no" />
    return '-'
  }, [countries, fieldName, gender, t, value])

  return (
    <div
      className={!disabled ? style.field : style.disabledField}
      onClick={() => !disabled && handleClick(id)}
    >
      {fieldContent}
    </div>
  )
}

export default DisplayField
