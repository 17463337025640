import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import {
  AvailabilityPayload,
  AvailableSpaces,
  CalculatePricePayload,
  CalculatePriceResponse,
  ExtraPlayersPayload,
  MovePayload,
} from './types'

export const getMoveAvailability = (
  bookingId: string,
  payload: AvailabilityPayload
): Promise<AvailableSpaces> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingId}/move/availability?date=${
    payload.date
  }&time=${payload.time}`

  return request(URL)
}

export const moveBooking = (bookingId: string, payload: MovePayload): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingId}/move/spaces`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Makes a simulated price calculation for a booking (does not update the actual booking data)
 * @param bookingUuid
 * @param @see {CalculatePricePayload}
 * @returns @see {CalculatePriceResponse}
 */
export const calculatePrice = (
  bookingUuid: string,
  payload: CalculatePricePayload
): Promise<CalculatePriceResponse> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingUuid}/calculate-price`

  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

/**
 * Makes a simulated price calculation for a booking (does not update the actual booking data)
 * @param bookingUuid
 * @param @see {CalculatePricePayload}
 * @returns @see {CalculatePriceResponse}
 */
export const setNumberOfExtraPlayers = (
  bookingUuid: string,
  payload: ExtraPlayersPayload
): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingUuid}/players`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}
