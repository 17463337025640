import React, { useState, useEffect, useCallback, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import produce from 'immer'

import styles from './styles.module.scss'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import SinglePolicy from '../../components/SinglePolicy'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import POLICY from '../../constants/defaultPolicies'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { queryMembershipPolicies } from '../../services/api-platform'

import PoliciesContext from './PoliciesContext'
import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import useSelectedClubId from '@sweetspot/sweetspot-js/common/hooks/useSelectedClubId'

const PAGE_LIMIT = 40

const ReservationPolicyManager = ({ membershipId, partnershipId, withTitle }) => {
  const { t } = useTranslation()
  const clubId = useSelectedClubId()

  // Refs
  const paginationVars = useRef({
    currentPage: 1,
    currentPageIsEmpty: false,
  })
  const policiesContainerRef = useRef()

  // Actions states
  const [checkingPolicies, setCheckingPolicies] = useState(true)
  const [loadingMorePolicies, setLoadingMorePolicies] = useState(false)
  const [savingNewPolicy, setSavingNewPolicy] = useState(false)
  const [savingExistingPolicy, setSavingExistingPolicy] = useState(false)

  // Values
  const [openPolicies, setOpenPolicies] = useState([])
  const [policies, setPolicies] = useState([])

  const [filters, setFilters] = useMergeState({
    active: true,
    inactive: true,
    archived: false,
  })

  useEffect(() => {
    setCheckingPolicies(true)
    if (policiesContainerRef?.current?.scrollTop) {
      policiesContainerRef.current.scrollTop = 0
    }
  }, [filters])

  // Callback
  const getPolicies = useCallback(
    async (page) => {
      const { currentPage, currentPageIsEmpty } = paginationVars.current
      if (page >= currentPage && currentPageIsEmpty) return

      const [res, err] = await to(
        queryMembershipPolicies(membershipId, {
          limit: PAGE_LIMIT,
          page: page,
          club: clubId,
          'order[priority]': 'asc',
          'state[]': [
            ...Object.keys(filters)
              .filter((key) => filters[key] === true)
              .map((key) => {
                if (key === 'active') return 'published'
                if (key === 'inactive') return 'canceled'
                if (key === 'archived') return 'archived'
                return ''
              }),
            ...(filters.inactive ? ['draft'] : []),
          ],
        })
      )

      if (!res || err) {
        return false
      } else {
        if (page > 1) {
          setPolicies((prev) => prev.concat(res).sort((a, b) => a.priority - b.priority))
        } else {
          setPolicies(res)
        }

        if (res?.length < PAGE_LIMIT - 1) {
          paginationVars.current.currentPageIsEmpty = true
        }
        return true
      }
    },
    [clubId, membershipId, filters]
  )

  useEffect(() => {
    paginationVars.current = {
      currentPage: 1,
      currentPageIsEmpty: false,
    }

    let debounce = setTimeout(() => {
      const { currentPage } = paginationVars.current
      getPolicies(currentPage).then(() => {
        paginationVars.current.currentPage++
        setCheckingPolicies(false)
      })
    }, 200)

    return () => {
      clearTimeout(debounce)
    }
  }, [getPolicies])

  useEffect(() => {
    let debounce

    const onScroll = (e) => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        const target = e.target
        const { currentPage, currentPageIsEmpty } = paginationVars.current

        if (
          !loadingMorePolicies &&
          !currentPageIsEmpty &&
          target.scrollTop === target.scrollHeight - target.offsetHeight
        ) {
          setLoadingMorePolicies(true)
          getPolicies(currentPage).then(() => {
            paginationVars.current.currentPage++
            setLoadingMorePolicies(false)
          })
        }
      }, 300)
    }

    if (policiesContainerRef?.current) {
      policiesContainerRef.current.addEventListener('scroll', onScroll)
    }

    return () => {
      clearTimeout(debounce)
      if (policiesContainerRef?.current) {
        policiesContainerRef.current.removeEventListener('scroll', onScroll)
      }
    }
  }, [getPolicies, loadingMorePolicies])

  const addNewPolicy = () => {
    setPolicies(
      [
        {
          ...POLICY,
          date: +moment(),
        },
      ].concat(policies)
    )
  }

  const deleteNewPolicy = () => {
    setPolicies((prev) => prev.filter((x) => x.id !== 'new'))
  }

  const replaceNewPolicy = (policy) => {
    deleteNewPolicy()
    setPolicies(
      produce((draft) => {
        draft = draft.map((p) => p)
        draft = [policy, ...draft]
        return draft
      })
    )
    setOpenPolicies([policy.id])
  }

  const updatePolicy = (policy) => {
    setPolicies((prev) =>
      prev.map((old) => {
        if (old.id === policy.id) {
          return policy
        }
        return old
      })
    )
  }

  const toggleOpenPolicy = (id) => {
    setOpenPolicies((prev) => (prev.includes(id) ? [] : [id]))
  }

  const archivePolicy = (policyId) => {
    setPolicies(
      produce((draft) => {
        let newState = draft.map((policy) => {
          if (policy.id === policyId) {
            return {
              ...policy,
              status: 'archived',
            }
          }
          return policy
        })
        if (!filters.archived) {
          newState = newState.filter((policy) => policy.id !== policyId)
        }
        return newState
      })
    )
  }

  const ContextValues = {
    values: {
      clubId: clubId,
      membershipId: membershipId,
      partnershipId: partnershipId,
      openPolicies: openPolicies,
      policies: policies,
    },
    actionStates: {
      checkingPolicies: checkingPolicies,
      setCheckingPolicies: setCheckingPolicies,
      savingNewPolicy: savingNewPolicy,
      setSavingNewPolicy: setSavingNewPolicy,
      savingExistingPolicy: savingExistingPolicy,
      setSavingExistingPolicy: setSavingExistingPolicy,
    },
    functions: {
      setOpenPolicies,
      deleteNewPolicy,
      archivePolicy,
      replaceNewPolicy,
      toggleOpenPolicy,
      updatePolicy,
    },
  }

  return (
    <PoliciesContext.Provider value={ContextValues}>
      <div className={cx(styles.container)}>
        <div className={cx(styles.headerRow)}>
          {withTitle && <h2 className={cx(styles.title)}>{t('words.policies')}</h2>}

          <ButtonBasic
            text={t('words.policy')}
            icon="plus"
            onClick={addNewPolicy}
            disabled={!!policies.find((x) => x.id === 'new') || checkingPolicies}
          />

          <div className={cx(styles.filtersContainer)}>
            <p className={cx(styles.filterText)}>{t('words.filter_one')}</p>
            <Checkbox
              label={t('words.active')}
              lightLabel={true}
              containerClassName={cx(styles.checkbox)}
              value={filters.active}
              onChange={(newValue) => setFilters({ active: newValue })}
            />
            <Checkbox
              label={t('words.inactive')}
              lightLabel={true}
              containerClassName={cx(styles.checkbox)}
              value={filters.inactive}
              onChange={(newValue) => setFilters({ inactive: newValue })}
            />
            <Checkbox
              label={t('words.archived')}
              lightLabel={true}
              containerClassName={cx(styles.checkbox)}
              value={filters.archived}
              onChange={(newValue) => setFilters({ archived: newValue })}
            />
          </div>
        </div>

        {checkingPolicies && (
          <div className={cx(styles.loaderContainer)}>
            <PulseLoader showIf={true} />
          </div>
        )}

        {!checkingPolicies && (!policies || policies?.length <= 0) && (
          <p className={cx(styles.noExist)}>{t('sentences.noPoliciesExist')}</p>
        )}

        <div className={cx(styles.policiesContainer)} ref={policiesContainerRef}>
          {policies && policies?.length > 0
            ? policies.map((policy, index) => {
                return (
                  <div className={cx(styles.singlePolicyWrapper)} key={policy.id} id={policy.id}>
                    <SinglePolicy
                      policy={policy}
                      currentIndex={index}
                      membershipId={membershipId}
                    />
                  </div>
                )
              })
            : null}
        </div>

        <div className={cx(styles.loaderContainer)}>
          <PulseLoader showIf={loadingMorePolicies} />
        </div>
      </div>
    </PoliciesContext.Provider>
  )
}

ReservationPolicyManager.propTypes = {
  membershipId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  partnershipId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withTitle: PropTypes.bool,
}

ReservationPolicyManager.defaultProps = {
  membership: null,
  partnershipId: null,
  withTitle: false,
}

export default ReservationPolicyManager
