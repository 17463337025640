import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import InnerBoxWrapper from '../InnerBoxWrapper'
import ValueText from '../ValueText'
import DarkBlueSubtitle from '../DarkBlueSubtitle'

const GolfCartFilterPresentation = ({ filter }) => {
  const { t } = useTranslation()

  return (
    <InnerBoxWrapper>
      <DarkBlueSubtitle>{t('words.rental')}</DarkBlueSubtitle>
      <ValueText>{t('words.golfCart_one')}</ValueText>
    </InnerBoxWrapper>
  )
}

GolfCartFilterPresentation.propTypes = {
  filter: PropTypes.array,
}

GolfCartFilterPresentation.defaultProps = {}

export default GolfCartFilterPresentation
