import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useQueries, useQuery } from 'react-query'
import m from 'moment'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

import {
  fetchBookings,
  moveBookingToTeeTime,
  reserveBooking,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { getGolfClubById } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { getTeeTimePriceByUUID } from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'

import {
  to,
  objectToArray,
  arrayToObject,
  getNextChar,
} from '@sweetspot/sweetspot-js/common/functions/utils'

import {
  initialState,
  reducer,
  actions,
} from '@sweetspot/sweetspot-js/features/bookings/functions/bookingsState'

import CloseIcon from '@sweetspot/sweetspot-js/common/components/CloseIcon'
import SkeletonLoader from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'

import BookingNotesCourses from '@sweetspot/sweetspot-js/features/bookings/components/BookingNotesCourses'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'

import { getBookingSlotItems } from '@sweetspot/sweetspot-js/features/bookings/functions/utils'

import { ReactComponent as SwapIcon } from '@sweetspot/sweetspot-js/assets/svgs/swap-icon.svg'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import { setSessionStorage } from '@sweetspot/shared/util/session-storage'
import getBookingWithRelations from '@sweetspot/sweetspot-js/features/bookings/functions/getBookingWithRelations'
import {
  BOOKING_GROUPS,
  BOOKING_RELATIONS,
} from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import {
  getTeeTimeByUuid,
  saveNotes,
} from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'
import { ITEM_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/itemRelations'
import ScoreCardPrint from '@sweetspot/club-portal-legacy/modals/PrintScoreCardModal'
import BookingBox from '../BookingBox'

import { InfoBook } from './parts/infoBook'

import styles from './styles.module.scss'
import { STORAGE_KEYS } from '@sweetspot/shared/util/constants'
import { getCourseById } from '@sweetspot/shared/data-access/api-platform'

const BookingSidePanel = ({
  teeTimeIdUuid: teeTimeUuidProp,
  teeTime,
  onClose,
  visible,
  requestOpenBookingModal,
  onRequestInitiateBookingMove,
  onRequestCancelBookingMove,
  onCompletedMoveBooking,
  targetTeeTime,
}) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(reducer, initialState)
  const [showPrintScoreCard, setShowPrintScoreCard] = useState(false)

  const [movingBooking, setMovingBooking] = useState(null)
  const [confirmBookingMove, setConfirmBookingMove] = useState(null)

  /** ------------------------------------ */
  /** ------------- CALLBACKS ------------ */
  /** ------------------------------------ */

  /**
   * Get single booking
   */

  const getBooking = useCallback(
    async (bookingUuid) => {
      const [res, err] = await to(
        getBookingWithRelations({
          bookingUuid,
          deserialize: true,
          includedRelations: [BOOKING_RELATIONS.BOOKING, BOOKING_RELATIONS.ITEMS],
          includedBookingGroups: [BOOKING_GROUPS.COURSE, BOOKING_GROUPS.PARTNERSHIP],
          includedItemGroups: [ITEM_GROUPS.SLOT, ITEM_GROUPS.PRODUCT_VARIANT],
        })
      )
      if (res) {
        dispatch({ type: actions.BOOKING_UPDATED, payload: res })
      }
      if (err) {
        addToast(t('sentences.couldNotLoadBooking'), { appearance: 'error' })
      }
    },
    [addToast, t]
  )

  /** ---------------------------------- */
  /** ------------- MEMOS ------------ */
  /** ---------------------------------- */
  const teeTimeUuid = useMemo(() => {
    return teeTimeUuidProp || teeTime?.uuid || null
  }, [teeTimeUuidProp, teeTime])

  /** ---------------------------------- */
  /** ------------- EFFECTS ------------ */
  /** ---------------------------------- */

  const { refetch: getTeeTime } = useQuery(
    [CLUB_QUERIES.TEE_TIMES, teeTimeUuid],
    () => {
      dispatch({ type: actions.TEE_TIME_UPDATING })
      return getTeeTimeByUuid(teeTimeUuid)
    },
    {
      enabled: !!teeTimeUuid,
      onError: () => {
        addToast(t('sentences.couldNotLoadTeeTime'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.TEE_TIME_UPDATED, payload: data })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.TEE_TIME_PRICE, state?.teeTime?.id],
    () => {
      dispatch({ type: actions.TEE_TIME_PRICE_UPDATING })
      if (state.course?.is_use_dynamic_pricing) {
        return getTeeTimePriceByUUID(state?.teeTime?.uuid)
      } else {
        return Promise.resolve(teeTime)
      }
    },
    {
      enabled: !!state?.course && !!state?.teeTime?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadTeeTimePrice'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.TEE_TIME_PRICE_UPDATED, payload: data })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.COURSES, state?.teeTime?.course?.id],
    () => {
      dispatch({ type: actions.COURSE_UPDATING })
      return getCourseById(state.teeTime.course.id)
    },
    {
      enabled: !!state?.teeTime?.course?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadGolfCourse'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.COURSE_UPDATED, payload: data })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.CLUBS, state?.course?.club?.id],
    () => {
      dispatch({ type: actions.GOLF_CLUB_UPDATING })
      return getGolfClubById(state.course.club.id)
    },
    {
      enabled: !!state?.course?.club?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadGolfClub'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.GOLF_CLUB_UPDATED, payload: data })
      },
    }
  )

  useQueries(
    teeTime?.orderBookings?.map((booking) => ({
      queryKey: [CLUB_QUERIES.BOOKINGS, teeTime, booking.uuid],
      queryFn: () =>
        getBookingWithRelations({
          bookingUuid: booking.uuid,
          deserialize: true,
          includedRelations: [BOOKING_RELATIONS.BOOKING, BOOKING_RELATIONS.ITEMS],
          includedBookingGroups: [BOOKING_GROUPS.ALL],
          includedItemGroups: [ITEM_GROUPS.ALL],
        }),
      enabled: !!teeTime?.orderBookings,
      onSuccess: (data) => {
        dispatch({ type: actions.BOOKING_UPDATED, payload: data })
        dispatch({ type: actions.BOOKINGS_SET_LOADER, payload: false })
      },
      onError: () => {
        addToast(t('sentences.couldNotLoadBookings'), { appearance: 'error' })
      },
    })) || []
  )

  useQuery(
    [
      CLUB_QUERIES.BOOKINGS,
      state?.course?.uuid,
      state?.teeTime?.from,
      state?.teeTime?.to,
      { 'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'] },
    ],
    () => {
      return fetchBookings({
        'course.uuid': state?.course?.uuid,
        'booking.start_time[after]': new Date(state.teeTime.from).toISOString(),
        'booking.start_time[before]': new Date(state.teeTime.to).toISOString(),
        'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
      })
    },
    {
      enabled: !!state?.course && !!state?.teeTime && !teeTime,
      onError: () => {
        addToast(t('sentences.couldNotLoadBookings'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        const bookingsObject = arrayToObject(data)
        dispatch({ type: actions.BOOKINGS_UPDATED, payload: bookingsObject })
      },
    }
  )

  useEffect(() => {
    if (!targetTeeTime) {
      setMovingBooking(null)
    }
  }, [targetTeeTime])

  useEffect(() => {
    dispatch({ type: actions.BOOKINGS_UPDATED, payload: null })
  }, [teeTime])

  /** ---------------------------------- */
  /** ------------- METHODS ------------ */
  /** ---------------------------------- */
  const handleUpdateBooking = (bookingUuid) => {
    getBooking(bookingUuid)
  }

  const updateBookingNotes = async (notes, teeTimeUuid) => {
    const [res, err] = await to(saveNotes(teeTimeUuid, { notes: JSON.stringify(notes) }))
    if (res) {
      getTeeTime()
    }
    if (err) {
      addToast(t('sentences.couldNotSaveNotes'), { appearance: 'error' })
    }
  }

  const handleOnRequestOpenBookingModal = async (numberOfSlots, bookingUuid) => {
    let res
    let err

    if (numberOfSlots) {
      // Also reserve, then open modal
      if (state?.teeTime?.id) {
        dispatch({ type: actions.BOOKING_RESERVING })
        let cancelData = null
        ;[res, err] = await to(
          reserveBooking(
            state.teeTime.uuid,
            {
              slots_number: numberOfSlots,
            },
            (headers) => (cancelData = headers)
          )
        )
        if (res) {
          setSessionStorage(
            `${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${res.uuid}`,
            JSON.stringify(cancelData)
          )
          // dispatch({ type: actions.BOOKING_RESERVED, payload: res });
          // getTeeTime(state.teeTime.id);
        }
        if (err) {
          // Non breaking
          // if (err?.violations) {
          //   displayToasts(err?.violations);
          // } else addToast(t('sentences.couldNotReserveBooking'), { appearance: 'error' });
          // dispatch({ type: actions.BOOKING_RESERVED, payload: null });
        }
      }
    }

    // Else just open
    requestOpenBookingModal(bookingUuid || res?.uuid || null)
  }

  const handleInitiateBookingMove = (booking) => {
    let numberOfSlotsNeeded = getBookingSlotItems(booking).filter(({ slot }) => slot).length
    let slotsFilter = [0, 1, 2, 3, 4]
    slotsFilter = [0, 1, 2, 3, 4].slice(numberOfSlotsNeeded)
    slotsFilter.push(0)

    onRequestInitiateBookingMove(slotsFilter)
    setMovingBooking(booking.id)
  }

  const handleOnResetBookingMove = useCallback(() => {
    setMovingBooking(null)
    onRequestCancelBookingMove()
    setConfirmBookingMove(false)
  }, [onRequestCancelBookingMove])

  const handleConfirmBookingMove = useCallback(
    (booking) => {
      if (!targetTeeTime || !state.teeTime) {
        handleOnResetBookingMove()
        return
      }
      const currentTeeTime = state.teeTime
      setConfirmBookingMove({
        booking,
        to: targetTeeTime,
        from: currentTeeTime,
      })
    },
    [targetTeeTime, state.teeTime, handleOnResetBookingMove]
  )

  const handleCompleteBookingMove = useCallback(
    async (booking) => {
      if (!targetTeeTime || !state.teeTime) {
        handleOnResetBookingMove()
        return
      }

      const [res, err] = await to(moveBookingToTeeTime(booking.uuid, targetTeeTime.uuid))

      if (res) {
        if (Object.keys(state.bookings).length === 1) {
          await updateBookingNotes([], state.teeTime.uuid)
        }

        const currentBookingNotes = JSON.parse(state.teeTime?.notes)
        const targetBookingNotes = JSON.parse(targetTeeTime?.notes)

        const notes = targetBookingNotes
          ? targetBookingNotes.concat(currentBookingNotes)
          : currentBookingNotes || []

        await updateBookingNotes(notes, targetTeeTime.uuid)

        addToast(t('sentences.bookingMoved'), { appearance: 'success' })
        handleOnResetBookingMove()
        onCompletedMoveBooking(targetTeeTime)
      }
      if (err) {
        addToast(t('sentences.couldNotMoveBooking'), { appearance: 'error' })
      }
    },
    [targetTeeTime, state.teeTime, onCompletedMoveBooking, handleOnResetBookingMove, addToast, t]
  )

  let currentChar = '@'
  const onShowScorecard = () => {
    setShowPrintScoreCard(true)
  }

  return (
    <React.Fragment>
      <div className={cx(styles.container, { [styles.visible]: visible })}>
        <div className={cx(styles.header)}>
          <CloseIcon onClick={onClose} className={styles.closeIcon} />
          {state.loaders.teeTime ||
          state.loaders.teeTimePrice ||
          state.loaders.course ||
          state.loaders.golfClub ? (
            <React.Fragment>
              <SkeletonLoader width="80%" height="20px" style={{ marginBottom: '10px' }} />
              <SkeletonLoader width="50%" height="20px" style={{ marginBottom: '10px' }} />
              <SkeletonLoader width="55%" height="30px" style={{ marginBottom: '5px' }} />
            </React.Fragment>
          ) : (
            <InfoBook
              teeTime={state.teeTime}
              course={state.course}
              club={state.golfClub}
              teeTimePrice={state.teeTimePrice}
              loaders={state.loaders}
              bookings={state.bookings}
              handleOnRequestOpenBookingModal={handleOnRequestOpenBookingModal}
              onShowScoreCard={onShowScorecard}
            />
          )}
        </div>
        <div className={cx(styles.bookings)}>
          {state.loaders.bookings ? (
            <div className={cx(styles.bookingBox)}>
              <SkeletonLoader
                width="100%"
                height="100px"
                borderRadius={8}
                style={{ marginBottom: '6px' }}
              />
              <SkeletonLoader width="100%" height="100px" borderRadius={8} />
            </div>
          ) : (
            <React.Fragment>
              {state.bookings &&
                objectToArray(state.bookings).map((booking) => {
                  currentChar = getNextChar(currentChar)
                  return (
                    <BookingBox
                      onRequestOpenBooking={(bookingUuid) =>
                        handleOnRequestOpenBookingModal(null, bookingUuid)
                      }
                      className={styles.bookingBox}
                      letter={currentChar}
                      key={booking.id}
                      booking={booking}
                      loaders={state.loaders}
                      onRequestBookingUpdate={handleUpdateBooking}
                      onRequestInitiateMoveBooking={handleInitiateBookingMove}
                      onRequestCompleteMoveBooking={handleConfirmBookingMove}
                      moving={movingBooking === booking.id}
                    />
                  )
                })}
            </React.Fragment>
          )}
        </div>
        <BookingNotesCourses
          onNotesUpdate={(notes) => updateBookingNotes(notes, state.teeTime.uuid)}
          notes={state.teeTime?.notes}
          styles={{
            container: styles.bottomContainer,
            notesContainer: styles.notesContainer,
            note: styles.notes,
            addNoteContainer: styles.addNoteContainer,
            inputContainer: styles.inputContainer,
          }}
        />
      </div>
      {showPrintScoreCard &&
        state.bookings &&
        state.course &&
        state?.golfClub?.is_score_card_printing_enabled === true &&
        state.teeTime && (
          <ScoreCardPrint
            bookings={state.bookings}
            golfCourse={state.course}
            golfClub={state.golfClub}
            teeTime={state.teeTime}
            onRequestClose={() => setShowPrintScoreCard(false)}
          />
        )}

      {confirmBookingMove && (
        <ConfirmPopup
          visible={true}
          title={`${t('words.move')} ${t('words.booking_one').toLowerCase()}`}
          titleIcon={SwapIcon}
          onClose={handleOnResetBookingMove}
          onReject={handleOnResetBookingMove}
          rejectText={t('words.cancel')}
          acceptText={t('words.confirm')}
          acceptTheme="default"
          onAccept={() => handleCompleteBookingMove(confirmBookingMove.booking)}
        >
          {getBookingSlotItems(confirmBookingMove.booking).map(({ slot }) => {
            const { player, stub_player } = slot
            let name = ''
            if (player?.first_name || player?.last_name) {
              name = `${player?.first_name || ''} ${player?.last_name || ''}`
            } else if (stub_player?.name) {
              name = stub_player.name
            }
            return (
              <p className={cx(styles.confirmText, styles.bold)} key={slot.id}>
                {name}
              </p>
            )
          })}
          <p className={cx(styles.confirmText)}>
            <span className={cx(styles.bold)}>{t('words.from')}:</span>{' '}
            {m(confirmBookingMove.from.from).format('dddd YYYY-MM-DD')}{' '}
            <span className={cx(styles.bold)}>
              {m(confirmBookingMove.from.from).format('HH:mm')}
            </span>
          </p>
          <p className={cx(styles.confirmText)}>
            <span className={cx(styles.bold)}>{t('words.to')}:</span>{' '}
            {m.utc(confirmBookingMove.to.from).local().format('dddd YYYY-MM-DD')}{' '}
            <span className={cx(styles.bold)}>
              {m.utc(confirmBookingMove.to.from).local().format('HH:mm')}
            </span>
          </p>
        </ConfirmPopup>
      )}
    </React.Fragment>
  )
}

BookingSidePanel.propTypes = {
  visible: PropTypes.bool,
  teeTimeUuid: PropTypes.oneOfType([PropTypes.string]),
  teeTime: PropTypes.shape({
    id: PropTypes.number,
    orderBookings: PropTypes.array.isRequired,
  }),
  onClose: PropTypes.func,
  requestOpenBookingModal: PropTypes.func,
  onRequestInitiateBookingMove: PropTypes.func,
  onRequestCancelBookingMove: PropTypes.func,
  targetTeeTime: PropTypes.object,
  onCompletedMoveBooking: PropTypes.func,
}

BookingSidePanel.defaultProps = {
  teeTimeUuid: null,
  teeTime: null,
  visible: true,
  requestOpenBookingModal: () => {},
}

export default BookingSidePanel
