import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import cx from 'classnames'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { ReactComponent as RemoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/minus-icon.svg'
import { ReactComponent as AddIcon } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon.svg'
import { ReactComponent as CopyIcon } from '@sweetspot/sweetspot-js/assets/svgs/copy-icon-2.svg'

import styles from './styles.module.scss'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { ReactComponent as TrashIcon } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import SelectPlayerPopup from '@sweetspot/sweetspot-js/features/players/components/SelectPlayerPopup'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { STATUSES } from '@sweetspot/sweetspot-js/common/components/StatusPill'
import { InView, useInView } from 'react-intersection-observer'

import { useToasts } from 'react-toast-notifications'
import ButtonDropdown from '@sweetspot/club-portal-legacy/components/ButtonDropdown'
import Papa from 'papaparse'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import {
  importCouponEmails,
  bulkExportVoucherCoupons,
  deleteBulkCodesWithoutHolder,
} from '@sweetspot/sweetspot-js/features/promotions/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { hasAccess } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'

const Loader = <PulseLoader color="#75B1EE" dotStyles={{ width: 10, height: 10 }} showIf={true} />

const CodesTab = ({ voucher, isActive, voucherActions, loaders, coupons, role, hasCdhNumber }) => {
  const { t } = useTranslation()
  const [activeCoupon, setActiveCoupon] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isDropZoneActive, setIsDropZoneActive] = React.useState(false)
  const [isImportLoading, setIsImportLoading] = React.useState(false)
  const [numberOfCodes, setNumberOfCodes] = React.useState(0)
  const {
    coupons: couponsList,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetchCoupons,
  } = coupons

  const inputRef = React.useRef(null)
  const importCsvRef = React.useRef(null)
  const { ref, inView } = useInView()
  const { addToast } = useToasts()

  const hasPermission = hasAccess(ACCESS_KEYS.PAGES.VOUCHERS.UI.MODAL.CREATE, role?.value)

  const WrongFileFormatError = React.useMemo(
    () => (
      <Trans i18nKey="vouchers.message_WrongFileFormat">
        Wrong file format,
        <u
          className={cx(styles.linkText, styles.errorTextColor)}
          onClick={() => importCsvRef.current?.click()}
        >
          try again
        </u>{' '}
        to import csv file (maximum 5 Mb)
      </Trans>
    ),
    []
  )

  const getWrongFormattedEmailsError = React.useCallback(
    (count) => (
      <Trans i18nKey="vouchers.message_EmailsHaveWrongFormat" count={count}>
        {count} emails have wrong format, fix the format and
        <u
          className={cx(styles.linkText, styles.errorTextColor)}
          onClick={() => importCsvRef.current?.click()}
        >
          try again
        </u>
      </Trans>
    ),
    []
  )

  const getPlayersNotFoundError = React.useCallback(
    (failedEmails) => (
      <Trans i18nKey="vouchers.message_PlayersNotFound" count={failedEmails.length}>
        {failedEmails.length} Players not found,{' '}
        <u
          className={cx(styles.linkText, styles.errorTextColor)}
          onClick={() => {
            // convert and download not found emails as csv
            const csvContent = Papa.unparse({ data: failedEmails.map((email) => [email]) })

            const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            const csvURL = window.URL.createObjectURL(csvData)
            const tempLink = document.createElement('a')
            tempLink.href = csvURL
            tempLink.setAttribute('download', 'Emails.csv')
            tempLink.click()
          }}
        >
          download as csv
        </u>
      </Trans>
    ),
    []
  )

  const isLoadingCodes = loaders.codes
  const isLoadingCoupons = loaders.coupons
  const isExpired = voucher.status === STATUSES.EXPIRED

  const isGenerateButtonDisabled = React.useMemo(
    () => isLoadingCodes || numberOfCodes < 1 || numberOfCodes > 1000,
    [isLoadingCodes, numberOfCodes]
  )

  // Infinite scroll
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  if (!voucher || voucher.id === 'new') return null

  const copyCode = async (code) => {
    try {
      await navigator.clipboard.writeText(code)
      addToast(t('vouchers.message_CopySuccess'), { appearance: 'success' })
    } catch (err) {
      console.error(err)
    }
  }

  const handleCloseRequest = () => {
    setActiveCoupon(null)
    inputRef.current = null
  }

  const importEmails = async (emails) => {
    setIsImportLoading(true)
    const [res, err] = await to(importCouponEmails(voucher.id, emails))
    if (res) {
      try {
        await refetchCoupons()
        const notFoundEmails = emails.filter((email) => !res.includes(email))
        if (notFoundEmails.length > 0) {
          setError(getPlayersNotFoundError(notFoundEmails))
        } else {
          addToast(t('vouchers.message_ImportSuccess'), { appearance: 'success' })
        }
      } catch (err) {
        addToast(`${t('errors.somethingWentWrongNoId')}. ${t('sentences.contactSupport')}`, {
          appearance: 'error',
        })
      }
    } else if (err) {
      addToast(`${t('errors.somethingWentWrongNoId')}. ${t('sentences.contactSupport')}`, {
        appearance: 'error',
      })
    }
    setIsImportLoading(false)
  }

  const csvFileToArray = (file) => {
    if (file.type !== 'text/csv') {
      setError(WrongFileFormatError)
      return
    }
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results) => {
        const emails = []
        const wrongFormattedEmails = []
        results.data?.forEach?.((row) => {
          const email = row?.[0]?.trim?.()
          if (email && !Utilities.validateEmail(email)) {
            // throw an error
            wrongFormattedEmails.push(email)
          } else if (email) {
            emails.push(email)
          }
        })
        const count = wrongFormattedEmails.length
        if (count > 0) {
          setError(getWrongFormattedEmailsError(count))
          return
        }
        setError('')
        importEmails(emails)
      },
      error: (error) => {
        setError(WrongFileFormatError)
      },
    })
  }

  const handleOnChange = (e) => {
    const file = e.target.files[0]
    csvFileToArray(file)
    e.target.value = null
  }

  const dragEnterHandler = (e) => {
    setIsDropZoneActive(true)
  }

  const dragLeaveHandler = (e) => {
    setIsDropZoneActive(false)
  }

  function dragOverHandler(ev) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault()
  }

  const dropHandler = (e) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault()
    setIsDropZoneActive(false)

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      ;[...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile()
          csvFileToArray(file)
        }
      })
    } else {
      // Use DataTransfer interface to access the file(s)
      ;[...e.dataTransfer.files].forEach((file, i) => {
        csvFileToArray(file)
      })
    }
  }

  const handleDeleteCodesWithoutHolder = async () => {
    const [, err] = await to(deleteBulkCodesWithoutHolder(voucher.id))
    await refetchCoupons()
    if (!err) {
      addToast(t('vouchers.message_All_codes_without_holders_are_deleted'), {
        appearance: 'success',
      })
    } else if (err.status === 400) {
      addToast(t('vouchers.message_No_codes_to_be_deleted'), {
        appearance: 'error',
      })
    } else {
      addToast(`${t('errors.somethingWentWrongNoId')}. ${t('sentences.contactSupport')}`, {
        appearance: 'error',
      })
    }
  }

  const handleExportToCsv = async () => {
    const [, err] = await to(bulkExportVoucherCoupons(voucher.id))
    if (!err) {
      addToast(t('vouchers.message_TheFileIsSentToYourEmail'), { appearance: 'success' })
    }
  }

  const handleGenerateCodeClick = async () => {
    await voucherActions.generateCode(true, numberOfCodes)
    setNumberOfCodes(0)
  }

  return (
    <div
      className={cx(styles.container, !isActive && styles.inactive)}
      {...(hasPermission ? { onDragEnter: dragEnterHandler } : {})}
    >
      {hasPermission && (
        <div className={styles.upperArea}>
          <div className={styles.generateCodeContainer}>
            <TextInputField
              type="number"
              containerWidth="70px"
              label={t('vouchers.label_NumberOfCodes')}
              lightMainLabelColor={true}
              value={numberOfCodes}
              onChange={(value) => setNumberOfCodes(Number(value))}
              containerClassName={styles.inputFieldContainer}
              inputClassName={styles.codesInput}
              inputProps={{
                min: 1,
                max: 1000,
              }}
            />
            <Button
              size="small"
              theme="main-outline"
              width="80px"
              className={cx(styles.button, styles.generate)}
              disabled={isGenerateButtonDisabled}
              disabledTheme="gray-outline"
              onClick={handleGenerateCodeClick}
            >
              {isLoadingCodes ? Loader : t('vouchers.button_Generate')}
            </Button>
          </div>
          <div className={cx(styles.actionsContainer)}>
            <input
              ref={importCsvRef}
              hidden
              type={'file'}
              accept={'.csv'}
              onChange={handleOnChange}
            />
            <ButtonDropdown
              size="small"
              theme="default-outline"
              text="words.actions"
              listItemClassName={styles.listItem}
              actionsListClassName={styles.actionsListClassName}
              values={[
                { id: 'import_document', label: 'vouchers.button_Import_Document' },
                {
                  id: 'export_to_csv',
                  label: 'vouchers.button_Export_to_csv',
                  disabled: !couponsList?.length,
                },
                {
                  id: 'delete_codes_with_no_holder',
                  label: 'vouchers.button_Delete_codes_with_no_holder',
                },
              ]}
              onClick={(id) => {
                if (id === 'import_document') {
                  importCsvRef.current?.click()
                } else if (id === 'export_to_csv') {
                  handleExportToCsv()
                } else if (id === 'delete_codes_with_no_holder') {
                  handleDeleteCodesWithoutHolder()
                }
              }}
              fromRight
            />
          </div>
        </div>
      )}
      {error && !isImportLoading && (
        <p className={cx(styles.topLeftMessage, styles.errorTextColor)}>{error}</p>
      )}
      {hasPermission && !error && !isImportLoading && (
        <p className={cx(styles.topLeftMessage, styles.infoTextColor)}>
          <Trans i18nKey="vouchers.message_DragAndDropOrBrowseCSVFile">
            Drag and drop or{' '}
            <i className={cx(styles.linkText)} onClick={() => importCsvRef.current?.click()}>
              browse
            </i>{' '}
            csv file to import (maximum 5 Mb)
          </Trans>
        </p>
      )}
      {isImportLoading && <div className={cx(styles.topLeftMessage)}>{Loader}</div>}
      <div className={styles.codes}>
        <div className={cx(styles.row, styles.header)}>
          <p className={styles.code}>{t('vouchers.header_Code')}</p>
          <p className={styles.copyIconContainer}></p>
          <p className={cx(styles.codeHolder, styles.header)}>{t('vouchers.header_CodeHolder')}</p>
          <p className={cx(styles.assigneeName, styles.header)}>
            {t('vouchers.header_AssigneeName')}
          </p>
          {hasPermission && <TrashIcon className={cx(styles.icon, styles.removeHeader)} />}
        </div>
        <div className={styles.scrollContainer}>
          {Array.isArray(couponsList) && couponsList.length > 0 ? (
            <div className={styles.list}>
              {couponsList.map((coupon) => {
                const isActive = Boolean(activeCoupon && coupon.id === activeCoupon.id)
                const isAssigned = !!coupon.player
                return (
                  <div key={coupon.uuid} className={styles.row}>
                    <p className={styles.code}>{coupon.code}</p>
                    <div
                      className={cx(styles.copyIconContainer, styles.copyIcon)}
                      onClick={() => copyCode(coupon.code)}
                    >
                      <CopyIcon className={styles.icon} />
                    </div>
                    <div
                      className={cx(
                        styles.codeHolder,
                        isActive && styles.left,
                        isExpired && styles.expired,
                        isAssigned && styles.assigned
                      )}
                      onClick={() => (isAssigned ? void 0 : setActiveCoupon(coupon))}
                    >
                      {isActive ? (
                        <>
                          <TextInputField innerRef={inputRef} />
                          <div className={styles.playerPopup}>
                            <SelectPlayerPopup
                              hasCdhNumber={hasCdhNumber}
                              attachToElementRef={inputRef}
                              className={styles.selectPlayer}
                              availableTabs={['all', 'members']}
                              onRequestClose={handleCloseRequest}
                              onSelectPlayer={({ player }) =>
                                voucherActions.assignCodeToPlayer(player.id, activeCoupon.code)
                              }
                            />
                          </div>
                        </>
                      ) : coupon.player ? (
                        <p>{`${coupon.player.first_name} ${coupon.player.last_name}`}</p>
                      ) : loaders.code_assign === coupon.code ? (
                        <div className={styles.assignLoader}>{Loader}</div>
                      ) : (
                        <AddIcon className={styles.icon} />
                      )}
                    </div>
                    <div
                      className={cx(
                        styles.assigneeName,
                        isActive && styles.left,
                        isExpired && styles.expired,
                        isAssigned && styles.assigned
                      )}
                    >
                      {coupon.updated_by || ''}
                    </div>
                    {loaders.code_remove === coupon.id ? (
                      <div className={styles.removeLoader}>{Loader}</div>
                    ) : (
                      hasPermission && (
                        <RemoveIcon
                          className={cx(styles.icon, styles.remove)}
                          onClick={() => voucherActions.removeCode(coupon.id)}
                        />
                      )
                    )}
                  </div>
                )
              })}
              <InView>
                <div ref={ref}></div>
              </InView>
              <PulseLoader
                dotClassName={cx(styles.dot)}
                className={cx(styles.loader)}
                showIf={isLoadingCoupons}
              />
            </div>
          ) : (
            !isLoadingCoupons && (
              <div className={styles.listEmpty}>{t('vouchers.label_CodesEmpty')}</div>
            )
          )}
        </div>
      </div>
      {isDropZoneActive && (
        <div
          id="drop_zone"
          className={cx(styles.dropArea)}
          {...(hasPermission
            ? {
                onDrop: dropHandler,
                onDragOver: dragOverHandler,
                onDragLeave: dragLeaveHandler,
              }
            : {})}
        >
          <p>{t('vouchers.message_dropFilesHere')}</p>
        </div>
      )}
    </div>
  )
}

export default CodesTab
