import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { useTranslation } from 'react-i18next'
import style from '../../style.module.scss'

const ScheduledDateInput = ({ errors, formState, setValue, toggleScheduleTo }) => {
  const { t } = useTranslation()
  return (
    <div className={errors.scheduledDate ? style.errored : ''}>
      <div className={style.scheduleTo}>
        <span className={style.optionsText}>{t('editPeriodsNew.scheduleTo')} </span>
        <TextInputField
          containerClassName={style.inputOptions}
          name="scheduledDate"
          type="calendar"
          value={formState.scheduledDate}
          calendarSettings={{
            selectedDate: formState.scheduledDate,
            returnDateFormat: 'YYYY-MM-DD',
          }}
          onChange={(value) => setValue('scheduledDate', value)}
          CalendarComponent={MiniCalendar}
          prefix={<input type="checkbox" onChange={toggleScheduleTo} />}
          calendarPrefix={true}
        />
      </div>
    </div>
  )
}

export default ScheduledDateInput
