import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Query club users
 *
 * @returns
 */
export const queryClubUsers = (
  query = {
    roles: null,
    'roles[]': null,
    'partnerships.id': null,
    search: null,
    club: null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/club-users`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get club user by id
 *
 * @param {number} id
 * @returns
 */
export const getClubUserById = (id) => {
  const url = `${getApiPlatformBaseUrl()}/club-users/${id}`
  return request(url)
}

/**
 * Create club user
 *
 * @returns
 */
export const createClubUser = (
  payload = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    club: '',
    company: '',
    role: '',
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/club-users`
  return request(url, { method: 'post', body: payload })
}

/**
 * Delete club user
 *
 * @param {number} userId
 * @returns
 */
export const deleteClubUser = (userId) => {
  const url = `${getApiPlatformBaseUrl()}/club-users/${userId}`
  return request(url, { method: 'delete' })
}

/**
 * Update club user
 *
 * @param {number} userId
 * @param {*} payload
 * @returns
 */
export const updateClubUser = (userId, payload) => {
  const url = `${getApiPlatformBaseUrl()}/club-users/${userId}`
  return request(url, { method: 'put', body: payload })
}

/**
 * Add role to club user
 *
 * @param {number} userId
 * @returns
 */
export const addClubUserRole = (userId, payload = { role: '', club: '' }) => {
  const url = `${getApiPlatformBaseUrl()}/club-users/${userId}/role/add`
  return request(url, { method: 'put', body: payload })
}

/**
 * Remove role from club user
 *
 * @param {number} userId
 * @returns
 */
export const removeClubUserRole = (userId, payload = { role: '', club: '' }) => {
  const url = `${getApiPlatformBaseUrl()}/club-users/${userId}/role/remove`
  return request(url, { method: 'put', body: payload })
}

/**
 * Get club user invites
 *
 * @param {number} clubId
 * @returns
 */
export const getClubUserInvites = (
  clubId,
  query = {
    page: 1,
    limit: 50,
  },
  ...args
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/clubs/${clubId}/club-user-invites`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, ...args)
}

/**
 * Create club user invite
 *
 * @param {number} clubId
 * @returns
 */
export const createClubUserInvite = (clubId, payload = { email: '', role: '', courses: [] }) => {
  const url = `${getApiPlatformBaseUrl()}/clubs/${clubId}/admin/invite`
  return request(url, { method: 'put', body: payload })
}

/**
 * Get club user invite by token
 *
 * @param {string} token
 * @returns
 */
export const getClubUserInvite = (token) => {
  const url = `${getApiPlatformBaseUrl()}/club-user-invites/${token}`
  return request(url)
}

/**
 * Confirm club user invite
 *
 * @param {string} token
 * @returns
 */
export const confirmClubUserInvite = (
  token,
  payload = { first_name: '', last_name: '', password: '' }
) => {
  const url = `${getApiPlatformBaseUrl()}/club-user-invites/${token}/confirm`
  return request(url, { method: 'put', body: payload })
}

/**
 * Resend club user invite
 *
 * @param {string} token
 * @returns
 */
export const resendClubUserInvite = (token) => {
  const url = `${getApiPlatformBaseUrl()}/club-user-invites/${token}/resend`
  return request(url, { method: 'put' })
}
