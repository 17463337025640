import React, { useState } from 'react'
import styles from './styles.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import NestedRowWrapper from '@sweetspot/sweetspot-js/common/components/NestedRowWrapper'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

const SlotsUnit = ({ slotsValue, unit, removeUnit, addNewSlots, unitsConf, onSaveChecker }) => {
  const { t } = useTranslation()
  const [slotsError, setSlotsError] = useState('')

  const setValue = (val) => {
    setSlotsError('')
    if (val < 0 || val > 100000) {
      setSlotsError(t('errors.valueMustBeBetweenThese', { this: 0, that: 100000 }))
    } else {
      addNewSlots(unit, val)
    }
  }

  return (
    <NestedRowWrapper title={t(unit.label)} onRemove={() => removeUnit(unit)}>
      <div className={cx(styles.unitRow)}>
        <TextInputField
          containerClassName={cx(styles.input)}
          labelTwo={t(unit.secondaryLabel)}
          type="number"
          placeholder="-"
          value={slotsValue}
          onChange={(val) => setValue(val)}
          error={slotsError}
          inputWidth="100px"
          inputProps={{
            tabIndex: unit.id,
            autoFocus: unitsConf[0]?.id === unit?.id,
          }}
          onEnter={onSaveChecker}
          onInputBlur={onSaveChecker}
        />
      </div>
    </NestedRowWrapper>
  )
}

SlotsUnit.propTypes = {
  slotsValue: PropTypes.number,
  unit: PropTypes.object,
  unitsConf: PropTypes.array,
  removeUnit: PropTypes.func,
  onSaveChecker: PropTypes.func,
  addNewSlots: PropTypes.func,
}

SlotsUnit.defaultProps = {
  unit: {},
  unitsConf: [],
  removeUnit: () => {},
  onSaveChecker: () => {},
  addNewSlots: () => {},
}

export default SlotsUnit
