import { useState, useMemo, useRef, useEffect } from 'react'
import cx from 'classnames'
import _toInteger from 'lodash/toInteger'
import _isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import produce from 'immer'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { ReactComponent as RemoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/stop-icon.svg'

import styles from './styles.module.scss'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'

import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { createNewChecker, deleteChecker, updateChecker } from '../../services/api-platform'

import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import useViolationToasts from '@sweetspot/sweetspot-js/common/hooks/useViolationToasts'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'

import { CHECKERS_TYPES } from '@sweetspot/sweetspot-js/features/reservationPolicies/constants/checkers'
import SimultaneousBookingChecker from './Checkers/SimultaneousBookingChecker'
import BookedSlotsChecker from './Checkers/BookedSlotsChecker'
import TimeBeforeChecker from './Checkers/TimeBeforeChecker'

const MAX_DAYS_IN_A_CALENDAR = 365
const MAX_HOURS_IN_A_DAY = 24

const SingleChecker = ({ checker: checkerProp, policyId, onRemoveChecker, index, disabled }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { displayToasts } = useViolationToasts()
  const saveTimeout = useRef(null)

  // States
  const [checker, setChecker] = useMergeState(checkerProp)
  const [maxNumberInput, setMaxNumberInput] = useState(
    checker?.configuration?.type === 'days' ? MAX_DAYS_IN_A_CALENDAR : MAX_HOURS_IN_A_DAY
  )

  useEffect(() => {
    if (checker?.configuration?.type === 'days') {
      setMaxNumberInput(MAX_DAYS_IN_A_CALENDAR)
    } else setMaxNumberInput(MAX_HOURS_IN_A_DAY)
  }, [checker?.configuration?.type])

  const prevChecker = usePrevious(checker)
  const [sameDayEnabled, setSameDayEnabled] = useState(
    !!checker?.configuration?.same_day_booking_time
  )

  const [slotsError, setSlotsError] = useState('')
  const [sameDayError, setSameDayError] = useState('')
  const [timeBeforeError, setTimeBeforeError] = useState('')

  // Memos
  const isNewChecker = useMemo(
    () => (typeof checker?.id === 'string' && checker.id?.includes('new') ? true : false),
    [checker.id]
  )

  // Effects
  useEffect(() => {
    if (isNewChecker) saveChecker()
  }, [isNewChecker])

  const toggleSameDayEnabled = (value) => {
    setSameDayEnabled(value)
    if (!value) {
      setChecker((prev) => ({
        configuration: {
          ...prev.configuration,
          same_day_booking_time: null,
        },
      }))
      saveChecker({
        ...checker,
        configuration: {
          ...checker.configuration,
          same_day_booking_time: null,
        },
      })
    }
  }

  const currentCheckerType = useMemo(() => {
    return CHECKERS_TYPES.find((item) => item.value === checker.type) || null
  }, [checker])

  // Functions
  const saveChecker = async (updatedChecker) => {
    const newChecker = produce(updatedChecker || checker, (draft) => {
      if (currentCheckerType === 'simultaneously_booked_slots_checker') {
        draft.configuration.number_of_simultaneous_slots =
          draft.configuration.number_of_simultaneous_slots || null
        draft.configuration.same_day_booking_time =
          draft.configuration.same_day_booking_time || (sameDayEnabled ? '00:00' : null)
        draft.type = 'draft.simultaneously_booked_slots_checker'
      } else if (currentCheckerType === 'number_of_days_checker') {
        draft.configuration.number = draft.configuration.number || 0
        draft.configuration.type = draft.configuration.type || 0
      } else if (currentCheckerType === 'booked_slots_per_time_range_checker') {
        draft.configuration = draft.configuration || null
        draft.type = 'draft.booked_slots_per_time_range_checker'
      }
    })
    const newprevChecker = updatedChecker ? checker : prevChecker

    clearTimeout(saveTimeout.current)

    const shouldSave = (newChecker) => {
      if (_isEqual(newChecker, newprevChecker)) {
        return false
      } else {
        return true
      }
    }

    if (isNewChecker && canSave(newChecker)) {
      saveTimeout.current = setTimeout(async () => {
        const [res, err] = await to(createNewChecker(policyId, newChecker))

        if (!res || err) {
          if (err?.violations) displayToasts(err?.violations)
          else addToast(t('sentences.couldNotSaveChecker'), { appearance: 'error' })
        } else {
          setChecker({ id: res.id, uuid: res.uuid })
        }
      }, 500)
    }

    if (!isNewChecker && canSave(newChecker) && shouldSave(newChecker)) {
      saveTimeout.current = setTimeout(async () => {
        const [res, err] = await to(updateChecker(newChecker?.uuid, newChecker))

        if (!res || err) {
          addToast(t('sentences.couldNotSaveChecker'), { appearance: 'error' })
        }
      }, 500)
    }
  }

  const canSave = (checker) => {
    if (!checker?.type) return false
    let hasError = false

    if (checker.type === 'simultaneously_booked_slots_checker') {
      const slots = checker?.configuration?.number_of_simultaneous_slots
      if (slots || slots === 0) {
        if (typeof slots !== 'number' || slots < 1 || slots > 1000) {
          setSlotsError(t('sentences.invalidSimultaneousSlotsValue'))
          hasError = true
        }
      }
      if (checker?.configuration?.same_day_booking_time && sameDayEnabled) {
        const time = checker?.configuration?.same_day_booking_time
        if (typeof time !== 'string' || time.includes('_')) {
          setSameDayError(t('sentences.invalidSameDayValue'))
          hasError = true
        }
      }
    } else if (checker.type === 'number_of_days_checker') {
      const inputNumber = checker?.configuration?.number
      if (inputNumber || inputNumber === 0) {
        if (typeof inputNumber !== 'number' || inputNumber < 0 || inputNumber > maxNumberInput) {
          setTimeBeforeError(t('sentences.invalidDaysBeforeValue', { maxValue: maxNumberInput }))
          hasError = true
        }
      }
    }
    return !hasError
  }

  const removeChecker = () => {
    if (disabled) return
    if (!isNewChecker) {
      deleteChecker(checker?.uuid)
    }
    onRemoveChecker()
  }

  const handleSetTimePeriodBefore = (value, newDropdownValue) => {
    setTimeBeforeError('')
    setChecker((prev) => {
      return {
        configuration: {
          ...prev.configuration,
          type: newDropdownValue === 'days' ? 'days' : 'hours',
          number: _toInteger(value),
        },
      }
    })
  }

  const handleSetIgnoreParticipant = (val) => {
    setChecker((prev) => ({
      configuration: {
        ...prev.configuration,
        ignore_when_adding_participant: val,
      },
    }))

    saveChecker({
      ...checker,
      configuration: {
        ...checker.configuration,
        ignore_when_adding_participant: val,
      },
    })
  }

  const handleTimeUnitChange = (id) => {
    setChecker((prev) => {
      return {
        configuration: {
          ...prev.configuration,
          type: id === 'days' ? 'days' : 'hours',
          number: 0,
        },
      }
    })
  }

  const handleSameDayChecker = (val) => {
    setSameDayError('')
    setChecker((prev) => ({
      configuration: {
        ...prev.configuration,
        same_day_booking_time: val || null,
      },
    }))
  }

  const handleSetSlotsChecker = (val) => {
    setSlotsError('')
    setChecker((prev) => ({
      configuration: {
        ...prev.configuration,
        number_of_simultaneous_slots: _toInteger(val),
      },
    }))
  }

  return (
    <div className={cx(styles.container)} style={{ zIndex: 100 - index }}>
      <div className={cx(styles.header)}>
        <div className={cx(styles.leftSide)}>
          <p className={cx(styles.headerTitle)}>{t(currentCheckerType?.label)}</p>
          {currentCheckerType?.infoTextKey ? (
            <InfoHover textId={currentCheckerType?.infoTextKey} />
          ) : null}
        </div>
        <div className={cx(styles.rightSide)}>
          <div
            className={cx(styles.removeIconContainer, disabled && styles.disabled)}
            onClick={removeChecker}
          >
            <RemoveIcon className={cx(styles.removeIcon)} />
          </div>
        </div>
      </div>

      <div className={cx(styles.innerContainer)}>
        {currentCheckerType && (
          <div className={cx(styles.rowsContainer)}>
            {currentCheckerType?.type === 'simultaneously_booked_slots_checker' && (
              <SimultaneousBookingChecker
                checker={checker}
                onSetSlotsChecker={handleSetSlotsChecker}
                onSetSameDayChecker={handleSameDayChecker}
                onSaveChecker={saveChecker}
                disabled={disabled}
                slotsError={slotsError}
                sameDayError={sameDayError}
                sameDayEnabled={sameDayEnabled}
                onToggle={() => toggleSameDayEnabled(!sameDayEnabled)}
              />
            )}
            {currentCheckerType?.type === 'number_of_days_checker' && (
              <TimeBeforeChecker
                checker={checker}
                maxNumberInput={maxNumberInput}
                onTimeUnitChange={handleTimeUnitChange}
                onSetTimePeriodBefore={handleSetTimePeriodBefore}
                onSetIgnoreParticipant={handleSetIgnoreParticipant}
                onSaveChecker={saveChecker}
                disabled={disabled}
                timeBeforeError={timeBeforeError}
              />
            )}
            {currentCheckerType?.type === 'booked_slots_per_time_range_checker' && (
              <BookedSlotsChecker
                checker={checker}
                onSetChecker={(value) => setChecker(value)}
                onSaveChecker={saveChecker}
                disabled={disabled}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

SingleChecker.propTypes = {
  index: PropTypes.number,
  checker: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.oneOf([
      'simultaneously_booked_slots_checker',
      'number_of_days_checker',
      'booked_slots_per_time_range_checker',
    ]),
    configuration: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  policyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemoveChecker: PropTypes.func,
  disabled: PropTypes.bool,
}

SingleChecker.defaultProps = {}

export default SingleChecker
