/**
 * @typedef {Object} PriceObject
 * @property {number} amount
 * @property {string} currency
 */
/**
 * Returns tee time price object depending on if it's price or dynamic price
 *
 * @param {Object} teeTime
 * @param {Boolean} is_use_dynamic_pricing
 * @return {PriceObject|null}
 */
export const getPriceObject = (teeTime, is_use_dynamic_pricing) => {
  const { dynamic_price, price } = teeTime

  if (is_use_dynamic_pricing && dynamic_price?.amount) return dynamic_price
  if (!is_use_dynamic_pricing && !isNaN(price?.amount)) return price
  return null
}

/**
 * Checks if player id is participant on tee time
 *
 * @param {number} playerId
 * @param {*} teeTime
 * @returns {Boolean}
 */
export const playerIdIsParticipant = (playerId, teeTimeOrderItems) => {
  if (!playerId || !teeTimeOrderItems) return false
  return teeTimeOrderItems.some((part) => part?.slot?.player?.id === playerId)
}

/**
 * Checks if player id is participant on tee time
 *
 * @param {number} playerId
 * @param {*} teeTime
 * @returns {Boolean}
 */
export const playerIdIsParticipantAndOwner = (playerId, teeTimeOrderItems) => {
  if (!playerId || !teeTimeOrderItems) return false
  return teeTimeOrderItems.some(
    (part) => part?.slot?.player?.id === playerId && part?.slot?.is_owner
  )
}
