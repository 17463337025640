import { getHoursMinsFromISOString } from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { zonedTimeToUtc, format, utcToZonedTime } from 'date-fns-tz'
import {
  addDays,
  startOfWeek,
  endOfWeek,
  isBefore,
  isAfter,
  parseISO,
  setHours,
  setMinutes,
} from 'date-fns'
const MONDAY = 1
const FULL_WEEK_LENGTH = 7

function divideIntoWeeks(startDate, endDate) {
  // Parse the ISO string dates as Date objects in UTC
  const start = utcToZonedTime(startDate, 'UTC')
  const end = utcToZonedTime(endDate, 'UTC')

  // Move the start date back to the nearest Monday
  let currentWeekStart = startOfWeek(start, { weekStartsOn: MONDAY }) // Monday start
  const weeks = []

  while (isBefore(currentWeekStart, end) || currentWeekStart.getTime() === end.getTime()) {
    // Get the end of the current week (Sunday)
    const currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: MONDAY })

    // Ensure the current week's end does not go beyond the specified end date
    const weekEnd = isAfter(currentWeekEnd, end) ? end : currentWeekEnd

    // Format dates as 'YYYY-MM-DD' without relying on timezone conversions
    weeks.push({
      startDate: format(currentWeekStart, 'yyyy-MM-dd'),
      endDate: format(weekEnd, 'yyyy-MM-dd'),
    })

    // Move to the next week
    currentWeekStart = addDays(currentWeekStart, FULL_WEEK_LENGTH)
  }

  return weeks
}
function addTimeWithTimezone(
  weekdays,
  startDate,
  endDate,
  startHours,
  startMinutes,
  endHours,
  endMinutes,
  timezone
) {
  const periodStartDate = startDate.split('T')[0]
  const periodEndDate = endDate.split('T')[0]

  const parsedPeriodStartDate = parseISO(periodStartDate)
  const parsedPeriodEndDate = parseISO(periodEndDate)

  return weekdays.map((weekday, idx) => {
    const parsedWeekdayStartDate = parseISO(weekday.startDate)
    const parsedWeekdayEndDate = parseISO(weekday.endDate)

    const start = isAfter(parsedPeriodStartDate, parsedWeekdayStartDate)
      ? parsedPeriodStartDate
      : parsedWeekdayStartDate

    const end = isBefore(parsedPeriodEndDate, parsedWeekdayEndDate)
      ? parsedPeriodEndDate
      : parsedWeekdayEndDate

    const fromZoned = setMinutes(setHours(start, startHours), startMinutes)
    const toZoned = setMinutes(setHours(end, +endHours + 1), endMinutes)

    // Convert the zoned time back to UTC
    const fromDate = zonedTimeToUtc(fromZoned, timezone)
    const toDate = zonedTimeToUtc(toZoned, timezone)

    return {
      id: idx + 1,
      name: `W${idx + 1} ${weekday.startDate} - ${weekday.endDate}`,
      fromDate,
      toDate,
    }
  })
}

const getWeekPeriodOptions = (timePeriod, t, courseTimezone) => {
  if (!timePeriod?.id) return []

  const [startHours, startMinutes] = getHoursMinsFromISOString(timePeriod.start_time_from)
  const [endHours, endMinutes] = getHoursMinsFromISOString(timePeriod.start_time_to)
  const { start_date, end_date } = timePeriod

  const weekPeriodOptions = divideIntoWeeks(start_date, end_date)

  const periodStartDate = start_date.split('T')[0]
  const periodEndDate = end_date.split('T')[0]

  const start = parseISO(periodStartDate)
  const end = parseISO(periodEndDate)

  const fromZoned = setMinutes(setHours(start, startHours), startMinutes)
  const toZoned = setMinutes(setHours(end, +endHours + 1), endMinutes)

  // Convert the zoned time back to UTC
  const fromDate = zonedTimeToUtc(fromZoned, courseTimezone)
  const toDate = zonedTimeToUtc(toZoned, courseTimezone)

  const allPeriod = {
    id: -1,
    name: t('editPeriodsNew.allPeriod'),
    fromDate,
    toDate,
  }

  return !timePeriod.period_price_status && weekPeriodOptions.length > 1
    ? [allPeriod]
    : addTimeWithTimezone(
        weekPeriodOptions,
        start_date,
        end_date,
        startHours,
        startMinutes,
        endHours,
        endMinutes,
        courseTimezone
      )
}

export default getWeekPeriodOptions
