import {
  InputBase,
  InputLeadingContainer,
  InputContainer,
  InputHint,
  Input,
} from '@sweetspot/scramble-ds'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { SearchInputProps } from './types'

export const SearchInput = ({ onSearch }: SearchInputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const { t } = useTranslation()

  const showHint = !isFocused || inputValue === ''

  return (
    <div className="bg-background-sand-lighter px-8 py-6">
      <InputBase className="w-full">
        <InputContainer>
          <InputLeadingContainer>
            <i className="fa-solid fa-magnifying-glass" />
          </InputLeadingContainer>
          <Input
            onFocus={() => {
              track(AMPLITUDE_EVENTS.PAYMENT_LINKS.SEARCHED)
              setIsFocused(true)
            }}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => {
              onSearch(e.target.value)
              setInputValue(e.target.value)
            }}
            rounding="pill"
            className="px-10"
          />
        </InputContainer>
        {showHint && (
          <InputHint variant="default" hintText={t('paymentLinks.searchBy')} className="mt-1" />
        )}
      </InputBase>
    </div>
  )
}
