import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'
import cx from 'classnames'

const TabSwitch = (props) => {
  const onTabSelect = (index) => {
    props.onTabSelect && props.onTabSelect(index)
  }
  if (props.tabs.length === 0) return null
  return (
    <div className={cx(style.container)}>
      <div className={cx(style.headers)}>
        {props.tabs.map((tab, index) => {
          return (
            <div
              key={`${props.id || 'tab'}_${index}`}
              className={cx(style.tab, props.activeTabIndex === index ? style.current : '')}
              onClick={() => onTabSelect(index)}
            >
              <p className={cx(style.label)}>{tab.title}</p>
            </div>
          )
        })}
      </div>
      {props.tabs.map((tab, index) => (
        <div
          className={cx(props.activeTabIndex !== index ? style.hidden : '')}
          key={`tab_${index}`}
        >
          {tab.content}
        </div>
      ))}
    </div>
  )
}

TabSwitch.propTypes = {
  id: PropTypes.string,
  activeTabIndex: PropTypes.number,
  onTabSelect: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
      disabled: PropTypes.bool,
    })
  ),
}

export default TabSwitch
