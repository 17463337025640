import TimePicker from '@sweetspot/club-portal-legacy/components/TimePicker'
import style from '../../style.module.scss'

const ScheduledTimeInput = ({ errors, formState, setValue }) => {
  return (
    <div className={errors.scheduledTime ? style.errored : ''}>
      <span className={style.optionsText}>&nbsp;</span>
      <TimePicker
        name="scheduledTime"
        value={formState.scheduledTime}
        onChange={(event) => setValue('scheduledTime', event.target.value)}
        className={`ss-input ${style.inputOptions}`}
        type="time"
        disabled={!formState.isScheduled}
        iconLeft
      />
    </div>
  )
}

export default ScheduledTimeInput
