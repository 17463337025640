import { useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

import styles from './styles.module.scss'

import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'

import { GOLF_ID_OPTIONS, PRIME_TIME_TYPES } from '@sweetspot/shared/util/constants'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  addBookingPeriodOverride,
  getBookingPeriodsByGolfCourseId,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { useToasts } from 'react-toast-notifications'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'

const EditTeeTime = ({ teeTime, teeTimeCategories, course, onRequestUpdateTeeTime }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [values, updateValues] = useMergeState({
    type: null,
    golfId: null,
    slots: undefined,
    primeTime: null,
  })
  const [initialValues, setInitialValues] = useMergeState({
    type: null,
    golfId: null,
    slots: undefined,
    primeTime: null,
  })

  const [showActionButtons, setShowActionButtons] = useState(true)

  useEffect(() => {
    if (teeTime) {
      let type = null
      let golfId = null
      let slots = undefined
      let primeTime = null

      // Tee time type
      type = teeTime.category?.id

      // Golf id
      if (typeof teeTime?.is_golf_id_required !== 'undefined') {
        if (teeTime.is_golf_id_required === true) {
          golfId = GOLF_ID_OPTIONS.find((x) => x.value === 'required')
        } else {
          golfId = GOLF_ID_OPTIONS.find((x) => x.value === 'optional')
        }
      }

      // Slots
      if (typeof teeTime?.max_slots !== 'undefined' && Number.isInteger(teeTime.max_slots)) {
        slots = teeTime.max_slots
      }

      // Prime time
      if (typeof teeTime?.is_prime_time !== 'undefined') {
        if (teeTime.is_prime_time === true) {
          primeTime = PRIME_TIME_TYPES.find((x) => x.name === 'yes')
        } else {
          primeTime = PRIME_TIME_TYPES.find((x) => x.name === 'no')
        }
      }

      updateValues({
        type,
        golfId,
        slots,
        primeTime,
      })
      setInitialValues({
        type,
        golfId,
        slots,
        primeTime,
      })
    }
  }, [teeTime])

  useEffect(() => {
    let debounceTimeout = setTimeout(() => {
      if (!_.isEqual(values, initialValues)) {
        setShowActionButtons(true)
      } else {
        setShowActionButtons(false)
      }
    }, 400)

    return () => clearTimeout(debounceTimeout)
  }, [values, initialValues])

  const handleChange = ({ name, value }) => {
    let found = null

    if (name === 'slots') {
      updateValues({
        [name]: parseInt(value) > 0 ? parseInt(value) : '',
      })
      return
    }
    if (name === 'type') {
      found = value
    }
    if (name === 'golfId') {
      found = GOLF_ID_OPTIONS.find((x) => x.id === value)
    }
    if (name === 'primeTime') {
      found = PRIME_TIME_TYPES.find((x) => x.id === value)
    }

    updateValues({
      [name]: found,
    })
  }

  const cancelChanges = () => {
    updateValues(initialValues)
    setShowActionButtons(false)
  }

  const saveChanges = async () => {
    setShowActionButtons(false)

    const [periodsRes, periodsErr] = await to(getBookingPeriodsByGolfCourseId(course.id))

    if (!periodsRes || periodsErr) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
      return
    }

    const date = new Date(teeTime.from)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    const ISODate = DateHelpers.toISODateTime(date)

    const activePeriod = periodsRes.find(
      (period) => period.start_date <= ISODate && period.end_date >= ISODate
    )

    if (!activePeriod) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
      return
    }

    // Exclude next tee time startTime (see FROM/TO fixes)
    const _to = moment(teeTime.to).valueOf() - activePeriod.interval * 60

    const [overrideRes, overrideErr] = await to(
      addBookingPeriodOverride(activePeriod.uuid, {
        repeat_on: 'every_day',
        start_date: DateHelpers.onlyDateString(date),
        end_date: DateHelpers.onlyDateString(date),
        start_time_from: moment(teeTime.from).tz(course?.timezone).format('HH:mm:ss'),
        start_time_to: moment(_to).tz(course?.timezone).format('HH:mm:ss'),
        category_id: values.type,
        slots: values.slots,
        is_golf_id_required: values.golfId.value === 'required' ? true : false,
        is_prime_time: values.primeTime.value === 1 ? true : false,
      })
    )

    if (!overrideRes || overrideErr) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
      return
    }

    setTimeout(() => {
      onRequestUpdateTeeTime()
    }, 1000)
  }

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.inputContainer)}>
        <span className={styles.label}>{t('category')}</span>
        <DropdownSelect
          statusClassName={styles.statusContainer}
          containerClassName={styles.dropdown}
          dropdownAnchor="top"
          readOnly
          showBorder
          hideSelf
          initialId={values.type || -1}
          selectedId={values.type || -1}
          values={teeTimeCategories.map((item) => ({
            id: item.id,
            color: item.color,
            name: item.name,
            value: item.id,
          }))}
          onSelect={(value) => handleChange({ name: 'type', value })}
        />
      </div>

      <div className={styles.inputContainer}>
        <span className={styles.label}>{t('golf_id')}</span>
        <DropdownSelect
          containerClassName={styles.dropdown}
          dropdownAnchor="top"
          readOnly
          showBorder
          hideSelf
          placeholder="golfId"
          initialId={Number.isInteger(values?.golfId?.id) ? values.golfId.id : -1}
          selectedId={Number.isInteger(values?.golfId?.id) ? values.golfId.id : -1}
          width={110}
          values={GOLF_ID_OPTIONS.map((item) => ({
            ...item,
            name: t(`teeSheet.${item.value}`),
          }))}
          onSelect={(value) => handleChange({ name: 'golfId', value })}
        />
      </div>

      <div className={cx(styles.inputContainer, styles.slots)}>
        <span className={styles.label}>{t('slots')}</span>
        <TextInputField
          colorTheme="white-outline"
          type="number"
          value={values.slots}
          onChange={(value) => handleChange({ name: 'slots', value })}
          inputProps={{
            id: styles.slotsInput,
          }}
        />
      </div>

      <div className={cx(styles.inputContainer)}>
        <span className={styles.label}>{t('editPeriodsNew.primeTime')}</span>
        <DropdownSelect
          containerClassName={styles.dropdown}
          dropdownAnchor="top"
          readOnly
          showBorder
          hideSelf
          initialId={Number.isInteger(values?.primeTime?.id) ? values.primeTime.id : -1}
          selectedId={Number.isInteger(values?.primeTime?.id) ? values.primeTime.id : -1}
          width="65px"
          values={PRIME_TIME_TYPES.map((item) => ({
            ...item,
            name: t(item.name),
          }))}
          onSelect={(value) => handleChange({ name: 'primeTime', value })}
        />
      </div>

      <Button
        className={cx(
          styles.actionButton,
          styles.cancelButton,
          showActionButtons && styles.visible
        )}
        onClick={cancelChanges}
        width="auto"
        text={t('words.cancel')}
        theme="none"
      />
      <Button
        onClick={saveChanges}
        className={cx(styles.actionButton, styles.saveButton, showActionButtons && styles.visible)}
        width="auto"
        text={t('words.save')}
      />
    </div>
  )
}

EditTeeTime.propTypes = {
  teeTime: PropTypes.object,
  teeTimeCategories: PropTypes.array,
  course: PropTypes.object,
  onRequestUpdateTeeTime: PropTypes.func,
}

EditTeeTime.defaultProps = {
  onRequestUpdateTeeTime: () => {},
}

export default EditTeeTime
