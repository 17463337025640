export function saveData(key, data) {
  sessionStorage.setItem(key, JSON.stringify(data))
}

export function delData(key) {
  sessionStorage.removeItem(key)
}

export function getData(key) {
  let data = sessionStorage.getItem(key)
  if (data === null || data === undefined) return null

  return JSON.parse(data)
}

export function saveDataPersistent(key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function delDataPersistent(key) {
  localStorage.removeItem(key)
}

export function getDataPersistent(key) {
  let data = localStorage.getItem(key)
  if (data === null || data === undefined || !data || data === 'undefined') return null
  else return JSON.parse(data)
}
