import Table from '@sweetspot/club-portal-legacy/components/Table'
import headers from './headers'

interface PaymentPlanPropsType {
  paymentsData: [
    {
      amount: {
        value: number
        currency: string
      }
      installment_loan: {
        month: number | null
        term: number | null
        payment_date: string | null
      }
      state: string
    }
  ]
}

const PaymentPlanTable = (props: PaymentPlanPropsType) => {
  const { paymentsData } = props

  return (
    <div>
      <Table headers={headers} values={paymentsData} />
    </div>
  )
}

export default PaymentPlanTable
