import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { t } from 'i18next'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import style from './style.module.scss'

import { addToast } from '@sweetspot/club-portal-legacy/store/actions'
import { _deleteGolfClub } from '@sweetspot/club-portal-legacy/services/golfClubApi'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'

class DeleteGolfClub extends Component {
  state = {
    selectedGolfclub: null,
    isLoading: false,
  }

  confirmDelete = () => {
    let text = this._confirm.value.trim().toLowerCase()
    if (text !== 'delete') {
      alert(getLang(this.props.lang)['settings']['confirmDelete'])
      return false
    }
    return true
  }

  handleDelete = () => {
    if (this.confirmDelete()) {
      _deleteGolfClub(this.state.selectedGolfclub.id)
        .then(() => {
          this.props.dispatch({
            type: CONSTANTS.GOLF_CLUB.DELETE,
            payload: this.state.selectedGolfclub.id,
          })
          this.props.dispatch(addToast('golfclubDeleted', 'success'))
          this._confirm.value = ''
        })
        .catch((err) => {
          this.props.dispatch(addToast('golfclubDeleteError', 'error'))
        })
    }
  }

  renderSelects = () => {
    return (
      <div className="ss-form-group">
        <DropdownSelect
          showBorder
          width="100%"
          values={this.props.golfClubList}
          onSelect={(id) => {
            let club = this.props.golfClubList.find((club) => club.id === id)
            if (!club) return

            this.setState({
              selectedGolfclub: { name: club.name, id: club.id },
            })
          }}
        />
      </div>
    )
  }

  renderForm = () => {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className="ss-label">{t('settings.typeDelete')}</label>
          <input className="ss-input" type="text" ref={(input) => (this._confirm = input)} />
        </div>
        <div>
          <button
            className={`system-button danger md-32 ${style.formButtons}`}
            onClick={this.handleDelete}
          >
            {t('settings.delete')}
          </button>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.label}>
          <Text textId="settings.deleteGolfclub" />
          <div>
            <PulseLoader showIf={this.state.isLoading} />
          </div>
        </div>
        {this.renderSelects()}
        {this.renderForm()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    golfClubList: state.golfClub.list,
    lang: state.auth.me.lang,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteGolfClub))

DeleteGolfClub.propTypes = {
  lang: PropTypes.string.isRequired,
  golfClubList: PropTypes.array.isRequired,
  deleteGolfClub: PropTypes.func.isRequired,
}
