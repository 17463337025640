export const BOOKING_RELATIONS = {
  BOOKING: 'BOOKING',
  ITEMS: 'ITEMS',
  PAYMENTS: 'PAYMENTS',
  REFUNDS: 'REFUNDS',
  SPACES: 'SPACES',
  INVENTORY_SCHEDULES: 'INVENTORY_SCHEDULES',
  ALL: 'ALL',
}

export const BOOKING_GROUPS = {
  CLUB: 'with_club',
  COURSE: 'with_course',
  LEGACY_BOOKING: 'with_booking',
  CUSTOMER: 'with_customer',
  CUSTOMER_FEDERATION_PROFILES: 'with_customer_federation_profiles',
  PARTNERSHIP: 'with_partnership',
  PROMOTIONS: 'with_promotions',
  ALL: 'ALL',
}
