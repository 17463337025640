import { useTranslation } from 'react-i18next'
import style from '../../style.module.scss'
import NumberInput from '@sweetspot/sweetspot-js/common/components/NumberInput'

const IntervalField = ({ errors, triedToSubmit, formState, setValue }) => {
  const { t } = useTranslation()

  const handleHoursChange = (event) => {
    const hours = +event.target.value
    const totalMinutes = hours * 60 + (formState.minutes || 0)
    setValue('interval', totalMinutes)
    setValue('hours', hours)
  }

  const handleMinutesChange = (event) => {
    const minutes = +event.target.value
    const totalMinutes = (formState.hours || 0) * 60 + minutes
    setValue('interval', totalMinutes)
    setValue('minutes', minutes)
  }

  return (
    <div className={triedToSubmit && errors.interval ? style.errored : ''}>
      <span className={style.optionsText}>
        {t('editPeriodsNew.interval')} ({t('hours').toLowerCase()}/
        {t('wizard.minutes').toLowerCase()})
      </span>
      <div className="flex items-center">
        <div className={style.numberInput}>
          <NumberInput
            value={formState.hours || 0}
            onChange={handleHoursChange}
            className={`ss-input ${style.inputOptions}`}
            min={0}
            max={23}
          />
        </div>
        <div className={style.numberInput}>
          <NumberInput
            value={formState.minutes || 0}
            onChange={handleMinutesChange}
            className={`ss-input ${style.inputOptions}`}
            min={0}
            max={59}
          />
        </div>
      </div>
    </div>
  )
}

export default IntervalField
