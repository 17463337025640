import React, { useEffect } from 'react'

import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import useVoucher, {
  FORM,
  VOUCHER_TYPE,
} from '@sweetspot/sweetspot-js/features/vouchers/hooks/useVoucher'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'

import styles from './styles.module.scss'
import GeneralTab from './GeneralTab'
import ActionsTab from '@sweetspot/club-portal-legacy/modals/VoucherModal/ActionsTab'
import RulesTab from '@sweetspot/club-portal-legacy/modals/VoucherModal/RulesTab'
import CodesTab from '@sweetspot/club-portal-legacy/modals/VoucherModal/CodesTab'
import cx from 'classnames'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import ConfirmModal from '@sweetspot/club-portal-legacy/components/ConfirmModal'
import { ReactComponent as TrashIcon } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import { ReactComponent as VoucherIcon } from '@sweetspot/club-portal-legacy/resources/images/voucher.svg'
import { connect } from 'react-redux'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'

const VoucherModal = (props) => {
  const hasPermission = hasAccess(ACCESS_KEYS.PAGES.VOUCHERS.UI.MODAL.CREATE, props.role?.value)
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [currentTab, setCurrentTab] = React.useState(hasPermission ? 1 : 4)

  const { voucher, errors, loaders, voucherActions, editField, dialog, discount, coupons } =
    useVoucher(props.match.params.voucherID, props.clubID)
  const isNew = voucher && voucher.id === 'new'
  const isCanceled = voucher && voucher.status === 'canceled'

  useEffect(() => {
    if (errors['$global']) {
      addToast(errors['$global'], { appearance: 'error' })
    }
  }, [errors.$global])

  const TABS = React.useMemo(() => {
    const codesTab =
      voucher && !isNew && voucher[FORM.VOUCHER_TYPE] !== VOUCHER_TYPE.STANDALONE
        ? {
            id: 4,
            stepNumber: 4,
            label: t('vouchers.tab_Codes'),
          }
        : null

    if (hasPermission)
      return [
        {
          id: 1,
          stepNumber: 1,
          label: t('vouchers.tab_General'),
        },
        {
          id: 2,
          stepNumber: 2,
          label: t('vouchers.tab_Rules'),
        },
        {
          id: 3,
          stepNumber: 3,
          label: t('vouchers.tab_Actions'),
        },
        codesTab,
      ]

    return [codesTab]
  }, [t, voucher])

  const currentGolfClub = props.golfClubs.list.find((club) => club.id === props.clubID)

  const hasCdhNumber = !!currentGolfClub?.cdh_id

  return (
    <ModalContainer
      width={'half'}
      height={'default'}
      theme="wizard"
      fixed={currentTab !== 1}
      headerBackgroundColor={'#4F5B6D'}
      headerColor="#FFFFFF"
      headerChildren={
        <SimpleTabs
          currentItem={currentTab}
          onClick={setCurrentTab}
          items={TABS}
          errors={[]}
          errorItems={[]}
          disabled={Boolean(!voucher || isNew)}
          styles={{
            container: styles.tabsContainer,
            tab: styles.tabsItem,
          }}
        />
      }
      headerTitle={
        <div className={styles.modalTitle}>
          <VoucherIcon />
          <span>
            {isNew
              ? t('vouchers.title_CreateNew')
              : `${t('vouchers.title_Voucher')}${
                  loaders.voucher ? '' : ` - ${voucher[FORM.NAME]}`
                }`}
          </span>
        </div>
      }
      headerButtons={
        voucher && !isNew && !loaders.voucher ? (
          <div className={styles.headerButtons}>
            {!isCanceled ? (
              hasPermission && (
                <Button
                  size="small"
                  theme="danger"
                  width="third"
                  text={t('vouchers.button_Invalidate')}
                  disabled={false}
                  onClick={voucherActions.invalidateVoucher}
                />
              )
            ) : (
              <Button
                size="small"
                theme="danger"
                width="third"
                text={t('vouchers.button_Delete')}
                disabled={false}
                onClick={voucherActions.deleteVoucher}
              />
            )}
          </div>
        ) : null
      }
      onClose={() => {
        history.push('/vouchers', { shouldReload: true })
        if (voucher && voucher.id !== 'new') {
          voucherActions.refetch()
        }
      }}
    >
      {loaders.voucher && currentTab !== 4 ? (
        <div className={cx(styles.loader)}>
          <PulseLoader showIf={true} />
        </div>
      ) : null}
      <GeneralTab
        isActive={currentTab === 1 && !loaders.voucher}
        editField={editField}
        loaders={loaders}
        voucherActions={voucherActions}
        voucher={voucher}
        errors={errors}
      />
      <RulesTab
        isActive={currentTab === 2 && !loaders.voucher}
        voucher={voucher}
        clubID={props.clubID}
      />
      <ActionsTab
        isActive={currentTab === 3 && !loaders.voucher}
        discount={discount}
        isLoading={loaders.discount}
        errors={errors}
        editField={editField}
        voucherActions={voucherActions}
      />
      <CodesTab
        hasCdhNumber={hasCdhNumber}
        isActive={currentTab === 4}
        voucher={voucher}
        loaders={loaders}
        voucherActions={voucherActions}
        coupons={coupons}
        role={props.role}
      />
      {dialog ? (
        <ConfirmModal
          headerIcon={<TrashIcon />}
          headerText={dialog.title}
          textArray={[dialog.message]}
          confirmText={t('words.confirm')}
          cancelText={t('words.close')}
          onCancel={dialog.onCancel}
          onConfirm={dialog.onConfirm}
        />
      ) : null}
    </ModalContainer>
  )
}

const mapStateToProps = (state) => ({
  clubID: state.golfClub.selectedId,
  golfClubs: state.golfClub,
  role: getFirstMatchingRole(state.auth.roles),
})

export default connect(mapStateToProps, null)(VoucherModal)
