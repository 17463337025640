import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

import { ReactComponent as PrimeTimeLight } from '@sweetspot/sweetspot-js/assets/svgs/prime-time-white.svg'
import { ReactComponent as PrimeTimeDark } from '@sweetspot/sweetspot-js/assets/svgs/prime-time-black.svg'

const PrimeTime = ({ showText, iconColor, hideToolTip }) => {
  const [showToolTip, setShowToolTip] = useState(false)

  const textColor = iconColor === 'light' ? '#FFFFFF' : '#000000'
  const renderTooltip = () => {
    if (showToolTip) {
      return (
        <div className={styles.infoHoverTooltip}>
          <div>Prime time</div>
          <div className={styles.arrow}></div>
        </div>
      )
    }
  }

  return (
    <div
      className={styles.infoHover}
      onMouseEnter={() => !hideToolTip && setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      {iconColor === 'light' ? <PrimeTimeLight /> : <PrimeTimeDark />}
      {showText && <div style={{ color: textColor, marginLeft: '8px' }}>Prime time</div>}
      {renderTooltip()}
    </div>
  )
}

PrimeTime.propTypes = {
  showText: PropTypes.bool,
  iconColor: PropTypes.string,
  hideToolTip: PropTypes.bool,
}

PrimeTime.defaultProps = {
  showText: false,
  iconColor: 'dark',
  hideToolTip: false,
}

export default PrimeTime
