import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Checkbox from '@sweetspot/club-portal-legacy/components/Checkbox'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'
import { ReactComponent as ScheduleIcon } from '@sweetspot/club-portal-legacy/resources/images/schedule-button.svg'
import { ReactComponent as WarningIcon } from '@sweetspot/club-portal-legacy/resources/images/warning-icon.svg'
import { ReactComponent as TrashIcon } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'

import style from './style.module.scss'
import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'
import { formatISO } from 'date-fns'

const PUBLISH = 'published'
const DRAFT = 'draft'
const SCHEDULE = 'scheduled'

const CreatePost = ({ isLoading, post, onUpdate, onRemove, onClose }) => {
  const [form, setForm] = useState({
    title: '',
    body: '',
    pubDate: '',
    pubTime: '',
  })
  const [showConfirm, setShowConfirm] = useState(false)
  const [removeConfirm, setRemoveConfirm] = useState(false)
  const [isScheduled, setScheduled] = useState(post.state === 'scheduled')
  const { t } = useTranslation()

  useEffect(() => {
    loadValues()
  }, [])

  const loadValues = () => {
    const [date, time] = post.published_at.split(' ')

    setForm({ ...form, body: post.body, title: post.title, pubDate: date, pubTime: time })
  }

  const { title, body, pubDate, pubTime } = form

  const handleChange = ({ name, value }) => {
    setForm({ ...form, [name]: value })
  }

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm)
  }

  const checkChange = () => {
    const [date, time] = post.published_at.split(' ')

    if (post.body === body && post.title === title && pubDate === date && pubTime === time)
      return false
    return true
  }

  const handleClose = () => {
    const isChanged = checkChange()

    if (isChanged) {
      setShowConfirm(true)
    } else {
      onClose()
    }
  }

  const generatePayload = (status) => {
    let publishDate = new Date()
    if (isScheduled && pubDate && pubTime) {
      publishDate = DateHelpers.toDateFromDateString(`${pubDate} ${pubTime}`)
    }
    const published_at = formatISO(publishDate)

    const payload = {
      title,
      body,
      published_at,
      status: status === SCHEDULE ? PUBLISH : status,
      id: post.id,
    }

    return payload
  }

  const saveDraft = () => {
    const payload = generatePayload(DRAFT)

    onUpdate(payload)
  }

  const isValidForm = () => {
    if (isLoading || !title || !body) return false
    if (isScheduled && (!pubDate || !pubTime)) return false
    const publishDate = DateHelpers.toDateFromDateString(`${pubDate} ${pubTime}`)
    if (isScheduled && !moment(publishDate).isAfter(moment(new Date()))) return false
    return true
  }

  const handlePublish = () => {
    const payload = generatePayload(isScheduled ? SCHEDULE : PUBLISH)

    onUpdate(payload)
  }

  const renderHeader = () => {
    return (
      <div className={style.header}>
        <Text textId="communication.editPost" />
        <CloseIcon onClick={handleClose} className={style.closeIcon} />
      </div>
    )
  }

  const renderBody = () => {
    return (
      <form className={style.body} onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className={`ss-label ${style.label}`}>{t('communication.subject')}</label>
          <input
            className={`ss-input ${style.input}`}
            name="title"
            type="text"
            disabled={isLoading}
            value={title}
            onChange={(e) => handleChange(e.target)}
          />
        </div>

        <div className="ss-form-group">
          <label className={`ss-label ${style.label}`}>{t('communication.bodyText')}</label>
          <textarea
            className={`ss-input ${style.input}`}
            name="body"
            rows="12"
            disabled={isLoading}
            value={body}
            onChange={(e) => handleChange(e.target)}
          />
        </div>
      </form>
    )
  }

  const renderFooter = () => {
    return (
      <div className={style.footer}>
        <div className={style.schedule}>
          <Checkbox
            append={t('communication.schedulePost')}
            className={style.schedulePost}
            checked={isScheduled}
            onChange={() => setScheduled(!isScheduled)}
          />

          <div className={style.date}>
            <TextInputField
              type="calendar"
              placeholder="YYYY-MM-DD"
              value={pubDate}
              calendarSettings={{
                selectedDate: pubDate,
                returnDateFormat: 'YYYY-MM-DD',
              }}
              onChange={(value) => handleChange({ name: 'pubDate', value })}
              CalendarComponent={MiniCalendar}
            />
          </div>

          <div className={style.time}>
            <TextInputField
              type="time"
              placeholder="HH:MM"
              value={pubTime}
              onChange={(value) => handleChange({ name: 'pubTime', value })}
            />
          </div>

          <button
            className={`system-button primary md-32 ${style.publish}`}
            disabled={!isValidForm()}
            onClick={handlePublish}
          >
            {isScheduled && <ScheduleIcon />}
            {t('communication.publish')}
          </button>
        </div>
        <div className={style.buttons}>
          <button
            className="system-button danger-outline md-32"
            disabled={isLoading}
            onClick={() => setRemoveConfirm(true)}
          >
            {t('communication.deletePost')}
          </button>
          <button
            className={`system-button info-outline md-32 ${style.draft}`}
            disabled={isLoading || !title || !body}
            onClick={saveDraft}
          >
            {t('communication.saveDraft')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderHeader()}
      {renderBody()}
      {renderFooter()}

      <ConfirmPopup
        visible={showConfirm}
        acceptTheme="default"
        titleIcon={WarningIcon}
        acceptText={t('confirm')}
        rejectText={t('cancel')}
        title={t('communication.confirmCloseTitle')}
        text={t('communication.confirmCloseText')}
        onReject={toggleConfirm}
        onClose={toggleConfirm}
        onAccept={onClose}
      />

      <ConfirmPopup
        visible={removeConfirm}
        titleIcon={TrashIcon}
        acceptText={t('wizard.delete')}
        rejectText={t('cancel')}
        title={t('communication.confirmDeleteTitle')}
        text={t('communication.confirmDeleteText')}
        onReject={() => setRemoveConfirm(false)}
        onClose={() => setRemoveConfirm(false)}
        onAccept={() => onRemove(post.id)}
      />
    </div>
  )
}

CreatePost.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  post: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default CreatePost
