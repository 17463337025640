import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const SimpleGrid = ({ children, wrapperClassName, rowFolds, headerClassName }) => {
  const scrollableRows = useRef([])
  const [overflownRows, setOverflownRows] = useState({})

  useEffect(() => {
    const rows = scrollableRows

    let timeout = null

    const handler = (ref, index) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        rows.current.forEach((xRef, xIndex) => {
          if (index === xIndex) return
          if (!xRef) return
          xRef.scrollTo({
            top: 0,
            left: ref.scrollLeft,
            behavior: 'smooth',
          })
        })
      }, 100)
    }

    if (rows?.current?.length > 0) {
      rows.current.forEach((ref, index) => {
        if (ref) {
          ref.addEventListener('scroll', () => handler(ref, index))
          if (isOverflown(ref)) {
            setOverflownRows((prev) => ({ ...prev, [index]: true }))
          } else {
            setOverflownRows((prev) => ({ ...prev, [index]: false }))
          }
        }
      })
    }

    return () => {
      clearTimeout(timeout)
      if (rows?.current?.length > 0) {
        rows.current.forEach((ref, index) => {
          if (ref) {
            ref.removeEventListener('scroll', () => handler(ref, index))
          }
        })
      }
    }
  }, [scrollableRows, children])

  const isOverflown = (ref) => {
    const { clientWidth, clientHeight, scrollWidth, scrollHeight } = ref
    return scrollHeight > clientHeight || scrollWidth > clientWidth
  }

  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      {React.Children.map(children, (row, rowIndex) => {
        if (!row) return null

        const rowKey = row?.key
        const {
          className: rowClassName,
          style: rowStyle,
          id: rowId,
          children: items,
          ...rowRest
          // TODO: fix later
          // eslint-disable-next-line no-unsafe-optional-chaining
        } = row?.props

        const isHeaderRow = rowClassName?.includes('row-header') ? true : false
        const isSubHeaderRow = rowClassName?.includes('row-sub-header') ? true : false
        const isBodyRow = rowClassName?.includes('row-body') ? true : false

        const RowFold = rowFolds ? rowFolds.find((x) => x?.key === rowKey) : null

        let leftFixed = []
        let rightFixed = []
        let normal = []

        React.Children.forEach(items, (item) => {
          if (!item) return
          const itemClassName = item?.props?.className
          if (itemClassName?.includes('fixed-left')) {
            leftFixed.push(item)
          } else if (itemClassName?.includes('fixed-right')) {
            rightFixed.push(item)
          } else {
            normal.push(item)
          }
        })

        const renderItem = (item, itemIndex) => {
          if (!item) return null

          const { key: itemKey } = item
          const {
            className: itemClassName,
            style: itemStyle,
            id: itemId,
            ...itemRest
            // TODO: fix later
            // eslint-disable-next-line no-unsafe-optional-chaining
          } = item?.props

          return React.cloneElement(item, {
            key: itemKey || rowIndex + '-grid-row-' + itemIndex + '-item',
            className: cx(
              styles.rowItem,
              isBodyRow && styles.bodyItem,
              isHeaderRow && styles.headerItem,
              isSubHeaderRow && styles.headerItem,
              itemClassName || null
            ),
            style: itemStyle || {},
            id: itemId || null,
            ...itemRest,
          })
        }

        return (
          <React.Fragment>
            <div
              className={cx(
                styles.row,
                isHeaderRow && styles.headerRow,
                isSubHeaderRow && styles.subHeaderRow,
                isBodyRow && styles.bodyRow,
                rowClassName || null,
                (isHeaderRow && headerClassName) || null
              )}
              style={rowStyle || {}}
              id={rowId || null}
              key={(rowKey || rowIndex) + '-grid-row'}
              {...rowRest}
            >
              {leftFixed?.length > 0 && (
                <div
                  className={cx(
                    styles.left,
                    overflownRows?.[rowIndex] === true && styles.isOverflown
                  )}
                >
                  {React.Children.map(leftFixed, (item, index) => renderItem(item, index))}
                </div>
              )}
              <div
                className={cx(
                  styles.body,
                  overflownRows?.[rowIndex] === true && styles.isOverflown
                )}
                ref={(ref) => {
                  scrollableRows.current[rowIndex] = ref
                }}
              >
                {React.Children.map(normal, (item, index) => renderItem(item, index))}
              </div>
              {rightFixed?.length > 0 && (
                <div
                  className={cx(
                    styles.right,
                    overflownRows?.[rowIndex] === true && styles.isOverflown
                  )}
                >
                  {React.Children.map(rightFixed, (item, index) => renderItem(item, index))}
                </div>
              )}
            </div>
            {RowFold && React.cloneElement(RowFold)}
          </React.Fragment>
        )
      })}
    </div>
  )
}

SimpleGrid.propTypes = {
  headers: PropTypes.node,
  rowFolds: PropTypes.arrayOf(PropTypes.node),
  wrapperClassName: PropTypes.string,
  headerClassName: PropTypes.string,
}

SimpleGrid.defaultProps = {
  headers: null,
  wrapperClassName: '',
  headerClassName: '',
}

export default SimpleGrid
