import { useMemo } from 'react'

import { APP_NAMES } from '@sweetspot/shared/util/constants'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'

const useIsClubPortal = () => {
  const isClubPortal = useMemo(() => getCurrentAppName() === APP_NAMES.CLUB_PORTAL, [])
  return isClubPortal
}

export default useIsClubPortal
