import styles from './time-picker.module.scss'
import cx from 'classnames'

const TimePicker = ({ iconLeft, type, disabled, ...props }) => {
  const className = cx(
    styles.timePicker,
    type === 'time' && styles.time,
    iconLeft && styles.left,
    disabled && styles.disabled
  )

  return (
    <div className={className}>
      <input type={type} {...props} />
    </div>
  )
}

export default TimePicker
