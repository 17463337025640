import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import BlueTitle from '../BlueTitle'

const DropdownHeader = ({ open, setOpen, className, title }) => {
  return (
    <div className={cx(styles.container, className)} onClick={() => setOpen(!open)}>
      <CaretDown className={cx(styles.svgDarkBlue, styles.caret, open && styles.open)} />
      <BlueTitle className={cx(styles.headerTitle)}>{title}</BlueTitle>
    </div>
  )
}

DropdownHeader.propTypes = {
  className: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}

DropdownHeader.defaultProps = {}

export default DropdownHeader
