import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import Button from '@sweetspot/sweetspot-js/common/components/Button'

const ActionsContainer = ({
  visible,
  position,
  anchorPosition,
  confirmText,
  cancelText,
  showCancelButton,
  showConfirmButton,
  confirmDisabled,
  onConfirm,
  onCancel,
  confirmLoading,
}) => {
  return (
    <div
      className={cx(
        styles.actionsContainer,
        {
          [styles.visible]: visible,
        },
        {
          [styles.positionTop]: position === 'top',
          [styles.positionBottom]: position === 'bottom',
        },
        {
          [styles.anchorRight]: anchorPosition === 'right',
        }
      )}
      tabIndex={-1}
    >
      <Button
        className={cx(styles.button, showCancelButton && !confirmLoading && styles.visible)}
        theme="whitebg-grayoutline"
        text={cancelText}
        onClick={onCancel}
        tabIndex={visible ? 0 : -1}
      />
      <Button
        className={cx(styles.button, showConfirmButton && styles.visible)}
        text={confirmText}
        onClick={onConfirm}
        disabled={confirmDisabled}
        loading={confirmLoading}
        loaderStyle="pulse"
        tabIndex={visible ? 0 : -1}
      />
    </div>
  )
}

ActionsContainer.propTypes = {
  visible: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom']),
  anchorPosition: PropTypes.oneOf(['left', 'right']),
  confirmText: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  cancelText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmLoading: PropTypes.bool,
}

ActionsContainer.defaultProps = {
  visible: false,
  position: 'bottom',
  anchorPosition: 'right',
  confirmText: 'Save',
  cancelText: 'Cancel',
  showCancelButton: true,
  showConfirmButton: true,
  onConfirm: () => {},
  onCancel: () => {},
  confirmLoading: false,
}

export default ActionsContainer
