import React, { useState, useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import { Toggle, Divider } from '@sweetspot/scramble-ds'

import SchemeTab from './SchemeTab'
import PricesTab from './PricesTab'
import BucketConfiguration from './BucketConfiguration/BucketConfiguration'
import { useBallPricingContext } from '../BallPricingProvider'

const TABS = {
  Scheme: 'Scheme',
  Prices: 'Prices',
}

const BallPricingTabsContainer = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(TABS.Scheme)
  const [isSchemeEditMode, setIsSchemeEditMode] = useState(false)
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false)
  const [openBucketConfigurationSideBar, setOpenBucketConfigurationSideBar] = useState(false)
  const {
    bucketConfiguration: { getBucketConfiguration, getRangeSchedule, isDirty, onSave },
    demandModel: { demandModelId, isDemandModelError, hasDemandEntries },
  } = useBallPricingContext()

  const noDemand = useMemo(
    () => !demandModelId || isDemandModelError || !hasDemandEntries,
    [demandModelId, isDemandModelError, hasDemandEntries]
  )

  const toggleBucketConfigurationSideBar = useCallback(
    (forceClose?: boolean) => {
      if (!forceClose && openBucketConfigurationSideBar && isDirty) {
        setOpenDiscardDialog?.(true)
      } else {
        setOpenBucketConfigurationSideBar(!openBucketConfigurationSideBar)
      }
    },
    [openBucketConfigurationSideBar, isDirty, setOpenDiscardDialog]
  )

  const handleOnOpenBucketConfiguration = useCallback(() => {
    toggleBucketConfigurationSideBar()
    getBucketConfiguration?.()
    getRangeSchedule?.()
  }, [getBucketConfiguration, toggleBucketConfigurationSideBar, getRangeSchedule])

  const renderContent = useCallback(() => {
    if (activeTab === TABS.Scheme) {
      return (
        <SchemeTab
          isSchemeEditMode={isSchemeEditMode}
          handleOnOpenBucketConfiguration={handleOnOpenBucketConfiguration}
        />
      )
    }
    if (activeTab === TABS.Prices) {
      return <PricesTab handleOnOpenBucketConfiguration={handleOnOpenBucketConfiguration} />
    }
    return null
  }, [activeTab, isSchemeEditMode, handleOnOpenBucketConfiguration])

  return (
    <div className="flex h-full flex-1 flex-col gap-4">
      <div className="flex items-center">
        <SimpleTabs
          currentItem={activeTab}
          onClick={setActiveTab}
          items={[
            { id: TABS.Scheme, label: t('words.demandLevels'), stepNumber: TABS.Scheme },
            { id: TABS.Prices, label: t('words.prices'), stepNumber: TABS.Prices },
          ]}
        />
        <div className="flex items-center gap-2">
          {activeTab === TABS.Scheme && (
            <>
              <div className="px-md flex items-center gap-2">
                <p
                  className={cx('text-content-sm text-text-dark', {
                    'text-text-pale': noDemand,
                  })}
                >
                  {isSchemeEditMode ? `${t('words.editing')}...` : t('words.edit')}
                </p>
                <Toggle
                  disabled={noDemand}
                  checked={isSchemeEditMode}
                  onCheckedChange={() => setIsSchemeEditMode(!isSchemeEditMode)}
                />
              </div>
              <Divider orientation={'vertical'} className="h-4" />
            </>
          )}
          <div
            className="flex cursor-pointer items-center gap-2 px-4 py-1"
            onClick={handleOnOpenBucketConfiguration}
          >
            <i className="fa-regular fa-sliders" />
            <p className="text-content-sm text-text-dark whitespace-nowrap">
              {t('sentences.bucketConfiguration')}
            </p>
          </div>
        </div>
      </div>
      {renderContent()}
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog?.(false)}
        onDiscard={() => {
          setOpenDiscardDialog?.(false)
          setOpenBucketConfigurationSideBar(false)
        }}
        onSave={() => {
          setOpenDiscardDialog?.(false)
          onSave?.(() => {
            setOpenBucketConfigurationSideBar(false)
          })
        }}
      />
      <BucketConfiguration
        open={openBucketConfigurationSideBar}
        onOpenChange={toggleBucketConfigurationSideBar}
      />
    </div>
  )
}

export default BallPricingTabsContainer
