import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'

const Header = () => {
  const { t } = useTranslation()

  const { selectedRange, toggleBaySideBar } = useBaysContext()

  return (
    <div className={'flex flex-row items-center border-b-[1px] px-6 py-[14px]'}>
      <p className={'flex-1 text-lg font-bold'}>
        {t('words.settings')}: {t('words.bays')}
      </p>
      <Button
        variant={'clear-dark'}
        size={'small'}
        onClick={() => toggleBaySideBar(BaySideBarMode.CREATE)}
        disabled={!selectedRange}
        className={'py-sm px-lg border-stroke-stone gap-2 text-sm font-medium'}
      >
        <i className="fa-regular fa-plus" />
        {t('words.new')}
      </Button>
    </div>
  )
}

export default Header
