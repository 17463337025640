import Body from './Body'
import Header from './Header'
import FilterToolbar from './FilterToolbar'
import { BallDispensersProvider } from './BallDispensersProvider'

const BallDispensers = () => (
  <BallDispensersProvider>
    <div className="flex h-full flex-col">
      <Header />
      <div className={'py-4'}>
        <FilterToolbar />
      </div>
      <div className={'flex flex-1 justify-center'}>
        <Body />
      </div>
    </div>
  </BallDispensersProvider>
)

export default BallDispensers
