import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import SearchInput from '@sweetspot/club-portal-legacy/components/SearchInput'
import Table from '@sweetspot/club-portal-legacy/components/Table'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import ModifyUser from '../ModifyUser'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import DeleteUser from '../DeleteUser'

import PlayersHelpers from '@sweetspot/club-portal-legacy/helpers/PlayersHelpers'

import { _getFederationId } from '@sweetspot/club-portal-legacy/services/adminApi'
import { isValidPhoneNumber } from 'libphonenumber-js'

import headers from './headers'
import { isCdhNumberFormat } from '@sweetspot/sweetspot-js/common/functions/utils'

export default class UsersTable extends Component {
  state = {
    searchText: '',
    completeSearch: false,
    selectedPlayer: null,
    tableValues: [],
    isLoading: false,
    memberships: null,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEnterKey)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterKey)
  }

  handleChange = (input) => {
    this.setState({
      searchText: input,
      errorMessage: '',
    })
  }

  handleEnterKey = (event) => {
    const key = event.key
    if (key === 'Enter' && this.state.searchText) this.findPlayer()
  }

  filterSearchText = (searchText) => {
    // Regex patterns
    const emailCheckPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    const gitCheckPattern = /^[\d{6}]+-\d{3}$/
    const stringContainsNumber = /\d/

    const isValidNumber = isValidPhoneNumber(searchText)
    const isValidEmail = emailCheckPattern.test(searchText)
    const isValidGIT = gitCheckPattern.test(searchText)
    const isValidCDH = isCdhNumberFormat(searchText)
    const isSearchTextContainsNumber = stringContainsNumber.test(searchText)

    // Detect what user is searching for
    if (isValidNumber) return '?limit=1&page=1&phone=' + encodeURIComponent(searchText)
    if (isValidEmail) return `?limit=1&page=1&email=${searchText}`
    if (isValidGIT || isValidCDH) return `?limit=1&page=1&golfId=${searchText}`

    if (!isSearchTextContainsNumber && !searchText.includes('@')) {
      return `?limit=5&page=1&search=${searchText}`
    }
  }

  findPlayer = async () => {
    const { searchText } = this.state
    const { isSuperAdmin, token, selectedClubId, getPlayers } = this.props

    this.setState({ isLoading: true })
    if (!searchText) return
    const filteredSearchText = this.filterSearchText(searchText)

    /**
     * CHANGE: 06.03.2024
     *
     * Removed /api/federation/import endpoint and added golfId parameter to search endpoint to get user with golf id
     *
     * NOTE: Now player could be searched with federationMembership, phone, email and golfId parameters
     *
     * NOTE: /api/federation/import endpoint creates user if user is not exist and that created user after deleting
     * which coused an issue
     */

    let query = filteredSearchText
    if (!isSuperAdmin) {
      query = `?relatedGolfClub=${selectedClubId}&golfClub=${selectedClubId}&limit=15&page=1&search=${searchText}`
    }

    getPlayers(token, query)
      .then((response) => {
        this.formatPlayers(response['hydra:member'])
      })
      .catch(() => {
        this.setState({ isLoading: false })
        this.formatPlayers([])
      })
  }

  formatPlayers = (fetchedPlayers) => {
    const { isSuperAdmin, selectedClubId, lang } = this.props

    let players = []
    if (fetchedPlayers.length > 0 && !isSuperAdmin)
      fetchedPlayers = fetchedPlayers.filter(
        (x) => x.home_club && x.home_club.id === selectedClubId
      )

    if (!fetchedPlayers.length) {
      players.push({
        id: 0,
        name: lang === 'en' ? 'No player found' : 'Ingen spelare hittades',
      })
    } else {
      players = PlayersHelpers.formatPlayers(fetchedPlayers, selectedClubId)
    }

    this.setState({ tableValues: players, isLoading: false })
  }

  onPlayerClick = async (player) => {
    if (!player || player.id === 0) return
    const { getPlayerMembership, getFederationProfile } = this.props

    const [federationId, err] = await _getFederationId(this.props.hasCdhNumber ? 'cdh' : 'git')
    const promises = [getPlayerMembership(player.id)]
    if (!err && federationId && federationId.length) {
      promises.push(getFederationProfile(player.id, federationId[0].id))
    }
    Promise.all(promises).then((values) => {
      this.setState({
        selectedPlayer: { ...player, federationInfo: values[1] },
        memberships: values[0],
      })
    })
  }

  getUsersHeaders() {
    const usersHeaders = {}

    for (let key in headers) {
      if (this.props.hasCdhNumber && key === 'golfId') {
        usersHeaders.cdh_id = {
          name: '.cdhNumber',
          type: 'label',
          valueType: 'string',
          width: '150px',
          selected: true,
          isSortable: true,
        }
      } else {
        usersHeaders[key] = { ...headers[key] }
      }
    }

    return usersHeaders
  }

  updateUser = (payload) => {
    this.setState({ isLoading: true })

    this.props
      .updateUser(payload)
      .then(() => {
        this.findPlayer()
        this.setState(
          {
            selectedPlayer: null,
          },
          this._modifyUser.resetValues
        )
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { isSuperAdmin, token, lang, deleteUser, onClickUnlink } = this.props

    return (
      <div className={style.container}>
        <div className={style.sideText} style={{ paddingTop: '200px' }}>
          <Text textId={'settings.users.users'} />
          <Text textId="edit" />
          {isSuperAdmin && <Text textId="remove" />}
        </div>
        <div className={style.mainContent}>
          <div className={style.searchInput}>
            <SearchInput
              placeholder={
                this.props.hasCdhNumber ? '.searchByPhoneCdhName' : '.searchByPhoneIDName'
              }
              onChange={(input) => this.handleChange(input)}
              autoFocus
            />
            <PulseLoader showIf={this.state.isLoading} />
          </div>
          <div className={style.usersTable}>
            <Table
              headers={this.getUsersHeaders()}
              values={this.state.tableValues}
              defaultSort="name"
              fillHeight
              fullWidth
              onRowClick={this.onPlayerClick}
            />
          </div>
          <div className={style.editForm}>
            <ModifyUser
              ref={(el) => (this._modifyUser = el)}
              token={token}
              lang={lang}
              user={this.state.selectedPlayer}
              updateUser={this.updateUser}
              onClickUnlink={onClickUnlink}
              memberships={this.state.memberships}
              isSuperAdmin={isSuperAdmin}
              hasCdhNumber={this.props.hasCdhNumber}
            />
            <br />
            {isSuperAdmin && (
              <DeleteUser
                token={token}
                lang={lang}
                user={this.state.selectedPlayer}
                deleteUser={deleteUser}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

UsersTable.propTypes = {
  hasCdhNumber: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  selectedClubId: PropTypes.number.isRequired,
  getPlayers: PropTypes.func.isRequired,
  getPlayerMembership: PropTypes.func.isRequired,
  getFederationProfile: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  onClickUnlink: PropTypes.func.isRequired,
}
