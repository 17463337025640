import React, { useState, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import m from 'moment'

import BasicBox from '@sweetspot/sweetspot-js/common/components/BasicBox'
import SimpleGrid from '@sweetspot/sweetspot-js/common/components/SimpleGrid'
import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import Button from '@sweetspot/sweetspot-js/common/components/Button'

import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { priceToLocal } from '@sweetspot/shared/util/functions'

import { downloadReceiptAsPDF } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'

import { ReactComponent as DownloadIcon } from '@sweetspot/sweetspot-js/assets/svgs/download-icon.svg'

import styles from './styles.module.scss'
import { getLatestUnpaidPayment } from '../../functions/utils'

const BookingPaymentsTable = ({ booking, className }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [receiptId, setReceiptId] = useState(0)

  const downloadEl = useRef(null)

  const showPayment = (payment) => {
    if (payment?.state === 'failed' || !getMethod(payment)) {
      return false
    }
    return true
  }

  const showReceiptButton = (payment) => {
    if (payment?.details?.creditCard?.last4 || payment?.credit_card?.last_four) {
      if (payment?.state === 'completed') {
        return true
      }
    }

    if (payment?.payment_link) {
      if (payment?.state === 'completed') {
        return true
      }
    }

    return false
  }

  const getMethod = (payment) => {
    if (payment?.details?.creditCard?.last4 || payment?.credit_card?.last_four) {
      if (['mc_mobilepay'].includes(payment?.credit_card?.brand))
        return {
          label: t('sentences.mobilePay'),
          code: 'card',
        }
      const last4 = payment?.details?.creditCard?.last4 ?? payment?.credit_card?.last_four
      return {
        label: `${t('words.app')} / ${t('words.card')} ..${last4}`,
        code: 'card',
      }
    }

    if (
      ['braintree_google_pay_sandbox', 'braintree_google_pay', 'google_pay'].includes(
        payment?.method?.code
      )
    ) {
      return {
        label: t('sentences.googlePay'),
        code: 'card',
      }
    }

    if (
      ['braintree_apple_pay_sandbox', 'braintree_apple_pay', 'apple_pay'].includes(
        payment?.method?.code
      )
    ) {
      return {
        label: t('sentences.applePay'),
        code: 'card',
      }
    }

    if (payment?.method?.code === 'on_site') {
      return {
        label: t('sentences.payOnSite'),
        code: 'on_site',
      }
    }

    if (payment?.method?.code === 'credit') {
      return {
        label: t('sentences.creditinvoice'),
        code: 'credit',
      }
    }

    if (
      (payment?.method?.code === 'adyen_card_sandbox' || payment?.method?.code === 'adyen_card') &&
      payment?.payment_link
    ) {
      return null
      // return {
      //   label: t('paymentLinks.paymentLink'),
      //   code: 'card',
      // }
    }

    return null
  }

  const getDate = (payment) => {
    if (payment?.details?.createdAt) {
      return m(payment.details.createdAt).format('YYYY-MM-DD HH:mm')
    }
    if (payment?.completed_at) {
      return m(payment.completed_at).format('YYYY-MM-DD HH:mm')
    }
    if (payment?.payment_link?.created_at) {
      return m(payment?.payment_link?.created_at).format('YYYY-MM-DD HH:mm')
    }
    return ''
  }

  const getType = (payment) => {
    if (payment?.type === 'refund') {
      if (payment?.state === 'authorized') {
        return (
          <span className={cx(styles.typeText)}>
            {t('words.refund')} ({t('words.processing')})
          </span>
        )
      }
      return <span className={cx(styles.typeText)}>{t('words.refund')}</span>
    } else {
      if (payment?.state === 'completed') {
        return <span className={cx(styles.typeText)}>{t('words.payment_one')}</span>
      }
      if (payment?.state === 'new') {
        return <span className={cx(styles.typeText)}>{t('sentences.awaitingPayment')}</span>
      }
      if (payment?.state === 'failed') {
        return <span className={cx(styles.typeText)}>{t('sentences.failedPayment')}</span>
      }
    }

    return <span className={cx(styles.typeText)}>{t('words.payment_one')}</span>
  }

  const getPSPreference = (payment) => {
    if (payment?.psp_reference) {
      return payment?.psp_reference
    }
    return '-'
  }

  const getAmount = (payment) => {
    if (payment?.type === 'refund') {
      return (
        <span className={cx(styles.positive)}>
          {priceToLocal(-1 * payment.amount, payment.currency_code, true)}
        </span>
      )
    }
    return (
      <span className={cx(styles.positive)}>
        {priceToLocal(payment.amount, payment.currency_code, true)}
      </span>
    )
  }

  const getTotalPaidAmount = () => {
    let totalPaid = 0

    if (!booking.payments) return 0
    ;[...booking.payments, ...(booking.refund_payments || [])]
      .filter((payment) => {
        if (payment?.state === 'completed') return true
        if (payment?.state === 'authorized') return true
      })
      .forEach((payment) => {
        totalPaid = totalPaid + payment.amount
      })

    if (booking.refund_payments) {
      booking.refund_payments.forEach((payment) => {
        totalPaid = totalPaid - payment.amount
      })
    }

    return totalPaid
  }

  const getTotalPaid = () => {
    let totalPaid = getTotalPaidAmount()
    return (
      <React.Fragment>
        {t('sentences.totalPaid')}:&nbsp;&nbsp;&nbsp;
        <span className={cx(styles.amount, { [styles.positive]: totalPaid > 0 })}>
          {priceToLocal(totalPaid, booking?.currency_code, true)}
        </span>
      </React.Fragment>
    )
  }

  const getLeftToPay = () => {
    let leftToPay = getLatestUnpaidPayment(booking)?.amount || 0

    return (
      <React.Fragment>
        {t('sentences.leftToPay')}:&nbsp;&nbsp;&nbsp;
        <span className={cx(styles.amount, { [styles.negative]: leftToPay > 0 })}>
          {priceToLocal(leftToPay, booking?.currency_code, true)}
        </span>
      </React.Fragment>
    )
  }

  const handleDownloadReceipt = async ({ id, type, uuid }) => {
    setReceiptId(id)
    setLoading(true)
    const receiptType = type === 'payment' ? 'payments' : 'refund-payments'
    const identifier = type === 'payment' ? uuid : id
    let filename = 'Receipt.pdf'
    downloadReceiptAsPDF(identifier, receiptType)
      .then((res) => {
        if (res.headers?.get('Content-Disposition')) {
          filename = res.headers
            .get('Content-Disposition')
            .split('filename=')[1]
            .replaceAll('"', '')
        }
        return res.blob()
      })
      .then((blob) => {
        const link = downloadEl.current
        link.download = filename
        link.href = window.URL.createObjectURL(blob)
        link.click()
        link.href = ''
        addToast(t('sentences.receiptDownloaded'), { appearance: 'success' })
        setReceiptId(0)
        setLoading(false)
      })
      .catch(() => {
        addToast(t('sentences.couldNotDownloadReceipt'), { appearance: 'error' })
        setReceiptId(0)
        setLoading(false)
      })
  }

  return (
    <BasicBox
      title={t('words.payment_one')}
      styles={{
        container: cx(styles.box, className),
      }}
    >
      <div className={cx(styles.container)}>
        {!booking ? (
          <SimpleGrid wrapperClassName={cx(styles.gridWrapper, styles.loading)}>
            <div style={{ height: '25%', backgroundColor: '#FFFFFF' }}>
              <Skeleton width="100%" height="80%" />
            </div>
            <div style={{ height: '25%', backgroundColor: '#FFFFFF' }}>
              <Skeleton width="100%" height="80%" />
            </div>
            <div style={{ height: '25%', backgroundColor: '#FFFFFF' }}>
              <Skeleton width="100%" height="80%" />
            </div>
            <div style={{ height: '25%', backgroundColor: '#FFFFFF' }}>
              <Skeleton width="100%" height="80%" />
            </div>
            <div style={{ height: '25%', backgroundColor: '#FFFFFF' }}>
              <Skeleton width="100%" height="80%" />
            </div>
          </SimpleGrid>
        ) : (
          <React.Fragment>
            <SimpleGrid wrapperClassName={styles.gridWrapper}>
              <div className={cx('row-header', styles.paymentsHeader)}>
                <p className={cx(styles.method)}>{t('sentences.paymentMethod')}</p>
                <p className={cx(styles.date)}>{t('words.date')}</p>
                <p className={cx(styles.psp)}>{t('paymentLinks.pspReference')}</p>
                <p className={cx(styles.type)}>{t('words.type')}</p>
                <p className={cx(styles.amount, 'fixed-right')}>{t('words.amount')}</p>
              </div>
              {[
                ...(booking.payments?.map((p) => ({ ...p, type: 'payment' })).reverse() || []),
                ...(booking.refund_payments?.map((p) => ({ ...p, type: 'refund' })) || []),
              ]
                .filter((p) => showPayment(p))
                .map((payment) => {
                  return (
                    <div key={payment.id} className={cx(styles.row, 'row-body')}>
                      <p className={cx(styles.method)}>{getMethod(payment)?.label}</p>
                      <p className={cx(styles.date)}>{getDate(payment)}</p>
                      <p className={cx(styles.psp)}>{getPSPreference(payment)}</p>
                      <p className={cx(styles.type)}>{getType(payment)}</p>
                      {showReceiptButton(payment) && (
                        <p className={cx(styles.receipt)}>
                          <Button
                            theme="gray-outline"
                            loaderStyle="pulse"
                            loading={payment.id === receiptId}
                            disabled={loading}
                            onClick={() => handleDownloadReceipt(payment)}
                          >
                            <React.Fragment>
                              <DownloadIcon />
                              {t('words.receipt')}
                            </React.Fragment>
                          </Button>
                        </p>
                      )}
                      <p className={cx(styles.amount, 'fixed-right')}>{getAmount(payment)}</p>
                    </div>
                  )
                })}
            </SimpleGrid>
            <div className={cx(styles.totalPaidRow)}>
              <p className={cx(styles.totalPaid)}>{getTotalPaid()}</p>
            </div>
            <div className={cx(styles.leftToPayRow)}>
              <p className={cx(styles.leftToPay)}>{getLeftToPay()}</p>
            </div>
          </React.Fragment>
        )}
      </div>
      <a ref={downloadEl}></a>
    </BasicBox>
  )
}

BookingPaymentsTable.propTypes = {
  booking: PropTypes.object,
  className: PropTypes.string,
  canEdit: PropTypes.bool,
}

BookingPaymentsTable.defaultProps = {
  canEdit: true,
}

export default BookingPaymentsTable
