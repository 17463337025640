import React, { useState, useMemo, useContext, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { archivePolicy, createReservationPolicy, updatePolicy } from '../../services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import PolicyContext from '../../containers/ReservationPolicyManager/PoliciesContext'

import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { ReactComponent as ArchiveIcon } from '@sweetspot/sweetspot-js/assets/svgs/archive-icon.svg'

import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import ToggleSwitchControlled from '@sweetspot/sweetspot-js/common/components/ToggleSwitchControlled'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import RulesWrapper from '../RulesWrapper'
import CheckersWrapper from '../CheckersWrapper'
import { useToasts } from 'react-toast-notifications'

const TABS = {
  RULES: 'RULES',
  CHECKERS: 'CHECKERS',
}

const SinglePolicy = ({ membershipId, policy }) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { values, functions, actionStates } = useContext(PolicyContext)

  // States
  const [name, setName] = useState(policy?.id === 'new' ? '' : policy.name)
  const [nameError, setNameError] = useState('')
  const prevName = usePrevious(name)

  const [isActive, setIsActive] = useState(policy?.id === 'new' ? false : policy.is_active)
  const [isActiveError, setIsActiveError] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [currentTab, setCurrentTab] = useState(TABS.RULES)
  const [isArchived, setIsArchived] = useState(policy.status === 'archived' || false)
  const [hasBeenOpened, setHasBeenOpened] = useState(false)

  // Memos
  const isOpen = useMemo(() => {
    return values.openPolicies.includes(policy.id)
  }, [values.openPolicies, policy.id])

  const isNewPolicy = useMemo(() => {
    return policy.id === 'new'
  }, [policy.id])

  const hasNameChange = useMemo(() => {
    return policy.name !== name
  }, [policy.name, name])

  useEffect(() => {
    if (isOpen) {
      setHasBeenOpened(true)
    }
  }, [isOpen])

  // Functions
  const savePolicyName = async () => {
    if (name === prevName) return

    if (!name) {
      setNameError(t('sentences.policyNameMustBeLonger'))
      return
    }
    if (name.replace(/\s/g, '')?.length <= 0) {
      setNameError(t('sentences.invalidName'))
      return
    }

    if (isNewPolicy) {
      actionStates.setSavingNewPolicy(true)

      const [res, err] = await to(createReservationPolicy(membershipId, { name, is_active: false }))

      if (!res || err) {
        actionStates.setSavingNewPolicy(false)
        setNameError(t('sentences.invalidName'))
      } else {
        actionStates.setSavingNewPolicy(false)
        functions.replaceNewPolicy({ ...res, name })
      }
    } else {
      if (policy.name !== name) {
        actionStates.setSavingExistingPolicy(true)
        const [res, err] = await to(updatePolicy(policy.uuid, { name, is_active: false }))
        if (!res || err) {
          actionStates.setSavingExistingPolicy(false)
          setNameError(t('sentences.invalidName'))
          // empty
        } else {
          actionStates.setSavingExistingPolicy(false)
          functions.updatePolicy({ ...res, name })
        }
      }
    }
  }

  const activatePolicyFn = async () => {
    const [, err] = await to(updatePolicy(policy.uuid, { name, is_active: true }))

    if (err) {
      setIsActive(false)
      setIsActiveError(t('sentences.couldNotActivatePolicy'))
      setTimeout(() => {
        setIsActiveError(null)
      }, 3000)
    }
  }

  const deactivatePolicyFn = async () => {
    const [, err] = await to(updatePolicy(policy.uuid, { name, is_active: false }))

    if (err) {
      setIsActive(true)
      setIsActiveError(t('sentences.couldNotDeactivatePolicy'))
      setTimeout(() => {
        setIsActiveError(null)
      }, 3000)
    }
  }

  const archivePolicyFn = async () => {
    setShowConfirmation(false)
    setIsArchived(true)

    const [res, err] = await to(archivePolicy(policy.id))

    if (res) {
      functions.archivePolicy(policy.id)
    }

    if (err || !res) {
      setIsArchived(false)
      addToast(t('sentences.couldNotArchivePolicy'), { appearance: 'error' })
    }
  }

  return (
    <div className={cx(styles.container)}>
      {isNewPolicy && (
        <div className={cx(styles.header, styles.newHeader)}>
          <p className={cx(styles.headerTitle)}>{t('sentences.newPolicy')}</p>
          <ButtonBasic
            className={cx(styles.deleteButton)}
            text={t('words.delete')}
            onClick={functions.deleteNewPolicy}
            color="red"
          />
        </div>
      )}

      {!isNewPolicy && (
        <div className={cx(styles.header)}>
          <div
            className={cx(styles.leftSide)}
            onClick={() => functions.toggleOpenPolicy(policy.id)}
          >
            <div className={cx(styles.caretContainer)}>
              <CaretDown className={cx(styles.caretDown, isOpen && styles.isOpen)} />
            </div>
            <p className={cx(styles.headerTitle)}>{policy.name}</p>
          </div>
          <div className={cx(styles.rightSide)}>
            {!isArchived ? (
              <div className={cx(styles.toggleWrapper)}>
                <ToggleSwitchControlled
                  onCheck={activatePolicyFn}
                  onUncheck={deactivatePolicyFn}
                  checked={isActive}
                  textChecked={t('words.active')}
                  textUnchecked={t('words.inActive')}
                  onChange={(val) => setIsActive(val)}
                  className={cx(styles.toggleActiveSwitch)}
                  switchWidth="full"
                  width="full"
                  medium
                  error={isActiveError}
                />
              </div>
            ) : (
              <span className={cx(styles.archivedLabel)}>{t('words.archived')}</span>
            )}
          </div>
        </div>
      )}

      <div className={cx(styles.foldContainer, (isNewPolicy || isOpen) && styles.isOpen)}>
        {(isOpen || isNewPolicy || hasBeenOpened) && (
          <React.Fragment>
            <div className={cx(styles.topRow, isNewPolicy && styles.extraMargin)}>
              <TextInputField
                error={nameError}
                disabled={isArchived}
                placeholder={t('sentences.addName')}
                labelTwo={t('sentences.policyName') + (isNewPolicy ? ' *' : '')}
                value={name}
                onChange={(val) => {
                  setNameError('')
                  setName(val)
                }}
                containerWidth="third"
                onEnter={() => savePolicyName()}
                inputProps={{
                  autoFocus: isNewPolicy,
                }}
                showActionButtons={(isNewPolicy || hasNameChange) && !nameError}
                confirmLoading={actionStates.savingNewPolicy || actionStates.savingExistingPolicy}
                onConfirm={savePolicyName}
                showCancelButton={name?.length > 0 && hasNameChange}
                onCancel={() => setName(policy?.name || '')}
                confirmText={isNewPolicy ? 'words.create' : 'words.save'}
              />
            </div>

            {!isNewPolicy && (
              <div className={cx(styles.tabsContainer)}>
                <div className={cx(styles.tabsHeaders)}>
                  <div
                    className={cx(
                      styles.singleTab,
                      currentTab === TABS.RULES && !isNewPolicy && styles.currentTab
                    )}
                    onClick={() => !isNewPolicy && setCurrentTab(TABS.RULES)}
                  >
                    <p className={cx(styles.tabLabel)}>{t('words.rules')}</p>
                  </div>
                  <div
                    className={cx(
                      styles.singleTab,
                      currentTab === TABS.CHECKERS && !isNewPolicy && styles.currentTab
                    )}
                    onClick={() => !isNewPolicy && setCurrentTab(TABS.CHECKERS)}
                  >
                    <p className={cx(styles.tabLabel)}>{t('words.policyCheckers')}</p>
                  </div>
                </div>
                <div className={cx(styles.tabsHeaderLine)}></div>
              </div>
            )}

            {!isNewPolicy && (
              <React.Fragment>
                <div
                  className={cx(
                    styles.rulesCheckersWrapper,
                    styles.rulesWrapper,
                    currentTab === TABS.RULES && !isNewPolicy && styles.active
                  )}
                >
                  <RulesWrapper policy={policy} disabled={isArchived} />
                </div>
                <div
                  className={cx(
                    styles.rulesCheckersWrapper,
                    styles.CheckersWrapper,
                    currentTab === TABS.CHECKERS && !isNewPolicy && styles.active
                  )}
                >
                  <CheckersWrapper policy={policy} disabled={isArchived} />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>

      <ConfirmPopup
        visible={showConfirmation}
        titleIcon={ArchiveIcon}
        title={t('sentences.archivePolicy')}
        text={t('sentences.archivePolicyConfirmation', { policy: policy?.name })}
        acceptTheme="danger"
        rejectTextKey="words.cancel"
        acceptTextKey="words.archive"
        onReject={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onAccept={() => archivePolicyFn()}
      />
    </div>
  )
}

SinglePolicy.propTypes = {
  membershipId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  policy: PropTypes.object.isRequired,
}

SinglePolicy.defaultProps = {}

export default SinglePolicy
