export interface Range {
  id: string
  name: string
  external_reference: string
  bucket_configuration_id: string | null
  demand_model_id: string
  toptracer_site_id?: string
  organization_id: string
  price_model_id: string
  range_servant_site_id: string
}

export enum TrackingTech {
  TOPTRACER = 'toptracer',
  TRACKMAN = 'trackman',
  INRANGE = 'inrange',
  NONE = 'none',
}

export type BayType = {
  id: string
  bay_number: number
  drivingRangeUuid: string
  floor?: number
  roof: boolean | null
  tracking_technology?: TrackingTech | null
  stance: Stance
}

export type BayTypeV2 = {
  id: string
  bay_number: number
  floor?: number
  amenities: BayAmenities
  ball_tracking_technology: BallTrackingTechnology
  stance: Stance
}

export type BayAmenities = {
  infrared_heat?: boolean
  roof: boolean | null
}

export type BallTrackingTechnology = {
  api_details: { trms_bay_number?: string | null }
  display?: BayScreenDisplay | null
  screen_mode?: BayScreenMode | null
  tracking_provider?: TrackingTech | null
}

export enum BayScreenDisplay {
  PLAYER_DEVICE = 'player_device',
  TOPTRACER_SCREEN = 'toptracer_screen',
}

export enum RoofFilter {
  WithRoof = 'words.withRoof',
  WithoutRoof = 'words.withoutRoof',
}

export enum StanceFilter {
  RightAndLeftHanded = 'sentences.rightAndLeftHanded',
  RightHanded = 'sentences.rightHanded',
  LeftHanded = 'sentences.leftHanded',
}

export enum RangeFilterType {
  TECHNOLOGIES = 'technologies',
  FLOORS = 'floors',
  ROOFS = 'roofs',
  STANCES = 'stances',
}

export enum BayScreenMode {
  OPEN = 'open',
  LOCKED = 'locked',
}

export interface RangeFilters {
  [RangeFilterType.TECHNOLOGIES]: {
    [key: string]: boolean
  }
  [RangeFilterType.FLOORS]: {
    [key: number]: boolean
  }
  [RangeFilterType.ROOFS]: {
    [key: string]: boolean
  }
  [RangeFilterType.STANCES]: {
    [key: string]: boolean
  }
}

export type BayOption = {
  [key: string]: {
    name: string
    logo?: string
  }
}

export enum Stance {
  LEFT = 'left',
  RIGHT = 'right',
  BOTH = 'both',
}
