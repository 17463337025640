import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ToggleSwitch from '@sweetspot/club-portal-legacy/components/ToggleSwitch'

import { useTranslation } from 'react-i18next'
import { addGolfCourseToGolfClub } from '@sweetspot/club-portal-legacy/store/actions'

import style from './style.module.scss'
import COURSE_TYPES, {
  COURSE_TYPES_ARR,
} from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import {
  LAST_CANCELLATION_POINT,
  MINUTES_180_MS,
  MINUTES_5_MS,
  getNonConfirmedBookingDurationOptions,
  getUnpaidBookingDurationOptions,
} from '@sweetspot/sweetspot-js/common/constants/autoCancelSettingsOptions'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import Pill from '@sweetspot/sweetspot-js/common/components/Pill'
import { useMembership } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembership'
import ExpandingPills from '@sweetspot/club-portal-legacy/components/ExpandingPills'
import { useTimeZone } from '@sweetspot/sweetspot-js/common/react-query/hooks/useTimeZone'
import { getSingleClub } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
const TEETIME_SOURCE = [
  { id: 'git', name: 'GIT' },
  { id: 'sweetspot', name: 'Sweetspot' },
  { id: 'none', name: 'None' },
]

const BOOKING_STATUS = [
  { id: -1, name: 'Sweetspot' },
  { id: 1, name: 'Phone' },
  { id: 3, name: 'Automatic' },
  { id: 4, name: 'Automatic with phone' },
]

const DEFAULT_CANCEL_POINT = 3

const initialForm = {
  gitId: '',
  name: '',
  federation: 'none',
  description: '',
  displayDays: '',
  bookingInfo: '',
  customDescription: '',
  shortCustomDescription: '',
  customBookingInfo: '',
  shortCustomBookingInfo: '',
  importantBookingInfo: '',
  customBookingEmailInfo: '',
  active: true,
  useDynamicPricing: false,
  longitude: '',
  latitude: '',
  enablePayment: false,
  enableArrivalRegistration: true,
  enableARAfterSchedule: false,
  enableStubPlayers: false,
  enablePayOnSite: false,
  customPayOnSiteTitle: '',
  customPayOnSiteDesc: '',
  enableMembershipSignup: false,
  teeTimeStatus: 'sweetspot',
  allowedMemberships: [],
  bookingType: -1,
  type: COURSE_TYPES.COURSE.value,
  vat: 6,
  nonConfirmedBookingAutoCancellationEnabled: true,
  nonConfirmedBookingAutoCancellationDuration: MINUTES_5_MS,
  unpaidBookingAutoCancellationEnabled: false,
  unpaidBookingAutoCancellationDuration: MINUTES_180_MS,
  unpaidBookingAutoCancellationDays: '',
  lastCancelPoint: DEFAULT_CANCEL_POINT,
}

const CreateGolfcourse = ({ token, golfClubList, addGolfCourse, golfClub }) => {
  const [isLoading, setLoading] = useState(false)
  const [federationName, setFederationName] = useState(null)
  const [errorText, setErrorText] = useState('')
  const [selectedClub, setSelectedClub] = useState(null)
  const [form, setForm] = useState(initialForm)
  const [memberships, setMemberships] = useState([])
  const [
    isUnpaidBookingAutoCancellationDurationCustom,
    setUnpaidBookingAutoCancellationDurationCustom,
  ] = useState(false)
  const { timezones } = useTimeZone()
  const _timezoneSelect = useRef()

  const [durationErrors, setDurationErrors] = useMergeState({
    nonConfirmedBookingAutoCancellationDuration: '',
    unpaidBookingAutoCancellationDuration: '',
  })

  useEffect(() => {
    if (selectedClub?.id) {
      setLoading(true)

      getSingleClub(selectedClub?.id)
        .then((fetchedClub) => {
          const { cdh_id, git_id } = fetchedClub

          if (cdh_id || git_id) {
            if (cdh_id) setFederationName('cdh')

            if (git_id) setFederationName('git')
          } else {
            setFederationName(null)
          }

          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }, [selectedClub])

  const { t } = useTranslation()

  const lastCancelPointOptions = useMemo(() => {
    return LAST_CANCELLATION_POINT.map((option) => ({
      ...option,
      label: t(option.label.translation, option.label.options),
    }))
  }, [t])

  const checkForErrors = ({
    name,
    booking_type,
    tee_time_source,
    pay_on_site_title,
    pay_on_site_description,
    display_tee_time_days,
    vat,
  }) => {
    if (name?.trim() === '') return 'settings.nameRequired'
    if (booking_type === null) return 'settings.selectBookingType'
    if (tee_time_source === null) return 'settings.selectTeeTime'
    if (pay_on_site_title?.length > 24) return 'settings.payOnSiteTitleError'
    if (pay_on_site_description?.length > 160) return 'settings.payOnSiteDescError'
    if (display_tee_time_days !== null && display_tee_time_days < 0)
      return 'sentences.invalidDisplayTeeTimeDays'
    if (vat < 0 || vat > 100)
      return `${t('words.vat')}: ${t('errors.valueMustBeBetweenThese', { this: 0, that: 100 })}`
    return ''
  }

  const resetForm = () => {
    setForm(initialForm)
  }

  const handleChange = (name, value) => {
    setForm((form) => ({ ...form, [name]: value }))
  }

  const handleUnpaidBookingAutoCancellationChange = (value) => {
    const { unpaidBookingAutoCancellationDuration } = form
    if (!value) {
      setUnpaidBookingAutoCancellationDurationCustom(false)
    } else if (
      unpaidBookingAutoCancellationDuration === '' ||
      !UNPAID_BOOKING_DURATION_OPTIONS.find(
        (x) => x.value === unpaidBookingAutoCancellationDuration
      )
    ) {
      setUnpaidBookingAutoCancellationDurationCustom(true)
    }
    handleChange('unpaidBookingAutoCancellationEnabled', value)
  }

  const handleUnpaidBookingAutoCancellationDurationChange = (value) => {
    if (UNPAID_BOOKING_DURATION_OPTIONS.find((x) => x.value === value) && value !== '') {
      handleChange('unpaidBookingAutoCancellationDuration', value)
      handleChange('unpaidBookingAutoCancellationDays', '')
      setUnpaidBookingAutoCancellationDurationCustom(false)
      return
    }
    setUnpaidBookingAutoCancellationDurationCustom(true)
    handleChange('unpaidBookingAutoCancellationDuration', value)
  }

  const handelUnpaidBookingAutoCancellationDaysChange = (value) => {
    const durationValue = value.split('.')[0]
    if (+durationValue > 0 && +durationValue <= 90) {
      handleChange('unpaidBookingAutoCancellationDays', +durationValue)
      handleChange('unpaidBookingAutoCancellationDuration', '')
      return
    }
    handleChange('unpaidBookingAutoCancellationDays', '')
  }

  const { data: membershipData } = useMembership({
    clubId: selectedClub?.id,
    state: 'published',
    totalFee: 0,
    sortOrder: 'asc',
    isActive: true,
    enabled: true,
  })

  useEffect(() => {
    setMemberships(membershipData)
  }, [membershipData])

  useEffect(() => {
    const club = golfClubList.find((club) => club.id === golfClub.selectedId)
    if (!club) return
    setSelectedClub(club)
  }, [])

  const sortByName = (a, b) => {
    let aName = a.toUpperCase()
    let bName = b.toUpperCase()
    if (aName < bName) return -1
    if (aName === bName) return 0
    if (aName > bName) return 1
  }

  const handleMembershipChange = (id, action) => {
    // Add
    if (action === 'add') {
      let newMembers = [...form.allowedMemberships, memberships.find((item) => item.id === id)]
      setMemberships(memberships.filter((item) => item.id !== id))
      handleChange('allowedMemberships', newMembers)
    }
    // Remove
    else if (action === 'remove') {
      let tempMembership = form.allowedMemberships.find((item) => item.id === id)
      let tempMemberships = memberships.slice()
      tempMemberships.push(tempMembership)
      tempMemberships.sort((a, b) => sortByName(a.name, b.name))
      setMemberships(tempMemberships)
      handleChange(
        'allowedMemberships',
        form.allowedMemberships.filter((item) => item.id !== id)
      )
    }
  }

  const saveSettings = () => {
    const {
      gitId,
      name,
      federation,
      description,
      displayDays,
      bookingInfo,
      customBookingInfo,
      shortCustomBookingInfo,
      importantBookingInfo,
      customBookingEmailInfo,
      active,
      useDynamicPricing,
      longitude,
      latitude,
      teeTimeStatus,
      bookingType,
      enablePayment,
      enableArrivalRegistration,
      enableARAfterSchedule,
      enableStubPlayers,
      enablePayOnSite,
      timezone,
      enableMembershipSignup,
      allowedMemberships,
      customPayOnSiteTitle,
      customPayOnSiteDesc,
      type,
      vat,
      nonConfirmedBookingAutoCancellationEnabled,
      nonConfirmedBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationEnabled,
      unpaidBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationDays,
      lastCancelPoint,
    } = form
    const clubId = selectedClub.id

    const federationId =
      federationName !== 'null'
        ? {
            [federationName === 'git' ? 'git_id' : 'cdh_id']: gitId,
          }
        : {}

    let payload = {
      ...federationId,
      name,
      federation,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      description,
      display_tee_time_days: displayDays === '' ? null : parseInt(displayDays),
      booking_information: bookingInfo,
      custom_booking_information: customBookingInfo,
      custom_booking_information_short: shortCustomBookingInfo,
      important_booking_information: importantBookingInfo,
      custom_email_information: customBookingEmailInfo,
      is_active: active,
      is_use_dynamic_pricing: useDynamicPricing,
      tee_time_source: teeTimeStatus,
      booking_type: bookingType,
      is_can_pay: enablePayment,
      is_arrival_registration: enableArrivalRegistration,
      is_arrival_registration_after_schedule: enableARAfterSchedule,
      is_stub_players_enabled: enableStubPlayers,
      is_pay_on_site_enabled: enablePayOnSite,
      pay_on_site_title: enablePayOnSite ? customPayOnSiteTitle : '',
      pay_on_site_description: enablePayOnSite ? customPayOnSiteDesc : '',
      timezone: timezone,
      membership_sign_up_settings: {
        is_enabled: allowedMemberships?.length > 0 ? enableMembershipSignup : false,
        memberships: allowedMemberships.map((item) => {
          return item.uuid
        }),
      },
      type: type || COURSE_TYPES.COURSE.value,
      vat: parseFloat(vat),
      non_confirmed_booking_auto_cancellation_enabled: nonConfirmedBookingAutoCancellationEnabled,
      non_confirmed_booking_auto_cancellation_duration: nonConfirmedBookingAutoCancellationDuration,
      unpaid_booking_auto_cancellation_enabled: unpaidBookingAutoCancellationEnabled,
      unpaid_booking_auto_cancellation_duration:
        unpaidBookingAutoCancellationDuration !== '' ? unpaidBookingAutoCancellationDuration : null,
      unpaid_booking_auto_cancellation_days:
        unpaidBookingAutoCancellationDays !== '' ? unpaidBookingAutoCancellationDays : null,
      booking_cancellation_limit_hours: lastCancelPoint,
    }
    let errorText = checkForErrors(payload)

    if (errorText) {
      setErrorText(errorText)
    } else {
      setLoading(true)
      addGolfCourse(token, clubId, payload)
        .then(() => {
          resetForm()
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err?.violations) {
            err.violations.forEach((err) => {
              if (err?.propertyPath === 'non_confirmed_booking_auto_cancellation_duration') {
                setDurationErrors({
                  non_confirmed_booking_auto_cancellation_duration: t('errors.fieldInvalid'),
                })
              } else if (err?.propertyPath === 'unpaid_booking_auto_cancellation_duration') {
                setDurationErrors({
                  unpaid_booking_auto_cancellation_duration: t('errors.fieldInvalid'),
                })
              }
            })
          }
        })
    }
  }

  const formatedTimeZones = useMemo(() => {
    if (timezones) {
      const filteredData = timezones.map((zone) => {
        const offset = Math.floor(zone.offset / 3600)
        const offsetString = offset > 0 ? `+${offset}` : offset
        return {
          id: zone.timezone_id,
          name: `(UTC ${offsetString}) ${zone.timezone_id}`,
          ...zone,
        }
      })
      return filteredData
    }
    return []
  }, [timezones])

  const NON_CONFIRMED_BOOKING_DURATION_OPTIONS = getNonConfirmedBookingDurationOptions()
  const UNPAID_BOOKING_DURATION_OPTIONS = getUnpaidBookingDurationOptions()

  const renderForm = () => {
    const {
      gitId,
      name,
      description,
      displayDays,
      bookingInfo,
      customBookingInfo,
      shortCustomBookingInfo,
      importantBookingInfo,
      customBookingEmailInfo,
      active,
      useDynamicPricing,
      longitude,
      latitude,
      enablePayment,
      enableArrivalRegistration,
      enableARAfterSchedule,
      enableStubPlayers,
      enablePayOnSite,
      customPayOnSiteTitle,
      customPayOnSiteDesc,
      timezone,
      enableMembershipSignup,
      allowedMemberships,
      type,
      vat,
      nonConfirmedBookingAutoCancellationEnabled,
      nonConfirmedBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationEnabled,
      unpaidBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationDays,
      lastCancelPoint,
    } = form

    const renderAllowedMemberships = () => {
      return (
        <>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.allowSignupDuringBooking')}</label>
            <ToggleSwitch
              checked={enableMembershipSignup}
              onChange={(value) => {
                handleChange('enableMembershipSignup', value)
              }}
            />
          </div>
          {enableMembershipSignup && (
            <div className="ss-form-group">
              <label className="ss-label">
                {t('settings.allowedMembership')}
                <InfoHover textId="settings.infoHoversGolfcourse.allowedMembership" />
              </label>
              <DropdownSelect
                showBorder
                width="100%"
                placeholder="select"
                initialId={null}
                values={memberships || []}
                onSelect={(value) => handleMembershipChange(value, 'add')}
              />
              <ExpandingPills>
                {allowedMemberships?.map((item) => (
                  <Pill
                    key={item.id}
                    text={item.name}
                    useCloseButton={true}
                    onClose={() => handleMembershipChange(item.id, 'remove')}
                  />
                ))}
              </ExpandingPills>
            </div>
          )}
        </>
      )
    }

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <TextInputField
          label={t('settings.name')}
          infoTextKey="settings.infoHoversGolfcourse.name"
          disabled={isLoading}
          value={name}
          onChange={(value) => handleChange('name', value)}
        />
        <div className="selects">{renderGolfClubSelect()}</div>

        <TextInputOptionsSelect
          label={t('words.type')}
          infoTextKey="sentences.courseTypeInfoDescription"
          disabled={isLoading}
          value={t(COURSE_TYPES_ARR.find((x) => x.value === type).labelKey)}
          options={COURSE_TYPES_ARR.filter((rawType) => rawType !== COURSE_TYPES.DRIVING_RANGE).map(
            (rawType, _i) => ({
              label: t(rawType.labelKey),
              value: rawType.value,
              id: _i,
            })
          )}
          onOptionSelect={(newType) => handleChange('type', newType.value)}
          containerClassName={style.inputOptionsSelect}
        />
        {type === 'course' && federationName && (
          <TextInputField
            label={t('Course ID')}
            infoTextKey="settings.infoHoversGolfcourse.id"
            disabled={isLoading}
            value={gitId}
            onChange={(value) => handleChange('gitId', value)}
          />
        )}

        <TextInputField
          label={t('settings.description')}
          infoTextKey="settings.infoHoversGolfcourse.description"
          disabled={isLoading}
          value={description}
          onChange={(value) => handleChange('description', value)}
        />
        <TextInputField
          label={t('settings.displayDays')}
          infoTextKey="settings.infoHoversGolfcourse.displayDays"
          disabled={isLoading}
          value={displayDays}
          onChange={(value) => handleChange('displayDays', value)}
        />
        <TextInputField
          label={t('settings.bookingInfo')}
          infoTextKey="settings.infoHoversGolfcourse.bookingInfo"
          type="text-area"
          rows={4}
          disabled={isLoading}
          value={bookingInfo}
          onChange={(value) => handleChange('bookingInfo', value)}
        />
        <TextInputField
          label={t('settings.customBooking')}
          infoTextKey="settings.infoHoversGolfcourse.customBookingInfo"
          type="text-area"
          rows={4}
          disabled={isLoading}
          value={customBookingInfo}
          onChange={(value) => handleChange('customBookingInfo', value)}
        />
        <TextInputField
          label={t('settings.shortCustomBooking')}
          infoTextKey="settings.infoHoversGolfcourse.shortCustomBookingInfo"
          type="text-area"
          rows={2}
          disabled={isLoading}
          value={shortCustomBookingInfo}
          onChange={(value) => handleChange('shortCustomBookingInfo', value)}
        />
        <TextInputField
          label={t('settings.importantBookingInfo')}
          infoTextKey="settings.infoHoversGolfcourse.importantBookingInfo"
          type="text-area"
          rows={4}
          disabled={isLoading}
          value={importantBookingInfo}
          onChange={(value) => handleChange('importantBookingInfo', value)}
        />
        <TextInputField
          label={t('settings.customBookingEmailInfo')}
          infoTextKey="settings.infoHoversGolfcourse.customBookingEmailInfo"
          type="text-area"
          rows={4}
          disabled={isLoading}
          value={customBookingEmailInfo}
          onChange={(value) => handleChange('customBookingEmailInfo', value)}
        />
        <div className="ss-form-group">
          <label className="ss-label">
            {t('words.timezone')}
            <InfoHover textId="sentences.timeZoneSelectDetails" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={timezone ?? 'Europe/Stockholm'}
            ref={_timezoneSelect}
            values={formatedTimeZones}
            onSelect={(value) => handleChange('timezone', value)}
          />
        </div>
        <TextInputField
          label={t('settings.latitude')}
          infoTextKey="settings.infoHoversGolfcourse.latitude"
          disabled={isLoading}
          value={latitude}
          onChange={(value) => handleChange('latitude', value)}
        />
        <TextInputField
          label={t('settings.longitude')}
          infoTextKey="settings.infoHoversGolfcourse.longitude"
          disabled={isLoading}
          value={longitude}
          onChange={(value) => handleChange('longitude', value)}
        />
        <TextInputField
          label={t('words.vat')}
          disabled={isLoading}
          value={vat}
          onChange={(value) => handleChange('vat', value)}
        />
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.active')}
            <InfoHover textId="settings.infoHoversGolfcourse.active" />
          </label>
          <ToggleSwitch checked={active} onChange={(value) => handleChange('active', value)} />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.useDynamicPricing')}
            <InfoHover textId="settings.infoHoversGolfcourse.dynamicPricing" />
          </label>
          <ToggleSwitch
            checked={useDynamicPricing}
            onChange={(value) => handleChange('useDynamicPricing', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enablePayment')}
            <InfoHover textId="settings.infoHoversGolfcourse.enablePayment" />
          </label>
          <ToggleSwitch
            checked={enablePayment}
            onChange={(value) => handleChange('enablePayment', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableArrivalRegistration')}
            <InfoHover textId="settings.infoHoversGolfcourse.enableArrivalRegistration" />
          </label>
          <ToggleSwitch
            checked={enableArrivalRegistration}
            onChange={(value) => handleChange('enableArrivalRegistration', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableArrivalRegistrationAfterSchedule')}
            <InfoHover textId="settings.infoHoversGolfcourse.enableArrivalRegistrationAfterSchedule" />
          </label>
          <ToggleSwitch
            checked={enableARAfterSchedule}
            onChange={(value) => handleChange('enableARAfterSchedule', value)}
          />
        </div>

        <div className="ss-form-group">
          <label className="ss-label">
            {t('golfcourseForm.labels.lastCancelPoint')}
            <InfoHover textId="golfcourseForm.hovers.lastCancelPoint" />
          </label>
          <TextInputOptionsSelect
            disabled={isLoading}
            value={
              lastCancelPointOptions.find((x) => x.value === lastCancelPoint)?.label ||
              DEFAULT_CANCEL_POINT
            }
            options={lastCancelPointOptions}
            onOptionSelect={(newPoint) => {
              handleChange('lastCancelPoint', newPoint.value)
            }}
            containerClassName={style.inputOptionsSelect}
          />
        </div>

        <div className="ss-form-group">
          <label className="ss-label">
            {t('sentences.enableAutocancelOnNonConfirmedBookings')}
          </label>
          <ToggleSwitch
            checked={nonConfirmedBookingAutoCancellationEnabled}
            onChange={(value) => handleChange('nonConfirmedBookingAutoCancellationEnabled', value)}
          />
        </div>

        {nonConfirmedBookingAutoCancellationEnabled && (
          <TextInputOptionsSelect
            label={t('sentences.nonConfirmedBookingAutoCancellationTime')}
            disabled={isLoading}
            value={
              NON_CONFIRMED_BOOKING_DURATION_OPTIONS.find(
                (x) => x.value === nonConfirmedBookingAutoCancellationDuration
              )?.label || ''
            }
            error={durationErrors?.non_confirmed_booking_auto_cancellation_duration}
            options={NON_CONFIRMED_BOOKING_DURATION_OPTIONS}
            onOptionSelect={(newDuration) => {
              setDurationErrors({ non_confirmed_booking_auto_cancellation_duration: '' })
              handleChange('nonConfirmedBookingAutoCancellationDuration', newDuration.value)
            }}
            containerClassName={style.inputOptionsSelect}
          />
        )}

        <div className="ss-form-group">
          <label className="ss-label">
            {t('sentences.enableAutocancelOnUnpaidBookingsFromApp')}
          </label>
          <ToggleSwitch
            checked={unpaidBookingAutoCancellationEnabled}
            onChange={(value) => handleUnpaidBookingAutoCancellationChange(value)}
          />
        </div>

        {unpaidBookingAutoCancellationEnabled && (
          <TextInputOptionsSelect
            label={t('sentences.unpaidBookingAutoCancellationTimeFromApp')}
            disabled={isLoading}
            value={
              UNPAID_BOOKING_DURATION_OPTIONS.find(
                (x) => x.value === unpaidBookingAutoCancellationDuration
              )?.label || t('dateTime.words.custom')
            }
            error={durationErrors?.unpaid_booking_auto_cancellation_duration}
            options={UNPAID_BOOKING_DURATION_OPTIONS}
            onOptionSelect={(newDuration) => {
              setDurationErrors({ unpaid_booking_auto_cancellation_duration: '' })
              handleUnpaidBookingAutoCancellationDurationChange(newDuration.value)
            }}
            containerClassName={style.inputOptionsSelect}
          />
        )}

        {isUnpaidBookingAutoCancellationDurationCustom && (
          <div className="ss-form-group">
            <TextInputField
              value={+unpaidBookingAutoCancellationDays || ''}
              label={t('sentences.unpaidBookingCustomAutoCancellationTimeLabel')}
              type="number"
              onChange={(value) => handelUnpaidBookingAutoCancellationDaysChange(value)}
            />
          </div>
        )}

        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableStubPlayers')}
            <InfoHover textId="settings.infoHoversGolfcourse.enableStubPlayers" />
          </label>
          <ToggleSwitch
            checked={enableStubPlayers}
            onChange={(value) => handleChange('enableStubPlayers', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enablePayOnSite')}
            <InfoHover textId="settings.infoHoversGolfcourse.enablePayOnSite" />
          </label>
          <ToggleSwitch
            checked={enablePayOnSite}
            onChange={(value) => handleChange('enablePayOnSite', value)}
          />
        </div>
        {enablePayOnSite && (
          <div className={style.customPayOnSite}>
            <TextInputField
              label={t('settings.customPayOnSiteTitle')}
              disabled={isLoading}
              error={customPayOnSiteTitle?.length > 24 ? t('settings.payOnSiteTitleError') : ''}
              value={customPayOnSiteTitle}
              onChange={(value) => handleChange('customPayOnSiteTitle', value)}
            />
            <TextInputField
              rows={4}
              type="text-area"
              label={t('settings.customPayOnSiteDesc')}
              disabled={isLoading}
              error={customPayOnSiteDesc?.length > 160 ? t('settings.payOnSiteDescError') : ''}
              value={customPayOnSiteDesc}
              onChange={(value) => handleChange('customPayOnSiteDesc', value)}
            />
          </div>
        )}
        {renderAllowedMemberships()}
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.teeTimeSource')}
            <InfoHover textId="settings.infoHoversGolfcourse.teeTimeSource" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={'sweetspot'}
            values={TEETIME_SOURCE}
            onSelect={(value) => handleChange('allowed', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.bookingType')}
            <InfoHover textId="settings.infoHoversGolfcourse.bookingType" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={-1}
            values={BOOKING_STATUS}
            onSelect={(value) => handleChange('bookingType', value)}
          />
        </div>
        <div className={style.formButtons}>
          <button
            className="system-button primary-outline md-32"
            disabled={isLoading}
            onClick={resetForm}
          >
            {t('settings.cancel')}
          </button>
          <button
            className="system-button info-outline md-32"
            disabled={isLoading}
            onClick={saveSettings}
          >
            {t('save')}
          </button>
        </div>
      </form>
    )
  }

  const renderGolfClubSelect = () => {
    return (
      <div>
        <div className="ss-form-group">
          <label className="ss-label">{t('golfclub')}</label>
          <DropdownSelect
            showBorder
            initialId={golfClub.selectedId}
            width="100%"
            values={golfClubList}
            onSelect={(id) => {
              const club = golfClubList.find((item) => item.id === id)
              if (club) setSelectedClub({ id, name: club.name })
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={style.wrapper}>
      <div className={style.title}>
        <Text textId="settings.createGolfcourse" />
        <div>
          <PulseLoader fillHeight showIf={isLoading} />
        </div>
      </div>
      <div>
        {renderForm()}
        <Alert showIf={!!errorText} onClick={() => setErrorText('')}>
          <Text textId={errorText} />
        </Alert>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    golfClub: state.golfClub,
    golfClubList: state.golfClub.list,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addGolfCourse: (token, clubId, payload) =>
      dispatch(addGolfCourseToGolfClub(token, clubId, payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGolfcourse)

CreateGolfcourse.propTypes = {
  token: PropTypes.string.isRequired,
  golfClub: PropTypes.object,
  golfClubList: PropTypes.array.isRequired,
  addGolfCourse: PropTypes.func.isRequired,
}
