import React from 'react'
import PropTypes from 'prop-types'

import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'

const PlayRightResult = (props) => {
  const selectedGolfClub = props.golfClub.list.find((club) => club.id === props.golfClub.selectedId)

  const succeedMessage =
    props.succeedGolfIds > 1
      ? 'settings.playRights.updatePlayerRightsSuccess'
      : 'settings.playRights.updatePlayerRightSuccess'

  const failedMessage =
    props.succeedGolfIds > 1
      ? 'settings.playRights.updatePlayerRightsError'
      : 'settings.playRights.updatePlayerRightError'

  return (
    <OverlayContainer>
      <div className={style.container}>
        <Text
          textId={succeedMessage}
          prepend={props.succeedGolfIds}
          append={selectedGolfClub.name}
        />

        {!!props.failedGolfIds.length && (
          <React.Fragment>
            <Text textId={failedMessage} prepend={props.failedGolfIds.length} />
            <textarea className="ss-input" rows="4" defaultValue={props.failedGolfIds.join('\n')} />
          </React.Fragment>
        )}

        <div className={style.buttons}>
          <div onClick={props.onClick}>
            <Text textId="ok" />
          </div>
        </div>
      </div>
    </OverlayContainer>
  )
}

PlayRightResult.propTypes = {
  succeedGolfIds: PropTypes.number.isRequired,
  failedGolfIds: PropTypes.array.isRequired,
  golfClub: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PlayRightResult
