import { getCourseById } from '@sweetspot/shared/data-access/api-platform'
import { queryTeeTimes } from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'

const LIMIT = 500

const getTeeTimes = async (course, week, space, timePeriod) => {
  if (!week || week.id === -1) {
    return []
  }

  if (!course?.uuid && course?.id) {
    course = getCourseById(course.id)
  }

  const query = {
    limit: LIMIT,
    'course.uuid': course.uuid,
    'from[before]': week.toDate.toISOString(),
    'from[after]': week.fromDate.toISOString(),
    'order[from]': 'asc',
  }

  if (space && space !== -1) {
    query['space.uuid'] = space
  }

  async function loop(page, results = []) {
    query.page = page
    const res = await queryTeeTimes(query)
    results.push(...res)
    if (res.length < LIMIT) {
      return results
    }
    return loop(++page, results)
  }

  const teeTimes = await loop(1)

  return teeTimes
}

export default getTeeTimes
