import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

import i18next from 'i18next'
import { utcToZonedTime, format } from 'date-fns-tz'

import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import { ReactComponent as TimeIcon } from '@sweetspot/club-portal-legacy/resources/images/schedule-button.svg'
import { useStyles } from '@sweetspot/sweetspot-js/common/hooks/useStyles'

export const TimezoneInfo = (props) => {
  const cx = useStyles(props.styles)
  const timezone = React.useMemo(() => {
    const date = utcToZonedTime(new Date().toISOString(), props.timezone)
    const tz = {
      timeZone: props.timezone,
    }
    return `${i18next.t('sentences.localTimeAtVenue')} ${format(date, 'HH:mm', tz)} \n (${format(
      date,
      'zzz',
      tz
    )}) ${props.timezone}`
  }, [props.timezone])
  if (!props.timezone) return null
  return (
    <InfoHover
      text={timezone}
      containerClassName={cx(styles.container)}
      toolTipIcon={<TimeIcon />}
      arrowPosition={18}
    />
  )
}

TimezoneInfo.propTypes = {
  timezone: PropTypes.string,
  style: PropTypes.object,
}
