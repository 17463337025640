import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useEffect, useState } from 'react'
import _isEqual from 'lodash/isEqual'
import { Range } from '@sweetspot/shared/types'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'

const useRanges = () => {
  const currentClubUuid = useSelector((state: RootState) => {
    if (state?.golfClub?.selectedId && state?.golfClub?.list?.length) {
      const selectedGolfClub = state.golfClub.list.find(
        (club) => club.id === state.golfClub.selectedId
      )
      if (selectedGolfClub) {
        return selectedGolfClub.uuid
      }
    }
  })
  const [selectedRange, setSelectedRange] = useState<Range | undefined>()

  const {
    data,
    isLoading: areRangesLoading,
    refetch,
  } = useQuery(
    [CLUB_QUERIES.RANGES, currentClubUuid],
    () => queryRanges({ organizationId: currentClubUuid }),
    {
      enabled: !!currentClubUuid,
    }
  )

  // if ranges are updated, update the selected range if needed
  useEffect(() => {
    if (selectedRange) {
      const updatedRange = data?.ranges.find((range) => range.id === selectedRange.id)
      if (updatedRange && !_isEqual(selectedRange, updatedRange)) {
        setSelectedRange(updatedRange)
      }
    }
  }, [data, selectedRange])

  return {
    ranges: (data?.ranges as Range[]) || [],
    areRangesLoading,
    selectedRange,
    setSelectedRange,
    refetchRanges: refetch,
  }
}

export default useRanges
