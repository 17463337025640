import { useActivePeriod, useSelectedCourse } from '../context'
import TimePeriodDetails from './TimePeriodDetails'

const PeriodDetailsPage = ({ match }) => {
  const [activePeriod] = useActivePeriod()
  const { selectedCourse } = useSelectedCourse()

  if (!activePeriod || activePeriod.id !== +match.params.id) return null

  return <TimePeriodDetails activePeriod={activePeriod} selectedCourse={selectedCourse} />
}

export default PeriodDetailsPage
