import * as _ from 'lodash'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import moment from 'moment'

const colorWhite = '#fff'
const baseColor = '#000000'
const minColor = '#EC8977'
const mainColor = '#74B1EE'
const demandColor = '#E8DD55'
const supplyColor = '#7CCCA9'

const commonStyle = {
  pointBorderColor: colorWhite,
  pointRadius: 4,
  responsive: true,
  fill: false,
}

export default class PriceHelper {
  static getPricePeriod(response) {
    const temp = []
    response
      // .filter(item => !item.default)
      .forEach((item) => {
        temp.push({
          id: item.id,
          name: item.name,
          fromDate: item.start_date,
          toDate: item.end_date,
          default: item.default,
          active: item.active,
          basePriceWork: item.price_segments[0].configuration.workday_price / 100,
          basePriceWeek: item.price_segments[0].configuration.weekend_price / 100,
          demandWork: item.price_segments[1].configuration.workday_demand / 100,
          demandWeek: item.price_segments[1].configuration.weekend_demand / 100,
          supplyWork: item.price_segments[2].configuration.workday_demand / 100,
          supplyWeek: item.price_segments[2].configuration.weekend_demand / 100,
          maxPriceWork:
            item.price_segments[0].configuration.workday_price / 100 +
            item.price_segments[1].configuration.workday_demand / 100 +
            item.price_segments[2].configuration.workday_demand / 100,
          maxPriceWeek:
            item.price_segments[0].configuration.weekend_price / 100 +
            item.price_segments[1].configuration.weekend_demand / 100 +
            item.price_segments[2].configuration.weekend_demand / 100,
          avgPriceWork: item.price_segments[3].configuration.workday_precision / 100,
          avgPriceWeek: item.price_segments[3].configuration.weekend_precision / 100,
          minPriceWork: item.price_segments[4].configuration.workday_min / 100,
          minPriceWeek: item.price_segments[4].configuration.weekend_min / 100,
        })
      })

    return temp
  }

  static formatForUpdate(period, periods) {
    const temp = !period.id ? periods : periods.filter((item) => item.id === period.id)[0]
    temp.name = period.name
    temp.is_active = true
    temp.is_default = period.default
    temp.start_date =
      typeof period.fromDate === 'string'
        ? period.fromDate
        : DateHelpers.toPHDateString(period.fromDate)
    temp.end_date =
      typeof period.toDate === 'string' ? period.toDate : DateHelpers.toPHDateString(period.toDate)

    const priceSegments = []

    if (
      temp.price_segments[0].configuration.workday_price !== period.basePriceWork * 100 ||
      temp.price_segments[0].configuration.weekend_price !== period.basePriceWeek * 100
    ) {
      priceSegments.push({
        ...temp.price_segments[0],
        configuration: {
          workday_price: period.basePriceWork * 100,
          weekend_price: period.basePriceWeek * 100,
        },
      })
    }

    if (
      temp.price_segments[1].configuration.workday_demand !== period.demandWork * 100 ||
      temp.price_segments[1].configuration.weekend_demand !== period.demandWeek * 100
    ) {
      priceSegments.push({
        ...temp.price_segments[1],
        configuration: {
          workday_demand: period.demandWork * 100,
          weekend_demand: period.demandWeek * 100,
        },
      })
    }

    if (
      temp.price_segments[2].configuration.workday_demand !== period.supplyWork * 100 ||
      temp.price_segments[2].configuration.weekend_demand !== period.supplyWeek * 100
    ) {
      priceSegments.push({
        ...temp.price_segments[2],
        configuration: {
          workday_demand: period.supplyWork * 100,
          weekend_demand: period.supplyWeek * 100,
        },
      })
    }

    if (
      temp.price_segments[3].configuration.workday_precision !== period.avgPriceWork * 100 ||
      temp.price_segments[3].configuration.weekend_precision !== period.avgPriceWork * 100
    ) {
      priceSegments.push({
        ...temp.price_segments[3],
        configuration: {
          workday_precision: period.avgPriceWork
            ? period.avgPriceWork * 100
            : temp.price_segments[3].configuration.workday_precision,
          weekend_precision: period.avgPriceWork
            ? period.avgPriceWork * 100
            : temp.price_segments[3].configuration.weekend_precision,
        },
      })
    }

    // if (period.avgPriceWeek)
    //   temp.price_segments[3].configuration.weekend_precision = period.avgPriceWeek * 100;

    if (
      temp.price_segments[4].configuration.workday_min !== period.minPriceWork * 100 ||
      temp.price_segments[4].configuration.weekend_min !== period.minPriceWeek * 100
    ) {
      priceSegments.push({
        ...temp.price_segments[4],
        configuration: {
          workday_min: period.minPriceWork
            ? period.minPriceWork * 100
            : temp.price_segments[4].configuration.workday_min,
          weekend_min: period.minPriceWeek
            ? period.minPriceWeek * 100
            : temp.price_segments[4].configuration.weekend_min,
        },
      })
    }

    return { pricePeriod: temp, priceSegments }
  }

  static formatForCreate(period) {
    const temp = {
      name: period.name,
      start_date:
        typeof period.fromDate === 'string'
          ? period.fromDate
          : DateHelpers.toPHDateString(period.fromDate),
      end_date:
        typeof period.toDate === 'string'
          ? period.toDate
          : DateHelpers.toPHDateString(period.toDate),
      workdays: [],
      weekends: [],
      overrides: [],
      default: true,
      active: false,
    }

    return temp
  }

  static getDefaultPriceSegments() {
    return [
      {
        segment_tag: 'base_price',
        priority: 0,
        configuration: {
          weekend_price: 0,
          workday_price: 0,
        },
      },
      {
        segment_tag: 'demand_historical',
        priority: 5,
        configuration: {
          weekend_demand: 500,
          workday_demand: 500,
        },
      },
      {
        segment_tag: 'demand_daily',
        priority: 10,
        configuration: {
          weekend_demand: 500,
          workday_demand: 500,
        },
      },
      {
        segment_tag: 'round_price',
        priority: 15,
        configuration: {
          weekend_precision: 500,
          workday_precision: 500,
        },
      },
      {
        segment_tag: 'min_price',
        priority: 20,
        configuration: {
          weekend_min: 1000,
          workday_min: 1000,
        },
      },
    ]
  }

  static getPeriodForCreate(index) {
    let startDate = new Date()
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    const startDateString = DateHelpers.toPHDateString(startDate)

    let endDate = new Date()
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)
    const endDateString = DateHelpers.toPHDateString(endDate)

    return {
      name: index === 0 ? 'Default' : `Period ${index}`,
      start_date: startDateString,
      end_date: endDateString,
      is_default: index === 0,
      is_active: false,
    }
  }

  static getTableData(response, courseTimeZone) {
    let temp = response.map((price) => ({
      price: price.price.amount / 100,
      start_time: moment.utc(price.from).tz(courseTimeZone).toDate(),
    }))

    const maxPrice = _(temp).orderBy('price', 'desc').value()[0].price
    const minPrice = _(temp).orderBy('price', 'asc').value()[0].price
    const average = _.meanBy(temp, 'price')
    const avgPrice = this.findClosest(temp, average).price
    const tableData = temp.map((item) => ({
      ...item,
      value: this.getValue(avgPrice, maxPrice, minPrice, item.price),
    }))

    return {
      tableData,
      maxPrice,
      avgPrice,
    }
  }

  static findClosest(array, x) {
    return array.reduce((best, current) => {
      return current.price >= x && (!best || current.price < best.price) ? current : best
    }, undefined)
  }

  static getValue(avg, max, min, price) {
    let index = 0
    if (price > avg) {
      index = price > (max + avg) / 2 ? 5 : 4
    } else {
      index = price < (min + avg) / 2 ? 1 : 2
    }
    switch (price) {
      case min:
        index = 0
        break
      case avg:
        index = 3
        break
      case max:
        index = 6
        break
      default:
        break
    }
    return index
  }

  static getPriceChartModuleValue(moduleName, defaultKey, isWeekend, priceModules) {
    const module = priceModules.find((mod) => _.camelCase(mod.module) === moduleName)
    if (module) {
      const key = `${isWeekend ? 'weekend' : 'workday'}_${defaultKey}`
      return module.settings[key] ? module.settings[key] / 100 : 0 // Handle null value
    }
    return 0
  }

  static generatePriceChartDataset(chartState) {
    return [
      {
        label: 'Price',
        backgroundColor: 'rgba(116, 177, 238, 0.4)',
        borderColor: mainColor,
        pointBackgroundColor: mainColor,
        data: chartState.price,
        ...commonStyle,
        fill: true,
      },
      {
        label: 'Minimum Price',
        borderColor: minColor,
        backgroundColor: minColor,
        pointBackgroundColor: minColor,
        data: chartState.minPrice,
        ...commonStyle,
      },
      {
        label: 'Base Price',
        borderColor: baseColor,
        backgroundColor: baseColor,
        pointBackgroundColor: baseColor,
        data: chartState.basePrice,
        ...commonStyle,
      },
      {
        label: 'Demand',
        borderColor: demandColor,
        backgroundColor: demandColor,
        pointBackgroundColor: demandColor,
        data: chartState.demandPrice,
        ...commonStyle,
      },
      {
        label: 'Supply',
        borderColor: supplyColor,
        backgroundColor: supplyColor,
        pointBackgroundColor: supplyColor,
        data: chartState.supplyPrice,
        ...commonStyle,
      },
    ]
  }
}
