import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import moment from 'moment'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { getPriceObject } from '@sweetspot/sweetspot-js/features/teeTimes/js/utils'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

const SimulatorsRangesBookHeader = ({
  selectedFrom,
  selectedTo,
  selectedSpaces,
  selectedTeeTimes,
  currentVenue,
  currentDate,
  className,
  onCancel,
  onBook,
  reservingBooking,
  isVisible,
  isBay,
}) => {
  const { t } = useTranslation()
  const [currencyCode] = useClubCurrency()

  const spacesString = useMemo(() => {
    if (selectedSpaces) {
      let string = ''

      let spacesByFloors = {}
      ;[...selectedSpaces]
        .sort((a, b) =>
          isBay ? (a.bay_number > b.bay_number ? 1 : -1) : a.name.localeCompare(b.name)
        )
        .forEach((space) => {
          const { floor } = space
          if (!spacesByFloors?.[floor]) spacesByFloors[floor] = []
          spacesByFloors[floor].push(space)
        })

      let sortedSpaces = Object.keys(spacesByFloors)
        .sort((a, b) => a - b)
        .map((key) => spacesByFloors[key])

      const numberOfFloors = sortedSpaces.length
      const lastFloorIndex = numberOfFloors - 1

      sortedSpaces.forEach((floor, index) => {
        const floorName = floor[0].floor

        if (index !== 0) string += ' '
        string += `${t('words.floor')} ${floorName}: `

        const numberOfSpaces = floor.length
        const lastSpaceIndex = numberOfSpaces - 1
        floor.forEach((space, index) => {
          string += isBay ? `${t('settings.bays.bay')} ${space.bay_number}` : `${space.name}`
          if (index !== lastSpaceIndex) string += ', '
        })
        if (index !== lastFloorIndex) string += ', '
      })

      return string
    }
    return ''
  }, [selectedSpaces, t, isBay])

  const totalPriceString = useMemo(() => {
    if (selectedTeeTimes) {
      let total = 0
      let currency = null
      selectedTeeTimes.forEach((teeTime) => {
        const priceObject = getPriceObject(teeTime, currentVenue?.is_use_dynamic_pricing)
        total += priceObject?.amount || 0

        if (!currency) currency = priceObject?.currency || currencyCode
      })

      return priceToLocal(total, currency, true)
    }
    return '-'
  }, [selectedTeeTimes])

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.visible]: isVisible,
        },
        className
      )}
    >
      <div className={cx(styles.infoContainer)}>
        <p className={cx(styles.venueName)}>{currentVenue?.name}</p>
        <div className={cx(styles.bookingInfoRow)}>
          <p className={cx(styles.text)}>
            <span className="material-icons md-16 md-dark">today</span>
            {currentDate &&
              currentVenue &&
              moment(currentDate).tz(currentVenue.timezone).format('YYYY-MM-DD')}
          </p>
          <p className={cx(styles.text, styles.blue)}>
            <span className="material-icons md-16 md-dark">schedule</span>
            {selectedFrom && selectedTo && currentVenue && (
              <React.Fragment>
                {moment.unix(selectedFrom).tz(currentVenue.timezone).format('HH:mm')}-
                {moment.unix(selectedTo).tz(currentVenue.timezone).format('HH:mm')}
              </React.Fragment>
            )}
          </p>
          <p className={cx(styles.text)}>
            <span className="material-icons md-16 md-dark">grid_view</span>
            {spacesString}
          </p>
          <p className={cx(styles.text)}>
            <span className="material-icons md-16 md-dark">payments</span>
            {totalPriceString}
          </p>
        </div>
      </div>
      <div className={cx(styles.controlsContainer)}>
        <button
          className={cx('system-button md-32 primary-outline', styles.cancelButton)}
          onClick={onCancel}
        >
          {t('words.cancel')}
        </button>
        <button className={cx('system-button md-32 primary')} onClick={onBook}>
          {reservingBooking ? <PulseLoader showIf={true} /> : t('words.book')}
        </button>
      </div>
    </div>
  )
}

SimulatorsRangesBookHeader.propTypes = {
  selectedFrom: PropTypes.number,
  selectedTo: PropTypes.number,
  selectedSpaces: PropTypes.array,
  selectedTeeTimes: PropTypes.array,
  currentVenue: PropTypes.object,
  currentDate: PropTypes.object,
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onBook: PropTypes.func,
  reservingBooking: PropTypes.bool,
}

SimulatorsRangesBookHeader.defaultProps = {
  className: '',
  onCancel: () => {},
  onBook: () => {},
  reservingBooking: false,
}

export default SimulatorsRangesBookHeader
