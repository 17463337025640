import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'

/**
 * This component creates a clickable cross icon. It is intended to be used to remove items
 * or close windows
 */
export default class RemoveButton extends Component {
  render() {
    let classNames = [style.container]
    if (this.props.className) classNames.push(this.props.className)

    return (
      <div
        className={classNames.join(' ')}
        onClick={() => {
          if (this.props.onClick) this.props.onClick()
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
    )
  }
}

RemoveButton.propTypes = {
  /**
   * Function to run when clicking on the button
   */
  onClick: PropTypes.func,
  /**
   * Additional class name for the component
   */
  className: PropTypes.string,
}
