import { TrackingTech } from '@sweetspot/shared/types'

export type DemandModel = {
  id: string
  demand_entries: DemandEntry[]
}

export type DemandEntry = {
  demand_level: string
  start_time: string
  end_time: string
  weekday: string
}

export type UpdateDemandModelPayload = {
  rangeId?: string
  demandModel: DemandModel
}

export type CreateDemandModelPayload = {
  rangeId?: string
  demand_entries: DemandEntry[]
}

export type PriceModel = {
  id: string
  price_tabs: PriceModelTab[]
}

export type PriceModelTab = {
  number_of_balls: number
  tracking_technology: TrackingTech
  price_entries: PriceEntry[]
}

export type PriceEntry = {
  price_in_cents: number
  start_time: string
  end_time: string
  weekday: string
  demand_level: DemandStatus
}

export enum DemandStatus {
  High = 'high',
  Medium = 'mid',
  Low = 'low',
  Closed = 'closed',
}
