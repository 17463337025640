import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { useHistory } from 'react-router'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import { ROLES_ARR, ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import { useSelector } from 'react-redux'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import produce from 'immer'
import Pill from '@sweetspot/sweetspot-js/common/components/Pill'
import ExpandingPills from '@sweetspot/club-portal-legacy/components/ExpandingPills'
import { createClubUserInvite } from '@sweetspot/sweetspot-js/features/clubUsers/services/api-platform'
import { useToasts } from 'react-toast-notifications'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

const InviteClubUserModal = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  let history = useHistory()
  const currentClubCoursesList = useSelector(({ golfCourse }) => golfCourse.list)
  const currentClubId = useSelector(({ golfClub }) => golfClub.selectedId)

  const [form, setForm] = useMergeState({
    email: '',
    role: null,
    courses: [],
  })
  const [submitting, setSubmitting] = useState(false)

  const allCoursesIncluded = useMemo(() => {
    if (!form.role?.value) return false
    if (
      form.role.value === ROLES_DEF.ROLE_CLUB_OWNER.value ||
      form.role.value === ROLES_DEF.ROLE_ADMIN.value ||
      form.role.value === ROLES_DEF.ROLE_CUSTOMER_SERVICE.value
    ) {
      return true
    }
    return false
  }, [form.role])

  const roles = useMemo(() => {
    return ROLES_ARR.filter((r) => !r.hidden).map((r) => ({ ...r, label: t(r.label), id: r.value }))
  }, [t])

  const courses = useMemo(() => {
    return currentClubCoursesList.map((c) => ({
      ...c,
      label: c.name,
      disabled: form?.courses?.some((c2) => c2.id === c.id) || false,
    }))
  }, [currentClubCoursesList, form])

  const onRoleOptionSelectListener = (role) => {
    setForm({ role: role })
  }

  const onCourseOptionSelectListener = (course) => {
    setForm(
      produce((draft) => {
        const index = draft.courses.findIndex((c) => c.id === course.id)
        if (index === -1) {
          draft.courses = [course, ...draft.courses]
        }
      })
    )
  }

  const onCloseCourseListener = (course) => {
    setForm(
      produce((draft) => {
        const index = draft.courses.findIndex((c) => c.id === course.id)
        if (index !== -1) draft.courses.splice(index, 1)
      })
    )
  }

  const onCloseListener = () => {
    history.push('/settings/club-users')
  }

  const onAcceptListener = async () => {
    const { email, role, courses } = form
    if (!email || !role || (!allCoursesIncluded && !courses?.length)) return

    setSubmitting(true)

    const [res, err] = await to(
      createClubUserInvite(currentClubId, {
        email,
        role: role.value,
        courses: allCoursesIncluded
          ? currentClubCoursesList.filter((c) => c?.uuid).map((c) => c.uuid)
          : courses.filter((c) => c?.uuid).map((c) => c.uuid),
      })
    )

    setSubmitting(false)

    if (!res || err) {
      addToast(t('sentences.couldNotSendInvite'), { appearance: 'error' })
    } else {
      addToast(t('sentences.inviteSent'), { appearance: 'success' })
      onCloseListener()
    }
  }

  const onRejectListener = () => {
    onCloseListener()
  }

  return (
    <ConfirmPopup
      onReject={onRejectListener}
      onAccept={onAcceptListener}
      rejectText={t('words.cancel')}
      acceptText={t('sentences.sendInvite')}
      acceptTheme="default"
      visible={true}
      onClose={onCloseListener}
      title={t('sentences.inviteNewUser')}
      containerClassName={cx(styles.popup)}
      acceptProps={{ loading: submitting }}
      rejectProps={{ disabled: submitting }}
    >
      <div className={cx('ss-form-wrapper')}>
        <div className={cx('ss-form-row mb-1.5')}>
          <div className={cx('ss-form-wrapper w-50p w-flex flex-0-1 mr-3.5')}>
            <label className={cx('ss-label')}>{t('words.email')}</label>
            <input
              value={form.email}
              onChange={(e) => setForm({ email: e.target.value })}
              placeholder={t('sentences.enterEmail')}
              className={cx('ss-input w-100p')}
              disabled={submitting}
            />
          </div>

          <div className={cx('ss-form-wrapper w-50p w-flex')}>
            <label className={cx('ss-label')}>{t('words.role')}</label>
            <TextInputOptionsSelect
              value={form?.role?.label || ''}
              placeholder={t('words.select')}
              options={roles}
              onOptionSelect={onRoleOptionSelectListener}
              inputReadOnly
              disabled={submitting}
              containerClassName="w-100p mb-0"
            />
          </div>
        </div>

        {!allCoursesIncluded ? (
          <React.Fragment>
            <div className={cx('ss-form-row')}>
              <div className={cx('ss-form-wrapper w-100p w-flex')}>
                <label className={cx('ss-label bold')}>{t('sentences.hasAccessToCourses')}</label>
                <TextInputOptionsSelect
                  inputReadOnly
                  containerClassName={cx('mb-0 w-100p w-flex')}
                  placeholder={t('words.select')}
                  options={courses}
                  onOptionSelect={onCourseOptionSelectListener}
                  disabled={submitting}
                />
              </div>
            </div>

            <ExpandingPills className={cx('w-100p w-flex mr-0', styles.pillsContainer)}>
              {form.courses.map((c) => (
                <Pill
                  key={c.id}
                  onClose={() => !submitting && onCloseCourseListener(c)}
                  useTitle={true}
                  text={c.label}
                />
              ))}
            </ExpandingPills>
          </React.Fragment>
        ) : (
          <p className={cx(styles.allCoursesText)}>
            {t('sentences.roleIncludesAccessToAllCourses')}
          </p>
        )}
      </div>
    </ConfirmPopup>
  )
}

InviteClubUserModal.propTypes = {}

InviteClubUserModal.defaultProps = {}

export default InviteClubUserModal
