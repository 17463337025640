import jwt from 'jsonwebtoken'
import { ITokenPayload } from './types'

export const createSignedToken = (payload: ITokenPayload, secret: string): string | null => {
  try {
    const token = jwt.sign(payload, secret)
    return token
  } catch (error) {
    return null
  }
}
