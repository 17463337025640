import { create } from 'zustand'

interface PaymentIdStore {
  selectedPaymentId: string | null
  setSelectedPaymentId: (id: string | null) => void
}

export const usePaymentIdStore = create<PaymentIdStore>((set) => ({
  selectedPaymentId: null,
  setSelectedPaymentId: (id: string | null) => set({ selectedPaymentId: id }),
}))
