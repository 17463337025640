import { useState, useEffect } from 'react'
import { FeatureFlagValue } from '@sweetspot/shared/data-access/api-platform'
import { FeatureFlags } from './types'
import { useFlagContext } from './context'

export const useFlag = <T extends keyof FeatureFlags>(flag: T): FeatureFlags[T] => {
  const { flags } = useFlagContext()

  const [flagValue, setFlagValue] = useState<FeatureFlagValue>(() => flags[flag] ?? false)
  useEffect(() => setFlagValue(flags[flag] ?? false), [flags, flag])
  return flagValue
}
