import {
  MembershipState,
  MembershipActions,
  SetListAction,
  AddListAction,
  SetTotalListAction,
  SetTotalPagesAction,
  SetFederationMembershipsAction,
} from '@sweetspot/club-portal-legacy/store/types'
import { CONSTANTS } from '../../constants'
import { initialState } from './initialState'

export default function membership(
  state = initialState,
  action: MembershipActions
): MembershipState {
  switch (action.type) {
    case CONSTANTS.MEMBERSHIP.SET_LIST:
      return { ...state, list: (action as SetListAction).payload }

    case CONSTANTS.MEMBERSHIP.ADD_LIST:
      return { ...state, list: [...state.list, ...(action as AddListAction).payload] }

    case CONSTANTS.MEMBERSHIP.SET_TOTAL_LIST:
      return { ...state, totalList: (action as SetTotalListAction).payload }

    case CONSTANTS.MEMBERSHIP.SET_TOTAL_PAGES:
      // eslint-disable-next-line
      const { itemsPerPage, totalItems } = (action as SetTotalPagesAction).payload
      // eslint-disable-next-line
      const totalPages = Math.ceil(totalItems / itemsPerPage)

      return { ...state, totalPages, itemsPerPage, totalItems }

    case CONSTANTS.MEMBERSHIP.SET_FEDERATION_MEMBERSHIPS:
      return { ...state, federationMemberships: (action as SetFederationMembershipsAction).payload }

    default:
      return state
  }
}
