import React from 'react'
import PropTypes from 'prop-types'

import TimePicker from 'rc-time-picker'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'

const TimePeriod = (props) => {
  const { startTime, endTime, onTimeChange } = props

  return (
    <div className={style.container}>
      <div>
        <Text textId="from" className={style.text} />

        <div className={style.timePicker}>
          <TimePicker
            allowEmpty={false}
            value={startTime}
            showSecond={false}
            onChange={(moment) => onTimeChange(moment, true)}
          />
        </div>
      </div>

      <div className={style.divider}>-</div>

      <div>
        <Text textId="to" className={style.text} />

        <div className={style.timePicker}>
          <TimePicker
            allowEmpty={false}
            value={endTime}
            showSecond={false}
            onChange={(moment) => onTimeChange(moment, false)}
          />
        </div>
      </div>
    </div>
  )
}

TimePeriod.propTypes = {
  startTime: PropTypes.object.isRequired,
  endTime: PropTypes.object.isRequired,
  onTimeChange: PropTypes.func.isRequired,
}

export default TimePeriod
