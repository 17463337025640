import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import Container from '@sweetspot/club-portal-legacy/components/Container'

import Main from './Main'
import Members from './Members'
import Partners from './Partners'
import Partnership from './Partnership'
import Vouchers from './Vouchers'
import TeeSheetDash from './TeeSheet'
import BookingDash from './Booking'
import Bills from './Bills'
import NotFound from '@sweetspot/club-portal-legacy/pages/NotFound'

class Statistics extends Component {
  render() {
    const { path } = this.props.match

    return (
      <Container>
        <Switch>
          <Route exact path={`${path}/tags`} component={Partners} />
          <Route exact path={`${path}/partnerships`} component={Partnership} />
          <Route exact path={`${path}/vouchers`} component={Vouchers} />
          <Route exact path={`${path}/transactions`} component={Bills} />
          <Route exact path={`${path}/memberships`} component={Members} />
          <Route exact path={`${path}/bookings`} component={BookingDash} />
          <Route exact path={`${path}/tee-sheet`} component={TeeSheetDash} />
          <Route exact path={`${path}`} component={Main} />
          <Route exact path={`${path}/*`} component={NotFound} />
        </Switch>
      </Container>
    )
  }
}

Statistics.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Statistics
