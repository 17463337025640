import sweetspotJsEn from '../langs/sweetspot-js/en.json'
import sweetspotJsSv from '../langs/sweetspot-js/sv.json'
import sweetspotJsDe from '../langs/sweetspot-js/de.json'
import sweetspotJsDa from '../langs/sweetspot-js/da.json'
import sweetspotJsFi from '../langs/sweetspot-js/fi.json'
import i18next from 'i18next'
import { I18nextNamespaces, LanguageKeys } from '../constants/enums'

export const addSweetspotJsBundles = (): void => {
  i18next.addResourceBundle(LanguageKeys.EN, I18nextNamespaces.DEFAULT, sweetspotJsEn, true, false)
  i18next.addResourceBundle(LanguageKeys.SV, I18nextNamespaces.DEFAULT, sweetspotJsSv, true, false)
  i18next.addResourceBundle(LanguageKeys.DE, I18nextNamespaces.DEFAULT, sweetspotJsDe, true, false)
  i18next.addResourceBundle(LanguageKeys.DA, I18nextNamespaces.DEFAULT, sweetspotJsDa, true, false)
  i18next.addResourceBundle(LanguageKeys.FI, I18nextNamespaces.DEFAULT, sweetspotJsFi, true, false)
}
