import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as SpinnerSvg } from '@sweetspot/sweetspot-js/assets/svgs/spinner.svg'

import styles from './styles.module.scss'

const Spinner = ({ visible, color, className }) => (
  <SpinnerSvg
    className={cx(
      styles.spinner,
      {
        [styles.visible]: visible,
      },
      {
        [styles.white]: color === 'white',
        [styles.dark]: color === 'dark',
        [styles.light]: color === 'light',
        [styles.blue]: color === 'blue',
      },
      className
    )}
  />
)

Spinner.propTypes = {
  visible: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'dark', 'light', 'blue']),
  className: PropTypes.string,
}

Spinner.defaultProps = {
  visible: true,
  color: 'white',
  className: '',
}

export default Spinner
