import { useMemo } from 'react'
import PropTypes from 'prop-types'

const QueryWrapper = ({ action, filterKey, queryKey, render }) => {
  const value = useMemo(() => {
    return action.configuration.filter[filterKey][queryKey]
  }, [action, filterKey, queryKey])

  return render(value)
}

QueryWrapper.propTypes = {
  action: PropTypes.object,
  filterKey: PropTypes.string,
  queryKey: PropTypes.string,
  render: PropTypes.func,
}

QueryWrapper.defaultProps = {}

export default QueryWrapper
