import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { z } from 'zod'
import { createPaymentLink } from '@sweetspot/shared/data-access/api-platform'
import { UserInformationFormSchema } from '../sheets/PaymentCreateSheet/validation'

import { PaymentSubmitData } from '../sheets/types'

export const usePaymentLinkCreate = () => {
  const currentClub = useSelector(({ golfClub }) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const { mutateAsync, isLoading, isSuccess, data } = useMutation(
    async ({
      payload,
      paymentId,
    }: {
      payload: {
        country: string | undefined
        expires_at: string
        delivery_methods: z.infer<typeof UserInformationFormSchema>
        note: string
      }
      paymentId: string
    }) => createPaymentLink(payload, paymentId)
  )

  const create = async (formData: PaymentSubmitData, paymentId: string) => {
    const payload = {
      country: currentClub?.address?.country?.code,
      expires_at: formData.expiringDate,
      delivery_methods: formData.deliveryMethods,
      note: formData?.note,
    }

    return await mutateAsync({
      payload,
      paymentId,
    })
  }

  return {
    create,
    isLoading,
    isSuccess,
    data,
  }
}
