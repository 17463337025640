import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { tv, type VariantProps } from 'tailwind-variants'
import { cn } from '../../../utils'

export const ButtonVariantKeys = {
  Primary: 'primary',
  Inverted: 'inverted',
  Secondary: 'secondary',
  Branded: 'branded',
  GhostDark: 'ghost-dark',
  GhostLight: 'ghost-light',
  ClearLight: 'clear-light',
  ClearDark: 'clear-dark',
  LinkLight: 'link-light',
  LinkDark: 'link-dark',
} as const

const buttonVariants = tv({
  base: [
    'px-lg inline-flex gap-x-2 items-center justify-center whitespace-nowrap transition-all disabled:pointer-events-none',
    'focus-visible:ring-2 focus-visible:ring-inset outline-none focus-visible:ring-border-stroke-focused',
  ],
  variants: {
    variant: {
      [ButtonVariantKeys.Primary]: [
        'bg-surface-primary text-text-light',
        'hover:bg-surface-primary-hover hover:shadow-lg',
        'active:bg-surface-primary-selected active:ring-0',
        'disabled:bg-surface-primary-disabled disabled:text-text-pale',
      ],
      [ButtonVariantKeys.Inverted]: [
        'bg-surface-inverted-primary text-text-dark',
        'hover:bg-surface-inverted-primary-hover hover:text-text-light hover:shadow-lg',
        'active:bg-surface-inverted-primary-focused active:text-text-dark active:ring-0',
        'disabled:bg-surface-inverted-primary-disabled disabled:text-text-pale',
      ],
      [ButtonVariantKeys.Secondary]: [
        'bg-surface-secondary text-text-light',
        'hover:bg-surface-secondary-hover hover:text-text-light hover:shadow-lg',
        'active:bg-surface-secondary-focused active:text-text-light active:ring-0',
        'disabled:bg-surface-secondary-disabled disabled:text-text-light',
      ],
      [ButtonVariantKeys.Branded]: [
        'bg-surface-brand text-text-dark',
        'hover:bg-surface-brand-hover hover:text-text-dark hover:shadow-lg',
        'active:bg-surface-brand-focused active:text-text-dark active:ring-0',
        'disabled:bg-surface-brand-disabled disabled:text-text-pale',
      ],
      [ButtonVariantKeys.GhostDark]: [
        'bg-transparent text-text-dark ring-2 ring-border-stroke-subtle ring-inset',
        'hover:bg-surface-clean-hover hover:text-text-dark hover:shadow-lg',
        'active:bg-surface-clean active:text-text-dark active:ring-0',
        'disabled:bg-surface-clean disabled:text-text-clean disabled:ring-2 disabled:ring-inset disabled:ring-border-stroke-clean',
      ],
      [ButtonVariantKeys.GhostLight]: [
        'bg-transparent text-text-light ring-2 ring-border-stroke-light ring-inset',
        'hover:bg-transparent hover:text-text-light',
        'active:bg-transparent active:text-text-light active:ring-0',
        'disabled:bg-transparent disabled:text-text-light disabled:ring-2 disabled:ring-inset disabled:ring-border-stroke-light',
      ],
      [ButtonVariantKeys.ClearLight]: [
        'bg-transparent text-text-light drop-shadow',
        'hover:bg-surface-clean-hover hover:text-text-subtle focus:drop-shadow-none hover:shadow-lg',
        'active:bg-transparent active:text-text-light active:drop-shadow',
        'disabled:bg-transparent disabled:text-text-light disabled:drop-shadow',
      ],
      [ButtonVariantKeys.ClearDark]: [
        'text-text-dark bg-transparent',
        'hover:bg-surface-primary-hover hover:text-text-light hover:shadow-lg',
        'active:bg-transparent active:text-text-dark',
        'disabled:bg-transparent disabled:text-text-pale',
      ],
      [ButtonVariantKeys.LinkLight]: [
        'text-text-light bg-transparent',
        'hover:text-text-light hover:drop-shadow hover:bg-transparent hover:underline',
        'active:text-text-light active:bg-transparent',
        'disabled:bg-transparent disabled:text-text-light disabled:opacity-40',
      ],
      [ButtonVariantKeys.LinkDark]: [
        'bg-transparent text-text-dark',
        'hover:bg-transparent hover:text-text-dark hover:underline',
        'active:bg-transparent active:text-text-dark',
        'disabled:bg-transparent disabled:text-text-dark disabled:opacity-40',
      ],
    },
    size: {
      large: 'h-touch-height-lg text-content-lg font-semibold',
      medium: 'h-touch-height-md text-content-lg font-semibold',
      small: 'h-touch-height-sm text-content-sm font-medium',
    },
    rounding: {
      rectangular: 'rounded-md',
      pill: 'rounded-full',
    },
    selected: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      selected: true,
      variant: ButtonVariantKeys.Primary,
      class: 'bg-surface-primary text-text-light',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.Inverted,
      class: 'bg-surface-inverted-primary-selected text-text-light',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.Secondary,
      class: 'bg-surface-secondary-selected text-text-light',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.Branded,
      class: 'bg-surface-brand-selected text-text-dark',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.GhostDark,
      class: 'ring-2 ring-inset ring-border-stroke-subtle bg-surface-clean text-text-dark',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.GhostLight,
      class: 'text-text-light ring-2 ring-inset ring-border-stroke-light bg-transparent',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.ClearLight,
      class: 'bg-transparent text-text-light drop-shadow',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.ClearDark,
      class: 'bg-transparent text-text-dark',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.LinkLight,
      class: 'bg-transparent text-text-light',
    },
    {
      selected: true,
      variant: ButtonVariantKeys.LinkDark,
      class: 'bg-surface-bright-selected text-text-dark',
    },
  ],
  defaultVariants: {
    variant: ButtonVariantKeys.Primary,
    size: 'medium',
    rounding: 'rectangular',
    selected: false,
  },
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  // eslint-disable-next-line react/require-default-props
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, rounding, selected, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, rounding, selected, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
