import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Router } from 'react-router'
import { connect } from 'react-redux'
import { withToastManager } from 'react-toast-notifications'

import { history } from '../store'
import { getLang } from '../languages'

import Login from './Login'
import TeeSheet from './TeeSheet'
import Bookings from './Bookings'
import NoShow from './NoShow'
import Players from './Players'
import Partnerships from './Partnerships'
import TeeTimePeriodsNew from './TeeTimePeriods'
import Settings from './Settings'
import AppUsers from './AppUsers'
import TeeSheetShare from './TeeSheetShare'
import Reports from './Reports'
import Statistics from './Statistics'
import Communication from './Communication'
import Vouchers from './Vouchers'
import Unauthorized from './Unauthorized'
import NotFound from './NotFound'
import NoPermission from './NoPermission'
import NoGolfClub from './NoGolfClub'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Utilities from '../helpers/Utilities'

import style from './style.module.scss'
import SimulatorsRangesOrder from './SimulatorsRangesOrder'
import ClubUserAcceptInvite from './ClubUserAcceptInvite'
import { Venues } from './Venues'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { PaymentLinksOverview } from '@sweetspot/club-portal/feature/payment-links'
import { PrivateRoute } from '@sweetspot/club-portal-legacy/components/PrivateRoute'
import Booking from './Booking'
import { LandingPage } from './LandingPage'
import InsightsRouter from './Insights/InsightsRouter'
import { Memberships } from './Memberships'
import { withTranslation } from 'react-i18next'

const LoginRoute = ({ component: Component, loggedIn, ...rest }) => {
  const pathname = rest.location.state ? rest.location.state.pathname : '/'
  return (
    <Route
      {...rest}
      render={(props) => (!loggedIn ? <Component {...props} /> : <Redirect to={{ pathname }} />)}
    />
  )
}

LoginRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  loggedIn: PropTypes.bool.isRequired,
}

class Routes extends Component {
  componentDidUpdate = (prevProps) => {
    const { lang: oldLang } = prevProps
    const {
      lang,
      loggedIn,
      toast,
      toastManager,
      location: { pathname },
      i18n,
    } = this.props

    if (typeof lang === 'string' && oldLang !== lang) {
      i18n.changeLanguage(lang === 'se' ? 'sv' : lang)
    }

    if (
      prevProps.loggedIn &&
      !loggedIn &&
      pathname !== '/login' &&
      !pathname.includes('tee-sheet-links')
    ) {
      this.props.history.push('/login')
      this.resetHubSpotWidget()
    }

    if (!prevProps.loggedIn && loggedIn && pathname === '/login') {
      if (this.props.location.state) {
        this.props.history.push(this.props.location.state)
      } else if (this.props.history.location?.pathname) {
        this.props.history.push(this.props.history.location)
      } else {
        this.props.history.push('/')
      }
    }

    if (prevProps.location.pathname !== pathname) {
      this.props.onUpdate()
    }

    if (prevProps.toast !== toast && toast.message && toast.type) {
      let text = Utilities.objectValueFromDotNotation(getLang(lang), 'toast.' + toast.message)

      if (!text) text = 'Error: ' + toast.message

      toastManager.add(text, {
        appearance: toast.type,
        autoDismiss: true,
      })
    }
  }

  resetHubSpotWidget = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove()
    }
  }

  renderPrivateRoutes() {
    const { loggedIn, golfClubs } = this.props

    const privateRoutes = [
      '/',
      '/bookings',
      '/booking/:orderUuid',
      '/simulators-ranges',
      '/simulators-ranges/orders/:uuid',
      '/simulators-venue',
      '/no-show',
      '/other-venue',
      '/partnerships',
      '/players',
      '/pricing',
      '/pro-venue',
      '/ranges-venue',
      '/settings',
      '/settings/:id',
      '/app-users',
      '/price-lists',
      '/communication',
      '/statistics',
      '/statistics/:id',
      '/memberships',
      '/vouchers',
      '/no-permission',
      '/unauthorized',
    ]

    if (golfClubs && golfClubs.length) {
      return (
        <Switch>
          <PrivateRoute exact path="/" component={LandingPage} loggedIn={loggedIn} />
          <PrivateRoute exact path="/course-venue" component={TeeSheet} loggedIn={loggedIn} />
          <PrivateRoute
            exact
            path="/simulators-venue"
            component={Venues}
            loggedIn={loggedIn}
            courseType={COURSE_TYPES.SIMULATOR.value}
          />
          <PrivateRoute
            exact
            path="/ranges-venue"
            component={Venues}
            courseType={COURSE_TYPES.DRIVING_RANGE.value}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/pro-venue"
            component={Venues}
            loggedIn={loggedIn}
            courseType={COURSE_TYPES.PRO.value}
          />
          <PrivateRoute
            exact
            path="/other-venue"
            component={Venues}
            loggedIn={loggedIn}
            courseType={COURSE_TYPES.OTHER.value}
          />
          <PrivateRoute
            exact
            path="/simulators-ranges/orders/:uuid"
            component={SimulatorsRangesOrder}
            loggedIn={loggedIn}
          />
          <PrivateRoute exact path="/bookings" component={Bookings} loggedIn={loggedIn} />
          <PrivateRoute exact path="/booking/:orderUuid" component={Booking} loggedIn={loggedIn} />
          <PrivateRoute exact path="/no-show" component={NoShow} loggedIn={loggedIn} />
          <PrivateRoute exact path="/no-show/player/:id" component={NoShow} loggedIn={loggedIn} />
          <PrivateRoute path="/players" component={Players} loggedIn={loggedIn} />
          <PrivateRoute exact path="/settings" component={Settings} loggedIn={loggedIn} />
          <PrivateRoute
            path="/settings/tee-time-periods"
            component={TeeTimePeriodsNew}
            loggedIn={loggedIn}
          />
          <PrivateRoute exact path="/settings/*" component={Settings} loggedIn={loggedIn} />
          <PrivateRoute exact path="/app-users" component={AppUsers} loggedIn={loggedIn} />
          <PrivateRoute path="/communication" component={Communication} loggedIn={loggedIn} />
          <PrivateRoute path="/statistics" component={Statistics} loggedIn={loggedIn} />
          <PrivateRoute path="/insights" component={InsightsRouter} loggedIn={loggedIn} />
          <PrivateRoute path="/reports" component={Reports} loggedIn={loggedIn} />
          <PrivateRoute path="/memberships" component={Memberships} loggedIn={loggedIn} />
          <PrivateRoute path="/partnerships" component={Partnerships} loggedIn={loggedIn} />
          <PrivateRoute path="/vouchers" component={Vouchers} loggedIn={loggedIn} />
          <PrivateRoute path="/no-permission" component={NoPermission} loggedIn={loggedIn} />
          <Route exact path="/unauthorized" component={Unauthorized} loggedIn={loggedIn} />
          <Route exact path="/payments" component={PaymentLinksOverview} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      )
    }

    return <PrivateRoute exact path={privateRoutes} component={NoGolfClub} loggedIn={loggedIn} />
  }

  render() {
    const { loggedIn, isLoading, golfClubs } = this.props

    return (
      <React.Fragment>
        {(!golfClubs || !golfClubs.length) && (
          <div className={isLoading ? style.loaderContainer : ''}>
            {isLoading && <PulseLoader showIf={true} />}
          </div>
        )}
        <Router history={history}>
          <Switch>
            <Route path="/tee-sheet-links/:id" component={TeeSheetShare} />
            <Route exact path="/club-user-invite/:token/verify" component={ClubUserAcceptInvite} />
            <LoginRoute path="/login" component={Login} loggedIn={loggedIn} />
            {this.renderPrivateRoutes()}
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

Routes.propTypes = {
  history: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  toast: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  me: PropTypes.object.isRequired,
  golfClubs: PropTypes.array.isRequired,
  selectedGolfclub: PropTypes.number.isRequired,
  courseType: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    isSuperAdmin: state.auth.isSuperAdmin,
    lang: state.auth.me.lang,
    isLoading: state.appState.isLoading,
    toast: state.appState.toast,
    me: state.auth.me,
    golfClubs: state.golfClub.list,
    selectedGolfclub: state.golfClub.selectedId,
  }
}

export default withToastManager(withRouter(connect(mapStateToProps)(withTranslation()(Routes))))
