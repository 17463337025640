import React, { useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import useDynamicFixedPosition from '@sweetspot/sweetspot-js/common/hooks/useDynamicFixedPosition'

const ButtonAndDropdownWrapper = ({
  buttonProps,
  id,
  children,
  onCancel,
  visible,
  dropdownClassName,
}) => {
  const wrapper = useRef()
  const container = useRef()

  useOnClickOutside(container, () => onCancel(false))
  const { top, left, isInView } = useDynamicFixedPosition(wrapper, visible)

  return (
    <div className={cx(styles.container)}>
      <ButtonBasic {...buttonProps} />
      <div className={cx(styles.dropdownWrapper)} ref={wrapper}>
        {isInView && (
          <Portal id={id}>
            <div
              ref={container}
              className={cx(styles.dropdownContainer, visible && styles.visible, dropdownClassName)}
              style={{ position: 'fixed', top, left }}
            >
              {children}
            </div>
          </Portal>
        )}
      </div>
    </div>
  )
}

ButtonAndDropdownWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    color: PropTypes.oneOf(['default', 'red']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    text: PropTypes.string,
    icon: PropTypes.oneOf(['plus']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  dropdownClassName: PropTypes.string,
}

ButtonAndDropdownWrapper.defaultProps = {
  buttonProps: {
    className: '',
    iconClassName: '',
    color: 'default',
    size: 'small',
    text: '',
    icon: null,
    disabled: false,
    onClick: () => {},
  },
  onCancel: () => {},
  visible: false,
  dropdownClassName: '',
}

export default ButtonAndDropdownWrapper
