import {
  _discountDraft,
  SETTINGS,
} from '@sweetspot/sweetspot-js/features/vouchers/hooks/useVoucher'
import {
  centsToFloat,
  decimalPercentToNumber,
  floatToCents,
  numberToDecimalPercent,
} from '@sweetspot/sweetspot-js/common/functions/utils'

export const parseAction = (action) => {
  if (!action) return _discountDraft
  const { type, configuration } = action
  const { filter, percentage_with_uses, percentage_with_discounted_value } = configuration
  const result = {
    [SETTINGS.ACTION_ID]: action.id,
    [SETTINGS.IS_USES_TYPE]: type === 'item_percentage_discount_with_uses_limitation',
    [SETTINGS.IS_VALUE_TYPE]: type === 'item_percentage_discount_with_discounted_value_limitation',
    [SETTINGS.APPLY_GOLF_CART]: Boolean(filter.golf_cart_filter),
    [SETTINGS.APPLY_HOLDER]: filter.slot_filter.slot_type_query.includes('holder_to_voucher_code'),
    [SETTINGS.APPLY_GUEST]: filter.slot_filter.slot_type_query.includes(
      'guest_to_voucher_code_holder'
    ),
  }
  return Object.assign(result, {
    [SETTINGS.PERCENTAGE]: decimalPercentToNumber(
      result.isUsesType
        ? percentage_with_uses[0]['percentage_coefficient']
        : percentage_with_discounted_value[0]['percentage_coefficient']
    ),
    [SETTINGS.USES_VALUE]: result.isUsesType
      ? percentage_with_uses[0]['uses_to']
      : centsToFloat(percentage_with_discounted_value[0]['discounted_value_to']),
    [SETTINGS.MIN_PRICE]: centsToFloat(
      result.isUsesType
        ? percentage_with_uses[0]['minimum_fee_total_amount']
        : percentage_with_discounted_value[0]['minimum_fee_total_amount']
    ),
  })
}

export const parseSettings = (draft) => {
  const isUsesType = draft[SETTINGS.IS_USES_TYPE]
  return {
    type: isUsesType
      ? 'item_percentage_discount_with_uses_limitation'
      : 'item_percentage_discount_with_discounted_value_limitation',
    configuration: {
      filter: Object.assign(draft[SETTINGS.APPLY_GOLF_CART] ? { golf_cart_filter: [] } : {}, {
        slot_filter: {
          slot_type_query: [
            draft[SETTINGS.APPLY_HOLDER] ? 'holder_to_voucher_code' : false,
            draft[SETTINGS.APPLY_GUEST] ? 'guest_to_voucher_code_holder' : false,
          ].filter((i) => i),
        },
      }),
      [`percentage_with_${isUsesType ? 'uses' : 'discounted_value'}`]: [
        {
          minimum_fee_total_amount: draft[SETTINGS.MIN_PRICE]
            ? floatToCents(draft[SETTINGS.MIN_PRICE])
            : null,
          percentage_coefficient: numberToDecimalPercent(draft[SETTINGS.PERCENTAGE]),
          [`${isUsesType ? 'uses_to' : 'discounted_value_to'}`]: isUsesType
            ? parseInt(draft[SETTINGS.USES_VALUE])
            : floatToCents(draft[SETTINGS.USES_VALUE]),
        },
      ],
    },
  }
}

export const isValid = (type, value) => {
  if (type === 'percentage') {
    return value >= 1 && value <= 100
  }
  if (type === 'discounted_value_to') {
    return value > 0 && value <= 1000000000
  }
  if (type === 'uses_to') {
    return value > 0 && value <= 1000000
  }
}
