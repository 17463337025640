import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

export const fetchMembershipList = (query) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/memberships`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, {
    method: 'GET',
  })
}

export const fetchCooperationsList = (query) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/cooperations`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, {
    method: 'GET',
  })
}

export const createCooperation = (hostClubID, partnerClubID) => {
  const URL = `${getApiPlatformBaseUrl()}/clubs/${hostClubID}/cooperation`
  return request(URL, {
    method: 'PUT',
    body: {
      partner_club: partnerClubID,
      partner_club_all_members_option: true,
    },
  })
}

export const fetchCooperation = (cooperationID) => {
  const URL = `${getApiPlatformBaseUrl()}/cooperations/${cooperationID}`
  return request(URL, {
    method: 'GET',
  })
}

export const addMemberships = (cooperation, value, isHost) => {
  const target = isHost ? 'host' : 'partner'
  const URL = `${getApiPlatformBaseUrl()}/coopearation/${
    cooperation.uuid
  }/add-${target}-club-memberships`
  return request(URL, {
    method: 'PUT',
    body: {
      [`${target}_club_memberships`]: value,
      [`${target}_club_all_members_option`]: Boolean(
        cooperation[`${target}_club_all_members_option`]
      ),
    },
  })
}

export const removeMemberships = (cooperation, value, isHost) => {
  const target = isHost ? 'host' : 'partner'
  const URL = `${getApiPlatformBaseUrl()}/coopearation/${
    cooperation.uuid
  }/remove-${target}-club-memberships`
  return request(URL, {
    method: 'PUT',
    body: {
      [`${target}_club_memberships`]: value,
      [`${target}_club_all_members_option`]: Boolean(
        cooperation[`${target}_club_all_members_option`]
      ),
    },
  })
}

export const toggleAllMembersOption = (cooperation, isHost) => {
  const target = isHost ? 'host' : 'partner'
  const URL = `${getApiPlatformBaseUrl()}/coopearation/${
    cooperation.uuid
  }/add-${target}-club-memberships`
  return request(URL, {
    method: 'PUT',
    body: {
      [`${target}_club_memberships`]: [],
      [`${target}_club_all_members_option`]: !cooperation[`${target}_club_all_members_option`],
    },
  })
}

export const cancelCooperation = (cooperationID) => {
  const URL = `${getApiPlatformBaseUrl()}/coopearation/${cooperationID}/cancel`
  return request(URL, {
    method: 'PUT',
  })
}

export const confirmCooperationRequest = (cooperationID) => {
  const URL = `${getApiPlatformBaseUrl()}/coopearation/${cooperationID}/confirm`
  return request(URL, {
    method: 'PUT',
  })
}

export const denyCooperationRequest = (cooperationID) => {
  const URL = `${getApiPlatformBaseUrl()}/coopearation/${cooperationID}/deny`
  return request(URL, {
    method: 'PUT',
  })
}
