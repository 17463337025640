import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const OnHover = ({ children, text, className, position }) => {
  const [visible, setVisible] = useState(false)

  return (
    <div
      className={cx(styles.container, className)}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div onMouseLeave={() => setVisible(false)}>{children}</div>
      {visible && (
        <div
          className={cx(styles.tooltip, {
            [styles.left]: position === 'left',
            [styles.right]: position === 'right',
            [styles.top]: position === 'top',
            [styles.bottom]: position === 'bottom',
          })}
        >
          <div className={styles.arrow}></div>
          <div>{text}</div>
        </div>
      )}
    </div>
  )
}

OnHover.propTypes = {
  text: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  className: PropTypes.string,
}

OnHover.defaultProps = {
  text: '',
  className: '',
  position: 'right',
}

export default OnHover
