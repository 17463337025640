import React, { useState } from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'

const ExpandingPills = ({ children, className }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className={cx(styles.container, className, { [styles.expanded]: isExpanded })}>
      <div className={cx(styles.mainContent)}>{children}</div>
      {children?.length > 3 && (
        <div
          className={cx('system-pill primary', styles.expandButton)}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!isExpanded ? `+${children?.length - 3}` : '-'}
        </div>
      )}
    </div>
  )
}

ExpandingPills.propTypes = {
  className: PropTypes.string,
}

export default ExpandingPills
