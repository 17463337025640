import clubPortalEn from '../langs/club-portal/en.json'
import clubPortalSv from '../langs/club-portal/sv.json'
import clubPortalDe from '../langs/club-portal/de.json'
import clubPortalDa from '../langs/club-portal/da.json'
import i18next from 'i18next'
import { I18nextNamespaces, LanguageKeys } from '../constants/enums'

export const addClubPortalBundles = (): void => {
  i18next.addResourceBundle(LanguageKeys.EN, I18nextNamespaces.DEFAULT, clubPortalEn, true, false)
  i18next.addResourceBundle(LanguageKeys.SV, I18nextNamespaces.DEFAULT, clubPortalSv, true, false)
  i18next.addResourceBundle(LanguageKeys.DE, I18nextNamespaces.DEFAULT, clubPortalDe, true, false)
  i18next.addResourceBundle(LanguageKeys.DA, I18nextNamespaces.DEFAULT, clubPortalDa, true, false)
}
