import React, { useMemo, useState, useRef, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import StepsProgress from '@sweetspot/club-portal-legacy/components/Wizard/partials/StepsProgress'
import Separator from '@sweetspot/club-portal-legacy/components/Wizard/partials/Separator'
import VersionAndName from '@sweetspot/club-portal-legacy/components/Wizard/partials/VersionAndName'
import Navigation from '@sweetspot/club-portal-legacy/components/Wizard/partials/Navigation'
import PartnershipFooter from '@sweetspot/club-portal-legacy/components/Wizard/partials/PartnershipFooter'

import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'

import GeneralSettings from '@sweetspot/club-portal-legacy/components/Wizard/containers/partnership/GeneralSettings'
import RulesAndCourses from '@sweetspot/club-portal-legacy/components/Wizard/containers/partnership/RulesAndCourses'
import Review from '@sweetspot/club-portal-legacy/components/Wizard/containers/partnership/Review'

import { validatePartnership, validatePromotions, validateCompletePartnership } from './validation'

import useWizard from '@sweetspot/club-portal-legacy/hooks/useWizard'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'
import usePrevious from '@sweetspot/club-portal-legacy/hooks/usePrevious'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'

import { ReactComponent as TrashCan } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'

const DEFAULT_PARTNERSHIP = require('./defaultPartnership.json')

const PartnershipWizardModal = () => {
  const history = useHistory()

  const handleOnCloseModal = () => {
    history.push('/partnerships', { shouldReload: true })
  }

  const {
    currentStep,
    loading,
    canSave,
    currentEntity: currentPartnership,
    values,
    errors,
    globalErrors,
    setEntityValue,
    clearEntityValue,
    updateExistingEntity: updatePartnership,
    createNewEntity: createNewPartnership,
    incrementStep,
    decrementStep,
    setStep,
    setRuleValue,
    setActionValue,
    clearRule,
    clearAction,
    savePromotions,
    deleteExistingEntity,
    activateExistingEntity,
    cancelExistingEntity,
  } = useWizard({
    handleCloseModal: handleOnCloseModal,
    defaultEntity: DEFAULT_PARTNERSHIP,
    type: 'partnership',
    entityIdParamKey: 'partnershipId',
    validateOnNew: validatePartnership,
    validateOnUpdate: validatePartnership,
    validatePromotions: validatePromotions,
    validateOnActivate: validateCompletePartnership,
    reviewStep: 3,
    rulesStep: 2,
  })

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)

  const prevCurrentStep = usePrevious(currentStep)

  let modalInnerRef = useRef(null)

  useEffect(() => {
    if (modalInnerRef && currentStep !== prevCurrentStep && modalInnerRef?.scrollTo) {
      modalInnerRef.scrollTo(0, 0)
    }
  }, [modalInnerRef, currentStep, prevCurrentStep])

  /********************************/
  /******* Memoized Values ********/
  /********************************/
  const PROGRESS_STEPS = useMemo(
    () => [
      {
        stepNumber: 1,
        label: t('wizard.general'),
        longLabel: t('wizard.generalSettings'),
      },
      {
        stepNumber: 2,
        label: t('wizard.rulesAndCourses'),
        longLabel: t('wizard.rulesAndCourses'),
      },
      {
        stepNumber: 3,
        label: t('wizard.review'),
        longLabel: t('wizard.review'),
      },
    ],
    [t]
  )

  const isCreated = useMemo(() => {
    return currentPartnership && !!currentPartnership.state
  }, [currentPartnership])

  /**********************************/
  /*********** Methods **************/
  /**********************************/
  const handleOnSave = () => {
    if (loading) return
    const { state } = currentPartnership

    switch (currentStep) {
      case 1:
        if (!state) {
          createNewPartnership(handleOnCreated)
        } else {
          updatePartnership(handleOnUpdated)
        }
        // --- COMMENT THIS FOR INITAL PAYMENT LINKS RELEASE, WILL GET ADDED BACK LATER
        // if (values?.automatic_payment_link) {
        //   track({
        //     event_type: AMPLITUDE_EVENTS.PAYMENT_LINKS.AUTOMATIC_LINKS_ACTIVATED,
        //     event_properties: {
        //       [AMPLITUDE_EVENTS.PAYMENT_LINKS.PARTNER_NAME]: values?.name,
        //     },
        //   })
        // }

        break
      case 2:
        savePromotions(handleOnPromotionsSaved)
        break
      default:
        break
    }
  }

  const handleOnCreated = () => {}

  const handleOnUpdated = (response) => {
    if (response?.detail && response?.violations) {
      addToast(response?.violations[0]?.message, { appearance: 'error' })
    }
  }

  const handleOnPromotionsSaved = () => {}

  const handleOnCancel = () => {
    cancelExistingEntity((success) => {
      if (success) {
        addToast(<p>{t('wizard.partnershipCanceled')}.</p>, { appearance: 'warning' })
        handleOnCloseModal()
      }
    })
  }

  const handleOnActivate = () => {
    activateExistingEntity((success) => {
      if (success) {
        const { name } = currentPartnership
        addToast(
          <p>
            {t('wizard.partnership')}
            {name ? <span> {name} </span> : ''}
            {t('wizard.isActivated').toLowerCase()}.
          </p>,
          { appearance: 'success' }
        )
      }
    })
  }

  const handleOnDelete = () => {
    deleteExistingEntity((success) => {
      if (success) {
        addToast(<p>{t('wizard.partnershipDeleted')}.</p>, { appearance: 'warning' })
        history.push('/partnerships', { shouldReload: true })
      }
    })
  }

  /**********************************/
  /*********** Render  **************/
  /**********************************/

  const renderHeader = () => (
    <>
      <StepsProgress
        currentEntity={currentPartnership}
        currentStep={currentStep}
        steps={PROGRESS_STEPS}
        goToStep={setStep}
        editing={currentPartnership.state && currentPartnership.state === 'enabled' ? true : false}
        globalErrors={globalErrors}
        errors={errors}
        type="partnership"
      />
      <Separator />
      <VersionAndName currentEntity={currentPartnership} currentStep={currentStep} />
      <Separator />
      <Navigation
        currentEntity={currentPartnership}
        steps={PROGRESS_STEPS}
        currentStep={currentStep}
        onBack={decrementStep}
        onForward={incrementStep}
        disabled={currentPartnership && currentPartnership.state !== 'created'}
      />
      <Separator />
    </>
  )

  return (
    <React.Fragment>
      <ModalContainer
        loading={loading}
        width="twoFifth"
        onClose={handleOnCloseModal}
        headerChildren={renderHeader()}
        onInnerRef={(ref) => (modalInnerRef = ref)}
      >
        {currentStep === 1 ? (
          <GeneralSettings
            values={values}
            errors={errors}
            onSetPartnershipValue={setEntityValue}
            onClearPartnershipvalue={clearEntityValue}
            isCreated={isCreated}
            disabled={loading}
          />
        ) : null}
        {currentStep === 2 ? (
          <RulesAndCourses
            disabled={!currentPartnership || currentPartnership?.state !== 'created'}
            currentPartnership={currentPartnership}
            values={values}
            errors={errors}
            onSetRuleValue={setRuleValue}
            onClearRule={clearRule}
            onSetActionValue={setActionValue}
            onClearAction={clearAction}
          />
        ) : null}
        {currentStep === 3 ? (
          <Review values={values} currentPartnership={currentPartnership} />
        ) : null}
        <PartnershipFooter
          currentStep={currentStep}
          currentEntity={currentPartnership}
          globalErrors={globalErrors}
          canSave={canSave}
          onSave={handleOnSave}
          onActivate={handleOnActivate}
          onDelete={() => setShowDeleteConfirmation(true)}
          onCancel={() => setShowCancelConfirmation(true)}
          state={currentPartnership && currentPartnership.state}
        />
      </ModalContainer>
      <ConfirmPopup
        visible={showDeleteConfirmation}
        onReject={() => setShowDeleteConfirmation(false)}
        onClose={() => setShowDeleteConfirmation(false)}
        onAccept={handleOnDelete}
        acceptTheme="danger"
        title={t('wizard.deletePartnership')}
        titleIcon={TrashCan}
        rejectText={t('cancel')}
        text={t('wizard.thisActionCanNotBeUndone') + '.'}
        acceptText={t('settings.delete')}
      />
      <ConfirmPopup
        visible={showCancelConfirmation}
        onReject={() => setShowCancelConfirmation(false)}
        onClose={() => setShowCancelConfirmation(false)}
        onAccept={handleOnCancel}
        acceptTheme="danger"
        title={t('wizard.cancelPartnership')}
        titleIcon={TrashCan}
        rejectText={t('cancel')}
        text={t('wizard.thisActionCanNotBeUndone') + '.'}
        acceptText={t('confirm')}
      />
    </React.Fragment>
  )
}

export default PartnershipWizardModal
