export enum LanguageKeys {
  EN = 'en',
  SV = 'sv',
  DE = 'de',
  DA = 'da',
  FI = 'fi',
}

export enum I18nextNamespaces {
  DEFAULT = 'translations',
}
