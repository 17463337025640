import React, { Component } from 'react'
import style from './style.module.scss'

export default class SettingComponent extends Component {
  renderTitle() {}

  renderContent() {}

  renderButtons() {}

  render() {
    return (
      <div className={style.container}>
        <div className={style.title}>{this.renderTitle()}</div>
        <div className={style.content}>{this.renderContent()}</div>
        <div className={style.buttons}>{this.renderButtons()}</div>
      </div>
    )
  }
}
