import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const ItemBox = ({ children, className, style }) => {
  return <div className={cx(styles.container, styles[style], className)}>{children}</div>
}

ItemBox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOf(['light', 'dark']),
}

ItemBox.defaultProps = {
  className: '',
  style: 'light',
}

export default ItemBox
