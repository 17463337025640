import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import DOMHelpers from '@sweetspot/club-portal-legacy/helpers/DOMHelpers'

import style from './style.module.scss'

import SEARCH_ICON from '@sweetspot/club-portal-legacy/resources/images/search-icon.svg'
import CARET_ICON from '@sweetspot/club-portal-legacy/resources/images/caret-down.svg'

/**
 * This component creates an input with a dropdown list of selectable items. The options are
 * searchable by typing in the input
 */
export default class SearchDropdown extends Component {
  state = {
    isOpen: false,
    searchString: '',
    selectedId: null,
  }

  componentDidMount = () => {
    this.initialize()
  }

  initialize = () => {
    const { initialId, isOpen, values } = this.props

    document.addEventListener('click', this.handleClickOut)

    // if has a selected option write it to the input
    if (initialId) {
      let selectedValue = values.find((value) => value.id === initialId)
      this.setState({
        selectedId: initialId,
        searchString: selectedValue || '',
        isOpen,
      })
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOut)
  }

  handleClickOut = (event) => {
    if (!DOMHelpers.didClickInsideArray([this._input, this._listContainer], event)) {
      const { temp } = this.state

      this.setState({
        isOpen: false,
        searchString: temp || '',
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  toggleOpen = () => {
    const { isOpen, searchString } = this.state

    if (!isOpen) this.setState({ searchString: '', temp: searchString })
    this.setState({ isOpen: !isOpen })
  }

  handleChange = (value) => {
    this.setState({
      searchString: value,
      temp: '',
    })
  }

  handleSelect = (id) => {
    const { values, onSelect } = this.props

    let selectedValue = values.find((value) => value.id === id)

    this.setState({
      selectedId: id,
      isOpen: false,
      searchString: selectedValue.name,
      temp: selectedValue.name,
    })

    if (onSelect) onSelect(id)
  }

  renderList = () => {
    const { searchString } = this.state
    const { values } = this.props

    const filtered = values.filter(
      (value) => !searchString || value.name.toLowerCase().includes(searchString.toLowerCase())
    )

    return (
      <div className={style.listContainer} ref={(container) => (this._listContainer = container)}>
        <div className={style.list}>
          {filtered.map((value) => {
            return (
              <div
                key={value.id}
                className={style.listItem}
                onClick={() => this.handleSelect(value.id)}
              >
                {value.name}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render = () => {
    const { isOpen, searchString } = this.state
    const { width, placeholder } = this.props

    let containerWidth = `${width}px` ? width : '100%'

    return (
      <div
        style={{ width: containerWidth }}
        className={classnames(style.container, { [style.open]: isOpen })}
      >
        <div
          ref={(input) => (this._input = input)}
          className={style.searchInput}
          onClick={this.toggleOpen}
        >
          <TextInputField
            type="text"
            placeholder={placeholder}
            prefix={<img src={SEARCH_ICON} alt="search" />}
            suffix={<img src={CARET_ICON} alt="caret" />}
            value={searchString}
            onChange={this.handleChange}
          />
        </div>

        {isOpen && this.renderList()}
      </div>
    )
  }
}

SearchDropdown.propTypes = {
  /**
   * List of options in the format `[{ name: String, id: Number }, ...]`
   *
   * If the name starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  values: PropTypes.array.isRequired,
  /**
   * ID of the initially selected option (optional)
   */
  initialId: PropTypes.number,
  /**
   * width of the select box (optional)
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Bool to update isOpen state (optional)
   */
  isOpen: PropTypes.bool,
  /**
   * Placeholder of an input (optional)
   */
  placeholder: PropTypes.string,
  /**
   * Function to run when selecting an option
   *
   * @param {Number} ID selected ID
   */
  onSelect: PropTypes.func,
}

SearchDropdown.defaultProps = {
  initialId: 0,
  width: '100%',
  placeholder: '',
  isOpen: false,
  onSelect: () => {},
}
