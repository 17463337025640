import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { TimezoneInfo } from '@sweetspot/club-portal-legacy/components/TimezoneInfo'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSelectedCourse } from '../../context'

const GolfCourseSelect = ({ selectedCourse }) => {
  const { t } = useTranslation()
  const { selectGolfCourse } = useSelectedCourse()
  const [courseOptions, setCourseOptions] = useState([])
  const golfCourses = useSelector((state) => state.golfCourse.list)

  const possibleTypes = new Set(golfCourses.map((course) => course.type))
  const allVenueTypes = [{ name: t('editPeriodsNew.allVenueTypes'), id: 'all' }]

  const typeOptions = [
    ...allVenueTypes,
    ...getGolfCourseTypes(t).filter(
      (golfCourseType) => !golfCourseType.id || possibleTypes.has(golfCourseType.id)
    ),
  ]

  const onCourseTypeChange = (value) => {
    const selectedGolfCourse = golfCourses.find((course) => course.type === value)

    if (value !== 'all' && selectedCourse.type !== value && selectedGolfCourse?.id) {
      selectGolfCourse(selectedGolfCourse.id)
    }

    setCourseOptions(golfCourses.filter((course) => value === 'all' || course.type === value))
  }

  const onChangeCourse = (value) => {
    selectGolfCourse(+value)
  }

  useEffect(() => {
    if (golfCourses) {
      setCourseOptions(golfCourses)
    }
  }, [golfCourses])

  return (
    <div className={'mx-0 my-2.5 flex gap-5 pl-2.5'}>
      <div className={'rounded-md border border-gray-300'}>
        <DropdownSelect
          values={typeOptions}
          selectedId={'all'}
          initialId={'all'}
          onSelect={onCourseTypeChange}
          containerClassName={'w-48'}
        />
      </div>

      <div className={'rounded-md border border-gray-300'}>
        <DropdownSelect
          values={courseOptions}
          selectedId={selectedCourse?.id}
          initialId={selectedCourse?.id}
          onSelect={onChangeCourse}
          containerClassName={'w-72'}
        />
      </div>

      {selectedCourse ? <TimezoneInfo timezone={selectedCourse.timezone} /> : null}
    </div>
  )
}

export default GolfCourseSelect

const getGolfCourseTypes = (t) => [
  { name: t('editPeriodsNew.courses'), id: 'course' },
  { name: t('editPeriodsNew.simulators'), id: 'simulator' },
  { name: t('editPeriodsNew.drivingRanges'), id: 'driving_range' },
  { name: t('editPeriodsNew.pro'), id: 'pro' },
  { name: t('editPeriodsNew.other'), id: 'other' },
]
