import React from 'react'
import PopupModal from '@sweetspot/sweetspot-js/common/components/PopupModal'
import styles from './styles.module.scss'
import cx from 'classnames'

const PopupWrapper = ({ className = '', ...rest }) => {
  return (
    <PopupModal
      containerClassName={cx(styles.wrapperContainer, className)}
      isFullsizeOverlay={true}
      disableShadow={true}
      {...rest}
    />
  )
}

export default PopupWrapper
