export const ITEM_GROUPS = {
  SLOT: 'with_slot',
  PAYMENT: 'with_payment',
  PAYMENT_DETAILS: 'with_payment_details',
  ADJUSTMENTS: 'with_adjustments',
  PRODUCT_VARIANT: 'with_product_variant',
  MEMBERSHIP_CARD: 'with_membership_card',
  PROMOTION_COUPONS: 'with_promotion_coupons',
  FEDERATION_PROFILES: 'with_customer_federation_profiles',
  WEB_BOOKING: 'web_booking', // For  anonymous player names if you are not part of booking
  ALL: 'ALL',
}
