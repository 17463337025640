import cx from 'classnames'

// Hook for extending styles.module.scss via props.styles
// Please check <target component styles.module.scss> before passing <styles> prop

// Uses single <styles> object prop
// styles={{
//   container: cx(styles.newContainer)
// }}
export const useStyles = (newStyles) => {
  if (!newStyles || typeof newStyles !== 'object') return cx
  return (...defaultStyles) => {
    if (typeof defaultStyles[0] !== 'string') {
      return cx(defaultStyles)
    }
    const className = defaultStyles[0].slice(7, defaultStyles[0].indexOf('__'))
    return cx(defaultStyles, newStyles[className])
  }
}
