import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Query membership reservation policies
 *
 */
export const queryMembershipPolicies = (
  id,
  query = {
    club: null,
    'club[]': null,
    name: null,
    context: null,
    'context[]': null,
    coupon_based: null,
    'order[priority]': null,
    'state[]': null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/memberships/${id}/reservation-policies`,
      query,
    },
    {
      skipNull: true,
    }
  )

  return request(url)
}

/**
 * Create single reservation policy
 *
 * @param {string} uuid
 * @param {object} body
 */
export const createReservationPolicy = (uuid, body) => {
  const url = `${getApiPlatformBaseUrl()}/memberships/${uuid}/reservation-policies`

  return request(url, { method: 'PUT', body })
}

/**
 * Update reservation policy
 *
 * @param {string} uuid
 * @param {object} body
 */
export const updatePolicy = (uuid, body) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policies/${uuid}`

  return request(url, { method: 'PUT', body })
}

/**
 * Archive policy
 *
 * @param {string|number} promotionId
 */
export const archivePolicy = (policyId) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policies/${policyId}/archive`

  return request(url, { method: 'PUT' })
}

/**
 * Create new checker in policy by id
 *
 * @param {string|number} policyId
 */
export const createNewChecker = (
  policyId,
  body = {
    type: null,
    configuration: {},
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policies/${policyId}/checker`
  return request(url, { method: 'PUT', body })
}

/**
 * Update an checker
 *
 * @param {string|number} checkerId
 */
export const updateChecker = (checkerId, body = {}) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policy-checkers/${checkerId}`
  return request(url, { method: 'PUT', body })
}

/**
 * Deletes an checker
 *
 * @param {string|number} checkerId
 */
export const deleteChecker = (checkerId) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policy-checkers/${checkerId}`
  return request(url, { method: 'DELETE' })
}

/**
 * Create new rule in reservation policy by id
 *
 * @param {string|number} policyId
 */
export const createNewRule = (
  policyId,
  body = {
    type: null,
    configuration: {},
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policies/${policyId}/rule`
  return request(url, { method: 'PUT', body })
}

/**
 * Update an policy rule
 *
 * @param {string|number} ruleId
 */
export const updateRule = (ruleId, body = {}) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policy-rules/${ruleId}`
  return request(url, { method: 'PUT', body })
}

/**
 * Delete rule
 *
 * @param {string|number} ruleId
 */
export const deleteRule = (ruleId) => {
  const url = `${getApiPlatformBaseUrl()}/reservation-policy-rules/${ruleId}`
  return request(url, { method: 'DELETE' })
}
