import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons'

export default class OrderArrows extends Component {
  constructor(props) {
    super(props)

    this.handleClickUp = this.handleClickUp.bind(this)
    this.handleClickDown = this.handleClickDown.bind(this)
  }

  handleClickUp() {
    if (this.props.onClickUp) this.props.onClickUp()
  }

  handleClickDown() {
    if (this.props.onClickDown) this.props.onClickDown()
  }

  render() {
    return (
      <div className={style.container}>
        <div className={style.arrowContainer} onClick={this.handleClickUp}>
          <FontAwesomeIcon icon={faArrowUp} />
        </div>
        <div className={style.arrowContainer} onClick={this.handleClickDown}>
          <FontAwesomeIcon icon={faArrowDown} />
        </div>
      </div>
    )
  }
}

OrderArrows.propTypes = {
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func,
}
