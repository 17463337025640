import React from 'react'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'
import { FederationPlayer } from '@sweetspot/shared/types'

type FederationInfoProps = {
  federationInfo: FederationPlayer | null
}

const FederationInfo: React.FC<FederationInfoProps> = ({ federationInfo }) => {
  return (
    <div className={style.container}>
      <div className={style.field}>
        <Text textId="communication.email" />
        {federationInfo?.email || '-'}
      </div>

      <div className={style.field}>
        <Text textId="phone" />
        {federationInfo?.phone || '-'}
      </div>

      <div className={style.field}>
        <Text textId="homeClub" />
        {federationInfo?.homeClub || '-'}
      </div>
    </div>
  )
}

export default FederationInfo
