import _filter from 'lodash/filter'
import _concat from 'lodash/concat'
import _sortBy from 'lodash/sortBy'
import { STATE } from '@sweetspot/sweetspot-js/features/cooperations/hooks/useCooperations'

export const filterGolfClubs = (golfClubList = [], cooperations = [], currentClubId) => {
  if (!Array.isArray(golfClubList) || !Array.isArray(cooperations)) {
    throw new Error(`[func filterGolfClubs]: Invalid input`)
  }
  const hostClubs = cooperations.map((c) => c.host_club.id)
  const partnerClubs = cooperations.map((c) => c.partner_club.id)
  return _filter(golfClubList, function (i) {
    return !hostClubs.includes(i.id) && !partnerClubs.includes(i.id) && i.id !== currentClubId
  })
}

export const filterAvailableMemberships = (allMemberships, sharedMemberships) => {
  if (!Array.isArray(allMemberships) || !Array.isArray(sharedMemberships)) {
    throw new Error(`[func filterAvailableMemberships]: Invalid input`)
  }
  sharedMemberships = sharedMemberships.map((i) => i.uuid)
  return _filter(allMemberships, function (i) {
    return !sharedMemberships.includes(i.uuid)
  })
}

export const filterCooperationsByID = (cooperations = [], golfClubID) => {
  if (!Array.isArray(cooperations) || !golfClubID) {
    throw new Error(`[func filterCooperationsByID]: Invalid input`)
  }
  return _filter(cooperations, function (i) {
    return i.host_club.id === golfClubID || i.partner_club.id === golfClubID
  })
}

// Only for preselected data (useCooperations select)
export const searchSortCooperations = (list = [], searchText = '') => {
  if (searchText.length > 0) {
    list = list.filter((i) => {
      return i['name'].toLowerCase().includes(searchText.toLowerCase())
    })
  }
  let count = 0
  let status_new = []
  let status_awaiting = []
  let status_confirmed = []
  let status_canceled = []
  while (count < list.length) {
    if (list[count].state === STATE.NEW) status_new.push(list[count])
    if (list[count].state === STATE.AWAITING_CONFIRMATION) status_awaiting.push(list[count])
    if (list[count].state === STATE.CONFIRMED) status_confirmed.push(list[count])
    if (list[count].state === STATE.CANCELED) status_confirmed.push(list[count])
    count++
  }
  return _concat(
    _sortBy(status_new, 'name'),
    _sortBy(status_awaiting, 'name'),
    _sortBy(status_confirmed, 'name'),
    _sortBy(status_canceled, 'name')
  )
}
