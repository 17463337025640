import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

import i18n from 'i18next'

import { getLocalStorage, setLocalStorage } from '@sweetspot/shared/util/local-storage'
import CloseIcon from '@sweetspot/sweetspot-js/common/components/CloseIcon'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import SimpleGrid from '@sweetspot/sweetspot-js/common/components/SimpleGrid'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'

import {
  getScoreCard,
  createScoreCard,
  getClubInfo,
  updateScoreCard,
  printScoreCard,
  updatePlayerInScorecard,
} from '@sweetspot/sweetspot-js/features/scorecard/services/api-platform'

import styles from './styles.module.scss'
import { useMutation, useQuery } from 'react-query'
import { GENDER_KEYS, ONTAG_QUERIES } from '@sweetspot/shared/util/constants'

// nn or nn(,.)n with leading + or not
const hcpRegex = new RegExp(/(^[+]?([0-9]{0,2})$)|(^[+]?([0-9]{1,2})[.,]([0-9]?)$)/)

const ScoreCardPrint = (props) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const scoreCardType = [
    { id: 0, label: i18n.t('words.paper') },
    { id: 1, label: i18n.t('words.digital') },
    { id: 2, label: i18n.t('words.both') },
  ]

  const [printers, setPrinters] = useState([])
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [selectedPrinter, setSelectedPrinter] = useState(null)
  const [selectedScoreCardType, setSelectedScoreCardType] = useState(scoreCardType[0])
  const [shouldCreateScorecard, setShouldCreateScorecard] = useState(false)
  const [scoreCard, setScorecard] = useState(null)
  const [participants, setParticipants] = useState([])
  const [shouldSelectAll, setShouldSelectAll] = useState(true)
  const [isPrinting, setIsPrinting] = useState(false)

  const { isFetching: isFetchingScorecard } = useQuery(
    [ONTAG_QUERIES.SCORECARD, props.teeTime],
    () => getScoreCard(props.teeTime.uuid),
    {
      enabled: !!props.teeTime,
      onSuccess: (data) => {
        if (!data?.length) {
          setShouldCreateScorecard(true)
        } else {
          setScorecard(data[0])
        }
      },
    }
  )

  const slotsFromBooking = []
  for (const booking of Object.values(props.bookings)) {
    for (const item of booking.items) {
      if (item.slot) slotsFromBooking.push(item.slot)
    }
  }

  useEffect(() => {
    selectAll(true)
  }, [scoreCard])

  useQuery(
    [ONTAG_QUERIES.CREATE_SCORECARD, shouldCreateScorecard],
    () =>
      createScoreCard({
        tee_time: props.teeTime.uuid,
      }),
    {
      enabled: !!props.teeTime && shouldCreateScorecard,
      onSuccess: (data) => {
        setScorecard(data)
      },
    }
  )

  useQuery([ONTAG_QUERIES.ACTUALIZE, scoreCard?.uuid], () => updateScoreCard(scoreCard?.uuid), {
    enabled: !!scoreCard && !!scoreCard.uuid,
    onSuccess: (data) => {
      if (data?.players?.length) {
        const modified = data.players.map((player) => {
          let bookingNum = 0
          const slotFromBooking = slotsFromBooking.find((slot) => slot.uuid === player.slot.uuid)
          const genderBooking = slotFromBooking?.player?.gender?.toLowerCase()
          return {
            ...player,
            selected: true,
            golf_id: player?.golf_id,
            exact_hcp: player?.exact_hcp || '36.0',
            booking: ['A', 'B', 'C', 'D'][bookingNum],
            tee: tees.find(
              (tee) =>
                tee.tee_id === player.start_tee_id &&
                GENDER_KEYS.find((key) => key.stringValue === player.gender)?.gitValue ===
                  tee.gender
            ),
            slope: player?.gender === 'woman' ? 0 : 1,
            isStub: slotFromBooking?.type === 'stub_player',
            genderFromBooking: genderBooking,
          }
        })
        setScorecard({ ...data, players: modified })
        setParticipants(modified)
      }
    },
    onError: (err) => {
      addToast(t('errors.somethingWentWrong', { id: 0 }), { appearance: 'error' })
    },
  })

  const { isFetching: isFetchingClubInfo } = useQuery(
    [ONTAG_QUERIES.CLUBINFO, props.golfClub],
    () => getClubInfo(props.golfClub.git_id),
    {
      onSuccess: (clubInfo) => {
        let id = 1
        let filteredPrinters = [{ id: -1, label: i18n.t('sentences.noPrinterWasFound') }]
        if (Array.isArray(clubInfo.printer_servers) && clubInfo.printer_servers.length) {
          if (clubInfo.printer_servers.length) {
            filteredPrinters = clubInfo.printer_servers
              .map((printer) => {
                return {
                  id: id++,
                  guid: printer.id,
                  label: printer.name,
                  disabled: !printer.is_connected,
                  status: {
                    active: printer.is_connected,
                    text: !printer.is_connected && t('words.offline'),
                  },
                }
              })
              .sort((a, b) => a.disabled - b.disabled)
          } else {
            filteredPrinters = [{ id: -1, label: i18n.t('sentences.noPrintersConnected') }]
          }
          const selectedPrinter = getDefaultPrinter()

          if (filteredPrinters.length) {
            setPrinters(filteredPrinters)
            setSelectedPrinter(
              filteredPrinters.find((x) => x.guid === selectedPrinter?.guid)
                ? selectedPrinter
                : filteredPrinters[0]
            )
          } else {
            addToast(t('sentences.noActivePrintersFound'), { appearance: 'error' })
          }
        }
      },
      onError: (err) => {
        addToast(t('sentences.noActivePrintersFound'), { appearance: 'error' })
      },
    }
  )

  const updatePlayer = useMutation({
    mutationFn: (playerData) => {
      const payload = {
        player: playerData.uuid,
        last_name: playerData.last_name ?? ' ',
        first_name: playerData.first_name ?? '-',
        golf_id: playerData.golf_id,
        hcp: playerData.exact_hcp,
        age: playerData.age ?? 18,
        start_tee_id: playerData.start_tee_id,
        start_order: playerData.start_order ?? 1,
        gender:
          playerData.genderFromBooking ??
          GENDER_KEYS.find((x) => x.gitValue === playerData.tee?.gender)?.stringValue,
      }
      return updatePlayerInScorecard(scoreCard?.uuid, payload)
    },
    onSettled: () => {},
  })

  const print = useMutation(
    (players) => {
      if (!scoreCard) return
      return printScoreCard({
        scorecard: scoreCard.uuid,
        players: players,
        printer_server_guid: selectedPrinter?.guid,
        desired_score_card_type: selectedScoreCardType.id,
      })
    },
    {
      onSuccess: () => {
        addToast(t('sentences.scorecardWasPrinted'), { appearance: 'success' })
        selectDefaultPrinter(selectedPrinter)
      },
      onError: (err) => {
        if (err.status === 400) {
          addToast(t('errors.somethingWentWrong', { id: 0 }), { appearance: 'error' })
        } else {
          addToast(t('sentences.couldNotConnectToPrinter'), { appearance: 'warning' })
        }
      },
    }
  )

  const tees = useMemo(() => {
    if (!props.golfCourse) return []
    let tempArray = [...props.golfCourse.course_tees]
    return tempArray.sort((tee1, tee2) => tee1.tee_alias - tee2.tee_alias)
  }, [props.golfCourse])

  const selectPrinter = (id) => {
    const selectedPrinter = printers.find((x) => x.id === id)
    setSelectedPrinter(selectedPrinter)
  }

  const selectScoreCardType = (id) => {
    const selectedScoreCardType = scoreCardType.find((type) => type.id === id)
    setSelectedScoreCardType(selectedScoreCardType)
  }

  const selectDefaultPrinter = (printer) => {
    setLocalStorage('defaultPrinter', JSON.stringify(printer))
  }

  const getDefaultPrinter = () => {
    let printer = getLocalStorage('defaultPrinter')
    if (!printer) return null
    return JSON.parse(printer)
  }

  const handlePrintScoreCard = (player) => {
    const selected = participants.filter((x) => selectedParticipants.includes(x.id))
    setIsPrinting(true)

    // Make sure the player we clicked "Print" on is first in the scorecard
    const firstPlayer = selected.find((x) => x.id === player.id)
    const players = []
    players.push(firstPlayer.uuid)

    // Handle rest of players
    const filteredPlayers = selected.filter((player) => player.id !== firstPlayer.id)
    filteredPlayers.forEach((player) => {
      players.push(player.uuid)
    })
    Promise.all(participants.map((player) => updatePlayer.mutateAsync(player)))
      .then(async () => {
        await print.mutateAsync(players)
      })
      .catch((err) => {
        addToast(t('errors.somethingWentWrong', { id: 0 }), { appearance: 'error' })
      })
      .finally(() => setIsPrinting(false))
  }

  const updatePlayerTee = (participant, startTee, hasSelectedTee = false) => {
    const modified = participants.map((player) => {
      if (player.uuid === participant.uuid) {
        player.selected = hasSelectedTee
        player.start_tee_id = startTee.tee_id
        player.tee = startTee
      }
      return player
    })
    setParticipants(modified)
  }

  const updateHcp = (value, participant) => {
    if (hcpRegex.test(value)) {
      const modified = participants.map((player) => {
        if (player.uuid === participant.uuid) {
          player.exact_hcp = value
        }
        return player
      })
      setParticipants(modified)
    }
  }

  const updateSelection = (participant) => {
    if (
      !participant.exact_hcp ||
      (isNaN(participant.exact_hcp) && isNaN(parseFloat(participant.exact_hcp)))
    ) {
      setSelectedParticipants(selectedParticipants.filter((x) => x !== participant.id))
    }
  }

  const selectParticipant = (id, value) => {
    if (value === true) {
      const part = participants.find((x) => x.id === id)
      if (part && part.exact_hcp && part.start_tee_id) {
        if (!isNaN(part.exact_hcp) && !isNaN(parseFloat(part.exact_hcp))) {
          setSelectedParticipants([...selectedParticipants, id])
        }
      }
    } else {
      setSelectedParticipants(selectedParticipants.filter((x) => x !== id))
    }
  }

  const selectAll = (value) => {
    setShouldSelectAll(value)
    if (value && participants?.length && selectedParticipants.length === 0) {
      const selected = participants.filter((x) => {
        return x && x.exact_hcp && x.start_tee_id
      })
      setSelectedParticipants(selected.map((x) => x.id))
    } else {
      setSelectedParticipants([])
    }
  }

  const isGenderUndefined = (participant) => {
    return (
      participant.isStub ||
      !participant.genderFromBooking ||
      participant.genderFromBooking === 'other'
    )
  }

  const getGenderName = (tee, participant) => {
    if (!tee) return ''
    let gName = tee.color

    if (isGenderUndefined(participant)) {
      gName += ' '
      gName += t(GENDER_KEYS.find((x) => x.gitValue === tee.gender).translationKey).toLowerCase()
    }
    return gName
  }

  const genderTees = (participant) => {
    // Make sure to only include tees with slopevalue and courserating from git
    let teesToUse = tees.filter((tee) => tee.slope_value !== 0 && tee.course_rating !== '0.0')
    if (isGenderUndefined(participant)) {
      return teesToUse
    } else {
      const genderKey = GENDER_KEYS.find((x) => x.stringValue === participant.gender)
      let gendertees = []
      for (let tee of teesToUse) {
        if (tee.gender === genderKey.gitValue) {
          gendertees.push(tee)
        }
      }
      return gendertees
    }
  }

  return (
    <div className={styles.container}>
      {props.onRequestClose && (
        <CloseIcon className={styles.closeIcon} onClick={props.onRequestClose} />
      )}
      <SimpleGrid wrapperClassName={styles.grid}>
        <div className="row-header">
          <div className={cx(styles.selected)}>
            <Checkbox
              containerClassName={cx(styles.selectCheckbox)}
              value={shouldSelectAll}
              onChange={(val) => selectAll(val)}
            />
          </div>
          <p className={cx(styles.name)}>{i18n.t('words.name')}</p>
          <p className={cx(styles.golfId)}>{i18n.t('words.golfId')}</p>
          <p className={cx(styles.hcp)}>{i18n.t('words.hcp')}</p>
          <p className={cx(styles.gender)}>{i18n.t('words.gender')}</p>
          <p className={cx(styles.tee)}>{i18n.t('words.tee')}</p>
          <p className={cx(styles.print)}>&nbsp;</p>
        </div>
        {participants?.map((part) => {
          const { golf_id, tee: playerTee, id } = part

          return (
            <div className={cx(styles.row, 'row-body')} key={id}>
              <div className={cx(styles.selected)}>
                <Checkbox
                  containerClassName={cx(styles.selectCheckbox)}
                  value={selectedParticipants.includes(id)}
                  onChange={(val) => selectParticipant(id, val)}
                />
              </div>
              <p className={cx(styles.name)}>{`${part?.first_name || ''} ${
                part?.last_name || ''
              }`}</p>
              <p className={cx(styles.golfId)}>{golf_id}</p>
              <div className={cx(styles.hcp)}>
                <TextInputField
                  value={part.exact_hcp}
                  containerClassName={styles.input}
                  onChange={(value) => updateHcp(value, part)}
                  onInputBlur={() => updateSelection(part)}
                />
              </div>
              <p className={cx(styles.gender)}>
                {part.isStub || !part.genderFromBooking ? '-' : part.genderFromBooking}
              </p>
              <div className={cx(styles.tee)}>
                <Button
                  className={cx(styles.gridButton, styles.teeButton)}
                  size="medium"
                  dropdownStyle="simple"
                  text={t('sentences.selectStartTee')}
                  dropdownValue={getGenderName(playerTee, part)}
                  dropdownOptions={genderTees(part).map((t) => ({
                    ...t,
                    label: getGenderName(t, part),
                  }))}
                  dropdownOptionOnClick={(item) => updatePlayerTee(part, item, true)}
                />
              </div>
              <div className={cx(styles.print)}>
                <Button
                  size="medium"
                  className={cx(styles.gridButton, styles.printButton)}
                  text={i18n.t('words.print')}
                  onClick={() => handlePrintScoreCard(part)}
                  loading={isPrinting}
                  disabled={
                    !part.exact_hcp ||
                    !part.start_tee_id ||
                    !selectedPrinter ||
                    selectedPrinter.id === -1 ||
                    (selectedPrinter.disabled && selectedScoreCardType?.id !== 1) ||
                    !selectedParticipants.find((x) => x === id)
                  }
                />
              </div>
            </div>
          )
        })}
      </SimpleGrid>
      <div className={cx(styles.settingsRow)}>
        <Button
          className={cx(styles.scoreCardType)}
          loading={isFetchingScorecard}
          loaderStyle="pulse"
          size="larger"
          disabled={printers.length === 0}
          dropdownStyle="simple"
          dropdownOptions={scoreCardType}
          dropdownValue={selectedScoreCardType.label}
          dropdownOptionOnClick={(item) => selectScoreCardType(item.id)}
        />
        <Button
          className={cx(styles.selectPrinter)}
          loading={isFetchingClubInfo}
          loaderStyle="pulse"
          size="larger"
          disabled={printers.length === 0 || selectedScoreCardType.id === 1}
          dropdownStyle="simple"
          dropdownOptions={printers}
          dropdownValue={selectedPrinter?.label || i18n.t('sentences.selectPrinter')}
          dropdownOptionOnClick={(item) => selectPrinter(item.id)}
        />
      </div>
    </div>
  )
}

ScoreCardPrint.propTypes = {
  bookings: PropTypes.shape({}),
  golfCourse: PropTypes.shape({
    git_id: PropTypes.string,
    course_tees: PropTypes.array,
  }),
  golfClub: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  teeTime: PropTypes.shape({}),
  onRequestClose: PropTypes.func,
}

export default ScoreCardPrint
