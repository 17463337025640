import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

const TextArea = (props) => {
  const { hasPrefix, hasSuffix, autoFocus, onEnterPress, autoSize, ...rest } = props
  const inputRef = useRef()

  useEffect(() => {
    if (props.innerRef) {
      props.innerRef.current = inputRef.current
    }
  }, [props.innerRef])

  useEffect(() => {
    if (autoFocus) {
      if (inputRef?.current?.focus) inputRef.current.focus()
    } else {
      if (inputRef?.current?.blur) inputRef.current.blur()
    }
  }, [autoFocus])

  useEffect(() => {
    if (!autoSize) return
    const input = inputRef.current
    input.style.height = '0px'
    input.style.height = `${parseInt(input.scrollHeight + 5)}px`
  }, [props.value, autoSize])

  useEffect(() => {
    const current = inputRef.current

    const handleOnEnterInInput = (e) => {
      if (
        (e?.keyCode === 13 || e?.which === 13 || e?.key === 'Enter' || e?.code === 'Enter') &&
        onEnterPress
      ) {
        e.preventDefault()
        e.stopPropagation()
        onEnterPress(e.target.value)
        return false
      }
    }

    if (current?.addEventListener) {
      current.addEventListener('keydown', handleOnEnterInInput)

      return () => current.removeEventListener('keydown', handleOnEnterInInput)
    }
  }, [inputRef, onEnterPress])

  return (
    <textarea
      autoComplete="off"
      ref={inputRef}
      {...rest}
      className={cx(
        styles.textInput,
        styles.borderDefault,
        {
          [styles.borderError]: props.error,
          [styles.borderSuccess]: props.success,
          [styles.borderDisabled]: props.disabled,
          [styles.textInputDisabled]: props.disabled,
          [styles.borderFilled]: props.value,
        },
        {
          [styles.hasPrefix]: hasPrefix,
          [styles.hasSuffix]: hasSuffix,
        },
        'no-override'
      )}
      onKeyDown={(e) => rest.type === 'number' && ['e', 'E'].includes(e.key) && e.preventDefault()}
    />
  )
}

TextArea.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.string,
  success: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'date']),
  hasPrefix: PropTypes.bool,
  hasSuffix: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onEnterPress: PropTypes.func,
  autoSize: PropTypes.bool,
}
TextArea.defaultProps = {
  value: '',
  error: '',
  success: '',
  disabled: false,
  onChange: () => {},
  placeholder: '',
  type: 'text',
  hasPrefix: false,
  hasSuffix: false,
  autoFocus: false,
  autoSize: false,
}

export default TextArea
