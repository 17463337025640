import { cn } from '@sweetspot/scramble-ds'

type NumberOfBaysButtonProps = {
  disabled: boolean
  onClick: () => void
  icon: string
}

const NumberOfBaysButton = ({ disabled, onClick, icon }: NumberOfBaysButtonProps) => (
  <div
    className={cn(
      'bg-surface-primary text-text-light flex h-8 w-8 cursor-pointer select-none items-center justify-center rounded-full',
      {
        'bg-surface-primary-disabled text-text-pale cursor-not-allowed': disabled,
      }
    )}
    onClick={onClick}
  >
    <i className={`fa-regular ${icon}`} />
  </div>
)

export default NumberOfBaysButton
