import React, { useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import DropdownBox from '../DropdownBox'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

const InputSearchOptionSimple = ({
  options,
  onOptionSelect,
  disabled,
  className,
  placeholder,
  value,
  onChange,
  loading,
  dropdownClassName,
  label,
}) => {
  const [focused, setFocused] = useState(false)
  const containerRef = useRef(null)

  useOnClickOutside(containerRef, () => setFocused(false))

  const onFocusHandler = () => {
    if (focused) {
      setFocused(!focused)
      return
    }

    if (!disabled) {
      setFocused(true)
    }
  }

  const handleOptionSelect = (option) => {
    setFocused(false)
    if (!disabled) onOptionSelect(option)
  }

  return (
    <div className={cx(styles.container, className)} ref={containerRef}>
      {label && <label className="ss-label">{label}</label>}
      <div
        className={cx('ss-input-row', disabled && 'disabled', focused && 'focused')}
        style={{ width: '100%' }}
        onClick={onFocusHandler}
      >
        <div className={cx('ss-prefix-suffix prefix inline')}>
          <span className={cx('material-icons md-16 md-dark')}>search</span>
        </div>
        <input
          onChange={onChange}
          className={cx('ss-input has-prefix prefix-inline')}
          value={value}
          placeholder={placeholder}
        />
      </div>
      <DropdownBox
        visible={focused}
        options={options}
        onOptionClick={(option) => handleOptionSelect(option)}
        loading={loading}
        className={dropdownClassName}
      />
    </div>
  )
}

InputSearchOptionSimple.propTypes = {
  options: PropTypes.array,
  onOptionSelect: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  label: PropTypes.string,
}

InputSearchOptionSimple.defaultProps = {
  options: [],
  onOptionSelect: () => {},
  className: '',
  disabled: false,
  placeholder: '',
  value: '',
  onChange: () => {},
  loading: false,
  label: '',
}

export default InputSearchOptionSimple
