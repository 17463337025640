import { getAllValuesFromMultiObjectAsArray } from '@sweetspot/sweetspot-js/common/functions/utils'
import { ROLES_DEF } from './roles'

export const ACCESS_KEYS = {
  SETTINGS: {
    GOLF_CLUB: {
      CREATE: 'SETTINGS.GOLF_CLUB.CREATE',
      MODIFY: 'SETTINGS.GOLF_CLUB.MODIFY',
      DELETE: 'SETTINGS.GOLF_CLUB.DELETE',
    },
    USERS: {
      PARTNER_ADMINS: 'SETTINGS.USERS.PARTNER_ADMINS',
      CLUB_ADMINS: 'SETTINGS.USERS.CLUB_ADMINS',
      PARTNER_INTEGRATIONS: 'SETTINGS.USERS.PARTNER_INTEGRATIONS',
    },
    GOLF_COURSE: {
      CREATE: 'SETTINGS.GOLF_COURSE.CREATE',
      MODIFY: 'SETTINGS.GOLF_COURSE.MODIFY',
      DELETE: 'SETTINGS.GOLF_COURSE.DELETE',
    },
    SPACES: {
      CREATE: 'SETTINGS.SPACES.CREATE',
      MODIFY: 'SETTINGS.SPACES.MODIFY',
      DELETE: 'SETTINGS.SPACES.DELETE',
    },
    DRIVING_RANGE: {
      MANAGE_RANGE: 'SETTINGS.DRIVING_RANGE.MANAGE_RANGE',
      BAYS: 'SETTINGS.DRIVING_RANGE.BAYS',
      BALL_DISPENSERS: 'SETTINGS.DRIVING_RANGE.BALL_DISPENSERS',
      BALL_PRICING: 'SETTINGS.DRIVING_RANGE.BALL_PRICING',
    },
    GOLF_CARTS: 'SETTINGS.GOLF_CARTS',
    TAGS: {
      CREATE: 'SETTINGS.TAGS.CREATE',
      MODIFY: 'SETTINGS.TAGS.MODIFY',
      DELETE: 'SETTINGS.TAGS.DELETE',
    },
    BILLING: {
      SETTINGS: 'SETTINGS.BILLING.SETTINGS',
      PAYOUTS: 'SETTINGS.BILLING.PAYOUTS',
    },
    PROMOTIONS: 'SETTINGS.PROMOTIONS',
    COURSE_GUIDES: 'SETTINGS.COURSE_GUIDES',
    QT_INTEGRATION: 'SETTINGS.QT_INTEGRATION',
    HOMECLUB: 'SETTINGS.HOMECLUB',
    WEB_BOOKING: 'SETTINGS.WEB_BOOKING',
    PLAY_RIGHTS: 'SETTINGS.PLAY_RIGHTS',
    COOPERATIONS: 'SETTINGS.COOPERATIONS',
    TEE_TIME_PERIODS: 'SETTINGS.TEE_TIME_PERIODS',
    TEE_TIME_CATEGORIES: 'SETTINGS.TEE_TIME_CATEGORIES',
    APP_USERS: 'SETTINGS.APP_USERS',
    PRICE_LISTS: 'SETTINGS.PRICE_LISTS',
    STATUS_PAGE: 'SETTINGS.STATUS_PAGE',
    PRICING: 'SETTINGS.PRICING',
    CLUB_USERS: {
      READ: 'SETTINGS.CLUB_USERS.READ',
      EDIT: 'SETTINGS.CLUB_USERS.EDIT',
      INVITE: 'SETTINGS.CLUB_USERS.INVITE',
    },
  },
  FEATURES: {
    BOOKING: {
      CREATE: 'FEATURES.BOOKING.CREATE',
      EDIT: {
        PRICE_ADJUSTMENT: 'FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT',
        VOUCHER: 'FEATURES.BOOKING.EDIT.VOUCHER',
        BOOKING_OWNER: 'FEATURES.BOOKING.EDIT.BOOKING_OWNER',
        COPY_INSERT: 'FEATURES.BOOKING.EDIT.COPY_INSERT',
        REPLACE_PLAYER: 'FEATURES.BOOKING.EDIT.REPLACE_PLAYER',
        ARRIVAL_REGISTRATION: 'FEATURES.BOOKING.EDIT.ARRIVAL_REGISTRATION',
        MARK_AS_PAID: 'FEATURES.BOOKING.EDIT.MARK_AS_PAID',
        PARTIAL_REFUND: 'FEATURES.BOOKING.EDIT.PARTIAL_REFUND',
        REMOVE_PLAYER: 'FEATURES.BOOKING.EDIT.REMOVE_PLAYER',
        TOGGLE_BOOKING_NOTIFICATION: 'FEATURES.BOOKING.EDIT.TOGGLE_BOOKING_NOTIFICATION',
        SET_PARTNERSHIP: 'FEATURES.BOOKING.EDIT.SET_PARTNERSHIP',
        PRINT_SCORECARD: 'FEATURES.BOOKING.EDIT.PRINT_SCORECARD',
        MOVE_BOOKING: 'FEATURES.BOOKING.EDIT.MOVE_BOOKING',
        ADD_NOTE: 'FEATURES.BOOKING.EDIT.ADD_NOTE',
        EDIT_TEE_TIMES: 'FEATURES.BOOKING.EDIT.EDIT_TEE_TIMES',
      },
      CANCEL: 'FEATURES.BOOKING.CANCEL',
    },
    PLAYER_CARD: {
      READ: 'FEATURES.PLAYER_CARD.READ',
      MEMBERSHIP: {
        ADD: 'FEATURES.PLAYER_CARD.MEMBERSHIP.ADD',
        MARK_AS_PAID: 'FEATURES.PLAYER_CARD.MEMBERSHIP.MARK_AS_PAID',
        REMOVE_FROM_MEMBERSHIP: 'FEATURES.PLAYER_CARD.MEMBERSHIP.REMOVE_FROM_MEMBERSHIP',
        UNMARK_AS_PAID: 'FEATURES.PLAYER_CARD.MEMBERSHIP.UNMARK_AS_PAID',
        REFUND: 'FEATURES.PLAYER_CARD.MEMBERSHIP.REFUND',
        CANCEL_UPCOMING_PAYMENTS: 'FEATURES.PLAYER_CARD.MEMBERSHIP.CANCEL_UPCOMING_PAYMENTS',
        CANCEL_AUTO_RENEWAL: 'FEATURES.PLAYER_CARD.MEMBERSHIP.CANCEL_AUTO_RENEWAL',
        LOCK_UNLOCK_MEMBERSHIP: 'FEATURES.PLAYER_CARD.MEMBERSHIP.LOCK_UNLOCK_MEMBERSHIP',
      },
      GIT_INFORMATION: 'FEATURES.PLAYER_CARD.GIT_INFORMATION',
    },
  },
  PAGES: {
    TEE_SHEET: {
      READ: 'PAGES.TEE_SHEET.READ',
      EDIT_TEE_TIMES: 'PAGES.TEE_SHEET.EDIT_TEE_TIMES',
    },
    BOOKINGS: 'PAGES.BOOKINGS',
    NO_SHOW: 'PAGES.NO_SHOW',
    PLAYERS: {
      READ: 'PAGES.PLAYERS.READ',
      SEARCH: 'PAGES.PLAYERS.SEARCH',
      FILTER: 'PAGES.PLAYERS.FILTER',
      EXPORT: 'PAGES.PLAYERS.EXPORT',
      ADD_EXTERNAL: 'PAGES.PLAYERS.ADD_EXTERNAL',
      OPEN_PLAYER_CARD: 'PAGES.PLAYERS.OPEN_PLAYER_CARD',
      DELETE_OR_EDIT_NOTE: 'PAGES.PLAYERS.DELETE_OR_EDIT_NOTE',
    },
    PAYMENTS: 'PAGES.PAYMENTS',
    MEMBERSHIPS: {
      READ: 'PAGES.MEMBERSHIPS.READ',
      CREATE: 'PAGES.MEMBERSHIPS.CREATE',
      FAILED_PAYMENTS: {
        READ: 'PAGES.MEMBERSHIPS.FAILED_PAYMENTS.READ',
      },
      IMPORT_INVITES: 'PAGES.MEMBERSHIPS.IMPORT_INVITES',
      PENDING_INVITES: 'PAGES.MEMBERSHIPS.PENDING_INVITES',
      MIGRATE: 'PAGES.MEMBERSHIPS.MIGRATE',
      MEMBERSHIP_SIGNUPS: 'PAGES.MEMBERSHIPS.MEMBERSHIP_SIGNUPS',
    },
    MARKETPLACE: 'PAGES.MARKETPLACE',
    STATISTICS: 'PAGES.STATISTICS',
    INSIGHTS: {
      MENU: 'PAGES.INSIGHTS.MENU',
      TRANSACTIONS: 'PAGES.INSIGHTS.TRANSACTIONS',
      CLUB_OVERVIEW: 'PAGES.INSIGHTS.CLUB_OVERVIEW',
      GOLF_COURSES: 'PAGES.INSIGHTS.GOLF_COURSES',
      SIMULATORS: 'PAGES.INSIGHTS.SIMULATORS',
      DISCOUNT_BREAKDOWN: 'PAGES.INSIGHTS.DISCOUNT_BREAKDOWN',
      OCCUPANCY: 'PAGES.INSIGHTS.OCCUPANCY',
      MEMBERSHIPS_AND_MEMBERS: 'PAGES.INSIGHTS.MEMBERSHIPS_AND_MEMBERS',
      PARTNERSHIPS: 'PAGES.INSIGHTS.PARTNERSHIPS',
      MEMBERSHIP_SALES: 'PAGES.INSIGHTS.MEMBERSHIP_SALES',
      RANGE_DISPENSER: 'PAGES.INSIGHTS.RANGE_DISPENSER',
      RANGE_BOOKINGS: 'PAGES.INSIGHTS.RANGE_BOOKINGS',
    },
    REPORTS: 'PAGES.REPORTS',
    COMMUNICATION: {
      READ: 'PAGES.COMMUNICATION.READ',
      HOME_CLUB_POSTS: 'PAGES.COMMUNICATION.HOME_CLUB_POSTS',
      BOOKINGS_EMAIL: 'PAGES.COMMUNICATION.BOOKINGS_EMAIL',
    },
    VOUCHERS: {
      CREATE: 'PAGES.VOUCHERS.CREATE',
      READ: 'PAGES.VOUCHERS.READ',
      UI: {
        MODAL: {
          CREATE: 'PAGES.VOUCHERS.MODAL.CREATE',
        },
      },
    },
    PARTNERSHIPS: 'PAGES.PARTNERSHIPS',
  },
}

export const ACCESS_TABLE = {
  [ROLES_DEF.ROLE_SA.value]: getAllValuesFromMultiObjectAsArray(ACCESS_KEYS),
  [ROLES_DEF.ROLE_CLUB_OWNER.value]: [
    ACCESS_KEYS.SETTINGS.GOLF_CLUB.MODIFY,
    ACCESS_KEYS.SETTINGS.GOLF_COURSE.CREATE,
    ACCESS_KEYS.SETTINGS.GOLF_COURSE.MODIFY,
    ACCESS_KEYS.SETTINGS.GOLF_COURSE.DELETE,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.MANAGE_RANGE,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BAYS,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_DISPENSERS,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_PRICING,
    ACCESS_KEYS.SETTINGS.SPACES.CREATE,
    ACCESS_KEYS.SETTINGS.SPACES.MODIFY,
    ACCESS_KEYS.SETTINGS.SPACES.DELETE,
    ACCESS_KEYS.SETTINGS.GOLF_CARTS,
    ACCESS_KEYS.SETTINGS.TAGS.CREATE,
    ACCESS_KEYS.SETTINGS.TAGS.MODIFY,
    ACCESS_KEYS.SETTINGS.TAGS.DELETE,
    ACCESS_KEYS.SETTINGS.BILLING.SETTINGS,
    ACCESS_KEYS.SETTINGS.BILLING.PAYOUTS,
    ACCESS_KEYS.SETTINGS.PROMOTIONS,
    ACCESS_KEYS.SETTINGS.COURSE_GUIDES,
    ACCESS_KEYS.SETTINGS.QT_INTEGRATION,
    ACCESS_KEYS.SETTINGS.HOMECLUB,
    ACCESS_KEYS.SETTINGS.WEB_BOOKING,
    ACCESS_KEYS.SETTINGS.PLAY_RIGHTS,
    ACCESS_KEYS.SETTINGS.TEE_TIME_PERIODS,
    ACCESS_KEYS.SETTINGS.TEE_TIME_CATEGORIES,
    ACCESS_KEYS.SETTINGS.APP_USERS,
    ACCESS_KEYS.SETTINGS.PRICE_LISTS,
    ACCESS_KEYS.SETTINGS.STATUS_PAGE,
    ACCESS_KEYS.SETTINGS.PRICING,
    ACCESS_KEYS.SETTINGS.CLUB_USERS.READ,
    ACCESS_KEYS.SETTINGS.CLUB_USERS.EDIT,
    ACCESS_KEYS.SETTINGS.CLUB_USERS.INVITE,
    ACCESS_KEYS.SETTINGS.USERS.CLUB_ADMINS,
    ACCESS_KEYS.FEATURES.BOOKING.CREATE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.VOUCHER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.BOOKING_OWNER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.COPY_INSERT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REPLACE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ARRIVAL_REGISTRATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PARTIAL_REFUND,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REMOVE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.TOGGLE_BOOKING_NOTIFICATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.SET_PARTNERSHIP,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRINT_SCORECARD,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MOVE_BOOKING,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ADD_NOTE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.EDIT_TEE_TIMES,
    ACCESS_KEYS.FEATURES.BOOKING.CANCEL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.READ,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.ADD,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.REMOVE_FROM_MEMBERSHIP,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.UNMARK_AS_PAID,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.REFUND,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.CANCEL_AUTO_RENEWAL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.LOCK_UNLOCK_MEMBERSHIP,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.GIT_INFORMATION,
    ACCESS_KEYS.PAGES.TEE_SHEET.READ,
    ACCESS_KEYS.PAGES.TEE_SHEET.EDIT_TEE_TIMES,
    ACCESS_KEYS.PAGES.BOOKINGS,
    ACCESS_KEYS.PAGES.NO_SHOW,
    ACCESS_KEYS.PAGES.PAYMENTS,
    ACCESS_KEYS.PAGES.PLAYERS.READ,
    ACCESS_KEYS.PAGES.PLAYERS.SEARCH,
    ACCESS_KEYS.PAGES.PLAYERS.FILTER,
    ACCESS_KEYS.PAGES.PLAYERS.EXPORT,
    ACCESS_KEYS.PAGES.PLAYERS.ADD_EXTERNAL,
    ACCESS_KEYS.PAGES.PLAYERS.OPEN_PLAYER_CARD,
    ACCESS_KEYS.PAGES.PLAYERS.DELETE_OR_EDIT_NOTE,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.READ,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.CREATE,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.FAILED_PAYMENTS.READ,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.IMPORT_INVITES,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.PENDING_INVITES,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.MEMBERSHIP_SIGNUPS,
    ACCESS_KEYS.PAGES.MARKETPLACE,
    ACCESS_KEYS.PAGES.STATISTICS,
    ACCESS_KEYS.PAGES.INSIGHTS.CLUB_OVERVIEW,
    ACCESS_KEYS.PAGES.INSIGHTS.DISCOUNT_BREAKDOWN,
    ACCESS_KEYS.PAGES.INSIGHTS.GOLF_COURSES,
    ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIPS_AND_MEMBERS,
    ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIP_SALES,
    ACCESS_KEYS.PAGES.INSIGHTS.OCCUPANCY,
    ACCESS_KEYS.PAGES.INSIGHTS.PARTNERSHIPS,
    ACCESS_KEYS.PAGES.INSIGHTS.SIMULATORS,
    ACCESS_KEYS.PAGES.INSIGHTS.TRANSACTIONS,
    ACCESS_KEYS.PAGES.INSIGHTS.MENU,
    ACCESS_KEYS.PAGES.INSIGHTS.RANGE_DISPENSER,
    ACCESS_KEYS.PAGES.INSIGHTS.RANGE_BOOKINGS,
    ACCESS_KEYS.PAGES.REPORTS,
    ACCESS_KEYS.PAGES.COMMUNICATION.READ,
    ACCESS_KEYS.PAGES.COMMUNICATION.HOME_CLUB_POSTS,
    ACCESS_KEYS.PAGES.COMMUNICATION.BOOKINGS_EMAIL,
    ACCESS_KEYS.PAGES.VOUCHERS.CREATE,
    ACCESS_KEYS.PAGES.VOUCHERS.READ,
    ACCESS_KEYS.PAGES.VOUCHERS.UI.MODAL.CREATE,
    ACCESS_KEYS.PAGES.PARTNERSHIPS,
    ACCESS_KEYS.SETTINGS.COOPERATIONS,
    ACCESS_KEYS.SETTINGS.USERS.PARTNER_ADMINS,
  ],
  [ROLES_DEF.ROLE_ADMIN.value]: [
    ACCESS_KEYS.SETTINGS.GOLF_CLUB.MODIFY,
    ACCESS_KEYS.SETTINGS.GOLF_COURSE.CREATE,
    ACCESS_KEYS.SETTINGS.GOLF_COURSE.MODIFY,
    ACCESS_KEYS.SETTINGS.GOLF_COURSE.DELETE,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.MANAGE_RANGE,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BAYS,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_DISPENSERS,
    ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_PRICING,
    ACCESS_KEYS.SETTINGS.SPACES.CREATE,
    ACCESS_KEYS.SETTINGS.SPACES.MODIFY,
    ACCESS_KEYS.SETTINGS.SPACES.DELETE,
    ACCESS_KEYS.SETTINGS.GOLF_CARTS,
    ACCESS_KEYS.SETTINGS.TAGS.CREATE,
    ACCESS_KEYS.SETTINGS.TAGS.MODIFY,
    ACCESS_KEYS.SETTINGS.TAGS.DELETE,
    ACCESS_KEYS.SETTINGS.BILLING.SETTINGS,
    ACCESS_KEYS.SETTINGS.BILLING.PAYOUTS,
    ACCESS_KEYS.SETTINGS.PROMOTIONS,
    ACCESS_KEYS.SETTINGS.COURSE_GUIDES,
    ACCESS_KEYS.SETTINGS.QT_INTEGRATION,
    ACCESS_KEYS.SETTINGS.HOMECLUB,
    ACCESS_KEYS.SETTINGS.WEB_BOOKING,
    ACCESS_KEYS.SETTINGS.PLAY_RIGHTS,
    ACCESS_KEYS.SETTINGS.TEE_TIME_PERIODS,
    ACCESS_KEYS.SETTINGS.TEE_TIME_CATEGORIES,
    ACCESS_KEYS.SETTINGS.APP_USERS,
    ACCESS_KEYS.SETTINGS.PRICE_LISTS,
    ACCESS_KEYS.SETTINGS.STATUS_PAGE,
    ACCESS_KEYS.SETTINGS.PRICING,
    ACCESS_KEYS.SETTINGS.CLUB_USERS.READ,
    ACCESS_KEYS.FEATURES.BOOKING.CREATE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.VOUCHER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.BOOKING_OWNER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.COPY_INSERT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REPLACE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ARRIVAL_REGISTRATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PARTIAL_REFUND,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REMOVE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.TOGGLE_BOOKING_NOTIFICATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.SET_PARTNERSHIP,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRINT_SCORECARD,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MOVE_BOOKING,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ADD_NOTE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.EDIT_TEE_TIMES,
    ACCESS_KEYS.FEATURES.BOOKING.CANCEL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.READ,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.ADD,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.REMOVE_FROM_MEMBERSHIP,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.UNMARK_AS_PAID,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.REFUND,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.CANCEL_AUTO_RENEWAL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.LOCK_UNLOCK_MEMBERSHIP,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.GIT_INFORMATION,
    ACCESS_KEYS.PAGES.TEE_SHEET.READ,
    ACCESS_KEYS.PAGES.TEE_SHEET.EDIT_TEE_TIMES,
    ACCESS_KEYS.PAGES.BOOKINGS,
    ACCESS_KEYS.PAGES.NO_SHOW,
    ACCESS_KEYS.PAGES.PLAYERS.READ,
    ACCESS_KEYS.PAGES.PLAYERS.SEARCH,
    ACCESS_KEYS.PAGES.PLAYERS.FILTER,
    ACCESS_KEYS.PAGES.PLAYERS.EXPORT,
    ACCESS_KEYS.PAGES.PLAYERS.ADD_EXTERNAL,
    ACCESS_KEYS.PAGES.PLAYERS.OPEN_PLAYER_CARD,
    ACCESS_KEYS.PAGES.PLAYERS.DELETE_OR_EDIT_NOTE,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.READ,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.CREATE,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.FAILED_PAYMENTS.READ,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.MEMBERSHIP_SIGNUPS,
    ACCESS_KEYS.PAGES.MARKETPLACE,
    ACCESS_KEYS.PAGES.STATISTICS,
    ACCESS_KEYS.PAGES.INSIGHTS.CLUB_OVERVIEW,
    ACCESS_KEYS.PAGES.INSIGHTS.DISCOUNT_BREAKDOWN,
    ACCESS_KEYS.PAGES.INSIGHTS.GOLF_COURSES,
    ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIPS_AND_MEMBERS,
    ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIP_SALES,
    ACCESS_KEYS.PAGES.INSIGHTS.OCCUPANCY,
    ACCESS_KEYS.PAGES.INSIGHTS.PARTNERSHIPS,
    ACCESS_KEYS.PAGES.INSIGHTS.SIMULATORS,
    ACCESS_KEYS.PAGES.INSIGHTS.TRANSACTIONS,
    ACCESS_KEYS.PAGES.INSIGHTS.MENU,
    ACCESS_KEYS.PAGES.INSIGHTS.RANGE_DISPENSER,
    ACCESS_KEYS.PAGES.INSIGHTS.RANGE_BOOKINGS,
    ACCESS_KEYS.PAGES.REPORTS,
    ACCESS_KEYS.PAGES.COMMUNICATION.READ,
    ACCESS_KEYS.PAGES.COMMUNICATION.HOME_CLUB_POSTS,
    ACCESS_KEYS.PAGES.COMMUNICATION.BOOKINGS_EMAIL,
    ACCESS_KEYS.PAGES.VOUCHERS.CREATE,
    ACCESS_KEYS.PAGES.VOUCHERS.READ,
    ACCESS_KEYS.PAGES.VOUCHERS.UI.MODAL.CREATE,
    ACCESS_KEYS.PAGES.PARTNERSHIPS,
    ACCESS_KEYS.SETTINGS.COOPERATIONS,
  ],
  [ROLES_DEF.ROLE_CUSTOMER_SERVICE.value]: [
    ACCESS_KEYS.SETTINGS.STATUS_PAGE,
    ACCESS_KEYS.SETTINGS.CLUB_USERS.READ,
    ACCESS_KEYS.SETTINGS.GOLF_CARTS,
    ACCESS_KEYS.FEATURES.BOOKING.CREATE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.VOUCHER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.BOOKING_OWNER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.COPY_INSERT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REPLACE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ARRIVAL_REGISTRATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PARTIAL_REFUND,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REMOVE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.TOGGLE_BOOKING_NOTIFICATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.SET_PARTNERSHIP,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRINT_SCORECARD,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MOVE_BOOKING,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ADD_NOTE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.EDIT_TEE_TIMES,
    ACCESS_KEYS.FEATURES.BOOKING.CANCEL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.READ,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.ADD,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.REMOVE_FROM_MEMBERSHIP,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.UNMARK_AS_PAID,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.CANCEL_AUTO_RENEWAL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.LOCK_UNLOCK_MEMBERSHIP,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.GIT_INFORMATION,
    ACCESS_KEYS.PAGES.TEE_SHEET.READ,
    ACCESS_KEYS.PAGES.TEE_SHEET.EDIT_TEE_TIMES,
    ACCESS_KEYS.PAGES.BOOKINGS,
    ACCESS_KEYS.PAGES.NO_SHOW,
    ACCESS_KEYS.PAGES.PLAYERS.READ,
    ACCESS_KEYS.PAGES.PLAYERS.SEARCH,
    ACCESS_KEYS.PAGES.PLAYERS.FILTER,
    ACCESS_KEYS.PAGES.PLAYERS.ADD_EXTERNAL,
    ACCESS_KEYS.PAGES.PLAYERS.OPEN_PLAYER_CARD,
    ACCESS_KEYS.PAGES.MARKETPLACE,
    ACCESS_KEYS.PAGES.STATISTICS,
    ACCESS_KEYS.PAGES.REPORTS,
    ACCESS_KEYS.PAGES.COMMUNICATION.READ,
    ACCESS_KEYS.PAGES.COMMUNICATION.HOME_CLUB_POSTS,
    ACCESS_KEYS.PAGES.COMMUNICATION.BOOKINGS_EMAIL,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.FAILED_PAYMENTS.READ,
    ACCESS_KEYS.PAGES.MEMBERSHIPS.MEMBERSHIP_SIGNUPS,
  ],
  [ROLES_DEF.ROLE_COURSE_OPERATOR.value]: [
    ACCESS_KEYS.SETTINGS.STATUS_PAGE,
    ACCESS_KEYS.SETTINGS.CLUB_USERS.READ,
    ACCESS_KEYS.SETTINGS.GOLF_CARTS,
    ACCESS_KEYS.FEATURES.BOOKING.CREATE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.VOUCHER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.BOOKING_OWNER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.COPY_INSERT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REPLACE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ARRIVAL_REGISTRATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PARTIAL_REFUND,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REMOVE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.TOGGLE_BOOKING_NOTIFICATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.SET_PARTNERSHIP,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRINT_SCORECARD,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MOVE_BOOKING,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ADD_NOTE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.EDIT_TEE_TIMES,
    ACCESS_KEYS.FEATURES.BOOKING.CANCEL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.READ,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.ADD,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.UNMARK_AS_PAID,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.GIT_INFORMATION,
    ACCESS_KEYS.PAGES.TEE_SHEET.READ,
    ACCESS_KEYS.PAGES.TEE_SHEET.EDIT_TEE_TIMES,
    ACCESS_KEYS.PAGES.BOOKINGS,
    ACCESS_KEYS.PAGES.NO_SHOW,
    ACCESS_KEYS.PAGES.PLAYERS.READ,
    ACCESS_KEYS.PAGES.PLAYERS.SEARCH,
    ACCESS_KEYS.PAGES.PLAYERS.FILTER,
    ACCESS_KEYS.PAGES.PLAYERS.ADD_EXTERNAL,
    ACCESS_KEYS.PAGES.PLAYERS.OPEN_PLAYER_CARD,
    ACCESS_KEYS.PAGES.MARKETPLACE,
    ACCESS_KEYS.PAGES.INSIGHTS.CLUB_OVERVIEW,
    ACCESS_KEYS.PAGES.INSIGHTS.GOLF_COURSES,
    ACCESS_KEYS.PAGES.INSIGHTS.OCCUPANCY,
    ACCESS_KEYS.PAGES.INSIGHTS.SIMULATORS,
    ACCESS_KEYS.PAGES.INSIGHTS.MENU,
    ACCESS_KEYS.PAGES.INSIGHTS.RANGE_DISPENSER,
    ACCESS_KEYS.PAGES.INSIGHTS.RANGE_BOOKINGS,
    ACCESS_KEYS.PAGES.VOUCHERS.READ,
    ACCESS_KEYS.PAGES.COMMUNICATION.READ,
    ACCESS_KEYS.PAGES.COMMUNICATION.BOOKINGS_EMAIL,
  ],
  [ROLES_DEF.ROLE_BOOKER.value]: [
    ACCESS_KEYS.SETTINGS.STATUS_PAGE,
    ACCESS_KEYS.SETTINGS.CLUB_USERS.READ,
    ACCESS_KEYS.SETTINGS.GOLF_CARTS,
    ACCESS_KEYS.FEATURES.BOOKING.CREATE,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.VOUCHER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.BOOKING_OWNER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.COPY_INSERT,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REPLACE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ARRIVAL_REGISTRATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MARK_AS_PAID,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PARTIAL_REFUND,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.REMOVE_PLAYER,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.TOGGLE_BOOKING_NOTIFICATION,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRINT_SCORECARD,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.MOVE_BOOKING,
    ACCESS_KEYS.FEATURES.BOOKING.EDIT.ADD_NOTE,
    ACCESS_KEYS.FEATURES.BOOKING.CANCEL,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.READ,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.ADD,
    ACCESS_KEYS.FEATURES.PLAYER_CARD.GIT_INFORMATION,
    ACCESS_KEYS.PAGES.TEE_SHEET.READ,
    ACCESS_KEYS.PAGES.BOOKINGS,
    ACCESS_KEYS.PAGES.PLAYERS.READ,
    ACCESS_KEYS.PAGES.PLAYERS.SEARCH,
    ACCESS_KEYS.PAGES.PLAYERS.FILTER,
    ACCESS_KEYS.PAGES.PLAYERS.ADD_EXTERNAL,
    ACCESS_KEYS.PAGES.PLAYERS.OPEN_PLAYER_CARD,
    ACCESS_KEYS.PAGES.MARKETPLACE,
  ],
  [ROLES_DEF.ROLE_MEMBERSHIP_SIGNUP_MANAGER.value]: [
    ACCESS_KEYS.PAGES.MEMBERSHIPS.MEMBERSHIP_SIGNUPS,
  ],
}
