import {
  Divider,
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { Header, LeftView, RightView } from './partials'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'
import { useTranslation } from 'react-i18next'

export const PaymentLinksOverview = () => {
  const isPaymentLinksFlagEnabled = useFlag(FlagNames.PaymentLinks)
  const { t } = useTranslation()

  if (isPaymentLinksFlagEnabled)
    return (
      <div>
        <Header />
        <div className="relative flex h-[calc(100vh-140px)]">
          <LeftView />
          <Divider variant="basic" orientation="vertical" className="h-full" />
          <RightView />
        </div>
      </div>
    )
  return (
    <div className="flex items-center justify-center pb-[80px]">
      <EmptyState>
        <EmptyStateTitle>{t('paymentLinks.plural')}</EmptyStateTitle>
        <EmptyStateDescription>
          {t('paymentLinks.paymentEmptyPageInformation')}
        </EmptyStateDescription>
        <EmptyStateButton>
          <a href="mailto:hello@sweetspot.io">{t('paymentLinks.contactUs')}</a>
        </EmptyStateButton>
      </EmptyState>
    </div>
  )
}
