import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

/* ------------------------------------ */
/* ------------------ Zustand State ------------------ */
/* ------------------------------------ */
type ActiveKeys = {
  1: string | undefined
  2: string | undefined
  3: string | undefined
}
interface SideSystemNavStoreType {
  isExpanded: boolean
  toggleIsExpanded: () => void
  setIsExpanded: (isExpanded: boolean) => void
  bottomNavIsActive: boolean
  toggleBottomNavIsActive: () => void
  setBottomNavIsActive: (isActive: boolean) => void
  activeKeys: ActiveKeys
  activeKeysByIsActiveProp: ActiveKeys
  setActiveKey: (level: keyof ActiveKeys, key: string) => void
  setActiveKeyByIsActiveProp: (level: keyof ActiveKeys, key: string) => void
}
export const useSideSystemNavStore = create(
  immer<SideSystemNavStoreType>((set) => ({
    isExpanded: false,
    toggleIsExpanded: () =>
      set((draft) => {
        draft.isExpanded = !draft.isExpanded
      }),
    setIsExpanded: (isExpanded) =>
      set((draft) => {
        draft.isExpanded = isExpanded
      }),
    bottomNavIsActive: false,
    toggleBottomNavIsActive: () =>
      set((draft) => {
        draft.bottomNavIsActive = !draft.bottomNavIsActive
      }),
    setBottomNavIsActive: (isActive) =>
      set((draft) => {
        draft.bottomNavIsActive = isActive
      }),
    activeKeys: {
      1: undefined,
      2: undefined,
      3: undefined,
    },
    activeKeysByIsActiveProp: {
      1: undefined,
      2: undefined,
      3: undefined,
    },
    setActiveKey: (level, key) =>
      set((draft) => {
        if (draft.activeKeys[level] === key) return

        if (level === 1) {
          draft.activeKeys[1] = key
          draft.activeKeys[2] = undefined
          draft.activeKeys[3] = undefined
        }
        if (level === 2) {
          draft.activeKeys[2] = key
          draft.activeKeys[3] = undefined
        }
        if (level === 3) {
          draft.activeKeys[3] = key
        }
      }),
    setActiveKeyByIsActiveProp: (level, key) =>
      set((draft) => {
        if (draft.activeKeysByIsActiveProp[level] === key) return

        if (level === 1) {
          draft.activeKeysByIsActiveProp[1] = key
          draft.activeKeysByIsActiveProp[2] = undefined
          draft.activeKeysByIsActiveProp[3] = undefined
        }
        if (level === 2) {
          draft.activeKeysByIsActiveProp[2] = key
          draft.activeKeysByIsActiveProp[3] = undefined
        }
        if (level === 3) {
          draft.activeKeysByIsActiveProp[3] = key
        }
      }),
  }))
)
