import React, { createContext, useContext, useState } from 'react'
import { PlayerCardContextData } from '../types'

const ANNUAL_DURATION = 1

// Create a context for managing player card related data.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PlayerCardContext = createContext<PlayerCardContextData>(undefined as any)

/**
 * A hook that provides an easier way to access the PlayerCard context.
 */
export const usePlayerCardContext = () => useContext(PlayerCardContext)

/**
 * Provider component for the PlayerCard context. This wraps parts of the application
 * that need access to the player card data and actions. It holds state for dialog type,
 * membership duration, and the membership year.
 */
export const PlayerCardProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren>> = ({
  children,
}) => {
  const [dialogType, setDialogType] = useState<number | null>(null)
  const [duration, setDuration] = useState<number>(ANNUAL_DURATION)
  const [year, setYear] = useState<number>(new Date().getFullYear())

  return (
    <PlayerCardContext.Provider
      value={{
        dialogType,
        setDialogType,
        duration,
        setDuration,
        year,
        setYear,
      }}
    >
      {children}
    </PlayerCardContext.Provider>
  )
}
