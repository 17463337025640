import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Query tee time categories
 *
 * @returns
 */
export const queryTeeTimeCategories = (
  query = {
    'club.id': null,
    page: 1,
    limit: 50,
  },
  ...args
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/tee-time-categories`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, ...args)
}

/**
 * Query tee time categories translations
 *
 * @returns
 */
export const queryTeeTimeCategoriesTranslations = (
  query = {
    locale: null,
    'locale[]': null,
    'translatable.uuid': null,
    'translatable.uuid[]': null,
    page: 1,
    limit: 50,
  },
  ...args
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/tee-time-categories/translations`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, ...args)
}
