import React from 'react'
import { useTranslation } from 'react-i18next'
import DisplayField from './DisplayField'

import style from './style.module.scss'

type InformationField = {
  id: number
  text: string
  value: string
}

type Props = {
  field: InformationField
  data: object
}

const FieldRenderer: React.FC<Props> = ({ field, data }) => {
  const { t } = useTranslation()
  return (
    <div key={field.id} className={style.itemTitle}>
      <label>{t(field.text)}</label>
      {data && (
        <DisplayField
          id={field.id}
          fieldName={field.value}
          value={data[field.value]}
          disabled={true}
        />
      )}
    </div>
  )
}

export default FieldRenderer
