import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import style from './style.module.scss'
import useSelectedClubId from '@sweetspot/sweetspot-js/common/hooks/useSelectedClubId'

const Reports = ({ courses, golfClubList }) => {
  const clubId = useSelectedClubId()
  const selectedGolfClub = golfClubList.find((club) => club.id === clubId)
  const [dashboardId, setDashboardId] = useState(364)
  const [selectedCourses, setSelectedCourses] = useState(
    courses?.list?.map((course) => ({
      ...course,
      label: course.name,
      value: course.id,
    })) || []
  )
  const [iframeUrl, setIframeUrl] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    updateIframe()
  }, [dashboardId, selectedCourses])

  const reportTypes = useMemo(() => {
    return [
      { id: 585, name: t('reports.all_bookings'), value: 585, showFilters: false },
      { id: 534, name: t('reports.simulator_bookings'), value: 534, showFilters: false },
      { id: 364, name: t('reports.member_booking'), value: 364, showFilters: true },
      { id: 362, name: t('reports.guest_booking'), value: 362, showFilters: true },
      { id: 365, name: t('reports.partner_booking'), value: 365, showFilters: true },
      { id: 363, name: t('reports.tag_booking'), value: 363, showFilters: true },
      { id: 392, name: t('reports.booked_golfcarts'), value: 392, showFilters: true },
      { id: 391, name: t('reports.not_paid'), value: 391, showFilters: true },
      { id: 535, name: t('reports.voucher_bookings'), value: 535, showFilters: true },
      { id: 537, name: t('reports.voucher_usage'), value: 537, showFilters: false },
    ]
  }, [t])

  const setParams = () => {
    if (dashboardId === 534) return { club_: clubId }
    if (dashboardId === 535) return { courses: selectedCourses.map((x) => x.uuid) }
    if (dashboardId === 537 || dashboardId === 585) return { club: selectedGolfClub.uuid }
    return { course: selectedCourses.map((x) => x.uuid) }
  }

  const updateIframe = () => {
    if (!selectedCourses.length) return

    const { REACT_APP_METABASE_SITE_URL: METABASE_URL, REACT_APP_METABASE_SECRET_KEY: SECRET_KEY } =
      process.env

    const payload = {
      resource: { dashboard: dashboardId },
      params: setParams(),
      exp: Math.round(Date.now() / 1000) + 600,
    }
    const token = jwt.sign(payload, SECRET_KEY)

    setIframeUrl(`${METABASE_URL}/embed/dashboard/${token}#bordered=false&titled=false`)
    console.log('object', `${METABASE_URL}/embed/dashboard/${token}#bordered=true&titled=true`)
  }

  const showFilter = useMemo(() => {
    return reportTypes.find((item) => item.id === dashboardId).showFilters
  }, [dashboardId, reportTypes])

  return (
    <Container className={style.container}>
      <div className={style.header}>
        <h3>{t('reports.text')}</h3>
        <label>{t('reports.selectReport')}</label>
        <DropdownSelect
          showBorder
          readOnly
          hideSelf
          width="300px"
          initialId={dashboardId}
          selectedId={dashboardId}
          values={reportTypes}
          onSelect={(id) => setDashboardId(id)}
        />
      </div>
      <hr />
      {showFilter && (
        <div className={style.filter}>
          <h3>{t('filters')}</h3>
          <MultiSelect
            buttonLabel={t('courses')}
            title={t('courses')}
            searchPlaceholder={t('wizard.searchCourses')}
            noSelectedLabel={t('noCoursesSelected')}
            width="full"
            options={courses?.list?.map((course) => ({
              ...course,
              label: course.name,
              value: course.id,
            }))}
            selectedOptions={selectedCourses}
            handleChangeSelected={(courses) => setSelectedCourses(courses)}
          />
        </div>
      )}
      <div className={style.content}>
        {!!selectedCourses.length && iframeUrl && (
          <iframe title="dashboard" src={iframeUrl} frameBorder="0" width="100%" height="100%" />
        )}
      </div>
    </Container>
  )
}

Reports.propTypes = {
  courses: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    courses: state.golfCourse,
    golfClubList: state.golfClub.list,
  }
}

export default connect(mapStateToProps)(Reports)
