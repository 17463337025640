import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  InputTrailingContainer,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@sweetspot/scramble-ds'

type PopoverHintProps = {
  i18nKey: string
}

const PopoverHint = ({ i18nKey }: PopoverHintProps) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <InputTrailingContainer
          className="relative bottom-0 left-0 right-0 top-0 flex w-6 transform-none items-center"
          onClick={() => setOpen(true)}
        >
          <i className="fa-regular fa-circle-info text-text-subtle" />
        </InputTrailingContainer>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="text-content-sm bg-base-black text-base-white w-[306px] px-4 py-6"
        onInteractOutside={() => setOpen(false)}
      >
        <Trans i18nKey={i18nKey} components={{ strong: <strong />, br: <br /> }} />
        <PopoverArrow className="fill-black" />
      </PopoverContent>
    </Popover>
  )
}

export default PopoverHint
