import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18next'
import TimePicker from 'rc-time-picker'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'
import { currencyCodeInCurrentLanguage } from '@sweetspot/sweetspot-js/common/functions/utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { withClubCurrency } from '../../../../hoc/withClubCurrency'
import moment from 'moment'

export class PriceOverride extends Component {
  constructor() {
    super()

    this.state = {
      fromDate: null,
      toDate: null,
      price: '',
    }

    this.getPricePeriod = this.getPricePeriod.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.addPeriodOverride = this.addPeriodOverride.bind(this)
  }

  getPricePeriod() {
    const { pricePeriods, activePeriodId } = this.props

    const activePricePeriod = pricePeriods.find((period) => period.id === activePeriodId)

    return activePricePeriod
  }

  handleTimeChange(target, momentDate) {
    this.setState({
      [target]: momentDate,
    })
  }

  handleChange(e) {
    const { name, value } = e.target

    this.setState({
      [name]: value,
    })
  }

  addPeriodOverride() {
    const { currentClubCurrency } = this.props
    const { fromDate, toDate, price } = this.state
    this.props.onAddPriceOverride(
      moment(fromDate).format('HH:mm'),
      moment(toDate).format('HH:mm'),
      price * 1,
      currentClubCurrency
    )

    this.setState({
      fromDate: null,
      toDate: null,
      price: '',
    })
  }

  isValidPeriod() {
    const { fromDate, toDate, price } = this.state

    if (!fromDate || !toDate || !price) return false
    if (fromDate > toDate) return false
    if (isNaN(price)) return false
    return true
  }

  renderOverrides() {
    const overrides = this.props.overrides
    return (
      overrides &&
      overrides.map((override) => (
        <div key={override.id} className="flex">
          <div className={style.timeRange}>
            <span>{override?.from}</span>
            <span>-</span>
            <span>{override?.to}</span>
          </div>
          <div className={`mx-3 ${style.priceValue}`}>
            <span>{priceToLocal(override?.price?.amount, override?.price?.currency, true)}</span>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCircleMinus}
              onClick={() => this.props.onRemovePriceOverride(override?.id)}
            />
          </div>
        </div>
      ))
    )
  }

  render() {
    const { fromDate, toDate, price } = this.state
    const { currentClubCurrency } = this.props

    return (
      <div className={style.container}>
        <hr />
        <Text className={style.override} textId="pricing.override" />

        <div className="grid grid-cols-9 items-end gap-2">
          <div className="col-span-2">
            <Text textId="fromTime" />
            <TimePicker
              className={style.timePicker}
              showSecond={false}
              value={fromDate}
              onChange={(moment) => this.handleTimeChange('fromDate', moment)}
            />
          </div>

          <div className="col-span-2">
            <Text textId="toTime" />
            <TimePicker
              className={style.timePicker}
              showSecond={false}
              value={toDate}
              onChange={(moment) => this.handleTimeChange('toDate', moment)}
            />
          </div>

          <div className="col-span-2">
            <Text
              textId="pricing.priceUnit"
              append={`(${currencyCodeInCurrentLanguage(currentClubCurrency)})`}
            />
            <input className="ss-input" name="price" value={price} onChange={this.handleChange} />
          </div>

          <div className="col-span-3">
            <button
              className="system-button info-outline md-32"
              disabled={!this.isValidPeriod()}
              onClick={this.addPeriodOverride}
            >
              {t('pricing.saveOverride')}
            </button>
          </div>
        </div>

        <div className={style.overrideList}>
          <Text textId="pricing.savedOverride" />
          <div>{this.renderOverrides()}</div>
        </div>
      </div>
    )
  }
}

PriceOverride.propTypes = {
  activePeriodId: PropTypes.number.isRequired,
  pricePeriods: PropTypes.array.isRequired,
  onUpdatePeriod: PropTypes.func.isRequired,
  currentCourse: PropTypes.object,
  currentClubCurrency: PropTypes.string.isRequired,
  overrides: PropTypes.array.isRequired,
}

export default withClubCurrency(
  connect((state) => ({
    currentCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
  }))(PriceOverride)
)
