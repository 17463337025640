import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Get all product variants in specific warehouse
 *
 * @param {string} warehouseUuid
 * @param {object} [query={
 *     page: 1,
 *     limit: 150
 *   }]
 */
export const getProductVariants = (
  warehouseUuid,
  query = {
    page: 1,
    limit: 150,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/warehouses/${warehouseUuid}/product-variants`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, { method: 'get' })
}

/**
 * @param {object} [payload={
 *     product_variant: {
 *       on_hold: 1,
 *       on_hand: 1,
 *       hold_strategy: 'deferred',
 *       is_tracked: true,
 *       is_postponable: true,
 *       state: 'active',
 *       product_code: 'golf_cart',
 *       variant_code: 'golf_cart',
 *       name: 'name'
 *     },
 *     warehouse: null
 *   }]
 */
export const createProductVariant = (
  productUuid,
  payload = {
    on_hold: 1,
    on_hand: 1,
    hold_strategy: 'deferred',
    is_tracked: true,
    is_postponable: true,
    state: 'not_active',
    variant_code: 'golf_cart',
    name: 'name',
    warehouse: null,
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/products/${productUuid}/product-variant`
  return request(url, { method: 'put', body: payload })
}

/**
 * Remove a single product variant
 *
 * @param {string} variantUuid
 */
export const removeProductVariant = (variantUuid) => {
  const url = `${getApiPlatformBaseUrl()}/product-variants/${variantUuid}`
  return request(url, { method: 'delete' })
}

/**
 * Update state and/or name of a product variant
 *
 * @param {string} variantUuid
 * @param {object} [payload={ name: '', state: 'inactive' }]
 */
export const updateProductVariant = (variantUuid, payload = { name: '', state: 'inactive' }) => {
  const url = `${getApiPlatformBaseUrl()}/product-variants/${variantUuid}`
  return request(url, { method: 'put', body: payload })
}

/**
 * Get single product variant
 *
 * @param {string} variantUuid
 * @return {Product variant}
 */
export const getProductVariant = (variantUuid) => {
  const url = `${getApiPlatformBaseUrl()}/product-variants/${variantUuid}`
  return request(url, { method: 'get' })
}

/**
 * Update a single product variant fee
 *
 * @param {number} feeId
 * @param {object} [payload={ vat: 20, price: 100 }]
 */
export const updateProductVariantFee = (feeId, payload = { vat: 20, price: 100 }) => {
  const url = `${getApiPlatformBaseUrl()}/product-variant-fees/${feeId}`
  return request(url, { method: 'put', body: payload })
}

/**
 * Create a new fee for the product variant
 *
 * @param {string} variantUuid
 * @param {object} [payload={ fee_unit_code: 'golf_cart_fleet', vat: 20, price: 150 }]
 */
export const createProductVariantFee = (
  variantUuid,
  payload = { fee_unit_code: 'golf_cart_fleet', vat: 20, price: 150 }
) => {
  const url = `${getApiPlatformBaseUrl()}/product-variants/${variantUuid}/product-variant-fee`
  return request(url, { method: 'put', body: payload })
}

/**
 * Get all fees for the product variant
 *
 * @param {string} variantUuid
 * @return {Array} The product variant fees
 */
export const getProductVariantFees = (variantUuid) => {
  const url = `${getApiPlatformBaseUrl()}/product-variant-fees/${variantUuid}`
  return request(url, { method: 'get' })
}

export const getRentalActivity = (
  query = {
    variantName: null,
    warehouseUuid: null,
    bookingUuid: null,
    order: null,
    page: null,
    limit: null,
    holdTo: null,
  }
) => {
  query = {
    'product_variant.name': query.variantName || null,
    'product_variant.warehouse.uuid': query.warehouseUuid || null,
    'booking.uuid': query.bookingUuid,
    'order[hold_from]': query.order,
    'hold_to[after]': query.holdTo,
    page: query.page,
    limit: query.limit,
  }
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/inventory-course-schedules`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url, { method: 'get', accept: 'application/ld+json' })
}
