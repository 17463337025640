import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { t } from 'i18next'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import SettingComponent from '@sweetspot/club-portal-legacy/pages/Settings/components/SettingComponent'

export default class DeleteUser extends SettingComponent {
  constructor(props) {
    super(props)

    this.state = {}

    this.confirmDelete = this.confirmDelete.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  confirmDelete() {
    let text = this._confirm.value.trim().toLowerCase()
    if (text !== 'delete') {
      alert(getLang(this.props.lang)['settings']['confirmDelete'])
      return false
    }
    return true
  }

  handleDelete() {
    const { deleteUser, user } = this.props
    if (this.confirmDelete()) {
      deleteUser(user.id).then(() => {
        this._confirm.value = ''
      })
    }
  }

  renderForm() {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className="ss-label">{t('settings.typeDelete')}</label>
          <input className="ss-input" type="text" ref={(input) => (this._confirm = input)} />
        </div>
        <div>
          <button
            className={`system-button warning ss-block-button ss-round-button md-32 ${style.formButtons}`}
            onClick={this.handleDelete}
            disabled={!this.props.user}
          >
            {t('settings.delete')}
          </button>
        </div>
      </form>
    )
  }

  render() {
    return <div className={style.wrapper}>{this.renderForm()}</div>
  }
}

DeleteUser.propTypes = {
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  user: PropTypes.object,
  deleteUser: PropTypes.func.isRequired,
}
