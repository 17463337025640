import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const QTAccessCode = ({ accessCode }) => {
  const { t } = useTranslation()

  if (!accessCode) return null

  return (
    <div className={cx(styles.container)}>
      <p className="text-brand-black font-semibold">{t('sentences.lockCode')}</p>
      <p>{accessCode}</p>
    </div>
  )
}

QTAccessCode.propTypes = {
  accessCode: PropTypes.string,
}

QTAccessCode.defaultProps = {
  accessCode: undefined,
}

export default QTAccessCode
