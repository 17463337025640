import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import styles from './styles.module.scss'

const Navigation = ({ steps, currentStep, onBack, onForward, currentEntity, disabled }) => {
  const getCurrentStepName = () => {
    const currentStepArray = steps.filter((step) => step.stepNumber === currentStep)
    return currentStepArray && currentStepArray[0]
      ? currentStepArray[0].longLabel || currentStepArray[0].label
      : ''
  }
  const canGoBack = () => {
    return currentStep > 1
  }
  const canGoForward = () => {
    return currentEntity && currentEntity.state && currentStep < steps.length
  }

  const handleClick = (direction) => {
    if (disabled) return
    if (direction === 'back' && onBack) onBack()
    if (direction === 'forward' && onForward) onForward()
  }

  return (
    <div className={styles.container}>
      {canGoBack() && !disabled ? (
        <FontAwesomeIcon
          onClick={() => handleClick('back')}
          className={cx(styles.icon, styles.backIcon)}
          icon={faChevronLeft}
        />
      ) : null}
      <p className={styles.title}>{getCurrentStepName()}</p>
      {canGoForward() && !disabled ? (
        <FontAwesomeIcon
          onClick={() => handleClick('forward')}
          className={cx(styles.icon, styles.forwardIcon)}
          icon={faChevronRight}
        />
      ) : null}
    </div>
  )
}

Navigation.propTypes = {
  currentEntity: PropTypes.object,
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  onBack: PropTypes.func,
  onForward: PropTypes.func,
  disabled: PropTypes.bool,
}

Navigation.defaultProps = {
  currentEntity: null,
  steps: [],
  currentStep: 1,
  onBack: () => {},
  onForward: () => {},
  disabled: false,
}

export default Navigation
