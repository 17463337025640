import { useSelector } from 'react-redux'

import { APP_NAMES } from '@sweetspot/shared/util/constants'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'

const useSelectedClubId = () => {
  const CURRENT_APP = getCurrentAppName()
  const currentClubId = useSelector((state) => {
    if (CURRENT_APP === APP_NAMES.PARTNER_PORTAL) {
      if (!state?.partnerships) return undefined
      const { selectedId, byId } = state.partnerships
      return byId?.[selectedId]?.club?.id
    }
    if (CURRENT_APP === APP_NAMES.CLUB_PORTAL) {
      return state?.golfClub?.selectedId
    }
    return null
  })

  return currentClubId
}

export default useSelectedClubId
