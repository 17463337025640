import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 *
 * @param {*} warehouseId
 * @returns
 */
export const getAvailableGolfCartsList = (
  warehouseId,
  query = {
    holdFrom: null,
    holdTo: null,
    productCode: null,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/warehouses/${warehouseId}/product-variants/available`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Add hold reservation for golf cart
 *
 * @param {number} warehouseId
 * @returns
 */
export const addClubUserHoldReservation = (
  warehouseId,
  payload = {
    hold_from: '',
    hold_to: '',
    product_variants: [],
    note: '',
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/warehouses/${warehouseId}/reservations/add`
  return request(url, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Removes hold reservation for golf cart
 *
 * @param {number} warehouseId
 * @param {string} reservationUuid
 * @returns
 */
export const removeClubUserHoldReservation = (warehouseId, reservationUuid) => {
  const url = `${getApiPlatformBaseUrl()}/warehouses/${warehouseId}/reservations/remove`
  return request(url, {
    method: 'PUT',
    body: { manual_inventory_reservation: reservationUuid },
  })
}

/**
 * Get club user hold reservations
 *
 * @param {number} warehouseId
 * @returns
 */
export const getClubUserHoldReservations = (
  warehouseId,
  query = {
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/warehouses/${warehouseId}/manual-inventory-reservations`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

/**
 * Get inventory course schedules
 *
 * @param {Object} query
 * @param {number} query.warehouse
 * @param {number} query.booking booking Uuid
 * @returns
 */
export const getInventoryCourseSchedules = (
  query = {
    warehouse: null,
    booking: null,
  }
) => {
  query = { ...query, booking: null, 'booking.uuid': query.booking }
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/inventory-course-schedules`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}
