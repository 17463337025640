import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Get spaces
 *
 * @returns
 */
export const getSpaces = (
  query = {
    club: null,
    'club[]': null,
    course: null,
    'course[]': null,
    page: 1,
    limit: 50,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/spaces`,
      query,
    },
    {
      skipNull: true,
    }
  )

  return request(url)
}

/**
 * Create space
 *
 * @param {string|number} clubId
 * @returns
 */
export const createSpace = (
  clubId,
  payload = { name: '', description: '', floor: 0, course: '' }
) => {
  const url = `${getApiPlatformBaseUrl()}/clubs/${clubId}/space`
  return request(url, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Delete space
 *
 * @param {string} spaceUuid
 * @returns
 */
export const deleteSpace = (spaceUuid) => {
  const url = `${getApiPlatformBaseUrl()}/spaces/${spaceUuid}`
  return request(url, { method: 'DELETE' })
}

/**
 * Update space
 *
 * @param {string} spaceUuid
 * @returns
 */
export const updateSpace = (
  spaceUuid,
  payload = { name: '', description: '', floor: 0, course: '' }
) => {
  const url = `${getApiPlatformBaseUrl()}/spaces/${spaceUuid}`
  return request(url, {
    method: 'PUT',
    body: payload,
  })
}
