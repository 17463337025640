import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
export const CacheInfo = () => {
  const { t } = useTranslation()
  return (
    <div
      className={
        'flex h-auto w-52 flex-row flex-nowrap items-center justify-center overflow-hidden rounded border bg-white p-2'
      }
    >
      <FontAwesomeIcon className="pr-2" size="xl" icon={faInfoCircle}></FontAwesomeIcon>
      <div>
        <p className="text-xs"> {t('newVersion')}</p>
        <p className="text-xs"> {t('reloadPage')}</p>
      </div>
    </div>
  )
}
