import { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import NotFound from '@sweetspot/club-portal-legacy/pages/NotFound'

import style from './style.module.scss'
import Posts from './components/Posts'
import BookingEmail from './components/BookingEmail'
import { connect } from 'react-redux'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'

class Communication extends Component {
  render() {
    const { path } = this.props.match
    const { role } = this.props

    return (
      <Container>
        <div className={style.content}>
          <Switch>
            <Route exact path={`${path}/home-club-posts`} component={Posts} />
            <Route exact path={`${path}/bookings-email`} component={BookingEmail} />
            <Redirect
              exact
              from={`${path}`}
              to={
                hasAccess(ACCESS_KEYS.PAGES.COMMUNICATION.HOME_CLUB_POSTS, role?.value)
                  ? `${path}/home-club-posts`
                  : `${path}/bookings-email`
              }
            />
            <Route exact path={`${path}/*`} component={NotFound} />
          </Switch>
        </div>
      </Container>
    )
  }
}

Communication.propTypes = {
  match: PropTypes.object.isRequired,
}

export default connect((state) => ({ role: getFirstMatchingRole(state.auth.roles) }))(Communication)
