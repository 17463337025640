import { useEffect, useCallback, useState, useRef } from 'react'
import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import cx from 'classnames'
import style from '../../style.module.scss'
import CheckBoxAll from './CheckBoxAll'

const PRICE_TABLE_CELL_HEIGHT = 40
const PRICE_TABLE_OFFSET = 50
const DEFAULT_PRICE_BODY_CONTAINER_HEIGHT = 400

const PriceListTable = ({ tableSelector, extraPlayers }) => {
  const containerRef = useRef(null)
  const [priceTableBodyContainerHeight, setPriceTableBodyContainerHeight] = useState(
    DEFAULT_PRICE_BODY_CONTAINER_HEIGHT
  )
  const { selectedCells, cbValue, lang } = tableSelector
  const { onRowClick, onColumnClick, onCellClick, onCheckBoxClick } = tableSelector
  const { selectedRows, selectedCols } = tableSelector
  const { tableData, timeSlots, daysMap, formState, DAYS_OF_WEEK } = tableSelector
  const DAYS_OF_WEEK_LOCALE = localizedDaysOfWeek(lang, 1)

  const updateContainerHeight = useCallback(() => {
    setPriceTableBodyContainerHeight(
      (containerRef.current?.offsetHeight || DEFAULT_PRICE_BODY_CONTAINER_HEIGHT) -
        PRICE_TABLE_CELL_HEIGHT -
        PRICE_TABLE_OFFSET
    )
  }, [])

  useEffect(() => {
    const containerElement = containerRef.current

    const resizeObserver = new ResizeObserver(() => {
      updateContainerHeight()
    })

    if (containerElement) {
      resizeObserver.observe(containerElement)
    }

    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
  }, [updateContainerHeight])

  return (
    <div ref={containerRef} className="flex w-[100%] flex-1 overflow-x-auto">
      <div className={style.priceTableContainer}>
        <div>
          <table className={style.priceTable}>
            <thead>
              <tr>
                <th style={{ height: PRICE_TABLE_CELL_HEIGHT }}>
                  <CheckBoxAll value={cbValue} onClick={onCheckBoxClick} />
                </th>
                {DAYS_OF_WEEK.map((day, dayIndex) => {
                  return (
                    <th
                      className={cx(
                        style.daysWeek,
                        selectedCols[dayIndex] && tableData[day] ? style.complete : ''
                      )}
                      style={{ height: PRICE_TABLE_CELL_HEIGHT }}
                      key={day}
                      onClick={() => onColumnClick(dayIndex, selectedCols[dayIndex])}
                      data-disabled={!tableData[day]}
                    >
                      {tableData[day] && (
                        <div>
                          <span>{DAYS_OF_WEEK_LOCALE[dayIndex].substr(0, 3)}</span>
                          {formState.week !== -1 && <span>{daysMap[day]}</span>}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            </thead>
          </table>
        </div>
        <div
          className={style.priceTableBodyContainer}
          style={{ height: priceTableBodyContainerHeight }}
        >
          <table className={style.priceTable}>
            <thead>
              <tr>
                {new Array(8).fill(null).map((_, idx) => (
                  <th key={idx}></th>
                ))}
              </tr>
            </thead>
            <tbody>
              {timeSlots.map((timeSlot, timeSlotIndex) => {
                const className = cx(
                  style.timeFrom,
                  selectedRows[timeSlotIndex] ? style.complete : ''
                )
                return (
                  <tr key={timeSlotIndex}>
                    <th
                      onClick={() => onRowClick(timeSlotIndex, selectedRows[timeSlotIndex])}
                      className={className}
                    >
                      <span>{timeSlot}</span>
                    </th>
                    {DAYS_OF_WEEK.map((day, dayIndex) => {
                      const cellId = `${timeSlotIndex}-${dayIndex}`
                      const timeSlot = tableData[day] && tableData[day][timeSlotIndex]
                      return (
                        <td
                          id={timeSlot ? cellId : ''}
                          key={dayIndex}
                          className={
                            selectedCells.has(cellId) && tableData[day] ? style.selected : ''
                          }
                        >
                          <div onClick={() => onCellClick(cellId, selectedCells.has(cellId))}>
                            <span className="player-price">
                              {timeSlot
                                ? timeSlot.price?.amount === 0
                                  ? timeSlot.price?.amount
                                  : timeSlot.price?.amount / 100 || '-'
                                : null}
                            </span>
                            {!extraPlayers && <span></span>}
                            <span className={style.extra}>
                              {extraPlayers && timeSlot
                                ? timeSlot.price?.amount === 0
                                  ? timeSlot.price?.amount
                                  : timeSlot?.price_per_extra_player / 100 || '-'
                                : null}
                            </span>
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PriceListTable
