import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

import {
  InputSelectTrigger,
  InputBase,
  InputContainer,
  InputSelectContent,
  InputSelect,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
  InputSelectContentHeader,
  InputLeadingContainer,
  ListItemLeading,
} from '@sweetspot/scramble-ds'

import { setLanguage } from '@sweetspot/shared/data-access/api-platform'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'
import { LANGUAGE_OPTIONS } from './consts'
import { LanguageOption } from './types'

const LanguageSelect = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { lang } = useSelector((state) => state.auth.me)

  const [selectedLang, setSelectedLang] = useState<LanguageOption | undefined>(
    LANGUAGE_OPTIONS.find((option) => option.value === lang)
  )

  const handleSelectLanguage = (language: string) => {
    const originalLanguage = lang
    dispatch({
      type: CONSTANTS.AUTH.SET_LANGUAGE,
      payload: language,
    })
    window.sessionStorage.setItem('currentLng', language)
    setLanguage(language).catch(() => {
      dispatch({
        type: CONSTANTS.AUTH.SET_LANGUAGE,
        payload: originalLanguage,
      })
      window.sessionStorage.setItem('currentLng', originalLanguage)
      addToast('languageChangeError', 'error')
    })
  }

  return (
    <InputSelect
      value={selectedLang}
      onValueChange={(language: LanguageOption) => {
        handleSelectLanguage(language.value)
        setSelectedLang(language)
      }}
    >
      <InputBase>
        <InputSelectTrigger className="w-[80px]">
          <InputContainer>
            <InputLeadingContainer>
              <img src={selectedLang?.flag} alt="flag" className="h-6 w-6" />
            </InputLeadingContainer>
            <InputSelectStatefulInput onChange={() => null} value="" />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>

      <InputSelectContent className="w-[200px]">
        <InputSelectContentView>
          <InputSelectContentHeader className="mt-2">
            {t('languageSelect')}
          </InputSelectContentHeader>
          {LANGUAGE_OPTIONS?.map((option) => (
            <InputSelectItem key={option.value} value={option}>
              <ListItem className="ml-2 h-full w-[175px] min-w-[175px] justify-center gap-2">
                <ListItemLeading>
                  <img src={option.flag} alt="flag" className="h-5 w-5" />
                </ListItemLeading>
                <ListItemMainContent className="justify-center">
                  <ListItemParagraph className="text-content-base">{option.name}</ListItemParagraph>
                </ListItemMainContent>
                {selectedLang?.value === option.value && (
                  <ListItemTrailing>
                    <i className="fa-regular fa-check h-4 w-4" />
                  </ListItemTrailing>
                )}
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default LanguageSelect
