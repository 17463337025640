import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import UserAvatar from '@sweetspot/club-portal-legacy/resources/images/user_avatar.svg'

import styles from './style.module.scss'

const menuItems = [
  { id: 0, text: 'partnerAdmin.information', disabled: false },
  { id: 1, text: 'partnerAdmin.partnerships', disabled: false },
]

const Sidebar = (props) => {
  const { activeId, onChange } = props

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <img src={UserAvatar} alt="user" />
      </div>
      {menuItems.map((menuItem) => (
        <div
          key={menuItem.id}
          className={classnames(styles.menuItem, {
            [styles.active]: menuItem.id === activeId,
            [styles.disabled]: menuItem.disabled,
          })}
          onClick={() => !menuItem.disabled && onChange(menuItem.id)}
        >
          <Text textId={menuItem.text} />
        </div>
      ))}
    </div>
  )
}

export default Sidebar

Sidebar.propTypes = {
  activeId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}
