import React from 'react'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import GolfClubIcon from '@sweetspot/club-portal-legacy/resources/images/golf-club-image.png'

import style from './style.module.scss'

const NoGolfClub = () => {
  return (
    <div className={style.container}>
      <img src={GolfClubIcon} alt="club" className={style.logo} />

      <div className={style.text}>
        <Text textId="noGolfClub.text" />
      </div>
    </div>
  )
}

export default NoGolfClub
