import { useEffect, useRef } from 'react'
import { UnregisterCallback } from 'history'
import { useHistory } from 'react-router-dom'

import { useBallDispensersContext } from '../BallDispensersProvider'

type UseRoutingBlockProps = {
  isDirty: boolean
  onSubmit: () => void
  onReset?: () => void
}

const useRoutingBlock = ({ isDirty, onReset, onSubmit }: UseRoutingBlockProps) => {
  const history = useHistory()
  const { setDiscardDialogCallbacks, setOpenDiscardDialog } = useBallDispensersContext()

  const unblock = useRef<UnregisterCallback | null>(null)

  useEffect(() => {
    unblock.current = history.block(() => {
      if (isDirty) {
        setOpenDiscardDialog?.(true)
        setDiscardDialogCallbacks?.({
          onClose: () => {
            unblock?.current?.()
            setOpenDiscardDialog?.(false)
          },
          onDiscard: () => {
            unblock?.current?.()
            onReset?.()
            setOpenDiscardDialog?.(false)
          },
          onSave: () => {
            unblock?.current?.()
            onSubmit()
            setOpenDiscardDialog?.(false)
            return true
          },
        })
        return false
      }
    })
    return () => {
      unblock?.current?.()
    }
  }, [isDirty, history, setDiscardDialogCallbacks, setOpenDiscardDialog, onSubmit, onReset])
}

export default useRoutingBlock
