import { OpeningHours as OpeningHoursBase } from '@sweetspot/club-portal-legacy/components/Warehouse/OpeningHours/openingHours'

const OpeningHours = (props) => {
  return (
    <div className={'mt-10'}>
      <OpeningHoursBase warehouseUuid={props.warehouseUuid} />
    </div>
  )
}

export { OpeningHours }
