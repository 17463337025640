import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import CAManagerModal from '@sweetspot/club-portal-legacy/modals/CAManagerModal'

import headers from './headers.json'
import AdminHelpers from '@sweetspot/club-portal-legacy/helpers/AdminHelpers'

import { queryClubUsers } from '@sweetspot/sweetspot-js/features/clubUsers/services/api-platform'
import { ROLES_ARR } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'

const actions = [
  {
    id: 1,
    label: 'membership.addMembership',
    action: 'createNewAdmin',
  },
]

class ClubAdmins extends Component {
  state = {
    currentPage: 1,
    isLoading: true,
    searchString: '',
    admins: [],
    totalPages: 1,
    totalRows: 0,
    rowsPerPage: 0,
  }

  componentDidMount = () => {
    this.getAdminsList()
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props
    if (!prevProps.location) return
    const { state } = prevProps.location

    if (location && location.state && location.state.shouldReload && !state) {
      this.getAdminsList(this.state.currentPage)
    }
  }

  handleSearch = (value = '') => {
    this.setState({ searchString: value })
  }

  handleActionConfirm = (action) => {
    if (action.id === 1) {
      this.onCreateNewAdmin()
    }
  }

  getAdminsList = (pageNum = 1) => {
    const { searchString } = this.state
    this.setState({ isLoading: true })

    queryClubUsers({
      limit: 50,
      page: pageNum,
      search: searchString || null,
      'roles[]': ROLES_ARR.map((role) => role.value),
    }).then((res) => {
      const rowsPerPage = 50
      const totalRows = res?.length || 0
      const totalPages = totalRows <= rowsPerPage ? 1 : Math.ceil(totalRows / rowsPerPage)
      const adminList = res
      if (!adminList) {
        this.setState({ isLoading: false, totalPages: 0, itemsPerPage: 0, totalItems: 0 })
      } else {
        this.setState({
          admins: AdminHelpers.formatClubAdmins(adminList),
          isLoading: false,
          totalPages,
          rowsPerPage,
          totalRows,
        })
      }
    })
  }

  onCreateNewAdmin = () => {
    this.props.history.push('/settings/sa/club-users/new')
  }

  onEditAdmin = (row) => {
    const { _id } = row
    this.props.history.push(`/settings/sa/club-users/${_id}`)
  }

  render() {
    const { isLoading, admins, totalPages, rowsPerPage, totalRows } = this.state

    return (
      <div className={style.content}>
        <Grid
          actions={actions}
          actionsEnabled
          isLoading={isLoading}
          values={admins}
          pageTitle={'settings.clubAdmins.text'}
          headers={headers}
          hideArrows
          searchEnabled
          searchPlaceholder={'.settings.clubAdmins.searchPlaceholder'}
          pagination
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          displayRangeOfRows
          onRowClick={(row) => this.onEditAdmin(row)}
          setCurrentPage={(currentPage) =>
            this.setState({ currentPage }, () => this.getAdminsList(currentPage))
          }
          onActionConfirm={this.handleActionConfirm}
          onSearchChange={this.handleSearch}
          onSearch={() => this.getAdminsList()}
        />

        <Switch>
          <Route exact path="/settings/sa/club-users/:id" component={CAManagerModal} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  golfClubs: state.golfClub,
  lang: state.auth.me.lang,
  token: state.auth.token,
})

export default connect(mapStateToProps)(ClubAdmins)

ClubAdmins.propTypes = {
  golfClubs: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string.isRequired,
}
