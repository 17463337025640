import { useExtraPlayerState } from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'
import { useMemo } from 'react'
import { areTeeTimesWithPPPModified } from '@sweetspot/sweetspot-js/features/bookings/functions/utils'

export const useBookingPaymentCalculations = ({
  isPaid,
  isPartiallyPaid,
  isConfirmed,
  booking,
}) => {
  const { calculatedPriceResult } = useExtraPlayerState()

  const {
    calculatedTotal,
    existingTotal,
    priceDifference,
    toPayTotal,
    toRefundTotal,
    paidTotal,
    refundedTotal,
  } = useMemo(() => {
    const calculatedTotal = calculatedPriceResult?.calculated_total || 0
    const existingTotal = calculatedPriceResult?.existing_total || 0
    const priceDifference = calculatedTotal - existingTotal
    const toPayTotal = calculatedPriceResult?.to_pay_total || 0
    const toRefundTotal = calculatedPriceResult?.to_refund_total || 0
    const paidTotal = calculatedPriceResult?.paid_total || 0
    const refundedTotal = calculatedPriceResult?.refunded_total || 0

    return {
      calculatedTotal,
      existingTotal,
      priceDifference,
      toPayTotal,
      toRefundTotal,
      paidTotal,
      refundedTotal,
    }
  }, [
    calculatedPriceResult?.calculated_total,
    calculatedPriceResult?.existing_total,
    calculatedPriceResult?.paid_total,
    calculatedPriceResult?.refunded_total,
    calculatedPriceResult?.to_pay_total,
    calculatedPriceResult?.to_refund_total,
  ])

  const areTeeTimesWithPPPModifiedResult = useMemo(
    () => areTeeTimesWithPPPModified(isConfirmed, booking, calculatedPriceResult?.tee_times),
    [isConfirmed, booking, calculatedPriceResult?.tee_times]
  )

  const totalAdjustments = useMemo(
    () =>
      calculatedPriceResult?.order_items?.reduce(
        (acc, item) =>
          acc + item?.adjustments?.reduce((sum, adjustment) => sum - adjustment.amount, 0),
        0
      ),
    [calculatedPriceResult?.order_items]
  )

  const showPriceDifference = useMemo(
    () => areTeeTimesWithPPPModifiedResult,
    [areTeeTimesWithPPPModifiedResult]
  )
  const showAmountToPay = useMemo(
    () => !isPaid && !isPartiallyPaid && !showPriceDifference,
    [isPaid, isPartiallyPaid, showPriceDifference]
  )
  const showDiscount = useMemo(
    () => !isPartiallyPaid && !!totalAdjustments && !showPriceDifference,
    [isPartiallyPaid, totalAdjustments, showPriceDifference]
  )

  const showPartiallyPaid = useMemo(
    () => !areTeeTimesWithPPPModifiedResult && !isPaid && isPartiallyPaid && !!toPayTotal,
    [areTeeTimesWithPPPModifiedResult, isPaid, isPartiallyPaid, toPayTotal]
  )

  const showAmountPaid = useMemo(
    () => (isPaid || isPartiallyPaid) && !showPriceDifference && !showPartiallyPaid,
    [isPaid, isPartiallyPaid, showPartiallyPaid, showPriceDifference]
  )

  return {
    calculatedTotal,
    existingTotal,
    priceDifference,
    toPayTotal,
    toRefundTotal,
    paidTotal,
    refundedTotal,
    areTeeTimesWithPPPModifiedResult,
    totalAdjustments,
    showPriceDifference,
    showAmountToPay,
    showDiscount,
    showPartiallyPaid,
    showAmountPaid,
  }
}
