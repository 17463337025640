import { createContext, useMemo, FC, PropsWithChildren, useContext } from 'react'

import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'
import { BallPricingContextValue } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/types'

import useBucketConfigurationSettings from './hooks/useBucketConfigurationSettings'
import useDemandModel from './hooks/useDemandModel'
import { BUCKET_DATA } from './constants'
import usePriceModel from './hooks/usePriceModel'

export const BallPricingContext = createContext<BallPricingContextValue>({
  areRangesLoading: false,
  areBallPricingLoading: false,
  ranges: [],
  bucketConfiguration: {
    openingHours: {},
    bucketSizes: [],
    discountsOptions: [],
    isExperienceBasedPricing: false,
    setIsExperienceBasedPricing: () => {
      return
    },
    ballPrice: {},
    maxNrOfBalls: BUCKET_DATA.maxNrOfBalls,
    maxNrOfBuckets: BUCKET_DATA.maxNrOfBuckets,
  },
  demandModel: {
    openingHours: {},
    timeSections: [],
    statusMatrix: [[]],
    DAYS_OF_WEEK: [],
  },
  priceModel: {},
})

export const BallPricingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { ranges, areRangesLoading, selectedRange, setSelectedRange } = useRanges()
  const demandModel = useDemandModel({
    rangeId: selectedRange?.id,
    demandModelId: selectedRange?.demand_model_id,
  })
  const bucketConfigurationSettings = useBucketConfigurationSettings({
    rangeId: selectedRange?.id,
    demandModelId: selectedRange?.demand_model_id,
    createDemandModel: demandModel.createDemandModel,
  })
  const priceModel = usePriceModel({
    rangeId: selectedRange?.id,
    priceModelId: selectedRange?.price_model_id,
    timeSections: demandModel.timeSections,
  })

  const value = useMemo(
    () => ({
      ranges,
      areRangesLoading,
      selectedRange,
      setSelectedRange,
      areBallPricingLoading: false,
      bucketConfiguration: {
        ...bucketConfigurationSettings,
      },
      demandModel: {
        ...demandModel,
      },
      priceModel: {
        ...priceModel,
      },
    }),
    [
      areRangesLoading,
      ranges,
      selectedRange,
      setSelectedRange,
      bucketConfigurationSettings,
      demandModel,
      priceModel,
    ]
  )

  return <BallPricingContext.Provider value={value}>{children}</BallPricingContext.Provider>
}

export const useBallPricingContext = () => useContext(BallPricingContext)
