export const RULE_TYPES = [
  {
    name: 'prime-time',
    id: 1,
    label: 'words.primeTime',
    value: 'prime_time_rule',
  },
  {
    name: 'venue-rule',
    id: 2,
    label: 'sentences.coursesAndSpaces',
    value: 'venue_rule',
  },
  {
    name: 'days-and-time',
    id: 3,
    label: 'sentences.daysAndTimes',
    value: 'days_and_times_in_week_policy_rule',
  },
]

export const TYPES_OBJ = {
  COURSESANDSPACES: 'venue_rule',
  DAYSANDTIMES: 'days_and_times_in_week_policy_rule',
  PRIMETIME: 'prime_time_rule',
}
