import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

const ColorCell = ({ color }) => {
  return <div className={style.container} style={{ background: color }} />
}

ColorCell.propTypes = {
  color: PropTypes.string,
}

export default ColorCell
