import { useEffect, useCallback } from 'react'
import { getElementBottomRight, getElementTopLeft } from '../functions/utils'
import useMergeState from './useMergeState'

const isScrollable = (ele) => {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight

  const overflowYStyle = window.getComputedStyle(ele).overflowY
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

  return hasScrollableContent && !isOverflowHidden
}

const inView = (element, scrollableParent) => {
  const { top, left } = getElementTopLeft(element)

  const { top: parentTop, left: parentLeft } = getElementTopLeft(scrollableParent)
  const { bottom: parentBottom, right: parentRight } = getElementBottomRight(scrollableParent)

  return top >= parentTop && left >= parentLeft && top <= parentBottom && left <= parentRight
}

const useDynamicFixedPosition = (wrapper, isVisible = false) => {
  const [values, setValues] = useMergeState({
    top: 0,
    left: 0,
    isInView: true,
    maxHeight: null,
  })

  const updateState = useCallback(
    (scrollableParents = []) => {
      if (wrapper && wrapper?.current) {
        const { top, left } = getElementTopLeft(wrapper.current)

        let visible = true

        if (scrollableParents?.length > 0) {
          scrollableParents.forEach((parent) => {
            if (!inView(wrapper.current, parent)) visible = false
          })
        }

        setValues({
          top,
          left,
          isInView: visible,
          maxHeight: window.innerHeight - top - 4,
        })
      }
    },
    [wrapper, isVisible]
  )

  useEffect(() => {
    updateState()
  }, [updateState])

  useEffect(() => {
    let scrollableParents = []

    let ticking = false
    const handler = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateState(scrollableParents)
          ticking = false
        })
        ticking = true
      }
    }

    if (wrapper && wrapper?.current && isVisible) {
      const getScrollableParent = (ele) => {
        if (!ele || ele === document.body) return
        if (!ele?.parentNode) return

        if (isScrollable(ele)) {
          scrollableParents.push(ele)
        }
        getScrollableParent(ele.parentNode)
      }

      getScrollableParent(wrapper.current)

      scrollableParents.forEach((ele) => {
        ele.addEventListener('scroll', handler)
      })
    }

    return () => {
      scrollableParents.forEach((ele) => {
        ele.removeEventListener('scroll', handler)
      })
    }
  }, [wrapper, isVisible, updateState])

  return values
}

export default useDynamicFixedPosition
