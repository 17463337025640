import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'
import produce from 'immer'
import { BOOKING_SOURCE_OPTIONS } from '@sweetspot/sweetspot-js/features/promotions/constants/rules'

const BookingSource = ({ rule, onSetRule, onSaveRule, disabled, loading, voucherDisabled }) => {
  const { t } = useTranslation()

  const translatedOptions = useMemo(() => {
    return BOOKING_SOURCE_OPTIONS.map((option) => ({ ...option, label: t(option.label) }))
  }, [BOOKING_SOURCE_OPTIONS])

  return (
    <div className={cx(styles.container)}>
      <MultiSelect
        noSelectedLabel={t('sentences.noBookingSourcesSelected')}
        width="full"
        buttonLabel={t('sentences.bookingSource')}
        options={translatedOptions}
        selectedOptions={translatedOptions.filter((option) =>
          rule?.configuration?.sources?.includes(option.value)
        )}
        handleChangeSelected={(newSelected) => {
          const updatedRule = produce(rule, (draft) => {
            draft.configuration.sources = newSelected.map((x) => x.value)
          })
          onSetRule(updatedRule)
          onSaveRule(updatedRule)
        }}
        disabled={disabled}
        loading={loading}
        readOnly={voucherDisabled}
        voucherDisabled={voucherDisabled}
      />
    </div>
  )
}

BookingSource.propTypes = {
  rule: PropTypes.object,
  onSetRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

BookingSource.defaultProps = {
  loading: false,
}

export default BookingSource
