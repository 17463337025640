export const ROLES_DEF = {
  ROLE_SA: {
    value: 'ROLE_SA',
    label: 'words.superAdmin',
    description: 'sentences.hasAccessToEverything',
    hidden: true,
    tier: 0,
  },
  ROLE_CLUB_OWNER: {
    value: 'ROLE_CLUB_OWNER',
    label: 'words.owner',
    description: 'sentences.hasAccessToEverything',
    tier: 1,
  },
  ROLE_PARTNER: {
    value: 'ROLE_PARTNER',
    label: 'words.partnerAdmin',
    description: '',
    hidden: true,
    tier: 1,
  },
  ROLE_ADMIN: {
    value: 'ROLE_ADMIN',
    label: 'words.admin',
    description: 'sentences.hasAccessToEverythingExceptCreatingNewUsersAndAssignRoles',
    tier: 2,
  },
  ROLE_CUSTOMER_SERVICE: {
    value: 'ROLE_CUSTOMER_SERVICE',
    label: 'sentences.customerSupport',
    description:
      'sentences.allCourseOperatorPrivilegesPlusAbilityToSetPricesCreatePostsAndSeeStatistics',
    tier: 3,
  },
  ROLE_COURSE_OPERATOR: {
    value: 'ROLE_COURSE_OPERATOR',
    label: 'sentences.courseOperator',
    description: 'sentences.allBookerPrivilegesPlusTeeTimePricingVouchersManagement',
    tier: 4,
  },
  ROLE_BOOKER: {
    value: 'ROLE_BOOKER',
    label: 'words.booker',
    description: 'sentences.hasFullAccessToBookingsManagement',
    tier: 5,
  },
  ROLE_MEMBERSHIP_SIGNUP_MANAGER: {
    value: 'ROLE_MEMBERSHIP_SIGNUP_MANAGER',
    label: 'sentences.signupManager',
    description: 'sentences.signupManagerDescription',
    tier: 6,
  },
  ROLE_PARTNER_BOOKER: {
    value: 'ROLE_PARTNER_BOOKER',
    label: 'words.partnerBooker',
    description: '',
    hidden: true,
    tier: 7,
  },
}

export const ROLES_ARR = Object.keys(ROLES_DEF).map((key) => ({ ...ROLES_DEF[key] }))
