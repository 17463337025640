import PropTypes from 'prop-types'
import CloseIcon from '../CloseIcon'

import { ReactComponent as TickGreen } from '@sweetspot/sweetspot-js/assets/svgs/tick-green.svg'
import { ReactComponent as TickRed } from '@sweetspot/sweetspot-js/assets/svgs/tick-red.svg'

const NotificationModal = ({ status, title, text, onClose }) => {
  const tickClassName = 'h-6 w-6 flex-shrink-0'
  const tickIcon =
    status === 'success' ? (
      <TickGreen className={tickClassName} />
    ) : (
      <TickRed className={tickClassName} />
    )

  return (
    <div
      className={`block w-96 rounded-lg border border-solid ${
        status === 'success' ? 'border-green-600' : 'border-red-600'
      } bg-white`}
    >
      <div className={'mb-2.5 flex'}>
        <div className={'mx-5 mt-4 flex items-center'}>{tickIcon}</div>
        <div className={'flex-grow'}>
          <p className={'pt-2.5 font-semibold leading-5'}>{title}</p>
          <p className={'font-roboto break-words text-sm font-normal leading-5 text-gray-500'}>
            {text}
          </p>
        </div>
        <div className={'pr-2 pt-2'}>
          <CloseIcon iconType={'google'} onClick={onClose} />
        </div>
      </div>
    </div>
  )
}

NotificationModal.propTypes = {
  success: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func,
}

NotificationModal.defaultProps = {
  success: false,
  title: '',
  text: '',
  onClose: () => {},
}

export default NotificationModal
