import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import { HEADERS } from './headers'
import { useState } from 'react'
import { usePendingInvites } from '@sweetspot/club-portal/feature/memberships'
import { useSelector } from 'react-redux'

export const PendingInvites = () => {
  const club = useSelector((state) => {
    return state.golfClub.list.find((club) => club.id === state.golfClub.selectedId)
  })
  const [currentPage, setCurrentPage] = useState(1)
  const { data, isFetching } = usePendingInvites({
    query: { club: club?.uuid, page: currentPage },
    queryOptions: {
      keepPreviousData: true,
    },
  })

  return (
    <div className={'relative grid h-full gap-8 p-[10px] text-base'}>
      <Grid
        filtersEnabled={false}
        values={data['hydra:member'] || []}
        pageTitle={'memberships.pendingInvites.heading_pending'}
        headers={HEADERS}
        isLoading={isFetching}
        pagination
        totalPages={Math.ceil(data['hydra:totalItems'] / data['hydra:itemsPerPage'])}
        rowsPerPage={
          data['hydra:itemsPerPage'] > data['hydra:totalItems']
            ? data['hydra:totalItems']
            : data['hydra:itemsPerPage']
        }
        totalRows={data['hydra:totalItems']}
        displayRangeOfRows
        setCurrentPage={(val) => setCurrentPage(val)}
      />
    </div>
  )
}
