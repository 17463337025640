import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import VoucherModal from '@sweetspot/club-portal-legacy/modals/VoucherModal'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import HEADERS from './headers.json'

import { ReactComponent as VoucherIcon } from '@sweetspot/club-portal-legacy/resources/images/voucher_grey.svg'

import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { getVouchers } from '@sweetspot/sweetspot-js/features/vouchers/services/api-platform'
import { withTranslation } from 'react-i18next'
import style from './style.module.scss'
import i18next from 'i18next'
import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'

const actions = [
  {
    id: 1,
    label: 'membership.addMembership',
    action: 'createVoucher',
  },
]

const filters = [
  {
    id: 1,
    name: '.status',
    options: [
      { id: 1, name: '.vouchers.active', filter: 'ACTIVE' },
      { id: 2, name: '.vouchers.invalidated', filter: 'INVALIDATED' },
      { id: 3, name: '.vouchers.expired', filter: 'EXPIRED' },
    ],
  },
  {
    id: 2,
    name: '.vouchers.text',
    options: [
      { id: 1, name: '.vouchers.standalone', filter: 'STANDALONE' },
      { id: 2, name: '.vouchers.bulkCodes', filter: 'BULKCODES' },
    ],
  },
]

class Vouchers extends Component {
  state = {
    activeFilters: [],
    sortItems: [],
    isLoading: false,
    searchString: '',
    vouchers: [],
    totalPages: 0,
    itemsPerPage: 0,
    totalItems: 0,
    currentPage: 1,
  }

  componentDidMount() {
    this.getVouchers()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { state } = prevProps.location

    if (this.props.clubID !== prevProps.clubID) {
      this.getVouchers()
    }
    if (location && location.state && location.state.shouldReload && !state) {
      this.getVouchers()
    }
  }

  handleSearch = (value = '') => {
    this.setState({ searchString: value })
  }

  handleSetCurrentPage = (pageNum = 1) => {
    this.setState({ currentPage: pageNum }, () => {
      this.getVouchers()
    })
  }

  handleActionConfirm = (action) => {
    if (action.id === 1) this.handleCreate()
  }

  setVoucherType = (voucherType) => {
    if (voucherType === 'global') return this.props.t('vouchers.label_StandaloneCode')
    if (voucherType === 'unique') return this.props.t('vouchers.label_BulkCodes')
  }

  setPeriodType = (dynamicPeriod) => {
    if (dynamicPeriod?.period_type === 'dynamic_period')
      return `${GetTranslatedString(i18next.language, `vouchers.label_Dynamic_type`)} - ${
        dynamicPeriod.duration_number
      } ${GetTranslatedString(i18next.language, 'vouchers.option_' + dynamicPeriod.duration_type)}`

    if (dynamicPeriod?.period_type === 'period') return this.props.t('vouchers.label_Period')
  }

  filterVouchers = (vouchers) => {
    return hasAccess(ACCESS_KEYS.PAGES.VOUCHERS.CREATE, this.props.role?.value)
      ? vouchers
      : vouchers.filter((item) => item.code_type === 'unique')
  }

  formatVouchers = (response) => {
    return response.map((item) => {
      return {
        id: item.id,
        name: item.name,
        durationType: this.setPeriodType(item.dynamic_period),
        validFrom: item.starts_at ? item.starts_at.substring(0, 10) : '',
        validTo: item.ends_at
          ? item.ends_at.substring(0, 10)
          : this.props.t('vouchers.label_NoEnd'),
        voucherType: this.setVoucherType(item.code_type),
        status: {
          text: this.props.t(
            item.status === 'canceled' ? 'words.invalidated' : `words.${item.status}`
          ),
          state: item.status === 'active' ? 'active' : 'inActive',
        },
        createdAt: DateHelpers.dateStringwithMinutes(item.created_at),
      }
    })
  }

  getVouchers = () => {
    const { searchString, currentPage, sortItems } = this.state

    let query = `?page=${currentPage}&club=${this.props.clubID}`

    const fieldNameMap = {
      name: 'name',
      validFrom: 'starts_at',
      validTo: 'ends_at',
      voucherType: 'codeType',
    }

    sortItems.forEach((sort) => {
      const fieldName = fieldNameMap[sort.name] || sort.name
      query += `&order[${fieldName}]=${sort.type}`
    })

    if (searchString) query += `&name=${searchString}`

    this.setState({ isLoading: true })
    getVouchers(query)
      .then((response) => {
        const vouchers = response['hydra:member']
        const totalItems = response['hydra:totalItems']
        const itemsPerPage = response['hydra:itemsPerPage']

        this.setState({
          isLoading: false,
          vouchers: this.formatVouchers(this.filterVouchers(vouchers)),
          totalItems,
          itemsPerPage,
          totalPages: Math.ceil(totalItems / itemsPerPage),
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          vouchers: [],
          totalItems: 0,
          itemsPerPage: 0,
          totalPages: 0,
        })
      })
  }

  handleSort = (sortItems) => {
    this.setState({ sortItems }, () => this.getVouchers())
  }

  handleFilters = (currentFilter) => {
    if (!currentFilter) {
      this.setState({ activeFilters: [], searchString: '' }, () => this.getVouchers())

      return null
    }
    let activeFilters = [...this.state.activeFilters]

    if (!activeFilters.includes(currentFilter)) {
      activeFilters.push(currentFilter)
    } else activeFilters.splice(activeFilters.indexOf(currentFilter), 1)
    this.setState({ activeFilters: activeFilters }, () => {
      this.handleUpdateVouchersToDisplay(activeFilters)
    })
  }

  handleUpdateVouchersToDisplay = (filters) => {
    if (!filters) {
      return
    }
    let queryString = ''

    if (this.state.activeFilters) {
      filters.forEach((currentFilter) => {
        switch (currentFilter) {
          case 'ACTIVE':
            queryString += `&status[]=active`
            break
          case 'INVALIDATED':
            queryString += `&status[]=canceled`
            break
          case 'EXPIRED':
            queryString += `&status[]=expired`
            break
          case 'STANDALONE':
            queryString += `&code_type[]=global`
            break
          case 'BULKCODES':
            queryString += `&code_type[]=unique`
            break
          default:
            break
        }
      })
    }

    this.setState({ searchString: queryString }, () => {
      this.getVouchers()
    })
  }

  handleCreate = () => {
    this.props.history.push('/vouchers/new')
  }

  handleRowClick = ({ id }) => {
    this.props.history.push(`/vouchers/${id}`)
  }

  render() {
    const { isLoading, vouchers, totalPages, itemsPerPage, totalItems } = this.state
    const { path } = this.props.match
    return (
      <div className={style.content}>
        <Grid
          {...(hasAccess(ACCESS_KEYS.PAGES.VOUCHERS.CREATE, this.props.role?.value)
            ? { actions: actions, actionsEnabled: true }
            : {})}
          filterStyles={'w-auto'}
          activeFilters={this.state.activeFilters}
          handleFilters={this.handleFilters}
          filters={filters}
          filtersEnabled
          filtersTitle={'vouchers.voucherFilter'}
          isLoading={isLoading}
          values={vouchers}
          pageTitle={'vouchers.title'}
          headers={HEADERS}
          searchEnabled
          searchPlaceholder={'.vouchers.placeholder_Search'}
          pagination
          totalPages={totalPages}
          rowsPerPage={itemsPerPage}
          totalRows={totalItems}
          displayRangeOfRows
          shouldHideColumnEdit
          onRowClick={this.handleRowClick}
          setCurrentPage={this.handleSetCurrentPage}
          onActionConfirm={this.handleActionConfirm}
          onSearchChange={this.handleSearch}
          onSearch={() => this.getVouchers()}
          onHeaderClick={this.handleSort}
        />

        {!isLoading && !vouchers.length && (
          <div className={style.noVoucher}>
            <VoucherIcon />
            <Text textId="vouchers.label_ListEmpty" />
          </div>
        )}

        <Route path={`${path}/:voucherID`} component={VoucherModal} />
      </div>
    )
  }
}

Vouchers.propTypes = {
  clubID: PropTypes.number.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = (state) => ({
  clubID: state.golfClub.selectedId,
  role: getFirstMatchingRole(state.auth.roles),
})

export default connect(mapStateToProps, null)(withTranslation()(Vouchers))
