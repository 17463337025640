import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'
import RulesWrapper from '@sweetspot/sweetspot-js/features/promotions/components/RulesWrapper'

const RulesTab = (props) => {
  if (!props.voucher || props.voucher.id === 'new') return null
  return (
    <div className={cx(styles.container, !props.isActive && styles.inactive)}>
      <RulesWrapper promotion={props.voucher} disabled={false} golfClubID={props.clubID} />
    </div>
  )
}

RulesTab.propTypes = {}

RulesTab.defaulProps = {}

export default RulesTab
