import React, { useState } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import ButtonAndDropdownSelectWrapper from '../ButtonAndDropdownSelectWrapper'
import HoverPill from '@sweetspot/sweetspot-js/common/components/HoverPill'

const MultiSelect = ({
  buttonLabel,
  title,
  options,
  selectedOptions,
  handleChangeSelected,
  noSelectedLabel,
  searchPlaceholder,
  width,
  singleSelectOnly,
  className,
  disabled,
  translateLabels,
  loading,
  dropdownProps,
  renderSelected,
  selectedClassName,
  onClickAdd,
  readOnly,
  voucherDisabled,
}) => {
  const { t } = useTranslation()

  const [showDropdown, setShowDropdown] = useState(false)

  const handleSelect = (optionsSelected) => {
    setShowDropdown(false)
    handleChangeSelected(optionsSelected)
  }

  const handleDeselect = (id) => {
    setShowDropdown(false)
    handleChangeSelected(selectedOptions.filter((y) => y.id !== id))
  }

  const widthClassName = cx({
    [style.maxWidth]: width === 'full',
    'w-1/2': width === 'half',
    'w-1/4': width === 'quarter',
  })

  return (
    <div className={cx(style.container, widthClassName, className)}>
      <span className={style.title}>{title}</span>
      <div className={cx(style.selectedOptions, selectedClassName)}>
        {!!selectedOptions.length &&
          selectedOptions.map(
            (typeof renderSelected === 'function' && renderSelected) ||
              function (item, index) {
                return (
                  <HoverPill
                    text={t(item.label)}
                    key={`pill_${index}`}
                    hoverText={item.hoverText}
                    dotEnabled={item.dotEnabled}
                    hoverDirection={item.hoverDirection}
                    onRemove={() => handleDeselect(item.id)}
                    removeEnabled={!voucherDisabled}
                  />
                )
              }
          )}
        {!selectedOptions.length && (
          <span className={style.noSelectedValues}>{noSelectedLabel}</span>
        )}
      </div>
      {!readOnly && (
        <ButtonAndDropdownSelectWrapper
          id="course-checker-dropdown"
          buttonProps={{
            className: cx(style.addButton),
            icon: 'plus',
            onClick: typeof onClickAdd === 'function' ? onClickAdd : () => setShowDropdown(true),
            text: buttonLabel,
            disabled: disabled,
            loading: loading,
          }}
          dropdownProps={{
            key: options[0]?.id,
            isVisible: showDropdown,
            options: options,
            preselectedOptions: selectedOptions,
            searchEnabled: true,
            searchPlaceholder: searchPlaceholder,
            onSave: (options) => handleSelect(options),
            onCancel: () => setShowDropdown(false),
            singleSelectOnly: singleSelectOnly,
            translateLabels: translateLabels,
            ...dropdownProps,
          }}
        />
      )}
    </div>
  )
}

MultiSelect.propTypes = {
  /** Main label */
  title: PropTypes.string.isRequired,

  /** Label for button */
  buttonLabel: PropTypes.string.isRequired,

  /** Placeholder for search input field */
  searchPlaceholder: PropTypes.string,

  /** Array of available options */
  options: PropTypes.array,

  /** Width of input field relative to container width: full | half | quarter */
  width: PropTypes.string,

  /** Function that is run when an option is selected/deselected*/
  handleChangeSelected: PropTypes.func.isRequired,

  /** String that is shown when no option is selected */
  noSelectedLabel: PropTypes.string,

  /** Array of selected options */
  selectedOptions: PropTypes.array,

  /** Boolean to only allow one option to be selected */
  singleSelectOnly: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  translateLabels: PropTypes.bool,
  loading: PropTypes.bool,
  dropdownProps: PropTypes.object,
  renderSelected: PropTypes.func,
  selectedClassName: PropTypes.string,
  onClickAdd: PropTypes.func,
  readOnly: PropTypes.bool,
}

MultiSelect.defaultProps = {
  title: '',
  buttonLabel: '',
  handleChangeSelected: () => {},
  searchPlaceholder: '',
  noSelectedLabel: '',
  options: [],
  selectedOptions: [],
  singleSelectOnly: false,
  width: 'half',
  className: '',
  disabled: false,
  translateLabels: false,
  loading: false,
  dropdownProps: {},
}

export default MultiSelect
