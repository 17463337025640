import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useTableSelector } from '../../context'
import style from '../../style.module.scss'
import PeriodAndSpaceSelect from './PeriodAndSpaceSelect'
import PriceTable from './PriceTable'
import PricingInputs from './PricingInputs'
import SaveChangesController from './SaveChangesController'

const PriceListTable = ({ period }) => {
  const { t } = useTranslation()
  const tableSelector = useTableSelector(period)
  const { selectedCells, register, onApply, onSaveChanges } = tableSelector
  const { selectedRows, selectedCols, formState, setValue, allowSaveChanges } = tableSelector
  const { selectedCourse, tableData, isLoading } = tableSelector
  const { price, price_per_extra_player, week, space } = formState
  const isCourse = selectedCourse.type === 'course'
  const extraPlayers = selectedCourse && selectedCourse.type !== 'course'
  const formDisabled =
    selectedCells.size === 0 ||
    isNaN(parseInt(price, 10)) ||
    (extraPlayers && isNaN(parseInt(price_per_extra_player, 10)))

  return (
    <div className={cx(style.timePeriodsFormContainer, style.container)}>
      <form onSubmit={onApply}>
        <div className={style.teeTimePeriodOptions}>
          <PeriodAndSpaceSelect
            selectedCourse={selectedCourse}
            setValue={setValue}
            period={period}
            week={week}
            space={space}
          />
        </div>
        {!isLoading && (
          <div className={`ml-1.5 mt-4 flex max-w-[968px] gap-2.5`}>
            <PricingInputs register={register} extraPlayers={extraPlayers} />
            <button
              className={`system-button primary-outline ${style.inputOptions} ${style.applyBtn}`}
              onClick={onApply}
              disabled={formDisabled}
            >
              {t('editPeriodsNew.apply')}
            </button>
            <div className={style.saveChanges}>
              <SaveChangesController
                isCourse={isCourse}
                period={period}
                space={space}
                formState={formState}
                onSaveChanges={onSaveChanges}
                allowSaveChanges={allowSaveChanges()}
              />
            </div>
          </div>
        )}
        {!isLoading && tableData && selectedRows && selectedCols ? (
          <PriceTable tableSelector={tableSelector} extraPlayers={extraPlayers} />
        ) : (
          <div className={style.tablePulseLoader}>
            <PulseLoader showIf={true} visible={true} className={style.pulseLoader} />
          </div>
        )}
      </form>
    </div>
  )
}

export default PriceListTable
