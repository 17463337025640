import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'

import { useTranslation } from 'react-i18next'

import style from './style.module.scss'

const { REACT_APP_MEDIA_BASE_URL } = process.env
const baseURL = REACT_APP_MEDIA_BASE_URL

const FileUploader = ({
  accept,
  fileSize,
  disabled,
  prevFile,
  confirmMessage,
  onChange,
  onError,
}) => {
  const [error, setError] = useState('')
  const [file, setFile] = useState(null)
  const [fileSrc, setFileSrc] = useState('')
  const [tempFile, setTempFile] = useState(null)

  const { t } = useTranslation()

  const _input = useRef()

  const handleChange = (e) => {
    const currentFile = e.target.files[0]

    if (!currentFile) return
    if (accept && !accept.split(',').includes(currentFile.type)) {
      setError(t('settings.webBookings.fileFormatError'))
      onError('Invalid file format')
    } else if (fileSize && currentFile.size / 1024 / 1024 > fileSize) {
      setError(t('settings.webBookings.fileSizeError'))
      onError('Invalid file format')
    } else if (prevFile) {
      setTempFile(currentFile)
    } else {
      handleReadFile(currentFile)
    }
  }

  const handleReadFile = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    setFile(file)
    if (onChange) onChange(file)

    reader.onloadend = () => {
      setFileSrc(reader.result)
      setError('')
      setTimeout(() => {
        onError('')
      }, 100)
    }
  }

  const handleCancelUpload = () => {
    setTempFile(null)
    setFileSrc('')
    if (_input && _input.current) _input.current.value = ''
  }

  const handleDelete = () => {
    setFile(null)
    setFileSrc('')
    setError('')
    setTempFile(null)
    if (onChange) onChange(null)
    if (_input && _input.current) _input.current.value = ''
    setTimeout(() => {
      onError('')
    }, 100)
  }

  return (
    <div className={style.container}>
      <input ref={_input} type="file" hidden accept={accept} onChange={handleChange} />
      {error && <label className={style.error}>{error}</label>}
      {(fileSrc || prevFile?.file_path) && (
        <div>
          <img height={50} src={fileSrc || `${baseURL}/${prevFile?.file_path}`} />
          <br />
          {file?.name}
        </div>
      )}
      <div className={style.btnGroup}>
        <Button
          size="larger"
          className={style.button}
          theme="default-outline"
          disabled={disabled}
          text={t('settings.webBookings.upload')}
          onClick={() => _input.current.click()}
        />
        <Button
          size="larger"
          className={style.button}
          theme="none"
          text={t('settings.delete')}
          onClick={handleDelete}
        />
      </div>

      <ConfirmPopup
        visible={!!tempFile}
        title={t('settings.webBookings.confirmUpload')}
        rejectText={t('cancel')}
        text={confirmMessage}
        acceptText={t('confirm')}
        onReject={handleCancelUpload}
        onClose={handleCancelUpload}
        onAccept={() => {
          handleReadFile(tempFile)
          setTempFile(null)
        }}
      />
    </div>
  )
}

FileUploader.propTypes = {
  accept: PropTypes.string,
  fileSize: PropTypes.number,
  prevFile: PropTypes.object,
  confirmMessage: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
}

FileUploader.defaultProps = {
  accept: '',
  onChange: () => {},
  onError: () => {},
}

export default FileUploader
