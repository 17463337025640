/**
 * Executes an API action
 *
 * @param {function} action
 *
 * @returns {Promise<any>}
 */
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

export function executeAction(action, ...params) {
  return new Promise((resolve, reject) => {
    action(...params)
      .then(resolve)
      .catch(reject)
  })
}

/**
 * Executes an API action with pagination
 *
 * @param {function} action
 * @param params
 *
 * @returns {Promise<any>}
 */
export function executePaginatedActionForPlatform(action, query) {
  return new Promise((resolve, reject) => {
    executeAction(action, query)
      .then((data) => {
        let list = data['hydra:member']
        const itemsPerPage = data['hydra:itemsPerPage']
        const totalItems = data['hydra:totalItems']
        let totalPages = Math.ceil(totalItems / itemsPerPage)
        if (totalPages >= 2) {
          Promise.all(
            Utilities.numberArraySequence(totalPages - 1).map((page) => {
              const updatedQuery = `${query}&page=${page + 2}`
              return executeAction(action, updatedQuery)
            })
          )
            .then((contents) => {
              contents.forEach((content) => {
                list = list.concat(content['hydra:member'])
              })

              return resolve(list)
            })
            .catch(reject)
        } else {
          return resolve(list)
        }
      })
      .catch(reject)
  })
}
