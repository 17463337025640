import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getOpeningHours,
  enableOpeningHours,
  updateOpeningHour,
  OpeningHour,
  OpeningHours,
} from '@sweetspot/shared/data-access/api-platform'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { WAREHOUSE_QUERY_KEYS } from '../constants/queryKeys'

export interface useOpeningHoursProps<T = string> {
  warehouseUuid: T
}

export const useOpeningHours = (props: useOpeningHoursProps<T>) => {
  const queryClient = useQueryClient()
  const queryKey = [WAREHOUSE_QUERY_KEYS.OPENING_HOURS, props.warehouseUuid]
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const useGetOpeningHours = <TResult = OpeningHours>(select?: (data: OpeningHours) => TResult) => {
    return useQuery(
      queryKey,
      (): OpeningHours => {
        return getOpeningHours({ warehouseId: props.warehouseUuid })
      },
      {
        staleTime: 10000,
        enabled: !!props.warehouseUuid,
      }
    )
  }

  const useEnableCustomOpeningHours = () => {
    return useMutation(
      () => {
        if (!props.warehouseUuid && openingHoursData?.opening_hours?.length > 0) return
        return enableOpeningHours({ warehouseId: props.warehouseUuid })
      },
      {
        onSuccess: (openingHours) => {
          queryClient.setQueryData(queryKey, openingHours.opening_hours)
        },
        onError: (err) => {
          addToast(t('warehouse.openingHours.error_enableCustom'))
        },
      }
    )
  }

  const useUpdateOpeningHours = (warehouseUuid?: string) => {
    return useMutation(
      (itemToUpdate: OpeningHour) => {
        let wId = props.warehouseUuid
        if (warehouseUuid) {
          wId = warehouseUuid
        }
        if (!wId) return
        return updateOpeningHour({ warehouseId: wId, body: itemToUpdate })
      },
      {
        // Optimistically update the cache value on mutate, but store
        // the old value and return it so that it's accessible in case of
        // an error
        onMutate: async (itemToUpdate: OpeningHour) => {
          await queryClient.cancelQueries(queryKey)
          const previousOpeningHours: OpeningHours = queryClient.getQueryData(queryKey) ?? []
          const updatedOpeningHours = previousOpeningHours.map((item: OpeningHour) => {
            if (item?.uuid === itemToUpdate?.uuid) {
              return itemToUpdate
            }
            return item
          })
          queryClient.setQueryData(queryKey, updatedOpeningHours)

          return { previousOpeningHours: previousOpeningHours }
        },
        // On failure, roll back to the previous value
        onError: (err, itemToUpdate: OpeningHour, context) => {
          queryClient.setQueryData(queryKey, context.previousOpeningHours)
        },
        // After success or failure, refetch the variants
        onSettled: (itemToUpdate: OpeningHour, err, previousOpeningHours, context) => {
          // queryClient.invalidateQueries(queryKey)
        },
      }
    )
  }

  return {
    useUpdateOpeningHours,
    useEnableCustomOpeningHours,
    useGetOpeningHours,
  }
}
