import React, { FC, memo, useMemo } from 'react'

import styles from './style.module.scss'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import { MembershipCard, Player } from '@sweetspot/shared/types'
import { useTranslation } from 'react-i18next'

type HeaderTitleProps = {
  player: Player | null
  membershipCards: MembershipCard[]
}

const PlayerTypeBadge = memo(({ isActiveMembership }: { isActiveMembership: boolean }) => {
  const { t } = useTranslation()

  if (isActiveMembership) {
    return (
      <div className={'rounded-2xl bg-blue-50 px-2.5 py-1 text-xs text-indigo-900'}>
        {t('member')}
      </div>
    )
  }

  return (
    <div className={'rounded-2xl bg-violet-50 px-2.5 py-1 text-xs text-violet-900'}>
      {t('guest')}
    </div>
  )
})

const HeaderTitle: FC<HeaderTitleProps> = ({ player, membershipCards }) => {
  const isActiveMembership = useMemo(
    () => membershipCards?.some((membershipCard) => membershipCard.status === 'active'),
    [membershipCards]
  )

  if (!player) return null

  return (
    <div className={styles.headerTitleContainer}>
      <div className={'flex items-center gap-1.5'}>
        <Text
          className={styles.headerTitle}
          textId={`${player.first_name} ${player.last_name}`}
        ></Text>
        <PlayerTypeBadge isActiveMembership={isActiveMembership} />
      </div>
      {player.hcp && <Text className={styles.headerSubTitle} textId={`HCP ${player.hcp}`} />}
    </div>
  )
}

export default HeaderTitle
