import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import {
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemParagraph,
  InputSelectSearchableItem,
} from '@sweetspot/scramble-ds'

import { FilteredBooking } from '../../../types'
import VenuesIcon from '../../VenuesIcon/VenuesIcon'

type HeaderSearchOptionProps = FilteredBooking & {
  handleOptionSelect: (id: string) => void
  hasGolfId: boolean
}

const HeaderSearchOption = ({
  id,
  name,
  golfId,
  date,
  courseType,
  course,
  hasGolfId,
  handleOptionSelect,
}: HeaderSearchOptionProps) => {
  return (
    <InputSelectSearchableItem
      value={`${id}`}
      onSelect={handleOptionSelect}
      className="pr-4 [&:not(:last-child)>div]:border-b"
    >
      <ListItem className="ml-3 h-full w-full gap-3">
        <div className="flex flex-1 gap-1">
          <ListItemLeading>
            <FontAwesomeIcon className="h-4" icon={faUser} />
          </ListItemLeading>
          <ListItemMainContent className="justify-center">
            <ListItemParagraph className="text-content-base">{name}</ListItemParagraph>
            {hasGolfId && (
              <ListItemParagraph className="text-content-sm">{golfId}</ListItemParagraph>
            )}
          </ListItemMainContent>
        </div>
        <div className="flex flex-1 gap-1">
          <ListItemLeading>
            <VenuesIcon courseType={courseType as GolfCourseTypes} type="regular" />
          </ListItemLeading>
          <ListItemMainContent className="justify-center">
            <ListItemParagraph className="text-content-base">{course}</ListItemParagraph>
            <ListItemParagraph className="text-content-sm">{date}</ListItemParagraph>
          </ListItemMainContent>
        </div>
      </ListItem>
    </InputSelectSearchableItem>
  )
}

export default HeaderSearchOption
