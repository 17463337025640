import { apiPlatformClient, request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import {
  CreateRangePayload,
  QueryRangesPayload,
} from '@sweetspot/sweetspot-js/features/ranges/services/types'
import { Range } from '@sweetspot/shared/types'

export const queryRanges = async (payload: QueryRangesPayload): Promise<{ ranges: Range[] }> => {
  const response = await apiPlatformClient().GET('/api/ranges', {
    params: {
      query: payload,
    },
  })

  // @ts-expect-error-next-line
  return response?.data
}

export const createRange = (body: CreateRangePayload): Promise<Range> => {
  const url = `${getApiPlatformBaseUrl()}/ranges`
  return request(url, { method: 'POST', body }) as Promise<Range>
}
