import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import Utilities from '../../../../helpers/Utilities'
import DOMHelpers from '../../../../helpers/DOMHelpers'
import Text from '../../../Text'

export default class SelectCell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedId: Utilities.isSet(this.props.initialId) ? this.props.initialId : null,
      isOpen: false,
    }

    this.toggleOpen = this.toggleOpen.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialId !== prevProps.initialId) {
      this.setState({
        selectedId: Utilities.isSet(this.props.initialId) ? this.props.initialId : null,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick(event) {
    if (!DOMHelpers.didClickInsideArray([this._container, this._listContainer], event)) {
      this.setState({
        isOpen: false,
      })
    }
  }

  handleSelect(id) {
    this.setState({
      selectedId: id,
    })
    if (this.props.onChange) this.props.onChange(id)
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  getSelectedOption() {
    if (this.state.selectedId === null) return null
    return this.props.options.find((option) => option.id === this.state.selectedId)
  }

  renderList() {
    if (this.state.isOpen) {
      return (
        <div className={style.listContainer} ref={(container) => (this._listContainer = container)}>
          <div className={style.listContent}>
            {this.props.options.map((option, index) => {
              let optionString = option.name
              if (option.name.toString().substring(0, 1) === '.') {
                optionString = <Text textId={option.name} />
              }

              return (
                <div
                  className={style.option}
                  onClick={() => {
                    this.handleSelect(option.id)
                  }}
                  key={index}
                >
                  {optionString}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }

  render() {
    let selectedOption = this.getSelectedOption()
    let selectedString = '...'
    if (selectedOption && selectedOption.name) {
      if (selectedOption.name.toString().substring(0, 1) === '.') {
        selectedString = <Text textId={selectedOption.name} />
      } else {
        selectedString = selectedOption.name
      }
    }

    let containerClassNames = [style.container]
    if (this.state.isOpen) containerClassNames.push(style.open)

    return (
      <div
        className={containerClassNames.join(' ')}
        onClick={this.toggleOpen}
        ref={(container) => (this._container = container)}
      >
        <div className={style.textContainer}>{selectedString}</div>
        {this.renderList()}
      </div>
    )
  }
}

SelectCell.propTypes = {
  options: PropTypes.array.isRequired,
  initialId: PropTypes.number,
  onChange: PropTypes.func,
}
