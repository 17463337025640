import React, { useMemo, useReducer, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { ReactComponent as AddIcon } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon-2.svg'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import {
  initialState,
  reducer,
  actions,
} from '@sweetspot/sweetspot-js/features/bookings/functions/bookingsState'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryPaginatedPartnerships } from '@sweetspot/sweetspot-js/features/partnerships/services/api-platform'
import _uniqBy from 'lodash/uniqBy'
import { useToasts } from 'react-toast-notifications'
import PartnershipBookingSelect from '@sweetspot/sweetspot-js/features/bookings/components/PartnershipBookingSelect'
import { isBookingConsideredPaid } from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  assignPartnershipToBooking,
  removePartnershipFromBooking,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'

const AddPartnershipButton = ({
  className,
  canEdit,
  iconClassName,
  itemWrapperClassName,
  buttonText,
  popoverClassName,
  theme,
  booking,
  onUpdateBooking,
}) => {
  const { t } = useTranslation()
  const boxRef = useRef(null)
  const dropdownRef = useRef(null)
  const [showBox, setShowBox] = useState(false)
  const [forceCloseDropdown, setForceCloseDropdown] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedPartnership, setSelectedPartnership] = useState(null)

  const { addToast } = useToasts()

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const element = document.getElementById('button-dropdowns')
    if (element) {
      dropdownRef.current = element
    }
  }, [])

  useOnClickOutside(
    boxRef,
    () => {
      setShowBox(false)
      setForceCloseDropdown(true)
    },
    true,
    dropdownRef
  )

  const roles = useRoles()
  const accessTable = useMemo(() => {
    const role = getFirstMatchingRole(roles)
    if (!role)
      return {
        SET_PARTNERSHIP: false,
      }
    return {
      SET_PARTNERSHIP: hasAccess(ACCESS_KEYS.FEATURES.BOOKING.EDIT.SET_PARTNERSHIP, role?.value),
    }
  }, [roles])

  useQuery(
    [CLUB_QUERIES.PARTNERSHIPS, booking?.club?.id],
    () => {
      dispatch({ type: actions.PARTNERSHIPS_UPDATING })
      return queryPaginatedPartnerships({
        'club.id[]': booking?.club?.id,
        'course.id': booking?.course?.id,
        limit: 50,
      })
    },
    {
      enabled: !!booking?.club?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadPartnerships'), { appearance: 'error' })
      },
      // NOTE: Backend sends dublicate objects for some reason, that breaks search
      select: (res) => _uniqBy([...res.map((r) => r[0]['hydra:member'])].flat(), 'name'),
      onSuccess: (data) => {
        dispatch({
          type: actions.PARTNERSHIPS_UPDATED,
          payload: data.filter((x) => x.status === 'active' || x.status === 'upcoming'),
        })
      },
    }
  )

  const applyPartnership = async () => {
    if (!selectedPartnership) return

    setLoading(true)

    const { uuid: bookingUuid } = booking

    if (selectedPartnership?.id === 0) {
      const [res, err] = await to(removePartnershipFromBooking(bookingUuid))
      if (!res || err) {
        addToast(t('sentences.couldNotUnassignPartnership'), { appearance: 'error' })
      }
      await onUpdateBooking()
      setLoading(false)
      setShowBox(false)
      return
    }

    const { uuid } = selectedPartnership

    const [res, err] = await to(assignPartnershipToBooking(bookingUuid, uuid))

    if (!res || err) {
      if (err?.violations?.find((x) => x.errorName === 'NOT_ELIGIBLE_PARTNERSHIP_ERROR')) {
        addToast(t('sentences.partershipNotViable'), { appearance: 'error' })
      } else if (
        err?.violations?.find((x) => x.errorName === 'PARTNERSHIP_HAS_BEEN_ASSIGNED_ERROR')
      ) {
        addToast(t('sentences.partnershipAlreadyAssigned'), { appearance: 'warning' })
      } else if (
        err?.message === 'Number of rounds is out of limit' ||
        err?.detail === 'Number of rounds is out of limit'
      ) {
        addToast(t('sentences.maxNumberOfRoundsReached'), { appearance: 'error' })
      } else if (
        err?.message === 'Play value is out of limit' ||
        err?.detail === 'Play value is out of limit'
      ) {
        addToast(t('sentences.maxPlayValueReached'), { appearance: 'error' })
      } else if (
        err?.detail ===
        'Could not assign partnership. Post-pay partnerships currently not supported.'
      ) {
        addToast(t('sentences.couldNotAssignPostPayPartner'), { appearance: 'error' })
      } else {
        addToast(t('sentences.couldNotAssignPartnership'), { appearance: 'error' })
      }
      setLoading(false)
      setShowBox(false)
      return
    }

    await onUpdateBooking()
    setLoading(false)
    setShowBox(false)
  }

  const handleCancelAction = () => {
    setSelectedPartnership(null)
    setShowBox(false)
    setForceCloseDropdown(true)
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
        },
        className
      )}
    >
      <div className={cx({ [styles.buttonContainer]: !canEdit })}>
        <Button
          className={cx(styles.itemWrapper, !canEdit && styles.disabled, itemWrapperClassName)}
          size="default"
          theme="gray"
          onClick={
            canEdit
              ? () => {
                  setShowBox((showBox) => !showBox)
                  setForceCloseDropdown(false)
                }
              : () => {}
          }
          loading={loading}
          loaderStyle="pulse"
          disabled={!canEdit}
        >
          <AddIcon className={cx(!canEdit && styles.disabledIcon, iconClassName)} />
          {buttonText}
        </Button>
      </div>
      <div ref={boxRef} className={cx(popoverClassName, styles.box, showBox && styles.visible)}>
        <PartnershipBookingSelect
          forceCloseDropdown={forceCloseDropdown}
          applyOnSelect={false}
          onSelect={setSelectedPartnership}
          selectedItem={selectedPartnership}
          applyloading={loading}
          buttonTheme="light-gray-outline"
          className={styles.selectContainer}
          withIcon={false}
          booking={booking}
          buttonStyle={styles.button}
          partnerships={state.partnerships}
          loading={state.loaders.partnerships}
          canEdit={!isBookingConsideredPaid(booking) && accessTable.SET_PARTNERSHIP}
        />
        <div className={styles.buttonsContainer}>
          <button
            onClick={handleCancelAction}
            disabled={false}
            className="system-button primary-outline md-32"
          >
            {t('words.cancel')}
          </button>
          <button
            onClick={applyPartnership}
            disabled={!selectedPartnership || loading}
            className="system-button primary md-32"
          >
            {loading ? (
              <PulseLoader showIf={true} color="#FFFFFF" dotStyles={{ width: 10, height: 10 }} />
            ) : (
              t('words.apply')
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

AddPartnershipButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  itemWrapperClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  canEdit: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  booking: PropTypes.object.isRequired,
  onUpdateBooking: PropTypes.func,
}

AddPartnershipButton.defaultProps = {
  canEdit: true,
  className: '',
  iconClassName: '',
  itemWrapperClassName: '',
  onUpdateBooking: () => {},
}

export default AddPartnershipButton
