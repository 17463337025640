import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './style.module.scss'

import FeedbackContainer from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/FeedbackContainer'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import { useTranslation } from 'react-i18next'

const ToggleSwitchControlled = ({
  checked,
  onChange,
  onCheck,
  onUncheck,
  disabled,
  className,
  width,
  label,
  round,
  textChecked,
  textUnchecked,
  error,
  switchWidth,
  sliderClassName,
  large,
  medium,
}) => {
  const { t } = useTranslation()

  const handleChange = () => {
    if (disabled) return

    const newValue = !checked

    if (onChange) onChange(newValue)
    if (onCheck && newValue === true) onCheck()
    if (onUncheck && newValue === false) onUncheck()
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        {
          [styles.widthAuto]: width === 'auto',
          [styles.widthFull]: width === 'full',
          [styles.widthHalf]: width === 'half',
          [styles.widthQuarter]: width === 'quarter',
        },
        className
      )}
    >
      <Label label={label} lightColor />
      <div
        className={cx(
          styles.container,
          {
            [styles.large]: large,
            [styles.medium]: medium,
          },
          {
            [styles.widthAuto]: switchWidth === 'auto',
            [styles.widthFull]: switchWidth === 'full',
            [styles.widthHalf]: switchWidth === 'half',
            [styles.widthQuarter]: switchWidth === 'quarter',
          }
        )}
      >
        <input type="checkbox" value={checked} className={styles.checkboxInput} />
        <div
          className={cx(styles.innerContainer, {
            [styles.containerRound]: round,
            [styles.containerDisabled]: disabled,
          })}
          onClick={handleChange}
        >
          <div
            className={cx(
              styles.slider,
              {
                [styles.sliderRound]: round,
              },
              {
                [styles.sliderUnchecked]: !checked,
                [styles.sliderChecked]: checked,
              },
              {
                [styles.sliderDisabledUnchecked]: !checked && disabled,
                [styles.sliderDisabledChecked]: checked && disabled,
              },
              sliderClassName
            )}
          ></div>
          <span
            className={cx(styles.text, {
              [styles.textActive]: !checked,
            })}
          >
            {textUnchecked
              ? textUnchecked.substring(0, 1) === '.'
                ? t(textUnchecked.substring(1))
                : textUnchecked
              : 'OFF'}
          </span>
          <span
            className={cx(styles.text, {
              [styles.textActive]: checked,
            })}
          >
            {textChecked
              ? textChecked.substring(0, 1) === '.'
                ? t(textChecked.substring(1))
                : textChecked
              : 'ON'}
          </span>
        </div>
      </div>
      {error && <FeedbackContainer error={error} />}
    </div>
  )
}

export default ToggleSwitchControlled

ToggleSwitchControlled.propTypes = {
  /**
   * Determines if the **ToggleSwitchControlled** is checked
   */
  checked: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitchControlled** should have rounded corners
   */
  round: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitchControlled** should be of medium size
   */
  medium: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitchControlled** should be of large size
   */
  large: PropTypes.bool,
  /**
   * Text to display when the **ToggleSwitchControlled** is checked. If no text is provided a check icon will
   * be displayed
   *
   * If the text starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  textChecked: PropTypes.string,
  /**
   * Text to display when the **ToggleSwitchControlled** is unchecked. If no text is provided a cross icon
   * will be displayed
   *
   * If the text starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  textUnchecked: PropTypes.string,
  /**
   * Function to run when the component is toggled
   *
   * @param {Boolean} checked state of the **ToggleSwitchControlled**
   */
  onChange: PropTypes.func,
  /**
   * Function to run when the **ToggleSwitchControlled** is checked
   */
  onCheck: PropTypes.func,
  /**
   * Function to run when the **ToggleSwitchControlled** is unchecked
   */
  onUncheck: PropTypes.func,
  /**
   * Controls width of container, optional
   */
  width: PropTypes.string,
  /**
   * Width of switch, optional
   */
  switchWidth: PropTypes.string,
  /**
   * ClassName for container element, optional
   */
  className: PropTypes.string,
  /**
   * If switch should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Any error string, optional
   */
  error: PropTypes.string,
  /**
   * Optional label for field
   */
  label: PropTypes.string,
  /**
   * Own classname for slider
   */
  sliderClassName: PropTypes.string,
}

ToggleSwitchControlled.defaultProps = {
  width: 'auto',
  className: '',
  round: true,
  disabled: false,
  error: '',
  label: '',
  sliderClassName: '',
}
