/* eslint-disable react/require-default-props */
import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { cn } from '../../../utils'

export interface ToggleProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  className?: string
}

const Toggle = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, ToggleProps>(
  ({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
      className={cn(
        'group peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors disabled:cursor-not-allowed',
        'focus-visible:ring-ring focus-visible:ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
        'data-[state=checked]:bg-text-success data-[state=checked]:disabled:bg-text-pale',
        'data-[state=unchecked]:bg-base-white data-[state=unchecked]:border-text-subtle data-[state=unchecked]:disabled:border-text-pale data-[state=unchecked]:active:border-text-medium',
        className
      )}
      data-testid="scramble-toggle"
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'bg-base-white pointer-events-none block h-3 w-3 rounded-full shadow-lg ring-0 transition-transform',
          'data-[state=checked]:bg-text-light data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0.5',
          'data-[state=unchecked]:bg-text-subtle data-[state=unchecked]:data-[disabled]:bg-text-pale group-active:data-[state=unchecked]:bg-text-medium'
        )}
      />
    </SwitchPrimitives.Root>
  )
)
Toggle.displayName = 'Toggle'

export { Toggle }
