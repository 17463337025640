import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { cn } from '@sweetspot/scramble-ds'

interface PriceRowProps {
  text: string
  price: string
  textClassName?: string
  loading?: boolean
  icon?: IconProp
  onPressIcon?: () => void
  hasOwner?: boolean
}

export const PriceRow = (props: PriceRowProps) => {
  if (props.loading) {
    return <PulseLoader contentClassName={'pl-0'} showIf={true} color="#E5E5E5" fillHeight />
  }
  return (
    <div className={'text-brand-black flex justify-between  pb-1 pl-2 text-sm'}>
      <span className={cn('text-stone-900', props.textClassName)}>{props.text}</span>
      <div>
        <span className={cn('whitespace-nowrap pr-2 text-stone-900', props.textClassName)}>
          {props.price}
        </span>
        {props.hasOwner && props.icon ? (
          <FontAwesomeIcon
            onClick={props.onPressIcon}
            className="cursor-pointer text-xs transition-transform hover:scale-125 [&>*]:text-stone-800"
            icon={props.icon}
          />
        ) : (
          <span className="inline-flex w-[12px]"> </span>
        )}
      </div>
    </div>
  )
}
