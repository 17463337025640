import { ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import { t } from 'i18next'
import m from 'moment'

export default class AdminHelpers {
  static formatPartnerAdminList(partnerAdmins) {
    if (!partnerAdmins) return
    const adminList = []

    partnerAdmins.forEach((admin) => {
      const lastLogin = admin?.last_login
        ? m.utc(admin.last_login).local().format('YYYY-MM-DD HH:mm')
        : ''
      const createdAt = admin?.created_at
        ? m.utc(admin.created_at).local().format('YYYY-MM-DD HH:mm')
        : ''

      const roles =
        admin.roles.length > 0 ? admin?.roles?.map((role) => t(ROLES_DEF[role].label)) : []

      adminList.push({
        _id: admin.id,
        uuid: admin.uuid,
        name: `${admin.first_name} ${admin.last_name}`,
        company: admin.company,
        email: admin.email,
        language: admin.language,
        phone: admin.phone,
        partnerType: admin.partner_type,
        role: roles.join(', '),
        lastLogin,
        createdAt,
      })
    })

    return adminList
  }

  static formatClubAdmins(clubAdmins) {
    if (!clubAdmins) return
    const adminList = []

    clubAdmins.forEach((admin) => {
      const lastLogin = admin?.last_login
        ? m.utc(admin.last_login).local().format('YYYY-MM-DD HH:mm')
        : ''
      const createdAt = admin?.created_at
        ? m.utc(admin.created_at).local().format('YYYY-MM-DD HH:mm')
        : ''

      adminList.push({
        _id: admin.id,
        name: `${admin.first_name} ${admin.last_name}`,
        email: admin.email,
        clubs: admin.clubs,
        lastLogin,
        createdAt,
      })
    })

    return adminList
  }
}
