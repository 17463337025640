import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { BOOKING_WINDOW_OPTIONS } from '../../../../constants'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'

import style from './style.module.scss'

const BookingWindow = ({ configuration, updatePolicy }) => {
  const { t } = useTranslation()
  const [timeValue, setTimeValue] = useState(configuration?.time_unit_value ?? 1)
  const [fromTimeValue, setFromTimeValue] = useState(configuration?.from_time || '06:00')

  const [timeValueError, setTimeValueError] = useState(null)
  const [fromTimeError, setFromTimeError] = useState(null)

  const handleChangeTimeUnit = (unit) => {
    let config = { ...configuration, time_unit: unit.value }
    if (unit.value === 'hour') {
      config.from_time = null
      if (config.time_unit_value === 0) {
        config.time_unit_value = 1
        setTimeValue(1)
      }
    }
    updatePolicy(config)
  }

  const handleToggleFromTime = (val) => {
    const config = { ...configuration, from_time: val ? '06:00' : null }

    if (!val) {
      setFromTimeValue('06:00')
      setFromTimeError(null)
    }
    updatePolicy(config)
  }

  const handleSaveTimevalue = () => {
    if (configuration?.time_unit === 'day' && timeValue < 0)
      setTimeValueError(`${t('teeTimeCategory.minValue')} "0"`)
    else if (configuration?.time_unit === 'hour' && timeValue < 1)
      setTimeValueError(`${t('teeTimeCategory.minValue')} "1"`)
    else if (timeValue === null || timeValue === undefined)
      setTimeValueError(t('teeTimeCategory.requiredValue'))
    else {
      setTimeValueError(null)
      updatePolicy({ ...configuration, time_unit_value: timeValue })
    }
  }

  const handleSaveFromTimevalue = () => {
    if (!fromTimeValue) setFromTimeError(t('teeTimeCategory.requiredValue'))
    else updatePolicy({ ...configuration, from_time: fromTimeValue })
  }

  return (
    <div className={style.container}>
      <div className={style.unitWrapper}>
        <TextInputField
          type="number"
          value={timeValue}
          error={timeValueError}
          containerWidth="quarter"
          onChange={(val) => setTimeValue(parseInt(val))}
          onInputBlur={() => handleSaveTimevalue()}
          inputProps={{
            min: configuration?.time_unit === 'hour' ? 1 : 0,
            step: 1,
          }}
        />
        <TextInputOptionsSelect
          containerClassName={style.timeUnitSelect}
          noMargin
          options={BOOKING_WINDOW_OPTIONS}
          value={t(BOOKING_WINDOW_OPTIONS.find((x) => x.value === configuration?.time_unit)?.label)}
          error={timeValueError ? ' ' : null}
          onOptionSelect={(unit) => handleChangeTimeUnit(unit)}
        />
      </div>

      {configuration?.time_unit === 'day' ? (
        <div className={style.fromTimeWrapper}>
          <span className={style.secondaryLabel}>{t('fromTime')}</span>
          <ToggleButton
            containerClassName={style.fromTimeToggle}
            value={!!configuration?.from_time}
            onChange={(val) => handleToggleFromTime(val)}
            containerWidth="quarter"
          />
          <TextInputField
            value={fromTimeValue}
            onChange={(val) => setFromTimeValue(val)}
            onInputBlur={() => handleSaveFromTimevalue()}
            error={fromTimeError}
            type="time"
            containerWidth="quarter"
            disabled={!configuration?.from_time}
          />
        </div>
      ) : null}
    </div>
  )
}

BookingWindow.propTypes = {
  configuration: PropTypes.object,
  updatePolicy: PropTypes.func,
}

export default BookingWindow
