import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { t } from 'i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import {
  getPricingOverrides,
  addPriceOverride,
  removePriceOverride,
} from '@sweetspot/shared/data-access/api-platform'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import PriceForm from '../PriceForm'
import PricingOverride from '../PricingOverride'

import style from './style.module.scss'
import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'

const initialPeriod = {
  activePeriodId: 0,
  id: 0,
  name: '',
  fromDate: '',
  toDate: '',
  minPriceWork: 0,
  minPriceWeek: 0,
  basePriceWork: 0,
  basePriceWeek: 0,
  demandWork: 0,
  demandWeek: 0,
  supplyWork: 0,
  supplyWeek: 0,
  maxPriceWork: 0,
  maxPriceWeek: 0,
  avgPriceWork: 0,
  avgPriceWeek: 0,
  overrides: [],
}

export class PriceControl extends Component {
  constructor() {
    super()

    this.state = {
      ...initialPeriod,
      isEdit: false,
      overrides: [],
    }

    this.handlePeriodChange = this.handlePeriodChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.toggleEditTitle = this.toggleEditTitle.bind(this)
    this.createPeriod = this.createPeriod.bind(this)
    this.updatePeriod = this.updatePeriod.bind(this)
    this.onAddPriceOverride = this.onAddPriceOverride.bind(this)
    this.onRemovePriceOverride = this.onRemovePriceOverride.bind(this)
    this.updateChart = this.updateChart.bind(this)
    this.removePeriod = this.removePeriod.bind(this)
  }

  componentDidMount() {
    this.initializePricePeriods()
    this.getPricingOverrides()
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.pricePeriods, this.props.pricePeriods) ||
      prevProps.activePeriodId !== this.props.activePeriodId
    ) {
      this.initializePricePeriods()
      this.getPricingOverrides()
    }
  }

  getPricingOverrides() {
    const { activePeriodId } = this.props
    try {
      getPricingOverrides(activePeriodId).then((response) => this.setState({ overrides: response }))
    } catch (error) {
      console.log(error)
    }
  }

  initializePricePeriods() {
    const { pricePeriods, activePeriodId } = this.props
    const activePeriod = pricePeriods.find((item) => item.id === activePeriodId)
    if (!activePeriod) {
      this.setState({ ...initialPeriod })
      return
    }

    this.setState({
      activePeriodId: activePeriodId,
      ...activePeriod,
      fromDate: activePeriod.fromDate.substr(0, 10),
      toDate: activePeriod.toDate.substr(0, 10),
    })
  }

  handlePeriodChange(id) {
    this.props.onChangePeriodId(id)
  }

  handleChange(value, target) {
    this.setState({ [target]: value })
  }

  handleNameChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleInputChange(e) {
    const { name, value } = e.target
    if (name === 'avgPriceWork') {
      if (!isNaN(value)) {
        this.setState({ [name]: value ? +parseFloat(value).toFixed(2) : '' })
      }
    } else {
      this.setState({ [name]: value ? parseInt(value) : 0 })
    }
    this.setState(
      (prevState) => ({
        maxPriceWork: prevState.basePriceWork + prevState.demandWork + prevState.supplyWork,
        maxPriceWeek: prevState.basePriceWeek + prevState.demandWeek + prevState.supplyWeek,
      }),
      () => {
        this.props.onPeriodChange(this.state)
      }
    )
  }

  toggleEditTitle() {
    this.setState({ isEdit: !this.state.isEdit })
  }

  createPeriod() {
    const { pricePeriods, addToast } = this.props
    if (pricePeriods.filter((period) => !period.active).length) {
      addToast('hasPriceDraftError', 'error')
      return
    }
    this.setState({ ...initialPeriod, isEdit: true })
    this.props.onCreatePeriod()
  }

  updateChart() {
    const { fromDate, toDate } = this.state

    this.props.onUpdateChart({
      ...this.state,
      fromDate: `${fromDate} 00:00:00`,
      toDate: `${toDate} 23:59:59`,
    })
  }

  updatePeriod() {
    const { fromDate, toDate } = this.state
    const { pricePeriods, activePeriodId } = this.props

    const activePeriod = pricePeriods.find((item) => item.id === activePeriodId)
    this.setState({ isEdit: false })
    this.props.onUpdatePeriod({
      ...this.state,
      overrides: activePeriod.overrides,
      fromDate: `${fromDate} 00:00:00`,
      toDate: `${toDate} 23:59:59`,
    })
  }

  onAddPriceOverride(fromDate, toDate, price, currency) {
    const { activePeriodId } = this.props
    const payload = {
      id: activePeriodId,
      from: fromDate,
      to: toDate,
      amount: price,
      currency,
    }
    try {
      addPriceOverride(payload).then((response) => {
        this.getPricingOverrides()
        this.props.addToast('updatePricePeriodSuccess', 'success')
      })
    } catch (error) {
      console.log(error)
    }
  }

  onRemovePriceOverride(overrideId) {
    const { overrides } = this.state
    const filteredOverrides = overrides.filter((override) => override?.id !== overrideId)
    this.setState({ overrides: filteredOverrides })
    try {
      removePriceOverride(overrideId).then((response) => {
        this.props.addToast('updatePricePeriodSuccess', 'success')
      })
    } catch (error) {
      console.log(error)
    }
  }

  removePeriod() {
    this.props.onRemovePeriod(this.state.activePeriodId)
  }

  renderDates() {
    const { isEdit, name, fromDate, toDate, activePeriodId } = this.state
    const { pricePeriods, lang } = this.props
    const activePeriod = pricePeriods.find((item) => item.id === activePeriodId)
    const activePeriodIndex =
      pricePeriods.length - pricePeriods.findIndex((item) => item.id === activePeriodId)

    if (!activePeriod || activePeriod.default) return
    if (isEdit) {
      return (
        <div className={style.formGroup}>
          <div className="ss-form-group">
            <label className={`ss-label ${style.text}`}>{t('name')}</label>
            <input className="ss-input" name="name" value={name} onChange={this.handleNameChange} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <TextInputField
                label=".from"
                type="calendar"
                calendarSettings={{
                  selectedDate: fromDate,
                }}
                value={fromDate}
                onChange={(value) => this.handleChange(value, 'fromDate')}
                CalendarComponent={MiniCalendar}
              />
            </div>

            <div>
              <TextInputField
                label=".to"
                type="calendar"
                calendarSettings={{
                  selectedDate: toDate,
                }}
                value={toDate}
                onChange={(value) => this.handleChange(value, 'toDate')}
                CalendarComponent={MiniCalendar}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={style.dates}>
          {DateHelpers.toPTFormDateString(fromDate, lang)} -
          {DateHelpers.toPTFormDateString(toDate, lang)}
          <br />
          {`Priority ${activePeriodIndex}`}
        </div>
      )
    }
  }

  renderSelectPeriod() {
    const { activePeriodId } = this.state
    const { pricePeriods } = this.props
    if (!pricePeriods || !pricePeriods.length) return
    const activePeriod = pricePeriods.filter((period) => period.id === activePeriodId)[0]
    const selectValues = pricePeriods.map((period) => {
      return {
        ...period,
        name: !period.active ? `${period.name} (Draft)` : period.name,
      }
    })

    return (
      <div className={style.selectPeriod}>
        <div className={style.title}>
          <DropdownSelect
            initialId={activePeriodId}
            width={250}
            values={selectValues}
            onSelect={(id) => this.handlePeriodChange(id)}
          />
          {activePeriod && !activePeriod.default && (
            <FontAwesomeIcon icon={faPen} onClick={this.toggleEditTitle} />
          )}
        </div>
        {this.renderDates()}
      </div>
    )
  }

  renderActionGroup() {
    const { isEdit, name, activePeriodId, fromDate, toDate } = this.state

    const isValidForm =
      name &&
      activePeriodId &&
      DateHelpers.isValidDate(fromDate) &&
      DateHelpers.isValidDate(toDate) &&
      DateHelpers.checkValidPeriod(new Date(fromDate), new Date(toDate))
    const activePeriod = this.props.pricePeriods.filter((period) => period.id === activePeriodId)[0]

    if (isEdit) {
      return (
        <div className={style.btnGroup}>
          <button
            className="system-button info-outline md-32"
            disabled={!isValidForm}
            onClick={this.updateChart}
          >
            {t('pricing.updateChart')}
          </button>
          <div>
            {activePeriod && !activePeriod.default && (
              <button
                className="system-button info-outline md-32"
                disabled={!activePeriodId}
                onClick={this.removePeriod}
              >
                {t('pricing.delete')}
              </button>
            )}
            <button
              className="system-button info-outline md-32"
              disabled={!isValidForm}
              onClick={this.updatePeriod}
            >
              {t('pricing.savePeriod')}
            </button>
          </div>
        </div>
      )
    }
    return (
      <div className={style.btnGroup2}>
        {activePeriod && !activePeriod.default && (
          <button
            className="system-button info-outline md-32"
            disabled={!activePeriodId}
            onClick={this.removePeriod}
          >
            {t('pricing.delete')}
          </button>
        )}
        <button
          className="system-button info-outline md-32"
          disabled={!isValidForm}
          onClick={this.updateChart}
        >
          {t('pricing.updateChart')}
        </button>
        <button
          className="system-button info-outline md-32"
          disabled={!isValidForm}
          onClick={this.updatePeriod}
        >
          {t('pricing.savePeriod')}
        </button>
      </div>
    )
  }

  renderBody() {
    const { pricePeriods, activePeriodId, maxPrice, avgPrice, isWeekend } = this.props

    if (!pricePeriods || !pricePeriods.length) return
    return (
      <div>
        {this.renderSelectPeriod()}
        <PriceForm
          {...this.state}
          maxPrice={maxPrice}
          avgPrice={avgPrice}
          isWeekend={isWeekend}
          handleChange={this.handleInputChange}
        />
        {this.renderActionGroup()}
        <PricingOverride
          pricePeriods={pricePeriods}
          activePeriodId={activePeriodId}
          overrides={this.state.overrides}
          onAddPriceOverride={this.onAddPriceOverride}
          onRemovePriceOverride={this.onRemovePriceOverride}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={style.priceControl}>
        <div className={style.createBtn}>
          <button className="system-button info-outline md-32" onClick={this.createPeriod}>
            {t('pricing.newPeriod')}
          </button>
        </div>
        {this.renderBody()}
      </div>
    )
  }
}

PriceControl.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  activePeriodId: PropTypes.number.isRequired,
  pricePeriods: PropTypes.array.isRequired,
  selectedGolfClubId: PropTypes.number.isRequired,
  maxPrice: PropTypes.number.isRequired,
  avgPrice: PropTypes.number.isRequired,
  isWeekend: PropTypes.bool.isRequired,
  onCreatePeriod: PropTypes.func.isRequired,
  onChangePeriodId: PropTypes.func.isRequired,
  onUpdatePeriod: PropTypes.func.isRequired,
  onUpdateChart: PropTypes.func.isRequired,
  onRemovePeriod: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
}

export default PriceControl
