import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

import FeedbackContainer from '../Partials/FeedbackContainer'
import DotMarker from '@sweetspot/sweetspot-js/common/components/DotMarker'
import Label from '../Partials/Label'
import { ReactComponent as Checkmark } from '@sweetspot/sweetspot-js/assets/svgs/checkmark.svg'

const Checkbox = ({
  value,
  onChange,
  lightLabel,
  label,
  success,
  error,
  errorMarked,
  disabled,
  containerClassName,
  containerWidth,
  infoTextKey,
  feedback,
  greenMark,
  redMark,
  labelProps,
}) => {
  const valueChanged = () => {
    if (disabled) return
    onChange(!value)
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          'w-full': containerWidth === 'full',
          'w-1/2': containerWidth === 'half',
          'w-1/4': containerWidth === 'quarter',
        },
        containerClassName
      )}
      title={label}
    >
      <div className={cx(styles.checkboxRow)}>
        {greenMark ? (
          <DotMarker color="green" />
        ) : (
          <div
            className={cx(styles.checkboxContainer, {
              [styles.checkboxChecked]: value,
              [styles.checkboxError]: errorMarked || error,
              [styles.checkboxDisabled]: disabled,
            })}
            onClick={valueChanged}
          >
            <input
              type="checkbox"
              checked={value}
              onChange={valueChanged}
              disabled={disabled}
              className={cx(styles.checkboxInput)}
            />
            <Checkmark
              className={cx(styles.checkmarkIcon, {
                [styles.checkmarkIconVisible]: value,
              })}
            />
          </div>
        )}
        {redMark && <DotMarker color="red" className={cx(styles.redDotContainer)} />}
        <Label
          label={label}
          infoTextKey={infoTextKey}
          defaultMargin={false}
          normalWeight={lightLabel}
          disabled={disabled}
          onClick={valueChanged}
          {...labelProps}
        />
      </div>

      {/* Feedback container */}
      {feedback ? <FeedbackContainer error={error} success={success} /> : null}
    </div>
  )
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  lightLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  success: PropTypes.string,
  error: PropTypes.string,
  errorMarked: PropTypes.bool,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  containerWidth: PropTypes.string,
  infoTextKey: PropTypes.string,
  feedback: PropTypes.bool,
  greenMark: PropTypes.bool,
  redMark: PropTypes.bool,
  labelProps: PropTypes.object,
}
Checkbox.defaultProps = {
  value: false,
  onChange: () => {},
  lightLabel: false,
  label: '',
  success: '',
  error: '',
  errorMarked: false,
  disabled: false,
  containerClassName: '',
  containerWidth: 'full',
  infoTextKey: '',
  feedback: true,
  greenMark: false,
  redMark: false,
  labelProps: {},
}

export default Checkbox
