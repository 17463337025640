import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

const crumbs = ['Low', '', '', 'Average', '', '', 'High']

export default class Panel extends Component {
  renderEmpty(_style) {
    return <div className={style.empty} style={_style} />
  }

  render() {
    const { maxPriceItems, panelItem } = this.props
    let height = 100 / maxPriceItems
    let _style = {
      height: (height > 18 ? 18 : height) + '%',
    }

    if (panelItem.type && panelItem.type === 'empty') {
      return this.renderEmpty(_style)
    }

    let containerClassName = [style.container]
    containerClassName.push(style[`bg${panelItem.value}`])

    return (
      <div className={containerClassName.join(' ')} style={_style}>
        <div>
          <span className={style.badge}>{crumbs[panelItem.value]}</span>
          <p>{panelItem.price}</p>
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  panelItem: PropTypes.object,
  maxPriceItems: PropTypes.number.isRequired,
}
