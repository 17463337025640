import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Text from '../Text'

/**
 * This component create a switch button used to toggle between two states
 */
export default class ToggleSwitch extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { onCheck, onUncheck, disabled, onChange } = this.props

    if (disabled) return

    if (onChange) onChange(e.target.checked)
    if (e.target.checked && onCheck) onCheck()
    if (!e.target.checked && onUncheck) onUncheck()
  }

  render() {
    return (
      <label
        className={[
          style.toggleSwitch,
          this.props.large ? style.large : '',
          this.props.medium ? style.medium : '',
          this.props.small ? style.small : '',
          this.props.disabled ? style.disabled : '',
        ].join(' ')}
      >
        <input type="checkbox" checked={this.props.checked} onChange={this.handleChange} />
        <span className={[style.slider, this.props.round ? style.round : ''].join(' ')} />
        <span className={style.textChecked}>
          <div>
            {this.props.textChecked ? (
              this.props.textChecked.substring(0, 1) === '.' ? (
                <Text textId={this.props.textChecked} />
              ) : (
                this.props.textChecked
              )
            ) : (
              'ON'
            )}
          </div>
        </span>
        <span className={style.textUnchecked}>
          <div>
            {this.props.textUnchecked ? (
              this.props.textUnchecked.substring(0, 1) === '.' ? (
                <Text textId={this.props.textChecked} />
              ) : (
                this.props.textUnchecked
              )
            ) : (
              'OFF'
            )}
          </div>
        </span>
      </label>
    )
  }
}

ToggleSwitch.propTypes = {
  /**
   * Determines if the **ToggleSwitch** is checked
   */
  checked: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitch** should have rounded corners
   */
  round: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitch** should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitch** should be of small size
   */
  small: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitch** should be of medium size
   */
  medium: PropTypes.bool,
  /**
   * Determines if the **ToggleSwitch** should be of large size
   */
  large: PropTypes.bool,
  /**
   * Text to display when the **ToggleSwitch** is checked. If no text is provided a check icon will
   * be displayed
   *
   * If the text starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  textChecked: PropTypes.string,
  /**
   * Text to display when the **ToggleSwitch** is unchecked. If no text is provided a cross icon
   * will be displayed
   *
   * If the text starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  textUnchecked: PropTypes.string,
  /**
   * Returns a ref to the *input*
   *
   * @param {Element} ref ref to the *input*
   */
  getRef: PropTypes.func,
  /**
   * Function to run when the component is toggled
   *
   * @param {Boolean} checked state of the **ToggleSwitch**
   */
  onChange: PropTypes.func,
  /**
   * Function to run when the **ToggleSwitch** is checked
   */
  onCheck: PropTypes.func,
  /**
   * Function to run when the **ToggleSwitch** is unchecked
   */
  onUncheck: PropTypes.func,
}
