import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import ReservationPolicyManager from '@sweetspot/sweetspot-js/features/reservationPolicies/containers/ReservationPolicyManager'
import { useSelector } from 'react-redux'

const ReservationPolicySettings = ({ membershipId }) => {
  const clubId = useSelector((state) => state?.golfClub?.selectedId)

  return (
    <div className={cx(styles.container)}>
      <ReservationPolicyManager clubId={clubId} membershipId={membershipId} />
    </div>
  )
}

ReservationPolicySettings.propTypes = {
  membershipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ReservationPolicySettings.defaultProps = {}

export default ReservationPolicySettings
