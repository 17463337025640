import { TeeTime } from '@sweetspot/shared/types'
import { IncreaseDecreasePill } from '@sweetspot/sweetspot-js/common/components/IncreaseDecreasePill'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { formatInTimeZone } from 'date-fns-tz'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useDebouncedExtraPlayerUpdate,
  useExtraPlayerState,
} from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'

interface ItemSlotInfoItemProps {
  teeTime: TeeTime & { currentItemUnitPrice?: number }
  timezone: string
  currencyCode: string
  bookedSlots: number
  hasOwner: boolean
}

export const ItemSlotInfoItem = (props: ItemSlotInfoItemProps) => {
  const { t } = useTranslation()
  const extraPlayerDispatch = useDebouncedExtraPlayerUpdate()
  const extraPlayerState = useExtraPlayerState()
  const pricePerExtraPlayer = props.teeTime.price_per_extra_player

  const currentPlayerCount = useMemo(
    () =>
      extraPlayerState.tee_times.find((t) => t.uuid === props.teeTime.uuid)?.players ||
      props.bookedSlots ||
      0,
    [extraPlayerState.tee_times, props.bookedSlots, props.teeTime.uuid]
  )

  const handleUpdatePlayerCount = useCallback(
    (value: number) => {
      extraPlayerDispatch(props.teeTime.uuid, value)
    },
    [extraPlayerDispatch, props.teeTime.uuid]
  )

  return (
    <div className={'px-lg rounded-lg bg-stone-50 py-3'}>
      <div className={'flex h-full items-center justify-between'}>
        <div className={'flex flex-col '}>
          <div className={'text-base font-semibold'}>
            {formatInTimeZone(new Date(props.teeTime.from), props.timezone, 'HH:mm')}-
            {formatInTimeZone(new Date(props.teeTime.to), props.timezone, 'HH:mm')}
          </div>
          <div className={'flex flex-col'}>
            <p className={'!text-text-subtle !text-xs !leading-[18px]'}>
              {t('pricing.basePrice')}:{' '}
              {priceToLocal(
                props.teeTime.regular_price || props.teeTime.currentItemUnitPrice,
                props.currencyCode,
                true
              )}
            </p>
            {!!pricePerExtraPlayer && (
              <p className={'!text-text-subtle !text-xs !leading-[18px]'}>{`+ ${priceToLocal(
                pricePerExtraPlayer,
                props.currencyCode,
                true
              )} ${t('sentences.perExtraPlayer')}`}</p>
            )}
          </div>
        </div>
        <IncreaseDecreasePill
          maxValue={props.teeTime.max_slots}
          text={t('words.player', { count: currentPlayerCount })}
          minValue={1}
          disabled={!props.hasOwner}
          value={currentPlayerCount}
          onDecrease={(val) => handleUpdatePlayerCount(val)}
          onIncrease={(val) => handleUpdatePlayerCount(val)}
        />
      </div>
    </div>
  )
}
