import { useState, useEffect } from 'react'
import Table from '@sweetspot/club-portal-legacy/components/Table'
import { BalancesModal } from '../BalancesModal'
import { tableHeader } from './tableHeader'
import { updateSweep } from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import { parseToNumber } from '../../functions'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

export const BalancesTable = ({
  clubId,
  adyenAccountBalanceValue,
  getBalanceAccountDetails,
}): JSX.Element => {
  const [rowData, setRowData] = useState(undefined)
  const [modalState, setModalState] = useState(false)
  const [payoutFrequency, setPayoutFrequency] = useState(undefined)
  const [triggerAmount, setTriggerAmount] = useState(0)
  const [minAmount, setMinAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const roles = useRoles()
  const [clubCurrency] = useClubCurrency()

  useEffect(() => {
    setTableData(adyenAccountBalanceValue)
  }, [adyenAccountBalanceValue])

  useEffect(() => {
    if (tableData.length) {
      const copyTableData = tableData.map((values) => {
        if (values.payout_frequency) {
          return { ...values, payout_frequency: t(`words.${values.schedule_type}`) }
        }

        return values
      })

      setTableData(copyTableData)
    }
  }, [t])

  const handleRowClick = (data) => {
    if (roles.includes('ROLE_SA') && Object.keys(data).length) {
      setRowData(data)
      setModalState(true)

      const { payout_frequency, trigger_amount, min_amount } = data
      const parsedToNumberTA = parseToNumber(trigger_amount)
      const parsedToNumberMA = parseToNumber(min_amount)
      setPayoutFrequency(payout_frequency)
      setTriggerAmount(parsedToNumberTA)
      setMinAmount(parsedToNumberMA)
    }
  }

  const handleFrequncyChange = (frequncy: string) => setPayoutFrequency(frequncy)
  const validateInputValue = (number) => {
    const regExLeadingZeros = /^0[0-9].*$/
    const checkLeadingZeros = regExLeadingZeros.test(number)

    return !checkLeadingZeros
  }
  const handleTriggerAmount = (triggerValueEvent) => {
    const triggerValue = triggerValueEvent.target.value
    if (triggerValue >= 0 && validateInputValue(triggerValue)) setTriggerAmount(triggerValue)
  }

  const handleMinAmount = (minValueEvent) => {
    const minValue = minValueEvent.target.value
    if (minValue >= 0 && validateInputValue(minValue)) setMinAmount(minValue)
  }

  const handleSaveBtn = async () => {
    const frequencyValues = [
      { id: 'daily', name: t('words.daily') },
      { id: 'weekly', name: t('words.weekly') },
      { id: 'monthly', name: t('words.monthly') },
    ]

    const selectedFreqVal = frequencyValues.filter((freqObj) => freqObj.name === payoutFrequency)
    setLoading(true)
    const { id: balance_account_id } = rowData
    try {
      await updateSweep({
        clubId,
        balance_account_id,
        schedule_type: selectedFreqVal[0].id,
        trigger_amount: parseInt(triggerAmount * 100),
        target_amount: parseInt(minAmount * 100),
        currency: clubCurrency,
      })
    } catch (error) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
    }
    setLoading(false)
    setModalState(false)
    getBalanceAccountDetails()
  }

  const handleCancelBtn = () => {
    setLoading(false)
    setModalState(false)
  }

  return (
    <>
      <Table onRowClick={(data) => handleRowClick(data)} values={tableData} headers={tableHeader} />
      {rowData && (
        <BalancesModal
          rowData={rowData}
          triggerAmount={triggerAmount}
          onTriggerChange={handleTriggerAmount}
          minAmount={minAmount}
          onMinChange={handleMinAmount}
          modalState={modalState}
          payoutFrequency={payoutFrequency}
          onFrequncyChange={handleFrequncyChange}
          onSave={handleSaveBtn}
          loading={loading}
          onCancel={handleCancelBtn}
        />
      )}
    </>
  )
}
