import React, { useMemo, useState, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import { hasAccess } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { useMembershipCard } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembershipCard'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import StatusPill, { STATUSES } from '@sweetspot/sweetspot-js/common/components/StatusPill'

import styles from './styles.module.scss'
import MembershipCardSinglePromotion from '../../components/MembershipCardSinglePromotion'
import MembershipCardSingleReservationPolicy from '../../components/MembershipCardSingleReservationPolicy'
import { useToasts } from 'react-toast-notifications'
import { MembershipCardPaymentSource } from '@sweetspot/shared/types'
// import PaymentPlanTable from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/PaymentPlan'

const TABS = {
  PAYMENT_PLAN: 'PAYMENT_PLAN',
  PROMOTIONS: 'PROMOTIONS',
  RESERVATION_POLICIES: 'RESERVATION_POLICIES',
}

const MembershipCard = ({
  membershipCardId,
  paymentsData,
  paymentPlanComponent,
  cancelMembershipAutoRenewal,
  enableMembershipAutoRenewal,
  role,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [currentTab, setCurrentTab] = useState(TABS.PROMOTIONS)

  const {
    isFetching: isFetchingMembershipCard,
    isLoading: isLoadingMembershipCard,
    isIdle: isIdleMembershipCard,
    data: dataMembershipCard,
    refetch: refetchMembershipCard,
  } = useMembershipCard(membershipCardId)

  const cardState = useMemo(() => {
    if (!dataMembershipCard) return null
    return dataMembershipCard?.state
  }, [dataMembershipCard])

  const isMembershipLocked = useMemo(() => {
    if (!dataMembershipCard) return null
    return dataMembershipCard?.is_locked
  }, [dataMembershipCard])

  const canCancelAutoRenewal = useMemo(() => {
    return (
      !isFetchingMembershipCard &&
      !isLoadingMembershipCard &&
      dataMembershipCard?.autorenewal?.is_active &&
      hasAccess(ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.CANCEL_AUTO_RENEWAL, role?.value)
    )
  }, [isFetchingMembershipCard, isLoadingMembershipCard, dataMembershipCard, role?.value])

  const canEnableAutoRenewal = useMemo(() => {
    if (dataMembershipCard) {
      const currentTime = new Date()
      const membershipCardStartDate = new Date(dataMembershipCard.starts_at)
      const membershipCardExpiryDate = new Date(dataMembershipCard.expires_at)
      const isMembershipCardPaid = dataMembershipCard?.is_paid

      return (
        !isFetchingMembershipCard &&
        !isLoadingMembershipCard &&
        dataMembershipCard?.membership.autorenewal.is_active &&
        !dataMembershipCard?.autorenewal?.is_active &&
        currentTime > membershipCardStartDate &&
        currentTime < membershipCardExpiryDate &&
        isMembershipCardPaid
      )
    }
    return false
  }, [isFetchingMembershipCard, isLoadingMembershipCard, dataMembershipCard])

  const cardStatus = useMemo(() => {
    if (!dataMembershipCard) return null
    return dataMembershipCard?.status
  }, [dataMembershipCard])

  const activePolicies = useMemo(() => {
    return dataMembershipCard?.membership?.reservation_policies.filter((x) => x?.is_active)
  }, [dataMembershipCard])

  const handleToggleMembershipAutoRenewal = useCallback(async () => {
    if (dataMembershipCard && dataMembershipCard?.autorenewal?.is_active) {
      try {
        await cancelMembershipAutoRenewal(dataMembershipCard?.id)
        refetchMembershipCard()
        addToast(t('memberships.label_auto_renewal_cancelled'), { appearance: 'success' })
      } catch (error) {
        addToast(t('memberships.label_error_auto_renewal_cancelled'), { appearance: 'error' })
      }
    } else {
      try {
        await enableMembershipAutoRenewal(dataMembershipCard.id)
        refetchMembershipCard()
        addToast(t('memberships.label_auto_renewal_enabled'), { appearance: 'success' })
      } catch (error) {
        addToast(t('memberships.label_error_auto_renewal_enabled'), { appearance: 'error' })
      }
    }
  }, [
    addToast,
    cancelMembershipAutoRenewal,
    dataMembershipCard?.id,
    dataMembershipCard?.autorenewal?.is_active,
    refetchMembershipCard,
    t,
  ])

  const renderPaidByContent = () => {
    if (!dataMembershipCard) return null

    const { is_paid, marked_paid_by, paid_at } = dataMembershipCard

    if (!is_paid || !marked_paid_by || !paid_at) return null

    return (
      <div className="mt-1 flex gap-1 text-xs text-[#8A8A8A]">
        <span>{paid_at}</span>
        <span>{marked_paid_by}</span>
      </div>
    )
  }

  const paymentStatus = useMemo(() => {
    if (cardState === 'new' && cardStatus !== 'expired' && cardStatus !== 'canceled') {
      return STATUSES.UNPAID
    } else if (
      dataMembershipCard?.payment_source === MembershipCardPaymentSource.ONLINE &&
      paymentsData?.length > 0 &&
      (paymentsData[0]?.state === 'refunded' || paymentsData[0]?.state === 'refund_initiated')
    ) {
      return STATUSES.REFUNDED
    } else if (
      (cardState === 'paid' && (cardStatus === 'active' || cardStatus === 'upcoming')) ||
      (cardStatus === 'canceled' &&
        ((dataMembershipCard?.payment_source === MembershipCardPaymentSource.ONSITE &&
          dataMembershipCard?.is_manually_paid) ||
          (dataMembershipCard?.payment_source === MembershipCardPaymentSource.ONLINE &&
            paymentsData &&
            paymentsData?.length > 0 &&
            paymentsData[0]?.state === 'paid'))) ||
      cardStatus === 'expired'
    ) {
      return STATUSES.PAID
    } else {
      return ''
    }
  }, [cardState, cardStatus, dataMembershipCard, paymentsData])

  if (isLoadingMembershipCard || isIdleMembershipCard) {
    return (
      <div className={cx(styles.container, styles.loading)}>
        <PulseLoader showIf={true} />
      </div>
    )
  }

  const translatePaymentSource = (source) => {
    if (source === MembershipCardPaymentSource.ONSITE) {
      return t('memberships.label_paymentSourceOnsite')
    }
    if (source === MembershipCardPaymentSource.ONLINE) {
      return t('memberships.label_paymentSourceOnline')
    }
    if (source === MembershipCardPaymentSource.GIT) {
      return t('memberships.label_paymentSourceGit')
    }
    return source ?? '-'
  }

  return (
    <div className={cx(styles.container)}>
      {/* Card Header */}
      <div className={cx(styles.cardHeader)}>
        <div className={cx(styles.leftColumn)}>
          <div className={cx(styles.autoRenewalContainer)}>
            <ToggleButton
              value={dataMembershipCard?.autorenewal?.is_active}
              secondaryLabel={t('memberships.label_auto_renew_subscription')}
              onChange={handleToggleMembershipAutoRenewal}
              disabled={!canCancelAutoRenewal && !canEnableAutoRenewal}
              lightLabel
            />
            {dataMembershipCard?.autorenewal?.cancellation_date && (
              <Label
                label={t('memberships.label_cancel_auto_renewal', {
                  date: dataMembershipCard.autorenewal.cancellation_date,
                })}
                lightColor
              />
            )}
          </div>
          <div className={cx(styles.statusContainer)}>
            <div className="flex h-auto flex-col pt-2">
              <p className={cx(styles.headerTitle)}>{t('words.status')}</p>
              <div className="flex h-auto w-full flex-row">
                {cardState === 'new' &&
                  cardStatus !== 'expired' &&
                  cardStatus !== 'canceled' &&
                  !isMembershipLocked && <StatusPill status={STATUSES.NEW} />}

                {cardState === 'paid' && cardStatus === 'active' && !isMembershipLocked && (
                  <StatusPill status={STATUSES.ACTIVE} />
                )}

                {cardState === 'paid' && cardStatus === 'upcoming' && !isMembershipLocked && (
                  <StatusPill status={STATUSES.UPCOMING} />
                )}

                {isMembershipLocked && <StatusPill status={STATUSES.LOCKED} />}

                {cardStatus === 'expired' && <StatusPill status={STATUSES.EXPIRED} />}

                {cardStatus === 'canceled' && <StatusPill status={STATUSES.CANCELLED} />}
              </div>
            </div>
            <div className="me-5 flex h-auto flex-col pt-2">
              <p className={cx(styles.headerTitle)}>{t('membership.paymentStatus.title')}</p>
              <div className="flex h-auto min-h-5 w-full flex-row">
                {paymentStatus ? <StatusPill status={paymentStatus} /> : <p>-</p>}
              </div>
            </div>
            <div className={cx(styles.paymentType)}>
              <p className={cx(styles.headerTitle)}>{t('sentences.paymentMethod')}</p>
              <p className={cx(styles.paymentText)}>
                {translatePaymentSource(dataMembershipCard?.payment_source)}
              </p>
            </div>
            <div className={cx(styles.dateWrapper)}>
              <p className={cx(styles.headerTitle)}>{t('sentences.startDate')}</p>
              <p className={cx(styles.dateText)}>{dataMembershipCard?.starts_at || '-'}</p>
            </div>
            <div className={cx(styles.dateWrapper)}>
              <p className={cx(styles.headerTitle)}>
                {t(
                  dataMembershipCard?.autorenewal?.is_active
                    ? 'words.auto_renew'
                    : 'sentences.endDate'
                )}
              </p>
              <p className={cx(styles.dateText)}>{dataMembershipCard?.expires_at || '-'}</p>
            </div>
          </div>
          {renderPaidByContent()}
        </div>
        <div className={cx(styles.rightColumn)}>
          {/* <p className={cx(styles.headerTitle)}>{t('words.description')}</p>
          <p className={cx(styles.description)}></p> */}
        </div>
      </div>

      {/* Tabs */}
      <SimpleTabs
        className={cx(styles.tabsContainer)}
        currentItem={currentTab}
        items={[
          {
            id: TABS.PROMOTIONS,
            stepNumber: TABS.PROMOTIONS,
            label: t('words.promotions'),
          },
          {
            id: TABS.RESERVATION_POLICIES,
            stepNumber: TABS.RESERVATION_POLICIES,
            label: t('sentences.reservationPolicies'),
          },
          {
            id: TABS.PAYMENT_PLAN,
            stepNumber: TABS.PAYMENT_PLAN,
            label: t('memberships.paymentPlan'),
          },
        ]}
        onClick={(newValue) => setCurrentTab(newValue)}
      />

      {/* Promotions */}
      {currentTab === TABS.PROMOTIONS &&
        dataMembershipCard?.membership?.promotions
          ?.sort((a, b) => a.priority - b.priority)
          ?.filter((promo) => promo.status === 'active')
          .map((promotion) => (
            <MembershipCardSinglePromotion key={promotion.id} promotion={promotion} />
          ))}

      {/* Reservation policies */}
      {currentTab === TABS.RESERVATION_POLICIES &&
        activePolicies?.map((policy) => (
          <MembershipCardSingleReservationPolicy key={policy.id} policy={policy} />
        ))}
      {/* Payment Plan  */}
      {currentTab === TABS.PAYMENT_PLAN &&
        paymentsData?.length > 0 &&
        paymentPlanComponent(paymentsData)}
    </div>
  )
}

MembershipCard.propTypes = {
  membershipCardId: PropTypes.number,
  paymentPlanComponent: PropTypes.func.isRequired,
  cancelMembershipAutoRenewal: PropTypes.func.isRequired,
  enableMembershipAutoRenewal: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
}

MembershipCard.defaultProps = {
  membershipCardId: null,
}

export default MembershipCard
