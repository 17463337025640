import commonEn from '../langs/common/en.json'
import commonSv from '../langs/common/sv.json'
import commonDe from '../langs/common/de.json'
import commonDa from '../langs/common/da.json'
import commonFi from '../langs/common/fi.json'
import i18next from 'i18next'
import { I18nextNamespaces, LanguageKeys } from '../constants/enums'

export const addCommonBundles = (): void => {
  i18next.addResourceBundle(LanguageKeys.EN, I18nextNamespaces.DEFAULT, commonEn, true, false)
  i18next.addResourceBundle(LanguageKeys.SV, I18nextNamespaces.DEFAULT, commonSv, true, false)
  i18next.addResourceBundle(LanguageKeys.DE, I18nextNamespaces.DEFAULT, commonDe, true, false)
  i18next.addResourceBundle(LanguageKeys.DA, I18nextNamespaces.DEFAULT, commonDa, true, false)
  i18next.addResourceBundle(LanguageKeys.FI, I18nextNamespaces.DEFAULT, commonFi, true, false)
}
