import { useSelector } from 'react-redux'
import AddOverrideForm from '../../../components/AddOverrideForm'
import Logs from '../../../components/Logs'
import { usePeriodOverrides } from '../../../context'

const ManageTab = ({ period }) => {
  const { isLoading, periodOverrides, addOverride, daysOptions, deleteOverride } =
    usePeriodOverrides(period)

  const currentCourse = useSelector((state) =>
    state.golfCourse.list.find((golfCourse) => golfCourse.id === state.golfCourse.selectedId)
  )

  if (!currentCourse) return null

  return (
    <div>
      <AddOverrideForm
        period={period}
        daysOptions={daysOptions}
        addOverride={addOverride}
        currentCourse={currentCourse}
      />
      <Logs isLoading={isLoading} overrides={periodOverrides} deleteOverride={deleteOverride} />
    </div>
  )
}

export default ManageTab
