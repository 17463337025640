import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Gets products by query
 *
 */
export const getProducts = (
  query = {
    golf_club_id: null,
    golf_course_id: null,
    page: null,
    limit: null,
  }
) => {
  const url = queryString.stringifyUrl(
    {
      url: `${getApiPlatformBaseUrl()}/products`,
      query,
    },
    {
      skipNull: true,
    }
  )
  return request(url)
}

// NOTE: remove addProductToOrder function after replacing it with addUniqueProductToOrder in PP and WB
/**
 * Add product variant to order
 *
 * @param {string} bookingUuid
 */
export const addProductToOrder = (
  bookingUuid,
  payload = {
    product: null,
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/bookings/${bookingUuid}/items/add`
  return request(url, { method: 'PUT', body: payload })
}

/**
 * Add unique product variant to order
 *
 * @param {string} bookingUuid
 */
export const addUniqueProductToOrder = (
  bookingUuid,
  payload = {
    product_variant: null,
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/orders/${bookingUuid}/items`
  return request(url, { method: 'PUT', body: payload })
}

/**
 * Get availability for a product
 *
 * @param {number|string} productId
 */
export const getProductAvailability = (productId, payload = { time: null, course: null }) => {
  const url = `${getApiPlatformBaseUrl()}/product/${productId}/available`
  return request(url, { method: 'PUT', body: payload })
}

/**
 * Removes product vartiant from order
 *
 * @param {string|number} itemId
 */
export const removeProductItemFromOrder = (
  bookingUuid,
  payload = {
    order_item: null,
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/bookings/${bookingUuid}/items/remove`
  return request(url, { method: 'PUT', body: payload })
}
