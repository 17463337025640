import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { ManageRangeContextValue, DiscardDialogCallbacks } from './types'
import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'

export const ManageRangeContext = createContext<ManageRangeContextValue>({
  areRangesLoading: false,
  ranges: [],
  openCreateRangeSidebar: false,
  refetchRanges: () => {
    return
  },
  toggleCreateRangeSideBar: () => {
    return
  },
})

export const ManageRangeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openCreateRangeSidebar, setOpenBaySideBar] = useState(false)
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false)
  const [discardDialogCallbacks, setDiscardDialogCallbacks] = useState<DiscardDialogCallbacks>()
  const { ranges, areRangesLoading, selectedRange, setSelectedRange, refetchRanges } = useRanges()
  const history = useHistory()

  // set openCreateRangeSidebar tab based on hash
  useEffect(() => {
    const hash = history.location.hash
    setOpenBaySideBar(hash === '#new')
  }, [history.location.hash])

  const toggleCreateRangeSideBar = useCallback(() => {
    const isOpen = !openCreateRangeSidebar
    const path = history.location.pathname
    history.push(isOpen ? `${path}#new` : path)
  }, [openCreateRangeSidebar, history])

  const value = useMemo(
    () => ({
      areRangesLoading,
      ranges,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      openCreateRangeSidebar,
      toggleCreateRangeSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    }),
    [
      areRangesLoading,
      ranges,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      openCreateRangeSidebar,
      toggleCreateRangeSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    ]
  )

  return <ManageRangeContext.Provider value={value}>{children}</ManageRangeContext.Provider>
}

export const useManageRangeContext = () => useContext(ManageRangeContext)
