/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react'

export interface SharedUtilCacheBusterProps {
  children: JSX.Element
}

const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    return a > b || Number.isNaN(b)
  }
  return false
}
// 30 sec pollingtime for now
const pollingTime = 30000

// Who you gonna call?
export function CacheBuster({ children }: SharedUtilCacheBusterProps) {
  const [haveUpdate, setHaveUpdate] = useState(false)

  const isDevEnv = () => process.env.NODE_ENV === 'development'

  useEffect(() => {
    if (isDevEnv()) {
      return undefined
    }
    let timer: NodeJS.Timeout
    const checkForUpdate = () => {
      fetch('/meta.json')
        .then((response) => {
          const contentType = response.headers.get('content-type')
          // Request will return html id file does not exist. therefore we need to check if its json
          if (!response.ok || !contentType?.includes('application/json')) {
            throw new Error()
          }
          return response.json()
        })
        .then((meta) => {
          if (!meta?.version || !global?.appVersion) {
            throw new Error()
          }
          const shouldForceRefresh = semverGreaterThan(meta.version, global.appVersion)
          if (shouldForceRefresh) {
            setHaveUpdate(true)
            clearInterval(timer)
          }
        })
        .catch(() => {
          // on any error just stop trying silently
          clearInterval(timer)
        })
    }
    timer = setInterval(() => {
      checkForUpdate()
    }, pollingTime)
    checkForUpdate()
    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleOnClick = () => {
    window.location.reload()
  }

  if (!haveUpdate || isDevEnv()) {
    return null
  }

  return (
    <div className="mr-3 flex h-full w-52 items-center justify-center">
      <div
        onClick={handleOnClick}
        className="flex w-auto cursor-pointer items-center justify-center"
      >
        {children}
      </div>
    </div>
  )
}
