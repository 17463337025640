import { FC } from 'react'

interface TotalRowProps {
  border?: boolean
  text: string
  price: string | number
  textSize?: string
}

export const TotalRow: FC<TotalRowProps> = (props) => {
  return (
    <div
      className={`text-brand-black flex justify-between pb-1  ${props.textSize || 'text-sm'}
      ${props.border && 'border-b border-b-stone-900'}`}
    >
      <span className="text-brand-black">{props.text}</span>
      <div>
        <span className={`text-brand-black whitespace-nowrap pr-3`}>{props.price}</span>
      </div>
    </div>
  )
}
