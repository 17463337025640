const HEADERS = {
  created: {
    name: '.created',
    type: 'label',
    valueType: 'string',
    selected: true,
    isSortable: true,
    maxWidth: '150px',
  },
  published_at: {
    name: '.singleDate',
    type: 'label',
    valueType: 'string',
    selected: true,
    maxWidth: '200px',
  },
  status: {
    name: '.status',
    type: 'label',
    valueType: 'string',
    selected: true,
    maxWidth: '120px',
  },
  title: {
    name: '.communication.name',
    type: 'label',
    valueType: 'string',
    selected: true,
    maxWidth: '250px',
  },
}

export default HEADERS
