import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import SingleRule from '@sweetspot/sweetspot-js/features/promotions/components/SingleRule'
import styles from './styles.module.scss'
import { BookingPromotionType } from '@sweetspot/shared/types'

interface VoucherRulesWrapperProps {
  promotion: BookingPromotionType & { rules: unknown[] }
  disabled: boolean
  golfClubID: number
}

const VoucherRulesWrapper: React.FC<VoucherRulesWrapperProps> = ({
  promotion,
  disabled,
  golfClubID,
}) => {
  const { rules } = promotion
  const { t } = useTranslation()

  return (
    <div className={cx(styles.container)}>
      {rules?.length === 0 && <h2 className={cx(styles.title)}>{t('sentences.noRules')}</h2>}
      {rules && rules.length
        ? rules.map((rule, index) => (
            <SingleRule
              key={rule.id}
              index={index}
              rule={rule}
              promotionId={promotion.id}
              disabled={disabled}
              golfClubID={golfClubID}
              voucherDisabled={true}
            />
          ))
        : null}
    </div>
  )
}

VoucherRulesWrapper.propTypes = {
  promotion: PropTypes.shape({
    rules: PropTypes.array.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disabled: PropTypes.bool,
  golfClubID: PropTypes.number,
}

VoucherRulesWrapper.defaultProps = {}

export default VoucherRulesWrapper
