import { ReactComponent as DefaultSimulatorIcon } from '@sweetspot/sweetspot-js/assets/svgs/simulator-default.svg'
import { ReactComponent as DefaultRangesIcon } from '@sweetspot/sweetspot-js/assets/svgs/driving-range-default.svg'
import { ReactComponent as DefaultProIcon } from '@sweetspot/sweetspot-js/assets/svgs/pro-default.svg'
import { ReactComponent as DefaultOtherIcon } from '@sweetspot/sweetspot-js/assets/svgs/other-default.svg'
import { GolfCourseTypes } from '@sweetspot/shared/types'

interface VenuesIconProps {
  courseType: GolfCourseTypes
  type: 'regular' | 'default'
}

const VenuesIcon = ({ courseType, type }: VenuesIconProps) => {
  const iconMap = {
    default: {
      [GolfCourseTypes.COURSE]: <i className="fa-kit fa-tee-regular text-content-base" />,
      [GolfCourseTypes.SIMULATOR]: (
        <DefaultSimulatorIcon width={16} height={16} className="[&_path]:fill-brand-black" />
      ),
      [GolfCourseTypes.DRIVING_RANGE]: (
        <DefaultRangesIcon width={16} height={16} className="[&_path]:fill-brand-black" />
      ),
      [GolfCourseTypes.PRO]: (
        <DefaultProIcon width={16} height={16} className="[&_path]:fill-brand-black" />
      ),
      [GolfCourseTypes.OTHER]: (
        <DefaultOtherIcon width={16} height={16} className="[&_path]:fill-brand-black" />
      ),
    },
    regular: {
      [GolfCourseTypes.COURSE]: <i className="fa-kit fa-tee-regular text-content-base" />,
      [GolfCourseTypes.SIMULATOR]: <i className="fa-kit fa-sim-regular text-content-base" />,
      [GolfCourseTypes.DRIVING_RANGE]: <i className="fa-kit fa-range-regular text-content-base" />,
      [GolfCourseTypes.PRO]: (
        <DefaultProIcon width={16} height={16} className="[&_path]:fill-brand-black" />
      ),
      [GolfCourseTypes.OTHER]: (
        <DefaultOtherIcon width={16} height={16} className="[&_path]:fill-brand-black" />
      ),
    },
  }

  return iconMap[type][courseType] || null
}

export default VenuesIcon
