import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import {
  BaysContextValue,
  BaySideBarMode,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'
import useBays from './hook/useBays'

export const BaysContext = createContext<BaysContextValue>({
  areRangesLoading: false,
  ranges: [],
  areBaysLoading: false,
  areBaysFetching: false,
  bays: [],
  openBaySideBar: false,
  openDiscardDialog: false,
  setSelectedBays: () => {
    return
  },
  toggleBaySideBar: () => {
    return
  },
  reFetchBays: () => {
    return
  },
  changeBaySideBarMode: () => {
    return
  },
  setOpenDiscardDialog: () => {
    return
  },
  refetchRanges: () => {
    return
  },
  mergeBays: () => {
    return {}
  },
})

export const BaysProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openBaySideBar, setOpenBaySideBar] = useState(false)
  const [baySideBarMode, setBaySideBarMode] = useState<BaySideBarMode | undefined>()
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false)
  const { ranges, areRangesLoading, selectedRange, setSelectedRange, refetchRanges } = useRanges()
  const {
    bays,
    areBaysLoading,
    setSelectedBays,
    selectedBays,
    refetch: reFetchBays,
    areBaysFetching,
    updateBay,
    createBay,
    updateBayBulk,
    checkForErrors,
    mergeBays,
    constructUpdateBayBulkPayload,
  } = useBays({
    selectedRangeId: selectedRange?.id,
    selectedRangeToptracerSiteId: selectedRange?.toptracer_site_id,
  })

  const toggleBaySideBar = useCallback(
    (mode?: BaySideBarMode) => {
      setBaySideBarMode(openBaySideBar ? undefined : mode)
      setOpenBaySideBar((prev) => !prev)
    },
    [openBaySideBar]
  )

  const value = useMemo(
    () => ({
      ranges,
      areRangesLoading,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      bays,
      areBaysLoading,
      areBaysFetching,
      setSelectedBays,
      selectedBays,
      openBaySideBar,
      toggleBaySideBar,
      reFetchBays,
      baySideBarMode,
      changeBaySideBarMode: setBaySideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateBay,
      createBay,
      updateBayBulk,
      checkForErrors,
      mergeBays,
      constructUpdateBayBulkPayload,
    }),
    [
      areRangesLoading,
      ranges,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      bays,
      areBaysFetching,
      areBaysLoading,
      setSelectedBays,
      selectedBays,
      openBaySideBar,
      toggleBaySideBar,
      reFetchBays,
      baySideBarMode,
      setBaySideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateBay,
      createBay,
      updateBayBulk,
      checkForErrors,
      mergeBays,
      constructUpdateBayBulkPayload,
    ]
  )

  return <BaysContext.Provider value={value}>{children}</BaysContext.Provider>
}

export const useBaysContext = () => useContext(BaysContext)
