import * as _ from 'lodash'

import DateHelpers from './DateHelpers'
import { isBookingCancelled } from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
export default class TeesheetHelpers {
  static getSplitOverrides(overrides, interval) {
    const result = []
    overrides.forEach((override) => {
      let startTime = override.start_date
      while (override.end_date > startTime) {
        result.push({
          ...override,
          start_date: startTime,
          end_date: startTime + interval * 60,
          from: startTime,
          to: startTime + interval * 60,
        })
        startTime += interval * 60
      }
    })
    return result
  }

  static formatOverrides(overrides, selectedOverrides, interval) {
    const splitedOverrides = this.getSplitOverrides(overrides, interval)
    selectedOverrides.forEach((cOverride) => {
      let isNew = true
      splitedOverrides.forEach((pOverride) => {
        if (cOverride.start_date === pOverride.start_date) {
          isNew = false
          pOverride.status = cOverride.status
        }
      })
      if (isNew) {
        splitedOverrides.push(cOverride)
      }
    })
    const groupOverrides = _.groupBy(splitedOverrides, (override) => override.status)
    let sortedOverrides = []
    Object.values(groupOverrides).forEach((override) => {
      sortedOverrides.push(_.orderBy(override, ['from'], ['asc']))
    })
    let newOverrides = []
    sortedOverrides.forEach((overrides) => {
      let breaks = [-1]
      for (let i = 0; i < overrides.length - 1; i++) {
        if (overrides[i].to !== overrides[i + 1].from) breaks.push(i)
      }
      breaks.push(overrides.length - 1)
      for (let i = 0; i < breaks.length - 1; i++) {
        newOverrides.push({
          ...overrides[breaks[i] + 1],
          to: overrides[breaks[i + 1]].from,
          end_date: overrides[breaks[i + 1]].end_date,
        })
      }
    })
    return newOverrides
  }

  static mapOrdersToTeetimes(orders, teeTimes) {
    const mappedOrders = []

    if (!orders) return teeTimes
    orders.forEach((order) => {
      if (!isBookingCancelled(order) && order.partnership) {
        let timeStamp = DateHelpers.toTimestamp(order?.booking?.start_time)
        let players = order?.items.map((item) => item.slot.player || item.slot.stub_player)
        mappedOrders.push({ startTime: timeStamp, players })
      }
    })

    const mappedTeeTimes = teeTimes.map((t) => {
      let order = mappedOrders.find((x) => x.startTime === t.from)
      if (!order) return t
      else return { ...t, partnershipPlayers: order.players }
    })

    return mappedTeeTimes
  }
}
