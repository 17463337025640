import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'

const ToggleCell = (props) => {
  const { t } = useTranslation()
  return (
    <div style={props.cellStyle} className={styles.toggle}>
      <ToggleButton
        value={props.value.isEnabled}
        secondaryLabel={
          props.value.label.toString()[0] === '.' ? t(props.value.label) : props.value.label
        }
        onChange={props.value.onChange}
      />
    </div>
  )
}

ToggleCell.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  cellStyle: PropTypes.object,
}

export default ToggleCell
