import React, { Component } from 'react'
import style from './style.module.scss'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import colors from '@sweetspot/club-portal-legacy/styles-legacy/_colors-legacy.scss'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import Checkbox from '@sweetspot/club-portal-legacy/components/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import { ReactComponent as GuestSlotIcon } from '@sweetspot/club-portal-legacy/resources/images/guest_slot_asset.svg'
import { ReactComponent as MemberSlotIcon } from '@sweetspot/club-portal-legacy/resources/images/member_slot_asset.svg'
import { ReactComponent as PartnerSlotIcon } from '@sweetspot/club-portal-legacy/resources/images/partner_slot_asset.svg'
import { ReactComponent as ReservedSlotIcon } from '@sweetspot/club-portal-legacy/resources/images/reserved_slot_asset_18.svg'
import { ReactComponent as AnonlotIcon } from '@sweetspot/club-portal-legacy/resources/images/anonymous-booked-18x18.svg'

const GUEST = 'guest'
const MEMBER = 'member'
const PARTNER = 'partner'
const PLAYER_TYPE = 'playerType'
const RESERVED = 'reserved'
const ANON = 'anonymous'

export default class FilterSideMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.toggleSideMenu = this.toggleSideMenu.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
  }

  toggleSideMenu() {
    this.setState({ open: !this.state.open })
  }

  handleFilterChange(value, type) {
    let filters = { ...this.props.filters }

    if (!filters[`${type}`].includes(value)) {
      filters[`${type}`].push(value)
    } else {
      // remove filter
      let indexOfFilter = filters[`${type}`].indexOf(value)
      filters[`${type}`].splice(indexOfFilter, 1)
    }

    localStorage.setItem('symbol_setting', JSON.stringify(filters[PLAYER_TYPE]))

    this.props.setFilters(filters)
  }

  renderTeeTimeTypes() {
    return (
      <div className={style.filters}>
        <div className={style.filterTitle}>
          <Text textId="golf.teeTimeTypes" />
        </div>
        <FilterItem
          text="golf.standardTime"
          color={colors['tee_sheet_green']}
          value="open"
          filterType="teeTimeType"
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="golf.members_only"
          color={colors['tee_sheet_dark_blue']}
          value="members_only"
          filterType="teeTimeType"
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="golf.on_site"
          color={colors['light_purple']}
          value="on_site"
          filterType="teeTimeType"
          onChange={this.handleFilterChange}
        />
      </div>
    )
  }

  renderUnbookedSlots() {
    return (
      <div className={style.filters}>
        <div className={style.filterTitle}>
          <Text textId="golf.unbookedSlots" />
        </div>
        <FilterItem
          text="golf.oneAvailable"
          value={1}
          filterType={'slots'}
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="golf.twoAvailable"
          value={2}
          filterType={'slots'}
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="golf.threeAvailable"
          value={3}
          filterType={'slots'}
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="golf.fourAvailable"
          value={4}
          filterType={'slots'}
          onChange={this.handleFilterChange}
        />
      </div>
    )
  }

  renderPlayerTypeFilter() {
    const {
      filters: { playerType },
    } = this.props

    return (
      <div className={style.filters}>
        <div className={style.filterTitle}>
          <Text textId="teeSheet.selectPlayerTypes" />
        </div>
        <FilterItem
          text="member"
          value={MEMBER}
          filterType={PLAYER_TYPE}
          checked={playerType.includes(MEMBER)}
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="partner"
          value={PARTNER}
          filterType={PLAYER_TYPE}
          checked={playerType.includes(PARTNER)}
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="guest"
          value={GUEST}
          filterType={PLAYER_TYPE}
          checked={playerType.includes(GUEST)}
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="reserved"
          value={RESERVED}
          filterType={PLAYER_TYPE}
          checked={playerType.includes(RESERVED)}
          onChange={this.handleFilterChange}
        />
        <FilterItem
          text="anonymous"
          value={ANON}
          filterType={PLAYER_TYPE}
          checked={playerType.includes(ANON)}
          onChange={this.handleFilterChange}
        />
      </div>
    )
  }

  render() {
    let classNames = [style.container]
    if (this.state.open) classNames.push(style.open)
    if (this.state.finishedLoading) classNames.push(style.finishedLoading)
    return (
      <div className={classNames.join(' ')}>
        <div className={style.header}>
          <div className={style.iconContainer} onClick={this.toggleSideMenu}>
            {!this.state.open ? (
              <div className={style.arrowIcons}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            ) : (
              <div className={style.arrowIcons}>
                <FontAwesomeIcon icon={faChevronRight} />
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            )}
          </div>
          {!this.state.open && (
            <div className={style.sideText}>
              <Text textId="filters" />
            </div>
          )}
        </div>

        <div className={style.filterContainer}>
          <div className={style.content}>
            <h5 className={style.label}>
              <Text textId="filterTeeTimes" />
            </h5>
            {this.renderUnbookedSlots()}
            {/* {this.renderTeeTimeTypes()} */}
          </div>
          <div className={style.content}>
            <h5 className={style.label}>
              <Text textId="teeSheet.playerTypeSetting" />
            </h5>
            {this.renderPlayerTypeFilter()}
            <div className={style.legendBtn}>
              <button
                className="system-button primary-outline md-32"
                onClick={this.props.toggleLegendPopup}
              >
                {t('teeSheet.legend')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FilterSideMenu.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  toggleLegendPopup: PropTypes.func.isRequired,
}

class FilterItem extends Component {
  renderImage() {
    if (this.props.color) {
      let _style = { background: this.props.color }
      return <div className={style.image} style={_style} />
    }
  }

  render() {
    const { filterType, color, value, text, checked, onChange } = this.props

    const Icon =
      value === 'partner'
        ? PartnerSlotIcon
        : value === 'member'
        ? MemberSlotIcon
        : value === 'reserved'
        ? ReservedSlotIcon
        : value === 'guest'
        ? GuestSlotIcon
        : AnonlotIcon

    return (
      <div className={style.itemContainer}>
        <div className={style.checkboxContainer}>
          <Checkbox checked={checked} onChange={() => onChange(value, filterType)} />
        </div>
        {color && (
          <div className={style.imageContainer}>
            <div className={style.imageContent}>{this.renderImage()}</div>
          </div>
        )}
        {filterType === PLAYER_TYPE && (
          <div className={style.playerType}>
            <Icon className={style.image} />
          </div>
        )}
        <div className={style.textContainer}>
          <Text textId={text} />
        </div>
      </div>
    )
  }
}

FilterItem.propTypes = {
  text: PropTypes.node.isRequired,
  color: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  filterType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
