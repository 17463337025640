import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useBallDispensersContext } from './BallDispensersProvider'
import { EmptyState, EmptyStateButton, EmptyStateTitle } from '@sweetspot/scramble-ds'

import BallDispensersTabsContainer from './BallDispensersTabs/BallDispensersTabs'

const Body = () => {
  const { t } = useTranslation()
  const { ranges, areRangesLoading, areBallDispensersFetching } = useBallDispensersContext()
  const isLoading = useMemo(
    () => areRangesLoading || areBallDispensersFetching,
    [areRangesLoading, areBallDispensersFetching]
  )

  const emptyStateKey = `loading-${areRangesLoading}-ranges-${ranges?.length || 0}-ball-pricing`

  if (isLoading) {
    return (
      <EmptyState
        key={emptyStateKey}
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>{t('words.loading')}</EmptyStateTitle>
      </EmptyState>
    )
  }

  if (!ranges?.length) {
    return (
      <EmptyState
        key={emptyStateKey}
        iconName="fa-light fa-flip-both fa-circle-exclamation bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>{t('settings.bays.noRangesCreated')}</EmptyStateTitle>
        <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
          <Link
            className="height-full flex w-full items-center justify-center"
            to={'/settings/manage-range'}
            target={'_blank'}
          >
            <i className="fa-regular fa-arrow-up-right-from-square mr-2"></i>
            {t('settings.bays.createFirstRange')}
          </Link>
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <BallDispensersTabsContainer />
}

export default Body
