import { useTranslation } from 'react-i18next'

import { Divider } from '@sweetspot/scramble-ds'

import { maxNrOfBulkBays } from '../../constants'
import NumberOfBaysButton from './NumberOfBaysButton'

type Props = {
  numberOfBays: number
  setNumberOfBay: (value: number) => void
}

const NumberOfBays = ({ numberOfBays, setNumberOfBay }: Props) => {
  const { t } = useTranslation()

  const increment = () => {
    if (numberOfBays === maxNrOfBulkBays) return
    setNumberOfBay(numberOfBays + 1)
  }

  const decrement = () => {
    if (numberOfBays === 1) return
    setNumberOfBay(numberOfBays - 1)
  }

  return (
    <div className="bg-background-sunset-ligher border-background-sunset-light p-md flex flex-col gap-2 rounded-md border">
      <div className="px-md flex gap-2">
        <i className="fa-solid fa-rectangle-vertical-history" />
        <p className="text-content-base font-bold">{t('sentences.bulkAction')}</p>
      </div>
      <Divider className="bg-background-sunset-light" />
      <div className="flex items-center justify-between gap-2">
        <p className="px-md text-content-sm font-bold">{t('settings.bays.numberOfBaysToCreate')}</p>
        <div className="flex items-center gap-6">
          <NumberOfBaysButton disabled={numberOfBays === 1} onClick={decrement} icon="fa-minus" />
          <div>{numberOfBays}</div>
          <NumberOfBaysButton
            disabled={numberOfBays === maxNrOfBulkBays}
            onClick={increment}
            icon="fa-plus"
          />
        </div>
      </div>
    </div>
  )
}

export default NumberOfBays
