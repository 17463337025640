import Container from '../../components/Container'
import { useSelector } from 'react-redux'
import { createSignedToken } from '../Statistics/utils'
import { FC } from 'react'
import type { State, holisticsFilter } from './Interface'
import type { ThisPropertyOf, AllKeysOf } from '@sweetspot/shared/types'
import type { holisticFilters } from './holisticFilters'

interface InsightsProps {
  secretKey: string
  filter: ThisPropertyOf<AllKeysOf<typeof holisticFilters>, 'filter'>
  embedCode: string
  drillthroughs: ThisPropertyOf<AllKeysOf<typeof holisticFilters>, 'drillthroughs'>
}

export const Insights: FC<InsightsProps> = (props) => {
  const expired_time = Math.floor(Date.now() / 1000) + 24 * 60

  const currentClubId = useSelector(({ golfClub }: State) => golfClub?.selectedId || null)
  const courseAccessIds = useSelector(({ golfCourse }: State) => golfCourse?.list || null).map(
    (item) => item.id
  )
  const filters = props.filter as {
    organisation?: holisticsFilter
    venue_id?: holisticsFilter
    club_id?: holisticsFilter
  }

  const drillthroughs = props.drillthroughs

  if (drillthroughs) {
    Object.values(drillthroughs).map((item) => {
      item.filters.organisation.default_condition.values = [currentClubId as number]
      return item
    })
  }

  if (filters.organisation) {
    filters.organisation = {
      hidden: true,
      default_condition: {
        operator: 'is',
        values: [currentClubId],
        modifier: null,
        options: null,
      },
    }
  }

  if (filters.club_id) {
    filters.club_id = {
      hidden: true,
      default_condition: {
        operator: 'is',
        values: [currentClubId],
        modifier: null,
        options: null,
      },
    }
  }

  if (filters.venue_id) {
    filters.venue_id.hidden = true
    filters.venue_id.default_condition.values = courseAccessIds
  }
  const payload = {
    settings: {
      enable_export_data: true,
    },
    permissions: {
      row_based: [],
    },
    filters: filters,
    exp: expired_time,
    drillthroughs: props.drillthroughs || undefined,
  }
  const token = createSignedToken(payload, props.secretKey)

  return (
    <Container>
      <div style={{ height: '100%' }}>
        <iframe
          src={`https://eu.holistics.io/embed/${props.embedCode}?_token=${token}`}
          title="holistics-iframe"
          style={{ width: '100%', height: '100%' }}
          allowFullScreen
        ></iframe>
      </div>
    </Container>
  )
}
