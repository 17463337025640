import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ItemBox from '../ItemBox'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatusHeader from '@sweetspot/club-portal-legacy/pages/SimulatorsRangesOrder/components/StatusHeader'
import { faCalendar, faClock } from '@fortawesome/pro-regular-svg-icons'

const OrderSummaryBox = ({ orderBooking, venue, children, isConfirmed, isConsideredPaid }) => {
  const { t, i18n } = useTranslation()

  const venueTimeZone = useMemo(() => {
    return venue?.timezone || null
  }, [venue])

  if (!orderBooking || !venue) return null

  const formatStartDate = (date) => {
    const formatString = moment(orderBooking.booking.start_time)
      .locale(i18n.language)
      .tz(venueTimeZone)
      .format('ddd, DD MMMM YYYY')
    if (i18n.language !== 'en') {
      return formatString.charAt(0).toUpperCase() + formatString.slice(1)
    }
    return formatString
  }

  return (
    <ItemBox style="dark" className={cx(styles.container)}>
      <StatusHeader
        isConfirmed={isConfirmed}
        isPaid={isConsideredPaid}
        isAwaitingPayment={!isConsideredPaid}
      />
      <div className={cx(styles.header)}>
        <h2 className={cx(styles.bookingId)}>
          {t('words.booking_one')} <span>{orderBooking.token}</span>
        </h2>
        <h2 className={cx(styles.title)}>{orderBooking.course?.name}</h2>
        <div className={cx(styles.descriptionRow)}>
          <p className={cx(styles.text)}>
            <FontAwesomeIcon icon={faCalendar} className={cx(styles.calendarIcon)} />
            {formatStartDate(orderBooking.booking.start_time)}
          </p>
          <p className={cx(styles.text)}>
            <FontAwesomeIcon icon={faClock} className={cx(styles.calendarIcon)} />
            {moment(orderBooking.booking.start_time).tz(venueTimeZone).format('HH:mm')}-
            {moment(orderBooking.booking.end_time).tz(venueTimeZone).format('HH:mm')}
          </p>
        </div>
      </div>
      {children}
    </ItemBox>
  )
}

OrderSummaryBox.propTypes = {
  orderBooking: PropTypes.object,
  orderBookingRefetch: PropTypes.func,
  venue: PropTypes.object,
  isConfirmed: PropTypes.bool,
  isConsideredPaid: PropTypes.bool,
}

OrderSummaryBox.defaultProps = {
  orderBooking: null,
  orderBookingRefetch: () => {},
  venue: null,
  isConfirmed: false,
  isConsideredPaid: false,
}

export default OrderSummaryBox
