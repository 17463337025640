import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import produce from 'immer'

import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import ConfirmModal from '@sweetspot/club-portal-legacy/components/ConfirmModal'

import CAForm from './components/CAForm'
import Header from './components/Header'
import GolfClubs from './components/GolfClubs'
import Sidebar from './components/Sidebar'

import {
  createClubAdmin,
  updateClubAdmin,
  deleteClubAdmin,
  getAdminById,
  addClubToAdmin,
  removeClubFromAdmin,
} from '@sweetspot/club-portal-legacy/store/actions'

import { ReactComponent as TrashIcon } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'

const EDIT = 1
const DELETE = 2

const INFORMATION = 3

const initialForm = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPass: '',
}

class CAManagerModal extends Component {
  state = {
    adminId: 0,
    isEdit: false,
    isDelete: false,
    isLoading: false,
    form: initialForm,
    clubs: [],
    response: null,
    errors: {},
    sidebar: INFORMATION,
  }

  componentDidMount() {
    const adminId = this.props.match.params.id

    if (adminId === 'new') this.setState({ isEdit: true })
    else {
      this.setState({
        adminId,
        isEdit: false,
        isLoading: true,
      })
      this.getAdminInfo(adminId)
    }
  }

  getAdminInfo = (adminId) => {
    const { token, dispatch } = this.props

    dispatch(getAdminById(token, adminId))
      .then((response) => {
        this.setState({
          isLoading: false,
          response,
          clubs: response.clubs,
          form: {
            firstName: response.first_name,
            lastName: response.last_name,
            email: response.email,
            password: '',
            confirmPass: '',
          },
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleClose = (shouldReload = false) => {
    let temp = {
      pathname: '/settings/sa/club-users',
    }
    if (shouldReload)
      temp.state = {
        shouldReload: true,
      }
    this.props.history.push(temp)
  }

  handleClickActions = (id) => {
    this.setState({ isEdit: id === EDIT })
    this.setState({ isDelete: id === DELETE })
  }

  handleFormChange = (value, name) => {
    const { form } = this.state

    this.setState(
      produce((draft) => {
        delete draft.errors[name]
        draft.form = { ...form, [name]: value }
      })
    )
  }

  handleCancel = () => {
    const { adminId, response } = this.state

    this.setState({ isEdit: false })
    if (!adminId) this.handleClose()
    else {
      this.setState({
        form: {
          firstName: response.first_name,
          lastName: response.last_name,
          email: response.email,
          password: '',
          confirmPass: '',
        },
      })
    }
  }

  changeSidebar = (id) => {
    this.setState({ sidebar: id })
  }

  getPayload = () => {
    const {
      form: { firstName, lastName, email, password },
    } = this.state

    let payload = {
      first_name: firstName,
      last_name: lastName,
      email,
    }

    if (password) payload.password = password

    return payload
  }

  handleCreate = () => {
    const { form } = this.state
    const { token, history, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(createClubAdmin(token, this.getPayload()))
      .then((response) => {
        this.setState({
          isLoading: false,
          isEdit: false,
          adminId: response.id,
          response,
          form: {
            ...form,
            password: '',
            confirmPass: '',
          },
        })
        history.push(`/settings/sa/club-users/${response.id}`)
      })
      .catch(({ errors }) => {
        this.setState({
          isLoading: false,
          errors: errors || {},
        })
      })
  }

  handleUpdate = () => {
    const { adminId, form } = this.state
    const { token, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(updateClubAdmin(token, { ...this.getPayload(), id: adminId }))
      .then(() => {
        this.setState({
          isLoading: false,
          isEdit: false,
          form: {
            ...form,
            password: '',
            confirmPass: '',
          },
        })
      })
      .catch(({ errors }) => {
        this.setState({
          isLoading: false,
          errors: errors || {},
        })
      })
  }

  handleDelete = () => {
    const { adminId } = this.state
    const { token, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(deleteClubAdmin(token, adminId))
      .then(() => {
        this.setState({
          isLoading: false,
          isDelete: false,
        })
        this.handleClose(true)
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  handleClubAdd = (clubId) => {
    const { adminId } = this.state
    const { token, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(addClubToAdmin(token, clubId, { admin: parseInt(adminId) }))
      .then(() => this.getAdminInfo(adminId))
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleClubRemove = (clubId) => {
    const { adminId, clubs } = this.state
    const { token, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(removeClubFromAdmin(token, clubId, { admin: parseInt(adminId) }))
      .then(() => {
        this.setState({
          isLoading: false,
          clubs: clubs.filter((club) => club.id !== clubId),
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  // Renders
  renderMain = () => {
    const { sidebar } = this.state
    if (sidebar === INFORMATION) return this.renderInfo()
    return this.renderClubActions()
  }

  renderInfo = () => {
    const { isEdit, isLoading, form, errors } = this.state

    return (
      <CAForm
        isEdit={isEdit}
        isLoading={isLoading}
        form={form}
        lang={this.props.lang}
        errors={errors}
        onChange={this.handleFormChange}
      />
    )
  }

  renderClubActions = () => {
    const { clubs } = this.state

    return <GolfClubs clubs={clubs} onClubRemove={this.handleClubRemove} />
  }

  renderConfirm = () => {
    const {
      isDelete,
      form: { firstName, lastName },
    } = this.state

    if (!isDelete) return null
    return (
      <ConfirmModal
        headerIcon={<TrashIcon />}
        headerText="settings.clubAdmins.deleteCA"
        textArray={[firstName, lastName, '.settings.clubAdmins.confirmDeleteCA']}
        confirmText="settings.delete"
        onCancel={() => this.setState({ isDelete: false })}
        onConfirm={this.handleDelete}
      />
    )
  }

  render = () => {
    const { adminId, isLoading, isEdit, sidebar, form, errors } = this.state
    const { lang, golfClubs } = this.props

    return (
      <ModalContainer
        layout="sidebar"
        loading={isLoading}
        onClose={() => this.handleClose(true)}
        sidebarChildren={
          <Sidebar isCreate={!adminId} sidebar={sidebar} onChange={this.changeSidebar} />
        }
        headerChildren={
          <Header
            isLoading={isLoading}
            lang={lang}
            sidebar={sidebar}
            isCreate={!adminId}
            isEdit={isEdit}
            form={form}
            errors={errors}
            clubs={golfClubs.list}
            onClickActions={this.handleClickActions}
            onCancel={this.handleCancel}
            onSave={!adminId ? this.handleCreate : this.handleUpdate}
            onClubAdd={this.handleClubAdd}
          />
        }
      >
        {this.renderMain()}
        {this.renderConfirm()}
      </ModalContainer>
    )
  }
}

CAManagerModal.propTypes = {
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  golfClubs: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.me.lang,
  golfClubs: state.golfClub,
  memberships: state.membership.totalList,
})

export default connect(mapStateToProps)(CAManagerModal)
