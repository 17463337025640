import { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cx from 'classnames'

import style from './style.module.scss'

/**
 * This component creates a checkbox with optional text
 */
export default class Checkbox extends Component {
  handleChange = _.debounce(() => {
    const { disabled, onChange } = this.props

    if (onChange && !disabled) onChange(this._checkbox.checked)
  }, 100)

  render() {
    const { disabled, title, checked, append, prepend, containerClassName, appendClassName } =
      this.props

    const attributes = {}
    const childNodes = this._cell?.childNodes
    if (
      childNodes &&
      childNodes[0] &&
      childNodes[0].offsetWidth < childNodes[0].scrollWidth &&
      title
    ) {
      attributes.title = title
    }

    return (
      <div
        className={cx(style.container, containerClassName, { [style.disabled]: disabled })}
        onClick={this.handleChange}
      >
        {!!prepend && <div className={style.prepend}>{prepend}</div>}
        <div ref={(container) => (this._checkboxContainer = container)}>
          <label className={style.checkbox}>
            <input
              type="checkbox"
              checked={checked}
              onChange={this.handleChange}
              ref={(checkbox) => (this._checkbox = checkbox)}
              disabled={disabled}
            />
            <span className={style.checkmark} />
          </label>
        </div>
        {!!append && (
          <div
            ref={(element) => (this._cell = element)}
            {...attributes}
            className={cx(style.append, appendClassName)}
          >
            {append}
          </div>
        )}
      </div>
    )
  }
}

Checkbox.propTypes = {
  /**
   * Function to run when the checkbox is checked
   *
   * @param {boolean} isChecked true if checked
   */
  onChange: PropTypes.func,
  /**
   * Title attribute which will be added to Container div
   */
  title: PropTypes.string,
  /**
   * Determines if the checkbox is checked
   */
  checked: PropTypes.bool,
  /**
   * Element to prepend to the checkbox
   */
  prepend: PropTypes.node,
  /**
   * Element to append to the checkbox
   */
  append: PropTypes.node,
  /**
   * Decides if the checkbox should be disabled
   */
  disabled: PropTypes.bool,
}
