import i18next from 'i18next'

export const MINUTES_5_MS = 300000
export const MINUTES_10_MS = 600000
export const DAY_MS = 86400000
// 3 hours
export const MINUTES_180_MS = 10800000

// Values is in ms
export const getNonConfirmedBookingDurationOptions = () => [
  {
    id: 1,
    value: MINUTES_5_MS,
    label: i18next.t('dateTime.words.thisMinute_other', { count: 5 }),
  },
  {
    id: 2,
    value: MINUTES_10_MS,
    label: i18next.t('dateTime.words.thisMinute_other', { count: 10 }),
  },
]

export const getUnpaidBookingDurationOptions = () => [
  {
    id: 1,
    value: MINUTES_5_MS,
    label: i18next.t('dateTime.words.thisMinute_other', { count: 5 }),
  },
  {
    id: 2,
    value: MINUTES_10_MS,
    label: i18next.t('dateTime.words.thisMinute_other', { count: 10 }),
  },
  {
    id: 3,
    value: MINUTES_180_MS,
    label: i18next.t('dateTime.words.thisHour_other', { count: 3 }),
  },
  {
    id: 4,
    value: '',
    label: i18next.t('dateTime.words.custom'),
  },
]

export const LAST_CANCELLATION_POINT = [
  {
    value: 0,
    label: {
      translation: 'sentences.noCancellation',
    },
  },
  {
    value: 1,
    label: {
      translation: 'sentences.nHoursBeforeStartTime',
      options: { count: 1 },
    },
  },
  {
    value: 3,
    label: {
      translation: 'sentences.nHoursBeforeStartTime',
      options: { count: 3 },
    },
  },
  {
    value: 6,
    label: {
      translation: 'sentences.nHoursBeforeStartTime',
      options: { count: 6 },
    },
  },
  {
    value: 12,
    label: {
      translation: 'sentences.nHoursBeforeStartTime',
      options: { count: 12 },
    },
  },
  {
    value: 24,
    label: {
      translation: 'sentences.nHoursBeforeStartTime',
      options: { count: 24 },
    },
  },
  {
    value: 48,
    label: {
      translation: 'sentences.nHoursBeforeStartTime',
      options: { count: 48 },
    },
  },
]
