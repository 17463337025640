import {
  createContext,
  useState,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { getAllFeatureFlags, FeatureFlagValue } from '@sweetspot/shared/data-access/api-platform'

export const REFRESH_INTERVAL_MS = 60000 // Refresh interval in milliseconds

type FlagContextValue = {
  areFlagsLoading: boolean
  flags: Record<string, FeatureFlagValue>
}

export const FlagContext = createContext<FlagContextValue>({
  areFlagsLoading: true,
  flags: {},
})

export const FlagProvider: FC<PropsWithChildren> = ({ children = undefined }) => {
  const [flags, setFlags] = useState<Record<string, FeatureFlagValue>>({})
  const [areFlagsLoading, setFlagsLoading] = useState(true)

  useEffect(() => {
    const fetchFlags = async () => {
      setFlagsLoading(true)
      try {
        const data = await getAllFeatureFlags()
        setFlags(data)
      } catch (error) {
        console.error('Error fetching feature flags:', error)
      }
      setFlagsLoading(false)
    }

    // Initial fetch
    fetchFlags()

    // Set up the interval for refetching flags every minute
    const intervalId = setInterval(fetchFlags, REFRESH_INTERVAL_MS)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, []) // Make sure the dependency array is empty to avoid re-setting the interval unnecessarily

  const value = useMemo(() => ({ flags, areFlagsLoading }), [flags, areFlagsLoading])

  return <FlagContext.Provider value={value}>{children}</FlagContext.Provider>
}

export const useFlagContext = () => useContext(FlagContext)
