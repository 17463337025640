import React from 'react'

import styles from './styles.module.scss'
import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

import { useTranslation } from 'react-i18next'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'

const MiniCalendar = ({
  onDateChange,
  blueStyle,
  isOpen,
  selectedDate,
  startDate,
  endDate,
  availableDays,
  disableBeforeToday,
  showDisabled,
  showBottomBorder,
}) => {
  const { t } = useTranslation()
  const [currentDate, setCurrentDate] = React.useState(selectedDate)

  const _calendarContainer = React.useRef()

  React.useEffect(() => {
    if (!isOpen) setCurrentDate(selectedDate)
  }, [isOpen, selectedDate])

  const getDate = () => {
    return selectedDate
  }

  const getDateString = () => {
    return DateHelpers.toWeekdayDateString(selectedDate)
  }

  const toggleNextMonth = () => {
    setCurrentDate(DateHelpers.nextMonth(currentDate))
  }

  const togglePreviousMonth = () => {
    setCurrentDate(DateHelpers.previousMonth(currentDate))
  }

  const header = () => {
    let monthString = DateHelpers.getMonthName(currentDate) + ' ' + currentDate.getFullYear()

    return (
      <div className={cx(styles.headerContainer)}>
        <div className={cx(styles.arrowContainer)} onClick={togglePreviousMonth}>
          <div className={cx(styles.arrow)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>

        <div className={cx(styles.monthContainer)}>
          <div className={cx(styles.month)}>{monthString}</div>
        </div>

        <div className={cx(styles.arrowContainer)} onClick={toggleNextMonth}>
          <div className={cx(styles.arrow)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    )
  }

  const calendar = () => {
    let labels = [
      t('dateTime.shortDays.monday'),
      t('dateTime.shortDays.tuesday'),
      t('dateTime.shortDays.wednesday'),
      t('dateTime.shortDays.thursday'),
      t('dateTime.shortDays.friday'),
      t('dateTime.shortDays.saturday'),
      t('dateTime.shortDays.sunday'),
    ]
    let days = DateHelpers.getMonthCalendar(currentDate)

    if (isOpen) {
      return (
        <div ref={_calendarContainer} className={cx(styles.calendarContainer)}>
          <div className={cx(styles.calendarLabels)}>
            {labels.map((label, key) => {
              const daySub = label.substring(0, 3).toUpperCase()
              return <p key={key}> {daySub} </p>
            })}
          </div>
          <div className={cx(styles.calendarDays)}>
            {days.map((day, key) => {
              let disabled = disableBeforeToday && moment(new Date()).isAfter(day, 'day')
              let classNames = [styles.dayPanel]

              if (day.getMonth() !== currentDate.getMonth()) {
                classNames.push(cx(styles.inactive))
              }
              if (disabled) classNames.push(cx(styles.disabled))

              if (DateHelpers.isSameDate(day, selectedDate)) {
                classNames.push(cx(styles.selected))
              }
              if (DateHelpers.isSameDate(day, new Date())) {
                classNames.push(cx(styles.today))
              }

              if (!DateHelpers.isInsidePeriodAndValidDay(startDate, endDate, availableDays, day)) {
                classNames.push(styles.inactive)
                disabled = true
              }

              return (
                <div
                  className={cx(classNames.join(' '))}
                  onClick={() => {
                    if (disabled) return
                    onDateChange(day, getDateString())
                    setCurrentDate(day)
                    getDate(day)
                  }}
                  key={key}
                >
                  <div className={cx(styles.dayText)}>{day.getDate()}</div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }

  let classNames = [styles.container]
  if (blueStyle) classNames.push(styles.blueStyle)
  if (showBottomBorder) classNames.push(styles.bottomBorder)

  return (
    <div className={cx(classNames.join(' '))}>
      {header()}
      {calendar()}
    </div>
  )
}

MiniCalendar.propTypes = {
  /**
   * Initially selected date (optional)
   */
  initialDate: PropTypes.instanceOf(Date),
  /**
   * Function to run when the date changes
   *
   * @param {Date} date selected date
   */
  onDateChange: PropTypes.func,
  /**
   * Determines if blue colors should be used for months and days and set background to white
   * **This props does not need to be provided**
   */
  blueStyle: PropTypes.bool,
  /**
   * Determines whether to show MiniCalendar or not
   */
  isOpen: PropTypes.bool,
  /**
   * Selected Date from calendar to display on tee sheet
   */
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  availableDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  disableBeforeToday: PropTypes.bool,
  showDisabled: PropTypes.bool,
  showBottomBorder: PropTypes.bool,
}

MiniCalendar.defaultProps = {
  disableBeforeToday: true,
  showBottomBorder: false,
}

export default MiniCalendar
