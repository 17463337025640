import React from 'react'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import { Country, Player } from '@sweetspot/shared/types'
import DisplayField from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/DisplayField'
import { InformationField } from '@sweetspot/club-portal-legacy/modals/PlayerCard/types'
import style from './style.module.scss'

type Props = {
  field: InformationField
  activeField: number
  handleClick: (id: number) => void
  renderValueEdit: (fieldName: keyof Player) => JSX.Element
  playerInfo: Player
  countries: Country[]
}

const FieldRenderer: React.FC<Props> = ({
  field,
  activeField,
  handleClick,
  renderValueEdit,
  playerInfo,
  countries,
}) => {
  return (
    <div key={field.id} className={style.infoItem}>
      <label>
        <Text textId={field.text} />
      </label>
      {field.id !== activeField ? (
        <DisplayField
          id={field.id}
          disabled={field.disabled}
          value={playerInfo[field.value as keyof Player]}
          fieldName={field.value}
          gender={playerInfo.gender}
          memberType={playerInfo.memberType}
          membershipCategory={playerInfo.club_membership_category}
          membershipType={playerInfo.membership_type}
          countries={countries}
          handleClick={handleClick}
        />
      ) : (
        renderValueEdit(field.value as keyof Player)
      )}
    </div>
  )
}

export default FieldRenderer
