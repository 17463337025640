import produce from 'immer'

export const initialState = {
  teeTime: null,
  teeTimePrice: null,
  bookings: null,
  canceledBookings: null,
  course: null,
  golfClub: null,
  tags: null,
  partnerships: [],
  notes: null,
  golfCarts: null,

  loaders: {
    teeTime: true,
    teeTimePrice: true,
    course: true,
    golfClub: true,
    bookings: true,
    booking: true,
    canceledBookings: true,
    notes: true,
    selectedBooking: true,
    reservingBooking: false,
    confirmingBooking: false,
    cancelingBooking: false,
    tags: true,
    partnerships: true,
    golfCarts: true,
  },
}

export const actions = {
  GOLF_CARTS_UPDATING: 'golfCartsUpdating',
  GOLF_CARTS_UPDATED: 'golfCartsUpdated',

  PARTNERSHIPS_UPDATING: 'partnershipsUpdating',
  PARTNERSHIPS_UPDATED: 'partnershipsUpdated',

  TEE_TIME_UPDATED: 'teeTimeUpdated',
  TEE_TIME_UPDATING: 'updatingTeeTime',

  TEE_TIME_PRICE_UPDATED: 'teeTimePriceUpdated',
  TEE_TIME_PRICE_UPDATING: 'teeTimePriceUpdating',

  CANCELED_BOOKINGS_UPDATED: 'canceledBookingsUpdated',
  CANCELED_BOOKINGS_UPDATING: 'canceledUpdatingBookings',
  CANCELED_BOOKINGS_SET_LOADER: 'canceledBookingsSetLoader',

  CANCELED_BOOKING_UPDATED: 'canceledBookingUpdated',

  BOOKINGS_UPDATED: 'bookingsUpdated',
  BOOKINGS_UPDATING: 'updatingBookings',
  BOOKINGS_SET_LOADER: 'bookingsSetLoader',

  BOOKING_UPDATED: 'bookingUpdated',

  COURSE_UPDATED: 'courseUpdated',
  COURSE_UPDATING: 'updatingCourse',

  GOLF_CLUB_UPDATING: 'golfClubUpdating',
  GOLF_CLUB_UPDATED: 'golfClubUpdated',

  BOOKING_SELECTED: 'selectedBooking',
  BOOKING_SELECTING: 'selectingBooking',

  BOOKING_RESERVING: 'reservingBooking',
  BOOKING_RESERVED: 'reservedBooking',

  BOOKING_CONFIRMING: 'confirmingBooking',
  BOOKING_CONFIRMED: 'confirmedBooking',

  BOOKING_CANCELING: 'cancelingBooking',
  BOOKING_CANCELED: 'canceledBooking',

  TAGS_UPDATING: 'tagsUpdating',
  TAGS_UDPATED: 'tagsUpdated',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.GOLF_CARTS_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.golfCarts = true
      })
    case actions.GOLF_CARTS_UPDATED:
      return produce(state, (draft) => {
        draft.loaders.golfCarts = false
        draft.golfCarts = action.payload
      })
    case actions.PARTNERSHIPS_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.partnerships = true
      })
    case actions.PARTNERSHIPS_UPDATED:
      return produce(state, (draft) => {
        draft.loaders.partnerships = false
        draft.partnerships = action.payload
      })
    case actions.TAGS_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.tags = true
      })
    case actions.TAGS_UDPATED:
      return produce(state, (draft) => {
        draft.loaders.tags = false
        draft.tags = action.payload
      })
    case actions.GOLF_CLUB_UPDATED:
      return produce(state, (draft) => {
        draft.loaders.golfClub = false
        draft.golfClub = action.payload
      })
    case actions.GOLF_CLUB_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.golfClub = true
      })
    case actions.BOOKING_CANCELED:
      return produce(state, (draft) => {
        draft.loaders.cancelingBooking = false
      })
    case actions.BOOKING_CANCELING:
      return produce(state, (draft) => {
        draft.loaders.cancelingBooking = true
      })
    case actions.BOOKING_CONFIRMED:
      return produce(state, (draft) => {
        draft.loaders.confirmingBooking = false
      })
    case actions.BOOKING_CONFIRMING:
      return produce(state, (draft) => {
        draft.loaders.confirmingBooking = true
      })
    case actions.TEE_TIME_UPDATED:
      return produce(state, (draft) => {
        draft.teeTime = action.payload
        draft.loaders.teeTime = false
      })

    case actions.TEE_TIME_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.teeTime = true
        draft.teeTime = null
      })

    case actions.CANCELED_BOOKINGS_UPDATED:
      return produce(state, (draft) => {
        draft.canceledBookings = action.payload
        draft.loaders.canceledBookings = false
      })

    case actions.CANCELED_BOOKINGS_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.canceledBookings = true
        draft.canceledBookings = null
      })

    case actions.BOOKINGS_UPDATED:
      return produce(state, (draft) => {
        draft.bookings = action.payload
        draft.loaders.bookings = false
      })

    case actions.BOOKINGS_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.bookings = true
        draft.bookings = null
      })

    case actions.BOOKINGS_SET_LOADER:
      return produce(state, (draft) => {
        draft.loaders.bookings = action.payload
      })

    case actions.CANCELED_BOOKINGS_SET_LOADER:
      return produce(state, (draft) => {
        draft.loaders.canceledBookings = action.payload
      })

    case actions.BOOKING_UPDATED:
      return produce(state, (draft) => {
        if (!draft.bookings) draft.bookings = {}
        draft.bookings[action.payload.id] = action.payload
        draft.loaders.booking = false
      })

    case actions.CANCELED_BOOKING_UPDATED:
      return produce(state, (draft) => {
        if (!draft.canceledBookings) draft.canceledBookings = {}
        draft.canceledBookings[action.payload.id] = action.payload
      })

    case actions.COURSE_UPDATED:
      return produce(state, (draft) => {
        draft.course = action.payload
        draft.loaders.course = false
      })

    case actions.COURSE_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.course = true
        draft.course = null
      })

    case actions.TEE_TIME_PRICE_UPDATING:
      return produce(state, (draft) => {
        draft.loaders.teeTimePrice = true
      })

    case actions.TEE_TIME_PRICE_UPDATED:
      return produce(state, (draft) => {
        draft.teeTimePrice = action.payload
        draft.loaders.teeTimePrice = false
      })

    case actions.BOOKING_RESERVING:
      return produce(state, (draft) => {
        draft.loaders.reservingBooking = true
      })

    case actions.BOOKING_RESERVED:
      return produce(state, (draft) => {
        draft.loaders.reservingBooking = false
        draft.loaders.bookings = false
        if (!draft.bookings) draft.bookings = {}
        if (action.payload) {
          draft.bookings[action.payload.id] = action.payload
        }
      })

    default:
      break
  }
}
