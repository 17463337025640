import React, { useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import DropdownSelect from '@sweetspot/sweetspot-js/common/components/DropdownSelect'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import useDynamicFixedPosition from '@sweetspot/sweetspot-js/common/hooks/useDynamicFixedPosition'

const ButtonAndDropdownSelectWrapper = ({ buttonProps, dropdownProps, id }) => {
  const wrapper = useRef()
  const container = useRef()

  useOnClickOutside(container, () => dropdownProps?.onCancel && dropdownProps.onCancel(false))

  const { top, left, isInView, maxHeight } = useDynamicFixedPosition(
    wrapper,
    dropdownProps?.isVisible || false
  )

  return (
    <div className={cx(styles.container)}>
      <ButtonBasic {...buttonProps} />
      <div className={cx(styles.dropdownWrapper)} ref={wrapper}>
        {isInView && (
          <Portal id={id}>
            <div
              ref={container}
              className={cx(styles.dropdownContainer)}
              style={{ position: 'fixed', top, left, height: maxHeight }}
            >
              <DropdownSelect {...dropdownProps} />
            </div>
          </Portal>
        )}
      </div>
    </div>
  )
}

ButtonAndDropdownSelectWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    color: PropTypes.oneOf(['default', 'red']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    text: PropTypes.string,
    icon: PropTypes.oneOf(['plus']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  dropdownProps: PropTypes.shape({
    searchEnabled: PropTypes.bool,
    isVisible: PropTypes.bool,
    singleSelectOnly: PropTypes.bool,
    options: PropTypes.array.isRequired,
    preselectedOptions: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    searchPlaceholder: PropTypes.string,
    translateLabels: PropTypes.bool,
    selectAllAvailable: PropTypes.bool,
    searchApiFunction: PropTypes.func,
    clearSearchCall: PropTypes.func,
  }),
}

ButtonAndDropdownSelectWrapper.defaultProps = {
  buttonProps: {
    className: '',
    iconClassName: '',
    color: 'default',
    size: 'small',
    text: '',
    icon: null,
    disabled: false,
    onClick: () => {},
  },
  dropdownSelect: {
    searchEnabled: false,
    isVisible: false,
    onSave: () => {},
    searchPlaceholder: '',
    options: [],
    preselectedOptions: [],
    singleSelectOnly: false,
    translateLabels: false,
    selectAllAvailable: true,
    loadingSearch: false,
  },
}

export default ButtonAndDropdownSelectWrapper
