import React from 'react'
import PropTypes from 'prop-types'

import { useStyles } from '@sweetspot/sweetspot-js/common/hooks/useStyles'

import styles from './styles.module.scss'

const BasicBox = (props) => {
  const cx = useStyles(props.styles)
  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.header)}>
        <p className={cx(styles.title)}>{props.title || ''}</p>
      </div>
      <div className={cx(styles.inner)}>{props.children}</div>
    </div>
  )
}

BasicBox.propTypes = {
  title: PropTypes.string,
  styles: PropTypes.object,
}

export default BasicBox
