import i18next from 'i18next'
import { I18nextNamespaces, LanguageKeys } from '@sweetspot/shared/translations'

export function getLang(lang) {
  switch (lang) {
    case 'en':
      return i18next.getResourceBundle(LanguageKeys.EN, I18nextNamespaces.DEFAULT)
    case 'se':
      return i18next.getResourceBundle(LanguageKeys.SV, I18nextNamespaces.DEFAULT)
    case 'sv':
      return i18next.getResourceBundle(LanguageKeys.SV, I18nextNamespaces.DEFAULT)
    case 'de':
      return i18next.getResourceBundle(LanguageKeys.DE, I18nextNamespaces.DEFAULT)
    case 'da':
      return i18next.getResourceBundle(LanguageKeys.DA, I18nextNamespaces.DEFAULT)
    default:
      return i18next.getResourceBundle(LanguageKeys.EN, I18nextNamespaces.DEFAULT)
  }
}
