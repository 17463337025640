import {
  _addMembership,
  _getMembershipById,
  _addPromotion,
  _publishMembership,
  _updateMembership,
  _deleteDraftMembership,
  _deleteMembership,
} from '@sweetspot/club-portal-legacy/services/membershipApi'

import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
import { queryMembershipCards } from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'

/**
 * Get's membership by player ID
 *
 * @param {string|number} playerId
 * @returns {Promise}
 */
export const getPlayerMembership = (playerId) => {
  return async () => {
    const res = await queryMembershipCards({
      'player.id': playerId,
    })
    return res
  }
}

/**
 * Get's single membership by ID
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const getMembershipById = (membershipId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getMembershipById(membershipId)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('fetchMembershipsError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Adds membership to club
 *
 * @param {string|number} [golfClubId=null]
 * @param {Object} [payload]
 * @returns {Promise}
 */
export const addMembership = (payload, golfClubId = null) => {
  return (dispatch, getState) => {
    const { golfClub } = getState()
    const { selectedId } = golfClub

    const clubId = golfClubId || selectedId

    if (!clubId) {
      dispatch(addToast('membershipCreateError', 'error'))
      return
    }

    return new Promise((resolve, reject) => {
      _addMembership(clubId, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('membershipCreateError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Updates membership
 *
 * @param {string|number} membershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateMembership = (membershipId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateMembership(membershipId, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('membershipUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Deletes membership that has draft status
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const deleteDraftMembership = (membershipId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deleteDraftMembership(membershipId)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('membershipUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Action for publishing membership
 *
 * @param {string|number} membershipId
 * @returns {Promise}
 */
export const publishMembership = (membershipId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _publishMembership(membershipId)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('membershipCreateError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Adds a promotion to a membership
 *
 * @param {string} membershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const addPromotionToMembership = (membershipId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addPromotion(membershipId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('membershipUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

export const deleteMembership = (membershipId) => {
  return () => {
    return new Promise((resolve, reject) => {
      _deleteMembership(membershipId)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }
}
