import React from 'react'
import styles from './styles.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SuccessIcon } from '@sweetspot/sweetspot-js/assets/svgs/green-check.svg'
import { ReactComponent as DangerIcon } from '@sweetspot/sweetspot-js/assets/svgs/danger-red.svg'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import Spinner from '@sweetspot/sweetspot-js/common/components/Spinner'

const TokenVerification = ({
  apiToken,
  isLoading,
  updateQTApiToken,
  setApiToken,
  QTConfig,
  errors,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.container)}>
      <p className={cx(styles.infoText)}>{t('sentences.provideApiTokenInformation')}</p>
      <div className={cx(styles.addTokenRow)}>
        <TextInputField
          label={t('words.apiToken')}
          value={apiToken}
          containerWidth={'286px'}
          onChange={(val) => setApiToken(val)}
          disabled={disabled}
        />

        <Button
          onClick={() => updateQTApiToken()}
          text={t('words.verify')}
          type="submit"
          width="auto"
          size="medium"
          className={cx(styles.button)}
          disabled={!apiToken.length || QTConfig?.api_key === apiToken || disabled}
        />

        <Spinner visible={isLoading} color="blue" className={styles.spinner} />
      </div>

      {QTConfig ? (
        <p className={cx(styles.statusRow, styles.success)}>
          <SuccessIcon />
          {`${t('sentences.apiTokenVerified')}. Version ${QTConfig?.version}`}
        </p>
      ) : null}
      {errors ? (
        <p className={cx(styles.statusRow, styles.error)}>
          <DangerIcon />
          {errors}
        </p>
      ) : null}
    </div>
  )
}

TokenVerification.propTypes = {
  apiToken: PropTypes.string,
  isLoading: PropTypes.bool,
  updateQTApiToken: PropTypes.func,
  setApiToken: PropTypes.func,
  QTConfig: PropTypes.object,
  errors: PropTypes.string,
  disabled: PropTypes.bool,
}

TokenVerification.defaultProps = {
  disabled: false,
}

export default TokenVerification
