import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import CreatePost from '@sweetspot/club-portal-legacy/Partials/Forms/HomeClubPost/CreatePost'
import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import UpdatePost from '@sweetspot/club-portal-legacy/Partials/Forms/HomeClubPost/UpdatePost'

import { ReactComponent as CommunicationIcon } from '@sweetspot/club-portal-legacy/resources/images/communication_icon.svg'

import headers from './headers'

import { addToast } from '@sweetspot/club-portal-legacy/store/actions'

import style from './style.module.scss'
import {
  deleteSingleHomeClubPost,
  getHomeClubPostHydra,
  putHomeClubPosts,
  putSingleHomeClubPost,
} from '@sweetspot/shared/data-access/api-platform'
import { withCurrentClubUuid } from '@sweetspot/club-portal-legacy/hoc/withCurrentClubUuid'

const actions = [
  {
    id: 1,
    label: 'communication.createNew',
    action: 'createNewAdmin',
  },
]

export class Posts extends Component {
  state = {
    posts: [],
    isLoading: false,
    isCreate: false,
    updateId: 0,
    searchInput: '',
    sortItems: [],
    currentPage: 1,
    totalRows: 0,
    rowsPerPage: 50,
  }

  componentDidMount() {
    this.getHomeClubPosts()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.golfClubId !== this.props.golfClubId)
      this.setState({ currentPage: 1 }, this.getHomeClubPosts)
  }

  getHomeClubPosts = () => {
    const { currentClubUuid, dispatch } = this.props

    const query = this.getQueryString()

    this.setState({ isLoading: true })
    getHomeClubPostHydra({ ...query, club: currentClubUuid })
      .then((res) => {
        const posts = res['hydra:member'].map((item) => {
          const publishedAt = item.published_at
            ? moment(new Date(item.published_at)).format('YYYY-MM-DD HH:mm')
            : '-'
          const createdAt = item.created_at
            ? moment(item.created_at).format('YYYY-MM-DD HH:mm')
            : '-'

          let state = item.status
          if (state === 'published' && moment(publishedAt).isAfter(moment(new Date())))
            state = 'scheduled'

          return {
            ...item,
            published_at: publishedAt,
            created: createdAt,
            state: state,
            status: `.communication.${state}`,
          }
        })
        this.setState({
          isLoading: false,
          posts: posts,
          rowsPerPage: res['hydra:itemsPerPage'],
          currentPage: query.page,
          totalRows: res['hydra:totalItems'],
        })
      })
      .catch(() => {
        dispatch(addToast('getPostsError', 'error'))
        this.setState({
          isLoading: false,
          posts: [],
          currentPage: 0,
          totalRows: 0,
          rowsPerPage: 50,
        })
      })
  }

  getQueryString = () => {
    const { searchInput, sortItems, currentPage } = this.state

    let query = {
      page: currentPage,
      limit: 50,
    }
    if (searchInput) query['title'] = searchInput
    const sortBy = sortItems.find((sort) => sort.name === 'created')
    if (sortBy?.type) {
      query[`order[created_at]`] = sortBy.type
    } else {
      query[`order[created_at]`] = 'desc'
    }
    return query
  }

  toggleCreate = () => {
    this.setState({ isCreate: !this.state.isCreate })
  }

  toggleUpdate = (updateId = 0) => {
    this.setState({ updateId })
  }

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.getHomeClubPosts)
  }

  handleSort = (sortItems) => {
    this.setState({ sortItems }, this.getHomeClubPosts)
  }

  handleCreate = (payload) => {
    const { golfClubId, dispatch } = this.props

    this.setState({ isLoading: true })
    putHomeClubPosts(golfClubId, payload)
      .then(() => {
        dispatch(addToast('createPostSuccess', 'success'))
        this.setState({ isCreate: false }, this.getHomeClubPosts)
      })
      .catch(() => {
        dispatch(addToast('createPostError', 'error'))
        this.setState({ isLoading: false })
      })
  }

  handleUpdate = (payload) => {
    const { dispatch } = this.props
    this.setState({ isLoading: true })
    const { id, ...rest } = payload
    putSingleHomeClubPost(id, rest)
      .then(() => {
        dispatch(addToast('updatePostSuccess', 'success'))
        this.setState({ updateId: 0 }, this.getHomeClubPosts)
      })
      .catch(() => {
        dispatch(addToast('updatePostError', 'error'))
        this.setState({ isLoading: false })
      })
  }

  handleRemove = (removeId) => {
    const { dispatch } = this.props
    this.setState({ isLoading: true })
    deleteSingleHomeClubPost(removeId)
      .then(() => {
        dispatch(addToast('removePostSuccess', 'success'))
        this.setState({ removeId: 0 }, this.getHomeClubPosts)
      })
      .catch(() => {
        dispatch(addToast('removePostError', 'error'))
        this.setState({ isLoading: false })
      })
  }

  renderCreateForm() {
    const { isLoading, isCreate } = this.state
    if (!isCreate) return

    return (
      <OverlayContainer>
        <div className={style.overlay}>
          <CreatePost
            isLoading={isLoading}
            onClose={this.toggleCreate}
            onCreate={this.handleCreate}
          />
        </div>
      </OverlayContainer>
    )
  }

  renderUpdateForm() {
    const { isLoading, updateId, posts } = this.state
    if (!updateId) return null
    const post = posts.find((post) => post.id === updateId)

    if (!post) return null

    return (
      <OverlayContainer>
        <div className={style.overlay}>
          <UpdatePost
            isLoading={isLoading}
            post={post}
            onUpdate={this.handleUpdate}
            onRemove={this.handleRemove}
            onClose={this.toggleUpdate}
          />
        </div>
      </OverlayContainer>
    )
  }

  render() {
    const { posts, isLoading, totalRows, rowsPerPage } = this.state

    const totalPages = totalRows <= rowsPerPage ? 1 : Math.ceil(totalRows / rowsPerPage)
    return (
      <div className={style.container}>
        <Grid
          actions={actions}
          actionsEnabled
          searchEnabled
          pagination
          searchPlaceholder={'.communication.searchPosts'}
          displayRangeOfRows
          isLoading={isLoading}
          headers={headers}
          values={posts}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          onActionConfirm={this.toggleCreate}
          onRowClick={(row) => this.toggleUpdate(row?.id)}
          onSearchChange={(value) => this.setState({ searchInput: value })}
          onSearch={() => this.getHomeClubPosts()}
          onHeaderClick={this.handleSort}
          setCurrentPage={(currentPage) => this.handleChangePage(currentPage)}
        />

        {this.renderCreateForm()}
        {this.renderUpdateForm()}
        {!posts?.length && (
          <div className={style.content}>
            {isLoading ? (
              <div className={style.loading}>
                <PulseLoader showIf={isLoading} />
                <Text textId="communication.loadingPosts" />
              </div>
            ) : (
              <div>
                <CommunicationIcon />
                <Text textId="communication.noPosts" className={style.noPosts} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

Posts.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubId: PropTypes.number.isRequired,
  currentClubUuid: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubId: state.golfClub.selectedId,
  }
}

export default connect(mapStateToProps)(withCurrentClubUuid(Posts))
