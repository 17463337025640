import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { useTranslation } from 'react-i18next'
import style from '../../style.module.scss'

const DayInput = ({ errors, formState, setValue, daysOptions }) => {
  const { t } = useTranslation()
  return (
    <div className={errors.day ? style.errored : ''}>
      <span className={style.optionsText}>{t('editPeriodsNew.day')}</span>
      <div className={style.select}>
        <DropdownSelect
          values={daysOptions}
          selectedId={formState.days}
          initialId={formState.days}
          onSelect={(value) => setValue('days', value)}
          noMargin
          fixTextLayout
        />
      </div>
      <div className={style.error}>{errors.days}</div>
    </div>
  )
}

export default DayInput
