const { localStorage } = window

export const Storage = localStorage
export const getLocalStorage = (key: string) => localStorage.getItem(key)
export const setLocalStorage = (key: string, value: string) => localStorage.setItem(key, value)
export const removeLocalStorage = (key: string) => localStorage.removeItem(key)
export const clearLocalStorage = () => localStorage.clear()
export const getLocalStorageWithExpiration = (key: string): string | null => {
  const result = JSON.parse(getLocalStorage(key) as string)
  if (result) {
    if (result.expiration <= Date.now()) {
      removeLocalStorage(key)
      return null
    }
    return result.data
  }
  return null
}

/**
 * @param maxAge As milliseconds
 */
export const setLocalStorageWithExpiration = (
  key: string,
  value: string,
  maxAge = 30 * 60 * 60 * 1000
) => {
  const result = {
    data: value,
    expiration: maxAge,
  }
  if (maxAge) {
    result.expiration = Date.now() + maxAge
  }

  setLocalStorage(key, JSON.stringify(result))
}
