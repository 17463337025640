import { Tooltip } from 'react-tooltip'
import MainCell from '../MainCell'

import style from './style.module.scss'
import { useId, useMemo } from 'react'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  courses?: any[]
  styles?: object
}

const GolfCartCell = ({ courses, styles }: Props) => {
  const key = useId()

  const courseList = useMemo(() => {
    if (courses && courses.length) {
      return courses.filter((course) => course?.name)
    }
    return []
  }, [courses])

  if (!courseList || courseList.length === 0) return '-'

  const containerClassNames = [style.container]

  return (
    <div className={containerClassNames.join(' ')} style={styles}>
      {courseList?.length && (
        <div className={style.inline}>
          <MainCell
            value={courseList?.[0]?.name ?? '-'}
            cellStyle={{ maxWidth: '200px', width: '100%' }}
          />

          {courseList.length > 1 && (
            <>
              <span data-tooltip-id={key} className={style.courseLabel}>
                +{courseList.length - 1}
              </span>

              <Tooltip id={key} place="bottom">
                <div className="courseList">
                  {courseList.map((course, i) =>
                    i > 0 && course && course?.name ? (
                      <div key={course.id}>
                        <span>{course.name}</span>
                      </div>
                    ) : null
                  )}
                </div>
              </Tooltip>
            </>
          )}
        </div>
      )}
      {!courses && <div>{'-'}</div>}
    </div>
  )
}

export default GolfCartCell
