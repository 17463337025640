import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { FC, useRef, useState } from 'react'
import { useOnClickOutside } from '@sweetspot/shared/util/hooks'

interface OverrideProps {
  onHide: () => void
  onApply: (val: string) => void
  defaultValue: number
  className?: string
}

export const Override: FC<OverrideProps> = (props) => {
  const [value, setValue] = useState<string>(String(props.defaultValue / 100))
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef(null)

  useOnClickOutside(containerRef, () => props.onHide())

  return (
    <div
      className={cx(
        'absolute right-0 z-10 flex flex-col rounded border bg-white p-2',
        props.className
      )}
      ref={containerRef}
    >
      <label className={'text-xs'}>{t('sentences.newPrice')}</label>

      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        ref={inputRef}
        type="number"
        className={cx('ss-input')}
      ></input>
      <div className="flex flex-row pl-4 pt-2">
        <button className={cx('system-button md-32 primary-outline mr-2')} onClick={props.onHide}>
          {t('words.cancel')}
        </button>
        <button className={cx('system-button md-32 primary')} onClick={() => props.onApply(value)}>
          {t('words.apply')}
        </button>
      </div>
    </div>
  )
}
