import {
  getUnixSeconds,
  toISOStringWithOffset,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import _isString from 'lodash/isString'
import { queryTeeTimes, queryTeeTimeDynamicPrices } from '../services/api-platform'

/**
 * Get collection of data for tee times
 * Manages combining of multiple requests that is usually needed to get all data for tee-sheet
 *
 * @param {string} courseUuid
 * @param {string} spaceUuid
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {Boolean} is_use_dynamic_pricing
 * @returns {Promise}
 */
export const getTeeTimesCollection = async (
  args = {
    courseUuid: '',
    spaceUuid: '',
    startDate: null,
    endDate: null,
    is_use_dynamic_pricing: true,
  }
) => {
  const { courseUuid, spaceUuid, startDate, endDate, is_use_dynamic_pricing } = args

  if ((!spaceUuid && !courseUuid) || (spaceUuid && !courseUuid)) {
    throw new Error('getTeeTimesCollection: courseUuid and/or spaceUuid is required')
  }
  if (!startDate) {
    throw new Error('getTeeTimesCollection: startDate is required')
  }
  if (!endDate) {
    throw new Error('getTeeTimesCollection: endDate is required')
  }

  const promises = [
    getAllPagesRequest(queryTeeTimes, {
      'course.uuid': courseUuid,
      'space.uuid': spaceUuid || null,
      'from[after]': _isString(startDate) ? startDate : toISOStringWithOffset(startDate),
      'from[before]': _isString(endDate) ? endDate : toISOStringWithOffset(endDate),
      'order[from]': 'asc',
      limit: 100,
      page: 1,
    }),
    is_use_dynamic_pricing
      ? queryTeeTimeDynamicPrices({
          course: courseUuid,
          space: spaceUuid || null,
          'from[after]': _isString(startDate) ? startDate : toISOStringWithOffset(startDate),
          'from[before]': _isString(endDate) ? endDate : toISOStringWithOffset(endDate),
        })
      : (() => [])(),
  ]

  const [data, err] = await to(Promise.all(promises))
  if (err) {
    throw err
  }

  const [teeTimes, teeTimeDynamicPrices] = data

  return teeTimes.map((teeTime) => {
    const teeTimeDynamicPrice = teeTimeDynamicPrices.find((price) => price.uuid === teeTime.uuid)

    const teeTimeObject = {
      ...teeTime,
      from_unix: getUnixSeconds(new Date(teeTime.from)),
      to_unix: getUnixSeconds(new Date(teeTime.to)),
    }

    if (teeTimeDynamicPrice) {
      teeTimeObject.dynamic_price = {
        ...teeTimeDynamicPrice,
        amount: teeTimeDynamicPrice.price,
      }
    }

    return teeTimeObject
  })
}
