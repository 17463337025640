import { z } from 'zod'
import { DURATION_TIME, FIRST_PAYMENT_PAY } from '../../consts'

export const SettingsFormScema = z.object({
  partial_payments: z.boolean(),
  first_payment_pay: z.enum([FIRST_PAYMENT_PAY.PERCENTAGE, FIRST_PAYMENT_PAY.AMOUNT]),
  percentage: z.number().min(1).max(99),
  amount: z.number().optional(),
  first_payment_duration: z.enum([
    DURATION_TIME.DAY,
    DURATION_TIME.TWO_DAYS,
    DURATION_TIME.CUSTOM_DAYS,
  ]),
  first_payment_duration_days: z.number(),
  last_payment_duration: z.enum([
    DURATION_TIME.MAX_DAYS,
    DURATION_TIME.HOURS,
    DURATION_TIME.DAY,
    DURATION_TIME.TWO_DAYS,
    DURATION_TIME.CUSTOM_DAYS,
  ]),
  last_payment_duration_days: z.number(),
  last_payment_duration_hours: z.number(),
  note: z.string().max(255).optional(),
})
