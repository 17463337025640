import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import style from './style.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import MiniCalendar from '../MiniCalendar2'

// Updated copy of sweetspot-react version
export default class CalendarDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: Boolean(props.isOpen),
      inputValue: '',
      blurEnabled: true,
    }
  }

  componentDidMount() {
    // Open dropdown on focus
    this._input.onfocus = () => {
      if (this.props.isReadOnly) return
      this.setState({
        isOpen: true,
      })
    }
    this._input.onblur = this.onBlur
    // Disable spellcheck
    this._input.setAttribute('spellcheck', false)

    this.props.defaultDate && this.setInputValue(this.props.defaultDate)
    this.props.isOpen && this._input.focus()
  }

  onBlur = () => {
    if (!this.state.blurEnabled) return
    if (this.state.isOpen && typeof this.props.onInputBlur === 'function') {
      this.props.onInputBlur(this.state.inputValue)
    }
    this.setState({
      isOpen: false,
    })
  }

  toggleBlur = (isEnabled) => {
    if (isEnabled) {
      this.setState({
        blurEnabled: true,
      })
      // this._input.focus();
    } else {
      this.setState({
        blurEnabled: false,
      })
    }
  }

  setDate = (date) => {
    if (this._calendar) {
      this._calendar.setDate(date)

      if (this._input) {
        setTimeout(() => {
          this._input.value = this._calendar.getDateString()
        }, 100)
      }
    }
  }

  setInputValue = (defaultValue) => {
    if (this.props.type === 'input') {
      this._input.value = moment(defaultValue ? new Date(defaultValue) : this.getDate()).format(
        'YYYY-MM-DD'
      )
    } else {
      this._input.value = this._calendar.getDateString()
    }
  }

  getDate = () => {
    if (this._calendar) {
      return this._calendar.getDate()
    }
  }

  onInputChange = (event) => {
    this.setState({
      inputValue: event.target.value,
    })
    if (typeof this.props.onInputChange === 'function') {
      this.props.onInputChange(event.target.value)
    }
  }

  handleDateChange = (date, dateString) => {
    if (this.props.startDate && !moment(date).isSameOrAfter(this.props.startDate, 'day')) return
    // Update input text value
    this._input.value = dateString
    if (this.props.type === 'input') this._input.value = moment(date).format('YYYY-MM-DD')
    // Run prop function with new date value
    if (this.props.onDateChange) this.props.onDateChange(date)
    // Close the dropdown
    this.setState({
      isOpen: false,
    })
  }

  renderInput = () => {
    let classNames = [style.inputContainer]
    if (this.props.fixedWidth) classNames.push(style.fixedWidth)
    if (this.props.fullWidth) classNames.push('w-full')
    if (this.state.isOpen) classNames.push(style.open)
    if (this.props.type === 'input') classNames.push(style.inputForm)
    if (this.props.error) classNames.push(style.inputError)

    return (
      <div className={classNames.join(' ')}>
        <input
          className="ss-input"
          readOnly={this.props.isReadOnly}
          defaultValue={this._input && this._input.value}
          ref={(input) => (this._input = input)}
          onChange={this.onInputChange}
          placeholder={this.props.placeholder || 'yyyy-mm-dd'}
          onKeyDown={this.handleKey}
        />
        {this.renderCalendar()}
        {this.renderError()}
      </div>
    )
  }

  renderError = () => {
    if (!this.props.error) return false
    return (
      <div className={style.errorContainer}>
        <p>{this.props.error}</p>
      </div>
    )
  }

  renderCalendar = () => {
    let _style = {}
    if (!this.state.isOpen) {
      _style.height = 0
      _style.overflow = 'hidden'
    }
    if (this.props.position === 'top') {
      _style.top = 'unset'
      _style.bottom = 30
    }
    if (this.props.isCentered) {
      _style.left = '50%'
      _style.transform = 'translate(-50%, 0%)'
    }

    return (
      <div
        className={style.calendarContainer}
        style={_style}
        ref={(div) => (this._calendarContainer = div)}
      >
        <MiniCalendar
          initialDate={this.props.defaultDate}
          returnDateFormat={this.props.returnDateFormat}
          startDate={this.props.startDate}
          onMonthChange={() => {
            this._input.focus()
            console.log('focus')
          }}
          onMouseInOut={this.toggleBlur}
          onDateChange={this.handleDateChange}
          ref={(calendar) => (this._calendar = calendar)}
          blueStyle={this.props.blueStyle}
        />
      </div>
    )
  }

  render() {
    const { type, onPrevClick, onNextClick } = this.props

    return (
      <div className={style.container}>
        {type !== 'input' && (
          <div className={style.content} onClick={onPrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        )}
        {this.renderInput()}
        {type !== 'input' && (
          <div className={style.content} onClick={onNextClick}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        )}
      </div>
    )
  }
}

CalendarDropdown.propTypes = {
  defaultDate: PropTypes.object,
  onDateChange: PropTypes.func,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  fixedWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  blueStyle: PropTypes.bool,
  type: PropTypes.string,
  position: PropTypes.string,
  returnDateFormat: PropTypes.string,
  isCentered: PropTypes.bool,
  startDate: PropTypes.object,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  onInputChange: PropTypes.func,
  onInputBlur: PropTypes.func,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
}

CalendarDropdown.defaultProps = {
  blueStyle: true,
  position: 'bottom',
  isCentered: true,
}
