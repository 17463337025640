import React from 'react'
import ManageBookingOwnerBox from '../../../../ManageBookingOwnerBox'
import AssignBookingOwnerBox from '../../../../AssignBookingOwnerBox'
import SpaceBox from '../../../../SpaceBox'
import PlayerSearch from '@sweetspot/sweetspot-js/ui-kit/components/PlayerSearch'
import BookingNotesSims from '../../../../BookingNotesSims'
import styles from './styles.module.scss'
import cx from 'classnames'
import { Button } from '@sweetspot/scramble-ds'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const DetailsStep = ({
  orderBooking,
  hasCustomer,
  hasStubCustomer,
  orderBookingIsFetching,
  addedSpaces,
  removingSpace,
  handleRemoveSpace,
  assignOwner,
  isAssigningOwner,
  handleEditSpacesButton,
  isBookingConfirmed,
  isConsideredPaid,
  timezone,
}) => {
  const currentClub = useSelector((state) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })
  const { t } = useTranslation()

  const spaceItems = useMemo(() => {
    let tempSpacesAndItems = {}
    if (!orderBooking) return
    orderBooking.spaces.forEach((space) => {
      tempSpacesAndItems[space.id] = orderBooking?.items?.filter(
        (elem) => elem.slot?.tee_time?.space?.uuid === space.uuid
      )
    })
    return tempSpacesAndItems
  }, [orderBooking])

  const clubHasCdhNumber = currentClub?.cdh_id
  return (
    <>
      <ManageBookingOwnerBox
        orderBooking={orderBooking}
        isOpen={hasCustomer || hasStubCustomer}
        shouldRender={!!orderBooking}
      />
      <AssignBookingOwnerBox
        isOpen={!hasCustomer && !hasStubCustomer}
        shouldRender={!!orderBooking}
      >
        <PlayerSearch
          className={cx(styles.customPlayerSearch)}
          onSelectPlayer={assignOwner}
          disabled={isAssigningOwner || orderBookingIsFetching}
          club={currentClub}
          clubHasCdhNumber={clubHasCdhNumber}
          searchPlaceholder={
            clubHasCdhNumber
              ? t('sentences.searchPlayersByNamePhoneEmailOrCDHNumber')
              : t('sentences.searchPlayersByNamePhoneEmailOrGolfId')
          }
        />
      </AssignBookingOwnerBox>
      <hr className={styles.divider} />
      <div className={'mb-8 flex items-center justify-between'}>
        <p className={'text-content-base font-bold'}>{t('words.spaces')}</p>
        <Button
          onClick={handleEditSpacesButton}
          disabled={isConsideredPaid || isBookingConfirmed}
          variant={'ghost-dark'}
          size={'sm'}
          className={cx(styles.editSpaceButton, 'px-4 py-1')}
        >
          <FontAwesomeIcon icon={faPen} />
          {t('sentences.editSpaces')}
        </Button>
      </div>
      {!!addedSpaces?.length && (
        <div className={styles.spacesContainer}>
          {addedSpaces.map((space) => (
            <SpaceBox
              key={space.id}
              space={space}
              canRemove={addedSpaces.length > 1}
              removeDisabled={removingSpace}
              orderBooking={orderBooking}
              hasOwner={hasCustomer || hasStubCustomer}
              items={spaceItems[space.id]}
              onRemove={() => handleRemoveSpace(space.id, space.uuid)}
              timezone={timezone}
            />
          ))}
        </div>
      )}
      <BookingNotesSims bookingID={orderBooking.uuid} />
    </>
  )
}
