import { useRef, useEffect, useState, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import Portal from '../Portal'

const OptionsMenu = ({ attachToElementRef, options, elementQuery, clickedRow, invertX }) => {
  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState({
    top: 0,
    ...(invertX ? { right: 0 } : { left: 0 }),
  })

  // On Click outside
  const containerRef = useRef()
  const handleOnRequestClose = () => {
    setVisible(false)
  }
  useOnClickOutside(containerRef, handleOnRequestClose)

  const handleOnClick = (onClick) => {
    onClick(clickedRow)
    setVisible(false)
  }

  const handleAttachedElementClick = useCallback(
    (event) => {
      const { clientY, clientX } = event
      setPosition({
        top: clientY,
        ...(invertX ? { right: window.innerWidth - clientX } : { left: clientX }),
      })
      setVisible(true)

      event.preventDefault()
      return false
    },
    [invertX]
  )

  useEffect(() => {
    if (!options || options?.length <= 0) {
      return
    }

    let element =
      attachToElementRef?.current ||
      attachToElementRef ||
      (elementQuery && document.querySelector(elementQuery))

    const listener = (event) => {
      if (!element || !element?.contains(event.target)) {
        return
      }
      handleAttachedElementClick(event)
    }

    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [attachToElementRef, options, elementQuery, handleAttachedElementClick])

  if (!options || options?.length <= 0 || !visible) return null

  return (
    <Portal id="mouse-dropdown">
      <div
        ref={containerRef}
        className={cx(styles.container, {
          [styles.visible]: visible,
          [styles.invert]: invertX,
        })}
        style={position}
      >
        {options.map(({ label, icon: Icon, onClick, children, disabled }) => (
          <div
            key={label}
            className={cx(styles.option, { [styles.disabled]: disabled })}
            onClick={() => !disabled && handleOnClick(onClick)}
          >
            {children ? (
              children
            ) : (
              <div className={cx(styles.noChildrenWrapper)}>
                {Icon && (
                  <div className={cx(styles.iconWrapper)}>
                    <Icon className={cx(styles.icon)} />
                  </div>
                )}
                {label && <p className={cx(styles.label)}>{label}</p>}
              </div>
            )}
          </div>
        ))}
      </div>
    </Portal>
  )
}

OptionsMenu.propTypes = {
  attachToElementRef: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.elementType,
      onClick: PropTypes.func,
      children: PropTypes.node,
    })
  ),
  elementQuery: PropTypes.string,
  invertX: PropTypes.bool,
}

OptionsMenu.defaultProps = {
  invertX: false,
}

export default OptionsMenu
