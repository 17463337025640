import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'

import customStyle from './style.module.scss'

type Props = {
  status: string | null
}

const PaymentDueCell = ({ status }: Props): JSX.Element => {
  const { t } = useTranslation()

  if (!status)
    return (
      <span className={customStyle.dueIconContainer}>
        <FontAwesomeIcon
          data-tooltip-id="grid-tooltip"
          icon={faCircleExclamation}
          size="lg"
          className={customStyle.invalidPayoutState}
        />

        <Tooltip id="grid-tooltip" place="bottom">
          <span className={customStyle.tooltipTextContainer}>
            {t('sentences.payoutCantBeDone')}
          </span>
        </Tooltip>
      </span>
    )

  return <span className={customStyle.dueDateContainer}>{status}</span>
}

export default PaymentDueCell
