import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'

import { Tabs } from '../types'

const BallDispensersEmptyState = () => {
  const { t } = useTranslation()

  return (
    <div className="bg-background-base-neutral relative flex h-full w-full items-center justify-center rounded">
      <EmptyState
        key="empty-state-ball-dispensers"
        className="max-w-full px-6 py-4"
        iconName="fa-solid fa-ball-pile bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>{t('settings.ballDispensers.noBallDispenser')}</EmptyStateTitle>
        <EmptyStateDescription>
          {t('settings.ballDispensers.configureFirstBallDispenser')}
        </EmptyStateDescription>
        <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
          <Link
            className="height-full flex w-full items-center justify-center"
            to={`#${Tabs.SETUP}`}
          >
            <i className="fa-regular fa-arrow-up-right-from-square mr-2"></i>
            {t('settings.ballDispensers.setUpBallDispenser')}
          </Link>
        </EmptyStateButton>
      </EmptyState>
    </div>
  )
}

export default BallDispensersEmptyState
