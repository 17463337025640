import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import style from './style.module.scss'

import { Container, Draggable } from 'react-smooth-dnd'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import Text from '../Text'
import Checkbox from '../Checkbox'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

import { ReactComponent as IconRows } from '@sweetspot/club-portal-legacy/resources/images/rows_icon.svg'

/**
 * This component displays a popup with a list of selectable elements
 */
class CheckboxSelect extends Component {
  state = {
    selectedOptions: [],
    options: [],
  }

  componentDidMount = () => {
    this.getInitialOptions()
    this.setState({ options: this.props.options })
  }

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps.options, this.props.options)) {
      this.setState(
        {
          options: this.props.options,
        },
        () => this.getInitialOptions()
      )
    }
  }

  /**
   * Returns the options as an object with [id -> bool] pairs
   */
  getInitialOptions = () => {
    let options = {}
    this.props.options.forEach((option) => {
      options[option.id] = !!option.selected
    })
    this.setState({ selectedOptions: options })
  }

  /**
   * Selects or deselects the option with the given ID in the state
   *
   * @param {Number} id
   */
  toggleOption = (id) => {
    // clone options to maintain state immutability
    let options = Utilities.deepClone(this.state.selectedOptions)
    options[id] = !options[id]

    this.setState({ selectedOptions: options })

    if (this.props.saveChangesOnCheck) {
      this.handleAccept(options)
    }
  }

  /**
   * Runs when the Accept button is clicked
   *
   * Calls the onAccept prop with an array of selected IDs
   */
  handleAccept = (options) => {
    let selectedOptions = []

    let newOptions = options ? options : this.state.selectedOptions

    let keys = Object.keys(newOptions)
    keys.forEach((key) => {
      if (newOptions[key]) selectedOptions.push(key)
    })

    if (this.props.onAccept) this.props.onAccept(selectedOptions)
  }

  toggleDrag = () => {
    this.setState({ isDrag: !this.state.isDrag })
  }

  renderCheckboxes = () => {
    const { isDrag, selectedOptions } = this.state
    const { onDrop, options } = this.props
    const calculatedHeight = options.length * 39
    return (
      <div className={style.checkboxContainer} style={{ height: `${calculatedHeight}px` }}>
        <Container
          dragClass={style.activeDrag}
          onDrop={onDrop}
          getChildPayload={(payload) => payload}
          onDragStart={this.toggleDrag}
          onDragEnd={this.toggleDrag}
        >
          {options.map((option, index) => {
            let text

            // if the name start with . it is considered as an ID for the Text component
            if (typeof option.name === 'string') {
              if (option.name.substring(0, 1) === '.') {
                text = <Text textId={option.name} />
              } else {
                text = <div>{option.name}</div>
              }
            }

            if (typeof option.name === 'object') {
              text = (
                <div>
                  <i class={option.name.icon.props.class} aria-hidden="true" />
                </div>
              )
            }

            const checkboxClass = [style.checkbox]
            if (isDrag && option.disabled) checkboxClass.push(style.disabled)

            return (
              <Draggable key={index}>
                <div className={checkboxClass.join(' ')}>
                  <IconRows className={style.image} />
                  <Checkbox
                    checked={selectedOptions[option.id]}
                    onChange={() => this.toggleOption(option.id)}
                    append={text}
                    disabled={option.disabled}
                  />
                </div>
              </Draggable>
            )
          })}
        </Container>
      </div>
    )
  }

  render() {
    if (!this.props.showIf) return null
    return (
      <div ref={this.props.innerRef} className={style.container}>
        <div className={style.content}>
          <div className={style.titleContainer}>{this.props.title}</div>

          {this.renderCheckboxes()}

          {!this.props.hideConfirmButtons && (
            <div className={style.buttons}>
              <Button
                theme="warning"
                onClick={() => {
                  if (this.props.onCancel) this.props.onCancel()
                }}
              >
                <Text textId="cancel" />
              </Button>
              <Button onClick={this.handleAccept}>
                <Text textId="save" />
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <CheckboxSelect {...props} innerRef={ref} />)

CheckboxSelect.propTypes = {
  /**
   * List of options in the format `[{ name: String, id: Number }, ...]`
   *
   * If the name starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  options: PropTypes.array.isRequired,
  /**
   * Title of the popup window
   */
  title: PropTypes.node,
  /**
   * Ref of the checkbox component
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  /**
   * Determines if the popup should be displayed
   */
  showIf: PropTypes.bool,
  /**
   * Function to run when **accepting**
   *
   * @param {Array} IDs list of selected IDs
   */
  onAccept: PropTypes.func,
  /**
   * Function to run when **cancelling**
   */
  onCancel: PropTypes.func,
  /**
   * Function to run when **finish drop**
   */
  onDrop: PropTypes.func,
  /**
   * Bool that decides if changes should be saved on each check/uncheck
   */
  saveChangesOnCheck: PropTypes.bool,
  /**
   * Bool that decides if confirm and cancel buttons should be hidden
   */
  hideConfirmButtons: PropTypes.bool,
}
