import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

export const Instructions = () => {
  const { t } = useTranslation()
  return (
    <details className={'bg-neutral-100-two group rounded border transition open:bg-white'}>
      <summary className={'flex select-none items-center gap-4 p-4'}>
        <FontAwesomeIcon icon={faCaretRight} className={'w-4 rotate-0 group-open:rotate-90'} />
        <h1 className={'font-medium'}>
          {t('memberships.membershipImports.instructions.heading_instructions')}
        </h1>
      </summary>
      <div className={'space-y-5 pb-4 pl-14 pr-4 text-sm [&>*>h2]:font-semibold'}>
        <div className="flex">
          <FontAwesomeIcon icon={faExclamationCircle} className={'me-2 w-4 pt-1'} />
          <p>{t('memberships.membershipImports.instructions.warning')}</p>
        </div>
        <div>
          <h2>{t('memberships.membershipImports.instructions.label_email')}</h2>
          <p>{t('memberships.membershipImports.instructions.message_email')}</p>
        </div>
        <div>
          <h2>{t('memberships.membershipImports.instructions.label_membershipUuid')}</h2>
          <p>{t('memberships.membershipImports.instructions.message_membershipUuid')}</p>
        </div>
        <div>
          <h2>{t('memberships.membershipImports.instructions.label_expirationDate')}</h2>
          <p>{t('memberships.membershipImports.instructions.message_expirationDate')}</p>
        </div>
        <div>
          <h2>{t('memberships.membershipImports.instructions.label_instructions')}</h2>
        </div>
        <div>
          <h2>{t('memberships.membershipImports.instructions.label_upload')}</h2>
          <p>{t('memberships.membershipImports.instructions.message_upload')}</p>
        </div>
      </div>
    </details>
  )
}
