import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import m from 'moment'

import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import { useStyles } from '@sweetspot/sweetspot-js/common/hooks/useStyles'

import styles from './styles.module.scss'
import useMe from '@sweetspot/sweetspot-js/common/hooks/useMe'

const BookingNotesCourses = (props) => {
  const { t } = useTranslation()
  const cx = useStyles(props.styles)
  const notesContainer = useRef()
  const me = useMe()

  const [notes, setNotes] = useState([])

  const [isInputFocused, setIsInputFocused] = useState(false)
  const [editNoteID, setEditNoteID] = useState()

  const [newNoteText, setNewNoteText] = useState('')

  const getTeeTimeNotes = () => {
    let parsedNotes = []
    try {
      parsedNotes = JSON.parse(props.notes) || []
    } catch (error) {
      parsedNotes.push({ text: notes, id: crypto.randomUUID() })
    }
    return parsedNotes
  }

  const addNote = async () => {
    if (newNoteText.trim().length === 0) {
      resetEdit()
      return
    }
    resetEdit()
    let notes = getTeeTimeNotes()
    let author = null

    if (me) {
      if (me?.first_name || me?.last_name) {
        author = `${me?.first_name || ''} ${me?.last_name || ''}`
      } else if (me?.email) {
        author = me.email
      }
    }

    if (editNoteID) {
      notes = notes.map((note) => {
        if (note.id === editNoteID) {
          return {
            ...note,
            text: newNoteText,
          }
        }
        return note
      })
    } else {
      notes.push({
        text: newNoteText,
        id: crypto.randomUUID(),
        time: m.utc().toISOString(),
        author: author,
      })
    }
    props.onNotesUpdate(notes)
  }

  const editNote = (note) => {
    if (note.id === editNoteID) {
      resetEdit()
    } else {
      setIsInputFocused(true)
      setNewNoteText(note.text)
      setEditNoteID(note.id)
    }
  }

  const removeNote = async (noteID) => {
    props.onNotesUpdate(notes.filter((i) => i.id !== noteID))
  }

  const resetEdit = () => {
    setEditNoteID(null)
    setIsInputFocused(false)
    setNewNoteText('')
  }

  useEffect(() => {
    if (props.notes) {
      const notes = getTeeTimeNotes().filter((noteObject) => noteObject !== null)
      setNotes(notes)
    } else {
      setNotes([])
    }
  }, [props.notes])

  useEffect(() => {
    const scrollDown = () => {
      if (notesContainer?.current) {
        notesContainer.current.scrollTo(0, notesContainer.current.scrollHeight)
      }
    }
    if (notes) {
      scrollDown()
    }
  }, [notes])

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.notesContainer)} ref={notesContainer}>
        {props.isLoading && !editNoteID ? (
          <div className={cx(styles.note)} key="placeholder">
            <Skeleton width="100%" height="30px" />
          </div>
        ) : (
          notes.map((note) => {
            if (!note) return null
            return (
              <div className={cx(styles.note)} key={note.id}>
                <div className={cx(styles.headerArea)}>
                  <p className={cx(styles.author)}>{note.author}</p>
                  <p className={cx(styles.time)}>{m(note.time).format('DD.MM.YYYY HH:mm')}</p>
                  {!props.isCancelled && (
                    <div className={cx(styles.buttonArea)}>
                      <p className={cx(styles.button)} onClick={() => editNote(note)}>
                        {t(`bookingNotes.button_Edit${editNoteID === note.id ? 'Cancel' : ''}`)}
                      </p>
                      <p className={cx(styles.button)} onClick={() => removeNote(note.id)}>
                        {t('bookingNotes.button_Remove')}
                      </p>
                    </div>
                  )}
                </div>
                <p className={cx(styles.text)}>{note.text}</p>
              </div>
            )
          })
        )}
      </div>

      {!props.isCancelled && (
        <div className={cx(styles.addNoteContainer, { [styles.active]: isInputFocused })}>
          <TextInputField
            containerClassName={cx(styles.textContainer)}
            inputClassName={cx(styles.inputContainer)}
            placeholder={t('bookingNotes.placeholder_AddNote')}
            type="text-area"
            rows={3}
            value={newNoteText}
            onChange={(val) => setNewNoteText(val)}
            inputProps={{
              autoFocus: isInputFocused,
            }}
            onInputFocus={() => setIsInputFocused(true)}
            onInputBlur={() => setIsInputFocused(false)}
            onEnter={addNote}
          />
          <p className={cx(styles.infoText, isInputFocused ? styles.visible : false)}>
            {t('bookingNotes.label_PressEnter')}
          </p>
        </div>
      )}
    </div>
  )
}

BookingNotesCourses.propTypes = {
  isLoading: PropTypes.bool,
  notes: PropTypes.string,
  onNotesUpdate: PropTypes.func.isRequired,
  isCancelled: PropTypes.bool,
  styles: PropTypes.object,
}

BookingNotesCourses.defaultProps = {
  isCancelled: false,
}

export default BookingNotesCourses
