import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const DotMarker = ({ color, className }) => {
  return (
    <div className={cx(styles.dotContainer, className)}>
      <div
        className={cx(styles.dot, {
          [styles.red]: color === 'red',
          [styles.green]: color === 'green',
        })}
      ></div>
    </div>
  )
}

DotMarker.propTypes = {
  color: PropTypes.oneOf(['red', 'green']),
  className: PropTypes.string,
}

DotMarker.defaultProps = {}

export default DotMarker
