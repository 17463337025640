import { ReactNode } from 'react'
import {
  Check,
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'

interface FilterOptionProps {
  icon: ReactNode
  label: string
  checked: boolean
  onCheckedChange: (checked: boolean) => void
  disabled: boolean
}

const FilterOption = ({ icon, label, checked, onCheckedChange, disabled }: FilterOptionProps) => (
  <ListItem className="w-full [&:not(:last-child)]:mb-2">
    <ListItemLeading>{icon}</ListItemLeading>
    <ListItemMainContent className="py-3">
      <ListItemMainLabel className="text-text-dark">{label}</ListItemMainLabel>
    </ListItemMainContent>
    <ListItemTrailing>
      <Check checked={checked} onCheckedChange={onCheckedChange} disabled={disabled} />
    </ListItemTrailing>
  </ListItem>
)

export default FilterOption
