import { forwardRef, useContext, useEffect, useId, useMemo } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { SecondLevelItemKeyContext, TopLevelItemKeyContext } from '../contexts'
import { useSideSystemNavStore } from '../state'
import { cn } from '../../../../utils'
import { Button } from '../../../atoms'

interface ThirdLevelItemProp extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
  isActive?: boolean
  isPending?: boolean
  disabled?: boolean
  text: string
}
const ThirdLevelItem = forwardRef<HTMLDivElement, ThirdLevelItemProp>(
  (
    { asChild, isActive = false, isPending = false, disabled = false, text, children, ...props },
    ref
  ) => {
    const key = useId()
    const parentKey = useContext(SecondLevelItemKeyContext)
    const grandParentKey = useContext(TopLevelItemKeyContext)

    const {
      setActiveKey,
      setActiveKeyByIsActiveProp,
      activeKeys: { 3: currentThirdLevelActiveItem },
      activeKeysByIsActiveProp: { 3: currentThirdLevelActiveItemByIsActiveProp },
    } = useSideSystemNavStore()

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        event.preventDefault()
        event.stopPropagation()
        return
      }
      setActiveKey(3, key)
    }

    const calculatedActive = useMemo(
      () =>
        isActive ||
        currentThirdLevelActiveItem === key ||
        currentThirdLevelActiveItemByIsActiveProp === key,
      [isActive, currentThirdLevelActiveItem, currentThirdLevelActiveItemByIsActiveProp, key]
    )

    useEffect(() => {
      if (isActive && parentKey && grandParentKey) {
        setActiveKeyByIsActiveProp(1, grandParentKey)
        setActiveKeyByIsActiveProp(2, parentKey)
        setActiveKeyByIsActiveProp(3, key)
      }
    }, [isActive, key, parentKey, grandParentKey, setActiveKeyByIsActiveProp])

    const Comp = asChild ? Slot : 'div'
    return (
      <Comp ref={ref} {...props}>
        <div
          className={cn(
            'items-hover border-1 bg-sand-50 hover:bg-sand-100 relative ml-auto flex h-12 w-[208px] items-center border pr-3.5',
            {
              'bg-sand-100': calculatedActive,
              'cursor-default opacity-40': disabled,
              'fa-fade': isPending,
            }
          )}
          onClick={handleClick}
          role="presentation"
        >
          <div className="bg-sand-300 absolute left-0 top-0 h-full w-[1px]" />

          <Button
            variant="clear-dark"
            size={calculatedActive ? 'medium' : 'small'}
            className={cn(
              'text-content-base hover:text-text-dark !text-text-dark border border-transparent !bg-transparent pl-[32px] !shadow-none transition-none focus:ring-0',
              {
                'truncate pr-[2px] font-bold': calculatedActive,
                'pr-1 font-normal': !calculatedActive,
                '!cursor-default': disabled,
              }
            )}
          >
            <span className="truncate">{text ?? ''}</span>
          </Button>
          {calculatedActive && <i className="fa-regular fa-angle-right text-content-xl !ml-auto" />}
        </div>
      </Comp>
    )
  }
)
ThirdLevelItem.displayName = 'ThirdLevelItem'

export { ThirdLevelItem }
