import { PriceRow } from '../PriceRow/PriceRow'
import { FC, useState, useMemo } from 'react'
import { formatInTimeZone } from 'date-fns-tz'
import { faCircleMinus, faPen } from '@fortawesome/pro-solid-svg-icons'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { Adjustment, Item, TeeTime } from '@sweetspot/shared/types'
import {
  deleteAdjustmentFromOrderItem,
  setItemPrice,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { Override } from './Override'
import { useTranslation } from 'react-i18next'

import { useToasts } from 'react-toast-notifications'
import { TotalRow } from '../TotalRow/TotalRow'
import { CalculatedOrderItem } from '@sweetspot/shared/data-access/api-platform'
import {
  useExtraPlayerContext,
  useExtraPlayerState,
} from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'

interface DetailsSummaryProps {
  items: Item[]
  teeTimeUuid: string
  timezone: string
  currency_code: string
  orderBookingRefetch: () => Promise<unknown>
  disableAdjustment: boolean
  isLastItem: boolean
  hasOwner: boolean
  isPaid: boolean
  isConfirmed: boolean
}

export const DetailsSummary: FC<DetailsSummaryProps> = (props) => {
  const { addToast } = useToasts()
  const [loadingAdjustment, setLoadingAdjustment] = useState(false)
  const extraPlayerState = useExtraPlayerState()
  const extraPlayerContext = useExtraPlayerContext()

  const [showOverride, setShowOverride] = useState(false)
  const { t } = useTranslation()
  const isTeeTimeWithPPP = useMemo(
    () =>
      !!props.items.find((item) => item.slot?.tee_time?.uuid === props.teeTimeUuid)?.slot.tee_time
        .price_per_extra_player,
    [props.items, props.teeTimeUuid]
  )

  const [baseItem, ...extraItems]: Array<
    CalculatedOrderItem & {
      teeTime?: TeeTime
      adjustmentsList?: Adjustment[]
    }
  > = useMemo(
    () =>
      extraPlayerState?.calculatedPriceResult?.order_items
        ?.filter((orderItem) => orderItem.tee_time_uuid === props.teeTimeUuid)
        .map((orderItem) => {
          const teeTime = props.items.find(
            (item) => item.slot.tee_time.uuid === orderItem.tee_time_uuid
          )?.slot.tee_time
          const item = props.items.find((item) => item.id === orderItem.id)
          const adjustmentsList = item?.adjustments || []

          return {
            ...orderItem,
            teeTime,
            adjustmentsList,
          }
        }) || [],
    [extraPlayerState?.calculatedPriceResult?.order_items, props.items, props.teeTimeUuid]
  )

  const extraItemsUnitsTotal = useMemo(
    () => extraItems.reduce((acc, item) => acc + item.quantity * item.units_total, 0),
    [extraItems]
  )

  const extraItemsTotal = useMemo(
    () => extraItems.reduce((acc, item) => acc + item.total, 0),
    [extraItems]
  )

  const baseItemUnitsTotal = useMemo(() => baseItem?.units_total || 0, [baseItem])

  const baseItemTotal = useMemo(() => baseItem?.total || 0, [baseItem])

  const removeAdjustment = async (itemId: number, adjustmentId: number) => {
    try {
      setLoadingAdjustment(true)
      await deleteAdjustmentFromOrderItem(itemId, adjustmentId)
    } catch (error) {
      addToast('sentences.couldNotRemoveAdjustment', { appearance: 'error' })
    }
    await props.orderBookingRefetch()
    await extraPlayerContext.calculatePrice()
    setLoadingAdjustment(false)
  }

  const applyAdjustmentStartPrice = async (value?: string) => {
    setShowOverride(false)
    if (!value || Number(value) < 0) {
      addToast(t('errors.valueMustBeThisOrHigher', { this: 0 }), { appearance: 'error' })
      return
    }

    setLoadingAdjustment(true)

    if (value?.includes(',')) {
      value = value.split(',').join('.')
    }
    const payloadValue = parseFloat(value)
    try {
      await setItemPrice(baseItem.id as unknown as string, Math.round(payloadValue * 100))
    } catch (error) {
      addToast(t('sentences.couldNotAddAdjustment'), { appearance: 'error' })
    } finally {
      await props.orderBookingRefetch()
      await extraPlayerContext.calculatePrice()
      setLoadingAdjustment(false)
    }
  }

  const adjustedPrice = useMemo(
    () =>
      baseItem?.adjustmentsList?.find(
        (adjustment) => adjustment?.type === 'order_item_new_price_adjustment'
      ),
    [baseItem]
  )

  const adjustedPriceAmount = useMemo(
    () =>
      adjustedPrice &&
      `${adjustedPrice.amount > 0 ? '+' : ''}${priceToLocal(
        adjustedPrice.amount,
        props.currency_code,
        true
      )}`,
    [adjustedPrice, props.currency_code]
  )

  const adjustmentsList = useMemo(
    () =>
      baseItem?.adjustmentsList?.reduce((acc: Adjustment[], adjustment) => {
        const isHolderAdjustment =
          adjustment.details?.configuration?.filter?.slot_filter?.slot_type_query?.includes(
            'holder'
          )

        if (isHolderAdjustment) {
          const guestToHolderAdjustments = extraItems.reduce((acc: Adjustment[], item) => {
            if (!item?.adjustments?.length) {
              return acc
            }

            const guestToHolders = item.adjustments.filter(
              (extraItemAdjustment) =>
                extraItemAdjustment &&
                extraItemAdjustment.type === adjustment.type &&
                extraItemAdjustment.origin_code === adjustment.origin_code &&
                extraItemAdjustment.details?.configuration?.filter?.slot_filter?.slot_type_query?.includes(
                  'guest_to_holder'
                )
            )

            return [...acc, ...guestToHolders]
          }, [])

          if (guestToHolderAdjustments?.length > 0) {
            const totalAmount = guestToHolderAdjustments.reduce((sum, adj) => sum + adj.amount, 0)
            return [...acc, adjustment, { ...guestToHolderAdjustments[0], amount: totalAmount }]
          }

          return [...acc, adjustment]
        }

        const extraItemsAdjustmentsAmount = extraItems.reduce((acc: number, item) => {
          if (!item?.adjustments?.length) {
            return acc
          }
          return (
            acc +
              item.adjustments
                .filter(
                  (extraItemAdjustment) =>
                    extraItemAdjustment &&
                    extraItemAdjustment.type === adjustment.type &&
                    extraItemAdjustment.origin_code === adjustment.origin_code
                )
                .reduce((acc, extraItemAdjustment) => acc + extraItemAdjustment.amount, 0) || 0
          )
        }, 0)

        return [...acc, { ...adjustment, amount: adjustment.amount + extraItemsAdjustmentsAmount }]
      }, []),
    [baseItem, extraItems]
  )

  const isPaidOrConfirmedBookingModified = useMemo(
    () =>
      (props.isPaid || props.isConfirmed) &&
      extraPlayerState?.calculatedPriceResult?.tee_times.find(
        (teeTime) => teeTime.uuid === props.teeTimeUuid
      )?.is_modified,
    [
      extraPlayerState?.calculatedPriceResult?.tee_times,
      props.isConfirmed,
      props.isPaid,
      props.teeTimeUuid,
    ]
  )

  return (
    <div>
      {baseItem?.teeTime && (
        <div className="text-brand-black py-1 ">
          {formatInTimeZone(baseItem.teeTime.from, props.timezone, 'HH:mm')}-
          {formatInTimeZone(baseItem.teeTime.to, props.timezone, 'HH:mm')}
        </div>
      )}
      <div className={'relative'}>
        <PriceRow
          hasOwner={props.hasOwner}
          text={t('pricing.basePrice')}
          price={priceToLocal(baseItemUnitsTotal, props.currency_code, true)}
          icon={props.disableAdjustment ? undefined : faPen}
          onPressIcon={() => setShowOverride(true)}
        />
        {showOverride && (
          <Override
            className={props.isLastItem ? '-bottom-[50px] right-4' : ''}
            defaultValue={adjustedPrice?.details?.new_price || baseItem?.units_total}
            onApply={applyAdjustmentStartPrice}
            onHide={() => setShowOverride(false)}
          />
        )}
      </div>
      {adjustedPrice && adjustedPriceAmount && (
        <PriceRow
          text={t('sentences.priceAdjustment')}
          loading={loadingAdjustment}
          price={adjustedPriceAmount}
          icon={props.disableAdjustment ? undefined : faCircleMinus}
          onPressIcon={() => removeAdjustment(baseItem.id, adjustedPrice.id)}
        />
      )}
      {isTeeTimeWithPPP &&
        (isPaidOrConfirmedBookingModified ||
          (extraItems.length > 0 && extraItemsUnitsTotal > 0)) && (
          <PriceRow
            textClassName={`${isPaidOrConfirmedBookingModified && 'font-bold text-blue-500'}`}
            text={t('extraPlayer.extraPlayers', {
              count: extraItems.length,
            })}
            price={priceToLocal(extraItemsUnitsTotal, props.currency_code, true)}
          />
        )}
      {adjustmentsList?.map((adjustment) => {
        if (adjustment?.type === 'order_item_new_price_adjustment') return null
        return (
          <PriceRow
            key={adjustment.id}
            text={adjustment.label || ''}
            price={priceToLocal(adjustment.amount, props.currency_code, true)}
          />
        )
      })}
      <TotalRow
        textSize="text-sm pl-2 pr-1"
        text={t('words.subtotal')}
        price={priceToLocal(baseItemTotal + extraItemsTotal, props.currency_code, true)}
      />
    </div>
  )
}
