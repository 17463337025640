import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const DropdownBox = React.forwardRef(
  ({ dropdownOpen, options, onOptionClick, className, children }, ref) => {
    const { t } = useTranslation()

    return (
      <div
        className={cx(styles.dropdownContainer, className, dropdownOpen && styles.active)}
        ref={ref}
      >
        {children || null}
        {options &&
          options?.length >= 0 &&
          options.map((item) => {
            const Icon = item?.icon
            const IconDisabled = item?.iconDisabled
            return (
              <div
                className={cx(styles.optionRow, item?.disabled ? styles.disabled : '')}
                key={item.id || item.label}
                onClick={() => !item?.disabled && onOptionClick(item)}
              >
                {IconDisabled && item?.disabled && <IconDisabled className={cx(styles.icon)} />}
                {Icon && !item?.disabled && <Icon className={cx(styles.icon)} />}
                <p className={cx(styles.optionLabel)}>{t(item.label)}</p>
              </div>
            )
          })}
      </div>
    )
  }
)

DropdownBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      iconDisabled: PropTypes.elementType,
      disabled: PropTypes.bool,
      id: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  dropdownOpen: PropTypes.bool,
  onOptionClick: PropTypes.func,
  className: PropTypes.string,
}

DropdownBox.defaultProps = {
  options: [],
  dropdownOpen: false,
  onOptionClick: () => {},
  className: '',
}

export default DropdownBox
