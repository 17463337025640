import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

/**
 * `usePlayerCardNavigationLogic` is a custom hook responsible for managing the navigation
 * logic specific to the PlayerCard. It provides methods that interact with the browser's
 * history and the current location to control the navigation of the PlayerCard.
 */
const usePlayerCardNavigationLogic = (isPlayerCardUpdated: boolean, onClose?: VoidFunction) => {
  // Hooks to access the browser's history and the current location within the app
  const history = useHistory()
  const location = useLocation()

  /**
   * The `handleClose` function determines the navigation route based on the current
   * pathname and whether the player card was updated. After deciding the path,
   * it redirects the user to the appropriate location.
   */
  const handleClose = useCallback(() => {
    if (onClose) {
      return onClose()
    }

    const temp: {
      pathname: string
      state?: { shouldReload: boolean }
    } = {
      pathname: '/players',
    }
    if (location.pathname.includes('no-show')) {
      temp.pathname = '/no-show'
    }
    if (isPlayerCardUpdated) temp.state = { shouldReload: true }
    history.push(temp)
  }, [history, isPlayerCardUpdated, location.pathname, onClose])

  return { handleClose }
}

export default usePlayerCardNavigationLogic
