import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import { hexToRgba } from '@sweetspot/sweetspot-js/common/functions/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faCircleMinus, faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { faPlusCircle as faLightPlusCircle } from '@fortawesome/pro-light-svg-icons'

const SelectableTeeTime = ({
  isSelected,
  isNextSelected,
  isPreviousSelected,
  isSuggested,
  isFirstInSelectedGroup,
  isPreviousSuggested,
  isNextSuggested,
  height,
  teeTime,
  onSelect,
  onDeselect,
  isInBooking,
  isDisabled,
  isHovered,
  setHoveredTeeTime,
  showTopBorder,
  isPossiblyDeselected,
}) => {
  const isPossiblySelect = useMemo(
    () => !isSelected && (isSuggested || isPreviousSelected || isNextSelected),
    [isNextSelected, isPreviousSelected, isSelected, isSuggested]
  )
  const handleOnHover = () => {
    setHoveredTeeTime(teeTime)
  }

  const handleOnLeave = () => {
    setHoveredTeeTime(null)
  }

  const showDashedBorder =
    !isInBooking && (isHovered || isSelected || isPossiblySelect || isFirstInSelectedGroup)

  const [backgroundOpacity, backgroundHoverOpacity] = useMemo(() => {
    if (isPossiblySelect) {
      return [0.15, 0.3]
    }

    if (isSelected) {
      return [0.6, 0.3]
    }

    if (isInBooking) {
      return [1, 0.9]
    }

    return [0.4, 0.3]
  }, [isInBooking, isPossiblySelect, isSelected])

  const [teeTimeIcon, teeTimeIconColor] = useMemo(() => {
    if (isSelected) {
      if (isHovered || isPossiblyDeselected) {
        return [faCircleMinus, 'dark']
      }

      return [faCircleCheck, 'dark']
    }
    if (!isDisabled) {
      if (isHovered) {
        return [faPlusCircle, 'dark']
      }
      if (isPossiblySelect) {
        return [faLightPlusCircle, 'subtle']
      }
    }

    return []
  }, [isDisabled, isHovered, isPossiblyDeselected, isPossiblySelect, isSelected])

  return (
    <div
      className={cx(styles.selectableTeeTime, {
        [styles.selected]: isSelected,
        [styles.topSelected]: isFirstInSelectedGroup,
        [styles.previousSelected]: isPreviousSelected || isPreviousSuggested,
        [styles.nextSelected]: isNextSelected || isNextSuggested,
        [styles.suggested]: isSuggested,
        [styles.isInBooking]: isInBooking,
        [styles.disabled]: isDisabled,
        [styles.borderTop]: showTopBorder && !showDashedBorder,
        'border-base-black border border-dashed': showDashedBorder,
      })}
      style={{
        height: height,
        backgroundColor: `rgba(${hexToRgba(
          teeTime.category.color,
          isHovered ? backgroundHoverOpacity : backgroundOpacity
        )})`,
      }}
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnLeave}
      onClick={() => (isSelected ? onDeselect(teeTime) : onSelect(teeTime))}
    >
      <div className={cx(styles.plusSignContainer, `opacity-${teeTimeIcon ? 100 : 0}`)}>
        {teeTimeIcon && (
          <FontAwesomeIcon
            icon={teeTimeIcon}
            className={`text-text-${teeTimeIconColor} scale-150`}
          />
        )}
      </div>
    </div>
  )
}

SelectableTeeTime.propTypes = {
  isSelected: PropTypes.bool,
  isNextSelected: PropTypes.bool,
  isPreviousSelected: PropTypes.bool,
  isSuggested: PropTypes.bool,
  isFirstInSelectedGroup: PropTypes.bool,
  height: PropTypes.number,
  teeTime: PropTypes.object,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  isInBooking: PropTypes.bool,
}

SelectableTeeTime.defaultProps = {}

export default SelectableTeeTime
