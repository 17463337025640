import { API_ENV_KEYS, APP_NAMES, STORAGE_KEYS } from '@sweetspot/shared/util/constants'
import { getLocalStorage } from '@sweetspot/shared/util/local-storage'

export const getApiEnv = (): API_ENV_KEYS | undefined => {
  return process.env?.REACT_APP_API_ENV as API_ENV_KEYS | undefined
}

export const apiEnvIsProdOrSandbx = () => {
  const ENV = getApiEnv()
  if (!ENV) return false
  return [API_ENV_KEYS.PROD, API_ENV_KEYS.SANDBOX].includes(ENV)
}

export const getApiPlatformBaseUrlRaw = (): string | undefined => {
  return process.env?.REACT_APP_API_PLATFORM_URL as string | undefined
}

export const getApiPlatformBaseUrl = (): string | undefined => {
  const API_URL = getApiPlatformBaseUrlRaw()

  if (apiEnvIsProdOrSandbx()) return API_URL
  return getLocalStorage(STORAGE_KEYS.API_PLATFORM_URL_KEY) || API_URL
}

export const getCurrentAppName = (): APP_NAMES | undefined => {
  return process.env?.REACT_APP_APP_NAME as APP_NAMES | undefined
}

export const clearBoundaryDoubleQuotes = (str: string): string => {
  return str.replace(/^"|"$/g, '')
}

export const getNodeEnv = (): string | undefined => {
  return process.env?.NODE_ENV
}

/**
 * @description ### Returns Go / Lua like responses(data, err)
 * when used with await
 *
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.all([req1, req2, req3])
 * - Example response [ [data1, data2, data3], undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.race([req1, req2, req3])
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 * @param {Promise} promise
 * @returns {Promise} [ data, undefined ]
 * @returns {Promise} [ undefined, Error ]
 */

export const to = <T, U = Error>(promise: Promise<T>): Promise<[T, null] | [null, U]> => {
  return promise
    .then<[T, null]>((data: T) => [data, null])
    .catch<[null, U]>((err: U) => Promise.resolve([null, err]))
}

export const sleep = (delay: number) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(() => resolve(true), delay))
}

export const isJson = (string: string) => {
  try {
    JSON.parse(string)
  } catch (error) {
    return false
  }
  return true
}
