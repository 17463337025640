import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withToastManager } from 'react-toast-notifications'
import _ from 'lodash'
import moment from 'moment'
import { track } from '@amplitude/analytics-browser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import TeesheetHelpers from '@sweetspot/club-portal-legacy/helpers/TeesheetHelpers'
import {
  isBookingWaitingForPayment,
  isBookingPartiallyPaid,
  isBookingCancelled,
  isCartItem,
  isSlotItem,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'

import GolfCourseSelect from '@sweetspot/club-portal-legacy/components/GolfCourseSelect'
import Container from '@sweetspot/club-portal-legacy/components/Container'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import XPanel from '@sweetspot/club-portal-legacy/components/XPanel'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'
import FilterSideMenu from './components/FilterSideMenu'

import EditSideMenu from './components/EditSideMenu'

import BookingModal from '@sweetspot/club-portal-legacy/modals/BookingModal'
import BookingSidePanel from '@sweetspot/club-portal-legacy/components/BookingSidePanel'
import EditTeeTime from '../../components/EditTeeTime'

import TimeTable from './components/TimeTable'
import { SearchBookings } from './components/SearchBookings'
import LegendPopup from './components/LegendPopup'
import style from './style.module.scss'

import {
  getTeeTimesForDayFromGolfCourse,
  selectGolfCourse,
  getBookings,
  addPeriodOverride,
  getPartnerTypes,
  getBookingPeriodsByGolfcourse,
} from '@sweetspot/club-portal-legacy/store/actions'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { getFromAndAfterDateForOneDay } from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { getTeeTimesCollection } from '@sweetspot/sweetspot-js/features/teeTimes/js/getTeeTimesCollection'
import getMultipleBookingsWithRelations from '@sweetspot/sweetspot-js/features/bookings/functions/getMultipleBookingsWithRelations'
import { getTranslatedTeeTimeCategories } from '@sweetspot/sweetspot-js/features/teeTimeCategories/js/getTranslatedTeeTimeCategories'
import {
  BOOKING_RELATIONS,
  BOOKING_GROUPS,
} from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { ITEM_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/itemRelations'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import SheetHeaderDateSelect from '@sweetspot/sweetspot-js/common/components/SheetHeaderDateSelect/SheetHeaderDateSelect'

const MEMBER = 'member'
const GUEST = 'guest'
const PARTNER = 'partner'
const RESERVED = 'reserved'
const ANON = 'anonymous'
const defaultFilters = {
  teeTimeType: [],
  slots: [0],
  sex: [],
  playerType: JSON.parse(localStorage.getItem('symbol_setting')) || [
    MEMBER,
    PARTNER,
    GUEST,
    RESERVED,
    ANON,
  ],
}

class TeeSheet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: new Date(),
      teeTimes: [],
      interval: null,
      isLoading: true,
      selectedTeeTimes: [],
      filteredTeeTimes: [],
      showPopup: false,
      preselectedBookingUuid: null,
      showBookingOverview: false,
      editMode: false,
      shiftPressed: false,
      arrowKeyDown: false,
      overrides: [],
      partnerTypes: [],
      scrollToLeft: 0,
      noteIsOpen: false,
      filters: defaultFilters,
      isLegendOpen: false,
      targetTeeTime: null,
      selectedBPIds: [], // in a booking overview component
      reserveSlots: 0,
      teeTimeCategories: [],
    }

    this.fetchTeeTimes = this.fetchTeeTimes.bind(this)
    this.changeTeeTime = this.changeTeeTime.bind(this)
    this.getPartnerTypes = this.getPartnerTypes.bind(this)
    this.setDate = this.setDate.bind(this)
    this.onTodayClick = this.onTodayClick.bind(this)
    this.handleTeeTimeClick = this.handleTeeTimeClick.bind(this)
    this.handleTeeTimeDoubleClick = this.handleTeeTimeDoubleClick.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.showPopup = this.showPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.closeBookingOverView = this.closeBookingOverView.bind(this)
    this.setNoteIsOpen = this.setNoteIsOpen.bind(this)
    this.setFilters = this.setFilters.bind(this)
    this.toggleLegendPopup = this.toggleLegendPopup.bind(this)
    this.setSlotFilter = this.setSlotFilter.bind(this)
    this.formatCurrentDate = this.formatCurrentDate.bind(this)
  }

  componentDidMount() {
    const { golfCourses, selectGolfCourse, periods, currentCourse } = this.props

    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    document.addEventListener('keypress', this.handleKeyDown)
    this.getTeeTimeCategories()
    this.interval = setInterval(() => this.refreshTeeSheet(), 30000)
    track(AMPLITUDE_EVENTS.TEE_SHEET_COURSES_SEEN)

    if (
      [
        COURSE_TYPES.DRIVING_RANGE.value,
        COURSE_TYPES.SIMULATOR.value,
        COURSE_TYPES.PRO.value,
        COURSE_TYPES.OTHER.value,
      ].includes(currentCourse?.type)
    ) {
      const firstCourse = golfCourses.find((x) => x?.type === COURSE_TYPES.COURSE.value)
      if (firstCourse) {
        selectGolfCourse(firstCourse.id)
        return
      }
    } else if (!periods?.length && currentCourse) {
      selectGolfCourse(currentCourse.id)
      this.fetchTeeTimes()
      return
    }

    this.fetchTeeTimes()
  }

  componentDidUpdate(prevProps, prevState) {
    const { date, selectedTeeTimes } = this.state
    const { currentCourse, golfClubs } = this.props

    if (prevProps.currentCourse !== currentCourse || prevState.date !== date) {
      this.setState({
        showBookingOverview: false,
        scrollToLeft: 0,
        overrides: [],
        filters: defaultFilters,
        FilterSideMenu,
      })
      this.fetchTeeTimes()
    }
    if (!_.isEqual(prevState.selectedTeeTimes[0], selectedTeeTimes[0])) {
      this.setState({ targetTeeTime: null })
    }
    if (prevProps.golfClubs?.selectedId !== golfClubs.selectedId) {
      this.getTeeTimeCategories()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)

    clearInterval(this.interval)
  }

  handleKeyDown(event) {
    const {
      arrowKeyDown,
      noteIsOpen,
      shiftPressed,
      editMode,
      teeTimes,
      showPopup,
      isLegendOpen,
      selectedTeeTimes,
      filters,
    } = this.state
    const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft']
    const key = event.key

    if (key === 'Escape' && (this.state.showBookingOverview || this.state.editMode)) {
      this.setState({ showBookingOverview: false, selectedTeeTimes: [], editMode: false })
      this.setSlotFilter()
    } else if (key === 'Escape' && isLegendOpen) {
      this.setState({ isLegendOpen: false })
    } else if (key === 'Shift') {
      this.setState({ shiftPressed: true })
    } else if (arrowKeys.includes(key)) {
      if (
        !arrowKeyDown &&
        !noteIsOpen &&
        !showPopup &&
        event.target.localName !== 'input' &&
        event.target.localName !== 'textarea'
      ) {
        event.preventDefault()
        this.changeTeeTime(key)
      }
    } else if (shiftPressed && key === 'R') {
      this.refreshTeeSheet()
    } else if (shiftPressed && editMode && key === 'A') {
      this.setState({ selectedTeeTimes: teeTimes })
    } else if (event.altKey && event.code.includes('Digit') && selectedTeeTimes?.length === 1) {
      this.setState({
        showPopup: true,
        showBookingOverview: false,
        reserveSlots: parseInt(event.code[5]),
      })
    }
    if (
      key === 'Enter' &&
      !editMode &&
      selectedTeeTimes?.length === 1 &&
      filters?.slots?.length === 1
    ) {
      this.handleTeeTimeDoubleClick(selectedTeeTimes[0])
    }
  }

  handleKeyUp(event) {
    const key = event.key
    const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft']

    if (key === 'Shift') {
      this.setState({ shiftPressed: false })
    } else if (arrowKeys.includes(key)) {
      this.setState({ arrowKeyDown: false })
    }
  }

  changeTeeTime(key) {
    const { selectedTeeTimes, teeTimes, editMode, showPopup, interval } = this.state
    if (!selectedTeeTimes || editMode || showPopup) return
    this.setState({ arrowKeyDown: true })

    const currentIndex = teeTimes.indexOf(selectedTeeTimes[0])
    let newIndex
    let left
    let newSelectedTeeTime
    const horizontalJump = interval === 8 ? 8 : 6
    switch (key) {
      case 'ArrowDown':
        newIndex = currentIndex + 1
        break
      case 'ArrowUp':
        newIndex = currentIndex - 1
        break
      case 'ArrowRight':
        newIndex = currentIndex + horizontalJump
        left = this.state.scrollToLeft + 150
        if (this.timeTableRef) {
          this.timeTableRef.scroll({
            left,
            behavior: 'smooth',
          })
        }
        this.setState({ scrollToLeft: left })
        break
      case 'ArrowLeft':
        newIndex = currentIndex - horizontalJump
        left = this.state.scrollToLeft - 150
        if (this.timeTableRef) {
          this.timeTableRef.scroll({
            left,
            behavior: 'smooth',
          })
        }
        this.setState({ scrollToLeft: left })
        break
      default:
        break
    }

    if (newIndex >= 0 && newIndex <= teeTimes.length - 1) {
      newSelectedTeeTime = teeTimes[newIndex]
      this.setState({ selectedTeeTimes: [newSelectedTeeTime], showBookingOverview: true })
    }
  }

  showPopup(preselectedBookingUuid) {
    this.setState({
      preselectedBookingUuid: preselectedBookingUuid || null,
      showPopup: true,
      showBookingOverview: false,
    })
  }

  closePopup() {
    this.fetchTeeTimes()
    this.setState({
      showPopup: false,
      showBookingOverview: false,
      scrollToLeft: 0,
      noteIsOpen: false,
      preselectedBookingUuid: null,
      reserveSlots: 0,
    })
  }

  closeBookingOverView() {
    this.setState({
      showBookingOverview: false,
      selectedTeeTimes: [],
      scrollToLeft: 0,
      noteIsOpen: false,
    })
    this.setSlotFilter()
    this.fetchTeeTimes()
  }

  refreshTeeSheet() {
    if (!this.state.showPopup && !this.state.editMode && !this.state.showBookingOverview) {
      this.fetchTeeTimes()
    }
  }

  fetchTeeTimes(cb) {
    const { date } = this.state
    const { selectedCourseID, currentCourse } = this.props

    if (!selectedCourseID || !currentCourse) return
    this.setState({
      isLoading: true,
      showBookingOverview: false,
      scrollToLeft: 0,
      selectedTeeTimes: [],
      showPopup: false,
      noteIsOpen: false,
    })

    const { afterDate, beforeDate } = getFromAndAfterDateForOneDay(
      date,
      currentCourse.timezone ?? 'Europe/Stockholm'
    )

    const promises = [
      getTeeTimesCollection({
        courseUuid: currentCourse.uuid,
        startDate: afterDate,
        endDate: beforeDate,
        is_use_dynamic_pricing: currentCourse.is_use_dynamic_pricing,
      }),
      getMultipleBookingsWithRelations({
        courseUuid: currentCourse.uuid,
        startDate: afterDate,
        endDate: beforeDate,
        includedRelations: [BOOKING_RELATIONS.ITEMS],
        includedBookingGroups: [BOOKING_GROUPS.LEGACY_BOOKING, BOOKING_GROUPS.PARTNERSHIP],
        includedItemGroups: [ITEM_GROUPS.SLOT, ITEM_GROUPS.PRODUCT_VARIANT],
      }),
    ]

    const matchesTeeTime = (booking, teeTime) => {
      const teeTimeTime = new Date(teeTime.from)
      return new Date(booking.booking.start_time).getTime() === teeTimeTime.getTime()
    }

    Promise.all(promises)
      .then(([teeTimes, bookings]) => {
        const cancelledOrderBookings = bookings.filter((booking) => isBookingCancelled(booking))
        const activeBookings = bookings.filter((booking) => !isBookingCancelled(booking))
        const unPaidBookings = activeBookings.filter(
          (booking) => isBookingWaitingForPayment(booking) || isBookingPartiallyPaid(booking)
        )

        const newTeeTimes = teeTimes.map((teeTime) => {
          const teeTimeActiveBookings = activeBookings.filter((booking) =>
            matchesTeeTime(booking, teeTime)
          )
          const teeTimeCancelledOrderBookings = cancelledOrderBookings.filter((booking) =>
            matchesTeeTime(booking, teeTime)
          )
          const isUnpaid = !!unPaidBookings.find((booking) => matchesTeeTime(booking, teeTime))

          let items = []
          teeTimeActiveBookings.forEach((booking) => {
            items = [
              ...items,
              ...(booking?.partnership
                ? booking.items.map((item) => ({ ...item, partnership: booking.partnership }))
                : booking.items),
            ]
          })

          return {
            ...teeTime,
            isUnpaid: isUnpaid,
            orderBookings: teeTimeActiveBookings,
            cancelledOrderBookings: teeTimeCancelledOrderBookings,
            orderItems: items.filter((item) => isSlotItem(item)),
            numberOfGC: items.filter((item) => isCartItem(item))?.length || 0,
          }
        })

        this.setState(
          {
            isLoading: false,
            teeTimes: newTeeTimes,
            interval: teeTimes[0].interval,
          },
          () => {
            /**
             * Using a callback after `setState` ensures that `filterTeeTimes` is called only after the state has been successfully updated.
             * This is crucial because `setState` is asynchronous, and directly calling a method after `setState` might lead to the method
             * using an outdated state. By placing `filterTeeTimes` inside the callback, we guarantee it uses the most recent state values.
             */
            this.setState(
              {
                isLoading: false,
                teeTimes: newTeeTimes,
                interval: teeTimes[0].interval,
              },
              this.filterTeeTimes
            )
          }
        )
        if (cb) cb(newTeeTimes)
      })
      .catch(() => {
        this.setState({ isLoading: false, teeTimes: [] })
      })

    this.getPartnerTypes()
  }

  getTeeTimeCategories = () => {
    const { golfClubs } = this.props

    getTranslatedTeeTimeCategories({
      clubId: golfClubs.selectedId,
      locale: i18next.language,
      allPages: true,
    })
      .then((res) => {
        this.setState({ teeTimeCategories: res })
      })
      .catch(() => {
        this.setState({ teeTimeCategories: [] })
      })
  }

  getPartnerTypes() {
    const { token, golfClubs, getPartnerTypes } = this.props
    getPartnerTypes(token, golfClubs.selectedId)
      .then((partnerTypes) => {
        this.setState({ partnerTypes: _.orderBy(partnerTypes, ['name'], ['asc']) })
      })
      .catch(() => {
        this.setState({ partnerTypes: [] })
      })
  }

  setDate(date) {
    this.setState({
      date: date,
      selectedTeeTimes: [],
      showBookingOverview: false,
      scrollToLeft: 0,
      editMode: false,
      overrides: [],
      noteIsOpen: false,
      filters: defaultFilters,
    })
  }

  formatCurrentDate(date) {
    let formatedDate = moment(date).format('dddd, D MMM')
    const today = moment()
    const tomorrow = today.clone().add(1, 'day')
    if (moment(date).isSame(today, 'day')) {
      formatedDate = `${i18next.t('dateTime.words.today')}, ${moment(date).format('D MMM')}`
    } else if (moment(date).isSame(tomorrow, 'day')) {
      formatedDate = `${i18next.t('dateTime.words.tomorrow')}, ${moment(date).format('D MMM')}`
    }
    return formatedDate
  }

  onTodayClick = () => {
    track(AMPLITUDE_EVENTS.TODAY_BUTTON_TAPPED)
  }

  /**
   * Runs when user clicks on Edit button
   * Opens side menu for editing tee times and enables edit mode
   */
  toggleEditTeeTime = () => {
    track(AMPLITUDE_EVENTS.EDIT_TEE_TIMES_TAPPED)

    this.setState({
      editMode: !this.state.editMode,
      showBookingOverview: false,
      scrollToLeft: 0,
      selectedTeeTimes: [],
      overrides: [],
    })
  }

  toggleLegendPopup() {
    this.setState({ isLegendOpen: !this.state.isLegendOpen })
  }

  setNoteIsOpen(open) {
    this.setState({ noteIsOpen: open })
  }

  handleEditCancel = () => {
    this.setState({
      editMode: !this.state.editMode,
      selectedTeeTimes: [],
      overrides: [],
    })
    if (this.state.showFilterSideMenu) {
      this.setState({ showFilterSideMenu: false })
    }
    this.fetchTeeTimes()
  }

  handleEditClear = () => {
    this.setState({ selectedTeeTimes: [] })
  }

  handleEditSave = (payload) => {
    const { selectedTeeTimes, showFilterSideMenu, editMode, date } = this.state
    const { token, periods, addPeriodOverride } = this.props
    this.setState({ isLoading: true })

    const tempOverrides = selectedTeeTimes.map((teeTime) => ({
      from: teeTime.from,
      to: teeTime.to,
      start_date: teeTime.from,
      end_date: teeTime.to,
      status: payload.status,
      interval: teeTime.interval,
      slots: teeTime.available_slots,
      is_golf_id_required: teeTime.is_golf_id_required,
    }))
    const interval = selectedTeeTimes[0].interval
    const formattedOverrides = TeesheetHelpers.formatOverrides([], tempOverrides, interval)
    if (showFilterSideMenu) {
      this.setState({ showFilterSideMenu: false })
    }
    const ISODate = DateHelpers.toISODateTime(date)
    const activePeriod = periods.find(
      (period) => period.start_date <= ISODate && period.end_date >= ISODate
    )

    if (activePeriod) {
      const promises = formattedOverrides.map((override) => ({
        repeat_on: 'every_day',
        start_date: DateHelpers.onlyDateString(date),
        end_date: DateHelpers.onlyDateString(date),
        start_time_from: moment
          .utc(override.from)
          .tz(this.props.currentCourse.timezone)
          .format('HH:mm:ss'),
        start_time_to: moment
          .utc(override.to)
          .tz(this.props.currentCourse.timezone)
          .format('HH:mm:ss'),
        category_id: payload?.status,
        slots: payload?.slots,
        is_golf_id_required: payload?.is_golf_id_required,
        is_prime_time: payload.is_prime_time,
      }))

      promises
        .reduce((previousPromise, nextItem) => {
          return previousPromise.then(() => {
            return addPeriodOverride(token, activePeriod.uuid, nextItem)
          })
        }, Promise.resolve())
        .then(() => {
          this.setState({
            editMode: !editMode,
            selectedTeeTimes: [],
          })
          setTimeout(() => {
            this.setState({ isLoading: false }, () => this.fetchTeeTimes())
          }, 1000)
        })
        .catch(() => this.setState({ isLoading: false }))
    }
  }

  /**
   * Runs when user clicks on a tee time
   * If user is in Edit mode it selects the tee time,
   * and if shift is pressed on click it selects all tee times
   * between first tee time and last tee time amongst selected tee times
   * If user is not in editmode it opens the booking overview for that tee time
   */
  handleTeeTimeClick(teeTime, panelGroupIndex) {
    const {
      editMode,
      teeTimes,
      shiftPressed,
      showPopup,
      isLoading,
      showBookingOverview,
      selectedBPIds,
    } = this.state
    const { currentCourse } = this.props
    let selectedTeeTimes = [...this.state.selectedTeeTimes]

    if (editMode) {
      track(AMPLITUDE_EVENTS.TEE_TIME_TAPPED)

      const teeTimeIndex = selectedTeeTimes.indexOf(teeTime)

      // Check if shift key is pressed and that more then one teetime is selected
      if (shiftPressed && selectedTeeTimes.length > 0) {
        const lastSelected = selectedTeeTimes[selectedTeeTimes.length - 1]
        const firstTeeTime = lastSelected.from > teeTime.from ? teeTime.from : lastSelected.from
        const lastTeeTime = lastSelected.from < teeTime.from ? teeTime.from : lastSelected.from

        let filteredTeeTimes = teeTimes.filter((tee) => {
          return tee.from >= firstTeeTime && tee.from <= lastTeeTime
        })

        selectedTeeTimes = [...selectedTeeTimes, ...filteredTeeTimes]
        selectedTeeTimes = _.uniqBy(selectedTeeTimes, (item) => item.from)
        this.setState({
          selectedTeeTimes,
        })
      } else {
        // Check that Tee Time is not already a selected tee time
        if (teeTimeIndex === -1) {
          selectedTeeTimes.push(teeTime)
        }
        // If it is, remove it from selected Tee time
        else {
          selectedTeeTimes.splice(teeTimeIndex, 1)
        }
      }

      this.setState({
        selectedTeeTimes,
      })
    } else {
      if (isLoading || showPopup) return
      track(AMPLITUDE_EVENTS.TEE_TIME_TAPPED)

      if (selectedTeeTimes.includes(teeTime)) {
        this.setState(
          { selectedTeeTimes: [], showBookingOverview: false, scrollToLeft: 0 },
          this.setSlotFilter
        )
        this.fetchTeeTimes()
      } else {
        const left = (panelGroupIndex + 1) * 80

        if (showBookingOverview && selectedBPIds && selectedBPIds.length) {
          let { selectedTeeTimes } = this.state

          selectedTeeTimes.splice(1, 1, teeTime)

          this.setState({
            selectedTeeTimes,
            targetTeeTime: teeTime,
          })
        } else {
          this.setState({ selectedTeeTimes: [teeTime] })
        }

        if (!currentCourse.is_use_dynamic_pricing && teeTime.price === null) {
          const { t, toastManager } = this.props
          return toastManager.add(t('sentences.couldNotLoadBooking'), { appearance: 'error' })
        }

        this.setState({
          showBookingOverview: true,
          scrollToLeft: left,
        })
      }
    }
  }

  /**
   * Opens booking modal when user double clicks on a tee time
   */
  async handleTeeTimeDoubleClick(teeTime) {
    const { currentCourse } = this.props
    if (this.state.isLoading) return
    track(AMPLITUDE_EVENTS.TEE_TIME_DOUBLE_TAPPED)

    if (!currentCourse.is_use_dynamic_pricing && teeTime.price === null) {
      const { t, toastManager } = this.props
      return toastManager.add(t('sentences.couldNotLoadBooking'), { appearance: 'error' })
    }

    this.setState({
      showPopup: true,
      showBookingOverview: false,
      scrollToLeft: 0,
      selectedTeeTimes: [teeTime],
    })
  }

  setFilters(filters) {
    this.setState({ filters }, this.filterTeeTimes)
  }

  setSlotFilter(slots = [0], selectedBPIds) {
    this.setState(
      {
        filters: { ...this.state.filters, slots },
        selectedBPIds,
      },
      this.filterTeeTimes
    )
  }

  filterTeeTimes() {
    const { filters, teeTimes } = this.state

    let filteredTeeTimes = teeTimes.filter((teeTime) => {
      return this.filterTeeTime(teeTime, filters)
    })
    this.setState({ filteredTeeTimes })
  }

  filterTeeTime(teeTime, filters) {
    let matchFilterSlots = false
    let matchFilterType = false

    // Filter by slots
    if (!filters.slots || filters.slots.length === 1) {
      matchFilterSlots = true
    } else {
      const sortedFilter = filters?.slots?.sort()
      if (teeTime?.available_slots && teeTime.available_slots >= sortedFilter[1])
        matchFilterSlots = true
    }

    // Filter by tee time type
    if (!filters.teeTimeType || filters.teeTimeType.length === 0) {
      matchFilterType = true
    } else {
      if (filters.teeTimeType.some((filter) => filter === teeTime.status)) matchFilterType = true
    }

    return matchFilterSlots && matchFilterType
  }

  renderGolfCourseSelect() {
    const { golfCourses, selectGolfCourse, currentCourse } = this.props

    return (
      <div className={style.golfcourseSelect}>
        <GolfCourseSelect
          newDesignBtn
          courses={golfCourses.filter(
            (course) => !course?.type || course.type === COURSE_TYPES.COURSE.value
          )}
          selectedCourse={currentCourse}
          onSelectCourse={selectGolfCourse}
        />
      </div>
    )
  }

  renderTimeSelect() {
    return (
      <div className={style.timeSelect}>
        <div className={style.calendarContainer}>
          <SheetHeaderDateSelect
            currentDate={this.state.date}
            handleSetDate={this.setDate}
            onTodayClick={this.onTodayClick}
            timezone={this.props.currentCourse?.timezone}
          />
        </div>
        <div className={style.iconContainer} onClick={() => this.fetchTeeTimes()}>
          <FontAwesomeIcon
            icon={faRefresh}
            size="1x"
            className="flex cursor-pointer items-center justify-center px-3"
          />
        </div>

        <PulseLoader showIf={this.state.isLoading} />
      </div>
    )
  }

  renderHeader() {
    const { isLoading, showBookingOverview, editMode, teeTimes } = this.state
    const { lang, role, currentCourse } = this.props

    const classNames = [style.headerContainer]
    if (showBookingOverview) classNames.push(style.bookingOverviewOpen)
    if (editMode) classNames.push(style.editModeOpen)

    return (
      <div className={classNames.join(' ')}>
        <div className={style.mainHeader}>{this.renderTimeSelect()}</div>
        <div className={cx(style.searchBookings, 'flex justify-end')}>
          <SearchBookings
            lang={lang}
            teeTimes={teeTimes}
            currentDate={this.state.date}
            selectedGolfCourse={currentCourse}
            handleSearchResultClick={this.handleTeeTimeDoubleClick}
          />
        </div>
        <div className={style.headerButton}>
          {hasAccess(ACCESS_KEYS.PAGES.TEE_SHEET.EDIT_TEE_TIMES, role?.value) && (
            <button
              className="system-button info-outline md-32"
              onClick={() => this.toggleEditTeeTime()}
              active={editMode}
              disabled={isLoading || showBookingOverview}
            >
              {i18next.t('edit')}
            </button>
          )}
        </div>
      </div>
    )
  }

  renderTeeTimeBookingModal = () => {
    const { showPopup, selectedTeeTimes, preselectedBookingUuid, reserveSlots, teeTimeCategories } =
      this.state

    if (showPopup) {
      return (
        <div className={style.bookingModalContainer}>
          <BookingModal
            teeTime={
              preselectedBookingUuid
                ? {
                    ...selectedTeeTimes[0],
                    orderBookings: [
                      ...selectedTeeTimes[0].orderBookings,
                      { uuid: preselectedBookingUuid },
                    ],
                  }
                : selectedTeeTimes[0]
            }
            teeTimeCategories={teeTimeCategories}
            onClose={this.closePopup}
            reserveSlots={reserveSlots}
            type="clubPortal"
            EditTeeTimeComponent={EditTeeTime}
          />
        </div>
      )
    }
  }

  renderLoader() {
    return (
      <div className="flex items-center justify-center">
        <SpinnerLoader text={i18next.t('sentences.loadingTeeTimes')} />
      </div>
    )
  }

  renderTimeTable() {
    const {
      teeTimes,
      filteredTeeTimes,
      editMode,
      selectedTeeTimes,
      overrides,
      filters,
      isLoading,
    } = this.state
    const { currentCourse } = this.props

    // Show loading only on first fetch
    if (isLoading && teeTimes?.length === 0) return this.renderLoader()
    return (
      <div
        className={style.timeTableContainer}
        ref={(timeTableRef) => (this.timeTableRef = timeTableRef)}
      >
        {teeTimes.length > 0 && (
          <TimeTable
            teeTimes={teeTimes}
            timezone={currentCourse?.timezone ?? 'Europe/Stockholm'}
            filteredTeeTimes={filteredTeeTimes}
            onClick={this.handleTeeTimeClick}
            onDoubleClick={this.handleTeeTimeDoubleClick}
            editMode={editMode}
            selectedTeeTimes={selectedTeeTimes}
            overrides={overrides}
            playerTypeFilter={filters.playerType}
            activeFilters={Object.values(filters).some((filter) => filter.length > 0)}
            course={currentCourse}
          />
        )}
      </div>
    )
  }

  renderRightSideMenu() {
    const {
      editMode,
      isLoading,
      selectedTeeTimes,
      showBookingOverview,
      targetTeeTime,
      teeTimeCategories,
      showPopup,
    } = this.state

    const sideMenuClassName = [style.sideMenu]
    if (editMode) sideMenuClassName.push(style.editMode)
    return (
      <div className={sideMenuClassName.join(' ')}>
        {editMode && (
          <EditSideMenu
            isLoading={isLoading}
            teeTimeCategories={teeTimeCategories}
            handleEditCancel={this.handleEditCancel}
            handleEditSave={this.handleEditSave}
            handleEditClear={this.handleEditClear}
            disabledButtons={!selectedTeeTimes.length}
            course={this.props.currentCourse}
          />
        )}
        {showBookingOverview && !showPopup && (
          <BookingSidePanel
            teeTime={selectedTeeTimes[0]}
            onClose={this.closeBookingOverView}
            visible={showBookingOverview}
            requestOpenBookingModal={(bookingUuid) => this.showPopup(bookingUuid)}
            onRequestInitiateBookingMove={(slotsFilter) => {
              this.setSlotFilter(slotsFilter, ['random'])
            }}
            onRequestCancelBookingMove={() => {
              this.setState({ selectedTeeTimes: [selectedTeeTimes[0]] })
              this.setSlotFilter()
            }}
            onCompletedMoveBooking={(newTeeTime) => {
              this.fetchTeeTimes((newTeeTimes) => {
                this.setState({
                  selectedTeeTimes: [newTeeTimes.find((x) => x.id === newTeeTime.id)],
                  showBookingOverview: true,
                })
              })
            }}
            targetTeeTime={targetTeeTime}
          />
        )}
      </div>
    )
  }

  renderLeftSideMenu() {
    if (this.state.showPopup) return
    return (
      <div className={style.leftSideMenu}>
        <FilterSideMenu
          filters={this.state.filters}
          setFilters={this.setFilters}
          toggleLegendPopup={this.toggleLegendPopup}
        />
      </div>
    )
  }

  renderLegendPopup() {
    if (this.state.isLegendOpen) {
      return <LegendPopup togglePopup={this.toggleLegendPopup} />
    }
  }

  render() {
    const { golfCourses } = this.props
    return (
      <Container className={style.outerContainer}>
        <div className={style.container}>
          {golfCourses && golfCourses.length > 1 && this.renderGolfCourseSelect()}
          <div className={style.panel}>
            {this.renderLeftSideMenu()}
            <XPanel>
              <div className={style.wrapper}>
                <div className={style.mainContainer}>
                  {this.renderHeader()}
                  <div className={style.table}>
                    {this.renderTimeTable()}
                    {this.renderRightSideMenu()}
                    {this.renderLegendPopup()}
                  </div>
                </div>
              </div>
            </XPanel>
          </div>
        </div>
        {this.renderTeeTimeBookingModal()}
      </Container>
    )
  }
}

TeeSheet.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubs: PropTypes.object.isRequired,
  selectGolfCourse: PropTypes.func.isRequired,
  golfCourses: PropTypes.array.isRequired,
  selectedCourseID: PropTypes.number,
  periods: PropTypes.array.isRequired,
  getTeeTimesForDayFromGolfCourse: PropTypes.func.isRequired,
  getBookings: PropTypes.func.isRequired,
  addPeriodOverride: PropTypes.func.isRequired,
  getPartnerTypes: PropTypes.func.isRequired,
  getBookingPeriodsByGolfcourse: PropTypes.func.isRequired,
  history: PropTypes.object,
  role: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubs: state.golfClub,
    golfCourses: state.golfCourse.list,
    selectedCourseID: state.golfCourse.selectedId,
    periods: state.bookingPeriod.list,
    role: getFirstMatchingRole(state.auth.roles),
    currentCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectGolfCourse: (id) => dispatch(selectGolfCourse(id)),
    getTeeTimesForDayFromGolfCourse: (courseUuid, startIso, endIso) =>
      dispatch(getTeeTimesForDayFromGolfCourse(courseUuid, startIso, endIso)),
    getBookings: (token, query) => dispatch(getBookings(token, query)),
    addPeriodOverride: (token, periodId, override) =>
      dispatch(addPeriodOverride(token, periodId, override)),
    getPartnerTypes: (token, golfClubId) => dispatch(getPartnerTypes(token, golfClubId)),
    getBookingPeriodsByGolfcourse: (token, courseId) =>
      dispatch(getBookingPeriodsByGolfcourse(token, courseId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withToastManager(TeeSheet)))
