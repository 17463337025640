import React, { FC, useState, useCallback } from 'react'
import { useMutation } from 'react-query'

import { Player } from '@sweetspot/shared/types'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { createPlayerNote } from '@sweetspot/shared/data-access/api-platform'
import NoteTextInputField from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/Notes/NoteTextInputField'

type Props = {
  player: Player | null
  onNewNoteAdd: () => void
}

const NewNoteInput: FC<Props> = ({ player, onNewNoteAdd }) => {
  const [loading, setIsLoading] = useState<boolean>(false)

  const { addToast } = useToasts()
  const { t } = useTranslation()

  const { mutateAsync: createPlayerCardMutation, isLoading } = useMutation(
    ({ playerId, note }: { playerId: string; note: string }) => createPlayerNote(note, playerId)
  )

  const handleSubmit = useCallback(
    async (note: string) => {
      if (!note || loading) return

      setIsLoading(true)

      try {
        const data = await createPlayerCardMutation({
          playerId: player?.uuid || '',
          note,
        })

        onNewNoteAdd()
        addToast(t('toast.playerNoteCreateSuccess'), { appearance: 'success' })
        return data // Resolve the promise with the data
      } catch (error) {
        addToast(t('toast.playerNoteCreateError'), { appearance: 'error' })
        throw error // Reject the promise with the error
      } finally {
        setIsLoading(false)
      }
    },
    [addToast, createPlayerCardMutation, loading, onNewNoteAdd, player?.uuid, t]
  )

  return <NoteTextInputField disabled={isLoading} onSubmit={handleSubmit} />
}

export default NewNoteInput
