import { useState, useCallback } from 'react'

const useStatusArray = (initialValue = []) => {
  const [array, setArray] = useState(initialValue || [])

  const remove = useCallback(
    (id) => {
      setArray((prev) => prev.filter((item) => item !== id))
    },
    [setArray]
  )

  const add = useCallback(
    (id) => {
      setArray((prev) => [...prev, id])
    },
    [setArray]
  )

  const reset = useCallback(() => {
    setArray([])
  }, [setArray])

  const toggle = useCallback(
    (id) => {
      if (array.includes(id)) {
        remove(id)
      } else {
        add(id)
      }
    },
    [array, add, remove]
  )

  const hasItem = useCallback(
    (id) => {
      if (array.includes(id)) {
        return true
      }
      return false
    },
    [array]
  )

  return {
    add,
    remove,
    array,
    reset,
    toggle,
    hasItem,
  }
}

export default useStatusArray
