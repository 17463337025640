import {
  _getBookingPeriodsByGolfcourse,
  _createBookingPeriod,
  _deleteBookingPeriod,
  _getPeriodOverrides,
  _addPeriodOverride,
  _deletePeriodOverride,
} from '../../services/bookingPeriodApi'
import { addToast } from './appStateActions'
import { CONSTANTS } from '../constants'

export function getBookingPeriodsByGolfcourse(golfcourseID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getBookingPeriodsByGolfcourse(golfcourseID)
        .then((res) => {
          dispatch({
            type: CONSTANTS.BOOKING_PERIOD.GET_LIST,
            payload: res,
          })
          return resolve(res)
        })
        .catch((err) => {
          dispatch({
            type: CONSTANTS.BOOKING_PERIOD.GET_LIST,
            payload: [],
          })
          return reject(err)
        })
    })
  }
}

export function createBookingPeriod(JWT, golfcourseID, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createBookingPeriod(golfcourseID, payload)
        .then((res) => {
          dispatch(addToast('createPeriodSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          let errMsg = 'createPeriodError'
          if (err?.violations?.length) {
            errMsg = err.violations[0].message
          }
          dispatch(addToast(errMsg, 'error'))
          return reject(err)
        })
    })
  }
}

export function deleteBookingPeriod(JWT, bookingPeriodID, lang) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deleteBookingPeriod(bookingPeriodID, lang)
        .then((res) => {
          dispatch(addToast('deletePeriodSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          let errMsg = 'deletePeriodError'
          if (err?.violations?.length) {
            errMsg = err.violations[0].message
          }
          dispatch(addToast(errMsg, 'error'))
          return reject(err)
        })
    })
  }
}

export function getPeriodOverrides(JWT, periodId, query) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getPeriodOverrides(periodId, query)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('fetchOverridesError', 'error'))
          return reject(err)
        })
    })
  }
}

export function addPeriodOverride(JWT, periodId, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addPeriodOverride(periodId, payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          let errMsg = 'createOverrideError'
          if (err?.violations?.length) {
            errMsg = err.violations[0].message
          }
          dispatch(addToast(errMsg, 'error'))
          return reject(err)
        })
    })
  }
}

export function deletePeriodOverride(JWT, overrideId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deletePeriodOverride(overrideId)
        .then((res) => {
          dispatch(addToast('deleteOverrideSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('deleteOverrideError', 'error'))
          return reject(err)
        })
    })
  }
}
