import { useState } from 'react'
import Routes from './Routes'
import TimePeriodsContext from './context'

const EditPeriodsNew = () => {
  const [globalState, setGlobalState] = useState(TimePeriodsContext._currentValue)
  return (
    <TimePeriodsContext.Provider value={[globalState, setGlobalState]}>
      <Routes />
    </TimePeriodsContext.Provider>
  )
}

export default EditPeriodsNew
