import { useState, useEffect } from 'react'

function useKeyPress() {
  // State for keeping track of whether key is pressed
  const [event, setEvent] = useState(null)

  // If pressed key is our target key then set to true
  function handler(event) {
    setEvent(event)
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', handler)

    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return event
}

export default useKeyPress
