import React from 'react'
import PropTypes from 'prop-types'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'

import { ReactComponent as EmptyImg } from '@sweetspot/club-portal-legacy/resources/images/home_club_icon.svg'
import TRASH_ICON from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import REMOVE_ICON from '@sweetspot/club-portal-legacy/resources/images/clear-icon.svg'

const GolfClubs = ({ clubs, onClubRemove }) => {
  if (!clubs || !clubs.length) {
    return (
      <div className={style.empty}>
        <EmptyImg />
        <Text textId="settings.clubAdmins.noClubs" />
      </div>
    )
  }
  return (
    <div className={style.container}>
      <div className={style.header}>
        <Text textId=".golfclub" />
        <img width="12" src={TRASH_ICON} alt="trash" />
      </div>

      <div>
        {clubs.map((club) => (
          <div key={club.id} className={style.listItem}>
            <div>{club.name}</div>
            <img width="22" src={REMOVE_ICON} alt="minus" onClick={() => onClubRemove(club.id)} />
          </div>
        ))}
      </div>
    </div>
  )
}

GolfClubs.propTypes = {
  clubs: PropTypes.array.isRequired,
  onClubRemove: PropTypes.func.isRequired,
}

export default GolfClubs
