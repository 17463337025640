import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ReactComponent as TrashCan } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import { ReactComponent as InfoSymbol } from '@sweetspot/sweetspot-js/assets/svgs/info-symbol.svg'
import IncludedCourseItem from '../IncludedCourseItem'
import Separator from '../Separator'

import HoverDiv from '@sweetspot/sweetspot-js/common/components/HoverDiv'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

const IncludedCoursesTable = ({ includedCourses, disabled, onDelete, onChangeCourse }) => {
  const { t } = useTranslation()

  const highestTime = Math.max(...includedCourses.map((i) => i.productAvailability?.minutes))

  return (
    <div className={styles.container}>
      <div className={'mb-2 grid w-full grid-cols-[30%,20%,auto,25px] items-center'}>
        <p className={'font-bold'}>{t('course')}</p>
        <p className={'font-bold'}>{t('wizard.rentalTime')}</p>
        <div className={'relative flex items-center justify-start'}>
          <p className={'mb-0 text-base font-bold'}>{t('wizard.preparationTime')}</p>
          <div className={'ml-2'}>
            <HoverDiv
              direction="left"
              hoverText={t('golfCarts.preparationInfo', { minutes: highestTime })}
            >
              <InfoSymbol />
            </HoverDiv>
          </div>
        </div>
        <div className={'flex justify-center'}>
          <TrashCan />
        </div>
      </div>
      <Separator />
      {includedCourses.map((course) => (
        <IncludedCourseItem
          key={course.id}
          disabled={disabled}
          course={course}
          onDelete={onDelete}
          onChange={onChangeCourse}
        />
      ))}
    </div>
  )
}

IncludedCoursesTable.propTypes = {
  includedCourses: PropTypes.array,
  lang: PropTypes.string,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onChangeCourse: PropTypes.func,
}
IncludedCoursesTable.defaultProps = {
  includedCourses: [],
  disabled: false,
  lang: 'en',
  onDelete: () => {},
  onChangeCourse: () => {},
}

export default connect((state) => ({ lang: state.auth.me.lang }))(IncludedCoursesTable)
