import { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  Button,
  cn,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import {
  BaySideBarMode,
  MergedBaysType,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { BayScreenDisplay, TrackingTech } from '@sweetspot/shared/types'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

import Summary from './Summary'
import DetailsForm from './Details/DetailsForm'

const UpdateBayBulk = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const {
    selectedRange,
    refetchRanges,
    openBaySideBar,
    selectedBays,
    toggleBaySideBar,
    reFetchBays,
    setSelectedBays,
    openDiscardDialog,
    setOpenDiscardDialog,
    updateBayBulk,
    checkForErrors,
    mergeBays,
    constructUpdateBayBulkPayload,
  } = useBaysContext()
  const [numberOfBays] = useState<number>(selectedBays?.length || 1)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const mergedBays = useMemo(() => mergeBays(selectedBays), [selectedBays, mergeBays])

  const [floor, setFloor] = useState<MergedBaysType['floor']>(mergedBays.floor)
  const [stance, setStance] = useState<MergedBaysType['stance']>(mergedBays.stance)
  const [roof, setRoof] = useState<MergedBaysType['roof']>(mergedBays.roof)
  const [trackingTech, setTrackingTech] = useState<MergedBaysType['trackingTech']>(
    mergedBays.trackingTech
  )
  const [display, setDisplay] = useState<MergedBaysType['display']>(mergedBays.display)
  const [screenMode, setScreenMode] = useState<MergedBaysType['screenMode']>(mergedBays.screenMode)
  const [siteId, setSiteId] = useState<string>(selectedRange?.toptracer_site_id || '')
  const [trmsNumbers, setTrmsNumbers] = useState<MergedBaysType['trmsNumbers']>({})
  const showDisplay = useMemo(() => {
    if (!trackingTech?.isMixed) {
      return trackingTech?.value === TrackingTech.TOPTRACER
    }
    return selectedBays?.some(
      (bay) => bay.ball_tracking_technology?.tracking_provider === TrackingTech.TOPTRACER
    )
  }, [selectedBays, trackingTech])

  const showScreenMode = useMemo(() => {
    if (!display?.isMixed) {
      return display?.value === BayScreenDisplay.TOPTRACER_SCREEN
    }
    return selectedBays?.some(
      (bay) => bay.ball_tracking_technology?.display === BayScreenDisplay.TOPTRACER_SCREEN
    )
  }, [selectedBays, display])

  // control which trmsNumbers are shown
  useEffect(() => {
    const filteredTrmsNumbers: MergedBaysType['trmsNumbers'] = {}
    selectedBays?.forEach((bay) => {
      const bayDisplay =
        bay.ball_tracking_technology?.tracking_provider === TrackingTech.TOPTRACER
          ? bay.ball_tracking_technology?.display
          : null
      if ([bayDisplay, display?.value].includes(BayScreenDisplay.TOPTRACER_SCREEN)) {
        filteredTrmsNumbers[bay.bay_number] = mergedBays.trmsNumbers?.[bay.bay_number]
      }
    })
    setTrmsNumbers(filteredTrmsNumbers)
  }, [selectedBays, mergedBays.trmsNumbers, display])

  // set tracking provider to toptracer if display is changed to toptracer screen
  useEffect(() => {
    if (!display?.isMixed && display?.value === BayScreenDisplay.TOPTRACER_SCREEN) {
      setTrackingTech({ value: TrackingTech.TOPTRACER, isMixed: false })
    }
  }, [display])

  // set tracking provider to toptracer and display to toptracer screen
  // if screenMode is set to a value
  useEffect(() => {
    if (!screenMode?.isMixed && screenMode?.value) {
      setTrackingTech({ value: TrackingTech.TOPTRACER, isMixed: false })
      setDisplay({ value: BayScreenDisplay.TOPTRACER_SCREEN, isMixed: false })
    }
  }, [screenMode])

  const dirtify = useCallback(<T,>(setter: (value: T) => void, value: T) => {
    setter(value)
    setIsDirty(true)
  }, [])

  const handleClose = useCallback(() => {
    if (isDirty) {
      setOpenDiscardDialog(true)
      return
    }
    setOpenDiscardDialog(false)
    toggleBaySideBar(BaySideBarMode.CREATE)
  }, [setOpenDiscardDialog, toggleBaySideBar, isDirty])

  const handleUpdateBay = useCallback(async () => {
    if (!selectedRange?.id || !selectedBays?.length) {
      return
    }
    const payload = constructUpdateBayBulkPayload?.({
      floor,
      stance,
      roof,
      trackingTech,
      display,
      screenMode,
      trmsNumbers,
      siteId,
    })

    const errorText = checkForErrors?.(payload)
    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else if (payload) {
      setIsLoading(true)
      updateBayBulk?.(payload)
        ?.then(() => {
          setIsLoading(false)
          addToast(t('toast.updateBaySuccess'), { appearance: 'success' })
          setIsDirty(false)
          reFetchBays()
          refetchRanges()
          setSelectedBays([])
          toggleBaySideBar()
        })
        ?.catch(() => {
          setIsLoading(false)
          setIsDirty(false)
        })
    }
  }, [
    selectedRange?.id,
    selectedBays,
    floor,
    stance,
    roof,
    trackingTech,
    trmsNumbers,
    display,
    screenMode,
    reFetchBays,
    setSelectedBays,
    toggleBaySideBar,
    addToast,
    t,
    checkForErrors,
    siteId,
    updateBayBulk,
    refetchRanges,
    constructUpdateBayBulkPayload,
  ])

  return (
    <Sheet open={openBaySideBar} onOpenChange={handleClose}>
      <SheetContent className={cn(isLoading && 'overflow-hidden')}>
        {isLoading && (
          <SpinnerLoader
            className="fixed left-[calc(100%-384px)]"
            text={t('sentences.updatingBay')}
            description={t('sentences.updatingBayDescription')}
            isOverlay
          />
        )}
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={handleClose}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{`${t('edit')} ${numberOfBays} ${t(
            'settings.bays.bays'
          ).toLowerCase()}`}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Summary numberOfBays={numberOfBays} />
          <DetailsForm
            floor={floor?.value}
            isFloorMixed={floor?.isMixed}
            setFloor={(value) =>
              dirtify<MergedBaysType['floor']>(setFloor, { isMixed: false, value })
            }
            stance={stance?.value}
            isStanceMixed={stance?.isMixed}
            setStance={(value) =>
              dirtify<MergedBaysType['stance']>(setStance, { isMixed: false, value })
            }
            roof={roof?.value}
            isRoofMixed={roof?.isMixed}
            setRoof={(value) => dirtify<MergedBaysType['roof']>(setRoof, { isMixed: false, value })}
            trackingTech={trackingTech?.value}
            isTrackingTechMixed={trackingTech?.isMixed}
            setTrackingTech={(value) =>
              dirtify<MergedBaysType['trackingTech']>(setTrackingTech, { isMixed: false, value })
            }
            showDisplay={showDisplay}
            display={display?.value}
            isDisplayMixed={display?.isMixed}
            setDisplay={(value) =>
              dirtify<MergedBaysType['display']>(setDisplay, { isMixed: false, value })
            }
            showScreenMode={showScreenMode}
            screenMode={screenMode?.value}
            isScreenModeMixed={screenMode?.isMixed}
            setScreenMode={(value) =>
              dirtify<MergedBaysType['screenMode']>(setScreenMode, { isMixed: false, value })
            }
            siteId={siteId}
            setSiteId={(siteId) => dirtify<string>(setSiteId, siteId)}
            trmsNumbers={trmsNumbers}
            setTrmsNumbers={(trmsNumber, bayNumber) =>
              dirtify<MergedBaysType['trmsNumbers']>(setTrmsNumbers, {
                ...trmsNumbers,
                [bayNumber]: trmsNumber,
              })
            }
          />
        </SheetCustomContent>

        <SheetFooter>
          <Button
            onClick={handleUpdateBay}
            className="min-h-touch-height-lg w-full"
            disabled={!isDirty}
          >
            {`${t('update')} ${numberOfBays} ${t('settings.bays.bays').toLowerCase()}`}
          </Button>
        </SheetFooter>
      </SheetContent>
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog?.(false)}
        onDiscard={() => {
          setOpenDiscardDialog(false)
          toggleBaySideBar(BaySideBarMode.EDIT)
        }}
        onSave={() => {
          setOpenDiscardDialog(false)
          handleUpdateBay()
        }}
      />
    </Sheet>
  )
}

export default UpdateBayBulk
