import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import { ReactComponent as Plus } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon-2.svg'
import PulseLoader from '../PulseLoader'

const ButtonBasic = ({
  className,
  iconClassName,
  color,
  size,
  text,
  icon,
  disabled,
  onClick,
  loading,
}) => {
  return (
    <button
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e)
        }
      }}
      className={cx(
        styles.buttonBasic,
        {
          [styles.sizeSmall]: size === 'small',
          [styles.sizeMedium]: size === 'medium',
          [styles.sizeLarge]: size === 'large',
        },
        {
          [styles.colorDefault]: color === 'default',
          [styles.colorRed]: color === 'red',
        },
        (disabled || loading) && styles.disabled,
        className
      )}
    >
      {loading ? (
        <PulseLoader dotClassName={cx(styles.loaderDotClass)} showIf={true} />
      ) : (
        <React.Fragment>
          {icon === 'plus' && <Plus className={cx(styles.icon, styles.plus, iconClassName)} />}
          {text}
        </React.Fragment>
      )}
    </button>
  )
}

ButtonBasic.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  color: PropTypes.oneOf(['default', 'red']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  text: PropTypes.string,
  icon: PropTypes.oneOf(['plus']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
}

ButtonBasic.defaultProps = {
  className: '',
  iconClassName: '',
  color: 'default',
  size: 'small',
  text: '',
  icon: null,
  disabled: false,
  onClick: () => {},
  loading: false,
}

export default ButtonBasic
