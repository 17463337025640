import React, { useState, useMemo, useContext, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import {
  archivePromotion,
  createClubPromotion,
  deactivatePromotion,
  activatePromotion,
  updatePromotion,
  createMembershipPromotion,
  getSinglePromotion,
} from '../../services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import PromotionsContext from '../../containers/PromotionsManager/PromotionsContext'

import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { ReactComponent as OptionsIcon } from '@sweetspot/sweetspot-js/assets/svgs/options-icon.svg'
import { ReactComponent as ArchiveIcon } from '@sweetspot/sweetspot-js/assets/svgs/archive-icon.svg'
import { ReactComponent as MoveToTop } from '@sweetspot/sweetspot-js/assets/svgs/move-to-top-gray.svg'
import { ReactComponent as MoveToBottom } from '@sweetspot/sweetspot-js/assets/svgs/move-to-bottom-gray.svg'
import { ReactComponent as MoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/move-icon-gray.svg'

import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import ToggleSwitchControlled from '@sweetspot/sweetspot-js/common/components/ToggleSwitchControlled'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import OptionsMenu from '@sweetspot/sweetspot-js/common/components/OptionsMenu'
import RulesWrapper from '../RulesWrapper'
import ActionsWrapper from '../ActionsWrapper'
import { useToasts } from 'react-toast-notifications'
import IncompatiblePromotions from './IncompatiblePromotions'
import HoverDiv from '@sweetspot/sweetspot-js/common/components/HoverDiv'

const TABS = {
  RULES: 'RULES',
  ACTIONS: 'ACTIONS',
}

const errorTypes = {
  PROMOTION_UNIQUENESS_ERROR: 'PROMOTION_UNIQUENESS_ERROR',
}

const SinglePromotion = ({
  promotion,
  currentIndex,
  onUpdatePromotionsOrder,
  onUpdatePromotionPriority,
  canDrag,
  dragHandle,
}) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { values, functions, actionStates } = useContext(PromotionsContext)

  // States
  const [name, setName] = useState(promotion?.id === 'new' ? '' : promotion.name)
  const [nameError, setNameError] = useState('')
  const prevName = usePrevious(name)

  const [isActive, setIsActive] = useState(
    promotion?.id === 'new' ? false : promotion.status === 'active'
  )
  const [isActiveError, setIsActiveError] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [currentTab, setCurrentTab] = useState(TABS.RULES)
  const [isArchived, setIsArchived] = useState(promotion.status === 'archived' || false)
  const [hasBeenOpened, setHasBeenOpened] = useState(false)

  // Memos
  const isOpen = useMemo(() => {
    return values.openPromotions.includes(promotion.id)
  }, [values.openPromotions, promotion.id])

  const isNewPromotion = useMemo(() => {
    return promotion.id === 'new'
  }, [promotion.id])

  const hasNameChange = useMemo(() => {
    return promotion.name !== name
  }, [promotion.name, name])

  useEffect(() => {
    if (isOpen) {
      setHasBeenOpened(true)
    }
  }, [isOpen])

  // Functions
  const savePromotionName = async () => {
    if (name === prevName) return

    if (!name || name.replace(/\s/g, '')?.length <= 0) {
      setNameError(t('sentences.invalidName'))
      return
    }

    if (isNewPromotion) {
      actionStates.setSavingNewPromotion(true)

      const { context, clubId, membershipId } = values
      let res, err

      if (context === 'club') {
        ;[res, err] = await to(
          createClubPromotion(clubId, {
            name: name,
            priority: 0,
          })
        )
      } else if (context === 'membership') {
        ;[res, err] = await to(createMembershipPromotion(membershipId, { name, priority: 0 }))
      }

      if (
        err &&
        err.violations &&
        err.violations.some(
          (violation) => violation.errorName === errorTypes.PROMOTION_UNIQUENESS_ERROR
        )
      ) {
        actionStates.setSavingNewPromotion(false)
        setNameError(t('sentences.alreadyExistName'))
      } else if (!res || err) {
        actionStates.setSavingNewPromotion(false)
        setNameError(t('sentences.invalidName'))
      } else {
        actionStates.setSavingNewPromotion(false)
        functions.replaceNewPromotion(res)
      }
    } else {
      if (promotion.name !== name) {
        actionStates.setSavingExistingPromotion(true)
        const [res, err] = await to(updatePromotion(promotion.id, { ...promotion, name }))
        if (!res || err) {
          actionStates.setSavingExistingPromotion(false)
          // empty
        } else {
          actionStates.setSavingExistingPromotion(false)
          functions.updatePromotion(res)
        }
      }
    }
  }

  const activatePromotionFn = async () => {
    // On timeout for animation
    setTimeout(() => {
      functions.updatePromotionListWhenActivateDeactivate(promotion.id, 'activate')
    }, 500)

    const [, err] = await to(activatePromotion(promotion.id))

    if (err) {
      setIsActive(false)
      setIsActiveError(t('sentences.couldNotActivatePromotion'))
      setTimeout(() => {
        setIsActiveError(null)
      }, 3000)
    }
  }

  const deactivatePromotionFn = async () => {
    // On timeout for animation
    setTimeout(() => {
      functions.updatePromotionListWhenActivateDeactivate(promotion.id, 'inactivate')
    }, 500)

    const [, err] = await to(deactivatePromotion(promotion.id))

    if (err) {
      setIsActive(true)
      setIsActiveError(t('sentences.couldNotDeactivatePromotion'))
      setTimeout(() => {
        setIsActiveError(null)
      }, 3000)
    }
  }

  const archivePromotionFn = async () => {
    setShowConfirmation(false)
    setIsArchived(true)

    const [res, err] = await to(archivePromotion(promotion.id))

    if (res) {
      functions.archivePromotion(promotion.id)

      const [resNew] = await to(getSinglePromotion(promotion.id))

      if (resNew) {
        functions.updatePromotion(resNew)
      }
    }

    if (err || !res) {
      setIsArchived(false)
      addToast(t('sentences.couldNotArchivePromotion'), { appearance: 'error' })
    }
  }

  return (
    <div className={cx(styles.container)}>
      {isNewPromotion && (
        <div className={cx(styles.header, styles.newHeader)}>
          <p className={cx(styles.headerTitle)}>{t('sentences.newPromotion')}</p>
          <ButtonBasic
            className={cx(styles.deleteButton)}
            text={t('words.delete')}
            onClick={functions.deleteNewPromotion}
            color="red"
          />
        </div>
      )}

      {!isNewPromotion && (
        <div className={cx(styles.header)}>
          <div
            className={cx(styles.leftSide)}
            onClick={() => functions.toggleOpenPromotion(promotion.id)}
          >
            <div className={cx(styles.caretContainer)}>
              <CaretDown className={cx(styles.caretDown, isOpen && styles.isOpen)} />
            </div>
            <p className={cx(styles.headerTitle)}>{promotion.name}</p>
          </div>
          <div className={cx(styles.rightSide)}>
            {!isArchived ? (
              <>
                <ToggleSwitchControlled
                  onCheck={activatePromotionFn}
                  onUncheck={deactivatePromotionFn}
                  checked={isActive}
                  textChecked={t('words.active')}
                  textUnchecked={t('words.inactive')}
                  onChange={(val) => setIsActive(val)}
                  className={cx(styles.toggleActiveSwitch)}
                  switchWidth="full"
                  medium
                  error={isActiveError}
                />
                <span id={`options-${promotion?.id}`}>
                  <OptionsIcon className={cx(styles.optionsIcon)} />
                </span>
                <OptionsMenu
                  invertX={true}
                  elementQuery={`#options-${promotion?.id}`}
                  options={[
                    ...(onUpdatePromotionsOrder &&
                    onUpdatePromotionPriority &&
                    Number.isInteger(currentIndex)
                      ? [
                          {
                            icon: MoveToTop,
                            label: t('sentences.moveToTop'),
                            onClick: () => {
                              onUpdatePromotionsOrder(currentIndex, 0)
                              onUpdatePromotionPriority(
                                promotion.id,
                                0,
                                currentIndex,
                                'move-to-top'
                              )
                            },
                          },
                          {
                            icon: MoveToBottom,
                            label: t('sentences.moveToBottom'),
                            onClick: () => {
                              onUpdatePromotionsOrder(currentIndex, null)
                              onUpdatePromotionPriority(promotion.id, null, currentIndex)
                            },
                          },
                        ]
                      : []),
                    {
                      icon: ArchiveIcon,
                      label: t('words.archive'),
                      onClick: () => setShowConfirmation(true),
                    },
                  ]}
                />
                {canDrag && !isArchived ? (
                  <div className={cx(styles.dragIconWrapper)} ref={dragHandle}>
                    <MoveIcon className={cx(styles.moveIcon)} />
                  </div>
                ) : (
                  <HoverDiv
                    hoverText={
                      isArchived
                        ? t('sentences.canNotMoveArchivedPromotion')
                        : t('sentences.canNotMovePromotionWhileCreatingNew')
                    }
                    direction="left"
                    className={cx(styles.dragIconWrapper, styles.disabled)}
                  >
                    <MoveIcon className={cx(styles.moveIcon)} />
                  </HoverDiv>
                )}
              </>
            ) : (
              <span className={cx(styles.archivedLabel)}>{t('words.archived')}</span>
            )}
          </div>
        </div>
      )}

      <div className={cx(styles.foldContainer, (isNewPromotion || isOpen) && styles.isOpen)}>
        {(isOpen || isNewPromotion || hasBeenOpened) && (
          <React.Fragment>
            <div className={cx(styles.topRow, isNewPromotion && styles.extraMargin)}>
              <TextInputField
                error={nameError}
                disabled={isArchived}
                labelTwo={t('sentences.promotionName') + (isNewPromotion ? ' *' : '')}
                value={name}
                onChange={(val) => {
                  setNameError('')
                  setName(val)
                }}
                containerWidth="third"
                onEnter={() => savePromotionName()}
                inputProps={{
                  autoFocus: isNewPromotion,
                }}
                showActionButtons={(isNewPromotion || hasNameChange) && !nameError}
                confirmLoading={
                  actionStates.savingNewPromotion || actionStates.savingExistingPromotion
                }
                onConfirm={savePromotionName}
                showCancelButton={name?.length > 0 && hasNameChange}
                onCancel={() => setName(promotion?.name || '')}
                confirmText={isNewPromotion ? 'words.create' : 'words.save'}
              />
              {(isOpen || isNewPromotion) && (
                <IncompatiblePromotions
                  currentIndex={currentIndex}
                  promotion={promotion}
                  className={cx(styles.incompatiblePromotions)}
                  disabled={
                    isNewPromotion ||
                    isArchived ||
                    actionStates.savingNewPromotion ||
                    actionStates.savingExistingPromotion
                  }
                />
              )}
            </div>

            {!isNewPromotion && (
              <div className={cx(styles.tabsContainer)}>
                <div className={cx(styles.tabsHeaderLine)}></div>
                <div className={cx(styles.tabsHeaders)}>
                  <div
                    className={cx(
                      styles.singleTab,
                      currentTab === TABS.RULES && !isNewPromotion && styles.currentTab
                    )}
                    onClick={() => !isNewPromotion && setCurrentTab(TABS.RULES)}
                  >
                    <p className={cx(styles.tabLabel)}>{t('words.rules')}</p>
                  </div>
                  <div
                    className={cx(
                      styles.singleTab,
                      currentTab === TABS.ACTIONS && !isNewPromotion && styles.currentTab
                    )}
                    onClick={() => !isNewPromotion && setCurrentTab(TABS.ACTIONS)}
                  >
                    <p className={cx(styles.tabLabel)}>{t('words.actions')}</p>
                  </div>
                </div>
              </div>
            )}

            {!isNewPromotion && (
              <React.Fragment>
                <div
                  className={cx(
                    styles.rulesActionsWrapper,
                    styles.rulesWrapper,
                    currentTab === TABS.RULES && !isNewPromotion && styles.active
                  )}
                >
                  <RulesWrapper promotion={promotion} disabled={isArchived} />
                </div>
                <div
                  className={cx(
                    styles.rulesActionsWrapper,
                    styles.actionsWrapper,
                    currentTab === TABS.ACTIONS && !isNewPromotion && styles.active
                  )}
                >
                  <ActionsWrapper promotion={promotion} disabled={isArchived} />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>

      <ConfirmPopup
        visible={showConfirmation}
        titleIcon={ArchiveIcon}
        title={t('sentences.archivePromotion')}
        text={t('sentences.archivePromotionConfirmation', { promotion: promotion?.name })}
        acceptTheme="danger"
        rejectTextKey="words.cancel"
        acceptTextKey="words.archive"
        onReject={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onAccept={() => archivePromotionFn()}
      />
    </div>
  )
}

SinglePromotion.propTypes = {
  promotion: PropTypes.object.isRequired,
  currentIndex: PropTypes.number,
  onUpdatePromotionsOrder: PropTypes.func,
  onUpdatePromotionPriority: PropTypes.func,
  onMoveToBottom: PropTypes.func,
  canDrag: PropTypes.bool,
  dragHandle: PropTypes.any,
}

SinglePromotion.defaultProps = {
  canDrag: true,
  dragHandle: null,
}

export default SinglePromotion
