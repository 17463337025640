import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { ReactComponent as RemoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/stop-icon.svg'

import styles from './styles.module.scss'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'

const NestedRowWrapper = ({
  children,
  onRemove,
  title,
  titleComponent,
  isCompact,
  isReadOnly,
  toggle,
  hideContent,
  disabled,
  containerClassName,
  voucherDisabled,
}) => {
  return (
    <div
      className={cx(
        voucherDisabled ? styles.containerReadOnly : styles.container,
        isCompact ? styles.compact : false
      )}
    >
      <div className={cx(styles.titleRow, isCompact ? styles.compact : false, containerClassName)}>
        {titleComponent || <p className={cx(styles.title)}>{title}</p>}
        {toggle && !toggle.isHidden ? (
          <ToggleButton
            value={toggle.value}
            secondaryLabel={toggle.text}
            containerWidth={'half'}
            toggleLeft={true}
            containerClassName={cx(styles.toggle)}
            dotEnabled={toggle.dotEnabled}
            dotText={toggle.dotText}
            onChange={() => toggle.onToggle(!toggle.value)}
          />
        ) : null}
        {!isReadOnly && (
          <div
            className={cx(styles.removeWrapper, isCompact ? styles.compact : false)}
            onClick={onRemove}
          >
            {!voucherDisabled && <RemoveIcon className={cx(styles.removeIcon)} />}
          </div>
        )}
      </div>
      <div className={cx(styles.content)}>
        {!hideContent && children && <div className={cx(styles.inner)}>{children}</div>}
        {disabled && <div className={styles.disabled} />}
      </div>
    </div>
  )
}

NestedRowWrapper.propTypes = {
  onRemove: PropTypes.func,
  title: PropTypes.string,
  hideContent: PropTypes.bool,
  disabled: PropTypes.bool,
  toggle: PropTypes.shape({
    isHidden: PropTypes.bool,
    text: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    dotEnabled: PropTypes.bool,
    dotText: PropTypes.string,
  }),
  titleComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isCompact: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  containerClassName: PropTypes.string,
}

NestedRowWrapper.defaultProps = {
  hideContent: false,
  onRemove: () => {},
  title: '',
}

export default NestedRowWrapper
