import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

import Label from '../Label'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

const SingleRadioOption = ({
  value,
  checked,
  label,
  description,
  infoTextKey,
  lightLabel,
  onChange,
  inputFieldProps,
  disabled,
  containerClassName,
}) => {
  const handleChange = () => {
    if (disabled || !onChange) return
    onChange(value)
  }
  return (
    <div className={cx(styles.componentContainer, containerClassName)}>
      <div
        className={cx(styles.radioContainer, {
          [styles.radioContainerChecked]: checked,
          [styles.radioContainerDisabled]: disabled,
        })}
        onClick={handleChange}
      >
        <input
          disabled={disabled}
          type="radio"
          checked={checked}
          onChange={handleChange}
          className={cx(styles.radioInput)}
        />
        <div
          className={cx(styles.innerCircle, {
            [styles.innerCircleVisible]: checked,
            [styles.innerCircleDisabled]: disabled,
          })}
        ></div>
      </div>
      <Label
        label={label}
        lightLabel={description}
        infoTextKey={infoTextKey}
        defaultMargin={false}
        normalWeight={lightLabel}
        disabled={disabled}
        onClick={handleChange}
      />
      {inputFieldProps ? (
        <div
          className={cx(styles.textInputWrapper, {
            [styles.textInputWrapperVisible]: checked,
          })}
        >
          <TextInputField
            disabled={disabled}
            {...inputFieldProps}
            containerClassName={styles.inputFieldClassName}
          />
        </div>
      ) : null}
    </div>
  )
}

SingleRadioOption.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  infoTextKey: PropTypes.string,
  lightLabel: PropTypes.bool,
  onChange: PropTypes.func,
  inputFieldProps: PropTypes.object,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
}
SingleRadioOption.defaultValues = {
  value: null,
  checked: false,
  label: '',
  description: '',
  infoTextKey: '',
  lightLabel: false,
  onChange: () => {},
  inputFieldProps: null,
  disabled: false,
  containerClassName: '',
}
export default SingleRadioOption
