import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import Button from '@sweetspot/sweetspot-js/common/components/Button'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

const PartnershipFooter = ({
  currentStep,
  canSave,
  state,
  onSave,
  onActivate,
  onDelete,
  onCancel,
  globalErrors,
}) => {
  const { t } = useTranslation()

  const shouldShowSave = useMemo(() => {
    if (currentStep === 1 || currentStep === 2) return true
    return false
  }, [currentStep])

  const shouldShowCancel = useMemo(() => {
    if (currentStep === 3 && state && state === 'enabled') return true
    return false
  }, [currentStep, state])

  const shouldShowDeleteAndActivate = useMemo(() => {
    if (currentStep === 3 && state && state === 'created') return true
    return false
  }, [currentStep, state])

  const getErrorString = (string) => {
    if (string === 'validation-error') return t('wizard.validationError')
    if (string === 'fees-required') return t('errors.atleastOneFeeIsRequired')
    if (string === 'promotions-required') return t('wizard.rulesMustBeAdded')
    if (string === 'invalid-rules-for-partnership')
      return t('wizard.invalidRulesForPartnershipType')
    return ''
  }

  return (
    <div className={cx(styles.container)}>
      <div
        className={cx(styles.validationErrorWrapper, {
          [styles.validationErrorWrapperVisible]: globalErrors.length >= 1,
        })}
      >
        <div className={cx(styles.validationErrorContainer)}>
          {globalErrors.map((err) => (
            <p key={err} className={cx(styles.validationErrorText)}>
              {getErrorString(err)}.
            </p>
          ))}
        </div>
      </div>

      {shouldShowSave && (
        <div className={styles.buttonContainer}>
          <Button
            size="larger"
            text={t('wizard.saveProceed')}
            onClick={onSave}
            disabled={!canSave}
          />
        </div>
      )}

      {shouldShowCancel && (
        <div className={styles.buttonContainer}>
          <Button
            size="larger"
            text={t('wizard.cancelPartnership')}
            onClick={onCancel}
            theme="danger"
          />
        </div>
      )}

      {shouldShowDeleteAndActivate && (
        <div className={styles.buttonContainer}>
          <Button
            size="larger"
            text={t('wizard.delete')}
            onClick={onDelete}
            theme="danger"
            width="48%"
          />
          <Button
            size="larger"
            text={t('wizard.activate')}
            onClick={onActivate}
            theme="green"
            width="48%"
          />
        </div>
      )}
    </div>
  )
}

PartnershipFooter.propTypes = {
  currentStep: PropTypes.number.isRequired,
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  onActivate: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  state: PropTypes.string,
  globalErrors: PropTypes.array,
}
PartnershipFooter.defaultProps = {}

export default PartnershipFooter
