import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { Me } from './types'

export const getMe = (): Promise<Me> => {
  const URL = `${getApiPlatformBaseUrl()}/auth/club-users/me`

  return request(URL)
}

export const getMyRoles = (): Promise<unknown> => {
  const URL = `${getApiPlatformBaseUrl()}/club-users/my-roles`

  return request(URL)
}
