import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.scss'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'

import CrossIcon from '@sweetspot/club-portal-legacy/resources/images/cross.svg'
import EyeIcon from '@sweetspot/club-portal-legacy/resources/images/eye.svg'

const PASSWORD = 1
const CONFIRM = 2

class CAForm extends Component {
  state = {
    showPass: false,
    showConfirm: false,
  }

  TRANSLATIONS = {
    existingEmail: GetTranslatedString(this.props.lang, 'settings.clubAdmins.emailExists'),
    passwordLength: GetTranslatedString(this.props.lang, 'settings.clubAdmins.passwordLength2'),
  }

  handleClearEmail = () => {
    this.props.onChange('', 'email')
  }

  toggleShow = (target) => {
    if (target === PASSWORD) {
      this.setState({ showPass: !this.state.showPass })
    } else {
      this.setState({ showConfirm: !this.state.showConfirm })
    }
  }

  renderInfo = () => {
    const {
      isLoading,
      form: { firstName, lastName, email },
    } = this.props

    if (isLoading) return null
    return (
      <div className={styles.container}>
        <div className={styles.field}>
          <label>
            <Text textId="firstName" />
          </label>
          <br />
          <span>{firstName}</span>
        </div>

        <div className={styles.field}>
          <label>
            <Text textId="lastName" />
          </label>
          <br />
          <span>{lastName}</span>
        </div>

        <div className={styles.field}>
          <label>
            <Text textId="communication.email" />
          </label>
          <br />
          <span>{email}</span>
        </div>

        <div className={styles.field}>
          <label>
            <Text textId="password" />
          </label>
          <br />
          <span>*********</span>
        </div>
      </div>
    )
  }

  render = () => {
    const { showPass, showConfirm } = this.state

    const {
      isEdit,
      form: { firstName, lastName, email, password, confirmPass },
      errors,
      onChange,
    } = this.props

    if (!isEdit) return this.renderInfo()

    const emailError = errors.email && this.TRANSLATIONS.existingEmail
    const passwordError = errors.password && this.TRANSLATIONS.passwordLength

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <TextInputField
            label=".firstName"
            value={firstName}
            onChange={(value) => onChange(value, 'firstName')}
          />

          <TextInputField
            label=".lastName"
            value={lastName}
            onChange={(value) => onChange(value, 'lastName')}
          />

          <TextInputField
            label=".communication.email"
            value={email}
            error={emailError}
            suffix={emailError && <img src={CrossIcon} alt="cross" />}
            onSuffixClick={this.handleClearEmail}
            onChange={(value) => onChange(value, 'email')}
          />

          <TextInputField
            label=".password"
            type={showPass ? 'text' : 'password'}
            value={password}
            error={passwordError}
            suffix={<img src={EyeIcon} alt="eye" />}
            onSuffixClick={() => this.toggleShow(PASSWORD)}
            onChange={(value) => onChange(value, 'password')}
          />

          <TextInputField
            label=".repeatPassword"
            type={showConfirm ? 'text' : 'password'}
            value={confirmPass}
            suffix={<img src={EyeIcon} alt="eye" />}
            onSuffixClick={() => this.toggleShow(CONFIRM)}
            onChange={(value) => onChange(value, 'confirmPass')}
          />
        </div>
      </div>
    )
  }
}

CAForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CAForm
