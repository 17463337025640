import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _omit from 'lodash/omit'
import cx from 'classnames'
import { t } from 'i18next'

import style from './style.module.scss'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import { ReactComponent as IconCaret } from '@sweetspot/club-portal-legacy/resources/images/caret.svg'
import Checkbox from '@sweetspot/club-portal-legacy/components/Checkbox'
import { ReactComponent as SearchIcon } from '@sweetspot/club-portal-legacy/resources/images/search-icon.svg'
import SearchInput from '@sweetspot/club-portal-legacy/components/SearchInput'

const SELECT_ALL_ITEMS = '_$all'

const setItem = (item, index, preselectedList, preselectedKey) => {
  let isChecked = false
  if (Object.prototype.hasOwnProperty.call(item, preselectedKey)) {
    isChecked = !!preselectedList.includes(item[preselectedKey])
  } else {
    console.log(`[func ItemManager/setItem]: Cannot init preselected key - ${preselectedKey}`)
  }
  return {
    [`_$index`]: index,
    [`_$checked`]: isChecked,
    ...item,
  }
}

const filterList = (list, key, text) => {
  if (!key || !text) return list
  return list.filter((i) => {
    return i[key].toLowerCase().includes(text.toLowerCase())
  })
}

const ItemManager = (props) => {
  const [leftSearchText, setLeftSearchText] = useState('')
  const [rightSearchText, setRightSearchText] = useState('')

  const [isLeftAllSelected, setLeftAllSelected] = useState(false)
  const [isRightAllSelected, setRightAllSelected] = useState(false)

  const [leftList, setLeftList] = useState(
    props.leftList.map((item, index) =>
      setItem(item, index, props.preselectedLeft, props.preselectedLeftKey)
    )
  )
  const [rightList, setRightList] = useState(
    props.rightList.map((item, index) =>
      setItem(item, index, props.preselectedRight, props.preselectedRightKey)
    )
  )

  const onSelect = (from, target) => {
    // Left handlers
    if (from === 'left') {
      if (leftList.length === 0) return
      if (target === SELECT_ALL_ITEMS) {
        setLeftAllSelected(!isLeftAllSelected)
        setLeftList(leftList.map((item) => ({ ...item, _$checked: !isLeftAllSelected })))
        props.onSelectAllLeft && props.onSelectAllLeft(!isLeftAllSelected)
      } else {
        setLeftList(
          leftList.map((item, index) => {
            if (target['_$index'] === index) {
              return { ...item, _$checked: !item['_$checked'] }
            } else return item
          })
        )
      }

      if (props.onSelectLeft && target !== SELECT_ALL_ITEMS) {
        props.onSelectLeft(_omit(target, ['_$index', '_$checked']))
      }
    }
    // Right handlers
    if (from === 'right') {
      if (rightList.length === 0) return
      if (target === SELECT_ALL_ITEMS) {
        setRightAllSelected(!isRightAllSelected)
        setRightList(rightList.map((item) => ({ ...item, _$checked: !isRightAllSelected })))
        props.onSelectAllRight && props.onSelectAllRight(!isRightAllSelected)
      } else {
        setRightList(
          rightList.map((item, index) => {
            if (target['_$index'] === index) {
              return { ...item, _$checked: !item['_$checked'] }
            } else return item
          })
        )
      }

      if (props.onSelectRight && target !== SELECT_ALL_ITEMS) {
        props.onSelectRight(_omit(target, ['_$index', '_$checked']))
      }
    }
  }
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.column}>
          <div className={style.row}>
            <Text textId={'cooperationCreate.column_Source'} className={style.label} />
          </div>
          <div className={style.row}>
            <Checkbox
              key={`left_check_all`}
              append={
                <div>{`${props.leftList.length} item${
                  props.leftList.length === 1 ? '' : 's'
                }`}</div>
              }
              checked={isLeftAllSelected}
              onChange={() => onSelect('left', SELECT_ALL_ITEMS)}
            />
          </div>
          <div className={cx(style.row, style.noPadding)}>
            <SearchInput
              className={style.searchContainer}
              inputClassName={style.inputContainer}
              iconClassName={style.iconContainer}
              placeholder={'.cooperationCreate.placeholder_Search'}
              onKeyPress={() => void 0}
              onChange={(value = '') => setLeftSearchText(value.trim())}
              icon={<SearchIcon className={style.searchIcon} />}
              isBordered
            />
          </div>
          <div className={style.list}>
            {filterList(leftList, props.leftKey, leftSearchText).map((item, index) => {
              return (
                <div className={cx(style.row, style.item)} key={`left_item_${index}`}>
                  <Checkbox
                    append={<Text textId={item[props.leftKey]} />}
                    checked={item['_$checked']}
                    onChange={() => onSelect('left', item)}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className={cx(style.column, style.middle)}>
          <button
            disabled={!props.addEnabled}
            onClick={props.onAdd}
            className={`system-button primary md-32 ${style.button}`}
          >
            {t('cooperationCreate.button_Add')}
            <IconCaret className={style.arrow} />
          </button>
          <button
            disabled={!props.removeEnabled}
            onClick={props.onRemove}
            className={`system-button primary md-32 ${style.button}`}
          >
            <IconCaret className={cx(style.arrow, style.left)} />
            {t('cooperationCreate.button_Remove')}
          </button>
        </div>
        <div className={style.column}>
          <div className={style.row}>
            <Text textId={'cooperationCreate.column_Target'} className={style.label} />
          </div>
          <div className={style.row}>
            <Checkbox
              key={`right_check_all`}
              append={
                <div>{`${props.rightList.length} item${
                  props.rightList.length === 1 ? '' : 's'
                }`}</div>
              }
              checked={isRightAllSelected}
              onChange={() => onSelect('right', SELECT_ALL_ITEMS)}
            />
          </div>
          <div className={cx(style.row, style.noPadding)}>
            <SearchInput
              className={style.searchContainer}
              inputClassName={style.inputContainer}
              iconClassName={style.iconContainer}
              placeholder={'.cooperationCreate.placeholder_Search'}
              onKeyPress={() => void 0}
              onChange={(value = '') => setRightSearchText(value.trim())}
              icon={<SearchIcon className={style.searchIcon} />}
              isBordered
            />
          </div>
          <div className={style.list}>
            {filterList(rightList, props.rightKey, rightSearchText).map((item, index) => {
              return (
                <div className={cx(style.row, style.item)} key={`right_item_${index}`}>
                  <Checkbox
                    append={<Text textId={item[props.rightKey]} />}
                    checked={item['_$checked']}
                    onChange={() => onSelect('right', item)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ItemManager.propTypes = {
  leftList: PropTypes.array.isRequired,
  rightList: PropTypes.array.isRequired,
  leftKey: PropTypes.string.isRequired,
  rightKey: PropTypes.string.isRequired,
  onSelectLeft: PropTypes.func,
  onSelectRight: PropTypes.func,
  onSelectAllLeft: PropTypes.func,
  onSelectAllRight: PropTypes.func,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  addEnabled: PropTypes.bool,
  removeEnabled: PropTypes.bool,
  preselectedLeft: PropTypes.array,
  preselectedLeftKey: PropTypes.string,
  preselectedRight: PropTypes.array,
  preselectedRightKey: PropTypes.string,
}

ItemManager.defaultProps = {
  leftKey: 'name',
  rightKey: 'name',
}

export default ItemManager
