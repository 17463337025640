import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import BookedSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/booked-slot.svg'
import GuestSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/booked-slot-guest.svg'
import MemberSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/booked-slot-member.svg'
import PartnerSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/booked-slot-partner.svg'
import AnonSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/booked-slot-anonymous.svg'
import ARSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/ar-slot.svg'
import ARGuestSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/ar-slot-guest.svg'
import ARMemberSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/ar-slot-member.svg'
import ARPartnerSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/ar-slot-partner.svg'
import ARAnonSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/ar-slot-anonymous.svg'
import ReservedSlotIcon from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/reserved-slot.svg'

import style from './style.module.scss'

const PARTNER = 'partner'
const MEMBER = 'member'
const GUEST = 'guest'
const RESERVED = 'reserved'
const ANON = 'anonymous'

export default class Slots extends Component {
  renderSlots() {
    const {
      maximumSlots,
      openSlots,
      participants: participantList,
      playerTypeFilter,
      partnershipPlayers,
    } = this.props

    const slots1 = []
    const slots2 = []
    const participants = participantList || []
    for (let i = 0; i < maximumSlots - openSlots; i++) {
      let classNames = [style.slot, style.playerType]
      let iconSrc = ''
      let participant = participants[i]
      const slot = participant?.slot

      if (!participant) continue

      if (
        (partnershipPlayers &&
          partnershipPlayers.find((player) => player?.id === slot?.player?.id)) ||
        participant?.partnership
      ) {
        if (slot?.is_arrival_registration) {
          iconSrc = !playerTypeFilter.includes(PARTNER) ? ARSlotIcon : ARPartnerSlotIcon
        } else {
          iconSrc = !playerTypeFilter.includes(PARTNER) ? BookedSlotIcon : PartnerSlotIcon
        }
      } else if (!slot?.player?.id && slot?.type !== 'stub_player') {
        iconSrc = playerTypeFilter.includes(RESERVED) ? ReservedSlotIcon : BookedSlotIcon
      } else if (slot?.is_member) {
        if (slot?.is_arrival_registration) {
          iconSrc = !playerTypeFilter.includes(MEMBER) ? ARSlotIcon : ARMemberSlotIcon
        } else {
          iconSrc = !playerTypeFilter.includes(MEMBER) ? BookedSlotIcon : MemberSlotIcon
        }
      } else if (slot?.type === 'stub_player') {
        if (slot?.is_arrival_registration) {
          iconSrc = !playerTypeFilter.includes(ANON) ? ARSlotIcon : ARAnonSlotIcon
        } else {
          iconSrc = !playerTypeFilter.includes(ANON) ? BookedSlotIcon : AnonSlotIcon
        }
      } else {
        if (slot?.is_arrival_registration) {
          iconSrc = !playerTypeFilter.includes(GUEST) ? ARSlotIcon : ARGuestSlotIcon
        } else {
          iconSrc = !playerTypeFilter.includes(GUEST) ? BookedSlotIcon : GuestSlotIcon
        }
      }

      if (slots1.length >= 5) {
        slots2.push(
          <div className={classNames.join(' ')} key={i + '-participant'}>
            <img alt="Slot" src={iconSrc} />
          </div>
        )
      } else {
        slots1.push(
          <div className={classNames.join(' ')} key={i + '-participant'}>
            <img alt="Slot" src={iconSrc} />
          </div>
        )
      }
    }

    for (let i = 0; i < openSlots; i++) {
      if (slots1.length >= 5) {
        slots2.push(<div className={cx(style.slot, style.openSlot)} key={i + '-open'} />)
      } else {
        slots1.push(<div className={cx(style.slot, style.openSlot)} key={i + '-open'} />)
      }
    }

    return (
      <>
        <div className={style.content}>{slots1}</div>
        <div className={style.content}>{slots2}</div>
      </>
    )
  }

  render() {
    const { selected } = this.props

    return (
      <div className={cx(style.container, { [style.selected]: selected })}>
        {this.renderSlots()}
      </div>
    )
  }
}

Slots.propTypes = {
  selected: PropTypes.bool,
  maximumSlots: PropTypes.number.isRequired,
  openSlots: PropTypes.number.isRequired,
  participants: PropTypes.array,
  playerTypeFilter: PropTypes.array.isRequired,
  partnershipPlayers: PropTypes.array,
}
