import { useCallback } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Check } from '@sweetspot/scramble-ds'
import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'
import OpeningHoursTimePicker from './OpeningHoursTimePicker'

const OpeningHours = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: { openingHours, setOpeningHours, openingHoursDefault },
  } = useBallPricingContext()

  const handleOnCheckedChange = useCallback(
    (checked: boolean, day: string) => {
      const openingHoursPayload: { open?: string; close?: string; disabled?: boolean } = {
        disabled: !checked,
      }
      if (checked) {
        openingHoursPayload.open = openingHoursDefault?.open
        openingHoursPayload.close = openingHoursDefault?.close
      }
      setOpeningHours?.({ [day]: openingHoursPayload })
    },
    [setOpeningHours, openingHoursDefault]
  )

  return (
    <div className="text-content-base text-text-dark font-bold">
      <span>{t('settings.openingHours')}</span>
      <div className="text-text-dark text-content-sm mt-2 flex justify-between font-bold">
        <span>{t('dateTime.words.day_one')}</span>
        <div className="flex gap-4">
          <div className="w-16">{t('words.from')}</div>
          <div className="mr-3 w-16">{t('words.to')}</div>
        </div>
      </div>
      {Object.entries(openingHours || {}).map(([day, hours]) => (
        <div key={day} className="mt-1 flex justify-between">
          <Check
            className="h-auto pl-2"
            checked={!hours.disabled}
            label={t(`dateTime.longDays.${day}_one`)}
            labelClassName={cx({
              'text-text-medium': hours.disabled,
            })}
            onCheckedChange={(checked) => handleOnCheckedChange?.(checked as boolean, day)}
          />
          <div className="flex gap-1">
            <OpeningHoursTimePicker
              day={day}
              time={hours.open}
              disabled={hours.disabled}
              type="open"
              defaultValue={openingHoursDefault?.open}
            />
            <OpeningHoursTimePicker
              day={day}
              time={hours.close}
              disabled={hours.disabled}
              type="close"
              defaultValue={openingHoursDefault?.close}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default OpeningHours
