import { calculatePrice } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import {
  CalculatePricePayload,
  CalculatePriceResponse,
} from '@sweetspot/sweetspot-js/features/bookings/types'
import React, {
  createContext,
  useReducer,
  Dispatch,
  ReactNode,
  useContext,
  useCallback,
} from 'react'
import { DATA_QUERIES } from '@sweetspot/shared/util/constants'
import { useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import _debounce from 'lodash/debounce'

export interface ExtraPlayerState extends CalculatePricePayload {
  calculatedPriceResult: CalculatePriceResponse
}

interface ExtraPlayerProviderProps {
  children: ReactNode
  initialState?: InitialState
  bookingUuid: string
}

interface InitialState extends ExtraPlayerState {}

// Initial state
const defaultInitialState: InitialState = {
  calculatedPriceResult: {
    calculated_total: 0,
    existing_total: 0,
    order_items: [],
    tee_times: [],
    is_modified: false,
  },
  tee_times: [],
}

// Action Types as Enum
export enum ActionType {
  SetExtraPlayerValue = 'SET_EXTRA_PLAYER_VALUE',
  ClearAction = 'SET_DEFAULT_STATE',
  UpdateOrderItems = 'UPDATE_CALCULATION',
}

// Action types
type SetTeeTimesAction = {
  type: ActionType.SetExtraPlayerValue
  uuid: string
  players: number
}
type ClearAction = { type: ActionType.ClearAction }
type UpdateOrderItemsAction = {
  type: ActionType.UpdateOrderItems
  calculatedPriceResult: CalculatePriceResponse
}

// Union type for all actions
type Action = SetTeeTimesAction | ClearAction | UpdateOrderItemsAction

// Create context
const ExtraPlayerContext = createContext<{
  state: ExtraPlayerState
  dispatch: Dispatch<Action>
  isLoading: boolean
  calculatePrice: () => Promise<unknown>
}>(undefined)

export const useExtraPlayerContext = () => {
  const context = useContext(ExtraPlayerContext)
  if (context === undefined) {
    throw new Error('useExtraPlayer must be used within an ExtraPlayerProvider')
  }
  return context
}

const useExtraPlayerState = () => {
  const context = useExtraPlayerContext()
  return context.state
}

const useExtraPlayerDispatch = () => {
  const context = useContext(ExtraPlayerContext)
  if (!context) {
    throw new Error('useExtraPlayerDispatch must be used within an ExtraPlayerProvider')
  }
  return context.dispatch
}

const useDebouncedExtraPlayerUpdate = () => {
  const dispatch = useExtraPlayerDispatch()
  // Debounce the dispatch function
  const debouncedDispatch = useCallback(
    _debounce((uuid, players) => {
      dispatch({
        type: ActionType.SetExtraPlayerValue,
        uuid: uuid,
        players: players,
      })
    }, 300), // 300 ms debounce period
    [dispatch]
  )

  return debouncedDispatch
}

// Reducer functions
const extraPlayerReducer = (state: ExtraPlayerState, action: Action): ExtraPlayerState => {
  switch (action.type) {
    case ActionType.SetExtraPlayerValue: {
      const index = state.tee_times.findIndex((item) => item.uuid === action.uuid)
      // Update value if teetime already exists
      if (index > -1) {
        const updatedTeeTimes = state.tee_times.map((teeTime) =>
          teeTime.uuid === action.uuid ? { ...teeTime, players: action.players } : teeTime
        )
        return {
          ...state,
          tee_times: updatedTeeTimes,
        }
      }
      // Add if not found
      else {
        return {
          ...state,
          tee_times: [
            ...state.tee_times,
            {
              uuid: action.uuid,
              players: action.players,
            },
          ],
        }
      }
    }
    case ActionType.UpdateOrderItems: {
      const teeTimes = action.calculatedPriceResult.tee_times.map(
        ({ uuid, players = 0, extra_players = 0 }) => ({
          uuid,
          players: players + extra_players,
        })
      )
      return {
        ...state,
        calculatedPriceResult: action.calculatedPriceResult,
        tee_times: teeTimes,
      }
    }
    case ActionType.ClearAction:
      return defaultInitialState
    default:
      return state
  }
}

const ExtraPlayerProvider: React.FC<ExtraPlayerProviderProps> = ({
  children,
  initialState = defaultInitialState,
  bookingUuid,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [state, dispatch] = useReducer(extraPlayerReducer, initialState)

  const hasBookingUuid = !!bookingUuid

  const { isLoading, refetch } = useQuery(
    [DATA_QUERIES.CALCULATE_PRICE, bookingUuid, state.tee_times],
    async () => await calculatePrice(bookingUuid, { tee_times: state.tee_times }),
    {
      onSuccess: (data: CalculatePriceResponse) => {
        dispatch({
          type: ActionType.UpdateOrderItems,
          calculatedPriceResult: data,
        })
      },
      onError: (err) => {
        if (
          err?.detail?.includes('Play value is out of limit') ||
          err?.message?.includes('Play value is out of limit')
        ) {
          addToast(t('sentences.maxPlayValueReached'), { appearance: 'error' })
          dispatch({
            type: ActionType.ClearAction,
          })
        } else if (
          err?.detail?.includes('Number of rounds is out of limit') ||
          err?.message?.includes('Number of rounds is out of limit')
        ) {
          addToast(t('sentences.maxNumberOfRoundsReached'), { appearance: 'error' })
          dispatch({
            type: ActionType.ClearAction,
          })
        } else if (
          err?.detail?.includes('Cannot modify players due to tee times having different prices') ||
          err?.message?.includes('Cannot modify players due to tee times having different prices')
        ) {
          addToast(t('sentences.cannotModifyPlayersDueToPrices'), { appearance: 'error' })
          dispatch({
            type: ActionType.ClearAction,
          })
        }
      },
      retry: false,
      enabled: !!hasBookingUuid,
    }
  )

  return (
    <ExtraPlayerContext.Provider
      value={{
        state,
        dispatch,
        isLoading,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        calculatePrice: hasBookingUuid ? refetch : async () => {},
      }}
    >
      {children}
    </ExtraPlayerContext.Provider>
  )
}

export {
  ExtraPlayerContext,
  ExtraPlayerProvider,
  useExtraPlayerDispatch,
  useExtraPlayerState,
  useDebouncedExtraPlayerUpdate,
}
