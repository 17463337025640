import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/**
 * Club user login
 *
 * @param {string} email
 * @param {string} password
 * @param {string} portal
 * @returns
 */
export const clubUserLogin = (
  payload = { email: '', password: '', portal: null, isLogin: true }
) => {
  const url = `${getApiPlatformBaseUrl()}/auth/club-users/login`
  return request(url, {
    method: 'post',
    body: payload,
    isLogin: typeof payload?.isLogin === 'boolean' ? payload.isLogin : true,
  })
}

/**
 * Login to club
 *
 * @param {string} clubUuid
 */
export const loginToClub = (clubUuid) => {
  const url = `${getApiPlatformBaseUrl()}/club-users/login-to-club`
  return request(url, { method: 'post', body: { club: clubUuid } })
}

/**
 * Request to user login
 *
 * @param {string} email
 * @param {string} password
 * @returns {promise}
 */

export const playerLogin = (email, password) => {
  const url = `${getApiPlatformBaseUrl()}/auth/players/login`
  return request(url, { method: 'POST', body: { email, password } })
}
