import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { UpdateRangePayload } from './types'

export const updateRange = (body: UpdateRangePayload): Promise<void> => {
  const { id, ...rest } = body
  const url = `${getApiPlatformBaseUrl()}/ranges/${id}`
  return request(url, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: { ...rest },
  })
}
