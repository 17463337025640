import { OutletStatus } from '@sweetspot/shared/data-access/api-platform'

import { BallQuantity, TerminalStatus } from './types'

export const ballDispenserBrandName = 'Range Servant'

export const terminalModel = 'S1U2'

export const defaultBallDispenser = { name: '', brand: ballDispenserBrandName, nrOfOutlets: 1 }

export const tagVariantsMap: { [key: string]: 'success-filled' | 'danger-filled' } = {
  [OutletStatus.OPERATIONAL]: 'success-filled',
  [OutletStatus.INACTIVE]: 'danger-filled',
  [OutletStatus.OFFLINE]: 'danger-filled',
  [OutletStatus.UNKNOWN]: 'danger-filled',
  [TerminalStatus.ONLINE]: 'success-filled',
  [BallQuantity.LOADED]: 'success-filled',
  [BallQuantity.LOW]: 'danger-filled',
}

export const tabIconsMap = {
  [OutletStatus.OPERATIONAL]: 'fa-check',
  [OutletStatus.INACTIVE]: 'fa-xmark',
  [OutletStatus.OFFLINE]: 'fa-wifi-exclamation',
  [OutletStatus.UNKNOWN]: 'fa-warning',
  [TerminalStatus.ONLINE]: 'fa-wifi',
  [BallQuantity.LOADED]: 'fa-check',
  [BallQuantity.LOW]: 'fa-warning',
}
