import { useSelector } from 'react-redux'

import TeetimeStatus from '@sweetspot/club-portal-legacy/components/TeetimeStatus'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'

const PeriodRule = ({ isLoading, period }) => {
  const { t } = useTranslation()
  const currentClub = useSelector((state) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })
  const hasCdhNumber = currentClub?.cdh_id

  if (isLoading)
    return (
      <div className={style.loadingContainer}>
        <PulseLoader showIf={isLoading} />
      </div>
    )

  const {
    name,
    start_date,
    end_date,
    start_time_from,
    start_time_to,
    interval,
    category,
    is_golf_id_required,
    is_prime_time,
  } = period

  return (
    <div className={style.container}>
      <div className={style.row}>
        <div className={style.name}>
          <span>{name}</span>
          <span>
            {start_date?.substring(0, 10)} - {end_date?.substring(0, 10)}
          </span>
        </div>
        <div className={style.status}>
          <span className={style.label}>{t('editPeriodsNew.category')}</span>
          {category ? <TeetimeStatus color={category.color} name={category.name} /> : <span />}
        </div>
        <div className={style.time}>
          <span className={style.label}>{t('editPeriodsNew.firstTimeStarts')}</span>
          <span>{start_time_from?.substr(11, 5)}</span>
        </div>
        <div className={style.time}>
          <span className={style.label}>{t('editPeriodsNew.lastTimeStarts')}</span>
          <span>{start_time_to?.substr(11, 5)}</span>
        </div>
        <div className={style.interval}>
          <span className={style.label}>{t('editPeriodsNew.interval')}</span>
          <span>{interval}</span>
        </div>
        <div className={style.golfIdOption}>
          <span className={style.label}>
            {hasCdhNumber ? t('words.cdhNumber') : t('editPeriodsNew.golfID')}
          </span>
          <span>
            {`${t(`editPeriodsNew.${is_golf_id_required ? 'required' : 'optional'}`).substr(
              0,
              3
            )}.`}
          </span>
        </div>
        <div className={style.slots}>
          <span className={style.label}>{t('editPeriodsNew.primeTime')}</span>
          <span>{is_prime_time ? t('yes') : t('no')}</span>
        </div>
        <div className={style.spaces}>
          <span className={style.label}>{t('editPeriodsNew.spaces')}</span>
          <span>All</span>
        </div>
        <div></div>
      </div>
    </div>
  )
}

PeriodRule.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  period: PropTypes.object,
}

export default PeriodRule
