import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

import Accordion from '@sweetspot/club-portal-legacy/components/Accordion'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import GeneralSettings from '@sweetspot/club-portal-legacy/components/Wizard/containers/partnership/GeneralSettings'
import RulesAndCourses from '@sweetspot/club-portal-legacy/components/Wizard/containers/partnership/RulesAndCourses'

const Review = ({ values, currentPartnership }) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.container)}>
      <Accordion title={t('wizard.generalSettings')}>
        <GeneralSettings values={values} disabled={true} onlyFields={true} />
      </Accordion>
      <Accordion title={t('wizard.rulesAndCourses')}>
        <RulesAndCourses
          values={values}
          onlyFields={true}
          disabled={true}
          currentPartnership={currentPartnership}
        />
      </Accordion>
      {currentPartnership?.state === 'created' ? (
        <div className={cx(styles.infoTextContainer)}>
          <p className={cx(styles.infoText)}>{t('wizard.thisPartnershipNeedsToBeActivated')}.</p>
        </div>
      ) : null}
    </div>
  )
}

Review.propTypes = {
  values: PropTypes.object.isRequired,
  currentPartnership: PropTypes.object.isRequired,
}

export default Review
