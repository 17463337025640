import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import DarkBlueSubtitle from '../../DarkBlueSubtitle'
import ValueText from '../../ValueText'
import { DAYS_OPTIONS } from '@sweetspot/sweetspot-js/features/promotions/constants/rules'
import InnerBoxWrapper from '../../InnerBoxWrapper'

const DaysAndTimes = ({ rule }) => {
  const { t } = useTranslation()

  // Converted to array
  const config = useMemo(() => {
    let data = []
    if (!rule?.configuration) return data

    Object.keys(rule.configuration).forEach((key) => {
      const day = rule.configuration[key]
      data = [...data, { times: day, key, option: DAYS_OPTIONS.find((x) => x.value === key) }]
    })

    return data.sort((a, b) => {
      return a.option.id - b.option.id
    })
  }, [rule])

  return (
    <div className={cx(styles.container)}>
      <DarkBlueSubtitle>{t('sentences.daysAndTimes')}</DarkBlueSubtitle>

      {config?.length ? (
        config.map((day) => (
          <InnerBoxWrapper key={day.key} className={cx(styles.row)}>
            <ValueText>{t(day.option.label)}</ValueText>

            <div className={cx(styles.timesWrapper)}>
              {day.times.length ? (
                day.times.map((time, index) => (
                  <ValueText
                    key={index}
                    className={cx(styles.robotoFont)}
                    fontWeight="medium"
                    fontSize="13"
                  >{`${time.from.substring(0, time.from.length - 3)} - ${time.to.substring(
                    0,
                    time.to.length - 3
                  )}`}</ValueText>
                ))
              ) : (
                <ValueText>{t('dateTime.words.allDay')}</ValueText>
              )}
            </div>
          </InnerBoxWrapper>
        ))
      ) : (
        <ValueText>{t('sentences.noDays&TimesAllowed')}</ValueText>
      )}
    </div>
  )
}

DaysAndTimes.propTypes = {
  rule: PropTypes.object,
}

DaysAndTimes.defaultProps = {}

export default DaysAndTimes
