import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryBaysV2 } from '@sweetspot/sweetspot-js/features/bays/services'
import {
  BayScreenDisplay,
  BayScreenMode,
  BayTypeV2,
  TrackingTech,
  ViolationError,
} from '@sweetspot/shared/types'
import {
  createBay,
  createBayBulk,
  CreateBayBulkPayload,
  CreateBayPayload,
  updateBay,
  updateBayBulk,
  UpdateBayBulkPayload,
  UpdateBayPayload,
  updateRange,
  UpdateRangePayload,
} from '@sweetspot/shared/data-access/api-platform'
import { isInteger } from '@sweetspot/shared/util/validators'
import { MergedBaysType } from '../types'

type UseBaysProps = {
  selectedRangeId?: string
  selectedRangeToptracerSiteId?: string
}

const useBays = ({ selectedRangeId, selectedRangeToptracerSiteId }: UseBaysProps) => {
  const [selectedBays, setSelectedBays] = useState<BayTypeV2[]>([])
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const {
    data,
    isLoading: areBaysLoading,
    isFetching: areBaysFetching,
    refetch,
  } = useQuery(
    [
      CLUB_QUERIES.BAYS,
      {
        drivingRangeUuid: selectedRangeId,
      },
    ],
    () =>
      queryBaysV2({
        drivingRangeId: selectedRangeId || '',
      }),
    {
      enabled: !!selectedRangeId,
    }
  )

  const checkForErrors = useCallback(
    (
      payload?: Omit<CreateBayPayload | UpdateBayPayload | UpdateBayBulkPayload, 'rangeId'> & {
        siteId?: string
      }
    ) => {
      if (!payload) return ''
      const trackingProvider = payload?.bay?.ball_tracking_technology?.tracking_provider
      const display = payload?.bay?.ball_tracking_technology?.display
      const screenMode = payload?.bay?.ball_tracking_technology?.screen_mode
      if (
        trackingProvider === TrackingTech.TOPTRACER &&
        display === BayScreenDisplay.TOPTRACER_SCREEN &&
        !screenMode
      ) {
        return `${t('settings.bays.screenMode')} ${t('errors.fieldRequired').toLowerCase()}`
      }
      const trmsBayNumber = payload?.bay?.ball_tracking_technology?.api_details?.trms_bay_number
      if (trmsBayNumber && !isInteger(trmsBayNumber)) {
        return t('errors.invalidTrmsBayNumber')
      }
      return ''
    },
    [t]
  )

  const handleOnError = (error: { error: string } | ViolationError) => {
    const errorObj = (error as ViolationError)?.violations?.[0]
    const defaultErrorMessage = errorObj
      ? `${errorObj.propertyPath}: ${errorObj.message}`
      : t('toast.defaultError')
    addToast(defaultErrorMessage, { appearance: 'error' })
  }

  const updateBayMutation = useMutation((data: UpdateBayPayload) => updateBay(data), {
    onError: handleOnError,
  })

  const updateBayBulkMutation = useMutation((data: UpdateBayBulkPayload) => updateBayBulk(data), {
    onError: handleOnError,
  })

  const createBayMutation = useMutation((data: CreateBayPayload) => createBay(data), {
    onError: handleOnError,
  })

  const createBayBulkMutation = useMutation((data: CreateBayBulkPayload) => createBayBulk(data), {
    onError: handleOnError,
  })

  const updateRangeSiteIdMutation = useMutation((data: UpdateRangePayload) => updateRange(data), {
    onError: handleOnError,
  })

  const handleBayUpdate = useCallback(
    async (data: UpdateBayPayload & { siteId?: string }) => {
      const promises = [updateBayMutation.mutateAsync(data)]
      if (selectedRangeId && data.siteId !== selectedRangeToptracerSiteId) {
        promises.push(
          updateRangeSiteIdMutation.mutateAsync({
            id: selectedRangeId,
            toptracer_site_id: data.siteId,
          })
        )
      }
      const responses = await Promise.all(promises)
      return responses
    },
    [updateBayMutation, selectedRangeId, updateRangeSiteIdMutation, selectedRangeToptracerSiteId]
  )

  const handleBayBulkUpdate = useCallback(
    async (data: UpdateBayBulkPayload & { siteId?: string }) => {
      const promises = [updateBayBulkMutation.mutateAsync(data)]
      if (selectedRangeId && data.siteId !== selectedRangeToptracerSiteId) {
        promises.push(
          updateRangeSiteIdMutation.mutateAsync({
            id: selectedRangeId,
            toptracer_site_id: data.siteId,
          })
        )
      }
      const responses = await Promise.all(promises)
      return responses
    },
    [
      updateBayBulkMutation,
      selectedRangeId,
      updateRangeSiteIdMutation,
      selectedRangeToptracerSiteId,
    ]
  )

  const handleBayCreate = useCallback(
    async (data: (CreateBayPayload | CreateBayBulkPayload) & { siteId?: string }) => {
      const { numberOfBays } = data as CreateBayBulkPayload
      const promises = []
      if (numberOfBays && numberOfBays > 1) {
        promises.push(createBayBulkMutation.mutateAsync(data as CreateBayBulkPayload))
      } else {
        promises.push(createBayMutation.mutateAsync(data))
      }

      if (selectedRangeId && data.siteId !== selectedRangeToptracerSiteId) {
        promises.push(
          updateRangeSiteIdMutation.mutateAsync({
            id: selectedRangeId,
            toptracer_site_id: data.siteId,
          })
        )
      }
      const responses = await Promise.all(promises)
      return responses
    },
    [
      createBayMutation,
      createBayBulkMutation,
      selectedRangeId,
      updateRangeSiteIdMutation,
      selectedRangeToptracerSiteId,
    ]
  )

  // if no tracking provider, display becomes null
  const getDisplayByTrackingTech = useCallback(
    ({
      display,
      trackingTech,
    }: {
      display?: BayScreenDisplay | null
      trackingTech?: TrackingTech | null
    }) => (trackingTech === TrackingTech.TOPTRACER ? display : null),
    []
  )

  // if no tracking provider and display is not toptracer, screen mode becomes null
  const getScreenModeByDisplay = useCallback(
    ({
      display,
      screenMode,
      trackingTech,
    }: {
      display?: BayScreenDisplay | null
      screenMode?: BayScreenMode | null
      trackingTech?: TrackingTech | null
    }) =>
      getDisplayByTrackingTech({ display, trackingTech }) === BayScreenDisplay.TOPTRACER_SCREEN
        ? screenMode
        : null,
    [getDisplayByTrackingTech]
  )

  // returns all bay data merged for bulk editing
  // it compares the fields of all bays
  // if a field is the same for all bays, it will return the field value
  // if a field is different for the bays, it will return undefined for that field
  const mergeBays = useCallback(
    (bays?: BayTypeV2[]): MergedBaysType => {
      if (!bays || !bays.length) return {}
      const mixedValue = { value: undefined, isMixed: true }

      const mergedBays = bays.reduce<MergedBaysType>(
        (acc, bay) => {
          const display = getDisplayByTrackingTech({
            display: bay.ball_tracking_technology.display,
            trackingTech: bay.ball_tracking_technology.tracking_provider,
          })
          const screenMode = getScreenModeByDisplay({
            trackingTech: bay.ball_tracking_technology.tracking_provider,
            display: bay.ball_tracking_technology.display,
            screenMode: bay.ball_tracking_technology.screen_mode,
          })

          return {
            floor: acc.floor?.value === bay.floor ? acc.floor : mixedValue,
            stance: acc.stance?.value === bay.stance ? acc.stance : mixedValue,
            roof: acc.roof?.value === bay.amenities.roof ? acc.roof : mixedValue,
            trackingTech:
              acc.trackingTech?.value === bay.ball_tracking_technology.tracking_provider
                ? acc.trackingTech
                : mixedValue,
            display: acc.display?.value === display ? acc.display : mixedValue,
            screenMode: acc.screenMode?.value === screenMode ? acc.screenMode : mixedValue,
            trmsNumbers: {
              ...acc.trmsNumbers,
              [bay.bay_number]: bay.ball_tracking_technology.api_details.trms_bay_number,
            },
          }
        },
        {
          floor: { value: bays[0].floor, isMixed: false },
          stance: { value: bays[0].stance, isMixed: false },
          roof: { value: bays[0].amenities.roof, isMixed: false },
          trackingTech: {
            value: bays[0].ball_tracking_technology.tracking_provider,
            isMixed: false,
          },
          display: {
            value: getDisplayByTrackingTech({
              display: bays[0].ball_tracking_technology.display,
              trackingTech: bays[0].ball_tracking_technology.tracking_provider,
            }),
            isMixed: false,
          },
          screenMode: {
            value: getScreenModeByDisplay({
              display: bays[0].ball_tracking_technology.display,
              trackingTech: bays[0].ball_tracking_technology.tracking_provider,
              screenMode: bays[0].ball_tracking_technology.screen_mode,
            }),
            isMixed: false,
          },
          trmsNumbers: {},
        }
      )

      return mergedBays
    },
    [getDisplayByTrackingTech, getScreenModeByDisplay]
  )

  const constructUpdateBayBulkPayload = useCallback(
    (values: MergedBaysType & { siteId?: string }): UpdateBayBulkPayload & { siteId?: string } => {
      const bayNumbers = selectedBays.map((bay) => bay.bay_number)
      return {
        rangeId: selectedRangeId as string,
        siteId: values.siteId,
        bayNumbers,
        trmsBayNumbers: bayNumbers.map((bayNumber) => values.trmsNumbers?.[bayNumber] || null),
        bay: {
          floor: values.floor?.isMixed ? null : values.floor?.value,
          stance: values.stance?.isMixed ? null : values.stance?.value,
          amenities: {
            roof: values.roof?.isMixed ? null : (values.roof?.value as boolean),
          },
          ball_tracking_technology: {
            api_details: {
              trms_bay_number: null,
            },
            display: values.display?.isMixed ? null : values.display?.value,
            screen_mode: values.screenMode?.isMixed ? null : values.screenMode?.value,
            tracking_provider: values.trackingTech?.isMixed
              ? null
              : (values.trackingTech?.value as TrackingTech),
          },
        },
      }
    },
    [selectedRangeId, selectedBays]
  )

  return {
    bays: (data as { data: BayTypeV2[] })?.data || [],
    areBaysLoading,
    areBaysFetching,
    selectedBays,
    setSelectedBays,
    refetch,
    updateBay: handleBayUpdate,
    createBay: handleBayCreate,
    updateBayBulk: handleBayBulkUpdate,
    checkForErrors,
    mergeBays,
    constructUpdateBayBulkPayload,
  }
}

export default useBays
