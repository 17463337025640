import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _isEqual from 'lodash/isEqual'
import m from 'moment'
import style from './style.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { withTranslation } from 'react-i18next'

// Copy of sweetspot-react version
class MiniCalendar extends Component {
  constructor(props) {
    super(props)

    let date = this.props.initialDate ? this.props.initialDate : DateHelpers.today()

    this.state = {
      currentDate: date,
      selectedDate: date,
      isOpen: true,
    }

    this.nextMonth = this.nextMonth.bind(this)
    this.previousMonth = this.previousMonth.bind(this)
    this.getDate = this.getDate.bind(this)
    this.getDateString = this.getDateString.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { initialDate } = this.props
    if (initialDate && !_isEqual(initialDate, prevProps.initialDate) && m(initialDate).isValid()) {
      this.setDate(initialDate)
    }
  }

  handleOnClickDate = (date) => {
    const { selectedDate } = this.state
    const { onDateChange, startDate, endDate } = this.props

    if (startDate && !m(date).isSameOrAfter(startDate, 'day')) return
    if (endDate && m(date).isSameOrAfter(endDate, 'day')) return

    if (selectedDate !== date && onDateChange) {
      this.setState(
        {
          selectedDate: date,
          currentDate: date,
        },
        () => {
          onDateChange(date, this.getDateString())
        }
      )
    }
  }

  getDate() {
    return this.state.selectedDate
  }

  getDateString() {
    const { returnDateFormat, lang } = this.props
    const { selectedDate } = this.state
    if (returnDateFormat && m(selectedDate).format(returnDateFormat) !== 'Invalid date') {
      return m(selectedDate).format(returnDateFormat)
    }
    return DateHelpers.toWeekdayDateString(selectedDate, lang)
  }

  setDate(date) {
    this.setState({
      currentDate: date,
      selectedDate: date,
    })
  }

  nextMonth(e) {
    if (typeof this.props.onMonthChange === 'function') {
      e.stopPropagation()
      e.preventDefault()
      this.props.onMonthChange()
    }
    this.setState({
      currentDate: DateHelpers.nextMonth(this.state.currentDate),
    })
  }

  previousMonth(e) {
    if (typeof this.props.onMonthChange === 'function') {
      e.stopPropagation()
      e.preventDefault()
      this.props.onMonthChange()
    }
    this.setState({
      currentDate: DateHelpers.previousMonth(this.state.currentDate),
    })
  }

  renderHeader() {
    let monthString =
      capitalize(DateHelpers.getMonthName(this.state.currentDate, this.props.lang)) +
      ' ' +
      this.state.currentDate.getFullYear()

    return (
      <div className={style.headerContainer}>
        <div className={style.arrowContainer} onClick={this.previousMonth}>
          <div className={style.arrow}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>

        <div className={style.monthContainer}>
          <div className={style.month}>{monthString}</div>
        </div>

        <div className={style.arrowContainer} onClick={this.nextMonth}>
          <div className={style.arrow}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    )
  }

  renderCalendar() {
    const { startDate, endDate, t } = this.props

    let _style = {
      container: {},
    }

    if (!this.state.isOpen) _style.container.height = 0

    let labels = [
      'dateTime.shortDays.monday',
      'dateTime.shortDays.tuesday',
      'dateTime.shortDays.wednesday',
      'dateTime.shortDays.thursday',
      'dateTime.shortDays.friday',
      'dateTime.shortDays.saturday',
      'dateTime.shortDays.sunday',
    ]
    let days = DateHelpers.getMonthCalendar(this.state.currentDate)

    return (
      <div className={style.calendarContainer} style={_style.container}>
        <div className={style.calendarLabels}>
          {labels.map((label, key) => {
            return <div key={key}> {t(label)} </div>
          })}
        </div>
        <div className={style.calendarDays}>
          {days.map((day, key) => {
            let classNames = [style.dayPanel]
            if (day.getMonth() !== this.state.currentDate.getMonth()) {
              classNames.push(style.inactive)
            }
            if (DateHelpers.isSameDate(day, this.state.selectedDate)) {
              classNames.push(style.selected)
            }
            if (DateHelpers.isSameDate(day, new Date())) {
              classNames.push(style.today)
            }
            if (startDate && !m(day).isSameOrAfter(startDate, 'day')) {
              classNames.push(style.disabled)
            }
            if (endDate && m(day).isSameOrAfter(endDate, 'day')) {
              classNames.push(style.disabled)
            }

            return (
              <div
                className={classNames.join(' ')}
                onClick={() => this.handleOnClickDate(day)}
                key={key}
              >
                <div className={style.dayText}>{day.getDate()}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onMouseOver = (e) => {
    this.props.onMouseInOut(false)
    e.stopPropagation()
    e.preventDefault()
  }

  onMouseOut = (e) => {
    this.props.onMouseInOut(true)
    e.stopPropagation()
    e.preventDefault()
  }

  render() {
    let classNames = [style.container]
    if (this.props.blueStyle) classNames.push(style.blueStyle)

    return (
      <div
        className={classNames.join(' ')}
        ref={this.props.onRef}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
      >
        {this.renderHeader()}
        {this.renderCalendar()}
      </div>
    )
  }
}

MiniCalendar.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  lang: PropTypes.string.isRequired,
  blueStyle: PropTypes.bool,
  returnDateFormat: PropTypes.string,
  onRef: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onMouseInOut: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.me.lang,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation()(MiniCalendar)
)
