import { Divider } from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'

type Props = {
  numberOfBays?: number | string
  bayNumber?: number
}

const Summary = ({ bayNumber, numberOfBays }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col">
      <div className="pt-md pb-xl pl-2">
        <p className="text-content-sm font-bold">{t('settings.bays.bayNr')}</p>
        <p className="text-content-base font-regular uppercase">
          {numberOfBays && +numberOfBays > 1 ? t('words.bulk') : bayNumber}
        </p>
      </div>
      <Divider />
    </div>
  )
}

export default Summary
