import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FirstPaymentPercentageAndAmount,
  FirstPaymentDuration,
  LastPaymentDuration,
  ToggleInput,
} from '../../../components/Settings'
import {
  Accordion,
  AccordionLabel,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  InputBase,
  TextareaContainer,
  TextareaCounter,
  Textarea,
} from '@sweetspot/scramble-ds'
import { PaymentLinkSettingsProps } from '../../types'
import { calculateDaysDifferenceBetweenStartAndExpiryDate } from '../../../utils'
import { useSelector } from 'react-redux'
import { Club } from '@sweetspot/shared/types'
export const PaymentLinkSettings = ({
  settingsForm,
  bookingFields,
  booking,
}: PaymentLinkSettingsProps) => {
  const { t } = useTranslation()
  const [accordionValue, setAccordionValue] = useState<string[]>(['accordion-1'])
  const currentClub = useSelector(
    ({ golfClub }: { golfClub: { list: Club[]; selectedId: number } }) =>
      golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )
  const note = settingsForm.watch('note')
  const showPartialPayments = settingsForm.watch('partial_payments')
  const { daysDifference } = calculateDaysDifferenceBetweenStartAndExpiryDate(
    booking?.booking?.start_time,
    booking?.course?.timezone
  )

  const hasClubPaymentLinkTheme = !!currentClub?.payment_link_theme_id

  return (
    <>
      <ToggleInput
        control={settingsForm.control}
        name="partial_payments"
        text={t('paymentLinks.acceptPartialPayment')}
        infoText={t('paymentLinks.splitTotalAmount')}
        disabled={daysDifference === 0}
      />
      {showPartialPayments && daysDifference > 0 && (
        <div className="mb-lg flex w-full flex-col">
          <p className="text-content-sm mb-xs font-bold leading-5">
            {t('paymentLinks.firstPaymentLink')}
          </p>
          <p className="text-content-sm mb-xs leading-5">{t('paymentLinks.amountAndValidity')}</p>
          <div className="bg-background-mono-lighter border-stroke-pale flex flex-col rounded-md border-2">
            <FirstPaymentPercentageAndAmount
              control={settingsForm.control}
              bookingFields={bookingFields}
              setValue={settingsForm.setValue}
            />
            <FirstPaymentDuration
              control={settingsForm.control}
              setValue={settingsForm.setValue}
              booking={booking}
            />
          </div>
        </div>
      )}
      {daysDifference === 0 && (
        <div className="mb-lg bg-background-sand-lighter border-border-stroke-subtle flex w-full border-spacing-2 flex-col rounded-md border-2 border-dotted px-4 py-3">
          <p className="text-content-sm mb-xs font-bold leading-5">
            {t('paymentLinks.attentionLessThan24h')}
          </p>
          <p className="mb-xs text-[13px] font-medium leading-4">
            {t('paymentLinks.attentionLessThan24hNote')}
          </p>
        </div>
      )}
      <div className="mb-lg flex w-full flex-col">
        <p className="text-content-sm mb-xs font-bold leading-5">
          {t(
            showPartialPayments
              ? 'paymentLinks.finalPaymentLinkValidity'
              : 'paymentLinks.paymentLinkValidy'
          )}
        </p>
        <div className="bg-background-mono-lighter border-stroke-pale flex flex-col rounded-md border-2">
          <LastPaymentDuration
            control={settingsForm.control}
            setValue={settingsForm.setValue}
            booking={booking}
          />
        </div>
      </div>
      <Accordion
        type="multiple"
        orientation="horizontal"
        value={accordionValue}
        onValueChange={setAccordionValue}
        className="mx-[-24px] mt-2 bg-transparent"
      >
        <AccordionItem value="accordion-1" className="mb-2 w-full max-w-full px-2.5">
          <AccordionTrigger className="py-2">
            <AccordionLabel> {t('paymentLinks.internalNotes')}</AccordionLabel>
          </AccordionTrigger>
          <AccordionContent className="border-t">
            <InputBase className="mt-4 max-h-[190px] py-3 pl-3 pr-2">
              <TextareaContainer maxCharacters={255}>
                <Textarea
                  placeholder={t('paymentLinks.internalNotesInfo')}
                  value={note}
                  onChange={(e) => settingsForm.setValue('note', e.target.value)}
                />
                <TextareaCounter />
              </TextareaContainer>
            </InputBase>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      {!hasClubPaymentLinkTheme && (
        <div className="mb-lg bg-background-sand-lighter border-border-stroke-subtle mt-5 flex w-full border-spacing-2 flex-col rounded-md border-2 border-dotted px-4 py-3">
          <p className="text-content-sm mb-xs font-bold leading-5">
            {t('paymentLinks.noBrandingTile')}
          </p>
          <p className="mb-xs text-[13px] font-medium leading-4">
            {t('paymentLinks.noBrandingText')}
          </p>
        </div>
      )}
    </>
  )
}
