import { QueryOptions, UseQueryResult, useQuery } from 'react-query'
import { MEMBERSHIP_QUERY_KEYS } from '../constants'
import { PendingInvitesParams, getPendingInvites } from '@sweetspot/shared/data-access/api-platform'
import { Hydra, PendingInvite } from '@sweetspot/shared/types'

export interface PendingInvitesProps<TResult> {
  query: PendingInvitesParams
  queryOptions?: QueryOptions
  select?: (data: TResult) => TResult
}

export const usePendingInvites = <TResult = Hydra<PendingInvite>>(
  props: PendingInvitesProps<TResult>
): UseQueryResult<TResult> => {
  return useQuery({
    queryKey: [MEMBERSHIP_QUERY_KEYS.PENDING_INVITES, props.query.page, props.query.club],
    queryFn: () => getPendingInvites(props.query),
    placeholderData: { 'hydra:member': [] },
    ...props.queryOptions,
  })
}
