export enum APP_NAMES {
  CLUB_PORTAL = 'CLUB_PORTAL',
  PARTNER_PORTAL = 'PARTNER_PORTAL',
  WEB_BOOKING = 'WEB_BOOKING',
}

export enum API_PORTAL_VALUES {
  CLUB = 'club',
  PARTNER = 'partner',
}

export enum API_ENV_KEYS {
  PROD = 'PROD',
  SANDBOX = 'SANDBOX',
  DEV = 'DEV',
}

export enum STORAGE_KEYS {
  COPIED_PLAYERS_KEY = 'a69def3c-789e-4d49-876f-f14eacddbfd2',
  DELAYED_MESSAGED_STORE_KEY = '70f2f862-bf33-47f6-bb47-a498d8cd6018',
  PERSIST = 'persist:root',
  API_PLATFORM_URL_KEY = '298f6722-0e5e-41b5-b7fe-19fb71fca98a',
}

// Order is important, has to match BE. Should be implemented in different way once we get GIT API access
export const GIT_CLUB_NAMES = [
  { id: 0, label: 'Björklidens GK' },
  { id: 1, label: 'GolfStar GC' },
  { id: 2, label: 'Gripsholms GK' },
  { id: 3, label: 'Hässelby GK' },
  { id: 4, label: 'Huvudstadens GK' },
  { id: 5, label: 'Viksbergs GK' },
  { id: 6, label: 'Waxholms GK' },
]

export const GENDER_KEYS = [
  {
    gitValue: 0,
    stringValue: 'woman',
    translationKey: 'words.woman',
  },
  {
    gitValue: 1,
    stringValue: 'man',
    translationKey: 'words.man',
  },
  {
    gitValue: null,
    stringValue: 'other',
    translationKey: 'words.other',
  },
]

export const GOLF_ID_OPTIONS = [
  { id: 0, name: 'Optional', value: 'optional' },
  { id: 1, name: 'Required', value: 'required' },
]

export const PRIME_TIME_TYPES = [
  { id: 0, value: 0, name: 'no' },
  { id: 1, value: 1, name: 'yes' },
]

export const REGEXP_PATTERNS = {
  EMOJIS:
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
  EMAIL:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
}

export const TEE_TIME_COLORS = [
  '#a7dec4',
  '#86d1f4',
  '#ffa9a3',
  '#e8bcf8',
  '#ffea9a',
  '#fca169',
  '#cbcbcb',
  '#62a786',
  '#5384bc',
  '#d95c54',
  '#b663d5',
  '#245eac',
  '#e8bf15',
]

export const TEE_TIME_TEXT_COLORS = {
  '#a7dec4': '#171717',
  '#86d1f4': '#171717',
  '#ffa9a3': '#171717',
  '#e8bcf8': '#171717',
  '#ffea9a': '#171717',
  '#fca169': '#171717',
  '#cbcbcb': '#171717',
  '#62a786': '#FFFFFF',
  '#5384bc': '#FFFFFF',
  '#d95c54': '#FFFFFF',
  '#b663d5': '#FFFFFF',
  '#245eac': '#FFFFFF',
  '#e8bf15': '#FFFFFF',
}

export const MAX_IMAGE_SIZE = {
  BYTES: 1e7,
  TEXT: '10MB',
}
