import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Divider,
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputTrailingContainer,
  Tag,
} from '@sweetspot/scramble-ds'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { BayScreenDisplay, BayScreenMode, TrackingTech, Stance } from '@sweetspot/shared/types'

type Props = {
  floor?: number
  stance?: Stance
  roof: boolean
  trackingTech: TrackingTech
  display?: string
  screenMode?: string
  bayNumber: number
  trmsNumber?: string
}

const DetailsView = ({
  floor,
  stance,
  roof,
  trackingTech,
  display,
  screenMode,
  bayNumber,
  trmsNumber,
}: Props) => {
  const { t } = useTranslation()

  const stanceTagVariant = useMemo(
    () => (stance === Stance.BOTH ? 'inverted' : 'warning-filled'),
    [stance]
  )

  const stanceTagIcon = useMemo(
    () => (stance === Stance.BOTH ? 'fa-arrow-right-arrow-left' : `fa-arrow-${stance}-long`),
    [stance]
  )

  const displays = useMemo(
    () => [
      {
        value: BayScreenDisplay.TOPTRACER_SCREEN,
        label: t('settings.bays.toptracerScreen'),
      },
      {
        value: BayScreenDisplay.PLAYER_DEVICE,
        label: t('settings.bays.playerDevice'),
      },
    ],
    [t]
  )

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col gap-4">
        <InputBase>
          <InputLabelContainer>{t('words.floor')}</InputLabelContainer>
          <Tag size="large" variant="inverted" className="mt-sm px-2">
            <i className="fa-regular fa-layer-group" />
            <span className="px-sm">{floor}</span>
          </Tag>
        </InputBase>
        <InputBase>
          <InputLabelContainer>{t('settings.bays.stance')}</InputLabelContainer>
          <Tag size="large" variant={stanceTagVariant} className="mt-sm text-text-dark px-2">
            <i className={`fa-regular ${stanceTagIcon}`} />
            <span className="px-sm">{t(`settings.bays.${stance}`)}</span>
          </Tag>
        </InputBase>
        <div>
          <div className={'text-content-sm pl-2 font-bold'}>{t('words.amenities')}</div>
          {roof ? (
            <Tag size="large" variant="inverted" className="mt-sm px-2">
              <i className="fa-regular fa-person-shelter" />
              <span className="px-sm">{t('settings.bays.roof')}</span>
            </Tag>
          ) : (
            <Tag size="large" variant="inverted" className="mt-sm bg-transparent px-2">
              <i className="fa-regular fa-dash" />
            </Tag>
          )}
        </div>

        <Divider />

        <div className="flex w-full flex-col gap-2">
          <div className="text-text-dark text-content-base ml-2 font-bold">
            {t('settings.bays.ballTrackingTechnology')}
          </div>
          <div className="mb-4 flex gap-3">
            {trackingTech === TrackingTech.NONE && (
              <Tag size="large" variant="inverted" className="px-[6px] py-1">
                <span className="px-sm">{capitalize(trackingTech)}</span>
              </Tag>
            )}

            {trackingTech === TrackingTech.TOPTRACER && (
              <>
                <Tag size="large" variant="inverted" className="px-[6px] py-1">
                  <i className={`fa-regular fa-${display === 'player' ? 'mobile' : 'tv'}`} />
                  <span className="px-sm">
                    {display
                      ? displays.find(({ value }) => value === display)?.label
                      : capitalize(trackingTech)}
                  </span>
                </Tag>
                {display === BayScreenDisplay.TOPTRACER_SCREEN && screenMode && (
                  <Tag size="large" variant="inverted" className="px-[6px] py-1">
                    <i
                      className={`fa-regular fa-${
                        screenMode === BayScreenMode.LOCKED ? 'lock' : 'lock-open'
                      }`}
                    />
                    <span className="px-sm">
                      {screenMode === BayScreenMode.LOCKED
                        ? t('words.locked')
                        : t('words.unlocked')}
                    </span>
                  </Tag>
                )}
              </>
            )}
          </div>

          {trackingTech === TrackingTech.TOPTRACER &&
            display === BayScreenDisplay.TOPTRACER_SCREEN &&
            trmsNumber && (
              <>
                <div className="flex flex-col gap-2">
                  <div className="text-text-dark text-content-sm ml-2 font-bold">
                    {t('settings.bays.apiDetails')}
                  </div>
                </div>

                <InputBase>
                  <InputLabelContainer className="mb-0.5">
                    {`${t('settings.bays.bay')} ${bayNumber} - ${t('settings.bays.trmsBayNumber')}`}
                  </InputLabelContainer>
                  <div className="flex w-full gap-2">
                    <InputContainer>
                      <InputLeadingContainer className="left-2">
                        <i className="fa-regular fa-link" />
                      </InputLeadingContainer>
                      <Input
                        value={trmsNumber}
                        className="text-content-base h-8 border pl-10"
                        placeholder={t('sentences.typeValue')}
                        disabled
                      />
                      <InputTrailingContainer>
                        <i className="fa-regular fa-lock" />
                      </InputTrailingContainer>
                    </InputContainer>
                  </div>
                </InputBase>
              </>
            )}
        </div>
      </div>
    </div>
  )
}

export default DetailsView
