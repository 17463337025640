import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Space } from '@sweetspot/shared/types'
import { IMoveBookingStep } from '../../../types'
import AvailabilityChecker from '../../../../AvailabilityChecker'
import SpaceSelector from '../../../../SpaceSelector'
import ItemBox from '../../../../ItemBox'

import styles from './styles.module.scss'

export const MoveBookingStep = ({
  orderBooking,
  venue,
  date,
  setDate,
  dateFormatted,
  time,
  setTime,
  availableSpaces,
  selectedSpaces,
  handleCheckAvailability,
  handleSelectSpace,
  isChecking,
  errors,
}: IMoveBookingStep) => {
  const { t } = useTranslation()

  return (
    <ItemBox className={cx(styles.container, styles.open)}>
      <h1 className="text-xl font-semibold">{t('sentences.moveBooking')}</h1>

      <AvailabilityChecker
        date={date}
        setDate={setDate}
        dateFormatted={dateFormatted}
        time={time}
        setTime={setTime}
        orderBooking={orderBooking}
        venue={venue}
        onCheckAvailability={handleCheckAvailability}
        errors={errors}
        isChecking={isChecking}
      />

      <h2 className="text-lg font-semibold">
        {t('words.spaces')} ({orderBooking.spaces.length || 0})
      </h2>
      {orderBooking.spaces.map((space: Space, index: number) => (
        <SpaceSelector
          key={space.id}
          index={index}
          orderBooking={orderBooking}
          venue={venue}
          availableSpaces={availableSpaces}
          selectedSpaces={selectedSpaces}
          onSelectSpace={handleSelectSpace}
          date={date}
          time={time}
        />
      ))}
    </ItemBox>
  )
}
