import { createContext, useContext } from 'react'
import { createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface InputSelectInterface {
  open: boolean
  setOpen: (open: boolean) => void
  withSearch?: boolean
  setWithSearch: (withSearch: boolean) => void
}

export const createInputSelectStore = (initProps?: Partial<InputSelectInterface>) => {
  const DEFAULT_PROPS = {
    open: false,
    withSearch: false,
  }

  return createStore(
    immer<InputSelectInterface>((set) => ({
      ...DEFAULT_PROPS,
      ...initProps,
      setOpen: (open) => {
        set((draft) => {
          draft.open = open
        })
      },
      setWithSearch: (withSearch) => {
        set((draft) => {
          draft.withSearch = withSearch
        })
      },
    }))
  )
}

export const InputSelectContext = createContext<ReturnType<typeof createInputSelectStore> | null>(
  null
)

export function useInputSelectStoreContext() {
  const context = useContext(InputSelectContext)
  if (!context) {
    throw new Error('useInputSelectStoreContext must be used within a InputSelectProvider')
  }
  return context
}
