import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { track } from '@amplitude/analytics-browser'

import { useTranslation } from 'react-i18next'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'

import styles from './styles.module.scss'

const ReserveNewBookingButton = ({ teeTime, teeTimePrice, loaders, onClick, className }) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.container, className)}>
      <p className={cx(styles.title)}>{t('sentences.newBookingNumberOfSlots')}</p>
      <div className={cx(styles.buttonsContainer)}>
        {[...Array(teeTime ? teeTime?.max_slots : 4)].map((_, i) => {
          return (
            <Button
              className={cx(styles.button)}
              key={i}
              width="45px"
              onClick={() => {
                track({
                  event_type: AMPLITUDE_EVENTS.TEE_TIME_SLOT_SELECTED,
                  event_properties: {
                    [AMPLITUDE_EVENTS.TEE_SLOTS_ADDED]: i + 1,
                  },
                })
                onClick(i + 1)
              }}
              disabled={
                loaders.reservingBooking ||
                !teeTime ||
                !teeTimePrice ||
                i + 1 > teeTime.available_slots
              }
              theme="default"
              disabledTheme="gray-outline"
              text={`${i + 1}`}
            />
          )
        })}
      </div>
    </div>
  )
}

ReserveNewBookingButton.propTypes = {
  className: PropTypes.string,
  teeTime: PropTypes.shape({
    available_slots: PropTypes.number.isRequired,
    max_slots: PropTypes.number.isRequired,
  }),
  teeTimePrice: PropTypes.shape({
    price: PropTypes.shape({ amount: PropTypes.number, currency: PropTypes.string }),
    dynamic_price: PropTypes.shape({ amount: PropTypes.number, currency: PropTypes.string }),
  }),
  loaders: PropTypes.shape({
    reservingBooking: PropTypes.bool,
  }),
  onClick: PropTypes.func,
}

ReserveNewBookingButton.defaultProps = {}

export default ReserveNewBookingButton
