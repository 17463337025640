import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as SuccessIcon } from '@sweetspot/club-portal-legacy/resources/images/green-check.svg'
import { ReactComponent as InfoIcon } from '@sweetspot/club-portal-legacy/resources/images/info_icon.svg'
import { ReactComponent as ErrorIcon } from '@sweetspot/club-portal-legacy/resources/images/red-stop.svg'
import { ReactComponent as WarningIcon } from '@sweetspot/club-portal-legacy/resources/images/warning-icon.svg'

import { ReactComponent as CloseIcon } from '@sweetspot/club-portal-legacy/resources/images/cross.svg'

import styles from './styles.module.scss'

const CustomToast = ({ children, appearance, onDismiss, transitionState }) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.exited]: transitionState === 'exited',
        [styles.entering]: transitionState === 'entering',
        [styles.entered]: transitionState === 'entered',
        [styles.exiting]: transitionState === 'exiting',
      })}
    >
      <div className={styles.iconContainer}>
        {appearance === 'success' && (
          <SuccessIcon className={cx(styles.statusIcon, styles.success)} />
        )}
        {appearance === 'error' && <ErrorIcon className={cx(styles.statusIcon, styles.error)} />}
        {appearance === 'warning' && (
          <WarningIcon className={cx(styles.statusIcon, styles.warning)} />
        )}
        {appearance === 'info' && <InfoIcon className={cx(styles.statusIcon, styles.info)} />}
      </div>
      <div className={styles.inner}>{children}</div>
      <div className={styles.closeContainer}>
        <CloseIcon className={styles.closeIcon} onClick={onDismiss} />
      </div>
    </div>
  )
}

CustomToast.propTypes = {
  appearance: PropTypes.string,
  onDismiss: PropTypes.func,
  transitionState: PropTypes.string,
}

export default CustomToast
