import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  InformationField,
  SignUpDetails,
} from '@sweetspot/club-portal-legacy/modals/MembershipSignupsModal/types'
import FieldRenderer from './FieldRenderer'

import style from './style.module.scss'

const playerDetails: InformationField[] = [
  { id: 1, text: 'firstName', value: 'firstName' },
  { id: 2, text: 'lastName', value: 'lastName' },
  { id: 3, text: 'communication.email', value: 'email' },
  { id: 4, text: 'phone', value: 'phone' },
  { id: 5, text: 'settings.playerCard.birthDate', value: 'dateOfBirth' },
]

const guardianDetails: InformationField[] = [
  { id: 1, text: 'firstName', value: 'firstName' },
  { id: 2, text: 'lastName', value: 'lastName' },
  { id: 3, text: 'golfId', value: 'golfId' },
  { id: 4, text: 'phone', value: 'phone' },
  { id: 5, text: 'communication.email', value: 'email' },
]

type Props = {
  playerData: SignUpDetails | object
}

const PlayerDetails: React.FC<Props> = ({ playerData }) => {
  const { t } = useTranslation()
  const renderPlayerDetails = (fieldsToRender: InformationField[]) => {
    return (
      <div className={style.sectionContainer}>
        {fieldsToRender.map((field) => (
          <FieldRenderer key={field.id} field={field} data={playerData} />
        ))}
      </div>
    )
  }
  const renderGuardianDetails = (fieldsToRender: InformationField[]) => {
    return (
      <div>
        <label className={style.sectionHeader}>{t('settings.playerCard.guardianInfo')}</label>
        <div className={style.sectionContainer}>
          {fieldsToRender.map((field) => (
            <FieldRenderer
              key={`${field.text}-${field.id}`}
              field={field}
              data={('guardian' in playerData && playerData?.guardian) || {}}
            />
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className={style.container}>
      <div className={style.playerDetailsContainer}>
        {renderPlayerDetails(playerDetails)}
        {renderGuardianDetails(guardianDetails)}
      </div>
    </div>
  )
}

export default PlayerDetails
