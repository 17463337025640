import React from 'react'
import { t } from 'i18next'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/pro-regular-svg-icons'

import styles from './styles.module.scss'

const NoPartnerships = (props) => {
  return (
    <div className={styles.container}>
      <FontAwesomeIcon className={styles.logo} icon={faHandshake} />
      <h2 className={styles.text}>
        <Text textId="partnerAdmin.noPartnerships" />
      </h2>
      <div className={styles.buttonContainer}>
        <button className="system-button primary md-32" onClick={props.onClick}>
          {t('createNew')}
        </button>
      </div>
    </div>
  )
}

export default NoPartnerships
