import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import queryString from 'query-string'

/**
 * Query booking emails
 *
 * @param {*} query
 * @param  {...any} args
 * @returns
 */
export const queryBookingEmails = (
  query = {
    golf_club: undefined,
    status: undefined,
    title: undefined,
    limit: 100,
    page: 1,
  },
  ...args
) => {
  const url = queryString.stringifyUrl({
    url: `${getApiPlatformBaseUrl()}/bookings/emails`,
    query,
  })
  return request(url, ...args)
}
