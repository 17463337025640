import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation()
  return (
    <div className={'flex flex-row items-center border-b-[1px] px-6 py-4'}>
      <p className={'flex-1 text-lg font-bold'}>
        {t('words.settings')}: {t('words.drivingRange')}/{t('sentences.ballDispensers')}
      </p>
    </div>
  )
}

export default Header
