import { useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import _orderBy from 'lodash/orderBy'

import styles from './styles.module.scss'

import Button from '@sweetspot/sweetspot-js/common/components/Button'

import { ReactComponent as PartnershipIcon } from '@sweetspot/sweetspot-js/assets/svgs/shake-hands-fill.svg'

import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

import {
  assignPartnershipToBooking,
  removePartnershipFromBooking,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'

import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

const PartnershipBookingSelect = ({
  booking,
  partnerships,
  loading,
  className,
  onRequestBookingUpdate,
  canEdit,
  withIcon,
  buttonStyle,
  buttonTheme,
  applyOnSelect,
  onSelect,
  selectedItem,
  applyloading,
  forceCloseDropdown,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [currentPartnership, setCurrentPartnership] = useState(null)
  const [loadingInternal, setLoadingInternal] = useState(false)

  useEffect(() => {
    if (booking?.partnership) {
      setCurrentPartnership(booking.partnership)
    } else {
      setCurrentPartnership(null)
    }
  }, [booking])

  const handleSelectPartnership = async (item) => {
    setLoadingInternal(true)

    const { uuid: bookingUuid } = booking

    if (item.id === 0) {
      const [res, err] = await to(removePartnershipFromBooking(bookingUuid))

      if (!res || err) {
        addToast(t('sentences.couldNotUnassignPartnership'), { appearance: 'error' })
      }
      onRequestBookingUpdate(bookingUuid).then(() => {
        setLoadingInternal(false)
      })
      return
    }

    const { uuid } = item

    const [res, err] = await to(assignPartnershipToBooking(bookingUuid, uuid))

    if (!res || err) {
      if (err?.violations?.find((x) => x.errorName === 'NOT_ELIGIBLE_PARTNERSHIP_ERROR')) {
        addToast(t('sentences.partershipNotViable'), { appearance: 'error' })
      } else if (
        err?.violations?.find((x) => x.errorName === 'PARTNERSHIP_HAS_BEEN_ASSIGNED_ERROR')
      ) {
        addToast(t('sentences.partnershipAlreadyAssigned'), { appearance: 'warning' })
      } else if (
        err?.message === 'Number of rounds is out of limit' ||
        err?.detail === 'Number of rounds is out of limit'
      ) {
        addToast(t('sentences.maxNumberOfRoundsReached'), { appearance: 'error' })
      } else if (
        err?.message === 'Play value is out of limit' ||
        err?.detail === 'Play value is out of limit'
      ) {
        addToast(t('sentences.maxPlayValueReached'), { appearance: 'error' })
      } else {
        addToast(t('sentences.couldNotAssignPartnership'), { appearance: 'error' })
      }
      setLoadingInternal(false)
      return
    }

    onRequestBookingUpdate(bookingUuid).then(() => {
      setLoadingInternal(false)
    })
  }

  const dropdownOptions = _orderBy(
    partnerships
      .filter((p) => p.id !== currentPartnership?.id)
      .map((partnership) => ({
        id: partnership.id,
        uuid: partnership.uuid,
        label: partnership.name,
      })),
    'label',
    'asc'
  )
  if (currentPartnership) dropdownOptions.push({ id: 0, label: t('words.none') })

  const handleDropdownOptionOnClick = (item) => {
    if (applyOnSelect) {
      handleSelectPartnership(item)
    } else {
      onSelect(item)
    }
  }

  return (
    <div className={cx(styles.container, className)}>
      {withIcon && (
        <div className={cx(styles.iconWrapper)}>
          <PartnershipIcon className={cx(styles.icon, { [styles.visible]: currentPartnership })} />
        </div>
      )}

      <Button
        forceCloseDropdown={forceCloseDropdown}
        disabled={!canEdit}
        loading={(!dropdownOptions && loading) || loadingInternal || applyloading}
        loaderStyle="pulse"
        size="default"
        theme={buttonTheme}
        isSearchable
        dropdownStyle="simple"
        dropdownValue={currentPartnership?.name || selectedItem?.label || t('words.partnerships')}
        className={cx(styles.button, buttonStyle, { [styles.loading]: loading })}
        dropdownOptions={dropdownOptions}
        dropdownOptionOnClick={handleDropdownOptionOnClick}
      />
    </div>
  )
}

PartnershipBookingSelect.propTypes = {
  className: PropTypes.string,
  partnerships: PropTypes.array,
  loading: PropTypes.bool,
  booking: PropTypes.shape({
    partnership: PropTypes.shape({}),
    uuid: PropTypes.string,
  }),
  onRequestBookingUpdate: PropTypes.func,
  canEdit: PropTypes.bool,
  withIcon: PropTypes.bool,
  buttonStyle: PropTypes.string,
  buttonTheme: PropTypes.string,
  applyOnSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.object,
  applyloading: PropTypes.bool,
  forceCloseDropdown: PropTypes.bool,
}

PartnershipBookingSelect.defaultProps = {
  withIcon: true,
  buttonStyle: '',
  buttonTheme: 'gray-outline',
  applyOnSelect: true,
  onSelect: () => {},
  selectedItem: {},
  applyloading: false,
  forceCloseDropdown: false,
}

export default PartnershipBookingSelect
