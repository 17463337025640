import { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import BookingModal from '@sweetspot/club-portal-legacy/modals/BookingModal'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import getBookingWithRelations from '@sweetspot/sweetspot-js/features/bookings/functions/getBookingWithRelations'
import {
  BOOKING_GROUPS,
  BOOKING_RELATIONS,
} from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { ITEM_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/itemRelations'
import styles from './styles.module.scss'

const Booking = () => {
  const history = useHistory()
  const { orderUuid } = useParams()
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const [teeTimeUuid, setTeetimeUuid] = useState()

  const { data } = useQuery(
    [CLUB_QUERIES.ORDER_BOOKING, orderUuid],
    () => {
      return getBookingWithRelations({
        bookingUuid: orderUuid,
        deserialize: true,
        includedRelations: [
          BOOKING_RELATIONS.BOOKING,
          BOOKING_RELATIONS.ITEMS,
          BOOKING_RELATIONS.PAYMENTS,
          BOOKING_RELATIONS.REFUNDS,
          BOOKING_RELATIONS.PROMOTIONS,
          BOOKING_RELATIONS.INVENTORY_SCHEDULES,
        ],
        includedBookingGroups: [BOOKING_GROUPS.ALL],
        includedItemGroups: [ITEM_GROUPS.ALL],
      })
    },
    {
      refetchOnMount: 'always',
      enabled: !!orderUuid,
      onSuccess: (data) => {
        setTeetimeUuid(data?.items?.[0]?.slot?.tee_time.uuid)
      },
      onError: (err) => {
        addToast(t('sentences.couldNotLoadBooking'), { appearance: 'error' })
      },
    }
  )

  const closePopup = () => {
    history.push('/')
  }

  return (
    <div className={styles.modalWrapper}>
      {data && (
        <BookingModal
          teeTimeUuid={teeTimeUuid}
          onClose={() => closePopup()}
          reserve={false}
          fromBookingsPage={true}
          singleBooking={data}
          openHistorical={data.state === 'canceled' ? true : false}
        />
      )}
    </div>
  )
}

export default Booking
