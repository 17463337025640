import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import cx from 'classnames'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import DOMHelpers from '@sweetspot/club-portal-legacy/helpers/DOMHelpers'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

/**
 * This component creates a text input used to search for items
 */
export default class SearchInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      showInput: false,
    }

    this.toggleInput = this.toggleInput.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    if (this.props.autoFocus) this.toggleInput()
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  componentDidUpdate(prevProps) {
    const { autoFocus, searchCompleted } = this.props
    if (searchCompleted !== prevProps.searchCompleted && searchCompleted) {
      this.setState({
        searchText: '',
        showInput: false,
      })
    }
    if (prevProps.autoFocus !== autoFocus && autoFocus) this.toggleInput()
  }

  /**
   * Shows or hides the input
   */
  toggleInput() {
    if (!this.state.showInput) {
      this.setState(
        {
          showInput: true,
        },
        () => {
          if (this.props.onFocus) this.props.onFocus(true)
          this._input.focus()
        }
      )
    }
  }

  /**
   * Hides the input if the user clicks outside of it
   *
   * @param {MouseEvent} event
   */
  handleClick(event) {
    if (this.state.searchText === '') {
      if (!DOMHelpers.didClickInside(this._container, event)) {
        if (this.props.onFocus) this.props.onFocus(false)
        this.setState({
          showInput: false,
          searchText: '',
        })
      }
    }
  }

  /**
   * Runs when the user inputs text
   *
   * @param {*} event
   */
  handleChange(event) {
    if (this.props.autoFormatPhone) {
      Utilities.autoFormatNumber(event)
    }
    let text = event.target.value
    this.setState({
      searchText: text,
    })
    if (this.props.onChange) {
      this.props.onChange(text)
    }
  }

  renderInput() {
    if (this.state.showInput) {
      return (
        <div className={cx(style.inputContainer, this.props.inputClassName)}>
          <input
            value={this.state.searchText}
            onChange={this.handleChange}
            onKeyPress={this.props.onKeyPress}
            ref={(input) => (this._input = input)}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
      )
    } else {
      return (
        <div className={style.placeholder}>
          {(this.props.placeholder && this.props.placeholder.substring(0, 1)) === '.' ? (
            <Text textId={this.props.placeholder} />
          ) : (
            <div>{this.props.placeholder}</div>
          )}
        </div>
      )
    }
  }

  render() {
    const { styles, className, isBordered } = this.props

    let containerClassNames = [style.container]
    if (className) containerClassNames.push(className)
    if (isBordered) containerClassNames.push(style.bordered)

    return (
      <div
        style={styles}
        className={containerClassNames.join(' ')}
        onClick={this.toggleInput}
        ref={(container) => (this._container = container)}
      >
        <div className={cx(style.iconContainer, this.props.iconClassName)}>
          <div>{this.props.icon || <FontAwesomeIcon icon={faMagnifyingGlass} />}</div>
        </div>
        {this.renderInput()}
      </div>
    )
  }
}

SearchInput.propTypes = {
  /**
   * Additional class name for the component
   */
  className: PropTypes.string,
  /**
   * Additional style name for the container
   */
  styles: PropTypes.object,
  /**
   * Placeholder text
   *
   * If the text starts with a dot (.) it will be used as a *textId* in a **Text** component
   */
  placeholder: PropTypes.string,
  /**
   * Autoformat input phone number
   *   */
  autoFormatPhone: PropTypes.bool,
  /**
   * Function to run when the user inputs text
   *
   * @param {String} text user input
   */
  onChange: PropTypes.func,
  /**
   * Function to run when the user presses the key
   *
   * @param {String} text user input
   */
  icon: PropTypes.element,
  iconClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  isBordered: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  searchCompleted: PropTypes.bool,
  autoFocus: PropTypes.bool,
}
