import { eachDayOfInterval, endOfWeek, format, formatISO, isAfter, isBefore } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { enGB, sv } from 'date-fns/locale'
import moment from 'moment'
import 'moment-timezone'
import 'moment/min/locales.min'

import { startOfWeek } from 'date-fns'
import { getLang } from '../languages'
import { toDateObject } from '../pages/TeeTimePeriods/context/useWeekPeriod/utils'
import sh from './StringHelpers'
import Utilities from './Utilities'

const CALENDAR_DAY_AMOUNT = 7 * 6

export default class DateHelpers {
  static today() {
    let today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)

    return today
  }

  static isSameDate(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  static toDateString(date, lang) {
    let monthStr = sh.capitalize(DateHelpers.getMonthName(date, lang))
    let dateStr = date.getDate()
    let yearStr = date.getFullYear()

    return `${monthStr} ${dateStr} ${yearStr}`
  }

  static toWeekdayDateString(date, lang) {
    let monthStr = sh.capitalize(DateHelpers.getMonthName(date, lang))
    let dateStr = date.getDate()
    let dayStr = this.getDayName(date, lang)
    return `${dayStr}, ${dateStr} ${monthStr} `
  }

  static toBFDateString(date, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(date).format('MMM Do YYYY')
    return this.jsUcfirst(newDate)
  }

  static toPTDateString(date, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(new Date(date)).format('MMMM Do')
    return this.jsUcfirst(newDate)
  }

  static toPTFormDateString(date, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(date).format('YYYY MM DD')
    return newDate
  }

  static toPODateString(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  static toPODateStringWithTimezone(date, timezone = 'Europe/Stockholm') {
    return moment(date).tz(timezone).format('YYYY-MM-DD')
  }

  static toFullDateString(date, lang) {
    moment.locale(this.convertToMomentLang(lang))
    const newDate = moment(date).format('YYYY-MM-DD  HH:mm')
    return this.jsUcfirst(newDate)
  }

  static onlyDateString(date, useUTC = false) {
    if (useUTC) return moment.utc(date).format('YYYY-MM-DD')
    return moment(date).format('YYYY-MM-DD')
  }

  static dateStringwithMinutes(date) {
    return moment(date).format('YYYY-MM-DD HH:mm')
  }

  static toPHDateString(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  static toDateStringWithTime(date) {
    return moment(date).format('DD-MM HH:mm')
  }

  static toDateStringWithTimezone(date, timezone = 'Europe/Stockholm', format = 'DD-MM HH:mm') {
    return moment.utc(date).tz(timezone).format(format)
  }

  // Function to convert from dateString to Date object. DateString Format: YYYY MM DD
  static toDateObject(dateString) {
    const year = dateString.substr(0, 4)
    const month = dateString.substr(5, 2)
    const day = dateString.substr(8, 2)
    return new Date(year, parseInt(month - 1), day)
  }

  static toISODateTime(date) {
    return moment(date).format('YYYY-MM-DDTHH:MM:SSZ')
  }

  static toDateFromDateString(dateString) {
    const [year, month, day] = dateString.split(' ')[0].split('-')
    const [hour, minute, second = 0] = dateString.split(' ')[1].split(':')

    return new Date(year, parseInt(month - 1), day, hour, minute, second)
  }

  static changeTimezone(date) {
    var SEDate = new Date(
      date.toLocaleString('en-US', {
        timeZone: 'Europe/Stockholm',
      })
    )

    const timeOffset = date.getTime() - SEDate.getTime()

    return new Date(date.getTime() + timeOffset)
  }

  static toDateFromDTString(dateTimeString) {
    const [year, month, day] = dateTimeString.split(' ')[0].split('-')
    const [hour, min, sec = 0] = dateTimeString.split(' ')[1].split(':')
    return new Date(year, parseInt(month - 1), day, hour, min, sec)
  }

  // Function to check if selected period(fromDate, toDate) is valid or not
  static checkValidPeriod(from, to) {
    if (from.getTime() > to.getTime()) return false
    return true
  }

  // Function to check if selected period(fromTime, toTime) is valid or not
  static checkValidTimePeriod(fromTime, toTime, allowEqual = true) {
    if (!fromTime || !toTime || (allowEqual ? fromTime > toTime : fromTime >= toTime)) return false
    return true
  }

  static toTimeString(date) {
    let hours = DateHelpers.prependZero(date.getHours())
    let minutes = DateHelpers.prependZero(date.getMinutes())
    return `${hours}.${minutes}`
  }

  static toDailyTimestamp(date) {
    date.setHours(12)
    date.setMinutes(0)
    date.setSeconds(0)

    return Math.floor(date.getTime() / 1000)
  }

  static toTimestamp(date) {
    return Math.floor(Date.parse(date) / 1000)
  }

  static toMinutes(minutes, hours, days = 0) {
    return minutes + hours * 60 + days * 24 * 60
  }

  static toTime(minutes) {
    minutes = minutes % (24 * 60 * 60)

    let hours = Math.floor(minutes / 60)
    minutes = minutes - hours * 60

    return `${DateHelpers.prependZero(hours)}:${DateHelpers.prependZero(minutes)}`
  }

  static toTimeObject(minutes) {
    minutes = minutes % (24 * 60 * 60)

    let hours = Math.floor(minutes / 60)
    minutes = minutes - hours * 60

    return { hours: DateHelpers.prependZero(hours), minutes: DateHelpers.prependZero(minutes) }
  }

  /**
   * Returns the time
   *
   * @param {Moment} momentObj
   * @return {String} timezone // Course Timezone
   */
  static getCourseTime(momentObj, timezone = 'Europe/Stockholm') {
    const courseTime = moment(momentObj).tz(timezone).format('HH:mm:ss')

    return courseTime
  }

  /**
   * Returns an array of strings of the days of the week in the current language
   *
   * @return {String[]} day strings
   */
  static getDayStrings(lang) {
    const dateStrings = getLang(lang)['date']
    return Object.values(dateStrings['days'])
  }

  static getDayStringsExtra(lang) {
    const dateStrings = getLang(lang)['date']
    let strings = Utilities.deepClone(dateStrings['days'])
    strings.unshift(dateStrings['weekend'])
    strings.unshift(dateStrings['everyDay'])
    return strings
  }

  static getAge(dob) {
    if (!dob) {
      return null
    }

    let then = dob.getTime()
    let now = new Date().getTime()
    let diff = now - then

    return Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
  }

  /**
   * Returns the normalized day number (week starting on monday)
   *
   * @param {Date} date
   * @return {Number} dayNumber
   */
  static getDayNumber(date) {
    let dayNumber = date.getDay() - 1
    if (dayNumber === -1) dayNumber = 6

    return dayNumber
  }

  static getDayName(date, lang) {
    return DateHelpers.getDayStrings(lang)[DateHelpers.getDayNumber(date)]
  }

  static getDateFromDateAndTime(date, time) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const hour = time.hours()
    const minutes = time.minutes()

    const newDate = new Date(year, month, day, hour, minutes)

    return newDate
  }

  static getUTCDateString(date) {
    const newDate = moment(date)
    const UTCDate = newDate.utc().format('YYYY-MM-DD')
    const UTCTime = newDate.utc().format('HH:mm:ss')

    const UTCDateString = `${UTCDate} ${UTCTime}`

    return UTCDateString
  }

  static getLocalDateFromUTCString(UTCDateString) {
    if (!UTCDateString) return null
    const offset = new Date().getTimezoneOffset() / 60

    const [date, time] = UTCDateString.split(' ')
    let [year, month, day] = date.split('-')
    let [hour, minute] = time.split(':')

    hour -= offset
    if (hour >= 24) {
      hour -= 24
      day = parseInt(day) + 1
    } else if (hour < 0) {
      hour += 24
      day -= 1
    }

    return new Date(year, month - 1, day, hour, minute)
  }

  /**
   * Returns the name of the month in the current language
   *
   * @param {Date} date
   * @param {String} lang
   * @return {String} month name
   */
  static getMonthName(date, lang) {
    const dateStrings = getLang(lang)['date']
    let monthNumber = date.getMonth()
    return dateStrings['months'][monthNumber]
  }

  /**
   * Returns an array of dates for the current month in calendar format
   *
   * @param {Date} date
   * @return {Date[]} calendar dates
   */
  static getMonthCalendar(date) {
    let firstDay = new Date(date.getTime())
    firstDay.setDate(1)
    let dayNumber = DateHelpers.getDayNumber(firstDay)

    if (dayNumber === 0) {
      firstDay.setDate(firstDay.getDate() - 7)
    } else {
      firstDay.setDate(firstDay.getDate() - dayNumber)
    }

    let dates = []
    for (let i = 0; i < CALENDAR_DAY_AMOUNT; i++) {
      let date = new Date(firstDay.getTime())
      date.setDate(date.getDate() + i)
      dates.push(date)
    }

    return dates
  }

  static previousDay(date) {
    let newDate = new Date(date.getTime())
    newDate.setDate(date.getDate() - 1)

    return newDate
  }

  static nextDay(date) {
    let newDate = new Date(date.getTime())
    newDate.setDate(date.getDate() + 1)

    return newDate
  }

  static oneMonthPrev(date) {
    date.setMonth(date.getMonth() - 1)

    return date
  }

  static oneMonthForward(date) {
    date.setMonth(date.getMonth() + 1)

    return date
  }

  /**
   * Returns a new Date of first of the next month
   *
   * @param {Date} date
   * @return {Date} next month
   */
  static nextMonth(date) {
    let newDate = new Date(date.getTime())
    newDate.setDate(1)
    newDate.setMonth(newDate.getMonth() + 1)

    return newDate
  }

  /**
   * Returns a new Date of first of the previous month
   *
   * @param {Date} date
   * @return {Date} next month
   */
  static previousMonth(date) {
    let newDate = new Date(date.getTime())
    newDate.setDate(1)
    newDate.setMonth(newDate.getMonth() - 1)

    return newDate
  }

  static prependZero(value) {
    if (value < 10) return '0' + value
    return value
  }

  static convertToMomentLang(lang) {
    return lang === 'se' ? 'sv_SE' : 'en'
  }

  static jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  static parseIncludedDays(rawDays) {
    let days = []
    Object.keys(rawDays).forEach((day) => {
      if (rawDays[day]?.to && rawDays[day]?.from) days.push(day)
    })

    const weekends = days.filter(
      (day) => day.toLowerCase() === 'saturday' || day.toLowerCase() === 'sunday'
    ).length
    const weekdays = days.filter(
      (day) =>
        day.toLowerCase() === 'monday' ||
        day.toLowerCase() === 'tuesday' ||
        day.toLowerCase() === 'wednesday' ||
        day.toLowerCase() === 'thursday' ||
        day.toLowerCase() === 'friday'
    ).length

    if (weekends === 2 && weekdays === 5) {
      return 'date.days.all_days'
    } else if (weekends === 2 && weekdays === 0) {
      return 'date.days.weekend'
    } else if (weekends === 0 && weekdays === 5) {
      return 'date.days.weekday'
    } else {
      return days.map((day) => `date.days.${day.toLowerCase()}`)
    }
  }

  static convertToHIS(string) {
    if (string.length === 5) {
      return `${string}:00`
    }
    return string
  }

  static isValidDateString(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/

    return regEx.test(dateString)
  }

  static isValidDate(dateString, format = 'YYYY-MM-DD') {
    return moment(dateString, format, true).isValid()
  }
}

export const localizedDaysOfWeek = (lang, weekStartsOn = 1) => {
  const locales = { sv, se: sv, en: enGB }
  const today = new Date()

  const weekStart = startOfWeek(today, { weekStartsOn })
  const weekEnd = endOfWeek(today, { weekStartsOn })

  const daysOfWeek = eachDayOfInterval({ start: weekStart, end: weekEnd })
  return daysOfWeek.map((day) => format(day, 'EEEE', { locale: locales[lang] }))
}

export const daysOfWeek = (weekStartsOn = 1) => {
  const today = new Date()

  const weekStart = startOfWeek(today, { weekStartsOn })
  const weekEnd = endOfWeek(today, { weekStartsOn })

  const daysOfWeek = eachDayOfInterval({ start: weekStart, end: weekEnd })
  return daysOfWeek.map((day) => format(day, 'EEEE'))
}

export const convertTimeToTimeZone = (utcTimeString, timezone) => {
  const utcDate = new Date(utcTimeString)
  return formatISO(utcToZonedTime(utcDate, timezone))
}

export const convertDateTimeToTimeZone = (utcDate, timezone) => {
  return utcToZonedTime(utcDate, timezone)
}

export const isOutsideDateRange = (element, startDate, endDate) => {
  if (isBefore(toDateObject(element.from), toDateObject(startDate))) return false
  if (isAfter(toDateObject(element.from), toDateObject(endDate))) return false
  return true
}
