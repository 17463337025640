import React from 'react'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'

const NoPermission = () => {
  return (
    <div className={style.container}>
      <div>
        <h2>
          <Text textId="noPermission.text" />
        </h2>
        <h4>
          <Text textId="noAccess" />
        </h4>
        <h5>
          <Text textId="noPermission.errorCode" />
        </h5>
      </div>
    </div>
  )
}

export default NoPermission
