import { useState, useMemo } from 'react'

import { HeaderOption, HeaderActions } from '../types'

export const useHeaderController = () => {
  const [headerToShow, setHeaderToShow] = useState<HeaderOption>(HeaderOption.DEFAULT)

  const headerActions: HeaderActions = useMemo(() => {
    return {
      setHeader: (header: HeaderOption) => {
        if (
          header !== headerToShow &&
          ([HeaderOption.BOOK, HeaderOption.DEFAULT].includes(headerToShow) ||
            (header !== HeaderOption.BOOK && header !== HeaderOption.DEFAULT))
        ) {
          setHeaderToShow(header)
        }
      },
      reset: () => {
        setHeaderToShow(HeaderOption.DEFAULT)
      },
      isOptionVisible: (header: HeaderOption) => {
        return header === headerToShow
      },
    }
  }, [headerToShow])
  return { headerToShow, headerActions }
}
