import { Space } from '@sweetspot/shared/types'

export enum Violations {
  MOVE_AVAILABILITY_NO_TEE_TIMES = 'errors.noTeeTimes',
  MOVE_AVAILABILITY_INCOMPATIBLE_INTERVALS = 'errors.incompatibleIntervals',
  MOVE_AVAILABILITY_NOT_ENOUGH_SPACES = 'errors.notEnoughSpaces',
  MOVE_AVAILABILITY_DATE_TIME_PAST = 'errors.dateTimePast',
  DATE_NOT_IN_PAST = 'errors.date_time_past',
  INVALID_TIME = 'errors.invalidTime',
}

export enum BookingSteps {
  BOOKING_DETAILS = 'bookingDetails',
  EDIT_SPACES = 'editSpaces',
  MOVE_BOOKING = 'moveBooking',
}

export interface IViolation {
  propertyPath: string
  message: string
  errorName: Violations
}

export interface IAvailabilityChecker {
  date: Date
  setDate: (date: Date) => void
  dateFormatted: string
  time: string
  setTime: (time: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderBooking: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  venue: any
  onCheckAvailability: () => void
  isChecking: boolean
  errors: IViolation[]
}

export interface ISpaceSelector {
  index: number
  availableSpaces: Space[]
  selectedSpaces: Space[]
  onSelectSpace: (space: Space, index: number) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderBooking: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  venue: any
  date: Date
  time: string
}

export interface IOrderSummaryFooter {
  showConfirm: boolean
  showMarkAsPaid: boolean
  showCancel: boolean
  showCancelRefund: boolean
  showIsPaidOnSite: boolean
  showMoveBooking: boolean
  onConfirm: () => void
  onMarkAsPaid: () => void
  onCancel: () => void
  onCancelRefund: () => void
  onMoveBooking: () => void
  onCancelMove: () => void
  confirmDisabled: boolean
  markAsPaidDisabled: boolean
  cancelDisabled: boolean
  cancelRefundDisabled: boolean
  saveChangesDisabled: boolean
  cancelMoveDisabled: boolean
  onBookSpaces: () => void
  bookSpacesBtnLoading: boolean
  bookSpacesDisabled: boolean
  isBookingConfirmed: boolean
  handleEditSpacesButton: () => void
  showMove: boolean
  handleMoveBookingButtonClick: () => void
  onSaveChanges: () => void
  onRevertChanges: () => void
  showSaveChanges: boolean
  revertChangesDisabled: boolean
  showEditSpacesButtons: boolean
  onCancelEditSpacesChanges: () => void
}
