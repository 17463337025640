import React, { useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'
import useViolationToasts from '@sweetspot/sweetspot-js/common/hooks/useViolationToasts'
import { RULE_TYPES } from '../../constants/rules'
import SingleRule from '../SingleRule'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { createNewRule } from '../../services/api-platform'

const RulesWrapper = ({ policy, disabled }) => {
  const { t } = useTranslation()
  const { displayToasts } = useViolationToasts()

  const [rules, setRules] = useState(policy?.rules || [])
  const [showSelectRule, setShowSelectRule] = useState(false)

  const dropdownRef = useRef(null)
  useOnClickOutside(dropdownRef, () => {
    setShowSelectRule(false)
  })

  const addNewRule = async (rule) => {
    if (rules.find((x) => x.type === rule.value)) {
      return
    }

    const type = rule.value
    const newRuleId = 'new-' + crypto.randomUUID()

    let newRule = {
      id: newRuleId,
      type,
    }

    if (type === 'prime_time_rule') {
      newRule = {
        ...newRule,
        configuration: {
          option: null,
        },
      }
    } else if (type === 'venue_rule') {
      newRule = {
        ...newRule,
        configuration: {
          venues: {},
        },
      }
    } else if (type === 'days_and_times_in_week_policy_rule') {
      newRule = {
        ...newRule,
        configuration: {},
      }
    }

    setRules((prev) => [...prev, newRule])
    setShowSelectRule(false)

    const [res, err] = await to(createNewRule(policy.uuid, newRule))
    if (err) {
      displayToasts(err?.violations)
    } else if (res) {
      setRules((prev) =>
        prev.map((rule) => {
          if (rule.id === newRuleId) {
            return { ...newRule, ...res }
          } else {
            return rule
          }
        })
      )
    }
  }

  const removeRule = (rule) => {
    setRules((prev) => {
      return prev.filter((item) => item.id !== rule.id)
    })
  }

  return (
    <div className={cx(styles.container)}>
      {rules?.length <= 0 && <h2 className={cx(styles.title)}>{t('sentences.noRules')}</h2>}

      {rules.map((rule, index) => (
        <SingleRule
          key={rule.id}
          index={index}
          rule={rule}
          policyId={policy.uuid}
          onRemoveRule={() => removeRule(rule)}
          disabled={disabled}
        />
      ))}

      <div className={cx(styles.newContainer)}>
        <ButtonBasic
          text={t('words.rule')}
          icon="plus"
          className={cx(styles.addRule)}
          onClick={() => setShowSelectRule(true)}
          disabled={disabled}
        />
        <DropdownBox
          ref={dropdownRef}
          dropdownOpen={!disabled && showSelectRule}
          options={RULE_TYPES.map((rule) => {
            if (rules.find((x) => x.type === rule.value)) {
              return {
                ...rule,
                disabled: true,
              }
            }
            return rule
          })}
          className={styles.dropdown}
          onOptionClick={addNewRule}
        />
      </div>
    </div>
  )
}

RulesWrapper.propTypes = {
  policy: PropTypes.shape({
    rules: PropTypes.array,
    uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disabled: PropTypes.bool,
}

RulesWrapper.defaultProps = {}

export default RulesWrapper
