import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'
import { GolfCart } from './components/GolfCart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useProductVariants } from '@sweetspot/sweetspot-js/features/productVariants/hooks/useProductVariants'
import { useProductVariant } from '@sweetspot/sweetspot-js/features/productVariants/hooks/useProductVariant'

const GolfCarts = ({ warehouseUuid, productId, fee, vat, onChange, setVatAndPrice }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [showError, setShowError] = useState(false)
  const {
    createVariant,
    removeVariant,
    productVariants: golfCarts,
    refetchProductVariants,
  } = useProductVariants(warehouseUuid, productId)

  const { createVariantFee } = useProductVariant()

  const defaultCart = {
    state: 'not_active',
    name: 'Golf cart ',
  }

  const defaultFee = {
    fee_unit_code: 'golf_cart_fleet',
    vat: parseFloat(vat) || 0,
    price: parseFloat(fee) || 0,
  }

  useEffect(() => {
    onChange('inventory', golfCarts)
  }, [golfCarts, removeVariant.isSuccess, createVariant.isSuccess, createVariantFee.isSuccess])

  useEffect(() => {
    if (createVariantFee.isSuccess && golfCarts?.length) {
      const tempFees = golfCarts[0].fees
      if (tempFees?.length) {
        setVatAndPrice(tempFees[0].price, tempFees[0].vat, golfCarts)
      }
    }
  }, [createVariantFee.isSuccess, golfCarts, createVariantFee.isLoading])

  useEffect(() => {
    setShowError(removeVariant.isError || createVariant.isError)
  }, [removeVariant.isError, createVariant.isError])

  const handleError = (err) => {
    if (showError) {
      setShowError(false)
      if (err) {
        if (err.detail === 'product_variant_has_order_items') {
          addToast(t('errors.golfcartRemove'), {
            appearance: 'error',
          })
        } else if (err.status !== 400) {
          addToast(`${t('errors.somethingWentWrongNoId')} ${t('sentences.contactSupport')}`, {
            appearance: 'error',
          })
        }
      }
    }
  }

  const handleAddCart = async () => {
    const newCart = defaultCart
    Object.assign(newCart, {
      name: defaultCart.name + ((golfCarts?.length ?? 0) + 1),
    })
    const variant = await createVariant.mutateAsync(newCart)
    await createVariantFee.mutateAsync({
      newFee: defaultFee,
      newVariantUuid: variant?.uuid,
    })
    refetchProductVariants()
  }

  return (
    <div className={cx(styles.container)}>
      {golfCarts?.length > 0 && (
        <>
          <div>{t('words.golfCart_one')}</div>
          <div>{t('words.active')}</div>
          <div>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </>
      )}
      {golfCarts?.map((cart, index) => (
        <GolfCart
          key={index}
          {...cart}
          onRemove={() => removeVariant.mutate(cart)}
          isDisabled={!cart?.uuid}
        ></GolfCart>
      ))}
      <button
        className={cx('system-button with-border', styles.button, {
          'system-button disabled': !warehouseUuid,
        })}
        onClick={() => handleAddCart()}
        disabled={
          !warehouseUuid || !productId || createVariant.isLoading || createVariantFee.isLoading
        }
      >
        <FontAwesomeIcon icon={faPlus} />
        {t('words.add')}
      </button>
      {removeVariant.isError && handleError(removeVariant.error)}
      {createVariant.isError && handleError(createVariant.error)}
    </div>
  )
}

export { GolfCarts }
