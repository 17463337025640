import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import Slots from '../Slots'

import PrimeTime from '@sweetspot/sweetspot-js/common/components/PrimeTime'
import { ReactComponent as NoteIcon } from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/note-icon.svg'
import { ReactComponent as GolfCartRequested } from '@sweetspot/club-portal-legacy/resources/images/golf_cart_requested_icon.svg'
import { ReactComponent as GolfCartPaid } from '@sweetspot/club-portal-legacy/resources/images/golf_cart_paid_icon.svg'
import { ReactComponent as GolfCartConfirmed } from '@sweetspot/club-portal-legacy/resources/images/golf_cart_confirmed_icon.svg'
import { ReactComponent as UnPaidIcon } from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/not-paid-icon.svg'
import { ReactComponent as GolfCartIcon } from '@sweetspot/club-portal-legacy/resources/images/golf-cart-icon2.svg'

import { TEE_TIME_TEXT_COLORS } from '@sweetspot/shared/util/constants'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { withClubCurrency } from '../../../../hoc/withClubCurrency'

let clickCount = 0
let clickTimeout = null

class Panel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timeOut: null,
      clicked: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const { teeTime, onClick, onDoubleClick, editMode, panelGroupIndex, disabled } = this.props
    if (!teeTime || disabled) return

    if (editMode) {
      onClick(teeTime)
    } else {
      this.setState({ clicked: true })

      const delay = 250
      if (clickCount === 0) {
        clickTimeout = setTimeout(() => {
          clickCount = 0
          onClick(teeTime, panelGroupIndex)
        }, delay)
        clickCount = 1
      } else {
        clearTimeout(clickTimeout)
        clickCount = 0
        onDoubleClick(teeTime)
      }
    }
  }

  renderHeader() {
    const { teeTime } = this.props
    const category = teeTime?.category
    let date = new Date(teeTime.from)
    let teeTimeMinutes = DateHelpers.toTimeString(date).slice(3, 5)
    let classNames = [style.headerContainer]
    const categoryColor = category?.color || '#a7dec4'
    const textColor = TEE_TIME_TEXT_COLORS[categoryColor]
    const shouldRender = this.shouldRender()

    return (
      <div style={{ background: categoryColor, color: textColor }} className={classNames.join(' ')}>
        <div className={style.time}>
          <span style={{ color: textColor }}>{shouldRender && teeTimeMinutes}</span>
          {teeTime.is_prime_time && (
            <div className={style.primeTime}>
              <PrimeTime iconColor={textColor === '#FFFFFF' ? 'light' : 'dark'} />
            </div>
          )}
        </div>
        <div style={{ color: textColor }} title={category?.name} className={style.category}>
          {shouldRender && category?.name}
        </div>
      </div>
    )
  }

  renderIcons() {
    const { teeTime } = this.props
    let golfCartNo = 0

    return (
      <div className={style.icons}>
        {!!teeTime?.numberOfGC && (
          <div className={style.golfCarts} key={teeTime.id}>
            <GolfCartIcon />
            <span>{teeTime?.numberOfGC}</span>
          </div>
        )}
        {teeTime.notes && teeTime.notes !== '[]' && teeTime.notes !== '[null]' && (
          <NoteIcon className={style.image} />
        )}
        {teeTime.isUnpaid && <UnPaidIcon className={style.image} />}
        {teeTime.orderItems &&
          teeTime.orderItems.map((orderItem, index) => {
            let status = orderItem?.slot?.golf_cart_status
            if (status !== 'none') {
              golfCartNo++
              if (golfCartNo > 2) return null
              let ImageComponent = ''
              if (status === 'requested') ImageComponent = GolfCartRequested
              else if (status === 'confirmed') ImageComponent = GolfCartConfirmed
              else if (status === 'paid') ImageComponent = GolfCartPaid

              if (!ImageComponent) return null

              return <ImageComponent key={index++} className={style.image} />
            }
            return null
          })}
      </div>
    )
  }

  renderMainContent() {
    const { teeTime, selected, editMode } = this.props
    const classNames = [style.mainContainer]
    const shouldRender = this.shouldRender()

    return (
      <div className={classNames}>
        {shouldRender && this.renderIcons()}
        {shouldRender && (
          <Slots
            selected={selected || editMode}
            maximumSlots={this.props.teeTime['max_slots']}
            openSlots={this.props.teeTime['available_slots']}
            participants={teeTime?.orderItems || []}
            playerTypeFilter={this.props.playerTypeFilter}
            partnershipPlayers={this.props.teeTime['partnershipPlayers']}
          />
        )}
      </div>
    )
  }

  renderFooter() {
    const { teeTime, currentClubCurrency, course } = this.props
    const classNames = [style.footerContainer]
    const priceObject = course?.is_use_dynamic_pricing ? teeTime.dynamic_price : teeTime.price
    const price = priceObject?.amount || 0
    const shouldRender = this.shouldRender()

    return (
      <div className={classNames}>
        <div className={style.price}>
          {shouldRender && priceToLocal(price, currentClubCurrency, true)}
        </div>
      </div>
    )
  }

  shouldRender() {
    const { teeTime, currentClubCurrency, course } = this.props
    const priceObject = course?.is_use_dynamic_pricing ? teeTime.dynamic_price : teeTime.price
    const price = priceObject?.amount || 0
    const priceValue = priceToLocal(price, currentClubCurrency, true)

    return !(
      typeof priceValue === 'string' &&
      (priceObject?.amount === 0 ? priceObject?.amount : !priceObject?.amount)
    )
  }

  renderEmpty(_style) {
    const { editMode } = this.props
    let contentClassNames = [style.content]

    if (editMode) contentClassNames.push(style.editMode)

    return (
      <div className={style.empty} style={_style}>
        <div className={contentClassNames.join(' ')}>
          <div className={style.timeContainer}>
            <div className={style.time} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { teeTime, marginTop, editMode, selected, filteredOut, disabled } = this.props

    let _style = {}

    if (teeTime.type && teeTime.type === 'empty') {
      return this.renderEmpty(_style)
    }
    const containerClassName = [style.container]
    if (selected) containerClassName.push(style.selected)
    if (editMode) containerClassName.push(style.editMode)
    if (disabled) containerClassName.push(style.disabled)

    let contentClassNames = [style.content]

    if (filteredOut) contentClassNames.push(style.filteredOut)
    if (marginTop) _style.marginTop = marginTop

    return (
      <div className={containerClassName.join(' ')} style={_style}>
        <div className={contentClassNames.join(' ')} onClick={this.handleClick}>
          {this.renderHeader()}
          {this.renderMainContent()}
          {this.renderFooter()}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  teeTime: PropTypes.object,
  allTeeTimes: PropTypes.object,
  maxTeeTimes: PropTypes.number.isRequired,
  marginTop: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  filteredOut: PropTypes.bool.isRequired,
  last: PropTypes.bool,
  bottom: PropTypes.bool,
  first: PropTypes.bool,
  top: PropTypes.bool,
  topLeft: PropTypes.bool,
  topRight: PropTypes.bool,
  bottomLeft: PropTypes.bool,
  bottomRight: PropTypes.bool,
  status: PropTypes.string,
  panelGroupIndex: PropTypes.number.isRequired,
  playerTypeFilter: PropTypes.array.isRequired,
  currentClubCurrency: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
}

export default withClubCurrency(Panel)
