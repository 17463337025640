import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

const StatusCell = ({ color, name }) => {
  return (
    <div className={style.container}>
      <div style={{ background: color }} className={cx(style.status)} />
      <span className={style.text}>{name}</span>
    </div>
  )
}

StatusCell.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
}

export default StatusCell
