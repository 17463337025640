import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import produce from 'immer'

import styles from './styles.module.scss'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import SinglePromotion from '../../components/SinglePromotion'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import PROMOTION from '../../constants/defaultPromotions'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { queryPromotions } from '../../services/api-platform'

import PromotionsContext from './PromotionsContext'
import { updatePromotionPriority as updatePromotionPriorityApi } from '../../services/api-platform'
import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import DraggableComponent from '@sweetspot/sweetspot-js/common/components/DraggableComponent'
import { useToasts } from 'react-toast-notifications'

const PAGE_LIMIT = 40

const PromotionsManager = ({
  context,
  clubId,
  membershipId,
  partnershipId,
  withTitle,
  restrictWidth,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  // Refs
  const paginationVars = useRef({
    currentPage: 1,
    currentPageIsEmpty: false,
  })
  const promotionsContainerRef = useRef()

  // Actions states
  const [checkingPromotions, setCheckingPromotions] = useState(true)
  const [loadingMorePromotions, setLoadingMorePromotions] = useState(false)
  const [savingNewPromotion, setSavingNewPromotion] = useState(false)
  const [savingExistingPromotion, setSavingExistingPromotion] = useState(false)

  // Values
  const [openPromotions, setOpenPromotions] = useState([])
  const [promotions, setPromotions] = useState([])

  const [filters, setFilters] = useMergeState({
    active: true,
    inactive: true,
    archived: false,
  })

  useEffect(() => {
    setCheckingPromotions(true)
    if (promotionsContainerRef?.current?.scrollTop) {
      promotionsContainerRef.current.scrollTop = 0
    }
  }, [filters])

  const canDrag = useMemo(() => {
    return promotions?.find((p) => p.id === 'new') ? false : true
  }, [promotions])

  const canDragSpecific = (thisPromotion, atIndex) => {
    let promotion
    if (thisPromotion) {
      promotion = thisPromotion
    } else if (atIndex) {
      promotion = promotions[atIndex]
    }
    if (promotion?.status === 'archived') {
      return false
    }
    return true
  }

  // Callback
  const getPromotions = useCallback(
    async (page) => {
      const { currentPage, currentPageIsEmpty } = paginationVars.current
      if (page >= currentPage && currentPageIsEmpty) return

      let query = {
        limit: PAGE_LIMIT,
        page: page,
        'order[priority]': 'asc',
        'state[]': [
          ...Object.keys(filters)
            .filter((key) => filters[key] === true)
            .map((key) => {
              if (key === 'active') return 'published'
              if (key === 'inactive') return 'canceled'
              if (key === 'archived') return 'archived'
              return ''
            }),
          ...(filters.inactive ? ['draft'] : []),
        ],
        context: context,
      }

      if (context === 'club') {
        query = {
          ...query,
          club: clubId,
        }
      } else if (context === 'membership') {
        query = {
          ...query,
          'membership.id': membershipId,
        }
      }

      const [res, err] = await to(queryPromotions(query))

      if (!res || err) {
        return false
      } else {
        if (page > 1) {
          setPromotions((prev) => prev.concat(res).sort((a, b) => a.priority - b.priority))
        } else {
          setPromotions(res.sort((a, b) => a.priority - b.priority))
        }

        if (res?.length < PAGE_LIMIT - 1) {
          paginationVars.current.currentPageIsEmpty = true
        }
        return true
      }
    },
    [context, clubId, membershipId, partnershipId, filters]
  )

  useEffect(() => {
    paginationVars.current = {
      currentPage: 1,
      currentPageIsEmpty: false,
    }

    let debounce = setTimeout(() => {
      const { currentPage } = paginationVars.current
      getPromotions(currentPage).then(() => {
        paginationVars.current.currentPage++
        setCheckingPromotions(false)
      })
    }, 200)

    return () => {
      clearTimeout(debounce)
    }
  }, [getPromotions])

  useEffect(() => {
    let debounce

    const onScroll = (e) => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        const target = e.target
        const { currentPage, currentPageIsEmpty } = paginationVars.current

        if (
          !loadingMorePromotions &&
          !currentPageIsEmpty &&
          target.scrollHeight - target.scrollTop - target.clientHeight < 1
        ) {
          setLoadingMorePromotions(true)
          getPromotions(currentPage).then(() => {
            paginationVars.current.currentPage++
            setLoadingMorePromotions(false)
          })
        }
      }, 300)
    }

    if (promotionsContainerRef?.current) {
      promotionsContainerRef.current.addEventListener('scroll', onScroll)
    }

    return () => {
      clearTimeout(debounce)
      if (promotionsContainerRef?.current) {
        promotionsContainerRef.current.removeEventListener('scroll', onScroll)
      }
    }
  }, [getPromotions, loadingMorePromotions])

  const addNewPromotion = () => {
    setPromotions(
      [
        {
          ...PROMOTION,
          date: +moment(),
        },
      ].concat(promotions)
    )
  }

  const deleteNewPromotion = () => {
    setPromotions((prev) => prev.filter((x) => x.id !== 'new'))
  }

  const replaceNewPromotion = (promotion) => {
    deleteNewPromotion()
    setPromotions(
      produce((draft) => {
        draft = draft.map((p) => ({ ...p, priority: p.priority + 1 }))
        draft = [promotion, ...draft]
        return draft
      })
    )
    setOpenPromotions([promotion.id])
  }

  const updatePromotion = (promotion) => {
    setPromotions((prev) =>
      prev.map((old) => {
        if (old.id === promotion.id) {
          return promotion
        }
        return old
      })
    )
  }

  const toggleOpenPromotion = (id) => {
    setOpenPromotions((prev) => (prev.includes(id) ? [] : [id]))
  }

  const archivePromotion = (promotionId) => {
    setPromotions(
      produce((draft) => {
        let newState = draft.map((promotion) => {
          if (promotion.id === promotionId) {
            return {
              ...promotion,
              status: 'archived',
            }
          }
          return promotion
        })
        if (!filters.archived) {
          newState = newState.filter((promotion) => promotion.id !== promotionId)
        }
        return newState
      })
    )
  }

  const updatePromotionListWhenActivateDeactivate = (promoId, value) => {
    if (
      (filters.active && !filters.inactive && value === 'inactivate') ||
      (filters.inactive && !filters.active && value === 'activate')
    ) {
      setPromotions(
        produce((draft) => {
          return draft.filter((promo) => promo.id !== promoId)
        })
      )
    }
  }

  const updatePromotionOrder = (oldIndex, newIndex) => {
    if (!canDrag || !canDragSpecific(null, oldIndex)) return

    setPromotions(
      produce((draft) => {
        if (newIndex === null && !paginationVars.current.currentPageIsEmpty) {
          draft.splice(oldIndex, 1)
        } else {
          draft.splice(
            newIndex === null ? promotions?.length - 1 : newIndex,
            0,
            draft.splice(oldIndex, 1)[0]
          )
        }
      })
    )
  }

  const updatePromotionPriority = async (itemId, newIndex, originalIndex, action) => {
    const promotion = promotions?.find((x) => x.id === itemId)

    if (!canDrag || !canDragSpecific(promotion)) return

    setOpenPromotions([])

    let promotionUnderUuid = null
    if (newIndex !== null) {
      promotionUnderUuid =
        promotions?.[newIndex === 0 && action === 'move-to-top' ? 0 : newIndex + 1]?.uuid
    }

    const [, err] = await to(updatePromotionPriorityApi(promotion.id, promotionUnderUuid))

    if (err) {
      addToast(t('sentences.failedToUpdatePromotionPriority'), { appearance: 'error' })
      // Revert to original order
      updatePromotionOrder(newIndex, originalIndex)
    } else {
      setPromotions(
        produce((draft) => {
          const index = draft.findIndex((p) => p.id === itemId)
          if (draft[index].updateTrigger) {
            draft[index].updateTrigger++
          } else {
            draft[index].updateTrigger = 1
          }
        })
      )
    }
  }

  const ContextValues = {
    values: {
      context: context,
      clubId: clubId,
      membershipId: membershipId,
      partnershipId: partnershipId,
      openPromotions: openPromotions,
      promotions: promotions,
    },
    actionStates: {
      checkingPromotions: checkingPromotions,
      setCheckingPromotions: setCheckingPromotions,
      savingNewPromotion: savingNewPromotion,
      setSavingNewPromotion: setSavingNewPromotion,
      savingExistingPromotion: savingExistingPromotion,
      setSavingExistingPromotion: setSavingExistingPromotion,
    },
    functions: {
      setOpenPromotions,
      deleteNewPromotion,
      archivePromotion,
      replaceNewPromotion,
      toggleOpenPromotion,
      updatePromotion,
      updatePromotionListWhenActivateDeactivate,
    },
  }

  console.log('promotions', promotions)

  return (
    <PromotionsContext.Provider value={ContextValues}>
      <div className={cx(styles.container, restrictWidth && styles.restrictWidth)}>
        <div className={cx(styles.headerRow)}>
          {withTitle && <h2 className={cx(styles.title)}>{t('words.promotions')}</h2>}

          <ButtonBasic
            text={t('words.promotion')}
            icon="plus"
            className={cx(styles.addPromotionButton)}
            onClick={addNewPromotion}
            disabled={!!promotions?.find((x) => x.id === 'new') || checkingPromotions}
          />

          <div className={cx(styles.loaderContainerHeader, checkingPromotions && styles.visible)}>
            <PulseLoader showIf={true} />
          </div>

          <div className={cx(styles.filtersContainer)}>
            <p className={cx(styles.filterText)}>{t('words.filter_one')}</p>
            <Checkbox
              label={t('words.active')}
              lightLabel={true}
              containerClassName={cx(styles.checkbox)}
              value={filters.active}
              onChange={(newValue) => setFilters({ active: newValue })}
            />
            <Checkbox
              label={t('words.inactive')}
              lightLabel={true}
              containerClassName={cx(styles.checkbox)}
              value={filters.inactive}
              onChange={(newValue) => setFilters({ inactive: newValue })}
            />
            <Checkbox
              label={t('words.archived')}
              lightLabel={true}
              containerClassName={cx(styles.checkbox)}
              value={filters.archived}
              onChange={(newValue) => setFilters({ archived: newValue })}
            />
          </div>
        </div>

        {!checkingPromotions && (!promotions || promotions?.length <= 0) && (
          <p className={cx(styles.noExist)}>{t('sentences.noPromotionsExist')}</p>
        )}

        <div className={cx(styles.promotionsContainer)} ref={promotionsContainerRef}>
          <DndProvider backend={HTML5Backend}>
            {promotions && promotions?.length > 0
              ? promotions.map((promotion, index) => {
                  return (
                    <DraggableComponent
                      key={promotion.id}
                      index={index}
                      id={promotion.id}
                      className={cx(styles.draggableWrapper)}
                      itemType="promotion"
                      moveItem={updatePromotionOrder}
                      onDrop={updatePromotionPriority}
                      canDrag={canDrag}
                      render={(drag) => (
                        <SinglePromotion
                          promotion={promotion}
                          currentIndex={index}
                          onUpdatePromotionsOrder={updatePromotionOrder}
                          onUpdatePromotionPriority={updatePromotionPriority}
                          canDrag={canDrag}
                          dragHandle={drag}
                        />
                      )}
                    />
                  )
                })
              : null}
          </DndProvider>
        </div>

        <div className={cx(styles.loaderContainer, loadingMorePromotions && styles.visible)}>
          <PulseLoader showIf={loadingMorePromotions} />
        </div>
      </div>
    </PromotionsContext.Provider>
  )
}

PromotionsManager.propTypes = {
  context: PropTypes.oneOf(['club', 'membership', 'partnership']).isRequired,
  clubId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  membershipId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  partnershipId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withTitle: PropTypes.bool,
  restrictWidth: PropTypes.bool,
}

PromotionsManager.defaultProps = {
  context: null,
  clubId: null,
  membershipId: null,
  partnershipId: null,
  withTitle: false,
  restrictWidth: true,
}

export default PromotionsManager
