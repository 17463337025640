import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import _slice from 'lodash/slice'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import SelectGolfClub from '@sweetspot/club-portal-legacy/Partials/Forms/SelectGolfClub'
import ManageMemberships from '@sweetspot/club-portal-legacy/Partials/Forms/ManageMemberships'

import tableHeaders from './headers.json'
import style from './style.module.scss'
import useCooperations from '@sweetspot/sweetspot-js/features/cooperations/hooks/useCooperations'
import {
  filterGolfClubs,
  searchSortCooperations,
} from '@sweetspot/sweetspot-js/features/cooperations/tools/data'
import useMemberships from '@sweetspot/sweetspot-js/features/cooperations/hooks/useMemberships'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryClubs } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { parseError } from '@sweetspot/sweetspot-js/common/functions/parse'

const PAGE_LIMIT = 40

const MODALS = {
  SELECT_CLUB: 'SELECT_CLUB',
  MANAGE_MEMBERSHIPS: 'MANAGE_MEMBERSHIPS',
}

export const Cooperations = (props) => {
  const { addToast } = useToasts()
  const {
    data: golfClubList = [],
    isFetching: isLoadingClubs,
    error: clubListError,
  } = useQuery(
    [CLUB_QUERIES.CLUBS],
    () =>
      queryClubs({
        page: null,
        limit: 9999,
        'order[name]': 'asc',
      }),
    {
      enabled: !!props.currentClubId,
    }
  )

  const { memberships: currentMemberships, isFetching: isLoadingMemberships } = useMemberships(
    golfClubList ? [props.currentClubId] : false
  )
  const {
    cooperations: cooperationsList,
    refetch,
    isLoading,
    error,
    createCooperation,
    fetchCooperation,
    updateCooperation,
  } = useCooperations(golfClubList ? props.currentClubId : false)

  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const [currentModal, setCurrentModal] = useState(null)
  const [currentCooperation, setCurrentCooperation] = useState(null)
  const [currentlyFetching, setCurrentlyFetching] = useState(null)

  const cooperations = searchSortCooperations(cooperationsList, searchText)

  const reset = () => {
    setCurrentModal(null)
    setCurrentCooperation(null)
    setCurrentlyFetching(null)
  }

  const openModal = (modalName) => {
    setCurrentModal(modalName)
  }

  const onCreateCooperation = async (partnerClubID) => {
    const targetClub = golfClubList.find((i) => i.id === partnerClubID)
    const cooperation = await createCooperation(targetClub.uuid)
    if (cooperation) {
      await viewCooperation(cooperation, true)
    }
  }

  const viewCooperation = async (cooperationData, isCreated) => {
    if (isCreated) {
      setCurrentCooperation(cooperationData)
      setCurrentModal(MODALS.MANAGE_MEMBERSHIPS)
    } else {
      setCurrentlyFetching(
        cooperationData[`${!cooperationData._isHost ? 'host' : 'partner'}_club`].name
      )
      setCurrentModal(MODALS.MANAGE_MEMBERSHIPS)
      const cooperation = await fetchCooperation(cooperationData)
      if (cooperation) {
        setCurrentCooperation({
          ...cooperation,
          // Preserve NEW state (see useCooperations.js, line 63)
          state: cooperationData.state,
          _isHost: cooperationData._isHost,
        })
        setCurrentlyFetching(null)
      }
    }
  }

  const onUpdateCooperation = async (actionName, value) => {
    setCurrentlyFetching(
      currentCooperation[`${!currentCooperation._isHost ? 'host' : 'partner'}_club`].name
    )
    const cooperation = await updateCooperation(currentCooperation, actionName, value)
    if (cooperation) {
      if (Object.prototype.hasOwnProperty.call(cooperation, 'uuid')) {
        const isHost = cooperation.host_club.id === props.currentClubId
        const target = isHost ? 'host' : 'partner'
        setCurrentCooperation({
          ...cooperation,
          // TODO: Fix backend response for REMOVE operation (object instead of array)
          [`${target}_club_memberships`]: !Array.isArray(cooperation[`${target}_club_memberships`])
            ? Object.values(cooperation[`${target}_club_memberships`])
            : cooperation[`${target}_club_memberships`],
          _isHost: isHost,
        })
        setCurrentlyFetching(null)
      } else {
        refetch().then()
        reset()
      }
    }
  }

  useEffect(reset, [props.currentClubId])

  useEffect(() => {
    if (error || clubListError) {
      const _error = parseError(error || clubListError)
      addToast(_error, { appearance: 'error' })
      reset()
    }
  }, [error, clubListError])

  const render_SelectGolfClub = () => {
    if (currentModal === MODALS.SELECT_CLUB) {
      return (
        <OverlayContainer>
          <div className={style.overlaySelect}>
            <SelectGolfClub
              isLoading={isLoadingClubs || isLoading.createCooperation}
              onClose={reset}
              onCreate={onCreateCooperation}
              golfClubList={filterGolfClubs(golfClubList, cooperations, props.currentClubId)}
            />
          </div>
        </OverlayContainer>
      )
    }
  }

  const render_ManageMemberships = () => {
    if (currentModal === MODALS.MANAGE_MEMBERSHIPS) {
      return (
        <OverlayContainer>
          <div className={style.overlayManage}>
            <ManageMemberships
              loadingTitle={currentlyFetching}
              currentClubId={props.currentClubId}
              memberships={currentMemberships}
              cooperation={currentCooperation}
              onClose={reset}
              isUpdating={isLoading.updateCooperation || isLoadingMemberships}
              onUpdate={onUpdateCooperation}
            />
          </div>
        </OverlayContainer>
      )
    }
  }

  return (
    <div className={style.content}>
      <Grid
        actions={[{ id: 1, label: 'cooperations.button_Create' }]}
        actionsEnabled
        isLoading={isLoading.fetchCooperations}
        searchEnabled
        searchPlaceholder={'.cooperations.placeholder_Search'}
        values={_slice(cooperations, (currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)}
        pageTitle={'cooperations.title'}
        headers={tableHeaders}
        hideArrows
        shouldHideColumnEdit
        onSearch={() => void 0}
        onRowClick={(row) => viewCooperation(row)}
        onActionConfirm={() => openModal(MODALS.SELECT_CLUB)}
        pagination
        totalPages={cooperations.length === 0 ? 1 : Math.ceil(cooperations.length / PAGE_LIMIT)}
        rowsPerPage={PAGE_LIMIT}
        totalRows={cooperations.length}
        displayRangeOfRows
        setCurrentPage={setCurrentPage}
        hideSearchButton
        onSearchChange={(value = '') => setSearchText(value.trim())}
      />
      {cooperations.length === 0 && (
        <div className={style.container}>
          {isLoading.fetchCooperations ? (
            <div className={style.loading}>
              <PulseLoader />
            </div>
          ) : (
            <Text textId="cooperations.table_Empty" className={style.tableEmpty} />
          )}
        </div>
      )}
      {render_SelectGolfClub()}
      {render_ManageMemberships()}
    </div>
  )
}

Cooperations.propTypes = {
  currentClubId: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => ({
  currentClubId: state.golfClub.selectedId,
})

export default connect(mapStateToProps, false)(Cooperations)
