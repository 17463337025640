import { BookingActions, BookingState } from '@sweetspot/club-portal-legacy/store/types'
import { CONSTANTS } from '../../constants'
import { initialState } from './initialState'

export default function auth(state = initialState, action: BookingActions): BookingState {
  switch (action.type) {
    case CONSTANTS.BOOKING_PERIOD.GET_LIST:
      return Object.assign({}, state, {
        list: action.payload,
      })
    default:
      return state
  }
}
