import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import style from './style.module.scss'
import PanelGroup from '../PanelGroup'

const FIRST_HOUR = 0
const LAST_HOUR = 23

export default class TimeTable extends Component {
  parseTeeTimes() {
    let teeTimes = {}

    for (let i = FIRST_HOUR; i <= LAST_HOUR; i++) {
      teeTimes[i] = []
    }

    this.props.teeTimes.forEach((teeTime) => {
      const hour = moment(teeTime.from).tz(this.props.timezone).hour()
      if (Object.prototype.hasOwnProperty.call(teeTimes, hour)) {
        teeTimes[hour].push(teeTime)
      }
    })

    return teeTimes
  }

  render() {
    const {
      editMode,
      selectedTeeTimes,
      filteredTeeTimes,
      onClick,
      onDoubleClick,
      overrides,
      playerTypeFilter,
      activeFilters,
      course,
    } = this.props

    const teeTimes = this.parseTeeTimes()
    const hours = Object.keys(teeTimes)

    let maxTeeTimes = 0

    Object.values(teeTimes).forEach((group) => {
      if (group.length > maxTeeTimes) {
        maxTeeTimes = group.length
      }
    })

    const hoursWithTeeTimes = hours.filter((hour) => teeTimes[hour].length > 0)
    const firstHour = hoursWithTeeTimes[0]
    const lastHour = hoursWithTeeTimes[hoursWithTeeTimes.length - 1]
    const timeTableWidth = hoursWithTeeTimes.length * 116

    return (
      <div className={style.container} style={{ width: `${timeTableWidth}px` }}>
        {hoursWithTeeTimes.map((hour, key) => {
          return (
            <PanelGroup
              hour={parseInt(hour)}
              teeTimes={teeTimes[hour]}
              timezone={this.props.timezone}
              totalGroups={hoursWithTeeTimes.length}
              maxTeeTimes={maxTeeTimes}
              first={hour === firstHour}
              last={hour === lastHour}
              editMode={editMode}
              selectedTeeTimes={selectedTeeTimes}
              filteredTeeTimes={filteredTeeTimes}
              activeFilters={activeFilters}
              overrides={overrides}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
              panelGroupIndex={key}
              playerTypeFilter={playerTypeFilter}
              key={key}
              course={course}
            />
          )
        })}
      </div>
    )
  }
}

TimeTable.propTypes = {
  teeTimes: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  selectedTeeTimes: PropTypes.array,
  filteredTeeTimes: PropTypes.array,
  overrides: PropTypes.array,
  playerTypeFilter: PropTypes.array.isRequired,
  activeFilters: PropTypes.bool.isRequired,
  course: PropTypes.object.isRequired,
}
