import m from 'moment'

export default class PartnershipHelpers {
  static formatPartnerships(partnerships) {
    const partnershipList = []

    partnerships.forEach((partnership) => {
      let startDate = m.utc(partnership.starts_at).local().format('YYYY-MM-DD')
      let endDate = m.utc(partnership.expires_at).local().format('YYYY-MM-DD')

      let statusText
      let statusState

      switch (partnership.status) {
        case 'future':
          statusText = '.inActive'
          statusState = 'inActive'
          break
        case 'canceled':
          statusText = '.canceled'
          statusState = 'inActive'
          break
        case 'upcoming':
          statusText = '.active'
          statusState = 'active'
          break
        case 'active':
          statusText = '.active'
          statusState = 'active'
          break
        case 'expired':
          statusText = '.expired'
          statusState = 'neutral'
          break
        default:
      }

      let status = {
        text: statusText,
        state: statusState,
      }

      partnershipList.push({
        _id: partnership.id,
        uuid: partnership.uuid,
        name: partnership.name,
        type: '.' + partnership.type,
        state: partnership.state,
        status,
        price: partnership.price_included_vat / 100,
        startDate,
        endDate,
      })
    })

    return partnershipList
  }
}
