import { createContext, useContext } from 'react'
import { createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface InputBaseInterface {
  error: boolean | undefined
  setError: (open: boolean | undefined) => void
  disabled: boolean | undefined
  setDisabled: (disabled: boolean | undefined) => void
  required: boolean | undefined
  setRequired: (required: boolean | undefined) => void
}

export const createInputBaseStore = (initProps?: Partial<InputBaseInterface>) => {
  const DEFAULT_PROPS = {
    error: false,
    disabled: false,
    required: false,
  }

  return createStore(
    immer<InputBaseInterface>((set) => ({
      ...DEFAULT_PROPS,
      ...initProps,
      setError: (error) =>
        set((draft) => {
          draft.error = error
        }),
      setDisabled: (disabled) =>
        set((draft) => {
          draft.disabled = disabled
        }),
      setRequired: (required) =>
        set((draft) => {
          draft.required = required
        }),
    }))
  )
}

export const InputBaseContext = createContext<ReturnType<typeof createInputBaseStore> | null>(null)

export function useInputBaseStoreContext() {
  const context = useContext(InputBaseContext)
  if (!context) {
    throw new Error('useInputBaseStoreContext must be used within a TextareaProvider')
  }
  return context
}
