import { useMutation } from 'react-query'
import { splitPaymentLink } from '@sweetspot/shared/data-access/api-platform'
import { BookingFields } from '../types'

export const usePaymentLinkSplit = (bookingFields: BookingFields) => {
  const { mutateAsync, isLoading, isSuccess, data } = useMutation((payload: { amount: number }) =>
    splitPaymentLink(payload, bookingFields.paymentId)
  )

  const send = async (amount: number) => {
    const payload = {
      amount: amount * 100,
    }

    return await mutateAsync(payload)
  }

  return {
    send,
    isLoading,
    isSuccess,
    data,
  }
}
