import cx from 'classnames'
import type classNames from 'classnames'
import { extendTailwindMerge } from 'tailwind-merge'
import { defaultConfig } from 'tailwind-variants'

import {
  colorKeys,
  borderRadiusKeys,
  backgroundColorKeys,
  borderColorKeys,
  fontSizeKeys,
  fontFamilyKeys,
  marginKeys,
  paddingKeys,
  surfaceColorKeys,
  textColorKeys,
  touchHeightKeys,
  translucentColorKeys,
} from '../tailwind-config/constants'

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      spacing: Object.keys(touchHeightKeys).map((key) => key),
      colors: [
        'transparent',
        'currentColor',
        ...Object.keys(colorKeys).map((key) => key),
        {
          surface: Object.keys(surfaceColorKeys).map((key) => key),
        },
        {
          border: Object.keys(borderColorKeys).map((key) => key),
        },
        {
          background: Object.keys(backgroundColorKeys).map((key) => key),
        },
        {
          text: Object.keys(textColorKeys).map((key) => key),
        },
        {
          translucent: Object.keys(translucentColorKeys).map((key) => key),
        },
      ],
      borderRadius: Object.keys(borderRadiusKeys).map((key) => key),
      padding: Object.keys(paddingKeys).map((key) => key),
      margin: Object.keys(marginKeys).map((key) => key),
    },
    classGroups: {
      'font-size': Object.keys(fontSizeKeys).map((key) => `text-${key}`),
      'font-family': Object.keys(fontFamilyKeys).map((key) => `font-${key}`),
    },
  },
})

// Disable tailwind-variants managing twMerge. We do that here using the cn function instead.
defaultConfig.twMerge = false

export const cn = (...inputs: classNames.ArgumentArray) => {
  return twMerge(cx(inputs))
}
