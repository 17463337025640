import NumberInput from '@sweetspot/sweetspot-js/common/components/NumberInput'
import { useTranslation } from 'react-i18next'
import style from '../style.module.scss'

const SlotsField = ({ errors, triedToSubmit, formState, setValue }) => {
  const { t } = useTranslation()

  return (
    <div className={triedToSubmit && errors.slots ? style.errored : ''}>
      <span className={style.optionsText}>{t('editPeriodsNew.slots')}</span>
      <div className={style.numberInput}>
        <NumberInput
          value={formState.slots}
          onChange={(event) => setValue('slots', +event.target.value)}
          className={`ss-input ${style.inputOptions}`}
          min={1}
          max={10}
        />
      </div>
    </div>
  )
}

export default SlotsField
