import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import styles from './styles.module.scss'

const VersionAndName = ({ currentEntity, currentStep }) => {
  const { t } = useTranslation()
  if (currentEntity) {
    return (
      <div className={styles.container}>
        <p className={cx(styles.name)}>
          {currentEntity.name || ''}{' '}
          {currentEntity.state && currentEntity.state === 'draft' ? (
            <span className={styles.state}>{` (${t('draft')})`}</span>
          ) : null}
          {currentEntity.status && currentEntity.status === 'future' && currentStep === 3 ? (
            <span className={styles.state}>{` (${t('inActive')})`}</span>
          ) : null}
          {currentEntity.status &&
          (currentEntity.status === 'active' || currentEntity.status === 'upcoming') &&
          currentStep === 3 ? (
            <span className={styles.state}>{` (${t('active')})`}</span>
          ) : null}
          {currentEntity.status && currentEntity.status === 'expired' && currentStep === 3 ? (
            <span className={styles.state}>{` (${t('expired')})`}</span>
          ) : null}
          {currentEntity.status && currentEntity.status === 'canceled' && currentStep === 3 ? (
            <span className={styles.state}>{` (${t('canceled')})`}</span>
          ) : null}
        </p>
      </div>
    )
  }

  return <div className={styles.container} />
}

VersionAndName.propTypes = {
  currentEntity: PropTypes.object,
  currentStep: PropTypes.number,
}
VersionAndName.defaultProps = {
  currentEntity: null,
  currentStep: null,
}

export default VersionAndName
