import React from 'react'
import style from './style.module.scss'
import cx from 'classnames'

/**
 * This component displays a darkened container covering the whole page and centers its children.
 *
 * @param {Object} props The props for the component.
 * @param {React.ReactNode} props.children The children elements.
 * @param {string} [props.className] Optional additional classnames.
 * @param {boolean} [props.bottom=false] If true, the overlay will be aligned at the bottom.
 * @param {function} [props.onClick] Optional click handler.
 * @param {boolean} [props.isFullsize=false] If true, the overlay will take the full size of the viewport.
 *
 * @returns {React.ReactNode}
 */
const OverlayContainer = ({ children, className, bottom, onClick, isFullsize, ...rest }) => {
  return (
    <div
      onClick={onClick}
      className={cx(style.container, className, bottom && style.bottom, {
        [style.fullsize]: isFullsize,
      })}
      {...rest}
    >
      {children}
    </div>
  )
}

export default OverlayContainer
