import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { PlayersSearchPayload } from '@sweetspot/shared/data-access/api-platform'
import { GolfCourseTypes } from '@sweetspot/shared/types'

export enum HeaderOption {
  DEFAULT = 0,
  EDIT_CATEGORY = 1,
  BOOK = 2,
}

export type HeaderActions = {
  setHeader: (header: HeaderOption) => void
  reset: () => void
  isOptionVisible: (header: HeaderOption) => boolean
}

export type HeaderSettingsOption = {
  key: string
  icon: IconDefinition
  label: string
  subLabel?: string
  onClick?: () => void
  copyText?: string
}

export interface TeeSheetSearchParams {
  searchValue: string
  searchPayload: PlayersSearchPayload
  courseType: GolfCourseTypes
  courseUuid: string
  currentDate: Date
}

export interface FilteredBooking {
  id: number
  uuid: string
  name: string
  golfId: string
  date: string
  startTime: Date
  courseUuid: string
  course: string
  courseId: number
  courseType?: GolfCourseTypes
  cdhId: string
}

export interface PlayerRelatedBookingsSearchParams {
  playerUuid: string
  courseUuid: string
}
