import Header from './Header'
import FilterToolbar from './FilterToolbar'
import { BaysProvider } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import BaysTableContainer from './BaysTable'
import BaySideBar from './BaySideBar'

const Bays = () => {
  return (
    <BaysProvider>
      <BaySideBar />
      <div className="flex h-full flex-col">
        <Header />
        <div className={'py-4'}>
          <FilterToolbar />
        </div>
        <div className={'flex flex-1 items-center justify-center px-6'}>
          <BaysTableContainer />
        </div>
      </div>
    </BaysProvider>
  )
}

export default Bays
