const HEADERS = {
  color: {
    name: '.teeTimeCategory.color',
    type: 'color',
    valueType: 'string',
    width: '70px',
    maxWidth: '70px',
    height: '45px',
    selected: true,
  },
  name: {
    name: '.name',
    type: 'label',
    valueType: 'string',
    selected: true,
  },
}

export default HEADERS
