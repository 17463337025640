import { IUniversalIdentifier } from '../../common'

export interface AutoRenewal {
  is_active: boolean
  days_before_cancellation: number
  days_before_email: number
}

export interface Membership extends IUniversalIdentifier {
  autorenewal: AutoRenewal
  name: string
  code: string
  version: number
  channels: string[]
  total: number
  total_monthly: number
  is_active: boolean
  is_git_sync: boolean
  play_right_only: boolean
  state: string
  fees: Fee[]
  duration: Duration
  description: string
  membership_requirements: MembershipRequirements[]
  membership_member_limit: MembershipMemberLimit
  id: number
  uuid: string
  is_sell_membership_enabled: boolean
  is_git_club_selectable: boolean
}

export interface MembershipCardPayment {
  amount: {
    value: number
    currency: string
  }
  state: string
  type: string
  paid_at: string
  id: number
  uuid: string
}

export type Fee = {
  id: number
  monthly_price: number
  price: number
  uuid: string
  vat: number
  fee_unit: FeeUnit
}

export type FeeUnit = {
  id: number
  uuid: string
  name: string
}

export type Duration = {
  options: string[]
  from: string
  to: string
}

export type MembershipRequirements = {
  type: string
  configuration: ConfigurationSetting
}

export type MembershipMemberLimit = {
  is_active: boolean
  current_members: number
  fully_booked: boolean
  max_members: number
}

export type ConfigurationSetting = {
  setting: boolean
}

export enum MembershipInvitationState {
  INIT = 'init',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export type MembershipInvitation = IUniversalIdentifier & {
  club: IUniversalIdentifier
  membership: IUniversalIdentifier
  email: string
  expiration_date: string
  import_date: string
  state: MembershipInvitationState
}

export enum MembershipItemType {
  INVITATION,
  CURRENT,
  LINK,
  AVAILABLE,
}

export enum MembershipCardPaymentSource {
  ONSITE = 'onsite',
  ONLINE = 'online',
  GIT = 'git',
}

export interface MembershipCard extends IUniversalIdentifier {
  /** Name of the membership card*/
  name: string
  is_locked: boolean
  is_paid: boolean
  status: string
  state: string
  membership: Membership
  order?: { token: number }
  payment_source?: MembershipCardPaymentSource
  starts_at: string
  expires_at: string
}

export interface PendingInvite extends IUniversalIdentifier {
  membership: Pick<Membership, 'uuid' | 'id' | 'name'>
  club: IUniversalIdentifier
  email: string
  expiration_date: string
  import_date: string
}

export interface MembershipSignup extends IUniversalIdentifier {
  membershipCardId: string
  first_name: string
  last_name: string
  email_address: string
  phone_number: string
  birth_date: string
  clubId: string
  playerId: string
  gitClubId: string
  signer: unknown
  guardian: unknown
}
