import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { UploadMediaObjectPayload, UploadMediaObjectResponse } from './types'

/**
 * Upload media object to cloud
 */
export const uploadMediaObject = (
  payload: UploadMediaObjectPayload
): Promise<UploadMediaObjectResponse> => {
  const url = `${getApiPlatformBaseUrl()}/media-objects`

  return request(url, {
    method: 'POST',
    contentType: 'multipart/form-data',
    body: payload,
  })
}
