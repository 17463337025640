import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

const CourseList = (props) => {
  const { courseList } = props

  return (
    <div className={style.courseList}>
      {courseList.map((course, index) => (
        <div key={course.id}>
          {course.name}
          <span onClick={() => props.onRemove(index)}>╳</span>
        </div>
      ))}
    </div>
  )
}

CourseList.propTypes = {
  courseList: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default CourseList
