import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { getInventoryCourseSchedules } from '@sweetspot/sweetspot-js/features/golfCarts/services/api-platform'
import { BOOKING_GROUPS, BOOKING_RELATIONS } from '../constants/bookingRelations'
import { ITEM_GROUPS } from '../constants/itemRelations'
import {
  fetchBooking,
  fetchBookingItems,
  fetchBookingPayments,
  fetchBookingRefunds,
  fetchBookingSpaces,
} from '../services/api-platform'

const DEFAULT_INCLUDED_RELATIONS = [BOOKING_RELATIONS.BOOKING, BOOKING_RELATIONS.ITEMS]
const DEFAULT_INCLUDED_BOOKING_GROUPS = [BOOKING_GROUPS.ALL]
const DEFAULT_INCLUDED_ITEM_GROUPS = []

const getBookingWithRelations = async (
  args = {
    bookingUuid: '',
    deserialize: false,
    includedRelations: DEFAULT_INCLUDED_RELATIONS,
    includedBookingGroups: DEFAULT_INCLUDED_BOOKING_GROUPS,
    includedItemGroups: DEFAULT_INCLUDED_ITEM_GROUPS,
  }
) => {
  const { bookingUuid, deserialize, includedRelations, includedBookingGroups, includedItemGroups } =
    {
      bookingUuid: '',
      deserialize: false,
      includedRelations: DEFAULT_INCLUDED_RELATIONS,
      includedBookingGroups: DEFAULT_INCLUDED_BOOKING_GROUPS,
      includedItemGroups: DEFAULT_INCLUDED_ITEM_GROUPS,
      ...args,
    }

  // Validate
  if (!bookingUuid) {
    throw new Error('getBookingWithRelations: bookingUuid is required')
  }

  if (!includedRelations.length) {
    throw new Error('getBookingWithRelations: includedRelations is required')
  }

  if (
    deserialize === true &&
    !includedRelations.includes(BOOKING_RELATIONS.BOOKING) &&
    !includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    throw new Error('getBookingWithRelations: deserialize is true, but booking is not included')
  }

  let promises = []

  // Fetch booking
  if (
    includedRelations.includes(BOOKING_RELATIONS.BOOKING) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    let bookingGroups

    if (includedBookingGroups.includes(BOOKING_GROUPS.ALL)) {
      bookingGroups = [
        ...Object.keys(BOOKING_GROUPS)
          .filter((key) => key !== 'ALL')
          .map((key) => BOOKING_GROUPS[key]),
      ]
    } else {
      bookingGroups = [...includedBookingGroups]
    }

    promises.push(fetchBooking(bookingUuid, { 'groups[]': bookingGroups }))
  } else {
    promises.push([])
  }

  // Fetch booking items
  if (
    includedRelations.includes(BOOKING_RELATIONS.ITEMS) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    let itemGroups
    if (includedItemGroups.includes(ITEM_GROUPS.ALL)) {
      itemGroups = [
        ...Object.keys(ITEM_GROUPS)
          .filter((key) => key !== 'ALL' && key !== 'WEB_BOOKING')
          .map((key) => ITEM_GROUPS[key]),
      ]
    } else {
      itemGroups = [...includedItemGroups]
    }

    promises.push(fetchBookingItems(bookingUuid, { 'groups[]': itemGroups }))
  } else {
    promises.push([])
  }

  // Fetch payments
  if (
    includedRelations.includes(BOOKING_RELATIONS.PAYMENTS) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    promises.push(fetchBookingPayments(bookingUuid))
  } else {
    promises.push([])
  }

  // Fetch refunds
  if (
    includedRelations.includes(BOOKING_RELATIONS.REFUNDS) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    promises.push(fetchBookingRefunds(bookingUuid))
  } else {
    promises.push([])
  }

  // Fetch spaces
  if (
    includedRelations.includes(BOOKING_RELATIONS.SPACES) ||
    includedRelations.includes(BOOKING_RELATIONS.ALL)
  ) {
    promises.push(fetchBookingSpaces(bookingUuid))
  } else {
    promises.push([])
  }

  const [res, err] = await to(Promise.all(promises))

  if (err) {
    console.log(err)
    throw new Error(err)
  }
  if (!res) {
    throw new Error('getBookingWithRelations: no results')
  }

  const [booking, bookingItems, bookingPayments, bookingRefunds, bookingSpaces] = res

  let inventorySchedulesRes, inventorySchedulesErr
  if (
    booking?.booking?.id &&
    (includedRelations.includes(BOOKING_RELATIONS.INVENTORY_SCHEDULES) ||
      includedRelations.includes(BOOKING_RELATIONS.ALL))
  ) {
    ;[inventorySchedulesRes, inventorySchedulesErr] = await to(
      getInventoryCourseSchedules({ booking: booking.booking.uuid })
    )
    if (inventorySchedulesErr) throw new Error(inventorySchedulesErr)
    if (!inventorySchedulesRes) throw new Error('getBookingWithRelations: no inventory schedules')
  }

  if (deserialize) {
    return {
      ...booking,
      booking: {
        ...booking.booking,
        inventory_course_schedules: inventorySchedulesRes,
      },
      items: bookingItems,
      payments: bookingPayments,
      refund_payments: bookingRefunds,
      spaces: bookingSpaces,
    }
  } else {
    return {
      booking,
      bookingItems,
      bookingPayments,
      bookingRefunds,
      bookingSpaces,
      inventorySchedulesRes,
    }
  }
}

export default getBookingWithRelations
