import React from 'react'
import PropTypes from 'prop-types'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import styles from '@sweetspot/club-portal-legacy/components/Wizard/containers/membership/GeneralSettings/styles.module.scss'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import Spinner from '@sweetspot/sweetspot-js/common/components/Spinner'
import { useTranslation } from 'react-i18next'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'

const MIGRATION_STATE = {
  Processing: 'processing',
  Completed: 'completed',
}

const MigrateButton = ({ autorenewal, membershipId, migrateMembershipAutorenewalSettings }) => {
  const role = useSelector((state) => getFirstMatchingRole(state.auth.roles))
  const { t } = useTranslation()

  if (!autorenewal?.is_active || !hasAccess(ACCESS_KEYS.PAGES.MEMBERSHIPS.MIGRATE, role?.value)) {
    return null
  }

  if (autorenewal.migration_state === MIGRATION_STATE.Completed) {
    return (
      <div className={cx(styles.migrateActionContainer)}>
        <Button theme={'black-outline'} disabled>
          {t('wizard.migrate')}
        </Button>
        <div className={cx(styles.migrateInfoContainer)}>
          <span className={cx(styles.completed)}>
            {t('wizard.migrationDoneMessage', {
              date: DateHelpers.onlyDateString(autorenewal.migrated_at),
            })}
          </span>
        </div>
      </div>
    )
  }

  if (autorenewal.migration_state === MIGRATION_STATE.Processing) {
    return (
      <div className={cx(styles.migrateActionContainer)}>
        <Button theme={'black-outline'} disabled>
          {t('wizard.migrate')}
          <Spinner visible={true} />
        </Button>
        <div className={cx(styles.migrateInfoContainer)}>
          <span>*</span>
          {t('wizard.migrate_info')}
        </div>
      </div>
    )
  }

  return (
    <div className={cx(styles.migrateActionContainer)}>
      <Button
        theme={'black-outline'}
        onClick={() => migrateMembershipAutorenewalSettings(membershipId)}
      >
        {t('wizard.migrate')}
      </Button>
      <div className={cx(styles.migrateInfoContainer)}>
        <span>*</span>
        {t('wizard.migrate_info')}
      </div>
    </div>
  )
}

MigrateButton.propTypes = {
  membershipId: PropTypes.number.isRequired,
  autorenewal: PropTypes.object,
  migrateMembershipAutorenewalSettings: PropTypes.func,
}

MigrateButton.defaultProps = {
  autorenewal: null,
  migrateMembershipAutorenewalSettings: () => {},
}

export default MigrateButton
