import { BayType } from '@sweetspot/shared/types'
import { apiPlatformClient, request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { QueryBaysPayload } from '@sweetspot/sweetspot-js/features/bays/services/types'

export const queryBays = (payload: QueryBaysPayload): Promise<{ bays: BayType[] }> => {
  const url = `${getApiPlatformBaseUrl()}/ranges/${payload.drivingRangeId}/bays`
  return request(url) as Promise<{ bays: BayType[] }>
}

export const queryBaysV2 = (payload: QueryBaysPayload) => {
  return apiPlatformClient().GET(`/api/v2/ranges/{rangeId}/bays`, {
    // @ts-expect-error-next-line
    params: { path: { rangeId: payload.drivingRangeId } },
  })
}
