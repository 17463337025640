import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import FeedbackContainer from './../Partials/FeedbackContainer'
import Label from './../Partials/Label'

const ButtonOptionsSelect = ({ error, label, options, onSelect, disabled, value, className }) => {
  const handleClick = (value) => {
    if (disabled) return
    if (onSelect) onSelect(value)
  }

  return (
    <div className={cx(styles.wrapper)}>
      <Label label={label} disabled={disabled} />
      <div className={cx(styles.container, { [styles.containerDisabled]: disabled }, className)}>
        {options.map((option) => (
          <div
            key={option.key || option.value}
            className={cx(styles.option, {
              [styles.optionSelected]: option.value === value,
              [styles.optionDisabled]: disabled,
              [styles.selectedAndDisabled]: option.value === value && disabled,
            })}
            onClick={() => handleClick(option.value)}
          >
            <p
              className={cx(styles.label, {
                [styles.labelSelected]: option.value === value,
              })}
            >
              {option.label}
            </p>
          </div>
        ))}
      </div>
      <FeedbackContainer error={error} />
    </div>
  )
}

ButtonOptionsSelect.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
}

ButtonOptionsSelect.defaultProps = {
  options: [],
  onSelect: () => {},
  disabled: false,
  error: '',
  label: '',
  className: '',
}

export default ButtonOptionsSelect
