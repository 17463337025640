const COURSE_TYPES = {
  COURSE: {
    value: 'course',
    labelKey: 'words.course_one',
  },
  SIMULATOR: {
    value: 'simulator',
    labelKey: 'words.simulator_one',
  },
  DRIVING_RANGE: {
    value: 'driving_range',
    labelKey: 'words.drivingRange',
  },
  PRO: {
    value: 'pro',
    labelKey: 'words.pro',
  },
  OTHER: {
    value: 'other',
    labelKey: 'words.other',
  },
}

export const COURSE_TYPES_ARR = [
  COURSE_TYPES.COURSE,
  COURSE_TYPES.SIMULATOR,
  COURSE_TYPES.DRIVING_RANGE,
  COURSE_TYPES.PRO,
  COURSE_TYPES.OTHER,
]

export default COURSE_TYPES
