import {
  _createAdmin,
  _getAdmins,
  _getSpecificAdmin,
  _updateAdmin,
  _deleteAdmin,
  _addClubToAdmin,
  _removeClubFromAdmin,
} from '@sweetspot/club-portal-legacy/services/adminApi'

import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'

/** ---------------- OLD ---------------- */
/** ---------------- OLD ---------------- */
/** ---------------- OLD ---------------- */

export function createClubAdmin(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createAdmin(payload)
        .then((res) => {
          dispatch(addToast('createdClubAdmin', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('createClubAdminError', 'error'))

          return reject(err)
        })
    })
  }
}

export function updateClubAdmin(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateAdmin(payload)
        .then((admin) => {
          dispatch(addToast('modifiedClubAdmin', 'success'))
          return resolve(admin)
        })
        .catch((err) => {
          dispatch(addToast('updateClubAdminError', 'error'))

          return reject(err)
        })
    })
  }
}

export function deleteClubAdmin(token, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deleteAdmin(id)
        .then((response) => {
          dispatch(addToast('deletedClubAdmin', 'success'))
          return resolve(response)
        })
        .catch(() => {
          dispatch(addToast('deleteClubAdminError', 'error'))
          return reject()
        })
    })
  }
}

/** ---------------- NEW ---------------- */
/** ---------------- NEW ---------------- */
/** ---------------- NEW ---------------- */

export const getListOfAdmins = (role, query) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getAdmins(role, query)
        .then((data) => resolve(data))
        .catch((err) => {
          dispatch(addToast('fetchAdminError', 'error'))
          reject(err)
        })
    })
  }
}

export const getAdminById = (token, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getSpecificAdmin(id)
        .then((data) => resolve(data))
        .catch((err) => {
          dispatch(addToast('fetchAdminError', 'error'))
          reject(err)
        })
    })
  }
}

export const addClubToAdmin = (token, clubId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addClubToAdmin(clubId, payload)
        .then((res) => {
          dispatch(addToast('addClubToAdminSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('addClubToAdminError', 'error'))

          return reject(err)
        })
    })
  }
}

export const removeClubFromAdmin = (token, clubId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _removeClubFromAdmin(clubId, payload)
        .then((res) => {
          dispatch(addToast('removeClubFromAdminSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('removeClubFromAdminError', 'error'))

          return reject(err)
        })
    })
  }
}
