import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import _sortBy from 'lodash/sortBy'

import * as API_APPLICATIONS from '@sweetspot/sweetspot-js/features/applications/services/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import { parseError } from '@sweetspot/sweetspot-js/common/functions/parse'

/**
 *
 * @param {{}} application
 * @returns
 */
const formatApplication = (application) => {
  return { ...application, name: application?.name?.name }
}

/**
 *
 * @param {[{}]} applications
 * @returns
 */
const formatApplications = (applications) => {
  return applications.map((app) => formatApplication(app))
}

const useApplications = (currentClubId, currentPage = null, pageLimit = null) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)

  const {
    data: applications,
    isFetching: isFetchingApplications,
    isFetchingNextPage,
    isFetchingPreviousPage,
    error: queryError,
    refetch,
  } = useInfiniteQuery(
    [
      CLUB_QUERIES.APPLICATIONS,
      {
        club: currentClubId,
        page: currentPage,
        limit: pageLimit,
      },
    ],
    () =>
      API_APPLICATIONS.fetchApplicationsList({
        club: currentClubId,
        page: currentPage,
        limit: pageLimit,
      }),
    {
      enabled: !!currentClubId,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length < pageLimit) return undefined
        return pages?.length + 1
      },
      select: ({ pages }) => {
        return _sortBy(formatApplications(pages[0]), 'name')
      },
    }
  )

  const createApplication = async (payload = { name: '', is_active: true }) => {
    try {
      setIsUpdating(true)
      const application = await API_APPLICATIONS.createApplication(payload)
      refetch().then()
      return formatApplication(application)
    } catch (e) {
      setError(Symbol(parseError(e)))
    } finally {
      setIsUpdating(false)
    }
  }

  const setApplicationStatus = async (application, isActive) => {
    try {
      setIsUpdating(true)
      await API_APPLICATIONS.updateApplication(application, isActive)
      refetch().then()
    } catch (e) {
      setError(Symbol(parseError(e)))
    } finally {
      setIsUpdating(false)
    }
  }

  const removeApplication = async (applicationID) => {
    try {
      setIsUpdating(true)
      await API_APPLICATIONS.removeApplication(applicationID)
      refetch().then()
    } catch (e) {
      setError(Symbol(parseError(e)))
    } finally {
      setIsUpdating(false)
    }
  }

  return {
    applications: applications || [],
    refetch,
    error: error || (queryError ? parseError(queryError) : null),
    createApplication,
    setApplicationStatus,
    removeApplication,
    isLoading: isFetchingApplications || isUpdating || isFetchingPreviousPage || isFetchingNextPage,
  }
}

export default useApplications
