import React, { useMemo, useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import produce from 'immer'
import ButtonAndDropdownSelectWrapper from '@sweetspot/sweetspot-js/common/components/ButtonAndDropdownSelectWrapper'
import ButtonAndDropdownWrapper from '@sweetspot/sweetspot-js/common/components/ButtonAndDropdownWrapper'
import NestedRowWrapper from '@sweetspot/sweetspot-js/common/components/NestedRowWrapper'

import { ReactComponent as CrossIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { DAYS_OPTIONS } from '@sweetspot/sweetspot-js/features/promotions/constants/rules'

const DaysAndTimesChecker = ({
  rule,
  disabled,
  onSetRule,
  onSaveRule,
  loading,
  voucherDisabled,
}) => {
  const { t } = useTranslation()

  const [showSelectDays, setShowSelectDays] = useState(false)
  const [showSelectTime, setShowSelectTime] = useState(false)

  const [fromValue, setFromValue] = useState('')
  const [fromError, setFromError] = useState('')

  const [toValue, setToValue] = useState('')
  const [toError, setToError] = useState('')

  const conf = useMemo(() => {
    return rule?.configuration || {}
  }, [rule])

  const daysConf = useMemo(() => {
    let days = Object.keys(conf)
      .map((key) => DAYS_OPTIONS.find((x) => x.value === key))
      .sort((a, b) => a.id - b.id)
    if (days?.length > 0) return days
    return false
  }, [conf])

  useEffect(() => {
    setFromValue('')
    setToValue('')
  }, [showSelectTime])

  useEffect(() => {
    setFromError('')
  }, [fromValue])

  useEffect(() => {
    setToError('')
  }, [toValue])

  const updateDaysConf = (newValues) => {
    const newRule = produce(rule, (draft) => {
      if (Array.isArray(draft.configuration)) {
        draft.configuration = {}
      }

      Object.keys(draft.configuration).forEach((day) => {
        if (!newValues.find((x) => x.value === day)) {
          delete draft.configuration[day]
        }
      })

      newValues.forEach((day) => {
        if (!draft.configuration[day.value]) {
          draft.configuration[day.value] = []
        }
      })
    })

    onSetRule(newRule)
    onSaveRule(newRule)
    setShowSelectDays(false)
  }

  const removeDay = (day) => {
    const newRule = produce(rule, (draft) => {
      delete draft.configuration[day.value]
    })

    onSetRule(newRule)
    onSaveRule(newRule)
  }

  const removeTime = (day, index) => {
    const newRule = produce(rule, (draft) => {
      draft.configuration[day.value].splice(index, 1)
    })

    onSetRule(newRule)
    onSaveRule(newRule)
  }

  const addNewTime = (day) => {
    let from = fromValue
    let to = toValue

    const dayTimes = conf[day.value]

    from = from + ':00'
    to = to + ':59'

    const getTimeNumber = (str) => {
      const a = str.split(':')
      const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
      return parseInt(seconds)
    }

    // hh:mm:ss
    const pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/

    if (!from.match(pattern)) {
      setFromError(t('sentences.invalidTimeFormat'))
      return
    }
    if (!to.match(pattern)) {
      setToError(t('sentences.invalidTimeFormat'))
      return
    }

    const fromNumber = getTimeNumber(from)
    const toNumber = getTimeNumber(to)

    if (fromNumber < 0 || fromNumber > 235900) {
      setFromError(t('sentences.invalidTimeFormat'))
      return
    }

    if (toNumber < 0 || toNumber > 235959) {
      setToError(t('sentences.invalidTimeFormat'))
      return
    }

    if (toNumber - 59 <= fromNumber) {
      setToError(t('sentences.toValueMustBeHigherThanFromValue'))
      return
    }

    if (
      dayTimes.find((x) => fromNumber >= getTimeNumber(x.from) && fromNumber <= getTimeNumber(x.to))
    ) {
      setFromError(t('sentences.timeCannotOverlapAnAlreadyAddedTime'))
      return
    }
    if (
      dayTimes.find((x) => toNumber >= getTimeNumber(x.from) && toNumber <= getTimeNumber(x.to))
    ) {
      setToError(t('sentences.timeCannotOverlapAnAlreadyAddedTime'))
      return
    }

    if (
      dayTimes.find((x) => getTimeNumber(x.from) >= fromNumber && getTimeNumber(x.to) <= toNumber)
    ) {
      setFromError(t('sentences.timeCannotOverlapAnAlreadyAddedTime'))
      setToError(t('sentences.timeCannotOverlapAnAlreadyAddedTime'))
      return
    }

    const newRule = produce(rule, (draft) => {
      draft.configuration[day.value].push({
        from,
        to,
      })
    })

    setShowSelectTime(false)
    onSetRule(newRule)
    onSaveRule(newRule)
  }

  return (
    <div className={cx(styles.container)}>
      {!daysConf && (
        <p className={cx(styles.noDaysSelectedText)}>{t('sentences.noDaysSelected')}</p>
      )}

      {daysConf &&
        daysConf.map((day) => (
          <NestedRowWrapper
            title={t(day.label)}
            key={day.id}
            onRemove={() => removeDay(day)}
            voucherDisabled={voucherDisabled}
            hideContent={voucherDisabled}
          >
            <div className={cx(styles.pillsContainer)}>
              {conf[day.value].map((time, index) => (
                <div key={index} className={cx(styles.miniPill)}>
                  <span>
                    {time.from.substr(0, 5)} - {time.to.substr(0, 5)}
                  </span>
                  {!disabled && (
                    <span className={styles.closeButton} onClick={() => removeTime(day, index)}>
                      {!voucherDisabled && <CrossIcon />}
                    </span>
                  )}
                </div>
              ))}
            </div>
            {!voucherDisabled && (
              <ButtonAndDropdownWrapper
                buttonProps={{
                  icon: 'plus',
                  text: t('words.time'),
                  onClick: () => setShowSelectTime(day),
                  disabled: disabled,
                }}
                id={'select-time-dropdown'}
                visible={showSelectTime?.id === day.id}
                dropdownClassName={styles.timeDropdownContainer}
                onCancel={() => showSelectTime?.id === day.id && setShowSelectTime(false)}
              >
                <React.Fragment>
                  <p className={cx(styles.dropdownTitle)}>{t('sentences.addTime')}</p>
                  {showSelectTime?.id === day.id && (
                    <div className={cx(styles.inputsRow)}>
                      <TextInputField
                        containerClassName={cx(styles.input)}
                        labelTwo={t('words.from')}
                        containerWidth="half"
                        type="time"
                        placeholder="00:00"
                        value={fromValue}
                        onChange={(val) => setFromValue(val)}
                        error={fromError}
                        inputProps={{
                          tabIndex: 1,
                        }}
                        onEnter={() => addNewTime(day)}
                      />
                      <TextInputField
                        containerClassName={cx(styles.input)}
                        labelTwo={t('words.to')}
                        containerWidth="half"
                        type="time"
                        placeholder="00:00"
                        value={toValue}
                        onChange={(val) => setToValue(val)}
                        error={toError}
                        inputProps={{
                          tabIndex: 2,
                        }}
                        onEnter={() => addNewTime(day)}
                      />
                    </div>
                  )}
                  <div className={cx(styles.buttonsRow)}>
                    <Button
                      theme="whitebg-grayoutline"
                      text={t('words.cancel')}
                      onClick={() => setShowSelectTime(false)}
                      className={cx(styles.button)}
                    />
                    <Button
                      className={cx(styles.button)}
                      text={t('words.save')}
                      onClick={() => addNewTime(day)}
                    />
                  </div>
                </React.Fragment>
              </ButtonAndDropdownWrapper>
            )}
          </NestedRowWrapper>
        ))}

      {!voucherDisabled && (
        <ButtonAndDropdownSelectWrapper
          id="select-days-dropdown"
          buttonProps={{
            icon: 'plus',
            text: t('dateTime.words.days'),
            onClick: () => setShowSelectDays(true),
            disabled: disabled,
            loading: loading,
          }}
          dropdownProps={{
            preselectedOptions: DAYS_OPTIONS.filter((day) =>
              daysConf ? daysConf.find((x) => x.value === day.value) : false
            ),
            translateLabels: true,
            isVisible: showSelectDays,
            searchEnabled: true,
            onCancel: () => setShowSelectDays(false),
            onSave: updateDaysConf,
            options: DAYS_OPTIONS,
          }}
        />
      )}
    </div>
  )
}

DaysAndTimesChecker.propTypes = {
  rule: PropTypes.object,
  disabled: PropTypes.bool,
  onSetRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  loading: PropTypes.bool,
}

DaysAndTimesChecker.defaultProps = {
  disabled: false,
  onSetRule: () => {},
  onSaveRule: () => {},
  loading: false,
}

export default DaysAndTimesChecker
