import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { IUniversalIdentifier } from '@sweetspot/shared/types'

import {
  addPriceOverridePayload,
  createPricePeriodPayload,
  createPriceSegmentPayload,
  updatePriceChartPayload,
  updatePricePeriodPayload,
  updatePriceSegmentPayload,
  getPricingPeriodsResponse,
  updatePriceChartResponse,
} from './types'

export function getPricingPeriods(courseId: string): Promise<getPricingPeriodsResponse> {
  const URL = `${getApiPlatformBaseUrl()}/price-periods?course=${courseId}`
  return request(URL)
}

export function createPricePeriod(
  payload: createPricePeriodPayload
): Promise<IUniversalIdentifier> {
  const { courseId, pricePeriod } = payload

  const URL = `${getApiPlatformBaseUrl()}/courses/${courseId}/price-period`
  return request(URL, {
    method: 'PUT',
    body: {
      start_date: pricePeriod.start_date,
      end_date: pricePeriod.end_date,
      name: pricePeriod.name,
      is_active: pricePeriod.is_active,
      is_default: pricePeriod.is_default,
    },
  })
}

export function createPriceSegment(
  payload: createPriceSegmentPayload
): Promise<IUniversalIdentifier> {
  const { periodId, priceSegment } = payload

  const URL = `${getApiPlatformBaseUrl()}/price-periods/${periodId}/price-segment`
  return request(URL, {
    method: 'PUT',
    body: {
      segment_tag: priceSegment.segment_tag,
      priority: priceSegment.priority,
      configuration: priceSegment.configuration,
    },
  })
}

export function updatePricePeriod(props: updatePricePeriodPayload): Promise<IUniversalIdentifier> {
  const { pricePeriod } = props

  const URL = `${getApiPlatformBaseUrl()}/price-periods/${pricePeriod.id}`
  return request(URL, {
    method: 'PUT',
    body: {
      start_date: pricePeriod.start_date,
      end_date: pricePeriod.end_date,
      name: pricePeriod.name,
      is_active: pricePeriod.is_active,
      is_default: pricePeriod.is_default,
    },
  })
}

export function updatePriceSegment(
  props: updatePriceSegmentPayload
): Promise<IUniversalIdentifier> {
  const { segmentId, priceSegment } = props

  const URL = `${getApiPlatformBaseUrl()}/price-segments/${segmentId}`
  return request(URL, {
    method: 'PUT',
    body: {
      priority: priceSegment.priority,
      configuration: priceSegment.configuration,
    },
  })
}

export function removePricePeriod(periodId: string): Promise<void> {
  const URL = `${getApiPlatformBaseUrl()}/price-periods/${periodId}`
  return request(URL, { method: 'DELETE' })
}

export const getPricingOverrides = (periodId: string): [] => {
  const URL = `${getApiPlatformBaseUrl()}/price-overrides?price_period=${periodId}`

  return request(URL, { method: 'GET' })
}

export const addPriceOverride = (
  payload: addPriceOverridePayload
): Promise<IUniversalIdentifier> => {
  const { id, from, to, amount, currency } = payload
  const URL = `${getApiPlatformBaseUrl()}/price-periods/${id}/price-override`

  return request(URL, {
    method: 'PUT',
    body: {
      from,
      to,
      amount,
      currency,
    },
  })
}

export const removePriceOverride = (overrideId: number): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/price-overrides/${overrideId}`

  return request(URL, { method: 'DELETE' })
}

export const updatePriceChart = (
  periodId: number,
  payload: updatePriceChartPayload
): updatePriceChartResponse => {
  const URL = `${getApiPlatformBaseUrl()}/price-periods/${periodId}/simulate`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}
