import cx from 'classnames'
import PropTypes from 'prop-types'

import RadioGroup from '@sweetspot/sweetspot-js/common/components/FormElements/RadioGroup'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ToggleSwitch from '@sweetspot/club-portal-legacy/components/ToggleSwitch'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import style from './style.module.scss'
import { TEE_TIME_COLORS } from '@sweetspot/shared/util/constants'

const displayOptions = [
  { value: 'default', label: 'teeTimeCategory.default' },
  { value: 'full', label: 'teeTimeCategory.fullBooked' },
  { value: 'none', label: 'teeTimeCategory.noSlots' },
]

const General = ({ isLoading, category, form, onCancel, onChange, onCreate, onUpdate }) => {
  const { t } = useTranslation()

  const getNameError = () => {
    if (!form.name) return ''
    if (form.name?.length > 50) return t('teeTimeCategory.nameError')
    return ''
  }

  const getCustomNameError = () => {
    if (!form.displayName?.replace(/\s/g, '')) return t('errors.required')
    if (form.displayName?.length > 50) return t('teeTimeCategory.nameError')
    return ''
  }

  const getDescError = () => {
    if (!form.description) return ''
    if (form.description?.length > 200) return t('teeTimeCategory.descError')
    return ''
  }

  const getCustomDescError = () => {
    if (!form.displayDesc) return ''
    if (form.displayDesc?.length > 200) return t('teeTimeCategory.descError')
    return ''
  }

  const showDescButtons = () => {
    return !getDescError() && category && category?.description !== form.description
  }

  const showCustomNameButtons = () => {
    return (
      !getCustomNameError() &&
      form.displayName?.replace(/\s/g, '') &&
      (category?.is_use_same_name ? category?.name : category?.custom_name) !== form.displayName
    )
  }

  const showCustomDescButtons = () => {
    return (
      !getCustomDescError() &&
      (category?.is_use_same_name ? category?.description : category?.custom_description) !==
        form.displayDesc
    )
  }

  const handleUpdate = (temp = form, loader = false) => {
    const payload = {
      id: category?.id,
      name: temp.name,
      description: temp.description,
      color: temp.color,
      is_use_same_name: temp.useSameName,
      custom_name: temp.displayName,
      custom_description: temp.displayDesc,
      display: temp.displaySetting,
    }

    onUpdate(payload, loader)
  }

  return (
    <div className={style.container}>
      <div className={style.formField}>
        <TextInputField
          label=".name"
          containerWidth="220px"
          inputProps={{ autoFocus: true }}
          placeholder={t('teeTimeCategory.addName')}
          confirmText={category ? 'save' : 'create'}
          value={form.name}
          error={getNameError()}
          confirmLoading={isLoading}
          onChange={(value) => onChange({ name: 'name', value })}
          showActionButtons={!isLoading && !getNameError() && category?.name !== form.name}
          showCancelButton={category?.name !== form.name && !!form.name?.replace(/\s/g, '')}
          onEnter={category ? () => handleUpdate(form, true) : onCreate}
          onConfirm={category ? () => handleUpdate(form, true) : onCreate}
          onCancel={onCancel}
          onOutsideClick={
            !isLoading && !getNameError() && category?.name !== form.name ? onCancel : undefined
          }
        />
      </div>
      <br />
      <div className={style.formField}>
        <TextInputField
          label=".settings.description"
          type="text-area"
          containerWidth="400px"
          disabled={!category}
          placeholder={t('teeTimeCategory.addDesc')}
          confirmText="save"
          error={getDescError()}
          value={form.description}
          confirmLoading={isLoading}
          onChange={(value) => onChange({ name: 'description', value })}
          showActionButtons={showDescButtons()}
          onEnter={() => handleUpdate(form, true)}
          onConfirm={() => handleUpdate(form, true)}
          onCancel={onCancel}
          onOutsideClick={showDescButtons() ? onCancel : undefined}
        />
      </div>
      <br />
      <div className={cx(style.formField, style.colorField)}>
        <label>{t('teeTimeCategory.selectColor')}</label>
        <div className={cx(style.colorList, { [style.disabled]: !category || isLoading })}>
          {TEE_TIME_COLORS.map((color, index) => (
            <div
              key={index}
              className={cx(style.outer, {
                [style.active]: form?.color === color,
              })}
              onClick={() => {
                if (category && !isLoading) {
                  onChange({ name: 'color', value: color }, true, true)
                }
              }}
            >
              <div style={{ background: color }} />
            </div>
          ))}
        </div>
      </div>
      <div className={style.displaySettings}>
        <label>{t('teeTimeCategory.displaySettings')}</label>
        <p>{t('teeTimeCategory.displaySlots')}</p>
        <RadioGroup
          value={form.displaySetting}
          disabled={!category}
          options={displayOptions.map((item) => ({ ...item, label: t(item.label) }))}
          onChange={(value) => {
            onChange({ name: 'displaySetting', value }, true)
          }}
        />
        <div className={style.secondSetting}>
          <div>
            <ToggleSwitch
              round
              small
              checked={form.useSameName}
              textChecked=". "
              textUnchecked=". "
              disabled={!category || isLoading}
              onChange={(value) => {
                onChange({ name: 'useSameName', value }, true, true)
              }}
            />
            <label>{t('teeTimeCategory.sameNameDesc')}</label>
          </div>
        </div>
        {!form.useSameName && (
          <>
            <div className={style.formField}>
              <TextInputField
                label=".name"
                containerWidth="220px"
                placeholder={t('teeTimeCategory.addName')}
                confirmText="save"
                error={getCustomNameError()}
                value={form.displayName}
                confirmLoading={isLoading}
                onChange={(value) => onChange({ name: 'displayName', value })}
                showActionButtons={showCustomNameButtons()}
                onEnter={() => handleUpdate(form, true)}
                onConfirm={() => handleUpdate(form, true)}
                onCancel={onCancel}
                onOutsideClick={showCustomNameButtons() ? onCancel : undefined}
              />
            </div>
            <br />
            <div className={style.formField}>
              <TextInputField
                label=".settings.description"
                type="text-area"
                containerWidth="400px"
                placeholder={t('teeTimeCategory.addDesc')}
                confirmText="save"
                error={getCustomDescError()}
                value={form.displayDesc}
                confirmLoading={isLoading}
                onChange={(value) => onChange({ name: 'displayDesc', value })}
                showActionButtons={showCustomDescButtons()}
                onEnter={() => handleUpdate(form, true)}
                onConfirm={() => handleUpdate(form, true)}
                onCancel={onCancel}
                onOutsideClick={showCustomDescButtons() ? onCancel : undefined}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

General.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  category: PropTypes.object,
  form: PropTypes.object,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default General
