import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import moment from 'moment'
import { inRange } from 'lodash'

const SimulatorRangesConflictHeader = ({
  selectedFrom,
  selectedTo,
  selectedSpaces,
  bookingsPerSpace,
  onCancel,
}) => {
  const { t } = useTranslation()

  const isConflict = useMemo(() => {
    if (
      !bookingsPerSpace ||
      !Object.keys(bookingsPerSpace)?.length ||
      !selectedFrom ||
      !selectedTo ||
      !selectedSpaces?.length
    )
      return false

    let bookings = []
    Object.keys(bookingsPerSpace).forEach((spaceId) => {
      bookingsPerSpace[spaceId].forEach((booking) =>
        bookings.push({
          startTime: booking.booking.start_time,
          endTime: booking.booking.end_time,
          spaceId: parseInt(spaceId),
        })
      )
    })

    return bookings.some((booking) => {
      const { startTime, endTime, spaceId } = booking
      const bookingStartTime = moment(startTime).unix()
      const bookingEndTime = moment(endTime).unix()

      const includesSelectedSpace = selectedSpaces.some((x) => x.id === spaceId)

      if (!includesSelectedSpace) return false

      if (
        inRange(selectedFrom, bookingStartTime, bookingEndTime) ||
        inRange(selectedTo - 1, bookingStartTime, bookingEndTime) ||
        (selectedFrom <= bookingStartTime && selectedTo >= bookingEndTime)
      )
        return true

      return false
    })
  }, [selectedFrom, selectedTo, bookingsPerSpace, selectedSpaces])

  return (
    <div className={cx(styles.container, isConflict && styles.visible)}>
      <div className={cx(styles.inner)}>
        <span className={cx('material-icons-round md-32 md-red', styles.icon)}>warning</span>
        <p className={cx(styles.text)}>{t('sentences.thereIsAConflictWithAnotherBooking')}</p>
        <span onClick={onCancel} className={cx('material-icons md-32 md-red', styles.close)}>
          close
        </span>
      </div>
    </div>
  )
}

SimulatorRangesConflictHeader.propTypes = {
  selectedFrom: PropTypes.number,
  selectedTo: PropTypes.number,
  bookingsPerSpace: PropTypes.object,
  onCancel: PropTypes.func,
  selectedSpaces: PropTypes.array,
}

SimulatorRangesConflictHeader.defaultProps = {}

export default SimulatorRangesConflictHeader
