import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useQueries } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import SlotsCalendar from '../../../../../../Venues/Common/components/SlotsCalendar'
import SpaceColumn from '../../../../../../Venues/Common/components/SpaceColumn'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { getTeeTimesCollection } from '@sweetspot/sweetspot-js/features/teeTimes/js/getTeeTimesCollection'
import {
  getStartAndEndOfDateLocal,
  getStartAndEndOfDateTZ,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'
import useQueryParams from '@sweetspot/sweetspot-js/common/hooks/useQueryParams'
import {
  fetchBookings,
  changeTime,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { getPriceObject } from '@sweetspot/sweetspot-js/features/teeTimes/js/utils'
import { setSessionStorage } from '@sweetspot/shared/util/session-storage'
import cx from 'classnames'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import moment from 'moment'
import produce from 'immer'
import { useParams } from 'react-router'
import { BOOKING_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

export const SpacesStep = ({
  venue,
  orderBooking,
  orderBookingRefetch,
  isBookSpaceClicked,
  setNewSelectedSpaces,
  setEditBookingState,
  handleBookSpaceBtnDisabledState,
  setBookSpaceBtnLoadingState,
  setBookSpaceBtnState,
  isBay,
  bays,
}): JSX.Element => {
  const currentClubId = useSelector((state) => state?.golfClub?.selectedId)
  const [selectedFrom, setSelectedFrom] = useState(null)
  const [selectedTo, setSelectedTo] = useState(null)
  const [selectedSpaces, setSelectedSpaces] = useState([])
  const [bookedSpacesTeeTimes, setBookedSpacesTeeTimes] = useState([])

  const { uuid: bookingUuid } = useParams()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const query = useQueryParams()
  const [state, setState] = useMergeState({
    currentVenue: null,
    currentDate: null,
    currentVenueSpaces: null,
    teeTimesPerSpace: null,
    bookingsPerSpace: null,
    bookingRelatedSpaces: null,
  })

  const selectedTeeTimes = useMemo(() => {
    if (selectedSpaces?.length && selectedFrom && selectedTo && state.teeTimesPerSpace) {
      const teeTimes = []
      Object.keys(state.teeTimesPerSpace).forEach((spaceId) => {
        if (selectedSpaces.find((x) => x.id === parseInt(spaceId))) {
          state.teeTimesPerSpace[spaceId].forEach((teeTime) => {
            if (state.currentVenue?.is_use_dynamic_pricing && teeTime.dynamic_price === null) return
            if (state.currentVenue?.is_use_dynamic_pricing === false && teeTime.price === null)
              return
            const { from_unix, to_unix } = teeTime
            if (from_unix >= selectedFrom && to_unix <= selectedTo) teeTimes.push(teeTime)
          })
        }
      })
      return teeTimes
    }
    return []
  }, [selectedFrom, selectedSpaces, selectedTo, state.teeTimesPerSpace, state.currentVenue])

  const { isFetching: spacesIsFetching } = useQuery(
    [CLUB_QUERIES.SPACES, { course: state.currentVenue?.id }],
    () => getSpaces({ course: state.currentVenue?.id }),
    {
      enabled: !!state.currentVenue?.id,
      onSuccess: (data) => {
        setState({ currentVenueSpaces: data, teeTimesPerSpace: null, bookingsPerSpace: null })
      },
    }
  )

  const teeTimesResults = useQueries(
    state.currentVenueSpaces?.map((space) => ({
      queryKey: [
        CLUB_QUERIES.TEE_TIMES,
        space.id,
        state.currentDate,
        currentClubId,
        state.currentVenue,
      ],
      queryFn: () => {
        const [startDay, endDay] = getStartAndEndOfDateTZ(
          state.currentDate,
          state.currentVenue?.timezone ?? 'Europe/Stockholm'
        )

        return getTeeTimesCollection({
          courseUuid: state.currentVenue?.uuid,
          spaceUuid: space.uuid,
          startDate: startDay,
          endDate: endDay,
          is_use_dynamic_pricing: state.currentVenue.is_use_dynamic_pricing,
        })
      },
      enabled: !!space?.id && !!state.currentVenue?.id && !!state.currentDate,
      onSuccess: (data) => {
        if (
          (state.currentVenue?.is_use_dynamic_pricing &&
            (data?.[0]?.dynamic_price || data?.[0]?.price)) ||
          !state.currentVenue?.is_use_dynamic_pricing
        )
          setState((prev) => ({
            teeTimesPerSpace: {
              ...prev.teeTimesPerSpace,
              [space.id]: data,
            },
          }))
      },
    })) || []
  )

  useQueries(
    state.currentVenueSpaces?.map((space) => ({
      queryKey: [
        CLUB_QUERIES.BOOKINGS,
        space.uuid,
        state.currentDate,
        state.currentVenue,
        {
          'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
        },
      ],
      queryFn: () => {
        const [startDay, endDay] = getStartAndEndOfDateLocal(new Date(state.currentDate))
        return getAllPagesRequest(fetchBookings, {
          'course.uuid': state.currentVenue?.uuid,
          'booking.start_time[after]': startDay?.toISOString(),
          'booking.start_time[before]': endDay?.toISOString(),
          'spaces.uuid': space.uuid,
          'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
          'groups[]': [BOOKING_GROUPS.LEGACY_BOOKING, BOOKING_GROUPS.CUSTOMER],
          page: 1,
          limit: 50,
        })
      },
      enabled: !!state.currentVenue?.uuid && !!state.currentDate && !!space?.uuid,
      refetchInterval: 30000,
      onSuccess: (data) => {
        setState((prev) => ({
          bookingsPerSpace: {
            ...prev.bookingsPerSpace,
            [space.id]: data,
          },
        }))
      },
    })) || []
  )

  const teeTimesIsLoading = useMemo(() => {
    if (teeTimesResults) {
      return teeTimesResults.some((el) => el.isFetching)
    }
    return true
  }, [teeTimesResults])

  useEffect(() => {
    if (venue) setState({ currentVenue: venue })
  }, [venue])

  useEffect(() => {
    if (selectedSpaces?.length === 0) {
      handleSelectFrom(null)
      handleSelectTo(null)
    }
  }, [selectedSpaces])

  useEffect(() => {
    if (state.currentVenue && !state.currentDate) {
      const urlDate = query.get('date')
      let date = moment(orderBooking.booking.start_time).tz(state.currentVenue.timezone)
      if (urlDate) {
        date = moment.unix(parseInt(urlDate)).tz(state.currentVenue.timezone)
      }
      setState({ currentDate: date })
    }
  }, [state.currentVenue, state.currentDate])

  useEffect(() => {
    if (bookedSpacesTeeTimes.length) {
      let teeTimes = []
      bookedSpacesTeeTimes.forEach((spaceTeetimeObj) => {
        if (spaceTeetimeObj.bookedTeeTimes.length) {
          teeTimes = [...teeTimes, ...spaceTeetimeObj.bookedTeeTimes]
        }
      })

      const bookedTeeTimeUuid = teeTimes.map((teeTime) => teeTime.uuid)
      const isNewTeeTimeAdded = selectedTeeTimes.filter(
        (teeTime) => !bookedTeeTimeUuid.includes(teeTime.uuid)
      )

      if (bookedTeeTimeUuid.length > selectedTeeTimes.length || isNewTeeTimeAdded.length) {
        handleBookSpaceBtnDisabledState(false)
      } else {
        handleBookSpaceBtnDisabledState(true)
      }
    }
  }, [selectedTeeTimes, bookedSpacesTeeTimes])

  useEffect(() => {
    if (state.teeTimesPerSpace) {
      if (state.currentVenueSpaces.length === Object.keys(state.teeTimesPerSpace).length) {
        const bookings = state.bookingsPerSpace
        const teeTimes = state.teeTimesPerSpace
        const spaceAndTeetimes = []
        if (bookings && teeTimes) {
          state.currentVenueSpaces.forEach((space) => {
            let bookedTeeTimes = []
            if (teeTimes?.[space.id]) {
              bookedTeeTimes = teeTimes?.[space.id].filter((teeTime) =>
                getTeeTimeBooking(bookings?.[space.id], teeTime)
              )
              spaceAndTeetimes.push({ relatedSpace: space, bookedTeeTimes })
            }
          })

          setBookedSpacesTeeTimes(spaceAndTeetimes)
          spaceAndTeetimes.forEach(({ relatedSpace, bookedTeeTimes }) => {
            if (bookedTeeTimes.length) {
              handleSelectSpace(relatedSpace)
              const { from_unix: from } = bookedTeeTimes[0]
              const { to_unix: to } = bookedTeeTimes[bookedTeeTimes.length - 1]
              handleSelectFrom(from)
              handleSelectTo(to)
            }
          })
        }
      }
    }
  }, [venue, state.teeTimesPerSpace])

  useEffect(() => {
    if (isBookSpaceClicked) {
      handleBookSpaces()
    }
  }, [isBookSpaceClicked])

  useEffect(() => {
    setNewSelectedSpaces(selectedSpaces)
  }, [selectedSpaces])

  const getTeeTimeBooking = (bookings, teeTime) => {
    return bookings?.find((booking) => {
      if (booking.uuid === bookingUuid) {
        const bookingStart = new Date(booking.booking.start_time),
          bookingEnd = new Date(booking.booking.end_time),
          teeTimeStart = new Date(teeTime.from),
          teeTimeEnd = new Date(teeTime.to)
        return (
          bookingStart.getTime() === teeTimeStart.getTime() ||
          bookingEnd.getTime() === teeTimeEnd.getTime() ||
          (teeTimeStart.getTime() > bookingStart.getTime() &&
            teeTimeEnd.getTime() < bookingEnd.getTime())
        )
      }
    })
  }

  const handleSelectFrom = (from) => {
    setSelectedFrom(from)
  }
  const handleSelectTo = (to) => {
    setSelectedTo(to)
  }

  const handleSelectSpace = (space) => {
    setSelectedSpaces(
      produce((draft) => {
        const index = draft.findIndex((x) => x.id === space.id)

        if (index !== -1) {
          draft.splice(index, 1)
        } else {
          draft.push(space)
        }
      })
    )
  }

  const getRelatedPayload = (spacesData = null, teeTimeData = null) => {
    if (!teeTimeData) teeTimeData = selectedTeeTimes
    if (!spacesData) spacesData = selectedSpaces

    const dateFormat = 'YYYY-MM-DD HH:mm:ss'
    const teeTimesPerSpace = state.teeTimesPerSpace

    const payload = {
      startTime: moment.unix(selectedFrom).utc().format(dateFormat),
      endTime: moment.unix(selectedTo).utc().format(dateFormat),
      data: [],
    }

    try {
      spacesData.forEach((space) => {
        const { uuid, id } = space
        const teeTimes = []
        teeTimesPerSpace[id].forEach((teeTime) => {
          if (teeTimeData.some((x) => x.id === teeTime.id)) {
            const priceObject = getPriceObject(teeTime, venue?.is_use_dynamic_pricing)
            teeTimes.push({
              uuid: teeTime.uuid,
              price: priceObject?.amount || 0,
            })
          }
        })

        payload.data.push({
          space_uuid: uuid,
          tee_times: teeTimes,
        })
      })
      return payload
    } catch (error) {
      addToast(t('sentences.failedToReserveBooking'), { appearance: 'error' })
      // Refetch teetimes in case our optimistic updates of UI caused problems
      for (const teetime of teeTimesResults) {
        teetime.refetch()
      }
      return
    }
  }

  const handleBookSpaces = async () => {
    if (selectedSpaces.length > 0) {
      setBookSpaceBtnLoadingState(true)
      setNewSelectedSpaces(selectedSpaces)

      let alreadyBookedTeeTimes = bookedSpacesTeeTimes.map(
        (spaceTeetimeObj) => spaceTeetimeObj.bookedTeeTimes
      )
      alreadyBookedTeeTimes = alreadyBookedTeeTimes.filter((teeTimeArr) => teeTimeArr.length > 0)

      let bookedTeeTimesLength = 0,
        bookedTeeTimes = []
      alreadyBookedTeeTimes.forEach((teeTimesArray) => {
        bookedTeeTimesLength = bookedTeeTimesLength + teeTimesArray.length
        bookedTeeTimes = [...bookedTeeTimes, ...teeTimesArray]
      })
      const payload = getRelatedPayload(selectedSpaces, selectedTeeTimes)

      const [res, err] = await to(
        changeTime(payload, bookingUuid, (headers, data) => {
          setSessionStorage(`cancel-${data.order.uuid}`, JSON.stringify(headers))
        })
      )
      if (err) {
        setEditBookingState(false)
        setBookSpaceBtnLoadingState(false)
      }

      if (res) {
        setEditBookingState(true)
      }

      setBookSpaceBtnState(false)
      orderBookingRefetch()
    }
  }

  const showSheet = useMemo(() => {
    return !spacesIsFetching && !teeTimesIsLoading && venue && state.currentVenueSpaces?.length
  }, [spacesIsFetching, venue, state.currentVenueSpaces, teeTimesIsLoading])

  return showSheet ? (
    <SlotsCalendar
      currentVenue={state.currentVenue}
      className={cx(styles.spacesContainer)}
      spaces={state.currentVenueSpaces}
      teeTimesPerSpace={state.teeTimesPerSpace || {}}
      render={(slotWidth, slotHeight, slotsPerHour, intervalMinutes) => (
        <React.Fragment>
          {state.currentVenueSpaces.map((item) => {
            const space = { ...item }
            if (isBay) {
              const bay = bays.find((bay) => bay.uuid === space.uuid)
              space.floor = bay?.floor
              space.bay_number = bay?.bay_number
              space.ball_tracking_technology = {
                tracking_provider: bay?.tracking_technology,
              }
              space.name = bay?.name
            }
            return (
              <SpaceColumn
                slotWidth={slotWidth}
                venue={venue}
                slotHeight={slotHeight}
                slotsPerHour={slotsPerHour}
                key={space.id}
                space={space}
                intervalMinutes={intervalMinutes}
                onSelectFrom={handleSelectFrom}
                onSelectTo={handleSelectTo}
                onSelectSpace={handleSelectSpace}
                selectedFrom={selectedFrom}
                selectedTo={selectedTo}
                isSpaceSelected={!!selectedSpaces.find((x) => x.id === space.id)}
                selectedSpaces={selectedSpaces}
                teeTimes={state.teeTimesPerSpace?.[space.id]}
                bookings={state.bookingsPerSpace?.[space.id] || []}
                editingCategory={false}
                shouldAnimate={false}
                isTeetimeEditable={true}
                editableBookingUUID={bookingUuid}
                isBay={isBay}
                bays={bays}
              />
            )
          })}
        </React.Fragment>
      )}
    />
  ) : (
    <div className={cx(styles.centeredContainer)}>
      <PulseLoader showIf={true} />
    </div>
  )
}
