import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const BlueTitle = ({ children, className }) => {
  return <h2 className={cx(styles.title, className)}>{children}</h2>
}

BlueTitle.propTypes = {
  className: PropTypes.string,
}

BlueTitle.defaultProps = {
  className: '',
}

export default BlueTitle
