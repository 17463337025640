import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

/**
 * This component creates a panel with an optional title
 */
export default class XPanel extends Component {
  scrollDown() {
    if (!this._content) return

    let scrollHeight = this._content.scrollHeight
    this._content.scrollTo(0, scrollHeight)
  }

  renderTitle() {
    if (this.props.title) {
      return (
        <div className={style.x_title}>
          <div className={style.title}>{this.props.title}</div>
        </div>
      )
    }
    return <div />
  }

  render() {
    let _style = {
      container: {},
      content: {},
    }

    if (this.props.fillHeight) _style.container.height = '100%'
    if (this.props.scroll) _style.content.overflow = 'auto'
    if (this.props.noPadding) _style.container.padding = 0

    return (
      <div className={['x_panel', style.panel].join(' ')} style={_style.container}>
        {this.renderTitle()}
        <div
          className={['x_content', style.content].join(' ')}
          style={_style.content}
          ref={(content) => (this._content = content)}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

XPanel.propTypes = {
  /**
   * Title for the **XPanel**
   */
  title: PropTypes.node,
  /**
   * Determines if the **XPanel** should fill available height
   */
  fillHeight: PropTypes.bool,
  /**
   * Determines if the content of the **XPanel** should be scrollable
   */
  scroll: PropTypes.bool,
  /**
   * Determines if the content of the **XPanel** has no padding
   */
  noPadding: PropTypes.bool,
}
