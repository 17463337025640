import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import style from './style.module.scss'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'
import { ReactComponent as WarningIcon } from '@sweetspot/club-portal-legacy/resources/images/warning-icon.svg'

import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import TabSwitch from '@sweetspot/club-portal-legacy/components/TabSwitch'
import ItemManager from '@sweetspot/club-portal-legacy/components/ItemManager'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

import { ACTIONS, STATE } from '@sweetspot/sweetspot-js/features/cooperations/hooks/useCooperations'
import { filterAvailableMemberships } from '@sweetspot/sweetspot-js/features/cooperations/tools/data'

// Enable dialog (useState)
const UNSAVED_CHANGES = false

const ManageMemberships = (props) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [isLocked, setIsLocked] = useState(false)
  const { t } = useTranslation()

  const cooperation = props.cooperation
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  useEffect(() => {
    if (!isLocked && !props.loadingTitle && cooperation) {
      setActiveTabIndex(cooperation._isHost ? 0 : 1)
      setIsLocked(true)
    }
  }, [props.loadingTitle, props.isUpdating])

  const [membershipsToAdd, setMembershipsToAdd] = useState([])
  const [membershipsToRemove, setMembershipsToRemove] = useState([])

  const onSelectLeftMembership = (membership) => {
    if (membershipsToAdd.includes(membership.uuid)) {
      setMembershipsToAdd(membershipsToAdd.filter((i) => i !== membership.uuid))
    } else {
      setMembershipsToAdd([...membershipsToAdd, membership.uuid])
    }
  }

  const onSelectRightMembership = (membership) => {
    if (membershipsToRemove.includes(membership.uuid)) {
      setMembershipsToRemove(membershipsToRemove.filter((i) => i !== membership.uuid))
    } else {
      setMembershipsToRemove([...membershipsToRemove, membership.uuid])
    }
  }

  const onSelectAllLeftMemberships = (shouldCheckAll) => {
    if (shouldCheckAll) {
      setMembershipsToAdd(props.memberships.map((i) => i.uuid))
    } else {
      setMembershipsToAdd([])
    }
  }

  const onSelectAllRightMemberships = (shouldCheckAll) => {
    if (shouldCheckAll) {
      setMembershipsToRemove(props.memberships.map((i) => i.uuid))
    } else {
      setMembershipsToRemove([])
    }
  }

  const onAddMemberships = () => {
    props.onUpdate(ACTIONS.ADD_MEMBERSHIPS, membershipsToAdd)
    setMembershipsToAdd([])
    setMembershipsToRemove([])
  }

  const onRemoveMemberships = () => {
    props.onUpdate(ACTIONS.REMOVE_MEMBERSHIPS, membershipsToRemove)
    setMembershipsToAdd([])
    setMembershipsToRemove([])
  }

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm)
  }

  const handleClose = () => {
    if (UNSAVED_CHANGES) {
      setShowConfirm(true)
    } else props.onClose()
  }

  const render_Loader = () => {
    return (
      <div className={style.loading}>
        <PulseLoader showIf={!!props.loadingTitle} />
      </div>
    )
  }

  const render_Header = () => {
    return (
      <div className={style.header}>
        <Text
          textId={
            props.loadingTitle ||
            cooperation[`${!cooperation._isHost ? 'host' : 'partner'}_club`].name
          }
        />
        <CloseIcon onClick={handleClose} className={style.closeIcon} />
      </div>
    )
  }

  const render_Body = () => {
    const TARGET = cooperation._isHost ? 'host' : 'partner'
    return (
      <div className={style.body}>
        <div className={cx(style.labelRow, style.column)}>
          <Label label={'.cooperationCreate.label_Status'} lightColor defaultMargin={false} />
          <Text textId={`.cooperations.state_${cooperation.state}`} />
        </div>
        <ToggleButton
          value={!!cooperation[`${TARGET}_club_all_members_option`]}
          label={'.cooperationCreate.label_IncludeAll'}
          hoverTextKey={'cooperationCreate.hover_IncludeAll'}
          onChange={() => props.onUpdate(ACTIONS.TOGGLE_ALL_MEMBERS)}
          hintClassName={style.hintAll}
          lightLabel
        />
        <div className={style.labelRow}>
          <Label label={'.cooperationCreate.label_IncludeMemberships'} lightColor />
          <InfoHover
            textId={'cooperationCreate.hover_IncludeMemberships'}
            containerClassName={style.hintMemberships}
          />
        </div>
        <TabSwitch
          id={'coops'}
          activeTabIndex={activeTabIndex}
          onTabSelect={(index) => setActiveTabIndex(index)}
          tabs={[
            {
              title: cooperation[`${cooperation._isHost ? 'host' : 'partner'}_club`].name,
              content: (
                <ItemManager
                  leftKey={'name'}
                  rightKey={'name'}
                  leftList={filterAvailableMemberships(
                    props.memberships,
                    cooperation[`${TARGET}_club_memberships`]
                  )}
                  rightList={cooperation[`${TARGET}_club_memberships`]}
                  onSelectLeft={onSelectLeftMembership}
                  onSelectRight={onSelectRightMembership}
                  onSelectAllLeft={onSelectAllLeftMemberships}
                  onSelectAllRight={onSelectAllRightMemberships}
                  onAdd={onAddMemberships}
                  onRemove={onRemoveMemberships}
                  addEnabled={membershipsToAdd.length > 0}
                  removeEnabled={membershipsToRemove.length > 0}
                  preselectedLeft={membershipsToAdd}
                  preselectedLeftKey={'uuid'}
                  preselectedRight={membershipsToRemove}
                  preselectedRightKey={'uuid'}
                />
              ),
            },
            {
              title: cooperation[`${!cooperation._isHost ? 'host' : 'partner'}_club`].name,
              content: render_SharedMemberships(),
            },
          ]}
        />
      </div>
    )
  }

  const render_SharedMemberships = () => {
    let list = cooperation[`${cooperation._isHost ? 'partner' : 'host'}_club_memberships`]
    if (cooperation[`${cooperation._isHost ? 'partner' : 'host'}_club_all_members_option`]) {
      // Display All Members label
      list = [{ uuid: 0, name: t('cooperationCreate.label_AllMembers') }, ...list]
    }
    return (
      <div className={style.shared}>
        {list.length > 0 ? (
          list.map((i, index) => {
            return (
              <div
                key={i.uuid}
                className={cx(style.sharedRow, (index + 1) % 2 === 0 ? style.even : false)}
              >
                <Text textId={i.name} />
              </div>
            )
          })
        ) : (
          <Text textId=".cooperationCreate.label_SharedEmpty" className={style.empty} />
        )}
      </div>
    )
  }

  const render_Footer = (state) => {
    let leftAction = null
    let rightAction = null

    if (state === STATE.AWAITING_CONFIRMATION || state === STATE.NEW) {
      if (cooperation._isHost) {
        leftAction = {
          text: 'cooperationCreate.button_CancelRequest',
          handler: () => props.onUpdate(ACTIONS.CANCEL_COOPERATION),
        }
        rightAction = null
      } else {
        leftAction = {
          text: 'cooperationCreate.button_DenyRequest',
          handler: () => props.onUpdate(ACTIONS.DENY_REQUEST),
        }
        rightAction = {
          text: 'cooperationCreate.button_ConfirmRequest',
          handler: () => props.onUpdate(ACTIONS.CONFIRM_REQUEST),
        }
      }
    }

    if (state === STATE.CONFIRMED) {
      leftAction = {
        text: 'cooperationCreate.button_CancelCooperation',
        handler: () => props.onUpdate(ACTIONS.CANCEL_COOPERATION),
      }
      rightAction = null
    }

    if (state === 'LOADING' || !leftAction) {
      return null
    }
    return (
      <div className={style.footer}>
        <button
          onClick={leftAction.handler}
          className={`system-button primary md-32 ${style.button} ${style.cancel}`}
        >
          {t(leftAction.text)}
        </button>
        {rightAction && (
          <button
            disabled={props.isUpdating}
            onClick={rightAction.handler}
            className={`system-button primary md-32 ${style.button}`}
          >
            {t(rightAction.text)}
          </button>
        )}
      </div>
    )
  }

  return (
    <div className={style.container}>
      {render_Header()}
      {props.loadingTitle ? render_Loader() : render_Body()}
      {render_Footer(props.loadingTitle ? 'LOADING' : cooperation.state)}

      <ConfirmPopup
        visible={showConfirm}
        acceptTheme="default"
        titleIcon={WarningIcon}
        acceptText={t('confirm')}
        rejectText={t('cancel')}
        title={t('communication.confirmCloseTitle')}
        text={t('cooperationCreate.label_Unsaved')}
        onReject={toggleConfirm}
        onClose={toggleConfirm}
        onAccept={props.onClose}
      />
    </div>
  )
}

ManageMemberships.propTypes = {
  currentClubId: PropTypes.number.isRequired,
  cooperation: PropTypes.object,
  memberships: PropTypes.array.isRequired,
  loadingTitle: PropTypes.string,
  isUpdating: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default ManageMemberships
