import React, { useCallback, useMemo } from 'react'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import style from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Bookings/style.module.scss'
import { useTranslation } from 'react-i18next'

type BookingFiltersProps = {
  disabled: boolean
  statusFilter?: string | null
  setStatusFilter: (status: string | null) => void
}

const BookingFilters: React.FC<BookingFiltersProps> = ({
  disabled,
  statusFilter,
  setStatusFilter,
}) => {
  const { t } = useTranslation()

  const BOOKING_STATUS_OPTIONS = useMemo(
    () => [
      { name: t('players.allStatuses'), id: 'all statuses' },
      { name: 'Reserved', id: 'on reservation' },
      { name: 'Awaiting payment', id: 'waiting for payment' },
      { name: 'Paid', id: 'paid' },
      { name: 'Pay on site', id: 'pay on site' },
      { name: 'Canceled', id: 'canceled' },
    ],
    [t]
  )

  const handleStatusFilterChange = useCallback(
    (selectedId: string) => {
      if (selectedId === BOOKING_STATUS_OPTIONS[0].id) {
        setStatusFilter(null)
      } else {
        setStatusFilter(selectedId)
      }
    },
    [setStatusFilter]
  )

  return (
    <div className={style.filtersContainer}>
      <DropdownSelect
        width="145px"
        placeholder={t('status')}
        showBorder
        disabled={disabled}
        values={BOOKING_STATUS_OPTIONS}
        initialId={statusFilter || BOOKING_STATUS_OPTIONS[0].id}
        selectedId={statusFilter || BOOKING_STATUS_OPTIONS[0].id}
        onSelect={handleStatusFilterChange}
      />
    </div>
  )
}

export default BookingFilters
