import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { useSelector } from 'react-redux'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  deleteSpace,
  getSpaces,
} from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { useToasts } from 'react-toast-notifications'
import { setSessionStorage, getSessionStorage } from '@sweetspot/shared/util/session-storage'

const initialForm = {
  confirmText: '',
}

const defaultErrors = {
  confirmText: '',
}

const ModifySpace = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [selectedClub, setSelectedClub] = useState(null)
  const [activeGolfCourse, setActiveGolfCourse] = useState(null)
  const [selectedSpace, setSelectedSpace] = useState(null)

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useMergeState(defaultErrors)
  const [errorText, setErrorText] = useState('')
  const [form, setForm] = useState(initialForm)

  const golfClubList = useSelector((state) => state.golfClub.list)

  const { data: golfCourseList, isFetching: golfCoursesIsFetching } = useQuery(
    [
      CLUB_QUERIES.COURSES,
      {
        'club.id': selectedClub?.id,
        'order[name]': 'asc',
        'type[]': [
          COURSE_TYPES.DRIVING_RANGE.value,
          COURSE_TYPES.SIMULATOR.value,
          COURSE_TYPES.PRO.value,
          COURSE_TYPES.OTHER.value,
        ],
        belongsToRangeContext: false,
      },
    ],
    () =>
      queryCourses({
        'club.id': selectedClub?.id,
        'order[name]': 'asc',
        'type[]': [
          COURSE_TYPES.DRIVING_RANGE.value,
          COURSE_TYPES.SIMULATOR.value,
          COURSE_TYPES.PRO.value,
          COURSE_TYPES.OTHER.value,
        ],
        belongsToRangeContext: false,
      }),
    {
      enabled: !!selectedClub?.id,
      select: (data) => {
        return data.map((course) => ({ ...course, label: course.name }))
      },
    }
  )

  const {
    data: spaces,
    isFetching: spacesIsFetching,
    refetch: refetchSpaces,
  } = useQuery(
    [CLUB_QUERIES.SPACES, selectedClub?.id, activeGolfCourse?.id],
    () => getSpaces({ club: selectedClub?.id, course: activeGolfCourse?.id }),
    {
      enabled: !!selectedClub?.id && !!activeGolfCourse?.id,
      select: (data) => {
        let sessionData
        try {
          sessionData = JSON.parse(getSessionStorage('deleted-spaces'))
        } catch (error) {
          // Nothing to do
        }

        if (sessionData?.length) {
          return data
            .map((item) => ({ ...item, label: item.name }))
            .filter((space) => !sessionData.includes(space.uuid))
        } else {
          return data.map((item) => ({ ...item, label: item.name }))
        }
      },
    }
  )

  const isLoading = useMemo(() => {
    return loading || golfCoursesIsFetching || spacesIsFetching
  }, [loading, golfCoursesIsFetching, spacesIsFetching])

  const isDisabledForm = useMemo(() => {
    return isLoading || !golfCourseList?.length || !spaces?.length
  }, [isLoading, golfCourseList, spaces])

  const checkForErrors = ({ confirmText }) => {
    let errors = {
      confirmText: null,
    }
    let hasErrors = false

    if (confirmText !== 'Delete' && confirmText !== 'Ta bort') {
      errors.confirmText = t('errors.fieldInvalid')
      hasErrors = true
    }

    if (hasErrors) {
      setErrors(errors)
      return false
    }

    return true
  }

  const handleChange = (name, value) => {
    setErrors({ [name]: '' })
    setForm({ ...form, [name]: value })
  }

  const saveSettings = async () => {
    const { confirmText } = form
    const { uuid: spaceUuid } = selectedSpace

    if (checkForErrors({ confirmText })) {
      setLoading(true)

      const [res, err] = await to(deleteSpace(spaceUuid))

      setLoading(false)

      if (res) {
        try {
          setSessionStorage(
            'deleted-spaces',
            JSON.stringify([...(JSON.parse(getSessionStorage('deleted-spaces')) || []), spaceUuid])
          )
        } catch (error) {
          // Nothing to do
        }

        refetchSpaces()
        addToast(t('sentences.spaceDeleted'), { appearance: 'warning' })
        // eslint-disable-next-line
      } else if (err?.detail === "Space can't be deleted, there are upcoming bookings") {
        setErrorText(t('sentences.youHaveToCancelAllUpcomingBookingsToBeAbleToDeleteThisSpace'))
        setLoading(false)
      } else {
        setErrorText(t('errors.somethingWentWrong', { id: 2527 }))
        setLoading(false)
      }
    }
  }

  return (
    <div className={cx(styles.container)}>
      <div className={styles.title}>
        <Text textId="sentences.deleteSpace" />
        <div>
          <PulseLoader fillHeight showIf={isLoading} />
        </div>
      </div>
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="selects">
            <div>
              <div className="ss-form-group">
                <label className="ss-label">{t('words.golfClub')}</label>
                <DropdownSelect
                  showBorder
                  width="100%"
                  values={golfClubList}
                  onSelect={(id) => {
                    const club = golfClubList.find((item) => item.id === id)
                    if (club) setSelectedClub({ id, name: club.name, uuid: club.uuid })
                  }}
                />
              </div>
              <div className="ss-form-group">
                <label className="ss-label">{t('words.golfCourse')}</label>
                <DropdownSelect
                  error={errors?.course}
                  showBorder
                  width="100%"
                  disabled={!selectedClub?.id}
                  values={golfCourseList || []}
                  onSelect={(id) => {
                    const course = golfCourseList.find((item) => item.id === id)
                    if (course) setActiveGolfCourse(course)
                  }}
                />
              </div>
              <div className="ss-form-group">
                <label className="ss-label">{t('words.space_one')}</label>
                <DropdownSelect
                  error={errors?.space}
                  showBorder
                  width="100%"
                  values={spaces || []}
                  disabled={!activeGolfCourse?.id}
                  onSelect={(id) => {
                    const space = spaces.find((item) => item.id === id)
                    if (space) setSelectedSpace(space)
                  }}
                />
              </div>
            </div>
          </div>
          <TextInputField
            error={errors.confirmText}
            label={t('sentences.typeDeleteToConfirm')}
            disabled={isDisabledForm}
            value={form.confirmText}
            onChange={(value) => handleChange('confirmText', value)}
          />
          <div className={styles.formButtons}>
            <button
              className="system-button danger md-32"
              disabled={isDisabledForm}
              onClick={saveSettings}
            >
              {t('words.delete')}
            </button>
          </div>
        </form>
        <Alert showIf={!!errorText} onClick={() => setErrorText('')}>
          <p>{errorText}.</p>
        </Alert>
      </div>
    </div>
  )
}

ModifySpace.propTypes = {}

ModifySpace.defaultProps = {}

export default ModifySpace
