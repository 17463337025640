import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './styles.module.scss'
import { buttonsTypes } from '@sweetspot/club-portal-legacy/constants/config'
import AddVoucherButton from '@sweetspot/sweetspot-js/features/bookings/components/AddVoucherButton'
import AddPartnershipButton from '@sweetspot/sweetspot-js/features/bookings/components/AddPartnershipButton'
import { useExtraPlayerContext } from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'

const AddButtonsList = ({ className, buttons }) => {
  const extraPlayerContext = useExtraPlayerContext()

  const handleBookingUpdate = useCallback(
    (item) => {
      item.onUpdateBooking()
      extraPlayerContext?.calculatePrice()
    },
    [extraPlayerContext]
  )

  if (buttons?.length === 0) return null

  return (
    <div className={cx(styles.container, className)}>
      {buttons.map((item) => {
        if (item.type === buttonsTypes.VOUCHER) {
          return (
            <AddVoucherButton
              iconClassName={styles.addBtnIcon}
              booking={item.booking}
              canEdit={!item.disabled}
              onUpdateBooking={() => handleBookingUpdate(item)}
              buttonText={item?.buttonText}
              itemWrapperClassName={cx(styles.add, { [styles.animation]: !item.disabled })}
              popoverClassName={styles.popover}
            />
          )
        }
        if (item.type === buttonsTypes.PARTNERSHIP) {
          return (
            <AddPartnershipButton
              iconClassName={styles.addBtnIcon}
              booking={item.booking}
              canEdit={!item.disabled}
              onUpdateBooking={() => handleBookingUpdate(item)}
              buttonText={item?.buttonText}
              itemWrapperClassName={cx(styles.add, { [styles.animation]: !item.disabled })}
              popoverClassName={styles.popover}
            />
          )
        }
      })}
    </div>
  )
}

export default AddButtonsList

AddButtonsList.propTypes = {
  className: PropTypes.string,
  buttons: PropTypes.array.isRequired,
}

AddButtonsList.defaultProps = {
  className: '',
}
