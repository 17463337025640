import { useState } from 'react'
import { useQuery } from 'react-query'
import { SignUpDetails } from '@sweetspot/club-portal-legacy/modals/MembershipSignupsModal/types'
import { getMembershipSignupByUuid } from '@sweetspot/shared/data-access/api-platform'

const useMembershipSignupDetails = (uuid?: string) => {
  const [signUpData, setSignUpData] = useState<SignUpDetails | object>({})
  const { isLoading: isDataLoading } = useQuery(
    ['signupDetails', uuid],
    () => getMembershipSignupByUuid(uuid || ''),
    {
      enabled: !!uuid,
      onSuccess: (data) => {
        const playerData = {
          firstName: data.first_name || '',
          lastName: data.last_name || '',
          phone: data.phone_number || '',
          email: data.email_address,
          dateOfBirth: data.birth_date,
          guardian: data.guardian
            ? {
                firstName: data.guardian.first_name,
                lastName: data.guardian.last_name,
                phone: data.guardian.phone_number,
                email: data.guardian.email_address,
                golfId: data.guardian.golf_id,
              }
            : {},
        }
        setSignUpData(playerData)
      },
    }
  )

  return {
    isDataLoading,
    data: signUpData,
  }
}

export default useMembershipSignupDetails
