import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import UserAvatar from '@sweetspot/club-portal-legacy/resources/images/user_avatar.svg'

import styles from './style.module.scss'

const INFORMATION = 3
const GOLF_CLUB = 4

export class Sidebar extends Component {
  getMenuItems = () => {
    const { sidebar } = this.props

    const menuItems = [
      {
        id: 3,
        text: 'partnerAdmin.information',
        disabled: false,
        isActive: sidebar === INFORMATION,
      },
      {
        id: 4,
        text: 'settings.clubAdmins.golfClubs',
        disabled: this.props.isCreate,
        isActive: sidebar === GOLF_CLUB,
      },
    ]

    return menuItems
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.avatar}>
          <img src={UserAvatar} alt="user" />
        </div>
        {this.getMenuItems().map((menuItem) => (
          <div
            key={menuItem.id}
            className={classnames(styles.menuItem, {
              [styles.active]: menuItem.isActive,
              [styles.disabled]: menuItem.disabled,
            })}
            onClick={() => !menuItem.disabled && this.props.onChange(menuItem.id)}
          >
            <Text textId={menuItem.text} />
          </div>
        ))}
      </div>
    )
  }
}

Sidebar.propTypes = {
  sidebar: PropTypes.number.isRequired,
  isCreate: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Sidebar
