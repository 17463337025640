import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

export const STATUSES = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  NEW: 'new',
  REFUNDED: 'refunded',
  LOCKED: 'locked',
}

const StatusPill = ({ status, className }) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.pill, className)}>
      <div
        className={cx(styles.dot, {
          [styles.green]: [STATUSES.ACTIVE, STATUSES.PAID].includes(status),
          [styles.red]: [STATUSES.UNPAID, STATUSES.EXPIRED, STATUSES.CANCELLED].includes(status),
          [styles.yellow]: [STATUSES.UPCOMING, STATUSES.NEW].includes(status),
          [styles.gray]: [STATUSES.REFUNDED].includes(status),
          [styles.deepRed]: [STATUSES.LOCKED].includes(status),
        })}
      ></div>
      <p className={cx(styles.text)}>
        {STATUSES.PAID === status && t('membership.paymentStatus.paid')}
        {STATUSES.ACTIVE === status && t('membership.status.active')}
        {STATUSES.UNPAID === status && t('membership.paymentStatus.notPaid')}
        {STATUSES.UPCOMING === status && t('membership.status.upcoming')}
        {STATUSES.EXPIRED === status && t('membership.status.expired')}
        {STATUSES.CANCELLED === status && t('membership.status.canceled')}
        {STATUSES.NEW === status && t('membership.status.new')}
        {STATUSES.REFUNDED === status && t('membership.status.refunded')}
        {STATUSES.LOCKED === status && t('membership.status.locked')}
      </p>
    </div>
  )
}

StatusPill.propTypes = {
  status: PropTypes.oneOf([
    'paid',
    'unpaid',
    'upcoming',
    'active',
    'expired',
    'cancelled',
    'new',
    'refunded',
  ]),
  className: PropTypes.string,
}

StatusPill.defaultProps = {
  status: '',
  className: '',
}

export default StatusPill
