import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Separator from '@sweetspot/club-portal-legacy/components/Wizard/partials/Separator'
import { ReactComponent as TrashCan } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import { ReactComponent as ClearIcon } from '@sweetspot/club-portal-legacy/resources/images/clear-icon.svg'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'

import styles from './styles.module.scss'

const IncludedCoursesTable = ({ includedCourses, onDelete, lang, disabled }) => {
  const TRANSLATIONS = {
    club: GetTranslatedString(lang, 'club'),
    course: GetTranslatedString(lang, 'course'),
  }

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.row, styles.headerRow)}>
        <p className={cx(styles.column, styles.headerLabel)}>{TRANSLATIONS.club}</p>
        <p className={cx(styles.column, styles.headerLabel)}>{TRANSLATIONS.course}</p>
        <div
          className={cx(styles.column, styles.trashIcon, {
            [styles.trashIconDisabled]: disabled,
          })}
        >
          <TrashCan />
        </div>
      </div>
      <Separator />
      {includedCourses.map((course) => {
        const { name, id, club } = course
        const { name: clubName } = club
        return (
          <div key={id} className={cx(styles.row, styles.courseRow)}>
            <p className={cx(styles.column, styles.courseLabel)}>{clubName}</p>
            <p className={cx(styles.column, styles.courseLabel)}>{name}</p>
            <div
              onClick={() => onDelete(id)}
              className={cx(styles.column, styles.clearIcon, {
                [styles.clearIconDisabled]: disabled,
              })}
            >
              <ClearIcon />
            </div>
          </div>
        )
      })}
    </div>
  )
}

IncludedCoursesTable.propTypes = {
  includedCourses: PropTypes.array,
  onDelete: PropTypes.func,
  lang: PropTypes.string,
  disabled: PropTypes.bool,
}
IncludedCoursesTable.defaultProps = {
  includedCourses: [],
  onDelete: () => {},
  disabled: false,
}

export default connect((state) => ({ lang: state.auth.me.lang }))(IncludedCoursesTable)
