import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const SuggestionBox = ({
  items,
  onItemClick,
  visible,
  labelKey,
  idKey,
  suggestionItemsItemKey,
  noAvailableLabel,
}) => {
  const { t } = useTranslation()

  const handleOnClick = (id) => {
    onItemClick(id)
  }

  return (
    <div
      className={cx(styles.container, {
        [styles.containerVisible]: visible && items,
      })}
    >
      {items && items.length > 0 ? (
        items.map((item) => {
          const label = item[labelKey]
          return (
            <div
              key={item[suggestionItemsItemKey]}
              className={cx(styles.itemContainer, {
                [styles.itemContainerDisabled]: !!item.disabled,
              })}
              onClick={() => {
                if (item.disabled) return
                handleOnClick(item[idKey])
              }}
            >
              <p className={cx(styles.itemLabel)}>
                {label} {item.disabled && item.disabledLabel ? `(${item.disabledLabel})` : null}
              </p>
            </div>
          )
        })
      ) : (
        <div className={cx(styles.itemContainer, styles.itemContainerNoAvailable)}>
          <p className={cx(styles.itemLabel)}>{noAvailableLabel || t('noSuggestionsAvailable')}</p>
        </div>
      )}
    </div>
  )
}

SuggestionBox.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  visible: PropTypes.bool,
  labelKey: PropTypes.string,
  idKey: PropTypes.string,
  suggestionItemsItemKey: PropTypes.string,
  noAvailableLabel: PropTypes.string,
}
SuggestionBox.defaultProps = {
  items: undefined,
  onItemClick: () => {},
  visible: false,
  labelKey: 'name',
  idKey: 'id',
  suggestionItemsItemKey: 'id',
  noAvailableLabel: undefined,
}

export default SuggestionBox
