import React from 'react'

const PromotionsContext = React.createContext({
  values: {
    context: null,
    clubId: null,
    membershipId: null,
    partnershipId: null,
    openPromotions: [],
    promotions: [],
  },
  actionStates: {
    checkingPromotions: true,
    setCheckingPromotions: () => {},
    savingNewPromotion: false,
    setSavingNewPromotion: () => {},
    savingExistingPromotion: false,
    setSavingExistingPromotion: () => {},
  },
  functions: {
    setOpenPromotions: () => {},
    deleteNewPromotion: () => {},
    replaceNewPromotion: () => {},
    toggleOpenPromotion: (id = 0) => {},
    archivePromotion: (promotionId = 0) => {},
    updatePromotion: () => {},
    updatePromotionListWhenActivateDeactivate: (id = 0, val = '') => {},
  },
})

export default PromotionsContext
