import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import { ReactComponent as Cross } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'

const CloseIcon = ({ onClick, className, iconType = 'svg' }) => {
  return (
    <>
      {iconType === 'svg' && <Cross onClick={onClick} className={cx(styles.cross, className)} />}
      {iconType === 'google' && (
        <span
          onClick={onClick}
          className={cx('material-icons-outlined', styles.crossFont, className)}
        >
          close
        </span>
      )}
    </>
  )
}

CloseIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconType: PropTypes.oneOf(['google', 'svg']),
}

export default CloseIcon
