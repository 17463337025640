import { APP_NAMES } from '@sweetspot/shared/util/constants'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'

interface ErrorBoundaryFallbackProps {
  appOrigin: APP_NAMES
  resetError(): void
  error: Error
  componentStack: string
  eventId: string
}

const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const { t } = useTranslation()

  const url = () => {
    if (props.appOrigin !== APP_NAMES.WEB_BOOKING) {
      return window.location.origin
    } else if (window.location.pathname.includes('/login')) {
      return '/login'
    } else {
      // Take care of club URLs
      const url = window.location.pathname
      const clubSlashIndex = url.indexOf('/', 1)
      const clubNameIndex = url.indexOf('/', clubSlashIndex + 1)

      if (clubNameIndex === -1) {
        return '/login'
      }
      const pathname = url.substring(0, clubNameIndex)
      return pathname
    }
  }

  return (
    <div className={'bg-sand-100 flex h-full w-full flex-col items-center overflow-auto p-8'}>
      <div className={'flex max-w-full flex-col space-y-12 pt-0 md:pt-16 lg:w-1/2'}>
        <div className={'flex flex-wrap items-center justify-center gap-4 lg:justify-normal'}>
          <div className={'text-8xl md:text-9xl'}>
            <FontAwesomeIcon icon={faCircleExclamation} />
          </div>
          <div className={'space-y-6'}>
            <div className={'text-6xl font-medium  md:text-8xl'}>
              {t('errors.boundary.heading_boundary')}
            </div>
            <div className={'pl-2 text-2xl md:pl-4 md:text-3xl'}>
              {t('errors.boundary.heading_boundary_details')}
            </div>
          </div>
        </div>

        <div className="bg-sand-50 mb-8 flex max-w-fit flex-col space-y-4 overflow-hidden rounded-sm border p-4 ">
          <ul>
            <li>{`Event-ID: ${props.eventId}`}</li>
          </ul>
        </div>

        <a href={url()} className={'pl-4 underline'}>
          {t('errors.boundary.label_retry_button')}
        </a>
      </div>
    </div>
  )
}

export { ErrorBoundaryFallback }
