import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import styles from './styles.module.scss'
import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'
import produce from 'immer'
import NestedRowWrapper from '@sweetspot/sweetspot-js/common/components/NestedRowWrapper'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import CalendarDropdown from '@sweetspot/sweetspot-js/common/components/CalendarDropdown2'

const ITEM_ACTIONS = {
  ADD_CONTROLS: 'ADD_CONTROLS',
  CLEAR_CONTROLS: 'CLEAR_CONTROLS',
}

const PERIOD_ACTIONS = {
  SET_DATE: 'SET_DATE',
  REMOVE: 'REMOVE',
}

const checkOverlapping = (periodID, dateValue, periods) => {
  let isOverlapping = false
  let c = 0
  while (c < periods.length) {
    if (moment(dateValue, 'YYYY-MM-DD').isBetween(periods[c].from, periods[c].to, 'days', '[]')) {
      if (periodID !== periods[c]._id) {
        isOverlapping = true
        break
      }
    }
    c++
  }
  return isOverlapping
}

const checkOverlappingReverse = (periodID, from, to, periods) => {
  let isOverlapping = false
  let c = 0
  while (c < periods.length) {
    if (periodID !== periods[c]._id) {
      if (
        moment(periods[c].from, 'YYYY-MM-DD').isBetween(from, to, 'days', '[]') ||
        moment(periods[c].to, 'YYYY-MM-DD').isBetween(from, to, 'days', '[]')
      ) {
        if (periodID !== periods[c]._id) {
          isOverlapping = true
          break
        }
      }
    }
    c++
  }
  return isOverlapping
}

const setItemControls = (periods, action) => {
  if (action === ITEM_ACTIONS.ADD_CONTROLS) {
    return periods.map((i, index) => ({
      ...i,
      _id: `period_${index}`,
      _isNew: false,
      _error_from: null,
      _error_to: null,
    }))
  }
  if (action === ITEM_ACTIONS.CLEAR_CONTROLS) {
    periods = periods.filter((i) => !i._error_from && !i._error_to)
    return periods.map((i) => ({ from: i.from, to: i.to }))
  }
}

const BookingPeriodMini = ({ periods, isLoading, header }) => {
  if (isLoading) return <PulseLoader showIf={true} />
  return (
    <div className={styles.miniContainer}>
      <p className={styles.miniHeader}>{header}</p>
      <div className={styles.miniRow}>
        {periods.map((i, index) => (
          <p key={`mini_${index}`}>{`${i.from} - ${i.to}`}</p>
        ))}
      </div>
    </div>
  )
}

const BookingPeriod = ({
  rule,
  onSetRule,
  onSaveRule,
  disabled,
  loading,
  readOnly,
  isMini,
  voucherDisabled,
}) => {
  const { t } = useTranslation()
  const [periods, setPeriods] = useState(
    setItemControls(rule.configuration.periods, ITEM_ACTIONS.ADD_CONTROLS)
  )

  if (isMini) {
    return (
      <BookingPeriodMini
        periods={rule.configuration.periods}
        isLoading={loading}
        header={t('bookingPeriod.title')}
      />
    )
  }

  const createPeriod = () => {
    setPeriods([
      ...periods,
      {
        from: null,
        to: null,
        _id: `period_${periods.length}`,
        _isNew: true,
        _error_from: null,
        _error_to: null,
      },
    ])
  }

  return (
    <div className={cx(styles.container)}>
      <MultiSelect
        noSelectedLabel={t('bookingPeriod.label_Empty')}
        width={'quarter'}
        buttonLabel={t('bookingPeriod.button_Add')}
        selectedClassName={styles.itemContainer}
        selectedOptions={periods}
        readOnly={voucherDisabled || readOnly}
        renderSelected={(period) => {
          if (loading) {
            return <PulseLoader showIf={true} key={`wt`} contentClassName={cx(null)} />
          }

          const updatePeriod = (actionType, payload) => {
            let result = null
            let isReady = false
            let errorText = null
            let noErrors = false
            if (actionType === PERIOD_ACTIONS.SET_DATE) {
              if (!payload.value) return
              result = periods.map((i) => {
                if (moment(payload.value).format('YYYY-MM-DD') === 'Invalid date') {
                  errorText = t('bookingPeriod.error_InvalidDate')
                } else {
                  errorText = checkOverlapping(payload.id, payload.value, periods)
                    ? t('bookingPeriod.error_OverlappingDate')
                    : null
                }
                // Check if From / To is available and Check FromTo order
                if (i._id === period._id && i[payload.periodType === 'from' ? 'to' : 'from']) {
                  const date = moment(payload.value, 'YYYY-MM-DD')
                  const hasWrongOrder =
                    payload.periodType === 'from' ? date.isAfter(i.to) : date.isBefore(i.from)
                  if (hasWrongOrder) {
                    errorText = t('bookingPeriod.error_WrongOrder')
                  } else {
                    const from =
                      payload.periodType === 'from'
                        ? moment(payload.value).format('YYYY-MM-DD')
                        : i.from
                    const to =
                      payload.periodType === 'from'
                        ? i.to
                        : moment(payload.value).format('YYYY-MM-DD')
                    const isOverlapping = checkOverlappingReverse(payload.id, from, to, periods)
                    if (isOverlapping || from === to) {
                      errorText = t('bookingPeriod.error_OverlappingDate')
                    }
                    if (!errorText) {
                      const orderFixed =
                        i[payload.periodType === 'from' ? '_error_to' : '_error_from'] ===
                        t('bookingPeriod.error_WrongOrder')
                      isReady = true
                      if (orderFixed) noErrors = true
                    }
                  }
                }
                return i._id === period._id
                  ? Object.assign(
                      {
                        ...i,
                        [payload.periodType]: moment(payload.value).format('YYYY-MM-DD'),
                        _isNew: false,
                        [`_error_${payload.periodType}`]: errorText,
                      },
                      noErrors
                        ? {
                            _error_from: null,
                            _error_to: null,
                          }
                        : {}
                    )
                  : i
              })
            }
            if (actionType === PERIOD_ACTIONS.REMOVE) {
              result = periods.filter((i) => i._id !== period._id)
              isReady = true
            }
            // Update on backend
            if (result !== null) {
              setPeriods(result)
              if (!errorText && isReady) {
                const updatedRule = produce(rule, (draft) => {
                  draft.configuration.periods = setItemControls(result, ITEM_ACTIONS.CLEAR_CONTROLS)
                })
                onSetRule(updatedRule)
                onSaveRule(updatedRule)
              }
            }
          }

          return (
            <div key={`${period._id}`}>
              <div className={styles.headerRow}>
                <p>{t('bookingPeriod.label_From')}</p>
                <p>{t('bookingPeriod.label_To')}</p>
              </div>
              <NestedRowWrapper
                key={`datetime`}
                onRemove={() => {
                  updatePeriod(PERIOD_ACTIONS.REMOVE)
                }}
                isCompact
                isReadOnly={readOnly}
                containerClassName={styles.periodContainer}
                voucherDisabled={voucherDisabled}
                titleComponent={
                  <div className={styles.periodRow}>
                    <CalendarDropdown
                      type="input"
                      placeholder={t('bookingPeriod.placeholder_Date')}
                      fixedWidth
                      isReadOnly={voucherDisabled || readOnly}
                      error={period._error_from}
                      defaultDate={
                        period.from ? moment(period.from, 'YYYY-MM-DD').toDate() : undefined
                      }
                      onDateChange={(dateValue) =>
                        updatePeriod(PERIOD_ACTIONS.SET_DATE, {
                          periodType: 'from',
                          value: dateValue,
                          id: period._id,
                        })
                      }
                      onInputBlur={(textValue) => {
                        updatePeriod(PERIOD_ACTIONS.SET_DATE, {
                          periodType: 'from',
                          value: textValue,
                          id: period._id,
                        })
                      }}
                      isOpen={period._isNew}
                    />
                    <CalendarDropdown
                      type="input"
                      placeholder={t('bookingPeriod.placeholder_Date')}
                      fixedWidth
                      isReadOnly={voucherDisabled || readOnly}
                      error={period._error_to}
                      defaultDate={period.to ? new Date(period.to) : undefined}
                      onDateChange={(dateValue) =>
                        updatePeriod(PERIOD_ACTIONS.SET_DATE, {
                          periodType: 'to',
                          value: dateValue,
                          id: period._id,
                        })
                      }
                      onInputBlur={(textValue) => {
                        updatePeriod(PERIOD_ACTIONS.SET_DATE, {
                          periodType: 'to',
                          value: textValue,
                          id: period._id,
                        })
                      }}
                    />
                  </div>
                }
              />
            </div>
          )
        }}
        onClickAdd={createPeriod}
        disabled={disabled}
        loading={loading}
      />
    </div>
  )
}

BookingPeriod.propTypes = {
  rule: PropTypes.object,
  onSetRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
}

BookingPeriodMini.propTypes = {
  periods: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

BookingPeriod.defaultProps = {
  loading: false,
}

export default BookingPeriod
