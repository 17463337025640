import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const AnimatedLine = ({ className, show }) => {
  return (
    <div className={cx(styles.container, className, show && styles.show)}>
      <div className={cx(styles.animatedLine)}></div>
    </div>
  )
}

AnimatedLine.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
}

AnimatedLine.defaultProps = {
  className: '',
  show: false,
}

export default AnimatedLine
