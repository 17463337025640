import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import { ReactComponent as UnPaidIcon } from '@sweetspot/club-portal-legacy/resources/images/unpaid-icon.svg'

export default class PaidStatusCell extends Component {
  render() {
    const status = this.props.status
    let containerClassNames = [style.container]

    if (status) containerClassNames.push(style.active)
    else containerClassNames.push(style.inActive)

    let statusText = status ? 'yes' : 'no'
    return (
      <div className={containerClassNames.join(' ')} style={this.props.style}>
        {status === null && '-'}
        {!status && status !== null && (
          <React.Fragment>
            <div className={style.image}>
              <UnPaidIcon className={style.image} />
            </div>
            <Text textId={statusText} />
          </React.Fragment>
        )}
      </div>
    )
  }
}

PaidStatusCell.propTypes = {
  style: PropTypes.object,
  status: PropTypes.bool,
}
