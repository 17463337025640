import { useQuery } from 'react-query'

import * as API_COOPERATIONS from '@sweetspot/sweetspot-js/features/cooperations/services/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

const useMemberships = (clubIDs = null, currentPage = null, pageLimit = 9999) => {
  const {
    data: memberships,
    isFetching,
    error: queryError,
    refetch,
  } = useQuery(
    [CLUB_QUERIES.MEMBERSHIPS, clubIDs, currentPage, pageLimit],
    () =>
      API_COOPERATIONS.fetchMembershipList({
        'club.id[]': clubIDs,
        page: currentPage,
        limit: pageLimit,
      }),
    {
      enabled: clubIDs && clubIDs.length > 0,
      select: (data) => {
        return data.map((item) => ({
          id: item.id,
          name: item.name,
          uuid: item.uuid,
        }))
      },
    }
  )

  return {
    memberships: memberships || [],
    refetch,
    error: queryError,
    isFetching: isFetching,
  }
}

export default useMemberships
