import {
  _updatePromotion,
  _deletePromotion,
  _addAction,
  _updateAction,
  _deleteAction,
  _addRule,
  _updateRule,
  _deleteRule,
} from '@sweetspot/club-portal-legacy/services/promotionsApi'

import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'

/**
 * Update promotion
 *
 * @param {string|number} promotionId
 * @param {Object} payload
 * @returns {Promise}
 */
export const updatePromotion = (promotionId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updatePromotion(promotionId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Deletes promotion
 *
 * @param {string|number} promotionId
 * @returns {Promise}
 */
export const deletePromotion = (promotionId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deletePromotion(promotionId)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Adds action to promotion
 *
 * @param {string|number} promotionId
 * @param {Object} payload action data
 * @returns {Promise}
 */
export const addActionToPromotion = (promotionId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addAction(promotionId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Updates action
 *
 * @param {string|number} actionId
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateAction = (actionId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateAction(actionId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Deletes action
 *
 * @param {string|number} actionId
 * @returns {Promise}
 */
export const deleteAction = (actionId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deleteAction(actionId)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Adds rule to promotion
 *
 * @param {string|number} promotionId
 * @param {Object} payload rule data
 * @returns {Promise}
 */
export const addRuleToPromotion = (promotionId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addRule(promotionId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Updates rule
 *
 * @param {string|number} ruleId
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateRule = (ruleId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateRule(ruleId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Deletes rule
 *
 * @param {string|number} ruleId
 * @returns {Promise}
 */
export const deleteRule = (ruleId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deleteRule(ruleId)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('promotionUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}
