import cx from 'classnames'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateButtonProps,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'

interface SpinnerLoaderProps {
  text: string
  isOverlay?: boolean
  description?: string
  button?: EmptyStateButtonProps
  className?: string
}

const SpinnerLoader = ({ text, description, isOverlay, button, className }: SpinnerLoaderProps) => (
  <EmptyState
    className={cx(
      '[&_i]:bg-background-mono-lighter [&_i]:mb-4 [&_i]:flex [&_i]:rounded-full [&_i]:p-5',
      { 'bg-translucent-light-900 absolute inset-0 z-10 h-full max-w-full': isOverlay },
      className
    )}
    loading
  >
    <EmptyStateTitle className="text-text-dark">{text}</EmptyStateTitle>
    {description && (
      <EmptyStateDescription className="max-w-[153px]">{description}</EmptyStateDescription>
    )}
    {button?.children && <EmptyStateButton {...button} />}
  </EmptyState>
)

export default SpinnerLoader
