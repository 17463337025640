import { useToasts } from 'react-toast-notifications'

const useViolationToasts = () => {
  const { addToast } = useToasts()

  const displayToasts = (violations) => {
    if (!violations) return

    return violations.map((v) => addToast(v?.message, { appearance: 'error' }))
  }

  return { displayToasts }
}

export default useViolationToasts
