import { useMemo } from 'react'
import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import StringHelpers from '@sweetspot/club-portal-legacy/helpers/StringHelpers'
import { useTranslation } from 'react-i18next'

const useTableHeader = () => {
  const { t } = useTranslation()
  const { lang } = useSelector((state: RootState) => state.auth.me)
  const DAYS_OF_WEEK = useMemo(() => localizedDaysOfWeek(lang), [lang])
  return (
    <table>
      <thead className="h-8">
        <tr>
          <th className="h-8 !w-[74px]">
            <div className="border-Borders-stroke-clean gap-md flex h-full w-full items-center rounded-tl-sm border-l border-r border-t p-2">
              <p className="text-content-xs text-text-dark flex-1 font-bold">{t('words.from')}</p>
              <i className="fa-regular fa-clock-seven scale-75" />
            </div>
          </th>
          <th className="h-8 !w-[74px]">
            <div className="border-Borders-stroke-clean gap-md flex h-full w-full items-center border-t p-2">
              <p className="text-content-xs text-text-dark flex-1 font-bold">{t('words.to')}</p>
              <i className="fa-regular fa-clock-ten scale-75" />
            </div>
          </th>
          {DAYS_OF_WEEK.map((day, index) => (
            <th key={day} className="h-8 !w-[114px]">
              <div
                className={`border-Borders-stroke-clean bg-background-mono-lighter h-full w-full border-t py-2 ${
                  index === DAYS_OF_WEEK.length - 1 ? 'rounded-tr-sm border-r' : ''
                }`}
              >
                <div
                  className={`flex h-full w-full items-center justify-center px-2 ${
                    index !== DAYS_OF_WEEK.length - 1
                      ? 'border-surface-inverted-primary border-r'
                      : ''
                  }`}
                >
                  <p className="text-content-xs text-text-dark w-full text-center font-bold">
                    {StringHelpers.capitalize(day)}
                  </p>
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
    </table>
  )
}

export default useTableHeader
