import { useCallback, useContext, useEffect, useId, useMemo, useState } from 'react'
import { useSideSystemNavStore } from '../state'
import { cn } from '../../../../utils'
import { Button } from '../../../atoms'
import { IsInBottomNavContext, TopLevelItemKeyContext } from '../contexts'

interface TopLevelItemProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  activeIconFaClassName: string
  inactiveIconFaClassName: string
  text: string
  disabled?: boolean
  isActive?: boolean
  isPending?: boolean
  onClick?: () => void
}
const TopLevelItem = ({
  text,
  activeIconFaClassName,
  inactiveIconFaClassName,
  disabled = false,
  isActive = false,
  isPending = false,
  children = null,
  onClick = undefined,
}: TopLevelItemProps) => {
  const key = useId()
  const {
    setActiveKey,
    setActiveKeyByIsActiveProp,
    setIsExpanded,
    setBottomNavIsActive,
    isExpanded,
    activeKeys: { 1: currentTopLevelActiveItem },
    activeKeysByIsActiveProp: { 1: currentTopLevelActiveItemByIsActiveProp },
  } = useSideSystemNavStore()
  const [isHover, setIsHover] = useState(false)
  const [showSubItemsState, setShowSubItemsState] = useState(false)
  const isInBottomNav = useContext(IsInBottomNavContext)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        event.preventDefault()
        event.stopPropagation()
        return
      }
      setActiveKey(1, key)

      if (isInBottomNav) setBottomNavIsActive(true)
      else setBottomNavIsActive(false)
      setIsExpanded(true)
      setShowSubItemsState((prev) => !prev)

      onClick?.()
    },
    [key, onClick, setActiveKey, setIsExpanded, setBottomNavIsActive, isInBottomNav, disabled]
  )

  useEffect(() => {
    if (currentTopLevelActiveItem !== key) setShowSubItemsState(false)
  }, [key, currentTopLevelActiveItem])

  useEffect(() => {
    if (isActive && key) {
      setActiveKeyByIsActiveProp(1, key)
    }
  }, [isActive, key, setActiveKeyByIsActiveProp])

  const hasSubItems = useMemo(() => !!children, [children])

  const calculatedActive = useMemo(
    () =>
      isActive ||
      currentTopLevelActiveItem === key ||
      currentTopLevelActiveItemByIsActiveProp === key,
    [isActive, currentTopLevelActiveItem, currentTopLevelActiveItemByIsActiveProp, key]
  )

  const showSubItems = useMemo(
    () => currentTopLevelActiveItem === key && hasSubItems && isExpanded && showSubItemsState,
    [currentTopLevelActiveItem, hasSubItems, isExpanded, key, showSubItemsState]
  )

  const isActiveOrHovered = calculatedActive || isHover

  return (
    <div
      role="presentation"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={cn('w-full cursor-pointer border-b border-transparent', {
        'border-bg-sand-800 overflow-y-auto overflow-x-hidden [&::-webkit-scrollbar]:hidden':
          calculatedActive,
      })}
      data-testid="top-level-item-container"
    >
      <div
        className={cn(
          'items-hover pl-lg bg-sand-50 flex h-12 w-[256px] items-center pr-3.5 transition-colors',
          {
            'bg-sand-100': isActiveOrHovered,
            'pointer-events-none opacity-40': disabled,
            'fa-fade': isPending,
          }
        )}
        data-testid="top-level-item"
        onClick={handleClick}
        role="presentation"
      >
        <Button
          variant="clear-dark"
          size={isActiveOrHovered ? 'medium' : 'small'}
          className={cn(
            'text-content-base hover:text-text-dark !ml-0 gap-x-0 border border-transparent !bg-transparent pl-0 !shadow-none transition-none focus:ring-0',
            !calculatedActive && 'font-normal'
          )}
        >
          <div className="mr-xl relative h-full w-6">
            <i
              data-testid="active-icon"
              className={cn(
                'text-content-xl mr-xl !text-text-dark absolute left-1/2 top-1/2 flex w-6 -translate-x-1/2 -translate-y-1/2 items-center justify-center opacity-0 transition-opacity',
                activeIconFaClassName,
                { 'opacity-100': isActiveOrHovered }
              )}
            />
            <i
              data-testid="inactive-icon"
              className={cn(
                'mr-xl text-content-xl !text-text-dark absolute left-1/2 top-1/2 flex w-6 -translate-x-1/2 -translate-y-1/2 items-center justify-center opacity-0 transition-opacity',
                inactiveIconFaClassName,
                { 'opacity-100': !isActiveOrHovered }
              )}
            />
          </div>
          {text ?? ''}
        </Button>
        <i
          className={cn(
            'fa-regular fa-angle-right text-content-xl ml-auto opacity-0 transition-opacity',
            {
              'opacity-100': calculatedActive,
              'fa-angle-right': !hasSubItems,
              'fa-angle-down': hasSubItems,
            }
          )}
        />
      </div>
      {hasSubItems && (
        <div
          data-testid="sub-items-container"
          className={cn(
            'block max-h-0 overflow-hidden overflow-y-auto overflow-x-hidden transition-[max-height] duration-300 ease-in-out [&::-webkit-scrollbar]:hidden',
            {
              'h-auto max-h-[1500px]': showSubItems,
            }
          )}
        >
          <TopLevelItemKeyContext.Provider key={key} value={key}>
            {children}
          </TopLevelItemKeyContext.Provider>
        </div>
      )}
    </div>
  )
}
TopLevelItem.displayName = 'TopLevelItem'

export { TopLevelItem }
