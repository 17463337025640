import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const PromotionReservationWrapper = ({ title, children, className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.titleContainer)}>
        <p className={cx(styles.title)}>{title || ''}</p>
      </div>
      {children}
    </div>
  )
}

PromotionReservationWrapper.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
}

PromotionReservationWrapper.defaultProps = {
  title: '',
  className: '',
}

export default PromotionReservationWrapper
