export interface IDropdownType {
  id: string
  name: string
}

export enum IVerificationStatus {
  VALID = 'Valid',
  INVALID = 'Invalid',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
}
