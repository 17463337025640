import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import EllipsedText from '@sweetspot/sweetspot-js/common/components/EllipsedText'

const MainCell = ({ value, cellStyle }) => {
  const { t } = useTranslation()

  let text = value || '-'
  if (value && value.toString()[0] === '.') text = t(value)

  return (
    <div style={cellStyle}>
      <EllipsedText text={text} />
    </div>
  )
}

MainCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cellStyle: PropTypes.object,
}

export default MainCell
