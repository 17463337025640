import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useMembershipSignupDetails from './hooks/useMembershipSignupDetails'

// Component and utility imports
import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import PlayerDetails from './components/PlayerDetails'

import styles from './styles.module.scss'

const MembershipSignupsModal: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { id } = useParams()
  const { isDataLoading, data } = useMembershipSignupDetails(id)

  const handleOnCloseModal = () => {
    history.push('/memberships/membership-signups')
  }

  return (
    !isDataLoading && (
      <ModalContainer
        layout="default"
        width={'auto'}
        height={'auto'}
        theme="wizard"
        headerBackgroundColor={'#4F5B6D'}
        headerColor="#FFFFFF"
        headerTitle={
          <div className={styles.modalTitle}>
            <span>{t('settings.playerCard.playerDetails')}</span>
          </div>
        }
        onClose={handleOnCloseModal}
      >
        <PlayerDetails playerData={data} />
      </ModalContainer>
    )
  )
}

export default MembershipSignupsModal
