import cx from 'classnames'
import m from 'moment'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'

import styles from './styles.module.scss'

const BookingHeader = ({ fromTime, loaders, course, format }) => {
  const getTime = useCallback(
    (fromTime) => {
      if (fromTime && course?.timezone && m(fromTime).isValid()) {
        return m(fromTime).tz(course.timezone).format('HH.mm')
      }
      return null
    },
    [course?.timezone]
  )

  const getTimeDate = useCallback(
    (fromTime) => {
      if (fromTime && course?.timezone && m(fromTime).isValid()) {
        return m(fromTime).tz(course.timezone).format('dddd | Do MMMM | YYYY')
      }
      return null
    },
    [course?.timezone]
  )

  return !loaders.bookings ? (
    <div className={cx(styles.container, { [styles.ccompact]: format === 'compact' })}>
      <h2 className={cx(styles.text, { [styles.compact]: format === 'compact' })}>
        {(!loaders.teeTime && getTime(fromTime)) || <Skeleton width={38} />}{' '}
        {(!loaders.teeTime && getTimeDate(fromTime)) || <Skeleton width={164} />}
        &emsp;
        {(!loaders.course && <span>{course?.name}</span>) || <Skeleton width={127} />}
        &emsp;
      </h2>
    </div>
  ) : (
    <div className={styles.loaderContainer}>
      <PulseLoader showIf={true} />
    </div>
  )
}

BookingHeader.propTypes = {
  fromTime: PropTypes.string,
  bookings: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      participants: PropTypes.objectOf(
        PropTypes.shape({
          is_member: PropTypes.bool,
          partner_type: PropTypes.any,
          is_arrival_registration: PropTypes.bool,
        })
      ),
    })
  ),
  course: PropTypes.shape({
    name: PropTypes.string,
  }),
  loaders: PropTypes.shape({
    teeTime: PropTypes.bool,
    teeTimePrice: PropTypes.bool,
    course: PropTypes.bool,
    bookings: PropTypes.bool,
  }),
  format: PropTypes.oneOf(['compact', 'full']),
}

BookingHeader.defaultProps = {
  loaders: {
    teeTimePrice: false,
    teeTime: false,
    course: false,
    bookings: false,
  },
  format: 'full',
}

export default BookingHeader
