import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'

import style from './style.module.scss'

const SelectGolfClub = (props) => {
  const { t } = useTranslation()
  const [selectedID, setSelectedID] = React.useState(null)

  const renderHeader = () => {
    return (
      <div className={style.header}>
        <Text textId="cooperationCreate.title" />
        <CloseIcon onClick={props.onClose} className={style.closeIcon} />
      </div>
    )
  }

  const renderBody = () => {
    return (
      <form className={style.body} onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className={`ss-label ${style.label}`}>
            {t('cooperationCreate.label_GolfClub')}
          </label>
          <DropdownSelect
            showBorder
            width="280px"
            placeholder={t('cooperationCreate.placeholder_SelectGolfClub')}
            values={props.golfClubList}
            onSelect={(itemID) => setSelectedID(itemID)}
          />
        </div>
      </form>
    )
  }

  const renderFooter = () => {
    return (
      <div className={style.footer}>
        <div className={style.actionGroup}>
          <div className={style.btnGroup}>
            {props.isLoading ? (
              <div className={style.loading}>
                <PulseLoader showIf={props.isLoading} />
              </div>
            ) : (
              <>
                <div className={style.cancel} onClick={props.onClose}>
                  <Text textId="cooperationCreate.button_Cancel" />
                </div>
                <button
                  className="system-button primary md-32"
                  disabled={props.isLoading || !selectedID}
                  onClick={() => props.onCreate(selectedID)}
                >
                  {t('cooperationCreate.button_Create')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </>
  )
}

SelectGolfClub.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  golfClubList: PropTypes.array.isRequired,
}

export default SelectGolfClub
