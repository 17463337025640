import { BallDispenser, RangeWithBallDispensers } from '@sweetspot/shared/data-access/api-platform'

import {
  IntegrationFormFields,
  SetupBallDispenser,
  SetupFormFields,
  StatusBallDispenser,
} from '../types'
import { ballDispenserBrandName, terminalModel } from '../constants'

type InitialValuesTypes = {
  statusData: StatusBallDispenser[]
  setupData: SetupFormFields
  integrationData: IntegrationFormFields
}

export const constructData = (data: RangeWithBallDispensers): InitialValuesTypes => {
  const statusData = data?.dispensers?.reduce<StatusBallDispenser[]>((acc, dispenser, index) => {
    const outlets =
      dispenser?.outlets?.map((outlet) => ({
        name: dispenser?.name,
        dispenserIndex: index,
        outlet: outlet?.name,
        outletId: outlet?.id,
        status: outlet?.is_active,
        outletStatus: outlet?.status,
        terminalStatus: 'N/A',
        ballQuantity: 'N/A',
        assetId: outlet?.asset_id,
      })) || []

    return [...acc, ...outlets]
  }, [])

  const setupBallDispensers =
    data?.dispensers?.map?.(({ id, name, outlets }) => ({
      id,
      name,
      brand: ballDispenserBrandName,
      nrOfOutlets: outlets?.length,
      outletOneName: outlets?.[0]?.name,
      outletTwoName: outlets?.[1]?.name,
    })) ?? []
  const integrationBallDispensers =
    data?.dispensers?.map?.(({ id, name, outlets }) => ({
      id,
      name,
      outlets: outlets?.map?.((outlet) => ({
        id: outlet?.id,
        name: outlet?.name,
        assetId: outlet?.asset_id,
        terminalModel: outlet?.terminal?.model || terminalModel,
        terminalSerialNumber: outlet?.terminal?.serial_number,
      })),
    })) ?? []

  return {
    statusData,
    setupData: {
      ballDispensers: setupBallDispensers,
    },
    integrationData: {
      siteId: data?.range_servant_site_id,
      ballDispensers: integrationBallDispensers,
    },
  }
}

export const constructSetupPayload = (
  dispensers: SetupBallDispenser[],
  existingDispensers?: BallDispenser[]
): RangeWithBallDispensers => ({
  dispensers: dispensers.map((dispenser) => {
    const { id, name, nrOfOutlets, outletOneName, outletTwoName } = dispenser
    const existingDispenser = existingDispensers?.find((disp) => disp.id === id)
    const outlets = [...Array(nrOfOutlets).keys()].map((outletNr) => {
      const existingOutlet = existingDispenser?.outlets?.[outletNr] || {}
      return {
        ...existingOutlet,
        name: (outletNr === 0 ? outletOneName : outletTwoName) || '',
      }
    })
    return { id: id || existingDispenser?.id, name, outlets }
  }),
})

export const constructIntegrationPayload = (
  values: IntegrationFormFields,
  existingDispensers?: BallDispenser[]
): RangeWithBallDispensers => ({
  range_servant_site_id: values.siteId,
  dispensers: values.ballDispensers?.map((dispenser) => {
    const { id, name } = dispenser
    const existingDispenser = existingDispensers?.find((disp) => disp.id === id)
    const outlets = dispenser.outlets?.map?.((outlet) => {
      const existingOutlet = existingDispenser?.outlets?.find((item) => item.id === outlet.id) || {}
      return {
        ...existingOutlet,
        ...outlet,
        asset_id: outlet.assetId,
        terminal: {
          id: existingOutlet?.terminal?.id,
          model: outlet.terminalModel || existingOutlet?.terminal?.model || terminalModel,
          serial_number: outlet.terminalSerialNumber,
        },
      }
    })
    return { id, name, outlets }
  }),
})

export const validateSetupTab = (payload: RangeWithBallDispensers): string => {
  const dispenserNameTooShort = payload.dispensers?.some(
    (dispenser) => dispenser.name && dispenser.name.length < 2
  )
  if (dispenserNameTooShort) return 'errors.ballDispenserNameTooShort'

  const outletNameTooShort = payload.dispensers?.some((dispenser) => {
    const hasTwoOutlets = dispenser.outlets?.length === 2
    return (
      hasTwoOutlets && dispenser.outlets.some((outlet) => !outlet.name || outlet.name.length < 2)
    )
  })
  if (outletNameTooShort) return 'errors.outletNameTooShort'

  return ''
}

export const validateIntegrationTab = (
  payload: RangeWithBallDispensers,
  t: (key: string) => string
): string => {
  const noSiteId = !payload.range_servant_site_id
  if (noSiteId) {
    return `${t('settings.ballDispensers.siteIdLabel')} ${t('errors.fieldRequired').toLowerCase()}`
  }

  const noAssetId = payload.dispensers?.some((dispenser) =>
    dispenser.outlets?.some((outlet) => !outlet.asset_id)
  )
  if (noAssetId) {
    return `${t('settings.ballDispensers.assetIdLabel')} ${t('errors.fieldRequired').toLowerCase()}`
  }

  const noTerminalSerialNumber = payload.dispensers?.some((dispenser) =>
    dispenser.outlets?.some((outlet) => !outlet.terminal?.serial_number)
  )
  if (noTerminalSerialNumber) return t('errors.terminalSerialNumberRequired')

  return ''
}
