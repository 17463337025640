import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

import Label from '../Partials/Label'
import FeedbackContainer from '../Partials/FeedbackContainer'
import SingleRadioOption from '../Partials/SingleRadioOption'

const RadioGroup = ({
  value,
  options,
  onChange,
  disabled,
  label,
  containerClassName,
  containerWidth,
  error,
  success,
  infoTextKey,
}) => {
  const handleChange = (newValue) => {
    if (disabled) return
    onChange(newValue)
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          'w-full': containerWidth === 'full',
          'w-1/2': containerWidth === 'half',
          'w-1/4': containerWidth === 'quarter',
        },
        containerClassName
      )}
    >
      <Label label={label} infoTextKey={infoTextKey} />

      {options.map((option) => (
        <SingleRadioOption
          key={option.value}
          {...option}
          disabled={option.disabled || disabled}
          lightLabel={!!label}
          onChange={handleChange}
          checked={option.value === value}
        />
      ))}
      <FeedbackContainer error={error} success={success} />
    </div>
  )
}

RadioGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  containerWidth: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  infoTextKey: PropTypes.string,
}
RadioGroup.defaultProps = {
  value: '',
  options: [],
  onChange: () => {},
  disabled: false,
  label: '',
  containerClassName: '',
  containerWidth: '',
  error: '',
  success: '',
  infoTextKey: '',
}

export default RadioGroup
