import { CONSTANTS } from '../constants'

export function addToast(message, type) {
  return (dispatch) => {
    dispatch({
      type: CONSTANTS.APP_STATE.ADD_TOAST,
      payload: { toast: { message, type } },
    })
  }
}

export function setLoading(isLoading) {
  return (dispatch) => {
    dispatch({
      type: CONSTANTS.APP_STATE.SET_LOADING,
      payload: { isLoading },
    })
  }
}
