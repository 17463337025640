import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const InnerBoxWrapper = ({ children, className }) => {
  return <div className={cx(styles.container, className)}>{children}</div>
}

InnerBoxWrapper.propTypes = {
  className: PropTypes.string,
}

InnerBoxWrapper.defaultProps = {
  className: '',
}

export default InnerBoxWrapper
