import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { ReactComponent as SearchIcon } from '@sweetspot/sweetspot-js/assets/svgs/search-icon.svg'

import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import useButtonListener from '@sweetspot/sweetspot-js/common/hooks/useButtonListener'
import Spinner from '@sweetspot/sweetspot-js/common/components/Spinner'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'

import {
  getElementTopLeft,
  getElementDimensions,
} from '@sweetspot/sweetspot-js/common/functions/utils'

import stylesDefault from './styles-default.module.scss'
import stylesWebApp from './styles-webapp.module.scss'

const DROPDOWN_CONTAINER_MAX_HEIGHT = 200
const SEARCH_INPUT_HEIGHT = 45
const DROPDOWN_ITEM_HEIGHT = 40

const Button = ({
  text,
  className,
  onClick,
  disabled,
  selected,
  type,
  width,
  children,
  theme,
  loading,
  loaderStyle,
  disabledTheme,
  size,
  rounded,
  dropdownOptions,
  dropdownChildren,
  dropdownOptionOnClick,
  dropdownValue,
  dropdownStyle,
  dropdownDefaultOpen,
  forceCloseDropdown,
  isSearchable,
  platformTheme,
  position,
  tabIndex,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [focusedDropdown, setFocusedDropdown] = useState(0)
  const [searchText, setSearchText] = useState('')
  const dropdownContainerRef = useRef()
  const dropdownButtonRef = useRef()
  const resultsRefs = useRef([])
  let searchInput = useRef()

  const styles = useMemo(() => {
    if (platformTheme === 'default') {
      return stylesDefault
    } else if (platformTheme === 'webApp') {
      return stylesWebApp
    }

    return stylesDefault
  }, [platformTheme])

  useOnClickOutside(dropdownContainerRef, () => {
    setDropdownOpen(false)
  })

  useEffect(() => {
    setDropdownOpen(dropdownDefaultOpen)
  }, [dropdownDefaultOpen])

  useEffect(() => {
    if (forceCloseDropdown) {
      setDropdownOpen(false)
    }
  }, [forceCloseDropdown])

  useButtonListener(
    ['Escape'],
    () => {
      setDropdownOpen(false)
    },
    'keydown',
    dropdownOpen
      ? {
          preventDefault: true,
          stopPropagation: true,
          capture: true,
        }
      : {
          preventDefault: false,
          stopPropagation: false,
          capture: false,
        }
  )

  const getFilteredOptions = useCallback(() => {
    if (!dropdownOptions || !dropdownOptions.length) return []

    if (isSearchable) {
      return dropdownOptions.filter(
        (option) => !!option?.label?.toLowerCase().includes(searchText.toLowerCase())
      )
    }

    return dropdownOptions
  }, [dropdownOptions, isSearchable, searchText])

  const handleDropdownOptionClick = useCallback(
    (item) => {
      if (disabled || loading || !item || item?.disabled) return
      if (dropdownOptionOnClick) dropdownOptionOnClick(item)
    },
    [disabled, dropdownOptionOnClick, loading]
  )

  const hasDropdown = useCallback(() => {
    if ((dropdownOptions && dropdownOptionOnClick) || dropdownChildren) return true
    return false
  }, [dropdownChildren, dropdownOptionOnClick, dropdownOptions])

  // Handle nav with arrow keys
  useEffect(() => {
    const handleKeyPresses = (e) => {
      if (!hasDropdown() || !dropdownOpen) return

      const filteredOptions = getFilteredOptions()

      if (
        e?.keyCode === 40 ||
        e?.which === 40 ||
        e?.key === 'ArrowDown' ||
        e?.code === 'ArrowDown'
      ) {
        const newIndex = focusedDropdown > filteredOptions.length - 2 ? 0 : focusedDropdown + 1
        setFocusedDropdown(newIndex)
        if (resultsRefs?.current?.[newIndex]?.scrollIntoView) {
          resultsRefs.current[newIndex].scrollIntoView({ behavior: 'smooth' })
        }
        e.preventDefault()
        e.stopPropagation()
      }
      if (e?.keyCode === 38 || e?.which === 38 || e?.key === 'ArrowUp' || e?.code === 'ArrowUp') {
        const newIndex = focusedDropdown < 1 ? filteredOptions.length - 1 : focusedDropdown - 1
        setFocusedDropdown(newIndex)
        if (resultsRefs?.current?.[newIndex]?.scrollIntoView) {
          resultsRefs.current[newIndex].scrollIntoView({ behavior: 'smooth' })
        }
        e.preventDefault()
        e.stopPropagation()
      }
      if (e?.keyCode === 13 || e?.which === 13 || e?.key === 'Enter' || e?.code === 'Enter') {
        if (filteredOptions[0]) {
          setSearchText('')
          setFocusedDropdown(0)
          setDropdownOpen(false)
          handleDropdownOptionClick(filteredOptions[focusedDropdown])
        }
      }
    }

    window.addEventListener('keydown', handleKeyPresses)

    return () => window.removeEventListener('keydown', handleKeyPresses)
  }, [
    dropdownOpen,
    searchText,
    dropdownOptions,
    focusedDropdown,
    getFilteredOptions,
    handleDropdownOptionClick,
    hasDropdown,
  ])

  const handleButtonClick = (e) => {
    if (disabled || loading) {
      e.preventDefault()
      return
    }
    if (onClick) onClick(e)
  }

  const handleDropdownButtonClick = (e) => {
    if (disabled || loading) return
    if (!searchInput?.current?.contains(e?.target)) {
      setDropdownOpen((prev) => !prev)
    }
  }

  const widthClassName = cx({
    'w-full': width === 'full',
    'w-1/2': width === 'half',
    'w-1/4': width === 'quarter',
  })

  const buttonClassName = cx(
    styles.button,
    {
      [styles.dropdownSimple]: hasDropdown() && dropdownStyle === 'simple',
      [styles.dropdownDouble]: hasDropdown() && dropdownStyle === 'double',
      [styles.hasDropdown]: hasDropdown(),
    },
    {
      [styles.rounded]: rounded,
    },
    {
      [styles.selected]: selected,
    },
    {
      [styles.loading]: loading,
    },
    className
  )

  const sizeClassName = cx({
    [styles.defaultSize]: size === 'default',
    [styles.mediumSize]: size === 'medium',
    [styles.largerSize]: size === 'larger',
    [styles.largeSize]: size === 'large',
    [styles.smallSize]: size === 'small',
  })

  const themeClassName = cx({
    [styles.noneTheme]: theme === 'none',
    [styles.defaultTheme]: theme === 'default',
    [styles.defaultThemeOutline]: theme === 'default-outline',
    [styles.mainThemeOutline]: theme === 'main-outline',
    [styles.dangerTheme]: theme === 'danger',
    [styles.dangerThemeOutline]: theme === 'danger-outline',
    [styles.greenTheme]: theme === 'green',
    [styles.grayTheme]: theme === 'gray',
    [styles.grayOutlineTheme]: theme === 'gray-outline',
    [styles.lightGrayOutlineTheme]: theme === 'light-gray-outline',
    [styles.whiteOutlineTheme]: theme === 'white-outline',
    [styles.blackOutlineTheme]: theme === 'black-outline',
    [styles.whiteBgGrayOutline]: theme === 'whitebg-grayoutline',
    [styles.disabledTheme]: theme === 'disabled' || disabled,
    [styles.clubTheme]: platformTheme === 'club',
    [styles.webAppTheme]: platformTheme === 'webApp',
  })

  const disabledThemeClassName = cx({
    [styles.disabledDefaultTheme]: disabledTheme === 'default' && disabled,
    [styles.disabledGrayOutlineTheme]: disabledTheme === 'gray-outline' && disabled,
  })

  const widthStyle =
    width &&
    typeof width === 'string' &&
    (width.includes('%') || width.includes('px') || width === 'auto')
      ? {
          width: width,
        }
      : {}

  const renderSpinner = () => {
    if (platformTheme === 'webApp') {
      return (
        <PulseLoader
          showIf={true}
          dotClassName={styles.pulseLoaderDot}
          className={styles.pulseLoaderClassName}
          contentClassName={styles.pulseLoaderContentClassName}
        />
      )
    }
    if (loaderStyle === 'spinner') {
      return <Spinner visible={true} className={styles.spinner} />
    }
    if (loaderStyle === 'pulse') {
      return (
        <PulseLoader
          showIf={true}
          dotClassName={styles.pulseLoaderDot}
          className={styles.pulseLoaderClassName}
          contentClassName={styles.pulseLoaderContentClassName}
        />
      )
    }
    return null
  }

  const renderDropdown = () => {
    const { top: dropdownButtonTop, left: dropdownButtonLeft } = getElementTopLeft(
      dropdownButtonRef.current
    )
    const { height, width } = getElementDimensions(dropdownButtonRef.current)
    const filteredOptions = getFilteredOptions()

    let top = 0

    if (dropdownButtonTop) {
      if (position === 'top') {
        const dropdownItemsHeight = Math.min(
          DROPDOWN_CONTAINER_MAX_HEIGHT,
          filteredOptions.length * DROPDOWN_ITEM_HEIGHT
        )
        const dropdownHeight = dropdownItemsHeight + SEARCH_INPUT_HEIGHT
        top = dropdownButtonTop - height - dropdownHeight
      } else {
        top = dropdownButtonTop + height + 3
      }
    }

    return (
      <React.Fragment>
        <div className={cx(styles.caretContainer)}>
          <CaretDown className={cx(styles.dropdownCaret)} />
        </div>
        <Portal id="button-dropdowns">
          <div
            ref={dropdownContainerRef}
            style={{ top, left: dropdownButtonLeft || 0, width: width }}
            className={cx(styles.dropdownContainer, {
              [styles.dropdownContainerOpen]: dropdownOpen,
            })}
          >
            {isSearchable && dropdownOpen && (
              <div ref={searchInput} className={styles.searchInput}>
                <TextInputField
                  prefix={<SearchIcon />}
                  inputProps={{ autoFocus: true }}
                  value={searchText}
                  onChange={(val) => {
                    setSearchText(val)
                    setFocusedDropdown(0)
                  }}
                />
              </div>
            )}
            {dropdownChildren ? (
              dropdownChildren
            ) : (
              <div className={styles.dropdownMenu}>
                {filteredOptions.map(
                  (item, index) =>
                    item && (
                      <p
                        ref={(ref) => (resultsRefs.current[index] = ref)}
                        key={item.id || item.label}
                        className={cx(styles.dropdownItem, {
                          [styles.selected]: item?.selected,
                          [styles.focused]: focusedDropdown === index,
                          [styles.disabled]: item?.disabled,
                        })}
                        onClick={() => handleDropdownOptionClick(item)}
                      >
                        {item.status && (
                          <span className={item.status.active ? styles.active : styles.inActive} />
                        )}
                        {item.longLabel || item.label}
                        {item.status && <label className={styles.status}>{item.status.text}</label>}
                      </p>
                    )
                )}
              </div>
            )}
          </div>
        </Portal>
      </React.Fragment>
    )
  }

  if (hasDropdown() && dropdownStyle === 'simple') {
    return (
      <button
        ref={dropdownButtonRef}
        type={type}
        onClick={handleDropdownButtonClick}
        tabIndex={tabIndex}
        className={cx(
          themeClassName,
          disabledThemeClassName,
          sizeClassName,
          buttonClassName,
          widthClassName
        )}
        style={widthStyle}
      >
        {loading ? (
          renderSpinner()
        ) : (
          <React.Fragment>
            {theme === 'gray' ? (
              <span className={cx(styles.dropdownSeparator)}>{dropdownValue || text}</span>
            ) : (
              dropdownValue || text
            )}

            {renderDropdown()}
          </React.Fragment>
        )}
      </button>
    )
  }

  return (
    <button
      type={type}
      onClick={(e) => handleButtonClick(e)}
      tabIndex={tabIndex}
      className={cx(
        themeClassName,
        disabledThemeClassName,
        sizeClassName,
        buttonClassName,
        widthClassName
      )}
      style={widthStyle}
    >
      {loading ? (
        renderSpinner()
      ) : (
        <React.Fragment>
          {children ? children : ''}
          {!children && text ? text : null}
        </React.Fragment>
      )}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  theme: PropTypes.oneOf([
    'default',
    'danger',
    'green',
    'none',
    'disabled',
    'gray',
    'gray-outline',
    'default-outline',
    'danger-outline',
    'white-outline',
    'black-outline',
    'whitebg-grayoutline',
  ]),
  platformTheme: PropTypes.oneOf(['webApp', 'club']),
  disabledTheme: PropTypes.oneOf(['gray-outline', 'default']),
  width: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half', 'quarter']), PropTypes.string]),
  size: PropTypes.oneOf(['default', 'larger', 'large', 'medium', 'small']),
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      longLabel: PropTypes.string,
    })
  ),
  dropdownChildren: PropTypes.node,
  dropdownOptionOnClick: PropTypes.func,
  dropdownValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dropdownStyle: PropTypes.oneOf(['simple']),
  dropdownDefaultOpen: PropTypes.bool,
  rounded: PropTypes.bool,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  loaderStyle: PropTypes.oneOf(['spinner', 'pulse']),
  isSearchable: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'button']),
  forceCloseDropdown: PropTypes.bool,
}

Button.defaultProps = {
  text: '',
  className: '',
  onClick: () => {},
  disabled: false,
  type: 'button',
  width: 'full',
  children: null,
  theme: 'default',
  disabledTheme: 'default',
  size: 'default',
  dropdownOptions: null,
  dropdownChildren: undefined,
  dropdownOptionOnClick: undefined,
  dropdownStyle: 'simple',
  dropdownDefaultOpen: false,
  forceCloseDropdown: false,
  dropdownValue: null,
  rounded: false,
  selected: false,
  loading: false,
  loaderStyle: 'spinner',
  isSearchable: false,
  platformTheme: 'club',
  position: 'button',
  tabIndex: 0,
}

export default Button
