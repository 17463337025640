import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import style from './style.module.scss'

import {
  getPlayerMembership,
  getPlayers,
  updatePlayer,
  deletePlayer,
  unlinkGolfId,
  getFederationInfo,
} from '@sweetspot/club-portal-legacy/store/actions'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import XPanel from '@sweetspot/club-portal-legacy/components/XPanel'
import UsersTable from './components/UsersTable'

class AppUsers extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleUnlinkGolfId = (playerId) => {
    const { token, unlinkGolfId, selectedGolfClub } = this.props

    const hasCdhNumber = !!selectedGolfClub?.cdh_id

    unlinkGolfId(token, playerId, hasCdhNumber ? 'cdh' : 'git').then(() => {
      if (!this._userTable?.state) return
      const { selectedPlayer } = this._userTable.state

      this._userTable.setState({
        selectedPlayer: { ...selectedPlayer, golfId: '' },
      })
    })
  }

  renderTable() {
    const { token, lang, getPlayers, updateUser, deleteUser, selectedGolfClub, isSuperAdmin } =
      this.props

    const hasCdhNumber = !!selectedGolfClub?.cdh_id

    return (
      <UsersTable
        ref={(comp) => (this._userTable = comp)}
        token={token}
        lang={lang}
        getPlayers={getPlayers}
        getPlayerMembership={this.props.getPlayerMembership}
        getFederationProfile={this.props.getFederationProfile}
        updateUser={updateUser}
        deleteUser={deleteUser}
        selectedClubId={selectedGolfClub?.id}
        onClickUnlink={this.handleUnlinkGolfId}
        isSuperAdmin={isSuperAdmin}
        hasCdhNumber={hasCdhNumber}
      />
    )
  }

  render() {
    return (
      <Container>
        <XPanel fillHeight scroll noPadding>
          <div className={style.content}>
            <div className={style.title}>{<Text textId={'settings.users.handleUsers'} />}</div>
            {this.renderTable()}
          </div>
        </XPanel>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const currentClub = state?.golfClub?.list.filter(
    (clubObj) => clubObj?.id === state?.golfClub?.selectedId
  )
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    roles: state.auth.roles,
    selectedGolfClub: currentClub[0],
    isSuperAdmin: state.auth.isSuperAdmin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPlayerMembership: (id) => dispatch(getPlayerMembership(id)),
    getFederationProfile: (playerId, gitId) => getFederationInfo(playerId, gitId),
    getPlayers: (token, query) => dispatch(getPlayers(token, query)),
    deleteUser: (id) => dispatch(deletePlayer(id)),
    updateUser: (payload) => dispatch(updatePlayer(payload)),
    unlinkGolfId: (token, playerId, federation) =>
      dispatch(unlinkGolfId(token, playerId, federation)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppUsers)

AppUsers.propTypes = {
  token: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  getPlayers: PropTypes.func.isRequired,
  getPlayerMembership: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  unlinkGolfId: PropTypes.func.isRequired,
  getFederationProfile: PropTypes.func.isRequired,
  selectedGolfClub: PropTypes.number.isRequired,
}
