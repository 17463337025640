import React from 'react'
import _toNumber from 'lodash/toNumber'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import * as API_VOUCHERS from '@sweetspot/sweetspot-js/features/vouchers/services/api-platform'
import {
  createNewAction,
  updateAction,
} from '@sweetspot/sweetspot-js/features/promotions/services/api-platform'

import * as DATE from '@sweetspot/sweetspot-js/features/vouchers/tools/date'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { parseError } from '@sweetspot/sweetspot-js/common/functions/parse'
import {
  isValid,
  parseAction,
  parseSettings,
} from '@sweetspot/sweetspot-js/features/vouchers/tools/configuration'
import { numberToLocalFormat } from '@sweetspot/sweetspot-js/common/functions/utils'
import i18n from 'i18next'

const ITEMS_PER_PAGE = 50
export const FORM = {
  ID: 'id',
  NAME: 'name',
  VOUCHER_TYPE: 'code_type',
  CODE: 'code',
  STARTS_AT: 'starts_at',
  START_TIME: 'start_time',
  ENDS_AT: 'ends_at',
  END_TIME: 'end_time',
  NO_END: 'no_end',
  PERIOD_TYPE: 'period_type',
  DURATION_NUMBER: 'duration_number',
  DURATION_TYPE: 'duration_type',
}
export const PERIOD_TYPE = {
  PERIOD: 'period',
  DYNAMIC_PERIOD: 'dynamic_period',
}
export const DURATION_TYPE = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
}
export const DEFAULT_PERIOD_TYPE = PERIOD_TYPE.PERIOD
export const DEFAULT_DURATION_NUMBER = 1
export const DEFAULT_DURATION_TYPE = DURATION_TYPE.MONTHS
export const DEFAULT_NO_FINAL_DATE_OF_USE = true
export const VOUCHER_TYPE = {
  STANDALONE: 'global',
  BULK: 'unique',
}
export const LOADER = {
  VOUCHER: 'voucher',
  NAME: 'name',
  PERIOD: 'period',
  CODES: 'codes',
  CODE_ASSIGN: 'code_assign',
  CODE_REMOVE: 'code_remove',
  DISCOUNT: 'discount',
  COUPONS: 'coupons',
}

const _voucherDraft = {
  [FORM.ID]: 'new',
  [FORM.NAME]: null,
  [FORM.VOUCHER_TYPE]: VOUCHER_TYPE.STANDALONE,
  [FORM.STARTS_AT]: null,
  [FORM.START_TIME]: null,
  [FORM.ENDS_AT]: null,
  [FORM.END_TIME]: null,
  [FORM.NO_END]: undefined,
  [FORM.CODE]: '',
  [FORM.PERIOD_TYPE]: null,
  [FORM.DURATION_NUMBER]: null,
  [FORM.DURATION_TYPE]: null,
}

export const SETTINGS = {
  ACTION_ID: 'actionID',
  IS_USES_TYPE: 'isUsesType',
  IS_VALUE_TYPE: 'isValueType',
  APPLY_GOLF_CART: 'applyGolfCart',
  APPLY_HOLDER: 'applyHolder',
  APPLY_GUEST: 'applyGuest',
  PERCENTAGE: 'percentage', // Percentage input
  USES_VALUE: 'usesValue', // Uses / Discounted Value input
  MIN_PRICE: 'minPrice', // Minimum price input
}

export const _discountDraft = {
  [SETTINGS.ACTION_ID]: null,
  [SETTINGS.IS_USES_TYPE]: false,
  [SETTINGS.IS_VALUE_TYPE]: false,
  [SETTINGS.APPLY_GOLF_CART]: true,
  [SETTINGS.APPLY_HOLDER]: true,
  [SETTINGS.APPLY_GUEST]: true,
  [SETTINGS.PERCENTAGE]: '',
  [SETTINGS.USES_VALUE]: '',
  [SETTINGS.MIN_PRICE]: '',
}

const useVoucher = (voucherID, clubID) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isEdited, setIsEdited] = React.useState(false)
  const [loaders, _setLoaders] = React.useState({})

  const setLoader = (target, value) => {
    _setLoaders({ ...loaders, [target]: value })
  }
  const [dialog, setDialog] = React.useState(null)
  const [voucherDraft, setVoucher] = React.useState(_voucherDraft)
  const [editField, setEditField] = React.useState(null)
  const [errors, setErrors] = React.useState({}) // Do not reset to null, use only object

  const [discountDraft, setDiscount] = React.useState(_discountDraft)

  const {
    data: voucher,
    isFetching,
    refetch: refetchVoucher,
    error: queryError,
  } = useQuery(
    [CLUB_QUERIES.VOUCHERS, voucherID],
    async () => {
      const data = await API_VOUCHERS.getVoucherByID(voucherID)
      setDiscount(parseAction(data.actions[0]))
      return {
        ...data,
        code: data.coupons.length > 0 ? data.coupons[0].code : data.code,
      }
    },
    {
      enabled: !!voucherID && voucherID !== 'new',
      retry: false,
    }
  )

  // Lazy loading 50 at a time
  const {
    refetch: refetchCoupons,
    isFetching: isFetchingCoupons,
    data: coupons,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [CLUB_QUERIES.COUPONS, voucherID],
    queryFn: async ({ pageParam = 1 }) => {
      let query = `?limit=${ITEMS_PER_PAGE}&page=${pageParam}`
      const coupons = await API_VOUCHERS.getCouponList(voucherID, query)
      return coupons
    },
    enabled: !!voucherID && voucherID !== 'new',
    getNextPageParam: (lastPage) => {
      const next = lastPage['hydra:view']['hydra:next']
      return next?.slice(-1) ?? undefined
    },
    select: (data) => data.pages.flatMap((page) => page['hydra:member']),
  })

  const refetch = async () => Promise.all([refetchVoucher(), refetchCoupons()])

  const createVoucher = async () => {
    let validateOK = true

    const validationError = (fieldName, errorString) => {
      validateOK = false
      setErrors((errors) => ({ ...errors, [fieldName]: t(errorString) }))
      return false
    }

    let payload = Object.assign(
      {
        [FORM.NAME]: voucherDraft[FORM.NAME],
        [FORM.VOUCHER_TYPE]: voucherDraft[FORM.VOUCHER_TYPE],
        [FORM.PERIOD_TYPE]: voucherDraft[FORM.PERIOD_TYPE] || DEFAULT_PERIOD_TYPE,
      },
      voucherDraft[FORM.PERIOD_TYPE] === PERIOD_TYPE.DYNAMIC_PERIOD
        ? {
            [FORM.DURATION_NUMBER]: voucherDraft[FORM.DURATION_NUMBER] ?? DEFAULT_DURATION_NUMBER,
            [FORM.DURATION_TYPE]: voucherDraft[FORM.DURATION_TYPE] || DEFAULT_DURATION_TYPE,
          }
        : {
            [FORM.STARTS_AT]: DATE.startDate(
              voucherDraft[FORM.STARTS_AT],
              voucherDraft[FORM.START_TIME],
              true
            ),
          },
      !(voucherDraft[FORM.NO_END] ?? DEFAULT_NO_FINAL_DATE_OF_USE)
        ? {
            [FORM.ENDS_AT]: voucherDraft[FORM.ENDS_AT]
              ? DATE.endDate(voucherDraft[FORM.ENDS_AT])
              : null,
          }
        : {},
      voucherDraft[FORM.VOUCHER_TYPE] === VOUCHER_TYPE.STANDALONE
        ? { [FORM.CODE]: voucherDraft[FORM.CODE] }
        : {}
    )

    setErrors({})
    Object.keys(payload).forEach((fieldName) => {
      if (!payload[fieldName]) {
        validationError(fieldName, 'vouchers.label_FieldEmpty')
      }
    })
    if (!validateOK) return
    try {
      setLoader(LOADER.VOUCHER, true)
      const voucher = await API_VOUCHERS.createVoucher(clubID, payload)
      setVoucher(_voucherDraft)
      setErrors({})
      history.push(`/vouchers/${voucher.id}`)
    } catch (e) {
      setErrors(parseError(e, true))
    } finally {
      setLoader(LOADER.VOUCHER, false)
    }
  }

  const startEdit = (fieldName) => {
    if (voucherID === 'new') return
    setEditField(fieldName)
    setErrors({ ...errors, [fieldName]: null })
  }

  const confirmEdit = async (target) => {
    if (!isEdited) {
      setEditField(null)
      return
    }
    let payload = null

    const isDynamicPeriod =
      voucherDraft[FORM.PERIOD_TYPE] !== null
        ? voucherDraft[FORM.PERIOD_TYPE] === PERIOD_TYPE.DYNAMIC_PERIOD
        : voucher.dynamic_period?.[FORM.PERIOD_TYPE] === PERIOD_TYPE.DYNAMIC_PERIOD
    const isNoEnd =
      voucherDraft[FORM.NO_END] === undefined
        ? voucher[FORM.ENDS_AT] === null
        : voucherDraft[FORM.NO_END]

    // startDateAndTime and endDateAndTime will first check if the voucherDraft [edited] has a value, if not it will check the voucher [already set] value
    const startDateAndTime = DATE.startDate(
      voucherDraft[FORM.STARTS_AT] || voucher[FORM.STARTS_AT],
      voucherDraft[FORM.START_TIME] || DATE.format(voucher[FORM.STARTS_AT], 'HH:mm')
    )

    const endDateAndTime = DATE.endDate(
      voucherDraft[FORM.ENDS_AT] || voucher[FORM.ENDS_AT],
      voucherDraft[FORM.END_TIME] || DATE.format(voucher[FORM.ENDS_AT], 'HH:mm')
    )

    payload = Object.assign(
      {
        [FORM.NAME]: voucherDraft[FORM.NAME] || voucher[FORM.NAME],
        [FORM.VOUCHER_TYPE]: voucher[FORM.VOUCHER_TYPE],
        [FORM.CODE]: voucher[FORM.CODE],
      },
      // If the period type is dynamic, the start date and time are not used and the duration number and type are used instead
      isDynamicPeriod
        ? {
            [FORM.PERIOD_TYPE]: PERIOD_TYPE.DYNAMIC_PERIOD,
            [FORM.DURATION_NUMBER]:
              voucherDraft[FORM.DURATION_NUMBER] ??
              voucher.dynamic_period?.[FORM.DURATION_NUMBER] ??
              DEFAULT_DURATION_NUMBER,
            [FORM.DURATION_TYPE]:
              voucherDraft[FORM.DURATION_TYPE] ||
              voucher.dynamic_period?.[FORM.DURATION_TYPE] ||
              DEFAULT_DURATION_TYPE,
          }
        : {
            [FORM.PERIOD_TYPE]: PERIOD_TYPE.PERIOD,
            [FORM.STARTS_AT]: startDateAndTime,
          },
      isNoEnd
        ? {}
        : {
            [FORM.ENDS_AT]: endDateAndTime,
          }
    )

    try {
      setLoader(target, true)
      await API_VOUCHERS.updateVoucher(voucherID, payload)
      await refetch()
      setVoucher(_voucherDraft)
      setErrors({})
      setIsEdited(false)
    } catch (e) {
      setErrors(parseError(e, true))
    } finally {
      setEditField(null)
      setTimeout(() => {
        setLoader(target, false)
      }, 50)
    }
  }

  const cancelEdit = (target) => {
    setEditField(null)
    const isNew = voucherID !== 'new'
    if (isNew) {
      setVoucher(_voucherDraft)
    }
    if (target === 'discount') {
      const hasDiscount = voucher.actions.length > 0
      setDiscount(hasDiscount ? parseAction(voucher.actions[0]) : _discountDraft)
      setErrors({})
    }
  }

  const clearErrorsOnPeriodTypeSwitch = () => {
    setErrors((prev) => ({
      ...prev,
      [FORM.ENDS_AT]: null,
      [FORM.END_TIME]: null,
      [FORM.STARTS_AT]: null,
      [FORM.START_TIME]: null,
    }))
  }

  const editVoucher = (fieldName, value) => {
    if (voucherID !== 'new') {
      setEditField(fieldName)
      setIsEdited(true)
    }
    if (Object.prototype.hasOwnProperty.call(voucherDraft, fieldName)) {
      let result
      result = {
        ...voucherDraft,
        [fieldName]: value,
      }
      setVoucher(result)
      let errorResult = {
        ...errors,
        [fieldName]: null,
      }
      setErrors(errorResult)
      if (fieldName === FORM.PERIOD_TYPE && value !== voucherDraft[FORM.PERIOD_TYPE]) {
        clearErrorsOnPeriodTypeSwitch()
      }
    } else console.log(`[editVoucher] Voucher property name - ${fieldName} does not exist`)
  }

  const deleteVoucher = () => {
    setDialog({
      title: t('vouchers.dialog_DeleteHeader'),
      message: t(
        `vouchers.dialog_Delete${voucher[FORM.VOUCHER_TYPE] === 'unique' ? 'Bulk' : ''}Text`
      ),
      onCancel: () => setDialog(null),
      onConfirm: async () => {
        try {
          setDialog(null)
          setLoader(LOADER.VOUCHER, true)
          await API_VOUCHERS.deleteVoucher(voucherID)
          history.push(`/vouchers/`, { shouldReload: true })
        } catch (e) {
          addToast(parseError(e), { appearance: 'error' })
        } finally {
          setLoader(LOADER.VOUCHER, false)
        }
      },
    })
  }

  const invalidateVoucher = () => {
    setDialog({
      title: t('vouchers.dialog_InvalidateHeader'),
      message: t(`vouchers.dialog_Invalidate${voucher.code_type === 'unique' ? 'Bulk' : ''}Text`),
      onCancel: () => setDialog(null),
      onConfirm: async () => {
        try {
          setDialog(null)
          setLoader(LOADER.VOUCHER, true)
          await API_VOUCHERS.invalidateVoucher(voucherID)
          history.push(`/vouchers/`, { shouldReload: true })
          addToast(t('vouchers.message_InvalidateVoucherSuccess'), { appearance: 'success' })
        } catch (e) {
          addToast(t('vouchers.message_InvalidateVoucherError'), { appearance: 'error' })
        } finally {
          setLoader(LOADER.VOUCHER, false)
        }
      },
    })
  }

  const generateCode = async (isBulk, number = 1) => {
    try {
      setLoader(LOADER.CODES, true)
      const { code } = isBulk
        ? await API_VOUCHERS.generateBulkCode(voucher.id, number)
        : await API_VOUCHERS.generateCode(clubID)
      if (isBulk) await refetch()
      setVoucher({
        ...voucherDraft,
        [FORM.CODE]: code,
      })
      setErrors((prev) => {
        let newErrors = { ...prev }
        if (newErrors?.[FORM.CODE]) {
          delete newErrors[FORM.CODE]
        }
        return newErrors
      })
    } catch (e) {
      addToast(parseError(e), { appearance: 'error' })
    } finally {
      setLoader(LOADER.CODES, false)
    }
  }

  const removeCode = async (codeID) => {
    try {
      setLoader(LOADER.CODE_REMOVE, codeID)
      await API_VOUCHERS.removeCode(codeID)
      await refetch()
      setVoucher({
        ...voucherDraft,
        [FORM.CODE]: '',
      })
    } catch (err) {
      if (err?.status === 400) {
        addToast(t('vouchers.message_VoucherCanNotBeDeleted'), { appearance: 'error' })
        return
      }
      addToast(parseError(err), { appearance: 'error' })
    } finally {
      setLoader(LOADER.CODE_REMOVE, null)
    }
  }

  const copyCode = () => {
    navigator.clipboard.writeText(voucher[FORM.CODE])
    addToast(t('vouchers.message_CopySuccess'), { appearance: 'success' })
  }

  const assignCodeToPlayer = async (playerID, code) => {
    try {
      setLoader(LOADER.CODE_ASSIGN, code)
      await API_VOUCHERS.assignCodeToPlayer(playerID, { code })
      await refetch()
      setVoucher({
        ...voucherDraft,
        [FORM.CODE]: '',
      })
    } catch (e) {
      addToast(parseError(e), { appearance: 'error' })
    } finally {
      setLoader(LOADER.CODE_ASSIGN, null)
    }
  }

  const editDiscount = (target, value) => {
    setEditField('discount')
    if (target === SETTINGS.IS_USES_TYPE) {
      if (discountDraft[SETTINGS.ACTION_ID]) {
        setEditField(null)
        return
      }
      setDiscount({
        ...discountDraft,
        [SETTINGS.IS_USES_TYPE]: true,
        [SETTINGS.IS_VALUE_TYPE]: false,
      })
    }
    if (target === SETTINGS.IS_VALUE_TYPE) {
      if (discountDraft[SETTINGS.ACTION_ID]) {
        setEditField(null)
        return
      }
      setDiscount({
        ...discountDraft,
        [SETTINGS.IS_USES_TYPE]: false,
        [SETTINGS.IS_VALUE_TYPE]: true,
      })
    }
    if (target === SETTINGS.MIN_PRICE) {
      const numberValue = _toNumber(value)
      if (!value) {
        return
      }
      if (numberValue > 10000) {
        setErrors({
          [target]: i18n.t('errors.valueMustBeBetweenThese', {
            this: 0,
            that: numberToLocalFormat(10000),
          }),
        })
      } else if (errors[target]) {
        setErrors({
          [target]: null,
        })
      }
      setDiscount({
        ...discountDraft,
        [target]: _toNumber(value),
      })
    }

    if (target === SETTINGS.PERCENTAGE || target === SETTINGS.USES_VALUE) {
      setDiscount({
        ...discountDraft,
        [target]: _toNumber(value),
      })
    }
    if (
      target === SETTINGS.APPLY_GOLF_CART ||
      target === SETTINGS.APPLY_HOLDER ||
      target === SETTINGS.APPLY_GUEST
    ) {
      setErrors({})
      setDiscount({
        ...discountDraft,
        [target]: !value,
      })
    }
  }

  const saveDiscount = async () => {
    if (!isValid('percentage', discountDraft[SETTINGS.PERCENTAGE])) {
      setErrors({ [SETTINGS.PERCENTAGE]: t('sentences.invalidDiscountPercentageValue') })
      return
    }

    if (
      !discountDraft[SETTINGS.APPLY_GOLF_CART] &&
      !discountDraft[SETTINGS.APPLY_HOLDER] &&
      !discountDraft[SETTINGS.APPLY_GUEST]
    ) {
      setErrors({ [SETTINGS.APPLY_GOLF_CART]: t('vouchers.message_DiscountTargetEmpty') })
      return
    }
    try {
      setErrors({})
      setLoader(LOADER.DISCOUNT, true)
      if (!discountDraft[SETTINGS.ACTION_ID]) {
        await createNewAction(voucherID, parseSettings(discountDraft))
      } else {
        await updateAction(discountDraft[SETTINGS.ACTION_ID], parseSettings(discountDraft))
      }
      await refetch()
      setEditField(null)
      addToast(t('vouchers.message_DiscountSuccess'), { appearance: 'success' })
    } catch (e) {
      addToast(t('vouchers.message_DiscountError'), { appearance: 'error' })
    } finally {
      setLoader(LOADER.DISCOUNT, false)
    }
  }

  return {
    voucher:
      voucherID === 'new'
        ? voucherDraft
        : Boolean(voucher) &&
          Object.assign(
            {},
            voucher,
            {
              [FORM.NAME]:
                voucherDraft[FORM.NAME] !== null ? voucherDraft[FORM.NAME] : voucher[FORM.NAME],
              [FORM.PERIOD_TYPE]:
                voucherDraft[FORM.PERIOD_TYPE] || voucher?.dynamic_period?.[FORM.PERIOD_TYPE],
              [FORM.ENDS_AT]:
                voucherDraft[FORM.ENDS_AT] || DATE.local(voucher[FORM.ENDS_AT], 'YYYY-MM-DD'),
              [FORM.END_TIME]:
                voucherDraft[FORM.END_TIME] || DATE.local(voucher[FORM.ENDS_AT], 'HH:mm'),
              [FORM.NO_END]:
                voucherDraft[FORM.NO_END] === undefined
                  ? voucher[FORM.ENDS_AT] === null
                  : voucherDraft[FORM.NO_END],
            },
            (
              voucherDraft[FORM.PERIOD_TYPE] !== null
                ? voucherDraft[FORM.PERIOD_TYPE] === PERIOD_TYPE.DYNAMIC_PERIOD
                : voucher?.dynamic_period?.[FORM.PERIOD_TYPE] === PERIOD_TYPE.DYNAMIC_PERIOD
            )
              ? {
                  [FORM.DURATION_NUMBER]:
                    voucherDraft[FORM.DURATION_NUMBER] !== null
                      ? voucherDraft[FORM.DURATION_NUMBER]
                      : voucher?.dynamic_period?.[FORM.DURATION_NUMBER],
                  [FORM.DURATION_TYPE]:
                    voucherDraft[FORM.DURATION_TYPE] ||
                    voucher?.dynamic_period?.[FORM.DURATION_TYPE],
                }
              : {
                  [FORM.STARTS_AT]:
                    voucherDraft[FORM.STARTS_AT] ||
                    DATE.local(voucher[FORM.STARTS_AT], 'YYYY-MM-DD'),
                  [FORM.START_TIME]:
                    voucherDraft[FORM.START_TIME] || DATE.local(voucher[FORM.STARTS_AT], 'HH:mm'),
                }
          ),
    discount: discountDraft,
    errors: {
      ...errors,
      global: queryError ? parseError(queryError) : null,
    },
    editField: editField,
    loaders: {
      ...loaders,
      [LOADER.VOUCHER]:
        (editField ||
        loaders[LOADER.CODE_ASSIGN] ||
        loaders[LOADER.CODE_REMOVE] ||
        loaders[LOADER.CODES] ||
        loaders[LOADER.DISCOUNT]
          ? false
          : isFetching) || loaders[LOADER.VOUCHER],
      [LOADER.COUPONS]: isFetchingCoupons,
    },
    dialog: dialog,
    coupons: {
      refetchCoupons,
      coupons,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
    },
    voucherActions: {
      refetch,
      createVoucher,
      startEdit,
      confirmEdit,
      cancelEdit,
      editVoucher,
      deleteVoucher,
      invalidateVoucher,
      generateCode,
      removeCode,
      copyCode,
      assignCodeToPlayer,
      editDiscount,
      saveDiscount,
    },
  }
}

export default useVoucher
