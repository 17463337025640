import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import style from '../../style.module.scss'

const FormButtons = ({ onCancel, onFormSubmit, createDisabled }) => {
  const { t } = useTranslation()
  return (
    <div className={style.teeTimePeriod}>
      {t('editPeriodsNew.createTeeTimePeriod')}
      <div className={style.buttons}>
        <button onClick={onCancel} className="system-button primary-outline md-36">
          {t('editPeriodsNew.cancel')}
        </button>

        <button
          className={cx(
            'system-button primary md-36',
            createDisabled ? style.createBtnDisabled : style.createBtnEnabled
          )}
          onClick={onFormSubmit}
          disabled={createDisabled}
        >
          {t('editPeriodsNew.create')}
        </button>
      </div>
    </div>
  )
}

export default FormButtons
