import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'
import _ from 'lodash'

import Select from 'react-select'
import Container from '@sweetspot/club-portal-legacy/components/Container'
import GolfCourseSelect from '@sweetspot/club-portal-legacy/components/GolfCourseSelect'

import { selectGolfCourse } from '@sweetspot/club-portal-legacy/store/actions'
import { getLang } from '@sweetspot/club-portal-legacy/languages'
import { playerTypes } from '@sweetspot/club-portal-legacy/constants/config'

import style from '../style.module.scss'

class Statistics extends Component {
  state = {
    iframeUrl: '',
    selectedOption: playerTypes[4],
  }

  componentDidMount() {
    this.getIFrameUrl()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.golfCourses, this.props.golfCourses)) {
      this.getIFrameUrl()
    }
  }

  Translations = {
    placeholder: getLang(this.props.lang, 'selectPlaceholder'),
  }

  getIFrameUrl = () => {
    const { selectedOption } = this.state

    const {
      golfCourses: { selectedId },
    } = this.props
    const { REACT_APP_METABASE_SITE_URL, REACT_APP_METABASE_SECRET_KEY } = process.env

    const payload = {
      resource: { dashboard: 99 },
      params: {
        course: selectedId,
        player_type: selectedOption.value,
      },
      exp: Math.round(Date.now() / 1000) + 600,
    }

    const token = jwt.sign(payload, REACT_APP_METABASE_SECRET_KEY)

    const iframeUrl = `${REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`

    this.setState({ iframeUrl })
  }

  handleChange = (value) => {
    this.setState(
      {
        selectedOption: value,
      },
      this.getIFrameUrl
    )
  }

  renderGolfCourseSelect() {
    const {
      golfCourses: { list: courseList },
      selectGolfCourse,
      currentCourse,
    } = this.props

    return (
      <GolfCourseSelect
        courses={courseList}
        selectedCourse={currentCourse}
        onSelectCourse={selectGolfCourse}
      />
    )
  }

  render() {
    const { iframeUrl, selectedOption } = this.state
    const { lang } = this.props

    return (
      <Container>
        <div className={style.container}>
          <div className={style.header}>
            {this.renderGolfCourseSelect()}
            <div className={style.selectContainer}>
              <div className={style.select}>
                <Select
                  placeholder={getLang(lang)['dashboard']['playerType']}
                  options={playerTypes}
                  value={selectedOption}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className={style.content}>
            <iframe title="dashboard" src={iframeUrl} frameBorder="0" width="100%" height="100%" />
          </div>
        </div>
      </Container>
    )
  }
}

Statistics.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfCourses: PropTypes.object.isRequired,
  selectGolfCourse: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfCourses: state.golfCourse,
    currentCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectGolfCourse: (id) => dispatch(selectGolfCourse(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics)
