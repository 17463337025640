import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import CopyLinkIcon from '@sweetspot/club-portal-legacy/resources/images/copy_link_icon.svg'

import { getShareLink } from './utils'
import { Venue, TeeTime } from '@sweetspot/shared/types'
import { FilteredBooking, SheetHeaderSearch } from '@sweetspot/club-portal/feature/venues'

interface SearchBookingsProps {
  lang: string
  teeTimes: TeeTime[]
  currentDate: Date
  selectedGolfCourse: Venue
  handleSearchResultClick: (teeTime: TeeTime) => void
}

export const SearchBookings = ({
  lang,
  teeTimes,
  currentDate,
  selectedGolfCourse,
  handleSearchResultClick,
}: SearchBookingsProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [shareLink, setShareLink] = useState<string>('')

  const hasCdhNumber = selectedGolfCourse?.cdh_id

  useEffect(() => {
    if (searchValue) setSearchValue('')
    if (selectedGolfCourse) {
      const assignShareLink = async () => {
        const link = await getShareLink(selectedGolfCourse, currentDate, lang)
        if (link) {
          const baseUrl = window.location.origin
          setShareLink(`${baseUrl}/tee-sheet-links/${link}`)
        }
      }

      assignShareLink()
    }
  }, [selectedGolfCourse?.uuid, currentDate])

  const handleTableRowClick = (booking: FilteredBooking) => {
    if (hasCdhNumber && !booking?.cdhId) return
    if (!booking || !booking.startTime || !booking?.courseUuid) return

    const timestamp = moment.utc(booking.startTime).unix()
    const teeTime = teeTimes.find((time) => moment.utc(time.from).unix() === timestamp)

    if (!teeTime) return
    handleSearchResultClick(teeTime)
  }

  const handleCopy = () => {
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 5000)
  }

  return (
    <div className="flex items-center">
      <SheetHeaderSearch
        onOptionSelect={handleTableRowClick}
        course={selectedGolfCourse}
        currentDate={currentDate}
      />
      <div
        className="ml-[25px] cursor-pointer"
        onClick={() => track(AMPLITUDE_EVENTS.COPY_LINK_TO_TEE_SHEET_TAPPED)}
      >
        <CopyToClipboard text={shareLink} onCopy={handleCopy}>
          <div className="w-[20px]">
            <img
              data-tip
              data-tooltip-id="clipboard_copy"
              alt="Clipboard-icon"
              src={CopyLinkIcon}
            />
          </div>
        </CopyToClipboard>
        <Tooltip className="z-[100]" id="clipboard_copy" place="right" effect="solid">
          <Text textId={isCopied ? 'teeSheet.copied' : 'teeSheet.copyLink'} />
        </Tooltip>
      </div>
    </div>
  )
}
