import StringHelpers from '@sweetspot/club-portal-legacy/helpers/StringHelpers'
import {
  formatDateInTimeZone,
  formatEndOfDayInTimeZone,
  isDateBeforeOtherDate,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { addMonths, format } from 'date-fns'

export const validateForm = (state, t) => {
  const errors = validateDraft(state, t)
  return errors
}

export const validateDraft = (state, selectedCourse, t) => {
  const errors = {}
  const nameError = validateName(state.name, t)
  if (nameError) {
    errors.name = nameError
  }

  const startDateError = validateStartDate(
    state.start_date,
    state.end_date,
    t,
    selectedCourse.timezone
  )
  if (startDateError) {
    errors.start_date = startDateError
  }

  const endDateError = validateEndDate(state.start_date, state.end_date, t)
  if (endDateError) {
    errors.end_date = endDateError
  }

  const { start_time_from, start_time_to } = state

  if (!start_time_from) {
    errors.start_time_from = t('editPeriodsNew.requiredField')
  }
  if (!start_time_to) {
    errors.start_time_to = t('editPeriodsNew.requiredField')
  }

  if (start_time_to < start_time_from) {
    errors.start_time_to = t('editPeriodsNew.errorTime2')
  }

  if (!state.interval) {
    errors.interval = t('editPeriodsNew.requiredField')
  }
  if (!state.category_id) {
    errors.category_id = t('editPeriodsNew.requiredField')
  }
  if (selectedCourse?.type === 'course' && !state.slots) {
    errors.slots = t('editPeriodsNew.requiredField')
  }
  if (Object.keys(errors).length) {
    return errors
  }
}

export const validateName = (name, t) => {
  const string_length = name ? name.length : 0
  if (!name) {
    return t('editPeriodsNew.requiredField')
  }

  if (string_length < 2) {
    return t('editPeriodsNew.errorName1')
  }
  if (string_length > 25) {
    return t('editPeriodsNew.errorName2')
  }

  if (!name.trim()) {
    return t('editPeriodsNew.nameNotEmpty')
  }

  if (StringHelpers.includeEmoji(name)) {
    return t('editPeriodsNew.nameNoEmojis')
  }

  return null
}

const validateStartDate = (startDate, endDate, t, timezone) => {
  if (!startDate) {
    return t('editPeriodsNew.requiredField')
  }

  const currentDate = formatDateInTimeZone(format(new Date(), 'yyyy-MM-dd'), timezone)
  const startDateInCourseTimezone = formatDateInTimeZone(startDate, timezone)
  const endDateInCourseTimezone = formatEndOfDayInTimeZone(endDate, timezone)
  const oneMonthFromNow = addMonths(new Date(startDateInCourseTimezone), 1)

  if (isDateBeforeOtherDate(new Date(startDateInCourseTimezone), currentDate)) {
    return t('editPeriodsNew.errorDate1')
  }

  if (isDateBeforeOtherDate(oneMonthFromNow, new Date(endDateInCourseTimezone))) {
    return t('editPeriodsNew.errorDate2')
  }

  return null
}

const validateEndDate = (startDate, endDate, t) => {
  if (!endDate) {
    return t('editPeriodsNew.requiredField')
  }

  if (new Date(endDate).getTime() < new Date().getTime()) {
    return t('editPeriodsNew.errorDate3')
  }

  if (new Date(endDate).getTime() < new Date(startDate).getTime()) {
    return t('editPeriodsNew.errorDate4')
  }

  return null
}
