import React, { useState, useRef, useEffect, useMemo } from 'react'
import m from 'moment'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

import FeedbackContainer from '../Partials/FeedbackContainer'
import InputPrefixSuffix from '../Partials/InputPrefixSuffix'
import InputInlinePrefixSuffix from '../Partials/InputInlinePrefixSuffix'
import InputField from '../Partials/InputField'
import TextArea from '../Partials/TextArea'
import Label from '../Partials/Label'
import SuggestionBox from '../Partials/SuggestionBox'
// Needs checking!
import MiniCalendar from '@sweetspot/sweetspot-js/common/components/MiniCalendar'
import ActionsContainer from '../Partials/ActionsContainer'
import CalendarBox from '@sweetspot/sweetspot-js/common/components/CalendarBox'

const TextInputField = ({
  onInputBlur,
  onInputFocus,
  onChange,
  onSuggestionClick,
  onEnter,
  disabled,
  label,
  labelTwo,
  lightMainLabelColor,
  placeholder,
  type,
  value,
  error,
  feedbackPosition,
  feedbackAnchorPosition,
  feedbackStyle,
  success,
  containerWidth,
  inputWidth,
  inputProps,
  infoTextKey,
  suffix,
  prefix,
  inlineSuffix,
  inlinePrefix,
  suggestionItems,
  suggestionItemsLabelKey,
  suggestionItemsIdentifierKey,
  suggestionItemsItemKey,
  noSuggestionsAvailableLabel,
  onSuffixClick,
  maskOptions,
  containerClassName,
  inputClassName,
  calendarSettings,
  rows,
  theme,
  colorTheme,
  showActionButtons,
  confirmText,
  showConfirmButton,
  cancelText,
  showCancelButton,
  onConfirm,
  onCancel,
  confirmLoading,
  noMargin,
  reducePaddingRight,
  confirmDisabled,
  onOutsideClick,
  CalendarComponent,
  innerRef,
  step,
  lessDarker,
  calendarPrefix,
}) => {
  const { t } = useTranslation()

  const [inputFocused, setInputFocused] = useState(false)
  const [suggestionsVisible, setSuggestionsVisible] = useState(false)
  const [calendarVisible, setCalendarVisible] = useState(false)

  const maskSetting = useMemo(() => {
    if (maskOptions) return maskOptions
    if (type === 'calendar')
      return {
        guide: false,
        mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-1]/, /[0-9]/, '-', /[0-3]/, /[0-9]/],
      }
    if (type === 'time') {
      let hours = /[0-9]/
      if (value?.length) {
        const chars = value.split('')
        hours = chars[0] === '2' ? /[0-3]/ : /[0-9]/
      }
      return {
        mask: [/[0-2]/, hours, ':', /[0-5]/, /[0-9]/],
      }
    }
    return null
  }, [maskOptions, type, value])

  let calendarRef = useRef(null)
  let containerRef = useRef(null)

  const handleClickOutside = (e) => {
    if (
      onOutsideClick &&
      containerRef &&
      containerRef.current &&
      !containerRef.current.contains(e.target) &&
      e.target.type !== 'button'
    ) {
      onOutsideClick()
    }
  }

  useEffect(() => {
    if (type === 'calendar' || onOutsideClick) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [type, onOutsideClick])

  const onBlur = (e, blurViaEnter) => {
    setInputFocused(false)
    if (suggestionsVisible) {
      setTimeout(() => {
        setSuggestionsVisible(false)
      }, 400)
    }
    if (onInputBlur && !blurViaEnter) onInputBlur(e?.target?.value)
  }

  const onFocus = (e) => {
    setInputFocused(true)
    setSuggestionsVisible(true)
    setCalendarVisible(true)
    if (onInputFocus) onInputFocus(e)
  }

  const valueChanged = (value) => {
    if (disabled) return
    if (onChange) onChange(value)
  }

  const handleOnSuggestionClick = (value) => {
    if (onSuggestionClick) onSuggestionClick(value)
    setSuggestionsVisible(false)
  }

  const handleCalendarDateSelect = (date, dateString) => {
    if (calendarSettings && calendarSettings.onDateChange) {
      calendarSettings.onDateChange(dateString)
    } else {
      valueChanged(dateString)
    }
    setCalendarVisible(false)
  }

  const getContainerStyles = () => {
    if (
      typeof containerWidth &&
      (containerWidth.includes('auto') ||
        containerWidth.includes('px') ||
        containerWidth.includes('%') ||
        containerWidth.includes('rem'))
    ) {
      return {
        width: containerWidth,
      }
    }
    return {}
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          'w-full': containerWidth === 'full',
          'w-1/2': containerWidth === 'half',
          'w-1/3': containerWidth === 'third',
          'w-1/4': containerWidth === 'quarter',
        },
        {
          [styles.noMargin]: noMargin === true,
        },
        containerClassName
      )}
      style={getContainerStyles()}
      ref={containerRef}
    >
      {/* Main label */}
      <Label label={label} infoTextKey={infoTextKey} lightColor={lightMainLabelColor} />

      {/* Second label */}
      <Label label={labelTwo} infoTextKey={!label && infoTextKey ? infoTextKey : null} secondary />

      {/* Input Container */}
      <div
        className={cx(
          styles.inputContainer,
          {
            'w-full': inputWidth === 'full',
            'w-1/2': inputWidth === 'half',
            'w-1/4': inputWidth === 'quarter',
            [styles.loginColorTheme]: colorTheme === 'login',
          },
          inputClassName
        )}
        ref={calendarRef}
      >
        {/* Input */}
        {type === 'text-area' ? (
          <TextArea
            innerRef={innerRef}
            disabled={disabled}
            onChange={(e) => valueChanged(e.target.value)}
            placeholder={placeholder}
            value={value}
            error={error}
            success={success}
            onEnterPress={onEnter}
            {...{ ...inputProps, onFocus: onFocus, onBlur: onBlur, rows }}
          />
        ) : (
          <React.Fragment>
            {prefix ? (
              <InputPrefixSuffix
                type="prefix"
                error={!!error}
                success={!!success}
                value={typeof value !== 'undefined' && value !== '' && value !== null}
                focused={inputFocused}
                theme={theme}
                disabled={disabled}
                calendarPrefix={calendarPrefix}
              >
                {prefix}
              </InputPrefixSuffix>
            ) : null}
            {inlinePrefix ? (
              <InputInlinePrefixSuffix
                type="prefix"
                error={!!error}
                success={!!success}
                value={typeof value !== 'undefined' && value !== '' && value !== null}
                focused={inputFocused}
                disabled={disabled}
                hasPrefix={!!prefix}
                theme={theme}
              >
                {inlinePrefix}
              </InputInlinePrefixSuffix>
            ) : null}
            <InputField
              innerRef={innerRef}
              colorTheme={colorTheme}
              theme={theme}
              step={step}
              disabled={disabled}
              onChange={(e) => valueChanged(e.target.value)}
              type={type === 'calendar' || type === 'time' ? 'text' : type}
              placeholder={placeholder}
              value={typeof value === 'number' ? value + '' : value}
              error={error}
              success={success}
              hasPrefix={!!prefix || !!inlinePrefix}
              hasSuffix={!!suffix || !!inlineSuffix}
              maskOptions={maskSetting}
              onEnterPress={onEnter}
              lessDarker={lessDarker}
              onTabPress={() => setCalendarVisible(false)}
              reducePaddingRight={reducePaddingRight}
              calendarPrefix={calendarPrefix}
              {...{ ...inputProps, onFocus: onFocus, onBlur: onBlur }}
            />
            {inlineSuffix ? (
              <InputInlinePrefixSuffix
                type="suffix"
                error={!!error}
                success={!!success}
                value={typeof value !== 'undefined' && value !== '' && value !== null}
                focused={inputFocused}
                disabled={disabled}
                hasSuffix={!!suffix}
              >
                {inlineSuffix}
              </InputInlinePrefixSuffix>
            ) : null}
            {suffix ? (
              <InputPrefixSuffix
                type="suffix"
                error={!!error}
                success={!!success}
                value={typeof value !== 'undefined' && value !== '' && value !== null}
                focused={inputFocused}
                disabled={disabled}
                theme={theme}
                onSuffixClick={onSuffixClick}
              >
                {suffix}
              </InputPrefixSuffix>
            ) : null}
          </React.Fragment>
        )}

        <SuggestionBox
          onItemClick={handleOnSuggestionClick}
          items={suggestionItems}
          labelKey={suggestionItemsLabelKey}
          idKey={suggestionItemsIdentifierKey}
          visible={suggestionsVisible}
          itemKey={suggestionItemsItemKey}
          noAvailableLabel={noSuggestionsAvailableLabel}
        />
        {type === 'calendar' && calendarVisible && (
          <CalendarBox
            visible={calendarVisible}
            containerRef={calendarRef}
            onClosePreview={() => setCalendarVisible(false)}
            CalendarComponent={{
              component: CalendarComponent ?? MiniCalendar,
              props: {
                initialDate:
                  calendarSettings &&
                  calendarSettings.selectedDate &&
                  m(calendarSettings.selectedDate).isValid()
                    ? new Date(calendarSettings.selectedDate)
                    : new Date(),
                returnDateFormat:
                  calendarSettings && calendarSettings.returnDateFormat
                    ? calendarSettings.returnDateFormat
                    : 'YYYY-MM-DD',
                startDate: calendarSettings?.startDate || null,
                endDate: calendarSettings?.endDate || null,
                onDateChange: handleCalendarDateSelect,
              },
            }}
          />
        )}
      </div>

      {/* Feedback container */}
      <FeedbackContainer
        error={error}
        success={success}
        position={feedbackPosition}
        anchorPosition={feedbackAnchorPosition}
        styles={feedbackStyle}
      />

      {/* Action buttons */}
      <ActionsContainer
        visible={showActionButtons && !disabled}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmText={t(confirmText)}
        showConfirmButton={showConfirmButton}
        cancelText={t(cancelText)}
        showCancelButton={showCancelButton}
        confirmLoading={confirmLoading}
        confirmDisabled={confirmDisabled}
      />
    </div>
  )
}

TextInputField.propTypes = {
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Error message to show for this field */
  error: PropTypes.string,

  /** Success message to show for this field */
  success: PropTypes.string,

  /** Position of feedback container */
  feedbackPosition: PropTypes.oneOf(['top', 'bottom', 'default']),
  feedbackAnchorPosition: PropTypes.oneOf(['left', 'right']),
  feedbackStyle: PropTypes.object,

  /** Is input disabled or not */
  disabled: PropTypes.bool,

  /** Called when input field is updated */
  onChange: PropTypes.func,

  /** Main label for field */
  label: PropTypes.string,

  /** Extra smaller label for field */
  labelTwo: PropTypes.string,

  /** Is main label in ligth color */
  lightMainLabelColor: PropTypes.bool,

  /** Placeholder text in input field */
  placeholder: PropTypes.string,

  /** Type of input */
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'calendar', 'text-area', 'time']),

  /** If type text-area */
  rows: PropTypes.number,

  /** Any custom class-names for component container  */
  containerClassName: PropTypes.string,

  /** Width of component container: full | half | quarter */
  containerWidth: PropTypes.string,

  /** Width of input field relative to container width: full | half | quarter */
  inputWidth: PropTypes.string,

  /** Text ID key for info text icon on hover */
  infoTextKey: PropTypes.string,

  /** Props to pass down to input element */
  inputProps: PropTypes.object,

  /** Triggered when input is focused */
  onInputFocus: PropTypes.func,

  /** Triggered when input is blurred */
  onInputBlur: PropTypes.func,

  /** Suffix for input field */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),

  /** Prefix for input field */
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),

  /** Prefix and suffix inline (without background and border) */
  inlineSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  inlinePrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),

  /** Suggestion box options */
  suggestionItems: PropTypes.array,
  suggestionItemsLabelKey: PropTypes.string,
  suggestionItemsIdentifierKey: PropTypes.string,
  onSuggestionClick: PropTypes.func,
  suggestionItemsItemKey: PropTypes.string,
  noSuggestionsAvailableLabel: PropTypes.string,
  onSuffixClick: PropTypes.func,
  maskOptions: PropTypes.shape({
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    guide: PropTypes.bool,
    placeholderChar: PropTypes.string,
    keepCharPositions: PropTypes.bool,
    showMask: PropTypes.bool,
  }),
  calendarSettings: PropTypes.shape({
    returnDateFormat: PropTypes.string,
    selectedDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.object,
    startDate: PropTypes.object,
    onDateChange: PropTypes.func,
  }),

  onEnter: PropTypes.func,
  theme: PropTypes.oneOf(['webApp', 'club', 'pp', 'voucherA', 'voucherB', 'login']),
  colorTheme: PropTypes.oneOf(['default', 'white-outline', 'login']),

  // Action buttons eg. cancel or save/confirm
  showActionButtons: PropTypes.bool,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  showConfirmButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onOutsideClick: PropTypes.func,
  confirmLoading: PropTypes.bool,
  noMargin: PropTypes.bool,
  reducePaddingRight: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  CalendarComponent: PropTypes.object,
  lessDarker: PropTypes.bool,
  calendarPrefix: PropTypes.bool,
  inputClassName: PropTypes.string,
  innerRef: PropTypes.any,
  step: PropTypes.number,
}

TextInputField.defaultProps = {
  theme: 'club',
  error: '',
  success: '',
  feedbackPosition: 'bottom',
  feedbackAnchorPosition: 'left',
  disabled: false,
  onChange: () => {},
  label: '',
  labelTwo: '',
  lightMainLabelColor: false,
  placeholder: '',
  type: 'text',
  containerClassName: '',
  inputClassName: '',
  containerWidth: 'full',
  inputWidth: 'full',
  onInputFocus: () => {},
  onInputBlur: () => {},
  infoTextKey: '',
  inputProps: {},
  suffix: null,
  prefix: null,
  inlineSuffix: null,
  inlinePrefix: null,
  suggestionItems: undefined,
  suggestionItemsLabelKey: 'name',
  suggestionItemsIdentifierKey: 'id',
  suggestionItemsItemKey: 'id',
  onSuggestionClick: () => {},
  noSuggestionsAvailableLabel: undefined,
  onSuffixClick: () => {},
  maskOptions: null,
  calendarSettings: null,
  onEnter: undefined,
  colorTheme: 'default',
  showActionButtons: false,
  confirmText: 'words.save',
  cancelText: 'words.cancel',
  onConfirm: () => {},
  onCancel: () => {},
  onOutsideClick: null,
  showConfirmButton: true,
  showCancelButton: true,
  confirmLoading: false,
  noMargin: false,
  reducePaddingRight: false,
  CalendarComponent: null,
  lessDarker: false,
  calendarPrefix: false,
  innerRef: null,
  step: null,
}

export default TextInputField
