import { useMemo } from 'react'

import { APP_NAMES } from '@sweetspot/shared/util/constants'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'

const useIsPartnerPortal = () => {
  const isPartnerPortal = useMemo(() => getCurrentAppName() === APP_NAMES.PARTNER_PORTAL, [])
  return isPartnerPortal
}

export default useIsPartnerPortal
