import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

export const refundMembershipCard = (id: number): Promise<unknown> => {
  const URL = `${getApiPlatformBaseUrl()}/membership-cards/${id}/refunds`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const cancelFutureMembershipCardPayments = (id: number): Promise<unknown> => {
  const URL = `${getApiPlatformBaseUrl()}/membership-cards/${id}/future-payments/cancel`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const membershipCardPayments = (uuid: string | undefined): Promise<unknown> => {
  const URL = `${getApiPlatformBaseUrl()}/players/membership-cards/${uuid}/payments`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
