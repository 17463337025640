import React from 'react'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

const GolfCartsIncluded = () => {
  const { t } = useTranslation()

  return (
    <TextInputField
      inputWidth="quarter"
      value=""
      label={t('wizard.includedGolfCarts')}
      disabled
      placeholder={t('wizard.disabled')}
    />
  )
}

export default GolfCartsIncluded
