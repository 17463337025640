import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

const headers = {
  name: {
    name: '.name',
    type: 'label',
    valueType: 'string',
    width: '250px',
    selected: true,
    isSortable: true,
  },
  golfId: {
    name: '.golfId',
    type: 'label',
    valueType: 'string',
    width: '150px',
    selected: true,
    isSortable: true,
  },
  phone: {
    name: '.phone',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
    isSortable: true,
  },
  email: {
    name: '.email',
    type: 'label',
    valueType: 'string',
    width: '220px',
    selected: true,
    isSortable: true,
  },
  hcp: {
    name: '.hcp',
    type: 'label',
    valueType: 'string',
    display: (hcp) => (hcp ? Utilities.displayHCP(parseFloat(hcp)) : '-'),
    width: '100px',
    selected: true,
    isSortable: true,
  },
  homeClub: {
    name: '.homeClub',
    type: 'label',
    valueType: 'string',
    width: '220px',
    selected: true,
    isSortable: true,
  },
  activeMembership: {
    name: '.activeMembership',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  canBook: {
    name: '.canBook',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  rounds: {
    name: '.numberOfRounds',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  playValue: {
    name: '.playValue',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  fireBaseAuth: {
    name: '.fireBaseAuth',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  gitLogged: {
    name: '.gitLogged',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  gitApiCreated: {
    name: '.gitApiCreated',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
}

export default headers
