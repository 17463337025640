import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getPathFromCourses } from '@sweetspot/club-portal-legacy/utils/golfClub'

export const LandingPage = () => {
  const courseList = useSelector((state) => state?.golfCourse?.list)
  const history = useHistory()

  useEffect(() => {
    if (!courseList || courseList.length === 0) {
      history.push('/courses-venue')
    }

    const path = getPathFromCourses(courseList)
    history.push(path)
  }, [courseList])
  return null
}
