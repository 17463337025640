import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faLayerGroup } from '@fortawesome/pro-regular-svg-icons'
import {
  Button,
  Divider,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { DeviceType, useDeviceType } from '@sweetspot/shared/util/hooks'
import { RangeFilterType, RoofFilter, StanceFilter, TrackingTech } from '@sweetspot/shared/types'

import { useRangeFilter } from '../../hooks'
import FilterOption from './components/FilterOption'
import { BayTechLogo } from '../'
import {
  faArrowRightArrowLeft,
  faArrowLeft,
  faArrowRight,
  faSunBright,
  faPersonShelter,
} from '@fortawesome/pro-regular-svg-icons'

export const RangeFilter = () => {
  const { t } = useTranslation()
  const deviceType = useDeviceType()
  const {
    isFiltersModalOpen,
    setIsFiltersModalOpen,
    filters,
    setFilters,
    resetFilters,
    floors,
    roofs,
    stances,
    trackingTechnologies,
  } = useRangeFilter()

  const [tempFilters, setTempFilters] = useState(filters)

  useEffect(() => {
    setTempFilters(filters)
  }, [filters, isFiltersModalOpen])

  const handleApplyFilters = () => {
    setFilters(tempFilters)
    setIsFiltersModalOpen(false)
  }

  const handleClearFilters = () => {
    resetFilters()
    setIsFiltersModalOpen(false)
  }

  const handleCheckedChange = (
    checked: boolean,
    filter:
      | keyof typeof tempFilters.technologies
      | keyof typeof tempFilters.floors
      | keyof typeof tempFilters.roofs
      | keyof typeof tempFilters.stances,
    type: RangeFilterType
  ) => {
    setTempFilters({
      ...tempFilters,
      [type]: { ...tempFilters[type], [filter]: checked },
    })
  }

  const filterTechnologiesOrFloors = useCallback(
    (filterGroup: { [key: string | number]: boolean }) =>
      Object.values(filterGroup).some((filter) => !!filter),
    []
  )

  const hasTempFilters = useMemo(
    () => Object.values(tempFilters || {}).some(filterTechnologiesOrFloors),
    [tempFilters, filterTechnologiesOrFloors]
  )

  const sheetSide = useMemo(
    () => (deviceType === DeviceType.LAPTOP ? 'right' : 'bottom'),
    [deviceType]
  )

  const hideFloorsFilter = useMemo(() => !floors?.some((floor) => floor !== 0), [floors])
  const hideRoofsFilter = useMemo(
    () => !roofs?.some((roof) => roof !== RoofFilter.WithoutRoof),
    [roofs]
  )
  return (
    <Sheet open={isFiltersModalOpen}>
      <SheetContent
        className="h-[95%] gap-0 rounded-t-xl lg:h-auto lg:rounded-none"
        side={sheetSide}
        onInteractOutside={() => setIsFiltersModalOpen(false)}
      >
        <SheetHeader className="mb-4">
          <SheetHeaderLeftIcon onClick={() => setIsFiltersModalOpen(false)}>
            <FontAwesomeIcon icon={faClose} className="w-3" />
          </SheetHeaderLeftIcon>
          <SheetTitle className="text-content-lg">{t('words.filter_one')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent>
          {trackingTechnologies?.map((technology) => (
            <FilterOption
              key={technology}
              icon={
                <BayTechLogo
                  technology={technology as TrackingTech}
                  className="h-5 w-5"
                  noneClassName="text-content-xl block w-5'"
                />
              }
              label={t(`bays.${technology}`)}
              disabled={trackingTechnologies.length === 1}
              checked={tempFilters.technologies[technology]}
              onCheckedChange={(checked: boolean) =>
                handleCheckedChange(checked, technology, RangeFilterType.TECHNOLOGIES)
              }
            />
          ))}

          <Divider className="mb-2" />
          {stances?.map((stance) => (
            <FilterOption
              key={stance}
              icon={
                <FontAwesomeIcon
                  icon={
                    stance === StanceFilter.RightAndLeftHanded
                      ? faArrowRightArrowLeft
                      : stance === StanceFilter.LeftHanded
                      ? faArrowLeft
                      : faArrowRight
                  }
                  className="h-5 w-5"
                />
              }
              label={t(stance)}
              disabled={stances.length === 1}
              checked={tempFilters[RangeFilterType.STANCES][stance]}
              onCheckedChange={(checked: boolean) =>
                handleCheckedChange(checked, stance, RangeFilterType.STANCES)
              }
            />
          ))}

          {!hideRoofsFilter && (
            <>
              <Divider className="mb-2" />
              {roofs?.sort().map((roof) => (
                <FilterOption
                  key={roof}
                  icon={
                    <FontAwesomeIcon
                      icon={roof === RoofFilter.WithRoof ? faPersonShelter : faSunBright}
                      className="h-5 w-5"
                    />
                  }
                  label={t(roof)}
                  disabled={roofs.length === 1}
                  checked={tempFilters.roofs[roof]}
                  onCheckedChange={(checked: boolean) =>
                    handleCheckedChange(checked, roof, RangeFilterType.ROOFS)
                  }
                />
              ))}
            </>
          )}

          {!hideFloorsFilter && (
            <>
              <Divider className="mb-2" />
              {floors?.sort().map((floor) => (
                <FilterOption
                  key={floor}
                  icon={<FontAwesomeIcon icon={faLayerGroup} className="h-5 w-5" />}
                  label={`${t('words.floor')} ${floor}`}
                  disabled={floors.length === 1}
                  checked={tempFilters.floors[floor]}
                  onCheckedChange={(checked: boolean) =>
                    handleCheckedChange(checked, floor, RangeFilterType.FLOORS)
                  }
                />
              ))}
            </>
          )}
        </SheetCustomContent>
        <SheetFooter className="!flex-col gap-y-1 !space-x-0">
          <Button
            variant="primary"
            size="large"
            onClick={handleApplyFilters}
            disabled={!hasTempFilters}
          >
            {t('words.apply')}
          </Button>
          <Button
            variant="clear-dark"
            size="large"
            onClick={handleClearFilters}
            disabled={!hasTempFilters}
          >
            {t('sentences.clearAll')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
