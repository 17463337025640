import { usePaymentLinkCreate } from './usePaymentLinkCreate'

export const usePaymentLinksCreate = () => {
  const paymentLink = usePaymentLinkCreate()
  const partialPaymentLink = usePaymentLinkCreate()

  const createPaymentLink = paymentLink.create

  const createPartialPaymentLink = partialPaymentLink.create

  const isPaymentLoading = paymentLink.isLoading || partialPaymentLink.isLoading
  const isPaymentSuccess = paymentLink.isSuccess || partialPaymentLink.isSuccess

  const paymentLinkData = paymentLink.data
  const partialPaymentLinkData = partialPaymentLink.data

  const isPaymentIdle = !isPaymentSuccess && !isPaymentLoading

  return {
    createPaymentLink,
    paymentLinkData,
    createPartialPaymentLink,
    partialPaymentLinkData,
    isPaymentLoading,
    isPaymentSuccess,
    isPaymentIdle,
  }
}
