import { Toggle, Tag, cn } from '@sweetspot/scramble-ds'
import { OutletStatus } from '@sweetspot/shared/data-access/api-platform'

import { BallQuantity, StatusBallDispenser, TerminalStatus } from '../../types'
import { tabIconsMap, tagVariantsMap } from '../../constants'

const renderText = ({
  value,
  noPadding,
  italic,
}: {
  value: string
  noPadding?: boolean
  italic?: boolean
}) => <span className={cn('text-text-dark', { '!px-2': !noPadding, italic })}>{value}</span>

const renderToggle = ({
  value,
  onActivate,
  t,
  row,
}: {
  value: boolean
  onActivate: (value: boolean, outletId: string) => void
  t: (key: string) => string
  row: StatusBallDispenser
}) => (
  <div className="flex max-w-[120px] items-center justify-between !px-2">
    <Toggle checked={value} onCheckedChange={(value) => onActivate(value, row.outletId || '')} />
  </div>
)

const renderTag = ({
  value,
  t,
}: {
  value: OutletStatus | TerminalStatus | BallQuantity
  t: (key: string) => string
}) => {
  return (
    <Tag className="text-content-xs mx-2 !px-2 !py-0.5" variant={tagVariantsMap[value]}>
      <i className={`fa-regular ${tabIconsMap[value]}`} />{' '}
      {t(`settings.ballDispensers.status.${value}`)}
    </Tag>
  )
}

const getHeaders = (
  t: (key: string) => string,
  onActivate: (value: boolean, outletId: string) => void
) => ({
  name: {
    name: '.sentences.ballDispenser',
    type: 'label',
    valueType: 'string',
    width: '180px',
    height: '32px',
    isSortable: true,
    render: (value: string, _: unknown, row: StatusBallDispenser) =>
      renderText({
        value: value || `${t('sentences.ballDispenser')} ${(row.dispenserIndex || 0) + 1}`,
      }),
  },
  outlet: {
    name: '.words.outlet',
    type: 'label',
    valueType: 'string',
    width: '80px',
    height: '32px',
    render: (value: string | null) => renderText({ value: value || t('words.one') }),
  },
  status: {
    name: '.words.active',
    type: 'label',
    valueType: 'boolean',
    width: '120px',
    height: '32px',
    render: (value: boolean, _: unknown, row: StatusBallDispenser) =>
      renderToggle({ value, onActivate, t, row }),
  },
  outletStatus: {
    name: '.settings.ballDispensers.outletStatus',
    type: 'label',
    valueType: 'string',
    width: '140px',
    height: '32px',
    render: (value: OutletStatus | TerminalStatus | BallQuantity) => renderTag({ value, t }),
  },
  terminalStatus: {
    name: '.settings.ballDispensers.terminalStatus',
    type: 'label',
    valueType: 'string',
    width: '140px',
    height: '32px',
    render: (value: string) => renderText({ value, italic: true }),
  },
  ballQuantity: {
    name: '.settings.ballDispensers.ballQuantity',
    type: 'label',
    valueType: 'string',
    width: '140px',
    height: '32px',
    render: (value: string) => renderText({ value, italic: true }),
  },
  assetId: {
    name: '.settings.ballDispensers.assetId',
    type: 'label',
    valueType: 'string',
    width: '140px',
    height: '32px',
    render: (value: string) => renderText({ value }),
  },
})

export default getHeaders
