import { differenceInYears, format, getTime } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { enGB, sv, de, da, fi } from 'date-fns/locale'
import i18next from 'i18next'
import { LanguageKeys } from '@sweetspot/shared/translations'

type Locales = {
  [key in LanguageKeys]: Locale
}

const locales: Locales = {
  [LanguageKeys.EN]: enGB,
  [LanguageKeys.SV]: sv,
  [LanguageKeys.DE]: de,
  [LanguageKeys.DA]: da,
  [LanguageKeys.FI]: fi,
}

export function formatLocalised(
  date: Date,
  dateFormat: string,
  options?: {
    locale?: Locale
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string {
  const locale = locales[i18next.language as LanguageKeys] || undefined
  return format(date, dateFormat, { locale, ...options })
}

export const isUnder18 = (date: Date | string): boolean => {
  const d = new Date(date)

  if (isNaN(d.getTime())) {
    return false
  }

  return differenceInYears(new Date(), d) < 18
}

export const isValidPlainDate = (value: string) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/

  if (!dateRegex.test(value)) return false

  const [year, month, day] = value.split('-')
  const parsedYear = parseInt(year, 10)
  const parsedMonth = parseInt(month, 10)
  const parsedDay = parseInt(day, 10)

  if (parsedMonth < 1 || parsedMonth > 12) return false

  const maxDaysInMonth = new Date(parsedYear, parsedMonth, 0).getDate()

  return parsedDay > 0 && parsedDay <= maxDaysInMonth
}

export const tryPlainFormatDate = (value: string) => {
  const date = new Date(value)

  if (isNaN(date.getTime())) {
    return ''
  }

  return format(date, 'yyyy-MM-dd')
}

export const getZonedDayStartAndEnd = (date, timezone) => {
  const startTimeWithZone = formatInTimeZone(date, timezone, "yyyy-MM-dd'T00:00:00'xxx")
  const startTime = new Date(startTimeWithZone).toLocaleString('sv', { timeZone: 'UTC' })
  const startTimeStr = startTime.split(' ')[0] + 'T' + startTime.split(' ')[1]

  const endTimeWithZone = formatInTimeZone(date, timezone, "yyyy-MM-dd'T23:59:59'xxx")
  const endTime = new Date(endTimeWithZone).toLocaleString('sv', { timeZone: 'UTC' })
  const endTimeStr = endTime.split(' ')[0] + 'T' + endTime.split(' ')[1]

  return { startTime: startTimeStr, endTime: endTimeStr }
}

// Function to convert from dateString to Date object. DateString Format: YYYY MM DD
export const toDateObject = (dateString: string) => {
  const year = parseInt(dateString.substr(0, 4))
  const month = parseInt(dateString.substr(5, 2))
  const day = parseInt(dateString.substr(8, 2))
  return new Date(year, month - 1, day)
}

export const today = () => {
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)

  return today
}

export const isSameDate = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate()

export const prependZero = (value: number) => {
  if (value < 10) return '0' + value
  return value
}

export const toTimeString = (date: Date) => {
  const hours = prependZero(date.getHours())
  const minutes = prependZero(date.getMinutes())
  return `${hours}.${minutes}`
}

export const changeTimezone = (date: Date | string, timeZone: string) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      })
    )
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    })
  )
}

export const getTimestamp = (date: Date | string) => {
  if (typeof date === 'string') {
    return getTime(new Date(date))
  }

  return getTime(date)
}
