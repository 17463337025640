import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { track } from '@amplitude/analytics-browser'

import { login } from '@sweetspot/club-portal-legacy/store/actions'

import { Login as LoginBase } from '@sweetspot/shared/feature/pages'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faEye, faEyeSlash, faLock } from '@fortawesome/pro-light-svg-icons'
import { DEV_ACCOUNTS } from '@sweetspot/sweetspot-js/common/components/EnvSelector'
import { AMPLITUDE_EVENTS, API_ENV_KEYS } from '@sweetspot/shared/util/constants'
import { useLocation } from 'react-router-dom'

const { REACT_APP_API_ENV } = process.env

const Login = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()
  const emailRef = useRef(null)

  const location = useLocation()

  const { addToast } = useToasts()

  useEffect(() => {
    track(AMPLITUDE_EVENTS.LOGIN_SCREEN_SEEN)
    emailRef.current.focus()
  }, [])

  const handleLogin = () => {
    track(AMPLITUDE_EVENTS.SIGN_IN_TAPPED)
    if (email && password) {
      setIsLoading(true)
      dispatch(login(email, password, location.state)).catch(() => {
        addToast(t('toast.loginError'), { appearance: 'error' })
        setIsLoading(false)
      })
    }
  }

  const signInElem = () => {
    return (
      <div className={'flex flex-col'}>
        <div className={'font-title mb-8 self-center text-xl '}>
          <h2 className="text-brand-black">{t('sentences.welcomeToCP')}</h2>
        </div>
        {[API_ENV_KEYS.DEV, API_ENV_KEYS.SANDBOX].includes(REACT_APP_API_ENV) && (
          <div className="bg-sand-50 mb-8 flex w-full flex-col rounded-sm border">
            <div>Test accounts</div>
            {Object.keys(DEV_ACCOUNTS).map((i) => (
              <p
                key={i}
                className={'hover:bg-sand-400 cursor-pointer rounded-sm'}
                onClick={() => {
                  setEmail(`${i}@sweetspot.io`)
                  setPassword(i)
                }}
              >
                <strong>{DEV_ACCOUNTS[i]}</strong>
                {` - ${i}@sweetspot.io`}
              </p>
            ))}
          </div>
        )}
        <form
          className={'space-y-4'}
          onSubmit={(e) => {
            e.preventDefault()
            handleLogin()
          }}
        >
          <TextInputField
            innerRef={emailRef}
            disabled={isLoading}
            size="large"
            type="email"
            value={email}
            onChange={(val) => setEmail(val)}
            onEnter={handleLogin}
            containerClassName={'bg-white py-2 rounded-xl'}
            inputClassName={'bg-white py-1 px-2'}
            placeholder={t('words.email')}
            theme={'login'}
            noMargin
            inlinePrefix={<FontAwesomeIcon icon={faAt} />}
            inputProps={{
              tabIndex: 1,
            }}
          />
          <TextInputField
            tabIndex={0}
            disabled={isLoading}
            size="large"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(val) => setPassword(val)}
            onSuffixClick={() => setShowPassword(!showPassword)}
            onEnter={handleLogin}
            inputClassName={'bg-white py-1 px-2'}
            containerClassName={'bg-white py-2 rounded-xl'}
            placeholder={t('words.password')}
            theme={'login'}
            noMargin
            inlinePrefix={<FontAwesomeIcon icon={faLock} />}
            suffix={
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash}
                className={'cursor-pointer'}
              />
            }
            inputProps={{
              tabIndex: 2,
            }}
          />
          <div tabIndex={0}>
            <button
              id={'login-button'}
              type={'submit'}
              className={
                'bg-brand-yellow focus:border-brand-black mt-8 h-14 w-full cursor-pointer rounded-2xl font-semibold text-black focus:border disabled:cursor-default disabled:opacity-75'
              }
              disabled={!password || !email || isLoading}
              tabIndex={3}
            >
              {isLoading ? <PulseLoader showIf color={'black'} /> : t('words.login')}
            </button>
          </div>
        </form>
        <div className={'mt-14'}>
          <p className={'text-center'}>
            Experiencing an issue? Check the operational status of Sweetspot{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sweetspot.statuspage.io/"
              className={'font-semibold underline'}
              tabIndex={4}
              onClick={() => track(AMPLITUDE_EVENTS.OPERATIONAL_STATUS_TAPPED)}
            >
              here
            </a>
          </p>
        </div>
      </div>
    )
  }
  return <LoginBase signInElement={signInElem()}></LoginBase>
}

export default Login
