import { useState, useEffect, useRef } from 'react'
import ToolTip from '@sweetspot/sweetspot-js/ui-kit/components/ToolTip/tooltip'

import './style.scss'

type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: React.ReactNode) => React.ReactNode
  children: React.ReactNode
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children

type EllipsedTextProps = {
  id: string | number
  text: string | number
  className: string
}

const EllipsedText = ({ id, text, className }: EllipsedTextProps) => {
  const [isEllipsed, setEllipsed] = useState(false)
  const cell = useRef(null)

  useEffect(() => {
    checkEllipsed()
  }, [text])

  const checkEllipsed = () => {
    if (!cell || !cell.current) return

    setEllipsed(cell.current.offsetWidth < cell.current.scrollWidth)
  }

  return (
    <ConditionalWrapper
      condition={isEllipsed}
      wrapper={(children) => <ToolTip text={text}>{children}</ToolTip>}
    >
      <div ref={cell} id="ellipsed-text" className={className}>
        {text}
      </div>
    </ConditionalWrapper>
  )
}

export default EllipsedText
