import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import { PricesTable } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingTabs/PricesTab/PricesTable'
import { TrackingTech } from '@sweetspot/shared/types'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'

import { PriceModelObject, PriceObject } from '../../types'
import { useBallPricingContext } from '../../BallPricingProvider'

const TABS: { [tech: string]: string } = {
  none: 'Standard',
  toptracer: 'Toptracer',
}

type PricesTabsProps = {
  data?: PriceModelObject[]
}

export const PricesTabs = ({ data }: PricesTabsProps) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(TABS.none)
  const {
    bucketConfiguration: { isExperienceBasedPricing },
  } = useBallPricingContext()

  const tabs = useMemo(() => {
    if (!isExperienceBasedPricing) {
      return null
    }
    return data?.map?.(({ trackingTech }) => ({
      id: TABS[trackingTech],
      stepNumber: TABS[trackingTech],
      label: trackingTech === TrackingTech.NONE ? t('words.standard') : capitalize(trackingTech),
    }))
  }, [t, data, isExperienceBasedPricing])

  // if there are no prices for the active tab,
  // set the active tab to the first tab with prices
  useEffect(() => {
    if (!!tabs?.length && !tabs?.find((tab) => tab.id === activeTab)) {
      setActiveTab(tabs[0].id)
    }
  }, [tabs, activeTab])

  const { none: standardData, toptracer: toptracerData }: { [key: string]: PriceObject[][] } =
    useMemo(() => {
      const defaultValue = { none: [], toptracer: [] }
      if (data) {
        return data.reduce(
          (acc, { trackingTech, priceMatrix }) => ({
            ...acc,
            [trackingTech]: priceMatrix,
          }),
          defaultValue
        )
      }
      return defaultValue
    }, [data])

  const renderContent = useCallback(() => {
    if (activeTab === TABS.none) {
      return <PricesTable data={standardData} />
    }
    if (activeTab === TABS.toptracer) {
      return <PricesTable data={toptracerData} />
    }
    return null
  }, [activeTab, standardData, toptracerData])

  return (
    <>
      {tabs && <SimpleTabs currentItem={activeTab} onClick={setActiveTab} items={tabs} />}
      {renderContent()}
    </>
  )
}
