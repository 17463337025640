import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatInTimeZone } from 'date-fns-tz'
import cx from 'classnames'

import InputSelectDateSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectDateSimple'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { queryTeeTimes } from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'
import { getPathFromVenue } from '@sweetspot/club-portal-legacy/utils/golfClub'
import { getZonedDayStartAndEnd } from '@sweetspot/club-portal-legacy/utils/date'
import { IAvailabilityChecker, Violations } from '../../types'

import styles from './styles.module.scss'

const AvailabilityChecker = ({
  date,
  setDate,
  dateFormatted,
  time,
  setTime,
  orderBooking,
  venue,
  onCheckAvailability,
  isChecking,
  errors,
}: IAvailabilityChecker) => {
  const { t } = useTranslation()

  const [startFromOptions, setStartFromOptions] = useState([])

  const { startTime, endTime } = useMemo(
    () => getZonedDayStartAndEnd(date, venue?.timezone),
    [date, venue?.timezone]
  )

  const venueDate = useMemo(() => Math.floor(date.getTime() / 1000), [date])

  useEffect(() => {
    setStartFromOptions([])
    queryTeeTimes({
      'course.uuid': orderBooking.course?.uuid,
      'from[after]': startTime,
      'from[before]': endTime,
      limit: 999,
    })
      .then((teeTimes) => {
        let startTimes = teeTimes.map((teeTime) => {
          return formatInTimeZone(new Date(teeTime.from), venue?.timezone, 'HH:mm')
        })

        // remove duplicates because course can have multiple spaces
        startTimes = [...new Set(startTimes)]

        setStartFromOptions(startTimes)
      })
      .catch((err) => {
        console.error(err)
        setStartFromOptions([])
      })
  }, [orderBooking.spaces, startTime, endTime, venue?.timezone])

  useEffect(() => {
    const formattedTime = formatInTimeZone(
      new Date(orderBooking?.booking?.start_time),
      venue?.timezone,
      'HH:mm'
    )
    setTime(formattedTime)
    setDate(new Date(orderBooking?.booking?.start_time))
  }, [setTime, setDate, orderBooking?.booking?.start_time, venue?.timezone])

  const handleSetDate = (dateValue: Date) => {
    setDate(dateValue)
  }

  const handleSetTime = (timeValue: string) => {
    setTime(timeValue)
  }

  return (
    <div>
      <div className={styles.filterContainer}>
        <InputSelectDateSimple
          className={styles.dateInput}
          label={t('sentences.selectDate')}
          currentDate={date}
          onSetDate={handleSetDate}
          disableBeforeToday
          placeholder={t('sentences.selectDate')}
          value={dateFormatted}
        />

        <div className={styles.timeInputContainer}>
          <span className={styles.label}>{t('sentences.startTime')}</span>
          <DropdownSelect
            showBorder
            initialId={time}
            values={startFromOptions.map((option) => ({
              id: option,
              name: option,
            }))}
            onSelect={handleSetTime}
          />
        </div>

        <button
          onClick={onCheckAvailability}
          className="system-button primary md-32"
          disabled={isChecking || !date || !time}
        >
          {t('sentences.checkAvailability')}
        </button>

        <a
          className={cx('mb-1 ml-2', styles.link)}
          href={`${getPathFromVenue(venue.type)}?date=${venueDate}&venue=${venue.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('sentences.showTeeSheet')}
        </a>
      </div>

      <div>
        {errors.map((error) => (
          <p className="mb-2 text-red-500">{t(Violations[error.errorName])}</p>
        ))}
      </div>
    </div>
  )
}

export default AvailabilityChecker
