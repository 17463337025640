export const DURATION_TIME = {
  DAY: 'DAY',
  TWO_DAYS: 'TWO_DAYS',
  MAX_DAYS: 'MAX_DAYS',
  CUSTOM_DAYS: 'CUSTOM_DAYS',
  HOURS: 'HOURS',
}

export const FIRST_PAYMENT_PAY = {
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
}

export const SECONDS_IN_DAY = 86400
