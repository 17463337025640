const flatSlots = (week) => {
  return Object.entries(week).reduce((acc, [, slots]) => {
    if (Array.isArray(slots)) {
      acc.push(
        ...slots.map((slot) => ({
          uuid: slot.uuid,
          price: slot.price,
          price_per_extra_player: slot.price_per_extra_player,
        }))
      )
    }
    return acc
  }, [])
}

export default flatSlots
