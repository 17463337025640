import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { APP_NAMES } from '@sweetspot/shared/util/constants'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'

export const useClubCurrency = (): [string | null] => {
  const CURRENT_APP = getCurrentAppName()
  const currentClub: { currency_code: string } | undefined = useSelector((state) => {
    if (CURRENT_APP === APP_NAMES.WEB_BOOKING) {
      return state?.club?.club
    }
    if (CURRENT_APP === APP_NAMES.CLUB_PORTAL) {
      const { golfClub } = state
      if (!golfClub || !golfClub?.list || !golfClub?.selectedId) return undefined
      const club = golfClub.list.find((c) => c.id === golfClub.selectedId)
      if (!club) return undefined
      return club
    }
    if (CURRENT_APP === APP_NAMES.PARTNER_PORTAL) {
      // Partner portal does not have club in store
      return undefined
    }
    return undefined
  })

  const currentClubCurrency = useMemo(() => {
    if (!currentClub || !currentClub?.currency_code) return null
    return currentClub.currency_code
  }, [currentClub])

  return [currentClubCurrency]
}
