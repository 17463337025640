import * as React from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { type VariantProps, tv } from 'tailwind-variants'
import { cn } from '../../../utils'

const avatarVariants = tv({
  base: 'bg-base-black text-text-light relative flex shrink-0 overflow-hidden rounded-full',
  variants: {
    size: {
      small: 'h-touch-height-sm w-touch-height-sm',
      medium: 'h-touch-height-md w-touch-height-md',
      large: 'h-touch-height-lg w-touch-height-lg',
      xlarge: 'h-touch-height-xl w-touch-height-xl',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

export interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarVariants> {
  className?: string
}

const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
  ({ className, size, ...props }, ref) => (
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(avatarVariants({ size, className }))}
      data-testid="scramble-avatar"
      {...props}
    />
  )
)
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> & { className?: string }
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('aspect-square h-full w-full', className)}
    {...props}
  />
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> & { className?: string }
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'bg-muted flex h-full w-full items-center justify-center rounded-full',
      className
    )}
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export { Avatar, AvatarImage, AvatarFallback }
