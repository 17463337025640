import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import produce from 'immer'
import { t } from 'i18next'
import cx from 'classnames'
import TimePicker from 'rc-time-picker'
import moment from 'moment'

import style from './style.module.scss'

import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import {
  getAddressValues,
  loadExternalScript,
} from '@sweetspot/club-portal-legacy/helpers/GolfClubHelpers'
import { getLang } from '@sweetspot/club-portal-legacy/languages'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions'
import {
  getSingleClub,
  updateSingleClub,
} from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { getGoogleMapsApiKey } from '@sweetspot/club-portal-legacy/utils/getKeys'

import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import ToggleSwitch from '@sweetspot/club-portal-legacy/components/ToggleSwitch'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import CountrySelect from '../CountrySelect'

const orderedRevenueSettingsKeys = [
  'memberships_revenue_fee',
  'green_fees_revenue_fee',
  'partnership_revenue_fee',
  'rentals_revenue_fee',
  'vouchers_revenue_fee',
  'others_revenue_fee',
  'extras_revenue_fee',
  'simulator_fee',
  'driving_range_fee',
  'range_dispenser_fee',
  'pro_class_fee',
]

class ModifyGolfClub extends Component {
  state = {
    federationID: null,
    federationName: 'none',
    availableGolfclubs: [],
    selectedGolfclub: null,
    selectedPartners: [],
    isLoading: false,
    scoreCardEnabled: false,
    enableShowingPlayerNames: false,
    isDemo: false,
    enableReceivingBookingConfirmation: false,
    toggleMembershipChurnReport: false,
    payment_provider: '',
    business_area: '',
    promotionMembershipBeforeVoucher: false,
    weekdayFromTime: null,
    weekdayToTime: null,
    weekendFromTime: null,
    weekendToTime: null,
    translations: {},
    revenue_settings: {
      memberships_revenue_fee: '',
      green_fees_revenue_fee: '',
      partnership_revenue_fee: '',
      rentals_revenue_fee: '',
      vouchers_revenue_fee: '',
      others_revenue_fee: '',
      extras_revenue_fee: '',
      simulator_fee: '',
      driving_range_fee: '',
      range_dispenser_fee: '',
      pro_class_fee: '',
    },
    errors: {},
    showAlert: false,
    addressCountry: '',
  }

  autoCompleteRef = React.createRef()
  federationSelector = React.createRef()

  componentWillMount = () => {
    this.getTranslations()
  }

  componentDidMount = () => {
    const GOOGLE_API_SCRIPT = `https://maps.googleapis.com/maps/api/js?key=${getGoogleMapsApiKey()}&language=${
      this.props.lang
    }&libraries=places`

    const scriptEle = loadExternalScript(GOOGLE_API_SCRIPT)
    scriptEle.addEventListener('load', () => {
      if (this._addressLine1) {
        const options = {
          fields: ['address_components'],
        }

        this.autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          this._addressLine1,
          options
        )

        this.autoCompleteRef.current.addListener('place_changed', this.fillAddress)
      }
    })

    this.selectGolfClub(this.props.golfClub.selectedId)
  }

  fillAddress = () => {
    const place = this.autoCompleteRef.current.getPlace()
    const { line1, postalCode, city, state, country } = getAddressValues(place)
    this._addressLine1.value = line1
    this._addressPostalCode.value = postalCode
    this._addressCity.value = city
    this._addressState.value = state
    this.setState({ addressCountry: country })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.lang !== this.props.lang) {
      this.getTranslations()
    }
  }

  getTranslations = () => {
    const { lang } = this.props
    this.setState({
      translations: {
        memberships_revenue_fee: GetTranslatedString(lang, 'settings.membershipRevFee'),
        green_fees_revenue_fee: GetTranslatedString(lang, 'settings.greenFeeRevFee'),
        partnership_revenue_fee: GetTranslatedString(lang, 'settings.partnersRevFee'),
        rentals_revenue_fee: GetTranslatedString(lang, 'settings.rentalsRevFee'),
        vouchers_revenue_fee: GetTranslatedString(lang, 'settings.vouchersRevFee'),
        others_revenue_fee: GetTranslatedString(lang, 'settings.othersRevFee'),
        extras_revenue_fee: GetTranslatedString(lang, 'settings.extrasFee'),
        simulator_fee: GetTranslatedString(lang, 'settings.simulatorRevFee'),
        driving_range_fee: GetTranslatedString(lang, 'settings.drivingRangesRevFee'),
        pro_class_fee: GetTranslatedString(lang, 'settings.proClassesRevFee'),
        range_dispenser_fee: GetTranslatedString(lang, 'settings.rangeDispenserFee'),
        voucher_apply_before: GetTranslatedString(lang, 'settings.voucherApplyBefore'),
        voucher_apply_after: GetTranslatedString(lang, 'settings.voucherApplyAfter'),
      },
    })
  }

  handleTimeChange = (target, moment) => {
    this.setState({
      [target]: moment ? moment : '',
    })
  }

  handleRevenueSettingChange(key, value) {
    if (value < 0 || value > 100) return
    this.setState(
      produce((state) => {
        state.revenue_settings[key] = value
      })
    )
  }

  selectGolfClub(id) {
    let club = this.props.golfClubList.find((club) => club.id === id)
    if (!club) return
    this.setState({
      selectedGolfclub: { name: club.name, id: id },
    })
    this.loadValues(club)
  }

  toggleAlert = () => {
    this.setState({ showAlert: !this.state.showAlert })
  }

  getFederation = (golfClub) => {
    if (golfClub['git_id']) return 'git'

    if (golfClub['cdh_id']) return 'cdh'

    return 'none'
  }

  loadValues = (golfclub) => {
    this.setState({ isLoading: true }, () => {
      getSingleClub(golfclub.id)
        .then((golfclub) => {
          let gitID = golfclub['git_id'] || golfclub['cdh_id']
          let name = golfclub['name']
          let federation = this.getFederation(golfclub)
          let phone = golfclub['phone']
          let email = golfclub['email']
          let website = golfclub['website']
          let description = golfclub['description']
          let scoreCardEnabled = golfclub['is_score_card_printing_enabled']
          let enableShowingPlayerNames = golfclub['is_expose_player_name']
          let enableReceivingBookingConfirmation =
            golfclub['is_enabled_club_admin_receive_booking_email']
          let toggleMembershipChurnReport = golfclub['send_membership_churn_report']
          let paymentProvider = golfclub['payment_provider']
          let businessArea = golfclub['business_area']
          let isDemo = golfclub['is_demo']
          let promotionMembershipBeforeVoucher = golfclub['is_promotion_membership_before_voucher']
          let terms = golfclub['terms_url']
          let addressLine1 = golfclub.address?.line1 || ''
          let addressLine2 = golfclub.address?.line2 || ''
          let addressCity = golfclub.address?.city || ''
          let addressState = golfclub.address?.state || ''
          let addressPostalCode = golfclub.address?.postal_code || ''
          let addressCountry = golfclub.address?.country?.code || ''
          let companyName = golfclub.billing?.company_name
          let billingPhone = golfclub.billing?.phone
          let billingEmail = golfclub.billing?.email
          let organisationNumber = golfclub.billing?.organisation_number
          let VATNumber = golfclub.billing?.vat_number
          let revenue_settings = golfclub.revenue_settings ? golfclub.revenue_settings : []

          let weekdayFromTime, weekdayToTime, weekendFromTime, weekendToTime
          if (golfclub.schedule.settings) {
            if (golfclub.schedule.settings.monday.to) {
              weekdayFromTime = moment(golfclub.schedule.settings.monday.from + 'Z', 'HH:mmZ')
              weekdayToTime = moment(golfclub.schedule.settings.monday.to + 'Z', 'HH:mmZ')
            }
            if (golfclub.schedule.settings.sunday.to) {
              weekendFromTime = moment(golfclub.schedule.settings.sunday.from + 'Z', 'HH:mmZ')
              weekendToTime = moment(golfclub.schedule.settings.sunday.to + 'Z', 'HH:mmZ')
            }
          }
          this.setState({
            weekdayFromTime,
            weekdayToTime,
            weekendFromTime,
            weekendToTime,
            federationName: federation,
            federationID: gitID,
          })

          this.setState({
            isLoading: false,
            revenue_settings,
          })

          this._name.value = name
          this._paymentProvider = paymentProvider
          this._businessArea = businessArea
          this._phone.value = phone
          this._email.value = email
          this._website.value = website
          this._terms.value = terms
          this._description.value = description
          this._companyName.value = companyName
          this._addressLine1.value = addressLine1
          this._addressLine2.value = addressLine2
          this._addressCity.value = addressCity
          this._addressState.value = addressState
          this._addressPostalCode.value = addressPostalCode
          this._billingPhone.value = billingPhone
          this._billingEmail.value = billingEmail
          this._organisationNumber.value = organisationNumber
          this._VATNumber.value = VATNumber
          this.setState({
            scoreCardEnabled,
            enableShowingPlayerNames,
            isDemo,
            enableReceivingBookingConfirmation,
            toggleMembershipChurnReport,
            promotionMembershipBeforeVoucher,
            payment_provider: paymentProvider,
            addressCountry: addressCountry,
          })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  checkForErrors = (settings) => {
    let error = false,
      errors = {}
    if (settings.name.trim() === '') {
      error = true
      errors = {
        ...errors,
        name: 'settings.nameRequired',
      }
    } else if (
      !Utilities.validateEmail(settings.email) ||
      !Utilities.validateEmail(settings.companyEmail)
    ) {
      error = true
      errors = {
        ...errors,
        email: 'settings.invalidEmail',
        companyEmail: 'settings.invalidEmail',
      }
    }
    return { error, errors }
  }

  saveSettings = () => {
    const {
      weekdayFromTime,
      weekdayToTime,
      weekendFromTime,
      weekendToTime,
      selectedGolfclub,
      revenue_settings,
      scoreCardEnabled,
      enableShowingPlayerNames,
      isDemo,
      enableReceivingBookingConfirmation,
      toggleMembershipChurnReport,
      promotionMembershipBeforeVoucher,
    } = this.state

    const revenueSettings = {}
    for (const key in revenue_settings) {
      const parsedValue = parseFloat(revenue_settings[key])
      revenueSettings[key] = isNaN(parsedValue) ? null : parsedValue
    }

    let schedule = {
      is_enabled: false,
      settings: {
        sunday: {
          from: null,
          to: null,
        },
        monday: {
          from: null,
          to: null,
        },
        tuesday: {
          from: null,
          to: null,
        },
        wednesday: {
          from: null,
          to: null,
        },
        thursday: {
          from: null,
          to: null,
        },
        friday: {
          from: null,
          to: null,
        },
        saturday: {
          from: null,
          to: null,
        },
      },
    }

    if (!!weekdayFromTime || !!weekendFromTime) {
      const weekdayFrom = weekdayFromTime.utc().format('HH:mm:ss')
      const weekDayTo = weekdayToTime.utc().format('HH:mm:ss')
      const weekendFrom = weekendFromTime.utc().format('HH:mm:ss')
      const weekendTo = weekendToTime.utc().format('HH:mm:ss')

      schedule = {
        is_enabled: true,
        settings: {
          sunday: {
            from: weekendFrom,
            to: weekendTo,
          },
          monday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          tuesday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          wednesday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          thursday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          friday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          saturday: {
            from: weekendFrom,
            to: weekendTo,
          },
        },
      }
    }

    const address = {
      line1: this._addressLine1.value,
      line2: this._addressLine2.value,
      city: this._addressCity.value,
      state: this._addressState.value,
      postal_code: this._addressPostalCode.value,
      country: {
        code: this.state.addressCountry,
      },
    }
    const billing = {
      company_name: this._companyName.value,
      phone: this._billingPhone.value,
      email: this._billingEmail.value,
      organisation_number: this._organisationNumber.value,
      vat_number: this._VATNumber.value,
    }

    const federationId =
      this.state.federationName !== 'none'
        ? {
            [this.state.federationName === 'git' ? 'git_id' : 'cdh_id']: this.state.federationID,
          }
        : {}

    const payload = {
      ...federationId,
      name: this._name.value,
      federation: this.state.federationName === 'none' ? null : this.state.federationName,
      payment_provider: this._paymentProvider,
      business_area: this._businessArea,
      is_demo: isDemo,
      phone: this._phone.value,
      email: this._email.value,
      website: this._website.value,
      terms_url: this._terms.value,
      description: this._description.value,
      is_sync_with_git: false,
      is_admin_assure_bookable: false,
      is_score_card_printing_enabled: scoreCardEnabled,
      is_expose_player_name: enableShowingPlayerNames,
      is_enabled_club_admin_receive_booking_email: enableReceivingBookingConfirmation,
      send_membership_churn_report: toggleMembershipChurnReport,
      is_promotion_membership_before_voucher: promotionMembershipBeforeVoucher,
      revenue_settings: revenueSettings,
      address,
      billing,
      schedule,
    }

    this.setState({ errors: {} })

    let { error, errors } = this.checkForErrors(payload)

    if (error) {
      this.setState({ errors })
      this.toggleAlert()
    } else {
      updateSingleClub(selectedGolfclub.id, payload)
        .then(() => {
          this.loadValues(selectedGolfclub)
          this.props.addToast('golfclubModified', 'success')
        })
        .catch((err) => {
          if (err?.violations) {
            errors = {}
            err.violations.forEach((error) => {
              const { propertyPath, errorName } = error
              let string = ''
              if (errorName === 'IS_BLANK_ERROR') {
                string = 'errors.fieldRequired'
              } else if (errorName === 'INVALID_URL_ERROR') {
                string = 'errors.fieldInvalid'
              } else {
                string = 'errors.fieldInvalid'
              }
              errors = {
                ...errors,
                [propertyPath]: string,
              }
            })
          }
          this.setState({ errors })
          this.toggleAlert()
        })
    }
  }

  resetError = (field) => {
    this.setState((prev) => ({ ...prev, errors: { ...prev.errors, [field]: '' } }))
  }

  renderSuperAdminSettings = () => {
    const paymentProviders = [
      { id: 'braintree', name: 'Braintree' },
      { id: 'adyen', name: 'Adyen' },
    ]

    const businessAreas = [
      { id: 'golf_club', name: 'Golf Club' },
      { id: 'simulator', name: 'Simulator' },
      { id: 'range', name: 'Range' },
      { id: 'academy', name: 'Academy' },
      { id: 'other', name: 'Other' },
    ]

    const onSelectBusinessArea = (businessArea) => (this._businessArea = businessArea)
    const onSelectPaymentProvider = (paymentType) => (this._paymentProvider = paymentType)
    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="superAdminSettings" />
          </div>
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('sentences.mainBusinessArea')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={this._businessArea || this.state.business_area}
            values={businessAreas}
            onSelect={onSelectBusinessArea}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('sentences.isDemo')}</label>
          <ToggleSwitch
            textUnchecked={'NO'}
            textChecked={'YES'}
            checked={this.state.isDemo}
            onChange={(checked) => this.setState({ isDemo: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('sentences.paymentProvider')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={this._paymentProvider}
            values={paymentProviders}
            onSelect={onSelectPaymentProvider}
          />
        </div>
        <div>
          <label className="ss-label !mb-2">{t('settings.sweetspotFees')}</label>
          {/* Revenue settings */}
          <div className={style.revenueSettings}>
            {orderedRevenueSettingsKeys.map((setting) => (
              <TextInputField
                key={setting}
                label={this.state.translations[setting]}
                value={
                  this.state.revenue_settings[setting] ? this.state.revenue_settings[setting] : ''
                }
                onChange={(percentage) => this.handleRevenueSettingChange(setting, percentage)}
                type="number"
                inputWidth="quarter"
                step={'any'}
                inputProps={{
                  min: 0.01,
                  max: 99.9,
                }}
                suffix="%"
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  renderScheduleSettings = () => {
    const { weekdayFromTime, weekdayToTime, weekendFromTime, weekendToTime } = this.state
    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="settings.openingHours" />
          </div>
          <InfoHover textId="settings.infoHoversGolfclub.openingHours" />
        </div>
        <div className={style.columnContainer}>
          <Text textId="settings.weekday" />
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekdayFromTime ? moment(weekdayFromTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekdayFromTime', moment)}
            minuteStep={5}
          />
          -
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekdayToTime ? moment(weekdayToTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekdayToTime', moment)}
            minuteStep={5}
          />
        </div>

        <div className={style.columnContainer}>
          <Text textId="settings.weekend" />
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekendFromTime ? moment(weekendFromTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekendFromTime', moment)}
            minuteStep={5}
          />
          -
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekendToTime ? moment(weekendToTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekendToTime', moment)}
            minuteStep={5}
          />
        </div>
      </div>
    )
  }

  renderDiscountCalculation = () => {
    const discountTypes = [
      { id: 'before', name: this.state.translations.voucher_apply_before },
      { id: 'after', name: this.state.translations.voucher_apply_after },
    ]
    const onSelectDiscountType = (discountType) => {
      this.setState({ promotionMembershipBeforeVoucher: discountType === 'after' })
    }
    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="settings.discountCalculation" />
          </div>
        </div>
        <div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.applyVoucherDiscount')}</label>
            <DropdownSelect
              showBorder
              width="100%"
              initialId={this.state.promotionMembershipBeforeVoucher ? 'after' : 'before'}
              values={!this.state.selectedGolfclub ? [] : discountTypes}
              onSelect={onSelectDiscountType}
            />
          </div>
        </div>
      </div>
    )
  }

  renderBillingSettings = () => {
    const lang = this.props.lang
    const onSelectCountry = (country) => this.setState({ addressCountry: country })

    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="settings.billingInformation" />
          </div>
        </div>
        <div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.companyName')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._companyName = input)}
              placeholder={getLang(lang)['settings']['addCompanyName']}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.addresseLine1')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['settings']['addAddresseLine1']}
              ref={(input) => (this._addressLine1 = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.addresseLine2')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['settings']['addAddresseLine2']}
              ref={(input) => (this._addressLine2 = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('players.city')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['players']['addCity']}
              ref={(input) => (this._addressCity = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.state')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['settings']['addState']}
              ref={(input) => (this._addressState = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('players.zipCode')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['players']['addZipCode']}
              ref={(input) => (this._addressPostalCode = input)}
            />
          </div>
          <CountrySelect onSelect={onSelectCountry} value={this.state.addressCountry} />
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.billingPhone')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._billingPhone = input)}
              placeholder={getLang(lang)['settings']['addBillingPhone']}
              onChange={Utilities.autoFormatNumber}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.billingEmail')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._billingEmail = input)}
              placeholder={getLang(lang)['settings']['addBillingEmail']}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.organisationNumber')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._organisationNumber = input)}
              placeholder={getLang(lang)['settings']['addOrganisationNumber']}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.VATNumber')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._VATNumber = input)}
              placeholder={getLang(lang)['settings']['addVATNumber']}
            />
          </div>
        </div>
      </div>
    )
  }

  renderForm = () => {
    const { errors } = this.state

    const golfFederations = [
      { id: 'none', name: t('settings.notApplicable') },
      { id: 'git', name: 'Svenska Golfförbundet' },
      { id: 'cdh', name: 'Scottish Golf Union' },
    ]
    const onFederationNameSelect = (federationValue) =>
      this.setState({ federationName: federationValue })

    const onFederationIDSelect = (federationIdValueEvent) =>
      this.setState({ federationID: federationIdValueEvent.target.value })

    return (
      <form className={style.golfClubForm} onSubmit={(e) => e.preventDefault()}>
        {this.renderSelects()}
        <div className="ss-form-group">
          <label className="ss-label">
            {t('Federation')}
            <InfoHover textId="settings.federation" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={this.state.federationName}
            ref={this.federationSelector}
            values={golfFederations}
            onSelect={(value) => onFederationNameSelect(value)}
          />
        </div>
        {this.state.federationName !== 'none' && (
          <div className="ss-form-group">
            <label className="ss-label">
              {this.state.federationName === 'git' ? 'GIT Club ID' : 'Federation ID'}
              <InfoHover textId="settings.infoHoversGolfclub.id" />
            </label>
            <input
              className={cx('ss-input', errors?.git_id ? 'error' : '')}
              type="text"
              value={this.state.federationID}
              onChange={onFederationIDSelect}
            />
            <div className="ss-invalid-feedback">{t(errors?.git_id)}</div>
          </div>
        )}

        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.name')}
            <InfoHover textId="settings.infoHoversGolfclub.name" />
          </label>
          <input className="ss-input" type="text" ref={(input) => (this._name = input)} />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.phone')}
            <InfoHover textId="settings.infoHoversGolfclub.phone" />
          </label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._phone = input)}
            onChange={Utilities.autoFormatNumber}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.email')}
            <InfoHover textId="settings.infoHoversGolfclub.email" />
          </label>
          <input className="ss-input" type="text" ref={(input) => (this._email = input)} />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.website')}
            <InfoHover textId="settings.infoHoversGolfclub.website" />
          </label>
          <input
            className={cx('ss-input', errors?.website ? 'error' : '')}
            type="text"
            placeholder="https://"
            ref={(input) => (this._website = input)}
            onChange={() => this.resetError('website')}
          />
          <div className="ss-invalid-feedback">{t(errors?.website)}</div>
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.terms')}
            <InfoHover textId="settings.infoHoversGolfclub.terms" />
          </label>
          <input
            className={cx('ss-input', errors?.terms_url ? 'error' : '')}
            type="text"
            ref={(input) => (this._terms = input)}
            placeholder="https://"
            onChange={() => this.resetError('terms_url')}
          />
          <div className="ss-invalid-feedback">{t(errors?.terms_url)}</div>
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.description')}
            <InfoHover textId="settings.infoHoversGolfclub.description" />
          </label>
          <textarea
            className="ss-input"
            type="text"
            rows="4"
            ref={(input) => (this._description = input)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableScoreCard')}
            <InfoHover textId="settings.infoHoversGolfclub.enableScoreCard" />
          </label>
          <ToggleSwitch
            checked={this.state.scoreCardEnabled}
            onChange={(checked) => this.setState({ scoreCardEnabled: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableShowingPlayerNames')}
            <InfoHover textId="settings.infoHoversGolfclub.enableShowingPlayerNames" />
          </label>
          <ToggleSwitch
            checked={this.state.enableShowingPlayerNames}
            onChange={(checked) => this.setState({ enableShowingPlayerNames: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableReceivingBookingConfirmation')}
            <InfoHover textId="settings.infoHoversGolfclub.enableReceivingBookingConfirmation" />
          </label>
          <ToggleSwitch
            checked={this.state.enableReceivingBookingConfirmation}
            onChange={(checked) => this.setState({ enableReceivingBookingConfirmation: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableMembershipChurtReport')}
            <InfoHover textId="settings.infoHoversGolfclub.enableMembershipChurtReport" />
          </label>
          <ToggleSwitch
            checked={this.state.toggleMembershipChurnReport}
            onChange={(checked) => this.setState({ toggleMembershipChurnReport: checked })}
          />
        </div>
        {this.renderScheduleSettings()}
        {this.renderDiscountCalculation()}
        {this.renderBillingSettings()}
        {this.props.isSuperAdmin && this.renderSuperAdminSettings()}
        <div className={style.formButtons}>
          <button onClick={this.saveSettings} className="system-button info-outline md-32">
            {t('save')}
          </button>
        </div>
      </form>
    )
  }

  renderSelects = () => {
    return (
      <div className="ss-form-group">
        <label className="ss-label">{t('golfclub')}</label>
        <DropdownSelect
          showBorder
          width="100%"
          initialId={this.props.golfClub.selectedId}
          values={this.props.golfClubList}
          onSelect={(id) => this.selectGolfClub(id)}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.label}>
          <Text textId="settings.modifyGolfclub" />
          <div>
            <PulseLoader fillHeight showIf={this.state.isLoading} />
          </div>
        </div>
        <div>
          {this.renderForm()}
          <Alert
            showIf={this.state.showAlert}
            onClick={() => {
              this.setState({
                showAlert: false,
              })
            }}
          >
            <Text textId={'errors.generalFormError'} />
          </Alert>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    golfClubList: state.golfClub.list,
    token: state.auth.token,
    golfClub: state.golfClub,
    lang: state.auth.me.lang,
    roles: state.auth.roles,
    isSuperAdmin: state.auth.isSuperAdmin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToast: (msg, status) => dispatch(addToast(msg, status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModifyGolfClub))

ModifyGolfClub.propTypes = {
  golfClubList: PropTypes.array.isRequired,
  addToast: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
}
