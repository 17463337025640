import React from 'react'
import { useTranslation } from 'react-i18next'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Club, TeeTime } from '@sweetspot/shared/types'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import BookingHeader from '@sweetspot/sweetspot-js/features/bookings/components/BookingHeader'
import ReserveNewBookingButton from '@sweetspot/sweetspot-js/features/bookings/components/ReserveNewBookingButton'

type loaders = {
  teeTime: boolean
  teeTimePrice: boolean
  course: boolean
  golfClub: boolean
  bookings: boolean
  booking: boolean
  canceledBookings: boolean
  notes: boolean
  selectedBooking: boolean
  reservingBooking: boolean
  confirmingBooking: boolean
  cancelingBooking: boolean
  tags: boolean
  partnerships: boolean
  golfCarts: boolean
}

interface InfoBookProps {
  teeTime: TeeTime
  course: unknown
  club: Club
  teeTimePrice: number
  loaders: loaders
  bookings: unknown
  handleOnRequestOpenBookingModal: (numberOfSlots: number, bookingUuid: string) => Promise<void>
  onShowScoreCard: () => void
}

export const InfoBook = (props: InfoBookProps) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      {props.teeTime?.is_prime_time ? <FontAwesomeIcon icon={faStar} /> : null}
      <BookingHeader
        fromTime={props.teeTime?.from}
        loaders={props.loaders}
        bookings={props.bookings}
        course={props.course}
        format="compact"
      />
      <div className={'flex w-full items-center justify-between'}>
        <ReserveNewBookingButton
          teeTime={props.teeTime}
          teeTimePrice={props.teeTimePrice}
          loaders={props.loaders}
          onClick={props.handleOnRequestOpenBookingModal}
        />
        {props?.club?.is_score_card_printing_enabled === true && (
          <Button
            size="larger"
            width="auto"
            className={'m-0 h-[24px] self-end pb-0 align-baseline'}
            text={t('sentences.printScoreCard')}
            theme="gray-outline"
            onClick={props.onShowScoreCard}
          />
        )}
      </div>
    </React.Fragment>
  )
}
