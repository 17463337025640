import { useMemo } from 'react'
import { useManageRangeContext } from '../ManageRangeProvider'
import RangeSelector from './RangeSelector'

const FilterToolbar = () => {
  const { ranges } = useManageRangeContext()
  const hasRanges = useMemo(() => ranges?.length > 0, [ranges])

  if (!hasRanges) return null

  return (
    <div className={'flex items-end justify-between px-6 py-4'}>
      <div className={'w-[398px]'}>
        <RangeSelector />
      </div>
    </div>
  )
}

export default FilterToolbar
