import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import DropdownHeader from '../DropdownHeader'
import ValueText from '../ValueText'

const IncompatiblePromotions = ({ incompatiblePromotions }) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  return (
    <div className={cx(styles.container)}>
      <DropdownHeader open={open} setOpen={setOpen} title={t('sentences.incompatiblePromotions')} />
      <div className={cx(styles.fold, open && styles.open)}>
        {incompatiblePromotions.map((promo) => (
          <ValueText key={promo.id}>{promo.name}</ValueText>
        ))}
      </div>
    </div>
  )
}

IncompatiblePromotions.propTypes = {
  incompatiblePromotions: PropTypes.array,
}

IncompatiblePromotions.defaultProps = {}

export default IncompatiblePromotions
