import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { GolfCourseTypes } from '@sweetspot/shared/types'

import { EmptyVenueText } from '../EmptyVenuPageText/EmptyVenuPageText'

type EmptyVenueProps = {
  courseType: GolfCourseTypes
  noVenues: boolean
  noSpaces: boolean
  noTeeTimes: boolean
  noPricing: boolean
  noBays: boolean
}

const EmptyVenue = ({
  courseType,
  noVenues,
  noSpaces,
  noTeeTimes,
  noPricing,
  noBays,
}: EmptyVenueProps) => {
  const { t } = useTranslation()

  const { currentClubName } = useSelector((state: RootState) => {
    const currentClub = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )
    return {
      currentClubName: currentClub?.name as string,
    }
  })

  const iconName = useMemo(() => {
    switch (courseType) {
      case GolfCourseTypes.SIMULATOR:
        return 'fa-kit fa-sim-light'
      case GolfCourseTypes.DRIVING_RANGE:
        return 'fa-kit fa-range-light'
      case GolfCourseTypes.PRO:
        return 'fa-kit fa-pro-light'
      case GolfCourseTypes.OTHER:
        return 'fa-sharp fa-regular fa-calendar-range'
      default:
        return 'fa-kit fa-tee-light'
    }
  }, [courseType])

  return (
    <div className="flex h-full w-full items-center justify-center">
      <EmptyState
        className="max-w-[480px]"
        iconName={`${iconName} bg-background-mono-lighter mb-4 flex rounded-full p-[22px]`}
      >
        {noVenues ? (
          <>
            {courseType === GolfCourseTypes.DRIVING_RANGE && (
              <EmptyStateTitle>{t('settings.bays.noRangesCreated')}</EmptyStateTitle>
            )}
            <EmptyStateDescription>
              <EmptyVenueText courseType={courseType} currentClubName={currentClubName} />
            </EmptyStateDescription>
            <div className="flex items-center justify-center gap-4">
              <NavLink
                style={{ textDecoration: 'none' }}
                to={
                  courseType === GolfCourseTypes.DRIVING_RANGE
                    ? '/settings/manage-range#new'
                    : '/settings/create-course'
                }
              >
                <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
                  {courseType === GolfCourseTypes.DRIVING_RANGE
                    ? t('settings.bays.createFirstRange')
                    : t('sentences.createCourse')}
                </EmptyStateButton>
              </NavLink>
              {courseType !== GolfCourseTypes.DRIVING_RANGE && (
                <NavLink style={{ textDecoration: 'none' }} to="/settings/edit-course">
                  <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
                    {t('sentences.modifyCourse')}
                  </EmptyStateButton>
                </NavLink>
              )}
            </div>
          </>
        ) : noSpaces ? (
          <>
            <EmptyStateDescription>{t('sentences.noSpacesOnCurrentCourse')}.</EmptyStateDescription>
            <NavLink style={{ textDecoration: 'none' }} to="/settings/create-space">
              <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
                {t('sentences.createSpace_other')}
              </EmptyStateButton>
            </NavLink>
          </>
        ) : noBays ? (
          <>
            <EmptyStateTitle>{t('settings.bays.noBaysCreated')}</EmptyStateTitle>
            <NavLink style={{ textDecoration: 'none' }} to="/settings/bays">
              <EmptyStateButton className="text-content-sm font-medium">
                {t('settings.bays.createFirstBay')}
              </EmptyStateButton>
            </NavLink>
          </>
        ) : noTeeTimes ? (
          <>
            <EmptyStateDescription>
              {t('sentences.noTimesGeneratedOnSpacesYet')}
              {'. '}
              {t('sentences.ifYouHaveAlreadyCreatedPeriodsPleaseWaitWhileTheyAreGenerated')}.
            </EmptyStateDescription>
            <NavLink style={{ textDecoration: 'none' }} to="/settings/tee-time-periods">
              <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
                {t('sentences.createPeriod')}
              </EmptyStateButton>
            </NavLink>
          </>
        ) : noPricing ? (
          <>
            <EmptyStateDescription>{t('sentences.noPricingPeriodsCreated')}.</EmptyStateDescription>
            <NavLink style={{ textDecoration: 'none' }} to="/settings/pricing">
              <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
                {t('sentences.managePricing')}
              </EmptyStateButton>
            </NavLink>
          </>
        ) : null}
      </EmptyState>
    </div>
  )
}

export default EmptyVenue
