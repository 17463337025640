import m from 'moment'

import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'

import { useStyles } from '@sweetspot/sweetspot-js/common/hooks/useStyles'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const PaymentLinkNotes = ({ styles: propStyles, isLoading, payments }) => {
  const cx = useStyles(propStyles)
  const { t } = useTranslation()

  if (!payments) return

  const filteredPayments = payments
    ?.filter((booking) => booking.payment_link && booking.payment_link.note)
    ?.map((booking) => ({
      id: booking.id,
      author: booking.payment_link.delivery_methods.owner_name,
      time: booking.payment_link.created_at,
      text: booking.payment_link.note,
      is_down_payment: booking.is_down_payment,
    }))

  const paymentsWithoutSameNote = filteredPayments?.filter(
    (payment, index, self) => index === self.findIndex((p) => p.text === payment.text)
  )

  if (paymentsWithoutSameNote.length === 0) return

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.notesContainer)}>
        {isLoading ? (
          <div className={cx(styles.note)} key="placeholder">
            <Skeleton width="100%" height="30px" />
          </div>
        ) : (
          paymentsWithoutSameNote?.map((payment) => {
            if (!payment) return null
            return (
              <div className={cx(styles.note)} key={payment.id}>
                <div className={cx(styles.headerArea)}>
                  <p className={cx(styles.author)}>{payment.author}</p>
                  <p className={cx(styles.time)}>{m(payment.time).format('DD.MM.YYYY HH:mm')}</p>
                  <p className={cx(styles.author, '!pl-1.5')}>
                    (
                    {paymentsWithoutSameNote.length === 1
                      ? t('paymentLinks.singular')
                      : payment.is_down_payment
                      ? t('paymentLinks.firstPaymentLink')
                      : paymentsWithoutSameNote.length === 2
                      ? t('paymentLinks.finalPaymentLink')
                      : t('paymentLinks.singular')}
                    )
                  </p>
                </div>
                <p className={cx(styles.text)}>{payment.text}</p>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default PaymentLinkNotes
