import React, { useMemo } from 'react'
import classnames from 'classnames'
import moment from 'moment'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import UserAvatar from '@sweetspot/club-portal-legacy/resources/images/user_avatar.svg'

import styles from './style.module.scss'
import { FederationPlayer, Player } from '@sweetspot/shared/types'
import { generateSidebarItems } from '@sweetspot/club-portal-legacy/modals/PlayerCard/utils/sidebar'

type SidebarProps = {
  sidebar: number
  federationInfo: FederationPlayer | null
  info: Player | null
  onChange: (id: number) => void
  isUkUser: boolean
}

const Sidebar: React.FC<SidebarProps> = ({ sidebar, federationInfo, info, onChange, isUkUser }) => {
  const sideBarItems = useMemo(
    () => generateSidebarItems(sidebar, federationInfo, isUkUser),
    [federationInfo, sidebar, isUkUser]
  )

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <img className="inline" src={UserAvatar} alt="user" />
      </div>
      {sideBarItems.map((menuItem) => (
        <div
          key={menuItem.id}
          className={classnames(styles.menuItem, {
            [styles.active]: menuItem.isActive,
            [styles.disabled]: menuItem.disabled,
          })}
          onClick={() => onChange(menuItem.id)}
        >
          <Text textId={menuItem.text} />
        </div>
      ))}
      <div className={styles.bottom}>
        <div>
          <Text textId="created" />
          <span>
            {info?.registered_at ? moment(info.registered_at).format('YYYY-MM-DD, HH:mm') : '-'}
          </span>
        </div>

        <div>
          <Text textId="lastLogin" />
          <span>
            {info?.last_login ? moment(info.last_login).format('YYYY-MM-DD, HH:mm') : '-'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
