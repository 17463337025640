import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import {
  ClubPriceBreakdown,
  CreateTeeSheetLinkPayload,
  TeeSheetLink,
  TeeTimesPriceBreakdownPayload,
} from './types'
import { Venue } from '@sweetspot/shared/types'

export const createTeeSheetLink = (
  courseId: number,
  payload: CreateTeeSheetLinkPayload
): Promise<TeeSheetLink> => {
  const URL = `${getApiPlatformBaseUrl()}/courses/${courseId}/tee-sheet-link`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export const getTeeSheetData = (hash: string): Promise<TeeSheetLink> => {
  const URL = `${getApiPlatformBaseUrl()}/tee-sheet-links/${hash}`
  return request(URL, {
    method: 'GET',
  })
}

/**
 *
 * @param hole set to null to remove image
 */
export const setGuideImageHole = (id: number, hole: number): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/course-guide-images/${id}`

  return request(URL, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: { hole },
  })
}

export const deleteGuideImage = (id: number): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/course-guide-images/${id}`

  return request(URL, {
    method: 'DELETE',
  })
}

export const getTeeTimesPriceBreakdown = (
  clubId: number,
  payload: TeeTimesPriceBreakdownPayload
): Promise<ClubPriceBreakdown> => {
  const URL = `${getApiPlatformBaseUrl()}/clubs/${clubId}/price-breakdown`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export const getCourseById = (courseId: number | undefined): Promise<Venue> => {
  const url = `${getApiPlatformBaseUrl()}/courses/${courseId}`
  return request(url)
}
