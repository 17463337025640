import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import Form from './components/Form'

import {
  getWebBookingSetting,
  setWebBookingSetting,
  addToast,
} from '@sweetspot/club-portal-legacy/store/actions'

import {
  _uploadMedia,
  _updateUrlSlug,
} from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

import style from './style.module.scss'

class WebBookings extends Component {
  state = {
    isLoading: false,
    isShowCP: false,
    form: {
      status: false,
      headerColor: '#ffffff',
      textColor: 'black',
      showClubName: 1,
      url: '',
      bgImage: null,
      logo: null,
      error: '',
    },
    initialForm: {},
  }

  componentDidMount() {
    this.getWebBookingSetting()
  }

  componentDidUpdate(prevProps) {
    if (this.props.golfClubs?.selectedId != prevProps.golfClubs?.selectedId) {
      this.getWebBookingSetting()
    }
  }

  getWebBookingSetting() {
    const { form } = this.state
    const {
      token,
      golfClubs: { selectedId },
      getWebBookingSetting,
    } = this.props

    this.setState({ isLoading: true })
    getWebBookingSetting(token, selectedId)
      .then(({ web_booking_setting: webBooking, slug }) => {
        const formData = {
          ...form,
          status: webBooking?.is_active,
          showClubName: webBooking?.is_club_name ? 1 : 0,
          headerColor: webBooking?.header_color,
          textColor: webBooking?.text_color,
          bgImage: webBooking?.background_image,
          logo: webBooking?.logo,
          url: slug,
        }
        this.setState({
          isLoading: false,
          form: formData,
          initialForm: formData,
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleChange = (name, value) => {
    const { form } = this.state

    this.setState({ form: { ...form, [name]: value } })
  }

  updateWebBookingSettings = async () => {
    const {
      form: { status, headerColor, textColor, showClubName, url, bgImage, logo },
      initialForm,
    } = this.state
    const {
      token,
      lang,
      golfClubs: { selectedId },
      setWebBookingSetting,
      addToast,
    } = this.props

    let payload = {
      is_active: status,
      is_club_name: !!showClubName,
      header_color: headerColor,
      text_color: textColor,
    }

    this.setState({ isLoading: true })
    let error,
      errMsg = 'updateWebBookingSettingError'
    if (initialForm.url !== url) {
      ;[, error] = await to(_updateUrlSlug(token, selectedId, { slug: url, lang }))
      errMsg = error?.violations[0]?.message
    }
    const [res, err] = await this.uploadMedia({ bgImage, logo })
    if (err || error) {
      addToast(errMsg, 'error')
      this.setState({ isLoading: false })
    } else {
      if (bgImage) {
        if (!bgImage.id) {
          payload['background_image'] = res[0]?.id
        } else {
          payload['background_image'] = bgImage.id
        }
      }
      if (logo) {
        if (!logo.id) {
          payload.logo = res[1]?.id || res[0]?.id
        } else {
          payload.logo = logo.id
        }
      }
      setWebBookingSetting(token, selectedId, payload)
        .then(() => {
          this.setState({ isLoading: false, initialForm: this.state.form })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  uploadMedia = async ({ bgImage, logo }) => {
    const { token } = this.props

    let media = new FormData()
    const promises = []

    if (bgImage && !bgImage?.id) {
      media.append('file', bgImage)
      promises.push(_uploadMedia(token, media))
    }
    if (logo && !logo?.id) {
      media.append('file', logo)
      promises.push(_uploadMedia(token, media))
    }

    return await to(Promise.all(promises))
  }

  isValidForm = () => {
    const { isLoading, form, initialForm } = this.state

    if (isLoading || form.error) return false
    if (_.isEqual(form, initialForm)) return false
    return /^[A-Za-z0-9-_]+$/.test(form.url)
  }

  render() {
    const { isLoading, form } = this.state
    const { isSuperAdmin } = this.props

    return (
      <div className={style.wrapper}>
        <Form
          disabled={isLoading}
          isSuperAdmin={isSuperAdmin}
          submitDisabled={!this.isValidForm()}
          {...form}
          onChange={this.handleChange}
          onSubmit={this.updateWebBookingSettings}
        />

        <div className={style.preview}></div>
      </div>
    )
  }
}

WebBookings.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubs: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  getWebBookingSetting: PropTypes.func.isRequired,
  setWebBookingSetting: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.me.lang,
  golfClubs: state.golfClub,
  isSuperAdmin: state.auth.isSuperAdmin,
})

const mapDispatchToProps = (dispatch) => ({
  getWebBookingSetting: (token, clubId) => dispatch(getWebBookingSetting(token, clubId)),
  setWebBookingSetting: (token, clubId, payload) =>
    dispatch(setWebBookingSetting(token, clubId, payload)),
  addToast: (msg, status) => dispatch(addToast(msg, status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WebBookings)
