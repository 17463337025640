export const ANNUAL_DURATION = 1

export enum MEMBERSHIP_REFUND_VIOLATION_ERRORS {
  MEMBERSHIP_CARD_HAS_NO_PAYMENTS = 'MEMBERSHIP_CARD_HAS_NO_PAYMENTS',
  MEMBERSHIP_CARD_REFUND_INITIATED = 'MEMBERSHIP_CARD_REFUND_INITIATED',
  MEMBERSHIP_CARD_ALREADY_REFUNDED = 'MEMBERSHIP_CARD_ALREADY_REFUNDED',
}

export const MEMBERSHIP_REFUND_VIOLATION_ERRORS_MAP: Record<
  MEMBERSHIP_REFUND_VIOLATION_ERRORS,
  string
> = {
  [MEMBERSHIP_REFUND_VIOLATION_ERRORS.MEMBERSHIP_CARD_HAS_NO_PAYMENTS]:
    'toast.membershipCardHasNoPayment',
  [MEMBERSHIP_REFUND_VIOLATION_ERRORS.MEMBERSHIP_CARD_REFUND_INITIATED]:
    'toast.membershipCardRefundInitiated',
  [MEMBERSHIP_REFUND_VIOLATION_ERRORS.MEMBERSHIP_CARD_ALREADY_REFUNDED]:
    'toast.membershipCardAlreadyRefunded',
}
