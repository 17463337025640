import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CloseIcon from '@sweetspot/sweetspot-js/common/components/CloseIcon'
import styles from './styles.module.scss'

const Pill = ({
  onClose,
  disabled,
  className,
  text,
  useCloseButton,
  visible,
  onClick,
  width,
  useTitle,
}) => {
  const [isClosed, setIsClosed] = useState(!visible)

  const container = useRef()

  const handleClose = (e) => {
    e.preventDefault()
    setIsClosed(true)
    if (onClose) onClose(container)
  }

  return (
    !isClosed && (
      <div
        className={cx('system-pill primary', {
          disabled: disabled,
          [className]: !!className,
        })}
        style={{ width: width }}
        onClick={onClick}
      >
        <div className={cx(styles.container)} title={useTitle ? text : null}>
          <div className={cx(styles.content)}>{text}</div>
          {(useCloseButton || onClose) && (
            <CloseIcon
              onClick={handleClose}
              iconType="google"
              className={cx(styles.closeButton)}
            ></CloseIcon>
          )}
        </div>
      </div>
    )
  )
}

Pill.propTypes = {
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  useCloseButton: PropTypes.bool,
  text: PropTypes.string,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
  useTitle: PropTypes.bool,
}

Pill.defaultProps = {
  visible: true,
  disabled: false,
  useCloseButton: false,
  onClose: null,
  text: '',
  useTitle: true,
}

export default Pill
