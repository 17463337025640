import React, { useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { useDrag, useDrop } from 'react-dnd'

import styles from './styles.module.scss'

const DraggableComponent = ({
  className,
  index,
  id,
  itemType,
  moveItem,
  onDrop,
  onBeginDrag,
  canDrag,
  render,
}) => {
  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: itemType,
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveItem(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: () => {
      if (onBeginDrag) onBeginDrag()
      return { id, index, originalIndex: index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult) => {
      const { originalIndex, id: itemId, index: newIndex } = dropResult
      onDrop(itemId, newIndex, originalIndex)
    },
    canDrag: () => {
      return canDrag
    },
  })

  preview(drop(ref))
  const opacity = isDragging ? 0 : 1

  return (
    <div ref={ref} className={cx(styles.container, className)} style={{ opacity }}>
      {render(drag)}
    </div>
  )
}

DraggableComponent.propTypes = {
  itemType: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.number,
  moveItem: PropTypes.func,
  onDrop: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBeginDrag: PropTypes.func,
  canDrag: PropTypes.bool,
  render: PropTypes.func,
}

DraggableComponent.defaultProps = {
  itemType: 'item',
  canDrag: true,
}

export default DraggableComponent
