import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import ToggleSwitch from '@sweetspot/club-portal-legacy/components/ToggleSwitch'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import {
  getGolfCourseImages,
  getGolfCoursesFromGolfClub,
  getSpecificGolfClub,
  getSpecificGolfCourse,
  updateGolfCourse,
  uploadGolfCourseImage,
} from '@sweetspot/club-portal-legacy/store/actions'
import { getSingleClub } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  _uploadMedia,
  syncTeesFromGit,
} from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { useTranslation } from 'react-i18next'

import ExpandingPills from '@sweetspot/club-portal-legacy/components/ExpandingPills'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import Pill from '@sweetspot/sweetspot-js/common/components/Pill'
import {
  LAST_CANCELLATION_POINT,
  MINUTES_180_MS,
  MINUTES_5_MS,
  DAY_MS,
  getNonConfirmedBookingDurationOptions,
  getUnpaidBookingDurationOptions,
} from '@sweetspot/sweetspot-js/common/constants/autoCancelSettingsOptions'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { useMembership } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembership'
import { COURSE_TYPES_ARR } from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import style from './style.module.scss'

import ConfirmModal from '@sweetspot/club-portal-legacy/components/ConfirmModal'
import { GENDER_KEYS } from '@sweetspot/shared/util/constants'
import { useTimeZone } from '@sweetspot/sweetspot-js/common/react-query/hooks/useTimeZone'

const TEETIME_SOURCE = [
  { id: 'git', name: 'GIT' },
  { id: 'sweetspot', name: 'Sweetspot' },
  { id: 'none', name: 'None' },
]
const BOOKING_STATUS = [
  { id: -1, name: 'Sweetspot' },
  { id: 1, name: 'Phone' },
  { id: 3, name: 'Automatic' },
  { id: 4, name: 'Automatic with phone' },
]

const DEFAULT_CANCEL_POINT = 3

const initialForm = {
  gitId: '',
  name: '',
  description: '',
  displayDays: '',
  bookingInfo: '',
  customBookingEmailInfo: '',
  importantBookingInfo: '',
  active: true,
  longitude: '',
  latitude: '',
  enablePayment: false,
  enableArrivalRegistration: true,
  enableARAfterSchedule: false,
  enableStubPlayers: false,
  enablePayOnSite: false,
  customPayOnSiteTitle: '',
  customPayOnSiteDesc: '',
  enableMembershipSignup: false,
  allowedMemberships: [],
  teeTimeSource: 'sweetspot',
  bookingType: -1,
  type: '',
  vat: '',
  nonConfirmedBookingAutoCancellationEnabled: false,
  nonConfirmedBookingAutoCancellationDuration: MINUTES_5_MS,
  unpaidBookingAutoCancellationEnabled: false,
  unpaidBookingAutoCancellationDuration: MINUTES_180_MS,
  lastCancelPoint: DEFAULT_CANCEL_POINT,
}

const ModifyGolfcourse = ({
  token,
  golfClubList,
  golfClub,
  getGolfCoursesFromGolfClub,
  getSpecificGolfCourse,
  getSpecificGolfClub,
  getGolfCourseImages,
  uploadGolfCourseImage,
  updateGolfCourse,
  isSA,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [federationName, setFederationName] = useState(null)
  const [golfCourseList, setGolfCourseList] = useState([])
  const [activeGolfCourse, setActiveGolfCourse] = useState(null)
  const [activeGolfClub, setActiveGolfClub] = useState(null)
  const [imageUploadErr, setImageUploadErr] = useState('')
  const [image, setImage] = useState(null)
  const [form, setForm] = useState(initialForm)
  const [syncingTees, setSyncingTees] = useState(false)
  const [memberships, setMemberships] = useState([])
  const [selectedClub, setSelectedClub] = useState(null)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [filteredCourses, setFilteredCourses] = useState([])
  const [membershipsThatWillBeRemoved, setMembershipsThatWillBeRemoved] = useState([])
  const [
    isUnpaidBookingAutoCancellationDurationCustom,
    setUnpaidBookingAutoCancellationDurationCustom,
  ] = useState(false)
  const [durationErrors, setDurationErrors] = useMergeState({
    nonConfirmedBookingAutoCancellationDuration: '',
    unpaidBookingAutoCancellationDuration: '',
  })

  useEffect(() => {
    if (selectedClub?.id) {
      setLoading(true)

      getSingleClub(selectedClub?.id)
        .then((fetchedClub) => {
          const { cdh_id, git_id } = fetchedClub

          if (cdh_id || git_id) {
            if (cdh_id) setFederationName('cdh')

            if (git_id) setFederationName('git')
          } else {
            setFederationName(null)
          }

          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }, [selectedClub])

  const { t } = useTranslation()

  const lastCancelPointOptions = useMemo(() => {
    return LAST_CANCELLATION_POINT.map((option) => ({
      ...option,
      label: t(option.label.translation, option.label.options),
    }))
  }, [t])

  const _bookingType = useRef()
  const _teeTimeSource = useRef()
  const _allowedMembershipSelect = useRef()
  const _timezoneSelect = useRef()
  const { addToast } = useToasts()
  const { timezones } = useTimeZone()

  const { data: membershipData } = useMembership({
    clubId: selectedClub?.id,
    state: 'published',
    totalFee: 0,
    totalMonthlyFee: 0,
    sortOrder: 'asc',
    isActive: true,
    enabled: true,
  })

  const { data: invalidMembershipData } = useMembership({
    clubId: selectedClub?.id,
    uuid: membershipsThatWillBeRemoved,
    enabled: selectedClub?.id && membershipsThatWillBeRemoved.length > 0,
  })

  const golfCourses = useSelector((state) => state.golfCourse.list)
  const possibleTypes = new Set(golfCourses.map((course) => course.type))
  const allVenueTypes = [{ name: t('editPeriodsNew.allVenueTypes'), id: 'all' }]
  const typeOptions = [
    ...allVenueTypes,
    ...getGolfCourseTypes(t).filter(
      (golfCourseType) => !golfCourseType.id || possibleTypes.has(golfCourseType.id)
    ),
  ]

  useEffect(() => {
    setFilteredCourses(golfCourseList)
  }, [golfCourseList])

  useEffect(() => {
    handleClubChange(golfClub.selectedId)
  }, [])

  const loadValues = async ({ id }) => {
    setLoading(true)

    // 1. Get synced tees
    // 2. Get last sync info - from course?
    const promises = [
      getSpecificGolfCourse(token, id),
      getGolfCourseImages(id),
      getSpecificGolfClub(token, activeGolfClub?.id),
    ]

    Promise.all(promises)
      .then(([course, images, club]) => {
        setActiveGolfClub(club)
        let image = ''
        if (images) {
          const lastIndex = images.length - 1
          image =
            images[lastIndex]?.content_url ||
            images[lastIndex]?.media_object?.content_url ||
            images[lastIndex]?.url ||
            ''
        }

        const autoCancellationSettings =
          course?.booking_settings?.auto_cancellation_settings || null

        let autoCancellationData

        if (autoCancellationSettings?.unpaid_booking_settings?.is_enabled) {
          autoCancellationData = UNPAID_BOOKING_DURATION_OPTIONS.find(
            (x) => x.value === autoCancellationSettings?.unpaid_booking_settings?.duration
          )
          if (
            autoCancellationSettings?.unpaid_booking_settings?.is_enabled &&
            (!autoCancellationData ||
              autoCancellationSettings?.unpaid_booking_settings?.duration === 0)
          ) {
            setUnpaidBookingAutoCancellationDurationCustom(true)
          }
        }

        setForm({
          gitId: course['git_id'] || course['cdh_id'],
          name: course['name'],
          description: course['description'],
          displayDays: course['display_tee_time_days'],
          bookingInfo: course['booking_information'] || '',
          customBookingEmailInfo: course['custom_email_information'] || '',
          importantBookingInfo: course['important_booking_information'] || '',
          longitude: course.lonlat['longitude'] || '',
          latitude: course.lonlat['latitude'] || '',
          active: course['is_active'],
          useDynamicPricing: course['is_use_dynamic_pricing'],
          bookingType: course['booking_type'],
          teeTimeSource: course['tee_time_source'],
          enablePayment: course['is_can_pay'],
          enableArrivalRegistration: course['is_arrival_registration'],
          enableARAfterSchedule: course['is_arrival_registration_after_schedule'],
          enableStubPlayers: course['is_stub_players_enabled'],
          enablePayOnSite: course['is_pay_on_site_enabled'],
          customPayOnSiteTitle: course['pay_on_site_title'] || '',
          customPayOnSiteDesc: course['pay_on_site_description'] || '',
          enableMembershipSignup: course['membership_sign_up_settings']?.is_enabled,
          timezone: course['timezone'],
          allowedMemberships: getInitialMemberships(
            course['membership_sign_up_settings']?.memberships
          ),
          courseImage: image,
          type: course['type'],
          vat: course['vat'],
          nonConfirmedBookingAutoCancellationEnabled:
            autoCancellationSettings?.non_confirmed_booking_settings?.is_enabled || false,
          nonConfirmedBookingAutoCancellationDuration:
            autoCancellationSettings?.non_confirmed_booking_settings?.duration || MINUTES_5_MS,
          unpaidBookingAutoCancellationEnabled:
            autoCancellationSettings?.unpaid_booking_settings?.is_enabled || false,
          unpaidBookingAutoCancellationDuration: autoCancellationData
            ? autoCancellationSettings?.unpaid_booking_settings?.duration
            : '',
          unpaidBookingAutoCancellationDays: !autoCancellationData
            ? autoCancellationSettings?.unpaid_booking_settings?.duration / DAY_MS
            : '',
          lastCancelPoint:
            course['booking_cancellation_limit_hours'] !== null &&
            course['booking_cancellation_limit_hours'] !== undefined
              ? course['booking_cancellation_limit_hours']
              : DEFAULT_CANCEL_POINT,
        })
        setLoading(false)
        _bookingType.current.setSelectedId(course['booking_type'])
        _teeTimeSource.current.setSelectedId(course['tee_time_source'])
      })
      .catch(() => {
        addToast(t('toast.fetchSpecificGolfcourseError'), { appearance: 'error' })
        setLoading(false)
      })
  }

  const getInitialMemberships = (membershipIds) => {
    const initialMembers = []
    const toBeRemoved = []

    let membershipCopy = membershipData?.slice()
    for (let id of membershipIds) {
      let index = membershipCopy.findIndex((elem) => elem.uuid === id)
      if (index > -1) {
        let tempItem = membershipCopy[index]
        initialMembers.push(tempItem)
        // membershipCopy = memberships.filter(item => item.uuid !== id);
        membershipCopy.splice(index, 1)
      } else {
        toBeRemoved.push(id)
      }
    }
    setMembershipsThatWillBeRemoved(toBeRemoved)
    setMemberships(membershipCopy)
    return initialMembers
  }

  const checkForErrors = ({
    name,
    booking_type,
    tee_time_source,
    pay_on_site_title,
    pay_on_site_description,
    display_tee_time_days,
    vat,
  }) => {
    if (name?.trim() === '') return 'settings.nameRequired'
    if (booking_type === null) return 'settings.selectBookingType'
    if (tee_time_source === null) return 'settings.selectTeeTime'
    if (pay_on_site_title?.length > 24) return 'settings.payOnSiteTitleError'
    if (pay_on_site_description?.length > 160) return 'settings.payOnSiteDescError'
    if (display_tee_time_days !== null && display_tee_time_days < 0)
      return 'sentences.invalidDisplayTeeTimeDays'
    if (imageUploadErr) return imageUploadErr
    if (vat < 0 || vat > 100)
      return `${t('words.vat')} - ${t('errors.valueMustBeBetweenThese', { this: 0, that: 100 })}`

    return ''
  }

  const sortByName = (a, b) => {
    let aName = a.toUpperCase()
    let bName = b.toUpperCase()
    if (aName < bName) return -1
    if (aName === bName) return 0
    if (aName > bName) return 1
  }

  const handleMembershipChange = (id, action) => {
    // Add
    if (action === 'add') {
      let newMembers = [...form.allowedMemberships, memberships.find((item) => item.id === id)]
      setMemberships(memberships.filter((item) => item.id !== id))
      handleChange('allowedMemberships', newMembers)
    }
    // Remove
    else if (action === 'remove') {
      let tempMembership = form.allowedMemberships.find((item) => item.id === id)
      let tempMemberships = memberships.slice()
      tempMemberships.push(tempMembership)
      tempMemberships.sort((a, b) => sortByName(a.name, b.name))
      setMemberships(tempMemberships)
      handleChange(
        'allowedMemberships',
        form.allowedMemberships.filter((item) => item.id !== id)
      )
      // _allowedMembershipSelect.current.setSelectedId(null);
    }
  }

  const handleClubChange = (id) => {
    const club = golfClubList.find((club) => club.id === id)
    if (!club) return
    setActiveGolfCourse(null)
    setForm(initialForm)
    setActiveGolfClub(club)
    getGolfCoursesFromGolfClub(club.id)
      .then((courses) => {
        // Filter out the courses that are part of a range context
        const filteredCourses = courses?.filter((course) => !course.belongs_to_range_context)
        setGolfCourseList(filteredCourses)
      })
      .catch(() => {
        setGolfCourseList([])
      })
    setSelectedClub(club)
  }

  const handleCourseChange = async (courseId) => {
    const [golfCourse] = await to(getSpecificGolfCourse(token, courseId))

    if (golfCourse) {
      setActiveGolfCourse(golfCourse)
      loadValues(golfCourse)
    }
  }

  const onCourseTypeChange = (value) => {
    if (value === 'all') return setFilteredCourses(golfCourseList)
    setFilteredCourses(golfCourseList.filter((course) => course.type === value))
  }

  const handleImageUpload = (e) => {
    const image = e.target.files[0]
    if (!image) return
    const types = ['image/jpeg', 'image/png']

    if (types.every((type) => image.type !== type)) {
      setImageUploadErr('settings.imageTypeError')
    } else if (image.size >= 100000) {
      setImageUploadErr('settings.imageSizeError')
    } else {
      setImageUploadErr('')
      setImage(image)
    }
  }

  const syncTees = async () => {
    setSyncingTees(true)
    const [res, err] = await to(syncTeesFromGit(activeGolfClub?.id))

    const promises = [
      getSpecificGolfCourse(token, activeGolfCourse?.id),
      getSpecificGolfClub(token, activeGolfClub?.id),
    ]

    Promise.all(promises)
      .then(([course, club]) => {
        setActiveGolfCourse(course)
        setActiveGolfClub(club)
        if (res) addToast(t('toast.syncTeesSuccess'), { appearance: 'success' })
        else if (err) addToast(t('toast.syncTeesError'), { appearance: 'error' })
        setSyncingTees(false)
      })
      .catch(() => {
        addToast(t('toast.syncTeesError'), { appearance: 'error' })
        setSyncingTees(false)
      })
  }

  const uploadCourseImage = async (image) => {
    let media = new FormData()

    media.append('file', image)

    const [res] = await to(_uploadMedia(token, media))
    if (res) {
      const courseId = activeGolfCourse.id
      return uploadGolfCourseImage(token, courseId, res?.id)
    } else return null
  }

  const handleChange = (name, value) => {
    setForm((form) => ({ ...form, [name]: value }))
  }

  const handleUnpaidBookingAutoCancellationChange = (value) => {
    const { unpaidBookingAutoCancellationDuration } = form
    if (!value) {
      setUnpaidBookingAutoCancellationDurationCustom(false)
    } else if (
      unpaidBookingAutoCancellationDuration === '' ||
      !UNPAID_BOOKING_DURATION_OPTIONS.find(
        (x) => x.value === unpaidBookingAutoCancellationDuration
      )
    ) {
      setUnpaidBookingAutoCancellationDurationCustom(true)
    }
    handleChange('unpaidBookingAutoCancellationEnabled', value)
  }

  const handleUnpaidBookingAutoCancellationDurationChange = (value) => {
    if (UNPAID_BOOKING_DURATION_OPTIONS.find((x) => x.value === value) && value !== '') {
      handleChange('unpaidBookingAutoCancellationDuration', value)
      handleChange('unpaidBookingAutoCancellationDays', '')
      setUnpaidBookingAutoCancellationDurationCustom(false)
      return
    }
    setUnpaidBookingAutoCancellationDurationCustom(true)
    handleChange('unpaidBookingAutoCancellationDuration', value)
  }

  const handelUnpaidBookingAutoCancellationDaysChange = (value) => {
    const durationValue = value.split('.')[0]
    if (+durationValue > 0 && +durationValue <= 90) {
      handleChange('unpaidBookingAutoCancellationDays', +durationValue)
      handleChange('unpaidBookingAutoCancellationDuration', '')
      return
    }
    handleChange('unpaidBookingAutoCancellationDays', '')
  }

  const handleDynamicPriceChange = (value) => {
    value ? handleChange('useDynamicPricing', value) : setShowWarningModal(true)
  }

  const onCancelModal = () => {
    setShowWarningModal(false)
  }

  const onConfirmModal = () => {
    handleChange('useDynamicPricing', false)
    setShowWarningModal(false)
  }

  const saveSettings = () => {
    const {
      gitId,
      name,
      description,
      displayDays,
      bookingInfo,
      customBookingEmailInfo,
      importantBookingInfo,
      active,
      useDynamicPricing,
      longitude,
      latitude,
      teeTimeSource,
      bookingType,
      enablePayment,
      enableArrivalRegistration,
      enableARAfterSchedule,
      enableStubPlayers,
      enablePayOnSite,
      customPayOnSiteTitle,
      customPayOnSiteDesc,
      timezone,
      enableMembershipSignup,
      allowedMemberships,
      type,
      vat,
      nonConfirmedBookingAutoCancellationEnabled,
      nonConfirmedBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationEnabled,
      unpaidBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationDays,
      lastCancelPoint,
    } = form

    const federationId =
      federationName !== null
        ? {
            [federationName === 'git' ? 'git_id' : 'cdh_id']: gitId,
          }
        : {}

    let payload = {
      ...federationId,
      name,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      description,
      display_tee_time_days: displayDays === '' ? null : parseInt(displayDays),
      custom_email_information: customBookingEmailInfo,
      important_booking_information: importantBookingInfo,
      booking_information: bookingInfo,
      is_active: active,
      is_use_dynamic_pricing: useDynamicPricing,
      tee_time_source: teeTimeSource,
      booking_type: bookingType,
      is_can_pay: enablePayment,
      is_arrival_registration: enableArrivalRegistration || false,
      is_arrival_registration_after_schedule: enableARAfterSchedule,
      is_stub_players_enabled: enableStubPlayers,
      is_pay_on_site_enabled: enablePayOnSite,
      pay_on_site_title: enablePayOnSite ? customPayOnSiteTitle : '',
      pay_on_site_description: enablePayOnSite ? customPayOnSiteDesc : '',
      timezone: timezone,
      membership_sign_up_settings: {
        is_enabled: allowedMemberships?.length > 0 ? enableMembershipSignup : false,
        memberships: allowedMemberships.map((item) => {
          return item.uuid
        }),
      },
      type: type,
      vat: parseFloat(vat),
      non_confirmed_booking_auto_cancellation_enabled: nonConfirmedBookingAutoCancellationEnabled,
      non_confirmed_booking_auto_cancellation_duration: nonConfirmedBookingAutoCancellationDuration,
      unpaid_booking_auto_cancellation_enabled: unpaidBookingAutoCancellationEnabled,
      unpaid_booking_auto_cancellation_duration:
        unpaidBookingAutoCancellationDuration !== '' ? unpaidBookingAutoCancellationDuration : null,
      unpaid_booking_auto_cancellation_days: unpaidBookingAutoCancellationDays
        ? unpaidBookingAutoCancellationDays
        : undefined,
      booking_cancellation_limit_hours: lastCancelPoint,
    }

    let errorText = checkForErrors(payload)
    if (errorText) {
      setErrorText(errorText)
    } else {
      setLoading(true)
      const courseId = activeGolfCourse.id
      const promises = []
      if (image) promises.push(uploadCourseImage(image))

      promises.push(updateGolfCourse(token, courseId, payload))

      Promise.all(promises)
        .then(() => {
          handleCourseChange(activeGolfCourse.id)
          setLoading(false)
        })
        .catch((errResponse) => {
          setLoading(false)
          errResponse.json().then((data) => {
            if (data?.violations) {
              data.violations.forEach((err) => {
                if (err?.propertyPath === 'non_confirmed_booking_auto_cancellation_duration') {
                  setDurationErrors({
                    non_confirmed_booking_auto_cancellation_duration: t('errors.fieldInvalid'),
                  })
                } else if (err?.propertyPath === 'unpaid_booking_auto_cancellation_duration') {
                  setDurationErrors({
                    unpaid_booking_auto_cancellation_duration: t('errors.fieldInvalid'),
                  })
                }
              })
            }
          })
        })
    }
  }

  const formatedTimeZones = useMemo(() => {
    if (timezones) {
      const filteredData = timezones.map((zone) => {
        const offset = Math.floor(zone.offset / 3600)
        const offsetString = offset > 0 ? `+${offset}` : offset
        return {
          id: zone.timezone_id,
          name: `(UTC ${offsetString}) ${zone.timezone_id}`,
          ...zone,
        }
      })
      return filteredData
    }
    return []
  }, [timezones])

  const NON_CONFIRMED_BOOKING_DURATION_OPTIONS = getNonConfirmedBookingDurationOptions()
  const UNPAID_BOOKING_DURATION_OPTIONS = getUnpaidBookingDurationOptions()

  const renderForm = () => {
    const {
      gitId,
      name,
      description,
      displayDays,
      bookingInfo,
      customBookingEmailInfo,
      importantBookingInfo,
      active,
      useDynamicPricing,
      longitude,
      latitude,
      enablePayment,
      enableArrivalRegistration,
      enableARAfterSchedule,
      enableStubPlayers,
      enablePayOnSite,
      customPayOnSiteTitle,
      customPayOnSiteDesc,
      timezone,
      enableMembershipSignup,
      allowedMemberships,
      courseImage,
      type,
      vat,
      nonConfirmedBookingAutoCancellationEnabled,
      nonConfirmedBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationEnabled,
      unpaidBookingAutoCancellationDuration,
      unpaidBookingAutoCancellationDays,
      lastCancelPoint,
    } = form

    const getCourseIDLabel = () => {
      const { git_id } = activeGolfClub
      if (git_id && git_id.length) return 'GIT Course ID'

      return 'Course ID'
    }

    const syncedTees = activeGolfCourse?.course_tees
    const syncLogs = activeGolfClub?.federation_sync_result_logs
      ? activeGolfClub?.federation_sync_result_logs[0]
      : null

    const renderAllowedMemberships = () => {
      return (
        <>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.allowSignupDuringBooking')}</label>
            <ToggleSwitch
              checked={enableMembershipSignup}
              onChange={(value) => {
                handleChange('enableMembershipSignup', value)
              }}
            />
          </div>
          {enableMembershipSignup && (
            <div className="ss-form-group">
              <label className="ss-label">
                {t('settings.allowedMembership')}
                <InfoHover textId="settings.infoHoversGolfcourse.allowedMembership" />
              </label>
              <DropdownSelect
                ref={_allowedMembershipSelect}
                showBorder
                width="100%"
                placeholder="select"
                initialId={null}
                values={memberships || []}
                onSelect={(value) => handleMembershipChange(value, 'add')}
              />
              <ExpandingPills>
                {allowedMemberships?.map((item) => (
                  <Pill
                    key={item.id}
                    text={item.name}
                    useCloseButton={true}
                    onClose={() => handleMembershipChange(item.id, 'remove')}
                  />
                ))}
              </ExpandingPills>
              {invalidMembershipData && invalidMembershipData.length > 0 && (
                <div className={'rounded border border-gray-500 bg-gray-100 p-2'}>
                  <h5 className="text-sm ">{t('settings.willBeRemoved')}</h5>
                  <ul>
                    {invalidMembershipData.map((data) => {
                      return <li className="text-sm">- {data.name}</li>
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
        </>
      )
    }

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="selects">{renderGolfClubSelect()}</div>

        <TextInputField
          label={t('settings.name')}
          infoTextKey="settings.infoHoversGolfcourse.name"
          disabled={isLoading}
          value={name}
          onChange={(value) => handleChange('name', value)}
        />

        {type === 'course' && federationName && (
          <TextInputField
            label={getCourseIDLabel()}
            infoTextKey="settings.infoHoversGolfcourse.id"
            disabled={isLoading}
            value={gitId}
            onChange={(value) => handleChange('gitId', value)}
          />
        )}

        <TextInputOptionsSelect
          label={t('words.type')}
          infoTextKey="sentences.courseTypeInfoDescription"
          disabled={isLoading}
          value={type ? t(COURSE_TYPES_ARR.find((x) => x.value === type).labelKey) : ''}
          options={COURSE_TYPES_ARR.map((rawType, _i) => ({
            label: t(rawType.labelKey),
            value: rawType.value,
            id: _i,
          }))}
          onOptionSelect={(newType) => handleChange('type', newType.value)}
          containerClassName={style.inputOptionsSelect}
        />
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.imageUpload')}
            <InfoHover textId="settings.infoHoversGolfcourse.golfcourseImage" />
          </label>
          <input className="ss-input" type="file" onChange={handleImageUpload} size="60" />
          {!!imageUploadErr !== '' && <div className={style.imageError}>{t(imageUploadErr)}</div>}
        </div>
        {!!courseImage && (
          <div className={style.golfCourseImage}>
            <Text textId="settings.currentImage" />
            <img src={courseImage} alt="golfcourse" />
            <br />
          </div>
        )}
        <TextInputField
          label={t('settings.description')}
          infoTextKey="settings.infoHoversGolfcourse.description"
          disabled={isLoading}
          value={description}
          onChange={(value) => handleChange('description', value)}
        />
        <TextInputField
          label={t('settings.displayDays')}
          infoTextKey="settings.infoHoversGolfcourse.displayDays"
          disabled={isLoading}
          value={displayDays}
          onChange={(value) => handleChange('displayDays', value)}
        />
        <TextInputField
          label={t('settings.bookingInfo')}
          infoTextKey="settings.infoHoversGolfcourse.bookingInfo"
          type="text-area"
          rows={4}
          disabled={isLoading}
          value={bookingInfo}
          onChange={(value) => handleChange('bookingInfo', value)}
        />
        <TextInputField
          label={t('settings.importantBookingInfo')}
          infoTextKey="settings.infoHoversGolfcourse.importantBookingInfo"
          type="text-area"
          rows={4}
          disabled={isLoading}
          value={importantBookingInfo}
          onChange={(value) => handleChange('importantBookingInfo', value)}
        />
        <TextInputField
          label={t('settings.customBookingEmailInfo')}
          infoTextKey="settings.infoHoversGolfcourse.customBookingEmailInfo"
          type="text-area"
          rows={4}
          disabled={isLoading}
          value={customBookingEmailInfo}
          onChange={(value) => handleChange('customBookingEmailInfo', value)}
        />
        {isSA && (
          <div className="ss-form-group">
            <label className="ss-label">
              {t('words.timezone')}
              <InfoHover textId="sentences.timeZoneSelectDetails" />
            </label>
            <DropdownSelect
              showBorder
              width="100%"
              initialId={timezone}
              ref={_timezoneSelect}
              values={formatedTimeZones}
              onSelect={(value) => handleChange('timezone', value)}
            />
          </div>
        )}
        <TextInputField
          label={t('settings.latitude')}
          infoTextKey="settings.infoHoversGolfcourse.latitude"
          disabled={isLoading}
          value={latitude}
          onChange={(value) => handleChange('latitude', value)}
        />
        <TextInputField
          label={t('settings.longitude')}
          infoTextKey="settings.infoHoversGolfcourse.longitude"
          disabled={isLoading}
          value={longitude}
          onChange={(value) => handleChange('longitude', value)}
        />
        <TextInputField
          label={t('words.vat')}
          disabled={isLoading}
          value={vat}
          onChange={(value) => handleChange('vat', value)}
        />

        {/* Sync tees - saved independently on sync. Only show if git_id is set*/}
        {selectedClub?.git_id !== '' && activeGolfCourse && form.gitId !== '' && (
          <div className={style.syncTeesWrapper}>
            <label className="ss-label">
              {t('settings.tees')}
              <InfoHover textId="settings.infoHoversGolfcourse.syncTees" />
            </label>

            {syncedTees?.length && syncLogs?.last_successful_result_time ? (
              <div className={style.teesTable}>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <Text textId={t('words.tee', { count: syncedTees.length })} />
                      </th>
                      <th>
                        <Text textId={t('words.gender')} />
                      </th>
                      <th>
                        <Text textId={t('words.course_rating')} />
                      </th>
                      <th>
                        <Text textId={t('words.slope')} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {syncedTees
                      .sort((a, b) => a.color - b.color)
                      .map((tee) => (
                        <tr key={tee?.id}>
                          <td>{tee?.color}</td>
                          <td>
                            {t(GENDER_KEYS.find((x) => x.gitValue == tee?.gender).translationKey)}
                          </td>
                          <td>{tee?.course_rating}</td>
                          <td>{tee?.slope_value}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : null}

            {(!syncedTees?.length || !activeGolfCourse) && (
              <p className={style.grayBold}>{t('settings.teesNotYetSynced')}</p>
            )}

            {!syncedTees?.length && (syncedTees?.status || syncedTees?.status === 'failed') ? (
              <p className={style.grayBold}>{t('settings.noTeesExist')}</p>
            ) : null}

            {syncLogs?.last_successful_result_time && syncedTees?.length > 0 && (
              <p className={cx(style.lastSync)}>
                {t('settings.lastSuccesfulSyncWithGIT')}
                &nbsp;
                {DateHelpers.toFullDateString(syncLogs?.last_successful_result_time)}
              </p>
            )}
            {syncLogs?.last_failed_result_time && syncedTees?.length > 0 && (
              <p className={cx(style.lastSync, style.failed)}>
                {t('settings.lastFailedSyncWithGIT')}
                &nbsp;
                {DateHelpers.toFullDateString(syncLogs?.last_failed_result_time)}
              </p>
            )}
            <Button
              disabled={!activeGolfCourse}
              loading={syncingTees}
              theme={'gray'}
              size={'small'}
              width={'165px'}
              loaderStyle={'pulse'}
              onClick={() => syncTees()}
            >
              <div className={style.syncIcon}>
                <FontAwesomeIcon icon={faRotate} />
              </div>
              {t('settings.syncTeesFromGIT')}
            </Button>
          </div>
        )}
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.active')}
            <InfoHover textId="settings.infoHoversGolfcourse.active" />
          </label>
          <ToggleSwitch checked={active} onChange={(value) => handleChange('active', value)} />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.useDynamicPricing')}
            <InfoHover textId="settings.infoHoversGolfcourse.dynamicPricing" />
          </label>
          <ToggleSwitch
            checked={useDynamicPricing}
            onChange={(value) => handleDynamicPriceChange(value)}
          />
          {showWarningModal && (
            <div>
              <ConfirmModal
                headerText="settings.useDynamicPricing"
                confirmText="confirm"
                onCancel={onCancelModal}
                onConfirm={onConfirmModal}
              >
                <div className={'flex flex-col gap-2'}>
                  {t('settings.useDynamicPricingWarningText')
                    .split('\n\n')
                    .map((p) => (
                      <h3 key={p}>{p}</h3>
                    ))}
                </div>
              </ConfirmModal>
            </div>
          )}
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enablePayment')}
            <InfoHover textId="settings.infoHoversGolfcourse.enablePayment" />
          </label>
          <ToggleSwitch
            checked={enablePayment}
            onChange={(value) => handleChange('enablePayment', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableArrivalRegistration')}
            <InfoHover textId="settings.infoHoversGolfcourse.enableArrivalRegistration" />
          </label>
          <ToggleSwitch
            checked={enableArrivalRegistration}
            onChange={(value) => handleChange('enableArrivalRegistration', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableArrivalRegistrationAfterSchedule')}
            <InfoHover textId="settings.infoHoversGolfcourse.enableArrivalRegistrationAfterSchedule" />
          </label>
          <ToggleSwitch
            checked={enableARAfterSchedule}
            onChange={(value) => handleChange('enableARAfterSchedule', value)}
          />
        </div>

        <div className="ss-form-group">
          <label className="ss-label">
            {t('golfcourseForm.labels.lastCancelPoint')}
            <InfoHover textId="golfcourseForm.hovers.lastCancelPoint" />
          </label>
          <TextInputOptionsSelect
            disabled={isLoading}
            value={
              lastCancelPointOptions.find((x) => x.value === lastCancelPoint)?.label ||
              DEFAULT_CANCEL_POINT
            }
            options={lastCancelPointOptions}
            onOptionSelect={(newPoint) => {
              handleChange('lastCancelPoint', newPoint.value)
            }}
            containerClassName={style.inputOptionsSelect}
          />
        </div>

        <div className="ss-form-group">
          <label className="ss-label">
            {t('sentences.enableAutocancelOnNonConfirmedBookings')}
          </label>
          <ToggleSwitch
            checked={nonConfirmedBookingAutoCancellationEnabled}
            onChange={(value) => handleChange('nonConfirmedBookingAutoCancellationEnabled', value)}
          />
        </div>

        {nonConfirmedBookingAutoCancellationEnabled && (
          <TextInputOptionsSelect
            label={t('sentences.nonConfirmedBookingAutoCancellationTime')}
            disabled={isLoading}
            value={
              NON_CONFIRMED_BOOKING_DURATION_OPTIONS.find(
                (x) => x.value === nonConfirmedBookingAutoCancellationDuration
              )?.label || ''
            }
            error={durationErrors?.non_confirmed_booking_auto_cancellation_duration}
            options={NON_CONFIRMED_BOOKING_DURATION_OPTIONS}
            onOptionSelect={(newDuration) => {
              setDurationErrors({ non_confirmed_booking_auto_cancellation_duration: '' })
              handleChange('nonConfirmedBookingAutoCancellationDuration', newDuration.value)
            }}
            containerClassName={style.inputOptionsSelect}
          />
        )}

        <div className="ss-form-group">
          <label className="ss-label">
            {t('sentences.enableAutocancelOnUnpaidBookingsFromApp')}
          </label>
          <ToggleSwitch
            checked={unpaidBookingAutoCancellationEnabled}
            onChange={(value) => handleUnpaidBookingAutoCancellationChange(value)}
          />
        </div>

        {unpaidBookingAutoCancellationEnabled && (
          <TextInputOptionsSelect
            label={t('sentences.unpaidBookingAutoCancellationTimeFromApp')}
            disabled={isLoading}
            value={
              UNPAID_BOOKING_DURATION_OPTIONS.find(
                (x) => x.value === unpaidBookingAutoCancellationDuration
              )?.label || t('dateTime.words.custom')
            }
            error={durationErrors?.unpaid_booking_auto_cancellation_duration}
            options={UNPAID_BOOKING_DURATION_OPTIONS}
            onOptionSelect={(newDuration) => {
              setDurationErrors({ unpaid_booking_auto_cancellation_duration: '' })
              handleUnpaidBookingAutoCancellationDurationChange(newDuration.value)
            }}
            containerClassName={style.inputOptionsSelect}
          />
        )}

        {isUnpaidBookingAutoCancellationDurationCustom && (
          <div className="ss-form-group">
            <TextInputField
              value={+unpaidBookingAutoCancellationDays || ''}
              label={t('sentences.unpaidBookingCustomAutoCancellationTimeLabel')}
              type="number"
              onChange={(value) => handelUnpaidBookingAutoCancellationDaysChange(value)}
            />
          </div>
        )}

        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableStubPlayers')}
            <InfoHover textId="settings.infoHoversGolfcourse.enableStubPlayers" />
          </label>
          <ToggleSwitch
            checked={enableStubPlayers}
            onChange={(value) => handleChange('enableStubPlayers', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enablePayOnSite')}
            <InfoHover textId="settings.infoHoversGolfcourse.enablePayOnSite" />
          </label>
          <ToggleSwitch
            checked={enablePayOnSite}
            onChange={(value) => handleChange('enablePayOnSite', value)}
          />
        </div>
        {enablePayOnSite && (
          <div className={style.customPayOnSite}>
            <TextInputField
              label={t('settings.customPayOnSiteTitle')}
              disabled={isLoading}
              value={customPayOnSiteTitle}
              error={customPayOnSiteTitle?.length > 24 ? t('settings.payOnSiteTitleError') : ''}
              onChange={(value) => handleChange('customPayOnSiteTitle', value)}
            />
            <TextInputField
              rows={4}
              type="text-area"
              label={t('settings.customPayOnSiteDesc')}
              disabled={isLoading}
              error={customPayOnSiteDesc?.length > 160 ? t('settings.payOnSiteDescError') : ''}
              value={customPayOnSiteDesc}
              onChange={(value) => handleChange('customPayOnSiteDesc', value)}
            />
          </div>
        )}
        {renderAllowedMemberships()}
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.teeTimeSource')}
            <InfoHover textId="settings.infoHoversGolfcourse.teeTimeSource" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={'sweetspot'}
            ref={_teeTimeSource}
            values={TEETIME_SOURCE}
            onSelect={(value) => handleChange('teeTimeSource', value)}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.bookingType')}
            <InfoHover textId="settings.infoHoversGolfcourse.bookingType" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={-1}
            ref={_bookingType}
            values={BOOKING_STATUS}
            onSelect={(value) => handleChange('bookingType', value)}
          />
        </div>
        <div className={style.formButtons}>
          <Button
            theme="default-outline"
            size="medium"
            width={'quarter'}
            disabled={isLoading}
            onClick={saveSettings}
          >
            {t('save')}
          </Button>
        </div>
      </form>
    )
  }

  const renderGolfClubSelect = () => {
    return (
      <div>
        <div className="ss-form-group">
          <label className="ss-label">{t('golfclub')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            values={golfClubList}
            initialId={golfClub.selectedId}
            onSelect={handleClubChange}
          />
        </div>

        <div className={'mx-0 my-2.5 flex gap-2'}>
          <div className={'rounded-md border border-gray-300'}>
            <DropdownSelect
              values={typeOptions}
              selectedId={'all'}
              initialId={'all'}
              onSelect={onCourseTypeChange}
              containerClassName={'w-56'}
            />
          </div>

          <div className={'rounded-md border border-gray-300'}>
            <DropdownSelect
              values={filteredCourses}
              onSelect={handleCourseChange}
              selectedId={activeGolfCourse}
              containerClassName={'w-96'}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={style.wrapper}>
      <div className={style.title}>
        <Text textId="settings.modifyGolfcourse" />
        <div>
          <PulseLoader fillHeight showIf={isLoading} />
        </div>
      </div>
      <div>
        {renderForm()}
        <Alert showIf={!!errorText} onClick={() => setErrorText('')}>
          <Text textId={errorText} />
        </Alert>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    golfClubList: state.golfClub.list,
    golfClub: state.golfClub,
    token: state.auth.token,
    lang: state.auth.me.lang,
    isSA: state.auth.isSuperAdmin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGolfCourse: (token, clubId, payload) =>
      dispatch(updateGolfCourse(token, clubId, payload)),
    getGolfCourseImages: (id) => dispatch(getGolfCourseImages(id)),
    getSpecificGolfClub: (token, id) => dispatch(getSpecificGolfClub(token, id)),
    getSpecificGolfCourse: (token, id) => dispatch(getSpecificGolfCourse(token, id)),
    getGolfCoursesFromGolfClub: (id) => dispatch(getGolfCoursesFromGolfClub(id)),
    uploadGolfCourseImage: (token, id, image) => dispatch(uploadGolfCourseImage(token, id, image)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyGolfcourse)

ModifyGolfcourse.propTypes = {
  lang: PropTypes.string,
  token: PropTypes.string.isRequired,
  roles: PropTypes.array,
  golfClubList: PropTypes.array.isRequired,
  golfClub: PropTypes.object,
  updateGolfCourse: PropTypes.func.isRequired,
  getGolfCourseImages: PropTypes.func.isRequired,
  getSpecificGolfCourse: PropTypes.func.isRequired,
  getSpecificGolfClub: PropTypes.func.isRequired,
  getGolfCoursesFromGolfClub: PropTypes.func.isRequired,
  uploadGolfCourseImage: PropTypes.func.isRequired,
}

const getGolfCourseTypes = (t) => [
  { name: t('editPeriodsNew.courses'), id: 'course' },
  { name: t('editPeriodsNew.simulators'), id: 'simulator' },
  { name: t('editPeriodsNew.drivingRanges'), id: 'driving_range' },
  { name: t('editPeriodsNew.pro'), id: 'pro' },
  { name: t('editPeriodsNew.other'), id: 'other' },
]
