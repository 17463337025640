import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { Tooltip } from 'react-tooltip'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import DRAFT_ICON from '@sweetspot/club-portal-legacy/resources/images/blank-file.svg'

import style from './style.module.scss'

const DRAFT = 'draft'

export class EmailListItem extends Component {
  renderIcon(email) {
    if (email.status === DRAFT) return <img alt="Draft" src={DRAFT_ICON} />
    return
  }

  renderButtons() {
    // const { toggleUpdate, toggleRemove } = this.props;
    const { toggleUpdate } = this.props

    return (
      <div className={style.buttons}>
        <FontAwesomeIcon data-tip data-tooltip-id="edit" icon={faPen} onClick={toggleUpdate} />
        <Tooltip id="edit" place="top" offset={{ top: '-7px' }} className={style.tooltip}>
          <Text textId="edit" />
        </Tooltip>
      </div>
    )
  }

  render() {
    const { email, activeId, onChangeActive } = this.props

    let className = [style.row]
    if (email.id === activeId) className.push(style.active)
    const date = DateHelpers.getLocalDateFromUTCString(email.updated_at)
    const from = DateHelpers.getLocalDateFromUTCString(email.from_time)
    const to = DateHelpers.getLocalDateFromUTCString(email.to_time)
    const fontStyle = { fontWeight: 500 }

    return (
      <div className={className.join(' ')} onClick={() => onChangeActive(email.id)}>
        <div className={style.date}>{moment(to).format('YYYY-MM-DD')}</div>
        <div className={style.period}>
          {moment(from).format('HH:mm')} - {moment(to).format('HH:mm')}
        </div>
        <div className={style.title}>{email.title}</div>
        <div className={style.status}>
          <Text
            textId={`communication.${email.status}`}
            style={email.status === DRAFT ? fontStyle : null}
          />
        </div>
        <div className={style.dateTime}>
          {email.status !== DRAFT ? moment(date).format('YYYY-MM-DD HH:mm') : ''}
        </div>
        <div className={style.buttons}>
          {email.id === activeId && email.status === DRAFT && this.renderButtons()}
        </div>
      </div>
    )
  }
}

EmailListItem.propTypes = {
  email: PropTypes.object.isRequired,
  activeId: PropTypes.number.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  toggleUpdate: PropTypes.func.isRequired,
  toggleRemove: PropTypes.func.isRequired,
}

export default EmailListItem
