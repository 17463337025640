import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable, faChartLine } from '@fortawesome/pro-solid-svg-icons'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import CalendarDropdown from '@sweetspot/club-portal-legacy/components/CalendarDropdown'
import OccupancyItem from '../OccupancyItem'
import PriceTable from '../PriceTable'
import PriceChart from '../PriceChart'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import style from './style.module.scss'

const mockOccupancies = [
  { percent: 100, days: 45 },
  { percent: 95, days: 14 },
  { percent: 90, days: 7 },
  { percent: 75, days: 7 },
  { percent: 50, days: 2 },
  { percent: 25, days: 1 },
  { percent: 0, days: 0 },
]

export class PriceSimulator extends Component {
  constructor() {
    super()

    this.state = {
      isGridView: true,
      activeOccNo: 0,
    }

    this.changeActiveOcc = this.changeActiveOcc.bind(this)
    this.toggleDays = this.toggleDays.bind(this)
    this.toggleView = this.toggleView.bind(this)
    this.prevDay = this.prevDay.bind(this)
    this.nextDay = this.nextDay.bind(this)
  }

  changeActiveOcc(index) {
    this.setState({ activeOccNo: index })
    this.props.onChangeOccupancy(mockOccupancies[index].percent)
  }

  toggleDays() {
    this.props.onToggleWorkdays()
  }

  toggleView() {
    this.setState({ isGridView: !this.state.isGridView })
  }

  prevDay() {
    const newDate = DateHelpers.previousDay(this.props.date)
    this.props.setDate(newDate)
    if (this._calendar) {
      this._calendar.setDate(newDate)
    }
  }

  nextDay() {
    const newDate = DateHelpers.nextDay(this.props.date)
    this.props.setDate(newDate)
    if (this._calendar) {
      this._calendar.setDate(newDate)
    }
  }

  renderOccupancyGroup() {
    const { activeOccNo } = this.state

    return mockOccupancies.map((item, index) => (
      <OccupancyItem
        key={index}
        percent={item.percent}
        days={item.days}
        active={activeOccNo === index}
        onClick={() => this.changeActiveOcc(index)}
      />
    ))
  }

  renderTimeSelect() {
    return (
      <div className={style.timeSelect}>
        <div className={style.calendarContainer}>
          <CalendarDropdown
            fixedWidth
            ref={(calendar) => (this._calendar = calendar)}
            onDateChange={this.props.setDate}
            onPrevClick={this.prevDay}
            onNextClick={this.nextDay}
            lang={this.props.lang}
            newDesign
          />
        </div>
      </div>
    )
  }

  renderViewMethod() {
    const { isGridView } = this.state
    const { isWeekend } = this.props

    return (
      <div className={style.viewMethod}>
        <div>
          <Text className={style.text} textId="pricing.day" />
          <div className={style.days}>
            <div className={!isWeekend ? style.active : ''} onClick={this.toggleDays}>
              <Text textId="date.weekday" />
            </div>
            <div className={isWeekend ? style.active : ''} onClick={this.toggleDays}>
              <Text textId="date.weekend" />
            </div>
          </div>
        </div>
        <div>
          <Text className={style.text} textId="pricing.view" />
          <div className={style.days}>
            <div className={isGridView ? style.active : ''} onClick={this.toggleView}>
              <FontAwesomeIcon icon={faTable} />
            </div>
            <div className={!isGridView ? style.active : ''} onClick={this.toggleView}>
              <FontAwesomeIcon icon={faChartLine} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderHeader() {
    return (
      <div className={style.header}>
        <div className={style.headerTitle}>
          <Text textId="pricing.simulator" />
        </div>

        <div>
          <div className={style.occupancy}>
            <Text className={style.title} textId="pricing.supply" />
            <Text textId="pricing.selectSupply" />
          </div>
          {this.renderOccupancyGroup()}
        </div>

        {this.renderViewMethod()}
      </div>
    )
  }

  renderBody() {
    const { isGridView, isWeekend } = this.state

    if (isGridView) {
      return <PriceTable tableData={this.props.tableData} />
    } else {
      return (
        <PriceChart
          isWeekend={isWeekend}
          chartData={this.props.tableData}
          priceModules={this.props.priceModules}
        />
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderTimeSelect()}
        {this.renderBody()}
      </div>
    )
  }
}

PriceSimulator.propTypes = {
  lang: PropTypes.string,
  date: PropTypes.object.isRequired,
  isWeekend: PropTypes.bool.isRequired,
  activePeriod: PropTypes.object,
  tableData: PropTypes.array.isRequired,
  priceModules: PropTypes.array.isRequired,
  onToggleWorkdays: PropTypes.func.isRequired,
  onChangeOccupancy: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
}

export default PriceSimulator
