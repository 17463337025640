import { useState } from 'react'
import ConfirmModal from '@sweetspot/club-portal-legacy/components/ConfirmModal'
import style from '../../style.module.scss'
import { useTranslation } from 'react-i18next'

const SaveChangesController = ({
  period,
  formState,
  onSaveChanges,
  allowSaveChanges,
  isCourse,
  space,
}) => {
  const { t } = useTranslation()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [saveAllChanges, setSaveAllChanges] = useState(false)

  const onSaveClick = () => {
    const { week } = formState
    const allTableData = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')
    const spacesChanged = Object.keys(allTableData[period.id][week]).length

    if ((spacesChanged === 1 && isCourse) || (!isCourse && space !== -1)) {
      onSaveChanges()
    } else {
      setShowConfirmModal(true)
    }
  }

  const onCloseModal = () => {
    setSaveAllChanges(false)
    setShowConfirmModal(false)
  }

  const onConfirmClick = () => {
    onSaveChanges(isCourse ? saveAllChanges : true)
    onCloseModal()
  }

  return (
    <div>
      <button
        className={`system-button primary md-32 ${style.inputOptions} ${style.saveChangesBtn}`}
        onClick={onSaveClick}
        disabled={!allowSaveChanges}
      >
        {t('editPeriodsNew.saveChanges')}
      </button>

      {showConfirmModal && (
        <div className={style.saveChangesConfirmModal}>
          <ConfirmModal
            headerText="editPeriodsNew.saveChanges"
            confirmText="editPeriodsNew.save"
            onCancel={onCloseModal}
            onConfirm={onConfirmClick}
            confirmVariant="primary"
          >
            <div className={style.modalContent}>
              <h3>
                {isCourse
                  ? t('editPeriodsNew.changesMoreThanOneSpace')
                  : t('editPeriodsNew.pricesChangeConfirmNote')}
              </h3>
              {isCourse && (
                <label>
                  <input
                    checked={saveAllChanges}
                    type="checkbox"
                    onChange={() => setSaveAllChanges((v) => !v)}
                  />
                  <span>{t('editPeriodsNew.saveAllChanges')}</span>
                </label>
              )}
            </div>
          </ConfirmModal>
        </div>
      )}
    </div>
  )
}

export default SaveChangesController
