import { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'

import { Button, Input, InputBase, InputContainer } from '@sweetspot/scramble-ds'
import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'

import BucketConfigurationSelect from '../BucketConfigurationSelect/BucketConfigurationSelect'

const BucketSizes = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: {
      bucketSizes,
      setBucketSizes,
      addBucketSize,
      removeBucketSize,
      maxNrOfBalls,
      maxNrOfBuckets,
      discountsOptions,
    },
  } = useBallPricingContext()

  const handleNrOfBalls = useCallback(
    (value: number, index: number) => {
      const nrOfBalls = Math.max(1, Math.min(value, maxNrOfBalls))
      setBucketSizes?.({ nrOfBalls }, index as number)
    },
    [maxNrOfBalls, setBucketSizes]
  )

  const preventedSymbols = useMemo(() => ['e', 'E', '+', '-', ',', '.'], [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isPreventedSymbol = preventedSymbols.includes(e.key)
      const value = (e.target as HTMLInputElement).value
      const hasTwoOrMoreDecimals =
        !['Backspace', 'Delete'].includes(e.key) && !e.metaKey && value.split('.')[1]?.length >= 2

      if (isPreventedSymbol || hasTwoOrMoreDecimals) {
        e.preventDefault()
      }
    },
    [preventedSymbols]
  )

  return (
    <div className="text-content-base text-text-dark font-bold">
      <span>{t('sentences.bucketSizes')}</span>
      <div className="text-text-dark text-content-sm mt-2 flex justify-between font-bold">
        <span>{t('words.option')}</span>
        <div className="mr-8 flex gap-1">
          <div className="w-20 pl-2">{t('sentences.nrOfBalls')}</div>
          <div className="w-20 pl-2">{t('words.discount')}</div>
        </div>
      </div>
      {bucketSizes?.map((bucket, index) => (
        <div className="mt-1 flex justify-between" key={`${bucket.discount}-${index}`}>
          <span>{index + 1}</span>
          <div className="flex items-center gap-1">
            <InputBase>
              <InputContainer>
                <Input
                  type="number"
                  value={bucket?.nrOfBalls}
                  className="text-content-base h-8 w-20 py-1"
                  min={1}
                  max={maxNrOfBalls}
                  onBlur={() => handleNrOfBalls(bucket?.nrOfBalls || 0, index)}
                  onChange={(e) => handleNrOfBalls(parseInt(e.target.value), index)}
                  onKeyDown={handleKeyDown}
                  placeholder="1-999"
                />
              </InputContainer>
            </InputBase>
            <BucketConfigurationSelect
              value={bucket?.discount}
              index={index}
              options={discountsOptions}
              className="w-20"
              renderValue={(value) => `${value}%`}
              onChange={(value, index) =>
                setBucketSizes?.({ discount: parseInt(value) }, index as number)
              }
            />
            <FontAwesomeIcon
              icon={faMinus}
              className={cx('cursor-pointer p-2', {
                'text-text-pale !cursor-not-allowed': bucketSizes?.length === 1,
              })}
              onClick={() => bucketSizes?.length > 1 && removeBucketSize?.(index)}
            />
          </div>
        </div>
      ))}
      {bucketSizes?.length < maxNrOfBuckets && (
        <div className="mt-[12px] flex justify-center">
          <Button rounding="pill" variant="inverted" size="small" onClick={addBucketSize}>
            <FontAwesomeIcon icon={faPlus} />
            {t('sentences.newSize')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default BucketSizes
