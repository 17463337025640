import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

import { PriceModelTab, queryPriceModelTabs } from '@sweetspot/shared/data-access/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { daysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import { constructPriceModelData, generateInitPriceList } from '../utils'
import { PriceModelByBalls } from '../types'

type UsePriceModelProps = {
  rangeId?: string
  priceModelId?: string
  timeSections: string[][]
}

const usePriceModel = ({
  rangeId,
  priceModelId: initialPriceModelId,
  timeSections,
}: UsePriceModelProps) => {
  const [priceModelId, setPriceModelId] = useState<string | undefined>()
  const [hasPriceModel, setHasPriceModel] = useState<boolean>(false)
  const [priceModel, setPriceModel] = useState<PriceModelByBalls>()
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const DAYS_OF_WEEK = useMemo(() => daysOfWeek(), [])
  const initialPriceMatrix = useMemo(() => {
    if (!timeSections) return []
    return timeSections.map(() => generateInitPriceList(7))
  }, [timeSections])

  useEffect(() => {
    setPriceModelId(initialPriceModelId)
  }, [initialPriceModelId])

  // fetch price model
  const {
    isFetching: isPriceModelFetching,
    refetch: getPriceModelTabs,
    isError: isPriceModelError,
  } = useQuery([CLUB_QUERIES.PRICE_MODEL_TABS, rangeId], () => queryPriceModelTabs(rangeId), {
    enabled: false,
    onSuccess: (data) => {
      const priceTabs = data?.price_tabs
      setHasPriceModel(priceTabs?.some((tab: PriceModelTab) => tab?.price_entries?.length > 0))
      setPriceModel(constructPriceModelData(priceTabs, initialPriceMatrix, DAYS_OF_WEEK))
    },
    onError: () => {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    },
  })

  return {
    getPriceModelTabs,
    priceModelId,
    hasPriceModel,
    isPriceModelFetching,
    isPriceModelError,
    priceModel,
  }
}

export default usePriceModel
