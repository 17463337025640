import React from 'react'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'

const Placeholder = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.label}>
        <Text textId="settings.selectCategory" />
      </div>
    </div>
  )
}

export default Placeholder
