import { useState, useEffect } from 'react'
import moment from 'moment'

let interval = null

const BasicTimer = ({ render, expirationDate }) => {
  const [timeLeft, setTimeLeft] = useState(null)

  useEffect(() => {
    if (expirationDate) {
      const deadline = moment.utc(expirationDate)

      const check = () => {
        const now = moment.utc()
        if (now.isAfter(deadline)) {
          setTimeLeft(0)
          clearInterval(interval)
        } else {
          setTimeLeft(deadline.diff(now))
        }
      }

      interval = setInterval(() => {
        check()
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [expirationDate])

  return render(timeLeft)
}

BasicTimer.propTypes = {}

BasicTimer.defaultProps = {}

export default BasicTimer
