import { DemandStatus } from '@sweetspot/shared/data-access/api-platform'
import { TrackingTech } from '@sweetspot/shared/types'

export const BUCKET_DATA = {
  maxNrOfBalls: 999,
  maxNrOfBuckets: 3,
  discount: [...Array(21).keys()].map((i) => i * 5),
}

export const BUCKET_SIZES_DEFAULT = [
  {
    nrOfBalls: '',
    discount: 0,
  },
]

export const BALL_PRICE_DATA: {
  maxBallPrice: number
  demands: Exclude<DemandStatus, DemandStatus.Closed>[]
} = {
  maxBallPrice: 9.99,
  demands: [DemandStatus.Low, DemandStatus.Medium, DemandStatus.High],
}

export const BALL_PRICE_TECHNOLOGIES_DEFAULT = [TrackingTech.NONE]

export const PRICE_ROUNDING_DATA = [0.5, 1, 5, 10]

export const PRICE_ROUNDING_DEFAULT = 100

export const OPENING_HOURS_DEFAULT = {
  open: '08:00',
  close: '18:00',
}

export const DEFAULT_OPENING_HOURS_WEEK = {
  monday: OPENING_HOURS_DEFAULT,
  tuesday: OPENING_HOURS_DEFAULT,
  wednesday: OPENING_HOURS_DEFAULT,
  thursday: OPENING_HOURS_DEFAULT,
  friday: OPENING_HOURS_DEFAULT,
  saturday: OPENING_HOURS_DEFAULT,
  sunday: OPENING_HOURS_DEFAULT,
}

export const statusColorMap: { [key in DemandStatus]: string } = {
  [DemandStatus.High]: 'bg-background-sunset-light',
  [DemandStatus.Medium]: 'bg-background-sand-light',
  [DemandStatus.Low]: 'bg-background-grass-light',
  [DemandStatus.Closed]: 'bg-surface-primary-disabled',
}
