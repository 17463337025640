import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import {
  createProductVariantFee,
  getProductVariant,
  updateProductVariant,
} from '../services/api-platform'

const useProductVariant = (variantUuid) => {
  const queryClient = useQueryClient()
  const queryKey = [CLUB_QUERIES.PRODUCT_VARIANT, variantUuid]

  const { data: productVariant, isFetching: isFetchingVariant } = useQuery(
    queryKey,
    () => getProductVariant(variantUuid),
    {
      enabled: !!variantUuid,
    }
  )

  const updateVariant = useMutation(
    (newVariant = { name: '', state: '' }) => {
      return updateProductVariant(variantUuid, newVariant)
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: async (newVariant) => {
        await queryClient.cancelQueries(queryKey)
        const previousVariant = queryClient.getQueryData([
          CLUB_QUERIES.PRODUCT_VARIANT,
          variantUuid,
        ])

        queryClient.setQueryData(queryKey, {
          ...previousVariant,
          ...newVariant,
        })

        return { previousVariant }
      },
      // After success or failure, refetch the variants
      onSettled: async (newVariant, err, previousVariant, context) => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )

  const createVariantFee = useMutation(
    ({
      newFee = {
        fee_unit_code: 'golf_cart_fleet',
        vat: 20,
        price: 150,
      },
      newVariantUuid = null,
    }) => {
      return createProductVariantFee(newVariantUuid ?? variantUuid, newFee)
    }
  )

  return { productVariant, isFetchingVariant, updateVariant, createVariantFee }
}

export { useProductVariant }
