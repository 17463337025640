import {
  convertTimeToTimeZone,
  isOutsideDateRange,
  localizedDaysOfWeek,
} from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelectedCourse, useWeekPeriod } from '../'
import TimePeriodsContext from '../context'
import getTeeTimes from '../useWeekPeriod/getTeeTimes'
import flatSlots from './flatSlots'
import useMutations from './useMutations'

const initialState = { price_per_extra_player: 0, price: 0, week: -1, space: -1 }

const useTableSelector = (period) => {
  const [globalState] = useContext(TimePeriodsContext)
  const allTableData = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')
  const { selectedCourse } = useSelectedCourse()
  const { handleSubmit, register, watch, setValue } = useForm({
    defaultValues: initialState,
  })
  const formState = watch()
  const [isUpdating, setUpdating] = useState(false)
  const { setTeeTimes, updateTeeTimes } = useMutations(
    period,
    selectedCourse,
    setValue,
    setUpdating
  )
  const [allCells, setAllCells] = useState(null)
  const [selectedCells, setSelectedCells] = useState(new Set())
  const [selectedCols, setSelectedCols] = useState(null)
  const [selectedRows, setSelectedRows] = useState(null)
  const [cbValue, setCbValue] = useState(null)
  const weekPeriod = useWeekPeriod(period, formState, selectedCourse, setValue)
  const { tableData, setTableData, weekPeriodOptions } = weekPeriod
  const { timeSlots, daysMap, isAllPeriod, isLoading } = weekPeriod
  const { week, space } = formState
  const hasChanged = allTableData[period.id]?.[week]?.[space]?.hasChanged
  const { lang } = globalState
  const [currency] = useClubCurrency()

  const DAYS_OF_WEEK = useMemo(() => localizedDaysOfWeek('en'), [])

  const onApply = handleSubmit((formState) => {
    const { week, space } = formState

    setTableData((tableData) => {
      const newData = JSON.parse(JSON.stringify(tableData.data))
      ;[...selectedCells].forEach((cell) => {
        const [row, col] = cell.split('-')
        const dayOfWeek = DAYS_OF_WEEK[+col]
        const slot = newData[dayOfWeek][+row]
        if (week !== -1) {
          slot.hasChanged = true
        }
        slot.price = slot.price || {}
        slot.price.currency = slot.price.currency || currency
        slot.price.amount = Math.round(formState.price * 100)

        slot.price_per_extra_player =
          formState.price_per_extra_player || formState.price_per_extra_player === 0
            ? Math.round(formState.price_per_extra_player * 100)
            : Math.round(formState.price * 100)
      })

      allTableData[period.id] = allTableData[period.id] || {}
      allTableData[period.id][week] = allTableData[period.id][week] || {}
      allTableData[period.id][week][space] = allTableData[period.id][week][space] || {}
      allTableData[period.id][week][space] = newData
      allTableData[period.id][week][space].hasChanged = true
      localStorage.setItem('ALL_TABLE_DATA', JSON.stringify(allTableData))
      return { ...tableData, data: newData }
    })
    setSelectedCells(new Set())
    setValue('price_per_extra_player', 0)
    setValue('price', 0)
  })

  const onSaveChanges = async (isAllChanges) => {
    let slots = flatSlots(tableData)

    if (isAllChanges) {
      const selectedWeekData = allTableData[period.id][week]
      slots = Object.entries(selectedWeekData).reduce((accumulator, [, arr]) => {
        accumulator.push(...flatSlots(arr))
        return accumulator
      }, [])
    }

    if (formState.week === -1) {
      return setTeeTimes(tableData)
    }

    if (
      period.period_price_status === 'weekly_price_set' ||
      period.period_price_status === 'all_period_price_set'
    ) {
      const filteredFinalSlots = slots
        .filter((teeTime) => teeTime.price)
        .map((teeTime) => {
          return {
            ...teeTime,
            price: {
              ...teeTime.price,
              amount: parseInt(teeTime.price.amount),
            },
            price_per_extra_player: parseInt(teeTime.price_per_extra_player),
          }
        })
      return updateTeeTimes(formState, filteredFinalSlots, isAllChanges)
    }

    if (!period.period_price_status) {
      const selectedWeek = weekPeriodOptions[0]
      const allTeeTimes = await getTeeTimes(selectedCourse, selectedWeek, space)

      const teeTimesWithNoOffset = allTeeTimes.map((teeTime) => {
        const elementWithNoOffSet = { ...teeTime }
        elementWithNoOffSet.from = convertTimeToTimeZone(teeTime.from, selectedCourse?.timezone)
        elementWithNoOffSet.to = convertTimeToTimeZone(teeTime.to, selectedCourse?.timezone)
        return elementWithNoOffSet
      })

      const startDate = new Date(selectedWeek.fromDate)
      const endDate = new Date(selectedWeek.toDate)

      const finalTeeTimes = teeTimesWithNoOffset.filter(
        (teeTime) =>
          isOutsideDateRange(teeTime, startDate, endDate) && startDate <= new Date(teeTime.from)
      )

      const bySpace = finalTeeTimes.reduce((accumulator, teeTime) => {
        accumulator[teeTime.from] = accumulator[teeTime.from] || []
        accumulator[teeTime.from].push(teeTime)
        return accumulator
      }, {})

      const orderedKeys = Object.keys(bySpace).sort()

      const finalSlots = []
      orderedKeys.forEach((from, index) => {
        const changedSlot = slots[index]
        const spaceSlotIds = bySpace[from].map((space) => space.uuid)
        for (let spaceSlotId of spaceSlotIds) {
          finalSlots.push({ ...changedSlot, uuid: spaceSlotId })
        }
      })

      const filteredFinalSlots = finalSlots
        .filter((teeTime) => teeTime.price)
        .map((teeTime) => {
          return {
            ...teeTime,
            price: {
              ...teeTime.price,
              amount: parseInt(teeTime.price.amount),
            },
            price_per_extra_player: parseInt(teeTime.price_per_extra_player),
          }
        })

      return updateTeeTimes(formState, filteredFinalSlots, isAllChanges)
    }
  }

  const onCheckBoxClick = (value) => {
    if (!value) {
      setSelectedCells(new Set(allCells))
    } else {
      setSelectedCells(new Set())
    }
  }

  const onRowClick = (row, isSelected) => {
    setSelectedCells((curr) => {
      const setCopy = new Set(curr)
      Array(7)
        .fill(null)
        .forEach((_, idx) => {
          const cellId = `${row}-${idx}`
          if (allCells.has(cellId)) {
            setCopy[isSelected ? 'delete' : 'add'](cellId)
          }
        })
      return setCopy
    })
  }

  const onColumnClick = (col, isSelected) => {
    if (tableData[DAYS_OF_WEEK[col]]) {
      setSelectedCells((curr) => {
        const setCopy = new Set(curr)
        Array(timeSlots.length)
          .fill(null)
          .forEach((_, idx) => {
            setCopy[isSelected ? 'delete' : 'add'](`${idx}-${col}`)
          })
        return setCopy
      })
    }
  }

  const onCellClick = (cellId, isSelected) => {
    setSelectedCells((curr) => {
      const setCopy = new Set(curr)
      if (isSelected) {
        setCopy.delete(cellId)
      } else {
        if (allCells.has(cellId)) setCopy.add(cellId)
      }
      return setCopy
    })
  }

  useEffect(() => {
    const cbValue =
      allCells && selectedCells.size === allCells.size
        ? 'all'
        : selectedCells.size > 0
        ? 'some'
        : null
    setCbValue(cbValue)

    setSelectedRows(
      Array(timeSlots.length)
        .fill(null)
        .map((_, idxRow) => {
          if (!timeSlots.length) return false
          for (let idxCol = 0; idxCol < 7; idxCol++) {
            if (tableData[DAYS_OF_WEEK[idxCol]] && !selectedCells.has(`${idxRow}-${idxCol}`)) {
              return false
            }
          }
          return true
        })
    )

    const selectedCols = Array(7)
      .fill(null)
      .map((_, idxCol) => {
        if (!timeSlots.length) return false
        for (let idxRow = 0; idxRow < timeSlots.length; idxRow++) {
          if (!selectedCells.has(`${idxRow}-${idxCol}`)) {
            return false
          }
        }
        return true
      })
    setSelectedCols(selectedCols)
  }, [DAYS_OF_WEEK, allCells, selectedCells, tableData, timeSlots.length])

  useEffect(() => {
    if (tableData) {
      const allCells = new Set()

      for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
        const dayOfWeek = DAYS_OF_WEEK[i]
        const slots = tableData[dayOfWeek]
        if (!slots) continue
        for (let j = 0; j < slots.length; j++) {
          allCells.add(`${j}-${i}`)
        }
      }

      setAllCells(allCells)
      setSelectedCells(new Set())
    }
  }, [DAYS_OF_WEEK, tableData, formState.week])

  const allowSaveChanges = useCallback(() => {
    if (!hasChanged || isUpdating) return false
    const selectedWeek = allTableData?.[period.id]?.[week]
    if (!selectedWeek) return false

    for (const space of Object.values(selectedWeek)) {
      for (const weekDay of Object.values(space)) {
        if (!Array.isArray(weekDay)) continue
        for (const slot of weekDay) {
          if ((slot?.price && slot?.price?.amount !== 0) || slot?.hasChanged) {
            return true
          }
        }
      }
    }
    return false
  }, [hasChanged, isUpdating, allTableData, period.id, week])

  return {
    selectedCells,
    tableData,
    onCheckBoxClick,
    cbValue,
    allCells,
    onRowClick,
    onColumnClick,
    onCellClick,
    selectedRows,
    selectedCols,
    formState,
    setValue,
    onApply,
    register,
    onSaveChanges,
    DAYS_OF_WEEK,
    selectedCourse,
    weekPeriodOptions,
    timeSlots,
    daysMap,
    isAllPeriod,
    allowSaveChanges,
    isLoading,
    lang,
  }
}

export default useTableSelector
