export function replaceOrRemove<T extends { id: number }>(
  array: T[],
  id: number,
  newItem?: T | null
): T[] {
  const index = array.findIndex((item) => item.id === id)

  if (index === -1) {
    return array
  }

  const newArray = array.slice()
  newArray.splice(index, 1)

  if (newItem === null || typeof newItem === 'undefined') {
    return newArray
  }

  newArray.push(newItem)

  return newArray
}

export function addItem<T>(array: T[], item: T | null | undefined): T[] {
  if (item === null || item === undefined) {
    return array
  }

  const newArray = array.slice()

  newArray.push(item)

  return newArray
}
export function replaceAll<T extends { id: number | string }>(
  array: T[],
  items: T[] | null | undefined
): T[] {
  if (items === null || items === undefined || items.length === 0) {
    return array
  }

  const idList = items.map((item) => item.id)

  const newArray = array.filter((item) => !idList.includes(item.id))

  items.forEach((item) => newArray.push(item))

  return newArray
}

export function sortBy<T>(array: T[], property: keyof T, ascending = true): T[] {
  const newArray = array.slice()

  newArray.sort((a, b) => {
    if (a[property] < b[property]) {
      return ascending ? -1 : 1
    }
    if (a[property] > b[property]) {
      return ascending ? 1 : -1
    }

    return 0
  })

  return newArray
}
