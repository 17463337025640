import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import produce from 'immer'

import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import ConfirmModal from '@sweetspot/club-portal-legacy/components/ConfirmModal'

import Header from './components/Header'
import PAForm from './components/PAForm'
import PartnershipList from './components/PartnershipList'
import Sidebar from './components/Sidebar'

import { getAdminsPartnerships, addToast } from '@sweetspot/club-portal-legacy/store/actions'

import { ReactComponent as TrashIcon } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import { ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import {
  createClubUser,
  deleteClubUser,
  getClubUserById,
  updateClubUser,
} from '@sweetspot/sweetspot-js/features/clubUsers/services/api-platform'

const INFORMATION = 0
const PARTNERSHIPS = 1

const EDIT = 1
const DELETE = 2

const initialForm = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  password: '',
  confirmPass: '',
  role: ROLES_DEF.ROLE_PARTNER.value,
  club: '',
}

class PAManagerModal extends Component {
  state = {
    activeId: 0,
    partnerId: 0,
    isEdit: false,
    isDelete: false,
    isLoading: false,
    form: initialForm,
    response: null,
    errors: {},
  }

  componentDidMount() {
    const { dispatch } = this.props
    const partnerId = this.props.match.params.id

    if (partnerId === 'new') this.setState({ partnerId: 0, isEdit: true })
    else {
      this.setState({
        partnerId,
        isEdit: false,
        isLoading: true,
      })
      getClubUserById(partnerId)
        .then((response) => {
          this.setState({
            isLoading: false,
            response,
            form: {
              firstName: response.first_name,
              lastName: response.last_name,
              email: response.email,
              company: response.company,
              password: '',
              confirmPass: '',
              role: ROLES_DEF[response.roles[0]].label,
            },
          })
        })
        .catch(() => {
          dispatch(addToast('getPartnerAdminError', 'error'))
          this.setState({ isLoading: false })
        })
    }
  }

  handleChangeInSidebar = (id) => {
    if (id === PARTNERSHIPS && this.state.activeId !== PARTNERSHIPS) this.fetchAdminsPartnerships()
    this.setState({ activeId: id })
  }

  handleClose = (shouldReload = false) => {
    let temp = {
      pathname: '/settings/partner-admins',
    }
    if (shouldReload)
      temp.state = {
        shouldReload: true,
      }
    this.props.history.push(temp)
  }

  handleClickActions = (id) => {
    this.setState({ isEdit: id === EDIT })
    this.setState({ isDelete: id === DELETE })
  }

  handleFormChange = (value, name) => {
    const { form } = this.state

    this.setState(
      produce((draft) => {
        delete draft.errors[name]
        draft.form = { ...form, [name]: value }
      })
    )
  }

  handleCancel = () => {
    const { partnerId, response } = this.state

    this.setState({
      isEdit: false,
      form: initialForm,
    })
    if (!partnerId) this.handleClose()
    else {
      this.setState({
        form: {
          firstName: response.first_name,
          lastName: response.last_name,
          email: response.email,
          company: response.company,
          password: '',
          confirmPass: '',
        },
      })
    }
  }

  fetchAdminsPartnerships = () => {
    const { dispatch } = this.props
    const { partnerId } = this.state

    if (partnerId === 0) return
    this.setState({ isLoading: true })
    dispatch(getAdminsPartnerships(partnerId))
      .then((data) => {
        const partnerships = data.map((x) => ({ id: x.id, partnership: x.name, club: x.club.name }))
        this.setState({
          isLoading: false,
          partnerships,
        })
      })
      .catch((errors) => {
        this.setState({
          isLoading: false,
          errors: errors || {},
          partnerships: [],
        })
      })
  }

  getPayload = () => {
    const {
      form: { firstName, lastName, email, company, password, confirmPass },
    } = this.state
    const { clubsList, currentClubId } = this.props
    const currentClubUuid = clubsList?.find((c) => c.id === currentClubId)?.uuid

    const payload = {
      first_name: firstName,
      last_name: lastName,
      company: company,
      email: email,
      password: password || null,
      password_confirmation: confirmPass || null,
      role: ROLES_DEF.ROLE_PARTNER.value,
      club: currentClubUuid,
    }

    return payload
  }

  handleCreate = () => {
    const { form } = this.state
    const { history, dispatch } = this.props

    this.setState({ isLoading: true })
    createClubUser(this.getPayload())
      .then((response) => {
        dispatch(addToast('createdPartnerAdmin', 'success'))
        this.setState({
          isLoading: false,
          isEdit: false,
          partnerId: response.id,
          response: form,
          form: { ...form, password: '', confirmPass: '' },
        })
        history.push(`/settings/partner-admins/${response.id}`)
      })
      .catch((err) => {
        dispatch(addToast('createPartnerAdminError', 'error'))
        this.setState({
          isLoading: false,
          errors: err?.errors || {},
        })
      })
  }

  handleUpdate = () => {
    const { form, partnerId } = this.state
    const { dispatch } = this.props

    this.setState({ isLoading: true })
    updateClubUser(partnerId, this.getPayload())
      .then(() => {
        dispatch(addToast('modifiedPartnerAdmin', 'success'))
        this.setState({
          isLoading: false,
          isEdit: false,
          form: { ...form, password: '', confirmPass: '' },
        })
      })
      .catch((err) => {
        dispatch(addToast('updatePartnerAdminError', 'error'))
        this.setState({
          isLoading: false,
          errors: err?.errors || {},
        })
      })
  }

  handleDelete = () => {
    const { partnerId } = this.state
    const { dispatch } = this.props

    this.setState({ isLoading: true })
    deleteClubUser(partnerId)
      .then(() => {
        dispatch(addToast('deletedPartnerAdmin', 'success'))
        this.setState({
          isLoading: false,
          isDelete: false,
        })
        this.handleClose(true)
      })
      .catch(() => {
        dispatch(addToast('deletePartnerAdminError', 'error'))
        this.setState({
          isLoading: false,
        })
      })
  }

  // Renders
  renderMain = () => {
    const { activeId } = this.state
    if (activeId === INFORMATION) return this.renderInfo()
    else if (activeId === PARTNERSHIPS) return this.renderPartnershipList()
  }

  renderInfo = () => {
    const { isEdit, isLoading, form, errors } = this.state

    return (
      <PAForm
        isEdit={isEdit}
        isLoading={isLoading}
        form={form}
        errors={errors}
        onChange={this.handleFormChange}
      />
    )
  }

  renderPartnershipList = () => {
    return (
      <PartnershipList partnerships={this.state.partnerships} isLoading={this.state.isLoading} />
    )
  }

  renderConfirm = () => {
    const {
      isDelete,
      form: { firstName, lastName },
    } = this.state

    if (!isDelete) return null
    return (
      <ConfirmModal
        headerIcon={<TrashIcon />}
        headerText="partnerAdmin.deletePA"
        textArray={[firstName, lastName, '.partnerAdmin.confirmDelete']}
        confirmText="settings.delete"
        onCancel={() => this.setState({ isDelete: false })}
        onConfirm={this.handleDelete}
      />
    )
  }

  render = () => {
    const { activeId, partnerId, isLoading, isEdit, form, errors } = this.state

    return (
      <ModalContainer
        layout="sidebar"
        onClose={() => this.handleClose(true)}
        sidebarChildren={<Sidebar activeId={activeId} onChange={this.handleChangeInSidebar} />}
        headerChildren={
          <Header
            isLoading={isLoading}
            hideActions={activeId !== INFORMATION}
            isCreate={!partnerId}
            isEdit={isEdit}
            form={form}
            errors={errors}
            onClickActions={this.handleClickActions}
            onCancel={this.handleCancel}
            onSave={!partnerId ? this.handleCreate : this.handleUpdate}
          />
        }
      >
        {this.renderMain()}
        {this.renderConfirm()}
      </ModalContainer>
    )
  }
}

PAManagerModal.propTypes = {
  // From Redux
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.me.lang,
  currentClubId: state.golfClub.selectedId,
  clubsList: state.golfClub.list,
  memberships: state.membership.totalList,
})

export default connect(mapStateToProps)(PAManagerModal)
