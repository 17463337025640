import { getLang } from '@sweetspot/club-portal-legacy/languages'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

/**
 * Gets translated string from the translation json
 * @param {string} lang
 * @param {string} key
 * @returns {string}
 */
const GetTranslatedString = (lang, key) => {
  return Utilities.objectValueFromDotNotation(getLang(lang), key)
}

export default GetTranslatedString
