import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const ValueText = ({ children, className, fontWeight, fontSize, suffixText }) => {
  return (
    <p
      className={cx(
        styles.text,
        {
          [styles.regular]: fontWeight === 'regular',
          [styles.medium]: fontWeight === 'medium',
        },
        {
          [styles.font10]: fontSize === '10',
          [styles.font12]: fontSize === '12',
          [styles.font13]: fontSize === '13',
          [styles.font14]: fontSize === '14',
        },
        className
      )}
    >
      {children || ''}
      {suffixText && <span className={cx(styles.suffixText)}>&nbsp;{suffixText}</span>}
    </p>
  )
}

ValueText.propTypes = {
  className: PropTypes.string,
  fontWeight: PropTypes.oneOf(['regular', 'medium']),
  fontSize: PropTypes.oneOf(['10', '12', '14', '13']),
  suffixText: PropTypes.string,
}

ValueText.defaultProps = {
  className: '',
  fontWeight: 'regular',
  fontSize: '14',
  suffixText: '',
}

export default ValueText
