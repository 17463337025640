import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import PromotionsContext from '@sweetspot/sweetspot-js/features/promotions/containers/PromotionsManager/PromotionsContext'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { selfAsync, to } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  addIncompatiblePromotion,
  getIncompatiblePromotions,
  queryPromotions,
  removeIncompatiblePromotion,
} from '@sweetspot/sweetspot-js/features/promotions/services/api-platform'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import HoverDiv from '@sweetspot/sweetspot-js/common/components/HoverDiv'

import { ReactComponent as HigherPriorityIcon } from '@sweetspot/sweetspot-js/assets/svgs/higher-priority-gray.svg'
import { ReactComponent as LowerPriorityIcon } from '@sweetspot/sweetspot-js/assets/svgs/lower-priority-gray.svg'
import { ReactComponent as HomeIcon } from '@sweetspot/sweetspot-js/assets/svgs/home-icon-dark.svg'
import { ReactComponent as CrossIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'
import ButtonAndDropdownSelectWrapper from '@sweetspot/sweetspot-js/common/components/ButtonAndDropdownSelectWrapper'
import { useInfiniteQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import DotMarker from '@sweetspot/sweetspot-js/common/components/DotMarker'

const formatOptions = (promotions, currentPromotion) => {
  return promotions.map((p) => {
    const isCurrentPromotion = p.id === currentPromotion.id
    const isInactive = p?.status === 'draft' || p?.status === 'canceled'

    return {
      ...p,
      label: p.name,
      greenMarker: !!isCurrentPromotion,
      redMarker: !!isInactive,
      disabled: !!isCurrentPromotion,
    }
  })
}

let searchDebounce

const IncompatiblePromotions = ({ promotion, className, disabled }) => {
  const { t } = useTranslation()
  const { values } = useContext(PromotionsContext)
  const isMounted = useRef(false)

  const currentContext = useMemo(() => {
    return values.context
  }, [values.context])

  const [loadingIncompatPromotions, setLoadingIncompatPromotions] = useState(true)
  const [showDropdowns, setShowDropdowns] = useMergeState({
    club: false,
    membership: false,
  })
  const [incompatiblePromotions, setIncompatiblePromotions] = useMergeState({
    club: [],
    membership: [],
  })

  const currentPromotionPriority = useMemo(() => {
    let allIncompatibleChildren = []

    Object.keys(incompatiblePromotions).forEach((key) => {
      incompatiblePromotions[key].forEach((p) => {
        if (p?.incompatible_promotions) {
          p.incompatible_promotions.forEach((x) => allIncompatibleChildren.push(x))
        }
      })
    })
    const find = () => {
      return allIncompatibleChildren.find((p) => p.id === promotion.id)?.priority
    }

    return typeof find() === 'number' ? find() : promotion.priority
  }, [incompatiblePromotions, promotion.id])

  const promotionsAbove = useMemo(() => {
    return incompatiblePromotions[currentContext].filter(
      (x) => x.priority < currentPromotionPriority
    )
  }, [incompatiblePromotions, currentPromotionPriority, currentContext])

  const promotionsBelow = useMemo(() => {
    return incompatiblePromotions[currentContext].filter(
      (x) => x.priority > currentPromotionPriority
    )
  }, [incompatiblePromotions, currentPromotionPriority, currentContext])

  const hasIncompatiblePromotions = useMemo(() => {
    let hasPromotions = false

    Object.keys(incompatiblePromotions).forEach((key) => {
      if (incompatiblePromotions[key]?.length) hasPromotions = true
    })

    return hasPromotions
  }, [incompatiblePromotions])

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  useEffect(() => {
    if (promotion.id) {
      selfAsync(async () => {
        setLoadingIncompatPromotions(true)

        // Load incompatible promotions from club context
        const [clubRes, clubErr] = await to(
          getIncompatiblePromotions(promotion.id, { context: 'club' })
        )

        // Load incompatible promotions from membership context
        const [membershipRes, membershipErr] =
          currentContext === 'membership'
            ? await to(getIncompatiblePromotions(promotion.id, { context: 'membership' }))
            : [[], null]

        if (!isMounted.current) return

        if (!clubRes || !membershipRes || clubErr || membershipErr) {
          setIncompatiblePromotions({
            club: [],
            membership: [],
          })
        } else {
          setIncompatiblePromotions({
            club: formatOptions(clubRes, promotion),
            membership: formatOptions(membershipRes, promotion),
          })
        }
        setLoadingIncompatPromotions(false)
      })
    }
  }, [promotion.id, promotion.updateTrigger, currentContext, values.clubId])

  const searchPromotions = async (value, context) => {
    clearTimeout(searchDebounce)

    return new Promise((resolve) => {
      searchDebounce = setTimeout(async () => {
        const { membershipId, clubId } = values

        let query = {
          limit: 999,
          page: 1,
          context: context,
          'order[name]': 'asc',
          name: value,
          'groups[]': ['list_promotions_short', 'Default'],
          'state[]': ['published', 'canceled', 'draft'],
        }

        if (context === 'club') {
          query = {
            ...query,
            club: clubId,
          }
        } else if (context === 'membership') {
          query = {
            ...query,
            'membership.id': membershipId,
          }
        }

        const [res] = await to(queryPromotions(query))

        if (!isMounted.current) return

        if (res) {
          resolve(formatOptions(res, promotion))
        } else {
          resolve([])
        }
      }, 500)
    })
  }

  const {
    data: membershipPromotions,
    fetchNextPage: loadNextMembershipPromotionsPage,
    isFetching: isFetchingMembershipPromotions,
    isFetchingNextPage: isFetchingNextPageOfMembershipPromotions,
    status: membershipPromotionsStatus,
  } = useInfiniteQuery(
    [
      CLUB_QUERIES.PROMOTIONS,
      values.clubId,
      { context: 'membership', 'groups[]': ['list_promotions_short', 'Default'] },
    ],
    ({ pageParam }) =>
      queryPromotions({
        limit: 50,
        page: pageParam,
        context: 'membership',
        'order[name]': 'asc',
        'state[]': ['published', 'canceled', 'draft'],
        club: values.clubId,
        'membership.id': values.membershipId,
        'groups[]': ['list_promotions_short', 'Default'],
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length < 50) return undefined
        return pages?.length + 1
      },
      select: (data) => {
        let returnData = []
        data.pages.forEach((page) => {
          returnData = [...returnData, ...page]
        })
        return formatOptions(returnData, promotion)
      },
      enabled:
        currentContext === 'membership' && values?.membershipId && !!promotion ? true : false,
    }
  )

  const {
    data: clubPromotions,
    fetchNextPage: loadNextClubPromotionsPage,
    isFetching: isFetchingClubPromotions,
    isFetchingNextPage: isFetchingNextPageOfClubPromotions,
    status: clubPromotionsStatus,
  } = useInfiniteQuery(
    [
      CLUB_QUERIES.PROMOTIONS,
      values.clubId,
      { context: 'club', 'groups[]': ['list_promotions_short', 'Default'] },
    ],
    ({ pageParam = 1 }) =>
      queryPromotions({
        limit: 50,
        page: pageParam,
        context: 'club',
        'order[name]': 'asc',
        'state[]': ['published', 'canceled', 'draft'],
        club: values.clubId,
        'groups[]': ['list_promotions_short', 'Default'],
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length < 50) return undefined
        return pages?.length + 1
      },
      select: (data) => {
        let returnData = []
        data.pages.forEach((page) => {
          returnData = [...returnData, ...page]
        })
        return formatOptions(returnData, promotion)
      },
      enabled: !!promotion,
    }
  )

  const saveIncompatiblePromotions = (selectedOptions, context) => {
    const previous = incompatiblePromotions[context]
    const promotionsToRemove = incompatiblePromotions[context].filter(
      (x) => !selectedOptions.find((p) => p.uuid === x.uuid)
    )
    const promotionsToAdd = selectedOptions.filter(
      (option) => !incompatiblePromotions[context].find((x) => x.uuid === option.uuid)
    )

    setShowDropdowns({ [context]: false })
    setIncompatiblePromotions({ [context]: selectedOptions })

    try {
      promotionsToRemove.map((p) => removeIncompatiblePromotion(promotion.id, p.uuid))
      promotionsToAdd.map((p) => addIncompatiblePromotion(promotion.id, p.uuid))
    } catch (error) {
      setIncompatiblePromotions({ [context]: previous })
    }
  }

  const removePromotion = (p, context) => {
    const previous = incompatiblePromotions[context]
    setIncompatiblePromotions((prev) => ({
      [context]: prev[context].filter((x) => x.id !== p.id),
    }))

    try {
      removeIncompatiblePromotion(promotion.id, p.uuid)
    } catch (error) {
      setIncompatiblePromotions({ [context]: previous })
    }
  }

  const getOptions = (context = currentContext) => {
    if (context === 'club' && clubPromotions) {
      return clubPromotions
    } else if (context === 'membership' && membershipPromotions) {
      return membershipPromotions
    } else {
      return []
    }
  }

  const renderPill = (promotion, context) => {
    const isArchived = promotion?.status === 'archived'
    const isInactive = promotion?.status === 'draft' || promotion.status === 'canceled'

    if (isArchived || isInactive) {
      return (
        <HoverDiv
          direction="left"
          hoverText={
            isArchived ? t('sentences.archivedPromotion') : t('sentences.inactivePromotion')
          }
          className={cx(styles.miniPill)}
          key={promotion.id}
        >
          <DotMarker color="red" />
          <span>{promotion.label}</span>
          {!disabled && (
            <span
              className={styles.closeButton}
              onClick={() => removePromotion(promotion, context)}
            >
              <CrossIcon />
            </span>
          )}
        </HoverDiv>
      )
    }

    return (
      <div key={promotion.id} className={styles.miniPill}>
        <span>{promotion.label}</span>
        {!disabled && (
          <span className={styles.closeButton} onClick={() => removePromotion(promotion, context)}>
            <CrossIcon />
          </span>
        )}
      </div>
    )
  }

  return (
    <div className={cx(styles.container, className)}>
      <Label label={t('sentences.incompatiblePromotions')} secondary />

      {loadingIncompatPromotions && <PulseLoader showIf={true} />}
      {!loadingIncompatPromotions && (
        <React.Fragment>
          {hasIncompatiblePromotions ? (
            <div className={cx(styles.incompatibleContainer)}>
              {currentContext === 'membership' && incompatiblePromotions['club']?.length ? (
                <div className={cx(styles.row)}>
                  <HoverDiv
                    hoverText={t('sentences.clubContextPromotions')}
                    direction="left"
                    className={cx(styles.iconContainer)}
                  >
                    <HomeIcon className={cx(styles.icon)} />
                  </HoverDiv>
                  <div className={cx(styles.pillsContainer)}>
                    {incompatiblePromotions['club'].map((p) => renderPill(p, 'club'))}
                  </div>
                </div>
              ) : null}

              {promotionsAbove?.length ? (
                <div className={cx(styles.row)}>
                  <HoverDiv
                    hoverText={t('sentences.promotionsWithHigherPriority')}
                    direction="left"
                    className={cx(styles.iconContainer)}
                  >
                    <HigherPriorityIcon className={cx(styles.icon)} />
                  </HoverDiv>
                  <div className={cx(styles.pillsContainer)}>
                    {promotionsAbove.map((p) => renderPill(p, currentContext))}
                  </div>
                </div>
              ) : null}
              {promotionsBelow?.length ? (
                <div className={cx(styles.row)}>
                  <HoverDiv
                    hoverText={t('sentences.promotionsWithLowerPriority')}
                    direction="left"
                    className={cx(styles.iconContainer)}
                  >
                    <LowerPriorityIcon className={cx(styles.icon)} />
                  </HoverDiv>
                  <div className={cx(styles.pillsContainer)}>
                    {promotionsBelow.map((p) => renderPill(p, currentContext))}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className={cx(styles.buttonsRows)}>
            {currentContext === 'club' && (
              <ButtonAndDropdownSelectWrapper
                id="select-incompatible-promotions-dropdown-club"
                buttonProps={{
                  icon: 'plus',
                  text: t('words.promotions'),
                  onClick: () =>
                    !showDropdowns[currentContext] && setShowDropdowns({ [currentContext]: true }),
                  disabled: disabled,
                }}
                dropdownProps={{
                  preselectedOptions: incompatiblePromotions[currentContext],
                  selectAllAvailable: false,
                  isVisible: showDropdowns[currentContext],
                  searchEnabled: true,
                  onCancel: () => setShowDropdowns({ [currentContext]: false }),
                  onSave: (newOptions) => saveIncompatiblePromotions(newOptions, currentContext),
                  onSearch: (value) => searchPromotions(value, currentContext),
                  loadMorePages: () =>
                    currentContext === 'membership'
                      ? loadNextMembershipPromotionsPage()
                      : currentContext === 'club'
                      ? loadNextClubPromotionsPage()
                      : () => {},
                  searchPlaceholder: t('sentences.searchPromotion'),
                  options: getOptions(),
                  loading:
                    (currentContext === 'club' &&
                      (clubPromotionsStatus === 'loading' ||
                        isFetchingClubPromotions ||
                        isFetchingNextPageOfClubPromotions)) ||
                    (currentContext === 'membership' &&
                      (membershipPromotionsStatus === 'loading' ||
                        isFetchingMembershipPromotions ||
                        isFetchingNextPageOfMembershipPromotions)),
                }}
              />
            )}
            {currentContext === 'membership' && (
              <React.Fragment>
                <ButtonAndDropdownSelectWrapper
                  id="select-incompatible-promotions-dropdown-membership"
                  buttonProps={{
                    icon: 'plus',
                    text: t('sentences.membershipPromotions'),
                    onClick: () =>
                      !showDropdowns.membership &&
                      setShowDropdowns({ club: false, membership: true }),
                    disabled: disabled,
                  }}
                  dropdownProps={{
                    preselectedOptions: incompatiblePromotions.membership,
                    selectAllAvailable: false,
                    isVisible: showDropdowns.membership,
                    searchEnabled: true,
                    onCancel: () => setShowDropdowns({ membership: false }),
                    onSave: (newOptions) => saveIncompatiblePromotions(newOptions, 'membership'),
                    onSearch: (value) => searchPromotions(value, 'membership'),
                    loadMorePages: () => loadNextMembershipPromotionsPage(),
                    searchPlaceholder: t('sentences.searchPromotion'),
                    options: getOptions('membership'),
                    loading:
                      membershipPromotionsStatus === 'loading' ||
                      isFetchingMembershipPromotions ||
                      isFetchingNextPageOfMembershipPromotions,
                  }}
                />
                <ButtonAndDropdownSelectWrapper
                  id="select-incompatible-promotions-dropdown-club"
                  buttonProps={{
                    icon: 'plus',
                    text: t('sentences.clubPromotions'),
                    onClick: () =>
                      !showDropdowns.club && setShowDropdowns({ club: true, membership: false }),
                    disabled: disabled,
                  }}
                  dropdownProps={{
                    preselectedOptions: incompatiblePromotions.club,
                    selectAllAvailable: false,
                    isVisible: showDropdowns.club,
                    searchEnabled: true,
                    onCancel: () => setShowDropdowns({ club: false }),
                    onSave: (newOptions) => saveIncompatiblePromotions(newOptions, 'club'),
                    onSearch: (value) => searchPromotions(value, 'club'),
                    loadMorePages: () => loadNextClubPromotionsPage(),
                    searchPlaceholder: t('sentences.searchPromotion'),
                    options: getOptions('club'),
                    loading:
                      clubPromotionsStatus === 'loading' ||
                      isFetchingClubPromotions ||
                      isFetchingNextPageOfClubPromotions,
                  }}
                />
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

IncompatiblePromotions.propTypes = {
  promotion: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

IncompatiblePromotions.defaultProps = {
  className: '',
  disabled: false,
}

export default IncompatiblePromotions
