import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import PromotionReservationWrapper from '../PromotionReservationWrapper'
import MembershipCardCheckers from '../MembershipCardCheckers'
import MembershipCardRules from '../MembershipCardRules'

const MembershipCardSingleReservationPolicy = ({ policy }) => {
  const { t } = useTranslation()

  const checkers = useMemo(() => {
    if (!policy?.checkers) return null
    return policy.checkers
  }, [policy.checkers])

  const rules = useMemo(() => {
    if (!policy?.rules) return null
    return policy.rules
  }, [policy.rules])

  return (
    <PromotionReservationWrapper className={cx(styles.wrapper)} title={policy?.name}>
      {!checkers?.length && (
        <div className={cx(styles.emptyPromotion)}>
          <p className={cx(styles.emptyText)}>{t('sentences.noPolicyCheckers')}</p>
        </div>
      )}

      {checkers?.length ? <MembershipCardCheckers checkers={checkers} /> : null}

      {rules?.length ? <MembershipCardRules rules={rules} context="reservationPolicies" /> : null}
    </PromotionReservationWrapper>
  )
}

MembershipCardSingleReservationPolicy.propTypes = {
  policy: PropTypes.object,
}

MembershipCardSingleReservationPolicy.defaultProps = {}

export default MembershipCardSingleReservationPolicy
