import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

export function _getGolfCartsFleets(query) {
  const URL = `${getApiPlatformBaseUrl()}/warehouses${query}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/ld+json',
    },
  })
}

export function _getGCFleet(uuid) {
  const URL = `${getApiPlatformBaseUrl()}/warehouses/${uuid}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _createGCFleet(payload) {
  const URL = `${getApiPlatformBaseUrl()}/clubs/${payload.clubId}/warehouse/new`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export function _updateGCFleet(lang, payload, uuid) {
  const URL = `${getApiPlatformBaseUrl()}/warehouses/${uuid}`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': lang === 'se' ? 'sv' : 'en',
    },
    body: JSON.stringify(payload),
  })
}

export function _removeGCFleet(uuid) {
  const URL = `${getApiPlatformBaseUrl()}/warehouses/${uuid}`

  return request(URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _getRentalActivities(golfCartId) {
  const URL = `${getApiPlatformBaseUrl()}/inventory-course-schedules/${golfCartId}/activity-days`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _createProductAvailability(payload) {
  const URL = `${getApiPlatformBaseUrl()}/product-availability-settings`

  return request(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export function _updateProductAvailability(payload) {
  const URL = `${getApiPlatformBaseUrl()}/product-availability-settings/${payload.id}`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}
