import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/** ---------------- OLD ---------------- */
/** ---------------- OLD ---------------- */
/** ---------------- OLD ---------------- */

export function _createAdmin(payload) {
  const URL = `${getApiPlatformBaseUrl()}/club-users`

  return request(URL, {
    method: 'POST',

    body: payload,
  })
}

export function _updateAdmin(payload) {
  const URL = `${getApiPlatformBaseUrl()}/club-users/${payload.id}`

  return request(URL, {
    method: 'PUT',

    body: payload,
  })
}

export function _deleteAdmin(id) {
  const URL = `${getApiPlatformBaseUrl()}/club-users/${id}`

  return request(URL, {
    method: 'DELETE',
  })
}

/**
 * Get's a list of admins
 *
 * @param {string} token
 * @param {string} role
 * @returns {Promise}
 */
export function _getAdmins(role, query) {
  const URL = `${getApiPlatformBaseUrl()}/club-users?roles=${role}${query}`

  return request(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/ld+json',
    },
  })
}

/**
 * Get's a single admin by ID
 *
 * @param {number} id
 * @returns {Promise}
 */
export function _getSpecificAdmin(id) {
  const URL = `${getApiPlatformBaseUrl()}/club-users/${id}`

  return request(URL, {
    method: 'GET',
  })
}

/** ---------------- NEW ---------------- */
/** ---------------- NEW ---------------- */
/** ---------------- NEW ---------------- */
export function _addClubToAdmin(clubId, payload) {
  const URL = `${getApiPlatformBaseUrl()}/clubs/${clubId}/admin/add`

  return request(URL, {
    method: 'PUT',

    body: payload,
  })
}

export function _removeClubFromAdmin(clubId, payload) {
  const URL = `${getApiPlatformBaseUrl()}/clubs/${clubId}/admin/remove`

  return request(URL, {
    method: 'PUT',

    body: payload,
  })
}

export function _getFederationId(federation = 'git') {
  const URL = `${getApiPlatformBaseUrl()}/federations?name=${federation}`

  return request(URL, {
    method: 'GET',
  })
    .then((data) => [data, undefined])
    .catch((e) => [undefined, e])
}

export function _getHomeClubInfo(gitId) {
  const URL = `${getApiPlatformBaseUrl()}/clubs?gitId=${gitId}`

  return request(URL, {
    method: 'GET',
  })
    .then((data) => [data, undefined])
    .catch((e) => [undefined, e])
}
