export const tableHeader = {
  name: {
    name: '.name',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
    disabled: true,
  },
  payout_frequency: {
    name: '.sentences.payoutFrequency',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
    disabled: true,
  },
  next_payment_due: {
    name: '.sentences.nextPaymentDue',
    type: 'paymentDue',
    valueType: 'string',
    width: '100px',
    selected: true,
    disabled: true,
    style: { fontSize: '15px' },
  },
  trigger_amount: {
    name: '.sentences.triggerAmount',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
    disabled: true,
    style: { display: 'flex', justifyContent: 'right' },
  },
  min_amount: {
    name: '.sentences.minAmount',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
    disabled: true,
    style: { display: 'flex', justifyContent: 'right' },
  },
  total_amount: {
    name: '.sentences.balance',
    type: 'label',
    valueType: 'string',
    width: '100px',
    selected: true,
    disabled: true,
    style: { display: 'flex', justifyContent: 'right' },
  },
}
