import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'
import _ from 'lodash'

import Container from '@sweetspot/club-portal-legacy/components/Container'

import style from '../style.module.scss'

class Vouchers extends Component {
  constructor() {
    super()

    this.state = {
      iframeUrl: '',
    }

    this.getIFrameUrl = this.getIFrameUrl.bind(this)
  }

  componentDidMount() {
    this.getIFrameUrl()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.golfClub, this.props.golfClub)) {
      this.getIFrameUrl()
    }
  }

  getIFrameUrl() {
    const {
      golfClub: { selectedId, list: clubs },
    } = this.props
    const { REACT_APP_METABASE_SITE_URL, REACT_APP_METABASE_SECRET_KEY } = process.env
    const { uuid } = clubs.find((club) => club.id === selectedId) || {}

    const payload = {
      resource: { dashboard: 162 },
      params: {
        club: uuid,
      },
      exp: Math.round(Date.now() / 1000) + 600,
    }

    const token = jwt.sign(payload, REACT_APP_METABASE_SECRET_KEY)

    const iframeUrl = `${REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=true`

    this.setState({ iframeUrl })
  }

  render() {
    const { iframeUrl } = this.state

    return (
      <Container>
        <div className={style.container}>
          <div className={style.content}>
            <iframe src={iframeUrl} title="Vouchers" frameBorder="0" width="100%" height="100%" />
          </div>
        </div>
      </Container>
    )
  }
}

Vouchers.propTypes = {
  token: PropTypes.string.isRequired,
  golfClub: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    golfClub: state.golfClub,
  }
}

export default connect(mapStateToProps)(Vouchers)
