import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

/**
 * Get fee units
 *
 * @param {string} federationName
 */
export const getFeeUnits = (federationName) => {
  const url = `${getApiPlatformBaseUrl()}/fee-units?federations.name=${federationName}`
  return request(url)
}

/**
 * Add fee to membership
 *
 * @param {number} membershipId
 */
export const addFeeToMembership = (
  membershipId,
  payload = {
    fee_unit: 0,
    vat: 0,
    price: 0,
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/crm/memberships/${membershipId}/fees/new`
  return request(url, {
    method: 'put',
    body: payload,
  })
}

/**
 * Update fee
 *
 * @param {number} feeId
 */
export const updateFee = (
  feeId,
  payload = {
    fee_unit: 0,
    price: 0,
    vat: 0,
  }
) => {
  const url = `${getApiPlatformBaseUrl()}/crm/fees/${feeId}`
  return request(url, {
    method: 'put',
    body: payload,
  })
}

/**
 * Delete fee
 *
 * @param {number} feeId
 */
export const deleteFee = (feeId) => {
  const url = `${getApiPlatformBaseUrl()}/crm/fees/${feeId}`
  return request(url, {
    method: 'delete',
  })
}
