import { useBaysContext } from '../BaysProvider'
import { BaySideBarMode } from '../types'
import CreateBay from './CreateBay'
import UpdateBay from './UpdateBay'
import BayDetails from './BayDetails'
import UpdateBayBulk from './UpdateBayBulk'

const BaySideBar = () => {
  const { baySideBarMode } = useBaysContext()

  if (baySideBarMode === BaySideBarMode.VIEW) {
    return <BayDetails />
  }

  if (baySideBarMode === BaySideBarMode.CREATE) {
    return <CreateBay />
  }

  if (baySideBarMode === BaySideBarMode.EDIT) {
    return <UpdateBay />
  }

  if (baySideBarMode === BaySideBarMode.EDIT_BULK) {
    return <UpdateBayBulk />
  }

  return null
}

export default BaySideBar
