import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { t } from 'i18next'

import styles from './styles.module.scss'

import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import GCForm from './components/GCForm'

import {
  addToast,
  createGCFleet,
  createProductAvailability,
  getGCFleet,
  getGolfCartFleets,
  getRentalActivities,
  removeGCFleet,
  updateGCFleet,
  updateProductAvailability,
} from '@sweetspot/club-portal-legacy/store/actions'
import {
  getProductVariants,
  updateProductVariantFee,
} from '@sweetspot/sweetspot-js/features/productVariants/services/api-platform'
import { getProducts } from '@sweetspot/sweetspot-js/features/products/services/api-platform'
import { OpeningHours } from './components/OpeningHours'
import RentalActivities from './components/RentalActivities'
import ReservedCarts from './components/ReservedCarts'

const initialForm = {
  name: '',
  fee: '',
  vat: '',
  isGCEnabled: false,
  isEnabledForPlayers: false,
  includedCourses: [],
  inventory: [],
}

const TABS = {
  SETTINGS: 'SETTINGS',
  RESERVED_CARTS: 'RESERVED_CARTS',
  RENTAL_ACTIVITY: 'RENTAL_ACTIVITY',
  OPENING_HOURS: 'OPENING_HOURS',
}

class GolfCartModal extends Component {
  state = {
    warehouseUuid: '',
    isLoading: false,
    form: initialForm,
    originForm: {},
    currentTab: TABS.SETTINGS,
    usedCourses: [],
    errors: {},
    activities: [],
    product: {},
  }

  componentDidMount() {
    let warehouseUuid = this.props.match.params.id
    if (warehouseUuid === 'new') warehouseUuid = ''

    this.setState({ warehouseUuid })
    this.getGCInfo(warehouseUuid)
  }

  getGCInfo = (warehouseUuid) => {
    const { token, dispatch, golfClubId } = this.props

    let query = `?golf_club_id=${golfClubId}`

    this.setState({ isLoading: true })

    const promises = [dispatch(getGolfCartFleets(token, query))]
    if (warehouseUuid) {
      promises.push(dispatch(getGCFleet(token, warehouseUuid)))
      promises.push(dispatch(getRentalActivities(token, warehouseUuid)))
      promises.push(getProductVariants(warehouseUuid))
      promises.push(getProducts({ golf_club_id: golfClubId }))
    }

    Promise.all(promises)
      .then((values) => {
        const usedCourses = []
        const fleets = values[0]['hydra:member'] || []
        fleets.forEach((fleet) => {
          usedCourses.push(...fleet.courses)
        })

        const fleet = fleets.find((item) => item.uuid === warehouseUuid)

        const products = values[4] || []
        const product = products?.find((product) => (product.code = 'golf_cart'))

        this.setState({
          isLoading: false,
          usedCourses: usedCourses.map((course) => course.id),
        })

        if (warehouseUuid && fleet && product) {
          const inventory = values[3] ?? []

          const fee = inventory?.[0]?.fees?.[0] ?? { price: '', vat: '' }
          const { price, vat } = fee

          const formData = {
            name: fleet.name,
            inventory: inventory,
            fee: (price / 100).toString(),
            vat: vat.toString(),
            isGCEnabled: fleet.is_active,
            isEnabledForPlayers: fleet.is_allowed_for_players,
            includedCourses: values[1].courses.map((course) => {
              const rentalTime = course.product_availability_setting?.rental_time
              const time = moment().set({
                hour: rentalTime ? moment.duration(rentalTime).hours() : 6,
                minute: rentalTime ? moment.duration(rentalTime).minutes() : 0,
                second: 0,
              })

              return {
                ...course,
                productAvailability: {
                  id: course.product_availability_setting?.id,
                  time,
                  minutes: course.product_availability_setting?.before_tee_time
                    ? moment
                        .duration(course.product_availability_setting?.before_tee_time)
                        .asMinutes()
                    : 30,
                },
              }
            }),
          }

          this.setState({
            form: formData,
            originForm: formData,
            activities: values[2],
            warehouseUuid: fleet.uuid,
            product: product,
          })
        } else if (warehouseUuid && !fleet) {
          dispatch(addToast('golfCartInAnotherClub', 'error'))
          this.handleClose()
        }
      })
      .catch(() => {
        this.handleClose()
      })
  }

  handleClose = () => {
    this.props.history.push('/settings/golf-carts', { shouldReload: true })
  }

  handleReset = () => {
    const { originForm, usedCourses } = this.state
    const { includedCourses } = this.state.form

    const includedCoursesIds = includedCourses.map((c) => c.id)
    const newUsedCourses = [...usedCourses].filter((id) => !includedCoursesIds.includes(id))

    this.setState({
      form: { ...originForm },
      usedCourses: newUsedCourses,
    })
  }

  handleRemove = () => {
    const { warehouseUuid } = this.state
    const { token, dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(removeGCFleet(token, warehouseUuid))
      .then(() => {
        this.handleClose()
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleFormChange = (name, value, updateBoth) => {
    const { form, originForm } = this.state

    if (updateBoth) {
      this.setState({ originForm: { ...originForm, [name]: value } })
    }

    this.setState({
      form: { ...form, [name]: value },
    })
  }

  handleVatAndPriceChange = (feeValue, vatValue, inventory) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        fee: feeValue / 100,
        vat: vatValue,
        inventory: inventory,
      },
    })
  }

  handleChangeUsed = (id) => {
    const { usedCourses } = this.state

    if (usedCourses.includes(id)) {
      this.setState({ usedCourses: usedCourses.filter((course) => course !== id) })
    } else {
      this.setState({ usedCourses: usedCourses.concat(id) })
    }
  }

  changeTab = (newTab) => {
    this.setState({ currentTab: newTab })
  }

  getPayload = () => {
    const {
      form: { name, fee, vat, isGCEnabled, isEnabledForPlayers, includedCourses },
    } = this.state
    const { golfClubId } = this.props

    const payload = {
      name,
      fee: parseInt(fee),
      vat: parseInt(vat),
      is_active: isGCEnabled,
      is_allowed_for_players: isEnabledForPlayers,
      courses: includedCourses.map((course) => `/api/courses/${course.id}`),
      clubId: golfClubId,
    }

    return payload
  }

  getProductAvailability = (includedCourses) => {
    const productAvailability = includedCourses.map((course) => {
      const { time, minutes } = course.productAvailability

      return {
        id: course.productAvailability?.id,
        course: course.id,
        rental_time: moment.duration(time.hours() * 60 + time.minutes(), 'minutes').toISOString(),
        time: moment
          .duration(time.hours() * 60 + time.minutes() - minutes, 'minutes')
          .toISOString(),
        before_tee_time: moment.duration(parseFloat(minutes), 'minutes').toISOString(),
      }
    })

    return productAvailability
  }

  handleCreate = async () => {
    const { form } = this.state
    const { token, history, dispatch, golfClubId } = this.props

    const payload = this.getPayload()

    const productAvailability = this.getProductAvailability(form.includedCourses)
    this.setState({ isLoading: true })

    const warehouse = await dispatch(createGCFleet(token, payload))

    const promises = []

    productAvailability.forEach((item) => {
      promises.push(dispatch(createProductAvailability(token, item)))
    })
    await Promise.all(promises)

    // Needs to be executed after createAvailability if it is the first time a warehouse is created in the club.
    const products = await getProducts({ golf_club_id: golfClubId })
    if (products && warehouse) {
      this.setState({
        isLoading: false,
        warehouseUuid: warehouse.uuid,
        product: products.find((product) => (product.code = 'golf_cart')),
        originForm: form,
      })
      history.push(`/settings/golf-carts/${warehouse.uuid}`)
    } else {
      this.setState({ isLoading: false })
    }
  }

  parseAndReplace = (input) =>
    typeof input === 'string' ? parseFloat(input.replace(',', '.')) : input

  handleUpdate = () => {
    const { warehouseUuid, form, originForm } = this.state
    const { token, lang, dispatch } = this.props

    const payload = this.getPayload()

    const productAvailability = this.getProductAvailability(form.includedCourses)

    const promises = [dispatch(updateGCFleet(token, lang, payload, warehouseUuid))]
    const includedCourses = originForm.includedCourses
    productAvailability.forEach((item) => {
      if (
        includedCourses.find((course) => course.id === item.course)?.productAvailability &&
        item.id
      ) {
        promises.push(dispatch(updateProductAvailability(token, item)))
      } else {
        promises.push(dispatch(createProductAvailability(token, item)))
      }
    })

    const newPrice = this.parseAndReplace(form.fee)
    const newVat = this.parseAndReplace(form.vat)

    for (var cart of form.inventory) {
      for (var fee of cart.fees) {
        promises.push(updateProductVariantFee(fee.id, { vat: newVat, price: newPrice }))
      }
    }
    this.setState({ isLoading: true })
    Promise.all(promises)
      .then(() => {
        this.setState({
          isLoading: false,
          originForm: form,
        })
      })
      .catch((err) => {
        console.log(err)
        this.getGCInfo(warehouseUuid)
      })
  }

  renderBody = () => {
    const { warehouseUuid, currentTab, form, originForm, usedCourses, activities, product } =
      this.state
    const { courses } = this.props

    switch (currentTab) {
      case TABS.SETTINGS:
        return (
          <GCForm
            courses={courses}
            usedCourses={usedCourses}
            form={form}
            isCreate={!warehouseUuid}
            warehouseUuid={warehouseUuid}
            productId={product?.id}
            activities={activities}
            originForm={originForm}
            onChange={this.handleFormChange}
            onChangeUsedCourses={this.handleChangeUsed}
            onCreate={this.handleCreate}
            onUpdate={this.handleUpdate}
            onReset={this.handleReset}
            onRemove={this.handleRemove}
            onFirstCreate={this.handleVatAndPriceChange}
          />
        )
      case TABS.RESERVED_CARTS:
        return <ReservedCarts warehouseUuid={warehouseUuid} timezone={courses?.[0]?.timezone} />
      case TABS.RENTAL_ACTIVITY:
        return <RentalActivities warehouseUuid={warehouseUuid} timezone={courses?.[0]?.timezone} />
      case TABS.OPENING_HOURS:
        return <OpeningHours warehouseUuid={warehouseUuid} />
    }

    return null
  }

  render = () => {
    const { warehouseUuid, isLoading, currentTab } = this.state

    return (
      <ModalContainer width="half" loading={isLoading} onClose={this.handleClose}>
        <div className={styles.container}>
          {!warehouseUuid ? (
            <div className={styles.headerText}>
              <Text textId="golfCarts.createGolfCart" />
            </div>
          ) : (
            <div className={cx('ss-btn-group', styles.buttonGroup)}>
              <button
                className={cx(
                  'system-button md-32',
                  currentTab === TABS.RENTAL_ACTIVITY ? 'secondary' : 'light-outline'
                )}
                onClick={() => this.changeTab(TABS.RENTAL_ACTIVITY)}
              >
                {t('golfCarts.rentalSettings')}
              </button>
              <button
                className={cx(
                  'system-button md-32',
                  currentTab === TABS.RESERVED_CARTS ? 'secondary' : 'light-outline'
                )}
                onClick={() => this.changeTab(TABS.RESERVED_CARTS)}
              >
                {t('sentences.reservedCarts')}
              </button>

              <button
                className={cx(
                  'system-button md-32',
                  currentTab === TABS.OPENING_HOURS ? 'secondary' : 'light-outline'
                )}
                onClick={() => this.changeTab(TABS.OPENING_HOURS)}
              >
                {t('warehouse.openingHours.heading')}
              </button>
              <button
                className={cx(
                  'system-button md-32',
                  currentTab === TABS.SETTINGS ? 'secondary' : 'light-outline'
                )}
                onClick={() => this.changeTab(TABS.SETTINGS)}
              >
                {t('golfCarts.fleetSettings')}
              </button>
            </div>
          )}

          {this.renderBody()}
        </div>
      </ModalContainer>
    )
  }
}

GolfCartModal.propTypes = {
  // From Redux
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  golfClubId: PropTypes.number.isRequired,
  dispatch: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.me.lang,
  golfClubId: state.golfClub.selectedId,
  courses: state.golfCourse.list,
  memberships: state.membership.totalList,
})

export default connect(mapStateToProps)(GolfCartModal)
