import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ItemBox from '../ItemBox'

const StatusHeader = ({ isConfirmed, isPaid, isAwaitingPayment }) => {
  const { t } = useTranslation()

  if (!isConfirmed) {
    return (
      <ItemBox className={cx(styles.container)}>
        <p className={cx(styles.text)}>
          <span className={cx('material-icons md-red', styles.icon)}>brightness_1</span>
          {t('words.unconfirmed')}
        </p>
      </ItemBox>
    )
  }

  return (
    <ItemBox className={cx(styles.container)}>
      {isConfirmed && (
        <p className={cx(styles.text)}>
          <span className={cx('material-icons md-green', styles.icon)}>brightness_1</span>
          {t('words.confirmed')}
        </p>
      )}

      {isPaid && (
        <p className={cx(styles.text)}>
          <span className={cx('material-icons md-green', styles.icon)}>brightness_1</span>
          {t('words.paid')}
        </p>
      )}

      {isAwaitingPayment && (
        <p className={cx(styles.text)}>
          <span className={cx('material-icons md-red', styles.icon)}>brightness_1</span>
          {t('sentences.paymentIncomplete')}
        </p>
      )}
    </ItemBox>
  )
}

StatusHeader.propTypes = {
  isConfirmed: PropTypes.bool,
  isPaid: PropTypes.bool,
  isAwaitingPayment: PropTypes.bool,
}

StatusHeader.defaultProps = {
  isConfirmed: false,
  isPaid: false,
  isAwaitingPayment: false,
}

export default StatusHeader
