import { useTranslation } from 'react-i18next'

import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'
import BucketConfigurationSelect from '../BucketConfigurationSelect/BucketConfigurationSelect'

const PriceRounding = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: { priceRounding, priceRoundingData, setPriceRounding },
  } = useBallPricingContext()

  return (
    <div>
      <div className="text-content-base text-text-dark font-bold">
        <span>{t('sentences.priceRounding')}</span>
      </div>
      <div className="mt-2 flex items-center justify-between">
        <div>{t('sentences.roundBucketPriceToNearest')}</div>
        <BucketConfigurationSelect
          className="w-20"
          baseClassName="w-20"
          value={priceRounding}
          options={priceRoundingData}
          onChange={(value) => setPriceRounding?.(parseFloat(value))}
        />
      </div>
    </div>
  )
}

export default PriceRounding
