// Maybe we should use something more simpler or native? Also kinda pointless, it's just pure obfuscation.
import AES from 'crypto-js/aes'
import ENC from 'crypto-js/enc-utf8'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@sweetspot/shared/util/local-storage'
import { getSessionStorage, removeSessionStorage } from '@sweetspot/shared/util/session-storage'
import { clearBoundaryDoubleQuotes } from '@sweetspot/shared/util/functions'
import { API_PLATFORM_TOKEN_STORAGE_KEYS, API_PLATFORM_TOKEN_SALTS } from './constants'

let authTokenLocal: string | null = null
let refreshTokenLocal: string | null = null

export const getStoredAuthToken = (
  storageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.AUTH_TOKEN_KEY,
  salt = API_PLATFORM_TOKEN_SALTS.AUTH_TOKEN_SALT
) => {
  if (authTokenLocal) return authTokenLocal

  const encryptedAuthToken = getLocalStorage(storageKey) || getSessionStorage(storageKey)

  if (!encryptedAuthToken) return null
  try {
    const token = AES.decrypt(encryptedAuthToken, salt).toString(ENC)
    if (token) {
      authTokenLocal = token
      return token
    }
    return null
  } catch (error) {
    return null
  }
}

export const storeAuthToken = (
  authToken: string,
  storageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.AUTH_TOKEN_KEY,
  salt = API_PLATFORM_TOKEN_SALTS.AUTH_TOKEN_SALT
): void => {
  const cleanAuthToken = clearBoundaryDoubleQuotes(authToken)
  const encryptedToken = AES.encrypt(cleanAuthToken, salt).toString()
  setLocalStorage(storageKey, encryptedToken)
  authTokenLocal = cleanAuthToken
}

export const getStoredRefreshToken = (
  storageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.REFRESH_TOKEN_KEY,
  salt = API_PLATFORM_TOKEN_SALTS.REFRESH_TOKEN_SALT
) => {
  if (refreshTokenLocal) return refreshTokenLocal
  const encryptedRefreshToken = getLocalStorage(storageKey) || getSessionStorage(storageKey)

  if (!encryptedRefreshToken) return null
  try {
    const token = AES.decrypt(encryptedRefreshToken, salt).toString(ENC)
    if (token) {
      refreshTokenLocal = token
      return token
    }
    return null
  } catch (error) {
    return null
  }
}

export const storeRefreshToken = (
  refreshToken: string,
  storageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.REFRESH_TOKEN_KEY,
  salt = API_PLATFORM_TOKEN_SALTS.REFRESH_TOKEN_SALT
): void => {
  const cleanRefreshToken = clearBoundaryDoubleQuotes(refreshToken)
  const encryptedToken = AES.encrypt(cleanRefreshToken, salt).toString()
  setLocalStorage(storageKey, encryptedToken)
  refreshTokenLocal = cleanRefreshToken
}

export const clearStoredAuthToken = (
  storageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.AUTH_TOKEN_KEY
): void => {
  authTokenLocal = null
  removeLocalStorage(storageKey)
  removeSessionStorage(storageKey)
}

export const clearStoredRefreshToken = (
  storageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.REFRESH_TOKEN_KEY
): void => {
  refreshTokenLocal = null
  removeLocalStorage(storageKey)
  removeSessionStorage(storageKey)
}

export const clearAllStoredTokens = (
  authTokenStorageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.AUTH_TOKEN_KEY,
  refreshTokenStorageKey = API_PLATFORM_TOKEN_STORAGE_KEYS.REFRESH_TOKEN_KEY
): void => {
  clearStoredAuthToken(authTokenStorageKey)
  clearStoredRefreshToken(refreshTokenStorageKey)
}
