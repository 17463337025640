import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useRef, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

export interface FileSelectorProps {
  addFile(file: File): void
}

export const FileSelector = (props: FileSelectorProps) => {
  const [isDragActive, setIsDragActive] = useState(false)
  const clickRef = useRef()
  const { t } = useTranslation()

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.addFile(e.dataTransfer.files[0])
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      props.addFile(e.target.files[0])
    }
  }

  const addFileButtonClicked = () => {
    clickRef.current.click()
  }

  return (
    <form onSubmit={(e) => e.preventDefault()} onDragEnter={handleDrag}>
      <input
        ref={clickRef}
        type="file"
        id="input-file-upload"
        multiple={false}
        className={'hidden'}
        onChange={handleChange}
        accept={'application/vnd.ms-excel, text/csv'}
      />
      <label
        className={cx(
          'border-sand-600 flex select-none flex-col items-center justify-center rounded border-2 border-dashed p-6 ',
          {
            'opacity-50': isDragActive,
          }
        )}
      >
        <div className={'flex flex-col items-center'}>
          <FontAwesomeIcon
            icon={faFileArrowUp}
            className={'[&>path]:text-sand-900 pb-3 text-5xl'}
          />
          <p>
            <span onClick={addFileButtonClicked} className={'cursor-pointer text-blue-400'}>{`${t(
              'memberships.membershipImports.label_upload'
            )}`}</span>
            <span className={'lowercase'}>{` ${t('words.or')} ${t(
              'memberships.membershipImports.label_dragAndDrop'
            )}`}</span>
          </p>
          <p>
            {`CSV `}
            <span className={'lowercase'}>{t('memberships.membershipImports.label_upTo')}</span>
            {` 10MB`}
          </p>
        </div>
      </label>
      {isDragActive && (
        <div
          id="drop_zone"
          className={'absolute left-0 top-0 h-full w-full'}
          onDrop={handleDrop}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
        ></div>
      )}
    </form>
  )
}
