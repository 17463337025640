import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import moment from 'moment'
import _slice from 'lodash/slice'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'

import headers from './headers.json'
import useApplications from '@sweetspot/sweetspot-js/features/applications/hooks/useApplications'
import { parseSymbol } from '@sweetspot/sweetspot-js/common/functions/parse'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions'

import { useTranslation } from 'react-i18next'

const PAGE_LIMIT = 15

const PartnerIntegrations = (props) => {
  const {
    applications: _applications,
    isLoading,
    error,
    setApplicationStatus,
  } = useApplications(props.currentClubId)
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1)

  const selectApplications = (data) => {
    return data.map((i) => {
      return {
        applicationName: i.name,
        partnerAdmin: `${i.owner?.first_name || '-'} ${i.owner?.last_name || ''}`,
        partnerEmail: i.owner?.email || '-',
        createdAt: i.created_at ? moment(i.created_at).format('YYYY-MM-DD HH:mm') : ' - ',
        status: {
          label: t(`.applications.label_${i.is_active ? 'Active' : 'Inactive'}`),
          isEnabled: i.is_active,
          onChange: async (value) => {
            await setApplicationStatus(i, value)
          },
        },
      }
    })
  }
  const applications = selectApplications(_applications)

  useEffect(() => {
    if (error) {
      let _error = typeof error === 'symbol' ? parseSymbol(error) : error
      addToast(_error, { appearance: 'error' })
    }
  }, [error])

  return (
    <div className={style.content}>
      <Grid
        isLoading={isLoading}
        values={_slice(applications, (currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)}
        pageTitle={'applications.title_SA'}
        headers={headers}
        shouldHideColumnEdit
        hideArrows
        searchPlaceholder={'.settings.partnerAdmins.searchPlaceholder'}
        pagination
        allowFunctionValues
        totalPages={applications.length === 0 ? 1 : Math.ceil(applications.length / PAGE_LIMIT)}
        rowsPerPage={PAGE_LIMIT}
        totalRows={applications.length}
        displayRangeOfRows
        onRowClick={() => void 0}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentClubId: state.golfClub.selectedId,
})

export default connect(mapStateToProps)(PartnerIntegrations)

PartnerIntegrations.propTypes = {
  currentClubId: PropTypes.number.isRequired,
}
