import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'
import _ from 'lodash'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import GolfCourseSelect from '@sweetspot/club-portal-legacy/components/GolfCourseSelect'

import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'

import { selectGolfCourse } from '@sweetspot/club-portal-legacy/store/actions'

import style from '../style.module.scss'

class Members extends Component {
  Translations = {
    future: GetTranslatedString(this.props.lang, 'future'),
    upcoming: GetTranslatedString(this.props.lang, 'upcoming'),
    expired: GetTranslatedString(this.props.lang, 'expired'),
    canceled: GetTranslatedString(this.props.lang, 'canceled'),
    active: GetTranslatedString(this.props.lang, 'active'),
    placeholder: GetTranslatedString(this.props.lang, 'statistics.selectMembership'),
  }

  membershipTypes = [
    { id: 1, value: 'future', label: this.Translations.future },
    { id: 2, value: 'upcoming', label: this.Translations.upcoming },
    { id: 3, value: 'expired', label: this.Translations.expired },
    { id: 4, value: 'canceled', label: this.Translations.canceled },
    { id: 5, value: 'active', label: this.Translations.active },
  ]

  state = {
    iframeUrl: '',
    membershipState: this.membershipTypes[4],
  }

  componentDidMount() {
    this.getIFrameUrl()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.golfCourses, this.props.golfCourses)) {
      this.getIFrameUrl()
    }
  }

  getIFrameUrl = () => {
    // const { membershipState } = this.state;
    const {
      golfClubs: { selectedId: clubId, list: clubList },
    } = this.props
    const { REACT_APP_METABASE_SITE_URL, REACT_APP_METABASE_SECRET_KEY } = process.env
    const golfClub = clubList.find((club) => club.id === clubId)

    const payload = {
      resource: { dashboard: 66 },
      params: {
        club: golfClub.uuid,
        // membership_card_status: membershipState.value
      },
      exp: Math.round(Date.now() / 1000) + 600,
    }

    const token = jwt.sign(payload, REACT_APP_METABASE_SECRET_KEY)

    const iframeUrl = `${REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=true`

    this.setState({ iframeUrl })
  }

  handleChange = (value) => {
    this.setState(
      {
        membershipState: value,
      },
      this.getIFrameUrl
    )
  }

  renderGolfCourseSelect() {
    const {
      golfCourses: { list: courseList },
      currentCourse,
      selectGolfCourse,
    } = this.props

    return (
      <GolfCourseSelect
        courses={courseList}
        selectedCourse={currentCourse}
        onSelectCourse={selectGolfCourse}
      />
    )
  }

  render() {
    const { iframeUrl } = this.state

    return (
      <Container>
        <div className={style.container}>
          <div className={style.header}>
            {this.renderGolfCourseSelect()}

            {/* <div className={style.select}>
              <Select
                placeholder={this.Translations.placeholder}
                options={this.membershipTypes}
                value={membershipState}
                onChange={this.handleChange}
              />
            </div> */}
          </div>
          <div className={style.content}>
            <iframe src={iframeUrl} title="Membership" frameBorder="0" width="100%" height="100%" />
          </div>
        </div>
      </Container>
    )
  }
}

Members.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfCourses: PropTypes.object.isRequired,
  golfClubs: PropTypes.object.isRequired,
  selectGolfCourse: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfCourses: state.golfCourse,
    currentCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
    golfClubs: state.golfClub,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectGolfCourse: (id) => dispatch(selectGolfCourse(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Members)
