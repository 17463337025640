import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

import {
  InputBase,
  InputContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'

interface BucketConfigurationSelectProps {
  value?: number | string
  index?: number
  onChange: (value: string, index: number | undefined) => void
  options: (number | string)[] | undefined
  className?: string
  baseClassName?: string
  renderValue?: (value?: string | number) => string | number | undefined
}

const BucketConfigurationSelect = ({
  value,
  index,
  onChange,
  options,
  className,
  renderValue,
  baseClassName,
}: BucketConfigurationSelectProps) => {
  if (!renderValue) {
    renderValue = (value) => value
  }

  return (
    <InputSelect value={value?.toString()} onValueChange={(value) => onChange(value, index)}>
      <InputBase className={baseClassName}>
        <InputSelectTrigger asChild>
          <InputContainer>
            <InputSelectStatefulInput
              className={cx('text-content-base h-8 py-1', className)}
              value={renderValue(value?.toString())}
            />
            <InputSelectTrailingContainer className="right-2" />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>
      <InputSelectContent>
        <InputSelectContentView>
          {options?.map((option) => (
            <InputSelectItem key={option} value={option.toString()}>
              <ListItem className="ml-2 h-full !w-[60px] !min-w-[60px] justify-center gap-2">
                <ListItemMainContent className="text-content-base justify-center">
                  {renderValue(option)}
                </ListItemMainContent>
                {option === value && (
                  <ListItemTrailing>
                    <FontAwesomeIcon icon={faCheck} />
                  </ListItemTrailing>
                )}
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default BucketConfigurationSelect
