import { faCircle, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

interface IncreaseDecreasePillProps {
  maxValue: number
  minValue: number
  text: string
  onIncrease?(value: number): void
  onDecrease?(value: number): void
  value?: number
  disabled?: boolean
}
export const IncreaseDecreasePill = (props: IncreaseDecreasePillProps) => {
  const [currentValue, setCurrentValue] = useState<number>(props.value ?? 0)

  useEffect(() => {
    setCurrentValue(props.value ?? 0)
  }, [props.value])

  const isDecreasePossible = () => {
    return !props.disabled && currentValue > props.minValue
  }

  const isIncreasePossible = () => {
    return !props.disabled && currentValue < props.maxValue
  }

  const handleIncrease = () => {
    const newValue = currentValue + 1
    setCurrentValue(newValue)
    props.onIncrease && props.onIncrease(newValue)
  }

  const handleDecrease = () => {
    const newValue = currentValue - 1
    setCurrentValue(newValue)
    props.onDecrease && props.onDecrease(newValue)
  }

  return (
    <div className={'flex flex-col items-center'}>
      <div className={'flex items-center gap-4'}>
        <button onClick={handleDecrease} className={''} disabled={!isDecreasePossible()}>
          <div className={'fa-stack fa-1x'}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`fa-stack-2x text-gray-200 ${
                !isDecreasePossible() && 'cursor-not-allowed !text-gray-100'
              }`}
            />
            <FontAwesomeIcon
              icon={faMinus}
              className={`fa-stack-1x text-brand-black ${
                !isDecreasePossible() && 'cursor-not-allowed !text-gray-300'
              }`}
            />
          </div>
        </button>
        <div className={'text-brand-black text-lg'}>{currentValue}</div>
        <button onClick={handleIncrease} className={'w-auto'} disabled={!isIncreasePossible()}>
          <div className={'fa-stack fa-1x'}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`fa-stack-2x text-gray-200 ${
                !isIncreasePossible() && 'cursor-not-allowed !text-gray-100'
              }`}
            />
            <FontAwesomeIcon
              icon={faPlus}
              className={`fa-stack-1x text-brand-black ${
                !isIncreasePossible() && 'cursor-not-allowed !text-gray-300'
              }`}
            />
          </div>
        </button>
      </div>
      <div className={' text-sm capitalize text-gray-500'}>{props.text}</div>
    </div>
  )
}
