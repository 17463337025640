import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import style from './style.module.scss'

import hash from 'object-hash'

import Checkbox from '../Checkbox'
import ColorCell from './components/ColorCell'
import MainCell from './components/MainCell'
import InputCell from './components/InputCell'
import MembershipCell from './components/MembershipCell'
import OrderCell from './components/OrderCell'
import PaidStatusCell from './components/PaidStatusCell'
import PartnershipCell from './components/PartnershipCell'
import GolfCartCell from './components/GolfCartCell'
import GolfClubCell from './components/GolfClubCell'
import RentalCell from './components/RentalCell'
import Scrollbar from '../Scrollbar'
import SelectCell from './components/SelectCell'
import CheckboxCell from './components/CheckboxCell'
import RightClickMenu from '@sweetspot/sweetspot-js/common/components/RightClickMenu'
import SortingArrows from './components/SortingArrows'
import SpaceWrapper from '../SpaceWrapper'
import StatusCell from './components/StatusCell'
import Text from '../Text'
import TimeCell from './components/TimeCell'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import ToggleCell from './components/ToggleCell'
import PaymentDueCell from './components/PaymentDueCell'
import { LockStateCell } from './components/LockStateCell'

/**
 * This component creates a table with editing, sorting, filtering and both vertical and horizontal
 * scrolling
 */
export default class Table extends Component {
  state = {
    values: this.getValuesWithUniqueID(),
    showVerticalScrollbar: false,
    showHorizontalScrollbar: false,
    selectedRows: [],
    sortItems: [],
    bodyRefSet: false,
  }

  isAltPressed = false
  isShiftPressed = false

  /**
   * Set event listeners
   */
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    window.addEventListener('resize', this.handleOverflow)

    const { defaultSort, defaultSortType, headers } = this.props
    if (defaultSort && Object.prototype.hasOwnProperty.call(headers, defaultSort)) {
      this.handleSort(defaultSort, defaultSortType || 'asc')
    }
  }

  /**
   * If values or filters change, filter and sort values
   * If selectedValues change, call onSelect prop
   *
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      !Utilities.arraysAreEqual(this.props.values, prevProps.values) ||
      !Utilities.arraysAreEqual(this.props.filters, prevProps.filters) ||
      this.props.values.length !== prevProps.values.length
    ) {
      // Get values with IDs and filter
      const newValues = this.getValuesWithUniqueID(true)

      this.setState({ values: newValues })
    }

    if (this.props.deselectAll !== prevProps.deselectAll) {
      if (this.props.deselectAll) this.deselectAll()
    }

    if (!Utilities.arraysAreEqual(this.state.selectedRows, prevState.selectedRows)) {
      if (this.props.onSelect) this.props.onSelect(this.getSelectedValues())
    }

    if (!_.isEqual(this.props.sortItems, prevProps.sortItems) && this.props.sortItems) {
      this.setState({ sortItems: this.props.sortItems })
    }

    this.handleOverflow()
  }

  /**
   * Remove event listeners
   */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
    window.removeEventListener('resize', this.handleOverflow)
  }

  /**
   * Show or hide scrollbars if needed when resizing the window
   */
  handleOverflow = () => {
    if (this.hasXOverflow()) {
      if (!this.state.showHorizontalScrollbar) this.setState({ showHorizontalScrollbar: true })
    } else {
      if (this.state.showHorizontalScrollbar) this.setState({ showHorizontalScrollbar: false })
    }

    if (this.hasYOverflow()) {
      if (!this.state.showVerticalScrollbar) this.setState({ showVerticalScrollbar: true })
    } else {
      if (this.state.showVerticalScrollbar) this.setState({ showVerticalScrollbar: false })
    }
  }

  /**
   * Create a unique hash for every row and save them in an object with the hash as key
   *
   * @param {Boolean} filter determines if the values should be filtered
   */
  getValuesWithUniqueID(filter = false) {
    let values = {}
    this.props.values.forEach((value) => {
      let valueHash = hash(value, { excludeKeys: (key) => key.substring(0, 1) === '_' })

      if (filter && this.props.filters) {
        for (let filter of this.props.filters) {
          if (filter && !filter(value)) return
        }
      }

      values[valueHash] = this.props.allowFunctionValues ? value : Utilities.deepClone(value)
    })

    return values
  }

  /**
   * Returns all rows
   *
   * @returns {Array} all **Table** rows
   * @public
   */
  getValues() {
    return Object.values(this.state.values)
  }

  /**
   * Returns selected rows
   *
   * @returns {Array} selected **Table** rows
   * @public
   */
  getSelectedValues() {
    return this.state.selectedRows.map((uniqueID) => {
      return this.state.values[uniqueID]
    })
  }

  /**
   * Deselects all rows in the table.
   */
  deselectAll() {
    this.setState({ selectedRows: [] })
  }

  handleSort = (name, sortType) => {
    const { sortItems } = this.state
    const { onHeaderClick } = this.props

    const values = this.getValuesWithUniqueID(true)

    let temp = []
    if (sortItems.filter((item) => item.name === name).length) {
      if (sortType) {
        temp = sortItems.map((item) => {
          if (item.name === name) return { ...item, type: sortType }
          return item
        })
      } else {
        temp = sortItems.filter((item) => item.name !== name)
      }
    } else {
      temp = sortItems.concat({ name, type: sortType })
    }

    this.setState({ sortItems: temp })

    if (onHeaderClick) {
      onHeaderClick(temp)
    } else {
      const uniqueIDs = Object.keys(values)

      const tempValues = _.orderBy(
        Object.values(values).map((item, index) => ({
          ...item,
          uniqueID: uniqueIDs[index],
        })),
        temp.map((item) => item.name),
        temp.map((item) => item.type)
      )

      let newValues = {}
      tempValues.forEach((value) => {
        newValues[value.uniqueID] = value
        delete newValues[value.uniqueID].uniqueID
      })

      this.setState({ values: newValues })
    }
  }

  findValueByProperty(key, value) {
    let uniqueIDs = Object.keys(this.state.values)
    let values = Object.values(this.state.values)

    let found = values.findIndex((val) => val[key] === value)
    if (found === -1) return null

    return uniqueIDs[found]
  }

  handleChange = (uniqueId, key, value) => {
    let type = this.props.headers[key].type

    if (type === 'order') {
      this.handleChangeOrder(uniqueId, key, value)
    } else {
      let copy = Utilities.deepClone(this.state.values)
      copy[uniqueId][key] = value
      this.setState({
        values: copy,
      })
    }
  }

  handleChangeOrder(uniqueId, key, value) {
    let originalValue = this.state.values[uniqueId][key]
    let valueToSwitch = this.findValueByProperty(key, value)

    if (valueToSwitch) {
      let copy = Utilities.deepClone(this.state.values)
      copy[uniqueId][key] = value
      copy[valueToSwitch][key] = originalValue
      this.setState({
        values: copy,
      })
    }
  }

  handleCheckHeader = () => {
    let uniqueIDs = Object.keys(this.state.values)

    if (this.state.selectedRows.length !== uniqueIDs.length) {
      this.setState({
        selectedRows: uniqueIDs,
      })
    } else {
      this.setState({
        selectedRows: [],
      })
    }
  }

  handleCheckRow = (uniqueID) => {
    let selectedRows = Utilities.deepClone(this.state.selectedRows)
    let index = selectedRows.indexOf(uniqueID)
    let uniqueIDs = Object.keys(this.state.values)

    if (this.isShiftPressed && selectedRows.length > 0) {
      const prevSelectedRow = selectedRows[selectedRows.length - 1]
      const prevSelectIndex = uniqueIDs.indexOf(prevSelectedRow)
      const newSelectIndex = uniqueIDs.indexOf(uniqueID)

      const lowestIndex = prevSelectIndex < newSelectIndex ? prevSelectIndex : newSelectIndex
      const highestIndex = prevSelectIndex > newSelectIndex ? prevSelectIndex : newSelectIndex

      for (let i = 0; i < uniqueIDs.length; i++) {
        if (i >= lowestIndex && i <= highestIndex) {
          let isSelected = selectedRows.indexOf(uniqueIDs[i])
          if (isSelected === -1) {
            selectedRows.push(uniqueIDs[i])
          }
        }
      }
    } else {
      if (index === -1) {
        selectedRows.push(uniqueID)
      } else {
        selectedRows.splice(index, 1)
      }
    }

    this.setState({
      selectedRows: selectedRows,
    })
  }

  getObjectKeys() {
    let keys = []
    Object.keys(this.props.headers).forEach((key) => {
      if (key.substring(0, 1) !== '_') {
        keys.push(key)
      }
    })

    return keys
  }

  getObjectValues(row) {
    let keys = this.getObjectKeys(row)
    return keys.map((key) => {
      // Fix for nested objects, if example.name will do row[example][name]
      const childKeyArray = key.split('.')
      const theValue = childKeyArray.reduce((accu, current, index) => {
        return accu[current]
      }, row)

      return theValue
    })
  }

  hasXOverflow() {
    return this._table.scrollWidth > this._table.offsetWidth
  }

  hasYOverflow() {
    return this._body.scrollHeight > this._body.offsetHeight
  }

  scrollToVertically = (ratio) => {
    let height = this._body.scrollHeight - this._body.offsetHeight
    this._body.scrollTo(0, height * ratio)
    if (this._checkboxContainer) {
      this._checkboxContainer.scrollTo(0, height * ratio)
    }
  }

  scrollToHorizontally = (ratio) => {
    let width = this._table.scrollWidth - this._table.offsetWidth
    this._table.scrollTo(width * ratio, 0)
  }

  scrollByVertically(pixels) {
    this._body.scrollBy(0, pixels)
    if (this._checkboxContainer) {
      this._checkboxContainer.scrollBy(0, pixels)
    }

    let height = this._body.scrollHeight - this._body.offsetHeight
    let scroll = this._body.scrollTop
    let ratio = height !== 0 ? scroll / height : 0

    if (this._verticalScroll) this._verticalScroll.scrollTo(0, ratio)
  }

  scrollByHorizontally(pixels) {
    this._table.scrollBy(pixels, 0)

    let width = this._table.scrollWidth - this._table.offsetWidth
    let scroll = this._table.scrollLeft
    let ratio = width !== 0 ? scroll / width : 0

    if (this._horizontalScroll) this._horizontalScroll.scrollTo(ratio, 0)
  }

  handleWheelScroll = (event) => {
    let delta = event.deltaY

    if (this.isAltPressed) {
      this.scrollByHorizontally(delta)
    } else {
      this.scrollByVertically(delta)
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Alt') this.isAltPressed = true
    if (event.key === 'Shift') this.isShiftPressed = true
  }

  handleKeyUp = (event) => {
    if (event.key === 'Alt') this.isAltPressed = false
    if (event.key === 'Shift') this.isShiftPressed = false
  }

  renderCheckboxes() {
    if (!this.props.hasCheckbox) return <div />

    let uniqueIDs = Object.keys(this.state.values)

    let bodyClassNames = [style.checkboxBody]
    if (this.props.fullHeight) bodyClassNames.push(style.fullHeight)

    let checkHeight = 0
    if (this._body) {
      checkHeight = this._body.clientHeight
    }

    return (
      <div className={style.checkboxes}>
        <div className={style.checkboxHeader}>
          <div>
            <Checkbox
              checked={
                this.state.selectedRows.length !== 0 &&
                this.state.selectedRows.length >= Object.keys(this.state.values).length
              }
              onChange={this.handleCheckHeader}
            />
          </div>
        </div>
        <SpaceWrapper>
          <div
            className={bodyClassNames.join(' ')}
            style={{ height: checkHeight + 'px' }}
            ref={(container) => (this._checkboxContainer = container)}
          >
            {uniqueIDs.map((id, index) => this.renderCheckboxBody(id, index))}
          </div>
        </SpaceWrapper>
      </div>
    )
  }

  renderCheckboxBody(uniqueID, index) {
    return (
      <div
        className={style.checkboxRow}
        ref={(row) => (this[`_checkbox_${uniqueID}`] = row)}
        onMouseEnter={() => {
          this[`_row_${uniqueID}`].classList.add(style.hover)
        }}
        onMouseLeave={() => {
          this[`_row_${uniqueID}`].classList.remove(style.hover)
        }}
        key={index}
      >
        <div>
          <Checkbox
            checked={this.state.selectedRows.includes(uniqueID)}
            onChange={() => {
              this.handleCheckRow(uniqueID)
            }}
          />
        </div>
      </div>
    )
  }

  renderHeader() {
    const { sortItems } = this.state
    const { headers, hideHeader, hideArrows, boldHeaders, useSingleColumnSort } = this.props

    const headerValues = Object.values(headers)
    const headerKeys = Object.keys(headers)
    let colTemplate = []
    for (let i = 0; i < headerValues.length; i++) {
      let width = 'minmax(100px, 1fr)'
      if (headerValues?.[i]?.width && headerValues?.[i]?.width !== '') {
        width = `minmax(${headerValues[i].width}, 1fr)`
      }
      if (headerValues?.[i]?.maxWidth && !!headerValues?.[i]?.maxWidth) {
        width = headerValues[i].maxWidth
      }
      colTemplate.push(width)
    }

    return (
      <div
        className={cx(style.headerContent, {
          [style.hideHeader]: hideHeader,
          [style.hideArrows]: hideArrows,
          [style.boldHeaders]: boldHeaders,
        })}
        style={{ gridTemplateColumns: colTemplate.join(' '), height: headerValues[0]?.height }}
      >
        {headerValues.map((header, index) => {
          let content = undefined

          if (typeof header.name === 'string') {
            content = header?.name?.toString() || ''
            if (header.name && header.name.toString().substring(0, 1) === '.') {
              content = <Text textId={header.name} />
            }
          }

          if (typeof header.name === 'object' && header.name.icon) {
            content = header.name.icon

            if (header.name.text && header.name.text.substring(0, 1) === '.') {
              content = (
                <div className="flex gap-1">
                  {header.name.icon} <Text textId={header.name.text} />
                </div>
              )
            }
          }

          return (
            <div key={index} className={style.headerElement} style={header.style}>
              {content}
              {header.isSortable && (
                <div className={style.arrowContainer}>
                  <SortingArrows
                    onClick={(sortType) => this.handleSort(headerKeys[index], sortType)}
                    resetSort={
                      useSingleColumnSort
                        ? !sortItems.find((item) => headerKeys[index] === item.name)
                        : false
                    }
                  />
                  {sortItems &&
                    sortItems.length > 1 &&
                    sortItems.findIndex((item) => item.name === headerKeys[index]) >= 0 && (
                      <span>
                        {sortItems.findIndex((item) => item.name === headerKeys[index]) + 1}
                      </span>
                    )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  renderBody() {
    const { headers: Headers, fullHeight, hasCheckbox, onRowClick, selectedRow } = this.props
    const headers = Object.values(Headers)

    const uniqueIDs = Object.keys(this.state.values)
    const colTemplate = []

    for (let i = 0; i < headers.length; i++) {
      let width = 'minmax(100px, 1fr)'
      if (headers?.[i]?.width && headers?.[i]?.width !== '') {
        width = `minmax(${headers[i].width}, 1fr)`
      }
      if (headers?.[i]?.maxWidth && !!headers?.[i]?.maxWidth) {
        width = headers[i].maxWidth
      }
      colTemplate.push(width)
    }

    if (!this.state.bodyRefSet) {
      this.setState({ bodyRefSet: true })
    }

    return (
      <SpaceWrapper disabled={!fullHeight}>
        <div
          ref={(body) => (this._body = body)}
          className={cx(style.body, {
            [style.fullHeight]: fullHeight,
            [style.noRowClick]: !onRowClick,
            [style.hideOnHoverEffect]: !hasCheckbox && !onRowClick,
          })}
        >
          {Object.values(this.state.values).map((row, key) => {
            return (
              <div
                className={cx(style.row, {
                  [style.selectedRow]: selectedRow && selectedRow === row.id,
                })}
                key={key}
                id={`tableId-${uniqueIDs[key]}`}
                ref={(row) => (this[`_row_${uniqueIDs[key]}`] = row)}
                onMouseEnter={() => {
                  if (this[`_checkbox_${uniqueIDs[key]}`]) {
                    this[`_checkbox_${uniqueIDs[key]}`].classList.add(style.hover)
                  }
                }}
                onMouseLeave={() => {
                  if (this[`_checkbox_${uniqueIDs[key]}`]) {
                    this[`_checkbox_${uniqueIDs[key]}`].classList.remove(style.hover)
                  }
                }}
                onClick={() => onRowClick && onRowClick(row)}
              >
                {this.props.rightClickOptions && (
                  <RightClickMenu
                    elementQuery={`#tableId-${uniqueIDs[key]}`}
                    options={this.props.rightClickOptions}
                    clickedRow={row}
                  />
                )}
                <div
                  className={style.rowContent}
                  style={{ gridTemplateColumns: colTemplate.join(' ') }}
                >
                  {this.renderCells(row, uniqueIDs[key])}
                </div>
              </div>
            )
          })}
        </div>
      </SpaceWrapper>
    )
  }

  renderCells(row, uniqueId) {
    let keys = this.getObjectKeys(row)
    // Render single cell for "No search result" if id is -1
    if (row._id === -1) {
      return <div className={style.cell}>{this.renderCell(keys[0], row.name, null, row)}</div>
    } else {
      return this.getObjectValues(row).map((col, index) => {
        return (
          <div className={style.cell} key={index}>
            {this.renderCell(keys[index], col, uniqueId, row)}
          </div>
        )
      })
    }
  }

  renderCell(key, value, uniqueId, row = null) {
    const { headers, nonEditable } = this.props
    const item = headers[key]
    const {
      style: cellStyle,
      type: cellType,
      valueType: cellValueType,
      min,
      display: displayFunc,
      render,
    } = item

    if (render) return render(value, item, row)

    if (Utilities.isSet(displayFunc)) {
      value = displayFunc(value, row)
    }

    switch (cellType) {
      case 'input':
        return (
          <InputCell
            initialValue={value}
            type={cellValueType}
            min={min}
            onChange={(value) => {
              this.handleChange(uniqueId, key, value)
            }}
          />
        )
      case 'select':
        return (
          <SelectCell
            options={this.props.headers[key].options}
            initialId={value}
            onChange={(id) => {
              this.handleChange(uniqueId, key, id)
            }}
          />
        )
      case 'checkbox':
        return <CheckboxCell checked={value.checked} onChange={value.onChange} />
      case 'button':
        return (
          <Link to={value[2] ? '#' : value[1]} style={value[2] ? {} : cellStyle}>
            {value[0]}
          </Link>
        )
      case 'time':
        return (
          <TimeCell
            initialTime={value}
            onChange={(minutes) => {
              this.handleChange(uniqueId, key, minutes)
            }}
          />
        )
      case 'order':
        return (
          <OrderCell
            initialValue={value}
            nonEditable={nonEditable}
            style={cellStyle}
            onChange={(value) => {
              this.handleChange(uniqueId, key, value)
            }}
          />
        )
      case 'status':
        return <StatusCell status={value} style={cellStyle} />
      case 'paidStatus':
        return <PaidStatusCell status={value} style={cellStyle} />
      case 'paymentDue':
        return <PaymentDueCell status={value} style={cellStyle} />
      case 'lockState':
        return <LockStateCell lockState={value} />
      case 'membershipList':
        return <MembershipCell membershipList={value} style={cellStyle} />
      case 'partnershipList':
        return <PartnershipCell partnershipList={value} style={cellStyle} />
      case 'golfCartList':
        return <GolfCartCell courses={value} style={cellStyle} />
      case 'golfClubList':
        return <GolfClubCell clubs={value} style={cellStyle} />
      case 'rentalList':
        return <RentalCell courses={value} style={cellStyle} />
      case 'color':
        return <ColorCell color={value} style={cellStyle} />
      case 'toggle':
        return <ToggleCell value={value} cellStyle={cellStyle} />
      case 'doubleRow':
        return (
          <div className={style.doubleCell}>
            {value.map((item, i) => this.renderDoubleCell(item, i, cellStyle))}
          </div>
        )
      default:
        return <MainCell value={value} cellStyle={cellStyle} />
    }
  }

  renderDoubleCell(value, index, cellStyle) {
    if (value && value.toString().substring(0, 1) === '.') {
      return <Text key={index} textId={value} style={cellStyle} useTitle={true} />
    } else {
      return (
        <div key={index} style={cellStyle} title={value}>
          {value ? value : '-'}
        </div>
      )
    }
  }

  renderFooter() {
    if (!this.props.hasFooter) return null
    return (
      <div className={style.footer}>
        <div className={style.footerElement}>
          <Text textId="totalRows" append={Object.values(this.state.values).length} />
        </div>
        <div className={style.footerElement}>
          <Text textId="selectedRows" append={this.state.selectedRows.length} />
        </div>
      </div>
    )
  }

  render() {
    const { nonEditable, fullHeight, hideScrollbar } = this.props
    let containerClassName = [style.container]

    if (nonEditable) containerClassName.push(style.nonEditable)
    if (fullHeight) containerClassName.push(style.fullHeight)

    return (
      <div className={containerClassName.join(' ')} onWheel={this.handleWheelScroll}>
        {this.renderCheckboxes()}
        <SpaceWrapper disabled={!fullHeight} style={{ overflow: 'auto' }}>
          <div className={style.table} ref={(table) => (this._table = table)}>
            {this.renderHeader()}
            {this.renderBody()}
          </div>
        </SpaceWrapper>
        {this.state.showVerticalScrollbar && (
          <Scrollbar
            onScroll={(x, y) => this.scrollToVertically(y)}
            ref={(scroll) => (this._verticalScroll = scroll)}
            className={style.verticalScrollbar}
          />
        )}
        {this.renderFooter()}
        {this.state.showHorizontalScrollbar && !hideScrollbar && (
          <Scrollbar
            onScroll={(x) => this.scrollToHorizontally(x)}
            ref={(scroll) => (this._horizontalScroll = scroll)}
            className={style.horizontalScrollbar}
            horizontal
          />
        )}
      </div>
    )
  }
}

Table.propTypes = {
  /**
   * Object containing headers for the table in the following format
   *
   * ```
   * {
   *   headerName: {
   *      name: required,
   *      type: required,
   *      valueType: required,
   *      width: optional,
   *      options: optional,
   *      min: optional
   *    },
   *    ...
   * }
   * ```
   *
   * @param {String} headerName arbitrary name for the header
   *
   * @param {String} name name of the header. If the name starts with a dot (.)
   * it will be used as a *textId* in a **Text** component
   *
   * @param {String} type type of the cell used to render this value<br />
   * &nbsp;&nbsp; • **label:** non editable text field<br />
   * &nbsp;&nbsp; • **select:** dropdown list of selectable options<br />
   * &nbsp;&nbsp; • **input:** editable input<br />
   * &nbsp;&nbsp; • **time:** editable time<br />
   * &nbsp;&nbsp; • **order:** editable order<br />
   *
   * @param {String} valueType type of the value<br />
   * &nbsp;&nbsp; • **string:** text<br />
   * &nbsp;&nbsp; • **number:** number<br />
   * &nbsp;&nbsp; • **time:** time in minutes<br />
   * &nbsp;&nbsp; • **day:** day as a numerical value<br />
   *
   * @param {String} width minimum width for the cell
   *
   * @param {Array} options array of options for **select** cell type in the format
   * `[{ name: String, id: Number }, ...]` <br />
   * &nbsp;&nbsp;&nbsp;&nbsp;If the name starts with a dot (.) it will be used as a *textId* in a
   * **Text** component
   *
   * @param {Number} min minimum value for **input** cell type with **number** value type
   */
  headers: PropTypes.object.isRequired,
  /**
   * Array containing the values for each row of the table in the following format
   *
   * ```
   * [
   *   {
   *     _id: 156325
   *     headerName1: value1,
   *     headerName2: value2,
   *     ...
   *   },
   *   ...
   * ]
   * ```
   *
   * Any value starting by an underscore (_) will be ignored by the **Table**, it serves only as a
   * reference when retrieving data from the **Table**<br />
   *
   * The header names must match the names given in the headers
   */
  values: PropTypes.array.isRequired,
  /**
   * Determines if the **Table** should fill available height
   */
  fullHeight: PropTypes.bool,
  /**
   * Determines if the headers should be in bold
   */
  boldHeaders: PropTypes.bool,
  /**
   * Determines if the **Table** should show horizontal scrollbar or not
   */
  hideScrollbar: PropTypes.bool,
  /**
   * Determines if Arrow icons should be shown in header
   */
  hideArrows: PropTypes.bool,
  /**
   * Determines if the **Table** should have a column with checkboxes used to select rows
   */
  hasCheckbox: PropTypes.bool,
  /**
   * Determines if the **Table** should have a footer with information about the rows
   */
  hasFooter: PropTypes.bool,
  /**
   * Determines if the **Table** should have hidden headers
   */
  hideHeader: PropTypes.bool,
  /**
   * Determines the default value to sort by, needs to match the header name
   */
  defaultSort: PropTypes.string,
  /**
   * Sets the initial sort order ('asc' or 'desc') for the column defined in defaultSort.
   */
  defaultSortType: PropTypes.oneOf(['asc', 'desc']),
  /**
   * Determines if the **Table** should be readonly
   */
  nonEditable: PropTypes.bool,
  /**
   * Array of filter functions to apply to the **Table** data
   */
  filters: PropTypes.array,
  /**
   * Array of sort items to sort table
   */
  sortItems: PropTypes.array,
  /**
   * Function to run when selecting or deselecting rows
   *
   * @param {Array} rows selected rows
   */
  onSelect: PropTypes.func,
  /**
   * Function to run when clicking a row
   *
   * @param {Array} rows selected rows
   */
  deselectAll: PropTypes.bool,
  onRowClick: PropTypes.func,

  /**
   * Options when right click a row
   *
   */
  rightClickOptions: PropTypes.array,
  /**
   * Function to run when clicking a header
   *
   * @param {Array} header clicked header name
   */
  onHeaderClick: PropTypes.func,
  /**
   * Values can contain functions (see getValuesWithUniqueID)
   */
  allowFunctionValues: PropTypes.bool,

  useSingleColumnSort: PropTypes.bool,

  /**
   * The row Id which is selected
   */
  selectedRow: PropTypes.string,
}
