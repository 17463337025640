import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

/**
 * This component creates animated bars used to show a loading process
 */
export default class PulseLoader extends Component {
  render() {
    const {
      className,
      contentClassName,
      loaderClassName,
      dotClassName,
      color,
      showIf,
      fillHeight,
      dotStyles,
    } = this.props
    let _style = {}
    let _dotStyle = {}
    if (!showIf) {
      _style.visibility = 'hidden'
    }
    if (fillHeight) {
      _style.height = '100%'
    }

    if (color) _dotStyle.backgroundColor = color

    if (dotStyles) {
      _dotStyle = {
        ..._dotStyle,
        ...dotStyles,
      }
    }

    return (
      <div className={cx(styles.container, className)} style={_style}>
        <div className={cx(styles.content, contentClassName)}>
          <div className={cx(styles.loader, loaderClassName)}>
            <div className={cx(styles.dot, styles.dot1, dotClassName)} style={_dotStyle}></div>
            <div className={cx(styles.dot, styles.dot2, dotClassName)} style={_dotStyle}></div>
            <div className={cx(styles.dot, styles.dot3, dotClassName)} style={_dotStyle}></div>
          </div>
        </div>
      </div>
    )
  }
}

PulseLoader.propTypes = {
  /**
   * Determines if the **PulseLoader** should be displayed
   */
  showIf: PropTypes.bool,
  /**
   * Determines if the **PulseLoader** container should fill available height
   */
  fillHeight: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  dotClassName: PropTypes.string,
  dotStyles: PropTypes.object,
  size: PropTypes.number,
  color: PropTypes.string,
}

PulseLoader.defaultProps = {
  size: 12,
  color: '#e4e4e4',
  dotStyles: {},
}
