import { BookingPayment } from '@sweetspot/shared/types'

export const getPaymentReferences = (payments: BookingPayment[]) => {
  const truePayments = payments?.filter((payment) => payment.psp_reference)

  if (truePayments?.length === 0) return ''

  if (truePayments?.length === 1) return `${truePayments[0].psp_reference}`

  return truePayments?.map((payment) => payment?.psp_reference).join(', ')
}
