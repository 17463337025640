import { useEffect } from 'react'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { useOptionsForSelects } from '../../context'
import { useTranslation } from 'react-i18next'
import style from '../../style.module.scss'

const PeriodAndSpaceSelect = ({ period, week, setValue, space, selectedCourse }) => {
  const { weekPeriodOptions, spacesOptions } = useOptionsForSelects(period)
  const { t } = useTranslation()

  useEffect(() => {
    if (period.period_price_status) {
      if (week === -1) {
        setValue('week', 1)
      }

      if (spacesOptions.length > 0 && (space === -1 || !space)) {
        setValue('space', spacesOptions[0].id)
      }
    }
  }, [period, week, spacesOptions, setValue, space])

  return (
    <>
      <div className={style.periodSelect}>
        <span className={style.optionsText}>{t('editPeriodsNew.period')}</span>
        <div className={`${style.select} ${style.periodSelect}`}>
          <DropdownSelect
            values={weekPeriodOptions}
            selectedId={week}
            initialId={week}
            disabled={week === -1 || weekPeriodOptions.length === 1}
            styleDisabled={week === -1}
            noStrikethroughWhenDisabled={true}
            onSelect={(value) => {
              setValue('week', value)
              setValue('hasUpdated', true)
            }}
          />
        </div>
      </div>
      {selectedCourse.type !== 'course' && (
        <div className={style.mainContainer}>
          <span className={style.optionsText}>{t('editPeriodsNew.space')}</span>
          <div className={`${style.select} ${style.spaceSelect}`}>
            <DropdownSelect
              values={spacesOptions}
              initialId={space}
              selectedId={space}
              onSelect={(value) => {
                setValue('space', value)
                setValue('hasUpdated', true)
              }}
              styleDisabled={week === -1}
              disabled={space === -1}
              noStrikethroughWhenDisabled={true}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default PeriodAndSpaceSelect
