import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons'

// @ts-expect-error InputSelectDateSimple is not in typescript
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

interface RefreshButtonProps {
  isLoading: boolean
  handleRefresh: () => void
}

const RefreshButton = ({ isLoading, handleRefresh }: RefreshButtonProps) => {
  return (
    <div className="flex">
      {isLoading && (
        <PulseLoader showIf className="min-w-12" contentClassName="!px-3" dotClassName="w-2 h-2" />
      )}
      <FontAwesomeIcon
        icon={faRefresh}
        size="1x"
        className="flex cursor-pointer items-center justify-center px-3"
        onClick={handleRefresh}
      />
    </div>
  )
}

export default RefreshButton
