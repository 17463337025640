import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import produce from 'immer'

import ButtonAndDropdownSelectWrapper from '@sweetspot/sweetspot-js/common/components/ButtonAndDropdownSelectWrapper'
import SlotsUnit from './components/SlotsUnit'

import { BOOKED_SLOTS_UNITS } from '@sweetspot/sweetspot-js/features/reservationPolicies/constants/checkers'

const BookedSlotsChecker = ({ checker, disabled, onSetChecker, onSaveChecker }) => {
  const { t } = useTranslation()

  const [showSelectUnits, setShowSelectUnits] = useState(false)

  const conf = useMemo(() => {
    return checker?.configuration || []
  }, [checker])

  const unitsConf = useMemo(() => {
    const units = conf
      .map((x) => x?.range)
      .map((key) => BOOKED_SLOTS_UNITS.find((x) => x.value === key))
      .sort((a, b) => a.id - b.id)

    if (units?.length > 0) return units
    return false
  }, [conf])

  const updateunitsConf = (newValues) => {
    const updatedChecker = produce(checker, (draft) => {
      if (unitsConf) {
        unitsConf.forEach((unit) => {
          if (!newValues.find((x) => x?.value === unit?.value)) {
            draft.configuration = draft.configuration.filter((x) => x?.range !== unit?.value)
          }
        })
      }

      newValues.forEach((unit) => {
        const newUnit = { range: unit.value, value: null }
        if (!checker.configuration.find((x) => x?.range === unit?.value)) {
          draft.configuration.push(newUnit)
        }
      })
    })

    onSetChecker(updatedChecker)
    onSaveChecker(updatedChecker)
    setShowSelectUnits(false)
  }

  const removeUnit = (unit) => {
    const updatedChecker = {
      ...checker,
      configuration: checker?.configuration.filter((x) => x?.range !== unit?.value),
    }

    onSetChecker(updatedChecker)
    onSaveChecker(updatedChecker)
  }

  const addNewSlots = (unit, slots) => {
    const updatedChecker = produce(checker, (draft) => {
      const updatedRule = { range: unit?.value, value: parseInt(slots) }
      draft.configuration = draft.configuration.filter((x) => x?.range !== unit?.value)
      draft.configuration.push(updatedRule)
    })

    onSetChecker(updatedChecker)
  }

  return (
    <div className={cx(styles.container)}>
      {!unitsConf && (
        <p className={cx(styles.noUnitsSelectedText)}>{t('sentences.noUnitSelected')}</p>
      )}

      {unitsConf &&
        unitsConf.map((unit) => (
          <SlotsUnit
            key={unit?.id}
            slotsValue={checker?.configuration.find((x) => x?.range === unit?.value)?.value}
            unit={unit}
            unitsConf={unitsConf}
            addNewSlots={addNewSlots}
            removeUnit={removeUnit}
            onSaveChecker={() => onSaveChecker()}
          />
        ))}

      <ButtonAndDropdownSelectWrapper
        id="select-units-dropdown"
        buttonProps={{
          icon: 'plus',
          text: t('words.unit'),
          onClick: () => setShowSelectUnits(true),
          disabled: disabled,
        }}
        dropdownProps={{
          preselectedOptions: BOOKED_SLOTS_UNITS.filter((unit) =>
            unitsConf ? unitsConf.find((x) => x.value === unit.value) : false
          ),
          translateLabels: true,
          isVisible: showSelectUnits,
          searchEnabled: true,
          onCancel: () => setShowSelectUnits(false),
          onSave: updateunitsConf,
          options: BOOKED_SLOTS_UNITS,
        }}
      />
    </div>
  )
}

BookedSlotsChecker.propTypes = {
  checker: PropTypes.object,
  disabled: PropTypes.bool,
  onSetChecker: PropTypes.func,
  onSaveChecker: PropTypes.func,
}

BookedSlotsChecker.defaultProps = {
  disabled: false,
  onSetChecker: () => {},
  onSaveChecker: () => {},
}

export default BookedSlotsChecker
