import React from 'react'
import PropTypes from 'prop-types'
import { faHandshake } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './style.module.scss'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import Table from '@sweetspot/club-portal-legacy/components/Table'
import headers from './headers.json'

const PartnershipList = (props) => {
  const partnerships = props.partnerships
  if (props.isLoading) return null
  if (partnerships && partnerships.length > 0) {
    return (
      <div className={styles.container}>
        <Table values={partnerships} headers={headers} hideArrows />
      </div>
    )
  } else {
    return (
      <div className={styles.emptyContainer}>
        <FontAwesomeIcon className={styles.logo} icon={faHandshake} />
        <div className={styles.text}>
          <Text textId="partnerAdmin.noPartnershipsAssigned" />
        </div>
      </div>
    )
  }
}

PartnershipList.propTypes = {
  isLoading: PropTypes.bool,
  partnerships: PropTypes.array,
}

export default PartnershipList
