import { Tag, cn } from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { getTagVariant, getTagLabel } from '../../utils'
import { PaymentStateTagProps } from '../types'

export const PaymentStateTag = ({ paymentLink, className }: PaymentStateTagProps) => {
  const { t } = useTranslation()

  const { state } = paymentLink.payment
  const { is_expired, is_active } = paymentLink

  const variant = getTagVariant(state, is_expired, is_active)
  const label = getTagLabel(state, is_expired, is_active, t)

  return (
    <Tag variant={variant} className={cn('text-text-dark px-2 font-semibold ', className)}>
      {label}
    </Tag>
  )
}
