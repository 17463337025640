import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const Skeleton = ({
  duration,
  width,
  height,
  borderRadius,
  className,
  theme,
  borderTopRadius,
  borderBottomRadius,
  borderLeftRadius,
  borderRightRadius,
  style,
}) => {
  let styleObject = {}

  if (width !== null) {
    styleObject.width = width
  }

  if (height !== null) {
    styleObject.height = height
  }

  if (borderRadius !== null) {
    styleObject.borderRadius = borderRadius
  }

  if (borderTopRadius !== null) {
    styleObject.borderTopLeftRadius = borderTopRadius
    styleObject.borderTopRightRadius = borderTopRadius
  }

  if (borderBottomRadius !== null) {
    styleObject.borderBottomLeftRadius = borderBottomRadius
    styleObject.borderBottomRightRadius = borderBottomRadius
  }

  if (borderLeftRadius !== null) {
    styleObject.borderTopLeftRadius = borderLeftRadius
    styleObject.borderBottomLeftRadius = borderLeftRadius
  }
  if (borderRightRadius !== null) {
    styleObject.borderTopRightRadius = borderRightRadius
    styleObject.borderBottomRightRadius = borderRightRadius
  }

  if (duration !== null) {
    styleObject.animationDuration = `${duration}s`
  }

  styleObject = { ...styleObject, ...style }

  return (
    <span
      className={cx(
        styles.defaultStyle,
        {
          [styles.darkTheme]: theme === 'dark',
          [styles.lightTheme]: theme === 'light',
        },
        className
      )}
      style={styleObject}
    >
      &zwnj;
    </span>
  )
}

Skeleton.propTypes = {
  duration: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderTopRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottomRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'dark', 'light']),
  style: PropTypes.object,
}

Skeleton.defaultProps = {
  duration: null,
  width: null,
  height: null,
  borderRadius: '4px',
  borderTopRadius: null,
  borderBottomRadius: null,
  borderLeftRadius: null,
  borderRightRadius: null,
  className: '',
  theme: 'default',
  style: {},
}

export default Skeleton
