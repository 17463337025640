import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip } from 'react-tooltip'

import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

type Props = {
  children: React.ReactNode
  text?: string
  textCopied?: string
  autoHide?: number
}

const ClipboardCopy = ({ children, text = '', textCopied = '', autoHide = 3000 }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <CopyToClipboard data-tooltip-id="clipboard-tooltip" text={text}>
        <div style={{ display: 'inline' }}>{children}</div>
      </CopyToClipboard>

      <Tooltip id="clipboard-tooltip" place="bottom" openOnClick delayHide={autoHide}>
        {t(textCopied)}
      </Tooltip>
    </>
  )
}

export default ClipboardCopy
