import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'

/**
 * This component creates a *div* containing text in the language selected by the user. The text is
 * provided in json files with one language per file
 */
const Text = (props) => {
  const { t } = useTranslation()

  const getText = () => {
    let textId = props.textId

    if (textId?.charAt(0) === '.') {
      textId = textId.substring(1)
    }

    // Retrieve text by ID and language
    let text = t(textId)

    // Extract substring if has specified prop
    if (props.substring && props.substring.length === 2) {
      text = text.substring(props.substring[0], props.substring[1])
    }

    // Add prepend if present
    if (props.prepend !== null && props.prepend !== undefined && props.prepend !== '') {
      text = props.prepend.toString().trim() + ' ' + text
    }

    // Add append if present
    if (props.append !== null && props.append !== undefined && props.append !== '') {
      text = text + ' ' + props.append.toString().trim()
    }

    return text
  }

  return (
    <div
      className={cx(style.text, props.className)}
      style={props.style ? props.style : {}}
      title={props.useTitle ? getText() : null}
    >
      {getText()}
    </div>
  )
}

Text.propTypes = {
  /**
   * Keys for text from json files separated by dots (.)
   */
  textId: PropTypes.string.isRequired,
  /**
   * Determines if the **Text** component should display a substring of the text
   *
   * The prop should have the format `[ start, end ]`
   */
  substring: PropTypes.array,
  /**
   * Text or number to prepend to the text
   */
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Text or number to append to the text
   */
  append: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Additional class name for the component
   */
  className: PropTypes.string,
  /**
   * Style for the component container
   */
  style: PropTypes.object,

  useTitle: PropTypes.bool,
}

export default Text
