import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@sweetspot/scramble-ds'

type DiscardDialogProps = {
  open?: boolean
  onClose: () => void
  onDiscard: () => void
  onSave: () => void
}

const DiscardDialog = ({ open, onClose, onDiscard, onSave }: DiscardDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent variant="default">
        <DialogHeader>
          <DialogTitle>{t('sentences.saveYourChangesTitle')}</DialogTitle>
          <DialogDescription>{t('sentences.saveYourChangesText')}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost-dark" type="button" size="large" onClick={onDiscard}>
              {t('words.discard')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="primary" size="large" onClick={onSave}>
              {t('words.save')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DiscardDialog
