import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import ConfirmWindow from '@sweetspot/club-portal-legacy/Partials/Dialogs/ConfirmWindow'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import SearchInput from '@sweetspot/club-portal-legacy/components/SearchInput'

import BookingEmailForm from '@sweetspot/club-portal-legacy/Partials/Forms/BookingEmail'
import EmailList from './EmailList'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import { queryBookingEmails } from '@sweetspot/sweetspot-js/features/communication/services/api-platform'

import {
  createBookingEmail,
  updateBookingEmail,
  removeBookingEmail,
  sendBookingEmail,
  getRecipients,
} from '@sweetspot/club-portal-legacy/store/actions'

import style from './style.module.scss'
import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'

export class BookingEmail extends Component {
  _debounce = null

  constructor(props) {
    super(props)

    this.emailFilterTypes = [
      { id: 1, name: getLang(props.lang)['communication']['all'], value: 'all' },
      { id: 2, name: getLang(props.lang)['communication']['draft'], value: 'draft' },
      { id: 3, name: getLang(props.lang)['communication']['sent'], value: 'sent' },
    ]

    this.state = {
      emails: [],
      recipients: {},
      isLoading: false,
      isCreate: false,
      updateId: 0,
      removeId: 0,
      activeId: 0,
      searchInput: '',
      filter: 1,
    }

    this.handleClearRecipients = this.handleClearRecipients.bind(this)
  }

  componentDidMount() {
    this.getBookingEmails()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.golfClubId !== this.props.golfClubId) this.getBookingEmails()
  }

  getBookingEmails() {
    clearTimeout(this._debounce)
    const query = this.getQuery()
    const doRequest = (allPages = true, query) => {
      if (allPages) return getAllPagesRequest(queryBookingEmails, query)
      else return queryBookingEmails({ ...query, limit: 500 })
    }
    const isSearch = !!query?.title

    this.setState({ isLoading: true })
    this._debounce = setTimeout(
      () => {
        doRequest(!isSearch, query)
          .then((emails) => {
            this.setState({
              isLoading: false,
              emails: emails.reverse(),
            })
          })
          .catch(() => {
            this.setState({
              isLoading: false,
              emails: [],
            })
          })
      },
      isSearch ? 500 : 0
    )
  }

  getQuery() {
    const { filter, searchInput } = this.state
    const { golfClubId } = this.props

    let query = {
      golf_club: golfClubId,
      status: undefined,
      title: undefined,
      limit: 100,
      page: 1,
    }
    if (filter !== 1) {
      query.status = this.emailFilterTypes.find((item) => item.id === filter).value.toLowerCase()
      if (searchInput) query.title = searchInput
    } else if (searchInput) {
      query.title = searchInput
    }
    return query
  }

  getRecipients = (query) => {
    const { token, getRecipients } = this.props

    this.setState({ isLoading: true })
    getRecipients(token, query)
      .then((data) => {
        this.setState({
          isLoading: false,
          recipients: data,
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          recipients: [],
        })
      })
  }

  toggleCreate = () => {
    this.setState({ isCreate: !this.state.isCreate }, this.handleClearRecipients)
  }

  toggleUpdate = (updateId = 0) => {
    this.setState({ updateId }, this.handleClearRecipients)
  }

  toggleRemove = (removeId = 0) => {
    this.setState({ removeId })
  }

  handleChange = (value) => {
    this.setState({ searchInput: value }, this.getBookingEmails)
  }

  handleChangeSelect = (id) => {
    this.setState({ filter: id }, this.getBookingEmails)
  }

  handleChangeActive = (id) => {
    this.setState({ activeId: id })
  }

  handleClearRecipients = () => {
    this.setState({ recipients: {} })
  }

  handleCreate = (payload) => {
    const { token, golfClubId, createEmail } = this.props

    this.setState({ isLoading: true })
    createEmail(token, { ...payload, club: `/api/clubs/${golfClubId}` })
      .then((email) => {
        if (payload.status === 'sent') {
          this.handleSend(email.id)
        } else {
          this.setState({ isCreate: false }, this.getBookingEmails)
          this._createEmail.handleClose()
        }
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleSend = (emailId) => {
    const { token, sendEmail } = this.props

    sendEmail(token, emailId)
      .then(() => {
        this.setState({ isCreate: false, updateId: 0 }, this.getBookingEmails)
        this._createEmail.handleClose()
        this._updateEmail.handleClose()
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleUpdate = (payload) => {
    const { token, golfClubId, updateEmail } = this.props

    this.setState({ isLoading: true })
    updateEmail(token, { ...payload, club: `/api/clubs/${golfClubId}` })
      .then(() => {
        if (payload.status === 'sent') {
          this.handleSend(payload.id)
        } else {
          this.setState({ updateId: 0 }, this.getBookingEmails)
          this._updateEmail.handleClose()
        }
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  handleRemove = () => {
    let { removeId } = this.state
    const { token, removeEmail } = this.props

    this.setState({ isLoading: true })
    removeEmail(token, removeId)
      .then(() => {
        this.setState({ removeId: 0 }, this.getBookingEmails)
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  renderHeader() {
    const { isLoading } = this.state

    return (
      <div className={style.header}>
        <button
          className="system-button info-outline md-32"
          disabled={isLoading}
          onClick={this.toggleCreate}
        >
          {t('communication.createNew')}
        </button>

        <DropdownSelect
          className={style.filter}
          initialId={1}
          showBorder
          values={this.emailFilterTypes}
          onSelect={this.handleChangeSelect}
        />

        <div className={style.searchInput}>
          <SearchInput isBordered name="search" onChange={(value) => this.handleChange(value)} />
        </div>
      </div>
    )
  }

  renderCreateForm() {
    const { isLoading, isCreate, recipients } = this.state
    const {
      golfCourse: { list: courseList },
    } = this.props
    if (!isCreate) return

    return (
      <OverlayContainer>
        <div className={style.overlay}>
          <BookingEmailForm
            ref={(comp) => (this._createEmail = comp)}
            isLoading={isLoading}
            courseList={courseList}
            recipients={recipients}
            onClose={this.toggleCreate}
            onSave={this.handleCreate}
            getRecipients={this.getRecipients}
            onClearRecipients={this.handleClearRecipients}
          />
        </div>
      </OverlayContainer>
    )
  }

  renderUpdateForm() {
    const { isLoading, updateId, emails, recipients } = this.state
    if (!updateId) return
    const {
      golfCourse: { list: courseList },
    } = this.props
    const email = emails.find((email) => email.id === updateId)

    return (
      <OverlayContainer>
        <div className={style.overlay}>
          <BookingEmailForm
            ref={(comp) => (this._updateEmail = comp)}
            isLoading={isLoading}
            email={email}
            courseList={courseList}
            recipients={recipients}
            onClose={this.toggleUpdate}
            onSave={this.handleUpdate}
            getRecipients={this.getRecipients}
            onClearRecipients={this.handleClearRecipients}
          />
        </div>
      </OverlayContainer>
    )
  }

  renderRemoveForm() {
    const { removeId } = this.state
    if (!removeId) return

    return (
      <ConfirmWindow
        textId="communication.confirmRemoveEmail"
        onConfirm={this.handleRemove}
        onCancel={() => this.toggleRemove()}
      />
    )
  }

  renderBody() {
    const { emails, activeId } = this.state

    return (
      <EmailList
        emails={emails}
        activeId={activeId}
        onChangeActive={this.handleChangeActive}
        toggleUpdate={this.toggleUpdate}
        toggleRemove={this.toggleRemove}
      />
    )
  }

  renderOverlay() {
    const { activeId, emails } = this.state

    const email = emails.find((email) => email.id === activeId)
    return <div className={style.bottom}>{email ? email.body : ''}</div>
  }

  render() {
    return (
      <div className={style.container}>
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderOverlay()}

        {this.renderCreateForm()}
        {this.renderUpdateForm()}
        {this.renderRemoveForm()}
      </div>
    )
  }
}

BookingEmail.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubId: PropTypes.number.isRequired,
  golfCourse: PropTypes.object.isRequired,
  createEmail: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  removeEmail: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  getRecipients: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubId: state.golfClub.selectedId,
    golfCourse: state.golfCourse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createEmail: (token, payload) => dispatch(createBookingEmail(token, payload)),
    updateEmail: (token, payload) => dispatch(updateBookingEmail(token, payload)),
    removeEmail: (token, emailId) => dispatch(removeBookingEmail(token, emailId)),
    sendEmail: (token, emailId) => dispatch(sendBookingEmail(token, emailId)),
    getRecipients: (token, query) => dispatch(getRecipients(token, query)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingEmail)
