import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

export const migrateAutorenewalSettings = (id: number): Promise<unknown> => {
  const URL = `${getApiPlatformBaseUrl()}/memberships/${id}/autorenewal/migrate`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
