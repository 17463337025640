import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import XPanel from '@sweetspot/club-portal-legacy/components/XPanel'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { faCheckCircle, faCircleMinus, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons'
import { importMembershipInvites } from '@sweetspot/shared/data-access/api-platform'
import { Instructions } from './Instructions'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { FileSelector } from './FileSelector'

export const MembershipImport = () => {
  const { t } = useTranslation()

  const [importFile, setImportFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const maxFileSize = 10485760 // 10 MB
  const [isUploaded, setIsUploaded] = useState(false)

  const addFile = (file) => {
    if (file.size > maxFileSize) {
      return
    }
    if (file.type === 'text/csv') {
      setImportFile(file)
    } else {
      return
    }
  }

  const removeFile = () => {
    setImportFile(null)
    setIsUploaded(false)
    setError(null)
  }

  const handleImport = (file) => {
    const formData = new FormData()
    formData.append('file', file)
    setIsLoading(true)

    importMembershipInvites(formData)
      .then((result) => {
        setIsUploaded(true)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => setIsLoading(false))
  }

  const renderImportFile = () => {
    return (
      <div
        className={
          'flex flex-row justify-between gap-4 rounded-md border border-gray-200 py-2 pl-4 pr-3'
        }
      >
        <div className={'flex min-w-0 flex-row items-center space-x-4'}>
          {!isLoading && (
            <FontAwesomeIcon
              icon={faCircleMinus}
              className="cursor-pointer [&>path]:text-gray-400 [&>path]:hover:text-gray-500"
              onClick={removeFile}
            />
          )}
          <div className={'min-w-0 truncate'} title={importFile.name}>
            {importFile.name}
          </div>
        </div>

        <div className={'flex flex-row items-center space-x-4'}>
          <button
            className="system-button primary h-8"
            onClick={() => handleImport(importFile)}
            disabled={isLoading}
          >
            {isLoading ? (
              <PulseLoader showIf={true} color={'white'} className={'[&>div]:!p-0'} />
            ) : (
              t('words.import')
            )}
          </button>
        </div>
      </div>
    )
  }

  const renderResult = () => {
    return (
      <div
        className={cx(
          '!border-grass-700 flex flex-row justify-between gap-4 rounded-md border py-2 pl-4 pr-3',
          { '!border-sunset-700': error }
        )}
      >
        {error ? (
          <div className={'flex min-w-0 items-center gap-4'}>
            <FontAwesomeIcon icon={faXmarkCircle} className={'[&>path]:text-sunset-700'} />
            <div className={'[&>p]:text-sunset-700'}>
              <p>{t('memberships.membershipImports.instructions.error_general')}</p>
              <p>{error.detail}</p>
            </div>
          </div>
        ) : (
          <div className={'flex min-w-0 items-center gap-4'}>
            <FontAwesomeIcon icon={faCheckCircle} className={'[&>path]:text-grass-700'} />
            <div className={'flex min-w-0 flex-col'}>
              <p className={'truncate'} title={importFile.name}>
                {importFile.name}
              </p>
              <p>{t('memberships.membershipImports.instructions.message_success')}</p>
            </div>
          </div>
        )}

        <div className={'flex flex-row items-center space-x-4'}>
          <FontAwesomeIcon
            icon={faXmark}
            className="w-6 cursor-pointer [&>path]:text-gray-400 [&>path]:hover:text-gray-500"
            onClick={removeFile}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={'h-full max-w-full p-[10px]'}>
      <XPanel fillHeight noPadding>
        <div className={'max-w-[750px] space-y-5 p-4'}>
          <h1 className={'text-2xl'}>{t('memberships.membershipImports.heading_importInvites')}</h1>
          {!importFile && <FileSelector addFile={(file) => addFile(file)} />}
          {importFile && !isUploaded && !error && renderImportFile()}
          {importFile && (isUploaded || error) && renderResult()}

          <Instructions />
        </div>
      </XPanel>
    </div>
  )
}
