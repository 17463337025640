import { ReactNode } from 'react'

export const PaymentLinkDetailsRow = ({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) => (
  <div className="mb-2 flex w-1/2 flex-col">
    <p className="text-content-sm font-bold leading-5">{title}</p>
    {children}
  </div>
)
