import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import { useStyles } from '@sweetspot/sweetspot-js/common/hooks/useStyles'

const FeedbackContainer = ({ error, success, position, anchorPosition, ...props }) => {
  const cx = useStyles(props.styles)
  return (
    <div
      className={cx(
        styles.feedbackContainer,
        {
          [styles.feedbackContainerVisible]: error || success,
        },
        {
          [styles.positionTop]: position === 'top',
          [styles.positionBottom]: position === 'bottom',
        },
        {
          [styles.anchorRight]: anchorPosition === 'right',
        },
        {
          [styles.error]: error,
          [styles.success]: success,
        },
        props.styles
      )}
    >
      {/* Error */}
      <p className={cx(styles.feedbackText, styles.error)}>{error}</p>

      {/* Success */}
      <p className={cx(styles.feedbackText, styles.success)}>{success}</p>
    </div>
  )
}

FeedbackContainer.propTypes = {
  error: PropTypes.string,
  success: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
  anchorPosition: PropTypes.oneOf(['left', 'right']),
  styles: PropTypes.object,
}
FeedbackContainer.defaultProps = {
  error: '',
  success: '',
  position: 'bottom',
  anchorPosition: 'left',
}

export default FeedbackContainer
