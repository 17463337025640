import { EmptyState, EmptyStateDescription, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { PaymentEmptyPageProps } from './types'

export const PaymentEmptyPage = ({ isLoading }: PaymentEmptyPageProps) => {
  const { t } = useTranslation()

  const loadingEmptyState = (
    <EmptyState loading={isLoading} iconName="fa-loader" className="w-full">
      <EmptyStateTitle>{t('paymentLinks.paymentLoading')}</EmptyStateTitle>
    </EmptyState>
  )

  const emptyState = (
    <EmptyState iconName="fa-money-bill-transfer" className="w-full">
      <EmptyStateTitle>{t('paymentLinks.selectPaymentLink')}</EmptyStateTitle>
      <EmptyStateDescription className="mx-6">
        {t('paymentLinks.clickToDisplaySummary')}
      </EmptyStateDescription>
    </EmptyState>
  )

  return (
    <div className="bg-sand-50 flex h-full w-1/2 items-start justify-center pt-[200px] xl:w-1/3">
      {isLoading ? <div>{loadingEmptyState}</div> : emptyState}
    </div>
  )
}
