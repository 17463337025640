import { AuthActionTypes, AuthState } from '@sweetspot/club-portal-legacy/store/types'
import { CONSTANTS } from '../../constants'
import { initialState } from './initialState'

export default function auth(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case CONSTANTS.AUTH.LOGIN:
    case CONSTANTS.AUTH.VALIDATE_TOKEN:
      return Object.assign({}, state, action.payload)
    case CONSTANTS.AUTH.LOGOUT:
      return Object.assign({}, state, initialState)
    case CONSTANTS.AUTH.ME:
    case CONSTANTS.AUTH.FETCH_ME_FROM_STORAGE:
      return Object.assign({}, state, {
        me: action.payload,
      })
    case CONSTANTS.AUTH.MY_ROLES:
    case CONSTANTS.AUTH.FETCH_MY_ROLES_FROM_STORAGE:
      return Object.assign({}, state, {
        roles: action.payload,
      })
    case CONSTANTS.AUTH.SET_LANGUAGE:
      return Object.assign({}, state, {
        me: Object.assign({}, state.me, { lang: action.payload }),
      })
    default:
      return state
  }
}
