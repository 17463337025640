import { forwardRef, HTMLAttributes } from 'react'
import { cn } from '@sweetspot/scramble-ds'

const Dock = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { isShown?: boolean }>(
  ({ className, isShown, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'bg-base-white absolute inset-x-0 bottom-0 z-50 origin-bottom scale-y-0 shadow-2xl transition-transform	',
        className,
        {
          'scale-y-100': isShown,
        }
      )}
      {...props}
    />
  )
)

export default Dock
