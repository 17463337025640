import { Tooltip } from 'react-tooltip'
import MainCell from '../MainCell'

import styles from './style.module.scss'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clubs?: any[]
  style?: object
}

const GolfClubCell = ({ clubs, style }: Props) => {
  if (!clubs || clubs.length === 0) return '-'

  return (
    <div className={styles.container} style={style}>
      {clubs && (
        <div className={styles.inline}>
          <MainCell value={clubs[0].name} cellStyle={{ maxWidth: '200px', width: '100%' }} />

          {clubs && clubs.length > 1 && (
            <>
              <span data-tooltip-id="grid-tooltip" className={styles.courseLabel}>
                +{clubs.length - 1}
              </span>

              <Tooltip id="grid-tooltip" place="bottom" delayShow={150}>
                <div className="courseList">
                  {clubs.map((club) => (
                    <div key={club.id}>
                      <span>{club.name}</span>
                    </div>
                  ))}
                </div>
              </Tooltip>
            </>
          )}
        </div>
      )}
      {!clubs && <div>{'-'}</div>}
    </div>
  )
}

export default GolfClubCell
