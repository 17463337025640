import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

const sortMemberships = (firstMembership, membershipList) => {
  let sortedMemberships = []
  if (!firstMembership) return null
  if (membershipList.length === 1) {
    sortedMemberships.push(firstMembership)
    return sortedMemberships
  }

  membershipList = membershipList.filter((x) => x.id !== firstMembership.id)

  sortedMemberships = membershipList.sort((a, b) => {
    let valueA = a.status
    let valueB = b.status

    if (valueA === 'active' && valueB !== 'active') {
      return -1
    }
    if (valueA !== 'active' && valueB === 'active') {
      return 1
    }

    return 0
  })

  sortedMemberships.sort((a, b) => {
    let valueA = a.status
    let valueB = b.status

    if (valueA === 'active') return 0

    if (valueA === 'upcoming' && valueB !== 'upcoming') {
      return -1
    }
    if (valueA !== 'upcoming' && valueB === 'upcoming') {
      return 1
    }

    return 0
  })

  return [firstMembership, ...sortedMemberships]
}

const findPlayersFacts = (facts, clubId) => {
  if (!facts) return ''

  let factsFromClub = facts.find((x) => {
    return x.club.id === clubId
  })

  if (!factsFromClub) return null
  return factsFromClub
}

export default class PlayersHelpers {
  static formatPlayers(players, clubId) {
    let playerList = []

    players.forEach((player) => {
      const name = `${player?.first_name} ${player?.last_name}`
      const membership = sortMemberships(
        player.first_usable_membership_by_priority,
        player.shown_memberships
      )
      const gitMembership = player.show_player_membership_to_assign
        ? player.show_player_membership_to_assign.membership_name
        : ''
      const homeClub = player.home_club ? player.home_club.name : null
      const facts = findPlayersFacts(player.facts, clubId)
      const lastPlayed = facts ? DateHelpers.onlyDateString(new Date(facts.last_played)) : ''

      playerList.push({
        ...player,
        _id: player.id,
        name,
        golfId: player.golf_id,
        hcp: Utilities.parseHCP(player.hcp),
        membership,
        gitMembership,
        playRight: player.has_play_right ? '.yes' : '.no',
        homeClub,
        SSUser: player.is_registered ? '.yes' : '.no',
        playValue: facts ? facts.play_value / 100 : '',
        numberOfRounds: facts ? facts.number_of_rounds : '',
        paidValue: facts ? facts.paid_value / 100 : '',
        lastPlayed,
        activeMembership: membership && membership.length ? '.yes' : '.no',
        canBook: player.is_can_book ? '.yes' : '.no',
        rounds: player.fact ? player.fact.number_of_rounds : '-',
        fireBaseAuth: player.is_firebase_authorized ? '.yes' : '.no',
        gitLogged: player.is_git_logged ? '.yes' : '.no',
        gitApiCreated: player.is_created_by_search_through_git_api ? '.yes' : '.no',
      })
    })
    return playerList
  }
}
