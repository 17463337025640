import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import TimePeriodsContext from '../context'
import getWeekPeriodOptions from './getWeekPeriodOptions'

const useOptionsForSelects = (timePeriod) => {
  const { t } = useTranslation()
  const [globalState] = useContext(TimePeriodsContext)
  const { categories, spaces, selectedCourse } = globalState

  const spacesOptions = timePeriod?.period_price_status ? spaces : [{ id: -1, name: 'All' }]
  const weekPeriodOptions = getWeekPeriodOptions(timePeriod, t, selectedCourse.timezone)

  return {
    categories,
    spacesOptions,
    weekPeriodOptions,
  }
}

export default useOptionsForSelects
