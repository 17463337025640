import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import style from './style.module.scss'
import _ from 'lodash'

import {
  getTeeTimesForDayFromGolfCourse,
  getPartnerTypes,
} from '@sweetspot/club-portal-legacy/store/actions'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import XPanel from '@sweetspot/club-portal-legacy/components/XPanel'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import EditTeeTime from '@sweetspot/club-portal-legacy/components/EditTeeTime'
import BookingModal from '@sweetspot/club-portal-legacy/modals/BookingModal'
import BookingsTable from './components/BookingsTable'

class Bookings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      date: new Date(),
      showPopUp: false,
      partnerTypes: [],
      bookingStatus: null,
    }

    this.showPopup = this.showPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.renderBookingPopUp = this.renderBookingPopUp.bind(this)
    this.getPartnerTypes = this.getPartnerTypes.bind(this)
  }

  componentDidMount() {
    this.getPartnerTypes()
  }

  showPopup(teeTime, status) {
    this.setState({ showPopup: true, teeTime, bookingStatus: status })
  }

  closePopup() {
    this.setState({
      showPopup: false,
    })
  }

  getPartnerTypes() {
    const { token, golfClubs, getPartnerTypes } = this.props
    getPartnerTypes(token, golfClubs.selectedId)
      .then((partnerTypes) => {
        this.setState({ partnerTypes: _.orderBy(partnerTypes, ['name'], ['asc']) })
      })
      .catch(() => {
        this.setState({ partnerTypes: [] })
      })
  }

  renderBookingPopUp() {
    const { showPopup, teeTime, bookingStatus } = this.state
    if (showPopup) {
      return (
        <div className={style.modalWrapper}>
          <BookingModal
            teeTimeUuid={teeTime.uuid}
            onClose={this.closePopup}
            reserve={false}
            openHistorical={bookingStatus === 'Canceled' ? true : false}
            fromBookingsPage={true}
            EditTeeTimeComponent={EditTeeTime}
          />
        </div>
      )
    }
  }

  renderTable() {
    const {
      token,
      lang,
      golfClub,
      golfCourses,
      isSuperAdmin,
      selectedGolfCourse,
      getTeeTimesForDayFromGolfCourse,
      golfClubList,
    } = this.props

    const currentGolfClub = golfClubList.find((club) => club.id === golfClub)

    const hasCdhNumber = !!currentGolfClub?.cdh_id

    return (
      <XPanel>
        {golfCourses && (
          <BookingsTable
            token={token}
            lang={lang}
            golfClubId={golfClub}
            golfCourses={golfCourses}
            isSuperAdmin={isSuperAdmin}
            selectedGolfCourse={selectedGolfCourse}
            getTeeTimesForDayFromGolfCourse={getTeeTimesForDayFromGolfCourse}
            showPopup={this.showPopup}
            hasCdhNumber={hasCdhNumber}
          />
        )}
      </XPanel>
    )
  }

  render() {
    return (
      <Container>
        <div className={style.content}>
          <div className={style.title}>{<Text textId="bookingsPage.bookings" />}</div>
          {this.renderTable()}
          {this.renderBookingPopUp()}
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lang: state.auth.me.lang,
    golfClubs: state.golfClub,
    golfClub: state.golfClub.selectedId,
    golfClubList: state.golfClub.list,
    golfCourses: state.golfCourse.list,
    selectedGolfCourse: state.golfCourse.selectedId,
    isSuperAdmin: state.auth.isSuperAdmin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTeeTimesForDayFromGolfCourse: (courseUuid, startIso, endIso) =>
      dispatch(getTeeTimesForDayFromGolfCourse(courseUuid, startIso, endIso)),
    getPartnerTypes: (token, golfClubId) => dispatch(getPartnerTypes(token, golfClubId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings)

Bookings.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClub: PropTypes.number.isRequired,
  golfClubs: PropTypes.object.isRequired,
  golfCourses: PropTypes.array.isRequired,
  getTeeTimesForDayFromGolfCourse: PropTypes.func.isRequired,
  getPartnerTypes: PropTypes.func.isRequired,
  selectedGolfCourse: PropTypes.number.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
}
