import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'

import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import style from '../style.module.scss'
import { USER_TYPES } from '../../../constants'

const AddUserType = ({ isParticipant = false, policy, onAddUserType }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const { t } = useTranslation()

  const dropdownRef = useRef(null)

  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false)
  })

  return (
    <div className={style.newContainer}>
      <ButtonBasic
        text={t('teeTimeCategory.userType')}
        icon="plus"
        className={style.addPromotionButton}
        onClick={() => setShowDropdown(true)}
      />
      <DropdownBox
        ref={dropdownRef}
        className={style.dropdown}
        dropdownOpen={showDropdown}
        options={(isParticipant ? USER_TYPES.filter((item) => item.id !== 3) : USER_TYPES).map(
          (type) => {
            if (policy.userTypes.find((x) => x.type === type.value)) {
              return {
                ...type,
                disabled: true,
              }
            }
            return type
          }
        )}
        onOptionClick={({ value }) => {
          onAddUserType(value)
          setShowDropdown(false)
        }}
      />
    </div>
  )
}

AddUserType.propTypes = {
  isParticipant: PropTypes.bool,
  policy: PropTypes.object,
  onAddUserType: PropTypes.func,
}

export default AddUserType
