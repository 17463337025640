import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField';

// Handlers
import DaysAndTimeIncludedRule from '@sweetspot/club-portal-legacy/components/Wizard/rules/DaysAndTimeIncludedRule'
import GolfCartsIncluded from '@sweetspot/club-portal-legacy/components/Wizard/actions/GolfCartsIncluded'
import CoursesIncludedRule from '@sweetspot/club-portal-legacy/components/Wizard/rules/CoursesIncludedRule'
import LimitationRule from '@sweetspot/club-portal-legacy/components/Wizard/rules/LimitationRule'

import styles from './styles.module.scss'

const RulesAndCourses = ({
  currentPartnership,
  values,
  errors,
  onSetRuleValue,
  onClearRule,
  onSetActionValue,
  onClearAction,
  disabled,
  onlyFields,
}) => {
  const getType = () => {
    const { type } = currentPartnership
    if (type === 'prepay') {
      return 'partnershipPrepay'
    }
    if (type === 'postpay') {
      return 'partnershipPostpay'
    }
  }

  const renderFields = () => {
    const promotion = values.promotions[Object.keys(values.promotions)?.[0]]
    if (!promotion) return null

    const promotionId = promotion.id
    return (
      <React.Fragment>
        <LimitationRule
          onSetRuleValue={(ruleType, value) => onSetRuleValue(promotionId, ruleType, value)}
          onSetActionValue={(actionType, value) => onSetActionValue(promotionId, actionType, value)}
          onClearRule={(ruleType) => onClearRule(promotionId, ruleType)}
          onClearAction={(actionType) => onClearAction(promotionId, actionType)}
          promotion={promotion}
          errors={errors}
          disabled={disabled}
          type={getType()}
        />
        <DaysAndTimeIncludedRule
          onChange={(ruleType, value) => onSetRuleValue(promotionId, ruleType, value)}
          promotion={promotion}
          errors={errors}
          disabled={disabled}
        />

        {getType() === 'partnershipPrepay' ? <GolfCartsIncluded /> : null}

        <CoursesIncludedRule
          onChange={(ruleType, value) => onSetRuleValue(promotionId, ruleType, value)}
          promotion={promotion}
          partnerCourses={[]}
          values={values}
          errors={errors}
          disabled={disabled}
        />
      </React.Fragment>
    )
  }

  if (onlyFields === true) {
    return renderFields()
  }

  return <div className={cx(styles.container)}>{renderFields()}</div>
}

RulesAndCourses.propTypes = {
  currentPartnership: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSetPromotionValue: PropTypes.func,
  onSetRuleValue: PropTypes.func,
  onClearRule: PropTypes.func,
  onSetActionValue: PropTypes.func,
  onClearAction: PropTypes.func,
  disabled: PropTypes.bool,
  lang: PropTypes.string,
  onlyFields: PropTypes.bool,
}
RulesAndCourses.defaultProps = {
  currentPartnership: {},
  values: {},
  errors: {},
  onSetPromotionValue: () => {},
  onSetRuleValue: () => {},
  onClearRule: () => {},
  onClearAction: () => {},
  onSetActionValue: () => {},
  disabled: false,
  onlyFields: false,
}

export default RulesAndCourses
