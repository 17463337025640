import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

/**
 * Fetches all pages for a given request
 * Service function must support {...rest} parameter for this to work
 * because we need to override the headers for the request
 * Service function must also support page and limit params
 * Passed Query params must also contain page and limit props
 *
 * @param {Function} service
 * @param  {...any} params
 */

export const getAllPagesRequest = async (service, ...params) => {
  // Find the query param (should contain limit and page props)
  const queryParamsIndex = params.findIndex((p) => p?.limit && p?.page)
  const queryParams = params?.[queryParamsIndex]

  if (!queryParams) {
    return service(...params)
  }

  const limit = queryParams.limit

  let results = []
  let totalItems = 0

  // Do first service to find total items
  const [firstRes, firstErr] = await to(service(...params, { accept: 'application/ld+json' }))

  if (firstErr) throw firstErr
  totalItems = firstRes['hydra:totalItems']

  if (totalItems <= 0) {
    return []
  }

  if (totalItems < limit) {
    return firstRes['hydra:member']
  }

  results = [...firstRes['hydra:member']]
  const numberOfPages = Math.ceil(totalItems / limit)

  let promises = []

  // Do async request for each page
  for (let i = 2; i <= numberOfPages; i++) {
    let newParams = [...params]
    newParams[queryParamsIndex] = { ...queryParams, page: i }
    promises.push(
      new Promise((resolve, reject) => {
        service(...newParams, {
          accept: 'application/ld+json',
        })
          .then((res) => {
            resolve(res['hydra:member'])
          })
          .catch((err) => reject(err))
      })
    )
  }

  const [data, err] = await to(Promise.all(promises))
  if (err) throw err
  else return [...results, ...data.flat()]
}
