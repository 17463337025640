import { ACTION_TYPES_OBJ, ACTION_TYPES } from '../../constants/actions'

import i18n from 'i18next'
import { numberToLocalFormat } from '@sweetspot/sweetspot-js/common/functions/utils'

const MAX_NUMBER_ROWS = 2

export const validateAction = (action) => {
  let errors = {
    type: '',
    limitation: '',
    rows: [],
  }
  let isValid = true

  if (!action?.type) {
    errors.type = i18n.t('sentences.missingActionType')
    isValid = false
  }

  if (!isValid) {
    return [isValid, errors]
  }

  for (let index = 0; index < MAX_NUMBER_ROWS; index++) {
    errors.rows.push({})
  }

  const currentActionType = ACTION_TYPES.find((x) => x.value === action.type)

  const percentageIsValid = (value) =>
    typeof value === 'number' && value <= 1 && value >= 0.01 ? true : false

  const minFeeIsValid = (value) =>
    value === null || (typeof value === 'number' && value <= 1000001 && value >= 0) ? true : false

  const discountAmountIsValid = (value) =>
    typeof value === 'number' && value <= 1000001 && value >= 0 ? true : false

  const discountedToValueIsValid = (value, currentRowIndex, numberOfRows) =>
    (numberOfRows > 1 && currentRowIndex === 1 && value === null) ||
    (typeof value === 'number' && value > 0 && value <= 1000000000)
      ? true
      : false

  const usesToValueIsValid = (value, currentRowIndex, numberOfRows) =>
    (numberOfRows > 1 && currentRowIndex === 1 && value === null) ||
    (typeof value === 'number' && value > 0 && value <= 1000000)
      ? true
      : false

  /** Validate basic percentage discount */
  if (currentActionType.value === ACTION_TYPES_OBJ.PERCENTAGE) {
    const percentage = action?.configuration?.percentage_coefficient
    const minFee = action?.configuration?.minimum_fee_total_amount

    errors.rows[0] = {
      percentage_coefficient: '',
      minimum_fee_total_amount: '',
    }

    if (!percentageIsValid(percentage)) {
      errors.rows[0].percentage_coefficient = i18n.t('sentences.invalidDiscountPercentageValue')
      isValid = false
    }

    if (!minFeeIsValid(minFee)) {
      errors.rows[0].minimum_fee_total_amount = i18n.t('sentences.invalidMinFeeValue')
      isValid = false
    }
  }

  if (currentActionType.value === ACTION_TYPES_OBJ.FIXED_DISCOUNT) {
    const discountAmount = action?.configuration?.discount_amount
    const minFee = action?.configuration?.minimum_fee_total_amount

    errors.rows[0] = {
      discount_amount: '',
      minimum_fee_total_amount: '',
    }

    if (!discountAmountIsValid(discountAmount)) {
      errors.rows[0].discount_amount = i18n.t('sentences.invalidDiscountAmount')
      isValid = false
    }

    if (!minFeeIsValid(minFee)) {
      errors.rows[0].minimum_fee_total_amount = i18n.t('sentences.invalidMinFeeValue')
      isValid = false
    }
  }

  /** Validate Item Percentage Discount With Minimum Fee And Discounted Value Action */
  /** Validate Item Percentage Discount With Minimum Fee And Uses Action */
  if (
    [
      ACTION_TYPES_OBJ.PERCENTAGE_VALUE,
      ACTION_TYPES_OBJ.PERCENTAGE_USES,
      ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES,
      ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE,
    ].includes(currentActionType.value)
  ) {
    const numberOfRows = action?.configuration[currentActionType.configurationKey]?.length

    const isUses = [
      ACTION_TYPES_OBJ.PERCENTAGE_USES,
      ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES,
    ].includes(currentActionType.value)
    const isValue = [
      ACTION_TYPES_OBJ.PERCENTAGE_VALUE,
      ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE,
    ].includes(currentActionType.value)

    action.configuration[currentActionType.configurationKey].forEach((row, index) => {
      const percentage = row?.percentage_coefficient
      const discountAmount = row?.discount_amount
      const discountedValue = row?.discounted_value_to
      const minFee = row?.minimum_fee_total_amount
      const usesTo = row?.uses_to

      errors.rows[index] = {
        discount_amount: '',
        percentage_coefficient: '',
        minimum_fee_total_amount: '',
      }

      if (isValue) {
        errors.rows[index].discounted_value_to = ''
      }
      if (isUses) {
        errors.rows[index].uses_to = ''
      }

      if (percentage && !percentageIsValid(percentage)) {
        errors.rows[index].percentage_coefficient = i18n.t(
          'sentences.invalidDiscountPercentageValue'
        )
        isValid = false
      }

      if (discountAmount && !discountAmountIsValid(discountAmount)) {
        errors.rows[index].discount_amount = i18n.t('sentences.invalidDiscountAmount')
        isValid = false
      }

      if (!minFeeIsValid(minFee)) {
        errors.rows[index].minimum_fee_total_amount = i18n.t('errors.valueMustBeBetweenThese', {
          this: 0,
          that: numberToLocalFormat(10000),
        })
        isValid = false
      }

      if (isValue) {
        if (!discountedToValueIsValid(discountedValue, index, numberOfRows)) {
          errors.rows[index].discounted_value_to = i18n.t('errors.valueMustBeBetweenThese', {
            this: 1,
            that: numberToLocalFormat(10000000),
          })
          isValid = false
        }
      }

      if (isUses) {
        if (!usesToValueIsValid(usesTo, index, numberOfRows)) {
          errors.rows[index].uses_to = i18n.t('errors.valueMustBeBetweenThese', {
            this: 1,
            that: numberToLocalFormat(1000000),
          })
          isValid = false
        }
      }
    })
  }

  return [isValid, errors]
}

export const parseApiErrors = (apiErrors, action) => {
  let errors = {
    type: '',
    limitation: '',
    rows: [],
  }

  for (let index = 0; index < MAX_NUMBER_ROWS; index++) {
    errors.rows.push({})
  }

  const currentActionType = ACTION_TYPES.find((x) => x.value === action.type)

  if (currentActionType.value === ACTION_TYPES_OBJ.PERCENTAGE) {
    if (
      apiErrors?.violations?.find(
        (e) => e?.errorName === 'INVALID_PROMOTION_CONFIGURABLE_ELEMENT_PERCENTAGE_CONFIGURATION'
      )
    ) {
      errors.rows[0].percentage_coefficient = i18n.t('sentences.invalidDiscountPercentageValue')
    }

    if (
      apiErrors?.violations?.find(
        (e) => e?.errorName === 'INVALID_PROMOTION_CONFIGURABLE_ELEMENT_MINIMUM_FEE_CONFIGURATION'
      )
    ) {
      errors.rows[0].minimum_fee_total_amount = i18n.t('sentences.invalidMinFeeValue')
    }
  }

  // if (currentActionType.value === ACTION_TYPES_OBJ.PERCENTAGE_VALUE) {
  //   const numberOfRows = action?.configuration[currentActionType.configurationKey]?.length;
  // }

  return errors
}
