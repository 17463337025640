import {
  MembershipSignupsQuery,
  queryMembershipSignups,
} from '@sweetspot/shared/data-access/api-platform'
import type { QueryObserverOptions } from 'react-query'
import { useQuery } from 'react-query'
import { MEMBERSHIP_QUERY_KEYS } from '../constants'

type Data = Awaited<ReturnType<typeof queryMembershipSignups>>

interface UseMembershipSignupsProps {
  query: MembershipSignupsQuery
  queryOptions?: QueryObserverOptions<Data>
}

export const useMembershipSignups = (props: UseMembershipSignupsProps) => {
  const query = useQuery<Data>({
    queryKey: [MEMBERSHIP_QUERY_KEYS.SIGNUPS, props.query],
    queryFn: () => queryMembershipSignups({ ...props.query }),
    placeholderData: {
      'hydra:member': [],
      'hydra:itemsPerPage': 0,
      'hydra:totalItems': 0,
      'hydra:view': {},
      'hydra:search': {},
    },
    ...props.queryOptions,
  })

  return query
}
