import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import { ReactComponent as UnSortedIcon } from '@sweetspot/club-portal-legacy/resources/images/unsorted-icon.svg'
import { ReactComponent as SortedIcon } from '@sweetspot/club-portal-legacy/resources/images/sorted-icon.svg'
import { ReactComponent as ReverseSortedIcon } from '@sweetspot/club-portal-legacy/resources/images/sorted-reverse-icon.svg'

const UNSORTED = 0
const SORTED = 1
const REVERSE_SORTED = 2

export default class SortingArrows extends Component {
  state = {
    status: UNSORTED,
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.resetSort !== this.props.resetSort) {
      if (this.props.resetSort) this.setState({ status: UNSORTED })
    }
  }

  handleClick = () => {
    const { status } = this.state
    const { onClick } = this.props

    const newStatus = status + 1 > REVERSE_SORTED ? UNSORTED : status + 1
    this.setState({ status: newStatus })
    if (onClick) onClick(newStatus === SORTED ? 'asc' : newStatus === REVERSE_SORTED ? 'desc' : '')
  }

  render() {
    const { status } = this.state

    return (
      <div className={style.container} onClick={this.handleClick}>
        {status === UNSORTED ? (
          <UnSortedIcon />
        ) : status === SORTED ? (
          <SortedIcon />
        ) : (
          <ReverseSortedIcon />
        )}
      </div>
    )
  }
}

SortingArrows.propTypes = {
  onClick: PropTypes.func,
  resetSort: PropTypes.bool,
}
