import React from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'
import InputSelectOptionSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectOptionSimple'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const offsetValues = [
  { label: -20 },
  { label: -15 },
  { label: -10 },
  { label: -5 },
  { label: 0 },
  { label: 5 },
  { label: 10 },
  { label: 15 },
  { label: 20 },
]

const timeSuffix = 'min'

const TimeOffset = ({ onSelect, doorOffsets, lightOffsets }) => {
  const { t } = useTranslation()

  const handleSelect = (category, key, value) => {
    onSelect(category, key, value * 60)
  }

  const getConvertedToMin = (value) => {
    return value / 60
  }

  return (
    <div className={cx(styles.container)}>
      <div>
        <div className={cx(styles.header)}>
          <label>{t('sentences.doorOffsetHeading')}</label>
        </div>
        <div className={cx(styles.selection)}>
          <InputSelectOptionSimple
            className={cx(styles.input)}
            value={`${getConvertedToMin(doorOffsets.start) ?? 0} ${timeSuffix}`}
            placeholder={`0 ${timeSuffix}`}
            label={t('sentences.doorOffsetStart')}
            options={offsetValues}
            onOptionSelect={(value) => handleSelect('door_offset', 'start', value.label)}
          />
          <InputSelectOptionSimple
            className={cx(styles.input)}
            value={`${getConvertedToMin(doorOffsets.end) ?? 0} ${timeSuffix}`}
            label={t('sentences.doorOffsetEnd')}
            placeholder={`0 ${timeSuffix}`}
            options={offsetValues}
            onOptionSelect={(value) => handleSelect('door_offset', 'end', value.label)}
          />
        </div>
        <div className={cx(styles.footer)}>
          {t('sentences.doorOffsetDescription', {
            startTime: getConvertedToMin(doorOffsets.start),
            endTime: getConvertedToMin(doorOffsets.end),
          })}
        </div>
      </div>
      <div>
        <div className={cx(styles.header)}>
          <label>{t('sentences.lightOffsetHeading')}</label>
        </div>
        <div className={cx(styles.selection)}>
          <InputSelectOptionSimple
            className={cx(styles.input)}
            value={`${getConvertedToMin(lightOffsets.start) ?? 0} ${timeSuffix}`}
            label={t('sentences.lightOffsetStart')}
            placeholder={`0 ${timeSuffix}`}
            options={offsetValues}
            onOptionSelect={(value) => handleSelect('light_offset', 'start', value.label)}
          />
          <InputSelectOptionSimple
            className={cx(styles.input)}
            value={`${getConvertedToMin(lightOffsets.end) ?? 0} ${timeSuffix}`}
            label={t('sentences.lightOffsetEnd')}
            placeholder={`0 ${timeSuffix}`}
            options={offsetValues}
            onOptionSelect={(value) => handleSelect('light_offset', 'end', value.label)}
          />
        </div>
        <div className={cx(styles.footer)}>
          {t('sentences.lightOffsetDescription', {
            startTime: getConvertedToMin(lightOffsets.start),
            endTime: getConvertedToMin(lightOffsets.end),
          })}
        </div>
      </div>
    </div>
  )
}

TimeOffset.propTypes = {
  headingText: PropTypes.string,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  footerText: PropTypes.string,
  course: PropTypes.object,
}

export default TimeOffset
