import { _createBookingPeriod } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'
import { formatTime, formatToDatePicker } from '@sweetspot/club-portal-legacy/utils/date'
import { useContext, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import TimePeriodsContext from './context'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions'
import { useDispatch } from 'react-redux'

const useNewPeriod = () => {
  const [globalState] = useContext(TimePeriodsContext)
  const { selectedCourse } = globalState
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const [newPeriod, setNewPeriod] = useState(null)

  const saveNewPeriodMutation = useMutation(
    (payload) => _createBookingPeriod(selectedCourse.id, payload),
    {
      onSuccess: (newPeriod) => {
        const queryKey = ['PERIODS', selectedCourse.id]
        queryClient.invalidateQueries(queryKey)
        ;(function loop() {
          const { isFetching } = queryClient.getQueryState(queryKey)
          if (isFetching) {
            setTimeout(loop, 200)
          } else {
            setNewPeriod(newPeriod)
            dispatch(addToast('createPeriodSuccess', 'success'))
          }
        })()
      },
      onError: (err) => {
        let errMsg = 'createPeriodError'
        if (err?.violations?.length) {
          errMsg = err.violations[0].message
        }
        dispatch(addToast(errMsg, 'error'))
      },
    }
  )

  const saveNewPeriod = async (formState) => {
    const payload = {
      ...formState,
      start_date: formatToDatePicker(formState.start_date),
      end_date: formatToDatePicker(formState.end_date),
      start_time_from: formatTime(formState.start_time_from),
      start_time_to: formatTime(formState.start_time_to),
    }

    saveNewPeriodMutation.mutate(payload)
  }

  return { saveNewPeriod, newPeriod }
}

export default useNewPeriod
