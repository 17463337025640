import React, { useRef, useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import {
  getElementDimensions,
  getElementTopLeft,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

import { ReactComponent as TriangleRight } from '@sweetspot/sweetspot-js/assets/svgs/triangle-right-darkblue.svg'

const HoverDiv = ({
  children,
  className,
  hoverChildren,
  hoverText,
  direction,
  hoverBoxClassName,
}) => {
  const containerRef = useRef()
  const hoverContainerRef = useRef()

  const [infoVisible, setInfoVisible] = useState(false)

  const [position, setPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    const { height: boxHeight } = getElementDimensions(hoverContainerRef.current)
    const { width, height } = getElementDimensions(containerRef.current)
    const { top, left } = getElementTopLeft(containerRef.current)

    const position = {
      top: boxHeight > height ? top - (boxHeight - height) / 2 : top,
      ...(direction === 'right' ? { left: left + width + 15 } : {}),
      ...(direction === 'left' ? { right: window.innerWidth - left + 15 } : {}),
    }

    setPosition(position)
  }, [infoVisible, direction])

  useOnClickOutside(containerRef, () => setInfoVisible(false))

  return (
    <div
      className={cx(styles.container, className)}
      ref={containerRef}
      onMouseEnter={() => setInfoVisible(true)}
      onMouseLeave={() => setInfoVisible(false)}
    >
      {children}
      <Portal id="info-text">
        <div
          ref={hoverContainerRef}
          style={{ position: 'fixed', ...position }}
          className={cx(styles.hoverBox, hoverBoxClassName, infoVisible && styles.visible)}
        >
          <TriangleRight
            className={cx(styles.triangleRight, direction === 'right' && styles.pointLeft)}
          />
          {hoverChildren && hoverChildren}
          {hoverText ? <p className={cx(styles.hoverText)}>{hoverText}</p> : null}
        </div>
      </Portal>
    </div>
  )
}

HoverDiv.propTypes = {
  className: PropTypes.string,
  hoverText: PropTypes.string,
  direction: PropTypes.oneOf(['right', 'left']),
  hoverBoxClassName: PropTypes.string,
  hoverChildren: PropTypes.any,
}

HoverDiv.defaultProps = {
  className: '',
  direction: 'right',
  hoverText: null,
}

export default HoverDiv
