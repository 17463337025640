import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { GolfCourseItem, RootState } from '@sweetspot/club-portal-legacy/store/types'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'

import { useManageRangeContext } from '../ManageRangeProvider'
import RangeTabs from './RangeTabs'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'

const RangeTabsContainer = () => {
  const { t } = useTranslation()
  const { ranges, areRangesLoading, selectedRange, toggleCreateRangeSideBar } =
    useManageRangeContext()
  const { currentClubName, currentClubId } = useSelector((state: RootState) => {
    const currentClub = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )
    return {
      currentClubName: currentClub?.name as string,
      currentClubId: currentClub?.id,
    }
  })

  const { data: courses, refetch } = useQuery(
    [
      CLUB_QUERIES.COURSES,
      { 'club.id': currentClubId, 'type[]': [COURSE_TYPES.DRIVING_RANGE.value] },
    ],
    () =>
      queryCourses({
        'club.id': currentClubId,
        'type[]': [COURSE_TYPES.DRIVING_RANGE.value],
      }),
    {
      enabled: !!currentClubId,
    }
  )

  // refetch the courses when ranges are changed
  useEffect(() => {
    // the corresponding course needs a bit time to be created after the range is created
    setTimeout(() => {
      refetch()
    }, 1000)
  }, [ranges, refetch])

  const currentCourse = useMemo(
    () =>
      (courses as GolfCourseItem[])?.find(
        (course: GolfCourseItem) => course.uuid === selectedRange?.external_reference
      ),
    [selectedRange, courses]
  )

  if (areRangesLoading) {
    return (
      <EmptyState
        key="loading"
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>{t('settings.range.loadingRanges')}</EmptyStateTitle>
        <EmptyStateDescription>{t('settings.bays.hangOnTight')}</EmptyStateDescription>
      </EmptyState>
    )
  }

  if (!ranges?.length) {
    return (
      <EmptyState
        key="empty-range"
        className="max-w-[432px]"
        iconName="fa-kit fa-range-light bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>{t('settings.bays.noRangesCreated')}</EmptyStateTitle>
        <EmptyStateDescription>
          {t('settings.range.createAtLeastOneRange', {
            name: currentClubName,
          })}
        </EmptyStateDescription>
        <EmptyStateButton
          className="text-content-sm font-medium hover:no-underline focus:no-underline"
          onClick={toggleCreateRangeSideBar}
        >
          {t('settings.bays.createFirstRange')}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return (
    <RangeTabs
      currentCourse={currentCourse}
      refetchCourses={() => {
        // the corresponding course needs a bit time to update after the range is updated
        setTimeout(() => {
          refetch()
        }, 1000)
      }}
    />
  )
}

export default RangeTabsContainer
