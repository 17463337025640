import React, { useState } from 'react'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'

import style from './style.module.scss'
import produce from 'immer'
import { useTranslation } from 'react-i18next'

export const BOOKING_WINDOW_OPTIONS = [
  {
    id: 1,
    label: 'days',
    value: 'day',
  },
  {
    id: 2,
    label: 'hours',
    value: 'hour',
  },
]

const BookingWindowMini = ({ configuration, isLoading, header }) => {
  const { t } = useTranslation()

  return (
    <div className={style.miniContainer}>
      <p className={style.miniHeader}>{header}</p>
      <div className={style.miniRow}>
        <p>
          {configuration?.period_duration === 'day'
            ? t('sentences.opensDaysBeforeStartTime', { count: configuration?.number })
            : t('sentences.opensHoursBeforeStartTime', { count: configuration?.number })}
        </p>
      </div>
    </div>
  )
}

const BookingWindow = ({ rule, onSetRule, onSaveRule, disabled, isMini }) => {
  const { t } = useTranslation()
  const [timeValue, setTimeValue] = useState(rule?.configuration?.number ?? 1)

  const [timeValueError, setTimeValueError] = useState(null)

  const isEditable = onSetRule && onSaveRule && !disabled

  if (isMini) {
    return (
      <BookingWindowMini
        configuration={rule.configuration}
        header={t('teeTimeCategory.bookingWindow')}
      />
    )
  }

  const handleChangeTimeUnit = (unit) => {
    if (!isEditable) return

    const updatedRule = produce(rule, (draft) => {
      draft.configuration.period_duration = unit.value

      if (unit.value === 'hour') {
        setTimeValue(1)
      }
    })

    onSetRule?.(updatedRule)
    onSaveRule?.(updatedRule)
  }

  const handleSaveTimeValue = () => {
    if (!isEditable) return

    if (rule?.configuration?.period_duration === 'day' && timeValue < 0) {
      setTimeValueError(`${t('teeTimeCategory.minValue')} "0"`)
    } else if (rule?.configuration?.period_duration === 'hour' && timeValue < 1) {
      setTimeValueError(`${t('teeTimeCategory.minValue')} "1"`)
    } else if (timeValue === null || timeValue === undefined) {
      setTimeValueError(t('teeTimeCategory.requiredValue'))
    } else {
      setTimeValueError(null)

      const updatedRule = produce(rule, (draft) => {
        draft.configuration.number = timeValue
      })

      onSetRule?.(updatedRule)
      onSaveRule?.(updatedRule)
    }
  }

  return (
    <>
      <span className={style.innerTitle}>{t('teeTimeCategory.opensBeforeStartTime')}</span>
      <div className={style.container}>
        <div className={style.unitWrapper}>
          <TextInputField
            type="number"
            value={timeValue}
            error={timeValueError}
            containerWidth="third"
            onChange={(val) => {
              if (!isEditable) return
              setTimeValue(parseInt(val))
            }}
            onInputBlur={() => handleSaveTimeValue()}
            inputProps={{
              min: rule?.configuration?.period_duration === 'hour' ? 1 : 0,
              step: 1,
            }}
            disabled={disabled}
          />
          <TextInputOptionsSelect
            containerClassName={style.timeUnitSelect}
            noMargin
            options={BOOKING_WINDOW_OPTIONS}
            value={t(
              BOOKING_WINDOW_OPTIONS.find((x) => x.value === rule?.configuration?.period_duration)
                ?.label
            )}
            error={timeValueError ? ' ' : null}
            onOptionSelect={(unit) => handleChangeTimeUnit(unit)}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  )
}

export default BookingWindow
