import {
  BallPricePayload,
  BucketConfigurationPayload,
  DemandEntry,
  DemandStatus,
  PriceEntry,
  PriceModelTab,
  RangeSchedulePayload,
  RangeWithConfiguration,
} from '@sweetspot/shared/data-access/api-platform'
import { TrackingTech } from '@sweetspot/shared/types'

import {
  BallPrice,
  BucketSize,
  OpeningHours,
  PriceModelByBalls,
  PriceObject,
  StatusMatrixByTime,
  PriceMatrixByTime,
  StatusObject,
} from '../types'
import {
  BALL_PRICE_DATA,
  BALL_PRICE_TECHNOLOGIES_DEFAULT,
  BUCKET_SIZES_DEFAULT,
} from '../constants'

// Function to find the earliest open hour and the latest close hour
function findTimeRange(openingHours: OpeningHours) {
  let earliestOpen = '23:59'
  let latestClose = '00:00'

  for (const day in openingHours) {
    const open = openingHours[day].open
    const close = openingHours[day].close
    if (open && open < earliestOpen) {
      earliestOpen = open
    }
    if (close && close > latestClose) {
      latestClose = close
    }
  }

  // round latestClose to next hour
  const [latestCloseHour, latestCloseMinute] = latestClose.split(':').map((time) => parseInt(time))
  if (latestCloseMinute > 0) {
    latestClose = formatTime(latestCloseHour + 1, 0)
  }

  return { earliestOpen, latestClose }
}

// Function to format time in HH:MM format
function formatTime(hour: number, minute: number) {
  return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

// Function to generate one-hour sections between the earliest open and latest close hours
export function generateTimeSections(openingHours: OpeningHours) {
  const { earliestOpen, latestClose } = findTimeRange(openingHours)

  const startHour = parseInt(earliestOpen.split(':')[0])
  const endHour = parseInt(latestClose.split(':')[0])

  const timeSections = []

  for (let hour = startHour; hour < endHour; hour++) {
    const start = formatTime(hour, 0)
    const end = formatTime(hour, 59)
    timeSections.push([start, end])
  }

  return timeSections
}

// Function to merge same hour intervals
// example: { '10:00': [], '10:16': [], '10:30': [] } => { '10:00': [] }
export const mergeStatusSameHourIntervals = (
  statusMatrixByTime: StatusMatrixByTime
): StatusMatrixByTime =>
  Object.entries(statusMatrixByTime).reduce<StatusMatrixByTime>((acc, [time, statusArray]) => {
    const hour = `${time.split(':')[0]}:00`
    const existingEntry = acc[hour]
    if (!existingEntry) {
      return {
        ...acc,
        [hour]: statusArray,
      }
    }
    return {
      ...acc,
      [hour]: existingEntry.map((statusObj, index) => ({
        ...statusObj,
        status:
          statusArray[index].status === DemandStatus.Closed
            ? statusObj.status
            : statusArray[index].status,
      })),
    }
  }, {})

export const constructBucketConfigurationData = (
  data: RangeWithConfiguration
): {
  bucketSizes: BucketSize[]
  ballPrice: BallPrice
  priceRounding?: number
  trackingTechnologies: TrackingTech[]
  isExperienceBasedPricing: boolean
} => {
  const { tracking_technologies, range } = data || {}
  const { bucket_configuration, price_model } = range || {}

  const trackingTechnologies =
    tracking_technologies?.map(({ id }) => id)?.sort((a) => (a === TrackingTech.NONE ? -1 : 1)) ||
    BALL_PRICE_TECHNOLOGIES_DEFAULT

  const bucketSizes =
    bucket_configuration?.bucket_sizes?.map((bucketSize) => ({
      nrOfBalls: bucketSize.number_of_balls,
      discount: bucketSize.discount_percentage,
    })) || BUCKET_SIZES_DEFAULT

  let ballPrice: BallPrice = price_model?.ball_prices?.reduce<BallPrice>(
    (acc, ballPrice) => ({
      ...acc,
      [ballPrice.tracking_technology]: {
        ...(acc[ballPrice.tracking_technology] || {}),
        [ballPrice.demand_level]: (ballPrice.price_per_ball / 100).toFixed(2),
      },
    }),
    {}
  )
  // Populate prices with 0 by default
  if (!ballPrice) {
    ballPrice = trackingTechnologies.reduce<BallPrice>(
      (acc, tech) => ({
        ...acc,
        [tech as string]: BALL_PRICE_DATA.demands.reduce(
          (acc, demand) => ({ ...acc, [demand]: '0.00' }),
          {}
        ),
      }),
      {}
    )
  }

  return {
    bucketSizes,
    priceRounding: bucket_configuration?.price_rounding
      ? bucket_configuration?.price_rounding / 100
      : undefined,
    ballPrice,
    trackingTechnologies,
    isExperienceBasedPricing: price_model.experience_based_pricing,
  }
}

export const constructBucketConfigurationPayload = (bucketConfiguration: {
  bucketSizes: BucketSize[]
  ballPrice: BallPrice
  priceRounding?: number
  isExperienceBasedPricing: boolean
}): BucketConfigurationPayload => {
  const bucket_sizes =
    bucketConfiguration?.bucketSizes?.map((bucketSize) => ({
      number_of_balls: bucketSize.nrOfBalls,
      discount_percentage: bucketSize.discount,
    })) || []

  const ball_prices: BallPricePayload[] = []
  Object.entries(bucketConfiguration?.ballPrice || {}).forEach(([tracking_technology, prices]) => {
    Object.entries(prices).forEach(([demand_level, price_per_ball]) => {
      ball_prices.push({
        tracking_technology: tracking_technology as TrackingTech,
        demand_level,
        price_per_ball: parseInt(`${price_per_ball * 100}`),
      })
    })
  })

  const price_rounding = bucketConfiguration.priceRounding
    ? bucketConfiguration.priceRounding * 100
    : undefined

  return {
    price_rounding,
    bucket_sizes,
    ball_prices,
    experience_based_pricing: bucketConfiguration.isExperienceBasedPricing,
  }
}

export const validateBucketConfiguration = ({
  bucket_sizes,
  opening_hours,
  ball_prices,
  price_rounding,
}: BucketConfigurationPayload & {
  opening_hours: OpeningHours
}): string => {
  // validate opening hours
  const openingHoursError = Object.values(opening_hours).reduce(
    (acc, { open, close, disabled }) => {
      if (!disabled && !acc) {
        if (!open || !close) {
          return 'errors.missingOpeningHours'
        } else if (open >= close) {
          return 'errors.invalidOpeningHours'
        }
      }
      return acc
    },
    ''
  )
  if (openingHoursError) return openingHoursError

  // validate bucket sizes
  const hasDuplicatedBucketSizes = bucket_sizes.reduce((acc, { number_of_balls }, index) => {
    const duplicated = bucket_sizes.findIndex(
      (size, i) => size.number_of_balls === number_of_balls && i !== index
    )
    return acc || duplicated !== -1
  }, false)
  if (hasDuplicatedBucketSizes) return 'errors.duplicatedBucketSizes'
  const hasNoBalls = bucket_sizes.some(({ number_of_balls }) => !number_of_balls)
  if (hasNoBalls) return 'errors.nrOfBallsRequired'

  // validate ball prices
  let ballPricesError = ball_prices.reduce((acc, { price_per_ball }) => {
    if (!acc && price_per_ball <= 0) {
      return 'errors.invalidBallPrices'
    }
    return acc
  }, '')
  if (!ball_prices.length) ballPricesError = 'errors.invalidBallPrices'
  if (ballPricesError) return ballPricesError

  // validate price rounding
  if (!price_rounding) {
    return 'errors.priceRoundingRequired'
  }

  return ''
}

export const constructScheduleData = (schedule: RangeSchedulePayload): OpeningHours =>
  Object.entries(schedule).reduce(
    (acc, [day, times]) => ({
      ...acc,
      [day]: {
        open: times.opening_time,
        close: times.closing_time,
        disabled: !times.opening_time && !times.closing_time,
      },
    }),
    {}
  )

export const constructSchedulePayload = (openingHours: OpeningHours): RangeSchedulePayload =>
  Object.entries(openingHours).reduce(
    (acc, [day, times]) => ({
      ...acc,
      [day]: {
        opening_time: times.disabled ? null : times.open,
        closing_time: times.disabled ? null : times.close,
      },
    }),
    {}
  )

export const constructStatusMatrix = (
  demand_entries: DemandEntry[],
  initialStatusMatrix: StatusObject[][],
  DAYS_OF_WEEK: string[]
): StatusObject[][] => {
  const mergedMatrix = [...initialStatusMatrix]
  const statusMatrixByTime: StatusMatrixByTime = {}
  demand_entries.forEach((demand) => {
    statusMatrixByTime[demand.start_time] = [
      ...(statusMatrixByTime[demand.start_time] || []),
      {
        status: demand.demand_level as DemandStatus,
        dayIndex: DAYS_OF_WEEK.findIndex((day) => day.toLowerCase() === demand.weekday),
      },
    ]
  })
  const mergedStatusMatrixByTime = mergeStatusSameHourIntervals(statusMatrixByTime)
  Object.values(mergedStatusMatrixByTime).forEach((statusArray, rowIndex) => {
    statusArray.forEach(({ status, dayIndex }) => {
      mergedMatrix[rowIndex] = mergedMatrix[rowIndex]
        ? [...mergedMatrix[rowIndex]]
        : [...(initialStatusMatrix[rowIndex - 1] || [])]
      mergedMatrix[rowIndex][dayIndex] = { status }
    })
  })

  return mergedMatrix
}

export const constructDemandPayload = (day: string, time: string, newStatus: DemandStatus) => {
  const startHour = parseInt(time?.split(':')[0])
  const endHour = startHour + 1
  const demandPayload = {
    demand_level: newStatus,
    start_time: time,
    end_time: `${String(endHour).padStart(2, '0')}:00`,
    weekday: day?.toLowerCase(),
  }

  return demandPayload
}

export const constructDefaultDemandPayload = (
  statusMatrix: StatusObject[][],
  timeSections: string[][],
  DAYS_OF_WEEK: string[]
): DemandEntry[] => {
  const demandEntries: DemandEntry[] = []

  statusMatrix.forEach((statusArray, rowIndex) => {
    statusArray.forEach((_, colIndex) => {
      const startHour = parseInt(timeSections[rowIndex][0]?.split(':')[0])
      const endHour = startHour + 1
      demandEntries.push({
        demand_level: DemandStatus.Medium,
        start_time: timeSections[rowIndex][0],
        end_time: `${String(endHour).padStart(2, '0')}:00`,
        weekday: DAYS_OF_WEEK[colIndex]?.toLowerCase(),
      })
    })
  })

  return demandEntries
}

export const generateInitStatusList = (length: number): StatusObject[] => {
  return Array.from({ length }, () => ({ status: DemandStatus.Closed }))
}

export const generateInitPriceList = (length: number): PriceObject[] => {
  return Array.from({ length }, () => {
    return { price: undefined, status: DemandStatus.Closed }
  })
}

// Function to merge same hour intervals
// example: { '10:00': [], '10:16': [], '10:30': [] } => { '10:00': [] }
export const mergePriceSameHourIntervals = (
  priceMatrixByTime: PriceMatrixByTime
): PriceMatrixByTime =>
  Object.entries(priceMatrixByTime).reduce<PriceMatrixByTime>((acc, [time, priceArray]) => {
    const hour = `${time.split(':')[0]}:00`
    const existingEntry = acc[hour]
    if (!existingEntry) {
      return {
        ...acc,
        [hour]: priceArray,
      }
    }
    return {
      ...acc,
      [hour]: existingEntry.map((statusObj, index) => ({
        ...statusObj,
        status:
          priceArray[index].status === DemandStatus.Closed
            ? statusObj.status
            : priceArray[index].status,
        price: priceArray[index].price || statusObj.price,
      })),
    }
  }, {})

export const contstructPriceMatrix = (
  priceEntries: PriceEntry[],
  initialPriceMatrix: PriceObject[][],
  DAYS_OF_WEEK: string[]
): PriceObject[][] => {
  const mergedMatrix = [...initialPriceMatrix]
  const priceMatrixByTime: { [key: string]: (PriceObject & { dayIndex: number })[] } = {}
  priceEntries.forEach((price) => {
    priceMatrixByTime[price.start_time] = [
      ...(priceMatrixByTime[price.start_time] || []),
      {
        status: price.price_in_cents ? price.demand_level : DemandStatus.Closed,
        dayIndex: DAYS_OF_WEEK.findIndex((day) => day.toLowerCase() === price.weekday),
        price: price.price_in_cents ? price.price_in_cents / 100 : undefined,
      },
    ]
  })
  const mergedPriceStatusMatrixByTime = mergePriceSameHourIntervals(priceMatrixByTime)
  Object.values(mergedPriceStatusMatrixByTime).forEach((priceArray, rowIndex) => {
    priceArray.forEach(({ status, dayIndex, price }) => {
      mergedMatrix[rowIndex] = mergedMatrix[rowIndex]
        ? [...mergedMatrix[rowIndex]]
        : [...(initialPriceMatrix[rowIndex - 1] || [])]
      mergedMatrix[rowIndex][dayIndex] = { status, price }
    })
  })

  return mergedMatrix
}

export const constructPriceModelData = (
  priceTabs: PriceModelTab[],
  initialPriceMatrix: StatusObject[][],
  DAYS_OF_WEEK: string[]
): PriceModelByBalls =>
  priceTabs?.reduce<PriceModelByBalls>(
    (acc, tab) => ({
      ...acc,
      [tab.number_of_balls]: [
        ...(acc[tab.number_of_balls] || []),
        {
          nrOfBalls: tab.number_of_balls,
          trackingTech: tab.tracking_technology,
          priceMatrix: contstructPriceMatrix(tab.price_entries, initialPriceMatrix, DAYS_OF_WEEK),
        },
      ],
    }),
    {}
  )
