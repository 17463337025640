import * as React from 'react'
import { cn } from '../../../utils'
import { Divider } from '../../atoms'

interface GeneralElementProps
  extends React.HTMLAttributes<HTMLDivElement>,
    React.HTMLAttributes<HTMLParagraphElement> {
  className?: string
}

interface ListItemMainContainerProps extends GeneralElementProps {
  showDivider?: boolean
}

const ListItem = React.forwardRef<HTMLDivElement, GeneralElementProps>(
  ({ className, ...props }, ref) => (
    <div
      className={cn('flex min-h-12 w-72 min-w-72 items-center gap-4', className)}
      ref={ref}
      {...props}
    />
  )
)
ListItem.displayName = 'ListItem'

const ListItemLeading = React.forwardRef<HTMLDivElement, GeneralElementProps>(
  ({ className, ...props }, ref) => (
    <div
      className={cn('flex flex-col items-center justify-center gap-0 self-stretch px-1', className)}
    >
      <div ref={ref} className={cn('px-1')} {...props} />
    </div>
  )
)

const ListItemTrailing = React.forwardRef<HTMLDivElement, GeneralElementProps>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn('flex flex-col items-center justify-center gap-0 self-stretch', className)}
    />
  )
)
ListItemTrailing.displayName = 'ListItemTrailing'

const ListItemMainLabel = React.forwardRef<HTMLDivElement, GeneralElementProps>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn(
        'font-content text-content-base dark flex h-6 self-stretch text-ellipsis font-bold',
        className
      )}
    />
  )
)
ListItemMainLabel.displayName = 'ListItemMainLabel'

const ListItemParagraph = React.forwardRef<HTMLParagraphElement, GeneralElementProps>(
  ({ className, ...props }, ref) => (
    <p
      ref={ref}
      className={cn('text-content-sm line-clamp-2 text-ellipsis font-normal', className)}
      {...props}
    />
  )
)
ListItemParagraph.displayName = 'ListItemParagraph'

const ListItemSecondaryLabel = React.forwardRef<HTMLDivElement, GeneralElementProps>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn('text-content-sm dark flex self-stretch text-ellipsis font-bold', className)}
    />
  )
)
ListItemSecondaryLabel.displayName = 'ListItemSecondaryLabel'

const ListItemMainContainer = React.forwardRef<HTMLDivElement, ListItemMainContainerProps>(
  ({ className, children, showDivider = false, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn('flex flex-[1_0_0] flex-col content-center items-start gap-0', className)}
    >
      <div className={cn('flex items-center gap-4 self-stretch')}>{children}</div>
      {showDivider && <Divider variant="basic" />}
    </div>
  )
)
ListItemMainContainer.displayName = 'ListItemMainContainer'

const ListItemMainContent = React.forwardRef<HTMLDivElement, GeneralElementProps>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn('flex flex-[1_0_0] flex-col content-center items-start gap-0 py-2', className)}
    />
  )
)
ListItemMainContent.displayName = 'ListItemMainContent'

export {
  ListItemLeading,
  ListItemTrailing,
  ListItemMainLabel,
  ListItemParagraph,
  ListItemSecondaryLabel,
  ListItem,
  ListItemMainContainer,
  ListItemMainContent,
}
