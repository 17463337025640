import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import MainCell from '../MainCell'

import { ReactComponent as UnPaidIcon } from '@sweetspot/club-portal-legacy/resources/images/unpaid-icon-white.svg'

export default class MembershipCell extends Component {
  render() {
    const { id, membershipList: memberships } = this.props
    if (!memberships || memberships.length === 0) return '-'

    let containerClassNames = [style.container]

    return (
      <div className={containerClassNames.join(' ')} style={this.props.style}>
        {memberships && (
          <div className={style.inline}>
            <MainCell
              id={id}
              value={memberships[0].membership.name}
              cellStyle={{ maxWidth: '150px', width: '100%' }}
            />
            <span className="px-1">|</span>
            <span className={style.status}>
              <Text textId={memberships[0].status} />
            </span>
            {!memberships.filter((membership) => membership.is_paid).length && (
              <div className={style.image}>
                <UnPaidIcon className={style.image} />
              </div>
            )}
            {memberships.length > 1 && (
              <span className={style.infoHover}>
                <InfoHover toolTipIcon={`+${memberships.length - 1}`}>
                  <MembershipList list={memberships} />
                </InfoHover>
              </span>
            )}
          </div>
        )}
        {!memberships && <div>{'-'}</div>}
      </div>
    )
  }
}

MembershipCell.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  membershipList: PropTypes.array,
}

const MembershipList = (props) => {
  return (
    <div className={style.membershipList}>
      {props.list &&
        props.list.map((membership) => {
          return (
            <div key={membership.id} className={style.membershipRow}>
              <span className={style.name}>{membership.membership.name}</span>&nbsp;|&nbsp;
              <span className={style.status}>
                <Text textId={membership.status} />
              </span>
              {membership.status === 'future' && (
                <div className={style.image}>
                  <UnPaidIcon className={style.image} />
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

MembershipList.propTypes = {
  list: PropTypes.array,
}
