import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import countries from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import sv from 'i18n-iso-countries/langs/sv.json'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'

countries.registerLocale(en)
countries.registerLocale(sv)

type Props = {
  value: string
  onSelect: (value: string) => void
}

const SUPPORTED_COUNTRY_CODES = [
  'SE',
  'DK',
  'NO',
  'GB',
  'NL',
  'DE',
  'FI',
  'AT',
  'CH',
  'BE',
  'IE',
  'FR',
  'ES',
  'IT',
  'LT',
  'US',
  'CA',
]

const CountrySelect = ({ value, onSelect }: Props) => {
  const { t } = useTranslation()
  const [allCountries, setAllCountries] = useState<{ id: string; name: string }[]>([])

  useEffect(() => {
    const allCountriesObj = countries.getNames(i18n.language.includes('sv') ? 'sv' : 'en', {
      select: 'official',
    })

    const allCountriesArr = SUPPORTED_COUNTRY_CODES.map((code) => ({
      id: code,
      name: allCountriesObj[code],
    })).filter((country) => country.name)

    setAllCountries(allCountriesArr)
  }, [])

  return (
    <div className="ss-form-group">
      <label className="ss-label">{t('players.country')}</label>
      <DropdownSelect
        showBorder
        width="100%"
        initialId={value}
        values={allCountries}
        onSelect={onSelect}
      />
    </div>
  )
}

export default CountrySelect
