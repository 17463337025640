import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import usePositionFixedElement from '@sweetspot/sweetspot-js/common/hooks/usePositionFixedElement'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import Checkbox from '../FormElements/Checkbox'
import produce from 'immer'
import { useTranslation } from 'react-i18next'

const PopoverMultiSelect = ({
  options,
  selectedValues,
  enableSelectAll,
  applyAction,
  cancelAction,
  popoverOptions,
  isVisible,
}) => {
  const { t } = useTranslation()
  const elementRef = useRef()

  const [values, setValues] = useState(selectedValues)

  useEffect(() => {
    setValues(selectedValues)
  }, [selectedValues, isVisible])

  const anchorElementRef = useMemo(() => {
    if (!popoverOptions?.anchorElementRef) return null
    return popoverOptions.anchorElementRef
  }, [popoverOptions])

  const allSelected = useMemo(() => {
    return values?.length === options?.length
  }, [values, options])

  const isChecked = (option) => {
    return values.includes(option.value)
  }

  const position = usePositionFixedElement({
    containerRef: anchorElementRef,
    elementRef,
    isVisible,
    anchorPoint: 'bottom-right',
    direction: 'down-left',
  })

  useOnClickOutside(elementRef, () => {
    if (cancelAction?.onClick) {
      cancelAction.onClick()
    }
  })

  const onChangeValueListener = (option) => {
    const optionValue = option.value
    setValues(
      produce((draft) => {
        const index = draft.indexOf(optionValue)
        if (index === -1) draft.push(optionValue)
        else {
          draft.splice(index, 1)
        }
      })
    )
  }

  const selectAll = (newValue) => {
    if (newValue === true) {
      setValues(options.map((o) => o.value))
    } else {
      setValues([])
    }
  }

  if (!options || !isVisible) return null

  return (
    <div
      className={cx(styles.container, isVisible && styles.visible)}
      ref={elementRef}
      style={{ ...(anchorElementRef ? position : {}) }}
    >
      {options.map((option) => (
        <div
          className={cx(styles.group)}
          key={option.value}
          onClick={() => onChangeValueListener(option)}
        >
          <Checkbox containerClassName={cx(styles.checkbox)} value={isChecked(option)} />
          <div className={cx(styles.right)}>
            <p className={cx(styles.label)}>{option?.label || ' '}</p>
            {option?.description && <p className={cx(styles.description)}>{option.description}.</p>}
          </div>
        </div>
      ))}
      <div className={cx(styles.footer)}>
        {enableSelectAll && (
          <div className={cx(styles.group, styles.footerGroup)}>
            <Checkbox
              value={allSelected}
              containerClassName={cx(styles.checkbox)}
              onChange={(newValue) => selectAll(newValue)}
            />
            <div className={cx(styles.right)}>
              <p className={cx(styles.label)}>{t('sentences.selectAll')}</p>
            </div>
          </div>
        )}
        <div className={cx(styles.right)}>
          {cancelAction && (
            <button
              className={cx('system-button md-32 primary-outline')}
              onClick={cancelAction.onClick}
            >
              {cancelAction.label}
            </button>
          )}
          {applyAction && (
            <button
              className={cx('system-button md-32 primary')}
              onClick={() => applyAction.onClick(values)}
            >
              {applyAction.label}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

PopoverMultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  selectedValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  enableSelectAll: PropTypes.bool,
  applyAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  cancelAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  popoverOptions: PropTypes.shape({
    anchorElementRef: PropTypes.any,
  }),
  isVisible: PropTypes.bool,
}

PopoverMultiSelect.defaultProps = {
  options: undefined,
  selectedValues: undefined,
  enableSelectAll: false,
  applyAction: undefined,
  cancelAction: undefined,
  popoverOptions: undefined,
  isVisible: false,
}

export default PopoverMultiSelect
