import { useMemo } from 'react'
import ModalSimple from '@sweetspot/sweetspot-js/ui-kit/components/ModalSimple'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { currencyCodeInCurrentLanguage } from '@sweetspot/sweetspot-js/common/functions/utils'

export const BalancesModal = ({
  modalState,
  payoutFrequency,
  triggerAmount,
  onTriggerChange,
  minAmount,
  onMinChange,
  rowData,
  onFrequncyChange,
  onSave,
  loading,
  onCancel,
}) => {
  const [currencyCode] = useClubCurrency()
  const { t } = useTranslation()
  const frequencyValues = [
    { id: t('words.daily'), name: t('words.daily') },
    { id: t('words.weekly'), name: t('words.weekly') },
    { id: t('words.monthly'), name: t('words.monthly') },
  ]
  const { name } = rowData
  const isDisabled = useMemo(() => {
    const regexToCheckNumber = /^[0-9]*.[0-9]{0,2}$/
    if (regexToCheckNumber.test(triggerAmount) && regexToCheckNumber.test(minAmount)) {
      const parsedTrigger = Number.parseFloat(triggerAmount).toFixed(2)
      const parsedMin = Number.parseFloat(minAmount).toFixed(2)
      if (parsedTrigger * 100 >= parsedMin * 100) return false
    }

    return true
  }, [triggerAmount, minAmount])

  return (
    <ModalSimple
      visible={modalState}
      onClose={onCancel}
      className={styles.modalContainer}
      headerChildren={
        <p className={styles.modalHeaderText}>
          {t('words.manage')} {name.toLowerCase()}
        </p>
      }
    >
      <>
        <form className={styles.formContainer} onSubmit={(e) => e.preventDefault()}>
          <div className={styles.dropdownRow}>
            <div className="ss-form-group">
              <label className={`ss-label ${styles.formLabel}`}>{t('words.frequency')}</label>
              <DropdownSelect
                showBorder
                width="100%"
                initialId={payoutFrequency}
                values={frequencyValues}
                onSelect={(countryName) => onFrequncyChange(countryName)}
              />
            </div>
          </div>
          <div className={styles.inputsRow}>
            <div className="ss-form-group">
              <label className={`ss-label ${styles.formLabel}`}>
                {t('sentences.triggerAmount_wo_currency')}
              </label>
              <div className="ss-input-group">
                <input
                  className="ss-input"
                  min={0}
                  step={1}
                  onChange={onTriggerChange}
                  value={triggerAmount}
                  type="number"
                />
                <div className={`ss-prefix-suffix suffix ${styles.inputGroupText}`}>
                  {currencyCodeInCurrentLanguage(currencyCode as string)}
                </div>
              </div>
            </div>
            <div className="ss-form-group">
              <label className={`ss-label ${styles.formLabel}`}>
                {t('sentences.minAmount_wo_currency')}
              </label>
              <div className="ss-input-group">
                <input
                  className="ss-input"
                  min={0}
                  step={1}
                  onChange={onMinChange}
                  value={minAmount}
                  type="number"
                />
                <div className={`ss-prefix-suffix suffix ${styles.inputGroupText}`}>
                  {currencyCodeInCurrentLanguage(currencyCode as string)}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className={styles.buttonsRow}>
          <button onClick={onCancel} className="system-button primary-outline md-32">
            {t('cooperationFilter.button_Cancel')}
          </button>
          <button
            disabled={isDisabled || loading}
            onClick={onSave}
            className="system-button primary md-32"
          >
            {t('cooperationFilter.button_Save')}
          </button>
        </div>
      </>
    </ModalSimple>
  )
}
