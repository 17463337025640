import { useMemo, useState, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import PlayerCardModal from '@sweetspot/club-portal-legacy/modals/PlayerCard'

import styles from './styles.module.scss'

const PlayerName = ({ slot, loading, className, onShowDetailsChange }) => {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)
  const playerId = slot.player?.id || slot.customer?.id

  const handleShowDetailsChange = useCallback(
    (value) => {
      if (onShowDetailsChange) {
        onShowDetailsChange(value)
      }
      setShowDetails(value)
    },
    [onShowDetailsChange]
  )

  const name = useMemo(() => {
    const { player, customer, stub_player } = slot

    if (player?.first_name || player?.last_name) {
      return `${player?.first_name || ''} ${player?.last_name || ''}`
    }

    if (customer?.first_name || customer?.last_name) {
      return `${customer?.first_name || ''} ${customer?.last_name || ''}`
    }

    if (stub_player?.name) {
      return stub_player.name
    }

    return t('words.unassigned')
  }, [slot, t])

  return (
    <>
      <div>
        <span
          className={cx(className, {
            [styles.clickable]: playerId,
          })}
          onClick={() => playerId && handleShowDetailsChange(true)}
        >
          {loading ? <Skeleton width={80}></Skeleton> : name}
        </span>
      </div>
      {showDetails && (
        <Portal id="player-card-modal">
          <PlayerCardModal playerId={playerId} onClose={() => handleShowDetailsChange(false)} />
        </Portal>
      )}
    </>
  )
}

PlayerName.propTypes = {
  slot: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  handleShowDetailsChange: PropTypes.func,
}

PlayerName.defaultProps = {}

export default PlayerName
