import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { addMinutes, differenceInMinutes, format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import ItemBox from '../ItemBox'
import { Space } from '@sweetspot/shared/types'
import { ISpaceSelector } from '../../types'

import styles from './styles.module.scss'

const SpaceSelector = ({
  index,
  availableSpaces,
  selectedSpaces,
  onSelectSpace,
  orderBooking,
  venue,
  date,
  time,
}: ISpaceSelector) => {
  const [value, setValue] = useState('')

  const selectableSpaces = useMemo(() => {
    // display only spaces that are not selected in other spaces
    return availableSpaces.filter(
      (availableSpace) =>
        !selectedSpaces.some(
          (selectedSpace) =>
            selectedSpace.uuid === availableSpace.uuid &&
            selectedSpace.uuid !== selectedSpaces[index]?.uuid
        )
    )
  }, [availableSpaces, selectedSpaces, index])

  useEffect(() => {
    setValue(selectedSpaces[index]?.uuid)
  }, [selectedSpaces, index])

  const newEndTime = useMemo(() => {
    if (selectedSpaces?.length === 0) return time
    const duration = differenceInMinutes(
      new Date(orderBooking.booking.end_time),
      new Date(orderBooking.booking.start_time)
    )
    const newStartTime = new Date(`${format(date, 'MMM, dd yyyy')}, ${time}`)
    const newEndTime = addMinutes(newStartTime, duration)
    return format(newEndTime, 'HH:mm')
  }, [selectedSpaces, time, orderBooking.booking.start_time, orderBooking.booking.end_time, date])

  return (
    <ItemBox className={styles.container}>
      <div className={styles.left}>
        <DropdownSelect
          showBorder
          initialId={value}
          values={selectableSpaces.map((space) => ({
            ...space,
            id: space.uuid,
          }))}
          onSelect={(spaceId) => {
            const space = availableSpaces.find((s) => s.uuid === spaceId) as Space
            onSelectSpace(space, index)
          }}
          containerClassName={styles.inputOptionsSelect}
        />
      </div>
      <div className="flex gap-3">
        {selectedSpaces?.length > 0 && (
          <span>
            {formatInTimeZone(date, venue?.timezone, 'EEE, dd MMM yyyy')} {time}-{newEndTime}
          </span>
        )}
        <span className={cx({ 'line-through': selectedSpaces?.length > 0 })}>
          {formatInTimeZone(orderBooking.booking.start_time, venue?.timezone, 'EEE, dd MMM yyyy')}{' '}
          {formatInTimeZone(orderBooking.booking.start_time, venue?.timezone, 'HH:mm')}-
          {formatInTimeZone(orderBooking.booking.end_time, venue?.timezone, 'HH:mm')}
        </span>
      </div>
    </ItemBox>
  )
}

export default SpaceSelector
