import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

import { useTranslation } from 'react-i18next'

import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'

const Label = ({
  label,
  lightLabel,
  normalWeight,
  infoTextKey,
  defaultMargin,
  secondary,
  secondaryDark,
  disabled,
  onClick,
  lightColor,
}) => {
  const { t } = useTranslation()

  if (!label) return null

  return (
    <label
      onClick={() => {
        if (onClick) onClick()
      }}
      className={cx(
        styles.label,
        {
          [styles.labelPointerHover]: !!onClick,
        },
        {
          [styles.labelMargin]: defaultMargin,
          [styles.labelNormal]: normalWeight,
          [styles.labelSecondary]: secondary,
          [styles.labelSecondaryDark]: secondaryDark,
          [styles.labelDisabled]: disabled,
          [styles.lightColor]: lightColor,
        }
      )}
    >
      {label.charAt(0) === '.' ? t(`${label.slice(1)}`) : label}
      {lightLabel && (
        <span>{lightLabel.charAt(0) === '.' ? t(`${lightLabel.slice(1)}`) : lightLabel}</span>
      )}
      {infoTextKey ? <InfoHover textId={infoTextKey} /> : null}
    </label>
  )
}

Label.propTypes = {
  label: PropTypes.string,
  lightLabel: PropTypes.string,
  normalWeight: PropTypes.bool,
  infoTextKey: PropTypes.string,
  defaultMargin: PropTypes.bool,
  secondary: PropTypes.bool,
  secondaryDark: PropTypes.bool,
  disabled: PropTypes.bool,
  lightColor: PropTypes.bool,
  onClick: PropTypes.func,
}

Label.defaultProps = {
  label: '',
  lightLabel: '',
  normalWeight: false,
  infoTextKey: '',
  defaultMargin: true,
  secondary: false,
  secondaryDark: false,
  disabled: false,
  lightColor: false,
  onClick: undefined,
}

export default Label
