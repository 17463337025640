import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

/**
 * This component creates a wrapper that anchors the space the top container fills and renders its
 * children in a relatively positioned container that fills available space. It is intended to be
 * used in cases where a height or width of 100% would not work, such as in a container with flex
 * or grid display on some browsers
 */
export default class SpaceWrapper extends Component {
  render() {
    if (this.props.disabled) {
      return <div className={style.content}>{this.props.children}</div>
    }
    return (
      <div className={style.container} style={this.props.style}>
        <div className={style.positioner}>
          <div className={style.content}>{this.props.children}</div>
        </div>
      </div>
    )
  }
}

SpaceWrapper.propTypes = {
  /**
   * Determines if the **SpaceWrapper** is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Style object for the container
   */
  style: PropTypes.object,
}
