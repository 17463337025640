import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import DOMHelpers from '../../helpers/DOMHelpers'

/**
 * This component creates a scrollbar. It provides full control and is suitable for complex
 * scrolling mechanisms where the standard scrollbar doesn't work as needed
 */
export default class Scrollbar extends Component {
  constructor(props) {
    super(props)

    this.noScrollEvent = false

    this.handleScroll = this.handleScroll.bind(this)
  }

  /**
   * Get the ratio between the width of the container and the width of the inner block. This value
   * represents the maximum scrollable width ratio
   *
   * @returns {Number} xRatio
   */
  getXRatio() {
    let width = this._container.clientWidth
    let maxWidth = this._container.children[0].scrollWidth

    return maxWidth !== 0 ? 1 - width / maxWidth : 1
  }

  /**
   * Get the ratio between the height of the container and the height of the inner block. This value
   * represents the maximum scrollable height ratio
   *
   * @returns {Number} yRatio
   */
  getYRatio() {
    let height = this._container.clientHeight
    let maxHeight = this._container.children[0].scrollHeight

    return maxHeight !== 0 ? 1 - height / maxHeight : 1
  }

  /**
   * Scrolls the scrollbar to the given x and y ratios
   *
   * @param {Number} xRatio width ratio
   * @param {Number} yRatio height ratio
   * @public
   */
  scrollTo(xRatio, yRatio) {
    let width = this._container.children[0].scrollWidth
    let height = this._container.children[0].scrollHeight

    this.noScrollEvent = true

    // calculate scroll position
    let xScroll = xRatio * width * this.getXRatio()
    let yScroll = yRatio * height * this.getYRatio()

    this._container.scrollTo(xScroll, yScroll)
  }

  handleScroll(event) {
    if (this.noScrollEvent) {
      this.noScrollEvent = false
    } else {
      let target = event.target

      // get inner block dimensions
      let width = target.children[0].scrollWidth
      let height = target.children[0].scrollHeight

      // get container scroll positions
      let scrollX = target.scrollLeft
      let scrollY = target.scrollTop

      // calculate scroll ratios using scrollbar ratios
      let widthRatio = width !== 0 ? scrollX / width / this.getXRatio() : 0
      let heightRatio = height !== 0 ? scrollY / height / this.getYRatio() : 0

      // call prop function with ratios
      if (this.props.onScroll) this.props.onScroll(widthRatio, heightRatio)
    }
  }

  render() {
    // calculate scrollbar size
    let scrollbarSize = DOMHelpers.getScrollbarWidth() + 'px'

    let _style = {}
    if (this.props.horizontal) {
      _style.height = scrollbarSize
    } else {
      _style.width = scrollbarSize
    }

    let containerClassNames = [style.container]
    if (this.props.className) containerClassNames.push(this.props.className)
    if (this.props.horizontal) containerClassNames.push(style.horizontal)

    return (
      <div
        className={containerClassNames.join(' ')}
        style={_style}
        onScroll={this.handleScroll}
        ref={(container) => (this._container = container)}
      >
        <div className={style.overflowContent} />
      </div>
    )
  }
}

Scrollbar.propTypes = {
  /**
   * Determines if the scrollbar should be displayed horizontally
   */
  horizontal: PropTypes.bool,
  /**
   * Function to run when scrolling
   *
   * @param {Number} widthRatio horizontal scroll ratio
   * @param {Number} heightRatio vertical scroll ratio
   */
  onScroll: PropTypes.func,
  /**
   * Additional class name for the component
   */
  className: PropTypes.string,
}
