import * as React from 'react'
import { useStore } from 'zustand'

import { tv, type VariantProps } from 'tailwind-variants'
import { cn } from '../../../../utils'
import { useInputBaseStoreContext } from '../store'
import { UniversalProps } from '../../types'
import { InputContainer, InputContainerProps, InputTrailingContainer } from '../InputBase'
import { TextareaContext, createTextareaStore, useTextareaStoreContext } from './store'

const textareaVariants = tv({
  base: cn(
    'bg-background flex min-h-[80px] w-full px-3 py-2 text-sm outline-none',
    'focus:border-border-stroke-subtle rounded-md border focus:border-2 text-content-base',
    'hover:bg-background-base-neutral ring-offset-background',
    'placeholder:italic placeholder:text-muted-foreground read-only:!border-border-stroke-neutral read-only:!border'
  ),
  variants: {
    state: {
      default: 'border-border-stroke-clean',
      error: 'border-border-stroke-danger focus:border-border-stroke-danger border-2',
      disabled:
        'disabled:cursor-not-allowed bg-background-base-neutral border-border-stroke-neutral text-text-medium',
    },
  },
  defaultVariants: {
    state: 'default',
  },
})

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {
  min?: number
  max?: number
  strictLength?: boolean
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, state, onChange, value, strictLength = true, ...props }, ref) => {
    const inputBaseStore = useInputBaseStoreContext()
    const { error, disabled } = useStore(inputBaseStore, (inputBaseState) => inputBaseState)
    const textareaStore = useTextareaStoreContext()
    const { setCharLength, maxCharacters } = useStore(
      textareaStore,
      (textareaState) => textareaState
    )

    React.useEffect(() => {
      if (value && typeof value === 'string') {
        setCharLength(value.length)
      }
    }, [value])

    const getState = () => {
      if (state) return state
      if (error) return 'error'
      if (disabled) return 'disabled'
      return 'default'
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newLength = event.target.value.length
      if (strictLength && newLength > maxCharacters) return
      setCharLength(newLength)

      if (onChange) {
        onChange(event)
      }
    }

    const inputState = getState()

    return (
      <textarea
        className={cn(textareaVariants({ state: inputState }), className)}
        ref={ref}
        data-testid="scramble-textarea"
        disabled={inputState === 'disabled'}
        onChange={handleInputChange}
        value={value}
        {...props}
      />
    )
  }
)
Textarea.displayName = 'Textarea'

const TextareaCounter = React.forwardRef<HTMLDivElement, UniversalProps>(
  ({ className, children, ...props }, ref) => {
    const store = useTextareaStoreContext()
    const { charLength, maxCharacters } = useStore(store, (textareaState) => textareaState)

    return (
      <InputTrailingContainer className={cn('right-2 top-[-10px]', className)} ref={ref} {...props}>
        <p className="text-text-subtle text-content-xs">{`${charLength}/${maxCharacters}`}</p>
      </InputTrailingContainer>
    )
  }
)

TextareaCounter.displayName = 'TextareaCounter'

export interface TextareaContainerProps extends InputContainerProps {
  maxCharacters?: number
}

const TextareaContainer = React.forwardRef<HTMLDivElement, TextareaContainerProps>(
  ({ maxCharacters = 999, ...props }, ref) => {
    const [store] = React.useState(createTextareaStore({ maxCharacters }))
    return (
      <TextareaContext.Provider value={store}>
        <InputContainer {...props} ref={ref} />
      </TextareaContext.Provider>
    )
  }
)

TextareaContainer.displayName = 'TextareaContainer'

export { Textarea, TextareaCounter, TextareaContainer }
