import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import DarkBlueSubtitle from '../../DarkBlueSubtitle'
import ValueText from '../../ValueText'

const PrimeTime = ({ rule, context }) => {
  const { t } = useTranslation()

  let config = rule?.configuration?.prime_time

  if (context === 'reservationPolicies') {
    config = config === null ? config : rule?.configuration.option === 'only'
  }

  return (
    <div className={cx(styles.container)}>
      <DarkBlueSubtitle>{t('words.primeTime')}</DarkBlueSubtitle>
      {config !== null ? (
        config === true ? (
          <ValueText>{t('sentences.appliesOnlyOnPrimeTime')}</ValueText>
        ) : (
          <ValueText>{t('sentences.notIncluded')}</ValueText>
        )
      ) : (
        <ValueText>{t('words.included')}</ValueText>
      )}
    </div>
  )
}

PrimeTime.propTypes = {
  rule: PropTypes.object,
  context: PropTypes.string,
}

PrimeTime.defaultProps = {
  context: 'promotions',
}

export default PrimeTime
