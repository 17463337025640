import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'

const SimultaneousBookingChecker = ({
  checker,
  onSetSlotsChecker,
  onSetSameDayChecker,
  onSaveChecker,
  onToggle,
  disabled,
  sameDayEnabled,
  slotsError,
  sameDayError,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={cx(styles.row)}>
        <TextInputField
          containerClassName={cx(styles.textInputField, styles.discountAmount)}
          type="number"
          label={t('sentences.addNumberOfBookings')}
          inputWidth="100px"
          inputProps={{
            className: cx(styles.inputField, styles.discountAmount),
            max: 1000,
            min: 1,
          }}
          value={checker?.configuration?.number_of_simultaneous_slots}
          onChange={(val) => onSetSlotsChecker(val)}
          error={slotsError}
          onEnter={() => onSaveChecker()}
          onInputBlur={() => onSaveChecker()}
          disabled={disabled}
        />
      </div>
      <span className={cx(styles.checkerLabel)}>{t('sentences.allowSameDayBooking')}</span>

      <div className={cx(styles.row)}>
        <ToggleButton
          containerClassName={cx(styles.fromTimeToggle)}
          secondaryLabel={t('sentences.fromTime')}
          containerWidth={'half'}
          value={sameDayEnabled}
          onChange={() => onToggle()}
        />
        <TextInputField
          containerClassName={cx(styles.textInputField, styles.fromTime)}
          type="time"
          placeholder="hh:mm"
          containerWidth="70px"
          inputProps={{
            className: cx(styles.inputField, styles.discountAmount),
            max: 1000,
            min: 0,
          }}
          value={checker?.configuration?.same_day_booking_time}
          onChange={(val) => onSetSameDayChecker(val)}
          error={sameDayError}
          onEnter={() => onSaveChecker()}
          onInputBlur={() => onSaveChecker()}
          disabled={disabled || !sameDayEnabled}
        />
      </div>
    </>
  )
}

SimultaneousBookingChecker.propTypes = {
  checker: PropTypes.object,
  onSetSlotsChecker: PropTypes.func,
  onSetSameDayChecker: PropTypes.func,
  onSaveChecker: PropTypes.func,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
  sameDayEnabled: PropTypes.bool,
  slotsError: PropTypes.string,
  sameDayError: PropTypes.string,
}

SimultaneousBookingChecker.defaultProps = {}

export default SimultaneousBookingChecker
