import { useMemo, useRef } from 'react'
import _isEqual from 'lodash/isEqual'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

import { ReactComponent as RemoveIcon } from '@sweetspot/sweetspot-js/assets/svgs/stop-icon.svg'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'
import { RULE_TYPES } from '../../constants/rules'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { createNewRule, deleteRule, updateRule } from '../../services/api-platform'
import { useToasts } from 'react-toast-notifications'

import PrimeTimeRule from './Rules/PrimeTimeRule'
import DaysAndTimesRule from './Rules/DaysAndTimesRule'
import CoursesAndSpacesIncludedRule from '@sweetspot/sweetspot-js/features/promotionsReservationsCommon/Rules/CoursesAndSpacesIncludedRule'

const SingleRule = ({ rule: ruleProp, policyId, onRemoveRule, index, disabled }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const saveTimeout = useRef(null)

  // States
  const [rule, setRule] = useMergeState(ruleProp)
  const prevRule = usePrevious(rule)

  // Memos
  const isNewRule = useMemo(
    () => (typeof rule?.id === 'string' && rule.id?.includes('new') ? true : false),
    [rule]
  )

  const currentRuleType = useMemo(() => {
    return RULE_TYPES.find((item) => item.value === rule.type) || null
  }, [rule])

  // Functions
  const saveRule = async (updatedRule) => {
    const newRule = updatedRule || rule
    const newPrevRule = updatedRule ? rule : prevRule

    clearTimeout(saveTimeout.current)

    const shouldSave = () => {
      if (_isEqual(newRule, newPrevRule)) {
        return false
      } else {
        return true
      }
    }

    const canSave = () => {
      if (newRule?.type) {
        return true
      }
      return false
    }

    if (isNewRule && canSave()) {
      saveTimeout.current = setTimeout(async () => {
        const [res, err] = await to(createNewRule(policyId, newRule))

        if (!res || err) {
          addToast(t('sentences.couldNotSaveRule'), { appearance: 'error' })
        } else {
          setRule({
            ...newRule,
            id: res.id,
            uuid: res.uuid,
          })
        }
      }, 500)
    }

    if (!isNewRule && canSave() && shouldSave()) {
      saveTimeout.current = setTimeout(async () => {
        const [res, err] = await to(updateRule(newRule.uuid, newRule))

        if (!res || err) {
          addToast(t('sentences.couldNotSaveRule'), { appearance: 'error' })
        }
      }, 500)
    }
  }

  const removeRule = () => {
    if (disabled) return

    if (!isNewRule) {
      deleteRule(rule.uuid)
    }
    onRemoveRule()
  }

  return (
    <div className={cx(styles.container)} style={{ zIndex: 100 - index }}>
      <div className={cx(styles.header)}>
        <div className={cx(styles.leftSide)}>
          <p className={cx(styles.headerTitle)}>
            {currentRuleType ? t(currentRuleType.label) : t('sentences.newRule')}
          </p>
        </div>
        <div className={cx(styles.rightSide)}>
          <div
            className={cx(styles.removeIconContainer, disabled && styles.disabled)}
            onClick={removeRule}
          >
            <RemoveIcon className={cx(styles.removeIcon)} />
          </div>
        </div>
      </div>
      <div className={cx(styles.foldContainer, styles.isOpen)}>
        <div className={cx(styles.innerContainer)}>
          {/* Booking source */}
          {currentRuleType?.value === 'prime_time_rule' && (
            <PrimeTimeRule
              rule={rule}
              onSetRule={(value) => setRule(value)}
              onSaveRule={(newRule) => saveRule(newRule)}
              disabled={disabled}
            />
          )}

          {/* Courses rule */}
          {currentRuleType?.value === 'venue_rule' && (
            <CoursesAndSpacesIncludedRule
              rule={rule}
              onSetRule={(value) => setRule(value)}
              onSaveRule={(newRule) => saveRule(newRule)}
              disabled={disabled}
              context="reservationPolicy"
            />
          )}

          {/* Days & Time rule */}
          {currentRuleType?.value === 'days_and_times_in_week_policy_rule' && (
            <DaysAndTimesRule
              rule={rule}
              onSetRule={(value) => setRule(value)}
              onSaveRule={(newRule) => saveRule(newRule)}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    </div>
  )
}

SingleRule.propTypes = {
  index: PropTypes.number,
  rule: PropTypes.object,
  policyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemoveRule: PropTypes.func,
  disabled: PropTypes.bool,
}

SingleRule.defaultProps = {}

export default SingleRule
