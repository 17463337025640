import type { Middleware } from 'openapi-fetch'

export const sunsetMiddleware: Middleware = {
  async onResponse({ response }) {
    const sunsetDate = response.headers.get('Sunset')
    const sunsetReason = response.headers.get('Deprecated-Reason')

    if (sunsetDate) {
      console.log(
        '%c Sunset Notice for endpoint: %s \n Sunset Date: %s \n Sunset Reason: %s',
        'color: black; background-color: yellow; padding: 2px; display: block; float: left',
        response.url.replace('http:', '').replace('https:', ''),
        sunsetDate,
        sunsetReason || 'Unknown'
      )
    }

    return undefined
  },
}

export const addEmptyBodyToPutPostRequestsMiddleware: Middleware = {
  async onRequest({ request }) {
    if (!request.body && (request.method === 'PUT' || request.method === 'POST')) {
      return new Request(request, {
        body: JSON.stringify({}),
      })
    }
    return undefined
  },
}

export const checkForEmptyBodyMiddleware: Middleware = {
  async onResponse({ response }) {
    if (
      response?.ok &&
      response.headers.get('Content-Type')?.includes('application/json') &&
      response?.status === 202
    ) {
      try {
        await response.clone().json()
      } catch (e) {
        const { body, ...rest } = response
        return new Response(null, {
          ...rest,
          status: 204,
        })
      }
    }
    return undefined
  },
}
