import React, { useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import DropdownBox from '../DropdownBox'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

const InputSelectOptionSimple = ({
  options,
  onOptionSelect,
  disabled,
  className,
  groupTitleClassName,
  disabledOptionsClassName,
  placeholder,
  value,
  label,
  useToolTip,
  icon,
}) => {
  const [focused, setFocused] = useState(false)
  const containerRef = useRef(null)

  useOnClickOutside(containerRef, () => setFocused(false))

  const onFocusHandler = () => {
    if (focused) {
      setFocused(!focused)
      return
    }

    if (!disabled) {
      setFocused(true)
    }
  }

  const handleOptionSelect = (option) => {
    setFocused(false)
    if (!disabled) onOptionSelect(option)
  }

  return (
    <div className={cx(styles.container, className)} ref={containerRef}>
      <div className="ss-form-wrapper" style={{ width: '100%' }}>
        {label && <label className="ss-label">{label}</label>}
        <div
          className={cx('ss-input-row', disabled && 'disabled', focused && 'focused')}
          style={{ width: '100%' }}
          onClick={onFocusHandler}
        >
          {icon && (
            <div className={cx('ss-prefix-suffix prefix inline')}>
              <span className={'material-icons md-12 md-light'} style={{ color: icon?.color }}>
                {icon?.name}
              </span>
            </div>
          )}
          <input
            className={cx('ss-input has-suffix suffix-inline', {
              'has-prefix': icon,
              'prefix-inline': icon,
            })}
            readOnly
            value={value}
            placeholder={placeholder}
          />
          <div className={cx('ss-prefix-suffix suffix inline')}>
            {focused ? (
              <span className={cx('material-icons md-12 md-dark')}>expand_less</span>
            ) : (
              <span className={cx('material-icons md-12 md-dark')}>expand_more</span>
            )}
          </div>
        </div>
      </div>
      <DropdownBox
        visible={focused}
        options={options}
        useToolTip={useToolTip}
        onOptionClick={(option) => handleOptionSelect(option)}
        groupTitleClassName={groupTitleClassName}
        disabledOptionsClassName={disabledOptionsClassName}
      />
    </div>
  )
}

InputSelectOptionSimple.propTypes = {
  options: PropTypes.array,
  onOptionSelect: PropTypes.func,
  className: PropTypes.string,
  groupTitleClassName: PropTypes.string,
  disabledOptionsClassName: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  useToolTip: PropTypes.bool,
  icon: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
}

InputSelectOptionSimple.defaultProps = {
  options: [],
  onOptionSelect: () => {},
  className: '',
  groupTitleClassName: '',
  disabledOptionsClassName: '',
  disabled: false,
  placeholder: '',
  value: '',
  label: '',
  useToolTip: false,
}

export default InputSelectOptionSimple
