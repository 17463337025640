import React from 'react'
import { useMembership } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembership'
import MembershipHandler, { MembershipHandlerProps as Props } from '../MembershipHandler'
import { Membership } from '@sweetspot/shared/types'

const MembershipWrapper: React.FC<Props> = (props) => {
  const { data: membershipData } = useMembership({
    clubId: props?.clubId,
    query: { limit: 999 },
  })

  return (
    <MembershipHandler
      {...props}
      membershipList={(membershipData as Membership[]) || []}
    ></MembershipHandler>
  )
}

export default MembershipWrapper
