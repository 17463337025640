const CheckBoxAll = ({ value, onClick }) => {
  return (
    <div className="flex justify-around px-3">
      <div
        onClick={() => onClick(value)}
        className={`
          group w-4 cursor-pointer rounded border border-gray-300
          px-0 ${value === 'all' || value === 'some' ? 'bg-blue-300 text-white' : ''}
        `}
      >
        {value === 'all' ? <span>&#x2713;</span> : null}
        {value === 'some' ? <span>&minus;</span> : null}
      </div>
      <label htmlFor="checkbox-all">All</label>
    </div>
  )
}

export default CheckBoxAll
