import CopyLinkIcon from '@sweetspot/club-portal-legacy/resources/images/copy_link_icon.svg'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import VoucherPromotion from './../VoucherTable/VoucherPromotion/index'

const SingleVoucher = ({ voucher, selectedGolfClubId }) => {
  const {
    ends_at,
    promotion: { name, status, actions },
    code,
  } = voucher

  const [isOpen, setIsOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const { t } = useTranslation()

  const getVoucherConfig = (voucher) => {
    const configTypes = ['percentage_with_uses', 'percentage_with_discounted_value']
    for (const type of configTypes) {
      const configPath = ['promotion', 'actions', 0, 'configuration', type, 0]
      const config = configPath.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
        voucher
      )
      if (config) return config
    }
    return null
  }

  const voucherConfig = getVoucherConfig(voucher)
  const isUsedUp = useMemo(() => {
    if (actions && actions?.length > 0 && voucherConfig) {
      const usedVoucherValue = actions[0].used
      if (voucherConfig.uses_to) {
        return voucherConfig.uses_to - usedVoucherValue === 0
      }
      if (voucherConfig.discounted_value_to) {
        return voucherConfig.discounted_value_to - usedVoucherValue === 0
      }
    }
    return false
  }, [actions, voucherConfig])

  const handleCopy = () => {
    setIsCopied(true)
  }

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false)
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [isCopied])

  const formatVoucherDates = useMemo(() => {
    const endDateString = DateHelpers.dateStringwithMinutes(ends_at)
    if (ends_at && status === 'active') {
      return `${t('settings.playerCard.voucher.validThrough')}: ${endDateString}`
    }
    if (ends_at) {
      return `${t('settings.playerCard.voucher.validThrough')}: ${endDateString}`
    } else {
      return t('settings.playerCard.voucher.noExpirationDate')
    }
  }, [ends_at, status, t])

  return (
    <div className={cx(styles.container, isOpen && styles.openContainer)}>
      <div className={cx(styles.header)} onClick={() => setIsOpen(!isOpen)}>
        <div className={cx(styles.leftSide)}>
          <div className={cx(styles.caretContainer)}>
            <CaretDown className={cx(styles.caretDown, isOpen && styles.isOpen)} />
          </div>
          {status && (
            <div className={'flex items-center gap-2'}>
              <p className={cx(styles.headerTitle)}>{name}</p>
              <div className={cx(styles.statusContainer)}>
                {status !== 'upcoming' && (
                  <div
                    className={cx(styles.dot, {
                      [styles.green]: status === 'active' && !isUsedUp,
                      [styles.red]: ['canceled', 'expired'].includes(status) || isUsedUp,
                    })}
                  ></div>
                )}
                <p className={cx(styles.text)}>
                  {isUsedUp
                    ? t('consumed')
                    : t(status).charAt(0).toUpperCase() + t(status).slice(1).toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={cx(styles.rightSide)}>
          <p className={'font-roboto text-sm font-normal'}>{formatVoucherDates}</p>
        </div>
      </div>

      {isOpen && (
        <>
          <div className={cx(styles.foldContainer, styles.isOpen)}>
            {code && (
              <div className={cx(styles.topRow)}>
                <p className={cx(styles.codeText)}>{code}</p>
                <CopyToClipboard text={code} onCopy={handleCopy}>
                  <div className={cx(styles.copyIconContainer)}>
                    <img
                      data-tip
                      data-tooltip-id="clipboard_copy"
                      alt="clipboard icon"
                      src={CopyLinkIcon}
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                    />
                  </div>
                </CopyToClipboard>
                <Tooltip id="clipboard_copy" place="right" effect="solid">
                  <p>
                    {isCopied
                      ? t('settings.playerCard.voucher.copied')
                      : t('settings.playerCard.voucher.copyVoucherCode')}
                  </p>
                </Tooltip>
              </div>
            )}
          </div>

          <div className={cx(styles.foldContainer, styles.isOpen)}>
            <div className={cx(styles.topRow)}>
              <VoucherPromotion
                getVoucherConfig={getVoucherConfig}
                voucher={voucher}
                selectedGolfClubId={selectedGolfClubId}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

SingleVoucher.propTypes = {
  voucher: PropTypes.object.isRequired,
  currentIndex: PropTypes.number,
  onUpdatePromotionsOrder: PropTypes.func,
  onUpdatePromotionPriority: PropTypes.func,
  onMoveToBottom: PropTypes.func,
  canDrag: PropTypes.bool,
  dragHandle: PropTypes.any,
}

SingleVoucher.defaultProps = {
  canDrag: true,
  dragHandle: null,
}

export default SingleVoucher
