import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import moment from 'moment'

import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import IncludedCoursesTable from '../IncludedCoursesTable'

import StringHelpers from '@sweetspot/club-portal-legacy/helpers/StringHelpers'
import { GolfCarts } from '@sweetspot/club-portal-legacy/components/GolfCarts'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import { currencyCodeInCurrentLanguage } from '@sweetspot/sweetspot-js/common/functions/utils'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { withClubCurrency } from '../../../../hoc/withClubCurrency'
import Separator from '../Separator'

const MIN_GOLF_CART_FEE = 0
const MAX_GOLF_CART_FEE = 999999
const MIN_VAT_GOLF_CART = 0
const MAX_VAT_GOLF_CART = 100

const GCForm = ({
  isCreate,
  form,
  activities,
  originForm,
  courses,
  usedCourses,
  onChange,
  onChangeUsedCourses,
  onCreate,
  onUpdate,
  onRemove,
  onReset,
  warehouseUuid,
  productId,
  currentClubCurrency,
  onFirstCreate,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [isGCEnabled, setIsGCEnabled] = useState(form.isGCEnabled)
  const [isEnabledForPlayers, setIsEnabledForPlayers] = useState(form.isEnabledForPlayers)
  const { t } = useTranslation()

  const getSuggestedItems = () => {
    return courses
      .filter((course) => !usedCourses.includes(course.id))
      .filter((course) => !form.includedCourses.some((iCourse) => iCourse.id === course.id))
      .filter((course) => course.name.toLowerCase().includes(searchValue.toLowerCase()))
  }

  const addCourse = (id) => {
    setSearchValue('')
    const newCourse = courses.find((course) => course.id === id)

    const time = moment().set({ hour: 6, minute: 0, second: 0 })
    onChange('includedCourses', [
      ...form.includedCourses,
      { ...newCourse, productAvailability: { time, minutes: 30 } },
    ])
    onChangeUsedCourses(id)
  }

  const changeCourse = (id, name, value) => {
    onChange(
      'includedCourses',
      form.includedCourses.map((item) => {
        if (item.id === id) {
          return { ...item, productAvailability: { ...item.productAvailability, [name]: value } }
        }
        return item
      })
    )
  }

  const removeCourse = (id) => {
    onChange(
      'includedCourses',
      form.includedCourses.filter((course) => course.id !== id)
    )
    onChangeUsedCourses(id)
  }

  const isValidPrice = (value) => /^[0-9]+([,.][0-9]+)?$/.test(value)
  const isValidName = (string) => !!string.trim() && !StringHelpers.includeEmoji(string)

  const isValidForm = () => {
    const { name, fee, vat, includedCourses, inventory } = form
    const availability = includedCourses.map((course) => course.productAvailability?.minutes || 0)

    const isFeeValid = isValidPrice(fee) && fee !== undefined && fee !== null && fee !== ''
    const isVatValid =
      isValidPrice(vat) && vat !== undefined && vat !== null && vat !== '' && vat <= 100

    if (!isValidName(name)) return false

    if (name.trim().length <= 1) return false

    if (!isCreate && inventory?.length > 0) {
      if (!isFeeValid || !isVatValid) return false
    }

    if (availability.includes('')) return false

    return true
  }

  useEffect(() => {
    setIsGCEnabled(form.isGCEnabled)
    setIsEnabledForPlayers(form.isEnabledForPlayers)
  }, [form.isGCEnabled, form.isEnabledForPlayers])

  return (
    <div className={'mt-2 flex h-[92%] flex-col'}>
      <div className={'flex-1'}>
        <TextInputField
          label=".golfCarts.golfCartName"
          value={form.name}
          onChange={(value) => onChange('name', value)}
        />

        {!isCreate && (
          <>
            <GolfCarts
              warehouseUuid={warehouseUuid}
              fee={form.fee}
              vat={form.vat}
              productId={productId}
              onChange={(fieldName, value) => onChange(fieldName, value, true)}
              setVatAndPrice={(price, vat, inventory) => onFirstCreate(price, vat, inventory)}
            ></GolfCarts>

            {form.inventory?.length > 0 && (
              <div className={'inline-flex items-center'}>
                <div className={'mr-4 flex w-[40%]'}>
                  <TextInputField
                    label=".golfCarts.golfCartFee"
                    value={form.fee}
                    suffix={currencyCodeInCurrentLanguage(currentClubCurrency)}
                    onChange={(value) => {
                      const numericValue = value.replace(/[^0-9]/g, '')
                      const intValue = parseInt(numericValue, 10)
                      if (
                        !isNaN(intValue) &&
                        intValue >= MIN_GOLF_CART_FEE &&
                        intValue <= MAX_GOLF_CART_FEE
                      ) {
                        onChange('fee', intValue.toString())
                      } else if (intValue > MAX_GOLF_CART_FEE) {
                        onChange('fee', MAX_GOLF_CART_FEE.toString())
                      } else {
                        onChange('fee', MIN_GOLF_CART_FEE.toString())
                      }
                    }}
                  />
                  <InfoHover textId="golfCarts.priceInfo" />
                </div>
                <TextInputField
                  label=".golfCarts.golfCartVat"
                  inputWidth="quarter"
                  suffix="%"
                  value={form.vat}
                  onChange={(value) => {
                    const numericValue = value.replace(/[^0-9]/g, '')
                    const intValue = parseInt(numericValue, 10)
                    if (
                      !isNaN(intValue) &&
                      intValue >= MIN_VAT_GOLF_CART &&
                      intValue <= MAX_VAT_GOLF_CART
                    ) {
                      onChange('vat', intValue.toString())
                    } else if (intValue > MAX_VAT_GOLF_CART) {
                      onChange('vat', MAX_VAT_GOLF_CART.toString())
                    } else {
                      onChange('vat', MIN_VAT_GOLF_CART.toString())
                    }
                  }}
                />
              </div>
            )}
          </>
        )}
        <div className={'flex cursor-pointer select-none text-base '}>
          <ToggleButton
            value={isGCEnabled}
            onChange={(value) => onChange('isGCEnabled', value)}
            containerWidth="full"
            containerClassName={'flex max-w-[40px]'}
          ></ToggleButton>
          <p className={'-mx-2 -mt-1'}>{t('golfCarts.enableGolfCart')}</p>
        </div>
        <div className={'flex cursor-pointer select-none text-base '}>
          <ToggleButton
            value={isEnabledForPlayers}
            onChange={(value) => onChange('isEnabledForPlayers', value)}
            containerWidth="full"
            containerClassName={'flex max-w-[40px]'}
          ></ToggleButton>
          <p className={'-mx-2 -mt-1'}>{t('golfCarts.enableBookingOfGolfCart')}</p>
        </div>
        <TextInputField
          label=".wizard.includedCourses"
          placeholder={t('wizard.searchCourses')}
          value={searchValue}
          onChange={(value) => setSearchValue(value)}
          suggestionItems={getSuggestedItems()}
          suggestionItemsLabelKey="name"
          suggestionItemsItemKey="itemKey"
          noSuggestionsAvailableLabel={t('wizard.noAvailableCourses')}
          onSuggestionClick={(id) => addCourse(id)}
        />
        {!!form.includedCourses.length && (
          <IncludedCoursesTable
            includedCourses={form.includedCourses}
            onChangeCourse={changeCourse}
            onDelete={(id) => removeCourse(id)}
          />
        )}
      </div>
      <Separator />
      <div>
        {isCreate && (
          <button
            className={'system-button primary ss-block-button md-32 my-2.5'}
            disabled={!isValidForm()}
            onClick={onCreate}
          >
            {t('create')}
          </button>
        )}
        {!isCreate && (
          <div className={'flex items-center justify-between'}>
            {!activities?.length && (
              <button className={'system-button warning md-32 my-2.5 w-[140px]'} onClick={onRemove}>
                {t('golfCarts.deleteGolfCart')}
              </button>
            )}
            {!isEqual(form, originForm) && (
              <div className={'flex'}>
                <button
                  className={'system-button primary-outline ss-block-button md-32 my-2.5 w-1/2'}
                  disabled={!isValidForm()}
                  onClick={onReset}
                >
                  <p>{t('cancel')}</p>
                </button>
                <button
                  className={'system-button primary ss-block-button md-32 my-2.5 ml-2.5 w-1/2'}
                  disabled={!isValidForm()}
                  onClick={onUpdate}
                >
                  {t('wizard.saveChanges')}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

GCForm.propTypes = {
  isCreate: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  originForm: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  courses: PropTypes.array.isRequired,
  usedCourses: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeUsedCourses: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  currentClubCurrency: PropTypes.string.isRequired,
}

export default withClubCurrency(GCForm)
