import cx from 'classnames'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { priceToLocal } from '@sweetspot/shared/util/functions'

export const EntityDetails = ({
  verificationStatus,
  entityDetails,
  accType,
  totalBalance,
}): JSX.Element => {
  const [currencyCode] = useClubCurrency()
  const { t } = useTranslation()
  return (
    <div className={cx(styles.userDataContainer)}>
      <div className={cx(styles.cellContainer)}>
        <label>{t('sentences.accountType')}</label>
        <span>{accType}</span>
      </div>
      <div className={cx(styles.cellContainer)}>
        <label>{t('words.country')}</label>
        <span>{entityDetails.address}</span>
      </div>
      <div className={cx(styles.cellContainer)}>
        <label>{t('sentences.legalName')}</label>
        <span>{entityDetails.name}</span>
      </div>
      <div className={cx(styles.cellContainer)}>
        <label>{t('words.email')}</label>
        <span>{entityDetails.email}</span>
      </div>
      <div className={cx(styles.cellContainer)}>
        <label>{t('sentences.payoutStatus')}</label>
        <span>{verificationStatus}</span>
      </div>
      <div className={cx(styles.cellContainer)}>
        <label>{t('sentences.totalBalance')}</label>
        <span>{totalBalance ? priceToLocal(totalBalance, currencyCode, true) : '-'}</span>
      </div>
    </div>
  )
}
