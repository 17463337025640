import React, { useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import FeedbackContainer from '../Partials/FeedbackContainer'
import InputPrefixSuffix from '../Partials/InputPrefixSuffix'
import InputField from '../Partials/InputField'
import Label from '../Partials/Label'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import DropdownBox from '../Partials/DropdownBox'
import InputInlinePrefixSuffix from '../Partials/InputInlinePrefixSuffix'

const TextInputOptionsSelect = ({
  onInputBlur,
  onInputFocus,
  onChange,
  onEnter,
  disabled,
  label,
  labelTwo,
  placeholder,
  type,
  value,
  error,
  feedbackPosition,
  feedbackAnchorPosition,
  success,
  containerWidth,
  inputWidth,
  inputProps,
  infoTextKey,
  suffix,
  prefix,
  containerClassName,
  theme,
  options,
  onOptionSelect,
  noMargin,
  inputReadOnly,
}) => {
  const [inputFocused, setInputFocused] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdownContainerRef = useRef()

  useOnClickOutside(dropdownContainerRef, () => {
    setDropdownOpen(false)
    setInputFocused(false)
  })

  const onOptionClick = (item) => {
    if (item?.disabled) return
    if (disabled) return

    setDropdownOpen(false)
    setInputFocused(false)

    if (onOptionSelect) onOptionSelect(item)
  }

  const onBlur = () => {
    if (dropdownOpen) return

    setInputFocused(false)
    if (onInputBlur) onInputBlur()
  }

  const onFocus = (e) => {
    setDropdownOpen(true)
    setInputFocused(true)
    if (onInputFocus) onInputFocus(e)
  }

  const valueChanged = (value) => {
    if (disabled) return
    if (onChange) onChange(value)
  }

  const getContainerStyles = () => {
    if (
      typeof containerWidth &&
      (containerWidth.includes('auto') ||
        containerWidth.includes('px') ||
        containerWidth.includes('%'))
    ) {
      return {
        width: containerWidth,
      }
    }
    return {}
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          'w-full': containerWidth === 'full',
          'w-1/2': containerWidth === 'half',
          'w-1/3': containerWidth === 'third',
          'w-1/4': containerWidth === 'quarter',
        },
        {
          [styles.noMargin]: noMargin === true,
        },
        containerClassName
      )}
      style={getContainerStyles()}
    >
      {/* Main label */}
      <Label label={label} infoTextKey={infoTextKey} />

      {/* Second label */}
      <Label label={labelTwo} infoTextKey={!label && infoTextKey ? infoTextKey : null} secondary />

      {/* Input Container */}
      <div
        className={cx(styles.inputContainer, {
          'w-full': inputWidth === 'full',
          'w-1/2': inputWidth === 'half',
          'w-1/4': inputWidth === 'quarter',
        })}
      >
        {/* Input */}
        {prefix ? (
          <InputPrefixSuffix
            type="prefix"
            error={!!error}
            success={!!success}
            value={typeof value !== 'undefined' && value !== '' && value !== null}
            focused={inputFocused}
            disabled={disabled}
          >
            {prefix}
          </InputPrefixSuffix>
        ) : null}
        <InputField
          theme={theme}
          disabled={disabled}
          onChange={(e) => valueChanged(e.target.value)}
          type={type === 'calendar' || type === 'time' ? 'text' : type}
          placeholder={placeholder}
          value={typeof value === 'number' ? value + '' : value}
          error={error}
          success={success}
          hasPrefix={!!prefix}
          hasSuffix={true}
          onEnterPress={onEnter}
          isExternalFocused={inputFocused}
          reducePaddingRight={true}
          {...{ ...inputProps, onFocus: onFocus, onBlur: onBlur, readOnly: inputReadOnly }}
        />

        <InputInlinePrefixSuffix
          type="suffix"
          error={!!error}
          success={!!success}
          value={typeof value !== 'undefined' && value !== '' && value !== null}
          focused={inputFocused}
          disabled={disabled}
          onSuffixClick={() => onFocus()}
        >
          <CaretDown className={cx(styles.dropdownIcon)} />
        </InputInlinePrefixSuffix>

        <DropdownBox
          dropdownOpen={dropdownOpen}
          ref={dropdownContainerRef}
          options={options}
          onOptionClick={onOptionClick}
        />
      </div>

      {/* Feedback container */}
      <FeedbackContainer
        error={error}
        success={success}
        position={feedbackPosition}
        anchorPosition={feedbackAnchorPosition}
      />
    </div>
  )
}

TextInputOptionsSelect.propTypes = {
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /** Error message to show for this field */
  error: PropTypes.string,

  /** Success message to show for this field */
  success: PropTypes.string,

  /** Position of feedback container */
  feedbackPosition: PropTypes.oneOf(['bottom', 'top']),
  feedbackAnchorPosition: PropTypes.oneOf(['left', 'right']),

  /** Is input disabled or not */
  disabled: PropTypes.bool,

  /** Called when input field is updated */
  onChange: PropTypes.func,

  /** Main label for field */
  label: PropTypes.string,

  /** Extra smaller label for field */
  labelTwo: PropTypes.string,

  /** Placeholder text in input field */
  placeholder: PropTypes.string,

  /** Type of input */
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'calendar', 'text-area', 'time']),

  /** If type text-area */
  rows: PropTypes.number,

  /** Any custom class-names for component container  */
  containerClassName: PropTypes.string,

  /** Width of component container: full | half | quarter */
  containerWidth: PropTypes.string,

  /** Width of input field relative to container width: full | half | quarter */
  inputWidth: PropTypes.string,

  /** Text ID key for info text icon on hover */
  infoTextKey: PropTypes.string,

  /** Props to pass down to input element */
  inputProps: PropTypes.object,

  /** Triggered when input is focused */
  onInputFocus: PropTypes.func,

  /** Triggered when input is blurred */
  onInputBlur: PropTypes.func,

  /** Suffix for input field */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),

  /** Prefix for input field */
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),

  onSuffixClick: PropTypes.func,

  onEnter: PropTypes.func,

  theme: PropTypes.oneOf(['webApp', 'club']),

  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      iconDisabled: PropTypes.elementType,
      disabled: PropTypes.bool,
      id: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  onOptionSelect: PropTypes.func,
  noMargin: PropTypes.bool,
  inputReadOnly: PropTypes.bool,
}

TextInputOptionsSelect.defaultProps = {
  theme: 'club',
  value: '',
  error: '',
  success: '',
  feedbackPosition: 'bottom',
  feedbackAnchorPosition: 'left',
  disabled: false,
  onChange: () => {},
  label: '',
  labelTwo: '',
  placeholder: '',
  type: 'text',
  containerClassName: '',
  containerWidth: 'full',
  inputWidth: 'full',
  onInputFocus: () => {},
  onInputBlur: () => {},
  infoTextKey: '',
  inputProps: {},
  suffix: null,
  prefix: null,
  suggestionItems: undefined,
  suggestionItemsLabelKey: 'name',
  suggestionItemsIdentifierKey: 'id',
  suggestionItemsItemKey: 'id',
  onSuggestionClick: () => {},
  noSuggestionsAvailableLabel: undefined,
  onSuffixClick: () => {},
  maskOptions: null,
  calendarSettings: null,
  onEnter: undefined,

  options: [],
  onOptionSelect: () => {},
  noMargin: false,
  inputReadOnly: true,
}

export default TextInputOptionsSelect
