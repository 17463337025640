import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { ImportID, PlayRightImport, PlayRightPayload } from './types'

export const createPlayRightsImport = (payload: PlayRightPayload): Promise<PlayRightImport> => {
  const URL = `${getApiPlatformBaseUrl()}/play-right-imports`

  return request(URL, {
    method: 'POST',
    body: payload.body,
  })
}

export const processPlayRightsImport = (id: ImportID): Promise<Record<string, never>> => {
  const URL = `${getApiPlatformBaseUrl()}/play-right-imports/${id}/import`

  return request(URL, {
    method: 'PUT',
    body: {},
  })
}

export const getImportStatus = (id: ImportID): Promise<PlayRightImport> => {
  const URL = `${getApiPlatformBaseUrl()}/play-right-imports/${id}`

  return request(URL, {
    method: 'GET',
  })
}
