import { useOnClickOutside } from '@sweetspot/shared/util/hooks'
import EnvSelector from '@sweetspot/sweetspot-js/common/components/EnvSelector'
import { API_ENV_KEYS } from '@sweetspot/shared/util/constants'
import { useRef, useState } from 'react'
import { LoginContainer } from './LoginContainer'

interface LoginProps {
  currentLoginStep?: string
  infoElement?: JSX.Element
  signInElement: JSX.Element
  resetPasswordStatus?: string | null
  changeResetPasswordStatus: (status: string | null) => void | undefined
}

export const Login = ({
  currentLoginStep,
  infoElement,
  signInElement,
  resetPasswordStatus,
  changeResetPasswordStatus,
}: LoginProps) => {
  const API_ENV = process.env.REACT_APP_API_ENV
  const [showEnvSelect, setShowEnvSelect] = useState(false)
  const envSelectRef = useRef(null)
  useOnClickOutside(envSelectRef, () => setShowEnvSelect(false))

  const devLogin = (): JSX.Element => {
    return (
      <div className={'absolute left-0 top-1 z-20 w-[600px] max-w-full'} ref={envSelectRef}>
        <button
          onClick={() => setShowEnvSelect(!showEnvSelect)}
          className={'h-8 rounded-2xl bg-white px-4 text-black'}
          tabIndex={-1}
        >
          Select env
        </button>

        {showEnvSelect && (
          <div className={'items-left stretch relative left-0 top-1 z-30 flex w-full flex-col'}>
            <div
              className={
                'min-w-1/2 flex flex-col items-center justify-center border-b border-solid border-black bg-white'
              }
            >
              <div className={'w-full p-3 text-center'}>
                <EnvSelector />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={'text-sweetblack relative flex'}>
      {API_ENV === API_ENV_KEYS.DEV && devLogin()}
      <div className={'relative flex min-h-0 min-w-0 flex-1 flex-row items-stretch justify-center'}>
        <LoginContainer
          signInElement={signInElement}
          currentLoginStep={currentLoginStep}
          resetPasswordStatus={resetPasswordStatus}
          changeResetPasswordStatus={changeResetPasswordStatus}
        />

        <div
          className={`min-h-600px
          relative hidden shrink-0 grow-[2] flex-col bg-[url('https://d3t919hsr1iz6l.cloudfront.net/images/logo/sign-in-bg.jpg')] bg-cover bg-fixed bg-[calc(50%)_center] bg-no-repeat md:flex`}
        >
          <div
            className={
              'flex flex-1 flex-col items-center justify-center text-7xl font-semibold text-black'
            }
          >
            <div>{infoElement}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
