import React, { useState, useCallback, useMemo } from 'react'
import usePlayerBookings from '@sweetspot/club-portal-legacy/modals/PlayerCard/hooks/usePlayerBookings'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Table from '@sweetspot/club-portal-legacy/components/Table'
import style from './style.module.scss'
import headers from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Bookings/headers'
import BookingFilters from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Bookings/Filters'
import { SortOrder } from '@sweetspot/shared/types'

type BookingsProps = {
  playerId?: number
}

const sortingMap: Record<string, string> = {
  date: 'order[booking.startTime]',
}

const Bookings = ({ playerId }: BookingsProps) => {
  const [statusFilter, setStatusFilter] = useState<string | null>(null)
  const [sorting, setSorting] = useState<{ name: string; type: SortOrder }[]>([])
  const { bookings, isLoading, error } = usePlayerBookings({ playerId, statusFilter, sorting })

  const updatedBookings = useMemo(() => {
    return bookings.map((booking) => {
      if (booking?.status?.toLowerCase() !== 'Waiting for payment'.toLowerCase()) return booking
      return {
        ...booking,
        status: 'Awaiting payment',
      }
    })
  }, [bookings])

  const onHeaderClick = useCallback((headers: { name: string; type: SortOrder }[]) => {
    const newSorting = headers
      ?.map((header) => {
        const queryParamName = sortingMap[header.name]
        return queryParamName ? { name: queryParamName, type: header.type } : null
      })
      .filter((sort): sort is { name: string; type: SortOrder } => sort !== null)

    setSorting(newSorting)
  }, [])

  return (
    <div className={style.container}>
      {!!error && <div className={style.error}>{error as string}</div>}
      <BookingFilters
        disabled={isLoading}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      {isLoading ? (
        <PulseLoader dotStyles={{ width: 10, height: 10 }} showIf={true} />
      ) : (
        <Table
          headers={headers}
          values={updatedBookings}
          onHeaderClick={onHeaderClick}
          fillHeight
          fullWidth
          defaultSort={'date'}
          defaultSortType={'desc'}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onRowClick={() => {}}
        />
      )}
    </div>
  )
}

export default Bookings
