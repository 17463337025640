import React, { FC } from 'react'

import styles from '../style.module.scss'

import { Note, Player } from '@sweetspot/shared/types'
import NewNoteInput from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/Notes/NewNoteInput'
import NotesList from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/Notes/NotesList'
import { useQuery } from 'react-query'
import { getPlayerNotes } from '@sweetspot/shared/data-access/api-platform'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

type Props = {
  player: Player | null
}

const Notes: FC<Props> = ({ player }) => {
  const {
    data: notes,
    isLoading,
    refetch,
  } = useQuery(['playerNotes', player?.uuid], () => getPlayerNotes(player?.uuid || ''), {
    enabled: !!player?.uuid,
  })
  return (
    <div className={styles.notesContainer}>
      <NewNoteInput player={player} onNewNoteAdd={refetch} />
      {isLoading ? (
        <PulseLoader
          color="#FFF"
          dotStyles={{ width: 10, height: 10, marginTop: '7px' }}
          showIf={true}
        />
      ) : (
        <NotesList notes={(notes as Note[]) || []} updateNotesList={refetch} />
      )}
    </div>
  )
}

export default Notes
