import { GolfCourseItem } from '../store/types'

export const getPathFromVenue = (venueType: string): string => {
  switch (venueType) {
    case 'course':
    default:
      return '/course-venue'

    case 'simulator':
      return '/simulators-venue'

    case 'driving_range':
      return '/ranges-venue'

    case 'pro':
      return '/pro-venue'

    case 'other':
      return '/other-venue'
  }
}

export const getPathFromCourses = (courses: GolfCourseItem[]): string => {
  switch (true) {
    case courses.some((course) => course.type === 'course'):
    default:
      return '/course-venue'

    case courses.some((course) => course.type === 'simulator'):
      return '/simulators-venue'

    case courses.some((course) => course.type === 'driving_range'):
      return '/ranges-venue'

    case courses.some((course) => course.type === 'pro'):
      return '/pro-venue'

    case courses.some((course) => course.type === 'other'):
      return '/other-venue'
  }
}
