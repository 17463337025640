import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
import {
  _getGolfCartsFleets,
  _getGCFleet,
  _createGCFleet,
  _updateGCFleet,
  _removeGCFleet,
  _getRentalActivities,
  _createProductAvailability,
  _updateProductAvailability,
} from '@sweetspot/club-portal-legacy/services/golfCartsApi'

export function getGolfCartFleets(token, query) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getGolfCartsFleets(query)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('getGCFleetsError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getGCFleet(token, uuid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getGCFleet(uuid)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          if (err?.status === 404) {
            dispatch(addToast('notExistingGolfCart', 'error'))
          } else {
            dispatch(addToast('getGCFleetError', 'error'))
          }

          return reject(err)
        })
    })
  }
}

export function createGCFleet(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createGCFleet(payload)
        .then((res) => {
          dispatch(addToast('createGCFleetSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('createGCFleetError', 'error'))
          return reject(err)
        })
    })
  }
}

export function updateGCFleet(token, lang, payload, golfCartId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateGCFleet(lang, payload, golfCartId)
        .then((res) => {
          dispatch(addToast('updateGCFleetSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          let errMsg = 'updateGCFleetError'
          if (err?.violations?.length) {
            errMsg = err.violations[0].message
          }
          dispatch(addToast(errMsg, 'error'))
          return reject(err)
        })
    })
  }
}

export function removeGCFleet(token, payload, golfCartId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _removeGCFleet(payload, golfCartId)
        .then((res) => {
          dispatch(addToast('removeGCFleetSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('removeGCFleetError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getRentalActivities(token, golfCartId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getRentalActivities(golfCartId)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('getRentalsError', 'error'))
          return reject(err)
        })
    })
  }
}

export function createProductAvailability(token, payload) {
  return () => {
    return new Promise((resolve, reject) => {
      _createProductAvailability(payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function updateProductAvailability(token, payload) {
  return () => {
    return new Promise((resolve, reject) => {
      _updateProductAvailability(payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}
