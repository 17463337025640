import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { t } from 'i18next'

import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import CalendarDropdown from '@sweetspot/club-portal-legacy/components/CalendarDropdown'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import TimePeriod from '@sweetspot/club-portal-legacy/components/TimePeriod'
import ConfirmWindow from '@sweetspot/club-portal-legacy/Partials/Dialogs/ConfirmWindow'
import CourseList from '@sweetspot/club-portal-legacy/pages/Communication/components/BookingEmail/CourseList'

import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'

import style from './style.module.scss'

const initialState = {
  title: '',
  body: '',
  date: new Date(),
  startTime: moment({ minutes: 0, hours: 0 }),
  endTime: moment({ minutes: 59, hours: 23 }),
  showConfirm: false,
  showConfirmSend: false,
  courseList: [],
}

const SEND = 'sent'
const DRAFT = 'draft'

class BookingEmail extends Component {
  constructor() {
    super()

    this.state = initialState

    this.loadValues = this.loadValues.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleAddCourse = this.handleAddCourse.bind(this)
    this.removeCourse = this.removeCourse.bind(this)
    this.getRecipients = this.getRecipients.bind(this)
    this.getQueryString = this.getQueryString.bind(this)
    this.toggleConfirm = this.toggleConfirm.bind(this)
    this.toggleConfirmSend = this.toggleConfirmSend.bind(this)
    this.checkChange = this.checkChange.bind(this)
    this.saveDraft = this.saveDraft.bind(this)
    this.handleSend = this.handleSend.bind(this)
  }

  componentDidMount() {
    const { email } = this.props

    if (email) this.loadValues()
  }

  loadValues() {
    const { email, courseList } = this.props

    const fromDate = DateHelpers.getLocalDateFromUTCString(email.from_time)
    const toDate = DateHelpers.getLocalDateFromUTCString(email.to_time)

    const startMin = new Date(fromDate).getMinutes()
    const startHour = new Date(fromDate).getHours()
    const endMin = new Date(toDate).getMinutes()
    const endHour = new Date(toDate).getHours()

    this.setState(
      {
        body: email.body,
        title: email.title,
        date: new Date(fromDate),
        startTime: moment({ minutes: startMin, hours: startHour }),
        endTime: moment({ minutes: endMin, hours: endHour }),
        courseList: courseList.filter((course) => email.golf_courses.includes(course.id)),
      },
      this.getRecipients
    )
  }

  handleChange(e) {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleDateChange(date) {
    this.setState({ date }, this.getRecipients)
  }

  handleTimeChange(moment, isStart) {
    if (!moment) {
      return
    }
    if (isStart) {
      this.setState({ startTime: moment }, this.getRecipients)
      if (moment > this.state.endTime) this.setState({ endTime: moment })
    } else {
      if (moment >= this.state.startTime) this.setState({ endTime: moment }, this.getRecipients)
    }
  }

  toggleConfirm() {
    this.setState({ showConfirm: !this.state.showConfirm })
  }

  toggleConfirmSend() {
    this.setState({ showConfirmSend: !this.state.showConfirmSend })
  }

  checkChange() {
    const { body, title, date, startTime, endTime, courseList } = this.state
    const { email } = this.props

    if (email) {
      const fromDate = DateHelpers.getLocalDateFromUTCString(email.from_time)
      const toDate = DateHelpers.getLocalDateFromUTCString(email.to_time)

      const fromMin = new Date(fromDate).getMinutes()
      const fromHour = new Date(fromDate).getHours()
      const toMin = new Date(toDate).getMinutes()
      const toHour = new Date(toDate).getHours()

      if (
        email.body === body &&
        email.title === title &&
        +fromDate === +new Date(date) &&
        +startTime === +moment({ minutes: fromMin, hours: fromHour }) &&
        +endTime === +moment({ minutes: toMin, hours: toHour }) &&
        _.isEqual(
          courseList,
          this.props.courseList.filter((course) => email.golf_courses.includes(course.id))
        )
      )
        return false
      return true
    } else {
      if (body || title) return true
      return false
    }
  }

  handleClose(e) {
    const isChanged = this.checkChange()

    if (!e) this.props.onClose()
    if (isChanged) {
      this.setState({ showConfirm: true })
    } else {
      this.props.onClose()
    }
  }

  handleClear() {
    this.setState(initialState)
  }

  handleAddCourse(id) {
    const { courseList } = this.state
    const isExists = !!courseList.filter((course) => course.id === id).length
    if (!isExists) {
      const newCourse = this.props.courseList.find((course) => course.id === id)

      this.setState(
        {
          courseList: courseList.concat(newCourse),
        },
        this.getRecipients
      )
    }
  }

  removeCourse(index) {
    const { courseList } = this.state

    courseList.splice(index, 1)

    this.setState({ courseList }, this.getRecipients)
  }

  getQueryString() {
    let { date, startTime, endTime, courseList } = this.state
    let queryString = ''

    const from = DateHelpers.getUTCDateString(DateHelpers.getDateFromDateAndTime(date, startTime))
    const to = DateHelpers.getUTCDateString(DateHelpers.getDateFromDateAndTime(date, endTime))

    queryString += `from=${from}`
    queryString += `&to=${to}`
    courseList.forEach((item) => {
      queryString += `&courses[]=${item.id}`
    })
    queryString += '&limit=500'

    return queryString
  }

  getRecipients() {
    const { startTime, endTime, courseList } = this.state

    if (!courseList.length || !startTime.isBefore(endTime)) {
      this.props.onClearRecipients()
      return
    }
    const queryString = this.getQueryString()

    this.props.getRecipients(queryString)
  }

  generatePayload(status) {
    const { title, body, startTime, endTime, date, courseList } = this.state
    const { email } = this.props

    const from = DateHelpers.getUTCDateString(DateHelpers.getDateFromDateAndTime(date, startTime))
    const to = DateHelpers.getUTCDateString(DateHelpers.getDateFromDateAndTime(date, endTime))

    let payload = {
      courses: courseList.map((course) => course.id),
      title,
      body,
      from_time: from,
      to_time: to,
      status,
    }

    if (email?.id) payload.id = email.id

    return payload
  }

  saveDraft() {
    const payload = this.generatePayload(DRAFT)

    this.props.onSave(payload)
  }

  handleSend() {
    const payload = this.generatePayload(SEND)

    this.props.onSave(payload)
  }

  renderCourseSelect() {
    const { courseList } = this.props

    return (
      <div className={style.courseList}>
        <DropdownSelect
          showBorder
          placeholder="communication.selectCourse"
          values={courseList}
          onSelect={this.handleAddCourse}
        />
      </div>
    )
  }

  renderCourseList() {
    const { courseList } = this.state

    return <CourseList courseList={courseList} onRemove={this.removeCourse} />
  }

  renderRecipients() {
    const { recipients } = this.props
    const { players_count: playerNo = 0, bookings_count: bookingNo = 0 } = recipients

    if (!bookingNo) return <div />
    return (
      <div>
        {playerNo}
        &nbsp;
        {playerNo > 1 ? (
          <Text textId="sideMenu.menuItems.players.text" />
        ) : (
          <Text textId="player" />
        )}
        &nbsp;
        <Text textId="communication.in" />
        &nbsp;
        {bookingNo}
        &nbsp;
        {bookingNo > 1 ? <Text textId="bookings" /> : <Text textId="bookingLetter" />}
      </div>
    )
  }

  renderTimePeriod() {
    const { startTime, endTime } = this.state

    return (
      <div>
        <div className={style.datepicker}>
          <div className={style.date}>
            <Text textId="singleDate" />
          </div>
          <CalendarDropdown type="input" isCentered={false} onDateChange={this.handleDateChange} />
        </div>
        <TimePeriod startTime={startTime} endTime={endTime} onTimeChange={this.handleTimeChange} />
      </div>
    )
  }

  renderHeader() {
    return (
      <div className={style.header}>
        <div>
          <Text textId="communication.writeEmail" className={style.light} />
          {this.renderCourseSelect()}
        </div>
        {this.renderCourseList()}
        <hr />
        <div className={style.selectPeriod}>
          <Text textId="communication.selectPeriod" className={style.regular} />
          <Text textId="communication.selectDesc" />
        </div>
        <div className={style.dateTime}>
          {this.renderTimePeriod()}
          <div className={style.recipients}>
            <Text textId="communication.recipients" />
            {this.renderRecipients()}
          </div>
        </div>
        <hr />
      </div>
    )
  }

  renderBody() {
    const { title, body } = this.state
    const { isLoading } = this.props

    return (
      <form className={style.body} onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className={`ss-label ${style.label}`}>{t('communication.subject')}</label>
          <input
            className={`ss-input ${style.input}`}
            name="title"
            type="text"
            disabled={isLoading}
            value={title}
            onChange={this.handleChange}
          />
        </div>

        <div className="ss-form-group">
          <label className={`ss-label ${style.label}`}>{t('communication.bodyText')}</label>
          <textarea
            className={`ss-input ${style.input}`}
            name="body"
            rows="8"
            disabled={isLoading}
            value={body}
            onChange={this.handleChange}
          />
        </div>
      </form>
    )
  }

  renderFooter() {
    const { title, body } = this.state
    const { isLoading, recipients } = this.props

    return (
      <div className={style.footer}>
        <div className={style.buttons}>
          <button
            className="system-button primary-outline md-32"
            disabled={isLoading || !title || !body || !recipients.bookings_count}
            onClick={this.saveDraft}
          >
            {t('communication.saveDraft')}
          </button>

          <div className={style.recipients}>{this.renderRecipients()}</div>

          <button
            className="system-button info-outline md-32"
            disabled={isLoading || !title || !body || !recipients.bookings_count}
            onClick={this.toggleConfirmSend}
          >
            {t('communication.send')}
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { showConfirm, showConfirmSend } = this.state

    return (
      <div className={style.emailForm}>
        <div>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
        <span className={style.closeBtn} onClick={this.handleClose}>
          &times;
        </span>

        {showConfirm && (
          <ConfirmWindow
            textId="communication.confirmCloseTitle"
            onConfirm={() => this.props.onClose()}
            onCancel={this.toggleConfirm}
          />
        )}

        {showConfirmSend && (
          <ConfirmWindow
            textId="communication.confirmSend"
            onConfirm={this.handleSend}
            onCancel={this.toggleConfirmSend}
          />
        )}
      </div>
    )
  }
}

BookingEmail.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  email: PropTypes.object,
  courseList: PropTypes.array.isRequired,
  recipients: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  getRecipients: PropTypes.func.isRequired,
  onClearRecipients: PropTypes.func.isRequired,
}

export default BookingEmail
