import { useState, useEffect } from 'react'
import { DeviceType } from './types'
// import { isMobile, isTablet } from '@sweetspot/sweetspot-js/common/functions/utils'

const maxMobile = 760
const maxTablet = 1024

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.LAPTOP)
  const { userAgent } = window.navigator

  useEffect(() => {
    // if (isMobile) {
    //   setDeviceType(DeviceType.MOBILE)
    // } else if (isTablet) {
    //   setDeviceType(DeviceType.TABLET)
    // } else {
    //   setDeviceType(DeviceType.LAPTOP)
    // }

    const onScreenSizeChange = () => {
      if (window.innerWidth <= maxMobile) setDeviceType(DeviceType.MOBILE)
      if (window.innerWidth > maxMobile && window.innerWidth < maxTablet)
        setDeviceType(DeviceType.TABLET)
      if (window.innerWidth >= maxTablet) setDeviceType(DeviceType.LAPTOP)
    }
    onScreenSizeChange()

    window.addEventListener('resize', onScreenSizeChange)

    return () => {
      window.removeEventListener('resize', onScreenSizeChange)
    }
  }, [userAgent])

  return deviceType
}
