import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

const InputPrefixSuffix = ({
  type,
  value,
  error,
  success,
  children,
  focused,
  disabled,
  onSuffixClick,
  theme,
  calendarPrefix,
}) => {
  const renderChildren = () => {
    if (typeof children === 'string') {
      return <p className={styles.text}>{children}</p>
    } else if (typeof children === 'function') {
      return children()
    } else {
      return children
    }
  }

  return (
    <div
      className={cx(
        styles.componentContainer,
        styles.defaultHeight,
        styles.borderDefault,
        {
          [styles.borderError]: error,
          [styles.borderSuccess]: success,
          [styles.borderFilled]: value,
          [styles.borderActive]: focused,
          [styles.borderDisabled]: disabled,
        },
        {
          [styles.prefix]: type === 'prefix',
          [styles.suffix]: type === 'suffix',
        },
        {
          [styles.webAppTheme]: theme === 'webApp',
          [styles.voucherA]: theme === 'voucherA',
          [styles.voucherB]: theme === 'voucherB',
          [styles.loginTheme]: theme === 'login',
        },
        {
          [styles.calendarPrefix]: calendarPrefix,
        }
      )}
      onClick={onSuffixClick}
    >
      {renderChildren()}
    </div>
  )
}

InputPrefixSuffix.propTypes = {
  type: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  focused: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  disabled: PropTypes.bool,
  onSuffixClick: PropTypes.func,
  theme: PropTypes.oneOf(['webApp', 'club', 'voucherA', 'voucherB', 'login']),
  calendarPrefix: PropTypes.bool,
}
InputPrefixSuffix.defaultProps = {
  type: 'suffix',
  value: false,
  error: false,
  success: false,
  focused: false,
  children: '',
  disabled: false,
  theme: 'club',
  onSuffixClick: () => {},
  calendarPrefix: false,
}

export default InputPrefixSuffix
