import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'

export function _getPlayers(queryString) {
  const URL = `${getApiPlatformBaseUrl()}/players${queryString}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/ld+json',
    },
  })
}

export function _exportPlayers(clubId, filterQuery) {
  const URL = `${getApiPlatformBaseUrl()}/clubs/${clubId}/export/players`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: { filters: filterQuery },
  })
}

export function _getPlayerCard(playerId) {
  const URL = `${getApiPlatformBaseUrl()}/players/${playerId}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _getPlayerRight(clubId, playerId) {
  const URL = `${getApiPlatformBaseUrl()}/play-rights?player=${playerId}&golf_club=${clubId}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _setPlayerCard(payload) {
  const URL = `${getApiPlatformBaseUrl()}/players/${payload.id}`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}

export function _setPlayerRight(payload) {
  const { id: playerId, playRight, club } = payload
  const URL = `${getApiPlatformBaseUrl()}/crm/player/${playerId}/play-right/${
    playRight ? 'add' : 'delete'
  }`

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: { club: club?.uuid },
  })
}

export function _getFederationInfo(playerId, federationId) {
  const URL = `${getApiPlatformBaseUrl()}/federation-player-profiles?player=${playerId}&federation=${federationId}`

  return request(URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function _unlinkGolfId(playerId, federation = 'git') {
  const URL = `${getApiPlatformBaseUrl()}/player/${playerId}/unlink-federation-player-profile`

  const payload = {
    federation,
  }

  return request(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  })
}
