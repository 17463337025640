import React, { useRef } from 'react'
import cx from 'classnames'
import style from './number-input.module.scss'

const NumberInput = ({ className, ...props }) => {
  const inputRef = useRef()
  const { min, max } = props

  const onBlur = (ev) => {
    const { value } = ev.target
    let changed = false
    if (typeof min !== 'undefined' && +value < min) {
      setNativeValue(inputRef.current, min)
      changed = true
    }
    if (typeof max !== 'undefined' && +value > max) {
      setNativeValue(inputRef.current, max)
      changed = true
    }

    if (changed) {
      const event = new InputEvent('change', { bubbles: true })
      setTimeout(() => inputRef.current.dispatchEvent(event))
    }
  }

  return (
    <div className={style.numberInput}>
      <div className={style.arrows}>
        <div className={style.arrowUp}></div>
        <div className={style.arrowDown}></div>
      </div>
      <input
        className={cx(className, 'no-override')}
        onBlur={onBlur}
        ref={inputRef}
        type="number"
        {...props}
      />
    </div>
  )
}

export default NumberInput

const setNativeValue = (element, value) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set
  const prototype = Object.getPrototypeOf(element)
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value)
  } else {
    valueSetter.call(element, value)
  }
}
