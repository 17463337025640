import * as React from 'react'
import { cn } from '../../../utils'
import { Button, ButtonProps } from '../../atoms'

interface EmptyStateProps extends React.HTMLAttributes<HTMLDivElement> {
  showIcon?: boolean
  iconName?: string
  loading?: boolean
}

const EmptyState = React.forwardRef<HTMLDivElement, EmptyStateProps>(
  ({ children, className, showIcon = true, iconName, loading = false, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex max-w-[300px] flex-col items-center justify-center', className)}
      {...props}
    >
      {showIcon && (
        <i
          className={cn(
            'text-brand-5xl mb-xl',
            loading
              ? 'fa-light fa-loader animate-[spin_3s_linear_infinite]'
              : iconName || 'fa-light fa-golf-ball-tee'
          )}
          data-testid="emptystate-icon"
        />
      )}
      {children}
    </div>
  )
)
EmptyState.displayName = 'EmptyState'

const EmptyStateTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ children, className, ...props }, ref) => (
  <h2 ref={ref} className={cn('text-content-lg mb-sm text-center font-bold', className)} {...props}>
    {children}
  </h2>
))
EmptyStateTitle.displayName = 'EmptyStateTitle'

const EmptyStateDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn('text-content-sm font-regular text-center', className)} {...props} />
))
EmptyStateDescription.displayName = 'EmptyStateDescription'

export interface EmptyStateButtonProps extends ButtonProps {
  asChild?: boolean
}

const EmptyStateButton = React.forwardRef<HTMLButtonElement, EmptyStateButtonProps>(
  ({ className, asChild = false, ...props }, ref) => {
    return (
      <Button
        size="small"
        className={cn('mt-lg', className)}
        ref={ref}
        {...props}
        asChild={asChild}
      />
    )
  }
)
EmptyStateButton.displayName = 'EmptyStateButton'

export { EmptyState, EmptyStateTitle, EmptyStateDescription, EmptyStateButton }
