import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Text from '@sweetspot/club-portal-legacy/components/Text'

import styles from './styles.module.scss'
import { RentalActivity } from './RentalActivity'
import { useInfiniteQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import cx from 'classnames'
import { getRentalActivity } from '@sweetspot/sweetspot-js/features/productVariants/services/api-platform'
import { useTranslation } from 'react-i18next'
import SearchInput from '@sweetspot/club-portal-legacy/components/SearchInput'
import { InView, useInView } from 'react-intersection-observer'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp19, faArrowDown91 } from '@fortawesome/pro-light-svg-icons'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

const RentalActivities = ({ warehouseUuid, timezone }) => {
  const [searchString, setSearchString] = useState('')
  const [sortOrder, setSortOrder] = useState('desc')
  const { t } = useTranslation()
  const { ref, inView } = useInView()
  const itemsPerPage = 25

  const { data, isFetching, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
    [CLUB_QUERIES.RENTAL_ACTIVITY, warehouseUuid, searchString, sortOrder],
    ({ pageParam = 1 }) => {
      return getRentalActivity({
        variantName: searchString,
        warehouseUuid: warehouseUuid,
        order: sortOrder,
        holdTo: new Date().toISOString(),
        limit: itemsPerPage,
        page: pageParam,
      })
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const next = lastPage?.['hydra:view']?.['hydra:next']
        return next ? pages?.length + 1 : next
      },
      keepPreviousData: true,
      select: (data) => {
        const pages = data.pages.flatMap((page) => page['hydra:member'])
        return { pages: pages, rentals: pages }
      },
      onError: (err) => {
        return []
      },
      refetchOnWindowFocus: true,
    }
  )

  const handleSearch = _.debounce((val) => {
    setSearchString(val)
  }, 800)

  // Infinite scroll
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage, isFetching])

  const handleSort = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.searchContainer)}>
        <SearchInput
          placeholder={t('sentences.golfcartNamePlaceholder')}
          onChange={(value) => handleSearch(value)}
          isBordered
        />
        <PulseLoader showIf={isFetching} />
      </div>
      <div className={cx(styles.gridContainer)}>
        <div onClick={() => handleSort()} className={cx(styles.sortable)} role="columnheader">
          {t('words.date')}
          <FontAwesomeIcon icon={sortOrder === 'desc' ? faArrowDown91 : faArrowUp19} size={'lg'} />
        </div>
        <div role="columnheader">{t('words.course_one')}</div>
        <div role="columnheader">{t('sentences.startTime')}</div>
        <div role="columnheader">{t('words.return')}</div>
        <div role="columnheader">{t('words.rentalItem')}</div>
        <div role="columnheader"></div>
        <div role="columnheader"></div>

        {data && data.rentals?.length > 0 && (
          <>
            {data?.rentals.map((rentalItem, index) => (
              <RentalActivity
                key={rentalItem.id}
                rentalItem={rentalItem}
                isEven={(index + 1) % 2 === 0}
                timezone={timezone}
              />
            ))}
            <InView>
              <div ref={ref}></div>
            </InView>
          </>
        )}
        {data && data.rentals?.length === 0 && (
          <div className={styles.noActivity}>
            <Text textId="golfCarts.rentalActivity.noActivity" />
          </div>
        )}
      </div>
    </div>
  )
}

RentalActivities.propTypes = {
  warehouseUuid: PropTypes.string,
}

export default RentalActivities
