import { format } from 'date-fns'

import { createTeeSheetLink } from '@sweetspot/shared/data-access/api-platform'

/**
 * Generates a share link for a selected golf course and current date.
 * @param {object} selectedGolfCourse - The selected golf course object.
 * @param {Date} currentDate - The current date.
 * @returns {Promise<string|null>} - A promise that resolves to the share link or null if an error occurs.
 */
export const getShareLink = async (selectedGolfCourse, currentDate, lang) => {
  if (!selectedGolfCourse) return

  const date = format(currentDate, 'yyyy-MM-dd HH:mm:ss')

  const response = await createTeeSheetLink(selectedGolfCourse?.id, {
    available_date: date,
    locale: lang === 'se' ? 'sv' : lang,
  })

  return response?.hash
}
