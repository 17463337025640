import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { ReactComponent as SearchIcon } from '@sweetspot/sweetspot-js/assets/svgs/search-icon.svg'

import style from './style.module.scss'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

const DropdownSelect = ({
  preselectedOptions,
  options,
  onCancel,
  onSave,
  searchEnabled,
  isVisible,
  searchPlaceholder,
  singleSelectOnly,
  translateLabels,
  selectAllAvailable,
  onSearch,
  clearSearchCall,
  loadMorePages,
  loading,
}) => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const [selectedOptions, setSelectedOptions] = useState(preselectedOptions)
  const [searchResults, setSearchResults] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)

  const scrollContainer = useRef()

  useEffect(() => {
    setSelectedOptions(preselectedOptions)
  }, [preselectedOptions])

  useEffect(() => {
    if (!isVisible) setSelectedOptions(preselectedOptions)
  }, [isVisible])

  useEffect(() => {
    if (!loadMorePages) return

    const onScroll = (e) => {
      const target = e.target
      const isAtBottom = target.scrollHeight - target.scrollTop - target.clientHeight < 1

      if (isAtBottom && !loading) {
        loadMorePages()
      }
    }

    if (scrollContainer?.current) {
      scrollContainer.current.addEventListener('scroll', onScroll)
    }

    return () => {
      if (scrollContainer?.current) {
        scrollContainer.current.removeEventListener('scroll', onScroll)
      }
    }
  }, [loadMorePages, scrollContainer.current, loading])

  const toggleOption = (id, value) => {
    if (id === 'SELECT_ALL') {
      if (!value) setSelectedOptions([])
      else setSelectedOptions(options)
    } else {
      if (value) {
        const toggledOption = presentOptions().find((x) => x.id === id)
        setSelectedOptions([...selectedOptions, toggledOption])
      } else setSelectedOptions(selectedOptions.filter((x) => x.id !== id))
    }
  }

  const handleSearch = async (value) => {
    setSearchValue(value)
    if (!value || !value.length) {
      if (clearSearchCall) clearSearchCall()
      setLoadingSearch(false)
      setSearchResults([])
      setSelectedOptions(preselectedOptions)
      return
    }
    const searchVal = value.toLowerCase()

    let results

    if (onSearch) {
      setLoadingSearch(true)
      results = await onSearch(searchVal)
      setLoadingSearch(false)
    } else {
      results = options.filter((x) =>
        (translateLabels ? t(x.label) : x.label).toLowerCase().includes(searchVal)
      )
    }
    if (results) {
      setSearchResults(results)
    }
  }

  const presentOptions = () => {
    if (searchValue) {
      return searchResults || []
    } else {
      return options || []
    }
  }

  const renderCheckboxes = () => {
    return (
      <div
        className={cx(style.checkboxContainer, {
          [style.singleSelectOnly]: singleSelectOnly || !selectAllAvailable,
        })}
        ref={scrollContainer}
      >
        {!singleSelectOnly && selectAllAvailable && (
          <div className={style.checkbox}>
            <Checkbox
              value={selectedOptions.length >= options.length}
              onChange={(value) => toggleOption('SELECT_ALL', value)}
              label={t('sentences.selectAll')}
            />
          </div>
        )}

        {presentOptions().map((option, index) => {
          const currentValue = !!selectedOptions?.find((x) => x?.id === option?.id)
          const isDisabled = option?.disabled
          const greenMark = option?.greenMarker
          const redMark = option?.redMarker

          return (
            <div
              key={index}
              className={cx(style.checkbox, isDisabled && style.disabled)}
              onClick={() => !isDisabled && toggleOption(option.id, !currentValue)}
            >
              <Checkbox
                greenMark={greenMark}
                redMark={redMark}
                value={currentValue}
                disabled={
                  (singleSelectOnly &&
                    !!selectedOptions.length &&
                    !selectedOptions?.find((x) => x?.id === option?.id)) ||
                  option?.disabled
                }
                label={translateLabels ? t(option.label) : option.label}
              />
            </div>
          )
        })}
      </div>
    )
  }

  if (!isVisible) return null

  return (
    <div className={style.container}>
      <div className={style.content}>
        {searchEnabled && (
          <div className={style.searchField}>
            <SearchIcon className={style.searchIcon} />
            <TextInputField
              containerClassName={style.searchInput}
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={(val) => handleSearch(val)}
            />{' '}
          </div>
        )}
        {!loadingSearch && renderCheckboxes()}
        <div
          className={cx(style.loadingSearchContainer, (loadingSearch || loading) && style.visible)}
        >
          <PulseLoader showIf={true} />
        </div>
        <div className={style.buttons}>
          <Button
            theme="none"
            width="third"
            onClick={() => {
              onCancel()
            }}
          >
            {t('words.cancel')}
          </Button>
          <Button theme="default" width="third" onClick={() => onSave(selectedOptions)}>
            {t('words.save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

DropdownSelect.propTypes = {
  /** Boolean to enable search field */
  searchEnabled: PropTypes.bool,

  /** Boolean to show or hide component */
  isVisible: PropTypes.bool,

  /** Boolean to make only one option selectable at the time */
  singleSelectOnly: PropTypes.bool,

  /** Array of available options */
  options: PropTypes.array.isRequired,

  /** Array of already selected options */
  preselectedOptions: PropTypes.array.isRequired,

  /** Function to run when the dropdown should be closed */
  onCancel: PropTypes.func.isRequired,

  /** Function to run when save button is clicked */
  onSave: PropTypes.func.isRequired,

  /** Placeholder for search field */
  searchPlaceholder: PropTypes.string,

  translateLabels: PropTypes.bool,
  selectAllAvailable: PropTypes.bool,
  onSearch: PropTypes.func,
  clearSearchCall: PropTypes.func,
  loadMorePages: PropTypes.func,
  loading: PropTypes.bool,
}

DropdownSelect.defaultProps = {
  searchEnabled: false,
  isVisible: false,
  onSave: () => {},
  searchPlaceholder: '',
  options: [],
  preselectedOptions: [],
  singleSelectOnly: false,
  translateLabels: false,
  selectAllAvailable: true,
  loadingSearch: false,
  loadMorePages: null,
  loading: false,
  onSearch: null,
}

export default DropdownSelect
