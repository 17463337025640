import React from 'react'

interface TableCellProps {
  className?: string
  children: React.ReactNode
  rowSpan?: number
  cellType?: 'td' | 'th'
}

const TableCell: React.FC<TableCellProps> = ({
  className = '',
  children,
  rowSpan,
  cellType = 'td',
}) => {
  const Cell = cellType
  return (
    <Cell
      className={`whitespace-nowrap px-3.5 py-1 text-left text-xs ${className}`}
      rowSpan={rowSpan}
    >
      {children}
    </Cell>
  )
}

export default TableCell
