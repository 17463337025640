import {
  _addPartnership,
  _getPartnershipById,
  _getAdminsPartnershipList,
  _addPromotion,
  _updatePartnership,
  _deletePartnership,
  _cancelPartnership,
  _activatePartnership,
} from '@sweetspot/club-portal-legacy/services/partnershipApi'

import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
/**
 * Get's single partnership by ID
 *
 * @param {string|number} partnershipId
 * @returns {Promise}
 */
export const getPartnershipById = (partnershipId) => {
  return () => {
    return new Promise((resolve, reject) => {
      _getPartnershipById(partnershipId)
        .then((res) => resolve(res))
        .catch((err) => {
          reject(err)
        })
    })
  }
}

/**
 * Adds partnership to club
 *
 * @param {string|number} [golfClubId=null]
 * @param {Object} [payload]
 * @returns {Promise}
 */
export const addPartnership = (payload, golfClubId = null) => {
  return (dispatch, getState) => {
    const { golfClub } = getState()
    const { selectedId } = golfClub

    const clubId = golfClubId || selectedId

    if (!clubId) {
      dispatch(addToast('partnershipCreateError', 'error'))
      return
    }

    return new Promise((resolve, reject) => {
      _addPartnership(clubId, payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('partnershipCreateError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Updates partnership
 *
 * @param {string|number} partnershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const updatePartnership = (partnershipId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updatePartnership(partnershipId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('partnershipUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/**
 * Delete partnership from golf club
 *
 * @param {string|number} partnershipId
 */
export const deletePartnership = (partnershipId) => {
  return () => {
    return new Promise((resolve, reject) => {
      _deletePartnership(partnershipId)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }
}

/**
 * Cancel a partnership
 *
 * @param {string|number} partnershipId
 */
export const cancelPartnership = (partnershipId) => {
  return () => {
    return new Promise((resolve, reject) =>
      _cancelPartnership(partnershipId)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    )
  }
}

/**
 * Activates a partnership
 *
 * @param {string|number} partnershipId
 */
export const activatePartnership = (partnershipId) => {
  return () => {
    return new Promise((resolve, reject) => {
      _activatePartnership(partnershipId)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }
}

/**
 * Adds a promotion to a partnership
 *
 * @param {string} partnershipId
 * @param {Object} payload
 * @returns {Promise}
 */
export const addPromotionToPartnership = (partnershipId, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addPromotion(partnershipId, payload)
        .then((res) => resolve(res))
        .catch((err) => {
          dispatch(addToast('partnershipUpdatedError', 'error'))
          reject(err)
        })
    })
  }
}

/* Get's partnerships that partner admin has access to
 *
 * @param {string} token
 * @param {string|number} adminId
 * @returns {Promise}
 */
export const getAdminsPartnerships = (adminId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getAdminsPartnershipList(adminId)
        .then((data) => resolve(data))
        .catch((err) => {
          dispatch(addToast('fetchPartnershipsError', 'error'))
          reject(err)
        })
    })
  }
}
