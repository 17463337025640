import { useMemo } from 'react'
import { Redirect, Route } from 'react-router'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'

export const AccessControlledRoute = ({
  component: Component,
  children,
  accessTableKey,
  ...rest
}) => {
  const roles = useRoles()
  const role = useMemo(() => getFirstMatchingRole(roles), [roles])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessTableKey && hasAccess(accessTableKey, role?.value)) {
          if (Component) {
            return <Component {...props} />
          } else if (children) {
            return children
          }
        } else if (!accessTableKey) {
          if (Component) {
            return <Component {...props} />
          } else if (children) {
            return children
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/no-permission',
              }}
            />
          )
        }
      }}
    />
  )
}
