import React, { useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'
import { CHECKERS_TYPES } from '../../constants/checkers'
import SingleChecker from '../SingleChecker'

const CheckersWrapper = ({ policy, disabled }) => {
  const { t } = useTranslation()

  const [checkers, setCheckers] = useState(policy?.checkers || [])
  const [showSelectRule, setShowSelectRule] = useState(false)

  const dropdownRef = useRef(null)
  useOnClickOutside(dropdownRef, () => {
    setShowSelectRule(false)
  })

  const addNewChecker = (checker) => {
    if (checker.disabled) return

    setShowSelectRule(false)

    const configuration =
      checker?.type === 'simultaneously_booked_slots_checker'
        ? { number_of_simultaneous_slots: 1, same_day_booking_time: null }
        : {
            number: 0,
            ignore_when_adding_participant: false,
            type: 'days',
          }

    setCheckers((prev) => [
      ...prev,
      {
        id: 'new-' + crypto.randomUUID(),
        type: checker?.type,
        configuration: checker?.type === 'booked_slots_per_time_range_checker' ? [] : configuration,
      },
    ])
  }

  const removeChecker = (checker) => {
    setCheckers((prev) => {
      return prev.filter((item) => item.id !== checker.id)
    })
  }

  return (
    <div className={cx(styles.container)}>
      {checkers?.length <= 0 && (
        <h2 className={cx(styles.title)}>{t('sentences.noPolicyCheckers')}</h2>
      )}

      {checkers.map((checker, index) => (
        <SingleChecker
          index={index}
          key={checker.id}
          checker={checker}
          policyId={policy.uuid}
          onRemoveChecker={() => removeChecker(checker)}
          disabled={disabled}
        />
      ))}

      <div className={cx(styles.newContainer)}>
        <ButtonBasic
          text={t('words.policyChecker')}
          icon="plus"
          className={cx(styles.addChecker)}
          onClick={() => setShowSelectRule(true)}
          disabled={disabled}
        />
        <DropdownBox
          ref={dropdownRef}
          dropdownOpen={!disabled && showSelectRule}
          options={CHECKERS_TYPES.map((rule) => {
            if (checkers.find((x) => x.type === rule.value)) {
              return {
                ...rule,
                disabled: true,
              }
            }
            return rule
          })}
          className={styles.dropdown}
          onOptionClick={(option) => addNewChecker(option)}
        />
      </div>
    </div>
  )
}

CheckersWrapper.propTypes = {
  policy: PropTypes.shape({
    checkers: PropTypes.array,
    uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disabled: PropTypes.bool,
}

CheckersWrapper.defaultProps = {}

export default CheckersWrapper
