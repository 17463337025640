import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './style.module.scss'

/**
 * This component creates a container with full height and 15px padding
 */
export default class Container extends Component {
  render() {
    return <div className={cx(style.container, this.props.className)}>{this.props.children}</div>
  }
}

Container.defaultProps = {
  className: '',
}
Container.propTypes = {
  className: PropTypes.string,
}
