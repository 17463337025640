import ToptracerLogo from '@sweetspot/shared/assets/images/logos/bayToptracer.png'
import TrackmanLogo from '@sweetspot/shared/assets/images/logos/bayTrackman.png'
import InrangeLogo from '@sweetspot/shared/assets/images/logos/bayInrange.png'

import { TrackingTech } from '@sweetspot/shared/types'
import { cn } from '@sweetspot/scramble-ds'

const LOGO_MAP = {
  [TrackingTech.NONE]: '',
  [TrackingTech.TOPTRACER]: ToptracerLogo,
  [TrackingTech.TRACKMAN]: TrackmanLogo,
  [TrackingTech.INRANGE]: InrangeLogo,
}

const BayTechLogo = ({
  technology,
  className = '',
  noneClassName = '',
}: {
  technology: TrackingTech
  className?: string
  noneClassName?: string
}) => {
  if (technology === TrackingTech.NONE)
    return <i className={cn('fa-kit fa-range-light', noneClassName)} />

  const logoSrc = LOGO_MAP[technology]
  return <img src={logoSrc} alt={technology} className={className} />
}

export default BayTechLogo
