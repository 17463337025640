import { useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'

import style from '../../style.module.scss'

interface GolfIdSelectProps {
  formState: {
    is_golf_id_required: boolean | null
  }
  setValue: (key: string, value: boolean | null) => void
  hasCdhNumber?: boolean
}

type GolfIdOptions = {
  [key: string]: {
    id: number
    name: string
    value: boolean | null
  }
}

const GolfIdSelect = ({ formState, setValue, hasCdhNumber }: GolfIdSelectProps) => {
  const { t } = useTranslation()

  const golfIdOptions: GolfIdOptions = useMemo(
    () => ({
      0: { id: 0, name: t('sentences.noChange'), value: null },
      1: { id: 1, name: t('yes'), value: true },
      2: { id: 2, name: t('no'), value: false },
    }),
    [t]
  )

  const selectedId = useMemo(() => {
    return parseInt(
      Object.keys(golfIdOptions).find(
        (key: string) => golfIdOptions[key].value === formState.is_golf_id_required
      ) as string
    )
  }, [golfIdOptions, formState.is_golf_id_required])

  return (
    <div>
      <div className={style.optionsText}>
        {hasCdhNumber ? t('editPeriodsNew.cdhNumberRequired') : t('editPeriodsNew.golfIDRequired')}
      </div>
      <div className={cx(style.select, style.categorySelect, '!pt-0 [&_svg]:!top-[14px]')}>
        <DropdownSelect
          values={Object.values(golfIdOptions)}
          selectedId={selectedId}
          initialId={selectedId}
          onSelect={(id) => setValue('is_golf_id_required', golfIdOptions[id].value)}
          noMargin
          readOnly
        />
      </div>
    </div>
  )
}

export default GolfIdSelect
