import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleMinus } from '@fortawesome/pro-solid-svg-icons'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import { ReactComponent as infoIcon } from '@sweetspot/sweetspot-js/assets/svgs/info-symbol.svg'
import { useProductVariant } from '@sweetspot/sweetspot-js/features/productVariants/hooks/useProductVariant'

const GolfCart = ({ uuid, name, state, onRemove, isDisabled }) => {
  const [cartName, setCartName] = useState(name || '')
  const [active, setActive] = useState(state)
  const [showModal, setShowModal] = useState(false)
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { productVariant: golfCart, updateVariant } = useProductVariant(uuid)

  useEffect(() => {
    setActive(golfCart?.state || state)
  }, [updateVariant.isSuccess, golfCart?.state])

  useEffect(() => {
    if (golfCart?.name) {
      setCartName(golfCart?.name)
    }
  }, [golfCart?.name])

  const handleDelete = () => {
    onRemove()
  }

  const onToggle = (val) => {
    if (!val) {
      setShowModal(true)
      setActive('not_active')
    } else {
      setActive('active')
    }
    updateVariant.mutate({ name: cartName, state: val ? 'active' : 'not_active' })
  }

  const handleUpdate = () => {
    if (cartName !== golfCart.name) {
      updateVariant.mutate({ name: cartName.trim(), state: active })
    }
  }

  const renderStatus = () => {
    if (!updateVariant?.context?.previousVariant || !golfCart?.name) {
      return null
    }
    const status = (
      <div
        className={cx(styles.status, {
          [styles.success]:
            updateVariant.isSuccess && updateVariant.context.previousVariant.name !== golfCart.name,
        })}
      >
        <FontAwesomeIcon icon={faCircleCheck} />
      </div>
    )
    return status
  }

  const renderError = (err) => {
    if (err.status !== 400) {
      addToast(`${t('errors.somethingWentWrongNoId')} ${t('sentences.contactSupport')}`, {
        appearance: 'error',
      })
    }
    updateVariant.reset()
  }

  return (
    <div className={cx(styles.container)}>
      {updateVariant.error && renderError(updateVariant.error)}
      <TextInputField
        inputWidth="full"
        value={cartName}
        onChange={(val) => setCartName(val.length > 250 ? val.slice(0, -1) : val)}
        onInputBlur={() => handleUpdate()}
        placeholder={t('sentences.golfcartNamePlaceholder')}
        containerClassName={cx(styles.input)}
        autoFocus
        inlineSuffix={renderStatus()}
        disabled={isDisabled}
      />
      <div className={cx(styles.clickable)}>
        <ToggleButton
          value={active === 'active'}
          onChange={(val) => onToggle(val)}
          containerWidth="full"
          containerClassName={cx(styles.toggle)}
          disabled={isDisabled}
        ></ToggleButton>
      </div>
      <div
        className={cx(styles.clickable, { [styles.isDisabled]: isDisabled })}
        onClick={handleDelete}
      >
        <FontAwesomeIcon icon={faCircleMinus} />
      </div>
      <ConfirmPopup
        onClose={() => setShowModal(false)}
        title={t('sentences.golfcartDeactivationTitle')}
        text={t('words.active')}
        visible={showModal}
        acceptText={t('sentences.agree')}
        onAccept={() => setShowModal(false)}
        acceptTheme="default"
        titleIcon={infoIcon}
        overrideIconColor={true}
      >
        {t('sentences.golfcartDeactivationWarning')}
      </ConfirmPopup>
    </div>
  )
}

export { GolfCart }
