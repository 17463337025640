import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import styles from './styles.module.scss'

import i18n from 'i18next'

import { ReactComponent as InfoSymbol } from '@sweetspot/sweetspot-js/assets/svgs/info-symbol.svg'

class InfoHover extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isTooltipActive: false,
    }

    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
  }

  showTooltip() {
    this.setState({
      isTooltipActive: true,
    })
  }

  hideTooltip() {
    this.setState({
      isTooltipActive: false,
    })
  }

  renderTooltip() {
    if (this.state.isTooltipActive) {
      return (
        <div className={styles.infoHoverTooltip}>
          <div
            className={styles.arrow}
            style={this.props.arrowPosition ? { top: `${this.props.arrowPosition}px` } : undefined}
          ></div>
          {this.props.textId ? (
            <div>{i18n.t(this.props.textId)}</div>
          ) : (
            <div>{this.props.text}</div>
          )}
          {this.props.children && <div>{this.props.children}</div>}
        </div>
      )
    }
  }

  render() {
    return (
      <div
        className={cx(styles.infoHover, this.props.containerClassName)}
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
      >
        {this.props.toolTipIcon ? (
          <div className={styles.miniPill}>{this.props.toolTipIcon}</div>
        ) : (
          <InfoSymbol />
        )}

        {this.renderTooltip()}
      </div>
    )
  }
}

InfoHover.propTypes = {
  text: PropTypes.any,
  textId: PropTypes.string,
  toolTipIcon: PropTypes.any,
  inline: PropTypes.bool,
  containerClassName: PropTypes.string,
  arrowPosition: PropTypes.number,
}

export default connect()(InfoHover)
