import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import OrderArrows from '../OrderArrows'

export default class OrderCell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: Utilities.isSet(this.props.initialValue) ? this.props.initialValue : '...',
    }

    this.handleClickUp = this.handleClickUp.bind(this)
    this.handleClickDown = this.handleClickDown.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: Utilities.isSet(this.props.initialValue) ? this.props.initialValue : '...',
      })
    }
  }

  handleClickUp() {
    let newValue = this.state.value - 1
    if (this.props.onChange) {
      this.props.onChange(newValue)
    }
  }

  handleClickDown() {
    let newValue = this.state.value + 1
    if (this.props.onChange) {
      this.props.onChange(newValue)
    }
  }

  render() {
    return (
      <div className={style.container}>
        <div className={style.textContainer}>{this.state.value}</div>
        <div className={style.arrowContainer}>
          {!this.props.nonEditable && (
            <OrderArrows onClickUp={this.handleClickUp} onClickDown={this.handleClickDown} />
          )}
        </div>
      </div>
    )
  }
}

OrderCell.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  nonEditable: PropTypes.bool,
}
