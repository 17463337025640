import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18next'

import Text from '@sweetspot/club-portal-legacy/components/Text'
import PlayRightResult from '@sweetspot/club-portal-legacy/Partials/Dialogs/PlayRightResult'

import {
  createPlayRightsImport,
  getImportStatus,
  processPlayRightsImport,
} from '@sweetspot/shared/data-access/api-platform'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions'

import style from './style.module.scss'

class PlayRights extends Component {
  constructor() {
    super()

    this.state = {
      isLoading: false,
      isShowResult: false,
      golfIds: [],
      failedGolfIds: [],
    }

    this.toggleShowResult = this.toggleShowResult.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  toggleShowResult() {
    this.setState({ isShowResult: !this.state.isShowResult })
  }

  handleClick() {
    const {
      golfClub: { selectedId },
      addToast,
    } = this.props

    const golfIds = this._golfIds.value.split('\n').filter((item) => !!item.replace(/\s/g, ''))
    const payload = {
      body: {
        golf_club: `/api/clubs/${selectedId}`,
        golf_ids: golfIds,
      },
    }

    this.setState({ isLoading: true })
    createPlayRightsImport(payload)
      .then(({ id }) => {
        processPlayRightsImport(id)
          .then(() => {
            let isProcessing = true
            setInterval(() => {
              if (!isProcessing) return
              getImportStatus(id)
                .then(({ status, failed_golf_ids, golf_ids }) => {
                  if (status !== 'in progress') {
                    isProcessing = false
                    this.setState({
                      isLoading: false,
                      isShowResult: true,
                      failedGolfIds: failed_golf_ids ? Object.keys(failed_golf_ids) : [],
                      golfIds: golf_ids,
                    })
                  }
                })
                .catch((err) => {
                  addToast('importPlayRightsError', 'error')
                  this.setState({
                    isLoading: false,
                    failedGolfIds: [],
                    golfIds: [],
                  })
                })
            }, 2000)
          })
          .catch((err) => {
            addToast('importPlayRightsError', 'error')
            this.setState({
              isLoading: false,
              failedGolfIds: [],
              golfIds: [],
            })
          })
      })
      .catch((err) => {
        addToast('importPlayRightsError', 'error')
        this.setState({
          isLoading: false,
          failedGolfIds: [],
          golfIds: [],
        })
      })
  }

  render() {
    const { isShowResult, golfIds, failedGolfIds, isLoading } = this.state
    const { golfClub } = this.props

    const succeedGolfIds = golfIds.filter((golfId) => !failedGolfIds.includes(golfId))

    return (
      <div className={style.container}>
        <div className={style.title}>
          <Text textId="settings.playRights.importPlayers" />
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.playRights.addGolfIDs')}</label>
            <textarea
              className="ss-input"
              rows="20"
              disabled={isLoading}
              ref={(input) => (this._golfIds = input)}
            />
          </div>

          <div className={style.formButtons}>
            <button
              className="system-button info-outline ss-block-button md-32"
              disabled={isLoading}
              onClick={this.handleClick}
            >
              {t('settings.playRights.upload')}
            </button>
          </div>
        </form>

        {isShowResult && (
          <PlayRightResult
            succeedGolfIds={succeedGolfIds.length}
            failedGolfIds={failedGolfIds}
            golfClub={golfClub}
            onClick={this.toggleShowResult}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    golfClub: state.golfClub,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToast: (message, type) => dispatch(addToast(message, type)),
  }
}

PlayRights.propTypes = {
  golfClub: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayRights)
