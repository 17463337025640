import { createContext, useContext } from 'react'
import { createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface TextareaInterface {
  charLength: number
  setCharLength: (open: number) => void
  maxCharacters: number
}

export const createTextareaStore = (initProps?: Partial<TextareaInterface>) => {
  const DEFAULT_PROPS = {
    charLength: 0,
    maxCharacters: 999,
  }

  return createStore(
    immer<TextareaInterface>((set) => ({
      ...DEFAULT_PROPS,
      ...initProps,
      setCharLength: (charLength) =>
        set((draft) => {
          draft.charLength = charLength
        }),
    }))
  )
}

export const TextareaContext = createContext<ReturnType<typeof createTextareaStore> | null>(null)

export function useTextareaStoreContext() {
  const context = useContext(TextareaContext)
  if (!context) {
    throw new Error('useTextareaStoreContext must be used within a TextareaProvider')
  }
  return context
}
