/**
 * Describes the shape of the context data for Player Card.
 * This includes properties for managing dialog type, membership duration, and the membership year,
 * as well as associated setters for each property.
 */
export type PlayerCardContextData = {
  dialogType: number | null
  duration: number
  year: number
  setDialogType: (value: number | null) => void
  setDuration: (value: number) => void
  setYear: (value: number) => void
}

/**
 * Describes the shape of an information tab field, which can be used to capture
 * specific details like user data, system attributes, etc.
 */
export type InformationField = {
  id: number
  text: string
  value?: string
  disabled?: boolean
  isActive?: boolean
}

/**
 * Enumerates the types of sidebar items available within the player card modal.
 */
export enum SidebarItem {
  INFORMATION = 1,
  MEMBERSHIP,
  VOUCHER,
  BOOKING,
  FEDERATION_INFO,
}

/**
 * Enum representing available membership actions.
 */
export enum MembershipActions {
  SEND_PAYMENT_LINK = 1,
  MARK_AS_PAID,
  REMOVE_MEMBERSHIP,
  ADD_MEMBERSHIP,
  CANCEL_OR_REFUND,
  LOCK_UNLOCK_MEMBERSHIP,
}
