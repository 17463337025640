import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import produce from 'immer'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { t } from 'i18next'

import style from './style.module.scss'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import {
  getAddressValues,
  loadExternalScript,
} from '@sweetspot/club-portal-legacy/helpers/GolfClubHelpers'
import { getSpecificGolfClub, createGolfClub } from '@sweetspot/club-portal-legacy/store/actions'
import { getGoogleMapsApiKey } from '@sweetspot/club-portal-legacy/utils/getKeys'

import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import ToggleSwitch from '@sweetspot/club-portal-legacy/components/ToggleSwitch'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import CountrySelect from '../CountrySelect'

const orderedRevenueSettingsKeys = [
  'memberships_revenue_fee',
  'green_fees_revenue_fee',
  'partnership_revenue_fee',
  'rentals_revenue_fee',
  'vouchers_revenue_fee',
  'others_revenue_fee',
  'extras_revenue_fee',
  'simulator_fee',
  'driving_range_fee',
  'range_dispenser_fee',
  'pro_class_fee',
]

class CreateGolfClub extends Component {
  state = {
    federation: 'none',
    availableGolfclubs: [],
    currency: 'SEK',
    selectedGolfclub: null,
    selectedPartners: [],
    showAlert: false,
    errorText: '',
    weekdayFromTime: null,
    weekdayToTime: null,
    weekendFromTime: null,
    weekendToTime: null,
    translations: {},
    enableScorecardPrinting: false,
    enableShowingPlayerNames: false,
    enableReceivingBookingConfirmation: false,
    toggleMembershipChurnReport: false,
    business_area: 'other',
    isDemo: false,
    payment_provider: 'braintree',
    promotionMembershipBeforeVoucher: false,
    revenue_settings: {
      memberships_revenue_fee: '',
      green_fees_revenue_fee: '',
      partnership_revenue_fee: '',
      rentals_revenue_fee: '',
      vouchers_revenue_fee: '',
      others_revenue_fee: '',
      extras_revenue_fee: '',
      simulator_fee: '',
      driving_range_fee: '',
      range_dispenser_fee: '',
      pro_class_fee: '',
    },
    addressCountry: '',
  }

  autoCompleteRef = React.createRef()
  federationSelector = React.createRef()

  componentWillMount = () => {
    this.getTranslations()
  }

  componentDidMount = () => {
    const GOOGLE_API_SCRIPT = `https://maps.googleapis.com/maps/api/js?key=${getGoogleMapsApiKey()}&language=${
      this.props.lang
    }&libraries=places`
    const scriptEle = loadExternalScript(GOOGLE_API_SCRIPT)
    scriptEle.addEventListener('load', () => {
      if (this._addressLine1) {
        const options = {
          fields: ['address_components'],
        }
        this.autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          this._addressLine1,
          options
        )
        this.autoCompleteRef.current.addListener('place_changed', this.fillAddress)
      }
    })
  }

  fillAddress = () => {
    const place = this.autoCompleteRef.current.getPlace()
    const { line1, postalCode, city, state, country } = getAddressValues(place)
    this._addressLine1.value = line1
    this._addressPostalCode.value = postalCode
    this._addressCity.value = city
    this._addressState.value = state
    this.setState({ addressCountry: country })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.lang !== this.props.lang) {
      this.getTranslations()
    }
  }

  getTranslations = () => {
    const { lang } = this.props
    this.setState({
      translations: {
        memberships_revenue_fee: GetTranslatedString(lang, 'settings.membershipRevFee'),
        green_fees_revenue_fee: GetTranslatedString(lang, 'settings.greenFeeRevFee'),
        partnership_revenue_fee: GetTranslatedString(lang, 'settings.partnersRevFee'),
        rentals_revenue_fee: GetTranslatedString(lang, 'settings.rentalsRevFee'),
        vouchers_revenue_fee: GetTranslatedString(lang, 'settings.vouchersRevFee'),
        others_revenue_fee: GetTranslatedString(lang, 'settings.othersRevFee'),
        extras_revenue_fee: GetTranslatedString(lang, 'settings.extrasFee'),
        simulator_fee: GetTranslatedString(lang, 'settings.simulatorRevFee'),
        driving_range_fee: GetTranslatedString(lang, 'settings.drivingRangesRevFee'),
        pro_class_fee: GetTranslatedString(lang, 'settings.proClassesRevFee'),
        range_dispenser_fee: GetTranslatedString(lang, 'settings.rangeDispenserFee'),
        voucher_apply_before: GetTranslatedString(lang, 'settings.voucherApplyBefore'),
        voucher_apply_after: GetTranslatedString(lang, 'settings.voucherApplyAfter'),
      },
    })
  }

  toggleAlert = () => {
    this.setState({ showAlert: !this.state.showAlert })
  }

  validateEmail = (email) => {
    return Utilities.validateEmail(email)
  }

  handleRevenueSettingChange = (key, value) => {
    if (value < 0 || value > 100) return
    this.setState(
      produce((state) => {
        state.revenue_settings[key] = value
      })
    )
  }

  handleTimeChange = (target, moment) => {
    this.setState({
      [target]: moment ? moment : '',
    })
  }

  checkForErrors = (settings) => {
    let error = false,
      errorText = ''
    if (settings.name.trim() === '') {
      error = true
      errorText = 'settings.nameRequired'
    } else if (
      !Utilities.validateEmail(settings.email) ||
      !Utilities.validateEmail(settings.companyEmail)
    ) {
      error = true
      errorText = 'settings.invalidEmail'
    }
    return { error, errorText }
  }

  resetValues = () => {
    this._id.value = ''
    this._name.value = ''
    this._phone.value = ''
    this._email.value = ''
    this._website.value = ''
    this._terms_url = ''
    this._description.value = ''
    this._companyName.value = ''
    this._addressLine1.value = ''
    this._addressLine2.value = ''
    this._addressCity.value = ''
    this._addressState.value = ''
    this._addressPostalCode.value = ''
    this._billingPhone.value = ''
    this._billingEmail.value = ''
    this._organisationNumber.value = ''
    this._VATNumber.value = ''
    this._paymentProvider = ''
    this._businessArea = ''
    this.setState({
      enableScorecardPrinting: false,
      enableShowingPlayerNames: false,
      enableReceivingBookingConfirmation: false,
      toggleMembershipChurnReport: false,
      federation: 'none',
      promotionMembershipBeforeVoucher: false,
      revenue_settings: {
        memberships_revenue_fee: '',
        green_fees_revenue_fee: '',
        rentals_revenue_fee: '',
        partnership_revenue_fee: '',
        vouchers_revenuee_fee: '',
        others_revenue_fee: '',
        extras_revenue_fee: '',
        simulator_fee: '',
        driving_range_fee: '',
        pro_class_fee: '',
        range_dispenser_fee: '',
      },
      addressCountry: '',
    })
  }

  saveSettings = () => {
    const { weekdayFromTime, weekdayToTime, weekendFromTime, weekendToTime, revenue_settings } =
      this.state
    const { token, createGolfClub } = this.props

    const revenueSettings = {}
    for (const key in revenue_settings) {
      const parsedValue = parseFloat(revenue_settings[key])
      revenueSettings[key] = isNaN(parsedValue) ? null : parsedValue
    }

    let schedule = {
      is_enabled: 0,
      settings: {
        sunday: {
          from: null,
          to: null,
        },
        monday: {
          from: null,
          to: null,
        },
        tuesday: {
          from: null,
          to: null,
        },
        wednesday: {
          from: null,
          to: null,
        },
        thursday: {
          from: null,
          to: null,
        },
        friday: {
          from: null,
          to: null,
        },
        saturday: {
          from: null,
          to: null,
        },
      },
    }

    if (!!weekdayFromTime || !!weekendFromTime) {
      const weekdayFrom = weekdayFromTime.format('HH:mm:ss')
      const weekDayTo = weekdayToTime.format('HH:mm:ss')
      const weekendFrom = weekendFromTime.format('HH:mm:ss')
      const weekendTo = weekendToTime.format('HH:mm:ss')

      schedule = {
        is_enabled: 1,
        settings: {
          sunday: {
            from: weekendFrom,
            to: weekendTo,
          },
          monday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          tuesday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          wednesday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          thursday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          friday: {
            from: weekdayFrom,
            to: weekDayTo,
          },
          saturday: {
            from: weekendFrom,
            to: weekendTo,
          },
        },
      }
    }

    const address = {
      line1: this._addressLine1.value,
      line2: this._addressLine2.value,
      city: this._addressCity.value,
      state: this._addressState.value,
      postal_code: this._addressPostalCode.value,
      country: {
        code: this.state.addressCountry,
      },
    }
    const billing = {
      company_name: this._companyName.value,
      phone: this._billingPhone.value,
      email: this._billingEmail.value,
      organisation_number: this._organisationNumber.value,
      vat_number: this._VATNumber.value,
    }

    const federationId =
      this.state.federation !== 'none'
        ? {
            [this.state.federation === 'git' ? 'git_id' : 'cdh_id']:
              this._id.value === '' ? null : this._id.value,
          }
        : {}

    const payload = {
      ...federationId,
      name: this._name.value,
      currency_code: this.state.currency,
      federation: this.state.federation === 'none' ? null : this.state.federation,
      payment_provider: this._paymentProvider,
      business_area: this._businessArea,
      is_demo: this.state.isDemo,
      phone: this._phone.value,
      email: this._email.value,
      website: this._website.value,
      terms_url: this._terms_url.value,
      description: this._description.value,
      is_sync_with_git: true,
      is_admin_assure_bookable: true,
      is_score_card_printing_enabled: this.state.enableScorecardPrinting,
      is_expose_player_name: this.state.enableShowingPlayerNames,
      is_enabled_club_admin_receive_booking_email: this.state.enableReceivingBookingConfirmation,
      send_membership_churn_report: this.state.toggleMembershipChurnReport,
      is_promotion_membership_before_voucher: this.state.promotionMembershipBeforeVoucher,
      revenue_settings: revenueSettings,
      address,
      billing,
      schedule,
    }
    let { error, errorText } = this.checkForErrors(payload)

    if (error) {
      this.setState({ errorText })
      this.toggleAlert()
    } else {
      createGolfClub(token, payload)
        .then(() => {
          this.resetValues()
        })
        .catch((err) => {
          if (!err?.violations) this.setState({ errorText: 'errors.somethingWentWrongNoId' })
          else {
            this.setState({ errorText: 'errors.generalFormError' })
          }
        })
    }
  }

  renderSuperAdminSettings = () => {
    const paymentProviders = [
      { id: 'braintree', name: 'Braintree' },
      { id: 'adyen', name: 'Adyen' },
    ]

    const businessAreas = [
      { id: 'golf_club', name: 'Golf Club' },
      { id: 'simulator', name: 'Simulator' },
      { id: 'range', name: 'Range' },
      { id: 'academy', name: 'Academy' },
      { id: 'other', name: 'Other' },
    ]

    const onSelectBusinessArea = (businessArea) => (this._businessArea = businessArea)
    const onSelectPaymentProvider = (paymentType) => (this._paymentProvider = paymentType)
    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="superAdminSettings" />
          </div>
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('sentences.mainBusinessArea')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={this._businessArea || this.state.business_area}
            values={businessAreas}
            onSelect={onSelectBusinessArea}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('sentences.isDemo')}</label>
          <ToggleSwitch
            textUnchecked={'NO'}
            textChecked={'YES'}
            checked={this.state.isDemo}
            onChange={(checked) => this.setState({ isDemo: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('sentences.paymentProvider')}</label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={this._paymentProvider || this.state.payment_provider}
            values={paymentProviders}
            onSelect={onSelectPaymentProvider}
          />
        </div>
        <div>
          <label className="ss-label !mb-2">{t('settings.sweetspotFees')}</label>
          {/* Revenue settings */}
          <div className={style.revenueSettings}>
            {orderedRevenueSettingsKeys.map((setting) => (
              <TextInputField
                key={setting}
                label={this.state.translations[setting]}
                value={this.state.revenue_settings[setting]}
                onChange={(percentage) => this.handleRevenueSettingChange(setting, percentage)}
                type="number"
                inputWidth="quarter"
                step={setting === 'extras_revenue_fee' ? '0.01' : '1'}
                inputProps={
                  setting === 'extras_revenue_fee'
                    ? {
                        min: 0.01,
                        max: 99.9,
                      }
                    : {
                        min: 1,
                        max: 100,
                      }
                }
                suffix="%"
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  renderScheduleSettings = () => {
    const { weekdayFromTime, weekdayToTime, weekendFromTime, weekendToTime } = this.state
    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="settings.openingHours" />
          </div>
          <InfoHover textId="settings.infoHoversGolfclub.openingHours" />
        </div>
        <div className={style.columnContainer}>
          <Text textId="settings.weekday" />
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekdayFromTime ? moment(weekdayFromTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekdayFromTime', moment)}
            minuteStep={5}
          />
          -
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekdayToTime ? moment(weekdayToTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekdayToTime', moment)}
            minuteStep={5}
          />
        </div>

        <div className={style.columnContainer}>
          <Text textId="settings.weekend" />
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekendFromTime ? moment(weekendFromTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekendFromTime', moment)}
            minuteStep={5}
          />
          -
          <TimePicker
            className={style.timePicker}
            showSecond={false}
            value={weekendToTime ? moment(weekendToTime, 'HH:mm') : moment('00:00:00', 'HH:mm')}
            onChange={(moment) => this.handleTimeChange('weekendToTime', moment)}
            minuteStep={5}
          />
        </div>
      </div>
    )
  }

  renderDiscountCalculation = () => {
    const discountTypes = [
      { id: 'before', name: this.state.translations.voucher_apply_before },
      { id: 'after', name: this.state.translations.voucher_apply_after },
    ]
    const onSelectDiscountType = (discountType) => {
      this.setState({ promotionMembershipBeforeVoucher: discountType === 'after' })
    }
    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="settings.discountCalculation" />
          </div>
        </div>
        <div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.applyVoucherDiscount')}</label>
            <DropdownSelect
              showBorder
              width="100%"
              initialId={this.state.promotionMembershipBeforeVoucher ? 'after' : 'before'}
              values={discountTypes}
              onSelect={onSelectDiscountType}
            />
          </div>
        </div>
      </div>
    )
  }

  renderBillingSettings = () => {
    const lang = this.props.lang
    const onSelectCountry = (country) => this.setState({ addressCountry: country })

    return (
      <div className={style.section}>
        <div className={style.header}>
          <div className={style.title}>
            <Text textId="settings.billingInformation" />
          </div>
        </div>
        <div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.companyName')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._companyName = input)}
              placeholder={getLang(lang)['settings']['addCompanyName']}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.addresseLine1')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['settings']['addAddresseLine1']}
              ref={(input) => (this._addressLine1 = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.addresseLine2')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['settings']['addAddresseLine2']}
              ref={(input) => (this._addressLine2 = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('players.city')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['players']['addCity']}
              ref={(input) => (this._addressCity = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.state')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['settings']['addState']}
              ref={(input) => (this._addressState = input)}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('players.zipCode')}</label>
            <input
              className="ss-input"
              type="text"
              placeholder={getLang(lang)['players']['addZipCode']}
              ref={(input) => (this._addressPostalCode = input)}
            />
          </div>
          <CountrySelect onSelect={onSelectCountry} value={this.state.addressCountry} />
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.billingPhone')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._billingPhone = input)}
              placeholder={getLang(lang)['settings']['addBillingPhone']}
              onChange={Utilities.autoFormatNumber}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.billingEmail')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._billingEmail = input)}
              placeholder={getLang(lang)['settings']['addBillingEmail']}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.organisationNumber')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._organisationNumber = input)}
              placeholder={getLang(lang)['settings']['addOrganisationNumber']}
            />
          </div>
          <div className="ss-form-group">
            <label className="ss-label">{t('settings.VATNumber')}</label>
            <input
              className="ss-input"
              type="text"
              ref={(input) => (this._VATNumber = input)}
              placeholder={getLang(lang)['settings']['addVATNumber']}
            />
          </div>
        </div>
      </div>
    )
  }

  renderForm = () => {
    const currencyTypes = [
      { id: 'SEK', name: 'SEK' },
      { id: 'DKK', name: 'DKK' },
      { id: 'GBP', name: 'GBP' },
      { id: 'EUR', name: 'EUR' },
      { id: 'NOK', name: 'NOK' },
      { id: 'CHF', name: 'CHF' },
      { id: 'CAD', name: 'CAD' },
      { id: 'USD', name: 'USD' },
    ]

    const golfFederations = [
      { id: 'none', name: t('settings.notApplicable') },
      { id: 'git', name: 'Svenska Golfförbundet' },
      { id: 'cdh', name: 'Scottish Golf Union' },
    ]

    const onSelectCurrencyType = (currencyType) => this.setState({ currency: currencyType })
    const onFederationSelect = (federationValue) => this.setState({ federation: federationValue })

    return (
      <form className={style.golfClubForm} onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.name')}
            <InfoHover textId="settings.infoHoversGolfclub.name" />
          </label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._name = input)}
            placeholder={getLang(this.props.lang)['settings']['name']}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('Federation')}
            <InfoHover textId="settings.federation" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId={'none'}
            ref={this.federationSelector}
            values={golfFederations}
            onSelect={(value) => onFederationSelect(value)}
          />
        </div>
        {this.state.federation !== 'none' && (
          <div className="ss-form-group">
            <label className="ss-label">
              {t('Federation ID')}
              <InfoHover textId="settings.infoHoversGolfclub.id" />
            </label>
            <input
              className={`ss-input ${style.formControl}`}
              type="text"
              ref={(input) => (this._id = input)}
              placeholder="Federation ID"
            />
          </div>
        )}

        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.phone')}
            <InfoHover textId="settings.infoHoversGolfclub.phone" />
          </label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._phone = input)}
            placeholder={getLang(this.props.lang)['settings']['phone']}
            onChange={Utilities.autoFormatNumber}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.email')}
            <InfoHover textId="settings.infoHoversGolfclub.email" />
          </label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._email = input)}
            placeholder={getLang(this.props.lang)['settings']['email']}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('words.currency')}
            <InfoHover textId="sentences.currencyInfo" />
          </label>
          <DropdownSelect
            showBorder
            width="100%"
            initialId="SEK"
            values={currencyTypes}
            onSelect={onSelectCurrencyType}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.website')}
            <InfoHover textId="settings.infoHoversGolfclub.website" />
          </label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._website = input)}
            placeholder={getLang(this.props.lang)['settings']['website']}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.terms')}
            <InfoHover textId="settings.infoHoversGolfclub.terms" />
          </label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._terms_url = input)}
            placeholder="https://"
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.description')}
            <InfoHover textId="settings.infoHoversGolfclub.description" />
          </label>
          <textarea
            className="ss-input"
            type="text"
            rows="4"
            ref={(input) => (this._description = input)}
            placeholder={getLang(this.props.lang)['settings']['description']}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableScoreCard')}
            <InfoHover textId="settings.infoHoversGolfclub.enableScoreCard" />
          </label>
          <ToggleSwitch
            checked={this.state.enableScorecardPrinting}
            onChange={(checked) => this.setState({ enableScorecardPrinting: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableShowingPlayerNames')}
            <InfoHover textId="settings.infoHoversGolfclub.enableShowingPlayerNames" />
          </label>
          <ToggleSwitch
            checked={this.state.enableShowingPlayerNames}
            onChange={(checked) => this.setState({ enableShowingPlayerNames: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableReceivingBookingConfirmation')}
            <InfoHover textId="settings.infoHoversGolfclub.enableReceivingBookingConfirmation" />
          </label>
          <ToggleSwitch
            checked={this.state.enableReceivingBookingConfirmation}
            onChange={(checked) => this.setState({ enableReceivingBookingConfirmation: checked })}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">
            {t('settings.enableMembershipChurtReport')}
            <InfoHover textId="settings.infoHoversGolfclub.enableMembershipChurtReport" />
          </label>
          <ToggleSwitch
            checked={this.state.toggleMembershipChurnReport}
            onChange={(checked) => this.setState({ toggleMembershipChurnReport: checked })}
          />
        </div>
        {this.renderScheduleSettings()}
        {this.renderDiscountCalculation()}
        {this.renderBillingSettings()}
        {this.props.isSuperAdmin && this.renderSuperAdminSettings()}
        <div className={style.formButtons}>
          <button onClick={this.resetValues} className="system-button primary-outline md-32">
            {t('settings.cancel')}
          </button>
          <button onClick={this.saveSettings} className="system-button info-outline md-32">
            {t('save')}
          </button>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.label}>
          <Text textId="settings.createGolfclub" />
        </div>
        {this.renderForm()}
        <Alert
          showIf={this.state.showAlert}
          onClick={() => {
            this.setState({
              showAlert: false,
            })
          }}
        >
          <Text textId={this.state.errorText} />
        </Alert>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    golfClubList: state.golfClub.list,
    token: state.auth.token,
    golfClub: state.golfClub,
    isSuperAdmin: state.auth.isSuperAdmin,
    lang: state.auth.me.lang,
    roles: state.auth.roles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createGolfClub: (token, payload) => dispatch(createGolfClub(token, payload)),
    getSpecificGolfClub: (token, id) => dispatch(getSpecificGolfClub(token, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateGolfClub))

CreateGolfClub.propTypes = {
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool,
  createGolfClub: PropTypes.func.isRequired,
  golfClubList: PropTypes.array.isRequired,
}
