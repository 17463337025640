import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import styles from './styles.module.scss'

import {
  FORM,
  PERIOD_TYPE,
  VOUCHER_TYPE,
  DURATION_TYPE,
  DEFAULT_DURATION_TYPE,
  DEFAULT_DURATION_NUMBER,
  DEFAULT_NO_FINAL_DATE_OF_USE,
} from '@sweetspot/sweetspot-js/features/vouchers/hooks/useVoucher'
import * as DATE from '@sweetspot/sweetspot-js/features/vouchers/tools/date'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import SingleRadioOption from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/SingleRadioOption'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import InputSelectDateSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectDateSimple'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { toLength } from 'lodash'

const GeneralTab = ({ isActive, voucher, voucherActions, editField, loaders, errors }) => {
  const { t } = useTranslation()
  if (!voucher) return null
  const isNew = voucher[FORM.ID] === 'new'
  const isDynamicPeriod = voucher[FORM.PERIOD_TYPE] === PERIOD_TYPE.DYNAMIC_PERIOD
  const isBulk = voucher[FORM.VOUCHER_TYPE] === VOUCHER_TYPE.BULK
  return (
    <div className={cx(styles.container, !isActive && styles.inactive)}>
      <TextInputField
        containerWidth={'half'}
        containerClassName={styles.input}
        onInputFocus={() => voucherActions.startEdit(FORM.NAME)}
        inputProps={{ autoFocus: !loaders.voucher && isNew }}
        showActionButtons={editField === FORM.NAME}
        showConfirmButton
        confirmText={t(`vouchers.label_${isNew ? 'Create' : 'Save'}`)}
        showCancelButton={true}
        onOutsideClick={editField === FORM.NAME ? voucherActions.cancelEdit : () => void 0}
        onConfirm={() => voucherActions.confirmEdit('name')}
        onCancel={() => voucherActions.cancelEdit('name')}
        confirmLoading={loaders.name}
        feedbackStyle={{
          feedbackContainer: cx(styles.error, styles.name),
        }}
        label={t('vouchers.label_VoucherName')}
        lightMainLabelColor
        placeholder={t('vouchers.placeholder_VoucherName')}
        value={voucher[FORM.NAME] || ''}
        error={errors[FORM.NAME]}
        onChange={(value = '') => voucherActions.editVoucher(FORM.NAME, value)}
      />
      <div className={cx(styles.choosePeriodType, styles.optionsRow)}>
        <SingleRadioOption
          containerClassName={cx(styles.option)}
          checked={!isDynamicPeriod}
          onChange={() => voucherActions.editVoucher(FORM.PERIOD_TYPE, PERIOD_TYPE.PERIOD)}
          label={t('vouchers.label_Period')}
        />
        <SingleRadioOption
          containerClassName={cx(styles.option)}
          checked={isDynamicPeriod}
          onChange={() => voucherActions.editVoucher(FORM.PERIOD_TYPE, PERIOD_TYPE.DYNAMIC_PERIOD)}
          label={t('vouchers.label_DynamicPeriod')}
        />
      </div>
      <div className={cx(styles.periodContainer, { [styles.active]: !isDynamicPeriod })}>
        <div>
          <Label label={t('vouchers.label_ValidFrom')} lightColor />
        </div>
        <div>
          <InputSelectDateSimple
            className={cx(styles.dateInput)}
            currentDate={DATE.now(voucher[FORM.STARTS_AT])}
            onSetDate={(value) => voucherActions.editVoucher(FORM.STARTS_AT, value)}
            disableBeforeToday={true}
            placeholder={t('vouchers.label_SelectDate')}
            orientation={'right'}
            value={DATE.value(voucher[FORM.STARTS_AT], 'YYYY-MM-DD')}
            error={errors[FORM.STARTS_AT] && t('errors.dateAndTimeInFuture')}
          />
        </div>
        <div>
          <Label label={t('vouchers.label_Time')} lightColor />
        </div>
        <div className={styles.time}>
          <TextInputField
            type="time"
            error={errors[FORM.START_TIME]}
            inputClassName={styles.timeInput}
            placeholder="00:00"
            value={voucher[FORM.START_TIME]}
            onChange={(value) => voucherActions.editVoucher(FORM.START_TIME, value)}
          />
        </div>
        <div>
          <Label label={t('vouchers.label_ValidTill')} lightColor />
        </div>
        <div>
          <InputSelectDateSimple
            className={cx(styles.dateInput)}
            currentDate={DATE.now(voucher[FORM.ENDS_AT])}
            onSetDate={(value) => voucherActions.editVoucher(FORM.ENDS_AT, value)}
            disableBeforeToday={true}
            placeholder={t(`vouchers.label_${voucher[FORM.NO_END] ? 'NoEnd' : 'SelectDate'}`)}
            orientation={'right'}
            startDate={DATE.parse(voucher[FORM.STARTS_AT])}
            endDate={DATE.max()}
            value={DATE.value(voucher[FORM.ENDS_AT], 'YYYY-MM-DD', voucher[FORM.NO_END])}
            disabled={voucher[FORM.NO_END]}
            error={errors?.[FORM.ENDS_AT]}
          />
        </div>
        <div>
          <Label label={t('vouchers.label_Time')} lightColor />
        </div>
        <div className={styles.time}>
          <TextInputField
            type="time"
            disabled={voucher[FORM.NO_END]}
            error={errors[FORM.END_TIME]}
            inputClassName={styles.timeInput}
            placeholder="00:00"
            value={voucher[FORM.END_TIME]}
            onChange={(value) => voucherActions.editVoucher(FORM.END_TIME, value)}
          />
        </div>
        <div></div>
        <ToggleButton
          value={voucher[FORM.NO_END]}
          secondaryLabel={t('vouchers.label_NoEnd')}
          error={errors[FORM.NO_END]}
          containerWidth="quarter"
          onChange={(value) => voucherActions.editVoucher(FORM.NO_END, value)}
          containerClassName={cx(styles.toggleEndDate)}
        />
        <div></div>
        <div
          className={cx(
            styles.periodConfirm,
            editField && editField !== FORM.NAME && editField !== 'discount' && styles.visible
          )}
        >
          <Button
            size="small"
            theme="default-outline"
            width="third"
            text={t(`words.cancel`)}
            className={cx(styles.action, loaders.period && styles.hidden)}
            onClick={() => voucherActions.cancelEdit('period')}
          />
          <Button
            size="small"
            width="third"
            className={cx(styles.action)}
            onClick={() => voucherActions.confirmEdit('period')}
          >
            {loaders.period ? (
              <PulseLoader
                color="#FFF"
                dotStyles={{ width: 7, height: 7 }}
                className={styles.loader}
                showIf={true}
              />
            ) : (
              t('words.save')
            )}
          </Button>
        </div>
      </div>
      <div
        className={cx(styles.periodContainer, {
          [styles.active]: isDynamicPeriod,
          [styles.dynamicPeriod]: isDynamicPeriod,
        })}
      >
        <div>
          <Label label={t('vouchers.label_Quantity')} lightColor />
        </div>
        <div className={styles.quantity}>
          <TextInputField
            type="number"
            error={errors[FORM.DURATION_NUMBER]}
            inputClassName={styles.quantityInput}
            value={voucher[FORM.DURATION_NUMBER] ?? DEFAULT_DURATION_NUMBER}
            onChange={(value) =>
              voucherActions.editVoucher(FORM.DURATION_NUMBER, value ? toLength(value) : value)
            }
            step={1}
            inputProps={{ min: 1 }}
          />
        </div>
        <div>
          <Label label={t('vouchers.label_Period')} lightColor />
        </div>
        <div className={styles.quantity}>
          <DropdownSelect
            error={errors?.course}
            showBorder
            width="100%"
            disabled={false}
            values={Object.values(DURATION_TYPE).map((id) => ({
              id,
              name: t(`vouchers.option_${id}`),
            }))}
            initialId={voucher[FORM.DURATION_TYPE] || DEFAULT_DURATION_TYPE}
            onSelect={(id) => {
              voucherActions.editVoucher(FORM.DURATION_TYPE, id)
            }}
          />
        </div>
        <div></div>
        <ToggleButton
          value={voucher[FORM.NO_END] ?? DEFAULT_NO_FINAL_DATE_OF_USE}
          secondaryLabel={t('vouchers.label_NoFinalDateOfUse')}
          containerWidth="full"
          onChange={(value) => voucherActions.editVoucher(FORM.NO_END, value)}
          containerClassName={cx(styles.toggleEndDate)}
        />
        {!(voucher[FORM.NO_END] ?? DEFAULT_NO_FINAL_DATE_OF_USE) && (
          <React.Fragment>
            <div>
              <Label label={t('vouchers.label_FinalDateOfUse')} lightColor />
            </div>
            <div>
              <InputSelectDateSimple
                className={cx(styles.dateInput)}
                currentDate={DATE.now(voucher[FORM.ENDS_AT])}
                onSetDate={(value) => voucherActions.editVoucher(FORM.ENDS_AT, value)}
                disableBeforeToday={true}
                placeholder={t(`vouchers.label_${voucher[FORM.NO_END] ? 'NoEnd' : 'SelectDate'}`)}
                orientation={'bottom'}
                error={errors[FORM.ENDS_AT]}
                startDate={DATE.parse(voucher[FORM.STARTS_AT])}
                endDate={DATE.max()}
                value={DATE.value(voucher[FORM.ENDS_AT], 'YYYY-MM-DD', voucher[FORM.NO_END])}
              />
            </div>
          </React.Fragment>
        )}
        <div></div>
        <div
          className={cx(
            styles.periodConfirm,
            editField && editField !== FORM.NAME && editField !== 'discount' && styles.visible
          )}
        >
          <Button
            size="small"
            theme="default-outline"
            width="third"
            text={t(`words.cancel`)}
            className={cx(styles.action, loaders.period && styles.hidden)}
            onClick={() => voucherActions.cancelEdit('period')}
          />
          <Button
            size="small"
            width="third"
            className={cx(styles.action)}
            onClick={() => voucherActions.confirmEdit('period')}
          >
            {loaders.period ? (
              <PulseLoader
                color="#FFF"
                dotStyles={{ width: 7, height: 7 }}
                className={styles.loader}
                showIf={true}
              />
            ) : (
              t('words.save')
            )}
          </Button>
        </div>
      </div>
      <div className={styles.typeArea}>
        <span className={styles.label}>{t('vouchers.label_VoucherType')}</span>
        {isNew ? (
          <div className={styles.optionsRow}>
            <SingleRadioOption
              containerClassName={cx(styles.option)}
              checked={!isBulk}
              onChange={() =>
                voucherActions.editVoucher(FORM.VOUCHER_TYPE, VOUCHER_TYPE.STANDALONE)
              }
              label={t('vouchers.option_Standalone')}
            />
            <SingleRadioOption
              containerClassName={cx(styles.option)}
              checked={isBulk}
              onChange={() => voucherActions.editVoucher(FORM.VOUCHER_TYPE, VOUCHER_TYPE.BULK)}
              label={t('vouchers.option_Bulk')}
            />
          </div>
        ) : (
          <div className={styles.optionsRow}>
            <SingleRadioOption
              containerClassName={cx(styles.option)}
              checked={true}
              onChange={() => void 0}
              label={t(`vouchers.option_${isBulk ? 'Bulk' : 'Standalone'}`)}
            />
          </div>
        )}
      </div>
      {voucher[FORM.VOUCHER_TYPE] === VOUCHER_TYPE.STANDALONE && (
        <div className={styles.codeArea}>
          <TextInputField
            containerWidth={'half'}
            containerClassName={cx(styles.input, !isNew ? styles.noCursor : false)}
            disabled={!isNew}
            feedbackStyle={{
              feedbackContainer: cx(styles.error, styles.code),
            }}
            label={t('vouchers.label_Code')}
            lightMainLabelColor
            placeholder={t('vouchers.placeholder_Code')}
            value={voucher.code}
            error={errors[FORM.CODE]}
            onChange={(value) => voucherActions.editVoucher(FORM.CODE, value)}
          />
          {isNew ? <p className={styles.or}>{t('vouchers.label_Or')}</p> : null}
          <Button
            size="small"
            theme="default-outline"
            width="third"
            text={t(`vouchers.button_${isNew ? 'Generate' : 'Copy'}`)}
            className={styles.codeBtn}
            onClick={isNew ? () => voucherActions.generateCode() : voucherActions.copyCode}
          />
        </div>
      )}
      {isNew ? (
        <Button
          size="small"
          width="third"
          text={t('vouchers.button_Create')}
          className={styles.createBtn}
          disabled={false}
          onClick={voucherActions.createVoucher}
        />
      ) : null}
    </div>
  )
}

GeneralTab.propTypes = {}

GeneralTab.defaulProps = {}

export default GeneralTab
